import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import FormLabel from '../../FormLabel';

type FormItemProps = {
  label: string;
  onSearch: (value: string) => void;
};
export default function FormItem(props: FormItemProps): JSX.Element {
  const { label, onSearch } = props;
  const [value, setValue] = useState<string>('');
  const handleChange = useCallback((e: any) => {
    const {
      target: { value: newValue },
    } = e;
    setValue(newValue);
  }, []);
  const handleSearch = useCallback(() => {
    if (value.trim().length >= 0) {
      onSearch(value);
    }
  }, [value, onSearch]);
  return (
    <Row style={{ marginTop: 10 }}>
      <Col span={24}>
        <Row align="middle">
          <Col span={5}>
            <Row justify="end">
              <FormLabel>{label}</FormLabel>
            </Row>
          </Col>
          <Col span={1} />
          <Col span={16}>
            <Row>
              <Col span={18}>
                <Input
                  placeholder={`input search ${label}`}
                  allowClear
                  onChange={handleChange}
                />
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  disabled={value.length < 1}
                  onClick={handleSearch}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
