export const notFoundProductOptions = [
  {
    value: 0,
    label: 'Include NFP Orders',
  },
  {
    value: 1,
    label: 'Hide Full NFP Orders',
  },
  {
    value: 2,
    label: 'Hide Partial NFP Orders',
  },
  {
    value: 3,
    label: 'Show Only NFP Orders',
  },
];

export const orderLineQtyOptions = [
  {
    value: 0,
    label: 'Show All',
  },
  {
    value: 1,
    label: 'Single Item Order',
  },
  {
    value: 2,
    label: 'Multiple Items',
  },
  {
    value: 3,
    label: 'Single Item Single Qty Order',
  },
  {
    value: 4,
    label: 'Single Item Multiple Qty',
  },
  /*{
    value: 5,
    label: 'Single Item with Custom Qty',
  },*/
];

export const outOfStockOptions = [
  {
    value: 0,
    label: 'Include OOS Orders',
  },
  {
    value: 1,
    label: 'Hide Full OOS Orders',
  },
  {
    value: 2,
    label: 'Hide Partial OOS Orders',
  },
  {
    value: 3,
    label: 'Show Only OOS Orders',
  },
];

export const printStateOptions = [
  {
    value: 1,
    label: 'Show Printed',
  },
  {
    value: 2,
    label: 'Show Unprinted',
  },
  {
    value: 3,
    label: 'Show Partial Printed',
  },
  {
    value: 0,
    label: 'Show All',
  },
];

export const productKitStateOptions = [
  {
    value: 0,
    label: 'All Orders',
  },
  {
    value: 1,
    label: 'Kit Orders',
  },
  {
    value: 2,
    label: 'Normal Orders',
  },
  {
    value: 3,
    label: 'Kit Only',
  },
  {
    value: 4,
    label: 'Normal Only',
  },
  {
    value: 5,
    label: 'Both Only',
  },
];
