import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  // Spin,
  Modal,
  Button,
  Select,
  Space,
  Popover,
  Image,
  Col,
  Card,
  // Switch,
  Checkbox,
  Tabs,
  Divider,
  Typography,
  Dropdown,
  Menu
} from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../../components/common/ModalDialog';
import {
  fetchPackageAndServiceList,
  delShipServiceById,
  exportShipAccountToChannelAccount,
  previewCartonLabel,
} from 'services/storeSetup';
// import { queryCountry } from 'services/country';
import { fetchPrintTemplates } from 'services/print';
import {
  getStoreByID,
  updateStoreInfo,
  fetchChannelAccountShipAccountList,
  delChannelAccountShipAccount,
  getChannelAccountInsertItem,
  delChannelAccountInsertItemById,
  getSlipList,
  deleteSlip,
  copySlip,
  previewSlipTemplate,
  getCartonLableList,
  deleteCartonLable,
  getGroupSlipList,
  deleteGroupSlip,
  copyGroupSlip,
  previewGroupSlipTemplate
} from 'services/storeSetup';
import { fetchAllShipAccountList } from 'services/ship';
import { fetchCarriers } from 'services/shipment';
// import { leaveEditForm } from 'utils';
import {
  DeleteOutlined,
  EditOutlined,
  FileSearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { COMMON_PADDING_SPACE, PERMISSION_LEVEL } from 'constants/config';
import {
  FormSubmitButton,
  DataGridPlus,
  BoolCell,
  // SectionWrapper,
  message as showMessage,
  FormLabel,
} from 'components/common';
// import {SelectCountry} from 'components/common';
import {
  getChannelAccountAddress,
  deleteChannelAccountAddress,
} from 'services/channelAccountAddress';
// import {
//   queryBillThridParty,
//   delBillThridPartyById,
// } from 'services/billThirdPartyAddress';
import { getAddressList } from 'services/address';

import AddServiceDialog from './AddServiceDialog';
import AddressDialog from './AddAddressDialog';
// import AddThirdPartyDialog from './AddThirdPartyDialog';
import AddChannelShipDialog from './AddChannelAccountShipAccountDialog';
import CopyServiceDialog from './CopyServiceDialog';
import InsertItemDialog from './InsertItemDialog';
import SlipTemplateDialog from './PackingSlipDialog';
import GroupSlipTemplateDialog from './GroupPackingSlipDialog';
import CartonLabelTemplateDialog from './CartonLabelDialog';
import fallbackImage from 'assets/images/logo192.png';
import { nowTime, sortByString } from 'utils';
import { userProfiles } from 'utils/auth';
import ChannelAccountSettingPage from './ChannelAccountCustomSetting';

const { TabPane } = Tabs;
const { Text } = Typography;

interface EditDetailProps extends ModalProps {
  onHide: () => void;
  onRefresh: () => void;
  onDelete: () => void;
  managePermission: number;
  storeInfo: StoreChannelRow | undefined;
}
interface ServiceRow {
  Service: {
    ShipServiceNum: number;
    ChannelAccountNum: number;
    ShipAccountNum: number;
    Carrier: string;
    Service: string;
    Primary: number;
    Status: number;
    Name: string;
  };
  Package: {
    ChannelAccountNum: number;
    PackageNum: number;
    ShipPackageNum: number;
    CarrierPackage: string;
    ShipAccountNum: number;
    Name: string;
    Primary: number;
    Status: number;
  };
}

// eslint-disable-next-line
export default (props: EditDetailProps) => {
  const { onHide, onRefresh, onDelete, storeInfo, managePermission } = props;
  const [loading, setLoading] = useState(false);
  // const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [loading4, setLoading4] = useState(false);
  // const [loading5, setLoading5] = useState(false);
  // const [loading6, setLoading6] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  // const [country, setCountry] = useState<any[]>([]);
  const [store, setStore] = useState<StoreChannelInfo>();
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [copyServiceDialogVisible, setCopyServiceDialogVisible] =
    React.useState(false);
  const [addressDialogVisible, setAddressDialogVisible] = React.useState(false);
  const [insertItemDialogVisible, setInsertItemDialogVisible] =
    React.useState(false);
  const [channelShipDialogVisible, setChannelShipDialogVisible] =
    React.useState(false);
  const [slipDialogVisible, setSlipDialogVisible] = React.useState(false);
  const [groupSlipDialogVisible, setGroupSlipDialogVisible] = React.useState(false);

  const [cartonLabelDialogVisible, setCartonLabelDialogVisible] =
    React.useState(false);
  const [currentChannelShip, setCurrentChannelShip] =
    React.useState<ChannelAccountShipAccountRow>();
  const [serviceList, setServiceList] = React.useState<ServiceRow[]>([]);
  const [currentService, setCurrentService] = React.useState<ServiceRow>();
  const [currentAddress, setCurrentAddress] =
    React.useState<ChannelAccountAddressRow>();
  const [currentInsertItem, setCurrentInsertItem] = React.useState<any>();
  // const [thirdPartyList, setThirdPartyList] = React.useState<
  //   BillThirdPartyRow[]
  // >([]);
  // const [currentThirdParty, setCurrentThirdParty] =
  //   React.useState<BillThirdPartyRow>();
  // const [thirdPartyDialog, setThirdPartyDialog] = React.useState(false);
  const [templates, setTemplates] = React.useState<PrintTemplate[]>([]);
  const [groupTemplates, setGroupTemplates] = React.useState<PrintTemplate[]>([]);
  const [cartonLabelTemplates, setCartonLabelTemplates] = React.useState<
    PrintTemplate[]
  >([]);
  const [edited, setEdited] = useState<boolean>(false);
  const [shipList, setShipList] = useState<any[]>([]);
  const [addressList, setAddressList] = useState<ChannelAccountAddressRow[]>(
    []
  );
  const [addressBook, setAddressBook] = useState<AddressRow[]>([]);
  const [channelShipList, setChannelShipList] = useState<
    ChannelAccountShipAccountRow[]
  >([]);
  const [insetItemList, setInsetItemList] = useState<any[]>([]);
  const [carriers, setCarriers] = useState<CarrierRow[]>([]);

  // const [showEditButton, setShowEditButton] = useState(true);
  const [fileFormat, setFileFormat] = useState<string>('');
  const [slipList, setSlipList] = useState<any[]>([]);
  const [groupSlipList, setGroupSlipList] = useState<any[]>([]);
  const [currentSlip, setCurrentSlip] = useState<any>();
  const [currentGroupSlip, setCurrentGroupSlip] = useState<any>();
  const [cartonLabelList, setCartLabelList] = useState<any[]>([]);
  const [currentCartLabel, setCurrentCartLabel] = useState<any>();
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [currentStatus, setCurrentStatus] = useState<number>();

  const [form] = Form.useForm();

  document.body.style.overflowY = 'hidden';

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getCarriers = async () => {
    try {
      const res = await fetchCarriers();
      setCarriers(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getSlips = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await getSlipList(storeInfo.channelAccountNum);
      if (res.isSuccess) {
        setSlipList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupSlips = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await getGroupSlipList(storeInfo.channelAccountNum);
      if (res.isSuccess) {
        setGroupSlipList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCartonlabel = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      setLoading8(true);
      const res = await getCartonLableList(storeInfo.channelAccountNum);
      setLoading8(false);
      if (res.isSuccess) {
        setCartLabelList(res.data);
      }
    } catch (error) {
      setLoading8(false);
      console.log(error);
    }
  };

  const deleteCartonLabelById = async (id: number) => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await deleteCartonLable(storeInfo.channelAccountNum, id);
      if (res.isSuccess) {
        showMessage.success({ content: 'Deleted Successfully' });
        getCartonlabel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copySlips = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await copySlip(storeInfo.channelAccountNum);
      if (res.isSuccess) {
        showMessage.success({ content: 'Copy Successfully' });
        getSlips();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyGroupSlips = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await copyGroupSlip(storeInfo.channelAccountNum);
      if (res.isSuccess) {
        showMessage.success({ content: 'Copy Successfully' });
        getGroupSlips();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSlipsById = async (id: number) => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await deleteSlip(storeInfo.channelAccountNum, id);
      if (res.isSuccess) {
        showMessage.success({ content: 'Deleted Successfully' });
        getSlips();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteGroupSlipsById = async (id: number) => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    try {
      const res = await deleteGroupSlip(storeInfo.channelAccountNum, id);
      if (res.isSuccess) {
        showMessage.success({ content: 'Deleted Successfully' });
        getGroupSlips();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressList = async () => {
    if (!storeInfo || !storeInfo.channelAccountNum) return;
    setLoading2(true);
    try {
      const res = await getChannelAccountAddress(storeInfo.channelAccountNum);
      setLoading2(false);
      if (res.isSuccess) {
        setAddressList(res.data);
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const fetchAddressBook = useCallback(async () => {
    try {
      // setLoading1(true);
      const res = await getAddressList();
      // setLoading1(false);
      if (res.isSuccess) {
        setAddressBook(res.data);
      }
    } catch (error) {
      // setLoading1(false);
    }
  }, []);

  const fetchChannelAccountInsertItem = async () => {
    try {
      setLoading7(true);
      const res = await getChannelAccountInsertItem(
        storeInfo?.channelAccountNum || 0
      );
      setLoading7(false);
      if (res.isSuccess) {
        setInsetItemList(res.data);
      }
    } catch (error) {
      setLoading7(false);
    }
  };

  const delelteInsertItemById = async (id: number) => {
    try {
      const res = await delChannelAccountInsertItemById(id);
      if (res.isSuccess) {
        showMessage.success({ content: 'deleted successfully' });
        fetchChannelAccountInsertItem();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const delChanelAccountAddressById = async (id: number) => {
    try {
      const res = await deleteChannelAccountAddress(id);
      if (res.isSuccess) {
        showMessage.success({ content: 'Deleted Successfully' });
        fetchAddressList();
      } else {
        showMessage.success({ content: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getChannelAccountShipAccountList = async () => {
    try {
      setLoading4(true);
      const res = await fetchChannelAccountShipAccountList(
        storeInfo?.channelAccountNum || 0
      );
      setLoading4(false);
      if (res.isSuccess) {
        setChannelShipList(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'warehouseCode'))
        );
      }
    } catch (error) {
      setLoading4(false);
      console.log(error);
    }
  };

  const delChannelAccountShipAccountById = async (id: number) => {
    try {
      const res = await delChannelAccountShipAccount(id);
      if (res.isSuccess) {
        showMessage.success({ content: 'deleted successfully' });
        getChannelAccountShipAccountList();
      } else {
        showMessage.success({ content: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServices = useCallback(async () => {
    try {
      setLoading3(true);
      const res = await fetchPackageAndServiceList(
        storeInfo?.shipAccountNum || 0,
        storeInfo?.channelAccountNum || 0
      );
      setLoading3(false);
      if (res.isSuccess) {
        setServiceList(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'warehouseCode'))
        );
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (error) {
      setLoading3(false);
      console.log(error);
    }
  }, [storeInfo]);

  const fetchShipList = async () => {
    try {
      // setLoading5(true);
      const res = await fetchAllShipAccountList();
      // setLoading5(false);
      setShipList(res);
    } catch (error) {
      // setLoading5(false);
      console.log(error);
    }
  };

  const getPackingSlipTemplates = async () => {
    try {
      const profile = userProfiles.profile;
      // setLoading6(true);
      const res = await fetchPrintTemplates({
        TemplateType: 8,
        ChannalNum: storeInfo?.centralChannelNum,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      // setLoading6(false);
      if (res.isSuccess) {
        setTemplates(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'templateName'))
        );
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (error) {
      // setLoading6(false);
      console.log(error);
    }
  };

  const getGroupPackingSlipTemplates = async () => {
    try {
      const profile = userProfiles.profile;
      // setLoading6(true);
      const res = await fetchPrintTemplates({
        TemplateType: 12,
        ChannalNum: storeInfo?.centralChannelNum,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      // setLoading6(false);
      if (res.isSuccess) {
        setGroupTemplates(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'templateName'))
        );
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (error) {
      // setLoading6(false);
      console.log(error);
    }
  };

  const getCartonLabelTemplates = async () => {
    try {
      const profile = userProfiles.profile;
      // setLoading6(true);
      const res = await fetchPrintTemplates({
        TemplateType: 9,
        ChannalNum: storeInfo?.centralChannelNum,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      // setLoading6(false);
      if (res.isSuccess) {
        setCartonLabelTemplates(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'templateName'))
        );
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (error) {
      // setLoading6(false);
      console.log(error);
    }
  };

  const getStore = async () => {
    try {
      setLoading(true);
      const res = await getStoreByID(storeInfo?.channelAccountNum || 0);
      setLoading(false);
      if (res.isSuccess) {
        setStore(res.data);
        setFileFormat(res.data.importFileFormat);
        setCurrentStatus(res.data.status);
        // setSnapshot(JSON.stringify(form.getFieldsValue()));
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const delServiceById = async (id: number) => {
    try {
      const res = await delShipServiceById(id);
      if (res.isSuccess) {
        showMessage.success({ content: 'Deleted Successfully' });
        fetchServices();
      } else {
        showMessage.success({ content: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!store || !storeInfo) return;
    console.log('params = ', params);
    // return
    try {
      setSaveLoading(true);
      const saveParams = {
        ChannelAccountNum: store.channelAccountNum,
        ChannelAccountName: params.ChannelAccountName,
        ChannelName: store.channelName,
        TimeZone: '',
        Status: params.status ? 1 : 0,
        DropShip: store.dropShip,
        CompanyName: params.companyName,
        Phone: params.phone,
        Email: params.email,
        // Logo: params.Logo,
        WebSite: 'website',
        BillToType: store.billToType,
        // PackingSlipTemplateNum: params.packingSlipTemplateNum,
        // PackingSlipFooter: params.packingSlipFooter,
        PrintTemplateNum: 0,
        // IsPrintPackingSlip: params.isPrintPackingSlip ? 1 : 0,
        IsLabelSeparately: params.IsLabelSeparately ? 1 : 0,
        IsHandleLabelSeparately: params.IsHandleLabelSeparately ? 1 : 0,
        IsRequireInsertItem: params.IsRequireInsertItem ? 1 : 0,
        IsAllowOrderImport: params.IsAllowOrderImport ? 1 : 0,
        ImportFileFormat: params.ImportFileFormat,
        AllowSplitOrder: params.AllowSplitOrder ? 1 : 0,
        NotUseProductSignature: params.NotUseProductSignature ? 1 : 0,
        RetailerId: params.ImportFileFormat === 'Dsco' ? params.RetailerId : '',
        OnlyMappedServiceAndPackage: params.OnlyMappedServiceAndPackage ? 1 : 0,
        EnableSync: params.EnableSync ? 1 : 0,
        SendShipmentsOneBatch: params.SendShipmentsOneBatch ? 1 : 0,
        RequireSmartVerify: params.RequireSmartVerify ? 1 : 0,
        PreassignedShippingPackage: params.PreassignedShippingPackage ? 1 : 0,
        SwitchFedExHomeGround: params.SwitchFedExHomeGround ? 1 : 0,
        IsDisplayPrice: store.isAdmin
          ? params.IsDisplayPrice
            ? 1
            : 0
          : undefined,
        FollowProductSeparatelyRule: params.FollowProductSeparatelyRule ? 1 : 0,
        EnableCarrierDeclareValue: params.EnableCarrierDeclareValue ? 1 : 0,
        FedExInternationalSettings: {
          ETD: params.Etd,
          PrintDocument: params.PrintDocument,
        },
        AllowSkipPrintPackingSlip: params.AllowSkipPrintPackingSlip ? 1 : 0,
        HideFromSelection: params.status ? 0 : params.hideFromSelection ? 1 : 0,
      };

      const res = await updateStoreInfo(saveParams);
      setSaveLoading(false);
      if (res.isSuccess) {
        showMessage.success({ content: 'saved Successfully' });
        setStore(res.data);
        setEdited(false);
        onRefresh();
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const UseWarehouseSetting = async (id: number) => {
    try {
      setLoading4(true);
      const res = await exportShipAccountToChannelAccount(id);
      setLoading4(false);
      if (res.isSuccess) {
        setChannelShipList(res.data);
        showMessage.success({ content: 'use warehouse setting successfully' });
      }
    } catch (error) {
      setLoading4(false);
      console.log(error);
    }
  };

  /**
   * packing slip preview
   * @returns
   */
  const previewPackingSlipTp = async (params: {
    ChannelAccountPackingSlipNum: number;
    PackingSlipTemplateNum: number;
    Footer: string;
    Logo: string;
    Time: string;
  }) => {
    try {
      setLoading3(true);
      const res = await previewSlipTemplate(params);
      setLoading3(false);
      const w = window.open('about:blank');
      w?.document.write(res);
    } catch (error) {
      setLoading3(false);
    }
  };

  const previewGroupPackingSlipTp = async (params: {
    ChannelAccountPackingSlipNum: number;
    PackingSlipTemplateNum: number;
    Footer: string;
    Logo: string;
    Time: string;
  }) => {
    try {
      setLoading3(true);
      const res = await previewGroupSlipTemplate(params);
      setLoading3(false);
      const w = window.open('about:blank');
      w?.document.write(res);
    } catch (error) {
      setLoading3(false);
    }
  };

  /**
   *
   */
  const previewCarton = async (params: {
    ChannelAccountCartonLabelNum: number;
    CartonLabelTemplateNum: number;
    ChannelAccountNum: number;
    Footer: string;
    Logo: string;
    Time: string;
  }) => {
    try {
      setLoading3(true);
      const res = await previewCartonLabel(params);
      setLoading3(false);
      const w = window.open('about:blank');
      w?.document.write(res);
    } catch (error) {
      setLoading3(false);
    }
  };

  /**
   * handle cancel event
   */
  const closeProductDetailDialog = () => {
    if (!edited) {
      setTimeout(() => {
        document.body.style.overflowY = 'unset';
      }, 0);
      onHide();
      return;
    }
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      setTimeout(() => {
        document.body.style.overflowY = 'unset';
      }, 0);
      onHide();
    } else {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onCancel() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    // getCarriers();
    // fetchAddressBook();
    // fetchAddressList();
    // fetchBillThirdPartyList();
    getStore();
    // getChannelAccountShipAccountList();
    // fetchShipList();
    // getPackingSlipTemplates();
    // fetchChannelAccountInsertItem();
  }, []);
  /* eslint-enable */

  const getOtherColumns = () => {
    return [
      {
        name: 'warehouseCode',
        header: 'Warehouse',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'insertItemName',
        header: 'Insert Item Name',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'insertItemPictureLink',
        header: 'Picture',
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        // renderHeader: () => 'Picture',
        render: (value: any) => {
          const { data } = value;

          return (
            <Image
              width={32}
              height={32}
              preview={false}
              src={
                data.insertItemPictureLink
                  ? data.insertItemPictureLink
                  : 'error'
              }
              fallback={fallbackImage}
              onClick={() => {
                console.log('click picture', data);
              }}
              style={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        name: 'insertItemNote',
        header: 'Note',
        defaultFlex: 1,
      },
      {
        name: 'isRemindUser',
        header: 'Need Reminder User',
        defaultFlex: 1,
        userSelect: true,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        maxWidth: 200,
        minWidth: 200,
        render: (value: any) => {
          const { data } = value;
          return data.isRemindUser ? 'Yes' : 'No';
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 250,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  setCurrentInsertItem(data);
                  setInsertItemDialogVisible(true);
                }}
              >
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the item ?',
                    content: `Insert Item name: ${data.insertItemName}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      delelteInsertItemById(data.channelAccountInsertItemNum);
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getSlipColumns = () => {
    return [
      {
        name: 'salesDivision',
        header: 'Sales Division',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'packingSlipTemplateName',
        header: 'Packing Slip Template',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'logo',
        header: 'Logo',
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        render: (value: any) => {
          const { data } = value;
          return (
            <Image
              width={32}
              height={32}
              preview={false}
              src={data.logo ? data.logo : 'error'}
              fallback={fallbackImage}
              onClick={() => {
                console.log('click picture', data);
              }}
              style={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        name: 'isPrintPackingSlip',
        header: 'Print Packing Slip',
        userSelect: true,
        sortable: false,
        textAlign: 'center',
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.isPrintPackingSlip} />;
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 280,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
               {false &&<Button
                type="link"
                onClick={() =>
                  previewPackingSlipTp({
                    ChannelAccountPackingSlipNum:
                      data.channelAccountPackingSlipNum,
                    PackingSlipTemplateNum: data.packingSlipTemplateNum,
                    Logo: data.logo,
                    Footer: data.packingSlipFooter,
                    Time: nowTime(),
                  })
                }
              >
                <FileSearchOutlined />
                Preview
              </Button>}

              <Button
                type="link"
                onClick={() => {
                  setCurrentSlip(data);
                  setSlipDialogVisible(true);
                }}
              >
                <EditOutlined />
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the row?',
                    content: `Sales Division: ${data.salesDivision}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      deleteSlipsById(data.channelAccountPackingSlipNum);
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getGroupSlipColumns = () => {
    return [
      {
        name: 'salesDivision',
        header: 'Sales Division',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'packingSlipTemplateName',
        header: 'Packing Slip Template',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'logo',
        header: 'Logo',
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        render: (value: any) => {
          const { data } = value;
          return (
            <Image
              width={32}
              height={32}
              preview={false}
              src={data.logo ? data.logo : 'error'}
              fallback={fallbackImage}
              onClick={() => {
                console.log('click picture', data);
              }}
              style={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        name: 'isPrintPackingSlip',
        header: 'Print Packing Slip',
        userSelect: true,
        sortable: false,
        textAlign: 'center',
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.isPrintPackingSlip} />;
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 280,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
               {false &&<Button
                type="link"
                onClick={() =>
                  previewGroupPackingSlipTp({
                    ChannelAccountPackingSlipNum:
                      data.channelAccountPackingSlipNum,
                    PackingSlipTemplateNum: data.packingSlipTemplateNum,
                    Logo: data.logo,
                    Footer: data.packingSlipFooter,
                    Time: nowTime(),
                  })
                }
              >
                <FileSearchOutlined />
                Preview
              </Button>}

              <Button
                type="link"
                onClick={() => {
                  setCurrentGroupSlip(data);
                  setGroupSlipDialogVisible(true);
                }}
              >
                <EditOutlined />
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the row?',
                    content: `Sales Division: ${data.salesDivision}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      deleteGroupSlipsById(data.channelAccountPackingSlipNum);
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getCartonColumns = () => {
    return [
      {
        name: 'salesDivision',
        header: 'Sales Division',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'cartonLabelTemplateName',
        header: 'Carton Label Template',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'logo',
        header: 'Logo',
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        textAlign: 'center',
        textVerticalAlign: 'middle',
        render: (value: any) => {
          const { data } = value;
          return (
            <Image
              width={32}
              height={32}
              preview={false}
              src={data.logo ? data.logo : 'error'}
              fallback={fallbackImage}
              onClick={() => {
                console.log('click picture', data);
              }}
              style={{ cursor: 'pointer' }}
            />
          );
        },
      },
      {
        name: 'isPrintCartonLabel',
        header: 'Print Carton Label',
        sortable: false,
        userSelect: true,
        textAlign: 'center',
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.isPrintCartonLabel} />;
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 280,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
               {false &&<Button
                type="link"
                onClick={() =>
                  previewCarton({
                    ChannelAccountCartonLabelNum:
                      data.channelAccountCartonLabelNum,
                    CartonLabelTemplateNum: data.cartonLabelTemplateNum,
                    ChannelAccountNum: storeInfo?.channelAccountNum || 0,
                    Logo: data.logo,
                    Footer: data.packingSlipFooter,
                    Time: nowTime(),
                  })
                }
              >
                <FileSearchOutlined />
                Preview
              </Button>}
              <Button
                type="link"
                onClick={() => {
                  setCurrentCartLabel(data);
                  setCartonLabelDialogVisible(true);
                }}
              >
                <EditOutlined />
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the row?',
                    content: `Sales Division: ${data.salesDivision}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      deleteCartonLabelById(data.channelAccountCartonLabelNum);
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getColumns = () => {
    return [
      {
        name: 'warehouseCode',
        header: 'Warehouse',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        needToolTip: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return (
        //     <Popover placement="topLeft" content={data.warehouseCode}>
        //       {data.warehouseCode}
        //     </Popover>
        //   );
        // },
      },
      {
        name: 'customer',
        header: 'Customer',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        needToolTip: true,
        render: (value: any) => {
          const { data } = value;
          return data.customer ? data.customer : 'All Customer'
        }
      },
      {
        name: 'name',
        header: 'Name',
        defaultFlex: 1,
        sortable: true,
        userSelect: true,
        needToolTip: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return (
        //     <Popover placement="topLeft" content={data.name}>
        //       {data.name}
        //     </Popover>
        //   );
        // },
      },
      {
        name: 'shipAccountName',
        header: 'ShipAccount',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        needToolTip: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   if (data.shipAccountNum === 0) {
        //     return 'Shopping Around';
        //   }
        //   const a = shipList.filter(
        //     (item) => item.shipAccountNum === data.shipAccountNum
        //   );
        //   return a.length > 0 ? (
        //     <Popover placement="topLeft" content={a[0].name}>
        //       {a[0].name}
        //     </Popover>
        //   ) : (
        //     ''
        //   );
        // },
      },
      {
        name: 'service',
        header: 'Service',
        defaultFlex: 1,
        sortable: false,
        userSelect: true,
        needToolTip: true,
        //   render: (value: any) => {
        //     const { data } = value;
        //     return (
        //       <Popover placement="topLeft" content={data.service}>
        //         {data.service}
        //       </Popover>
        //     );
        //   },
      },
      {
        name: 'package',
        header: 'Package',
        sortable: false,
        userSelect: true,
        defaultFlex: 1,
        needToolTip: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return (
        //     <Popover placement="topLeft" content={data.package}>
        //       {data.package}
        //     </Popover>
        //   );
        // },
      },
      // {
      //   name: 'signatureRequired',
      //   header: 'Signature Required',
      //   sortable: false,
      //   defaultFlex: 1,
      //   render: (value: any) => {
      //     const { data } = value;
      //     const content = {
      //       '0': 'Adult signature required',
      //       '1': 'Direct signature required',
      //       '2': 'Indirect signature required',
      //       '3': 'No signature required',
      //       '4': 'Default',
      //     }[`${data.signatureRequired as string}`];
      //     return (
      //       <Popover placement="topLeft" content={content}>
      //         {content}
      //       </Popover>
      //     );
      //   },
      // },
      {
        name: 'saturdayDelivery',
        header: 'Saturday Delivery',
        sortable: false,
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.saturdayDelivery} />;
        },
      },
      {
        name: 'billType',
        header: 'Bill Type',
        sortable: false,
        defaultFlex: 1,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return { '0': 'Shipper', '2': 'ThirdParty' }[
            `${data.billType as string}`
          ];
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 250,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  setCurrentService(data);
                  setDialogVisible(true);
                }}
              >
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the Service ?',
                    content: `service: ${data.name}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onOk() {
                      delServiceById(data.channelAccountServiceNum);
                    },
                    onCancel() { },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getChannenlShipColums = () => {
    return [
      {
        name: 'warehouseCode',
        header: 'Warehouse',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return data.warehouseCode;
        // },
      },
      {
        name: 'shipAccountName',
        header: 'Ship Account',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return data.shipAccountName;
        // },
      },
      {
        name: 'isDefault',
        header: 'Is Default',
        maxWidth: 150,
        sortable: false,
        render: (value: any) => {
          const { data } = value;
          return <BoolCell value={data.isDefault} />;
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 250,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  setCurrentChannelShip(data);
                  setChannelShipDialogVisible(true);
                }}
              >
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the row ?',
                    content: `Warehouse: ${data.warehouseCode} |  Ship Account: ${data.shipAccountName} `,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      delChannelAccountShipAccountById(
                        data.channelAccountShipAccountNum
                      );
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const getAddressColumns = () => {
    return [
      {
        name: 'warehouseCode',
        header: 'Warehouse',
        userSelect: true,
        defaultFlex: 1,
        sortable: true,
        // render: (value: any) => {
        //   const { data } = value;
        //   return data.warehouseCode;
        // },
      },
      {
        name: 'returnAddress',
        header: 'Return Address',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
        render: (value: any) => {
          const { data } = value;
          // return data.returnAddressName;
          const addressInfo = addressBook.filter(
            (item) => item.addressNum === data.returnAddressNum
          );
          return (
            <Popover
              placement="topLeft"
              content={
                addressInfo.length > 0
                  ? `${addressInfo[0].contactName}, ${addressInfo[0].street1} ${addressInfo[0].street2}, ${addressInfo[0].city}, ${addressInfo[0].state} ${addressInfo[0].postalCode}, ${addressInfo[0].country}`
                  : ''
              }
            >
              {addressInfo.length > 0
                ? addressInfo[0].addressAlias
                : data.returnAddressName}
            </Popover>
          );
        },
      },
      {
        name: 'fromAddressNum',
        header: 'Ship From',
        defaultFlex: 1,
        userSelect: true,
        sortable: true,
        render: (value: any) => {
          const { data } = value;
          // return data.fromAddressName;
          const addressInfo = addressBook.filter(
            (item) => item.addressNum === data.fromAddressNum
          );
          return (
            <Popover
              placement="topLeft"
              content={
                addressInfo.length > 0
                  ? `${addressInfo[0].contactName}, ${addressInfo[0].street1} ${addressInfo[0].street2} ${addressInfo[0].street2}, ${addressInfo[0].city}, ${addressInfo[0].state} ${addressInfo[0].postalCode}, ${addressInfo[0].country}`
                  : ''
              }
            >
              {addressInfo.length > 0
                ? addressInfo[0].addressAlias
                : data.fromAddressName}
            </Popover>
          );
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        minWidth: 250,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
              <Button
                type="link"
                onClick={() => {
                  setCurrentAddress(data);
                  setAddressDialogVisible(true);
                }}
              >
                Edit
              </Button>

              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the address ?',
                    content: `warehouse: ${data.warehouseName}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: 'hover-danger-button' },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onCancel() { },
                    onOk() {
                      delChanelAccountAddressById(
                        data.channelAccountAddressNum
                      );
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  };

  const basicInfo = (store: any) => {
    return (
      <Card
        bodyStyle={{
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <Space size="large">
          {store.channelAccountType !== 1 &&<span>
            <Text strong>Channel:</Text>
            <Text style={{ marginLeft: 6 }}>{store?.channelName}</Text>
          </span>}
          {store.channelAccountType !== 1 &&<span>
            <Text strong>CCA#:</Text>
            <Text style={{ marginLeft: 6 }}>
              {store?.centralChannelAccountNum}
            </Text>
          </span>}
          {store.channelAccountType === 1 &&<span>
            <Text strong>Customer Code:</Text>
            <Text style={{ marginLeft: 6 }}>{store?.customerCode}</Text>
          </span>}
          {store.channelAccountType === 1 &&<span>
            <Text strong>Customer Name:</Text>
            <Text style={{ marginLeft: 6 }}>
              {store?.customerName}
            </Text>
          </span>}
          <span>
            <Text strong>Store #:</Text>
            <Text style={{ marginLeft: 6 }}>{store?.channelAccountNum}</Text>
          </span>
          <span>
            <Text strong>Store Name:</Text>
            <Text style={{ marginLeft: 6 }}>{store.channelAccountName}</Text>
          </span>
        </Space>
      </Card>
    );
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  return (
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton={true}
      fullscreen={true}
      maskClosable={false}
      onClose={closeProductDetailDialog}
      onFullscreen={onFullscreen}
      title={`Channel Account Detail - ${storeInfo?.channelAccountName}`}
      visible={props.visible}
      width={detailDialogWidth()}
      footer={
        <Space>
        <FormSubmitButton
          loading={saveLoading}
          changed={true}
          edited={edited}
          onSubmit={handleSave}
          disabled={managePermission < PERMISSION_LEVEL['EDIT']}
          onEdit={() => {
            setEdited(true);
          }}
        />
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="5">
                <Button
                  danger
                  type="text"
                  style={{ width: '100%', textAlign: 'left' }}
                  icon={<DeleteOutlined />}
                  onClick={() => onDelete()}
                  disabled={managePermission < PERMISSION_LEVEL['EDIT']}
                >
                  Delete
                </Button>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <Button type='default' id={`po_detail_more_button`}>
            ...
          </Button>
        </Dropdown>
      </Space>
      }
      // titleRightCrumb={
      //   <Space>
      //     <FormSubmitButton
      //       loading={saveLoading}
      //       changed={true}
      //       edited={edited}
      //       onSubmit={handleSave}
      //       disabled={managePermission < PERMISSION_LEVEL['EDIT']}
      //       onEdit={() => {
      //         setEdited(true);
      //       }}
      //     />
      //     <Button
      //       danger
      //       type="primary"
      //       icon={<DeleteOutlined />}
      //       onClick={() => onDelete()}
      //       disabled={managePermission < PERMISSION_LEVEL['EDIT']}
      //     >
      //       Delete
      //     </Button>
      //   </Space>
      // }
    >
      <Card
        loading={loading}
        bodyStyle={{ height: '100%', padding: COMMON_PADDING_SPACE }}
        style={{ height: '100%' }}
      >
        {store ? (
          <Form {...layout} form={form} style={{ height: '100%' }}>
            <Tabs
              defaultActiveKey="1"
              type="card"
              style={{ height: '100%' }}
              onChange={(v) => {
                switch (v) {
                  case '1':
                    // setShowEditButton(true);
                    break;
                  case '2':
                    // setShowImg(true);
                    // setShowEditButton(false);
                    if (templates.length === 0) {
                      getPackingSlipTemplates();
                      getSlips();
                    }
                    break;
                  case '12':
                    if (groupTemplates.length === 0) {
                      getGroupPackingSlipTemplates();
                      getGroupSlips();
                    }
                    break;
                  case '8':
                    // setShowImg(true);
                    // setShowEditButton(false);
                    if (cartonLabelTemplates.length === 0) {
                      getCartonLabelTemplates();
                      getCartonlabel();
                    }
                    break;
                  case '4':
                    // setShowEditButton(false);
                    if (addressBook.length === 0) {
                      fetchAddressBook();
                    }
                    if (addressList.length === 0) {
                      fetchAddressList();
                    }
                    break;
                  case '6':
                    // setShowEditButton(false);
                    if (carriers.length === 0) {
                      getCarriers();
                    }
                    if (shipList.length === 0) {
                      fetchShipList();
                    }
                    if (channelShipList.length === 0) {
                      getChannelAccountShipAccountList();
                    }
                    break;
                  case '3':
                    // setShowEditButton(false);
                    if (carriers.length === 0) {
                      getCarriers();
                    }
                    if (shipList.length === 0) {
                      fetchShipList();
                    }
                    if (serviceList.length === 0) {
                      fetchServices();
                    }
                    break;
                  case '7':
                    // setShowEditButton(true);
                    if (insetItemList.length === 0) {
                      fetchChannelAccountInsertItem();
                    }
                    break;
                  case '10':
                    // setShowEditButton(true);
                    if (insetItemList.length === 0) {
                      fetchChannelAccountInsertItem();
                    }
                    break;
                  case '11':
                    // setShowEditButton(false);
                    break;
                }
              }}
            >
              <TabPane tab="Store Settings" key="1" forceRender>
                {store && (
                  <>
                    {store?.channelAccountType !==1 &&<Form.Item label={<FormLabel>Channel</FormLabel>}>
                      <span>{store?.channelName}</span>
                    </Form.Item>}

                    {store?.channelAccountType !==1 &&<Form.Item label={<FormLabel>CCA#</FormLabel>}>
                      <span>{store?.centralChannelAccountNum}</span>
                    </Form.Item>}

                    {store?.channelAccountType ===1 &&<Form.Item label={<FormLabel>Customer Code</FormLabel>}>
                      <span>{store?.customerCode}</span>
                    </Form.Item>}

                    {store?.channelAccountType ===1 &&<Form.Item label={<FormLabel>Customer Name</FormLabel>}>
                      <span>{store?.customerName}</span>
                    </Form.Item>}

                    <Form.Item label={<FormLabel>Store #</FormLabel>}>
                      <span>{store?.channelAccountNum}</span>
                    </Form.Item>

                    <Form.Item
                      label={<FormLabel>Store Name</FormLabel>}
                      initialValue={store.channelAccountName}
                      name="ChannelAccountName"
                    >
                      <Input readOnly={!edited} />
                    </Form.Item>

                    <Form.Item
                      label={<FormLabel>Status</FormLabel>}
                      initialValue={store?.status}
                      name="status"
                    >
                      <Select disabled={!edited} onChange={(value) => {
                        setCurrentStatus(value);
                        if (value === 0) {
                          form.setFieldsValue({ hideFromSelection: 1 })
                        }
                      }}>
                        <Select.Option value={0}>Inactive</Select.Option>
                        <Select.Option value={1}>Active</Select.Option>
                      </Select>
                    </Form.Item>
                    {currentStatus === 0 &&
                      <Form.Item
                        label={<FormLabel>Hide from Selection</FormLabel>}
                        initialValue={store?.hideFromSelection}
                        name="hideFromSelection"
                      >
                        <Select disabled={!edited}>
                          <Select.Option value={1}>Yes</Select.Option>
                          <Select.Option value={0}>No</Select.Option>
                        </Select>
                      </Form.Item>
                    }
                    <Form.Item
                      name="companyName"
                      initialValue={store?.companyName}
                      label={<FormLabel>Company Name</FormLabel>}
                    >
                      <Input readOnly={!edited} />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      initialValue={store?.phone}
                      label={<FormLabel>Phone</FormLabel>}
                    >
                      <Input readOnly={!edited} />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      initialValue={store?.email}
                      label={<FormLabel>Email</FormLabel>}
                    >
                      <Input readOnly={!edited} />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      name="IsLabelSeparately"
                      initialValue={store.isLabelSeparately}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Shipping label is handled separately
                      </Checkbox>
                    </Form.Item>
                  </>
                )}
              </TabPane>
              <TabPane tab="Packing Slip" key="2" forceRender>
                {basicInfo(store)}
                <Row justify="space-between" style={{ marginTop: 8 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        setCurrentSlip(undefined);
                        setSlipDialogVisible(true);
                      }}
                    >
                      <PlusOutlined />
                      Add Packing Slip Template
                    </Button>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="AllowSkipPrintPackingSlip"
                      initialValue={store ? store.allowSkipPrintPackingSlip : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited} style={{ marginTop: 4 }}>
                        Allow user skip printing packing slip
                      </Checkbox>
                    </Form.Item>
                  </Space>

                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to copy slip templates?',
                        okText: 'Yes',
                        cancelText: 'No',
                        content: `This operation is irreversible.`,
                        onOk: () => {
                          copySlips();
                        },
                      });
                    }}
                  >
                    Copy Packing Slip templates from Channel
                  </Button>
                </Row>

                <DataGridPlus
                  style={{ minHeight: 300 }}
                  idProperty="packingslipNum"
                  columns={getSlipColumns()}
                  dataSource={slipList}
                  loading={loading3}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>
              <TabPane tab="Address" key="4" forceRender>
                {basicInfo(store)}
                <Button
                  type="primary"
                  style={{ marginTop: 8 }}
                  onClick={() => {
                    setCurrentAddress(undefined);
                    setAddressDialogVisible(true);
                  }}
                >
                  <PlusOutlined />
                  Add Channel Account Address
                </Button>
                {addressBook.length > 0 && (
                  <DataGridPlus
                    style={{ minHeight: 300 }}
                    idProperty="channelAccountAddressNum"
                    columns={getAddressColumns()}
                    dataSource={addressList}
                    loading={loading2}
                    pagination="local"
                    {...({} as any)}
                  />
                )}
              </TabPane>
              <TabPane tab="Shipping Account" key="6" forceRender>
                {basicInfo(store)}
                <Row justify="space-between" style={{ marginTop: 8 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentChannelShip(undefined);
                      setChannelShipDialogVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    Add Shipping Account
                  </Button>
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title:
                          'Are you sure you want to use warehouse setting?',
                        okText: 'Yes',
                        cancelText: 'No',
                        content: `This operation is irreversible.`,
                        onOk: () => {
                          UseWarehouseSetting(
                            store ? store.channelAccountNum : 0
                          );
                        },
                      });
                    }}
                  >
                    Use Warehouse Setting
                  </Button>
                </Row>

                <DataGridPlus
                  style={{ minHeight: 300 }}
                  idProperty="ChannelAccountShipAccountNum"
                  columns={getChannenlShipColums()}
                  dataSource={channelShipList}
                  loading={loading4}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>

              <TabPane tab="Shipping Service Mapping" key="3" forceRender>
                {basicInfo(store)}
                <p>
                  Setup how you'd like to handle shipping service classes which
                  will apply for you.
                </p>
                <Row justify="space-between">
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentService(undefined);
                      setDialogVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    Add Service Mapping
                  </Button>
                  <Button
                    onClick={() => {
                      setCopyServiceDialogVisible(true);
                    }}
                  >
                    Copy Channel Service
                  </Button>
                </Row>

                <DataGridPlus
                  style={{ minHeight: 300 }}
                  idProperty="channelAccountServiceNum"
                  columns={getColumns()}
                  dataSource={serviceList}
                  loading={loading3}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>
              <TabPane tab="Other" key="7" forceRender>
                {basicInfo(store)}
                <Row justify="space-between" style={{ marginTop: 8 }}>
                  <Col span={8}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="IsAllowOrderImport"
                      initialValue={store ? store.isAllowOrderImport : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited} style={{ marginTop: 4 }}>
                        Allow Manual Order Import
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="ImportFileFormat"
                      initialValue={store.importFileFormat}
                      label={<FormLabel>Order import format</FormLabel>}
                      labelCol={{ span: 12 }}
                    >
                      <Select
                        disabled={!edited}
                        allowClear
                        size="small"
                        onChange={(value: string) => setFileFormat(value)}
                      >
                        <Select.Option value="Shopify">Shopify</Select.Option>
                        <Select.Option value="DigitBridge">
                          DigitBridge
                        </Select.Option>
                        <Select.Option value="Dsco">Dsco</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {fileFormat === 'Dsco' ? (
                      <Form.Item
                        name="RetailerId"
                        initialValue={store.retailerId}
                        label={<FormLabel>RetailerID</FormLabel>}
                      >
                        <Input disabled={!edited} />
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Row>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="IsHandleLabelSeparately"
                      initialValue={
                        store ? store.isHandleLabelSeparately : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Allow user handle shipping label separately
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="OnlyMappedServiceAndPackage"
                      initialValue={
                        store ? store.onlyMappedServiceAndPackage : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Only allow mapped shipping service and package type
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Row>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="NotUseProductSignature"
                      initialValue={store ? store.notUseProductSignature : true}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Don't use Product Signature Option
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="AllowSplitOrder"
                      initialValue={store ? store.allowSplitOrder : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>Allow Split Order</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>

                <Divider style={{ marginTop: 6, marginBottom: 6 }} />

                <Row>
                  <Col span={6}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="EnableSync"
                      initialValue={store ? store.enableSync : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>Enable Sync</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="SendShipmentsOneBatch"
                      initialValue={store ? store.sendShipmentsOneBatch : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Send all shipments as one batch
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="RequireSmartVerify"
                      initialValue={store ? store.requireSmartVerify : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Mark Shipped After “Smart Verify” (for preassign and
                        batch print)
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Row>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="SwitchFedExHomeGround"
                      initialValue={store ? store.switchFedExHomeGround : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Auto switch FedEx Home & Ground
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="PreassignedShippingPackage"
                      initialValue={
                        store ? store.preassignedShippingPackage : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Preassigned Shipping and Package
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Row>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="FollowProductSeparatelyRule"
                      initialValue={
                        store ? store.followProductSeparatelyRule : true
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Apply Product Ship Separately Rule
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="EnableCarrierDeclareValue"
                      initialValue={
                        store ? store.enableCarrierDeclareValue : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Enable Carrier Declared Value
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 6, marginBottom: 6 }} />
                <Row>
                  <h3
                    style={{
                      color: edited ? 'black' : 'rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    FedEx Internation
                  </h3>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="Etd"
                      initialValue={
                        store && store.fedExInternationalSettings
                          ? store.fedExInternationalSettings.etd
                          : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>ETD</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="PrintDocument"
                      initialValue={
                        store && store.fedExInternationalSettings
                          ? store.fedExInternationalSettings.printDocument
                          : false
                      }
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Print Document (if ETD is Checked,you needn't check
                        Print Document)
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Other 2" key="11" forceRender>
                {storeInfo && (
                  <ChannelAccountSettingPage
                    channelAccountNum={storeInfo.channelAccountNum}
                  />
                )}
              </TabPane>
              <TabPane tab="Insert item(s)" key="10" forceRender>
                <Row>
                  <Col span={6}>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="IsRequireInsertItem"
                      initialValue={store ? store.isRequireInsertItem : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited}>
                        Require Insert Item(s)
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col
                    span={6}
                    style={{ display: 'flex', justifyContent: 'end' }}
                  >
                    <Button
                      disabled={!edited}
                      onClick={() => {
                        setCurrentInsertItem(undefined);
                        setInsertItemDialogVisible(true);
                      }}
                    >
                      <PlusOutlined />
                      Add Insert Item
                    </Button>
                  </Col>
                </Row>
                <DataGridPlus
                  style={{ minHeight: 200, maxHeight: 200 }}
                  idProperty="channelAccountNum"
                  columns={getOtherColumns()}
                  dataSource={insetItemList}
                  loading={loading7}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>
              {store.isAdmin && (
                <TabPane tab="Advanced Setting" key="9" forceRender>
                  {basicInfo(store)}
                  <Row style={{ minHeight: 100, marginTop: 8 }}>
                    <Col span={12}>
                      <Form.Item
                        noStyle
                        valuePropName="checked"
                        name="IsDisplayPrice"
                        initialValue={store ? store.isDisplayPrice : false}
                        label={<FormLabel noColon>{''}</FormLabel>}
                      >
                        <Checkbox disabled={!edited}>Is Display Price</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              )}
                            <TabPane tab="Group Packing Slip" key="12" forceRender>
                {basicInfo(store)}
                <Row justify="space-between" style={{ marginTop: 8 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={() => {
                        setCurrentGroupSlip(undefined);
                        setGroupSlipDialogVisible(true);
                      }}
                    >
                      <PlusOutlined />
                      Add Group Packing Slip Template
                    </Button>
                    <Form.Item
                      noStyle
                      valuePropName="checked"
                      name="AllowSkipPrintPackingSlip"
                      initialValue={store ? store.allowSkipPrintPackingSlip : false}
                      label={<FormLabel noColon>{''}</FormLabel>}
                    >
                      <Checkbox disabled={!edited} style={{ marginTop: 4 }}>
                        Allow user skip printing packing slip
                      </Checkbox>
                    </Form.Item>
                  </Space>

                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to copy  group slip templates?',
                        okText: 'Yes',
                        cancelText: 'No',
                        content: `This operation is irreversible.`,
                        onOk: () => {
                          copyGroupSlips();
                        },
                      });
                    }}
                  >
                    Copy Group Packing Slip templates from Channel
                  </Button>
                </Row>

                <DataGridPlus
                  style={{ minHeight: 300 }}
                  idProperty="packingslipNum"
                  columns={getGroupSlipColumns()}
                  dataSource={groupSlipList}
                  loading={loading3}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>

              <TabPane tab="Carton Label" key="8" forceRender>
                {basicInfo(store)}
                <Row justify="start" style={{ marginTop: 8 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentCartLabel(undefined);
                      setCartonLabelDialogVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    Add Carton Label Template
                  </Button>
                </Row>

                <DataGridPlus
                  style={{ minHeight: 300 }}
                  idProperty="channelAccountCartonLabelNum"
                  columns={getCartonColumns()}
                  dataSource={cartonLabelList}
                  loading={loading8}
                  pagination="local"
                  {...({} as any)}
                />
              </TabPane>
            </Tabs>
          </Form>
        ) : (
          <Row
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}
      </Card>
      {dialogVisible && (
        <AddServiceDialog
          visible
          onHide={() => setDialogVisible(false)}
          onRefresh={() => {
            setCurrentService(undefined);
            fetchServices();
          }}
          service={currentService}
          store={store}
          shipList={shipList}
          carriers={carriers}
        // thirdPartyList={thirdPartyList}
        />
      )}
      {addressDialogVisible && (
        <AddressDialog
          visible
          onHide={() => setAddressDialogVisible(false)}
          onRefresh={() => {
            setCurrentService(undefined);
            fetchAddressList();
          }}
          store={store}
          // addressBook={addressBook}
          address={currentAddress}
        />
      )}
      {channelShipDialogVisible && (
        <AddChannelShipDialog
          visible
          onHide={() => setChannelShipDialogVisible(false)}
          onRefresh={() => {
            setCurrentChannelShip(undefined);
            getChannelAccountShipAccountList();
          }}
          carriers={carriers}
          channelShip={currentChannelShip}
          store={store}
        // shipList={shipList}
        // addressBook={addressBook}
        />
      )}
      {copyServiceDialogVisible && (
        <CopyServiceDialog
          visible
          onHide={() => setCopyServiceDialogVisible(false)}
          onRefresh={() => {
            // setCurrentService(undefined);
            fetchServices();
          }}
          store={store}
          shipList={shipList}
          carriers={carriers}
        />
      )}
      {insertItemDialogVisible && (
        <InsertItemDialog
          visible
          onHide={() => setInsertItemDialogVisible(false)}
          onRefresh={() => {
            // setCurrentService(undefined);
            fetchChannelAccountInsertItem();
          }}
          store={store}
          insertItem={currentInsertItem}
        />
      )}
      {slipDialogVisible && (
        <SlipTemplateDialog
          visible
          onHide={() => setSlipDialogVisible(false)}
          onRefresh={() => {
            // setCurrentService(undefined);
            getSlips();
          }}
          store={storeInfo}
          slip={currentSlip}
          templates={templates}
        />
      )}
      {groupSlipDialogVisible && (
        <GroupSlipTemplateDialog
          visible
          onHide={() => setGroupSlipDialogVisible(false)}
          onRefresh={() => {
            // setCurrentService(undefined);
            getGroupSlips();
          }}
          store={storeInfo}
          slip={currentGroupSlip}
          templates={groupTemplates}
        />
      )}
      {cartonLabelDialogVisible && (
        <CartonLabelTemplateDialog
          visible
          onHide={() => setCartonLabelDialogVisible(false)}
          onRefresh={() => {
            // setCurrentService(undefined);
            getCartonlabel();
          }}
          store={storeInfo}
          carton={currentCartLabel}
          templates={cartonLabelTemplates}
        />
      )}
    </ModalDialog>
  );
};
