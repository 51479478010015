import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Space, Tabs, Empty, Modal, Row } from 'antd';
import { fetchWarehouseList } from 'services/warehouse';
import { PlusOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import {
  GreyCardWrapper,
  HeaderTitle,
  DataGridPlus,
  DeleteButton,
  message,
  TablesFilter,
} from 'components/common';
import {
  getAllShoppingAround,
  copyShoppingAround,
  delWarehouseShoppingAround,
} from 'services/shoppingAround';
import {
  getCarrierServiceRestrictionList,
  delCarrierServiceRestrictionById,
  copyCarrierServiceRestrictionList,
} from 'services/carrierServiceRestrictions';
import { fetchCarriers } from 'services/shipment';
import EditDialog from './EditDialog';
import EditRestriction from './EditRestrictionDialog';
import {
  LS_DEFAULT_WAREHOUSE_KEY,
  DIMENSION_UNITS,
  WEIGHT_UNITS,
} from 'constants/config';
import { sortByString } from 'utils';
import { ClassicCard } from 'components/common/styles';

const { TabPane } = Tabs;

export default function ShoppingAround(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [allShoppingArounds, setAllShoppingArounds] = useState<any[]>([]);
  const [shoppingArounds, setShoppingArounds] = useState<any[]>([]);
  const [current, setCurrent] = React.useState<any>();
  const [editVisible, setEditVisible] = React.useState(false);
  const [editRestrictionVisible, setEditRestrictionVisible] =
    React.useState(false);
  const [carriers, setCarriers] = useState<any[]>([]);
  const [inited, setInited] = useState(false);
  const [warehouses, setWarehouse] = useState<SimpleWarehouse[]>([]);
  // const [warehouseKeyValue, setWarehouseKeyValue] = useState<{
  //   [key: string]: string;
  // }>({});
  const [activeTab, setActiveTab] = useState<string>();
  const [activeTab1, setActiveTab1] = useState<string>('serviceMappingSetting');
  const [allRestrictions, setAllRestrictions] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [allTotal, setAllTotal] = useState<number>(0);
  const [filteredMappingData, setFilteredMappingData] = useState<any[]>([]);
  const [filteredRestrictionData, setFilteredRestrictionData] = useState<any[]>([]);
  const limitRef = useRef(10);
  const skipRef = useRef(0);

  const getWarehouses = async () => {
    try {
      setLoading(true);
      const res = await fetchWarehouseList();
      const temp = res.filter((i: any) => i.warehouseType === 1 || i.warehouseType === 5)
      setLoading(false);
      if (temp.length > 0) {
        setActiveTab(
          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
          `${temp[0].warehouseNum}`
        );
      } else {
        setActiveTab('-1');
      }
      // const temp: { [key: string]: string } = {};
      // res.map((i: any) => {
      //   temp[i.warehouseNum] = i.warehouseCode;
      //   return true;
      // });
      // setWarehouseKeyValue(temp);
      setWarehouse(temp);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const columns = [
    {
      header: 'Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`mapping_grid_warehouse_row_${rowIndex}`}>
      //       {data.warehouseCode}
      //     </span>
      //   );
      // },
    },
    {
      header: 'Service Name',
      name: 'shoppingAroundCarrierServiceName',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`mapping_grid_service_name_row_${rowIndex}`}>
      //       {data.shoppingAroundCarrierServiceName}
      //     </span>
      //   );
      // },
      // sort: true,
    },
    {
      header: 'Carrier',
      name: 'carrierName',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`mapping_grid_carrier_name_row_${rowIndex}`}>
      //       {data.carrierName}
      //     </span>
      //   );
      // },
    },
    {
      header: 'Service',
      name: 'carrierServiceName',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`mapping_grid_carrier_service_name_row_${rowIndex}`}>
      //       {data.carrierServiceName}
      //     </span>
      //   );
      // },
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      maxWidth: 250,
      minWidth: 250,
      textAlign: 'center',
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Space>
            <Button
              id={`mapping_grid_action_row_${rowIndex}_edit_button`}
              onClick={() => {
                setCurrent(data);
                setEditVisible(true);
              }}
            >
              <EditOutlined />
              Edit
            </Button>
            {!data.isSysDefined && (
              <DeleteButton
                id={`mapping_grid_action_row_${rowIndex}_delete_button`}
                confirmContent={`warehouse: ${data.warehouseCode} | Carrier: ${data.carrierName} | Service: ${data.carrierServiceName}`}
                confirmTitle="Are you sure you want to delete the row ?"
                onClick={() => delShoppingAround(data)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const restrictionColumns = [
    {
      header: 'Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`restriction_grid_warehouse_row_${rowIndex}`}>
      //       {data.warehouseCode}
      //     </span>
      //   );
      // },
    },
    {
      header: 'State Restriction',
      name: 'stateRestrictionType',
      userSelect: true,
      // sortable: false,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`restriction_grid_state_restriction_row_${rowIndex}`}>
      //       {data.stateRestrictionType}
      //     </span>
      //   );
      // },
    },
    {
      header: 'Carrier',
      name: 'carrierName',
      userSelect: true,
      // sortable: false,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`restriction_grid_carrier_row_${rowIndex}`}>
      //       {data.carrierName}
      //     </span>
      //   );
      // },
    },
    {
      header: 'Service Name',
      name: 'name',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`restriction_grid_service_name_row_${rowIndex}`}>
      //       {data.name}
      //     </span>
      //   );
      // },
      // sort: true,
    },
    {
      header: 'Weight Min',
      name: 'weightRangeMin',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: WEIGHT_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_weight_min_row_${rowIndex}`}>{`${data.weightRangeMin
            } ${WEIGHT_UNITS[data.weightUnit || 0]}`}</span>
        );
      },
    },
    {
      header: 'Weight Max',
      name: 'weightRangeMax',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: WEIGHT_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_weight_max_row_${rowIndex}`}>{`${data.weightRangeMax
            } ${WEIGHT_UNITS[data.weightUnit || 0]}`}</span>
        );
      },
    },
    {
      header: 'Length Min',
      name: 'lengthRangeMin',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: DIMENSION_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_length_min_row_${rowIndex}`}>{`${data.lengthRangeMin
            } ${DIMENSION_UNITS[data.dimensionUnit || 0]}`}</span>
        );
      },
    },
    {
      header: 'Length Max',
      name: 'lengthRangeMax',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: DIMENSION_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_length_max_row_${rowIndex}`}>{`${data.lengthRangeMax
            } ${DIMENSION_UNITS[data.dimensionUnit || 0]}`}</span>
        );
      },
    },
    {
      header: 'Length + Girth Min',
      name: 'lengthGirthRangeMin',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: DIMENSION_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_length_girth_min_row_${rowIndex}`}>{`${data.lengthGirthRangeMin
            } ${DIMENSION_UNITS[data.dimensionUnit || 0]}`}</span>
        );
      },
    },
    {
      header: 'Length + Girth Max',
      name: 'lengthGirthRangeMax',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      renderEnum: DIMENSION_UNITS,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`restriction_grid_length_girth_max_row_${rowIndex}`}>{`${data.lengthGirthRangeMax
            } ${DIMENSION_UNITS[data.dimensionUnit || 0]}`}</span>
        );
      },
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      maxWidth: 250,
      minWidth: 250,
      textAlign: 'center',
      defaultFlex: 1,
      defaultLocked: 'end',
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Space>
            <Button
              id={`restriction_grid_action_row_${rowIndex}_edit`}
              onClick={() => {
                setCurrent(data);
                setEditRestrictionVisible(true);
              }}
            >
              <EditOutlined />
              Edit
            </Button>
            {!data.isSysDefined && (
              <DeleteButton
                id={`restriction_grid_action_row_${rowIndex}_delete`}
                confirmContent={`warehouse: ${data.warehouseCode} | Service: ${data.name}`}
                confirmTitle="Are you sure you want to delete the row ?"
                onClick={() => delRestriction(data)}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const getCarriers = async () => {
    try {
      setLoading(true);
      const res = await fetchCarriers();
      setLoading(false);
      setCarriers(res);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getRestrictions = useCallback(async () => {
    try {
      setLoading2(true);
      const res = await getCarrierServiceRestrictionList();
      setLoading2(false);
      if (res.isSuccess) {
        // const temp = res.data.map((i: any) => {
        //   return {
        //     ...i,
        //     warehouseCode: warehouseKeyValue[i.warehouseNum],
        //     carrierName: '',
        //   };
        // });
        const data = res.data.sort((a: any, b: any) =>
          sortByString(a, b, 'stateRestrictionType')
        );
        setAllRestrictions(data);
        if (activeTab === '-1') {
          setRestrictions(data);
        } else {
          setRestrictions(
            data.filter((i: any) => i.warehouseNum === Number(activeTab))
          );
        }
      }
    } catch (error) {
      setLoading2(false);
    }
  }, [activeTab]);

  const delRestriction = async (data: any) => {
    try {
      const res = await delCarrierServiceRestrictionById(
        data.carrierServiceRestrictionNum
      );
      if (res.isSuccess) {
        message.success({ content: 'Deleted Row Successfully' });
        getRestrictions();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const delShoppingAround = async (data: any) => {
    try {
      const res = await delWarehouseShoppingAround(
        data.warehouseShoppingAroundNum
      );
      if (res.isSuccess) {
        message.success({ content: 'Deleted Row Successfully' });
        if (activeTab === '-1') {
          getAllShopAroundList(limitRef.current, 0);
        } else {
          getShopAroundList(limitRef.current, 0);
        }
      }
    } catch (error) { }
  };

  const getShopAroundList = useCallback(
    async (limit: number, skip: number) => {
      try {
        // const params = form.getFieldsValue();
        setLoading1(true);
        const res = await getAllShoppingAround(
          skip / limit + 1,
          limit,
          Number(activeTab)
        );
        setLoading1(false);
        if (res.isSuccess) {
          // setAllShoppingAround(res.data.data);
          setShoppingArounds(
            res.data.data.sort((a: any, b: any) =>
              sortByString(a, b, 'shoppingAroundCarrierServiceName')
            )
          );
          setTotal(res.data.count);
          skipRef.current = skip;
          limitRef.current = limit;
          // setDataUpdated((prev) => !prev);
        }
      } catch (error) {
        setLoading1(false);
      }
    },
    [activeTab]
  );

  const getAllShopAroundList = useCallback(
    async (limit: number, skip: number) => {
      try {
        console.log(limit, skip);
        // const params = form.getFieldsValue();
        setLoading1(true);
        const res = await getAllShoppingAround(
          skip / limit + 1,
          limit
          // Number(activeTab)
        );
        setLoading1(false);
        if (res.isSuccess) {
          setAllShoppingArounds(res.data.data);
          //setShoppingArounds(res.data.data);
          setAllTotal(res.data.count);
          skipRef.current = skip;
          limitRef.current = limit;
          // setDataUpdated((prev) => !prev);
        }
      } catch (error) {
        setLoading1(false);
      }
    },
    []
  );
  // copyCarrierServiceRestrictionList

  const handleRestrictionrCopy = useCallback(async () => {
    try {
      const res = await copyCarrierServiceRestrictionList(Number(activeTab));
      if (res.isSuccess) {
        message.success({ content: 'Copy has been completed' });
        getRestrictions();
      }
    } catch (error) { }
  }, [getRestrictions, activeTab]);

  const handleCopy = useCallback(async () => {
    try {
      setLoading1(true);
      const res = await copyShoppingAround(Number(activeTab));
      setLoading1(false);
      if (res.isSuccess) {
        getAllShopAroundList(limitRef.current, 0);
        message.success({ content: 'Copy has been completed' });
      }
    } catch (error) {
      setLoading1(false);
    }
  }, [getAllShopAroundList, activeTab]);

  /* eslint-disable */
  useEffect(() => {
    getWarehouses();
    getCarriers();
    // getRestrictions();
  }, []);
  useEffect(() => {
    if (warehouses.length > 0) {
      getRestrictions();
    }
  }, [warehouses]);

  useEffect(() => {
    if (!inited && activeTab) {
      if (activeTab === '-1') {
        if (activeTab1 === 'serviceMappingSetting') {
          getAllShopAroundList(limitRef.current, 0);
        } else {
          setRestrictions([...allRestrictions]);
        }
      } else {
        if (activeTab1 === 'serviceMappingSetting') {
          getShopAroundList(limitRef.current, 0);
        } else {
          setRestrictions(
            allRestrictions.filter(
              (i: any) => i.warehouseNum === Number(activeTab)
            )
          );
        }
      }
      setInited(true);
    }
  }, [activeTab, activeTab1]);
  useEffect(() => {
    if (!inited) return;
    if (activeTab === '-1') {
      if (activeTab1 === 'serviceMappingSetting') {
        getAllShopAroundList(limitRef.current, 0);
      } else {
        setRestrictions([...allRestrictions]);
      }
    } else {
      if (activeTab1 === 'serviceMappingSetting') {
        getShopAroundList(limitRef.current, 0);
      } else {
        const temp = allRestrictions.filter(
          (i: any) => i.warehouseNum === Number(activeTab)
        );
        setRestrictions([...temp]);
      }
    }
  }, [activeTab, activeTab1]);
  /* eslint-enable */

  return (
    <>
      <HeaderTitle
        breadcrumb={['Administration', 'Shipping', 'Shopping Around']}
      ></HeaderTitle>

      <GreyCardWrapper style={{ marginTop: 20 }}>
        {warehouses ? (
          <ClassicCard loading={loading || loading2}>
            {warehouses.length > 0 && (
              <Tabs
                activeKey={activeTab}
                // defaultActiveKey={activeTab || `${warehouses[0].warehouseNum}`}
                type="card"
                size="large"
                tabPosition="top"
                style={{ width: '100%', maxWidth: 1400 }}
                // style={{ width: 0 }}
                onChange={(activeKey) => {
                  setActiveTab(activeKey);
                  skipRef.current = 0;
                }}
              >
                {[
                  ...warehouses,
                  { warehouseCode: 'All', warehouseNum: -1 },
                ].map((item) => (
                  <TabPane
                    tab={item.warehouseCode}
                    key={`${item.warehouseNum}`}
                  >
                    <Tabs
                      activeKey={activeTab1}
                      type="card"
                      size="large"
                      tabPosition="top"
                      onChange={(activeKey) => {
                        setActiveTab1(activeKey);
                        skipRef.current = 0;
                      }}
                    >
                      <TabPane
                        tab="Service Mapping Setting"
                        key="serviceMappingSetting"
                      >
                        <Row justify="space-between">
                          <Space>
                            <Button
                              id="copy_default_mapping_setting_button"
                              disabled={item.warehouseNum === -1}
                              onClick={() => {
                                Modal.confirm({
                                  title:
                                    'Are you sure want to copy default mapping settings?',
                                  okText: 'Yes',
                                  cancelText: 'No',
                                  content: `warehouse : ${item.warehouseCode}`,
                                  onOk() {
                                    handleCopy();
                                  },
                                });
                              }}
                            >
                              <CopyOutlined />
                              Copy Default Mapping Settings
                            </Button>
                            <TablesFilter
                              columns={columns}
                              dataSource={
                                item.warehouseNum === -1
                                  ? allShoppingArounds
                                  : shoppingArounds
                              }
                              setFilteredData={setFilteredMappingData}
                            />

                          </Space>

                          <Button
                            id="add_service_mapping_button"
                            type="primary"
                            onClick={() => {
                              setCurrent(undefined);
                              setEditVisible(true);
                            }}
                          >
                            <PlusOutlined />
                            Add Service Mapping
                          </Button>
                        </Row>

                        <DataGridPlus
                          name="ShoppingAround"
                          dataSource={filteredMappingData}
                          loading={loading1}
                          columns={columns}
                          idProperty="id"
                          count={item.warehouseNum === -1 ? allTotal : total}
                          skip={skipRef.current}
                          limit={limitRef.current}
                          pagination="remote"
                          onChange={
                            item.warehouseNum === -1
                              ? getAllShopAroundList
                              : getShopAroundList
                          }
                          /* eslint-disable react/jsx-props-no-spreading */
                          {...({} as any)}
                        />
                      </TabPane>
                      <TabPane
                        tab="Service Restriction Setting"
                        key="serviceRestrictionSetting"
                      >
                        <Row justify="space-between">
                          <Space>
                            <Button
                              id="copy_restriction_button"
                              disabled={item.warehouseNum === -1}
                              onClick={() => {
                                Modal.confirm({
                                  title:
                                    'Are you sure want to copy default service restriction setting?',
                                  okText: 'Yes',
                                  cancelText: 'No',
                                  onOk() {
                                    handleRestrictionrCopy();
                                  },
                                });
                              }}
                            >
                              <CopyOutlined />
                              Copy Default Service Restriction Setting
                            </Button>
                            <TablesFilter
                              columns={restrictionColumns}
                              dataSource={restrictions}
                              setFilteredData={setFilteredRestrictionData}
                            />
                          </Space>

                          <Button
                            id="add_restriction_button"
                            type="primary"
                            onClick={() => {
                              setCurrent(undefined);
                              setEditRestrictionVisible(true);
                            }}
                          >
                            <PlusOutlined />
                            Add Service Restriction Setting
                          </Button>
                        </Row>
                        {warehouses.length > 0 && (
                          <DataGridPlus
                            name="restrictions"
                            dataSource={filteredRestrictionData}
                            columns={restrictionColumns}
                            idProperty="id"
                            pagination="local"
                            /* eslint-disable react/jsx-props-no-spreading */
                            {...({} as any)}
                          />
                        )}
                      </TabPane>
                    </Tabs>
                  </TabPane>
                ))}
              </Tabs>
            )}
          </ClassicCard>
        ) : (
          <Empty />
        )}
      </GreyCardWrapper>

      <GreyCardWrapper style={{ marginTop: 20 }}></GreyCardWrapper>
      {editVisible && (
        <EditDialog
          visible
          carries={carriers}
          onHide={() => setEditVisible(false)}
          onRefresh={() => {
            if (activeTab === '-1') {
              getAllShopAroundList(limitRef.current, 0);
            } else {
              getShopAroundList(limitRef.current, 0);
            }
          }}
          shipAround={current}
        />
      )}
      {editRestrictionVisible && (
        <EditRestriction
          visible
          carries={carriers}
          onHide={() => setEditRestrictionVisible(false)}
          onRefresh={() => {
            getRestrictions();
          }}
          restriction={current}
          warehouseNum={Number(activeTab)}
        />
      )}
    </>
  );
}
