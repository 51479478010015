import React, { useCallback, useEffect, useState } from 'react';
import { Row, Modal, Button, Space, Tooltip, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getDashboardTransferFailed } from 'services/dashboard';
import {
  ColumnDataType,
  SpaceCell,
  message,
  CopyComponent,
} from 'components/common';
import {
  DATAGRID_CACHE_SHIPMENTS_COLS,
  DATAGRID_CELL_ICON_WIDTH1,
  DATE_TIME_NO_SECOND_FORMAT,
} from 'constants/config';
import { CopyOutlined } from '@ant-design/icons';
import ShipmentDetail from '../Sales/ShipmentList/ShipmentDetailDialog';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';

const { Text } = Typography;

interface ModalProps {
  visible: boolean;
  onHide: () => void;
  params: any;
}
// eslint-disable-next-line
export default (props: ModalProps) => {
  const { visible, onHide, params } = props;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const [current, setCurrent] = React.useState<any | undefined>();
  const [shipmentDetailVisible, setShipmentDetailVisible] = useState(false);
  const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);

  const onClickChannelOrderID = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setDetailDialogVisible(true);
    }
  };

  const columns = [
    {
      header: 'Order Date',
      name: 'orderDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
      advanced: 1,
    },
    {
      header: 'Channel Order ID',
      maxWidth: 206,
      minWidth: 206,
      name: 'channelOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      advanced: 2,
      render: (row: any) => {
        const { data } = row;

        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.channelOrderId);
                    message.info(
                      `"${data.channelOrderId}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickChannelOrderID(data)}
            text={data.channelOrderId}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Shipment ID',
      name: 'shipmentId',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 3,
      render: (row: any) => {
        const { data } = row;
        return (
          <CopyComponent value={data.shipmentId}>
            <Text
              ellipsis={{ tooltip: data.locationCode }}
              style={{
                cursor: 'pointer',
                width: `calc(100% - 14px)`,
                color: '#1976d2',
              }}
              onClick={() => {
                setCurrent(data);
                setShipmentDetailVisible(true);
              }}
            >
              {data.shipmentId}
            </Text>
          </CopyComponent>
        );
      },
    },
  ];

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getDashboardTransferFailed(params);
      setLoading(false);
      if (res.isSuccess) {
        setList(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <Modal
      title="Errored shipments"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" onClick={onHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
        name="locationReport"
        autoWith={false}
        dataSource={list}
        loading={loading}
        columns={columns}
        idProperty="id"
        pagination="local"
        /* eslint-disable react/jsx-props-no-spreading */
        {...({} as any)}
      />

      {shipmentDetailVisible && current && (
        <ShipmentDetail
          onClose={() => setShipmentDetailVisible(false)}
          shipmentInfo={current}
          visible
          isHistory={false}
        />
      )}
      {detailDialogVisible && current && (
        <OrderDetail
          allowPopupWindow
          isHistory={false}
          fulfillmentOrderId={current.fulfillmentOrderId}
          fulfillmentOrderNum={current.fulfillmentOrderNum}
          selectShipment={current.shipmentId}
          onClose={() => setDetailDialogVisible(false)}
        />
      )}
    </Modal>
  );
};
