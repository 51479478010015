import React from 'react';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';
import { fetchShoppingAroundCarrierServiceList } from 'services/sales';

type Props = {
  onChange?: Function;
  shopAroundServiceNum: number;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  warehouseNum: number;
};

const ServiceSelector = (props: Props) => {
  const { useState } = React;
  const { shopAroundServiceNum, warehouseNum } = props;
  const [currentServiceNum, setCurrentServiceNum] = useState(0);
  const [serviceOptions, setFlatRateOptions] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>();
  const size = props.size || 'middle';
  const style = props.style || {};

  // eslint-disable-next-line
  const getServices = async () => {
    try {
      const packages = await fetchShoppingAroundCarrierServiceList(warehouseNum);

      console.log('packages -->', packages);
      setIsLoadingOptions(false);

      if (Array.isArray(packages)) {
        setFlatRateOptions(packages);
        trySelectService(packages, shopAroundServiceNum);
      }
    } catch(e) {
      message.error(`Fetch shopping around service error ${e}`);
    }
  };

  const onSelectionChange = (value: any) => {
    const sels = serviceOptions.filter(e => e.carrierServiceNum === value);

    setSelectedValue(value);

    if (typeof props.onChange === 'function') {
      if (sels.length > 0) {
        props.onChange(sels[0]);
      }
    }
  };

  const serviceSelectOptions = (options: StringKAnyVPair[]) => {
    return options.map((e) => (
      <Select.Option
        key={e.carrierServiceNum}
        value={e.carrierServiceNum}
      >
        {e.name}
      </Select.Option>
    ));
  };

  // eslint-disable-next-line
  const trySelectService = (
    options: StringKAnyVPair[],
    service: number,
  ) => {
    const sels = options.filter(e => e.carrierServiceNum === service);

    //console.log('try', sels, service);
    if (sels.length > 0) {
      setSelectedValue(service);

      if (typeof props.onChange === 'function') {
        props.onChange(sels[0]);
      }
    } else {
      setSelectedValue(undefined);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setIsLoadingOptions(true);
      getServices();
      setInited(true);
    }

    if (currentServiceNum !== shopAroundServiceNum) {
      //console.log('pppp', props);
      if (shopAroundServiceNum > 0) {
        trySelectService(serviceOptions, shopAroundServiceNum);
      } else {
        setSelectedValue(undefined);
      }

      setCurrentServiceNum(shopAroundServiceNum);
    }
  }, [
    currentServiceNum,
    getServices,
    inited,
    serviceOptions,
    shopAroundServiceNum,
    trySelectService,
    warehouseNum,
  ]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          dropdownClassName="scan-ship-select-dropdown-option-container"
          onChange={onSelectionChange}
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {serviceSelectOptions(serviceOptions)}
        </Select>
      )}
    </Row>
  );
};

export default ServiceSelector;
