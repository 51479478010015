import { combineReducers } from 'redux';
import admin from './admin';
import inventory from './inventory';
import regular from './regular';
import sales from './sales';

const reducers = combineReducers({
  admin,
  inventory,
  regular,
  sales,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
