import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FormView = styled.div`
  width: 100%;
  padding: 25px;
  border-radius: 5px;
  background-color: #ffffff;
`;
// export const InfoView = styled.div`
//   width: px;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   padding-left: 20px;
// `;

export const ProductInfo = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
`;
export const ProductInfoText = styled.div`
  width: 200;
  height: 200;
  margin-top: 20px;
  padding: 10px;
  align-self: flex-start;
`;
export const InfoBox = styled.div`
  width: 100%;
  margin-top: 20px;
  /* background-color: #ececec;
  padding: 10px; */
`;
export const LocationInfo = styled.div`
  width: 100%;
  background-color: #ffffff;
`;
export const LocationInfoBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: center;
`;
