import React, { useEffect, useState, useCallback } from 'react';
import { Button, Form, Input, InputNumber, message, Modal, Result, Row, Select, Steps, Checkbox } from 'antd';
import { FormLabel } from 'components/common';
import { createChannelAccount } from 'services/storeSetup';
import { fetchChannels } from 'services/sales'
import { sortByString } from 'utils';
import { LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import {
  getCustomers,
} from 'services/return';
interface EditDetailProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  checkDetail: (current: any) => void;
}

const CreateChannelAccountDialog = (props: EditDetailProps) => {
  const { visible, onHide, onRefresh, checkDetail } = props;
  const [channels, setChannels] = useState<any[]>([]);
  const [channel, setChannel] = useState<any>();
  const [saveLoading, setSaveLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [formValues, setFormValues] = useState<any>()
  const [channelType, setChannelType] = useState<0 | 1>(0) //Channel = 0  DropShip = 1
  const [useDefault, setUseDefault] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);

  const [form] = Form.useForm();
  const { Step } = Steps;

  const getCustomerInfo = useCallback(async () => {
    try {
      const res = await getCustomers()
      if (res.isSuccess) {
        setCustomers(res.data)
      }
    } catch (error) {

    }
  }, [])

  useEffect(() => {
    getCustomerInfo()
  }, [getCustomerInfo])

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onRefresh();
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onRefresh();
          onHide();
        },
      });
    }
  };


  useEffect(() => {
    const fetch = async () => {
      const res = await fetchChannels();
      if (res.isSuccess) {
        setChannels(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'value'))
        );
      }
    };
    fetch();
  }, []);

  const getCoustomerName = (code: string, list: { customerCode: string, customerName: string }[]) => {
    if (code) {
      const temp = list.filter(i => i.customerCode === code)
      if (temp.length > 0) {
        return temp[0].customerName
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  const handleSave = async () => {
    console.log('formvalues = ', formValues);
    try {
      setSaveLoading(true);
      const params = channelType === 0 ? {
        ChannelAccountType: 0,
        ChannelName: formValues.ChannelName,
        CentralChannelAccountNum: formValues.CentralChannelAccountNum,
        ChannelAccountName: formValues.ChannelAccountName
      } : {
        ChannelAccountType: 1,
        IsDefault: useDefault,
        ChannelName:'',
        ChannelNum:0,
        CustomerCode: useDefault? undefined : formValues.CustomerCode,
        CustomerName: useDefault? undefined: getCoustomerName(formValues.CustomerCode, customers),
        CustomerUuid: '',
        // ChannelAccountName: formValues.ChannelAccountName
      }
      const { data, isSuccess } = await createChannelAccount(params);
      setSaveLoading(false);
      if (isSuccess) {
        message.success({ content: 'Added successfully' });
      }
      setChannel(data);
      setCurrentStep(currentStep + 1);
      // onRefresh();
      // onHide();
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  const handleReset = () => {
    form.resetFields();
    setChannel(undefined);
    setFormValues(undefined);
    setCurrentStep(0)
  }

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    const handleValidate = async () => {
      const params = await form.validateFields();
      if (params.errorFields) return;
      setFormValues(params)
      setCurrentStep(currentStep + 1);
    }
    return (
      <Row justify="space-between">
        <Button
          style={{ opacity: currentStep === 0 ? 0 : 1 }}
          onClick={() => { setCurrentStep(currentStep - 1) }}
          disabled={currentStep === 0}
          icon={<LeftOutlined />}
        >
          Previous
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            currentStep === 0 ? handleValidate() : handleSave();
          }}
          loading={saveLoading}
          icon={currentStep === 0 ? <RightOutlined /> : <PlusOutlined />}
        >
          {currentStep === 0 ? 'Next' : 'Add Channel'}
        </Button>
      </Row>
    )
  }

  const steps = [
    { title: 'Channel Info', key: 'create' },
    { title: 'Review', key: 'review' },
    { title: 'Done', key: 'generate' },
  ]

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={`Add Channel Account`}
      centered
      visible={visible}
      maskClosable={false}
      width={1100}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={currentStep === 2 ? null : <Footer />}
    >
      <Steps current={currentStep}>
        {
          steps.map(step => {
            return <Step {...step} />
          })
        }
      </Steps>
      {
        currentStep === 0 && (
          <Form form={form} {...layout} style={{ margin: "40px auto", width: "60%" }}>
            <Form.Item
              label={<FormLabel>Channel Type</FormLabel>}
            >
              <Select
                style={{ width: '100%' }}
                value={channelType}
                onChange={setChannelType}
              >
                <Select.Option value={0}>Channel</Select.Option>
                <Select.Option value={1}>Dropship Customer</Select.Option>
              </Select>
            </Form.Item>

            {channelType === 0 &&<Form.Item
              name="ChannelName"
              label={<FormLabel>Channel</FormLabel>}
              rules={[{ required: true, message: 'Channel is required' }]}
            >
              <Select
                showSearch
                allowClear
                placeholder="Please select"
                style={{ width: '100%' }}
                optionFilterProp="label"
              >
                {channels.map((item) => (
                  <Select.Option
                    key={`${item.id}-${item.value}`}
                    value={item.value}
                    label={item.value}
                  >
                    {item.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>}
            { channelType === 0 && <Form.Item
              name="CentralChannelAccountNum"
              label={<FormLabel>CCA#</FormLabel>}
              rules={[{ required: true, message: 'CCA# must be a number!' }]}
            >
              <InputNumber type={'number'} controls={false} placeholder="Please input" style={{ width: '100%' }} />
            </Form.Item>}
            {
              channelType === 1 && <Form.Item name="useDefault" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox onChange={(e)=> {
                if(e.target.checked){
                  form.setFieldsValue({CustomerCode: undefined})
                }
                setUseDefault(e.target.checked)
              }
                }>use Default Customer</Checkbox>
            </Form.Item>
            }
            {channelType === 1 &&   <Form.Item
                name="CustomerCode"
                label={<FormLabel>Customer</FormLabel>}
                rules={[
                  { required: !useDefault, message: 'Please select customer!' },
                ]}
              >
                <Select
                  showSearch
                  disabled={useDefault}
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option) {
                      const { children = '', value = '' } = option;
                      return (typeof children === 'string' && children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        || (typeof value === 'string' && value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }

                    return false;
                  }}
                >
                  {customers.map(item =>
                    <Select.Option key={item.customerCode} value={item.customerCode}>
                      {item.customerName}
                    </Select.Option>

                  )}
                </Select>
              </Form.Item>}
              { channelType === 0 && <Form.Item
              name="ChannelAccountName"
              label={<FormLabel>Store Name</FormLabel>}
              rules={[{ required: true, message: 'Store# is required' }]}
            >
              <Input allowClear placeholder="Please input" />
            </Form.Item>}
          </Form>
        )
      }
      {
        currentStep === 1 && (
          <Form {...layout} style={{ margin: "40px auto", width: "60%" }}>
            {channelType === 0 && <Form.Item
              label={<FormLabel>Channel</FormLabel>}
            >
              {formValues.ChannelName}
            </Form.Item>}
            {channelType === 0 && <Form.Item
              label={<FormLabel>CCA#</FormLabel>}
            >
              {formValues.CentralChannelAccountNum}
            </Form.Item>}

            {channelType === 1 && <Form.Item
              label={<FormLabel>Customer</FormLabel>}
            >
              {useDefault ? 'Use Default Costomer' : formValues.CustomerCode}
            </Form.Item>}

            {channelType === 0 &&<Form.Item
              label={<FormLabel>Store Name</FormLabel>}
            >
              {formValues.ChannelAccountName}
            </Form.Item>}
          </Form>
        )
      }
      {
        currentStep === 2 && (
          <Result
            status="success"
            title={`Channel Account: ${formValues.ChannelAccountName} was added successfully!`}
            subTitle={`Click "Configure" to setup this account or click "Add More" to add new channel account.`}
            extra={[
              <Button key="continue" onClick={handleReset}>Add More</Button>,
              <Button key="detail" onClick={() => { checkDetail(channel) }} type="primary">Configure</Button>,
            ]}
          >
          </Result>
        )
      }
    </Modal>
  );
};

export default CreateChannelAccountDialog;
