import { useEffect, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import {
  warehouseList,
  // countryList,
  transactionReasonList,
  transactionTypeList,
  tagList,
  userProfileSettingList,
  userPermissionsList,
  setDatabaseNum,
} from 'actions/adminActions';
import { fetchTransactionReasonList } from 'services/transactionReason';
// import { queryCountry } from 'services/country';
import { fetchTransactionTypeList } from 'services/transactionType';
import { fetchTags } from 'services/tag';
import { fetchWarehouseListPlus } from 'services/warehouse';
import { fetchProfileSettings, fetchPermissions, fetchDatabaseNum } from 'services/user'

export default function useCache() {
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    //cache warehouse list
    /*fetchWarehouseListPlus().then((warehouses) => {
      dispatch(warehouseList(warehouses));
    });*/
    //cache transactionReason list
    fetchTransactionReasonList().then((data) => {
      dispatch(transactionReasonList(data));
    });
    // cache user profile settings
    fetchProfileSettings().then((data) => {
      dispatch(userProfileSettingList(data))
    }).catch(() => dispatch(userProfileSettingList([])))
    // cache user permissions
    fetchPermissions().then((data) => {
      dispatch(userPermissionsList(data))
    }).catch(()=> dispatch(userPermissionsList([])))
    //caceh country list
    // queryCountry().then((countrys) => {
    //   dispatch(countryList(countrys));
    // });
    //transactionTypes
    fetchTransactionTypeList().then((data) => {
      dispatch(transactionTypeList(data));
    });
    //tag
    fetchTags().then((data) => {
      dispatch(tagList(data));
    });
    fetchDatabaseNum().then((data)=>{
      dispatch(setDatabaseNum(data))
    })
  }, [dispatch]);

  return {
    /**
     * realod TransactionType list
     */
    reloadTransactionTypeListCache: async () => {
      const data = await fetchTransactionTypeList();
      dispatch(transactionTypeList(data));
    },
    /**
     * realod TransactionReason list
     */
    reloadTransactionReasonListCache: async () => {
      const data = await fetchTransactionReasonList();
      dispatch(transactionReasonList(data));
    },
    /**
     * realod Warehouse list
     */
    reloadWarehouseListCache: async () => {
      const data = await fetchWarehouseListPlus();
      dispatch(warehouseList(data));
    },
    /**
     * realod Tag list
     */
    realodTagListCache: async () => {
      const data = await fetchTags();
      dispatch(tagList(data));
    },
  };
}
