import React, { useCallback, useState } from 'react';
import { Button, Card, Form } from 'antd';
import {
  HeaderTitle,
  SelectWarehouse,
  FormLabel,
  message,
} from 'components/common';
import { SaveOutlined } from '@ant-design/icons';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

// eslint-disable-next-line
export default () => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();

  const saveSetting = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setSaveLoading(true);
    try {
      //todo save
      localStorage.setItem(
        LS_DEFAULT_WAREHOUSE_KEY,
        params.defaultWarehouse.id
      );
      // localStorage.setItem(
      //   LS_DEFAULT_WAREHOUSE_CODE,
      //   params.defaultWarehouse.code
      // );
      // localStorage.setItem(
      //   LS_DEFAULT_WAREHOUSE_WAREHOUSEID,
      //   `${params.defaultWarehouse.warehouseId}`
      // );

      setSaveLoading(false);
      message.success({ content: 'Saved successfully' });
    } catch (error) {
      setSaveLoading(false);
    }
  }, [form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Settings']}>
        <Button
          type="primary"
          loading={saveLoading}
          onClick={() => saveSetting()}
        >
          <SaveOutlined />
          Save
        </Button>
      </HeaderTitle>
      <Card>
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="defaultWarehouse"
            label={<FormLabel>Default Warehouse</FormLabel>}
            rules={[
              { required: true, message: 'Default Warehouse is required' },
            ]}
          >
            <SelectWarehouse
              placeholder={
                localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
                  ? ''
                  : 'Please select a default warehouse'
              }
            />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
