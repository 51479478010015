import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Select } from 'antd';
import { RootState } from 'reducers';
import { formatPrinterToTree } from 'utils';

type SelectorProps = {
  onChange?: Function;
  placeholder?: string;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  value?: number;
};

const PrinterSelector = (props: SelectorProps) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  // eslint-disable-next-line
  const [selectedValue, setSelectedValue] = useState(props.value || 0);
  const [tree, setTree] = useState<any[]>([])
  const style = props.style || {};

  const printerList: Printer[] = useSelector(
    (state: RootState) => state.sales.printerList,
    shallowEqual
  );

  const initData = useCallback(()=>{
    setTree(formatPrinterToTree(printerList))
  },[printerList])

  useEffect(()=>{
    initData()
  },[initData])

  const handleSelectPrinter = (val: number) => {
    let printer: Printer = {} as Printer;

    setSelectedValue(val);

    if (val && val > 0) {
      for (let i = 0; i < printerList.length; i++) {
        if (val === printerList[i].printerNum) {
          printer = printerList[i];
          break;
        }
      }
    }

    if ('function' === typeof props.onChange) {
      props.onChange(printer);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }
  }, [inited]);

  return (
    <Row align="middle" style={style}>
        <Select
          onChange={handleSelectPrinter}
          placeholder={props.placeholder || ''}
          size={props.size || 'middle'}
          style={{ width: '100%' }}
          optionLabelProp="label"
          value={selectedValue || undefined}
        >
          {tree?.map((item) => {
                return (
                  <Select.OptGroup
                    key={item.label}
                    label={item.label}
                  >
                    {item.options.map((citem: any) => {
                        return (
                          <Select.Option
                            key={citem.value}
                            value={citem.value}
                            label={`${item.label} - ${citem.label}`}
                          >
                            {citem.label}
                          </Select.Option>
                        );
                      })
                      }
                  </Select.OptGroup>
                );
              })}
        </Select>
    </Row>
  );
};

export default PrinterSelector;
