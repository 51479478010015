import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Audit from 'components/Sales/Smart/Audit';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartPickScreen(): JSX.Element {
  return (
    // <ContentLayout title={`Audit(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}>
    <ContentLayout titleKey="titles.audit" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}>
      <Audit />
    </ContentLayout>
  );
}
