import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Typography, Spin, Modal, Button, Space } from 'antd';
import { message, FormLabel } from 'components/common';
import { fetchProduct, patchProduct } from 'services/product';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

const { Text } = Typography;
type EditProductCodeProps = {
  visible: boolean;
  onHide: () => void;
  product?: ProductRow;
};
export default function EditProductCode(
  props: EditProductCodeProps
): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<ProductRow | null>();
  const { visible, onHide } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    const fetch = async () => {
      if (props.product && props.visible) {
        setLoading(true);
        const newProduct = await fetchProduct(props.product.productId);
        setProduct(newProduct);
        if (newProduct) {
          form.setFieldsValue({ Code: newProduct.code });
        }
        setLoading(false);
      }
    };
    fetch();
  }, [props, form]);
  const onSubmit = async () => {
    if (!product) {
      return;
    }
    let values: StringKAnyVPair = {};
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }
    setLoading(true);
    await patchProduct(product.productId, values);
    message.success({ content: 'Update product code successfully!' });
    setLoading(false);
    onHide();
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="space-between">
        <Button icon={<EditOutlined />}>Edit Alternates</Button>
        <Space>
          <Button key="submit" type="primary" onClick={onSubmit} icon={<EditOutlined />}>
            Edit Code
          </Button>
          <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Edit Product Code"
      centered
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={onHide}
      getContainer={false}
      footer={<Footer />}
    >
      <Spin spinning={loading}>
        <Row>
          <Text>{`SKU:'${product && product.sku}'`}</Text>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Form
            /* eslint-disable react/jsx-props-no-spreading */
            {...layout}
            form={form}
          >
            <Form.Item
              name="Code"
              label={<FormLabel>New Code</FormLabel>}
              rules={[{ required: true, message: 'Please input code!' }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Row>
      </Spin>
    </Modal>
  );
}
EditProductCode.defaultProps = {
  product: undefined,
};
