import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { fetchCarriers } from 'services/shipment';

const { TabPane } = Tabs;
type SelectCarrierProps = {
  value: CarrierRow | undefined;
  onChange: (carrier: CarrierRow) => void;
};
export default function SelectCarrier(props: SelectCarrierProps): JSX.Element {
  const { onChange, value } = props;
  const [carriers, setCarriers] = useState<Array<CarrierRow>>([]);
  useEffect(() => {
    const fetch = async () => {
      if (carriers.length === 0) {
        const newCarriers = await fetchCarriers();
        const filterCarrier = newCarriers.filter((i) => i.carrierNum > 0);
        setCarriers([
          ...filterCarrier,
          { carrierNum: -1, name: 'Custom Package', status: 0 },
        ]);
        if (filterCarrier.length > 0) {
          onChange(filterCarrier[0]);
        } else {
          onChange({ carrierNum: -1, name: 'Custom Package', status: 0 });
        }
      }
    };
    fetch();
  }, [carriers, onChange]);

  return (
    <>
      {carriers.length > 0 && (
        <Tabs
          id="tabs"
          style={{width: '100%'}}
          defaultActiveKey={
            value ? `${value.carrierNum}` : `${carriers[0].carrierNum}`
          }
          size="middle"
          type="card"
          onChange={(carrierNum) => {
            const carrier = carriers.filter(
              (item) => `${item.carrierNum}` === carrierNum
            )[0];
            onChange(carrier);
          }}
        >
          {carriers.map((carrier) => {
            return <TabPane tab={carrier.name} key={`${carrier.carrierNum}`} />;
          })}
        </Tabs>
      )}
    </>
  );
}
