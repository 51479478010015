import React from 'react';
import styled from 'styled-components';
import PopupWindow from 'components/PopupWindow';

const ContentWrapper = styled.div`
  width: 100%;
`;

// eslint-disable-next-line
export default () => {
  const { useRef, useState } = React;
  const [inited, setInited] = useState(false);
  const wrapperRef = useRef<any>(null);

  const hideSiderBar = () => {
    const wrp = wrapperRef?.current;

    if (wrp) {
      const sider = wrp.previousSibling;

      if (sider) {
        sider.style.display = 'none';
      }
    }
  };

  React.useEffect(() => {
    if (!inited) {
      hideSiderBar();
      setInited(true);
    }
  }, [inited]);

  return (
    <ContentWrapper ref={wrapperRef}>
      <PopupWindow />
    </ContentWrapper>
  );
};
