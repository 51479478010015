import React from 'react';
import ContentLayout from 'components/ContentLayout';

function Fulfill() {
  return (
    <ContentLayout>
      <h1>Fulfill</h1>
    </ContentLayout>
  );
}

export default Fulfill;
