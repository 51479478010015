import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, ColumnDataType, TablesFilter } from 'components/common';
import { CloseOutlined } from '@ant-design/icons';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';

type Props = {
  onHide: () => void;
  onOk: (selectedReturn: any) => void;
  returnList: any[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, returnList, onOk } = props;
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [selected, setSelected] = useState<PoRow>();
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const columns = [
    {
      header: 'Order',
      name: 'channelOrderId',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'RMA #',
      name: 'returnNo',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Custom Name',
      name: 'shipToName',
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return data.shipToName || data.endBuyerName;
      }
    },
    {
      header: 'Enter Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
  ];
  return (
    <Modal
      title="Return List"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button id="cancel_button" onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
            {/* <Button type="primary" onClick={handleOk}>
              Use Selected
            </Button> */}
          </Space>
        </Row>
      }
    >
      <TablesFilter
        columns={columns}
        dataSource={returnList}
        setFilteredData={setFilteredData}
      />
      <DataGridPlus
        columns={columns}
        onRowClick={(row) => {
          onOk(row.data);
        }}
        dataSource={filteredData}
        idProperty="ponum"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
