/**
 * Administration-related service requests are here.
 */
import { HTTP_STATUS_OK } from 'constants/config';
import { digitBridgeApi } from './http';

/**
 * Create user authentication for a warehouse.
 */
export const createWarehouseUserAuthEntity = async (
  warehouseId: string,
  email: string
): Promise<any> => {
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/authorities`,
    email
  );
};

/**
 * Delete a user authentication for a warehouse.
 */
export const deleteWarehouseUserAuthEntity = async (
  warehouseId: string,
  rowId: number
): Promise<any> => {
  return await digitBridgeApi.delete(
    `/api/warehouses/${warehouseId}/authorities/${rowId}`
  );
};

/**
 * Get users of company with masterAccountNum.
 */
export const fetchUserList = async (): Promise<any> => {
  const res = await digitBridgeApi.get('/api/UserList');
  let ret: StringKAnyVPair[] = [];

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = data;
    }
  }

  return ret;
};

/**
 * Fetch user authentication list of warehouse.
 */
export const fetchWarehouseUserAuthList = async (
  warehouseId: string,
  email?: string
): Promise<any> => {
  const params = email ? { keyword: email } : {};

  return await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/authorities`,
    { params }
  );
};

/**
 * delete
 */
export const deteleTestData = async (): Promise<any> => {
  return await digitBridgeApi.delete('/api/tests/data');
};


export const fetchAllUserAuthList = async (): Promise<any> =>  await digitBridgeApi.get('/api/authorities');
