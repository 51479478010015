import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import WhiteListHistory from 'components/Reports/WhiteListHistory';

function Shipment() {
  return (
    <ContentLayout>
      <SiteContent>
        <WhiteListHistory />
      </SiteContent>
    </ContentLayout>
  );
}

export default Shipment;
