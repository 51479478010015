import React, { useCallback, useRef, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Row,
  Form,
  Col,
  Space,
  DatePicker,
  Input,
  Select,
  Tooltip,
  Radio,
  Typography,
  Modal,
  Checkbox,
  Dropdown,
  Menu,
} from 'antd';
import {
  CopyOutlined,
  FilePdfOutlined,
  SearchOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  SettingFilled,
  CaretDownOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  DownloadOutlined,
  RestOutlined
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import moment from 'moment';
import { RootState } from 'reducers';
import theme from 'assets/styles/theme';
import {
  ClearButton,
  ColumnDataType,
  /*DataGridPlus,*/
  ExportCSV,
  FormLabel,
  HeaderTitle,
  Loading,
  ScreenMask,
  SelectWarehouse,
  SpaceCell,
  message,
  BoolCell,
  GreyCardWrapper,
  CopySpan,
  CopyComponent,
  OrderDetailsCell,
  SearchCollapse,
  TablesFilter,
} from 'components/common';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';
import { ConditionColumns } from 'components/common/SearchCollapse';
import { ClassicCard } from 'components/common/styles';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import ManageShipmentDialog from 'components/Sales/ScanVerifyPrint/ManageShipment/ManageShipmentDialog';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import {
  DATAGRID_CACHE_SHIPMENTS_COLS,
  DATAGRID_CELL_ICON_WIDTH1,
  DATE_TIME_NO_SECOND_FORMAT,
  DIMENSION_UNITS as unitEnum,
  LOADING_ICON_SIZE1,
  LS_DEFAULT_WAREHOUSE_KEY,
  WEIGHT_UNITS as weightUnitEnum,
} from 'constants/config';
import { exportBusinessFile } from 'services/files';
import * as SaleServices from 'services/sales';
import {
  fetchShipmentsList,
  fetchShipmentsListFromHistory,
  fetchShipmentsListWithItem,
  postExportShipmentLabelPdf
} from 'services/sales';
import { fetchStoreList } from 'services/storeSetup';
import { getShipAccountByWarehouse } from 'services/ship';
import { getInventoriesBySkus } from 'services/orders';
import {
  exportShipmentPackingSlip,
  exportShipmentDocument,
  fetchCarriers,
  getCarrierServices,
  resetShipmentPending,
  retryShopAround,
  setShippingLabelRelease,
  resendtoErp,
  resync3pl,
  batchResendToErp,
  syncToErp,
} from 'services/shipment';
import {
  convertTimeByUtc,
  convertToUtcTime,
  formatNum,
  getLocaleTimezoneOffset,
  getWarehouseIdFromCache,
  nowTime,
  onSelectionChange,
  sortByString,
  trimValues,
} from 'utils';
import { userProfiles } from 'utils/auth';
// import { fetchProfileSettings } from 'services/user';

import ExportLabelPDF from './LablePdf';
import ShipmentDetail from './ShipmentDetailDialog';
import MarkShippedDialog from './MarkShippedDialog';
import { PackSlipCell } from './styles';
import {
  shipmentStatusKeyValue as statusKeyValue,
  shopAroundStatusKeyValue,
} from 'constants/enums'
import { useLocation } from 'react-router-dom';

type KeyWordType =
  | 'channelOrderId'
  | 'SO'
  | '2ndChannelOrderId'
  | 'sellerOrderId'
  | 'traceId'
  | 'fulfillmentOrderId'
  | 'shipmentId'
  | 'centralOrderNum';

type AdvancedKeyWordType =
  | 'DivisionCode'
  | 'DepartmentCode'
  | 'GroupCode'
  | 'SubGroupCode';

const KeyWordTypeMap = {
  channelOrderId: 'Channel Order ID',
  SO: 'Sales Order#',
  '2ndChannelOrderId': '2nd Channel Order ID',
  sellerOrderId: 'Seller Order ID',
  traceId: 'Trace ID',
  fulfillmentOrderId: 'Fulfillment Order ID',
  shipmentId: 'Shipment ID',
  centralOrderNum: 'Central Order#',
};

const AdvancedKeyWordTypeMap = {
  DivisionCode: 'Division Code',
  DepartmentCode: 'Department Code',
  GroupCode: 'Group Code',
  SubGroupCode: 'Subgroup Code',
};

const { RangePicker } = DatePicker;
const { Text } = Typography;

const billToTypeKeyValue: { [key: string]: string } = {
  '0': 'Shipper',
  '1': 'Receiver',
  '2': 'Third Party',
};

export default function ShipmentsGrid(): JSX.Element {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const dwarehouseNum = searchParams.get('warehouseNum')
  const defaultStart = searchParams.get('s');
  const defaultEnd = searchParams.get('e');
  // const defaultStatus = searchParams.get('status');
  const defaultStatus = searchParams.get('status')?.split('|').map(item => {
    // @ts-ignore
    return { label: statusKeyValue[item], value: item };
  });
  const defaultShopAroundStatus = searchParams.get('shopAroundStatus');
  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const userPermissions = useSelector(
    (state: RootState) => state.admin.userPermissions,
    shallowEqual
  );
  const hasAddressPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40021 && i.permissionLevel === 4) : []
  const [shipments, setShipments] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false)
  const [lockScreen, setLockScreen] = useState(false);
  const [tempParams, setTempParams] = useState<any>();
  const [shipAccountList, setShipAccountList] = useState<ShipAccountRow[]>([]);
  const [serviceList, setServiceList] = useState<any[]>([]);
  const [carriers, setCarriers] = useState<any[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<number>();
  const [searchFrom, setSearchFrom] = useState(0); // from history:1
  const [dataType, setDataType] = useState(0); // hitory data: 1
  const [gridType, setGridType] = useState<boolean>(false); // true means itemview
  const [shipmentDialogVisible, setShipmentDialogVisible] = useState(false);
  const [marKDialogVisible, setMarkDialogVisible] = useState(false);
  const sortStatus = useRef(false);
  const sortId = useRef('createDate');
  const [keyWordType, setKeyWordType] = useState<KeyWordType>('channelOrderId');
  const [current, setCurrent] = React.useState<any | undefined>();
  const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);
  const [data, setData] = useState<Array<ChannelAccountRow>>([]);
  const [dateRangeAble, setDateRangeAble] = useState<boolean>(false);
  const [productDialogVisible, setProductDialogVisible] =
    useState<boolean>(false);
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<boolean>(false);

  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>({});
  const [viewOrderEnterDateRange, setViewOrderEnterDateRange] =
    useState<number>(0);

  const [advancedKeyWordType, setAdvancedKeyWordType] =
    useState<AdvancedKeyWordType>('DivisionCode');
  // const [columns, setColumns] = useState<any[]>([]);
  const [isShowAdvanced, setIsShowAdvanced] = useState<boolean>(false);
  const [isShowPrint, setIsShowPrint] = useState<boolean>(false);

  const [exportLablePdfVisible, setExportLablePdfVisible] = useState(false);
  const [conditionColumns, setConditionColumns] = useState<ConditionColumns[]>(
    []
  );
  const [factor, setFactor] = useState<boolean>(false);
  const [shipmentDetailVisible, setShipmentDetailVisible] = useState(false);
  const [searchLimit, setSearchLimit] = useState<any>({ CreateTime: 'default' });
  const [oper, setOper] = useState<number>(0); //equals or start with
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [init, setInit] = useState<boolean>(false);

  const limitRef = useRef(10);
  const skipRef = useRef(0);

  const [form] = Form.useForm();
  const history = useHistory();

  const warehouses = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );

  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'ShipmentsCreateDateRange'
      );
      if (temp.length > 0) {
        setViewOrderEnterDateRange(temp[0].settingValue);
      }
    }
  }, [userProfileSettings]);
  // const getProfileSettings = useCallback(async () => {
  //   try {
  //     const settings = await fetchProfileSettings();
  //     if (Array.isArray(settings)) {
  //       const temp = settings.filter(
  //         (e) => e.settingCode === 'ViewOrderEnterDateRange'
  //       );
  //       if (temp.length > 0) {
  //         setViewOrderEnterDateRange(temp[0].settingValue);
  //       }
  //     }
  //   } catch (e) {
  //     message.error(`Fetch profile setting error: ${e}`);
  //   }
  // }, []);

  const onClickChannelOrderID = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setDetailDialogVisible(true);
    }
  };
  const onClickQty = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setShipmentDialogVisible(true);
    }
  };

  const onClickSKU = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setProductDialogVisible(true);
    }
  };

  let tempcolumns = [
    {
      header: 'Order Date',
      name: 'orderDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
      advanced: 1,
    },
    {
      header: 'Channel Order ID',
      maxWidth: 206,
      minWidth: 206,
      name: 'channelOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      advanced: 2,
      render: (row: any) => {
        const { data } = row;

        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.channelOrderId);
                    message.info(
                      `"${data.channelOrderId}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickChannelOrderID(data)}
            text={data.channelOrderId}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Shipment ID',
      name: 'shipmentId',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 3,
      render: (row: any) => {
        const { data } = row;
        return dataType === 0 ? (
          <CopyComponent value={data.shipmentId}>
            <Text
              ellipsis={{ tooltip: data.locationCode }}
              style={{
                cursor: 'pointer',
                width: `calc(100% - 14px)`,
                color: '#1976d2',
              }}
              onClick={() => {
                setCurrent(data);
                setMarkDialogVisible(true);
              }}
            >
              {data.shipmentId}
            </Text>
          </CopyComponent>
        ) : (
          <CopyComponent value={data.shipmentId}>
            <Text
              ellipsis={{ tooltip: data.locationCode }}
              style={{
                width: `calc(100% - 14px)`,
              }}
            >
              {data.shipmentId}
            </Text>
          </CopyComponent>
        );
      },
    },
    {
      header: 'Shipping Label PDF',
      // name: 'shippingStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      textAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
      advanced: 3.1,
      showInPrint: true,
      render(row: any) {
        const {
          row: { data },
        } = { row };
        return (
          <Row>
            <Col offset={5} span={14}>
              <Tooltip placement="top" title="Generate Shipping Label PDF">
                <PackSlipCell align="middle" justify="space-between">
                  <span>
                    {data.shippingStatus === 0 ? 'Not Printed' : 'Printed'}
                  </span>
                  <FilePdfOutlined
                    className="pdf-printed-icon"
                    style={{ cursor: 'pointer', color: '#1976d2' }}
                    onClick={() => {
                      if (data.shippingStatus === 1) {
                        handleExportShippingLabel(data);
                      } else {
                        setCurrent(data);
                        setExportLablePdfVisible(data);
                      }
                    }}
                  />
                </PackSlipCell>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      header: 'Packing Slip Printed',
      name: 'packingSlipPrintStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      textAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
      advanced: 3.2,
      showInPrint: true,
      render(row: any) {
        const {
          row: { data },
        } = { row };
        return (
          <Row>
            <Col offset={5} span={14}>
              <Tooltip placement="top" title="Generate Packing Slip PDF">
                <PackSlipCell align="middle" justify="space-between">
                  <span>
                    {data.packingSlipPrintStatus ? 'Printed' : 'Not Printed'}
                  </span>
                  <FilePdfOutlined
                    className="pdf-printed-icon"
                    onClick={() => handleExportPackingSlip(data)}
                  />
                </PackSlipCell>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      header: 'Store Name',
      name: 'channelAccountName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 4,
    },
    {
      header: 'SKU',
      name: 'sku',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 220,
      advanced: 4.1,
      showInItems: true,
      render: (row: any) => {
        const { data } = row;
        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(`"${data.sku}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickSKU(data)}
            text={data.sku}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Ship Qty',
      name: 'shippingQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 120,
      advanced: 5,
      render: (value: any) => {
        const { data } = value;
        return data.shippingQty > 1 ? (
          <CopyComponent value={data.lineShippingQty || data.shippingQty}>
            <Text
              ellipsis={{ tooltip: data.locationCode }}
              style={{
                cursor: 'pointer',
                color: '#1976d2',
                width: `calc(100% - 14px)`,
              }}
              onClick={() => onClickQty(data)}
            >
              {data.lineShippingQty || data.shippingQty}
            </Text>
          </CopyComponent>
        ) : (
          <CopySpan inGrid value={data.lineShippingQty || data.shippingQty} />
        );
      },
    },
    {
      header: 'In Stock',
      name: 'stock',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 5.1,
      showInItems: true,
    },
    {
      header: 'Status',
      name: 'shippingStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 6,
      renderEnum: statusKeyValue,
      render: (value: any) => {
        const { data } = value;
        return dataType === 0 ? (
          data.shippingStatus === 9 ? (
            <CopyComponent value={data.shippingStatus}>
              <Text
                ellipsis={{ tooltip: data.locationCode }}
                style={{
                  cursor: 'pointer',
                  color: '#1976d2',
                  width: `calc(100% - 14px)`,
                }}
                onClick={() => {
                  Modal.confirm({
                    title:
                      'Reset shipment from shipping to pending. Are you sure you want to continue?',
                    okText: 'Yes',
                    content: (
                      <Space direction="vertical">
                        <Text>
                          Shipment Id: <Text strong>{data.shipmentId}</Text>
                        </Text>
                        <Text>
                          Channel Order ID:{' '}
                          <Text strong>{data.channelOrderId}</Text>
                        </Text>
                      </Space>
                    ),
                    onOk() {
                      resetStatus(data);
                    },
                  });
                }}
              >
                {statusKeyValue[`${data.shippingStatus}`]}
              </Text>
            </CopyComponent>
          ) : (
            <CopySpan
              inGrid
              value={statusKeyValue[`${data.shippingStatus}`] || ''}
            />
          )
        ) : (
          <CopySpan
            inGrid
            value={statusKeyValue[`${data.shippingStatus}`] || ''}
          />
        );
      },
    },
    {
      header: 'Ship Date',
      name: 'actualShipDate',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 7,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.actualShipDate
                ? //? moment(data.actualShipDate).format('MM/DD/YYYY hh:mm A')
                convertTimeByUtc(
                  data.actualShipDate,
                  '',
                  '',
                  'MM/DD/YYYY hh:mm A'
                )
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Channel Name',
      name: 'channelName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 8,
    },
    {
      header: '2nd Channel Order ID',
      name: 'secondaryChannelOrderId',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 9,
    },
    {
      header: 'Seller Order ID',
      name: 'sellerOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 10,
    },
    {
      header: 'Shipped by',
      name: 'shippedBy',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 11,
    },
    {
      header: 'Weight',
      name: 'weight',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 12,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.weight
                ? `${data.weight} ${weightUnitEnum[data.weightUnit || 0]}`
                : ''
            }
          />
        );
      },
      sort: (a: number, b: number) => a - b,
    },
    {
      header: 'Package',
      name: 'packageName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 13,
    },
    {
      header: 'Length',
      name: 'length',
      type: 'number',
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 14,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.length
                ? `${data.length} ${unitEnum[data.lengthUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Width',
      name: 'width',
      type: 'number',
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 15,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.width
                ? `${data.width} ${unitEnum[data.lengthUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Height',
      name: 'height',
      type: 'number',
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 16,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.height
                ? `${data.height} ${unitEnum[data.lengthUnit || 0]}`
                : ''
            }
          />
        );
      },
    },
    {
      header: 'Update Date',
      name: 'updateDate',
      resizable: true,
      draggable: true,
      minWidth: 169,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
      advanced: 17,
    },
    {
      header: 'Carrier',
      name: 'shippingCarrier',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 18,
    },
    {
      header: 'Service',
      name: 'shippingService',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 19,
    },
    {
      header: 'Tracking #',
      name: 'trackingNumber',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 20,
    },
    {
      header: 'Recipient',
      name: 'shipToName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 21,
    },
    {
      header: 'Documents',
      name: 'documents',
      sortable: true,
      resizable: true,
      draggable: true,
      textAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
      advanced: 23,
      render(row: any) {
        const {
          row: { data },
        } = { row };

        return data.documents && data.documents.length > 0 ? (
          <Row>
            {data.documents.map((item: any) => (
              <Tooltip
                key={item.blobName}
                placement="top"
                title={`${item.fileName}(${item.copiesToPrint})`}
                trigger={['hover', 'click']}
              >
                <FilePdfOutlined
                  className="pdf-printed-icon"
                  style={{ color: '#1976d2' }}
                  onClick={() => handleExportDocuments(item, data.shipmentNum)}
                />
              </Tooltip>
            ))}
          </Row>
        ) : null;
      },
    },
    {
      header: 'Shopping Around Status',
      name: 'shopAroundStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 24,
      renderEnum: shopAroundStatusKeyValue,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={shopAroundStatusKeyValue[`${data.shopAroundStatus}`]}
          />
        );
      },
    },
    {
      header: 'Shopping Around Count',
      name: 'shopAroundCount',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 25,
    },
    {
      header: 'Require Shopping Around ',
      name: 'shopAroundRequired',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 26,
      renderEnum: {
        '0': 'Not Required',
        '1': 'Required',
      },
      render: (value: any) => {
        const { data } = value;
        const temp: { [key: string]: string } = {
          '0': 'Not Required',
          '1': 'Required',
        };
        return <CopySpan inGrid value={temp[`${data.shopAroundRequired}`]} />;
      },
    },
    {
      header: 'Shipping Label Release',
      name: 'shippingLabelRelease',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 35,
      renderEnum: {
        '1': 'Allow',
        '0': 'Not Allow',
      },
      render: (value: any) => {
        const { data } = value;
        const temp: { [key: string]: string } = {
          '1': 'Allow',
          '0': 'Not Allow',
        };
        return <CopySpan inGrid value={temp[`${data.shippingLabelRelease}`]} />;
      },
    },
    {
      header: 'Ship Cost',
      name: 'totalShippingCost',
      resizable: true,
      draggable: true,
      sortable: true,
      textAlign: 'end',
      headerAlign: 'center' as 'center',
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 27,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.totalShippingCost
                ? `${formatNum(data.totalShippingCost)}`
                : ''
            }
          />
        );
      },
      sort: (a: number, b: number) => a - b,
    },
    {
      header: 'Signature',
      name: 'signatureRequired',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 28,
      renderEnum: {
        '-2': 'N/A',
        '0': 'Adult',
        '1': 'InDirect',
        '2': 'Signature',
        '3': 'No',
        '4': 'Adult Restrict',
      },
      render: (value: any) => {
        const { data } = value;
        const temp: { [key: string]: string } = {
          '-2': 'N/A',
          '0': 'Adult',
          '1': 'InDirect',
          '2': 'Signature',
          '3': 'No',
          '4': 'Adult Restrict',
        };
        return <CopySpan inGrid value={temp[`${data.signatureRequired}`]} />;
      },
    },
    {
      header: 'B2T',
      name: 'billToType',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 29,
      render: (value: any) => {
        const { data } = value;
        return <BoolCell value={data.billToType === 2} />;
      },
    },
    {
      header: 'Verified By',
      name: 'smartVerifyUser',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 30,
    },
    {
      header: 'Verified Date',
      name: 'smartVerifyDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
      advanced: 31,
    },
    // {
    //   header: 'Ship to Name',
    //   name: 'shipToName',
    //   defaultVisible: false,
    //   advanced: 200,
    // },
    {
      header: 'Ship to Address',
      name: 'shipToAddressLine1',
      defaultVisible: false,
      adressColumn: true,
      advanced: 201,
    },
    {
      header: 'Ship to City',
      name: 'shipToCity',
      defaultVisible: false,
      adressColumn: true,
      advanced: 202,
    },
    {
      header: 'Ship to State',
      name: 'shipToState',
      defaultVisible: false,
      adressColumn: true,
      advanced: 203,
    },
    {
      header: 'Ship to Postal',
      name: 'shipToPostalCode',
      defaultVisible: false,
      adressColumn: true,
      advanced: 204,
    },
    {
      header: 'Ship to Phone',
      name: 'shipToDaytimePhone',
      defaultVisible: false,
      adressColumn: true,
      advanced: 205,
    },
    {
      header: 'Trace ID',
      name: 'traceId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 32,
    },
    {
      header: 'Fulfillment Order ID',
      name: 'fulfillmentOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 150,
      advanced: 33,
      render: (row: any) => {
        const { data } = row;
        return (
          <OrderDetailsCell cellText={data.fulfillmentOrderId} rowData={data} />
        );
      },
    },
  ];

  tempcolumns.push({
    header: 'Action',
    name: 'action',
    //@ts-ignore
    showColumnMenuTool: false,
    defaultLocked: 'end',
    sortable: false,
    minWidth: 100,
    maxWidth: 100,
    advanced: 1.5,
    defaultFlex: 1,
    render: (value: any) => {
      const { data, rowIndex } = value;
      const targetWarehouse = warehouses.find(warehouse => warehouse.warehouseNum === data.warehouseNum);
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Button
                  id="grid_shipment_detail_button"
                  type="text"
                  style={{ width: '100%', textAlign: 'left' }}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    setCurrent(data);
                    setShipmentDetailVisible(true);
                  }}
                >
                  Shipment Detail
                </Button>
              </Menu.Item>
              <Menu.Item key="2">
                <Button
                  id="grid_order_detail_button"
                  type="text"
                  style={{ width: '100%', textAlign: 'left' }}
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    setCurrent(data);
                    setDetailDialogVisible(true);
                  }}
                >
                  Order Detail
                </Button>
              </Menu.Item>
              <Menu.Item key="3">
                <Button
                  id="grid_order_mark_button"
                  type="text"
                  style={{ width: '100%', textAlign: 'left' }}
                  icon={<FileExcelOutlined />}
                  onClick={() => {
                    setCurrent(data);
                    setMarkDialogVisible(true);
                  }}
                >
                  Mark Shipped & Cancel & Hold
                </Button>
              </Menu.Item>
              {data.shippingLabelRelease === 0 &&
                data.shippingStatus === 1 &&
                data.shippingLabelRelease === 0 && (
                  <Menu.Item key="4">
                    <Button
                      id="grid_order_release_button"
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<FileDoneOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title:
                            'Are you sure you want to allow shipping label release?',
                          content: `Shipment ID: ${data.shipmentId}`,
                          okText: 'Yes',
                          okType: 'default',
                          // okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onCancel() { },
                          onOk() {
                            allowRelease(data);
                          },
                        });
                      }}
                    >
                      Shipping Label Release
                    </Button>
                  </Menu.Item>
                )}
              {(data.shippingStatus === 1 || data.shippingStatus === 16) && (
                <Menu.Item key="5">
                  <Button
                    id="grid_resend_erp_button"
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<FileSyncOutlined />}
                    onClick={() => {
                      resendErp(data);
                      // Modal.confirm({
                      //   title: 'Are you sure you want to resend to ERP?',
                      //   content: `Shipment ID: ${data.shipmentId}`,
                      //   okText: 'Yes',
                      //   okType: 'default',
                      //   // okButtonProps: { className: 'hover-danger-button' },
                      //   cancelText: 'No',
                      //   autoFocusButton: 'cancel',
                      //   onCancel() { },
                      //   onOk() {
                      //     resendErp(data);
                      //   },
                      // });
                    }}
                  >
                    Resend to ERP
                  </Button>
                </Menu.Item>
              )}
              {
                targetWarehouse && targetWarehouse.warehouseType === 5 && data.shippingStatus === 1 && (
                  <Menu.Item key="6">
                    <Button
                      id="grid_resend_3pl_warehouse_button"
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<FileSyncOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to resend to third party warehouse?',
                          content: `Shipment ID: ${data.shipmentId}`,
                          okText: 'Yes',
                          okType: 'default',
                          // okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onCancel() { },
                          onOk() {
                            resend3rdWarehouse(data);
                          },
                        });
                      }}
                    >
                      Resent to Third party warehouse
                    </Button>
                  </Menu.Item>
                )
              }
            </Menu>
          }
          trigger={['click']}
        >
          <Button
            id={`shipment_grid_action_row_${rowIndex}_dropdown`}
            icon={<SettingFilled />}
          >
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      );
    },
  });

  const columns = hasAddressPermissions.length > 0 ? [...tempcolumns] : tempcolumns.filter(i => !i.adressColumn)

  const columns2 = columns.filter((item) => !item.showInItems);
  const columns3 = columns.filter(
    (item) => item.advanced && item.advanced <= 17 && ![8, 12, 14, 15, 16].includes(item.advanced)
  );
  const columns4 = columns.filter(
    (item) =>
      item.advanced &&
      item.advanced <= 17 &&
      item.advanced !== 8 &&
      !item.showInItems
  );
  const columns5 = columns.filter((item) => !item.showInPrint && ![12, 14, 15, 16].includes(item.advanced));
  const columns6 = columns.filter(
    (item) => !item.showInPrint && !item.showInItems
  );
  const columns7 = columns.filter(
    (item) => !item.showInPrint && item.advanced <= 17 && ![8, 12, 14, 15, 16].includes(item.advanced)
  );
  const columns8 = columns.filter(
    (item) =>
      !item.showInPrint &&
      item.advanced <= 17 &&
      item.advanced !== 8 &&
      !item.showInItems
  );

  const allowRelease = async (data: any) => {
    try {
      setLoading(true);
      const res = await setShippingLabelRelease(data.shipmentNum);
      setLoading(false);
      if (res) {
        message.success({
          content: 'Allow shipping label release successfully',
        });
        handleSearch();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const resendErp = async (data: any) => {
    try {
      setLoading(true);
      const res = await resendtoErp(data.shipmentNum)
      setLoading(false)
      if (res.isSuccess) {
        message.success({
          content: 'Resend to ERP successfully',
        });
        handleSearch();
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const resend3rdWarehouse = async (data: any) => {
    try {
      setLoading(true);
      const res = await resync3pl(data.shipmentNum);
      if (res.isSuccess) {
        message.success({
          content: 'Resend to Third party warehouse successfully',
        });
        handleSearch();
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false)
    }
  }

  const resetStatus = async (data: any) => {
    try {
      setLoading(true);
      const res = await resetShipmentPending(data.shipmentNum);
      setLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Reset to pending successfully' });
        handleSearch();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCarrierList = async () => {
    try {
      const res = await fetchCarriers();
      setCarriers(res);
    } catch (error) { }
  };

  const getAllService = async (carrierNum: number) => {
    try {
      // setLoading(true);
      const res = await getCarrierServices(carrierNum);
      // setLoading(false);
      if (res.isSuccess) {
        setServiceList(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchShipAccountByWarehouse = async (id: number) => {
    try {
      setLoading(true);
      const res = await getShipAccountByWarehouse(id);
      setLoading(false);
      if (res.isSuccess) {
        setShipAccountList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportShippingLabel = async (data: any) => {
    if (data.shippingStatus === 1) {
      try {
        const params = {
          isBatch: false,
          ShipmentNum: data.shipmentNum,
          ShippingAccountNum: data.shippingAccountNum,
          ShipFrom: data.warehouseNum,
          //ShipFrom: warehouseId,
          //ShipFrom: 10032,
          PrinterAccountNum: 0,
          PrinterNum: 0,
          PrinterName: '0',
          Carrier: data.shippingCarrier,
          CarrierNum: data.shipCarrierNum,
          Confirmation: data.signatureRequired,
          Service: data.shippingService,
          PackageName: data.packageName,
          PackageType: data.packageCode,
          ShipPackageNum: data.shipPackageNum,
          ShipServiceNum: data.shipServiceNum,
          Height: data.height,
          Length: data.length,
          Weight: data.weight,
          Width: data.width,
          MasterAccountNum: data.masterAccountNum,
          ProfileNum: data.profileNum,
          InsuredValue: data.insuredValue,
          BillToType: data.billToType,
          BillThirdPartyAccount: data.billThirdPartyAccount,
          BillThirdPartyCountry: data.billThirdPartyCountry,
          BillThirdPartyPostalCode: data.billThrdPartyPostalcode,
          CN22: data.cN22,
        };
        setLoading(true);
        const res = await postExportShipmentLabelPdf(params);
        setLoading(false);
        const fileType = res.headers[`content-type`]
        const content = res.data;
        const blob = new Blob([content], { type: fileType });
        const fileName = fileType === 'application/pdf' ? `Shipment-${data.shipmentNum}.pdf` : `Shipment-${data.shipmentNum}.png`;
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      message.error({
        content: `shipment status(${statusKeyValue[data.shippingStatus]
          }) cann't be export.`,
      });
    }
  };

  const handleExportPackingSlip = async (slip: StringKAnyVPair) => {
    // console.log('slip', slip);
    setLockScreen(true);

    try {
      const res = await exportShipmentPackingSlip(
        slip.fulfillmentOrderNum,
        slip.shipmentNum,
        nowTime()
      );
      setLockScreen(false);
      const pdfData = new Blob([res], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(pdfData);

      window.open(fileURL, 'preview.pdf');
      /*let blob = new Blob([res, {type: 'application/pdf'}]);
      let file = window.URL.createObjectURL(blob);
      let a = document.createElement('a');

      a.href = file;
      a.download = res.name || 'pdf.pdf';
      a.target = '_blank';
      console.log(file);
      document.body.appendChild(a);
      a.click(); 

      setTimeout(() => {
        window.URL.revokeObjectURL(file);
        document.body.removeChild(a);
      }, 100);*/
    } catch (e) {
      setLockScreen(false);
      console.log(`export packing slip error ${e}`);
    }
  };

  const handleExportDocuments = async (documents: any, shipmentNum: number) => {
    setLockScreen(true);
    try {
      const res = await exportShipmentDocument(documents.blobName, shipmentNum);
      setLockScreen(false);
      const pdfData = new Blob([res], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(pdfData);

      window.open(fileURL, `${documents.fileName}.pdf`);
    } catch (e) {
      setLockScreen(false);
      console.log(`export document error ${e}`);
    }
  };

  const changeShowAdvancedFilters = () => {
    if (showAdvancedFilters) {
      const { BatchPrint, NeedSmartVerify, advancedKeyword, ShippingLabelRelease } =
        form.getFieldsValue();
      if (
        BatchPrint !== undefined ||
        NeedSmartVerify !== undefined ||
        advancedKeyword !== undefined ||
        ShippingLabelRelease !== undefined
      ) {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'The advanced filter will be reset',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            form.setFieldsValue({
              BatchPrint: undefined,
              NeedSmartVerify: undefined,
              advancedKeyword: undefined,
              ShippingLabelRelease: undefined,
            });
            setShowAdvancedFilters(false);
          },
        });
      } else {
        setShowAdvancedFilters(false);
      }
      return;
    }
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    setSelectedCarrier(undefined);
    setDateRangeAble(false);
    form.resetFields();
  }, [form]);

  const formatParams = (params: any) => {
    let str: string = (params.ExcludedSkus || '')
      .trim()
      .replace(/[\r\n]/g, ',')
      .replace(/,+/g, ',')
      .replace('/,$/gi', '');
    str =
      str.substring(str.length - 1) === ','
        ? str.substring(0, str.length - 1)
        : str;
    return {
      warehouseNum:
        params.WarehouseNum.warehouseNum || params.WarehouseNum.id,
      pageIndex: 1,
      pageSize: limitRef.current,
      Queued: params.Queued.value > -1 ? params.Queued.value : undefined,
      NeedSmartVerify: params.NeedSmartVerify
        ? params.NeedSmartVerify.value
        : undefined,
      ShippingLabelRelease: params.ShippingLabelRelease ? params.ShippingLabelRelease.value : undefined,
      BatchPrint: params.BatchPrint ? params.BatchPrint.value : undefined,
      orderFrom: params.OrderTime
        ? //? moment(params.OrderTime[0]).startOf('day').format()
        convertToUtcTime(params.OrderTime[0].startOf('day').format())
        : undefined,
      orderTo: params.OrderTime
        ? //? moment(params.OrderTime[1]).endOf('day').format()
        convertToUtcTime(params.OrderTime[1].endOf('day').format())
        : undefined,
      shipFrom: params.ShipTime
        ? //? moment(params.ShipTime[0]).format()
        convertToUtcTime(params.ShipTime[0].format())
        : undefined,
      shipTo: params.ShipTime
        ? //? moment(params.ShipTime[1]).format()
        convertToUtcTime(params.ShipTime[1].format())
        : undefined,
      // shippingCarrier: params.ShippingCarrier
      //   ? params.ShippingCarrier.value
      //   : undefined,
      Carrier: (params.ShippingCarrier || []).map((item: any) => item.value),
      // shippingService: params.ShippingService
      //   ? params.ShippingService.value
      //   : undefined,
      ShipService: (params.ShippingService || []).map((item: any) => item.value),
      shipAccountNum: params.ShipAccountNum
        ? params.ShipAccountNum.value
        : undefined,
      trackingNum: params.TrackingNumber,
      ChannelAccountName: (params.ChannelAccountName || []).map((item: any) => item.value),
      ChannelOrderId:
        keyWordType === 'channelOrderId' ? params.keyWord : undefined,
      SO: keyWordType === 'SO' ? params.keyWord : undefined,
      SecondChannelOrderId:
        keyWordType === '2ndChannelOrderId' ? params.keyWord : undefined,
      TraceId: keyWordType === 'traceId' ? params.keyWord : undefined,
      FulfillmentOrderId:
        keyWordType === 'fulfillmentOrderId' ? params.keyWord : undefined,
      SellerOrderId:
        keyWordType === 'sellerOrderId' ? params.keyWord : undefined,
      ShipmentId: keyWordType === 'shipmentId' ? params.keyWord : undefined,
      DivisionCode: advancedKeyWordType === 'DivisionCode' ? params.advancedKeyword : undefined,
      DepartmentCode:
        advancedKeyWordType === 'DepartmentCode'
          ? params.advancedKeyword
          : undefined,
      GroupCode:
        advancedKeyWordType === 'GroupCode'
          ? params.advancedKeyword
          : undefined,
      SubGroupCode:
        advancedKeyWordType === 'SubGroupCode'
          ? params.advancedKeyword
          : undefined,

      CentralOrderNum:
        keyWordType === 'centralOrderNum'
          ? params.keyWord || undefined
          : undefined,

      // ShippingStatus: params.Status ? params.Status.value : undefined,
      Status: (params.Status || []).map((item: any) => item.value),
      Sorts: sortId.current
        ? [
          {
            property: sortId.current,
            direction: sortStatus.current ? 'asc' : 'desc',
          },
        ]
        : [],
      SKU: params.SKU,
      Qty: params.Qty ? params.Qty.value : undefined,
      ShopAroundStatus: params.ShopAroundStatus
        ? params.ShopAroundStatus.value
        : undefined,
      StartCreateDate:
        params.CreateTime && !params.keyWord
          ? //? moment(params.CreateTime[0]).format()
          convertToUtcTime(params.CreateTime[0].format())
          : undefined,
      EndCreateDate:
        params.CreateTime && !params.keyWord
          ? //? moment(params.CreateTime[1]).format()
          convertToUtcTime(params.CreateTime[1].format())
          : undefined,
      ExcludedSkus: str ? str : undefined,
      Oper: oper,
      ShipToName: params.ShipToName,
      customerCode: params.customerCode
    };
  }

  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    setSelected({});
    setSelectedRow([]);
    const formParams = await form.validateFields();
    const params = trimValues(formParams);
    form.setFieldsValue({ ...params });
    getConditionColumns(params);
    setLockScreen(true);
    //setLoading(true);
    try {
      const queryparams = formatParams(params);
      setTempParams({ ...queryparams, searchType: searchFrom === 1 ? 1 : params.itemView ? 2 : 0 })
      if (skipRef.current !== 0) {
        skipRef.current = 0;
      }
      const res =
        searchFrom === 1
          ? await fetchShipmentsListFromHistory(queryparams)
          : params.itemView
            ? await fetchShipmentsListWithItem(queryparams)
            : await fetchShipmentsList(queryparams);

      const res1 =
        searchFrom !== 1 && params.itemView && res.data.data.length > 0
          ? await getInventoriesBySkus(
            getWarehouseIdFromCache(
              params.WarehouseNum.warehouseNum || params.WarehouseNum.id
            ),
            // limitRef.current,
            // skipRef.current,
            'sku',
            Array.from(
              new Set(res.data.data.map((i: any) => i.sku.trim()))
            ).toString()
          )
          : undefined;

      setLockScreen(false);

      if (res.isSuccess) {
        setGridType(params.itemView);
        if (res1 && res1.length > 0) {
          const tempData = res.data.data.map((i: any) => {
            const temp = res1.filter((item: any) => item.sku === i.sku);
            if (temp.length > 0) {
              return { ...i, stock: temp[0].quantity };
            } else {
              return { ...i };
            }
          });
          setShipments({ ...res.data, data: tempData });
        } else {
          setShipments(res.data);
        }
        // setShipments(res.data);
        setDataType(searchFrom);
        setTempParams({ ...queryparams, searchType: searchFrom === 1 ? 1 : params.itemView ? 2 : 0 })
        // skipRef.current = 0;
        //setLoading(false);
        setLockScreen(false);
      }
    } catch (error) {
      //setLoading(false);
      console.log('error', error);
      setLockScreen(false);
    }
    // eslint-disable-next-line
  }, [form, sortStatus, searchFrom, keyWordType, advancedKeyWordType, oper]);
  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      setLoading(true);
      setSelected({});
      setSelectedRow([]);
      try {
        const res =
          dataType === 1
            ? await fetchShipmentsListFromHistory({
              ...tempParams,
              searchType: undefined,
              pageIndex: skip / limit + 1,
              pageSize: limit,
            })
            : gridType
              ? await fetchShipmentsListWithItem({
                ...tempParams,
                searchType: undefined,
                pageIndex: skip / limit + 1,
                pageSize: limit,
              })
              : await fetchShipmentsList({
                ...tempParams,
                searchType: undefined,
                pageIndex: skip / limit + 1,
                pageSize: limit,
              });

        const res1 =
          dataType !== 1 && gridType && res.data.data.length > 0
            ? await getInventoriesBySkus(
              getWarehouseIdFromCache(tempParams.warehouseNum),
              // limitRef.current,
              // skipRef.current,
              'sku',
              Array.from(
                new Set(res.data.data.map((i: any) => i.sku.trim()))
              ).toString()
            )
            : undefined;

        if (res.isSuccess) {
          if (res1 && res1.length > 0) {
            const tempData = res.data.data.map((i: any) => {
              const temp = res1.filter((item: any) => item.sku === i.sku);
              if (temp.length > 0) {
                return { ...i, stock: temp[0].quantity };
              } else {
                return { ...i };
              }
            });
            setShipments({ ...res.data, data: tempData });
          } else {
            setShipments(res.data);
          }
          limitRef.current = limit;
          skipRef.current = skip;
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [tempParams, dataType, gridType]
  );

  // eslint-disable-next-line
  const validateShipment = (i: any, type: 'download' | 'generate', key: string) => {
    // let flag = true;
    let msg = '';
    if (i.warehouseNum !== Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))) {
      msg = `[${i.shipmentNum}] ${type} failed, does not belong to the default warehouse`;
    }
    if (i.shippingStatus !== 0 && type === 'generate') {
      msg = `[${i.shipmentNum}] ${type} failed, only supports pending status`;
    }
    if (i.shippingStatus !== 1 && type === 'download' && key !== 'downloadPackingSlip') {
      msg = `[${i.shipmentNum}] ${type} failed, only supports shipped status`;
    }
    if (i.shipCarrierNum === 0) {
      msg = `[${i.shipmentNum}] ${type} Export failed, shopping around is not supported`;
    }
    if (!i.weight) {
      msg = `[${i.shipmentNum}] ${type} failed, weight not set`;
    }
    if (!i.shipServiceNum) {
      msg = `[${i.shipmentNum}] ${type} failed, shipping service not set`;
      return false;
    }
    if (!i.shipPackageNum && i.shipPackageNum !== 0) {
      msg = `[${i.shipmentNum}] ${type} failed, shipping package not set`
    }
    // if (i.isPrintPackingSlip === 0 && ['downloadShippingLabelAndPackingSlip', 'downloadPackingSlip'].includes(key)) {
    //   msg = `No PackingSlip for Shipment: ${i.shipmentNum}`;
    // }
    if (msg) {
      // console.log('im here ', msg);
      message.error({ content: msg });
      return false;
    }
    return true;
  }

  const handleGenerateOrDownload = useCallback(async (i: any, type: 'download' | 'generate', key: string) => {
    // if (!validateShipment(i, type, key)) return false;
    try {
      if (type === 'generate') {
        const profile = userProfiles.profile;
        const pd = {
          isBatch: false,
          ShipmentNum: i.shipmentNum,
          ShippingAccountNum: i.shippingAccountNum,
          ShipFrom: parseInt(
            localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
          ),
          PrinterAccountNum: 0,
          PrinterNum: 0,
          PrinterName: '0',
          Carrier: i.shippingCarrier,
          CarrierNum: i.shipCarrierNum,
          Confirmation: i.deliveryConfirmation,
          Service: i.shippingServiceCode,
          PackageName: i.packageName,
          PackageType: i.packageCode,
          ShipPackageNum: i.shipPackageNum,
          ShipServiceNum: i.shipServiceNum,
          Height: i.height,
          Length: i.length,
          Weight: i.weight,
          Width: i.width,
          MasterAccountNum: profile.masterAccountNum,
          ProfileNum: profile.profileNum,
          InsuredValue: i.insuredValue,
          BillToType: i.billToType,
          BillThirdPartyAccount: i.billThirdPartyAccount,
          BillThirdPartyCountry: i.billThirdPartyCountry,
          BillThirdPartyPostalCode: i.billThrdPartyPostalcode,
          CN22: i.cN22,
        };

        // const res = await postExportShipmentLabelPdf(pd);
        // @ts-ignore
        const res = await SaleServices[key](pd);
        const fileType = res.headers[`content-type`]
        const content = res.data;
        const blob = new Blob([content], { type: fileType });
        const suffix = fileType === 'application/pdf' ? '.pdf' : '.png';
        let fileName = '';

        switch (key) {
          case 'generateNewShippingLabel':
            fileName = `Shipment - ${i.shipmentNum}${suffix}`;
            break;
          case 'generateNewShippingLabelAndPackingSlip':
            fileName = `PackingSlipWithShipment - ${i.shipmentNum}${suffix}`;
            break;
          default: break;
        }

        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        return true;
      }

      if (type === 'download') {
        // @ts-ignore
        const res = await SaleServices[key](i.shipmentNum);
        const fileType = res.headers[`content-type`]
        const content = res.data;
        const blob = new Blob([content], { type: fileType });
        const suffix = fileType === 'application/pdf' ? '.pdf' : '.png';
        let fileName = '';
        switch (key) {
          case 'downloadShippingLabel':
            fileName = `Shipment - ${i.shipmentNum}${suffix}`;
            break;
          case 'downloadShippingLabelAndPackingSlip':
            fileName = `PackingSlipWithShipment - ${i.shipmentNum}${suffix}`;
            break;
          case 'downloadPackingSlip':
            fileName = `PackingSlip - ${i.shipmentNum}${suffix}`;
            break;
          default: break;
        }

        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        return true;
      }
    } catch (error) {
      console.log('error = ', error);
    }
  }, []);

  const handleClickMoreItem = useCallback(async (type: 'download' | 'generate', key: string) => {
    let needRefresh: boolean = false;
    const temp = [...selectedRow];

    setLockScreen(true);
    try {
      await temp.reduce(async (memo, i) => {
        const result = await memo;
        // const isUpdated = await exportPdfItem(i);
        const isUpdated = await handleGenerateOrDownload(i, type, key);
        if (isUpdated) {
          needRefresh = true;
        }
        return result || isUpdated;
      }, true);
      if (needRefresh) {
        handleDataGridChange(limitRef.current, skipRef.current);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLockScreen(false);
    }
  }, [selectedRow, handleDataGridChange, handleGenerateOrDownload]);

  const handleRetryShopArround = useCallback(async () => {
    try {
      const temp = [...selectedRow];
      // if (temp.length === 0) {
      //   return message.info({
      //     content: 'The selected items are already in pending status',
      //   });
      // }
      setLoading(true);
      const res = await retryShopAround(temp.map((i) => i.shipmentNum));
      if (res.isSuccess) {
        message.success('Retry shopping around successfully.');
        handleDataGridChange(limitRef.current, skipRef.current);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [selectedRow, handleDataGridChange]);

  // const formItemLayout = {
  //   labelCol: {
  //     xs: { span: 24 },
  //     sm: { span: 8 },
  //   },
  //   wrapperCol: {
  //     xs: { span: 24 },
  //     sm: { span: 12 },
  //   },
  // };
  /* eslint-disable */

  const getConditionColumns = (formValues: any) => {
    const {
      keyWord,
      WarehouseNum,
      ShipTime = [],
      ShipToName,
      customerCode,
      SKU,
      OrderTime = [],
      CreateTime = [],
      ChannelAccountName = [],
      Status = [],
      ShippingCarrier = [],
      Qty = {},
      Queued = {},
      ShippingService = [],
      sortBy = {},
      TrackingNumber,
      ShopAroundStatus = {},
      ShipAccountNum = {},
      NeedSmartVerify = {},
      ShippingLabelRelease = {},
      BatchPrint = {},
      advancedKeyword,
      itemView,
    } = formValues;
    const shipDateStr =
      ShipTime && ShipTime[0]
        ? `${ShipTime[0].format('YYYY/MM/DD')} - ${ShipTime[1].format(
          'YYYY/MM/DD'
        )}`
        : undefined;
    const OrderDateStr =
      OrderTime && OrderTime[0]
        ? `${OrderTime[0].format('YYYY/MM/DD')} - ${OrderTime[1].format(
          'YYYY/MM/DD'
        )}`
        : undefined;
    const CreateDateStr =
      CreateTime && CreateTime[0]
        ? `${CreateTime[0].format('YYYY/MM/DD')} - ${CreateTime[1].format(
          'YYYY/MM/DD'
        )}`
        : undefined;
    const storeNameStr = ChannelAccountName.map((item: any) => item.label).join(
      ', '
    );
    const carrierStr = ShippingCarrier.map((item: any) => item.label).join(
      ', '
    );
    const statusStr = Status.map((item: any) => item.label).join(
      ', '
    );
    const serviceStr = ShippingService.map((item: any) => item.label).join(
      ', '
    );
    setConditionColumns([
      // @ts-ignore
      { label: KeyWordTypeMap[keyWordType], value: keyWord },
      { label: 'Warehouse', value: WarehouseNum.warehouseCode },
      { label: 'Ship Date', value: shipDateStr },
      { label: 'SKU / UPC', value: SKU },
      { label: 'Order Date', value: OrderDateStr },
      { label: 'Enter date', value: CreateDateStr },
      { label: 'Store Name', value: storeNameStr },
      { label: 'Status', value: statusStr },
      { label: 'Carrier', value: carrierStr },
      { label: 'Order Item(s) QTY', value: Qty.label ?? undefined },
      { label: 'Queued Status', value: Queued.label ?? undefined },
      { label: 'Shipping Service', value: serviceStr },
      { label: 'Sort By', value: sortBy.label ?? undefined },
      { label: 'Tracking Number', value: TrackingNumber ?? undefined },
      {
        label: 'Shopping Around Status',
        value: ShopAroundStatus.label ?? undefined,
      },
      { label: 'Item View', value: itemView ? 'Yes' : 'No' },
      { label: 'Search From', value: searchFrom === 0 ? 'Current' : 'History' },
      { label: 'Shipping Account', value: ShipAccountNum.label ?? undefined },
      {
        label: 'Smart Verify Require',
        value: NeedSmartVerify.label ?? undefined,
      },
      {
        label: 'Shipping Label Release',
        value: ShippingLabelRelease.label ?? undefined
      },
      { label: 'Batch Print', value: BatchPrint.label ?? undefined },
      {
        label: AdvancedKeyWordTypeMap[advancedKeyWordType],
        value: advancedKeyword,
      },
      { label: 'ShipToName', value: ShipToName },
      { label: 'Customer #', value: customerCode }
    ]);
  };

  useEffect(() => {
    getCarrierList();
  }, []);
  useEffect(() => {
    if (selectedCarrier || selectedCarrier === 0) {
      getAllService(selectedCarrier);
    }
  }, [selectedCarrier]);

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setData(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (keyWordType === 'fulfillmentOrderId' || keyWordType === 'centralOrderNum' || keyWordType === 'shipmentId') {
      setOper(0)
    }
  }, [keyWordType])


  /**
  * export file for import
  */
  const exportFileForImport = useCallback(
    async (SelectField) => {
      try {
        const job = await exportBusinessFile(11, {
          FilterCondition: {
            ShipmentNums: (selectedRow || []).map(shipment => shipment.shipmentNum)
          }
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, tempParams, sortId, gridType, selected, selectedRow]
  );

  const batchResend = useCallback(
    async () => {
      try {
        setLoading(true);
        const nums = (selectedRow || []).filter(j=>j.shippingStatus=== 1).map(shipment => shipment.shipmentNum)
        const res = await batchResendToErp(nums)
        setLoading(false)
        if (res.isSuccess) {
          message.success({
            content: 'Resend to ERP successfully',
          });
          handleSearch();
        }
      } catch (error) {
        setLoading(false)
      }
    },
    [selectedRow]
  );

  const sendToERP = useCallback(
    async () => {
      try {
        setSyncing(true);
        const nums = (selectedRow || []).map(shipment => shipment.shipmentNum)
        const res = await syncToErp(nums)
        setSyncing(false)
        if (res.isSuccess) {
          message.success({
            content: res.data,
          });
          handleSearch();
        }
      } catch (error) {
        setSyncing(false)
      }
    },
    [selectedRow]
  );

  /**
   * download all data
   */
  const downLoadAll = useCallback(
    async (SelectField) => {
      try {
        const job = await exportBusinessFile(10, {
          FilterCondition: {
            ...tempParams,
            pageIndex: undefined,
            pageSize: undefined,
            // searchType: dataType === 1 ? 1 : gridType ? 2 : 0,
            Sorts: undefined,
            // Documents: undefined
            timeZoneOffset: getLocaleTimezoneOffset(),
          },
          SelectField,
          OrderByField:
            tempParams.Sorts.length > 0
              ? `${tempParams.Sorts[0].property} ${tempParams.Sorts[0].direction}`
              : '',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, tempParams, sortId, gridType]
  );

  useEffect(() => {
    if(defaultStart && defaultEnd) return
    if (viewOrderEnterDateRange) {
      form.setFieldsValue({
        CreateTime: [
          moment().subtract(viewOrderEnterDateRange, 'days').startOf('days'),
          moment().add(1, 'days').endOf('days'),
        ],
      });
    }
  }, [viewOrderEnterDateRange, form]);

  

  const initSearch = useCallback(()=>{
    if(Number(defaultShopAroundStatus)) {
      form.setFieldsValue({
        ShopAroundStatus: Number(defaultShopAroundStatus),
      });
    }
    if(defaultStatus) {
      form.setFieldsValue({
        Status: defaultStatus,
      });
    }
    if(defaultStart && defaultEnd) {
      form.setFieldsValue({
        CreateTime: [
          moment(defaultStart),
          moment(defaultEnd),
        ],
      });
    }
  },[defaultStatus, defaultShopAroundStatus])

  useEffect(() => {
    if ((defaultStatus || defaultShopAroundStatus) && !init) {
      initSearch();
      handleSearch();
      setInit(true);
    }
  }, [defaultStatus, defaultShopAroundStatus, init, handleSearch, initSearch]);

  /* eslint-enable */

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };
  return (
    <>
      <HeaderTitle breadcrumb={['Sales Order', 'Shipments']} >
        <Space>
        <Button
            type="default"
            style={{ width: '100%', textAlign: 'left' }}
            // icon={<RestOutlined />}
            loading={syncing}
            onClick={sendToERP}
          >
            Sync last 10 shipments to ERP
          </Button>
        </Space>
      </HeaderTitle>
      <GreyCardWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SearchCollapse conditionColumns={conditionColumns} factor={factor}>
          <Form
            form={form}
            {...formItemLayout}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <ClassicCard>
              <Row>
                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 9 },
                      style: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                      },
                    }}
                    label={
                      <Select
                        style={{ padding: 0 }}
                        size="small"
                        bordered={false}
                        dropdownMatchSelectWidth={false}
                        value={keyWordType}
                        defaultValue="channelOrderId"
                        onChange={(value) => {
                          setKeyWordType(value as KeyWordType);
                          form.setFieldsValue({
                            keyWord: '',
                          });
                        }}
                      >
                        <Select.Option value="channelOrderId">
                          Channel Order ID
                        </Select.Option>
                        <Select.Option value="SO">Sales Order#</Select.Option>
                        <Select.Option value="2ndChannelOrderId">
                          2nd Channel Order ID
                        </Select.Option>
                        <Select.Option value="sellerOrderId">
                          Seller Order ID
                        </Select.Option>
                        <Select.Option value="traceId">Trace ID</Select.Option>
                        <Select.Option value="fulfillmentOrderId">
                          Fulfillment Order ID
                        </Select.Option>
                        <Select.Option value="shipmentId">
                          Shipment ID
                        </Select.Option>
                        <Select.Option value="centralOrderNum">
                          Central Order#
                        </Select.Option>
                      </Select>
                    }
                  >
                    <Form.Item noStyle name="keyWord">
                      <Input
                        addonBefore={
                          <Select
                            value={oper}
                            onChange={(value) => setOper(value)}
                            dropdownMatchSelectWidth={100}
                            disabled={
                              keyWordType === 'centralOrderNum' ||
                              keyWordType === 'fulfillmentOrderId' ||
                              keyWordType === 'shipmentId'
                            }
                          >
                            <Select.Option value={0}>equals</Select.Option>
                            <Select.Option value={1}>starts with</Select.Option>
                          </Select>
                        }
                        type="text"
                        onChange={(e) => {
                          const iptVal =
                            keyWordType === 'centralOrderNum'
                              ? e.target.value.replace(/\D/g, '')
                              : e.target.value;
                          form.setFieldsValue({
                            keyWord: iptVal,
                          });
                          setSearchLimit({ ...searchLimit, keyWord: iptVal });
                          if (iptVal) {
                            if (!dateRangeAble) {
                              setDateRangeAble(true);
                              form.setFieldsValue({
                                CreateTime: [undefined, undefined],
                              });
                            }
                          } else {
                            if (dateRangeAble) {
                              setDateRangeAble(false);
                              form.setFieldsValue({
                                CreateTime: [
                                  moment()
                                    .subtract(viewOrderEnterDateRange, 'day')
                                    .startOf('day'),
                                  moment().endOf('day'),
                                ],
                              });
                            }
                          }
                        }}
                        allowClear={
                          searchLimit.OrderTime ||
                          searchLimit.CreateTime ||
                          searchLimit.ShipTime
                        }
                      />
                    </Form.Item>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="WarehouseNum"
                    label={<FormLabel>Warehouse</FormLabel>}
                  >
                    <SelectWarehouse
                      defaultWarehouseNum={Number(dwarehouseNum)}
                      onChange={(warehouse) => {
                        form.setFieldsValue({
                          ShipAccountNum: undefined,
                          ShippingService: undefined,
                        });
                        fetchShipAccountByWarehouse(warehouse.id);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    //initialValue={[
                    //moment().startOf('day'),
                    //moment().endOf('day'),
                    //]}
                    name="ShipTime"
                    label={<FormLabel>Ship Date</FormLabel>}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00:00', 'hh:mm:ss'),
                          moment('23:59:59', 'hh:mm:ss'),
                        ],
                      }}
                      ranges={{
                        Today: [moment().startOf('day'), moment().endOf('day')],
                        Yesterday: [
                          moment().add(-1, 'day').startOf('day'),
                          moment().add(-1, 'day').endOf('day'),
                        ],
                        '2 days ago': [
                          moment().add(-2, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        '3 days ago': [
                          moment().add(-3, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        'This Week': [
                          moment().startOf('week'),
                          moment().endOf('week'),
                        ],
                        'Last Week': [
                          moment().add(-1, 'week').startOf('week'),
                          moment().add(-1, 'week').endOf('week'),
                        ],
                        'This Month': [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                      }}
                      format="MM/DD/YYYY hh:mm:ssA"
                      onChange={(e) =>
                        setSearchLimit({ ...searchLimit, ShipTime: e })
                      }
                      allowClear={
                        searchLimit.OrderTime ||
                        searchLimit.CreateTime ||
                        searchLimit.keyWord
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="SKU"
                    label={<FormLabel>SKU / UPC</FormLabel>}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="OrderTime"
                    label={<FormLabel>Order Date</FormLabel>}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      showTime
                      ranges={{
                        Today: [moment().startOf('day'), moment().endOf('day')],
                        Yesterday: [
                          moment().add(-1, 'day').startOf('day'),
                          moment().add(-1, 'day').endOf('day'),
                        ],
                        '2 days ago': [
                          moment().add(-2, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        '3 days ago': [
                          moment().add(-3, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        'This Week': [
                          moment().startOf('week'),
                          moment().endOf('week'),
                        ],
                        'Last Week': [
                          moment().add(-1, 'week').startOf('week'),
                          moment().add(-1, 'week').endOf('week'),
                        ],
                        'This Month': [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                      }}
                      format="MM/DD/YYYY"
                      onChange={(e) =>
                        setSearchLimit({ ...searchLimit, OrderTime: e })
                      }
                      allowClear={
                        searchLimit.ShipTime ||
                        searchLimit.CreateTime ||
                        searchLimit.keyWord
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="CreateTime"
                    initialValue={[
                      moment().subtract(1, 'day').startOf('day'),
                      moment().endOf('day'),
                    ]}
                    label={<FormLabel>Enter date</FormLabel>}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      disabled={dateRangeAble}
                      showTime={{
                        hideDisabledOptions: true,
                        defaultValue: [
                          moment('00:00:00', 'hh:mm:ss'),
                          moment('23:59:59', 'hh:mm:ss'),
                        ],
                      }}
                      ranges={{
                        Today: [moment().startOf('day'), moment().endOf('day')],
                        Yesterday: [
                          moment().add(-1, 'day').startOf('day'),
                          moment().add(-1, 'day').endOf('day'),
                        ],
                        '2 days ago': [
                          moment().add(-2, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        '3 days ago': [
                          moment().add(-3, 'day').startOf('day'),
                          moment().endOf('day'),
                        ],
                        'This Week': [
                          moment().startOf('week'),
                          moment().endOf('week'),
                        ],
                        'Last Week': [
                          moment().add(-1, 'week').startOf('week'),
                          moment().add(-1, 'week').endOf('week'),
                        ],
                        'This Month': [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                      }}
                      format="MM/DD/YYYY hh:mm:ssA"
                      onChange={(e) =>
                        setSearchLimit({ ...searchLimit, CreateTime: e })
                      }
                      allowClear={
                        searchLimit.ShipTime ||
                        searchLimit.OrderTime ||
                        searchLimit.keyWord
                      }
                    />
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="ChannelAccountName"
                    initialValue={[]}
                    label={<FormLabel>Store Name</FormLabel>}
                  >
                    <Select
                      labelInValue
                      mode="multiple"
                      placeholder="Please select"
                      // defaultValue={[]}
                      style={{ width: '100%' }}
                      optionFilterProp="label"
                      onChange={() => setFactor(!factor)}
                    >
                      {data
                        .filter((i) => i.hideFromSelection === 0)
                        .map((item) => (
                          <Select.Option
                            key={item.channelAccountNum}
                            value={item.channelAccountNum}
                            label={item.channelAccountName}
                          >
                            {item.channelAccountName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="Status"
                    label={<FormLabel>Status</FormLabel>}
                  >
                    <Select allowClear labelInValue mode="multiple" onChange={() => setFactor(!factor)}>
                      <Select.Option value={0}>Pending</Select.Option>
                      <Select.Option value={1}>Shipped</Select.Option>
                      {/* <Select.Option value={2}>Partially Shipped</Select.Option> */}
                      <Select.Option value={250}>Processing</Select.Option>
                      <Select.Option value={8}>Held</Select.Option>
                      <Select.Option value={9}>Printing</Select.Option>
                      <Select.Option value={10}>SmartVerify Ready</Select.Option>
                      <Select.Option value={16}>Cancelled</Select.Option>
                      <Select.Option value={32}>Abandoned</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="ShippingCarrier"
                    label={<FormLabel>Carrier</FormLabel>}
                  >
                    <Select
                      mode="multiple"
                      labelInValue
                      allowClear
                      onChange={e => {
                        // const { value } = option;
                        form.setFieldsValue({
                          ShippingService: undefined,
                          ShipAccountNum: undefined,
                        });
                        if ((e || []).length === 1) {
                          setSelectedCarrier(Number(e[0].value))
                        } else {
                          setSelectedCarrier(undefined);
                        }
                        setFactor(!factor);
                        // setSelectedShipAccount(undefined);
                        // setSelectedCarrier(Number(value));
                        // if()
                      }}
                    >
                      {carriers.map((item) => (
                        <Select.Option
                          key={item.carrierNum}
                          value={item.carrierNum}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="Qty"
                    initialValue={{ value: 0, label: 'No Limit' }}
                    label={<FormLabel>Order Item(s) QTY</FormLabel>}
                  >
                    <Select labelInValue>
                      <Select.Option value={0}>No Limit</Select.Option>
                      <Select.Option value={1}>Equal 1</Select.Option>
                      <Select.Option value={-1}>More than 1</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="Queued"
                    initialValue={{ value: -1, label: 'All' }}
                    label={<FormLabel>Queued Status</FormLabel>}
                  >
                    <Select labelInValue>
                      <Select.Option value={-1}>All</Select.Option>
                      <Select.Option value={1}>Queued</Select.Option>
                      <Select.Option value={0}>Non-queued</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="ShippingService"
                    label={<FormLabel>Shipping Service</FormLabel>}
                  >
                    <Select
                      mode="multiple"
                      labelInValue
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      disabled={!selectedCarrier && selectedCarrier !== 0}
                      onChange={() => setFactor(!factor)}
                    >
                      {serviceList.map((item, index) => (
                        <Select.Option
                          value={item.carrierServiceNum}
                          key={item.carrierServiceNum}
                          label={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="sortBy"
                    initialValue="createDate desc"
                    label={<FormLabel>Sort By</FormLabel>}
                  >
                    <Select
                      dropdownMatchSelectWidth={false}
                      allowClear
                      // labelInValue
                    >
                      <Select.Option value="createDate desc">
                        Create Time Descending
                      </Select.Option>
                      <Select.Option value="createDate asc">
                        Create Time Ascending
                      </Select.Option>
                      <Select.Option value="actualShipDate desc">
                        Ship Time Descending
                      </Select.Option>
                      <Select.Option value="actualShipDate asc">
                        Ship Time Ascending
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col lg={8} sm={24} md={12}>
                  <Form.Item
                    name="TrackingNumber"
                    label={<FormLabel>Tracking Number</FormLabel>}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {searchFrom === 0 && (
                  <Col lg={8} md={12} sm={24} style={{ paddingTop: 5 }}>
                    {/* <Form.Item
                    name="isShowItem"
                    // valuePropName="checked"
                    label={<FormLabel noColon>{''}</FormLabel>}
                  > */}
                    <Row justify="end" gutter={10}>
                      <Col>
                        <Checkbox
                          style={{ marginRight: 20 }}
                          checked={isShowAdvanced}
                          onChange={(e) => setIsShowAdvanced(e.target.checked)}
                        >
                          Advanced View
                        </Checkbox>
                      </Col>
                      <Col>
                        <Form.Item
                          name="itemView"
                          noStyle
                          valuePropName="checked"
                        >
                          <Checkbox>Item View</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Checkbox
                          checked={isShowPrint}
                          onChange={(e) => setIsShowPrint(e.target.checked)}
                        >
                          Show PDF Print
                        </Checkbox>
                      </Col>
                    </Row>
                    {/* </Form.Item> */}
                  </Col>
                )}
              </Row>

              <Row>
                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="ShopAroundStatus"
                    label={<FormLabel>Shopping Around Status</FormLabel>}
                  >
                    <Select allowClear labelInValue>
                      <Select.Option value={-1}>All</Select.Option>
                      <Select.Option value={0}>Pending</Select.Option>
                      <Select.Option value={1}>Success</Select.Option>
                      <Select.Option value={2}>Failed</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="ShipAccountNum"
                    label={<FormLabel>Shipping Account</FormLabel>}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      disabled={!form.getFieldValue('WarehouseNum')}
                      labelInValue
                    >
                      {selectedCarrier
                        ? carriers
                          .filter((i) => i.carrierNum === selectedCarrier)
                          .map((item) => (
                            <Select.OptGroup
                              label={item.name}
                              key={item.carrierNum}
                            >
                              {shipAccountList
                                .filter(
                                  (i) => i.carrierNum === item.carrierNum
                                )
                                .map((child) => (
                                  <Select.Option
                                    key={child.shipAccountNum}
                                    value={child.shipAccountNum}
                                    label={child.name}
                                  >
                                    {child.name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          ))
                        : carriers
                          .filter((i) => i.carrierNum !== 0)
                          .map((item) => (
                            <Select.OptGroup
                              label={item.name}
                              key={item.carrierNum}
                            >
                              {shipAccountList
                                .filter(
                                  (i) => i.carrierNum === item.carrierNum
                                )
                                .map((child) => (
                                  <Select.Option
                                    key={child.shipAccountNum}
                                    value={child.shipAccountNum}
                                    label={child.name}
                                  >
                                    {child.name}
                                  </Select.Option>
                                ))}
                            </Select.OptGroup>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="NeedSmartVerify"
                    label={<FormLabel>Smart Verify Require</FormLabel>}
                  >
                    <Select allowClear labelInValue>
                      <Select.Option value={-1}>All</Select.Option>
                      <Select.Option value={1}>Verified</Select.Option>
                      <Select.Option value={0}>Not Verified</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="ShippingLabelRelease"
                    label={<FormLabel>Shipping Label Release</FormLabel>}
                  >
                    <Select allowClear labelInValue>
                      <Select.Option value={1}>Allow</Select.Option>
                      <Select.Option value={0}>Not Allow</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="BatchPrint"
                    label={<FormLabel>Batch Print</FormLabel>}
                  >
                    <Select allowClear labelInValue>
                      <Select.Option value={-1}>All</Select.Option>
                      <Select.Option value={1}>With Verified</Select.Option>
                      <Select.Option value={0}>Without Verified</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    labelCol={{
                      xs: { span: 24 },
                      sm: { span: 9 },
                      style: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                      },
                    }}
                    label={
                      <Select
                        style={{ padding: 0 }}
                        size="small"
                        bordered={false}
                        dropdownMatchSelectWidth={false}
                        value={advancedKeyWordType}
                        onChange={(value) => {
                          setAdvancedKeyWordType(value as AdvancedKeyWordType);
                        }}
                      >
                        <Select.Option value="DivisionCode">
                          Division Code
                        </Select.Option>
                        <Select.Option value="DepartmentCode">
                          Department Code
                        </Select.Option>
                        <Select.Option value="GroupCode">
                          Group Code
                        </Select.Option>
                        <Select.Option value="SubGroupCode">
                          Subgroup Code
                        </Select.Option>
                      </Select>
                    }
                  >
                    <Form.Item noStyle name="advancedKeyword">
                      <Input type="text" allowClear />
                    </Form.Item>
                  </Form.Item>
                </Col>

                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="ExcludedSkus"
                    label={<FormLabel>Excluded SKU</FormLabel>}
                  >
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Col>
                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="ShipToName"
                    label={<FormLabel>Recipient Name</FormLabel>}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col
                  lg={8}
                  sm={24}
                  md={12}
                  style={{ display: showAdvancedFilters ? 'block' : 'none' }}
                >
                  <Form.Item
                    name="customerCode"
                    label={<FormLabel>Customer #</FormLabel>}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                justify="center"
                align="middle"
                gutter={16}
                style={{ paddingRight: 70 }}
              >
                <Col>
                  <Button
                    type="link"
                    onClick={() => changeShowAdvancedFilters()}
                  >
                    {showAdvancedFilters
                      ? 'Hide Advanced Filter'
                      : 'Show Advanced Filter'}
                  </Button>
                </Col>
                <Col>
                  <Form.Item
                    style={{ height: 32, margin: 0 }}
                    label={<FormLabel>Search From</FormLabel>}
                  >
                    <Radio.Group
                      style={{ width: 170 }}
                      defaultValue={0}
                      onChange={(e) => setSearchFrom(e.target.value)}
                    >
                      <Radio value={0} style={{ display: 'inline' }}>
                        Current
                      </Radio>
                      <Radio value={1} style={{ display: 'inline' }}>
                        History
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      const sortBy = form.getFieldValue('sortBy');
                      if (sortBy) {
                        const data = sortBy.split(' ');
                        sortId.current = data[0];
                        sortStatus.current = data[1] === 'asc' ? true : false;
                      } else {
                        sortId.current = '';
                        sortStatus.current = false;
                      }
                      handleSearch();
                    }}
                  >
                    <SearchOutlined />
                    Search
                  </Button>
                </Col>
                <Col>
                  <ExportCSV
                    disabled={!shipments || shipments.data.length === 0}
                    data={
                      shipments
                        ? gridType
                          ? shipments.data.map((i: any) => {
                            return { ...i, shippingQty: i.lineShippingQty };
                          })
                          : shipments.data
                        : []
                    }
                    showExportSelected
                    selectedRows={selectedRow}
                    columns={
                      gridType
                        ? columns.filter(
                          (item: any) =>
                            !item.showInPrint &&
                            item.name !== 'documents' &&
                            item.header !== 'Action' &&
                            item.name !== 'shippingLabelRelease'
                        )
                        : columns.filter(
                          (item: any) =>
                            !item.showInItems &&
                            !item.showInPrint &&
                            item.name !== 'documents' &&
                            item.header !== 'Action'
                        )
                    }
                    scope="Shipment"
                    formatColumns={{
                      shippingStatus: statusKeyValue,
                      packingSlipPrintStatus: {
                        '0': 'Not Printed',
                        '1': 'Printed',
                      },
                      signatureRequired: {
                        '-2': 'N/A',
                        '0': 'Adult',
                        '1': 'InDirect',
                        '2': 'Signature',
                        '3': 'No',
                        '4': 'Adult Restrict',
                      },
                      shopAroundStatus: shopAroundStatusKeyValue,
                      billToType: billToTypeKeyValue,
                    }}
                    downloadFromApi={(data) => downLoadAll(data)}
                  />
                </Col>
                <Col>
                  <ClearButton onClick={handleReset} />
                </Col>
              </Row>
            </ClassicCard>
          </Form>
        </SearchCollapse>
      </GreyCardWrapper>
      {shipments && (
        <GreyCardWrapper
          style={{
            marginTop: 20,
          }}
        >
          {(dataType !== 1 && !gridType) ? (
            <Row justify="space-between">
              <TablesFilter
                columns={columns}
                dataSource={shipments.data}
                setFilteredData={setFilteredData}
              />
              <Space>
                <Button
                  disabled={selectedRow.length === 0}
                  onClick={() => handleRetryShopArround()}
                >
                  Retry Shopping Around
                </Button>
                <Dropdown overlay={(
                  <Menu>
                    <Menu.Item key="generate_new_shipping_label">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<FilePdfOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={() => handleClickMoreItem('generate', 'generateNewShippingLabel')}
                      >
                        Generate New Shipping Label
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="generate_new_shipping_label_and_packing_slip">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<FilePdfOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={() => handleClickMoreItem('generate', 'generateNewShippingLabelAndPackingSlip')}
                      >
                        Generate New Shipping Label and Packing Slip
                      </Button>
                    </Menu.Item>

                    

                    {/* <Menu.Item key="resend_selected_shipment_to_erp">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<RestOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={sendToERP}
                      >
                        Send Shipment to ERP
                      </Button>
                    </Menu.Item> */}

                    
                    <Menu.Item key="download_shipping_label">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<DownloadOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={() => handleClickMoreItem('download', 'downloadShippingLabel')}
                      >
                        Download Shipping Label
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="download_shipping_label_and_packing_slip">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<DownloadOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={() => handleClickMoreItem('download', 'downloadShippingLabelAndPackingSlip')}
                      >
                        Download Shipping Label and Packing Slip
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="download_packing_slip">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<DownloadOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={() => handleClickMoreItem('download', 'downloadPackingSlip')}
                      >
                        Download Packing Slip
                      </Button>
                    </Menu.Item>
                    <Menu.Item key="export_file_for_import">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<DownloadOutlined />}
                        disabled={selectedRow.length === 0}
                        // onClick={() => handleClickMoreItem('download', 'downloadPackingSlip')}
                        onClick={exportFileForImport}
                      >
                        Export File for Import
                      </Button>
                    </Menu.Item>

                    <Menu.Item key="resend_selected_shipment_to_erp">
                      <Button
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        icon={<RestOutlined />}
                        disabled={selectedRow.length === 0}
                        onClick={batchResend}
                      >
                        Resend Selected Shipment to ERP
                      </Button>
                    </Menu.Item>
                  </Menu>
                )}>
                  <Button>
                    More
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </Row>
          ) : (
            <TablesFilter
              columns={columns}
              dataSource={shipments.data}
              setFilteredData={setFilteredData}
            />
          )}
          {isShowPrint && isShowAdvanced && gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              autoWith={false}
              showScrollButton
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              loading={loading}
              columns={columns.filter(item => ![8, 12, 14, 15, 16].includes(item.advanced))}
              idProperty="id"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {isShowPrint && isShowAdvanced && !gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              showScrollButton
              autoWith={false}
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              checkboxColumn={dataType !== 1}
              checkboxOnlyRowSelect={true}
              selected={selected}
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  shipments.data,
                  setSelected,
                  setSelectedRow,
                  'shipmentNum'
                )
              }
              loading={loading}
              columns={columns2}
              idProperty="shipmentNum"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {isShowPrint && !isShowAdvanced && gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              autoWith={false}
              showScrollButton
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              loading={loading}
              columns={columns3}
              idProperty="id"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {isShowPrint && !isShowAdvanced && !gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              showScrollButton
              autoWith={false}
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              checkboxColumn={dataType !== 1}
              checkboxOnlyRowSelect={true}
              selected={selected}
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  shipments.data,
                  setSelected,
                  setSelectedRow,
                  'shipmentNum'
                )
              }
              loading={loading}
              columns={columns4}
              idProperty="shipmentNum"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {!isShowPrint && isShowAdvanced && gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              autoWith={false}
              showScrollButton
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              loading={loading}
              columns={columns5}
              idProperty="id"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {!isShowPrint && isShowAdvanced && !gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              showScrollButton
              autoWith={false}
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              checkboxColumn={dataType !== 1}
              checkboxOnlyRowSelect={true}
              selected={selected}
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  shipments.data,
                  setSelected,
                  setSelectedRow,
                  'shipmentNum'
                )
              }
              loading={loading}
              columns={columns6}
              idProperty="shipmentNum"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {!isShowPrint && !isShowAdvanced && gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              autoWith={false}
              showScrollButton
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              loading={loading}
              columns={columns7}
              idProperty="id"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
          {!isShowPrint && !isShowAdvanced && !gridType && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_SHIPMENTS_COLS}
              name="locationReport"
              showScrollButton
              autoWith={false}
              skip={skipRef.current}
              count={shipments.count}
              dataSource={filteredData}
              onChange={(limit, skip) => handleDataGridChange(limit, skip)}
              pageSizes={[10, 50, 200, 500, 1000]}
              checkboxColumn={dataType !== 1}
              checkboxOnlyRowSelect={true}
              selected={selected}
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  shipments.data,
                  setSelected,
                  setSelectedRow,
                  'shipmentNum'
                )
              }
              loading={loading}
              columns={columns8}
              idProperty="shipmentNum"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )}
        </GreyCardWrapper>
      )}
      {productDialogVisible && current && (
        <ProductDetail
          visible
          onHide={() => setProductDialogVisible(false)}
          productId={current.productId || ''}
        />
      )}
      {shipmentDialogVisible && current && (
        <ManageShipmentDialog
          onClose={() => setShipmentDialogVisible(false)}
          orderId={current.channelOrderId}
          resetOrderShipment={() => { }}
          visible={shipmentDialogVisible}
          warehouseNum={current.warehouseNum}
        />
      )}
      {marKDialogVisible && current && (
        <MarkShippedDialog
          onHide={() => setMarkDialogVisible(false)}
          onRefresh={() => handleSearch()}
          shipmentInfo={current}
          visible
        />
      )}
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
      {detailDialogVisible && current && (
        <OrderDetail
          allowPopupWindow
          isHistory={dataType === 1}
          fulfillmentOrderId={current.fulfillmentOrderId}
          fulfillmentOrderNum={current.fulfillmentOrderNum}
          selectShipment={current.shipmentId}
          onClose={() => setDetailDialogVisible(false)}
        />
      )}
      {exportLablePdfVisible && (
        <ExportLabelPDF
          visible
          onClose={() => {
            setExportLablePdfVisible(false);
            handleDataGridChange(limitRef.current, skipRef.current);
          }}
          // onHide={()=>setExportLablePdfVisible(false)}
          onRefresh={() => { }}
          shipment={current}
          markVerified={true}
        />
      )}
      {shipmentDetailVisible && current && (
        <ShipmentDetail
          onClose={() => setShipmentDetailVisible(false)}
          shipmentInfo={current}
          visible
          isHistory={dataType === 1}
        />
      )}
    </>
  );
}
