import React, { useCallback, useEffect, useState } from 'react';
import { Form, message, Row, Col, Typography, Modal, Button } from 'antd';
// import { leaveEditForm } from 'utils';
import { addCustomPackage, updateCustomPackage } from 'services/shipment';
import {
  TextArea,
  Checkbox,
  InputNumber,
  Input,
  FormPlus,
  FormSubmitButton,
  FormLabel,
  SelectPackageType,
  UploadImage,
} from 'components/common';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

const RowPlus = styled(Row)`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const { Text } = Typography;
type PackageDialogProps = {
  shipmentPackage?: ShipmentPackageRow;
  packageTypes: any[];
  onHide: () => void;
  onSubmit: () => void;
};

const PackageDialog = (props: PackageDialogProps): JSX.Element => {
  const { onHide, shipmentPackage, onSubmit, packageTypes } = props;
  const [title, setTitle] = useState('');
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  // const [canScanBarcode, setCanScanBarcode] = useState<boolean>(false);
  const [snapshot, setSnapshot] = useState<StringKAnyVPair>({});
  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  useEffect(() => {
    if (shipmentPackage) {
      const currentSnapshot = {
        ...shipmentPackage,
        packageType: shipmentPackage.packageType,
        scanBarcode: shipmentPackage.scanBarcode === 1,
        usePictureToRemindUser: shipmentPackage.usePictureToRemindUser === 1,
        isCalculateWeight: shipmentPackage.isCalculateWeight === 0 ? true : false,
        isCalculateDimension: shipmentPackage.isCalculateDimension === 0 ? true : false,
      };
      form.setFieldsValue({
        ...currentSnapshot,
      });
      setSnapshot({
        ...currentSnapshot,
      });
      // setCanScanBarcode(shipmentPackage.scanBarcode === 1);
      setEdited(false);
      setTitle('Edit Package');
    } else {
      setEdited(true);
      setTitle('Create Package');
    }
  }, [shipmentPackage, form]);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    setSaveLoading(true);
    const {
      name,
      packageType,
      length,
      width,
      height,
      // lengthUnits,
      weight,
      // weightUnits,
      barcode,
      usePictureToRemindUser,
      packagePictureLink,
      scanBarcode,
      isCalculateWeight,
      isCalculateDimension,
    } = form.getFieldsValue();
    if (!name) {
      message.warning({ content: 'Please Input Name!' });
      setSaveLoading(false);
      return;
    }
    if (!length && length !== 0) {
      message.warning({ content: 'Please Input Length!' });
      setSaveLoading(false);
      return;
    }
    if (!width && width !== 0) {
      message.warning({ content: 'Please Input Width!' });
      setSaveLoading(false);
      return;
    }
    if (!height && height !== 0) {
      message.warning({ content: 'Please Input Height!' });
      setSaveLoading(false);
      return;
    }
    if (!weight && weight !== 0) {
      message.warning({ content: 'Please Input Weight!' });
      setSaveLoading(false);
      return;
    }
    // if (scanBarcode && !barcode) {
    //   message.warning({ content: 'Please Input barcode!' });
    //   setSaveLoading(false);
    //   return;
    // }
    //create ShipmentPackage
    if (!shipmentPackage) {
      const result = await addCustomPackage(
        parseInt(packageType, 10),
        name,
        length,
        width,
        height,
        // lengthUnits || 'inch',
        0,
        weight,
        // weightUnits || 'Oz',
        1,
        scanBarcode ? 1 : 0,
        barcode,
        packagePictureLink,
        usePictureToRemindUser ? 1 : 0,
        isCalculateWeight ? 0 : 1,
        isCalculateDimension ? 0 : 1
      );
      setSaveLoading(false);
      if (result) {
        message.success('Create Package Successfully!');
        onSubmit();
        onHide();
      } else {
        message.warning('Create Package Failed!');
      }
    } else {
      const result = await updateCustomPackage(
        shipmentPackage.shippingPackageNum,
        parseInt(packageType, 10),
        name,
        length,
        width,
        height,
        // lengthUnits || 'inch',
        0,
        weight,
        // weightUnits || 'Oz',
        1,
        scanBarcode ? 1 : 0,
        barcode,
        shipmentPackage.status,
        packagePictureLink,
        usePictureToRemindUser ? 1 : 0,
        isCalculateWeight ? 0 : 1,
        isCalculateDimension ? 0 : 1
      );
      setSaveLoading(false);
      if (result) {
        message.success('Update Package Successfully!');
        setEdited(false);
        // document.body.style.overflowY = 'unset';
        onSubmit();
      } else {
        message.warning('Update Package Failed!');
      }
    }
  }, [onHide, onSubmit, shipmentPackage, form]);
  /**
   * handle cancel event
   */
  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel || !edited) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      title={title}
      centered
      visible
      width={628}
      destroyOnClose
      maskClosable={false}
      onCancel={handleCancel}
      getContainer={false}
      // afterClose={() => {
      //   //fuck antd bug!
      //   setTimeout(() => {
      //     document.body.style.overflowY = 'unset';
      //   }, 0);
      // }}
      footer={
        <Row justify="space-between">
          <FormSubmitButton
            loading={saveLoading}
            changed={changed}
            edited={edited}
            onSubmit={handleSubmit}
            onEdit={() => {
              setEdited(true);
            }}
          />
          <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
        </Row>
      }
    >
      <Text>
        Please enter the details of your package below.This package will be
        available in addition to the standard packaging.Selecting this package
        will automatically apply the dimensions below.
      </Text>
      <FormPlus
        /* eslint-disable react/jsx-props-no-spreading */
        {...layout}
        form={form}
        snapshot={snapshot}
        onChanged={(ok: boolean) => {
          setChanged(ok);
        }}
      >
        <RowPlus>
          <div style={{ marginLeft: 16, marginRight: 10 }}>
            <FormLabel>Package Type</FormLabel>
          </div>
          <Form.Item name="packageType" style={{ marginBottom: 0 }}>
            <SelectPackageType
              id="packageType"
              packageTypes={packageTypes}
              edited={edited}
              outline={false}
              disabled={!edited}
              style={{ marginLeft: 3, width: 274 }}
            />
          </Form.Item>
        </RowPlus>
        <RowPlus>
          <div style={{ marginLeft: 66, marginRight: 10 }}>
            <FormLabel>Name</FormLabel>
          </div>
          <Form.Item name="name" style={{ marginBottom: 0, width: 274 }}>
            <Input id="name" autoFocus outline edited disabled={!edited} />
          </Form.Item>
        </RowPlus>
        <RowPlus>
          <div style={{ marginLeft: 77, marginRight: 12 }}>
            <FormLabel>Size</FormLabel>
          </div>
          <Form.Item name="length" style={{ marginBottom: 0 }}>
            <InputNumber
              id="length"
              outline
              edited
              min={0}
              step={0.1}
              precision={1}
              disabled={!edited}
              style={{ width: 80 }}
            />
          </Form.Item>
          <Text style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }}>
            L
          </Text>
          <Form.Item name="width" style={{ marginBottom: 0 }}>
            <InputNumber
              id="width"
              outline
              edited
              min={0}
              step={0.1}
              precision={1}
              disabled={!edited}
              style={{ width: 80 }}
            />
          </Form.Item>
          <Text style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }}>
            W
          </Text>
          <Form.Item name="height" style={{ marginBottom: 0 }}>
            <InputNumber
              id="height"
              outline
              edited
              min={0}
              step={0.1}
              precision={1}
              disabled={!edited}
              style={{ width: 80 }}
            />
          </Form.Item>
          <Text style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }}>
            H(in)
          </Text>
        </RowPlus>
        <RowPlus>
          <div style={{ marginLeft: 58, marginRight: 10 }}>
            <FormLabel>Weight</FormLabel>
          </div>
          <Form.Item name="weight" style={{ marginBottom: 0 }}>
            <InputNumber
              id="weight"
              outline
              edited
              min={0}
              step={0.1}
              precision={1}
              disabled={!edited}
              style={{ width: 80 }}
            />
          </Form.Item>
          <Text style={{ marginLeft: 3, marginRight: 3, fontWeight: 'bold' }}>
            Oz
          </Text>
        </RowPlus>
        <Form.Item
          name="isCalculateWeight"
          style={{ marginLeft: 120, marginBottom: -10 }}
        // valuePropName="checked"
        >
          <Checkbox id="isCalculateWeight" edited={edited}>
            Don't base on this package to calculate weight
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="isCalculateDimension"
          style={{ marginLeft: 120, marginBottom: -10 }}
        // valuePropName="checked"
        >
          <Checkbox id="isCalculateDimension" edited={edited}>
            Don't base on this package to calculate dimension
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="scanBarcode"
          style={{ marginLeft: 120, marginBottom: 0 }}
        // valuePropName="checked"
        >
          <Checkbox
            id="scanBarcode"
            edited={edited}
          // onChange={(value: any) => {
          //   setCanScanBarcode(value);
          //   if (!value) {
          //     form.setFieldsValue({
          //       isCalculateWeight: false,
          //       isCalculateDimension: false,
          //       barcode: '',
          //     });
          //   }
          // }}
          >
            Scan Barcode
          </Checkbox>
        </Form.Item>
        <RowPlus>
          <div style={{ marginLeft: 55, marginRight: 10 }}>
            <FormLabel>Barcode</FormLabel>
          </div>
          <Form.Item name="barcode" style={{ marginBottom: 0 }}>
            <TextArea
              id="barcode"
              rows={6}
              // edited={edited && canScanBarcode}
              disabled={!edited}
              outline
              placeholder="Enter Barcode"
            />
          </Form.Item>
        </RowPlus>
        <Col style={{ position: 'absolute', right: 10, bottom: 80 }}>
          <Form.Item name="usePictureToRemindUser" style={{ marginBottom: 0 }}>
            <Checkbox id="usePictureToRemindUser" edited={edited}>
              Use Picture to remind user
            </Checkbox>
          </Form.Item>
          <Form.Item name="packagePictureLink" style={{ marginBottom: 0 }}>
            <UploadImage edited={edited} style={{ marginTop: 5 }} />
          </Form.Item>
        </Col>
      </FormPlus>
    </Modal>
  );
};
PackageDialog.defaultProps = {
  shipmentPackage: undefined,
};
export default PackageDialog;
