import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Select } from 'antd';
import { DisconnectOutlined, LinkOutlined } from '@ant-design/icons';
import { message, LoadingIcon } from 'components/common';

import { Label } from 'components/common/styles';
import { RootState } from 'reducers';
import theme from 'assets/styles/theme';
import { fetchScaleList } from 'services/sales';

type SelectorProps = {
  allowClear?: boolean;
  onChange?: Function;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  value?: number;
};

// eslint-disable-next-line
export default (props: SelectorProps) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  // eslint-disable-next-line
  const [scaleOptions, setScaleOptions] = useState<Scale[]>([]);
  const [selectedValue, setSelectedValue] = useState(props.value || 0);
  const style = props.style || {};

  const scaleList: Scale[] = useSelector(
    (state: RootState) => state.sales.scaleList,
    shallowEqual
  );

  // eslint-disable-next-line
  const getScales = async () => {
    try {
      const scales = await fetchScaleList();
      console.log('scales', scales);

      setIsLoadingOptions(false);

      if (scales.length > 0) {
        setScaleOptions(
          scales.map((e) => ({
            approved: e.approved,
            clientId: e.clientId,
            connectAccountNum: e.connectAccountNum,
            instanceID: e.instanceID,
            masterAccountNum: e.masterAccountNum,
            name: e.name,
            note: e.note,
            online: e.online,
            pcName: e.pcName,
            profileNum: e.profileNum,
            scaleNum: e.scaleNum,
            status: e.status,
            unit: e.unit,
            venderId: e.venderId,
          }))
        );
      }
    } catch (e) {
      message.error(`Fetch scales error: ${  e}`);
    }
  };

  /**
   * The select option onChange handler.
   */
  const handleSelectScale = (val: number) => {
    let scale: Scale = {} as Scale;

    setSelectedValue(val);

    if (val && val > 0) {
      for (let i = 0; i < scaleList.length; i++) {
        if (val === scaleList[i].scaleNum) {
          scale = scaleList[i];
          break;
        }
      }
    }

    if ('function' === typeof props.onChange) {
      props.onChange(scale as Scale);
    }
  };

  const scaleSelectOptions = (options: Scale[]) => {
    return options.map((e) => (
      <Select.Option key={e.scaleNum} label={e.name} value={e.scaleNum}>
        {e.online ? (
          <LinkOutlined />
        ) : (
          <DisconnectOutlined style={{ color: theme['@danger-color'] }} />
        )}
        &nbsp;
        {e.name}
&nbsp;
        {e.scaleNum > 0 ? (
          <Label>
            (
            <span className="label-grey">{e.pcName}</span>
            )
          </Label>
        ) : (
          ''
        )}
        &nbsp;
        {e.scaleNum > 0 ? (
          <Label>
            (
            <span className="label-grey">
              {e.online ? 'Online' : 'Offline'}
            </span>
            )
          </Label>
        ) : (
          ''
        )}
      </Select.Option>
    ));
  };

  React.useEffect(() => {
    if (!inited) {
      //setIsLoadingOptions(true);
      //getScales();
      setInited(true);
    }
  }, [inited]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          allowClear={props.allowClear}
          onChange={handleSelectScale}
          size={props.size || 'middle'}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {scaleSelectOptions(scaleList)}
        </Select>
      )}
    </Row>
  );
};
