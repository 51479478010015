export const siteCfg = {
  'fulfillmentweb.z4.web.core.chinacloudapi.cn': {
    name: 'DigitBridge(dev) WMS',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'localhost:3000': {
    name: 'DigitBridge(L) WMS',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsappdev.digitbridge.com': {
    name: 'DigitBridge(dev) WMS',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsapppre.digitbridge.com': {
    name: 'DigitBridge(pre) WMS',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'wmsapp.digitbridge.com': {
    name: 'DigitBridge WMS',
    logo: '/imgs/LOGO-185X185.png',
    logo185: '/imgs/LOGO-185X185.png',
  },
  'dbgfuljfjpmanswdev.z19.web.core.windows.net': {
    name: 'JFJP(dev) WMS',
    logo: '/imgs/JFJP-logo.png',
    logo185: '/imgs/JFJP-logo.png',
  },
};
