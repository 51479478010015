import React from 'react';
import { isEqualObject } from 'crud-object-diff';
import { v4 as uuid } from 'uuid';
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  Modal,
  Row,
  //Select,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import {
  CloseCircleOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import theme from 'assets/styles/theme';
import {
  CellNumberInput,
  FormField,
  FormLabel,
  Loading,
  LoadingIcon,
  ScreenMask,
  message,
} from 'components/common';
import {
  InfoButton,
  Label,
} from 'components/common/styles';
import {
  FormRightAlign,
  FormVSpacer,
} from 'components/common/styles/CustomPropsComponents';
import { LOADING_ICON_SIZE1, SCAN_TO_SHIP_OZ_PRECISION } from 'constants/config';
import { calcShoppingAroundResult, fetchShipmentFlatRatePackages } from 'services/sales';
import {
  copyTextContent,
  getShippingToAddress,
  getWeightByLBOZ,
  getWeightLBOZ,
} from 'utils';
import { userProfiles } from 'utils/auth';
import FlatRateSelector from './FlatRateSelector';
import ServiceSelector from './ServiceSelector';
import {
  ShipmentWeightInputBoard,
  SizeInputRow,
  ShoppingAroundContainer,
  UnitLabel,
} from '../styles';

type Props = {
  close : Function;
  onSetShoppingAround ?: Function;
  packageHeight ?: number;
  packageLength ?: number;
  packageWidth ?: number;
  shipment : StringKAnyVPair;
  shipWeight : number;
  updateTag : string;
  visible : boolean;
  warehouseNum : number;
};

const ShoppingAroundDialog = (props : Props) => {
  const { useMemo, useState } = React;
  const { shipment, warehouseNum } = props;
  const FORM_COL1_WIDTH = 6;
  const FORM_COL2_WIDTH = 15;
  const numberInputHeight = 80;
  const [currentPropsWeight, setCurrentPropsWeight] = useState(0);
  const [currentShipment, setCurrentShipment] = useState<StringKAnyVPair>({});
  const [currentUpdateTag, setCurrentUpdateTag] = useState('');
  const [fedexRateEnable, setFedexRateEnable] = useState(false);
  const [fedexRateError, setFedexRateError] = useState(false);
  const [fedexRateErrorMessage, setFedexRateErrorMessage] = useState('');
  const [fedexShipCarrierNum, setFedexShipCarrierNum] = useState<number>();
  const [fedexShipPackageNum, setFedexShipPackageNum] = useState<number>();
  const [fedexWeightError, setFedexWeightError] = useState(false);
  const [fedexWeightErrorMessage, setFedexWeightErrorMessage] = useState('');
  const [fedexWeightLB, setFedexWeightLB] = useState<number>();
  const [fedexWeightOZ, setFedexWeightOZ] = useState<number>();
  const [flatRatePkgs, setFlatRatePkgs] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [inputLBValue, setInputLBValue] = useState<number>();
  const [inputOZValue, setInputOZValue] = useState<number>();
  const [inputWeightError, setInputWeightError] = useState(false);
  const [inputWeightErrorMessage, setInputWeightErrorMessage] = useState('');
  const [isLoadingFlatRatePkgs, setIsLoadingFlatRatePkgs] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [packageHeight, setPackageHeight] = useState<number>();
  const [packageHeightError, setPackageHeightError] = useState(false);
  const [packageHeightErrorMessage, setPackageHeightErrorMessage] = useState('');
  const [packageLength, setPackageLength] = useState<number>();
  const [packageLengthError, setPackageLengthError] = useState(false);
  const [packageLengthErrorMessage, setPackageLengthErrorMessage] = useState('');
  const [packageWidth, setPackageWidth] = useState<number>();
  const [packageWidthError, setPackageWidthError] = useState(false);
  const [packageWidthErrorMessage, setPackageWidthErrorMessage] = useState('');
  const [serviceError, setServiceError] = useState(false);
  const [serviceErrorMsg, setServiceErrorMessage] = useState('');
  const [serviceNum, setServiceNum] = useState(0);
  const [shoppingAroundResult, setShoppingAroundResult] = useState<StringKAnyVPair[]>([]);
  const [showResult, setShowResult] = useState(false);
  const [uspsRateEnable, setUspsRateEnable] = useState(false);
  const [uspsRateError, setUspsRateError] = useState(false);
  const [uspsRateErrorMessage, setUspsRateErrorMessage] = useState('');
  const [uspsShipCarrierNum, setUspsShipCarrierNum] = useState<number>();
  const [uspsShipPackageNum, setUspsShipPackageNum] = useState<number>();
  const [uspsWeightError, setUspsWeightError] = useState(false);
  const [uspsWeightErrorMessage, setUspsWeightErrorMessage] = useState('');
  const [uspsWeightLB, setUspsWeightLB] = useState<number>();
  const [uspsWeightOZ, setUspsWeightOZ] = useState<number>();
  const selectedShipmentNum = useMemo(() => {
    return shipment.shipmentNum || 0;
  }, [shipment]);

  const clearDialogStatus = () => {
    setCurrentPropsWeight(0);
    setFedexRateError(false);
    setUspsRateError(false);
    setShowResult(false);
    setInputLBValue(undefined);
    setInputOZValue(undefined);
    setPackageHeight(undefined);
    setPackageLength(undefined);
    setPackageWidth(undefined);
    setFedexRateEnable(false);
    setFedexWeightLB(undefined);
    setServiceNum(0);
    setFedexWeightOZ(undefined);
    setUspsRateEnable(false);
    setUspsWeightLB(undefined);
    setUspsWeightOZ(undefined);
  };

  const costColumnValue = (row: StringKAnyVPair) => {
    if (row.error) return (<Tooltip placement="top" title={row.error}>
        <Tag icon={<CloseCircleOutlined />} color="error">Error</Tag>
      </Tooltip>);
    if (row.internalError) return (<Tooltip placement="top" title={row.internalError}>
        <Tag icon={<CloseCircleOutlined />} color="error">Internal Error</Tag>
      </Tooltip>);
    if (!row.applicable) return 'Not Applicable';
    if (typeof row.totalCost === 'number' && row.totalCost >= 0) return (<Row align="middle" justify="end">
      {row.warning && <Tooltip placement="top" title={row.warning}>
        <Tag icon={<ExclamationCircleOutlined />} color="warning">Warning</Tag>
      </Tooltip>}
      <span>$ {row.totalCost}</span>
    </Row>);
  };

  const handleApplyResult = (result: StringKAnyVPair) => {
    if (typeof props.onSetShoppingAround === 'function') {
      props.onSetShoppingAround(result);
    }

    onCancel();
  };

  const handleShoppingAround = async () => {
    //console.log('shipment num', selectedShipmentNum);
    /*if (!packageLength) {
      setPackageLengthError(true);
      setPackageLengthErrorMessage('Valid length is required');
      return;
    }

    if (!packageWidth) {
      setPackageWidthError(true);
      setPackageWidthErrorMessage('Valid width is required');
      return;
    }

    if (!packageHeight) {
      setPackageHeightError(true);
      setPackageHeightErrorMessage('Valid height is required');
      return;
    }*/

    const weightIsOk = isFillWeight();

    if (weightIsOk < 0) {
      switch (weightIsOk) {
        case -1:
          setInputWeightError(true);
          setInputWeightErrorMessage('Please input valid weight');
          break;
      }

      return;
    }

    if (fedexRateEnable) {
      if (!fedexShipPackageNum) {
        setFedexRateError(true);
        setFedexRateErrorMessage('Please select package type');
        return;
      }

      if (!getWeightByLBOZ(fedexWeightLB, fedexWeightOZ)) {
        setFedexWeightError(true);
        setFedexWeightErrorMessage('Valid weight is needed');
        return;
      }
    }

    if (uspsRateEnable) {
      if (!uspsShipPackageNum) {
        setUspsRateError(true);
        setUspsRateErrorMessage('Please select package type');
        return;
      }

      if (!getWeightByLBOZ(uspsWeightLB, uspsWeightOZ)) {
        setUspsWeightError(true);
        setUspsWeightErrorMessage('Valid weight is needed');
        return;
      }
    }

    if (!serviceNum) {
      setServiceError(true);
      setServiceErrorMessage('Please set a Shop Around Service');
      return;
    }

    const packages : StringKAnyVPair[] = [];
    const profile = userProfiles.profile;
    const params = {
      "ShipmentNum": selectedShipmentNum,
      "ShoppingAroundServiceNum": serviceNum,
      "Packages": packages,
      "Length": packageLength,
      "Width": packageWidth,
      "Height": packageHeight,
      "Weight": getWeightByLBOZ(inputLBValue, inputOZValue),
      "MasterAccountNum": profile.masterAccountNum,
      "ProfileNum": profile.profileNum,
    };

    if (fedexRateEnable) {
      packages.push({
        ShipCarrierNum: fedexShipCarrierNum,
        ShipPackageNum: fedexShipPackageNum,
        Weight: getWeightByLBOZ(fedexWeightLB, fedexWeightOZ),
      });
    }

    if (uspsRateEnable) {
      packages.push({
        ShipCarrierNum: uspsShipCarrierNum,
        ShipPackageNum: uspsShipPackageNum,
        Weight: getWeightByLBOZ(uspsWeightLB, uspsWeightOZ),
      });
    }

    console.log('pp', params);
    setLockScreen(true);

    try {
      const res = await calcShoppingAroundResult(selectedShipmentNum, params);
      //console.log('--> -->', res);
      setLockScreen(false);

      /*if (res && typeof res === 'object') {
        setShoppingAroundResult(res);
      }*/
      if (Array.isArray(res)) {
        setShoppingAroundResult(res);
      }
    } catch (e) {
      setLockScreen(false);
      console.log(`Calculating shopping arround error ${e}`);
    }

    setShowResult(true);
  };

  const isFillWeight = () => {
    const inputWeight = getWeightByLBOZ(inputLBValue, inputOZValue);

    if (!fedexRateEnable && !uspsRateEnable) {
      if (inputWeight <= 0) return -1;
    }

    return 0;
  };

  const onFedexFlatRateChange = (pkg : StringKAnyVPair) => {
    //setPackageHeight(pkg.height);
    //setPackageLength(pkg.length);
    //setPackageWidth(pkg.width);
    setFedexShipCarrierNum(pkg.carrierNum);
    setFedexShipPackageNum(pkg.shippingPackageNum);
    setFedexRateError(false);
    setFedexRateErrorMessage('');

    if (!fedexRateEnable) {
      setFedexRateEnable(true);
    }

    /*if (typeof pkg.weight === 'number') {
      const [lb, oz] = getWeightLBOZ(pkg.weight);

      setFedexWeightLB(lb);
      setFedexWeightOZ(oz);
    }*/
  };

  const onUSPSFlatRateChange = (pkg : StringKAnyVPair) => {
    //setPackageHeight(pkg.height);
    //setPackageLength(pkg.length);
    //setPackageWidth(pkg.width);
    setUspsShipCarrierNum(pkg.carrierNum);
    setUspsShipPackageNum(pkg.shippingPackageNum);
    setUspsRateError(false);
    setUspsRateErrorMessage('');

    if (!uspsRateEnable) {
      setUspsRateEnable(true);
    }

    /*
    if (typeof pkg.weight === 'number') {
      const [lb, oz] = getWeightLBOZ(pkg.weight);

      setUspsWeightLB(lb);
      setUspsWeightOZ(oz);
    }*/
  };

  const onFedexRateChecked = (evt : any) => {
    setFedexRateEnable(evt.target.checked);
  };

  const onUspsRateChecked = (evt : any) => {
    setUspsRateEnable(evt.target.checked);
  };

  const onServiceChange = (service : StringKAnyVPair) => {
    setServiceNum(service.carrierServiceNum);
    setServiceError(false);
    setServiceErrorMessage('');
  };

  const onInputLBChange = (value : any) => {
    setInputLBValue(value as number);
    setFedexWeightLB(value as number);
    setUspsWeightLB(value as number);
    setInputWeightError(false);
    setInputWeightErrorMessage('');
  };

  const onInputOZChange = (value : any) => {
    setInputOZValue(value as number);
    setFedexWeightOZ(value as number);
    setUspsWeightOZ(value as number);
    setInputWeightError(false);
    setInputWeightErrorMessage('');
  };

  const onInputFedexLBChange = (value : any) => {
    setFedexWeightLB(value as number);
    setFedexWeightError(false);
    setFedexWeightErrorMessage('');
  };

  const onInputFedexOZChange = (value : any) => {
    setFedexWeightOZ(value as number);
    setFedexWeightError(false);
    setFedexWeightErrorMessage('');
  };

  const onInputUspsLBChange = (value : any) => {
    setUspsWeightLB(value as number);
    setUspsWeightError(false);
    setUspsWeightErrorMessage('');
  };

  const onInputUspsOZChange = (value : any) => {
    setUspsWeightOZ(value as number);
    setUspsWeightError(false);
    setUspsWeightErrorMessage('');
  };

  const onInputHeightChange = (value : number) => {
    setPackageHeight(value);
    setPackageHeightError(false);
    setPackageHeightErrorMessage('');
  };

  /**
   * Callback for changing package length.
   */
  const onInputLengthChange = (value : number) => {
    setPackageLength(value);
    setPackageLengthError(false);
    setPackageLengthErrorMessage('');
  };

  /**
   * Callback for changing package width.
   */
  const onInputWidthChange = (value : number) => {
    setPackageWidth(value);
    setPackageWidthError(false);
    setPackageWidthErrorMessage('');
  };

  const onCancel = () => {
    clearDialogStatus();
    props.close();
  };

  const shoppingCarrier = (result: StringKAnyVPair) => {
    const { shipAccountName, shippingCarrier, shippingService } = result;

    return [shippingCarrier, shipAccountName, shippingService].filter(e => e)
      .join(', ');
  };

  const trySetFlatRate = async (shipmentNum: number) => {
    setIsLoadingFlatRatePkgs(true);
    // when load flat rate, replace flat rate selector with loading
    try {
      const pkgs = await fetchShipmentFlatRatePackages(shipmentNum);

      if (Array.isArray(pkgs)) {
        console.log('fl ra pkgs', pkgs);
        setFlatRatePkgs(pkgs);
        // set fedex or usps flat rate value
      }
    } catch (e) {
      message.error(`Fetch shipment flat rate packages error: ${e}`);
    } finally {
      setIsLoadingFlatRatePkgs(false);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }

    //console.log('shipm', shipment);
    if (props.visible) {
      if (
        currentPropsWeight !== props.shipWeight &&
        props.shipWeight > 0
      ) {
        const [lb, oz] = getWeightLBOZ(props.shipWeight);

        onInputLBChange(lb);
        onInputOZChange(oz);
        setCurrentPropsWeight(props.shipWeight);
      }

      if (currentUpdateTag !== props.updateTag) {
        if (typeof props.packageHeight === 'number') setPackageHeight(props.packageHeight);
        if (typeof props.packageLength === 'number') setPackageLength(props.packageLength);
        if (typeof props.packageWidth === 'number') setPackageWidth(props.packageWidth);

        if (!isEqualObject(currentShipment, props.shipment)) {
          if (props.shipment.shipmentNum > 0) {
            trySetFlatRate(props.shipment.shipmentNum);
          }

          setCurrentShipment(props.shipment);
        }

        setCurrentUpdateTag(props.updateTag);
        console.log('update tag', props.updateTag, props.shipment);
      }
    }
  }, [
    currentPropsWeight,
    currentShipment,
    currentUpdateTag,
    inited,
    props,
  ]);

  return (
    <>
      <Modal
        bodyStyle={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          padding: 16,
        }}
        centered
        destroyOnClose
        footer={null}
        maskClosable={false}
        okButtonProps={{}}
        onCancel={onCancel}
        title={
          <Label theme={{ fontSize: '20px' }}>Shop Around</Label>
        }
        visible={props.visible}
        width={960}
      >
        <ShoppingAroundContainer>
          <Row className="shipping-addr-row" align="middle">
            <FormLabel fontSize={16}>Shipping Address</FormLabel>
            <span>&nbsp;&nbsp;{getShippingToAddress(shipment)}</span>
            &nbsp;&nbsp;
            <CopyOutlined
              onClick={() => copyTextContent(getShippingToAddress(shipment), 'Copied shipping address successfully')}
              style={{color: theme['@info-color']}}
            />
          </Row>
          <FormVSpacer />
          <ShipmentWeightInputBoard justify="center">
            <Space>
              <FormField error={inputWeightError}>
                <CellNumberInput
                  align="center"
                  fontSize={48}
                  height={numberInputHeight}
                  min={0}
                  onChange={onInputLBChange}
                  paddingleft={4}
                  value={inputLBValue}
                />
              </FormField>
              <span className="weight-unit">(LB)</span>
              <FormField error={inputWeightError}>
                <CellNumberInput
                  align="center"
                  fontSize={48}
                  height={numberInputHeight}
                  min={0}
                  onChange={onInputOZChange}
                  paddingleft={4}
                  precision={SCAN_TO_SHIP_OZ_PRECISION}
                  value={inputOZValue}
                />
              </FormField>
              <span className="weight-unit">(OZ)</span>
            </Space>
          </ShipmentWeightInputBoard>
          {inputWeightError && <Row>
            <span className="error-msg">{inputWeightErrorMessage}</span>
          </Row>}
          <FormVSpacer />
          <Row align="middle">
            <Col span={FORM_COL1_WIDTH}>
              <FormRightAlign>
                <FormLabel>Size</FormLabel>
              </FormRightAlign>
            </Col>
            <Col span={FORM_COL2_WIDTH}>
              <SizeInputRow align="middle">
                <Col style={{ width: '31%' }}>
                  <Space size={0}>
                    <FormField error={packageLengthError} errorMessage={packageLengthErrorMessage}>
                      <InputNumber
                        min={0}
                        onChange={onInputLengthChange}
                        style={{ width: '100%' }}
                        value={packageLength}
                      />
                    </FormField>
                    <UnitLabel>L</UnitLabel>
                  </Space>
                </Col>
                <Col style={{ width: '31%' }}>
                  <Space size={0}>
                    <FormField error={packageWidthError} errorMessage={packageWidthErrorMessage}>
                      <InputNumber
                        min={0}
                        onChange={onInputWidthChange}
                        style={{ width: '100%' }}
                        value={packageWidth}
                      />
                    </FormField>
                    <UnitLabel>W</UnitLabel>
                  </Space>
                </Col>
                <Col style={{ width: '38%' }}>
                  <Space size={0}>
                    <FormField error={packageHeightError} errorMessage={packageHeightErrorMessage}>
                      <InputNumber
                        min={0}
                        onChange={onInputHeightChange}
                        style={{ width: '100%' }}
                        value={packageHeight}
                      />
                    </FormField>
                    <UnitLabel>H(in)</UnitLabel>
                  </Space>
                </Col>
              </SizeInputRow>
            </Col>
          </Row>
          <hr />
          <Row align="middle">
            <Col className="carrier-field-label">
              <Checkbox
                checked={fedexRateEnable}
                onChange={onFedexRateChecked}
              >
                Can be put into this box and use FedEx Flat Rate to ship
              </Checkbox>
            </Col>
            <Col className="carrier-field-selector">
              <FormField error={fedexRateError} errorMessage={fedexRateErrorMessage}>
                {isLoadingFlatRatePkgs ?
                  <LoadingIcon /> :
                  <FlatRateSelector
                    carrierNum={1}
                    disabled={!fedexRateEnable}
                    flatRatePkgs={flatRatePkgs}
                    onChange={onFedexFlatRateChange}
                    warehouseNum={warehouseNum}
                  />
                }
              </FormField>
            </Col>
            <Col className="carrier-field-weight-wrapper">
              <Row>
                <Col className="carrier-field-weight">
                  <Space size={0}>
                    <FormField error={fedexWeightError}>
                      <InputNumber
                        disabled={!fedexRateEnable}
                        min={0}
                        onChange={onInputFedexLBChange}
                        style={{ width: '100%' }}
                        value={fedexWeightLB}
                      />
                    </FormField>
                    <UnitLabel>LB</UnitLabel>
                  </Space>
                </Col>
                <Col className="carrier-field-weight">
                  <Space size={0}>
                    <FormField error={fedexWeightError}>
                      <InputNumber
                        disabled={!fedexRateEnable}
                        min={0}
                        onChange={onInputFedexOZChange}
                        style={{ width: '100%' }}
                        value={fedexWeightOZ}
                      />
                    </FormField>
                    <UnitLabel>OZ</UnitLabel>
                  </Space>
                </Col>
              </Row>
              {fedexWeightError && <Row>
                <span className="error-msg">{fedexWeightErrorMessage}</span>
              </Row>}
            </Col>
          </Row>
          <FormVSpacer />
          <Row align="middle">
            <Col className="carrier-field-label">
              <Checkbox
                checked={uspsRateEnable}
                onChange={onUspsRateChecked}
              >
                Can be put into this box use USPS Flat Rate to ship
              </Checkbox>
            </Col>
            <Col className="carrier-field-selector">
              <FormField error={uspsRateError} errorMessage={uspsRateErrorMessage}>
                {isLoadingFlatRatePkgs ?
                  <LoadingIcon /> :
                  <FlatRateSelector
                    carrierNum={3}
                    disabled={!uspsRateEnable}
                    flatRatePkgs={flatRatePkgs}
                    onChange={onUSPSFlatRateChange}
                    warehouseNum={warehouseNum}
                  />
                }
              </FormField>
            </Col>
            <Col className="carrier-field-weight-wrapper">
              <Row>
                <Col className="carrier-field-weight">
                  <Space size={0}>
                    <FormField error={uspsWeightError}>
                      <InputNumber
                        disabled={!uspsRateEnable}
                        min={0}
                        onChange={onInputUspsLBChange}
                        style={{ width: '100%' }}
                        value={uspsWeightLB}
                      />
                    </FormField>
                    <UnitLabel>LB</UnitLabel>
                  </Space>
                </Col>
                <Col className="carrier-field-weight">
                  <Space size={0}>
                    <FormField error={uspsWeightError}>
                      <InputNumber
                        disabled={!uspsRateEnable}
                        min={0}
                        onChange={onInputUspsOZChange}
                        style={{ width: '100%' }}
                        value={uspsWeightOZ}
                      />
                    </FormField>
                    <UnitLabel>OZ</UnitLabel>
                  </Space>
                </Col>
              </Row>
              {uspsWeightError && <Row>
                <span className="error-msg">{uspsWeightErrorMessage}</span>
              </Row>}
            </Col>
          </Row>
          {/*<FormVSpacer />
          <Row align="middle">
            <Col className="carrier-field-label">
              <Checkbox>Can use UPS Flat Rate to ship</Checkbox>
            </Col>
          </Row>*/}
          <hr />
          <Row align="middle">
            <FormLabel>Order Request Shipping Service</FormLabel>
            <span className="label-field">{shipment.requestService}</span>
          </Row>
          <hr />
          <Row justify="space-between">
            <Space>
              <FormLabel>Shop Around Service</FormLabel>
              <FormField error={serviceError} errorMessage={serviceErrorMsg}>
                <ServiceSelector
                  onChange={onServiceChange}
                  shopAroundServiceNum={shipment.shopAroundServiceNum || 0}
                  style={{ width: 150 }}
                  warehouseNum={warehouseNum}
                />
              </FormField>
            </Space>
            <InfoButton type="primary" onClick={handleShoppingAround}>Shop Around</InfoButton>
          </Row>
          <FormVSpacer />
          {showResult && (shoppingAroundResult.map((e, i) => (<React.Fragment key={uuid()}>
            <FormVSpacer />
            <Row align="middle" className="shopping-around-result-row">
              <Col span={9}>
                <span className="shopping-around-result-label">{shoppingCarrier(e)}</span>
              </Col>
              <Col span={6} style={{textAlign: 'center'}}>
                <span className="shopping-around-result-label">{e.packageName}</span>
              </Col>
              <Col span={4} style={{textAlign: 'right'}}>
                <span className="shopping-around-result-label">{costColumnValue(e)}</span>
              </Col>
              <Col span={5} style={{textAlign: 'right'}}>
                {i === 0 && <Button
                  disabled={e.error || !e.applicable}
                  onClick={() => handleApplyResult(e)}
                  type="primary"
                >
                  Use This Service
                </Button>}
                {i > 0 && <Button
                  disabled={e.error || !e.applicable}
                  ghost
                  onClick={() => handleApplyResult(e)}
                  type="primary"
                >
                  Use This Service
                </Button>}
              </Col>
            </Row>
          </React.Fragment>)))}
        </ShoppingAroundContainer>
      </Modal>
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default ShoppingAroundDialog;
