import React from 'react';
import { Modal, Row, Button, Space } from 'antd';
import {CheckCircleTwoTone} from '@ant-design/icons'

type Props = {
  onOk: (key:string) => void;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {onOk } = props;

  return (
    <Modal
      centered
      visible={true}
      width={500}
      title={<Row justify="center" align="middle"> <CheckCircleTwoTone style={{marginRight: 8}} twoToneColor="#52c41a"  size={16}/> Restock Successfully</Row>}
      destroyOnClose={true}
      getContainer={false}
      closable={false}
      footer={null}
      maskClosable={false}
    >
        <Row justify="space-between">
            <Space>
                <Button onClick={()=> onOk('details')}>Details</Button>
            </Space>
            <Space>
                <Button type="primary" onClick={()=> onOk('restock')}>Restock</Button>
                <Button onClick={()=> onOk('receive')}>Receive</Button>
                <Button onClick={()=> onOk('complete')}>Complete</Button>
            </Space>
        </Row>
    </Modal>
  );
};
