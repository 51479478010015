import React, { useCallback, useEffect, useState } from 'react';
import {
  Spin,
  Modal,
  Button,
  Space,
  Checkbox,
  Row,
  Col,
  Typography,
  TimePicker,
} from 'antd';
import { message } from 'components/common';
import {
  fetchServiceListByShipAccount,
  saveServiceListByShipAccount,
} from 'services/ship';
import { getCarrierServices } from 'services/shipment';
import moment from 'moment';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface CreateLotProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  account: ShipAccountRow;
  carrierKeyValue: any;
}
const range = (start: number, end: number) => {
  const arr = [];
  let i = start;
  while (i < end) {
    arr.push(i);
    i++;
  }
  return arr;
};
const ShippingOptionsDialog = (props: CreateLotProps) => {
  const { visible, onHide, onRefresh, account, carrierKeyValue } = props;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [serviceList, setServiceList] = useState<any[] | undefined>();
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [checkedValues1, setCheckedValues1] = useState<string[]>([]);
  const [checkedValues2, setCheckedValues2] = useState<string[]>([]);
  const [checkedValues3, setCheckedValues3] = useState<string[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [carrierServices, setCarrierServices] = useState<any[]>([]);

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      const res = await saveServiceListByShipAccount(
        carrierServices.map((item, index: number) => {
          return {
            ShipAccountNum: account.shipAccountNum,
            Name: item.name,
            CarrierServiceNum: item.carrierServiceNum,
            Status: checkedValues.indexOf(item.code) > -1 ? 1 : 0,
            isDisplay: checkedValues1.indexOf(item.code) > -1 ? 1 : 0,
            saturdayasBusinessDay:
              checkedValues2.indexOf(item.code) > -1 ? 1 : 0,
            nextBusinessDayStartTime: times[index],
            enableOneRate: checkedValues3.indexOf(item.code) > -1 ? 1 : 0,
          };
        })
      );

      setLoading(false);

      if (res.isSuccess) {
        message.success({ content: 'Saved Successfully' });
        onRefresh();
        onHide();
      } else {
        message.error({ content: 'Saved Failed' });
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    checkedValues,
    checkedValues1,
    checkedValues2,
    checkedValues3,
    times,
    carrierServices,
    account,
    onHide,
    onRefresh,
  ]);

  const getCarrierService = useCallback(async () => {
    try {
      setLoading1(true);
      const res = await getCarrierServices(account.carrierNum);
      setLoading1(false);
      if (res.isSuccess) {
        setCarrierServices(res.data);
      }
    } catch (error) {
      setLoading1(false);
    }
  }, [account]);

  const getServices = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchServiceListByShipAccount(account.shipAccountNum);

      setLoading(false);
      if (res.isSuccess) {
        setServiceList(res.data);
        setCheckedValues(
          res.data
            .filter((item: any) => item.status)
            .map((i: any) => i.carrierServiceCode)
        );
        setCheckedValues1(
          res.data
            .filter((item: any) => item.status && item.isDisplay)
            .map((i: any) => i.carrierServiceCode)
        );
        setCheckedValues2(
          res.data
            .filter((item: any) => item.status && item.saturdayasBusinessDay)
            .map((i: any) => i.carrierServiceCode)
        );
        setCheckedValues3(
          res.data
            .filter((item: any) => item.status && item.enableOneRate)
            .map((i: any) => i.carrierServiceCode)
        );
      }
    } catch (error) {
      setLoading(false);
    }
  }, [account]);

  /* eslint-disable */
  useEffect(() => {
    getServices();
    getCarrierService();
  }, []);

  useEffect(() => {
    if (carrierServices.length > 0 && serviceList) {
      const temp = carrierServices.map((item) => {
        const tempService = serviceList?.filter(
          (i) => i.carrierServiceCode === item.code
        );
        return tempService.length > 0
          ? tempService[0].nextBusinessDayStartTime
          : item.nextBusinessDayStartTime;
      });
      setTimes(temp);
    }
  }, [serviceList, carrierServices]);
  /* eslint-enable */

  return (
    <Modal
      title={`Shipping Options (${carrierKeyValue[`${account.carrierNum}`]
        } Services)`}
      centered
      visible={visible}
      maskClosable={false}
      width={1340}
      destroyOnClose
      onCancel={onHide}
      getContainer={false}
      bodyStyle={{ overflow: 'auto' }}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="shipping_option_save_button"
              key="submit"
              type="primary"
              onClick={handleSave}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
            <Button id="shipping_option_cancel_button" onClick={onHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading || loading1} style={{ alignSelf: 'center' }}>
        {serviceList && carrierServices && (
          <Row>
            <Col style={{ width: 300 }}>
              <Text strong>Service Name</Text>
            </Col>
            <Col style={{ textAlign: 'center', width: 70 }}>
              <Text strong>Enabled</Text>
            </Col>
            <Col style={{ textAlign: 'center', width: 200 }}>
              <Text strong>Display to scan to ship</Text>
            </Col>

            <Col style={{ textAlign: 'center', width: 250 }}>
              <Text strong>Staturday as Bussiness day</Text>
            </Col>

            <Col style={{ textAlign: 'center', width: 140 }}>
              <Text strong>Enable OneRate</Text>
            </Col>

            <Col style={{ textAlign: 'center', width: 250 }}>
              <Text strong>Next Business Day start time</Text>
            </Col>

            <Col style={{ width: 300 }}>
              {carrierServices.map((item, index) => (
                <Row>
                  <Col span={24} style={{ marginBottom: 6.8, marginTop: 1.24 }}>
                    <Text
                      id={`name_row_${index}`}
                      key={`name${item.carrierServiceNum}`}
                    >
                      {item.name}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col
              style={{
                textAlign: 'center',
                marginBottom: 6.8,
                marginTop: 1.24,
                width: 70,
              }}
            >
              <Checkbox.Group
                // style={{ width: 55 }}
                defaultValue={serviceList.map(
                  (item) => item.carrierServiceCode
                )}
                onChange={(checked) => {
                  const temp = checked.map((item) => `${item}`);
                  const prevValues = [...checkedValues];
                  const isAdd = temp.length > prevValues.length;
                  const tempItem = isAdd
                    ? temp.filter((i) => prevValues.indexOf(i) < 0)[0]
                    : prevValues.filter((i) => temp.indexOf(i) < 0)[0];
                  setCheckedValues(temp);
                  isAdd
                    ? setCheckedValues1((prev) => [...prev, tempItem])
                    : setCheckedValues1((prev) => {
                      let arr = [...prev];
                      if (arr.indexOf(tempItem) > -1) {
                        arr.splice(
                          arr.findIndex((item: string) => item === tempItem),
                          1
                        );
                      }
                      return arr;
                    });
                  isAdd
                    ? setCheckedValues3((prev) => [...prev, tempItem])
                    : setCheckedValues3((prev) => {
                      let arr = [...prev];
                      if (arr.indexOf(tempItem) > -1) {
                        arr.splice(
                          arr.findIndex((item: string) => item === tempItem),
                          1
                        );
                      }
                      return arr;
                    });
                  // setCheckedValues1(checked.map((item) => `${item}`));
                }}
              >
                <Row>
                  {carrierServices.map((item, index) => (
                    <Col
                      span={24}
                      style={{ marginBottom: 6.8, marginTop: 1.24 }}
                      key={`row${item.carrierServiceNum}`}
                    >
                      <Checkbox
                        id={`enable_row_${index}`}
                        key={item.carrierServiceNum}
                        value={item.code}
                      ></Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col style={{ textAlign: 'center', width: 200 }}>
              <Checkbox.Group
                // style={{ width: '100%' }}
                value={checkedValues1}
                // defaultValue={serviceList.map(
                //   (item) => item.carrierServiceCode
                // )}
                onChange={(checked) =>
                  setCheckedValues1(checked.map((item) => `${item}`))
                }
              >
                <Row>
                  {carrierServices.map((item, index) => (
                    <Col
                      span={24}
                      style={{ marginBottom: 6.8, marginTop: 1.24 }}
                      key={`displayrow${item.carrierServiceNum}`}
                    >
                      <Checkbox
                        id={`display_to_scan_to_ship_row_${index}`}
                        disabled={checkedValues.indexOf(item.code) < 0}
                        key={`display${item.carrierServiceNum}`}
                        value={item.code}
                      ></Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col style={{ textAlign: 'center', width: 250 }}>
              <Checkbox.Group
                // style={{ width: '100%' }}
                value={checkedValues2}
                // defaultValue={serviceList.map(
                //   (item) => item.carrierServiceCode
                // )}
                onChange={(checked) =>
                  setCheckedValues2(checked.map((item) => `${item}`))
                }
              >
                <Row>
                  {carrierServices.map((item, index) => (
                    <Col
                      span={24}
                      style={{ marginBottom: 6.8, marginTop: 1.24 }}
                      key={`displayrow2${item.carrierServiceNum}`}
                    >
                      <Checkbox
                        id={`staturday_as_busssiness_day_row_${index}`}
                        disabled={checkedValues.indexOf(item.code) < 0}
                        key={`display2${item.carrierServiceNum}`}
                        value={item.code}
                      ></Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col style={{ textAlign: 'center', width: 140 }}>
              <Checkbox.Group
                // style={{ width: '100%' }}
                value={checkedValues3}
                // defaultValue={serviceList.map(
                //   (item) => item.carrierServiceCode
                // )}
                onChange={(checked) =>
                  setCheckedValues3(checked.map((item) => `${item}`))
                }
              >
                <Row>
                  {carrierServices.map((item, index) => (
                    <Col
                      span={24}
                      style={{ marginBottom: 6.8, marginTop: 1.24 }}
                      key={`displayrow3${item.carrierServiceNum}`}
                    >
                      <Checkbox
                        id={`enable_one_rate_row_${index}`}
                        disabled={checkedValues.indexOf(item.code) < 0}
                        key={`display3${item.carrierServiceNum}`}
                        value={item.code}
                      ></Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col style={{ textAlign: 'center', width: 250 }}>
              {times.map((item: string, index: number) => (
                <Row justify="center">
                  <TimePicker
                    id={`next_bussiness_day_start_time_row_${index}`}
                    onChange={(time) =>
                      setTimes((prev) => {
                        const temp = [...prev];
                        temp[index] = moment(time).format('HH:mm');
                        return [...temp];
                      })
                    }
                    disabledHours={() => range(0, 9)}
                    disabledMinutes={(h) => (h <= 9 ? range(0, 30) : [])}
                    use12Hours
                    showNow={false}
                    style={{ marginBottom: 6 }}
                    size="small"
                    disabled={
                      checkedValues.indexOf(carrierServices[index].code) < 0
                    }
                    value={moment(item, 'HH:mm')}
                    format="h:mm a"
                  />
                </Row>
              ))}

              {/* <Checkbox.Group
                // style={{ width: '100%' }}
                value={checkedValues2}
                // defaultValue={serviceList.map(
                //   (item) => item.carrierServiceCode
                // )}
                onChange={(checked) =>
                  setCheckedValues2(checked.map((item) => `${item}`))
                }
              >
                <Row>
                  {carrierServices.map((item) => (
                    <Col span={24} key={`displayrow3${item.carrierServiceNum}`}>
                      <Checkbox
                        disabled={checkedValues.indexOf(item.code) < 0}
                        key={`display3${item.carrierServiceNum}`}
                        value={item.code}
                      ></Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group> */}
            </Col>
          </Row>
          // <Checkbox.Group
          //   style={{ width: '100%' }}
          //   defaultValue={serviceList.map((item) => item.carrierServiceCode)}
          //   onChange={(checked) =>
          //     setCheckedValues(checked.map((item) => `${item}`))
          //   }
          // >
          //   <Row>
          //     {carrierServices.map((item) => (
          //       <Col span={18} key={item.carrierServiceNum}>
          //         <Checkbox key={item.carrierServiceNum} value={item.code}>
          //           {item.name}
          //         </Checkbox>
          //       </Col>
          //     ))}
          //   </Row>
          // </Checkbox.Group>
        )}
      </Spin>
    </Modal>
  );
};

export default ShippingOptionsDialog;
