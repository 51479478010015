import React, { useCallback, useState } from 'react';
import { Form, Row, Modal, Button, Space, InputNumber } from 'antd';
import { FormLabel } from 'components/common';
import { message } from 'components/common';
import { editClaimTotalQty } from 'services/return';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onSave: (data: any) => void;
  data: any;
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onSave, data } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSave = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const res = await editClaimTotalQty(data.fulfillmentBulkReturnNum, params.ClaimTotalQty);
      setLoading(false);
      if (res.isSuccess) {
        onSave(params.ClaimTotalQty);
        message.success({ content: `Saved successfully` });
      }
    } catch (error) {
      setLoading(false);
    }
  }, [data, form, onSave]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title={`Edit Claimed Total Qty`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              loading={loading}
              type="primary"
              onClick={handleSave}
            >
              <SaveOutlined />
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      {data && (
        <Form {...layout} form={form}>
          <Form.Item
            name="ClaimTotalQty"
            initialValue={data.claimTotalQty}
            label={<FormLabel fontSize={20}>Claimed Total Qty</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Please input Claimed Total Qty!',
              },
            ]}
          >
            <InputNumber
              size="large"
              min={0}
              precision={0}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
