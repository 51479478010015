/**
 * route rule service requests are here.
 */
import { digitBridgeApi } from './http';


export const getRuteRules = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/routeRules');
}

export const addRouteRules = async (params: any): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/routeRules', json);
}

export const deleteRouteRules = async (id: string): Promise<boolean> => {
  return await digitBridgeApi.delete(`/api/routeRules/${id}`);
}

export const getRuleByID = async (id: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/routeRules/${id}`);
}

export const editRouteRules = async (id: string, params: any): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.patch(`/api/routeRules/${id}`, json);
}

type RouteSortParams = {
  RouteRuleNum: number;
  Seq: number;
}[]

export const sortRouteRules = async (params: RouteSortParams): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.put('/api/routeRules/sorts', json);
}