import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../common/ModalDialog';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { FormLabel, message } from '../common';
import {
  Card,
  Tabs,
  Form,
  Typography,
  Button,
  Result,
  Modal,
  Space,
  Row
} from 'antd';
import {
  getThirdPartySettingDefs,
  getThirdPartySettings,
  saveThirdPartySettings,
} from 'services/warehouse';
import { userProfiles } from 'utils/auth';
import moment from 'moment';
import { isJsonString } from 'utils';
import uiTypeRender from 'utils/uiTypeRender';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

interface Props extends ModalProps {
  onClose: Function;
  warehouseInfo: WarehouseRow;
}

const ThirdPartySettingDialog = (props: Props) => {
  const { warehouseInfo } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [activeTab, setActiveTab] = useState<string>();
  const [settings, setSettings] = useState<any[]>([]);
  const [userSetting, setUserSetting] = useState<{ [key: string]: any }>({});

  const [form] = Form.useForm();

  const getSettings = async () => {
    try {
      setLoading(true);
      const res = await getThirdPartySettingDefs(warehouseInfo.warehouseId);
      setLoading(false);
      if (res.isSuccess) {
        setSettings(res.data);
        if (!activeTab && res.data.length > 0) {
          setActiveTab(res.data[0].category);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const get3rdWarehouseSettings = async () => {
    try {
      setLoading1(true);
      const res = await getThirdPartySettings(warehouseInfo.warehouseId);
      setLoading1(false);
      if (res.isSuccess) {
        const temp: { [key: string]: any } = {};
        res.data.map((item: any) => {
          temp[item.settingCode] = item.settingValue;
          return true;
        });
        setUserSetting({ ...temp, isReady: true });
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const saveSettings = async () => {
    const { masterAccountNum, profileNum } = userProfiles.profile;
    const params = await form.validateFields();
    const postParams = [];
    for (let i in params) {
      if (params[i]) {
        postParams.push({
          SettingCode: i,
          SettingValue: params[i],
          MasterAccountNum: masterAccountNum,
          ProfileNum: profileNum,
        });
      }
    }
    try {
      setSaveLoading(true);
      const res = await saveThirdPartySettings(
        warehouseInfo.warehouseId,
        postParams
      );
      setSaveLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Saved Successfully' });
        get3rdWarehouseSettings();
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  const closeProductDetailDialog = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: { className: 'hover-danger-button' },
        content: `Changes you made may not be saved.`,
        onCancel() {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  /* eslint-disable */
  useEffect(() => {
    getSettings();
    get3rdWarehouseSettings();
  }, []);
  /* eslint-enable */

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        // footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Third Party Setting (Warehouse: ${warehouseInfo.code})`}
        visible={props.visible}
        width={detailDialogWidth()}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
                Cancel
              </Button>
              <Button
                type="primary"
                loading={saveLoading}
                onClick={saveSettings}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Space>
        </Row>
        }
      >
        <div style={{ height: '100%', width: '100%' }}>
          <Form
            style={{ height: '100%' }}
            form={form}
            labelCol={{
              style: { width: 300, display: 'flex', justifyContent: 'start' },
            }}
            wrapperCol={{
              style: { width: 'calc(100% - 300px)' },
            }}
          >
            <Card
              style={{ width: '100%', height: '100%' }}
              loading={loading || loading1}
              bodyStyle={{ padding: 8, height: '100%' }}
            >
              {settings.length > 0 ? (
                userSetting &&
                userSetting.isReady &&
                activeTab && (
                  <Tabs
                    activeKey={activeTab}
                    size="middle"
                    type="card"
                    onChange={(value) => setActiveTab(value)}
                    style={{ height: '100%' }}
                  >
                    {settings.map((i: any) => (
                      <TabPane
                        tab={i.category}
                        key={i.category}
                        style={{ height: '100%' }}
                      >
                        {i.scopes.map((m: any, idx2: number) => (
                          <Card
                            key={`${m.scope}${idx2}`}
                            size="small"
                            bordered={false}
                            style={{ height: '100%' }}
                            title={<Title level={5}>{m.scope}</Title>}
                          >
                            {m.scopeItems.map((f: any, index: number) => {
                              return !f.isVisibleByCustomer ? null : (
                                <div key={`${f.settingCode}${index}`}>
                                  <Form.Item
                                    name={f.settingCode}
                                    initialValue={
                                      f.thirdPartySettingDefUI.uiType === 3 ||
                                      f.thirdPartySettingDefUI.uiType === 11
                                        ? moment(userSetting[f.settingCode])
                                        : f.thirdPartySettingDefUI.uiType === 7
                                        ? isJsonString(
                                            userSetting[f.settingCode]
                                          )
                                          ? JSON.parse(
                                              userSetting[f.settingCode]
                                            )
                                          : []
                                        : f.thirdPartySettingDefUI.uiType === 12
                                        ? undefined
                                        : userSetting[f.settingCode]
                                    }
                                    label={
                                      <FormLabel noColon>
                                        {f.settingName}
                                      </FormLabel>
                                    }
                                    rules={[
                                      {
                                        required:
                                          f.thirdPartySettingDefUI.uiType ===
                                            12 && userSetting[f.settingCode]
                                            ? false
                                            : true,
                                        message: `${f.settingName} is required`,
                                      },
                                    ]}
                                  >
                                    {uiTypeRender(
                                      f.thirdPartySettingDefUI,
                                      f.canBeModifiedByCustomer
                                    )}
                                  </Form.Item>
                                  {f.note && (
                                    <Text
                                      style={{ marginLeft: 20 }}
                                      type="secondary"
                                    >
                                      {f.note}
                                    </Text>
                                  )}
                                </div>
                              );
                            })}
                          </Card>
                        ))}
                      </TabPane>
                    ))}
                  </Tabs>
                )
              ) : (
                <Result
                  status="error"
                  title="Failed to extract configuration data."
                  subTitle="Please contact the system administrator."
                ></Result>
              )}
            </Card>
          </Form>
        </div>
      </ModalDialog>
    </>
  );
};

export default ThirdPartySettingDialog;
