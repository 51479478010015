/**
 * prepack service requests are here.
 */
import { digitBridgeApi, digitBridgePrinterApi } from './http';
import { validateHttpStatus } from 'utils';

export const fetchPrepackSettings = async (): Promise<any> => await digitBridgeApi.get('/api/prepacksetting');

export type PackItemLine = {
  fulfillmentPackLineNum: number,
  fulfillmentPackNum: number,
  prepackSKU: any,
  sku: any,
  productTitle: any,
  prepackFulfillmentProductNum: number,
  fulfillmentProductNum: number,
  requestQty: number,
  caseSerialNumber: any,
  lotNumber: any,
  enterDate: string,
  databaseNum: number,
  masterAccountNum: number,
  profileNum: number,
  createBy: string,
  createDate: string,
  updateBy: string,
  updateDate: string,
  pickedQty: number,
  pickStatus: number,
  pickedBy: any
  upc: any;
  parentLine: any,
  componentQuantity: number,
}

export type PackItem = {
  fulfillmentPackNum: 1,
  packCode: string,
  warehouseNum: number,
  warehouseCode: string,
  description: string,
  note: string,
  startDate: string,
  endDate: string,
  requestQty: number,
  packQty: number,
  status: number,
  syncStatus: number,
  syncInfo: string,
  enterDate: string,
  databaseNum: number,
  masterAccountNum: number,
  profileNum: number,
  createBy: string,
  createDate: string,
  updateBy: string,
  updateDate: string,
  line: PackItemLine,
  warehouseId: string,
}

export type updatePrepackSettingsParams = {
  prepackSettingNum: number;
  packOverPercentage: number;
  unpackOverPercentage: number;
  enableCSN: number;
  csnTotalLength: number;
  csnPrefix: string;
  csnNumber: number;
  csnSuffix: string;
  packCSNRequired: number;
  masterAccountNum: number;
  profileNum: number;
}
export const updatePrepackSettings = async (params: updatePrepackSettingsParams): Promise<any> => {
  const formatParams = {
    PrepackSettingNum: params.prepackSettingNum,
    PackOverPercentage: params.packOverPercentage,
    UnpackOverPercentage: params.unpackOverPercentage,
    EnableCSN: params.enableCSN,
    CSNTotalLength: params.csnTotalLength,
    CSNPrefix: params.csnPrefix,
    CSNNumber: params.csnNumber,
    CSNSuffix: params.csnSuffix,
    PackCSNRequired: params.packCSNRequired,
    MasterAccountNum: params.masterAccountNum,
    ProfileNum: params.profileNum,
  }
  const json = JSON.stringify(formatParams)
  return await digitBridgeApi.post('/api/prepacksetting', json);
}

type QuaryPackListParams = {
  $skip: number;
  $top: number;
  $sortBy: string;
  warehouseNum?: number;
  startDate?: string;
  endDate?: string;
  prepackSKU?: string;
  childSKU?: string;
  oper?: number;
  status?: number;
}

export const queryPackList = async (params: QuaryPackListParams): Promise<any> => {
  return await digitBridgeApi.get('/api/prepack/packs', { params });
};

export const getPackLines = async (fulfillmentPackNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/prepack/packs/${fulfillmentPackNum}/lines`);
};

export const cancelPack = async (fulfillmentPackNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/prepack/packs/${fulfillmentPackNum}/cancel`);
};

export const completePack = async (fulfillmentPackNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/prepack/packs/${fulfillmentPackNum}/complete`);
};

export const confirmPack = async (fulfillmentPackNum: number, fulfillmentPackLineNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/prepack/packs/${fulfillmentPackNum}/lines/${fulfillmentPackLineNum}/confirm`);
};


type CeatePackProcessLineParams = {
  caseSerialNumber: string,
  prepackSKU: string,
  childSKU: string,
  packQty: 0
}

export const ceatePackProcessLine = async (fulfillmentPackNum: number, params: CeatePackProcessLineParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/prepack/packs/${fulfillmentPackNum}/process`, json);
};

type ExprotpackPickListParams = {
  FulfillmentPackNum: number,
  PrintTemplateNum: number,
  MasterAccountNum: number,
  ProfileNum: number
}

type ExprotpackDetailParams = {
  FulfillmentPackNum: number,
  PrintTemplateNum: number,
  MasterAccountNum: number,
  ProfileNum: number,
  Lines: { [key: string]: any },
}

export const exportPackPickListPDF = async (params: ExprotpackPickListParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/prepack/picklist/export', json, { responseType: 'blob' });
};

export const previewPackPickList = async (params: ExprotpackPickListParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('api/prepack/pickList/preview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};

export const exportPackDetailPDF = async (params: ExprotpackDetailParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/prepack/label/export', json, { responseType: 'blob' });
};

export const previewPackDetailList = async (params: ExprotpackDetailParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('api/prepack/label/preview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};

export const getPackByItemNum = async (itemNum: string): Promise<any> => await digitBridgeApi.get(`/api/prepack/packs/${itemNum}`);

type PickToPackParams = {
  WarehouseNum: number;
  LocationNum: number;
  SKU: string;
  PickQty: number;
}

export const pickToPack = async (fulfillmentPackNum: number, fulfillmentPackLineNum:number, params: PickToPackParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post(`/api/prepack/packs/${fulfillmentPackNum}/lines/${fulfillmentPackLineNum}/PickToPack`, json);
};
export const getPackProcessLines = async (fulfillmentPackNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/prepack/packs/${fulfillmentPackNum}/process`);
};


export const editPackProcessLine = async (fulfillmentPackNum: number, fulfillmentPackProcessNum: number, params:{PackQty: number}): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.patch(`/api/prepack/packs/${fulfillmentPackNum}/process/${fulfillmentPackProcessNum}`, json);
};

export const delPackProcessLine = async (fulfillmentPackNum: number, fulfillmentPackProcessNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/prepack/packs/${fulfillmentPackNum}/process/${fulfillmentPackProcessNum}`);
};

export const getPackByNum = async (fulfillmentPackNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/prepack/packs/${fulfillmentPackNum}`);
};

export const getPackByBarCode = async (barCode: string): Promise<any> => {
  return await digitBridgeApi.get('/api/prepack/scan',{params: {s: barCode}});
};
