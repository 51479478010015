import React from 'react';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  InputNumber,
  Menu,
  Modal,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  CaretDownOutlined,
  // DeleteOutlined,
  //EditOutlined,
  // ExclamationCircleOutlined,
  ImportOutlined,
  MinusCircleOutlined,
  PrinterOutlined,
  SearchOutlined,
  SettingFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import MoveItemDialog from './MoveItemDialog';
import ProductDetailsDialog from '../ProductDetail/ProductDetailsDialog';
import { PrintLabel } from 'components/common';
import EditLocationDialog from '../../Warehouse/EditLocationDialog';
import { TitlebarCheckboxContainer } from './style';
import { Loading } from 'components/common';
import { ScreenMask } from 'components/common';
import { message } from 'components/common';
// import { TransactionType } from 'constants/config';
import {
  BadgeBox,
  ButtonBox,
  ButtonIcon,
  DetailBox,
  GreyCardWrapper,
  Label,
  // deleteIconStyle,
} from 'components/common/styles';
import {
  LOADING_ICON_SIZE1,
  REGULAR_SECTION_INNER_VERTICAL_SPACING,
} from 'constants/config';
import { fetchWarehouseProductList } from 'services/inventory';
import {
  fetchWarehouseEntity,
  fetchWarehouseLocationChildren,
} from 'services/warehouse';
// import { updateProductQuantity } from 'services/product';

const labelTheme = { fontSize: '1.2em' };

type LocationInfo = {
  [key: number]: {
    dropdownMenuVisible: boolean;
    editMoveQuantityDict: { [key: number]: number };
    expanded: boolean;
    loaded: boolean;
    locationCode: string;
    quantity: number;
    productList: InventoryProductRow[];
    selectedAllRow: boolean;
    selectedRowIds: Array<number>; // the selected inventory row id list
    selectedSomeRow: boolean; // whether some inventory is selected
    warehouseNum: number;
    warehouseId: string;
    lcoationNum: number;
    locationId: string;
  };
};

/**
 * The type definition of the properties for the component.
 */
type ContainerListProps = {
  childLocationList: WarehouseLocationRow[];
  editParentLocationFn?: Function;
  parentLocation: StringKAnyVPair;
  setLocationId: Function;
  warehouseNum: number;
  warehouseId: string;
};

const ContainerList = (props: ContainerListProps) => {
  const { useState } = React;
  const {
    parentLocation,
    childLocationList,
    setLocationId,
    warehouseNum,
    warehouseId,
  } = props;
  const [editingInventoryList, setEditingInventoryList] = useState<
    InventoryProductRow[]
  >([]);
  const [editingLocation, setEditingLocation] = useState<{
    [key: string]: any;
  }>({});
  const [expandAll, setExpandAll] = useState(false);
  const [moveDialogRefreshFn, setMoveDialogRefreshFn] = useState<any>(null);
  const [printLabelVisible, setPrintLabelVisible] = useState(false);
  const [labelData, setLabelData] = useState<PrintLabelTypes[]>([]);
  const printType = React.useRef<'location' | 'barcode'>('location');
  const [locationInfo, setLocationInfo] = useState<LocationInfo>(() => {
    let info: LocationInfo = {};

    childLocationList.forEach((e) => {
      info[e.id] = {
        warehouseNum: e.warehouseNum,
        warehouseId: e.warehouseId,
        lcoationNum: e.locationNum,
        locationId: e.locationId,
        dropdownMenuVisible: false,
        editMoveQuantityDict: {},
        expanded: false,
        loaded: false,
        locationCode: e.code,
        quantity: e.quantity,
        productList: [],
        selectedAllRow: false,
        selectedRowIds: [],
        selectedSomeRow: false,
      };
    });

    return info;
  });
  const [locationSummary, setLocationSummary] = useState<{
    [key: number]: WarehouseLocationRow;
  }>(() => {
    let ret: { [key: number]: WarehouseLocationRow } = {};

    childLocationList.forEach((e) => (ret[e.id] = e));

    return ret;
  });
  const [lockScreen, setLockScreen] = useState(false);
  const [moveItemDialogVisible, setMoveItemDialogVisible] = useState(false);
  const [pageItemSelectMenuVisible, setPageItemSelectMenuVisible] =
    useState(false);
  const [paramSkip /*setParamSkip*/] = useState(0);
  const [paramTop /*setParamTop*/] = useState(50);
  const [paramSortBy /*setParamSortBy*/] = useState('ProductTitle');
  const [paramCalculateTotal /*setParamCalculateTotal*/] = useState(true);
  const [paramCount /*setParamCount*/] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<InventoryProductRow>(
    {} as InventoryProductRow
  );
  const [productDetailsDialogVisible, setProductDetailsDialogVisible] =
    useState(false);

  const productColumns = [
    {
      key: 0,
      align: 'center' as 'center',
      render: (_: number, record: InventoryProductRow, index: number) => {
        return (
          <DetailBox
            onClick={() => {
              console.log('product', record);
              setSelectedProduct(record);
              openProductDetailsDialog();
            }}
          >
            Details
          </DetailBox>
        );
      },
    },
    {
      key: 1,
      title: 'SKU',
      dataIndex: 'sku',
      sorter: (a: InventoryProductRow, b: InventoryProductRow) =>
        a.sku.localeCompare(b.sku),
    },
    {
      key: 2,
      title: 'Part Number',
      dataIndex: 'partNumber',
    },
    {
      key: 3,
      title: 'Lot Number',
      dataIndex: 'lotNumber',
    },
    {
      key: 5,
      title: 'Quantity',
      align: 'right' as 'right',
      dataIndex: 'quantity',
      sorter: (a: InventoryProductRow, b: InventoryProductRow) =>
        a.quantity - b.quantity,
    },
    {
      key: 6,
      width: 150,
      render(_: string, record: InventoryProductRow) {
        return (
          <InputNumber
            key={`input-qty-${record.id}-${record.productNum}-${record.quantity}`}
            size="small"
            min={0}
            max={record.quantity}
            style={{ width: '100%', height: '100%' }}
            onChange={(value) => onChangeMoveNumber(record, value)}
          />
        );
      },
    },
  ];

  const parentDropdownMenuItem = (
    <>
      <Menu>
        <Menu.Item key="1">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => selectPageInventories(true)}
          >
            Page
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            onClick={() => selectPageInventories(false)}
          >
            None
          </Button>
        </Menu.Item>
      </Menu>
    </>
  );

  const getDropdownMenuItem = (locationNum: number, locationId: string) => {
    return (
      <>
        <Menu>
          <Menu.Item key="1">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<ImportOutlined />}
              onClick={() =>
                moveLocationSelectedInventories(locationNum, locationId)
              }
            >
              Move
            </Button>
          </Menu.Item>
          {/* <Menu.Item key="2">
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={<DeleteOutlined style={deleteIconStyle} />}
              onClick={() =>
                removeLocationSelectedInventories(locationNum, locationId)
              }
            >
              Remove
            </Button>
          </Menu.Item> */}
          <Menu.Item key="3">
            <Button
              icon={<PrinterOutlined />}
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              onClick={() => printLocationSelectedInventories(locationNum)}
            >
              Print
            </Button>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  const getLocationSelectedInventoryList = React.useCallback(
    (locationId: number) => {
      const { editMoveQuantityDict, productList, selectedRowIds } =
        locationInfo[locationId];
      return productList
        .filter(
          (e) =>
            editMoveQuantityDict[e.id] > 0 && selectedRowIds.indexOf(e.id) > -1
        )
        .map((e) => {
          return {
            ...e,
            quantity: editMoveQuantityDict[e.id],
          };
        });
    },
    [locationInfo]
  );

  const getLocationSelectionState = (
    id: number,
    selectedRowIds: Array<any>
  ) => {
    const { productList } = locationInfo[id];
    const list = selectedRowIds;

    return {
      selectedAllRow: list.length > 0 && list.length === productList.length,
      selectedRowIds: list,
      selectedSomeRow: list.length > 0 && list.length < productList.length,
    };
  };

  // eslint-disable-next-line
  const getProductColumns = (locationId: number) => {
    const checkColumn: ColumnsType<InventoryProductRow> = [
      {
        key: 7,
        align: 'center' as 'center',
        render(_: string, record: InventoryProductRow) {
          return (
            <Checkbox
              checked={
                locationInfo[record.locationNum].selectedRowIds.indexOf(
                  record.id
                ) > -1
              }
              onChange={(evt) => {
                onCheckRow(record, evt.target.checked);
              }}
            />
          );
        },
        title() {
          const { selectedAllRow, selectedSomeRow } = locationInfo[locationId];

          return (
            <Checkbox
              key={locationId}
              indeterminate={selectedSomeRow}
              checked={selectedAllRow}
              onChange={(evt) => onCheckAllRow(locationId, evt.target.checked)}
            />
          );
        },
      },
    ];

    return [...productColumns, ...checkColumn];
  };

  /**
   * Get the product filter. The filter is like sku cn 'xxx'.
   *
   * @return {string}
   */
  const getProductSearchFilter = (keyword: string, id: number) => {
    const keys = ['SKU', 'LotNumber', 'ProductTitle'];
    const filters = keys.map((e) => `${e} cn '${keyword}'`);
    const locationClause = `LocationNum eq ${id}`;

    return keyword
      ? `${locationClause} and (${filters.join(' or ')})`
      : locationClause;
  };

  /**
   * Get the product list for selected warehouses.
   *
   * @param {number} warehouseId - the id of warehouse, note: 0 is all warehouses
   * @param {number} locationId - it is used to filter the inventory record
   * @param {?string} filter - SQL filter
   */
  const getWarehouseProductList = async (
    warehouseNum: number,
    warehouseId: string,
    locationId: string,
    locationNum: number,
    filter?: string
  ) => {
    let query: StringKAnyVPair = {
      $skip: paramSkip,
      $top: paramTop,
      $sortBy: paramSortBy,
      $calculateTotal: paramCalculateTotal,
      $count: paramCount,
    };

    if (filter) {
      query.$filter = filter;
    }

    let products;

    try {
      products = await fetchWarehouseProductList(warehouseId, query);
      console.log('prd list', products);
      if (products && 'object' === typeof products) {
        //setContentReady(true);
        //setProductList(
        const list: InventoryProductRow[] = products.data
          .filter((e: InventoryProductRow) => e.locationNum === locationNum)
          .map((e: any, i: number) => {
            return {
              id: i,
              code: 'code',
              sku: e.sku,
              upc: e.upc,
              title: e.productTitle,
              locationId: locationId,
              locationCode: e.locationCode,
              locationNum: locationNum,
              // lotNumber: e.lotNumber,
              warehouseId: warehouseId,
              warehouseCode: e.warehouseCode,
              warehouseNum: warehouseNum,
              quantity: e.quantity,
              partNumber: e.partNumber,
              picture: e.pictureUrl,
              productNum: e.fulfillmentProductNum,
              productId: e.productId,
            };
          });

        //);
        return list;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  /**
   * Get product list of a location in a warehouse.
   */
  const getWarehouseLocationProductList = async (
    warehouseNum: number,
    warehouseId: string,
    locationNum: number,
    locationId: string
  ) => {
    const filter = getProductSearchFilter('', locationNum);
    let list = await getWarehouseProductList(
      warehouseNum,
      warehouseId,
      locationId,
      locationNum,
      filter
    );

    setLocationState(locationNum, {
      loaded: true,
      dropdownMenuVisible: false,
      productList: list,
    });

    return list;
  };

  /**
   * The callback when the move number was changed.
   */
  const onChangeMoveNumber = (row: InventoryProductRow, value: number) => {
    let { editMoveQuantityDict } = locationInfo[row.locationNum];
    let list = locationInfo[row.locationNum].selectedRowIds;
    let change = false;

    if (value > 0) {
      if (list.indexOf(row.id) === -1) {
        list = [...list, row.id];
        //setLocationState(row.locationId, {selectedRowIds: list});
        change = true;
      }
    } else {
      let index = list.indexOf(row.id);

      if (index > -1) {
        list.splice(index, 1);
        change = true;
      }
    }

    let selection = getLocationSelectionState(row.locationNum, list);

    editMoveQuantityDict[row.id] = value;

    if (change) {
      //setLocationSelection(row.locationId, list);
      setLocationState(row.locationNum, {
        ...selection,
        editMoveQuantityDict,
      });
    } else {
      setLocationState(row.locationNum, { editMoveQuantityDict });
    }
  };

  /**
   * The callback when checked the select all row checkbox for a location.
   */
  const onCheckAllRow = (locationId: number, checked: boolean) => {
    const { productList } = locationInfo[locationId];
    let list: Array<any> = [];

    if (checked) {
      list = productList.map((e) => e.id);
    }

    setLocationSelection(locationId, list);
  };

  /**
   * The callback when select the row checkbox.
   */
  // eslint-disable-next-line
  const onCheckRow = (row: InventoryProductRow, checked: boolean) => {
    console.log(locationInfo, row.locationId);
    let index = -1;
    let list = locationInfo[row.locationNum].selectedRowIds;

    for (let i = 0; i < list.length; i++) {
      if (row.id === list[i]) {
        index = i;
        break;
      }
    }

    if (index > -1) {
      if (!checked) {
        list.splice(index, 1);
      }
    } else {
      if (checked) {
        list.push(row.id);
      }
    }

    setLocationSelection(row.locationNum, list);
  };

  const openProductDetailsDialog = () => {
    setProductDetailsDialogVisible(true);
  };

  const moveLocationSelectedInventories = (
    locationNum: number,
    locationId: string
  ) => {
    const { locationCode, selectedRowIds, warehouseId } =
      locationInfo[locationNum];

    setLocationState(locationNum, { dropdownMenuVisible: false });

    if (selectedRowIds.length > 0) {
      const list = getLocationSelectedInventoryList(locationNum);

      if (list.length > 0) {
        setEditingInventoryList(list);
        setEditingLocation({
          id: locationNum,
          code: locationCode,
        });
        setMoveDialogRefreshFn(() => {
          return () => {
            refreshLocationInfo(warehouseId, parentLocation.strId);
            refreshLocationProductList(locationId, locationNum);
          };
        });
        setMoveItemDialogVisible(true);
      } else {
        message.info(
          `Please set the quantity need to move for '${locationCode}'`
        );
      }
    } else {
      message.info(`Please select the rows need to move for '${locationCode}'`);
    }
  };

  // const movePageSelectedInventories = () => {
  //   let selectedLocationCodes = [];
  //   let selectedLocationIds: Array<number> = [];
  //   let list: InventoryProductRow[] = [];

  //   for (let lid in locationInfo) {
  //     const id = parseInt(lid);
  //     const { locationCode } = locationInfo[id];
  //     const selectedList = getLocationSelectedInventoryList(id);

  //     if (selectedList.length > 0) {
  //       list = list.concat(selectedList);
  //       selectedLocationIds.push(id);
  //       selectedLocationCodes.push(locationCode);
  //     }
  //   }

  //   console.log('--->', list, selectedLocationIds, selectedLocationCodes);
  //   if (list.length > 0) {
  //     setEditingInventoryList(list);
  //     setEditingLocation({
  //       id: selectedLocationIds,
  //       code: selectedLocationCodes,
  //     });
  //     setMoveDialogRefreshFn(() => {
  //       return async () => {
  //         setLockScreen(true);
  //         refreshLocationInfo(warehouseId, parentLocation.strId);
  //         await refreshPageProductList();
  //         setLockScreen(false);
  //       };
  //     });
  //     setMoveItemDialogVisible(true);
  //   } else {
  //     message.info(
  //       `Please select and set the quantity need to move in this page`
  //     );
  //   }
  // };

  const printLocationSelectedInventories = (locationId: number) => {
    const { locationCode } = locationInfo[locationId];
    const selectedList = getLocationSelectedInventoryList(locationId);

    setLocationState(locationId, { dropdownMenuVisible: false });

    if (selectedList.length > 0) {
      console.log('print', locationId, selectedList);
      setLabelData([
        ...selectedList.map((item) => {
          return {
            code: item.sku + '',
            title: item.title,
            quantity: 1,
          };
        }),
      ]);
      printType.current = 'barcode';
      setPrintLabelVisible(true);
    } else {
      message.info(
        `Please select the rows need to print for '${locationCode}'`
      );
    }
  };

  const refreshLocationInfo = async (
    warehouseId: string,
    locationId: string
  ) => {
    const list = await fetchWarehouseLocationChildren(warehouseId, locationId);
    let summary: { [key: number]: WarehouseLocationRow } = {};

    console.log('lc <><>', list);
    list.forEach((e: StringKAnyVPair) => {
      let location = locationSummary[e.locationNum];

      location.quantity = e.quantity;
      summary[location.id] = location;
    });

    setLocationSummary(summary);
  };

  const refreshLocationProductList = async (
    locationId: string,
    locationNum: number,
    cleanSelect: boolean = false
  ) => {
    //const { warehouseId } = locationInfo[locationId];
    let infoDict = locationInfo;
    let cleanState = getLocationSelectionState(locationNum, []);

    setLocationState(locationNum, {
      loaded: false,
      dropdownMenuVisible: false,
    });

    const list = await getWarehouseLocationProductList(
      warehouseNum,
      warehouseId,
      locationNum,
      locationId
    );

    if (cleanSelect) {
      // note cleanSelect is not cleanState
      for (let lid in infoDict) {
        const id = parseInt(lid);

        infoDict[id] = {
          ...infoDict[id],
          ...getLocationSelectionState(id, []),
        };
      }
    }

    const state = {
      editMoveQuantityDict: {},
      loaded: true,
      dropdownMenuVisible: false,
      productList: list,
      ...cleanState,
    };
    setLocationInfo({
      ...infoDict,
      [locationNum]: {
        ...infoDict[locationNum],
        ...state,
      },
    });
  };

  // const refreshPageProductList = async () => {
  //   let infoDict = { ...locationInfo };

  //   for (let lid in locationInfo) {
  //     const id = parseInt(lid);
  //     const { loaded, lcoationNum, locationId } = locationInfo[id];

  //     if (loaded) {
  //       let cleanState = getLocationSelectionState(id, []);
  //       let list = await getWarehouseLocationProductList(
  //         warehouseNum,
  //         warehouseId,
  //         lcoationNum,
  //         locationId
  //       );

  //       infoDict[id] = {
  //         ...infoDict[id],
  //         ...cleanState,
  //         editMoveQuantityDict: {},
  //         productList: list,
  //       };
  //     }
  //   }

  //   setLocationInfo(infoDict);
  // };

  // const removeLocationSelectedInventories = (
  //   locationNum: number,
  //   locationId: string
  // ) => {
  //   const { locationCode, selectedRowIds /*warehouseNum*/ } =
  //     locationInfo[locationNum];

  //   setLocationState(locationNum, { dropdownMenuVisible: false });

  //   if (selectedRowIds.length > 0) {
  //     const list = getLocationSelectedInventoryList(locationNum);

  //     if (list.length > 0) {
  //       console.log('remove', list);
  //       Modal.confirm({
  //         title: `Do you want to remove selected inventories for '${locationCode}'?`,
  //         icon: <ExclamationCircleOutlined />,
  //         okText: 'Yes',
  //         okType: 'danger',
  //         cancelText: 'No',
  //         onOk: () => {
  //           const update = list.map((e) => ({
  //             FulfillmentProductNum: e.productNum,
  //             WarehouseNum: e.warehouseId,
  //             LocationNum: e.locationId,
  //             Quantity: e.quantity,
  //             SKU: e.sku,
  //             TransactionType: TransactionType.Remove,
  //           }));
  //           setLockScreen(true);
  //           console.log('up', update);
  //           updateProductQuantity(update)
  //             .then((res) => {
  //               setLockScreen(false);

  //               if (res) {
  //                 message.success(`Remove inventories successfully`);
  //                 refreshLocationProductList(locationId, locationNum);
  //                 refreshLocationInfo(warehouseId, parentLocation.strId);
  //               } else {
  //                 message.error(`Remove inventories failded.`);
  //               }
  //             })
  //             .catch((e) => {
  //               setLockScreen(false);
  //               message.error(`Remove inventories error: ${e}`);
  //             });
  //         },
  //         onCancel() {},
  //       });
  //     } else {
  //       message.info(
  //         `Please set the quantity need to remove for '${locationCode}'`
  //       );
  //     }
  //   } else {
  //     message.info(
  //       `Please select the rows need to remove for '${locationCode}'`
  //     );
  //   }
  // };

  const selectPageInventories = (checked: boolean) => {
    let infoDict = { ...locationInfo };

    for (let id in infoDict) {
      const locationId = parseInt(id);
      const { productList } = infoDict[locationId];
      let list: Array<any> = [];

      if (checked) {
        list = productList.map((e) => e.id);
      }

      let selection = getLocationSelectionState(locationId, list);

      infoDict[locationId] = { ...infoDict[locationId], ...selection };
    }

    setLocationInfo(infoDict);
    setPageItemSelectMenuVisible(false);
  };

  /**
   * Set the visible state of the dropdown menu for the location.
   *
   * @param {number} id - the location id
   * @param {boolean} visible
   */
  const setLocationDropdownMenuVisible = (id: number, visible: boolean) => {
    const { loaded } = locationInfo[id];

    if (loaded) {
      setLocationState(id, {
        dropdownMenuVisible: visible,
        expanded: true,
      });
    } else {
      setLocationState(id, { expanded: true });
    }
  };

  /**
   * Set the location inventories selection state.
   *
   * @param {number} id - the location id
   * @param {Array<any>} selectedRowIds
   */
  const setLocationSelection = (id: number, selectedRowIds: Array<any>) => {
    const selection = getLocationSelectionState(id, selectedRowIds);

    setLocationState(id, selection);
  };

  /**
   * Set the state of a location. For example, expand/collapse a location.
   *
   * @param {param} id - the location id
   * @param {{[key: string]: any}} - some key-value pairs of the state
   */
  const setLocationState = (id: number, state: StringKAnyVPair) => {
    if (id in locationInfo) {
      let raw = locationInfo[id];

      if (state.loaded) {
        raw.loaded = true;
        raw.expanded = true; // force to open the folder
      }

      setLocationInfo({
        ...locationInfo,
        [id]: {
          ...raw,
          ...state,
        },
      });

      if (state.expanded && !raw.loaded) {
        getWarehouseLocationProductList(
          raw.warehouseNum,
          raw.warehouseId,
          raw.lcoationNum,
          raw.locationId
        );
      }
    }
  };

  /**
   * Set parent location related state when state of child container changed.
   */
  const setParentStateFromChildren = React.useCallback(() => {
    let allExpanded = true;

    for (let k in locationInfo) {
      const info = locationInfo[k];

      if (!info.expanded) {
        allExpanded = false;
        break;
      }
    }

    setExpandAll(allExpanded);
  }, [locationInfo]);

  /**
   * Expand or collapse the location.
   */
  const toggleLocation = (locationId: number) => {
    const { expanded } = locationInfo[locationId];

    setLocationState(locationId, { expanded: !expanded });
  };

  /**
   * Expand or collapse all the children locations.
   */
  const toggleAllLocations = async () => {
    let infoDict = { ...locationInfo };

    setLockScreen(true);

    for (let id in infoDict) {
      let info = infoDict[parseInt(id)];

      if (expandAll) {
        info.expanded = false;
      } else {
        info.expanded = true;

        if (!info.loaded) {
          info.productList = await getWarehouseLocationProductList(
            info.warehouseNum,
            info.warehouseId,
            info.lcoationNum,
            info.locationId
          );
          info.loaded = true;
        }
      }
    }

    setLockScreen(false);
    setLocationInfo(infoDict);
    setExpandAll(!expandAll);
  };

  const allPageItemSelected = React.useMemo(() => {
    let ret = true;
    let loadNum = 0;

    for (let id in locationInfo) {
      const { loaded, selectedAllRow } = locationInfo[parseInt(id)];

      if (loaded) {
        loadNum++;

        if (!selectedAllRow) {
          ret = false;
          break;
        }
      }
    }

    if (loadNum === 0) {
      ret = false;
    }

    return ret;
  }, [locationInfo]);

  // the columns for the location table
  const locationColumns = React.useMemo(() => {
    let columns: { [key: number]: ColumnsType<InventoryProductRow> } = {};

    childLocationList.forEach((e) => {
      columns[e.id] = getProductColumns(e.id);
    });

    return columns;
  }, [childLocationList, getProductColumns]);

  const someContainersLoaded = React.useMemo(() => {
    let ret = false;

    for (let id in locationInfo) {
      const { loaded, quantity } = locationInfo[parseInt(id)];

      if (loaded && quantity > 0) {
        ret = true;
        break;
      }
    }

    return ret;
  }, [locationInfo]);

  // const someInventoriesReadyMove = React.useMemo(() => {
  //   let ret = false;

  //   for (let id in locationInfo) {
  //     const { editMoveQuantityDict, selectedRowIds } =
  //       locationInfo[parseInt(id)];

  //     for (let i = 0; i < selectedRowIds.length; i++) {
  //       if (editMoveQuantityDict[selectedRowIds[i]]) {
  //         ret = true;
  //         break;
  //       }
  //     }
  //   }

  //   return ret;
  // }, [locationInfo]);

  const someInventoriesSelected = React.useMemo(() => {
    let ret = false;

    for (let id in locationInfo) {
      const { selectedRowIds } = locationInfo[parseInt(id)];

      if (selectedRowIds.length > 0) {
        ret = true;
        break;
      }
    }

    return ret;
  }, [locationInfo]);

  React.useEffect(() => {
    setParentStateFromChildren();
  }, [setParentStateFromChildren]);

  return (
    <>
      <GreyCardWrapper>
        <Row align="middle" justify="space-between">
          <Col>
            <Space>
              {expandAll ? (
                <MinusCircleOutlined onClick={toggleAllLocations} />
              ) : (
                <PlusCircleOutlined onClick={toggleAllLocations} />
              )}
              <Label theme={labelTheme} onClick={toggleAllLocations}>
                Parent:{' '}
                <span className="label-grey">{parentLocation.code}</span>
              </Label>
              {/*<EditOutlined
                onClick={() => {
                  if ('function' === typeof props.editParentLocationFn) {
                    props.editParentLocationFn();
                  }
                }}
              />*/}
            </Space>
          </Col>
          <Col>
            <ButtonBox>
              {/* <Button
                disabled={!someInventoriesReadyMove}
                onClick={movePageSelectedInventories}
              >
                Move
              </Button> */}
              <Button
                disabled={!someInventoriesSelected}
                onClick={() => {
                  setPrintLabelVisible(true);
                  printType.current = 'location';
                  setLabelData([
                    {
                      code: parentLocation.code + '',
                      title: '',
                      quantity: 1,
                    },
                    ...childLocationList.map((item) => {
                      return {
                        code: item.code + '',
                        title: '',
                        quantity: 1,
                      };
                    }),
                  ]);
                }}
              >
                <ButtonIcon className="bi-printer" />
                Print
              </Button>
              <TitlebarCheckboxContainer>
                <Dropdown
                  disabled={!someContainersLoaded}
                  onVisibleChange={setPageItemSelectMenuVisible}
                  overlay={parentDropdownMenuItem}
                  trigger={['click']}
                  visible={pageItemSelectMenuVisible}
                >
                  <Button icon={<Checkbox checked={allPageItemSelected} />}>
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </TitlebarCheckboxContainer>
            </ButtonBox>
          </Col>
        </Row>
      </GreyCardWrapper>
      <GreyCardWrapper color="#ECECEC">
        {childLocationList.map((e, i) => (
          <GreyCardWrapper
            key={i}
            style={{
              marginBottom:
                i < childLocationList.length - 1
                  ? REGULAR_SECTION_INNER_VERTICAL_SPACING
                  : 0,
            }}
          >
            <Row align="middle" justify="space-between">
              <Space>
                {e.quantity > 0 ? (
                  locationInfo[e.id].expanded ? (
                    <MinusCircleOutlined
                      onClick={() =>
                        setLocationState(e.id, { expanded: false })
                      }
                    />
                  ) : (
                    <PlusCircleOutlined
                      onClick={() => setLocationState(e.id, { expanded: true })}
                    />
                  )
                ) : (
                  ''
                )}
                <Label theme={labelTheme} onClick={() => toggleLocation(e.id)}>
                  {e.code}
                </Label>
                <SearchOutlined onClick={() => setLocationId(e.id)} />
              </Space>
              <Space>
                {e.quantity > 0 ? (
                  <Label theme={labelTheme}>
                    Quantity:{' '}
                    <span className="label-grey">
                      {locationSummary[e.id].quantity}
                    </span>
                  </Label>
                ) : (
                  <BadgeBox>Empty</BadgeBox>
                )}
                <Dropdown
                  disabled={
                    !locationInfo[e.id].loaded ||
                    locationInfo[e.id].quantity === 0
                  }
                  onVisibleChange={(visible) =>
                    setLocationDropdownMenuVisible(e.id, visible)
                  }
                  overlay={getDropdownMenuItem(e.locationNum, e.locationId)}
                  trigger={['click']}
                  visible={locationInfo[e.id].dropdownMenuVisible}
                >
                  <Button icon={<SettingFilled />}>
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </Row>
            {e.quantity > 0 && locationInfo[e.id].expanded ? (
              <Table
                bordered
                columns={locationColumns[e.id]}
                dataSource={locationInfo[e.id].productList}
                loading={!locationInfo[e.id].loaded}
                rowKey={(record: InventoryProductRow) => record.id}
                style={{ overflow: 'auto', width: '100%' }}
              />
            ) : (
              ''
            )}
          </GreyCardWrapper>
        ))}
      </GreyCardWrapper>
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
      {printLabelVisible && (
        <PrintLabel
          visible
          onHide={() => setPrintLabelVisible(false)}
          printType={printType.current}
          labelData={labelData}
        />
      )}
      {productDetailsDialogVisible && (
        <ProductDetailsDialog
          visible
          productId={selectedProduct.productId}
          onHide={() => setProductDetailsDialogVisible(false)}
        />
      )}
      <MoveItemDialog
        inventoryList={editingInventoryList}
        warehouseId={warehouseId}
        location={editingLocation}
        onHide={() => setMoveItemDialogVisible(false)}
        onSuccess={moveDialogRefreshFn}
        visible={moveItemDialogVisible}
      />
    </>
  );
};

type Props = {
  location: StringKAnyVPair;
  setLocationId: Function;
  visible: boolean;
  warehouseNum: number;
  warehouseId: string;
};

const AuditContainer = (props: Props) => {
  const { warehouseNum, warehouseId, location, visible } = props;
  const [editLocationDialogVisible, setEditLocationDialogVisible] =
    React.useState(false);
  const [isLoadingContainer, setIsLoadingContainer] = React.useState(false);
  const [locationId, setLocationId] = React.useState(-1);
  const [locationList, setLocationList] = React.useState<
    WarehouseLocationRow[]
  >([]);
  const [lockScreen, setLockScreen] = React.useState(false);
  const [warehouse, setWarehouse] = React.useState<WarehouseRow>(
    {} as WarehouseRow
  );

  /**
   * Open the editing location dialog.
   */
  const editParentLocation = async () => {
    let wh = { ...warehouse };

    if (!warehouse.id) {
      setLockScreen(true);

      try {
        const entity = await fetchWarehouseEntity(warehouseId);

        wh = {
          id: entity.warehouseNum,
          code: entity.warehouseCode,
          name: entity.warehouseName,
          isExternal: entity.external,
          totalQuantity: entity.totalQuantity,
          isEnabled: entity.isEnabled,
          warehouseId: entity.warehouseId,
        };
        setWarehouse(wh);
      } catch (e) {
        message.error(`Fetch warehouse information error`);
      }

      setLockScreen(false);
    }

    console.log('ss', wh);
    setEditLocationDialogVisible(true);
  };

  const fetchContainerList = async (
    warehouseId: string,
    locationId: string,
    locationNum: number
  ) => {
    const list = await fetchWarehouseLocationChildren(warehouseId, locationId);

    if (list && Array.isArray(list)) {
      console.log('ll', list);
      setLocationId(locationNum);
      setIsLoadingContainer(false);
      setLocationList(
        list.map((e) => {
          return {
            id: e.locationNum,
            code: e.locationCode,
            parent: e.parentNum,
            parentCode: e.parentCode,
            quantity: e.quantity,
            creator: e.createBy,
            reserved: e.reserved,
            backstock: e.backstock,
            locationId: e.locationId,
            locationNum: e.locationNum,
            warehouseId: e.warehouseId,
            warehouseNum: e.warehouseNum,
          };
        })
      );
    }
  };

  // eslint-disable-next-line
  const refreshContainerList = () => {
    setIsLoadingContainer(true);
    fetchContainerList(warehouseId, location.strId, location.id);
  };

  React.useEffect(() => {
    if (visible) {
      if (
        'number' === typeof location.id &&
        locationId !== location.id &&
        location.strId
      ) {
        console.log('loc', location);
        refreshContainerList();
      }
    }
  }, [visible, location, locationId, refreshContainerList, warehouseNum]);

  return (
    <>
      {isLoadingContainer ? (
        <Loading size={LOADING_ICON_SIZE1} style={{ margin: '30px 0' }} />
      ) : locationList.length > 0 ? (
        <ContainerList
          childLocationList={locationList}
          editParentLocationFn={editParentLocation}
          parentLocation={location}
          setLocationId={props.setLocationId}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
        />
      ) : (
        <Label theme={labelTheme}>
          '{location.code}' doesn't have any container.
          <br />
          Want to create some?
          <Button id="link_to_create" type="link" onClick={editParentLocation}>
            Attach Child Container
          </Button>
        </Label>
      )}
      <Modal
        title={`Edit location '${location.code}' for '${warehouse.code}' (${warehouse.name}) Warehouse`}
        afterClose={refreshContainerList}
        centered
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        visible={editLocationDialogVisible}
        width={1000}
        onCancel={() => setEditLocationDialogVisible(false)}
      >
        <EditLocationDialog
          editContainer={true}
          parentLocation={location}
          warehouseNum={warehouse.id}
          warehouseId={warehouse.warehouseId}
        />
      </Modal>
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
    </>
  );
};

export default AuditContainer;
