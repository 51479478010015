import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartText,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import { mergeLocation } from 'services/inventory';
import { queryLocationsByKeyword } from 'services/warehouse';
import FinishDialog from './Finish';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';
import { useTranslation } from 'react-i18next';
import { Button, Row, Space } from 'antd';

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [step, setStep] = useState<'sourceLocation' | 'targetLocation'>(
    'sourceLocation'
  );
  const [sLocation, setSLocation] = useState<any>();
  const [tLocation, setTLocation] = useState<any>();
  // const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);
  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      // setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const queryLocation = useCallback(
    async (
      value: string,
      isSource: boolean,
      callback: (value: any) => void
    ) => {
      try {
        setFetching(true);
        const res = await queryLocationsByKeyword(warehouseId, value, true);
        setFetching(false);

        if (!res.error) {
          callback(res);
          if (isSource) {
            setStep('targetLocation');
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId]
  );

  const postMerge = useCallback(async () => {
    if (tLocation.locationCode === sLocation.locationCode)
      return message.warning({ content: 'Cannot merge to itemself' });
    try {
      setFetching(true);
      const res = await mergeLocation({
        warehouseId,
        fromLocation: sLocation.locationNum,
        toLocation: tLocation.locationNum,
      });
      setFetching(false);
      if (res) {
        setFinishDialogVisible(true);
      } else {
        message.error({ content: 'merge failed!' });
      }
    } catch (error) {
      setFetching(false);
    }
  }, [tLocation, sLocation, warehouseId]);

  /* eslint-disable */
  useEffect(() => {
    if (tLocation && sLocation) {
      postMerge();
    }
  }, [tLocation]);
  /* eslint-enable */

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'sourceLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanSourceLocationtoStart")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, true, setSLocation);
              }}
            />
          </SmartSpace>
          <Row style={{ marginTop: 10 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('Pick', true, setSLocation);
                }}
              >
                Use Pick
              </Button>
            </Space>
          </Row>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}
      {step === 'targetLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>
              {t("smart.movemerge.source")}
            </SmartFormLabel>
            <SmartText>{sLocation?.locationCode}</SmartText>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanDestinationLocationComplete")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, false, setTLocation);
              }}
            />
            <Row style={{ marginTop: 10 }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    queryLocation('Pick', true, setTLocation);
                  }}
                >
                  Use Pick
                </Button>
              </Space>
            </Row>
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}
      {finishDialogVisible && (
        <FinishDialog
          tLocation={tLocation.locationCode}
          sLocation={sLocation.locationCode}
          onSubmit={(value) => {
            setFinishDialogVisible(false);
            if (!value) {
              setStep('sourceLocation');
            } else {
              setTLocation(undefined);
              queryLocation(value, true, setSLocation);
            }
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
