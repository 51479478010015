import React, { useCallback, useRef } from 'react';
import { FormInstance, Select } from 'antd';

type SelectProps = {
  locations: any[];
  onChange: (locationNum?: number, row?: any) => void;
  value?: number;
  style?: any;
  form?: FormInstance<any>;
  title?: string;
  disabled?: boolean;
};

// eslint-disable-next-line
export default (props: SelectProps) => {
  const { value, onChange, style, form, title, locations } = props;
  const timerRef = useRef<any>(null);

  const fetchLocations = useCallback(
    async (value: string) => {
      // init filed value
      if (title && form) {
        let obj: any = {};
        obj[title] = null;
        form?.setFieldsValue(obj);
      }
      try {
        if (title && locations.length > 0) {
          const temp = locations.filter((item) =>
            item.locationCode.toUpperCase().includes(value.toUpperCase())
          );
          if (temp.length === 1) {
            let obj: any = {};
            obj[title] = temp[0].locationNum;
            form?.setFieldsValue(obj);
          }
          // if (locations.find(item => item.locationCode.toUpperCase().includes(value.toUpperCase()))) {
          //   let obj: any = {};
          //   obj[title] = locations[0].locationNum
          //   form?.setFieldsValue(obj);
          // }
        }
      } catch (error) {}
    },
    [form, title, locations]
  );

  const handleSearch = (value: string) => {
    if (value) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      timerRef.current = setTimeout(() => fetchLocations(value), 300);
    }
  };

  return (
    <Select
      showSearch
      allowClear
      disabled={props.disabled}
      defaultValue={value}
      placeholder="input search text"
      style={style ? { width: '100%', ...style } : { width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      value={value}
      // filterOption={false}
      filterOption={(input, option) => {
        if (option) {
          const { children } = option;

          if (typeof children === 'string') {
            return (
              (children as string).toLowerCase().indexOf(input.toLowerCase()) >=
              0
            );
          }
        }

        return false;
      }}
      onSearch={handleSearch}
      // onSelect={(value:any) => console.log('%$$$$$$$',value)}
      onSelect={(value: number) => {
        const row = locations.filter((i) => i.locationNum === value);
        onChange(value, row);
      }}
      notFoundContent={null}
    >
      {locations.map((item) => (
        <Select.Option key={item.locationNum} value={item.locationNum}>
          {item.locationCode}
        </Select.Option>
      ))}
    </Select>
  );
};
