import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, InputNumber, Modal, Row, Space } from 'antd';
import { isEqualObject } from 'crud-object-diff';
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Loading,
  ScreenMask,
  DataGridPlus,
  HelpMessage,
  message,
} from 'components/common';
import { Label } from 'components/common/styles';
import { TransactionType } from 'constants/config';
import { REGULAR_SECTION_ROW_VERTICAL_SPACING } from 'constants/config';
import { deselectAuditProductRows } from 'actions/inventoryActions';
import theme from 'assets/styles/theme';
import { updateProductQuantity } from 'services/product';
import { confirmLeave } from 'utils/uiHelper';

type ColumnRenderType = {
  cellProps?: any;
  data: InventoryProductRow;
};

type QuantityDictType = {
  [key: string]: {
    qty: number;
    move: number;
  };
};

type Props = {
  inventoryList: InventoryProductRow[];
  location: StringKAnyVPair;
  onHide: () => void;
  onSuccess?: Function;
  visible: boolean;
};

// eslint-disable-next-line
export default (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { inventoryList, location, onHide, onSuccess, visible } = props;
  const [inited, setInited] = React.useState(false);
  const [lockScreen, setLockScreen] = React.useState(false);
  const [quantityDict, setQuantityDict] = React.useState<QuantityDictType>({});
  const [rawState, setRawState] = React.useState<StringKAnyVPair>({});
  const spacerStyle = { height: REGULAR_SECTION_ROW_VERTICAL_SPACING };
  const locationCode = React.useMemo(() => {
    let ret = '';

    if (location.code && Array.isArray(location.code)) {
      ret = location.code.join(', ');
    } else if ('string' === typeof location.code) {
      ret = location.code;
    }

    return ret;
  }, [location]);
  const totalQuantity = React.useMemo(() => {
    let total = 0;

    for (let k in quantityDict) {
      total += quantityDict[k].move;
    }

    return total;
  }, [quantityDict]);

  const clearState = () => {
    setInited(false);
    setQuantityDict({});
    //setLocationId(-1);
    //setWarehouseId(-1);
    //setTotalQuantity(0);
  };

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      headerAlign: 'center',
      defaultFlex: 1,
      minWidth: 220,
      userSelect: true,
      needToolTip: true,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;

      //   return (
      //     <Popover placement="topLeft" content={data.sku}>
      //       {data.sku}
      //     </Popover>
      //   );
      // },
    },
    {
      name: 'partNumber',
      header: 'Part Number',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'lotNumber',
      header: 'Lot Number',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'title',
      header: 'Product Name',
      headerAlign: 'center',
      defaultFlex: 1,
      userSelect: true,
      needToolTip: true,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;

      //   return (
      //     <Popover placement="topLeft" content={data.title}>
      //       {data.title}
      //     </Popover>
      //   );
      // },
    },
    {
      name: 'quantity',
      header: 'Quantity',
      defaultFlex: 1,
      headerAlign: 'center' as 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;

        return quantityDict[data.uuid]?.qty || 0;
      },
    },
    {
      name: '',
      header: '',
      defaultFlex: 1,
      maxWidth: 100,
      minWidth: 100,
      resizable: false,
      sortable: false,
      textAlign: 'center' as 'center',
      renderHeader: () => totalQuantity,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <InputNumber
            key={`${data.id}-${data.locationId}-${data.productNum}`}
            size="large"
            min={0}
            max={data.quantity}
            defaultValue={quantityDict[data.uuid]?.move || 0}
            style={{ width: '100%', height: '100%' }}
            onChange={(value) => onChangeMoveNumber(data, value)}
          />
        );
      },
    },
  ];

  const handleCancel = async () => {
    const formValue = {
      move: quantityDict,
    };

    if (isEqualObject(formValue, rawState)) {
      onHide();
    } else {
      confirmLeave(onHide);
    }
  };

  const handleSubmit = async () => {
    let update: StringKAnyVPair[] = [];

    inventoryList.forEach((e: InventoryProductRow) => {
      if (quantityDict[e.uuid]) {
        const move = quantityDict[e.uuid].move;

        if (move > 0) {
          update.push({
            FulfillmentProductNum: e.productNum,
            WarehouseNum: e.warehouseId,
            LocationNum: e.locationId,
            Quantity: move,
            SKU: e.sku,
            TransactionType: TransactionType.Remove,
          });
        }
      }
    });
    //console.log('up', update);
    setLockScreen(true);
    try {
      const res = await updateProductQuantity(update);
      let closeLater = false;

      if (res) {
        const deselect = () => {
          dispatch(
            deselectAuditProductRows(
              true,
              inventoryList.map((e) => e.id)
            )
          );
          setTimeout(() => {
            dispatch(deselectAuditProductRows(false));
          }, 100);
        };

        if (onSuccess && 'function' === typeof onSuccess) {
          closeLater = true;
          //onSuccess();
          setTimeout(() => {
            setLockScreen(false);
            message.success('Deduct products successfully');
            onSuccess();
            deselect();
            onHide();
          }, 1000); // use timeout to avoid React max deep execution warning
        }

        if (!closeLater) {
          setLockScreen(false);
          message.success('Deduct products successfully');
          onHide();
        }
      } else {
        message.error('Move inventories failed');
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Move inventories error: ${e}`);
    }
  };

  /**
   * The callback when the move number was changed.
   */
  const onChangeMoveNumber = (row: InventoryProductRow, value: number) => {
    const { quantity, uuid } = row;
    let dict = {
      ...quantityDict,
      [uuid]: {
        move: value,
        qty: quantity - value,
      },
    };

    setQuantityDict(dict);
  };

  const onDialogClose = () => {
    clearState();
  };

  React.useEffect(() => {
    if (visible && !inited) {
      let dict: QuantityDictType = {};

      inventoryList.forEach((e) => {
        dict[e.uuid] = {
          qty: e.quantity,
          move: 0,
        };
      });
      //console.log('dd', dict);
      setRawState({
        move: JSON.parse(JSON.stringify(dict)),
      });
      setQuantityDict(dict);
      setInited(true);
    }
  }, [inited, inventoryList, visible]);

  return (
    <Modal
      title={`Deduct items in location`}
      centered
      destroyOnClose={true}
      getContainer={false}
      onCancel={handleCancel}
      maskClosable={false}
      afterClose={onDialogClose}
      visible={visible}
      width={800}
      footer={
        <Row justify="space-between">
          <Space>
            <HelpMessage placement="top" helpId={4} marginLeft={5} />
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={location.id <= 0 || totalQuantity === 0}
              onClick={handleSubmit}
              icon={<SaveOutlined />}
            >
              Deduct
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Row align="middle">
        <ExclamationCircleOutlined style={{ color: theme['@warning-color'] }} />
        &nbsp;&nbsp;
        <Label>
          The items you want to deduct are with quantity{' '}
          <span className="label-bold">'{totalQuantity}'</span>.
        </Label>
      </Row>
      <div style={spacerStyle}></div>
      <Row>
        <Label>
          <span className="label-bold">Current Location: </span>
          <span className="label-grey">{locationCode}</span>
        </Label>
      </Row>
      <div style={spacerStyle}></div>
      <div style={spacerStyle}></div>
      <DataGridPlus
        columns={columns}
        dataSource={inventoryList}
        idProperty="uuid"
        showColumnMenuTool={false}
        {...({} as any)}
      />
      {lockScreen ? (
        <ScreenMask>
          <Loading size={48} />
        </ScreenMask>
      ) : (
        ''
      )}
    </Modal>
  );
};
