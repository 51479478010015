import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space, Popconfirm } from 'antd';
import { DataGridPlus, message, ColumnDataType } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import {
  getReceivedTransfer,
  closeTransfer,
  deleteReceiveLine,
} from 'services/inventory';
import { getWarehouseIdFromCache } from 'utils';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import { CheckOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  transfer: any;
  onRefresh: () => void;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onRefresh, transfer } = props;
  const [loading, setLoding] = useState(false);
  const [saveLoaing, setSaveLoaidng] = useState(false);
  const [data, setData] = useState([]);
  // const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  // const [selected, setSelected] = React.useState<any>({});

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const delLine = useCallback(
    async (fulfillmentReceiveNum: number) => {
      try {
        setLoding(true);
        const res = await deleteReceiveLine(
          getWarehouseIdFromCache(transfer.warehouseNum),
          transfer.transferNum,
          fulfillmentReceiveNum
        );
        setLoding(false);
        if (res) {
          message.success({ content: 'deleted successfully' });
          setData((prev) =>
            prev.filter(
              (i: any) => i.fulfillmentReceiveNum !== fulfillmentReceiveNum
            )
          );
        }
      } catch (error) {
        setLoding(false);
      }
    },
    [transfer]
  );

  const handleClose = useCallback(async () => {
    try {
      setSaveLoaidng(true);
      const res = await closeTransfer(
        getWarehouseIdFromCache(transfer.warehouseNum),
        transfer.transferNum
      );
      setSaveLoaidng(false);
      if (res) {
        message.success({ content: 'closed successfully' });
        onRefresh();
      }
    } catch (error) {
      setSaveLoaidng(false);
    }
  }, [onRefresh, transfer]);

  const getData = useCallback(async () => {
    try {
      setLoding(true);
      console.log(transfer);
      const res = await getReceivedTransfer(
        getWarehouseIdFromCache(transfer.warehouseNum),
        transfer.transferNum
      );
      setData(res);
      setLoding(false);
    } catch (error) {
      setLoding(false);
    }
  }, [transfer]);

  const columns = [
    // {
    //   name: 'fulfillmentReceiveNum',
    //   header: 'Receive Number',
    //   showColumnMenuTool: true,
    //   defaultFlex: 1,
    // },
    {
      name: 'sku',
      header: 'SKU',
      userSelect: true,
      minWidth: 220,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Name',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Receive Qty',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'warehouseCode',
      header: 'warehouse',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'locationCode',
      header: 'Location',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      header: 'Received Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      name: 'createBy',
      userSelect: true,
      header: 'received By ',
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      sortable: false,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return transfer.warehouseTransferStatus !== 2 ? (
          <Space>
            <Popconfirm
              placement="rightTop"
              title="Are you sure to cancel this row?"
              onConfirm={() => delLine(data.fulfillmentReceiveNum)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ className: 'danger-btn', icon: <CheckOutlined /> }}
              cancelButtonProps={{ icon: <CloseOutlined /> }}
            >
              <Button
                type="link"
                id={`cancel_row_${data.fulfillmentReceiveNum}`}
              >
                Cancel
              </Button>
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    if (transfer) {
      getData();
    }
  }, [getData, transfer]);

  return (
    <Modal
      title={
        transfer.warehouseTransferStatus === 2
          ? `Receive History - ${transfer.batchNumber}`
          : `Complete Transfer - ${transfer.batchNumber}`
      }
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            {transfer.warehouseTransferStatus !== 2 && (
              <Button
                id="complete_btn"
                type="primary"
                disabled={data.length === 0}
                loading={saveLoaing}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to complete this transfer?',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                      handleClose();
                    },
                  });
                }}
                icon={<SaveOutlined />}
              >
                {saveLoaing ? 'Completing' : 'Complete Transfer'}
              </Button>
            )}
            <Button id="hide_dialog" onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        loading={loading}
        dataSource={data}
        idProperty="fulfillmentReceiveNum"
        pagination="local"
        // checkboxColumn
        // checkboxOnlyRowSelect={true}
        // selected={selected}
        // onSortInfoChange={() => setSelected({})}
        // onChange={() => setSelected({})}
        // onSelectionChange={(props) => {
        //   const { selected, data, unselected } = props;
        //   setSelected(selected);
        //   if (selected === true && !unselected) {
        //     setSelectedRow(data as any);
        //   } else {
        //     if (!unselected) {
        //       setSelectedRow(Object.values(selected as any));
        //     } else {
        //       setSelectedRow((prev) => {
        //         return prev.filter(
        //           (item) =>
        //             item.channelAccountNum !== (data as any).channelAccountNum
        //         );
        //       });
        //     }
        //   }
        // }}
        {...({} as any)}
      />
    </Modal>
  );
};
