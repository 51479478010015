import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Row,
  Button,
  Space,
  Form,
  Card,
  Input,
  Typography,
  DatePicker,
  Select,
  InputNumber,
  Spin,
} from 'antd';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { DiffOutlined, EditOutlined, FrownOutlined, SmileOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  DataGridPlus,
  ButtonIcon,
  message,
  HeaderTitle,
  SelectWarehouse,
  FormLabel,
  TablesFilter,
} from 'components/common';
import SelectLocationDialog from 'components/common/SelectLocationPlus/SelectLocationDialog';
import { GridIconBox } from 'components/common/styles';
import { fetchReturns, getReturnLines } from 'services/return';
import { getSpecialLocations } from 'services/warehouse';
import ReceiveDialog from './ReceiveDialog';
// import ScanToReceiveDialog from './ScanToReceive';
import ScanToReceiveDialog from '../../Po/PoReceive2/ScanToReceive2'
import SelectPoDialog from './SelectPoDialog';
import ReceiveAllDialog from './ReceiveAllDialog';
import { useSelector, shallowEqual } from 'react-redux';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';
import DeviceSettingDialog from '../../Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import { RootState } from 'reducers';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { specialWarehouseInfo } from 'actions/adminActions';
import SuccessDialog from './SuccessDialog';

const { Text } = Typography;
const { Search } = Input;
const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

type SpecialInfoItem = {
  databaseNum: number;
  locations: any[];
  warehouseCode: string;
  warehouseId: string;
  warehouseNum: number;
  warehouseType: number;
};

type SpecialInfoType = {
  2?: SpecialInfoItem;
  3?: SpecialInfoItem;
  4?: SpecialInfoItem;
};

type ReturnRestockProps = {
  isModal?: boolean;
  modalReturn?: any;
  goto?: (key: string, newData: any) => void;
};

// eslint-disable-next-line
export default (props: ReturnRestockProps) => {
  const defaultLocationCode = 'General';
  const { isModal, modalReturn, goto } = props;
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const cacheInfo = useSelector(
    (state: RootState) => state.admin.defaultSpecialWarehouseInfo,
    shallowEqual
  );
  const dispatch: Dispatch<any> = useDispatch();

  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSearch = searchParams.get('channelOrderId');
  const defaultWarehouseNum = searchParams.get('warehouseNum');
  const [selectReturnDialogVisible, setSelectReturnDialogVisible] =
    useState(false);
  const [receiveDialog, setReceiveDialog] = useState(false);
  const [scanToReceiveDialogVisible, setScanToReceiveDialogVisible] =
    useState(false);
  const [returnList, setReturnList] = useState<any[]>([]);
  const [selectedReturn, setSelectedReturn] = useState<any>();
  const [lines, setLines] = useState<any[]>([]);
  const [initing, setIniting] = useState(false);
  const [newData, setNewData] = useState<any>();
  const [successDialogVisible, setSuccessDialogVisible] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseRow | undefined
  >(
    isModal && modalReturn
      ? {
        id: modalReturn.warehouseNum,
        code: modalReturn.warehouseCode,
        name: '',
        isExternal: false,
        totalQuantity: 0,
        isEnabled: true,
        warehouseId: getWarehouseIdFromCache(modalReturn.warehouseNum),
      }
      : defaultWarehouseNum
        ? {
          id: Number(defaultWarehouseNum),
          code: '',
          name: '',
          isExternal: false,
          totalQuantity: 0,
          isEnabled: true,
          warehouseId: '',
        }
        : undefined
  );
  // const [damageWarehouse, setDamageWarehouse] = useState<any>();
  // const [returnWarehouse, setReturnWarehouse] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [receiptDate, setReceiptDate] = useState<moment.Moment>(moment());
  const [selectSearchType, setSelectSearchType] =
    useState<string>('channelOrderId');
  const [current, setCurrent] = useState<any>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState(false);
  const [
    selectDamageLocationDialogVisible,
    setSelectDamageLocationDialogVisible,
  ] = useState(false);
  // const [returnLocations, setReturnLocations] = useState<any[]>([]);
  // const [damageLocations, setDamageLocations] = useState<any[]>([]);
  const [receiveAllDialogVisible, setReceiveAllDialogVisible] =
    useState<boolean>(false);
  const [inited, setInited] = useState(false);
  const [allDamage, setAllDamage] = useState(false);
  const [specialInfo, setSpecialInfo] = useState<any>({
    2: undefined,
    3: undefined,
    4: undefined,
  });
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const searchRef = useRef('');

  /**
   * handle hide event
   */

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 200,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      minWidth: 200,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Description',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Received Qty',
      userSelect: true,
      showColumnMenuTool: true,
      sortable: false,
      defaultFlex: 1,
    },
    {
      name: 'inspectionQty',
      header: 'Inspection Qty',
      userSelect: true,
      showColumnMenuTool: true,
      sortable: false,
      defaultFlex: 1,
    },
    {
      name: 'stockingQty',
      header: 'Resaleable Qty',
      renderHeader: () => (
        <span role="img" aria-label="smile">
          Resaleable Qty &#128512;
        </span>
      ),
      showColumnMenuTool: true,
      sortable: false,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <InputNumber
            id={`grid_columns_stock_qty_row_${rowIndex}`}
            value={data.stockingQty}
            min={0}
            max={
              data.inspectionQty - (data.damageingQty || 0) > 0
                ? data.inspectionQty - (data.damageingQty || 0)
                : 0
            }
            onChange={(qty) =>
              setLines((prev) => {
                const temp = prev;
                const index = prev.findIndex(
                  (item) =>
                    item.fulfillmentReturnLineNum ===
                    data.fulfillmentReturnLineNum
                );
                if (index > -1) {
                  temp[index] = {
                    ...temp[index],
                    stockingQty: qty,
                  };
                  return [...temp];
                } else {
                  return prev;
                }
              })
            }
          />
        );
      },
    },
    {
      name: 'stockingLocationCode',
      header: 'Resaleable Loc',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <>
            {data.stockingLocationCode || ''}
            <GridIconBox>
              <EditOutlined
                id={`grid_columns_stock_location_row_${rowIndex}`}
                onClick={() => {
                  setCurrent(data);
                  setSelectLocationDialogVisible(true);
                }}
              />
            </GridIconBox>
          </>
        );
      },
    },
    {
      name: 'damageingQty',
      header: 'Damage Qty',
      renderHeader: () => (
        <span role="img" aria-label="crying">
          Damage Qty &#128557;
        </span>
      ),
      showColumnMenuTool: true,
      sortable: false,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <InputNumber
            id={`grid_columns_nonstock_qty_row_${rowIndex}`}
            value={data.damageingQty}
            min={0}
            max={
              data.inspectionQty - (data.stockingQty || 0) > 0
                ? data.inspectionQty - (data.stockingQty || 0)
                : 0
            }
            onChange={(qty) =>
              setLines((prev) => {
                const temp = prev;
                const index = prev.findIndex(
                  (item) =>
                    item.fulfillmentReturnLineNum ===
                    data.fulfillmentReturnLineNum
                );
                if (index > -1) {
                  temp[index] = {
                    ...temp[index],
                    damageingQty: qty,
                  };
                  return [...temp];
                } else {
                  return prev;
                }
              })
            }
          />
        );
      },
    },
    {
      name: 'damageingLocationCode',
      header: 'Damage Loc',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <>
            {data.damageingLocationCode || ''}
            <GridIconBox>
              <EditOutlined
                id={`grid_columns_nonstock_location_row_${rowIndex}`}
                onClick={() => {
                  setCurrent(data);
                  setSelectDamageLocationDialogVisible(true);
                }}
              />
            </GridIconBox>
          </>
        );
      },
    },
    {
      name: 'notes',
      header: 'Note',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  // const getLocations = async (id: string, type: number) => {
  //   const list = await fetchWarehouseLocationList(id, false);
  //   if (type === 3) {
  //     setDamageLocations(
  //       list.map((row: any) => {
  //         return {
  //           id: row.locationNum,
  //           code: row.locationCode,
  //           parentCode: row.parentCode,
  //           parent: row.parentNum,
  //           creator: row.createBy,
  //           reserved: row.reserved,
  //           backstock: row.backstock,
  //           warehouseId: row.warehouseId,
  //           ...row,
  //         };
  //       })
  //     );
  //   } else {
  //     setReturnLocations(
  //       list.map((row: any) => {
  //         return {
  //           id: row.locationNum,
  //           code: row.locationCode,
  //           parentCode: row.parentCode,
  //           parent: row.parentNum,
  //           creator: row.createBy,
  //           reserved: row.reserved,
  //           backstock: row.backstock,
  //           warehouseId: row.warehouseId,
  //           ...row,
  //         };
  //       })
  //     );
  //   }
  // };

  const getAllSpecialInfo = useCallback(async () => {
    if (
      cacheInfo &&
      cacheInfo.warehouseNum &&
      cacheInfo.warehouseNum === selectedWarehouse?.id
    ) {
      if (cacheInfo.specialWarehouseInfo) {
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        cacheInfo.specialWarehouseInfo.map((i: any) => {
          if (i.warehouseType === 2) {
            temp[2] = i;
          }
          if (i.warehouseType === 3) {
            temp[3] = i;
          }
          if (i.warehouseType === 4) {
            temp[4] = i;
          }
          return true;
        });
        if (!temp[2] || !temp[3]) {
          message.info({
            content:
              'Return-Resalable warehouse or Return-Damage warehouse is not found, please check whether you have permission or whether the warehouse exists',
          });
        }
        setSpecialInfo(temp);
        if (isModal && modalReturn) {
          setSelectedReturn(modalReturn);
        }
      }
    } else {
      try {
        setIniting(true);
        const res = await getSpecialLocations(
          selectedWarehouse?.warehouseId || '',
          0
        );
        setIniting(false);
        if (res) {
          dispatch(
            specialWarehouseInfo({
              warehouseNum: selectedWarehouse?.id || 0,
              specialWarehouseInfo: res,
            })
          );
        }
      } catch (error) {
        setIniting(false);
      }
    }
  }, [selectedWarehouse, isModal, modalReturn, cacheInfo, dispatch]);

  const getTransferByValue = useCallback(
    async (poNumberSearchValue) => {
      if (
        !selectedWarehouse ||
        !selectedWarehouse.warehouseId ||
        !poNumberSearchValue
      )
        return;
      setInited(true);
      try {
        const queryparams = {
          $skip: 0,
          $top: 10,
          $sortBy: 'TransferNum desc',
          channelOrderId:
            selectSearchType === 'channelOrderId'
              ? poNumberSearchValue
              : undefined,
          returnNo:
            selectSearchType === 'returnNo' ? poNumberSearchValue : undefined,
          invoice:
            selectSearchType === 'invoiceNo' ? poNumberSearchValue : undefined,
          oper: 0,
        };
        const transferList = await fetchReturns(
          selectedWarehouse.warehouseId,
          queryparams
        );
        if (transferList.data.length === 0) {
          return message.warning({ content: 'no data matched' });
        }
        setSelectedReturn(transferList.data[0]);
      } catch (err) {
        console.log(err);
      }
    },
    [selectedWarehouse, selectSearchType]
  );

  const getTransferList = useCallback(async () => {
    if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
    setInited(true);
    try {
      const queryparams = {
        $skip: 0,
        $top: 10000,
        $sortBy: 'TransferNum desc',
        oper: 0,
      };
      const returnList = await fetchReturns(
        selectedWarehouse.warehouseId,
        queryparams
      );
      setReturnList(
        returnList.data.map((item: any) => {
          return { ...item, stockingQty: 0 };
        })
      );
      setSelectReturnDialogVisible(true);
    } catch (err) {
      console.log(err);
    }
  }, [selectedWarehouse]);

  const getPoLines = useCallback(
    async (returnNum: number) => {
      if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
      try {
        setLoading(true);
        const lines = await getReturnLines(
          selectedWarehouse.warehouseId,
          returnNum
        );
        setLoading(false);
        const temp = (specialInfo[2]?.locations || []).filter(
          (i: any) => i.locationCode === defaultLocationCode
        );
        const temp1 = (specialInfo[3]?.locations || []).filter(
          (i: any) => i.locationCode === defaultLocationCode
        );
        setLines(
          lines.map((item: any) => {
            return {
              ...item,
              stockingLocationCode:
                temp.length > 0 ? temp[0].locationCode : undefined,
              stockingLocationNum:
                temp.length > 0 ? temp[0].locationNum : undefined,
              damageingLocationCode:
                temp1.length > 0 ? temp1[0].locationCode : undefined,
              damageingLocationNum:
                temp1.length > 0 ? temp1[0].locationNum : undefined,
            };
          })
        );
      } catch (err) {
        setLoading(false);
      }
    },
    [selectedWarehouse, specialInfo]
  );

  /**
   * receive all event
   */
  const handelReceiveAll = useCallback(
    (location: WarehouseLocationRow, allAsDamage: boolean) => {
      setLines((prev: any[]) => {
        const temp = prev.map((item) => {
          return allAsDamage
            ? {
              ...item,
              damageingLocationCode: location.code,
              damageingLocationNum: location.id,
              damageingQty: item.inspectionQty,
              stockingLocationCode: undefined,
              stockingLocationNum: undefined,
              stockingQty: undefined,
            }
            : {
              ...item,
              stockingLocationCode: location.code,
              stockingLocationNum: location.id,
              stockingQty: item.inspectionQty,
              damageingLocationCode: undefined,
              damageingLocationNum: undefined,
              damageingQty: undefined,
            };
        });
        return [...temp];
      });
    },
    []
  );

  // const getSpecialWarehouse = useCallback(async () => {
  //   try {
  //     const res = await getWarehouseSpecial(
  //       selectedWarehouse?.warehouseId || '',
  //       3
  //     );
  //     if (res) {
  //       setDamageWarehouse(res);
  //       getLocations(res.warehouseId, 3);
  //     }
  //     const res1 = await getWarehouseSpecial(
  //       selectedWarehouse?.warehouseId || '',
  //       2
  //     );
  //     if (res1) {
  //       setReturnWarehouse(res1);
  //       getLocations(res1.warehouseId, 2);
  //     }
  //   } catch (error: any) {
  //     message.error({ content: error.response.data.error });
  //   }
  // }, [selectedWarehouse]);

  useEffect(() => {
    if (selectedReturn && selectedReturn.fulfillmentReturnNum) {
      getPoLines(selectedReturn.fulfillmentReturnNum);
    }
  }, [selectedReturn, getPoLines]);

  useEffect(() => {
    if (
      selectedWarehouse &&
      selectedWarehouse.id ===
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    ) {
      getAllSpecialInfo();
    }
  }, [selectedWarehouse, getAllSpecialInfo]);

  useEffect(() => {
    if (defaultSearch && !inited) {
      getTransferByValue(defaultSearch.trim());
    }
  }, [defaultSearch, getTransferByValue, inited]);

  return (
    <>
      {!isModal && (
        <HeaderTitle
          breadcrumb={[
            'Return',
            `RMA Restock (Warehouse : ${getWarehouseCodeFromCache(
              Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
            )}）`,
          ]}
        />
      )}
      <Spin spinning={initing}>
        {!isModal && (
          <Card style={{ justifyContent: 'center' }}>
            <Row justify="center">
              <Space>
                <>
                  <FormLabel>Warehouse</FormLabel>
                  <SelectWarehouse
                    id="form_warehouse_select"
                    disabled
                    onlyNormal
                    value={selectedWarehouse}
                    width={200}
                    onChange={(warehouse) => {
                      setSelectedWarehouse(warehouse);
                      setSelectedReturn(undefined);
                      setLines([]);
                    }}
                  />
                </>
                <Input.Group compact style={{ width: 'auto', minWidth: 360 }}>
                  <Select
                    id="form_channel_order_select"
                    defaultValue="channelOrderId"
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => setSelectSearchType(value)}
                    style={{ width: '45%' }}
                  >
                    <Select.Option value="channelOrderId">
                      Channel Order ID
                    </Select.Option>
                    <Select.Option value="returnNo">RMA#</Select.Option>
                    <Select.Option value="invoiceNo">Invoice#</Select.Option>
                    {/* <Select.Option value="sku">SKU</Select.Option> */}
                  </Select>
                  <Search
                    id="form_search_value_input"
                    style={{ width: '55%' }}
                    allowClear
                    defaultValue={defaultSearch ? defaultSearch : undefined}
                    loading={loading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 && getTransferByValue(searchRef.current)
                    }
                    onChange={(e) => (searchRef.current = e.target.value)}
                    onSearch={(value) => {
                      getTransferByValue(value.trim());
                    }}
                  />
                </Input.Group>
                <Button
                  id="form_search_list__button"
                  onClick={() => getTransferList()}
                  icon={<UnorderedListOutlined />}
                >
                  Show All Return List
                </Button>
              </Space>
            </Row>
          </Card>
        )}
        <Card style={{ marginTop: isModal ? 0 : 20 }}>
          <Card>
            <Row justify="center" align="middle">
              <Space size={50}>
                <Form.Item label={<FormLabel>Channel Order ID</FormLabel>}>
                  <Text>{selectedReturn?.channelOrderId}</Text>
                </Form.Item>
                <Form.Item label={<FormLabel>RMA#</FormLabel>}>
                  <Text>{selectedReturn?.returnNo}</Text>
                </Form.Item>
                <Form.Item label={<FormLabel>Invoice#</FormLabel>}>
                  <Text>{selectedReturn?.invoiceNo}</Text>
                </Form.Item>
                <Form.Item label={<FormLabel>Status</FormLabel>}>
                  <Text
                    type={selectedReturn?.status === 2 ? 'danger' : 'success'}
                  >
                    {selectedReturn ? statusEnum[selectedReturn.status] : ''}
                  </Text>
                </Form.Item>
              </Space>
            </Row>

            <Row justify="center" align="middle">
              <Space>
                <FormLabel>Restock Date</FormLabel>
                <DatePicker
                  id="form_receipt_data_date_picker"
                  allowClear={false}
                  style={{ marginLeft: 10 }}
                  defaultValue={moment()}
                  format="MM/DD/YYYY"
                  onChange={(date) => setReceiptDate(date || moment())}
                />
                <Button
                  id="form_receive_button"
                  type="primary"
                  disabled={
                    !selectedWarehouse ||
                    !selectedReturn ||
                    selectedReturn.status === 2
                  }
                  onClick={() => {
                    setReceiveDialog(true);
                  }}
                  icon={<DiffOutlined />}
                >
                  Restock
                </Button>
                <Button
                  id="form_scan_to_receive_button"
                  disabled={!selectedReturn}
                  onClick={() => setScanToReceiveDialogVisible(true)}
                >
                  <ButtonIcon className="bi-upc-scan" />
                  Scan to Restock
                </Button>
              </Space>
            </Row>
          </Card>
          <Card
            title={
              <Row align="middle">
                <Space>
                  <Text>Return lines</Text>
                  <TablesFilter
                    columns={columns}
                    dataSource={lines}
                    setFilteredData={setFilteredData}
                  />
                </Space>
              </Row>
            }
            extra={
              <Space>
                <Button
                  id="form_receive_all_as_resaleable_button"
                  disabled={!selectedWarehouse || lines.length === 0}
                  onClick={() => {
                    setAllDamage(false);
                    setReceiveAllDialogVisible(true);
                  }}
                  icon={<SmileOutlined />}
                >
                  Restock All as Resaleable
                </Button>
                <Button
                  id="form_receive_all_as_damage_button"
                  disabled={!selectedWarehouse || lines.length === 0}
                  onClick={() => {
                    setAllDamage(true);
                    setReceiveAllDialogVisible(true);
                  }}
                  icon={<FrownOutlined />}
                >
                  Restock All as Damage
                </Button>
              </Space>
            }
          >
            {selectedWarehouse && (
              <DataGridPlus
                columns={columns}
                loading={loading}
                dataSource={filteredData}
                idProperty="fulfillmentReturnLineNum"
                showColumnMenuTool={false}
                {...({} as any)}
              />
            )}
          </Card>
        </Card>
      </Spin>
      {selectReturnDialogVisible && (
        <SelectPoDialog
          onHide={() => {
            setSelectReturnDialogVisible(false);
          }}
          onOk={(retrurn) => {
            setSelectReturnDialogVisible(false);
            if (retrurn.fulfillmentReturnNum) {
              setSelectedReturn(retrurn);
            }
          }}
          returnList={returnList}
        />
      )}

      {receiveDialog &&
        selectedReturn &&
        selectedWarehouse &&
        specialInfo[2] &&
        specialInfo[3] && (
          <ReceiveDialog
            warehouse={selectedWarehouse}
            lines={lines.filter(
              (item) =>
                (item.stockingLocationCode &&
                  item.stockingQty &&
                  item.stockingLocationNum) ||
                (item.damageingLocationCode &&
                  item.damageingQty &&
                  item.damageingLocationNum)
            )}
            damageWarehouseNum={specialInfo[3]?.warehouseNum}
            returnWarehouseNum={specialInfo[2]?.warehouseNum}
            returnNum={selectedReturn.fulfillmentReturnNum}
            dateString={moment(receiptDate).toString()}
            onRefresh={(newData: any) => {
              if (isModal) {
                setNewData(newData)
                setSuccessDialogVisible(true)
              } else {
                getPoLines(selectedReturn.fulfillmentReturnNum);
              }
              setReceiveDialog(false)
            }}
            onHide={() => setReceiveDialog(false)}
          />
        )}

      {selectedReturn && scanToReceiveDialogVisible && (
        <ScanToReceiveDialog
          // sourceItems={lines}
          targetStrs={lines.map((i) => {
            return {
              sku: i.sku,
              upc: i.upc || ''
            }
          })}
          onHide={() => setScanToReceiveDialogVisible(false)}
          onSuccess={(st) => {
            if (Array.isArray(st)) {
              setLines((prev) => {
                const tmp = prev;
                const skus = prev.map((item) => item.sku);
                st.map((item, index) => {
                  const i = skus.indexOf(item.sku);
                  if (i > -1) {
                    tmp[i].stockingQty = tmp[i].stockingQty
                      ? item.quantity + (tmp[i].stockingQty || 0)
                      : item.quantity;
                  }
                  return true;
                });
                return [...tmp];
              });
            }
            setScanToReceiveDialogVisible(false);
          }}
        />
      )}
      {selectLocationDialogVisible && current && (
        <SelectLocationDialog
          visible={true}
          dataSource={(specialInfo[2]?.locations || []).map((i: any) => {
            return {
              ...i,
              id: i.locationNum,
              code: i.locationCode,
            };
          })}
          onSelect={(value) => {
            setLines((prev) => {
              const temp = prev;
              const index = prev.findIndex(
                (item) =>
                  item.fulfillmentReturnLineNum ===
                  current.fulfillmentReturnLineNum
              );
              if (index > -1) {
                temp[index] = {
                  ...temp[index],
                  stockingLocationCode: value.code,
                  stockingLocationNum: value.id,
                };
                return [...temp];
              } else {
                return temp;
              }
            });
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
          onHide={() => {
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}
      {selectDamageLocationDialogVisible && current && (
        <SelectLocationDialog
          visible={true}
          dataSource={(specialInfo[3]?.locations || []).map((i: any) => {
            return {
              ...i,
              id: i.locationNum,
              code: i.locationCode,
            };
          })}
          onSelect={(value) => {
            setLines((prev) => {
              const temp = prev;
              const index = prev.findIndex(
                (item) =>
                  item.fulfillmentReturnLineNum ===
                  current.fulfillmentReturnLineNum
              );
              if (index > -1) {
                temp[index] = {
                  ...temp[index],
                  damageingLocationCode: value.code,
                  damageingLocationNum: value.id,
                };
                return [...temp];
              } else {
                return temp;
              }
            });
            setSelectDamageLocationDialogVisible(false);
            setCurrent(undefined);
          }}
          onHide={() => {
            setSelectDamageLocationDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}
      {receiveAllDialogVisible && specialInfo[2] && specialInfo[3] && (
        <ReceiveAllDialog
          allDamage={allDamage}
          onHide={() => setReceiveAllDialogVisible(false)}
          onSuccess={(location, allType) => {
            setReceiveAllDialogVisible(false);
            handelReceiveAll(location, allType);
          }}
          warehouseNum={
            allDamage
              ? specialInfo[3].warehouseNum
              : specialInfo[2].warehouseNum
          }
        />
      )}
      {successDialogVisible &&
        <SuccessDialog onOk={(key) => {
          setSuccessDialogVisible(false)
          if (key === 'restock') {
            getPoLines(selectedReturn.fulfillmentReturnNum);
          }
          if (key === 'details') {
            goto && goto('details', newData)
          }
          if (key === 'receive') {
            goto && goto('receive', newData)
          }
          if (key === 'complete') {
            goto && goto('complete', newData)
          }
        }} />
      }
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
    </>
  );
};
