import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import message from './message';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import theme from 'assets/styles/theme';

type Props = {
  value: string;
  id?: string;
};

export default function CopySpan(props: Props) {
  const { value, } = props;

  return (<span>
      <CopyToClipboard
        text={value}
        options={{
          format:'text/plain'
        }}
        onCopy={() => message.info({ content: 'Copied' })}
      >
        <Tooltip placement="top" title="Copy">
          <CopyOutlined
            style={{
              visibility: 'visible',
              color: theme['@info-color'],
            }}
          />
        </Tooltip>
      </CopyToClipboard>
    </span>
  );
}
