import React, { useCallback, useEffect, useState } from 'react';
import { Row, Modal, Button, Space } from 'antd';
import { DataGridPlus, FormLabel } from 'components/common';
import { fetchWarehouseProductList } from 'services/inventory';
import { CloseOutlined } from '@ant-design/icons';

const columns = [
  {
    header: 'SKU',
    name: 'sku',
    minWidth: 220,
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
  },
  {
    header: 'UPC',
    name: 'upc',
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
  },
  {
    header: 'Product Title',
    name: 'productTitle',
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
  },
  {
    header: 'Quantity',
    name: 'quantity',
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
  },
];

type LocationInfoProps = {
  visible: boolean;
  onHide: () => void;
  current: any;
  warehouseId: string;
};
export default function LocationInfoModal(
  props: LocationInfoProps
): JSX.Element {
  const [qtyLoading, setQtyLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [qtyData, setQtyData] = useState<any[]>([]);
  const { visible, onHide, current, warehouseId } = props;

  const getSKUList = useCallback(
    async (top: number = 10, skip: number = 0) => {
      const { locationNum = 0 } = current;
      let query = {
        $skip: skip,
        $top: top,
        $sortBy: 'sku asc',
        // $filter: `LocationCode cn ${locationCode}`,
        $calculateTotal: true,
        $count: true,
        hideZero: true,
        locationNum,
      };
      setQtyLoading(true);
      try {
        const res = await fetchWarehouseProductList(warehouseId, query);
        console.log(res);
        if (res && 'object' === typeof res) {
          setCount(res.count);
          setQtyData(res.data);
        }
      } catch (error) {
        console.log(error);
      }
      setQtyLoading(false);
    },
    [warehouseId, current]
  );

  useEffect(() => {
    getSKUList();
  }, [getSKUList]);

  return (
    <Modal
      title="Location Info"
      centered
      visible={visible}
      width={800}
      destroyOnClose
      onCancel={onHide}
      footer={<Button onClick={() => onHide()} icon={<CloseOutlined />}>Close</Button>}
    >
      {current && (
        <>
          <Row>
            <Space direction="vertical" style={{ flexGrow: 1 }}>
              <Space>
                <FormLabel>Location</FormLabel>
                <Space>{current?.locationCode}</Space>
              </Space>
               {/* <Space>
                <FormLabel>Title</FormLabel>
                <Space>{current?.productTitle}</Space>
              </Space>
              <Space>
                <FormLabel>SKU</FormLabel>
                <Space>{current?.sku}</Space>
              </Space>
              <Space>
                <FormLabel>UPC</FormLabel>
                <Space>{current?.upc}</Space>
              </Space>
              <Space>
                <FormLabel>Quantity</FormLabel>
                <Space> {current?.quantity}</Space>
              </Space> */}
            </Space>
          </Row>
          <DataGridPlus
            style={{ maxHeight: 200 }}
            name="quantity"
            columns={columns}
            dataSource={qtyData}
            loading={qtyLoading}
            pagination
            pageSizes={[10, 20, 30, 50, 100, 200]}
            count={count}
            onChange={(top, skip) => getSKUList(top, skip)}
            {...({} as any)}
          />
        </>
      )}
    </Modal>
  );
}
