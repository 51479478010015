import React from 'react';
import { Modal, Row, Select } from 'antd';
import { v4 as uuid } from 'uuid';
import { ExclamationCircleFilled } from '@ant-design/icons'
import { LoadingIcon, message } from 'components/common';
//import { fetchWarehouseAccountServiceList } from 'services/sales';
import { fetchChannelAccountServiceList } from 'services/sales';
import { ScanToShip } from '.';
import { shipToAddressIsPoBox } from 'utils'

type SelectorProps = {
  channelAccountNum: number;
  disabled?: boolean;
  onChange?: Function;
  serviceCode?: string;
  shipAccountNum?: number;
  shipmentNum: number;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  updateTag?: string;
  warehouseNum: number;
  shipment?: any;
};

const ServiceSelector = (props: SelectorProps) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [shipmentNum, setShipmentNum] = useState(0);
  //const [serviceCode, setServiceCode] = useState<string | undefined>(props.serviceCode);
  const [serviceCode, setServiceCode] = useState<string | undefined>();
  const [selectedValue, setSelectedValue] = useState<string>();
  const [serviceOptions, setServiceOptions] = useState<StringKAnyVPair[]>([]);
  const [shipFrom, setShipFrom] = useState(0);
  const [updateTag, setUpdateTag] = useState<string>();
  const size = props.size || 'middle';
  const style = props.style || {};

  // eslint-disable-next-line
  const checkPropsServiceCode = (services: StringKAnyVPair[]) => {
    //console.log('ready check', serviceCode, props.serviceCode);
    //if (serviceCode === props.serviceCode) return;

    if (props.serviceCode) {
      const sel = findOptionByService(services, props.serviceCode);

      //console.log('pp->', serviceCode, props.serviceCode, sel);
      setServiceCode(props.serviceCode);
      setTimeout(() => {
        if (sel) {
          //setSelectedValue(sel.carrierServiceNum);
          setSelectedValue(uniqueServiceId(sel));

          if (typeof props.onChange === 'function') {
            props.onChange(sel);
          }
        } else {
          setSelectedValue(undefined);

          if (typeof props.onChange === 'function') {
            props.onChange({});
          }
        }
      }, 0);
    }
  };

  const findOptionByService = (
    services: StringKAnyVPair[],
    value: string | number,
  ): StringKAnyVPair | undefined => {
    let found = false;
    let sel: StringKAnyVPair = {};

    services.every((e) => {
      e.items.every((f: StringKAnyVPair) => {
        if (Array.isArray(f.items)) {
          f.items.every((g) => {
            if (tryCheckPropsServiceCode(g, value as string) /*g.carrierServiceCode === value*/ ||
                g.carrierServiceNum === value ||
                uniqueServiceId(g) === value) {
              //sel = g;
              sel = getUserOption(g, f);
              //if (sel) sel.carrier = e.carrier; // set carrier manual
              found = true;

              return false;
            }

            return !found;
          });
        }

        return !found;
      });

      return !found;
    });

    if (found) {
      return sel;
    }
  };

  const getUserOption = (opt: StringKAnyVPair, parent: StringKAnyVPair) => {
    return {
      ...opt,
      billThirdPartyAccount: parent.billThirdPartyAccount,
      billThirdPartyCountry: parent.billThirdPartyCountry,
      billThirdPartyCountryNum: parent.billThirdPartyCountryNum,
      billThirdPartyPostalCode: parent.billThirdPartyPostalCode,
      billToType: parent.billToType,
    };
  };

  // eslint-disable-next-line
  const getServices = async () => {
    try {
      //const services = await fetchAllShipAccountServices();
      //const services = await fetchWarehouseAccountServiceList(
      const services = await fetchChannelAccountServiceList(
        props.warehouseNum,
        props.channelAccountNum,
        1,
      );
      //console.log('-> cc account', props.channelAccountNum);
      setIsLoadingOptions(false);

      if (Array.isArray(services) && services.length > 0) {
        setServiceOptions([...services]);
        checkPropsServiceCode(services);
      }
    } catch (e) {
      message.error(`Fetch services error: ${e}`);
    }
  };

  const serviceSelectGroups = (options: StringKAnyVPair[]) => {
    return options.map((e, i) => (
      <Select.OptGroup label={e.carrier} key={i}>
        {e.items.map((f: StringKAnyVPair) => (
          //<React.Fragment key={f.shipAccountNum}>
          <React.Fragment key={uuid()}>
            {e.items.length > 1 ? (
              <Select.Option
                className="scan-verify-ship-service-account-option"
                disabled
                //key={f.shipAccountNum}
                key={uuid()}
                label={f.shipAccountName}
                value={f.shipAccountNum}
              >
                {f.shipAccountName}
              </Select.Option>
            ) : (
              ''
            )}
            {serviceSelectOptions(f.items)}
          </React.Fragment>
        ))}
      </Select.OptGroup>
    ));
  };

  const serviceSelectOptions = (options: StringKAnyVPair[]) => {
    return options.map((e) => (
      <Select.Option
        className="scan-verify-ship-service-option"
        //key={e.carrierServiceNum}
        key={uuid()}
        label={e.carrierServiceName}
        //value={e.carrierServiceNum}
        value={uniqueServiceId(e)}
      >
        {e.carrierServiceName}
      </Select.Option>
    ));
  };

  /**
   * Callback for selecting a service option.
   */
  const onSelectionChange = (value: any) => {
    const sel = findOptionByService(serviceOptions, value);

    // check ship to address - po box must use usps 
    if(props.shipment && sel && sel.carrierNum !== 3 && shipToAddressIsPoBox(props.shipment)) {
      Modal.confirm({
        title: "It is better to use USPS service for PO Box, are you sure you want to use the current option?",
        icon: <ExclamationCircleFilled />,
        okText: 'No',
        cancelText: 'Yes',
        onOk() {
          setSelectedValue('');
          if (typeof props.onChange === 'function') {
            props.onChange({});
          }
        },
        onCancel() {
          const selection = sel as StringKAnyVPair;  
          setSelectedValue(uniqueServiceId(selection));
    
          if (typeof props.onChange === 'function') {
            props.onChange(sel);
          }
        },
      })
    } else {
      if (sel) {
        const selection = sel as StringKAnyVPair;
  
        //setSelectedValue(selection.carrierServiceNum);
        setSelectedValue(uniqueServiceId(selection));
  
        if (typeof props.onChange === 'function') {
          props.onChange(sel);
        }
      }
    }
  };

  const tryCheckPropsServiceCode = (svr: StringKAnyVPair, svrCode: string) => {
    return props.shipAccountNum ?
      svr.carrierServiceCode === svrCode && svr.shipAccountNum === props.shipAccountNum :
      svr.carrierServiceCode === svrCode;
  };

  const uniqueServiceId = (svr: StringKAnyVPair) => {
    return `${svr.shipAccountNum}_${svr.shipServiceNum}`;
  };

  React.useEffect(() => {
    if (!inited) {
      //setIsLoadingOptions(true);
      //getServices();
      setInited(true);
    }

    //console.log(props.serviceCode, props.shipAccountNum, '<------');
    if (shipmentNum !== props.shipmentNum) {
      setShipmentNum(props.shipmentNum);
      setSelectedValue(undefined);

      if (props.serviceCode && serviceOptions.length > 0) {
        if (serviceCode) {
          /*if (serviceCode === props.serviceCode) {
            setSelectedValue(selectedValue);

            if (typeof props.onChange === 'function') {
              props.onChange(selectedValue);
            }
          } else {
            checkPropsServiceCode(serviceOptions);
          }*/
          if (updateTag !== props.updateTag) {
            console.log(props.serviceCode, '<----', serviceCode, props.shipmentNum);
            checkPropsServiceCode(serviceOptions);
          }
        }
      }
    }

    if (shipFrom !== props.warehouseNum) {
      setShipFrom(props.warehouseNum);

      if (props.warehouseNum > 0) {
        setIsLoadingOptions(true);
        getServices();
      } else {
        // set the options to be null array
      }
    }

    if (updateTag !== props.updateTag) {
      if (serviceOptions.length > 0 /*&&
          serviceCode !== props.serviceCode*/
      ) {
        checkPropsServiceCode(serviceOptions);
      } else {
        setIsLoadingOptions(true);
        getServices();
      }

      setUpdateTag(props.updateTag);
    }
  }, [
    checkPropsServiceCode,
    getServices,
    inited,
    props,
    //selectedValue,
    serviceCode,
    serviceOptions,
    shipFrom,
    shipmentNum,
    updateTag,
  ]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          onChange={onSelectionChange}
          disabled={props.warehouseNum === -1 || props.disabled}
          dropdownClassName="scan-ship-select-dropdown-option-container"
          id={ScanToShip.labelSvrSelectorId}
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {serviceSelectGroups(serviceOptions)}
        </Select>
      )}
    </Row>
  );
};

export default ServiceSelector;
