import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Space } from 'antd';
import axios from 'axios';
import {
  HeaderTitle,
  message as showMessage,
  DeleteButton,
  DataGridPlus,
  TablesFilter,
} from 'components/common';

import {
  fetchTransactionReasonList,
  delTransactionReasonById,
} from 'services/transactionReason';
import { fetchTransactionTypeList } from 'services/transactionType';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { transactionReasonList as dispatchTransactionReasonList } from 'actions/adminActions';
import CreateDialog from './CreateReasonDialog';
import EditDialog from './EditReasonDialog';

type ColumnRenderType = {
  data: TransactionReasonRow;
};
export default function TransactionReasons() {
  const dispatch: Dispatch<any> = useDispatch();
  const [reasonList, setReasonList] = React.useState<TransactionReasonRow[]>(
    []
  );
  const [types, setTypes] = React.useState<TransactionTypeRow[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [createlVisible, setCreatelVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<TransactionReasonRow>();
  const [editVisible, setEditVisible] = React.useState(false);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  // const [limit, setLimit] = React.useState<number>(10);
  // const [skip, setSkip] = React.useState<number>(0);
  //   const [sortInfo, setSortInfo] = React.useState<TypeSortInfo>();

  const requestCancelSource = axios.CancelToken.source();

  const columns = [
    {
      name: 'id',
      header: 'ID',
      defaultVisible: false,
    },
    {
      name: 'typeName',
      header: 'Type',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
    },
    {
      name: 'name',
      header: 'Name',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
    },
    // {
    //   name: 'removeFromHolds',
    //   header: 'Remove from holds',
    //   sortable: false,
    //   maxWidth: 200,
    //   render: (value: ColumnRenderType) => {
    //     const { data } = value;
    //     return (
    //       <Space>
    //         {data.removeFromHolds === true
    //           ? 'Yes'
    //           : data.removeFromHolds === false
    //           ? 'No'
    //           : 'N/A'}
    //       </Space>
    //     );
    //   },
    // },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      autoWidth: false,
      minWidth: 250,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        if (data.masterAccountNum === 0 && data.profileNum === 0) {
          return '';
        } else {
          return (
            <>
              {data.name !== 'Add Item' && data.name !== 'Remove Item' && (
                <Space>
                  <Button
                    onClick={() => {
                      setEditVisible(true);
                      setCurrent(data);
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                  <DeleteButton
                    confirmContent={`name: ${data.name}`}
                    confirmTitle="Are you sure you want to delete the transaction reason ?"
                    onClick={() => delTransactionReason(data)}
                  />
                </Space>
              )}
            </>
          );
        }
      },
    },
  ];

  const getReasonList = async () => {
    setLoading(true);
    try {
      const nextTypes = await fetchTransactionTypeList();
      let reasons = await fetchTransactionReasonList();
      setLoading(false);
      reasons = reasons.map((reason) => {
        const reasonType = nextTypes.filter((item) => item.id === reason.type);
        const typeName = reasonType.length > 0 ? reasonType[0].name : '';
        return {
          ...reason,
          typeName,
        };
      });
      setReasonList(reasons);
      setTypes(nextTypes.filter((i) => i.usage === 2));
      dispatch(dispatchTransactionReasonList(reasons));
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const delTransactionReason = async (reason: TransactionReasonRow) => {
    setLoading(true);
    try {
      const res = await delTransactionReasonById(reason.transactionReasonId);
      if (res) {
        showMessage.success({
          content: `${reason.name} has been deleted`,
        });
        getReasonList();
      } else {
        showMessage.error({ content: 'Delete failed' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /* eslint-disable */
  React.useEffect(() => {
    getReasonList();
    return () => {
      requestCancelSource.cancel();
    };
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Transaction Reasons']}>
        <Button type="primary" onClick={() => setCreatelVisible(true)}>
          <PlusOutlined />
          Create New Transaction Reason
        </Button>
      </HeaderTitle>
      <TablesFilter
        columns={columns}
        dataSource={reasonList}
        setFilteredData={setFilteredData}
      />
      <DataGridPlus
        style={{ minHeight: 500 }}
        idProperty="id"
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        pagination="local"
        {...({} as any)}
      // limit={limit}
      // skip={skip}
      // onLimitChange={setLimit}
      // onSkipChange={setSkip}
      // sortInfo={sortInfo}
      // onSortInfoChange={setSortInfo}
      />
      {createlVisible && (
        <CreateDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => getReasonList()}
          types={types}
        />
      )}
      {editVisible && (
        <EditDialog
          visible
          onHide={() => setEditVisible(false)}
          onRefresh={() => getReasonList()}
          reason={current}
          types={types}
        />
      )}
    </>
  );
}
