import React from 'react';
import { v4 as uuid } from 'uuid';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';

//import { fetchAllShipmentPackages } from 'services/shipment';
//import { fetchShipmentPackageByCarrier } from 'services/shipment';
import { fetchPackageAggregateListByCarrier } from 'services/shipment';
import { ScanToShip } from '.';

type PackageOption = {
  group: string;
} & ShipmentPackageRow;

type SelectorProps = {
  carrier: string;
  carrierNum?: number;
  channelAccountNum?: number;
  disabled?: boolean;
  onChange?: Function;
  packageNum?: number;
  shipmentNum: number;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  updateTag?: string;
  warehouseNum: number;
};

let currentPackageNum: number | undefined = undefined;

const PackageSelector = (props: SelectorProps) => {
  const { useState } = React;
  const { carrier, carrierNum, channelAccountNum, warehouseNum } = props;
  const [currentCarrier, setCurrentCarrier] = useState(0);
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  //const [packageOptions, setPackageOptions] = useState<ShipmentPackageRow[]>(
  const [packageOptions, setPackageOptions] = useState<PackageOption[]>(
    []
  );
  const [selectedValue, setSelectedValue] = useState<number>();
  const [shipmentNum, setShipmentNum] = useState(0);
  const [updateTag, setUpdateTag] = useState<string>();
  const size = props.size || 'middle';
  const style = props.style || {};

  const generatePackageSelectionGroup = (
    //list: ShipmentPackageRow[],
    list: PackageOption[],
    groupLabel: string,
  ) => {
    return list.length > 0 ? (
      <Select.OptGroup label={groupLabel} key={uuid()}>
        {list.map(e => (
          <Select.Option
            className="scan-verify-ship-service-option"
            key={e.shippingPackageNum}
            label={e.name}
            value={e.shippingPackageNum}
          >
            {e.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    ) : '';
  };

  // eslint-disable-next-line
  const getPackages = async () => {
    try {
      //const packages = await fetchAllShipmentPackages();
      //const pkg = await fetchShipmentPackageByCarrier(carrier);
      // const pkg = await fetchPackageAggregateListByCarrier(carrier, warehouseNum);
      const pkg = await fetchPackageAggregateListByCarrier(warehouseNum, carrierNum, channelAccountNum);

      setIsLoadingOptions(false);

      if (pkg) {
        //console.log('pkg', pkg);
        const { carrierPackages, customPackages } = pkg;
        //let packages: ShipmentPackageRow[] = [];
        let packages: PackageOption[] = [];

        if (Array.isArray(carrierPackages)) {
          const tpkgs = carrierPackages.map(e => ({ ...e, group: props.carrier, }));
          //packages = [...packages, ...carrierPackages];
          packages = [...packages, ...tpkgs];
        }

        if (Array.isArray(customPackages)) {
          const tpkgs = customPackages.map(e => ({ ...e, group: 'Custom', }));
          //packages = [...packages, ...customPackages];
          packages = [...packages, ...tpkgs];
        }

        //console.log('pp', packages);
        //packages = packages.filter((e) => e.status);

        if (Array.isArray(packages) && packages.length > 0) {
          setPackageOptions([...packages]);

          //console.log('try sel', currentPackageNum, props);
          /*if (props.packageNum) {
            trySelectPackage(packages, props.packageNum);
          } else {
            trySelectPackage(packages, 0);
          }*/
          trySelectPackage(packages, currentPackageNum);
        }
      }
    } catch (e) {
      message.error(`Fetch packages error: ${e}`);
    }
  };

  //const packageSelectOptions = (options: ShipmentPackageRow[]) => {
  const packageSelectOptions = (options: PackageOption[]) => {
    //const list1 = options.filter((e) => e.shippingCustomPackageNum !== 0);
    //const list2 = options.filter((e) => e.shippingCustomPackageNum === 0);
    const list1 = options.filter((e) => e.group === props.carrier);
    const list2 = options.filter((e) => e.group !== props.carrier);
    //const list = [...list2, ...list1];

    /*return list.map((e) => (
      <Select.Option
        key={e.shippingPackageNum}
        label={e.name}
        value={e.shippingPackageNum}
      >
        {e.name}
      </Select.Option>
    ));*/

    return (
      <>
        {generatePackageSelectionGroup(list1, props.carrier)}
        {generatePackageSelectionGroup(list2, 'Custom')}
      </>
    );
  };

  const onSelectionChange = (value: any) => {
    const sels = packageOptions.filter((e) => e.shippingPackageNum === value);

    console.log('sels pack', sels);
    if (sels.length === 1) {
      setSelectedValue(sels[0].shippingPackageNum);

      if (typeof props.onChange === 'function') {
        props.onChange(sels[0]);
      }
    }
  };

  // eslint-disable-next-line
  const trySelectPackage = (packages: StringKAnyVPair[], packageNum: number | undefined) => {
    const sels = packages.filter((e) => e.shippingPackageNum === packageNum);

    //console.log('try select', packageNum);
    if (sels.length > 0) {
      setSelectedValue(packageNum);

      if (typeof props.onChange === 'function') {
        props.onChange(sels[0]);
      }
    } else {
      setSelectedValue(undefined);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
    }

    currentPackageNum = props.packageNum;

    if (carrierNum && carrierNum !== currentCarrier) {
      setIsLoadingOptions(true);
      setCurrentCarrier(carrierNum);
      getPackages();
    }

    if (shipmentNum !== props.shipmentNum) {
      setShipmentNum(props.shipmentNum);
      setSelectedValue(undefined);

      if (props.packageNum) {
        trySelectPackage(packageOptions, props.packageNum);
      }
    }

    if (updateTag !== props.updateTag) {
      //console.log('==>', packageOptions, props);
      /*if (packageOptions.length > 0 &&
          typeof props.packageNum === 'number' &&
          props.packageNum >= 0
      ) {*/
      if (packageOptions.length > 0) {
        //console.log('trying', props.packageNum, currentPackageNum);
        trySelectPackage(packageOptions, props.packageNum);
      }

      setUpdateTag(props.updateTag);
    }
  }, [
    carrierNum,
    currentCarrier,
    getPackages,
    inited,
    packageOptions,
    props,
    shipmentNum,
    trySelectPackage,
    updateTag,
  ]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          disabled={!carrier || props.disabled}
          dropdownClassName="scan-ship-select-dropdown-option-container"
          id={ScanToShip.labelPkgSelectorId}
          onChange={onSelectionChange}
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {packageSelectOptions(packageOptions)}
        </Select>
      )}
    </Row>
  );
};

export default PackageSelector;
