import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Input,
  Checkbox,
  Select,
  Divider,
  Descriptions,
} from 'antd';
import { FormLabel, SelectZone, message } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  createWarehouseLocationEntity,
  getWarehouseAspects,
} from 'services/warehouse';
import WarehouseLocationSearchBox from './WarehouseLocationSearchBox';

interface CreateProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: (id: any) => void;
  warehouseNum: number;
  warehouseId: string;
}

export default function CreateWarehouseDialog(props: CreateProps) {
  const { visible, onHide, onRefresh, warehouseNum, warehouseId } = props;
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isContainer, setIsContainer] = React.useState(false);
  const [data, setData] = useState<any>([]);
  const [selectedAspect, setSelectedAspect] = useState<any>();

  const [form] = Form.useForm();

  const getAspentList = useCallback(async () => {
    try {
      setDataLoading(true);
      const res = await getWarehouseAspects(warehouseId);
      setDataLoading(false);
      if (res) {
        setData(res);
      }
    } catch (error) {
      setDataLoading(false);
    }
  }, [warehouseId]);

  const handleCreateLocation = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;

    try {
      const entity = {
        LocationCode: params.LocationCode,
        ParentNum: params.ParentNum,
        Quantity: 0,
        Note: params.Note,
        Reserved: params.Reserved,
        Backstock: params.Backstock,
        LocationAspectsNum: params.LocationAspectsNum,
        ZoneNum: params.ZoneNum,
      };
      setLoading(true);
      const newId = await createWarehouseLocationEntity(warehouseId, entity);
      setLoading(false);

      if ('string' !== typeof newId) {
        let msg = `Add Location ${params.LocationCod} failed`;
        if (newId && 'object' === typeof newId) {
          msg = newId.error ? newId.error : msg;
        }
        message.error({ content: msg });

        return;
      }
      message.success(`Add Location ${params.LocationCod} Successfully`)
      onRefresh(newId);
    } catch (e) {
      setLoading(false);
      console.log('created warehouse location error:', e);
    }
  }, [warehouseId, form, onRefresh]);

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  useEffect(() => {
    getAspentList();
  }, [getAspentList]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Add Location"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreateLocation}
              loading={loading}
              icon={<PlusOutlined />}
            >
              {loading ? 'Adding' : 'Add'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading || dataLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form form={form} {...layout} style={{ marginTop: 20 }}>
          <Form.Item
            name="LocationCode"
            label={<FormLabel>Location Code</FormLabel>}
            rules={[{ required: true, message: 'Please input location code!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
            <Checkbox onChange={(e) => setIsContainer(e.target.checked)}>
              Is Container
            </Checkbox>
          </Form.Item>

          {isContainer && (
            <Form.Item
              name="ParentNum"
              label={<FormLabel>Parent Location</FormLabel>}
              rules={[
                { required: true, message: 'Please select a parent location!' },
              ]}
            >
              <WarehouseLocationSearchBox warehouseNum={warehouseNum} />
            </Form.Item>
          )}

          {isContainer && (
            <Form.Item
              wrapperCol={{ span: 16, offset: 8 }}
              name="Reserved"
              valuePropName="checked"
            >
              <Checkbox>Reserve locations</Checkbox>
            </Form.Item>
          )}

          {isContainer && (
            <Form.Item
              wrapperCol={{ span: 16, offset: 8 }}
              name="Backstock"
              valuePropName="checked"
            >
              <Checkbox>Backstock locations</Checkbox>
            </Form.Item>
          )}

          <Form.Item name="Note" label={<FormLabel>Note</FormLabel>}>
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item name="ZoneNum" label={<FormLabel>Location Zone</FormLabel>}>
            <SelectZone warehouseId={warehouseId} />
          </Form.Item>

          <Form.Item
            name="LocationAspectsNum"
            label={<FormLabel>Location Aspect</FormLabel>}
            // rules={[{ required: true, message: 'Please select aspect' }]}
          >
            <Select
              allowClear
              onChange={(value) => {
                const temp = data.filter(
                  (i: any) => i.locationAspectsNum === value
                );
                if (temp.length > 0) {
                  setSelectedAspect(temp[0]);
                } else {
                  setSelectedAspect(undefined);
                }
              }}
            >
              {data.map((i: any) => (
                <Select.Option
                  key={i.locationAspectsNum}
                  value={i.locationAspectsNum}
                >
                  {i.locationAspectsName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {selectedAspect && <Divider />}
          {selectedAspect && (
            <Descriptions title="Aspect Info">
              <Descriptions.Item label="Volume Size">{`${
                selectedAspect.volumeLength || 0
              } x ${selectedAspect.volumeWidth || 0} x ${
                selectedAspect.volumeHeight || 0
              } (${selectedAspect.dimensionUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Capacity Size">{`${
                selectedAspect.capacityLength || 0
              } x ${selectedAspect.capacityWidth || 0} x ${
                selectedAspect.capacityHeight || 0
              } (${selectedAspect.dimensionUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Tare Weight">{`${
                selectedAspect.tareWeight || 0
              } (${selectedAspect.weightUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Max Load">
                {selectedAspect.maxLoad}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}
