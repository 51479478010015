import React from 'react';
import { Row, Space, Typography, Tag } from 'antd';
import dayjs from 'dayjs';

const { Text } = Typography;
type TagProps = {
  type?: string;
  title: string;
  data: any;
  labelField?: string;
  valueField?: string;
  onRemove: (data?: Array<any>) => void;
  noClose?: boolean;
};
export default function TagPlus(props: TagProps): JSX.Element {
  const {
    data,
    labelField,
    valueField,
    title,
    onRemove,
    noClose,
    type = 'list',
  } = props;
  /**
   * handle tag close event
   */
  const handleClose = (tag?: any) => {
    if (type === 'list') {
      const fieldName = valueField || 'id';
      const newData = data.filter((item: any) => {
        if (tag[fieldName] !== item[fieldName]) {
          return true;
        }
        return false;
      });
      onRemove(JSON.parse(JSON.stringify(newData)));
    }
    if (type === 'date') {
      onRemove();
    }
  };
  if (type === 'list' && data.length > 0 && valueField && labelField) {
    return (
      <Row align="middle">
        <Space>
          <Text>{`${title}:`}</Text>
          {data.map((item: any) => {
            return (
              <Tag
                color="#108ee9"
                closable
                onClose={() => {
                  handleClose(item);
                }}
                key={item[valueField]}
              >
                {item[labelField]}
              </Tag>
            );
          })}
        </Space>
      </Row>
    );
  }
  if (type === 'date' && data) {
    return (
      <Row align="middle">
        <Space>
          <Text>{`${title}:`}</Text>
          <Tag
            color="#108ee9"
            closable={noClose ? false : true}
            onClose={() => {
              handleClose();
            }}
          >
            {dayjs(data).format('MM/DD/YYYY hh:mm A')}
          </Tag>
        </Space>
      </Row>
    );
  }
  return <span />;
}
TagPlus.defaultProps = {
  type: undefined,
  labelField: undefined,
  valueField: undefined,
};
