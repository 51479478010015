import React, { useCallback } from 'react';
import { Button, ButtonProps, FormInstance } from 'antd';
import { leaveEditForm } from 'utils';
import { CloseOutlined } from '@ant-design/icons';

interface FormCancelButtonProps extends ButtonProps {
  id?: string;
  title?: string;
  snapshot?: StringKAnyVPair;
  currentform: FormInstance<any>;
}
/**
 * Extend button component
 * Feature:Leave the change form and give a reminder box to stay
 */
export default function FormCancelButton(
  props: FormCancelButtonProps
): JSX.Element {
  const { currentform } = props;
  const {
    title,
    snapshot = currentform.getFieldsValue(),
    onClick,
    ...other
  } = props;
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(
    async (e) => {
      const cancel = await leaveEditForm(snapshot || {}, currentform);
      if (cancel) {
        if (typeof onClick === 'function') {
          onClick(e);
        }
      }
    },
    [onClick, snapshot, currentform]
  );
  return (
    <Button
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      onClick={handleCancel}
      icon={<CloseOutlined />}
    >
      {title || 'Cancel'}
    </Button>
  );
}
FormCancelButton.defaultProps = {
  title: undefined,
  snapshot: undefined,
};
