import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { ButtonIcon } from 'components/common';
import { SmartButton, SmartRow, SmartScanner } from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type OrderFinishDialogProps = {
  order: SmartPickOrder;
  onSubmit: (order: string | undefined) => void;
};
export default function OrderFinishDialog(
  props: OrderFinishDialogProps
): JSX.Element {
  const { order, onSubmit } = props;
  const { t } = useTranslation();
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (value?: string) => {
      onSubmit(value);
    },
    [onSubmit]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    const { fulfillmentOrderNum } = order;
    return (
      <SmartRow justify="center">
        <ButtonIcon className="bi-emoji-smile" />
        <div
          style={{ marginLeft: 5 }}
        >{`${fulfillmentOrderNum} was done!`}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        {t("smart.pick.copywrite")}
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }}>
        <SmartScanner onChangeValue={(value) => handleScannerChange(value)} />
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            handleScannerChange(undefined);
          }}
        >
          {t("smart.pick.nextOrder")}
        </SmartButton>
      </SmartRow>
    </Modal>
  );
}
