import React, { useCallback } from 'react';
import { Button, Modal, Row, Space, Typography, Form } from 'antd';
import { statItemsQuantity, leaveEditForm } from 'utils';
import { ScanningGunInput } from 'components/common';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const { Text } = Typography;
type Props = {
  onHide: () => void;
  onSubmit: (items: { [key: string]: number }) => void;
};

export default function ScanToMoveDialog(props: Props) {
  const { onHide, onSubmit } = props;
  const [form] = Form.useForm();
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const items = statItemsQuantity(form.getFieldValue('skus').split('\n'));
    onSubmit(items);
  }, [form, onSubmit]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);
  const layout = {
    wrapperCol: { span: 24 },
  };
  return (
    <Modal
      title="Scan to Move"
      centered
      visible={true}
      width={650}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button id="continue_btn" key="submit" type="primary" onClick={handleSubmit} icon={<SaveOutlined />}>
              Continue
            </Button>
            <Button id="cancel_btn" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Row>
        <Text>You may move up to 1000 unique SKUS at a time</Text>
      </Row>
      <Form {...layout} form={form} style={{ marginTop: 10 }}>
        <Form.Item
          name="skus"
          label=""
          rules={[{ required: true, message: 'Please input SKUs!' }]}
        >
          <ScanningGunInput
            rows={10}
            onChange={(value) => {
              form.setFieldsValue({ skus: value });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
