import React from 'react';
import { isEqualObject } from 'crud-object-diff';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';
import { sortByString } from 'utils';

import { fetchStoreList } from 'services/storeSetup';

type SelectorProps = {
  onChange?: Function;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  value?: Array<number>;
};
// eslint-disable-next-line
export default (props: SelectorProps) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [accountOptions, setAccountOptions] = useState<StringKAnyVPair[]>([]);
  const [currentValues, setCurrentValues] = useState<number[]>([]);
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const size = props.size || 'middle';
  const style = props.style || {};

  const getPackages = async () => {
    try {
      const res = await fetchStoreList();
      setIsLoadingOptions(false);

      if(res.isSuccess) {
        setAccountOptions([
          ...res.data.filter((i:any) => i.status === 1)
        ])
      }
    } catch (e) {
      message.error(`Fetch packages error: ${e}`);
    }
  };

  const accountSelectOptions = (options: StringKAnyVPair[]) => {
    return options
      .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
      .map((e) => (
        <Select.Option
          key={e.channelAccountNum}
          label={e.channelName}
          value={e.channelAccountNum}
        >
          {e.channelAccountName}
        </Select.Option>
      ));
  };

  const onSelectionChange = (value: number[]) => {
    setSelectedValues(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setIsLoadingOptions(true);
      getPackages();
      setInited(true);
    }

    if (props.value) {
      if (!isEqualObject(props.value, currentValues)) {
        setSelectedValues([...props.value]);
        setCurrentValues([...props.value]);
      }
    }
  }, [currentValues, inited, props, selectedValues]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          dropdownClassName="scan-ship-select-dropdown-option-container"
          mode="multiple"
          onChange={onSelectionChange}
          size={size}
          style={{ width: '100%' }}
          value={selectedValues}
        >
          {accountSelectOptions(accountOptions)}
        </Select>
      )}
    </Row>
  );
};
