import React, { CSSProperties } from 'react';
import { Row, Typography, Checkbox } from 'antd';
import { LockFilled, UnlockFilled } from '@ant-design/icons';

const { Text } = Typography;
type LockerProps = {
  value: boolean;
  label?: string;
  size?: number;
  type?: string;
  style?: CSSProperties;
  onChange: (value: boolean) => void;
};
export default function Locker(props: LockerProps): JSX.Element {
  const { value, onChange, label, size, type, style } = props;
  const isCheckbox = type === 'checkbox';
  if (isCheckbox) {
    return (
      <Checkbox
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      >
        {label}
      </Checkbox>
    );
  }
  return (
    <Row
      onClick={() => onChange(!value)}
      align="middle"
      style={{ cursor: 'pointer', ...style }}
    >
      {value && <LockFilled style={{ color: 'red', fontSize: size || 24 }} />}
      {!value && <UnlockFilled style={{ fontSize: size || 24 }} />}
      <Text style={{ marginLeft: 3 }}>{label}</Text>
    </Row>
  );
}
Locker.defaultProps = {
  label: undefined,
  size: undefined,
  type: undefined,
  style: undefined,
};
