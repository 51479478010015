import styled from 'styled-components';

export const FormItemLeft = styled.div`
    flex-grow: 1;
`
export const LockerBox = styled.div`
    width: 50px;
    padding-left: 10px;
    padding-top: 3px;
`
