import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  Button,
  Space,
  Tooltip,
  Row,
  Modal,
  Checkbox,
  Typography,
  Input,
  Table,
  Tag
} from 'antd';
import {
  CloudUploadOutlined,
  CopyOutlined,
  //FileTextOutlined,
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import {
  ColumnDataType,
  /*DataGridPlus,*/
  GreyCardWrapper,
  HeaderTitle,
  ImportDialog,
  SpaceCell,
  message,
  CopySpan,
  OrderDetailsCell,
  CopyComponent,
  TablesFilter,
} from 'components/common';
import DataGridPlus from 'components/common/DataGridPlus/DataGrid';
import {
  fetchFullOrders,
  fetchFullOrdersFromHistory,
  fetchFullOrdersWithItem,
  getInventoriesBySkus,
  batchHoldOrders,
  batchUnholdOrders,
  syncOrderFromERP
} from 'services/orders';
import ActionsPanel from './ActionsPanel';
// import DataGridPanel from './DataGridPanel';
import StatisticsPanel from './StatisticsPanel';
import {
  COMMON_CARD_WRAPPER_MARGIN,
  COMMON_PADDING_SPACE,
  DATAGRID_CACHE_VIEWORDER_COLS,
  DATAGRID_CELL_ICON_WIDTH1,
  // LS_DEFAULT_WAREHOUSE_KEY,
  // LS_DEFAULT_WAREHOUSE_CODE,
} from 'constants/config';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache, onSelectionChange, getProfileSettingValue, convertTimeByUtc } from 'utils';
// import DeviceSettingDialog from 'components/Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import { RootState } from 'reducers';
import { shallowEqual, useSelector } from 'react-redux';
import {
  orderProcessStatusKeyValue as processStatusKeyValue,
  orderStatusKeyValue,
} from 'constants/enums'

const resultColumns = [
  {
    title: 'Fulfillment Order ID',
    dataIndex: 'fulfillmentOrderId',
    key: 'fulfillmentOrderId',
  },
  {
    title: 'Result',
    dataIndex: 'success',
    key: 'success',
    render: (text: boolean) => (
      <Tag color={text ? 'green' : 'red'}>{text ? 'Success' : 'Failed'}</Tag>
    ),
  },
  {
    title: 'Error Reason',
    dataIndex: 'error',
    key: 'error',
  },
];

/**
 * Return ViewOrders
 * @returns {JSX.Element}
 */
export default function ViewOrders(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false)
  // const whs = useSelector(
  //   (state: RootState) => state.admin.warehouses,
  //   shallowEqual
  // );
  const [entity, setEntity] = useState<OrderEntity>();
  const [selectedRows, setSelectedRows] = useState<Array<OrderRow>>([]);
  const [importDialogVisible, setImportDialogVisible] = React.useState(false);
  const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<Order | undefined>();
  const queryRef = useRef<{ [key: string]: any }>({});
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const sortString = useRef('fulfillmentOrderNum desc');
  const [productDialogVisible, setProductDialogVisible] =
    useState<boolean>(false);
  // const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  // const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [dataType, setDataType] = React.useState<number>(0); // history data: 1
  const [isItemGrid, setIsItemGrid] = React.useState<boolean>(false);
  const pageMark = React.useRef(false);
  const [clickedButton, setClickedButton] = useState<number>(0);
  const [selected, setSelected] = React.useState<any>({});
  const [groupAble, setGroupAble] = React.useState<boolean>(false);
  const [batchLoading, setBatchLoading] = React.useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isSimpleFlow, setIsSimpleFlow] = useState(false);
  const reason = useRef<string>('');
  const userPermissions = useSelector(
    (state: RootState) => state.admin.userPermissions,
    shallowEqual
  );

  const getShipFlow = useCallback(async () => {
    const res = await getProfileSettingValue('ShipFlow', 'ScanFlow')
    setIsSimpleFlow(res === 'SimpleFlow')
  }, [])

  useEffect(() => {
    getShipFlow()
  }, [getShipFlow])


  const onClickChannelOrderID = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setDetailDialogVisible(true);
    }
  };

  const onClickSKU = (data: Order) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      setCurrent(data);
      setProductDialogVisible(true);
    }
  };

  const columns = useMemo(() => {
    const hasAddressPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40021 && i.permissionLevel === 4) : []
    const temp = [
      {
        header: 'Store Name',
        name: 'channelAccountName',
        userSelect: true,
        sortable: true,
        draggable: true,
        resizable: true,
        defaultFlex: 1,
      },
      {
        header: 'Channel Order ID',
        resizable: true,
        draggable: true,
        minWidth: 216,
        name: 'channelOrderId',
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;

          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.channelOrderId);
                      message.info(
                        `"${data.channelOrderId}" has been copied to clipboard`
                      );
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => onClickChannelOrderID(data)}
              text={data.channelOrderId}
              textIsButton
            />
          );
        },
      },
      {
        header: '2nd Channel Order ID',
        name: 'secondaryChannelOrderId',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Seller Order ID',
        name: 'sellerOrderId',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Order Date',
        name: 'originalOrderDate',
        resizable: true,
        draggable: true,
        dataType: ColumnDataType.DATE,
        userSelect: true,
        defaultFlex: 1,
        sort: () => 1,
      },
      {
        header: 'Ship Date',
        name: 'shipDate',
        draggable: true,
        resizable: true,
        dataType: ColumnDataType.DATE,
        defaultFlex: 1,
        sort: () => 1,
      },
      {
        header: 'Process Status',
        name: 'advanceProcess',
        sortable: true,
        draggable: true,
        resizable: true,
        defaultFlex: 1,
        minWidth: 210,
        renderEnum: processStatusKeyValue,
        render: (row: any) => {
          const { data } = row;
          return (
            <CopyComponent
              value={
                data.advanceProcess || data.advanceProcess === 0
                  ? data.advanceProcess === 2 && data?.advanceProcessNote
                    ? data?.advanceProcessNote
                    : processStatusKeyValue[`${data.advanceProcess}`]
                  : ''
              }
            >
              <Typography.Text>
                {data.advanceProcess
                  ? processStatusKeyValue[`${data.advanceProcess}`]
                  : ''}
                {data?.advanceProcessNote && data.advanceProcess !== 1 && (
                  <Tooltip
                    placement="top"
                    title={
                      <Typography.Paragraph style={{ color: 'white' }} copyable>
                        {data?.advanceProcessNote || ''}
                      </Typography.Paragraph>
                    }
                  >
                    <InfoCircleOutlined style={{ marginLeft: 4 }} />
                  </Tooltip>
                )}
              </Typography.Text>
            </CopyComponent>
          );
        },
      },
      {
        header: 'Pick Status',
        name: 'pickStatus',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        renderEnum: {
          '0': 'Not Picked',
          '1': 'Picked',
          '2': 'Partially Picked',
        },
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                {
                  '0': 'Not Picked',
                  '1': 'Picked',
                  '2': 'Partially Picked',
                }[`${data.pickStatus as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Pick Type',
        name: 'pickType',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        renderEnum: {
          '1': 'System',
          '2': 'Manual',
          '0': '-'
        },
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                {
                  '1': 'System',
                  '2': 'Manual',
                  '0': '-'
                }[`${data.pickType as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Pick Date',
        name: 'pickDate',
        resizable: true,
        draggable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        sort: (item: any) => 1,
        render: (row: any) => {
          const { data } = row;
          return data.pickDate === '0001-01-01T00:00:00' ? '-' : convertTimeByUtc(data.pickDate);
        }
      },
      {
        header: 'Pick By',
        name: 'pickBy',
        resizable: true,
        draggable: true,
        userSelect: true,
        defaultFlex: 1,
        minWidth: 150,
        defaultVisible: !isSimpleFlow,
        render: (row: any) => {
          const { data } = row;
          return data.pickBy === null ? '-' : data.pickBy;
        }
      },
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        resizable: true,
        draggable: true,
        userSelect: true,
        defaultFlex: 1,
        minWidth: 150,
        defaultVisible: false,
      },
      {
        header: 'Order Fulfillment Status',
        name: 'orderStatus',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        renderEnum: orderStatusKeyValue,
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                orderStatusKeyValue[`${data.orderStatus as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Sales Division',
        name: 'salesDivision',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Recipient Name',
        name: 'shipToName',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Request Shipping Service',
        name: 'requestShippingService',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Enter Date',
        name: 'enterDate',
        draggable: true,
        resizable: true,
        dataType: ColumnDataType.DATE,
        defaultFlex: 1,
        sort: () => 1,
      },
      {
        header: 'Sales Order Id',
        name: 'erpSalesOrderNumber',
        draggable: true,
        resizable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
      },
      {
        header: 'Ship to Address',
        name: 'shipToAddressLine1',
        defaultVisible: false,
        adressColumn: true,
      },
      {
        header: 'Ship to City',
        name: 'shipToCity',
        defaultVisible: false,
        adressColumn: true,
      },
      {
        header: 'Ship to State',
        name: 'shipToState',
        defaultVisible: false,
        adressColumn: true,
      },
      {
        header: 'Ship to Postal',
        name: 'shipToPostalCode',
        defaultVisible: false,
        adressColumn: true,
        advanced: 204,
      },
      {
        header: 'Ship to Phone',
        name: 'shipToDaytimePhone',
        adressColumn: true,
        defaultVisible: false,
      },
      {
        header: 'Trace ID',
        name: 'traceId',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Fulfillment Order ID',
        name: 'fulfillmentOrderId',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;
          return (
            <OrderDetailsCell
              cellText={data.fulfillmentOrderId}
              rowData={data}
            />
          );
        },
      },
    ];
    return hasAddressPermissions.length > 0 ? [...temp] : temp.filter(i => !i.adressColumn)
  }, [isSimpleFlow, userPermissions]);

  const columnsWithItem = useMemo(() => {
    const hasAddressPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40021 && i.permissionLevel === 4) : []
    const temp = [
      {
        header: 'Store Name',
        name: 'channelAccountName',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
      },
      {
        header: 'Channel Order ID',
        minWidth: 216,
        name: 'channelOrderId',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;

          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.channelOrderId);
                      message.info(
                        `"${data.channelOrderId}" has been copied to clipboard`
                      );
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => onClickChannelOrderID(data)}
              text={data.channelOrderId}
              textIsButton
            />
          );
        },
      },
      {
        header: 'SKU',
        name: 'sku',
        resizable: true,
        sortable: true,
        draggable: true,
        userSelect: true,
        minWidth: 220,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;

          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.sku);
                      message.info(
                        `"${data.sku}" has been copied to clipboard`
                      );
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => onClickSKU(data)}
              text={data.sku}
              textIsButton
            />
          );
        },
      },
      {
        header: 'Order Qty',
        name: 'orderQty',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'In-Stock',
        name: 'stock',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: '2nd Channel Order ID',
        name: 'secondaryChannelOrderId',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Seller Order ID',
        name: 'sellerOrderId',
        resizable: true,
        sortable: true,
        draggable: true,
        userSelect: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Order Date',
        name: 'originalOrderDate',
        resizable: true,
        draggable: true,
        dataType: ColumnDataType.DATE,
        userSelect: true,
        defaultFlex: 1,
        sort: () => 1,
      },
      {
        header: 'Process Status',
        name: 'advanceProcess',
        resizable: true,
        sortable: true,
        draggable: true,
        defaultFlex: 1,
        renderEnum: processStatusKeyValue,
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                processStatusKeyValue[`${data.advanceProcess as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Pick Status',
        name: 'pickStatus',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        renderEnum: {
          '0': 'Not Picked',
          '1': 'Picked',
          '2': 'Partially Picked',
        },
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                {
                  '0': 'Not Picked',
                  '1': 'Picked',
                  '2': 'Partially Picked',
                }[`${data.pickStatus as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Pick Type',
        name: 'pickType',
        resizable: true,
        draggable: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        renderEnum: {
          '1': 'System',
          '2': 'Manual',
          '0': '-'
        },
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                {
                  '1': 'System',
                  '2': 'Manual',
                  '0': '-'
                }[`${data.pickType as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Pick Date',
        name: 'pickDate',
        resizable: true,
        draggable: true,
        defaultFlex: 1,
        defaultVisible: !isSimpleFlow,
        sort: (item: any) => 1,
        render: (row: any) => {
          const { data } = row;
          return data.pickDate === '0001-01-01T00:00:00' ? '-' : convertTimeByUtc(data.pickDate);
        }
      },
      {
        header: 'Pick By',
        name: 'pickBy',
        resizable: true,
        draggable: true,
        userSelect: true,
        defaultFlex: 1,
        minWidth: 150,
        defaultVisible: !isSimpleFlow,
        render: (row: any) => {
          const { data } = row;
          return data.pickBy === null ? '-' : data.pickBy;
        }
      },
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        resizable: true,
        draggable: true,
        userSelect: true,
        defaultFlex: 1,
        minWidth: 150,
        defaultVisible: false,
      },
      {
        header: 'Order Fulfillment Status',
        name: 'orderStatus',
        resizable: true,
        sortable: true,
        draggable: true,
        defaultFlex: 1,
        renderEnum: orderStatusKeyValue,
        render: (row: any) => {
          const { data } = row;
          return (
            <CopySpan
              inGrid
              value={
                orderStatusKeyValue[`${data.orderStatus as string}`] || ''
              }
            />
          );
        },
      },
      {
        header: 'Sales Division',
        name: 'salesDivision',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Recipient Name',
        name: 'shipToName',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Request Shipping Service',
        name: 'requestShippingService',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Enter Date',
        name: 'enterDate',
        resizable: true,
        draggable: true,
        dataType: ColumnDataType.DATE,
        defaultFlex: 1,
        sort: () => 1,
      },
      {
        header: 'Sales Order Id',
        name: 'erpSalesOrderNumber',
        draggable: true,
        resizable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
      },
      {
        header: 'Ship to Address',
        name: 'shipToAddressLine1',
        defaultVisible: false,
        adressColumn:true,
        advanced: 201,
      },
      {
        header: 'Ship to City',
        name: 'shipToCity',
        defaultVisible: false,
        adressColumn:true,
        advanced: 202,
      },
      {
        header: 'Ship to State',
        name: 'shipToState',
        defaultVisible: false,
        adressColumn:true,
        advanced: 203,
      },
      {
        header: 'Ship to Postal',
        name: 'shipToPostalCode',
        defaultVisible: false,
        advanced: 204,
      },
      {
        header: 'Ship to Phone',
        name: 'shipToDaytimePhone',
        defaultVisible: false,
        adressColumn:true,
        advanced: 205,
      },
      {
        header: 'Trace ID',
        name: 'traceId',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        defaultVisible: false,
      },
      {
        header: 'Fulfillment Order ID',
        name: 'fulfillmentOrderId',
        resizable: true,
        draggable: true,
        sortable: true,
        userSelect: true,
        defaultFlex: 1,
        render: (row: any) => {
          const { data } = row;
          return (
            <OrderDetailsCell
              cellText={data.fulfillmentOrderId}
              rowData={data}
            />
          );
        },
      },
    ];
    return hasAddressPermissions.length > 0 ? [...temp] : temp.filter(i => !i.adressColumn)
  }, [isSimpleFlow, userPermissions]);

  /**
   * handle search event
   */
  const handleSearch = useCallback(
    async (
      limit: number,
      skip: number,
      sFrom: number,
      isShowItem: boolean,
      query?: { [key: string]: any }
    ) => {
      console.log(sFrom, isShowItem);
      setSelected({});
      if (query) {
        queryRef.current = query;
        if (query.sortBy) {
          sortString.current = query.sortBy;
        } else {
          sortString.current = 'fulfillmentOrderNum desc';
        }
        skipRef.current = 0;
      } else {
        if (limitRef.current !== limit && pageMark.current) {
          pageMark.current = false;
          return;
        }
        if (limitRef.current !== limit && !pageMark.current) {
          skipRef.current = 0;
          limitRef.current = limit;
          pageMark.current = true;
        } else {
          skipRef.current = skip;
          limitRef.current = limit;
        }
      }
      setLoading(true);
      let newEntity;
      if (sFrom) {
        newEntity = await fetchFullOrdersFromHistory(
          limitRef.current,
          skipRef.current,
          sortString.current,
          queryRef.current
        );
        setIsItemGrid(false);
        setEntity(newEntity);
        setDataType(sFrom);
        setLoading(false);
        setSelectedRows([]);
      } else {
        if (isShowItem) {
          newEntity = await fetchFullOrdersWithItem(
            limitRef.current,
            skipRef.current,
            sortString.current,
            queryRef.current
          );

          const res =
            newEntity.data.length > 0
              ? await getInventoriesBySkus(
                getWarehouseIdFromCache(queryRef.current.warehouseNum),
                // limitRef.current,
                // skipRef.current,
                sortString.current,
                Array.from(new Set(newEntity.data.map((i: any) => i.sku.trim()))).toString()
              )
              : undefined;
          setIsItemGrid(true);
          setLoading(false);
          if (res && res.length > 0) {
            const tempData = newEntity.data.map((i: any) => {
              const temp = res.filter((item: any) => item.sku === i.sku);
              if (temp.length > 0) {
                return {
                  ...i,
                  tempUuid: `${i.channelOrderId}@@@${i.sku}`,
                  stock: temp[0].quantity,
                };
              } else {
                return { ...i, tempUuid: `${i.channelOrderId}@@@${i.sku}` };
              }
            });
            setEntity({ ...newEntity, data: tempData });
          } else {
            setEntity(newEntity);
          }
          setDataType(sFrom);
          setSelectedRows([]);
        } else {
          newEntity = await fetchFullOrders(
            limitRef.current,
            skipRef.current,
            sortString.current,
            queryRef.current
          );
          setLoading(false);
          setIsItemGrid(false);
          setEntity(newEntity);
          setDataType(sFrom);
          setLoading(false);
          setSelectedRows([]);
        }
      }
    },
    [queryRef, limitRef, skipRef, sortString, pageMark]
  );
  /**
   * reload data
   */
  const handleReload = useCallback(async () => {
    setLoading(true);
    //setGridOrderRows([]);
    setSelected({});
    let newEntity;
    if (dataType) {
      newEntity = await fetchFullOrdersFromHistory(
        limitRef.current,
        skipRef.current,
        sortString.current,
        queryRef.current
      );
      setEntity(newEntity);
      setLoading(false);
      setSelectedRows([]);
    } else {
      if (isItemGrid) {
        newEntity = await fetchFullOrdersWithItem(
          limitRef.current,
          skipRef.current,
          sortString.current,
          queryRef.current
        );
        const res =
          newEntity.data.length > 0
            ? await getInventoriesBySkus(
              getWarehouseIdFromCache(queryRef.current.warehouseNum),
              // limitRef.current,
              // skipRef.current,
              sortString.current,
              Array.from(new Set(newEntity.data.map((i: any) => i.sku.trim()))).toString()
            )
            : undefined;
        setLoading(false);
        if (res && res.length > 0) {
          const tempData = newEntity.data.map((i: any) => {
            const temp = res.filter((item: any) => item.sku === i.sku);
            if (temp.length > 0) {
              return {
                ...i,
                tempUuid: `${i.channelOrderId}@@@${i.sku}`,
                stock: temp[0].quantity,
              };
            } else {
              return { ...i, tempUuid: `${i.channelOrderId}@@@${i.sku}` };
            }
          });
          setEntity({ ...newEntity, data: tempData });
        } else {
          setEntity(newEntity);
        }
        setSelectedRows([]);
      } else {
        newEntity = await fetchFullOrders(
          limitRef.current,
          skipRef.current,
          sortString.current,
          queryRef.current
        );
        setEntity(newEntity);
        setLoading(false);
        setSelectedRows([]);
      }
    }
  }, [queryRef, limitRef, skipRef, sortString, dataType, isItemGrid]);

  const batchHold = useCallback(async () => {
    const temp = Array.from(
      new Set(selectedRows.map((i) => i.fulfillmentOrderNum))
    );
    try {
      setBatchLoading(true);
      const res = await batchHoldOrders(temp, reason.current);
      setBatchLoading(false);
      if (res) {
        // message.success('Batch hold orders successfully');
        // {fulfillmentOrderNum: 1003476, success: true, error: null}
        Modal.info({
          title: 'Batch Hold Finished',
          width: 600,
          content: (
            <Table
              size="small"
              dataSource={res}
              columns={resultColumns}
              pagination={false}
            />
          ),
          onOk() { },
        });
        handleSearch(limitRef.current, skipRef.current, 0, false);
      }
    } catch (error) {
      setBatchLoading(false);
    }
  }, [handleSearch, selectedRows, reason]);

  const batchUnhold = useCallback(async () => {
    const temp = Array.from(
      new Set(selectedRows.map((i) => i.fulfillmentOrderNum))
    );
    try {
      setBatchLoading(true);
      const res = await batchUnholdOrders(temp, reason.current);
      setBatchLoading(false);
      if (res) {
        // message.success('Batch unhold orders successfully');
        Modal.info({
          title: 'Batch Unhold Finished',
          width: 600,
          content: (
            <Table
              size="small"
              dataSource={res}
              columns={resultColumns}
              pagination={false}
            />
          ),
          onOk() {
            setBatchLoading(false);
          },
        });
        handleSearch(limitRef.current, skipRef.current, 0, false);
      }
    } catch (error) { }
  }, [handleSearch, selectedRows, reason]);

  const syncOrder = useCallback(async ()=>{
    try {
      setSyncLoading(true)
      const res = await syncOrderFromERP()
      setSyncLoading(false)
      if(res.isSuccess) {
        message.success(res.data)
      }
    } catch (error) {
      setSyncLoading(false)
    }
  },[])

  const renderRowContextMenu = (menuProps: any, context: any) => {
    const { cellProps } = context;
    //menuProps.autoDismiss = true;

    if (cellProps.id === 'channelOrderId') {
      menuProps.items.push({
        label: 'Details',
        onClick: () => {
          const { rowProps } = context;

          setCurrent(rowProps.data);
          setDetailDialogVisible(true);
        },
      });
    }
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Sales Order', 'View Orders']}>
        <Space>
        <Button
            id="syncOrd_btn"
            type="default"
            onClick={() => syncOrder()}
          >
            <SyncOutlined spin={syncLoading} />
            Sync Order from ERP
          </Button>
          <Button
            id="importOrd_btn"
            type="default"
            disabled={dataType === 1}
            onClick={() => setImportDialogVisible(true)}
          >
            <CloudUploadOutlined />
            Import Order
          </Button>
        </Space>
      </HeaderTitle>
      <GreyCardWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ActionsPanel
          selectedRows={selectedRows}
          onResetClickButton={() => setClickedButton(0)}
          warehouseCode={getWarehouseCodeFromCache(
            queryRef.current.warehouseNum
          )}
          exportdata={entity}
          columns={isItemGrid ? columnsWithItem : columns}
          loading={loading}
          clickedButton={clickedButton}
          onSearch={handleSearch}
          onReaload={handleReload}
        />
      </GreyCardWrapper>
      {entity && (
        <GreyCardWrapper
          style={{
            marginTop: COMMON_CARD_WRAPPER_MARGIN,
          }}
        >
          <Row
            justify="space-between"
            style={{ marginBottom: COMMON_PADDING_SPACE }}
          >
            <Space>
              <StatisticsPanel entity={entity} />
              <Checkbox
                id="groupToolbar_checkbox"
                onChange={(e) => setGroupAble(e.target.checked)}
              >
                Group Toolbar
              </Checkbox>
              <TablesFilter
                columns={columnsWithItem}
                dataSource={entity.data}
                setFilteredData={setFilteredData}
              />
            </Space>
            <Space>
              {queryRef.current && queryRef.current.orderStatus === '8' && (
                <Button
                  id="unhold_btn"
                  loading={batchLoading}
                  disabled={selectedRows.length === 0 || dataType === 1}
                  onClick={() => {
                    Modal.confirm({
                      title:
                        'Unhold selected orders. Are you sure to continue？',
                      okText: 'Yes',
                      cancelText: 'No',
                      content: (
                        <Space direction="vertical">
                          <Typography.Text>Unhold Reason</Typography.Text>
                          <Input.TextArea
                            id="set_unhold_reason"
                            rows={2}
                            onChange={(event) =>
                              (reason.current = event.target.value)
                            }
                          />
                        </Space>
                      ),
                      onCancel() {
                        reason.current = '';
                      },
                      onOk() {
                        batchUnhold();
                      },
                    });
                  }}
                >
                  Unhold Orders
                </Button>
              )}

              {queryRef.current && queryRef.current.orderStatus === '0' && (
                <Button
                  id="hold_btn"
                  loading={batchLoading}
                  disabled={selectedRows.length === 0 || dataType === 1}
                  onClick={() => {
                    Modal.confirm({
                      title: 'Hold selected orders. Are you sure to continue？',
                      okText: 'Yes',
                      cancelText: 'No',
                      content: (
                        <Space direction="vertical">
                          <Typography.Text>Hold Reason</Typography.Text>
                          <Input.TextArea
                            id="set_hold_reason"
                            rows={2}
                            onChange={(event) =>
                              (reason.current = event.target.value)
                            }
                          />
                        </Space>
                      ),
                      onCancel() {
                        reason.current = '';
                      },
                      onOk() {
                        batchHold();
                      },
                    });
                  }}
                >
                  Hold Orders
                </Button>
              )}

              <Button
                id="unQueue_btn"
                disabled={selectedRows.length === 0 || dataType === 1}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure to continue？',
                    icon: <ExclamationCircleOutlined />,
                    // content: 'Un-queue selected orders',
                    content: <span style={{ color: theme['@danger-color'], fontWeight: 'bold' }}>This action will void the selected orders on papers already printed.</span>,
                    okText: 'Yes',
                    onOk() {
                      setClickedButton(4);
                    },
                    onCancel() {
                      return;
                    },
                  });
                }}
              >
                Un-queue Orders
              </Button>
              {/* <Button
                id="changeTags_btn"
                disabled={selectedRows.length === 0 || dataType === 1}
                onClick={() => {
                  setClickedButton(2);
                }}
              >
                Change Tags
              </Button> */}
              <Button
                id="retry_btn"
                disabled={selectedRows.length === 0 || dataType === 1}
                onClick={() => {
                  // setSelectTagsDialogVisible(true);
                  Modal.confirm({
                    title: 'Are you sure to continue？',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Retry Advance Process',
                    okText: 'Yes',
                    onOk() {
                      setClickedButton(3);
                    },
                    onCancel() {
                      return;
                    },
                  });
                }}
              >
                Retry Order Process
              </Button>

              {queryRef.current && queryRef.current.orderStatus === '0' && <Button
                id="retry_service_mapping_btn"
                disabled={selectedRows.length === 0 || dataType === 1}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure to continue？',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Retry service mapping selected orders',
                    okText: 'Yes',
                    onOk() {
                      setClickedButton(5);
                    },
                    onCancel() {
                      return;
                    },
                  });
                }}
              >
                Retry Service Mapping
              </Button>
              }
            </Space>
          </Row >
          {isItemGrid && (
            <DataGridPlus
              cacheKey={DATAGRID_CACHE_VIEWORDER_COLS}
              name="viewOrdersItem"
              skip={skipRef.current}
              showScrollButton
              autoWith={false}
              disableGroupByToolbar={!groupAble}
              checkboxColumn
              checkboxOnlyRowSelect
              loading={loading}
              idProperty={'tempUuid'}
              columns={columnsWithItem}
              dataSource={filteredData}
              count={entity.count}
              pagination
              pageSizes={[10, 50, 200, 500, 1000]}
              onChange={(limit, skip) => handleSearch(limit, skip, 0, true)}
              selected={selected}
              // onSelectionChange={(config: any) => {
              //   const { selected: rows, data, unselected } = config;
              //   setSelected(rows);
              //   if (rows === true && !unselected) {
              //     setSelectedRows(data as any);
              //   } else {
              //     if (!unselected) {
              //       setSelectedRows(Object.values(rows as any));
              //     } else {
              //       setSelectedRows((prev) => {
              //         return prev.filter(
              //           (item) =>
              //             item.fulfillmentOrderId !==
              //             (data as any).fulfillmentOrderId
              //         );
              //       });
              //     }
              //   }
              // }}
              onSelectionChange={props => onSelectionChange(props, entity.data, setSelected, setSelectedRows, 'tempUuid')}
              rowContextMenu={renderRowContextMenu}
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          )
          }
          {
            !isItemGrid && (
              <DataGridPlus
                cacheKey={DATAGRID_CACHE_VIEWORDER_COLS}
                name="viewOrders"
                skip={skipRef.current}
                showScrollButton
                autoWith={false}
                disableGroupByToolbar={!groupAble}
                checkboxColumn
                checkboxOnlyRowSelect
                loading={loading}
                // idProperty="channelOrderId"
                idProperty="fulfillmentOrderId"
                columns={columns}
                dataSource={filteredData}
                count={entity.count}
                pagination
                pageSizes={[10, 50, 200, 500, 1000]}
                onChange={handleSearch}
                // selected={selectedRows}
                selected={selected}
                // onSelectionChange={(config: any) => {
                //   const { selected: rows, data, unselected } = config;
                //   setSelected(rows);
                //   if (rows === true && !unselected) {
                //     setSelectedRows(data as any);
                //   } else {
                //     if (!unselected) {
                //       setSelectedRows(Object.values(rows as any));
                //     } else {
                //       setSelectedRows((prev) => {
                //         return prev.filter(
                //           (item) =>
                //             item.fulfillmentOrderId !==
                //             (data as any).fulfillmentOrderId
                //         );
                //       });
                //     }
                //   }
                // }}
                onSelectionChange={props => onSelectionChange(props, entity.data, setSelected, setSelectedRows, 'fulfillmentOrderId')}
                rowContextMenu={renderRowContextMenu}
                /* eslint-disable react/jsx-props-no-spreading */
                {...({} as any)}
              />
            )
          }
        </GreyCardWrapper >
      )}
      {
        importDialogVisible && (
          <ImportDialog
            onRefesh={() => { }}
            onHide={() => setImportDialogVisible(false)}
            operation={{ key: 2001, value: 'OrderImport', name: 'Order Import' }}
            showModal
          />
        )
      }
      {
        detailDialogVisible && current && (
          <OrderDetail
            allowPopupWindow
            isHistory={dataType === 1}
            fulfillmentOrderId={current.fulfillmentOrderId}
            fulfillmentOrderNum={current.fulfillmentOrderNum}
            onClose={() => setDetailDialogVisible(false)}
          />
        )
      }
      {
        productDialogVisible && current && (
          <ProductDetail
            visible
            onHide={() => setProductDialogVisible(false)}
            productId={current.fulfillmentProductId || ''}
          />
        )
      }
      {/* {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      } */}
    </>
  );
}
