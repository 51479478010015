import { Button, Row } from 'antd';
import React, { useCallback, useState } from 'react';
import { queryCheckoutProducts } from 'services/reports';
import {
  //   ExportCSV,
  DataGridPlus,
  SectionWrapper,
  SelectWarehouse,
  HeaderTitle,
  FormLabel,
  ButtonIcon,
} from 'components/common';
import { Select } from 'antd';
import DetailDialog from './DetailDialog';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import { SearchOutlined } from '@ant-design/icons';
import { userProfiles } from 'utils/auth';

type ColumnRenderType = {
  data: WarehouseRow;
};

const filterValue = [
  { name: 'sku', operator: 'startsWith', type: 'string', value: '' },
  { name: 'quantity', operator: 'gte', type: 'number', value: undefined },
];

export default function LocationReport(): JSX.Element {
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [reports, setReports] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [current, serCurrent] = useState<any>();
  const [specialLocation, setSpecialLocation] = useState(1);
  const [detailDialogVisible, setDetailDialogVisible] = useState(false);

  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM") === '1';

  document.body.style.overflowY = 'unset';

  const columns = [
    {
      header: 'SKU',
      name: 'sku',
      userSelect: true,
      defaultFlex: 1,
      minWidth: 220,
    },
    {
      header: 'LOT#',
      name: 'lotNumber',
      userSelect: true,
      defaultFlex: 1,
      visible: enableLOT
    },
    {
      header: 'UOM',
      name: 'uom',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      header: 'Rate',
      name: 'uomRate',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      header: 'Base Qty',
      name: 'uomBaseQty',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    // {
    //   header: 'Location',
    //   name: 'locationCode',
    //   defaultFlex: 1,
    // },
    {
      header: 'Quantity',
      name: 'quantity',
      type: 'number',
      userSelect: true,
      filterEditor: NumberFilter,
      defaultFlex: 1,
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      headerAlign: 'center',
      minWidth: 100,
      // maxWidth: 100,
      sortable: false,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <Button
            type="default"
            onClick={() => {
              serCurrent(data);
              setDetailDialogVisible(true);
            }}
            icon={<ButtonIcon className="bi bi-file-earmark-text" />}
          >
            Detail
          </Button>
        );
      },
    },
  ];
  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    if (warehouse) {
      setLoading(true);
      try {
        const entity = await queryCheckoutProducts(
          warehouse.warehouseId,
          specialLocation
        );
        setReports(
          entity.map((item: any) => {
            return {
              ...item,
              code: warehouse.code,
              warehouseId: warehouse.warehouseId,
            };
          })
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }, [warehouse, specialLocation]);

  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Special Inventory']} />
      <SectionWrapper
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Row>
          <Row align="middle">
            <FormLabel>Warehouse</FormLabel>
            <SelectWarehouse
              style={{ marginLeft: 5 }}
              width={300}
              value={warehouse}
              onChange={(nextWarehouse) => {
                setWarehouse(nextWarehouse);
              }}
            />
          </Row>
          <Row align="middle" style={{ marginLeft: 10 }}>
            <FormLabel>Special Location</FormLabel>
            <Select
              defaultValue={1}
              style={{ width: 300, marginLeft: 5 }}
              onChange={(value) => setSpecialLocation(Number(value))}
            >
              <Select.Option value={1}>Checkout</Select.Option>
              <Select.Option value={2}>Pick</Select.Option>
              <Select.Option value={3}>Pick Missing</Select.Option>
            </Select>
          </Row>
          <Button
            loading={loading}
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={handleSearch}
          >
            <SearchOutlined />
            <span>Search</span>
          </Button>
        </Row>
      </SectionWrapper>
      <SectionWrapper>
        <DataGridPlus
          style={{ minHeight: 500 }}
          autoWith={false}
          defaultFilterValue={filterValue}
          columns={columns}
          dataSource={reports}
          pageSizes={[10, 20, 50, 100]}
          columnUserSelect={true}
          loading={loading}
          idProperty="inventoryNum"
          pagination="local"
          showColumnMenuTool={false}
          {...({} as any)}
        />
      </SectionWrapper>
      {detailDialogVisible && current && (
        <DetailDialog
          visible
          onHide={() => setDetailDialogVisible(false)}
          report={current}
        />
      )}
    </>
  );
}
