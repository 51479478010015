import React from 'react';
import { Row, Spin, Modal, Button, Space, DatePicker, Form, Card } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
//import dayjs from 'dayjs';
import moment from 'moment';
import { ColumnDataType, DataGridPlus, FormLabel } from 'components/common';
import { queryCheckoutDetail } from 'services/reports';
import { convertToUtcTime } from 'utils';

const { RangePicker } = DatePicker;

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  report: any;
}
const UserComponent = (props: CreateBrandProps) => {
  const { visible, onHide, report } = props;
  const [loading, setLoading] = React.useState(false);
  const [transationList, setTransationList] = React.useState<any[]>([]);

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const columns = [
    // {
    //   name: 'poNumber',
    //   header: 'PONumber',
    //   defaultFlex: 1,
    //   headerAlign: 'center',
    // },
    {
      dataType: ColumnDataType.DATE,
      name: 'transactionDateTime',
      header: 'DateTime',
      textAlign: 'center',
      userSelect: true,
      defaultFlex: 1,
      /*render: (value: any) => {
        const { data } = value;
        return data.transactionDateTime
          ? dayjs(data.transactionDateTime).format('MM/DD/YYYY hh:mm')
          : '';
      },*/
    },
    {
      name: 'locationCode',
      header: 'Location',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'beforeQuantity',
      header: 'Prior Quantity',
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
      // headerAlign: 'end',
    },
    {
      name: 'transactionQuantity',
      header: 'Transaction Quantity',
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
      // headerAlign: 'end',
    },
    {
      name: 'afterQuantity',
      header: 'After Quantity',
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
      // headerAlign: 'end',
    },
    {
      name: 'user',
      header: 'User',
      userSelect: true,
      defaultFlex: 1,
    },
  ];

  const getDetailList = async () => {
    const { queryDate } = form.getFieldsValue();
    try {
      setLoading(true);
      const res = await queryCheckoutDetail(
        report.warehouseId,
        report.fulfillmentProductNum,
        convertToUtcTime(
          queryDate[0].startOf('day').format() ||
          moment().subtract(6, 'days').startOf('day').format()
        ),
        convertToUtcTime(
          queryDate[1].endOf('day').format() || moment().format()
        )
      );
      setTransationList(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  /* eslint-disable */
  React.useEffect(() => {
    if (report) {
      getDetailList();
    }
  }, [report]);
  /* eslint-enable */

  return (
    <Modal
      title="Special Inventory Detail"
      centered
      visible={visible}
      bodyStyle={{ padding: 0 }}
      maskClosable={false}
      width={1000}
      destroyOnClose={true}
      onCancel={() => {
        document.body.style.overflowY = 'unset';
        onHide();
      }}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                document.body.style.overflowY = 'unset';
                onHide();
              }}
              icon={<CloseOutlined />}
            >
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item name="warehouse" label={<FormLabel>Warehouse</FormLabel>}>
            <span>{report.code}</span>
          </Form.Item>
          <Form.Item name="product" label={<FormLabel>SKU</FormLabel>}>
            <span>{report.sku}</span>
          </Form.Item>
          <Form.Item
            name="queryDate"
            initialValue={[
              moment().subtract(6, 'days').startOf('day'),
              moment(),
            ]}
            label={<FormLabel>Query By Date</FormLabel>}
          >
            <RangePicker
              getPopupContainer={(triggerNode: any) => triggerNode.parentNode}
              format="MM/DD/YYYY"
              showTime
              style={{ width: '100%' }}
              ranges={{
                Today: [moment().startOf('day'), moment().endOf('day')],
                Yesterday: [
                  moment().add(-1, 'day').startOf('day'),
                  moment().add(-1, 'day').endOf('day'),
                ],
                '2 days ago': [
                  moment().add(-2, 'day').startOf('day'),
                  moment().endOf('day'),
                ],
                '3 days ago': [
                  moment().add(-3, 'day').startOf('day'),
                  moment().endOf('day'),
                ],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'Last Week': [
                  moment().add(-1, 'week').startOf('week'),
                  moment().add(-1, 'week').endOf('week'),
                ],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
              }}
            />
          </Form.Item>
          <Form.Item
            name="search"
            initialValue={[
              moment().subtract(6, 'days').startOf('day'),
              moment(),
            ]}
            label={<FormLabel noColon>{''}</FormLabel>}
          >
            <Button
              type="primary"
              loading={loading}
              onClick={() => getDetailList()}
            >
              Search
            </Button>
          </Form.Item>
        </Form>
        <Card size="small">
          <DataGridPlus
            style={{ minHeight: 300, maxHeight: 400 }}
            columns={columns}
            columnUserSelect={true}
            dataSource={transationList}
            idProperty="id"
            pagination="local"
            showColumnMenuTool={false}
            {...({} as any)}
          />
        </Card>
      </Spin>
    </Modal>
  );
};

export default UserComponent;
