import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  // EditOutlined,
  EllipsisOutlined,
  UpOutlined,
  DownOutlined,
  FileExcelOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  // CopySpan,
  DataGridPlus,
  FormLabel,
  TablesFilter,
  message,
} from '../../../components/common';
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Space,
  Modal,
  // Input,
  Collapse,
  Empty,
  Typography,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import {
  getUnpackLines,
  cancelUnpack,
  completeUnpack,
  getUnpackProcessLines,
  delUnpackProcessLine,
  getUnpackByNum,
} from 'services/unpack';
// import EditQtyDialog from './EditLineDialog';
import PrintDialog from './printDialog';
import SelectSKUDialog from './SelectPrintSkuDialog';
import { fetchPrepackSettings } from 'services/prepack';
import Text from 'antd/lib/typography/Text';
import { unpackStatusKeyValue as statusEnum } from 'constants/enums'

const { Panel } = Collapse;

const TCollapse = styled(Collapse)`
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    position: relative;
    padding: 0px 16px;
    padding-right: 40px;
  }
`;

interface Props extends ModalProps {
  onClose: Function;
  unpack: Unpack;
  onProcess: Function;
}

const SYNC_STATUS_MAP: { [key: string]: string } = {
  '0': 'Not sync',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Wait for response'
}

const ProductDetailDialog = (props: Props) => {
  const { unpack, onProcess } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [current, setCurrent] = useState<UnpackProcessLine>();
  // const [filterValue, setFilterValue] = useState<string>();
  const [info, setInfo] = useState<Unpack>();
  const [lines, setLines] = useState<any>([]);
  const [filterlines, setFilterlines] = useState<any>([]);
  // const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [processLines, setProcessLines] = useState<UnpackProcessLine[]>([]);
  const [printDialogVisible, setPrintDialogVisible] = useState(false);
  const [selectSKUDialogVisible, setSelectSKUDialogVisible] = useState(false);
  const [enableCSN, setEnableCSN] = useState(false);

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  // const debounceFn = debounce((val: string) => {
  //   setFilterValue(val);
  // }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const fetchProcessLines = useCallback(async (fulfillmentPackNum: number) => {
    try {
      const res = await getUnpackProcessLines(fulfillmentPackNum);
      if (res) {
        setProcessLines(res);
      }
    } catch (error) {}
  }, []);

  const fetchLines = useCallback(async (id: number) => {
    const res = await getUnpackLines(id);
    if (res) {
      setLines([...res]);
    }
  }, []);

  const deleteLine = useCallback(
    async (fulfillmentPackNum: number, fulfillmentUnpackProcessNum: number) => {
      try {
        const res = await delUnpackProcessLine(
          fulfillmentPackNum,
          fulfillmentUnpackProcessNum
        );
        if (res) {
          message.success('Delete Successfully');
          setDeleteDialogVisible(false)
          fetchLines(fulfillmentPackNum);
          fetchProcessLines(fulfillmentPackNum);
        }
      } catch (error) {}
    },
    [fetchProcessLines, fetchLines]
  );

  const getTempLineColumns = useCallback((showCSNumber: boolean) => {
    const temp = [
      {
        name: 'fulfillmentUnpackProcessNum',
        type: 'string',
        header: 'Id',
        defaultVisible: false,
      },
      // {
      //   name: 'sku',
      //   header: 'SKU',
      //   minWidth: 150,
      //   showColumnMenuTool: false,
      //   userSelect: true,
      //   defaultFlex: 1,
      // },
      // {
      //   name: 'upc',
      //   header: 'UPC',
      //   minWidth: 150,
      //   showColumnMenuTool: false,
      //   userSelect: true,
      //   defaultFlex: 1,
      // },
      {
        name: 'caseSerialNumber',
        header: 'CS#',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'unpackQty',
        header: 'Qty',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'sourceLocationCode',
        header: 'From',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'destinationLocationCode',
        header: 'To',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Dropdown
              overlay={
                <Menu>
                  {/* <Menu.Item key="0">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setEditQtyDialogVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Menu.Item> */}
                  <Menu.Item key="1">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
                      type="text"
                      // disabled={data.receiveQty !== 0}
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setDeleteDialogVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ];
    return showCSNumber
      ? temp
      : temp.filter((i) => i.name !== 'caseSerialNumber');
  }, []);

  const refreshInfo = useCallback(async (fulfillmentPackNum) => {
    try {
      const res = await getUnpackByNum(fulfillmentPackNum);
      if (res) {
        setInfo(res);
      }
    } catch (error) {}
  }, []);

  const handleComplete = async (id: number) => {
    try {
      const res = await completeUnpack(id);
      if (res) {
        message.success('Complete successfully');
        //todo refresh
        refreshInfo(id);
      }
    } catch (error) {}
  };

  const handleCancel = async (id: number) => {
    try {
      const res = await cancelUnpack(id);
      if (res) {
        message.success('cancel successfully');
        //todo refresh
        refreshInfo(id);
      }
    } catch (error) {}
  };

  const getPrepackSetting = useCallback(async () => {
    try {
      const res = await fetchPrepackSettings();
      if (res.isSuccess) {
        if (res.data.enableCSN && res.data.enableCSN === 1) {
          setEnableCSN(true);
        }
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (unpack) {
      setInfo(unpack);
      getPrepackSetting();
      fetchLines(unpack.fulfillmentPackNum);
      fetchProcessLines(unpack.fulfillmentPackNum);
    }
  }, [unpack, fetchLines, fetchProcessLines, getPrepackSetting]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Prepack: Unpack ${info?.upackCode} `}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <Card
            size="small"
            extra={
              <Space>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="11">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setPrintDialogVisible(true);
                          }}
                        >
                          Print Pick List
                        </Button>
                      </Menu.Item>
                      <Menu.Item key="12">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setSelectSKUDialogVisible(true);
                          }}
                        >
                          Print SKU Label
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<PrinterOutlined />}>Print</Button>
                </Dropdown>
                {info && (info?.status === 0 || info?.status === 1) && (
                  <Button type="primary" onClick={() => onProcess()}>
                    Process
                  </Button>
                )}
                {info && info?.status === 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: info.requestQty > info.unpackQty ? 'Not quite finished yet. Are you sure you want to complete the unpack ?' :
                        'Are you sure you want to complete the unpack ?',
                        content:  <Space direction="vertical">
                        <Text>{`ERP Request#: ${info.upackCode}`}</Text>
                        <Text>{`Request Qty: ${info.requestQty}`}</Text>
                        <Text>{`Processed Qty: ${info.unpackQty}`}</Text>
                      </Space>,
                        okText: 'Yes',
                        okType: 'default',
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onOk() {
                          handleComplete(info.fulfillmentPackNum);
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Complete
                  </Button>
                )}
                {info && info?.status === 0 && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <Button
                            type="text"
                            style={{ width: '100%', textAlign: 'left' }}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                              Modal.confirm({
                                title:
                                  'Are you sure you want to cancel the unpack ?',
                                content: `ERP Request#: ${info.upackCode}`,
                                okText: 'Yes',
                                okType: 'default',
                                okButtonProps: {
                                  className: 'hover-danger-button',
                                },
                                cancelText: 'No',
                                autoFocusButton: 'cancel',
                                onOk() {
                                  handleCancel(info.fulfillmentPackNum);
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <EllipsisOutlined />
                  </Dropdown>
                )}
              </Space>
            }
          >
            <Row style={{ width: '100%' }}>
              <Col span={6}>
                <FormLabel>Request#</FormLabel>
                {info?.upackCode}
              </Col>
              <Col span={6}>
                <FormLabel>Description</FormLabel>
                {info?.description}
              </Col>
              <Col span={6}>
                <FormLabel>Requested by</FormLabel>
                {info?.createBy}
              </Col>
              <Col span={6}>
                <FormLabel>Status</FormLabel>
                {statusEnum[`${info?.status}`]}
              </Col>
              <Col span={6}>
                <FormLabel>Start Date</FormLabel>
                {moment(info?.startDate).format('MM/DD/YYYY')}
              </Col>
              <Col span={6}>
                <FormLabel>End Date</FormLabel>
                {moment(info?.endDate).format('MM/DD/YYYY')}
              </Col>
              <Col span={6}>
                <FormLabel>Sync Status</FormLabel>
                {SYNC_STATUS_MAP[`${info?.syncStatus || 0}`]}
              </Col>
              <Col span={6}>
                <FormLabel>Printed at</FormLabel>
                  {info?.printAt ? moment(info?.printAt).format('MM/DD/YYYY') : ''}
              </Col>
              <Col span={6}>
                <FormLabel>Printed by</FormLabel>
                {info?.printBy}
              </Col>
              <Col span={6}>
                <FormLabel>Note</FormLabel>
                {info?.note}
              </Col>
            </Row>
          </Card>

          <Card
            size="small"
            style={{ marginTop: 12 }}
            title={
              <TablesFilter
                dataSource={lines}
                setFilteredData={setFilterlines}
                columns={[{ name: 'sku' }, { name: 'upc' }]}
              />
            }
            bodyStyle={{
              overflowY: 'auto',
            }}
          >
            {filterlines.map((item: UnpackLine) => (
              <Card
                size="small"
                type="inner"
                key={item.fulfillmentPackLineNum}
                title={`SKU: ${item.sku}`}
                style={{ marginBottom: 10 }}
              >
                <TCollapse
                  ghost
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => {
                    return isActive ? (
                      <Typography.Link>
                        <UpOutlined style={{ marginRight: 2 }} />
                        Hide Detail
                      </Typography.Link>
                    ) : (
                      <Typography.Link>
                        <DownOutlined style={{ marginRight: 2 }} />
                        Show Detail
                      </Typography.Link>
                    );
                  }}
                >
                  <Panel
                    header={
                      <Row style={{ width: '80%' }}>
                        <Col span={6}>
                          <FormLabel>UPC</FormLabel>
                          {item.upc || ''}
                        </Col>
                        <Col span={6}>
                          <FormLabel>Product Name</FormLabel>
                          {item.productTitle}
                        </Col>
                        <Col span={6}>
                          <FormLabel>Requested</FormLabel>
                          {item.requestQty}
                        </Col>
                        <Col span={6}>
                          <FormLabel>Unpacked</FormLabel>
                          {item.unpackQty}
                        </Col>
                      </Row>
                    }
                    key="1"
                  >
                    <DataGridPlus
                      name="lineGrid"
                      style={{ minHeight: 200 }}
                      idProperty="code"
                      dataSource={processLines.filter(
                        (i) => i.sku === item.sku
                      )}
                      columns={getTempLineColumns(enableCSN)}
                      /* eslint-disable react/jsx-props-no-spreading */
                      {...({} as any)}
                    />
                  </Panel>
                </TCollapse>
              </Card>
            ))}
            {filterlines.length === 0 && <Empty />}
          </Card>
        </div>
        {current && deleteDialogVisible && (
          <Modal
            title="Are you sure delete this Row?"
            visible
            onOk={() => {
              deleteLine(
                current.fulfillmentUnpackNum,
                current.fulfillmentUnpackProcessNum
              );
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`SKU: ${current.sku}`}</p>
          </Modal>
        )}
        {/* {editQtyDialogVisible && current && (
          <EditQtyDialog
            data={current}
            visible
            lines={lines}
            upackCode={info?.upackCode || ''}
            onSave={(data) => {
              setEditQtyDialogVisible(false);
              fetchLines(data.fulfillmentPackNum);
              fetchProcessLines(data.fulfillmentPackNum);
            }}
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
          />
        )} */}
        {printDialogVisible && info && (
          <PrintDialog
            onHide={() => setPrintDialogVisible(false)}
            detailData={info}
            title="Print Pick List"
          />
        )}
        {selectSKUDialogVisible && info && lines && (
          <SelectSKUDialog
            onHide={() => setSelectSKUDialogVisible(false)}
            detailData={info}
            sourceLines={lines}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
