import React from 'react';
import { Link } from 'react-router-dom';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';


export default function Pick(): JSX.Element {
  const { t } = useTranslation()
  return (
    <SmartBlock>
      <SmartSpace>
        <Link to="/smart/confirmByTracking">
          <SmartButton>
            {t('smart.confirm.byTrackingNum')}
          </SmartButton>
        </Link>
        {/* <Link to="/smart/confirmByChannelOrderId"> */}
        <SmartButton isComingSoon>
          {t('smart.confirm.byChannelOrderId')}
        </SmartButton>
        {/* </Link> */}
      </SmartSpace>
    </SmartBlock>
  );
}
