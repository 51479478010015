import React, { useState, useEffect, useCallback } from 'react';
import { Row, Spin, Modal, Button, Space, Typography } from 'antd';
import { message } from 'components/common';
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { poReceiveClose, getBatchNumber } from 'services/purchaseOrder';
import moment from 'moment';

const { Paragraph, Text } = Typography;

interface CloseReceiveDialogProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  selectedRow: any[];
  warehouseId: string;
  generateBatchNumber: string;
}

// eslint-disable-next-line
export default (props: CloseReceiveDialogProps) => {
  const {
    visible,
    onHide,
    onRefresh,
    selectedRow,
    warehouseId,
    generateBatchNumber,
  } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [batchNumber, setBatchNumber] = React.useState<string>('');

  const fetchBatchNumber = useCallback(async () => {
    try {
      setLoading(true);
      // const generateBatchNumber = await getProfileSettingValue('GenerateBatchNumber');
      const batchCode =
        generateBatchNumber === '1'
          ? selectedRow[0].vendorCode
          : selectedRow[0].poNumber;
      const res = await getBatchNumber(batchCode, moment().format('YYYYMMDD'));
      setLoading(false);
      if (res.isSuccess) {
        setBatchNumber(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [selectedRow, generateBatchNumber]);

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const res = await poReceiveClose(warehouseId, {
        PoReceiveNums: selectedRow.map((i) => i.poReceiveNum),
        VendorCode: selectedRow[0].vendorCode,
        BatchNumber: batchNumber,
      });
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: 'Close receive successfully. ',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    fetchBatchNumber();
  }, []);
  /* eslint-enable */

  return (
    <Modal
      title="Do you want to close selected items?"
      centered
      visible={visible}
      maskClosable={false}
      width={500}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to continue？',
                  style: { width: 450 },
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <Row justify="start">
                      <Text>
                        You will use{` `}
                        <Text mark strong>
                          {batchNumber}
                        </Text>
                        {` `}
                        as the batch number, which may not be modified in the
                        future.
                      </Text>
                    </Row>
                  ),
                  onOk() {
                    handleSubmit();
                  },
                  onCancel() {},
                });
              }}
              loading={submitLoading}
              icon={<CheckOutlined />}
            >
              OK
            </Button>
            <Button onClick={onHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start">
          <Text>The batch number is:</Text>
          <Paragraph
            copyable
            editable={{
              tooltip: false,
              onChange: setBatchNumber,
            }}
            mark
            strong
            style={{
              width: '100%',
              marginTop: 20,
              textAlign: 'center',
            }}
          >
            {batchNumber}
          </Paragraph>
        </Row>
      </Spin>
    </Modal>
  );
};
