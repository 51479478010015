import React, { useCallback, useEffect, useState } from 'react';
import {
  Input,
  Row,
  Modal,
  Button,
  Space,
  Col,
  Image,
  Popconfirm,
  Form,
} from 'antd';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import {
  fetchProductMedias,
  postProductMedia,
  deleteProductMedia,
  postProductPrimaryMedia,
} from 'services/product';
import { message, DataGridPlus, CopySpan } from 'components/common';
import { isImageUrl, leaveEditForm } from 'utils';

type EditProductPicturesDialogProps = {
  visible: boolean;
  onHide: () => void;
  product: ProductRow;
};
export default function EditProductPicturesDialog(
  props: EditProductPicturesDialogProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Array<ProductPictureEntity>>([]);
  const [help, setHelp] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const { visible, onHide, product } = props;
  const [form] = Form.useForm();
  const layout = {
    wrapperCol: { span: 24 },
  };
  const columns = [
    {
      maxWidth: 60,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Popconfirm
            id={`delete_row_${rowIndex}`}
            title="Are you sure want to delete this picture Url?"
            okText="Delete"
            onConfirm={() => {
              handleDeletePicture(data);
            }}
            cancelText="Cancel"
            okButtonProps={{ className: 'danger-btn', icon: <CheckOutlined /> }}
            cancelButtonProps={{ icon: <CloseOutlined /> }}
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    },
    {
      header: 'View',
      maxWidth: 100,
      textAlign: 'center',
      render: (value: any) => {
        const {
          data: { mediaUrl },
          rowIndex
        } = value;
        return <Image id={`view_row_${rowIndex}`} width={32} src={mediaUrl}/>;
      },
    },
    {
      header: 'Picture Url',
      name: 'mediaUrl',
      defaultFlex: 1,
      render: (value: any) => {
        const {
          data,
          rowIndex
        } = value;
        // return <span id={`picture_url_row_${rowIndex}`} >{data.mediaUrl}</span>;
        return <CopySpan id={`picture_url_row_${rowIndex}`} inGrid value={data.mediaUrl}></CopySpan>
      },
    },
    {
      title: '',
      name: 'action',
      maxWidth: 100,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Checkbox
            id={`action_row_${rowIndex}_checkbox`}
            //id="primary_check_box"
            checked={data.isPrimaryImage}
            onChange={(e) => {
              handlePrimaryPicture(e, data);
            }}
          >
            Primary
          </Checkbox>
        );
      },
    },
  ];
  const fetchList = useCallback(async () => {
    const picturs = await fetchProductMedias(product.productId);
    setDataSource(picturs);
  }, [product]);
  useEffect(() => {
    const fetch = async () => {
      if (visible) {
        setLoading(true);
        await fetchList();
        setLoading(false);
      }
    };
    fetch();
  }, [product, visible, fetchList]);
  /**
   * Create picture
   */
  const handleCreatePicture = useCallback(async () => {
    const nextUrl = form.getFieldValue('url');
    if (isImageUrl(nextUrl)) {
      setUrl(nextUrl);
    } else {
      setHelp('Please input valid picture url');
    }
  }, [form]);
  /**
   * Delete picture
   */
  const handleDeletePicture = useCallback(
    async (record: ProductPictureEntity) => {
      if (product) {
        setLoading(true);
        await deleteProductMedia(product.productId, record.rowNum);
        await fetchList();
        setLoading(false);
        setHelp('');
        form.resetFields();
        message.success({ content: 'Delete Media Url Successfully!' });
      }
    },
    [fetchList, form, product]
  );
  /**
   * set primary picture
   */
  const handlePrimaryPicture = useCallback(
    async (e: CheckboxChangeEvent, record: ProductPictureEntity) => {
      const { checked } = e.target;
      if (product && checked) {
        setLoading(true);
        await postProductPrimaryMedia(product.productId, record.rowNum);
        await fetchList();
        setLoading(false);
        setHelp('');
        form.resetFields();
        message.success({
          content: 'Picture Url(s) are saved successfully！',
        });
      }
    },
    [fetchList, form, product]
  );
  /**
   *handle image
   */
  const handleImage = useCallback(
    async (valid: boolean) => {
      if (!valid) {
        message.warning({ content: 'Media Url invalid!' });
        return;
      }
      setLoading(true);
      await postProductMedia(product.productId, url);
      await fetchList();
      setLoading(false);
      setHelp('');
      form.resetFields();
      message.success({
        content: 'Update Pictures Urls Submitted Successfully!',
      });
    },
    [url, form, product, fetchList]
  );
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="cancel_edit" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Edit Picture Urls"
      centered
      visible={visible}
      width={800}
      destroyOnClose
      onCancel={handleCancel}
      getContainer={false}
      footer={<Footer />}
    >
      {url.length > 0 && (
        <img
          alt=""
          src={url}
          style={{ width: 0, height: 0 }}
          onError={() => {
            handleImage(false);
          }}
          onLoad={() => {
            handleImage(true);
          }}
        />
      )}
      <Row>
        <Col span={20}>
          <Form
            /* eslint-disable react/jsx-props-no-spreading */
            {...layout}
            form={form}
          >
            <Form.Item
              name="url"
              validateStatus={help ? 'error' : ''}
              help={help}
            >
              {/*<Input id="picture_url_input" placeholder="Picture Url" />*/}
              <Input id="pic_url_input" placeholder="Picture Url" />
            </Form.Item>
          </Form>
        </Col>
        <Col span={4}>
          {/*<Button id="add_picture_button" type="primary" block onClick={handleCreatePicture}>*/}
          <Button id="add_pic_btn" type="primary" block onClick={handleCreatePicture}>
            Add Picture
          </Button>
        </Col>
      </Row>
      <DataGridPlus
        idProperty="rowNum"
        loading={loading}
        
        dataSource={dataSource.reverse()}
        columns={columns}
        pagination={false}
        /* eslint-disable react/jsx-props-no-spreading */
        {...({} as any)}
      />
    </Modal>
  );
}
