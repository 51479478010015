import React from 'react';
import { Row } from 'antd';
import NumberFormat from 'react-number-format';
import { FormLabel } from 'components/common';

type StatisticsItemProps = {
  title: string;
  value: number;
};
/**
 * Return StatisticsItem
 * @param {StatisticsItemProps} props
 * @returns {JSX.Element}
 */
export default function StatisticsItem(
  props: StatisticsItemProps
): JSX.Element {
  const { title, value } = props;
  return (
    <Row>
      <FormLabel>{title}</FormLabel>
      <NumberFormat displayType="text" value={value} thousandSeparator />
    </Row>
  );
}
