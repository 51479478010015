import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import Pack from 'components/PrePack/Pack';

function WarehouseTransfer() {
  return (
    <ContentLayout>
      <SiteContent>
        <Pack />
      </SiteContent>
    </ContentLayout>
  );
}

export default WarehouseTransfer;
