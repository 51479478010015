import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';

const { Text } = Typography;

const FLabel = styled(Text)`
  font-family: Lato, sans-serif;
  font-weight: 600;
  overflow:  hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
type Props = {
  strong?: boolean;
  type?: BaseType | undefined;
  noColon?: boolean;
  children: string;
  ellipsis?: boolean;
  fontSize?: number;
  require?: boolean;
  noWrap?: boolean;
};
export default function FormLabel(props: Props): JSX.Element {
  let { fontSize } = useContext(ThemeContext) || {};
  const { strong, type, noColon, children, require } = props;

  if (props.fontSize) fontSize = props.fontSize || fontSize || 14;

  return (
    <FLabel strong={strong} type={type}  ellipsis={{ tooltip: children }}>
      {require && (
        <span
          style={{
            display: 'inline-block',
            marginRight: 4,
            color: '#bc0000',
            fontSize: fontSize,
            lineHeight: 1,
          }}
        >
          *
        </span>
      )}
      {children}
      {!noColon && ' :'}
    </FLabel>
  );
}
