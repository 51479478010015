import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Tooltip } from 'antd';
import { v4 as uuid } from 'uuid';
import { pingPrintstation, pongPrintstation, setPrintstationIsOk } from 'actions/printActions';
import theme from 'assets/styles/theme';
import { SIGNALR_CONNECTION_CONNECTED } from 'constants/config';
import { RootState } from 'reducers';
import store from 'store';
import { getPrinterAccountNumList } from 'utils';
import { useTranslation } from 'react-i18next';

type Props = {
  fontSize?: number;
  marginLeft?: number;
  marginRight?: number;
};

const SignalRLinkLight = (props: Props) => {
  const PING_INTERVAL = 5000;
  const { useCallback, useRef, useState } = React;
  const i18n = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const signalConnection: number = useSelector(
    (state: RootState) => state.regular.signalConnection,
    shallowEqual,
  );
  const {
    fontSize = 20,
    marginLeft = 6,
    marginRight = 6,
  } = props;
  const initialColor = theme['@success-color'];
  const initialLightStyle = {
    color: initialColor,
    fontSize,
    marginLeft,
    marginRight,
  };
  const [inited, setInited] = useState(false);
  const [connState, setConnState] = useState(signalConnection);
  const [lastUpdateTag, setLastUpdateTag] = useState<string>();
  const [lightIcon, setLightIcon] = useState('bi-circle-fill');
  const [lightMsg, setLightMsg] = useState('');
  const [lightStyle, setLightStyle] = useState<StringKAnyVPair>(initialLightStyle);
  const [pongTime, setPongTime] = useState<string>();
  const [printstationTimer, setPrintstationTimer] = useState<any>(null);
  const [updateTag, setUpdateTag] = useState<string>();
  const shell = useRef<any>(null);
  const printstationIsOk: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printstationIsOk,
    shallowEqual,
  );
  const printstationPong: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printstationPong,
    shallowEqual,
  );

  const testPrintstationHeartbeat = useCallback(() => {
    if (shell?.current) {
      const {
        regular: { signalConnection },
      } = store.getState() as StringKAnyVPair;
      let forceUpdate = false;

      if (signalConnection === SIGNALR_CONNECTION_CONNECTED) {
        const accounts = getPrinterAccountNumList();

        //console.log('acct -- -->', accounts);
        if (accounts.length > 0) {
          const {
            sales: { printstationPong: { time } },
          } = store.getState() as StringKAnyVPair;

          for (let num of accounts) {
            dispatch(pingPrintstation(num));
          }

          if (time && typeof time === 'number') {
            const now = (new Date()).getTime();

            if (now - time > PING_INTERVAL) {
              dispatch(pongPrintstation({}));
              dispatch(setPrintstationIsOk(false));
              forceUpdate = true;
            }
          }
        } else {
          //if (printstationIsOk) forceUpdate = true;
          forceUpdate = true;
        }
      } else {
        if (printstationIsOk) forceUpdate = true;
      }

      if (forceUpdate) {
        //console.log('force up');
        setUpdateTag(uuid());
      }

      const timer = setTimeout(testPrintstationHeartbeat, PING_INTERVAL);
      setPrintstationTimer(timer);
    }
  }, [dispatch, printstationIsOk]);

  const updateLightColor = useCallback(() => {
    let color = initialColor;

    //console.log('up --->');
    if (signalConnection === SIGNALR_CONNECTION_CONNECTED) {
      const accounts = getPrinterAccountNumList();

      color = theme['@warning-color'];
      setLightMsg('Printstation disconnected');
      setLightIcon('bi-circle-fill');

      if (accounts.length === 0) {
        //color = theme['@warning-bg-color'];
        setLightIcon('bi-circle-fill');
        setLightMsg(i18n.t('scanToShip.noPrinter') || '');
      }

      if (typeof printstationPong.sender === 'string') {
        for (let i = 0; i < accounts.length; i++) {
          if (printstationPong.sender.includes(`${accounts[i]}`)) {
            const { time } = printstationPong;
            const now = (new Date()).getTime();
            //console.log('--->', time, now, now - time, printstationPong.sender);
            if (now - time < PING_INTERVAL) {
              color = initialColor;
              setLightIcon('bi-check-circle-fill');
              setLightMsg('');
              dispatch(setPrintstationIsOk(true));
              break;
            }
          }
        }
      }
    } else {
      color = theme['@error-color'];
      setLightIcon('bi-x-circle-fill');
      setLightMsg('Connection error');
      //console.log('disconnected');
    }

    setLightStyle({...lightStyle, color});
  }, [
    i18n,
    dispatch,
    initialColor,
    lightStyle,
    printstationPong,
    signalConnection,
  ]);

  React.useEffect(() => {
    if (!inited) {
      testPrintstationHeartbeat();
      //updateLightColor();
      setInited(true);
    }

    if (
      printstationPong.timestamp &&
      printstationPong.timestamp !== pongTime
    ) {
      updateLightColor();
      setPongTime(printstationPong.timestamp);
    }

    if (connState !== signalConnection) {
      updateLightColor();
      setConnState(signalConnection);
      //console.log('up --->', signalConnection);
    }

    if (lastUpdateTag !== updateTag) {
      updateLightColor();
      setLastUpdateTag(updateTag);
    }

    return () => {
      // eslint-disable-next-line
      if (!shell?.current) {
        if (printstationTimer) {
          clearTimeout(printstationTimer);
          setPrintstationTimer(null);
          console.log('cleared');
        }
      }
    };
  }, [
    connState,
    inited,
    lastUpdateTag,
    pongTime,
    printstationPong,
    printstationTimer,
    signalConnection,
    testPrintstationHeartbeat,
    updateLightColor,
    updateTag,
  ]);

  return (
    <span ref={shell}>
      {lightMsg ?
        <Tooltip placement="bottom" title={lightMsg}>
          <i className={lightIcon} style={lightStyle} />
        </Tooltip>
        :
        <i className="bi-check-circle-fill" style={lightStyle} />
      }
    </span>
  );
};

export default SignalRLinkLight;
