import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StopOutlined } from '@ant-design/icons';
import { Typography, Button, InputNumber, Form, Input, Spin } from 'antd';
import {
  getWarehouseIdFromCache,
  playAudio,
  getWarehouseCodeFromCache,
} from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../../SmartComponent';
import {
  queryLocationsByKeyword,
  getSpecialLocations,
} from 'services/warehouse';
import {
  fetchWarehouseProductList,
  moveProductLocationToLocation,
} from 'services/inventory';
import moment from 'moment';
import FinishDialog from './Finish';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import { specialWarehouseInfo } from 'actions/adminActions';

const { Text } = Typography;

type SpecialInfoItem = {
  databaseNum: number;
  locations: any[];
  warehouseCode: string;
  warehouseId: string;
  warehouseNum: number;
  warehouseType: number;
};

type SpecialInfoType = {
  2?: SpecialInfoItem;
  3?: SpecialInfoItem;
  4?: SpecialInfoItem;
  6?: SpecialInfoItem;
  7?: SpecialInfoItem;
};

export default function ReturnToLocation(): JSX.Element {
  const sWarehouseType = 1;
  const tWarehouseType = 7;
  const history = useHistory();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  // const [locations, setLocations] = useState<WarehouseLocationRow[]>([]);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [step, setStep] = useState<string>('sourceLocation'); //'sourceLocation' | 'targetLocation' | 'scanItem' | 'scanQty'
  const [sLocation, setSLocation] = useState<WarehouseLocationRow>();
  const [tLocation, setTLocation] = useState<WarehouseLocationRow>();
  const [productLocations, setProductLocations] = useState<any[]>([]);
  const [qty, setQty] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [scanTime, setScanTime] = useState<string>('');
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [hasWarehouse, setHasWarehouse] = useState(true);
  const cacheInfo = useSelector(
    (state: RootState) => state.admin.defaultSpecialWarehouseInfo,
    shallowEqual
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [specialInfo, setSpecialInfo] = useState<any>({
    2: undefined,
    3: undefined,
    4: undefined,
    6: undefined,
    7: undefined,
  });
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  const getAllSpecialInfo = useCallback(async () => {
    if (
      cacheInfo &&
      cacheInfo.warehouseNum &&
      cacheInfo.warehouseNum === warehouseNum
    ) {
      if (cacheInfo.specialWarehouseInfo) {
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
          6: undefined,
          7: undefined,
        };
        cacheInfo.specialWarehouseInfo.map((i: any) => {
          if (i.warehouseType === 2) {
            temp[2] = i;
          }
          if (i.warehouseType === 3) {
            temp[3] = i;
          }
          if (i.warehouseType === 4) {
            temp[4] = i;
          }
          if (i.warehouseType === 6) {
            temp[6] = i;
          }
          if (i.warehouseType === 7) {
            temp[7] = i;
          }
          return true;
        });
        if (!temp[7]) {
          setHasWarehouse(false);
        }
        setSpecialInfo(temp);
      }
    } else {
      try {
        setFetching(true);
        const res = await getSpecialLocations(warehouseId, 0);
        setFetching(false);
        if (res) {
          dispatch(
            specialWarehouseInfo({
              warehouseNum: warehouseNum,
              specialWarehouseInfo: res,
            })
          );
        }
      } catch (error) {
        setFetching(false);
      }
    }
  }, [warehouseNum, warehouseId, cacheInfo, dispatch]);

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
      //todo check damage warehouse enable
    }
  }, [whs]);

  useEffect(() => {
    if (warehouseId) {
      getAllSpecialInfo();
    }
  }, [warehouseId, getAllSpecialInfo]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const handleKeyDown = async (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      setInputValue('');
      evt.preventDefault();
      if (evt.target.value) {
        if (
          evt.target.value === productLocations[0].sku ||
          evt.target.value === productLocations[0].upc
        ) {
          playAudio('success');
          setQty((prev) => prev + 1);
          setScanTime(moment().format('hh:mm:ssA'));
        } else {
          playAudio('error');
          message.info({ content: 'not match item' });
        }
      }
    }
  };

  const moveProduct = useCallback(async () => {
    try {
      setFetching(true);
      const res = await moveProductLocationToLocation({
        fromWarehouse: warehouseId,
        fromLocation: sLocation?.locationNum || -1,
        fulfillmentProductNum: productLocations[0].fulfillmentProductNum,
        quantity: qty,
        toWarehouse: specialInfo[tWarehouseType].warehouseNum,
        toLocation: tLocation?.locationNum || -1,
      });
      setFetching(false);
      if (res) {
        setFinishDialogVisible(true);
        // message.success({ content: 'Move successfully' });
      }
    } catch (error) {
      setFetching(false);
    }
  }, [sLocation, tLocation, warehouseId, productLocations, qty, specialInfo]);

  const fetchProductsByLocationCode = async (code: string) => {
    try {
      const query = {
        $skip: 0,
        $top: 10000,
        $sortBy: 'locationCode',
        $calculateTotal: true,
        $count: true,
        uniqueCode: code,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setFetching(false);
      if (res.data.length > 0) {
        const temp = res.data.filter(
          (item: any) => item.locationCode === sLocation?.locationCode
        );
        if (temp.length > 0) {
          setProductLocations(
            res.data.filter((item: any) => item.locationCode !== 'Checkout')
          );
          setStep('targetLocation');
        } else {
          message.error({
            content: `not found Item from '${sLocation?.locationCode}'`,
          });
        }
      } else {
        message.error({ content: 'not found Item' });
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const queryLocation = useCallback(
    async (
      value: string,
      step: string,
      callback: (value: any) => void,
      warehouseType: number
    ) => {
      try {
        let res: any = undefined;
        if (warehouseType === 1) {
          setFetching(true);
          res = await queryLocationsByKeyword(warehouseId, value, true);
          setFetching(false);
        } else {
          res = specialInfo[warehouseType]
            ? (specialInfo[warehouseType].locations || []).find(
                (i: any) => i.locationCode === value
              )
            : undefined;
        }

        if (res) {
          if(!res.error){
            callback(res);
            if (step.length > 0) {
              setStep(step);
            }
          }
        } else {
          playAudio('error');
          message.info({ content: `not match location '${value}'` });
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId, specialInfo]
  );

  return (
    <div style={{ marginTop: 20 }}>
      <Spin
        spinning={!hasWarehouse}
        indicator={<StopOutlined style={{ fontSize: 20 }} />}
        tip="Please check if the warehouse exists"
      >
        {step === 'sourceLocation' && (
          <>
            <SmartSpin loading={fetching} />
            <SmartSpace>
              <SmartFormLabel
                style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
              >
                {t('smart.movemerge.scanSourceLocationtoStart')}
              </SmartFormLabel>
              <SmartScanner
                disabled={!hasWarehouse}
                style={{ marginTop: 10 }}
                onChangeValue={(value) => {
                  queryLocation(
                    value,
                    'scanItem',
                    setSLocation,
                    sWarehouseType
                  );
                }}
              />
            </SmartSpace>
            <SmartRow justify="space-between">
              <Button
                type="text"
                onClick={() => {
                  queryLocation(
                    'General',
                    'scanItem',
                    setSLocation,
                    sWarehouseType
                  );
                }}
              >
                Use General
              </Button>
            </SmartRow>

            <SmartBackButton
              onClick={() => {
                history.goBack();
              }}
            />
          </>
        )}

        {step === 'scanItem' && (
          <>
            <SmartSpin loading={fetching} />
            <SmartRow>
              <SmartFormLabel>{t('smart.movemerge.from')}</SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </SmartRow>
            <SmartSpace>
              <SmartFormLabel
                style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
              >
                {t('smart.movemerge.scanItem')}
              </SmartFormLabel>
              <SmartScanner
                style={{ marginTop: 10, marginBottom: 8 }}
                onChangeValue={(value) => fetchProductsByLocationCode(value)}
              />
            </SmartSpace>
            <SmartBackButton
              onClick={() => {
                setStep('sourceLocation');
              }}
            />
          </>
        )}
        {step === 'targetLocation' && (
          <>
            <SmartSpin loading={fetching} />
            <SmartRow>
              <SmartFormLabel>{t('smart.movemerge.from')}</SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </SmartRow>
            <SmartRow justify="space-between">
              <Text>
                {t('common.sku')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {productLocations[0]?.sku}
                </Text>
              </Text>

              <Text>
                {t('common.upc')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {productLocations[0]?.upc}
                </Text>
              </Text>
            </SmartRow>
            <SmartSpace>
              <SmartFormLabel
                style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
              >
                {t('smart.movemerge.scanLocation')}
              </SmartFormLabel>
              <SmartScanner
                style={{ marginTop: 10 }}
                onChangeValue={(value) => {
                  queryLocation(value, 'scanQty', setTLocation, tWarehouseType);
                }}
              />
            </SmartSpace>
            <SmartRow justify="space-between">
              <Button
                type="text"
                onClick={() => {
                  queryLocation(
                    'General',
                    'scanQty',
                    setTLocation,
                    tWarehouseType
                  );
                }}
              >
                Use General
              </Button>
            </SmartRow>
            <SmartBackButton
              onClick={() => {
                setStep('scanItem');
              }}
            />
          </>
        )}
        {step === 'scanQty' && (
          <>
            <SmartSpin loading={fetching} />
            <SmartRow justify="space-between">
              <Text>
                {t('smart.movemerge.from')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {sLocation?.locationCode}
                </Text>
              </Text>

              <Text>
                {t('smart.movemerge.to')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {tLocation?.locationCode}
                </Text>
              </Text>
            </SmartRow>
            <SmartRow justify="space-between">
              <Text>
                {t('common.sku')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {productLocations[0]?.sku}
                </Text>
              </Text>

              <Text>
                {t('common.upc')}:
                <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                  {productLocations[0]?.upc}
                </Text>
              </Text>
            </SmartRow>
            <SmartRow>
              <Form.Item
                label={
                  <Text
                    style={{
                      fontSize: 18,
                      color: 'rgb(86, 149, 34)',
                      fontWeight: 600,
                    }}
                  >
                    {t('smart.movemerge.scanItem')}:
                  </Text>
                }
              >
                <Input
                  autoFocus
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  style={{ marginLeft: 8 }}
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
            </SmartRow>
            <SmartRow justify="space-between" style={{ alignItems: 'start' }}>
              <Form.Item
                label={
                  <Text
                    style={{
                      fontSize: 18,
                      color: 'rgb(86, 149, 34)',
                      fontWeight: 600,
                    }}
                  >
                    {t('common.qty')}:
                  </Text>
                }
              >
                <InputNumber
                  min={0}
                  value={qty}
                  style={{ marginLeft: 8 }}
                  onChange={(value) => setQty(value)}
                />
              </Form.Item>
              <Button type="primary" onClick={() => moveProduct()}>
                {t('common.confirm')}
              </Button>
            </SmartRow>
            <SmartRow>
              <Text>
                {/* Last scan at: <Text>{scanTime}</Text> */}
                {t('smart.movemerge.lastScanAt', { value: scanTime })}:
              </Text>
            </SmartRow>

            <SmartBackButton
              onClick={() => {
                setStep('targetLocation');
              }}
            />
          </>
        )}
        {finishDialogVisible && tLocation && sLocation && (
          <FinishDialog
            tLocation={tLocation.locationCode || ''}
            sLocation={sLocation.locationCode || ''}
            sku={productLocations[0].sku}
            upc={productLocations[0].upc}
            qty={qty}
            onSubmit={(value) => {
              setFinishDialogVisible(false);
              if (value === 'home') {
                history.push('/smart');
              }
              if (value === 'sourceLocation') {
                setSLocation(undefined);
                setTLocation(undefined);
                setQty(0);
                setScanTime('');
                setStep('sourceLocation');
              }
              if (value === 'item') {
                setTLocation(undefined);
                setQty(0);
                setScanTime('');
                setStep('scanItem');
              }
            }}
          />
        )}
      </Spin>

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
