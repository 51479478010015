/**
 * auto set datagrid height
 * @param {StringKAnyVPair} props
 * @param {number} limit
 * @returns {StringKAnyVPair}
 */
export default function AutoHeight(
  props: StringKAnyVPair,
  limit: number
): StringKAnyVPair {
  const { style, pagination, dataSource, count, columns } = props;
  if (!(style && style.height) && dataSource.length !== 0) {
    let height = 88; //header height:38 groupby:50
    //whether has group
    let grouped = false;
    columns.forEach((column: any) => {
      const { group } = column;
      if (group) {
        grouped = true;
      }
    });
    if (grouped) {
      height += 38;
    }
    if (!pagination) {
      height += (count || (dataSource as any).length || 10) * 41; //row height:40px,split line height:1px,pagination:41px
    } else {
      let currentPageSize = limit || 10;
      const currentCount = count || (dataSource as any).length || 10;
      if (currentPageSize > currentCount) {
        currentPageSize = currentCount;
      }
      height += currentPageSize * 41 + 41; //row height:40px,split line height:1px,
    }
    return {
      zIndex: 1,
      minHeight: height + 2,
      ...style,
      height,
      maxHeight: 12 * 41,
    };
  }
  return style;
}
