import * as actionTypes from 'actions/actionTypes';
import {
  setIsLoadingPrinterList,
  setIsLoadingScaleList,
  setPackingPrinter,
  setPackingPrinter2,
  setPrinterList,
  setPrinterListLoaded,
  setScale,
  setScaleList,
  setScaleListLoaded,
  setShipmentPrinter,
  setShipmentPrinter4x8,
  setShipmentPrinterCoton,
} from 'actions/salesAction';
import { message } from 'components/common';
import { fetchPrinterList } from 'services/print';
import { fetchScaleList } from 'services/sales';

const loadPrinters = async (dispatch) => {
  try {
    const { code, data } = await fetchPrinterList();

    if (code === 200) {
      if (Array.isArray(data)) {
        dispatch(
          setPrinterList(data)
        );
        dispatch(setIsLoadingPrinterList(false));
        dispatch(setPrinterListLoaded(true));
      }
    }
  } catch (e) {
    message.error(`Fetch printers error: ${e}`);
  }
};

const loadScales = async (dispatch) => {
  try {
    const scales = await fetchScaleList();
    const list = scales.map((e) => ({
      approved: e.approved,
      clientId: e.clientId,
      connectAccountNum: e.connectAccountNum,
      instanceID: e.instanceID,
      masterAccountNum: e.masterAccountNum,
      name: e.name,
      note: e.note,
      online: e.online,
      pcName: e.pcName,
      profileNum: e.profileNum,
      scaleNum: e.scaleNum,
      status: e.status,
      unit: e.unit,
      venderId: e.venderId,
    }));

    list.splice(0, 0, {
      name: 'Do Not Use Scale',
      scaleNum: 0,
    });
    dispatch(setScaleList([...list]));
    dispatch(setScaleListLoaded(true));
    dispatch(setIsLoadingScaleList(false));
  } catch (e) {
    message.error(`Fetch scales error: ${e}`);
  }
};

const lazyCache = (store) => (next) => (action) => {
  const { dispatch } = store;
  const { sales } = store.getState();
  const { printerList, scaleList } = sales;
  let forward = true;

  switch (action.type) {
    case actionTypes.LOAD_PRINTER_LIST:
      if (!sales.printerListLoaded) {
        if (!sales.printerListIsLoading) {
          dispatch(setIsLoadingPrinterList(true));
          loadPrinters(dispatch);
        }
      }

      forward = false;
      break;

    case actionTypes.LOAD_SCALE_LIST:
      if (!sales.scaleListLoaded) {
        if (!sales.scaleListIsLoading) {
          dispatch(setIsLoadingScaleList(true));
          loadScales(dispatch);
        }
      }

      forward = false;
      break;

    case actionTypes.SET_SALES_SCALE_BY_ID:
      if (scaleList.length > 0 && action.scaleId > 0) {
        let scale = {};

        for (let i = 0; i < scaleList.length; i++) {
          if (action.scaleId === scaleList[i].scaleNum) {
            scale = scaleList[i];
          }
        }

        if (scale.scaleNum > 0) {
          dispatch(setScale(scale));
        }
      }

      forward = false;
      break;

    case actionTypes.SET_PACKING_PRINTER_BY_ID:
    case actionTypes.SET_PACKING_PRINTER2_BY_ID:
    case actionTypes.SET_SHIPMENT_PRINTER_BY_ID:
    case actionTypes.SET_SHIPMENT_PRINTER4x8_BY_ID:
    case actionTypes.SET_SHIPMENT_PRINTER_COTON_BY_ID:
      if (printerList.length > 0 && action.printerId > 0) {
        let printer = {};

        for (let i = 0; i < printerList.length; i++) {
          if (action.printerId === printerList[i].printerNum) {
            printer = printerList[i];
          }
        }

        if (printer.printerNum > 0) {
          if (action.type === actionTypes.SET_SHIPMENT_PRINTER_BY_ID) {
            dispatch(setShipmentPrinter(printer));
          } else if (action.type === actionTypes.SET_SHIPMENT_PRINTER4x8_BY_ID) {
            dispatch(setShipmentPrinter4x8(printer));
          } else if (action.type === actionTypes.SET_SHIPMENT_PRINTER_COTON_BY_ID) {
            dispatch(setShipmentPrinterCoton(printer));
          } else if (action.type === actionTypes.SET_PACKING_PRINTER_BY_ID) {
            dispatch(setPackingPrinter(printer));
          } else {
            dispatch(setPackingPrinter2(printer));
          }
        }
      }

      forward = false;
      break;

    default:
      break;
  }

  if (forward) {
    next(action);
  }
};

export default lazyCache;
export const loadPrinterList = loadPrinters;
