import React, { useCallback, useEffect, useState } from 'react';
import { Row, Space, Form, Radio, Select, Col, Divider, Typography, InputNumber } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { shallowEqual, useSelector } from 'react-redux';
import {
  FormLabel,
  message,
  // InputInteger,
  ModalPlus,
  Button,
} from 'components/common';
import { adjustmentItems3 } from 'services/product';
// import { TransactionType } from 'constants/config';
import { leaveEditForm } from 'utils';
import { MIDDLE_FONT_SIZE } from 'constants/config';
import { ThemeProvider } from 'styled-components';
import { RootState } from 'reducers';
import Text from 'antd/lib/typography/Text';

type Props = {
  onHide: (forceRefresh: boolean) => void;
  product: InventoryProductRow;
  warehouseId: string;
  locationId: string;
  onSuccess?: Function;
  lotEnable: boolean;
  uomEnable: boolean;
};

const EditProductQuantityDialog = (props: Props): JSX.Element => {
  const { onHide, product, warehouseId, onSuccess, lotEnable, uomEnable } = props;
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [action, setAction] = useState<number>(-1);
  const [qty, setQty] = useState<number>(0)
  const transactionReasons = useSelector(
    (state: RootState) => state.admin.transactionReasons,
    shallowEqual
  );
  const [handleOk, setHandleOK] = useState<boolean>(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ quantity: 0 });
    setQty(0)
  }, [product, form]);

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if(!uomEnable && product.uom !== 'EA'){
      return message.warning('The uom setting is disabled and this operation cannot be performed.')
    }
    if(!lotEnable && product.lotNumber){
      return message.warning('The lotnumber setting is disabled and this operation cannot be performed.')
    }
    setSaveLoading(true);
    const value = form.getFieldValue('quantity');
    const reason = form.getFieldValue('reason');
    const res = await adjustmentItems3({
      warehouseId: warehouseId,
      productNum: product.productNum,
      locationNum: product.locationNum,
      adjustmentType: action,
      quantity: value,
      transactionReason: reason,
      uom: product.uom,
      lotNumber: product.lotNumber,
      uomRate: product.uomRate
    });
    setSaveLoading(false);
    if (res) {
      message.success({ content: 'Update Product Quantity Successfully!' });
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
      onHide(true);
    }
  }, [action, form, product, onHide, warehouseId, onSuccess, lotEnable, uomEnable]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(
    async (theme: Theme) => {
      if (product) {
        const cancel = await leaveEditForm(
          { quantity: product.quantity },
          form,
          '',
          theme
        );
        if (cancel) {
          onHide(false);
        }
      } else {
        onHide(false);
      }
    },
    [onHide, product, form]
  );
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const fontSize = MIDDLE_FONT_SIZE;
  return (
    <ThemeProvider theme={{ fontSize }}>
      <ModalPlus
        title="Update Product Quantity"
        centered
        visible={true}
        width={700}
        destroyOnClose={true}
        onCancel={() => {
          handleCancel({ fontSize });
        }}
        getContainer={false}
        footer={
          <Row justify="end">
            <Space>
              <Button
                id="edit_cancel_btn"
                onClick={() => {
                  handleCancel({ fontSize });
                }}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
              <Button
                id="edit_update_btn"
                key="submit"
                type="primary"
                disabled={!handleOk}
                onClick={handleSubmit}
                loading={saveLoading}
              >
                <SaveOutlined style={{ fontSize }} />
                Update
              </Button>
            </Space>
          </Row>
        }
      >
        <Row>
          <Col span={24}>
            <Text>
              SKU:{' '}
              <Text
                ellipsis={{ tooltip: product.sku }}
                strong
              >
                {product.sku}
              </Text>
            </Text>
          </Col>
          <Col span={24}>
            <Text>
              UPC:{' '}
              <Text
                ellipsis={{ tooltip: product.upc }}
                strong
              >
                {product.upc}
              </Text>
            </Text>
          </Col>
          <Col span={24}>
            <Text>
              Title:{' '}
              <Text
                ellipsis={{ tooltip: product.title }}
                strong
              >
                {product.title}
              </Text>
            </Text>
          </Col>
          {lotEnable && <Col span={24}>
            <Text>
              LOT#:{' '}
              <Text
                ellipsis={{ tooltip: product.lotNumber }}
                strong
              >
                {product.lotNumber}
              </Text>
            </Text>
          </Col>}
        </Row>
        <Divider />
        <Form form={form} {...layout}>
          <Form.Item label={<FormLabel>Action</FormLabel>}>
            <Radio.Group
              id="edit_action_radio_group"
              value={action}
              onChange={(e) => {
                const { value } = e.target;
                setAction(value);
                form.setFieldsValue({
                  quantity: value === 2 ? 0 : 1,
                });
                setQty(value === 2 ? 0 : 1)
              }}
            >
              <Radio
                value={0}
                style={{
                  display: 'block',
                  marginTop: -13,
                  fontSize,
                }}
              >
                Add
              </Radio>
              <Radio value={1} style={{ display: 'block', fontSize }}>
                Remove
              </Radio>
              <Radio value={2} style={{ display: 'block', fontSize }}>
                New total
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<FormLabel>Quantity</FormLabel>}>
            <Space size="large">
            <Form.Item noStyle name="quantity" >
            {/* <InputInteger
              disabled={action < 0}
              type={action === 2 ? 2 : undefined}
              oriValue={qty}
              onChange={(value: string) => {
                form.setFieldsValue({ quantity: parseInt(value) });
                setQty(parseInt(value))
              }}
            /> */}
            <InputNumber precision={0} step={1}  min={0} onChange={setQty}/>
            </Form.Item>
            {uomEnable&&<Typography.Text strong>{`${product.uom || 'EA'}(${product.uomRate || 1})`}</Typography.Text>}
            </Space>
          </Form.Item>
          {uomEnable&&<Form.Item label={<FormLabel>Base Qty</FormLabel>}>
            <Typography.Text>{qty * (product.uomRate || 1)}</Typography.Text>
          </Form.Item>}
          <Form.Item
            label={<FormLabel>Reason</FormLabel>}
            name="reason"
            rules={[{ required: true, message: 'Reason is required' }]}
          >
            <Select
              id="edit_reason_selecor"
              disabled={action < 0}
              onChange={(value) => {
                if (value) {
                  setHandleOK(true);
                } else {
                  setHandleOK(false);
                }
              }}
            >
              {transactionReasons
                .filter((i) => i.type === 3)
                .map((item) => (
                  <Select.Option key={item.id} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </ModalPlus>
    </ThemeProvider>
  );
};

export default EditProductQuantityDialog;
