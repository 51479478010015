import React from 'react';
import { DatePicker, Row, Select } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
type LastCountDateProps = {
  value?: any;
  onChange?: (value: any) => void;
};
export default function LastCountDate(props: LastCountDateProps): JSX.Element {
  const { onChange, value } = props;
  let selectValue = value || '';
  if (typeof selectValue === 'object') {
    selectValue = 'range';
  }
  return (
    <Row>
      <Select
        value={selectValue}
        style={{ width: 150 }}
        onChange={(newValue) => {
          onChange?.(newValue);
        }}
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value="never">No Count Date</Select.Option>
        <Select.Option value="range">Date Range</Select.Option>
      </Select>
      {selectValue === 'range' && (
        <RangePicker
          style={{ marginLeft: 10 }}
          disabled={value === 'never'}
          showTime
          ranges={{
            Today: [moment().startOf('day'), moment().endOf('day')],
            Yesterday: [
              moment().add(-1, 'day').startOf('day'),
              moment().add(-1, 'day').endOf('day'),
            ],
            '2 days ago': [
              moment().add(-2, 'day').startOf('day'),
              moment().endOf('day'),
            ],
            '3 days ago': [
              moment().add(-3, 'day').startOf('day'),
              moment().endOf('day'),
            ],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'Last Week': [
              moment().add(-1, 'week').startOf('week'),
              moment().add(-1, 'week').endOf('week'),
            ],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          format="MM/DD/YYYY"
          onChange={(values) => {
            onChange?.(values);
          }}
        />
      )}
    </Row>
  );
}
LastCountDate.defaultProps = {
  value: undefined,
  onChange: undefined,
};
