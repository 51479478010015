import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Input,
  // Typography,
} from 'antd';
import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import {
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { SelectWarehouse } from 'components/common';
import {
  addChannelAccountAddress,
  editChannelAccountAddress,
} from 'services/channelAccountAddress';
import { getAddressList } from 'services/address';
import CreateAddressDialog from 'components/Admin/AddressBook/createDialog';

// const { Text } = Typography;

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  address?: ChannelAccountAddressRow;
  // addressBook: AddressRow[];
  store?: StoreChannelInfo;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, address, store } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addressBook, setAddressBook] = useState<AddressRow[]>([]);
  const [selectedFrom, setSelectedForm] = useState<AddressRow>();
  const [selectedReturn, setSelectedReturn] = useState<AddressRow>();
  const [createlVisible, setCreatelVisible] = React.useState(false);
  const [addressAct, setAddressAct] = React.useState<
    'add' | 'editFrom' | 'editReturn'
  >('add');

  const [form] = Form.useForm();

  const fetchAddressList = async () => {
    try {
      setLoading(true);
      const res = await getAddressList(0);
      setLoading(false);
      if (res.isSuccess) {
        setAddressBook(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields || !store) return;
    const postParams = {
      ChannelAccountAddressNum: address ? address?.channelAccountAddressNum : 0,
      ChannelAccountNum: store.channelAccountNum,
      WarehouseNum: params.WarehouseNum.warehouseNum || params.WarehouseNum.id,
      FromAddressNum: params.FromAddressNum,
      ReturnAddressNum: params.ReturnAddressNum,
      ReturnContact: params.ReturnContact,
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!address) {
        res = await addChannelAccountAddress(postParams);
      } else {
        res = await editChannelAccountAddress(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: address ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  /* eslint-disable */
  React.useEffect(() => {
    fetchAddressList();
  }, []);
  useEffect(() => {
    if (address && addressBook) {
      const temp = addressBook.filter(
        (item) => item.addressNum === address.fromAddressNum
      );
      setSelectedForm(temp.length > 0 ? temp[0] : undefined);
      const temp1 = addressBook.filter(
        (item) => item.addressNum === address.returnAddressNum
      );
      setSelectedReturn(temp1.length > 0 ? temp1[0] : undefined);
    }
  }, [address, addressBook]);
  /* eslint-enable */
  return (
    <Modal
      title={`${address ? 'Edit' : 'Add'} Channel Account Address`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              onClick={() => {
                setAddressAct('add');
                setCreatelVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              New Address
            </Button>
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {address ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${address ? 'Saving' : 'Adding'}`
                : `${address ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {addressBook.length > 0 && (
          <Form {...layout} form={form} style={{ marginTop: 20 }}>
            <Form.Item
              name="WarehouseNum"
              initialValue={address ? { id: address.warehouseNum } : undefined}
              label={<FormLabel>Warehouse </FormLabel>}
              rules={[{ required: true, message: 'Warehouse is required' }]}
            >
              <SelectWarehouse
                disabled={address ? true : false}
                width={438}
                placeholder="please select warehouse"
              />
            </Form.Item>
            <Form.Item
              name="FromAddressNum"
              initialValue={address?.fromAddressNum}
              label={<FormLabel>From Address</FormLabel>}
              rules={[{ required: true, message: 'From Address is required' }]}
              extra={
                selectedFrom ? (
                  <Space direction="vertical">
                    <span>{selectedFrom.contactName || ''}</span>
                    {selectedFrom.street1 && (
                      <span>{selectedFrom.street1}</span>
                    )}
                    {selectedFrom.street2 && (
                      <span>{selectedFrom.street2}</span>
                    )}
                    {selectedFrom.street3 && (
                      <span>{selectedFrom.street3}</span>
                    )}
                    <span>{`${selectedFrom.city || ''}, ${
                      selectedFrom.state || ''
                    } ${selectedFrom.postalCode || ''}`}</span>
                    <span>{selectedFrom.country || ''}</span>
                    <Button
                      onClick={() => {
                        setAddressAct('editFrom');
                        setCreatelVisible(true);
                      }}
                    >
                      <EditOutlined />
                      Update Address
                    </Button>
                  </Space>
                ) : (
                  ''
                )
              }
            >
              <Select
                optionLabelProp="label"
                showSearch
                optionFilterProp="label"
                onChange={(num) =>
                  setSelectedForm(
                    addressBook.filter(
                      (item) => item.addressNum === Number(num)
                    )[0]
                  )
                }
              >
                {addressBook.map((item) => (
                  <Select.Option
                    value={item.addressNum}
                    key={item.addressNum}
                    label={item.addressAlias}
                  >
                    <span>{`${item.addressAlias || ''} - ${
                      item.contactName ? item.contactName + ',' : ''
                    } ${item.street1 || ''} ${item.street2 || ''} ${
                      item.street3 ? item.street3 + ',' : ','
                    } ${item.city ? item.city + ',' : ''} ${item.state || ''} ${
                      item.postalCode ? item.postalCode + ',' : ','
                    } ${item.country || ''}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="ReturnContact"
              initialValue={address?.returnContact}
              label={<FormLabel>RMA Contact </FormLabel>}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="ReturnAddressNum"
              initialValue={address?.returnAddressNum}
              label={<FormLabel>Return Address </FormLabel>}
              rules={[
                { required: true, message: 'Return Address is required' },
              ]}
              // selectedReturn
              //   ? `${selectedReturn?.contactName}, ${selectedReturn?.street1} ${selectedReturn?.street2} ${selectedReturn?.street3}, ${selectedReturn?.city}, ${selectedReturn?.state} ${selectedReturn?.postalCode}, ${selectedReturn?.country}`
              //   : ''
              extra={
                selectedReturn ? (
                  <Space direction="vertical">
                    <span>{selectedReturn.contactName || ''}</span>
                    {selectedReturn.street1 && (
                      <span>{selectedReturn.street1}</span>
                    )}
                    {selectedReturn.street2 && (
                      <span>{selectedReturn.street2}</span>
                    )}
                    {selectedReturn.street3 && (
                      <span>{selectedReturn.street3}</span>
                    )}
                    <span>{`${selectedReturn.city || ''}, ${
                      selectedReturn.state || ''
                    } ${selectedReturn.postalCode || ''}`}</span>
                    <span>{selectedReturn.country || ''}</span>
                    <Button
                      onClick={() => {
                        setAddressAct('editReturn');
                        setCreatelVisible(true);
                      }}
                    >
                      <EditOutlined />
                      Update Address
                    </Button>
                  </Space>
                ) : (
                  ''
                )
              }
            >
              <Select
                optionLabelProp="label"
                showSearch
                optionFilterProp="label"
                onChange={(num) =>
                  setSelectedReturn(
                    addressBook.filter(
                      (item) => item.addressNum === Number(num)
                    )[0]
                  )
                }
              >
                {addressBook.map((item) => (
                  <Select.Option
                    value={item.addressNum}
                    key={item.addressNum}
                    label={item.addressAlias}
                  >
                    <span>{`${item.addressAlias || ''} - ${
                      item.contactName ? item.contactName + ',' : ''
                    } ${item.street1 || ''} ${item.street2 || ''} ${
                      item.street3 ? item.street3 + ',' : ','
                    } ${item.city ? item.city + ',' : ''} ${item.state || ''} ${
                      item.postalCode ? item.postalCode + ',' : ','
                    } ${item.country || ''}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Spin>
      {createlVisible && (
        <CreateAddressDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => fetchAddressList()}
          address={
            addressAct === 'add'
              ? undefined
              : addressAct === 'editFrom'
              ? selectedFrom
              : selectedReturn
          }
        />
      )}
    </Modal>
  );
};
