import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, CollapseProps, Row, Typography } from 'antd';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import FormLabel from './FormLabel';

export interface ConditionColumns {
  label: string;
  value: string;
}

interface SearchCollapseProps extends CollapseProps {
  children: React.ReactNode;
  conditionColumns: Array<ConditionColumns>;
  factor?: any;   // 会影响content高度的麻烦东西
}

const CollapseWrapper = styled.div`
	overflow: hidden;
	width: 100%
`

export default function SearchCollapse(props: SearchCollapseProps): JSX.Element {
  const { children, conditionColumns, factor } = props;
  const descriptionItems = conditionColumns.filter(item => item.value);
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [contentHeight, setContentHeight] = useState<number>();
  const [conditionHeight, setConditionHeight] = useState<number>();
  const contentRef = useRef<any>();
  const conditionRef = useRef<any>()

  useLayoutEffect(() => {
    if (contentRef.current) {
      const { offsetHeight } = contentRef.current;
      setContentHeight(offsetHeight)
    }
  }, [contentRef, showSearch, children, factor])

  useLayoutEffect(() => {
    if (conditionRef.current) {
      setConditionHeight(conditionRef.current.offsetHeight);
    }
  }, [conditionRef, showSearch])

  useEffect(() => {
    setIsExpand(showSearch);
  }, [showSearch])

  return (
    <CollapseWrapper>
      <Row>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: isExpand ? contentHeight : conditionHeight,
            transition: 'all 0.4s',
            overflow: 'hidden',
          }}
        >
          {
            showSearch ? (
              <div ref={contentRef}>{children}</div> 		// children is here!
            ) : (
              // descriptions part
              <div ref={conditionRef} style={{marginRight: 100}}>
                {/* {
                  descriptionItems.length > 0 ? (
                    <Descriptions title="Search Condition" size="small" >
                      {
                        descriptionItems
                          .map((item, index) =>
                            <Descriptions.Item key={index} label={<FormLabel noColon>{item.label}</FormLabel>}>{item.value}</Descriptions.Item>
                          )
                      }
                    </Descriptions>
                  ) : (
                    <Descriptions title="Search Condition" size="small" contentStyle={{ fontSize: 16, color: '#666' }} >
                      <Typography.Text>
                        You haven't searched yet.
                      </Typography.Text>
                    </Descriptions>
                  )
                } */}
                {
                  descriptionItems.length > 0 ? (
                    <Row gutter={30}>
                      {
                        descriptionItems.map((item, index) =>
                          <Col key={index}><FormLabel>{item.label}</FormLabel>{item.value}</Col>
                        )
                      }
                    </Row>
                  ) : (
                    <Typography.Title level={4}>
                      You haven't searched yet.
                    </Typography.Title>
                  )
                }
              </div>
            )
          }
          <Button
            style={{
              position: 'absolute',
              bottom: showSearch ? 20 : '50%',
              right: showSearch ? 20 : 8,
              transform: showSearch ? 'translateY(0%)' : 'translateY(50%)'
            }}
            type="link"
            onClick={() => setShowSearch(!showSearch)}
            icon={showSearch ? <UpOutlined /> : <DownOutlined />}
          >
            {showSearch ? 'Collapse' : 'Expand'}
          </Button>
        </div>
      </Row>
      {/* footer part */}
      {/* <Row justify="end">
        <Button
          type="link"
          onClick={() => setShowSearch(!showSearch)}
          icon={showSearch ? <UpOutlined /> : <DownOutlined />}
        >
          {showSearch ? 'Collapse' : 'Expand'}
        </Button>
      </Row> */}
    </CollapseWrapper>
  );
}
