/**
 * Product-related service requests are here.
 */
import { validateHttpStatus } from 'utils';
import { digitBridgeApi } from './http';

/**
 * Product Thumbnail Size Type
 */
export enum ProductThumbnailSizeType {
  small = 50,
  middle = 150,
}
/**
 * Fetch products
 * @param {[key:string]:any} query
 * @returns {Array<ProductRow>}
 */
export const fetchProducts = async (query?: {
  [key: string]: any;
}): Promise<any> => {
  let params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'SKU',
    $count: false,
    thumbnailSize: 150,
  };
  if (query && 'object' === typeof query) {
    params = {
      ...params,
      ...query,
    };
  }
  try {
    const { count, data } = (await digitBridgeApi.get('/api/products', {
      params,
    })) as any;
    const productRows = data.map((item: any) => {
      item.id = item.fulfillmentProductNum;
      return item;
    });
    return {
      count: params['$count'] ? count : productRows.length,
      products: productRows,
    };
  } catch (e) {
    /*if (e.response.status === 404) {
      return { count: 0, products: [] };
    }*/
    const { response } = e as { response: StringKAnyVPair };
    if (response && response.status === 404) {
      return { count: 0, products: [] };
    }
    throw e;
  }
};
/**
 * Fetch  product
 * @param {number} productId -- product id
 * @param {number} thumbnailSize -- thumbnail size
 * @returns {ProductRow | null}
 */
export const fetchProduct = async (
  productId: string,
  size?: ProductThumbnailSizeType
): Promise<ProductRow | null> => {
  try {
    const entity = (await digitBridgeApi.get(
      `/api/products/${productId}`
    )) as any;
    //fetch thumbnailUrl
    if (size) {
      entity.thumbnailUrl = await fetchProductThumbnailUrl(productId, size);
    }
    entity.id = entity.fulfillmentProductNum;
    return entity;
  } catch (e) {
    /*if (e.response.status === 404) {
      return null;
    }*/
    const { response } = e as { response: StringKAnyVPair };
    if (response && response.status === 404) {
      return null;
    }
    throw e;
  }
};
/**
 * Patch product. Note the api will return a boolean value.
 * @param {number} productId -- productId
 * @param {[key:string]:any} entity
 * @returns {void}
 */
export const patchProduct = async (
  productId: string,
  entity: StringKAnyVPair
): Promise<any> => {
  console.log(entity);
  const json = JSON.stringify(entity);
  return await digitBridgeApi.patch(
    `/api/products/${productId}`,
    json
  );
};
/**
 * Fetch  media url list
 * @param {number} productId --product id
 */
export const fetchProductMedias = async (
  productId: string
): Promise<Array<ProductPictureEntity>> => {
  return (await digitBridgeApi.get(
    `/api/products/${productId}/medias`
  )) as any;
};
/**
 * Post product media
 * @param {number} productId -- product id
 * @param {string} url -- url
 * @returns {void}
 */
export const postProductMedia = async (
  productId: string,
  url: string
): Promise<void> => {
  const json = JSON.stringify({ MediaUrl: url, MediaType: 1, SeqNo: 1 });
  return await digitBridgeApi.post(
    `/api/products/${productId}/medias`,
    json
  );
};
/**
 * Delete product media
 * @param {number} productId -- product id
 * @param {number} rowNum -- rownum
 * @returns {void}
 */
export const deleteProductMedia = async (
  productId: string,
  rowNum: number
): Promise<void> => {
  return await digitBridgeApi.delete(
    `/api/products/${productId}/medias/${rowNum}`
  );
};
/**
 * Post product media as primary picture
 * @param {number} productId -- product id
 * @param {number} rowNum -- rownum
 * @returns {void}
 */
export const postProductPrimaryMedia = async (
  productId: string,
  rowNum: number
): Promise<void> => {
  return await digitBridgeApi.post(
    `/api/products/${productId}/medias/${rowNum}`
  );
};
/**
 * @deprecated
 * Update inventory location
 * @param {number} productId -- product id
 * @param {ProductRow} detail -- detail
 * @param {number} warehouseId
 * @param {number} locationId -- locationId
 * @returns {Promise<boolean>}
 */
export const updateLocation = async (
  productId: string,
  detail: ProductRow,
  warehouseId: number,
  locationId: number
): Promise<boolean> => {
  const json = JSON.stringify({
    WarehouseNum: warehouseId,
    LocationNum: locationId,
    SKU: detail.sku,
    LastTransactionReasonName: 'Add Item',
    quantity: (detail as any).quantity,
  });
  return await digitBridgeApi.post(
    `/api/products/${productId}/updateQuantity`,
    json
  );
};

/**
 * Update the product quantity.
 * It seems that the SKU property is needed.
 */
export const updateProductQuantity = async (
  updateData: any[]
): Promise<boolean> => {
  return await digitBridgeApi.post(
    //`/api/products/updateQuantity/${dbCode()}`,
    '/api/products/updateQuantity',
    JSON.stringify(updateData)
  );
};

/**
 * Fetch the inventory list of warehouse.
 *
 * @param {WarehouseRow} warehouse
 * @param {[key: string]: any} - the query options, it contains these props:
 *                               $skip - how many records will been skipped
 *                               $top - how many records will been returned
 *                               $sortBy - which field to sort
 *                               $calculateTotal - whether to count the total
 *                               $count - whether to count the records number
 *                               $filter
 */
export const fetchInventory = async (
  warehouse: { id: number, warehouseId: string },
  query?: StringKAnyVPair
): Promise<InventoryEntity> => {
  let params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'SKU',
    $calculateTotal: false,
    $count: false,
  };

  if (query && 'object' === typeof query) {
    params = {
      ...params,
      ...query,
    };
  }

  const entity = (await digitBridgeApi.get(
    `/api/products/inventories/${warehouse.warehouseId}`,
    { params }
  )) as any;
  const data = entity.data.map((item: any, index: number) => {
    item.rowId = index;
    item.id = item.fulfillmentProductNum;
    return item;
  });
  return {
    ...entity,
    data,
  };
};

/**
 * This function is a variant of previous fetchInventory.
 */
export const fetchInventoryByWarehouseId = async (
  warehouseId: string,
  query?: StringKAnyVPair
): Promise<InventoryEntity> => {
  let params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'SKU',
    $calculateTotal: false,
    $count: false,
  };

  if (query && 'object' === typeof query) {
    params = {
      ...params,
      ...query,
    };
  }

  const entity = (await digitBridgeApi.get(
    `/api/products/inventories/${warehouseId}`,
    { params }
  )) as any;
  const data = entity.data.map((item: any) => {
    item.id = item.fulfillmentProductNum;
    return item;
  });
  return {
    ...entity,
    data,
  };
};

/**
 * Add inventory items
 * @param {WarehouseRow} warehouse warehouse
 * @param {ProductRow} product
 * @param {WarehouseLocationRow} location
 * @param {TransactionReasonRow} transactionReason
 * @param {number} quantity
 */
// export const inventoryRemoveItems = async (
//   warehouse: WarehouseRow,
//   product: ProductRow,
//   location: WarehouseLocationRow,
//   transactionReason: TransactionReasonRow,
//   quantity: number
// ): Promise<void> => {
//   const json = JSON.stringify({
//     LastWarehouseName: warehouse.name,
//     LastWarehouseCode: warehouse.code,
//     // WarehouseNum: warehouse.id,
//     // LocationNum: location.id,
//     SKU: product.sku,
//     LastProductTitle: product.productTitle,
//     Quantity: quantity,
//     LastTransactionReasonName: transactionReason.name,
//     TransactionType: transactionReason.type,
//     LocationCode: location.code,
//     // WarehouseCode: warehouse.id,
//   });
//   await digitBridgeApi.post(
//     `/api/products/${product.id}/updateQuantity/${warehouse.id}-${location.id
//     }`,
//     json
//   );
// };

/**
 *  inventory receive
 * @param productId productId
 * @param entity
 */
export const inventoryReceive = async (
  warehouseId: number,
  locationId: number,
  locationCode: string,
  productId: string,
  sku: string,
  quantity: number,
  po: string,
  code: string,
  name: string
): Promise<boolean> => {
  const json = JSON.stringify({
    // WarehouseNum: warehouseId,
    // LocationNum: locationId,
    LocationCode: locationCode,
    SKU: sku,
    Quantity: quantity,
    LastPoNumber: po,
    LastTransactionReasonName: 'Add',
    TransactionType: 2,
    LastWarehouseName: code,
    LastWarehouseCode: name,
    // LastScannedCode: 1234,
    // LocationCode: Loc 001,
    // WarehouseCode: tqf002,
  });
  return await digitBridgeApi.post(
    `/api/products/${productId}/updateQuantity/${warehouseId}-${locationId}`,
    json
  );
};
/**
 * Search product by SKU/productTitle
 * @returns {Array<ProductRow>}
 */
export const searchProductByKey = async (
  key: string,
  onlySku?: boolean,
): Promise<ProductRow | undefined> => {
  const filter = onlySku ? `sku eq '${key}' or upc eq '${key}'` : `sku eq '${key}' or upc eq '${key}' or productTitle eq '${key}'`;
  const params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'SKU',
    $filter: filter,
    $count: false,
    thumbnailSize: 150,
  };
  try {
    const { data } = (await digitBridgeApi.get('/api/products', {
      params,
    })) as any;
    if (data.length > 0) {
      return data.map((entity: any) => {
        entity.id = entity.fulfillmentProductNum;
        return entity;
      })[0];
    }
  } catch (e) {
    return undefined;
  }
  return undefined;
};

/**
 * Delete Product by id
 */
export const delProductById = async (id: string): Promise<any> => {
  return await digitBridgeApi.delete(`/api/products/${id}`);
};

/**
 *  create product
 */
type CreateProductParams = {
  SKU: string;
  PartNumber: string;
  ProductTitle: string;
  Brand: number;
  CalssificationNum: number;
  PrimarySupplier: string;
  Cost: number;
};
export const createProduct = async (
  params: CreateProductParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/products', json);
};

/**
 * Fetch  product Thumbnail
 * @param {number} productId -- product id
 * @param {number} size -- image size
 * @returns {string}
 */
export const fetchProductThumbnailUrl = async (
  productId: string,
  size?: number
): Promise<string> => {
  try {
    const entity = (await digitBridgeApi.get(
      `/api/products/${productId}/thumbnails/${size || 150}`,
      { validateStatus: (status) => validateHttpStatus([404], status) }
    )) as any;
    return entity.mediaUrl;
  } catch (e) {
    return '';
  }
};

/**
 * adjustmentItems
 * @param {WarehouseRow} warehouse warehouse
 * @param {ProductRow} product
 * @param {WarehouseLocationRow} location
 * @param {number} adjustmentType
 * @param {number} quantity
 */
type AdjustmentItemsParams = {
  warehouseId: string;
  productId: number;
  locationId: number;
  adjustmentType: number;
  quantity: number;
  transactionReason: string;
};
export const adjustmentItems = async (
  params: AdjustmentItemsParams
): Promise<boolean> => {
  const json = JSON.stringify({
    Quantity: params.quantity,
    AdjustmentType: params.adjustmentType,
    TransactionReason: params.transactionReason,
  });
  try {
    await digitBridgeApi.post(
      `/api/warehouses/${params.warehouseId}/locations/${params.locationId
      }/products/${params.productId}/adjustment`,
      json
    );
    return true;
  } catch (e) {
    return false;
  }
};
/**
 * Set Default Package
 */
type UpdateProductDefaultPackageParams = {
  FulfillmentProductNum: number,
  ShippingCustomPackageNum: number,
  PackageMaxQty: number,
  BoxLength: number,
  BoxWidth: number,
  BoxHeight: number,
  ProductLength: number,
  ProductWidth: number,
  ProductHeight: number
}
export const updateProductDefaultPackage = async (params: UpdateProductDefaultPackageParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateProductPackage', json)
}

/**
 *  get default packageid by productid
 */
export const getDefaultpackageId = async (productNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/productPackages/${productNum}`)
}

/**
 * product Flat Rate Option
 */
type ProductFlatRateOptionsParams = {
  ProductFlatRateOptionNum: number,
  FulfillmentProductNum: number,
  CarrierNum: number,
  Qualified: number,
  CarrierPackageNum?: number,
  PackageMaxQty?: number,
}

/**
 * flate rate
 */
export const addProductFlatRateOption = async (params: ProductFlatRateOptionsParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/AddProductFlatRateOption', json)
}

export const updateProductFlatRateOption = async (params: ProductFlatRateOptionsParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateProductFlatRateOption', json)
}

export const getProductFlatRateOptionList = async (fulfillmentProductNum: number): Promise<any> => {
  return await digitBridgeApi.get('/api/GetProductFlatRateOptionList', { params: { fulfillmentProductNum } })
}

export const delProductFlatRateOptionById = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/productFlatRateOptions/${id}`)
}

/**
 *  product carrier signature 
 */
type ProductCarrierSignatureParams = {
  ProductCarrierSignatureNum: number,
  FulfillmentProductNum: number,
  CarrierSignatureNum: number
}
export const addProductCarrierSignature = async (params: ProductCarrierSignatureParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/AddProductCarrierSignature', json)
}

export const updateProductCarrierSignature = async (params: ProductCarrierSignatureParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateProductCarrierSignature', json)
}

export const getProductCarrierSignature = async (fulfillmentProductNum: number): Promise<any> => {
  return await digitBridgeApi.get('/api/GetProductCarrierSignatureList', { params: { fulfillmentProductNum } })
}

export const delProductCarrierSignatureById = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/productCarrierSignatures/${id}`)
}
export const getCarrierSignatures = async (): Promise<any> => {
  // return await digitBridgeApi.get('/api/GetCarrierSignatures')
  const res: any = await digitBridgeApi.get('/api/GetCarrierSignatures');
  const resOptions: any[] = [];
  if (res.isSuccess && res.data.length > 0) {
    const arr = [
      'No Signature Required',
      'Direct signature required',
      'Adult Signature',
      'Adult Signature Restricted Delivery',
      'Indirect Signature'
    ]
    let options = res.data || [];
    arr.forEach(name => {
      resOptions.push(...options.filter((opt: any) => opt.name === name));
      options = options.filter((opt: any) => opt.name !== name);
    })
    resOptions.push(...options);
    return { ...res, data: resOptions };
  }
}

export const queryProductByKeyword = async (keyword: string): Promise<any> => {
  const params = {
    isContains: false,
    keyword,
  }
  return await digitBridgeApi.get('/api/products/partial', { params })
}

export const getProductCustomsDeclaration = async (fulfillmentProductNum: number, code?: string): Promise<any> => {
  const params = {
    code
  }
  return await digitBridgeApi.get(`/api/Products/${fulfillmentProductNum}/CustomsDeclaration`, { params })
}
type CustomsDeclarationParams = {
  CustomsDeclarationNum: number,
  FulfillmentProductNum: number,
  Description: string,
  CurrencyCode: string,
  DeclaredValue: number,
  HarmonizationCode: string,
  OriginCountryNum: number,
  CustomsDeclarationNote: string,
}
export const updateProductCustomsDeclaration = async (params: CustomsDeclarationParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/Products/${params.FulfillmentProductNum}/CustomsDeclaration`, json)
}


export const setCommodityDescription = async (nums: number[], description: string): Promise<any> => {
  const json = JSON.stringify({
    FulfillmentProductNums: nums,
    CommodityDescription: description,
  })
  return await digitBridgeApi.put('/api/Products', json)
}

export const syncProductToERP = async (skus: string[], updateExist: boolean, syncDimension: boolean, syncWeight: boolean): Promise<any> => {
  const json = JSON.stringify(skus)
  const params = {
    updateExist,
    syncDimension,
    syncWeight
  }
  return await digitBridgeApi.post('/api/products/sync', json, { params })
}

export const batchArchive = async (FulfillmentProductNums: number[]): Promise<any> => {
  const json = JSON.stringify({ FulfillmentProductNums })
  return await digitBridgeApi.post('/api/products/archives', json)
}

export const getSyncList = async (createDate: string): Promise<any> => {
  const params = {
    createDate,
  }
  return await digitBridgeApi.get('/api/products/synclist', { params })
}

export const getBundleDetail = async (productId: string): Promise<any> => { return await digitBridgeApi.get(`/api/BundleProduct/${productId}`) };

export const getProductLotRule = async (productNum: number): Promise<any> => { return await digitBridgeApi.get(`/api/products/${productNum}/lotRule`) };

export const getProductLotList = async (productId: string): Promise<any> => { return await digitBridgeApi.get(`/api/products/${productId}/lot`) };

export const getProductUomList = async (sku: string): Promise<any> => {
  const params = {
    sku,
  }
  return await digitBridgeApi.get('/api/products/uomList', { params })
}

export const getCheckoutProductLotUom = async (warehouseId: string, productNum: number): Promise<any> => {return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/products/${productNum}/checkouts`)}

type AdjustmentItemsParams2 = {
  warehouseId: string;
  productNum: number;
  locationNum: number;
  quantity: number;
  transactionReason: string;
  lotNumber?: string;
  uom?: string;
  UomRate?: number;
};
export const adjustmentItems2 = async (
  params: AdjustmentItemsParams2
): Promise<boolean> => {
  const json = JSON.stringify({
    Quantity: params.quantity,
    TransactionReason: params.transactionReason,
    LotNumber: params.lotNumber,
    Uom: params.uom,
    UomRate: params.UomRate
  });
  try {
    await digitBridgeApi.post(
      `/api/warehouses/${params.warehouseId}/locations/${params.locationNum
      }/products/${params.productNum}/auditAddItem`,
      json
    );
    return true;
  } catch (e) {
    return false;
  }
};

type AdjustmentItemsParams3 = {
  warehouseId: string;
  productNum: number;
  locationNum: number;
  adjustmentType: number;
  quantity: number;
  transactionReason: string;
  lotNumber?: string;
  uom?: string;
  uomRate?: number;
};

export const adjustmentItems3 = async (
  params: AdjustmentItemsParams3
): Promise<boolean> => {
  const json = JSON.stringify({
    Quantity: params.quantity,
    TransactionReason: params.transactionReason,
    LotNumber: params.lotNumber,
    Uom: params.uom,
    UomRate: params.uomRate,
    AdjustmentType: params.adjustmentType
  });
  try {
    await digitBridgeApi.post(
      `/api/warehouses/${params.warehouseId}/locations/${params.locationNum
      }/products/${params.productNum}/adjustment2`,
      json
    );
    return true;
  } catch (e) {
    return false;
  }
};