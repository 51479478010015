import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dispatch } from 'redux';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Modal,
  Row,
  //Select,
  Space,
} from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { isEqualObject } from 'crud-object-diff';
import { v4 as uuid } from 'uuid';
//import ShippingAccountSelector from './ShippingAccountSelector';
//import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';

import {
  FormField,
  FormLabel,
  //Loading,
  ScreenMask,
  TimerLock,
  message,
} from 'components/common';
import {
  ButtonIcon,
  //DangerButton,
  Label,
  InfoButton,
  //SecondaryButton,
} from 'components/common/styles';
import {
  FormRightAlign,
  FormVSpacer,
} from 'components/common/styles/CustomPropsComponents';
import {
  //COMMON_MSG_DEFAULT_DISPLAY_DURATION,
  DEFAULT_PRINT_MODE,
  //LOADING_ICON_SIZE1,
  LS_DEFAULT_WAREHOUSE_KEY,
  MESSAGE_BAR_POS_TOP1,
  MIN_FEDEX_SMART_POST_WEIGHT,
  PRINT_MESSAGE_TYPE_ERROR,
  PRINT_TYPE_LABEL,
  SERVICE_FEDEX_SMARTPOST_NUM,
  SHIPMENT_ITEM_VERIFY_STATE,
  SHIPMENT_LABEL_SIZE1,
  SHIPMENT_SHIPPING_STATE,
} from 'constants/config';
import { RootState } from 'reducers';
import { printPackingSlip, printShipmentLabel } from 'actions/printActions';
import {
  //setPrintLabelEnd,
  setPrintLabelResponse,
  //setPrintLabelMessage as setLabelPrintingMessage,
  setPrintLabelResult,
  setPrintingHang,
  setScale,
} from 'actions/salesAction';
//import { joinGroup, leaveGroup } from 'actions/signalrAction';
import {
  getShipmentRate,
  postPrintShipmentLabel,
  postPrintShipmentPackingSlip,
  markShipmentShipped,
} from 'services/sales';
import { fetchShipmentDetail } from 'services/shipment';
import { getProfileSettingValue, isMacos, /*isTestEnv,*/ nowTime } from 'utils';
import { userProfiles } from 'utils/auth';

import ShoppingAroundDialog from '../ScanVerifyPrint/ShoppingAroundDialog';
import MarkShippedDialog from '../ShipmentList/MarkShippedDialog';
import { ScanToShip, ShipmentUnit, isSkipPrintPackSlip } from '.';
import {
  BottomButtonRow,
  LARGE_WIDTH_LIMIT,
  LabelFormDropdownItem,
  MaskMessageBox,
  PrintLabelFormContainer,
  PrintLabelTitlebar,
  //PrintOptionContainer,
  PrintOptionPanel,
  RightLabelFormContainer,
  SizeInputRow,
  UnitLabel,
} from './styles';
import AlertDialog from './AlertDialog';
import CountrySelector from './CountrySelector';
import DeviceSettingDialog from './DeviceSettingDialog';
import ServiceSelector from './ServiceSelector';
import PackageSelector from './PackageSelector';
import SignatureSelector from './SignatureSelector';
import WeightBoard from './WeightBoard';

type Props = {
  changeShipment?: Function;
  deviceSetting?: Function;
  getPackingSlipType?: Function;
  shipment: ShipmentUnit | null;
  onMarkShipped?: Function;
  onPrintLabelSuccess?: Function;
  openShipMultiPackages?: Function;
  markVerified: boolean;
  syncShipmentProps?: Function;
  multiPkgShipments?: StringKAnyVPair[];
};

let bottomMessageTimer: any = null;
let keydownHandler: any = null;

const PrintLabel = (props: Props) => {
  const FORM_COL1_WIDTH = 7;
  const FORM_COL2_WIDTH = 17;
  const FORM_FIELD_WIDTH1 = 290;
  //const INPUTNUMBER1_WIDTH = 60;
  const PRINT_INTERVAL = 1000;
  const { useState } = React;
  const dispatch: Dispatch<any> = useDispatch();
  const selectedShipment = props.shipment;
  const [activeShipOptionPanelKey, setActiveShipOptionPanelKey] = useState<string[]>([]);
  const [alertDialogContent, setAlertDialogContent] = useState('');
  const [alertDialogOnClose, setAlertDialogOnClose] = useState<Function>();
  const [alertDialogTitle, setAlertDialogTitle] = useState('');
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [BTPAccount, setBTPAccount] = useState('');
  const [BTPAccountError, setBTPAccountError] = useState(false);
  const [BTPAccountErrorMessage, setBTPAccountErrorMessage] = useState('');
  const [BTPCountry, setBTPCountry] = useState<number>();
  const [BTPPostCode, setBTPPostCode] = useState('');
  const [BTPPostCodeError, setBTPPostCodeError] = useState(false);
  const [BTPPostCodeErrorMessage, setBTPPostCodeErrorMessage] = useState('');
  const [confirmShipVisible, setConfirmShipVisible] = useState(false);
  const [currentPageWidth, setCurrentPageWidth] = useState(0);
  const [currentShipmentNum, setCurrentShipmentNum] = useState(0);
  //const [deviceDialogClosable, setDeviceDialogClosable] = useState(true);
  // eslint-disable-next-line
  const [deliveryTime, setDeliveryTime] = useState('');
  const [deviceSettingVisible, setDeviceSettingVisible] = useState(false);
  const [formFieldSize, setFormFieldSize] = useState<GeneralSizeType>('middle');
  const [formField1Width, setFormField1Width] = useState(FORM_FIELD_WIDTH1);
  //const [currentScale, setCurrentScale] = useState<Scale>();
  const [inited, setInited] = useState(false);
  const [insuredValue, setInsuredValue] = useState<number>();
  const [lastPrintTime, setLastPrintTime] = useState(0);
  const [lastRateParam, setLastRateParam] = useState<StringKAnyVPair>({});
  const [lastScale, setLastScale] = useState<Scale>();
  const [lockScreen, setLockScreen] = useState(false);
  const [markTrackDialogVisible, setMarkTrackDialogVisible] = useState(false);
  const [needBTP, setNeedBTP] = useState(false);
  const [packageHeight, setPackageHeight] = useState<number>();
  const [packageLength, setPackageLength] = useState<number>();
  const [packageName, setPackageName] = useState('');
  const [packageNum, setPackageNum] = useState(-1);
  const [packageServiceError, setPackageServiceError] = useState(false);
  const [packageServiceErrorMessage, setPackageServiceErrorMessage] =
    useState('');
  const [packageType, setPackageType] = useState('');
  const [packageTypeError, setPackageTypeError] = useState(false);
  const [packageTypeErrorMessage, setPackageTypeErrorMessage] = useState('');
  const [packageUpdateTag, setPackageUpdateTag] = useState<string>();
  const [packageWeight, setPackageWeight] = useState(0);
  const [packageWidth, setPackageWidth] = useState<number>();
  // eslint-disable-next-line
  const [printLabelError, setPrintLabelError] = useState(false);
  // eslint-disable-next-line
  const [printLabelMessage, setPrintLabelMessage] = useState('');
  const [printMessageBarStyle, setPrintMessageBarStyle] =
    useState<StringKAnyVPair>({ top: MESSAGE_BAR_POS_TOP1 });
  const [serviceCode, setServiceCode] = useState<string>();
  const [serviceUpdateTag, setServiceUpdateTag] = useState<string>();
  const [shipCarrier, setShipCarrier] = useState('');
  const [shipCarrierNum, setShipCarrierNum] = useState(0);
  const [shipConfirmation, setShipConfirmation] = useState<number | null>(null);
  const [shipFrom, setShipFrom] = useState(-1);
  // eslint-disable-next-line
  const [shipFromError, setShipFromError] = useState(false);
  // eslint-disable-next-line
  const [shipFromErrorMessage, setShipFromErrorMessage] = useState('');
  const [shipMultiPkgEnabled, setShipMultiPkgEnabled] = useState(false);
  const [shipService, setShipService] = useState('');
  const [shipServiceNum, setShipServiceNum] = useState(0);
  const [shipAccountNum, setShipAccountNum] = useState(0);
  const [shipWeight, setShipWeight] = useState<number>();
  const [shoppingAroundDialogVisible, setShoppingAroundDialogVisible] =
    useState(false);
  const [shoppingAroundSizeLocked, setShoppingAroundSizeLocked] = useState(false);
  const [shopAroundUpdateTag, setShopAroundUpdateTag] = useState('');
  const [topMessage, setTopMessage] = useState('');
  const [useScale, setUseScale] = useState(true);
  const [canNotChangeSize, setCanNotChangeSize] = useState(false);
  const optionPanel = React.useRef<any>(null);
  const printLabelButton = React.useRef<any>(null);
  const shell = React.useRef<any>(null);
  const unlockButton = React.useRef<any>(null);
  const [CN22, setCN22] = React.useState(false);

  const i18n = useTranslation();

  const cartonPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinterCoton,
    shallowEqual
  );

  const packingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );
  const packingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode,
    shallowEqual
  );
  const pageWidth: number = useSelector(
    (state: RootState) => state.regular.pageWidth,
    shallowEqual
  );
  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );
  const printLabelResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResult,
    shallowEqual
  );
  const printstationIsOk: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printstationIsOk,
    shallowEqual,
  );
  const scaleLB: number = useSelector(
    (state: RootState) => state.sales.scaleLB,
    shallowEqual
  );
  const scaleOZ: number = useSelector(
    (state: RootState) => state.sales.scaleOZ
  );
  const selectedScale: Scale = useSelector(
    (state: RootState) => state.sales.scale,
    shallowEqual
  );
  const shipPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter,
    shallowEqual
  );
  const shipPrinter4x8: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter4x8,
    shallowEqual
  );
  const selectedScaleIsOnline: boolean = React.useMemo(() => {
    let ret = false;

    if (selectedScale.scaleNum > 0) {
      ret = 'number' === typeof scaleLB && 'number' === typeof scaleOZ;
    }

    return ret;
  }, [scaleLB, scaleOZ, selectedScale]);
  const selectedShipmentNum = React.useMemo(() => {
    return selectedShipment ? selectedShipment.shipmentNum : 0;
  }, [selectedShipment]);
  const selectedShipmentSignature = React.useMemo(() => {
    return selectedShipment && typeof selectedShipment.signatureRequired === 'number' ? selectedShipment.signatureRequired : undefined;
  }, [selectedShipment]);
  // eslint-disable-next-line
  const selectedShipmentUseMappedServiceAndPackage = React.useMemo(() => {
    return selectedShipment ?
      (selectedShipment.shipServiceNum > 0 && selectedShipment.shipPackageNum >= 0 && selectedShipment.onlyMappedServiceAndPackage === 1) :
      false;
  }, [selectedShipment]);
  const shipmentNeedPrintPackingSlip = React.useMemo(() => {
    const shipment = selectedShipment as StringKAnyVPair;
    const skipPS = isSkipPrintPackSlip();
    let ret = false;

    // if (shipment && ...) do not use shipment to replace selectedShipment at first
    if (selectedShipment && shipment.isPrintPackingSlip && !(shipment.allowSkipPrintPackingSlip && skipPS)) ret = true;

    return ret;
  }, [selectedShipment]);
  const shipmentPrintable = React.useMemo(() => {
    return (
      !!(
        selectedShipment &&
        selectedShipment.isLabelSeparately !== 1 &&
        selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
      ) && props.markVerified
    );
  }, [props, selectedShipment]);
  const shipmentShippedMarkable = React.useMemo(() => {
    return (
      !!(
        selectedShipment &&
        (selectedShipment.isLabelSeparately === 1 ||
          (selectedShipment.isLabelSeparately === 0 &&
            !selectedShipment.labelRequired))
      ) && props.markVerified
    );
  }, [props, selectedShipment]);

  const checkShipMultiPkgEnabled = async () => {
    const enabled = await getProfileSettingValue('EnableMultiPackage', '0');

    setShipMultiPkgEnabled(`${enabled}` === '1' ? true : false);
    //console.log('e-->', enabled, typeof enabled);
  };

  const handleMarkAsShipped = async () => {
    //console.log('mark as shipped', selectedShipmentNum);
    setConfirmShipVisible(false);
    setLockScreen(true);

    try {
      await markShipmentShipped(selectedShipmentNum);

      if (typeof props.onMarkShipped === 'function') {
        await props.onMarkShipped();
      }

      if (shipmentNeedPrintPackingSlip) {
        printShipmentPackingSlip();
      }
    } catch (e) {
      openAlertDialog({
        title: 'Error',
        content: `Mark as shipped error ${e}`,
      });
    } finally {
      setLockScreen(false);
    }
  };

  // eslint-disable-next-line
  const rightDropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={handleMarkAsShipped}>
        <LabelFormDropdownItem>Mark as Shipped</LabelFormDropdownItem>
      </Menu.Item>
    </Menu>
  );

  const adjustLayout = () => {
    if (!shell?.current) return;

    if (window.innerWidth > LARGE_WIDTH_LIMIT) {
      setFormFieldSize('large');
      setFormField1Width(360);
    } else {
      setFormFieldSize('middle');
      setFormField1Width(FORM_FIELD_WIDTH1);
    }
  };

  const clearFormValue = () => {
    setShipAccountNum(0);
    setShipCarrier('');
    setShipCarrierNum(0);
    setShipService('');
    setShipServiceNum(0);
    setPackageName('');
    setPackageNum(-1);
    setPackageType('');
    setPackageHeight(undefined);
    setPackageLength(undefined);
    setPackageWidth(undefined);
    setPackageWeight(0);
    setTopMessage('');
    console.log('clear clear clear');
  };

  const confirmMarkAsShipped = () => {
    const shipmentTitle = selectedShipment ? selectedShipment.shipmentTitle : '';

    setConfirmShipVisible(false);
    Modal.confirm({
      title: 'Mark as Shipped',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to mark '${shipmentTitle}' as shipped`,
      okText: 'Mark',
      onOk: handleMarkAsShipped,
      cancelText: 'Cancel',
    });
  };

  const fillFormValueByShipment = (shipment: StringKAnyVPair) => {
    const {
      height,
      length,
      packageCode,
      packageName,
      shipCarrierNum,
      shipPackageNum,
      shippingAccountNum,
      shippingCarrier,
      shippingServiceCode,
      width,
    } = shipment;

    if (packageCode) setPackageType(packageCode);
    if (packageName) setPackageName(packageName);
    if (shipCarrierNum) setShipCarrierNum(shipCarrierNum);
    if (typeof shipPackageNum === 'number') setPackageNum(shipPackageNum);
    if (shippingAccountNum) setShipAccountNum(shippingAccountNum);
    if (shippingCarrier) setShipCarrier(shippingCarrier);
    if (shippingServiceCode) setShipService(shippingServiceCode);

    setPackageHeight(height || undefined);
    setPackageLength(length || undefined);
    setPackageWidth(width || undefined);
    setShipWeight(
      selectedShipment ? selectedShipment.weight || undefined : undefined
    );
    setServiceCode(
      selectedShipment
        ? selectedShipment.shippingServiceCode || undefined
        : undefined
    );
    setPackageUpdateTag(uuid());
    setServiceUpdateTag(uuid());
  };

  /**
   * Get the shipping rate.
   */
  const getShipRate = async (param: StringKAnyVPair) => {
    setLastRateParam(param);
    const rate = await getShipmentRate(selectedShipmentNum, param);

    console.log('rate', rate);
    setLastRateParam(param);

    if (rate && typeof rate === 'object') {
      setDeliveryTime(rate.guaranteedDelivery);
    }
  };

  // eslint-disable-next-line
  const handleKeydown = (evt: any) => {
    if (evt.target.tagName.toLowerCase() !== 'input') {
      //console.log('kkk ccc', evt.keyCode);
      if (evt.keyCode === 27) {
        if (unlockButton?.current) {
          unlockButton?.current.click();
        }

        setConfirmShipVisible(false);
      }

      if (evt.keyCode === 80) {
        printLabelButton?.current.click();
      }
    }
  };

  /**
   * The handler for not using scale.
   */
  const handleNotUseScale = () => {
    if (selectedScale.scaleNum > 0) {
      setLastScale({ ...selectedScale });
    }

    dispatch(setScale({}));
    setUseScale(false);
  };

  /**
   * The handler for printing shipping label.
   */
  const handlePrintLabel = async () => {
    let labelPrinter = shipPrinter;
    console.log('sf', shipFrom, shipPrinter, insuredValue);
    if (
      shipPrinter.printerNum === undefined ||
      shipPrinter.printerNum <= 0
    ) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    if (props.shipment) {
      console.log('shipment size', props.shipment.labelSize);
      if (props.shipment.labelSize === SHIPMENT_LABEL_SIZE1) {
        if (
          shipPrinter4x8.printerNum === undefined ||
          shipPrinter4x8.printerNum <= 0
        ) {
          if (typeof props.deviceSetting === 'function') {
            props.deviceSetting();
            message.info(`Please select a 4'' * 8'' inch printer to print this label`);
          }

          return;
        }

        labelPrinter = shipPrinter4x8;
      }
    }

    if (props.shipment && props.shipment.isPrintCartonLabel) {
      if (
        cartonPrinter.printerNum === undefined ||
        cartonPrinter.printerNum <= 0
      ) {
        if (typeof props.deviceSetting === 'function') {
          props.deviceSetting();
          message.info('Please select a carton label printer to ready to print carton label');
        }

        return;
      }
    }

    //dispatch(setLabelPrintingMessage({ShipmentNum: selectedShipmentNum, TrackingNumber: 9998880000}));
    //return;

    if (shipFrom < 0) {
      //setShipFromError(true);
      //setShipFromErrorMessage('Please Select a Ship From');
      openAlertDialog({ title: 'Error', content: 'Please select a warehouse' });

      return;
    }

    //openAlertDialog({title: 'Print Error',});
    if (shipAccountNum <= 0) {
      setPackageServiceError(true);
      setPackageServiceErrorMessage('Please select a service');
      openAlertDialog({ title: 'Error', content: 'Please select a service' });
      //setPrintMessageBarStyle({ top: 0 });
      //setPrintLabelError(true);
      //setPrintLabelMessage('Please select a service');
      return;
    }

    if (packageWeight <= 0) {
      //setPrintMessageBarStyle({ top: 0 });
      //setPrintLabelError(true);
      //setPrintLabelMessage('Please specify the weight');
      openAlertDialog({ title: 'Error', content: 'Please specify the weight' });
      return;
    }

    /*if (!packageLength || !packageHeight || !packageWidth) {
      //setPrintMessageBarStyle({ top: 0 });
      //setPrintLabelError(true);
      //setPrintLabelMessage('Please specify valid size');
      openAlertDialog({ title: 'Error', content: 'Please specify valid size' });
      return;
    }*/

    //if (!packageType) {
    if (packageNum < 0) {
      setPackageTypeError(true);
      setPackageTypeErrorMessage('Please Select a Package Type');
      openAlertDialog({ title: 'Error', content: 'Please select a package' });

      return;
    }

    if (needBTP) {
      const invalidBTP = false;

      if (!BTPAccount) {
        setBTPAccountError(true);
        setBTPAccountErrorMessage('Please input the account');
        //invalidBTP = true;
      }

      if (!BTPPostCode) {
        setBTPPostCodeError(true);
        setBTPPostCodeErrorMessage('Please input the postal code');
        //invalidBTP = true;
      }

      if (invalidBTP) {
        openAlertDialog({
          title: 'Error',
          content: 'Please provide valid third party content',
        });
        return;
      }
    }

    const profile = userProfiles.profile;
    const printTime = (new Date()).getTime();
    const pd = {
      isBatch: false,
      ShipmentNum: selectedShipmentNum,
      ShippingAccountNum: shipAccountNum,
      ShipFrom: shipFrom,
      //ShipFrom: warehouseId,
      //ShipFrom: 10032,
      Carrier: shipCarrier,
      CarrierNum: shipCarrierNum,
      Confirmation: shipConfirmation,
      Service: shipService,
      PackageName: packageName,
      PackageType: packageType,
      //PackageType: 'FedExYourPackaging',
      PrinterName: labelPrinter.printerName,
      PrinterNum: labelPrinter.printerNum,
      PrinterAccountNum: labelPrinter.printerAccountNum,
      ShipPackageNum: packageNum,
      ShipServiceNum: shipServiceNum,
      Height: packageHeight,
      Length: packageLength,
      Weight: packageWeight,
      Width: packageWidth,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      InsuredValue: insuredValue,
      BillToType: needBTP ? 2 : 0,
      BillThirdPartyAccount: BTPAccount,
      BillThirdPartyCountry: BTPCountry || null,
      BillThirdPartyPostalCode: BTPPostCode,
      CN22: CN22 ? 1 : 0,
    };
    //console.log('print label', pd, ' - ', shipPrinter);
    //return;

    if (printTime - lastPrintTime < PRINT_INTERVAL) {
      openAlertDialog({
        title: 'Error',
        content: 'Please keep the time interval between two prints not too short',
      });

      return;
    }

    setLockScreen(true);
    setLastPrintTime(printTime);

    const printMode = await getProfileSettingValue('PrintStationMode', DEFAULT_PRINT_MODE);

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printShipmentLabel(pd));
      dispatch(setPrintingHang(true));
    } else {
      try {
        const res = await postPrintShipmentLabel(pd);

        if (res) {
          console.log('p res', res);
          setTimeout(() => {
            tryWaitShipmentShipped(async () => {
              if (typeof props.onMarkShipped === 'function') {
                await props.onMarkShipped();

                if (shipmentNeedPrintPackingSlip) {
                  await printShipmentPackingSlip();
                }
              }
            });
          }, 0);
        }
      } finally {
        setLockScreen(false);
      }
    }

    if (!printstationIsOk) {
      // modify here with alert
      //openAlertDialog({ title: 'Error', content: 'Please make sure Print Station works well' });
      /*setLockScreen(true);

      try {
        const res = await postPrintShipmentLabel(pd);

        //setLockScreen(false);
        //message.info('Try to print separately');

        if (res) {
          console.log('p res', res);
          setTimeout(() => {
            tryWaitShipmentShipped(async () => {
              if (typeof props.onMarkShipped === 'function') {
                await props.onMarkShipped();
              }
            });
          }, 0);
        }
      } finally {
        setLockScreen(false);
      }*/
    }
  };

  /**
   * The handler for using scale.
   */
  const handleUseScale = () => {
    if (lastScale && lastScale.scaleNum > 0) {
      dispatch(setScale({ ...lastScale }));
      setUseScale(true);
    } else {
      setDeviceSettingVisible(true);
    }
  };

  const onBTPAccountChange = (evt: any) => {
    const up: StringKAnyVPair = {};

    setBTPAccount(evt.target.value);
    up.billThirdPartyAccount = evt.target.value;
    setBTPAccountError(false);
    setBTPAccountErrorMessage('');
    trySyncShipmentProps(up);
  };

  const onBTPCountryChange = (country: number) => {
    setBTPCountry(country);
  };

  const onBTPOptionSelected = (evt: any) => {
    setNeedBTP(evt.target.checked);
  };

  const onBTPPostCodeChange = (evt: any) => {
    const up: StringKAnyVPair = {};

    setBTPPostCode(evt.target.value);
    up.billThirdPartyPostalCode = evt.target.value;
    setBTPPostCodeError(false);
    setBTPPostCodeErrorMessage('');
    trySyncShipmentProps(up);
  };

  const onCN22Change = (evt: any) => {
    const up: StringKAnyVPair = {};

    setCN22(evt.target.checked);
    up.cN22 = evt.target.checked ? 1 : 0;
    trySyncShipmentProps(up);
  };

  // eslint-disable-next-line
  const onCollapseShipOptions = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveShipOptionPanelKey(key);

      if (key.length > 0) {
        setTimeout(() => {
          optionPanel?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
      }
    }
  };

  /**
   * Callback for changing package height.
   */
  const onInputHeightChange = (value: number) => {
    const up: StringKAnyVPair = {};

    setPackageHeight(value);
    up.height = value;
    trySyncShipmentProps(up);
  };

  /**
   * Callback for changing package length.
   */
  const onInputLengthChange = (value: number) => {
    const up: StringKAnyVPair = {};

    setPackageLength(value);
    up.length = value;
    trySyncShipmentProps(up);
  };

  /**
   * Callback for changing package width.
   */
  const onInputWidthChange = (value: number) => {
    const up: StringKAnyVPair = {};

    setPackageWidth(value);
    up.width = value;
    trySyncShipmentProps(up);
  };

  /**
   * Callback for changing insured value.
   */
  const onInsuredValueChange = (value: number) => {
    setInsuredValue(value);

    if (!value) {
      setTimeout(() => setInsuredValue(undefined), 0);
    }
  };

  /**
   * Callback for clicking the print message bar.
   */
  const onPrintMessageBarClick = () => {
    if (bottomMessageTimer) {
      clearTimeout(bottomMessageTimer);
      bottomMessageTimer = null;
    }

    setPrintMessageBarStyle({ top: MESSAGE_BAR_POS_TOP1 });
  };

  const onSetShoppingAround = (res: StringKAnyVPair) => {
    const up: StringKAnyVPair = {};
    console.log('res', res);
    setServiceCode(res.shippingServiceCode);
    setShipAccountNum(res.shipAccountNum);
    up.shippingAccountNum = res.shipAccountNum;
    setServiceUpdateTag(uuid());
    setShipCarrier(res.shippingCarrier);
    setShipCarrierNum(res.shipCarrierNum);
    up.shipCarrierNum = res.shipCarrierNum;
    setPackageNum(res.shipPackageNum);
    up.shipPackageNum = res.shipPackageNum;

    if (res.height) setPackageHeight(res.height);
    if (res.height) up.height = res.height;
    if (res.length) setPackageLength(res.length);
    if (res.length) up.length = res.length;
    if (res.width) setPackageWidth(res.width);
    if (res.width) up.width = res.width;
    if (res.weight) setShipWeight(res.weight);
    if (res.weight) up.weight = res.weight;

    setShoppingAroundSizeLocked(res.height || res.length || res.width);
    trySyncShipmentProps(up);
  };

  /**
   * Callback for selecting a shipping account.
   */
  // eslint-disable-next-line
  const onShippingAccountChange = (account: StringKAnyVPair) => {
    setShipAccountNum(account.shipAccountNum);
    setShipCarrier(account.carrier);
  };

  const onShoppingAroundButtonClick = (evt: any) => {
    setShopAroundUpdateTag(uuid());
    setShoppingAroundDialogVisible(true);
    evt.stopPropagation();
    evt.preventDefault();
  };

  const onShoppingAroundButtonKeyDown = (evt: any) => {
    if (evt.keyCode === 13) {
      onShoppingAroundButtonClick(evt);
    } else {
      evt.stopPropagation();
      evt.preventDefault();
    }
  };

  /**
   * Callback for shipping confirmation selection change.
   */
  const onSignatureOptionChange = (value: any) => {
    //console.log('confirmation', value);
    if (typeof value === 'number') {
      setShipConfirmation(value);
    } else {
      setShipConfirmation(null);
    }
  };

  /**
   * Callback for selecting a shipping package.
   */
  const onShippingPackageChange = (pkg: StringKAnyVPair) => {
    if (pkg.shippingPackageNum > -1) {
      const up: StringKAnyVPair = {};
      let heightExist = false;
      let lengthExist = false;
      let widthExist = false;

      if (selectedShipment) {
        const { height, length, width } = selectedShipment;

        /*
        heightExist = typeof height === 'number' && height >= 0;
        lengthExist = typeof length === 'number' && length >= 0;
        widthExist = typeof width === 'number' && width >= 0;
        */
        heightExist = typeof height === 'number' && height > 0;
        lengthExist = typeof length === 'number' && length > 0;
        widthExist = typeof width === 'number' && width > 0;
      }

      setPackageName(pkg.name);
      setPackageNum(pkg.shippingPackageNum);
      //setPackageType(pkg.packageNum);
      //setPackageType(pkg.carrierPackage);
      setPackageType(pkg.code || '');
      up.packageName = pkg.name;
      up.shipPackageNum = pkg.shippingPackageNum;

      // set for non General Package
      if (pkg.shippingPackageNum !== 0) {
        let sizeExist = heightExist ||
          lengthExist ||
          widthExist ||
          (typeof pkg.height === 'number' && pkg.height > 0) ||
          (typeof pkg.length === 'number' && pkg.length > 0) ||
          (typeof pkg.width === 'number' && pkg.width > 0);
        //setCanNotChangeSize(heightExist || lengthExist || widthExist);
        //setCanNotChangeSize(sizeExist);
        setCanNotChangeSize(sizeExist || true);
      } else {
        setCanNotChangeSize(false);
      }

      if (shoppingAroundSizeLocked) {
        setShoppingAroundSizeLocked(false);
      } else {
        if (!heightExist) setPackageHeight(pkg.height || undefined);
        if (!heightExist) up.height = pkg.height || undefined;
        if (!lengthExist) setPackageLength(pkg.length || undefined);
        if (!lengthExist) up.length = pkg.length || undefined;
        if (!widthExist) setPackageWidth(pkg.width || undefined);
        if (!widthExist) up.width = pkg.width || undefined;
      }

      setPackageTypeError(false);
      setPackageTypeErrorMessage('');
      trySyncShipmentProps(up);
    }
  };

  /**
   * Callback from selecting a shipping service.
   */
  const onShippingServiceChange = (svr: StringKAnyVPair) => {
    if (typeof svr.shipAccountNum === 'undefined') {
      setPackageNum(-1);
      setPackageUpdateTag(uuid());
      // wether need to clear some status at here
      setTopMessage('');
      setShipCarrierNum(0);
      return;
    }

    const up: StringKAnyVPair = {};

    setShipAccountNum(svr.shipAccountNum);
    up.shippingAccountNum = svr.shipAccountNum;
    setShipCarrier(svr.carrierName);
    setShipCarrierNum(svr.carrierNum);
    up.shipCarrierNum = svr.carrierNum;
    //setShipService(svr.service);
    setShipService(svr.carrierServiceCode);
    up.shippingService = svr.carrierServiceName;
    setShipServiceNum(svr.carrierServiceNum);
    up.shipServiceNum = svr.carrierServiceNum;
    //setPackageNum(-1); // check carrier number to set package number
    setPackageServiceError(false);
    setPackageServiceErrorMessage('');
    onPrintMessageBarClick();

    if (svr.carrierServiceNum === SERVICE_FEDEX_SMARTPOST_NUM) {
      if (typeof shipWeight === 'number' && shipWeight < MIN_FEDEX_SMART_POST_WEIGHT) {
        setShipWeight(MIN_FEDEX_SMART_POST_WEIGHT);
        up.weight = MIN_FEDEX_SMART_POST_WEIGHT;
        setTopMessage('Weight was adjusted to Smart Post minimum 1lb');
      }
    } else {
      setTopMessage('');
    }

    up.billToType = svr.billToType;

    if (svr.billToType === 2) {
      setActiveShipOptionPanelKey(['1']);
      setNeedBTP(true);
      setBTPAccount(svr.billThirdPartyAccount || '');
      up.billThirdPartyAccount = svr.billThirdPartyAccount || '';
      setBTPPostCode(svr.billThirdPartyPostalCode || '');
      up.billThirdPartyPostalCode = svr.billThirdPartyPostalCode || '';
      setBTPCountry(svr.billThirdPartyCountryNum || undefined);
    } else {
      setActiveShipOptionPanelKey([]);
      setNeedBTP(false);
      setBTPAccount('');
      up.billThirdPartyAccount = '';
      setBTPPostCode('');
      up.billThirdPartyPostalCode = '';
      setBTPCountry(undefined);
    }

    trySyncShipmentProps(up);
  };

  /**
   * Callback for selectiing a warehouse.
   */
  // eslint-disable-next-line
  const onWarehouseSelectionChange = async (warehouseId: any) => {
    setShipFrom(warehouseId);
    setShipFromError(false);
    setShipFromErrorMessage('');
    localStorage.setItem(LS_DEFAULT_WAREHOUSE_KEY, warehouseId);
  };

  /**
   * Callback for weight changing.
   */
  const onWeightChange = (weight: number) => {
    const up: StringKAnyVPair = {};

    setPackageWeight(weight);
    up.weight = weight;
    trySyncShipmentProps(up);
  };

  const openAlertDialog = (config: StringKAnyVPair) => {
    setAlertDialogVisible(true);
    setAlertDialogContent(config.content || '');
    setAlertDialogOnClose(
      typeof config.onClose === 'function' ? config.onClose : undefined
    );
    setAlertDialogTitle(config.title || '');
  };

  const openMarkTrackDialog = () => {
    setConfirmShipVisible(false);
    setMarkTrackDialogVisible(true);
  };

  const openShipMultiPackages = () => {
    const { changeShipment, multiPkgShipments, openShipMultiPackages } = props;
    let dataIsOk = true;

    if (multiPkgShipments) {
      let errMsg = '';
      let smNum = 0;

      for (let i = 0; i < multiPkgShipments.length; i++) {
        const e = multiPkgShipments[i];

        if (!(e.weight > 0)) {
          dataIsOk = false;
          errMsg = 'Please fill in the weight of the package';
          smNum = e.shipmentNum;
          break;
        }
      }

      if (!dataIsOk) {
        if (typeof changeShipment === 'function') {
          changeShipment(smNum);
        }

        Modal.error({
          title: 'Please complete the shipment form',
          content: errMsg,
        });
      }
    }

    if (dataIsOk && typeof openShipMultiPackages === 'function') {
      Modal.confirm({
        title: 'Ship Multiple packages',
        content: 'Do you want to ship multiple packages?',
        onOk: () => {
          if (typeof openShipMultiPackages === 'function') {
            openShipMultiPackages();
          }
        },
      });
    }
  };

  const printMoreDropdownMenu = () => {
    const isSmp = props.multiPkgShipments && props.multiPkgShipments.length > 1;

    return (<Menu>
      <Menu.Item key="0" onClick={readyMarkAsShipped}>
        <LabelFormDropdownItem>Mark as Shipped</LabelFormDropdownItem>
      </Menu.Item>
      {shipMultiPkgEnabled && isSmp && (
        <Menu.Item key="1" onClick={openShipMultiPackages}>
          <LabelFormDropdownItem>Ship Multiple Packages</LabelFormDropdownItem>
        </Menu.Item>
      )}
    </Menu>);
  };

  const printShipmentPackingSlip = async () => {
    const { getPackingSlipType } = props;
    let printer = packingPrinter;
    const profile = userProfiles.profile;

    if (typeof getPackingSlipType === 'function') {
      setLockScreen(true);

      const typ = await getPackingSlipType();

      if (typ === 1) printer = packingPrinter2;

      setLockScreen(false);
    }

    if (printer.printerNum === undefined || printer.printerNum <= 0) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    const fulfillmentOrderNum = selectedShipment ? selectedShipment.fulfillmentOrderNum : 0;
    const printMode = await getProfileSettingValue('PrintStationMode', DEFAULT_PRINT_MODE);
    const slip = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: fulfillmentOrderNum,
      ShipmentNum: selectedShipmentNum,
      Time: nowTime(),
    };

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printPackingSlip(slip));
    } else {
      try {
        //const res = await postPrintShipmentPackingSlip(slip);
        await postPrintShipmentPackingSlip(slip);
      } catch (e) {
        //setLockScreen(false);
        openAlertDialog({
          title: 'Error',
          content: `Print packing slip error: ${e}`,
        });
      }
    }
  };

  const readyMarkAsShipped = () => {
    setConfirmShipVisible(true);
  };

  /**
   * Try to get shipping rate.
   */
  // eslint-disable-next-line
  const tryGetShipRate = () => {
    const values = [
      selectedShipmentNum,
      shipAccountNum,
      shipFrom,
      shipCarrier,
      shipService,
      packageType,
      packageLength,
      packageWidth,
      packageHeight,
      packageWeight,
    ];

    if (values.every((e) => e)) {
      const param = {
        ShipmentNum: selectedShipmentNum,
        ShippingAccountNum: shipAccountNum,
        //ShipFrom: shipFrom,
        ShipFrom: 10032,
        Carrier: shipCarrier,
        //Service: shipService,
        Service: 'FedExGround',
        //PackageType: packageType,
        PackageType: 'FedExYourPackaging',
        Length: packageLength,
        Width: packageWidth,
        Height: packageHeight,
        Weight: packageWeight,
      };

      if (!isEqualObject(param, lastRateParam)) {
        console.log('post', param);
        getShipRate(param);
      }
    }
  };

  const trySyncShipmentProps = (ps: StringKAnyVPair) => {
    if (typeof props.syncShipmentProps === 'function') {
      props.syncShipmentProps(selectedShipmentNum, ps);
    }
  };

  // eslint-disable-next-line
  const tryWaitShipmentShipped = (callback: Function) => {
    const MAX_COUNT = 5;
    const TIMEOUT = 3000;
    let count = 0;

    const getShipment = async () => {
      setLockScreen(true);

      try {
        const shipment = await fetchShipmentDetail(selectedShipmentNum);

        count = count + 1;

        if (shipment && typeof shipment === 'object') {
          //console.log('-->', count, shipment.shippingStatus);
          if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
            callback();
            setLockScreen(false);
          } else {
            if (count < MAX_COUNT) {
              setTimeout(getShipment, TIMEOUT);
            } else {
              setLockScreen(false);
            }
          }
        } else {
          setLockScreen(false);
        }
      } catch (e) {
        message.error(`Fetch shipment error: ${e}`);
        setLockScreen(false);
      }
    };

    getShipment();
  };

  const unlockNoResponseMask = () => {
    setLockScreen(false);
    dispatch(setPrintLabelResponse({}));
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (!inited) {
      //adjustLayout();
      const warehouseId = parseInt(
        localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
      );

      setShipFrom(warehouseId);
      setShipWeight(
        selectedShipment ? selectedShipment.weight || undefined : undefined
      );
      checkShipMultiPkgEnabled();
      document.addEventListener('keydown', handleKeydown, false);
      keydownHandler = handleKeydown;
      setInited(true);
    }

    setUseScale(!!(selectedScale && selectedScale.scaleNum > 0));
    //tryGetShipRate();

    if (currentPageWidth !== pageWidth) {
      adjustLayout();
      setCurrentPageWidth(pageWidth);
    }

    if (currentShipmentNum !== selectedShipmentNum) {
      setCurrentShipmentNum(selectedShipmentNum);
      clearFormValue();
      fillFormValueByShipment(selectedShipment || {});
      setInsuredValue(props.shipment ? props.shipment.insuredValue : undefined);
      setCN22(props.shipment ? props.shipment.cN22 : false);
    }

    if (printLabelResponse && selectedShipment) {
      const types = [PRINT_TYPE_LABEL];

      if (types.indexOf(printLabelResponse.type) > -1) {
        console.log('print label response', printLabelResponse);
        if (printLabelResponse.result === PRINT_MESSAGE_TYPE_ERROR) {
          openAlertDialog({
            title: 'Print Error',
            content: printLabelResponse.message,
            onClose: dispatch(setPrintingHang(false)),
          });
        }

        if (printLabelResponse.id === selectedShipment.shipmentNum) {
          setLockScreen(false);
          setTimeout(() => {
            dispatch(setPrintLabelResponse({}));
          }, 0);
        }
      }
    }

    if (printLabelResult.code) {
      //if (!isEqualObject(lastPrintLabelResult, printLabelResult)) {
      //setLastPrintLabelResult(printLabelResult);
      console.log('p res', printLabelResult);
      const { code, message, isSuccess } = printLabelResult;

      if (typeof code === 'number') {
        //let interval = 3000;

        dispatch(setPrintingHang(false));
        dispatch(setPrintLabelResult({}));

        if (isSuccess) {
          /*
          setPrintLabelMessage('Printed Label Successfully');

          if (typeof props.onPrintLabelSuccess === 'function') {
            props.onPrintLabelSuccess();
          }
          */
          setLockScreen(true);
        } else {
          setLockScreen(false);
          openAlertDialog({
            title: 'Print Error',
            content: message,
            //onClose: () => () => onPrintMessageBarClick(),
          });
        }
      }
    }

    return () => {
      dispatch(setPrintLabelResponse({}));
      setTimeout(() => {
        // eslint-disable-next-line
        if (!shell?.current) {
          if (keydownHandler) {
            document.removeEventListener('keydown', keydownHandler, false);
            keydownHandler = null;
          }
        }
      }, 0);
    };
  }, [
    currentShipmentNum,
    currentPageWidth,
    dispatch,
    handleKeydown,
    inited,
    pageWidth,
    printLabelResponse,
    printLabelResult,
    props,
    selectedScale,
    selectedShipment,
    selectedShipmentNum,
    shell,
    tryGetShipRate,
    useScale,
  ]);
  /* eslint-enable */

  return (
    <>
      <RightLabelFormContainer ref={shell}>
        <PrintLabelTitlebar align="middle" justify="space-between">
          <Label className="label-bold">{i18n.t('scanToShip.printLabel.title')}</Label>
          {/*<Checkbox
            checked={useScale}
            onChange={evt => setUseScale(evt.target.checked)}
          >
            Use Scale
          </Checkbox>*/}
          {useScale ? (
            selectedScaleIsOnline ? (
              ''
            ) : (
              <InfoButton onClick={handleNotUseScale}>
                {i18n.t('scanToShip.printLabel.notUseScale')}
              </InfoButton>
            )
          ) : selectedScaleIsOnline ? (
            <InfoButton onClick={handleUseScale}>{i18n.t('scanToShip.printLabel.useScale')}</InfoButton>
          ) : (
            ''
          )}
        </PrintLabelTitlebar>
        <PrintLabelFormContainer pageMode={pageShowMode}>
          {topMessage && <Alert type="warning" showIcon message={topMessage} />}
          <WeightBoard
            lbInputId={ScanToShip.weightLbInputId}
            onChange={onWeightChange}
            ozInputId={ScanToShip.weightOzInputId}
            shipmentNum={selectedShipmentNum}
            weight={shipWeight}
          />
          <Row justify="center">
            <Col lg={24} xxl={23}>
              {shipFrom <= 0 && (
                <Row>
                  <Col span={FORM_COL2_WIDTH} offset={FORM_COL1_WIDTH}>
                    <Button type="link">{i18n.t('scanToShip.printLabel.setWarehouse')}</Button>
                  </Col>
                </Row>
              )}
              {/*<FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Ship From</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <div style={{width: formField1Width}}>
                    <FormField
                      error={shipFromError}
                      errorMessage={shipFromErrorMessage}
                    >
                      <WarehouseSelectBox
                        onChange={onWarehouseSelectionChange}
                        value={shipFrom}
                      />
                    </FormField>
                  </div>
                </Col>
              </Row>*/}
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>{i18n.t('scanToShip.printLabel.label.service')}</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <FormField
                    error={packageServiceError}
                    errorMessage={packageServiceErrorMessage}
                  >
                    <ServiceSelector
                      channelAccountNum={
                        selectedShipment
                          ? selectedShipment.channelAccountNum
                          : 0
                      }
                      //disabled={selectedShipmentUseMappedServiceAndPackage}
                      onChange={onShippingServiceChange}
                      serviceCode={serviceCode}
                      shipAccountNum={shipAccountNum}
                      shipmentNum={selectedShipmentNum}
                      size={formFieldSize}
                      style={{ width: formField1Width }}
                      updateTag={serviceUpdateTag}
                      warehouseNum={shipFrom}
                      shipment={selectedShipment}
                    />
                  </FormField>
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle" justify="center">
                <Col span={FORM_COL1_WIDTH}>
                  <Row justify="end">
                    <FormRightAlign>
                      <FormLabel>{i18n.t('scanToShip.printLabel.label.package')}</FormLabel>
                    </FormRightAlign>
                  </Row>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <Row align="middle">
                    <FormField
                      error={packageTypeError}
                      errorMessage={packageTypeErrorMessage}
                    >
                      <PackageSelector
                        carrier={shipCarrier}
                        carrierNum={shipCarrierNum}
                        channelAccountNum={
                          selectedShipment
                            ? selectedShipment.channelAccountNum
                            : 0
                        }
                        //disabled={selectedShipmentUseMappedServiceAndPackage}
                        onChange={onShippingPackageChange}
                        //packageNum={selectedShipment ? selectedShipment.shipPackageNum || undefined : undefined}
                        packageNum={packageNum >= 0 ? packageNum : undefined}
                        shipmentNum={selectedShipmentNum}
                        size={formFieldSize}
                        style={{ width: formField1Width }}
                        updateTag={packageUpdateTag}
                        warehouseNum={shipFrom}
                      />
                    </FormField>
                  </Row>
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>{i18n.t('scanToShip.printLabel.label.size')}</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <SizeInputRow align="middle">
                    <Col style={{ width: '31%' }}>
                      <Space size={0}>
                        <FormField>
                          <InputNumber
                            disabled={canNotChangeSize}
                            id={ScanToShip.labelLengthInputId}
                            min={0}
                            onChange={onInputLengthChange}
                            style={{ width: '100%' }}
                            value={packageLength}
                          />
                        </FormField>
                        <UnitLabel>L</UnitLabel>
                      </Space>
                    </Col>
                    <Col style={{ width: '31%' }}>
                      <Space size={0}>
                        <FormField>
                          <InputNumber
                            disabled={canNotChangeSize}
                            id={ScanToShip.labelWidthInputId}
                            min={0}
                            onChange={onInputWidthChange}
                            style={{ width: '100%' }}
                            value={packageWidth}
                          />
                        </FormField>
                        <UnitLabel>W</UnitLabel>
                      </Space>
                    </Col>
                    <Col style={{ width: '38%' }}>
                      <Space size={0}>
                        <FormField>
                          <InputNumber
                            disabled={canNotChangeSize}
                            id={ScanToShip.labelHeightInputId}
                            min={0}
                            onChange={onInputHeightChange}
                            style={{ width: '100%' }}
                            value={packageHeight}
                          />
                        </FormField>
                        <UnitLabel>H(in)</UnitLabel>
                      </Space>
                    </Col>
                  </SizeInputRow>
                </Col>
              </Row>
              {/*<FormVSpacer />
              <Row>
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Requested</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <Label>
                    <span className="label-grey">Not Specified</span>
                  </Label>
                </Col>
              </Row>*/}
              {/*<FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Confirmation</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <Select style={{ width: formField1Width }}></Select>
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Insurance</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <Select style={{ width: formField1Width }}></Select>
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Rate</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <Label>12.51</Label>
                </Col>
              </Row>*/}
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>{i18n.t('scanToShip.printLabel.label.signature')}</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col className="confirmation-container" span={FORM_COL2_WIDTH}>
                  <SignatureSelector
                    carrierNum={shipCarrierNum}
                    onChange={onSignatureOptionChange}
                    shipmentNum={selectedShipmentNum}
                    signature={selectedShipmentSignature}
                    size={formFieldSize}
                    style={{ width: formField1Width }}
                  />
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>{i18n.t('scanToShip.printLabel.label.carrierDeclareValue')}</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col className="confirmation-container" span={FORM_COL2_WIDTH}>
                  <FormField>
                    <InputNumber
                      id={ScanToShip.labelCDVInputId}
                      onChange={onInsuredValueChange}
                      min={0}
                      style={{ width: formField1Width }}
                      value={insuredValue}
                    />
                  </FormField>
                </Col>
              </Row>
              {/*<Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Ship Date</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  Today
                </Col>
              </Row>
              <FormVSpacer />
              <Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Delivery Time</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  {deliveryTime}
                </Col>
              </Row>
              <FormVSpacer />*/}
            </Col>
          </Row>
          <FormVSpacer style={{ borderTop: '1px solid #D9D9D9' }} />
          <Collapse
            activeKey={activeShipOptionPanelKey}
            onChange={onCollapseShipOptions}
          >
            <PrintOptionPanel
              header={
                <div className="collapse-bar">
                  <Row align="middle" justify="space-between">
                    <Label>
                      <span className="label-bold">{i18n.t('scanToShip.printLabel.other')}</span>
                    </Label>
                    <Button
                      id={ScanToShip.labelShopAroundBtnId}
                      ghost
                      type="primary"
                      onClick={onShoppingAroundButtonClick}
                      onKeyDown={onShoppingAroundButtonKeyDown}
                    >
                      {i18n.t('scanToShip.printLabel.shopAround')}
                    </Button>
                  </Row>
                </div>
              }
              key="1"
            >
              <Row justify="center">
                <Col
                  ref={optionPanel}
                  lg={24}
                  xxl={23}
                  style={{ padding: '0 12px' }}
                >
                  {/*<Row align="middle">
                <Col span={FORM_COL1_WIDTH}>
                  <FormRightAlign>
                    <FormLabel>Shipping Account</FormLabel>
                  </FormRightAlign>
                </Col>
                <Col span={FORM_COL2_WIDTH}>
                  <ShippingAccountSelector
                    onChange={onShippingAccountChange}
                    size={formFieldSize}
                    style={{ width: formField1Width }}
                  />
                </Col>
              </Row>
              <FormVSpacer />*/}
                  <fieldset>
                    <legend>
                      <Checkbox
                        checked={needBTP}
                        onChange={onBTPOptionSelected}
                      >
                        {i18n.t('scanToShip.printLabel.3rdParty')}
                      </Checkbox>
                    </legend>
                    <Row align="middle">
                      <Col span={FORM_COL1_WIDTH}>
                        <FormRightAlign>
                          <FormLabel>{i18n.t('scanToShip.printLabel.label.accountNum')}</FormLabel>
                        </FormRightAlign>
                      </Col>
                      <Col span={FORM_COL2_WIDTH}>
                        <FormField
                          error={BTPAccountError}
                          errorMessage={BTPAccountErrorMessage}
                        >
                          <Input
                            disabled={!needBTP}
                            onChange={onBTPAccountChange}
                            size={formFieldSize}
                            style={{ width: formField1Width }}
                            value={BTPAccount}
                          />
                        </FormField>
                      </Col>
                    </Row>
                    <FormVSpacer />
                    <Row align="middle">
                      <Col span={FORM_COL1_WIDTH}>
                        <FormRightAlign>
                          <FormLabel>{i18n.t('scanToShip.printLabel.label.postalCode')}</FormLabel>
                        </FormRightAlign>
                      </Col>
                      <Col span={FORM_COL2_WIDTH}>
                        <FormField
                          error={BTPPostCodeError}
                          errorMessage={BTPPostCodeErrorMessage}
                        >
                          <Input
                            disabled={!needBTP}
                            onChange={onBTPPostCodeChange}
                            size={formFieldSize}
                            style={{ width: formField1Width }}
                            value={BTPPostCode}
                          />
                        </FormField>
                      </Col>
                    </Row>
                    <FormVSpacer />
                    <Row align="middle">
                      <Col span={FORM_COL1_WIDTH}>
                        <FormRightAlign>
                          <FormLabel>{i18n.t('scanToShip.printLabel.label.country')}</FormLabel>
                        </FormRightAlign>
                      </Col>
                      <Col span={FORM_COL2_WIDTH}>
                        <CountrySelector
                          disabled={!needBTP}
                          onChange={onBTPCountryChange}
                          size={formFieldSize}
                          width={formField1Width}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                  {/*<PrintOptionContainer>
                    <FormVSpacer />
                    <Label>
                      <span className="label-bold">Shipment Options</span>
                    </Label>
                    <FormVSpacer />
                    <Checkbox>This shipment requires Additional Handling</Checkbox>
                    <FormVSpacer />
                    <Checkbox>This shipment includes alcohol</Checkbox>
                    <FormVSpacer />
                    <Checkbox>This shipment requires a Freight Classification code</Checkbox>
                    <FormVSpacer />
                    <Checkbox>This shipment is a non-machinable</Checkbox>
                    <FormVSpacer />
                    <Label>
                      <span className="label-bold">Delivery Options</span>
                    </Label>
                    <FormVSpacer />
                    <Checkbox>Collect payment on delivery(C.O.D)</Checkbox>
                    <FormVSpacer />
                    <Checkbox>Saturday Delivery</Checkbox>
                    <FormVSpacer />
                    <Checkbox>Deliver may release package without signature</Checkbox>
                    <FormVSpacer />
                    <Label>
                      <span className="label-bold">Label Options</span>
                    </Label>
                    <FormVSpacer />
                    <Checkbox>Bill duties and taxes to payor of shipping charges</Checkbox>
                    <FormVSpacer />
                    <Checkbox>This shipment includes Dry Ice</Checkbox>
                    <FormVSpacer />
                    <Checkbox>Include a return label with the outgoing shipping label</Checkbox>
                    <FormVSpacer />
                    <Checkbox>Print postage on the shipping label for this shipment</Checkbox>
                    <FormVSpacer />
                    <Label>
                      <span className="label-bold">Marketplace Options</span>
                    </Label>
                    <FormVSpacer />
                    <Checkbox>Do not notifiy marketplace</Checkbox>
                  </PrintOptionContainer>*/}
                </Col>
              </Row>
              <FormVSpacer />
              <Row justify='center'>
                <Col
                  lg={24}
                  xxl={23}
                  style={{ padding: '0 12px' }}
                >
                  <Checkbox id="label_cn22" checked={CN22} onChange={onCN22Change}>Print CN22</Checkbox>
                </Col>
              </Row>
            </PrintOptionPanel>
          </Collapse>
        </PrintLabelFormContainer>
        <div className="verify-bottom-bar">
          <BottomButtonRow justify="center">
            <Col lg={2} xxl={2}></Col>
            <Col lg={16} xxl={12}>
              <InfoButton
                disabled={!shipmentPrintable}
                id={ScanToShip.labelPrintBtnId}
                onClick={handlePrintLabel}
                ref={printLabelButton}
                size="large"
                style={{ width: '100%' }}
                type="primary"
              >
                <ButtonIcon className="bi-printer" />
                {i18n.t('scanToShip.printLabel.printBtn')}
              </InfoButton>
            </Col>
            {shipmentShippedMarkable &&
              <Col className="dropdown-right-col" lg={6} xxl={10}>
                {/*<Button size="large" onClick={readyMarkAsShipped}>
                  <EllipsisOutlined />
                </Button>*/}
                <Dropdown overlay={printMoreDropdownMenu()} trigger={['click']}>
                  <Button size="large">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              </Col>}
            {/*<Dropdown overlay={rightDropdownMenu} trigger={['click']}>
                <Button size="large"><EllipsisOutlined /></Button>
            </Dropdown>*/}
          </BottomButtonRow>
          <div className="verify-msg-bar" style={printMessageBarStyle}>
            <Row>
              <Alert
                type={printLabelError ? 'error' : 'success'}
                message={
                  <>
                    <Row justify="space-between">
                      <Space>
                        {printLabelError ? (
                          <i className="bi-exclamation-lg error-icon" />
                        ) : (
                          <i className="bi-check-lg success-icon" />
                        )}
                        <Label>{printLabelMessage}</Label>
                      </Space>
                      <Space>
                        <span>{i18n.t('common.close')}</span>
                        <span className="close-icon">&times;</span>
                      </Space>
                    </Row>
                  </>
                }
                onClick={onPrintMessageBarClick}
              />
            </Row>
          </div>
        </div>
      </RightLabelFormContainer>
      <DeviceSettingDialog
        closable
        close={() => {
          setDeviceSettingVisible(false);
        }}
        visible={deviceSettingVisible}
      />
      {lockScreen && (
        <ScreenMask>
          <TimerLock>
            <Row align="middle" justify="center" style={{ height: '100%' }}>
              <MaskMessageBox>
                <Row className="message-box">
                  <span>{i18n.t('scanToShip.printLabel.noResponse')}</span>
                </Row>
                <Row className="button-box" justify="center">
                  <Button
                    onClick={unlockNoResponseMask}
                    ref={unlockButton}
                    size="large"
                    type="primary"
                  >
                    {i18n.t('common.continue')} ({isMacos() ? 'esc' : 'Esc'})
                  </Button>
                </Row>
              </MaskMessageBox>
            </Row>
          </TimerLock>
        </ScreenMask>
      )}
      <AlertDialog
        close={() => setAlertDialogVisible(false)}
        content={alertDialogContent}
        onClose={alertDialogOnClose}
        title={alertDialogTitle}
        visible={alertDialogVisible}
      />
      <ShoppingAroundDialog
        close={() => setShoppingAroundDialogVisible(false)}
        onSetShoppingAround={onSetShoppingAround}
        packageHeight={packageHeight}
        packageLength={packageLength}
        packageWidth={packageWidth}
        shipWeight={packageWeight}
        shipment={selectedShipment || {}}
        updateTag={shopAroundUpdateTag}
        visible={shoppingAroundDialogVisible}
        warehouseNum={shipFrom}
      />
      <Modal
        centered
        closable={false}
        footer={null}
        title=""
        visible={confirmShipVisible}
        width={600}
      >
        <Row justify="space-between">
          <Button onClick={() => setConfirmShipVisible(false)} size="large">
            {i18n.t('common.close')}
          </Button>
          <Space>
            <Button
              onClick={openMarkTrackDialog}
              size="large"
            >
              Mark shipped with tracking
            </Button>
            <Button onClick={confirmMarkAsShipped} size="large" type="primary">
              {i18n.t('scanToShip.printLabel.markAsShipped')}
            </Button>
          </Space>
        </Row>
      </Modal>
      {markTrackDialogVisible && (
        <MarkShippedDialog
          onHide={() => setMarkTrackDialogVisible(false)}
          onRefresh={() => {if (props.onMarkShipped) props.onMarkShipped()}}
          shipmentInfo={props.shipment}
          visible
        />
      )}
    </>
  );
};

export default PrintLabel;
