import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, FormLabel, message } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import { transferReceive } from 'services/inventory';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  lines: any[];
  transferNum: number;
  warehouse: WarehouseRow;
  onRefresh: () => void;
  dateString: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, lines, onRefresh, dateString, transferNum, warehouse } =
    props;
  const [loading, setLoding] = useState(false);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleReceive = useCallback(async () => {
    setLoding(true);
    const params = lines.map((item) => {
      return {
        // FulfillmentPoLineNum: item.poLineNum,
        FulfillmentTransferLineNum: item.fulfillmentTransferLineNum,
        // FulfillmentProductNum: item.productNum,
        ReceiveQty: item.receivingQty,
        LocationNum: item.receivingLocationNum,
        Note: item.notes,
        // LocationCode: item.receivingLocationCode,
        // WarehouseCode: warehouse.code,
        // PoPrice: item.price || 0,
        // ReceiptDate: moment(dateString),
      };
    });
    try {
      const res = await transferReceive(
        warehouse.warehouseId,
        transferNum,
        params
      );
      setLoding(false);
      if (res) {
        message.success({ content: 'receive successful' });
        onRefresh();
        onHide();
      } else {
        message.error({ content: 'receive failed' });
      }
    } catch (err: any) {
      message.error(err.response.data.error);
      setLoding(false);
    }
  }, [onHide, transferNum, lines, onRefresh, warehouse]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receivingQty',
      header: 'Receiving Qty',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'receivingLocationCode',
      header: 'Location',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'notes',
      header: 'Note',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  return (
    <Modal
      title="Receive"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id={`${loading ? 'Receiving' : 'Receive'}_btn`}
              type="primary"
              loading={loading}
              onClick={() => handleReceive()}
              icon={<CheckOutlined />}
            >
              {loading ? 'Receiving' : 'Receive'}
            </Button>
            <Button id="hideDialog_btn" onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Space>
        <FormLabel>Receipt Date</FormLabel>
        {moment(dateString).format('MM/DD/YYYY')}
      </Space>
      <DataGridPlus
        columns={columns}
        dataSource={lines}
        idProperty="poLineNum"
        {...({} as any)}
      />
    </Modal>
  );
};
