import React, { useCallback, useRef, useState } from 'react';
import { Modal, InputNumber, Typography } from 'antd';
import { message } from 'components/common';
import {
  SmartButton,
  SmartRow,
  SmartScanner,
  SmartFormLabel,
} from '../../SmartComponent';
import { moveProductLocationToLocation } from 'services/inventory';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type OrderFinishDialogProps = {
  warehouseId: string;
  warehouseNum: number;
  sLocation: WarehouseLocationRow;
  tLocation: WarehouseLocationRow;
  product: any;
  onSubmit: (qty: number) => void;
  onCloseModal: () => void;
};

export default function OrderFinishDialog(
  props: OrderFinishDialogProps
): JSX.Element {
  const { sLocation, tLocation, product, warehouseId, warehouseNum, onSubmit, onCloseModal } =
    props;
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const qty = useRef<number>(1);
  const [str, setStr] = useState<string>('');
  /**
   * handle move event
   */
  const moveProduct = useCallback(async () => {
    try {
      setConfirmLoading(true);
      const res = await moveProductLocationToLocation({
        fromWarehouse: warehouseId,
        fromLocation: sLocation?.locationNum,
        fulfillmentProductNum: product.fulfillmentProductNum,
        quantity: qty.current,
        toWarehouse: warehouseNum,
        toLocation: tLocation?.locationNum,
      });
      setConfirmLoading(false);
      if (res) {
        message.success({ content: 'Moved successfully' });
        onSubmit(qty.current);
      }
    } catch (error) {
      setConfirmLoading(false);
    }
  }, [sLocation, tLocation, product, warehouseId, qty, onSubmit, warehouseNum]);
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        {/* <ButtonIcon className="bi-emoji-smile" /> */}
        <div style={{ marginLeft: 5 }}>{'Move Item'}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        <SmartFormLabel>
          {t("smart.movemerge.from")}
        </SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {sLocation?.locationCode}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>
          {t("smart.movemerge.to")}
        </SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {tLocation?.locationCode}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>
          {t("common.sku")}
        </SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {product?.sku}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>
          {t("common.upc")}
        </SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {product?.upc}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>
          {t("common.title")}
        </SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {product?.productTitle}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>
          {t("common.qty")}
        </SmartFormLabel>
        <InputNumber
          autoFocus
          defaultValue={1}
          style={{ marginLeft: 4 }}
          onChange={(value) => (qty.current = Number(value))}
        />
      </SmartRow>

      <SmartRow>
        <Text type="secondary">
          {t("smart.movemerge.scanDestinationLocationConfirm")}
        </Text>
      </SmartRow>
      <SmartRow style={{ marginTop: 8 }}>
        <SmartScanner
          id="reScanLocationInput"
          isAutoFocus={1}
          onTextChange={(value) => setStr(value)}
          onChangeValue={(value) => {
            if (value) {
              if (value === tLocation?.locationCode) {
                moveProduct();
              } else {
                message.warning(t("smart.movemerge.scanDestinationLocationError") || '')
              }
            }
          }}
        />
      </SmartRow>

      {!showButton && <SmartRow style={{ marginTop: 8 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => setShowButton(true)}
        >
          ...
        </SmartButton>
      </SmartRow>}

      {showButton && <><SmartRow style={{ marginTop: 8}} justify="center">
        <SmartButton
          style={{ width: 200,  marginBottom: 8 }}
          disabled={str !== tLocation?.locationCode}
          loading={confirmLoading}
          onClick={() => moveProduct()}
        >
          {t("common.confirm")}
        </SmartButton>
      </SmartRow>
      <SmartRow justify="center">
        <SmartButton
          style={{ width: 200 }}
          type='default'
          onClick={() => onCloseModal()}
        >
          {t("smart.common.goBack")}
        </SmartButton>
      </SmartRow>
      </>
      }
    </Modal>
  );
}
