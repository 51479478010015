import { Select, SelectProps } from 'antd';
import React from 'react';

interface SimpleSelectProps extends SelectProps {
  id?: string;
  data: Array<any>;
  width?: number;
  placeholder?: string;
  style?: React.CSSProperties;
  valueField?: string;
  labelField?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
};
/**
 * Return simpleSelect components
 * @param {SimpleSelectProps} props
 * @returns {JSX.Element}
 */
export default function SimpleSelect(props: SimpleSelectProps): JSX.Element {
  const {
    data,
    valueField = 'id',
    labelField = 'label',
    width = '100%',
    style,
    placeholder,
    value,
    onChange,
  } = props;
  const extendProps: StringKAnyVPair = value ? { value } : { placeholder };
  return (
    <Select
      {...props}
      style={{ width, ...style }}
      allowClear
      /* eslint-disable react/jsx-props-no-spreading */
      {...extendProps}
      onChange={onChange}
    >
      {data.map((option) => {
        return (
          <Select.Option
            key={option[valueField]}
            value={`${option[valueField]}`}
          >
            {option[labelField]}
          </Select.Option>
        );
      })}
    </Select>
  );
}
SimpleSelect.defaultProps = {
  width: undefined,
  placeholder: undefined,
  style: undefined,
  valueField: undefined,
  labelField: undefined,
  value: undefined,
  onChange: undefined,
};
