import React, { useState } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space } from 'antd';
import { CloseOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { editTransactionReason } from 'services/transactionReason';
import { FormLabel } from 'components/common';
import { message as showMessage } from 'components/common';
import { leaveEditForm } from 'utils';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  reason: TransactionReasonRow | undefined;
  types: TransactionTypeRow[];
}
export default function EditReasonDialog(props: CreateBrandProps) {
  const { visible, onHide, onRefresh, reason, types } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!reason) return;
    setLoading(true);
    try {
      const res = await editTransactionReason(reason.transactionReasonId, {
        InvTransactionReasonType: reason.type,
        InvTransactionReasonName: params.Name,
        RemoveFromHolds: reason.removeFromHolds,
      });
      if (res) {
        showMessage.success({
          content: 'Saved Successfully！',
        });
        form.resetFields();
        onRefresh();
        onHide();
      } else {
        showMessage.error({ content: 'Save failed' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = await leaveEditForm({ Name: reason?.name }, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, reason, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Edit Transation Reason"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              loading={loading}
              icon={<SaveOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {reason && (
          <Form {...layout} form={form} style={{ marginTop: 20 }}>
            <Form.Item
              name="Rtype"
              label={<FormLabel>Transaction Type</FormLabel>}
            >
              {types.filter((item) => item.id === reason.type)[0].name}
            </Form.Item>
            <Form.Item
              name="Name"
              initialValue={reason?.name}
              label={<FormLabel>Name</FormLabel>}
              rules={[
                { required: true, message: 'Please Input Transaction Reason' },
              ]}
            >
              <Input placeholder="Input Transaction Reason" />
            </Form.Item>

            {/* {reason?.type === 7 && (
              <Form.Item
                name="RemoveFromHolds"
                initialValue={reason?.removeFromHolds}
                valuePropName="checked"
                label={<FormLabel>Checkout from holds</FormLabel>}
              >
                <Checkbox />
              </Form.Item>
            )} */}
          </Form>
        )}
      </Spin>
    </Modal>
  );
}
