import React from 'react';
import { DataGridPlus, ColumnDataType } from 'components/common';

type SaleGridProps = {
  data: Array<SaleRow>;
  style?: React.CSSProperties;
};
/**
 * Returns SaleGrid Component
 * @param {SaleGridProps} props
 * @returns  {JSX.Element}
 */
export default function SaleGrid(props: SaleGridProps): JSX.Element {
  const { data, style } = props;
  const columns = [
    {
      header: 'Order ID',
      name: 'channelOrderId',
      userSelect: true,
      defaultFlex: 1,
      minWidth: 200,
    },
    {
      header: 'Channel Account Name',
      name: 'channelName',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Order Date',
      name: 'originalOrderDate',
      userSelect: true,
      dataType: ColumnDataType.DATE,
      defaultFlex: 1,
    },
  ];
  return (
    <DataGridPlus
      style={style}
      idProperty="fulfillmentOrderNum"
      columns={columns}
      dataSource={data}
      {...({} as any)}
    />
  );
}
