import React, { useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Modal, Row, Space } from 'antd';
import { PlusOutlined, CloudUploadOutlined } from '@ant-design/icons';
//import {BoolCell} from 'components/common';
import {
  FormVSpacer,
  SectionWrapper,
} from 'components/common/styles/CustomPropsComponents';
import { REGULAR_SECTION_VERTICAL_SPACING } from 'constants/config';

// import WarehouseCell from './WarehouseCell';
import { RootState } from 'reducers';
import {
  // createWarehouse,
  // editWarehouseAddress,
  editWarehouseLocation,
} from 'actions/adminActions';
import { warehouseList as dispatchWarehouseList } from 'actions/adminActions';
//import { holdCurrentPage } from 'actions/regularActions';
import {
  CopySpan,
  DataGridPlus,
  HeaderTitle,
  ImportDialog,
  TablesFilter,
  message,
} from 'components/common';
import WarehouseActionCell from './WarehouseActionCell';
import { fetchWarehouseList, deleteWarehouseEntity } from 'services/warehouse';
import WarehouseAddressDialog from './EditAddressDialog/WarehouseAddressDialog';
// import CreateWarehouseDialog from './CreateWarehouseDialog';
import CreateDialog from './CreateDiallog';
// import EditAddressDialog from './EditAddressDialog/';
import EditLocationDialog from './EditLocationDialog';
import EditWarehouseDialog from './EditWarehouseDialog';
import ThirdPartySettingDialog from './ThirdPartySettingDialog';
import { sortByNumber } from 'utils';

type ColumnRenderType = {
  data: WarehouseRow;
};

const WarehouseList = () => {
  const dispatch: Dispatch<any> = useDispatch();
  /**
   * This state is only used for warehouse creation dialog submit data to the backend.
   * When it is true, the dialog will submit data.
   */
  const [loading, setLoading] = React.useState(true);
  const [warehouseAddressDialogVisible, setWarehouseAddressDialogVisible] =
    React.useState(false);
  const [thirdPartySettingDialogVisible, setThirdPartySettingDialogVisible] =
    React.useState(false);
  const [editWarehouseVisible, setEditWarehouseVisible] = React.useState(false);
  const [createWarehouseVisible, setCreateWarehouseVisible] =
    React.useState(false);
  const [currentWarehouse, setCurrentWarehouse] =
    React.useState<WarehouseRow>();
  // const [currentWarehouseAddress, setCurrentWarehouseAddress] =
  //   React.useState<WarehouseAddressRow>();
  const [warehouseList, setWarehouseList] = React.useState<WarehouseRow[]>([]);
  // const createWarehouseEntity = React.useCallback(
  //   () => dispatch(createWarehouse(true)),
  //   [dispatch]
  // );
  const editingWarehouse: WarehouseRow | null = useSelector(
    (state: RootState) => state.admin.editingWarehouse,
    shallowEqual
  ) as any;
  // const isCreatingWarehouse: boolean = useSelector(
  //   (state: RootState) => state.admin.isCreatingWarehouse
  // );
  // const isDetailWarehouseAddress: boolean = useSelector(
  //   (state: RootState) => state.admin.isDetailWarehouseAddress
  // );
  const isEditingWarehouseLocation: boolean = useSelector(
    (state: RootState) => state.admin.isEditingWarehouseLocation,
    shallowEqual
  );
  // const onCreateWarehouse = (row: WarehouseRow) => {
  //   setWarehouseList([row, ...warehouseList]);
  //   //update warehouse list
  //   dispatch(dispatchWarehouseList([row, ...warehouseList]));
  // };
  // const closeCreateWarehouseDialog = React.useCallback(
  //   () => dispatch(createWarehouse(false)),
  //   [dispatch]
  // );
  // const closeEditAddressDialog = React.useCallback(
  //   () => dispatch(editWarehouseAddress(false, null)),
  //   [dispatch]
  // );
  const closeEditLocationDialog = React.useCallback(
    () => dispatch(editWarehouseLocation(false, null)),
    [dispatch]
  );
  const [refresh, setRefresh] = React.useState(true);
  const [importDialogVisible, setImportDialogVisible] = React.useState(false);
  const [clickIndex, setClickIndex] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  /**
   * Delete warehouse according to its id.
   */
  const deleteWarehouse = async (warehouse: WarehouseRow) => {
    try {
      const res = await deleteWarehouseEntity(warehouse.warehouseId);
      if (res) {
        message.success(`Delete warehouse '${warehouse.name}' successfully!`);
        setRefresh(true);
      } else {
        message.error(`Delete warehouse '${warehouse.name}' failded.`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowEditWarehouseDialog = React.useCallback(
    (warehouse: WarehouseRow) => {
      setEditWarehouseVisible(true);
      setCurrentWarehouse(warehouse);
    },
    []
  );
  const handleShowWarehouseAddressDialog = React.useCallback(
    (warehouse: WarehouseRow) => {
      setWarehouseAddressDialogVisible(true);
      setCurrentWarehouse(warehouse);
    },
    []
  );
  const handleShowThirdPartySettingDialog = React.useCallback(
    (warehouse: WarehouseRow) => {
      setThirdPartySettingDialogVisible(true);
      setCurrentWarehouse(warehouse);
    },
    []
  );
  const handleHideWarehouseAddressDialog = React.useCallback(
    (forceRefresh: boolean, warehouse: WarehouseRow) => {
      setWarehouseAddressDialogVisible(false);
      if (forceRefresh) {
        setRefresh(true);
      }
    },
    []
  );

  const columns = [
    {
      name: 'code',
      header: 'Code',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // headerAlign: 'center',
    },
    {
      name: 'name',
      header: 'Name',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // headerAlign: 'center',
    },
    {
      name: 'warehouseType',
      header: 'Warehouse Type',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // headerAlign: 'center',
      renderEnum: {
        1: 'Normal',
        2: 'Return-Resalable',
        3: 'Return-Damage',
        4: 'Return-Inspection',
        5: 'Third Party',
        6: 'Receiving',
        7: 'Damage'
      },
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <CopySpan
            inGrid
            value={
              data.warehouseType
                ? {
                  1: 'Normal',
                  2: 'Return-Resalable',
                  3: 'Return-Damage',
                  4: 'Return-Inspection',
                  5: 'Third Party',
                  6: 'Receiving',
                  7: 'Damage'
                }[data.warehouseType] || ''
                : ''
            }
          />
        );
      },
    },
    {
      name: 'correspondingWarehouseNum',
      header: 'Corresponding Warehouse',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // headerAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        if (
          !data.correspondingWarehouseNum &&
          data.correspondingWarehouseNum !== 0
        )
          return '';
        const temp = warehouseList.filter(
          (item) => item.id === data.correspondingWarehouseNum
        );
        return <CopySpan inGrid value={temp.length > 0 ? temp[0].code : ''} />;
      },
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      minWidth: 100,
      maxWidth: 100,
      sortable: false,
      // textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <WarehouseActionCell
            onEditWarehouseDialogShow={handleShowEditWarehouseDialog}
            warehouse={data}
            onWarehouseAddressDialogShow={handleShowWarehouseAddressDialog}
            onThirdPartySettingDialogShow={handleShowThirdPartySettingDialog}
            deleteFn={() => {
              Modal.confirm({
                title: 'Are you sure to delete this warehouse?',
                className: 'del-modal-dialog-context',
                content: `warehouseCode: ${data.code}`,
                okText: 'Yes',
                okType: 'default',
                okButtonProps: { className: 'hover-danger-button' },
                cancelText: 'No',
                autoFocusButton: 'cancel',
                onOk: () => {
                  deleteWarehouse(data);
                },
              });
            }}
          />
        );
      },
    },
    // {
    //   name: 'totalQuantity',
    //   header: 'Total Quantity',
    //   textAlign: 'center',
    //   defaultFlex: 1,
    //   type: 'number',
    //   userSelect: false,
    // },
  ];

  React.useEffect(() => {
    if (refresh) {
      setRefresh(false);
      const fetch = async () => {
        try {
          const list = await fetchWarehouseList();
          setLoading(false);
          if (list && Array.isArray(list)) {
            const warehouses = list.map((e) => {
              return {
                id: e.warehouseNum,
                code: e.warehouseCode,
                name: e.warehouseName,
                isExternal: e.external,
                ...e,
              };
            });
            setWarehouseList(warehouses);
            //update warehouse list
            dispatch(dispatchWarehouseList(warehouses));
            //dispatch(holdCurrentPage(true));
          }
        } catch (e) {
          console.log('err', e);
        }
      };
      fetch();
    }
  }, [dispatch, refresh]);

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Warehouse List']} />
      <SectionWrapper>
        <Row justify="space-between">
          <TablesFilter
            dataSource={warehouseList.sort((a: any, b: any) =>
              sortByNumber(a, b, 'warehouseType')
            )}
            columns={columns}
            setFilteredData={setFilteredData}
          />
          <Space>
            <Button type="default" onClick={() => setImportDialogVisible(true)}>
              <CloudUploadOutlined />
              Import Location
            </Button>
            <Button
              type="primary"
              onClick={() => setCreateWarehouseVisible(true)}
            >
              <PlusOutlined />
              Create New Warehouse
            </Button>
          </Space>
        </Row>
      </SectionWrapper>
      <FormVSpacer height={REGULAR_SECTION_VERTICAL_SPACING} />
      <SectionWrapper>
        {filteredData.length > 0 && (
          <DataGridPlus
            style={{ minHeight: 500 }}
            autoWith={false}
            columns={columns}
            name="warehouseList"
            // columnUserSelect={true}
            dataSource={filteredData}
            loading={loading}
            idProperty="id"
            pagination="local"
            // showColumnMenuTool={false}
            {...({} as any)}
          />
        )}
      </SectionWrapper>
      {/* {isCreatingWarehouse ? (
        <Modal
          title="Create New Warehouse"
          centered
          visible={isCreatingWarehouse}
          width={600}
          destroyOnClose={true}
          onCancel={closeCreateWarehouseDialog}
          onOk={() => {
            setIsSubmittingWarehouseCreation(true);
            setTimeout(() => {
              // modify the state to be false in another point of execution
              // so the creation dialog can repeat execution
              setIsSubmittingWarehouseCreation(false);
            }, 0);
          }}
          okText="Create New Warehouse"
        >
          <CreateWarehouseDialog
            triggerCreation={isSubmittingWarehouseCreation}
            onCreateWarehouse={onCreateWarehouse}
            warehouseList={warehouseList}
          />
        </Modal>
      ) : (
        ''
      )} */}
      {/* {isDetailWarehouseAddress && editingWarehouse ? (
        <Modal
          title="Warehouse Address"
          centered
          visible={isDetailWarehouseAddress}
          width={800}
          footer={null}
          destroyOnClose={true}
          onCancel={closeEditAddressDialog}
        >
          <EditAddressDialog warehouse={editingWarehouse} />
        </Modal>
      ) : (
        ''
      )} */}
      {isEditingWarehouseLocation && editingWarehouse ? (
        <Modal
          title={`Edit location for '${editingWarehouse.code}' (${editingWarehouse.name}) Warehouse`}
          centered
          destroyOnClose={true}
          footer={null}
          maskClosable={false}
          onCancel={closeEditLocationDialog}
          visible={isEditingWarehouseLocation}
          width={1050}
        >
          <Row justify='end'>
            <Space>
              <Button
                icon={<PlusOutlined />}
                style={{ marginBottom: 12 }}
                type="primary"
                block
                onClick={() => setClickIndex((prev) => prev + 1)}
              >
                Add Location
              </Button>
            </Space>
          </Row>
          <EditLocationDialog
            clickAddLocation={clickIndex}
            warehouseNum={editingWarehouse.id}
            warehouseId={editingWarehouse.warehouseId}
          />
        </Modal>
      ) : (
        ''
      )}
      {warehouseAddressDialogVisible && currentWarehouse && (
        <WarehouseAddressDialog
          warehouse={currentWarehouse}
          onHide={handleHideWarehouseAddressDialog}
        />
      )}
      {editWarehouseVisible && currentWarehouse && (
        <EditWarehouseDialog
          visible={true}
          warehouse={currentWarehouse}
          onHide={() => setEditWarehouseVisible(false)}
          onRefresh={() => setRefresh(true)}
          warehouseList={warehouseList}
        />
      )}
      {createWarehouseVisible && (
        <CreateDialog
          visible
          onHide={() => {
            setCreateWarehouseVisible(false);
          }}
          onRefresh={() => {
            setRefresh(true);
          }}
          warehouseList={warehouseList}
        />
      )}
      {importDialogVisible && (
        <ImportDialog
          onRefesh={() => { }}
          onHide={() => setImportDialogVisible(false)}
          operation={{ key: 2101, value: 'Location', name: 'Location' }}
          showModal
        />
      )}
      {thirdPartySettingDialogVisible && currentWarehouse && (
        <ThirdPartySettingDialog
          onClose={() => setThirdPartySettingDialogVisible(false)}
          warehouseInfo={currentWarehouse}
          visible={true}
        />
      )}
    </>
  );
};

export default WarehouseList;
