import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { printCartonLabel, printPackingSlip } from 'actions/printActions';
import {
  setPrintCartonResult,
  setPrintLabelResponse,
  setPrintSlipResult,
} from 'actions/salesAction';
import {
  FormField,
  LoadingIcon,
  Loading,
  ScreenMask,
  message,
} from 'components/common';
import { DangerButton, Label } from 'components/common/styles';
import { FormVSpacer } from 'components/common/styles/CustomPropsComponents';
import {
  DEFAULT_PRINT_PACKING_SLIP_CONFIRM,
  LOADING_ICON_SIZE1,
  PRINT_MESSAGE_ACTION_TIMEOUT,
  PRINT_MESSAGE_TIMEOUT,
  PRINT_PACKING_SLIP_NEED_CONFIRMATION,
  PRINT_TYPE_PACK_SLIP,
} from 'constants/config';
import { RootState } from 'reducers';
import { fetchShipmentInsertitems } from 'services/shipment';
// import { fetchProfileSettings } from 'services/user';
import { nowTime, playBell } from 'utils';
import { userProfiles } from 'utils/auth';
import { ShipmentUnit, isSkipPrintPackSlip } from '.';
import ShipmentAttachedItems from './ShipmentAttachedItems';
import { ShipmentConfirmDialogBody, ShipmentConfirmDialogFooter } from './styles';

type Props = {
  changeOrder?: Function;
  changeShipment?: Function;
  close: Function;
  closable?: boolean;
  isTracking?: boolean;
  onPrintError?: Function;
  packingSlipType?: number;
  shipment: ShipmentUnit;
  shipmentList: ShipmentUnit[];
  visible: boolean;
};

// let profileSettings: StringKAnyVPair[] = [];

const ShipmentConfirmDialog = (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { useRef, useState } = React;
  const { shipment } = props;
  const [cartonLabelPrinted, setCartonLabelPrinted] = useState(false);
  const [cartonPrintError, setCartonPrintError] = useState(false);
  const [cartonPrintErrorMsg, setCartonPrintErrorMsg] = useState('');
  const [cartonPrinted, setCartonPrinted] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [insertedItems, setInsertedItems] = useState<StringKAnyVPair[]>([]);
  // const [isFetchProfileSetting, setIsFetchProfileSetting] = useState(false);
  const [isLoadingPicture, setIsLoadingPicture] = useState(false);
  const [isTracking, setIsTracking] = useState(props.isTracking || false);
  const [lockScreen, setLockScreen] = useState(false);
  const [mannualTrackEnabled, setMannualTrackEnabled] = useState(false);
  const [noSlip, setNoSlip] = useState(true);
  const [orderError, setOrderError] = useState(false);
  const [orderErrorMsg, setOrderErrorMsg] = useState('');
  const [packingSlipPrintDirectly, setPackingSlipPrintDirectly] = useState(false);
  const [packingSlipPrinted, setPackingSlipPrinted] = useState(false);
  const [printPackingSlipConfirmation, setPrintPackingSlipConfirmation] = useState<boolean>();
  const [showOrderScanner, setShowOrderScanner] = useState(false);
  const [showShipmentSelector, setShowShipmentSelector] = useState(false);
  const [slipPrintError, setSlipPrintError] = useState(false);
  const [slipPrintErrorMsg, setSlipPrintErrorMsg] = useState('');
  const [slipPrintTimeout, setSlipPrintTimeout] = useState<any>(null);
  const [slipPrinted, setSlipPrinted] = useState(false);
  const [step2, setStep2] = useState(false);
  const [trackError, setTrackError] = useState(false);
  const [trackErrorMsg, setTrackErrorMsg] = useState('');
  const [isTrackNumberOk, setIsTrackNumberOk] = useState(false);
  const orderInputRef = useRef<any>(null);
  const trackingRef = useRef<any>(null);
  const shipmentList = React.useMemo(() => {
    return props.shipmentList.filter(e => e.shipmentNum !== shipment.shipmentNum);
  }, [props.shipmentList, shipment]);

  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );

  const cartonPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinterCoton,
    shallowEqual
  );

  const packingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );

  const packingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );

  const printCartonResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printCartonResult,
    shallowEqual
  );

  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );

  const printSlipResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printSlipResult,
    shallowEqual
  );

  const clearState = () => {
    clearTimeout(slipPrintTimeout);
    setSlipPrintError(false);
    setSlipPrintErrorMsg('');
    setSlipPrintTimeout(null);
  };

  const getPrintPackingSlipConfirmValue = () => {
    let ret = DEFAULT_PRINT_PACKING_SLIP_CONFIRM; 
    if ( userProfileSettings ) {
      const ps = userProfileSettings.filter(e => e.settingCode === 'PrintPackingSlipAfterConfirm');

      if (ps && Array.isArray(ps) && ps[0]) {
        ret = typeof ps[0].settingValue === 'undefined' ? DEFAULT_PRINT_PACKING_SLIP_CONFIRM : ps[0].settingValue;
      } else {
        message.info('Profile data may be incomplete');
        console.log('p', ps);
      }
    }
    return typeof ret === 'string' ? (parseInt(ret) || DEFAULT_PRINT_PACKING_SLIP_CONFIRM) : ret;
  };

  // eslint-disable-next-line
  // const getProfileSettings = async () => {
  //   setLockScreen(true);

  //   try {
  //     const settings = await fetchProfileSettings();

  //     setLockScreen(false);

  //     if (Array.isArray(settings)) {
  //       profileSettings = settings;
  //       setPackingSlipConfirmationValue();
  //     }
  //   } catch (e) {
  //     message.error(`Fetch profile setting error: ${e}`);
  //     setLockScreen(false);
  //   }
  // };

  const getShipmentInsertitems = async (shipmentNum: number) => {
    setLockScreen(true);

    try {
      const res = await fetchShipmentInsertitems(shipmentNum);

      console.log('-> re ->', res);
      setLockScreen(false);

      if (Array.isArray(res)) {
        setInsertedItems(res);
      }
    } catch(e) {
      message.error(`Get items error: ${e}`);
      setLockScreen(false);
    }
  };

  const handleContinueTrackingFromError = () => {
    setErrorOccurred(false); 

    setTimeout(() => {
      trackingRef?.current.focus();
      trackingRef?.current.select();
    }, 300);
  };

  /**
   * Callback for mannual tracking number change event.
   */
  // eslint-disable-next-line
  const handleMannualTrackingChange = (evt: any) => {
    setTrackError(false);
    setTrackErrorMsg('');
  };

  /**
   * Callback for mannual tracking keydown event.
   */
  const handleMannualTrackingKeyDown = (evt: any) => {
    const { keyCode } = evt;

    if ([9, 13].indexOf(keyCode) > -1) {
      const tn = trackingRef?.current.input.value.trim().toLowerCase();

      if (tn.indexOf(`${shipment.trackingNumber}`.toLowerCase()) > -1) {
        playBell('bell');
        onTrackingNumberCorrect();
      } else {
        playBell('error');
        setTrackError(true);
        setTrackErrorMsg('Tracking Number does not match!');
        setTimeout(() => {
          trackingRef?.current.focus();
          trackingRef?.current.select();
        }, 300);
      }
    }
  };

  /**
   * Callback for order input change event.
   */
  // eslint-disable-next-line
  const handleOrderInputChange = (evt: any) => {
    setOrderError(false);
    setOrderErrorMsg('');
  };

  /**
   * Callback for order input keydown event.
   */
  const handleOrderInputKeyDown = (evt: any) => {
    const { keyCode } = evt;

    if ([9, 13].indexOf(keyCode) > -1) {
      const tn = orderInputRef?.current.input.value.trim();

      if (tn) {
        if (typeof props.changeOrder === 'function') {
          playBell('bell');
          props.changeOrder(tn);
        }

        onCancel();
      } else {
        playBell('error');
        setOrderError(true);
        setOrderErrorMsg('Valid Order is needed!');
      }
    }
  };

  const onCancel = () => {
    clearState();
    props.close();
  };

  // eslint-disable-next-line
  const onPackingSlipPrinted = () => {
    setSlipPrintError(false);
    setSlipPrintErrorMsg('');
    setSlipPrinted(true);

    if (shipmentList.length > 0) {
      setShowShipmentSelector(true);
    } else {
      setShowOrderScanner(true);
      setTimeout(() => {
        orderInputRef?.current?.focus();
      }, 500);
    }
  };

  /**
   * Callback for selecting shipment.
   */
  const onSelectShipment = (key: any) => {
    if (typeof props.changeShipment === 'function') {
      props.changeShipment(key);
    }

    onCancel();
  };

  /**
   * Callback when tracking number is correct.
   */
  const onTrackingNumberCorrect = () => {
    const needCarton = shipment.isPrintCartonLabel;
    const skipPS = isSkipPrintPackSlip();
    const sm = shipment as StringKAnyVPair;
    const needSlip = shipment.isPrintPackingSlip && !(sm.allowSkipPrintPackingSlip && skipPS);

    setIsTracking(false);
    setIsTrackNumberOk(true);
    // if need to print slip
    setNoSlip(!needSlip);
    setStep2(true);

    if (needCarton && !cartonPrinted) {
      console.log('printing carton');
      readyPrintCartonLabel();
    }

    if (needSlip && !packingSlipPrinted) {
      // send signalR to print packing slip
      readyPrintPackingSlip();
      const timeout = setTimeout(() => {
        setSlipPrintError(true);
        setSlipPrintErrorMsg('Possible errors in  printing packing slip, you can reprint it later.');
        setSlipPrinted(true);
        setSlipPrintTimeout(null);
      }, PRINT_MESSAGE_TIMEOUT);
      setSlipPrintTimeout(timeout);
    } else {
      onPackingSlipPrinted();
    }
    /*
    setTimeout(() => {
      setSlipPrinted(true);
      if (shipmentList.length > 0) {
        setShowShipmentSelector(true);
      } else {
        setShowOrderScanner(true);
        orderInputRef?.current.focus();
      }
    }, 2000);*/
    // if no slip to print
  };

  const onTrackingNumberError = () => {
    props.close();

    if (typeof props.onPrintError === 'function') {
      props.onPrintError();
    }
  };

  const readyPrintCartonLabel = () => {
    const profile = userProfiles.profile;
    //const slipType = props.packingSlipType || 0;
    const printer = cartonPrinter;
    const carton = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: shipment.fulfillmentOrderNum,
      ShipmentNum: shipment.shipmentNum,
      Time: nowTime(),
    };
    //setLockScreen(true);
    dispatch(printCartonLabel(carton));
    setCartonPrinted(true);
  };

  // eslint-disable-next-line
  const readyPrintPackingSlip = () => {
    const profile = userProfiles.profile;
    const slipType = props.packingSlipType || 0;
    const printer = slipType ? packingPrinter2 : packingPrinter;
    const slip = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: shipment.fulfillmentOrderNum,
      ShipmentNum: shipment.shipmentNum,
      Time: nowTime(),
    };
    //setLockScreen(true);
    dispatch(printPackingSlip(slip));
    setPackingSlipPrinted(true);
  };

  const reprintPackingSlip = () => {
    setSlipPrinted(false);
    onTrackingNumberCorrect();
  };

  // eslint-disable-next-line
  const setPackingSlipConfirmationValue = () => {
    setPrintPackingSlipConfirmation(getPrintPackingSlipConfirmValue() === PRINT_PACKING_SLIP_NEED_CONFIRMATION);
  };

  const showErrorHandler = () => {
    setErrorOccurred(true);
    setMannualTrackEnabled(false);
  };

  const showManualHandler = () => {
    setErrorOccurred(false);
    setMannualTrackEnabled(true);
  };

  React.useEffect(() => {
    if (userProfileSettings) {
      setPackingSlipConfirmationValue();
    } 

    if (!isLoadingPicture) {
      getShipmentInsertitems(shipment.shipmentNum);
      setIsLoadingPicture(true);
    }

    if (isTracking) {
      trackingRef?.current.focus();
    }

    if (
      shipment.isPrintPackingSlip
      && !((shipment as StringKAnyVPair).allowSkipPrintPackingSlip && isSkipPrintPackSlip())
      && !packingSlipPrinted
    ) {
      if (typeof printPackingSlipConfirmation === 'boolean') {
        if (!printPackingSlipConfirmation) {
          readyPrintPackingSlip(); 
          console.log('print slip directly');
          setPackingSlipPrintDirectly(true);
        }
      }
    }

    if (printLabelResponse && shipment) {
      const types = [PRINT_TYPE_PACK_SLIP];

      //console.log('tt', types, printLabelResponse);
      if (types.indexOf(printLabelResponse.type) > -1) {
        setTimeout(() => {
          onPackingSlipPrinted();
        }, PRINT_MESSAGE_ACTION_TIMEOUT);
        clearTimeout(slipPrintTimeout);
        setSlipPrintTimeout(null);
        dispatch(setPrintLabelResponse({}));
      }
    }

    if (printCartonResult && Object.keys(printCartonResult).length > 0) {
       
      if (printCartonResult.code === 200) {
        setCartonLabelPrinted(true);
      }

      if (
        printCartonResult.code !== 200 &&
        typeof printCartonResult.message === 'string'
      ) {
        //clearTimeout(slipPrintTimeout);
        //setSlipPrintTimeout(null);
        setCartonPrintError(true);
        setCartonPrintErrorMsg(printCartonResult.message);
      }

      dispatch(setPrintCartonResult({}));
    }

    if (printSlipResult && Object.keys(printSlipResult).length > 0) {
      //console.log('su', printSlipResult);
      if (
        printSlipResult.code !== 200 &&
        typeof printSlipResult.message === 'string'
      ) {
        clearTimeout(slipPrintTimeout);
        setSlipPrintTimeout(null);
        setSlipPrinted(true);
        setSlipPrintError(true);
        setSlipPrintErrorMsg(printSlipResult.message);
      }

      dispatch(setPrintSlipResult({}));
    }
  }, [
    dispatch,
    isLoadingPicture,
    isTracking,
    onPackingSlipPrinted,
    packingSlipPrinted,
    printCartonResult,
    printLabelResponse,
    printPackingSlipConfirmation,
    printSlipResult,
    readyPrintPackingSlip,
    setPackingSlipConfirmationValue,
    shipment,
    slipPrintTimeout,
    userProfileSettings,
  ]);

  return (
    <>
      <Modal
        bodyStyle={{
          backgroundColor: '#D9DCE1',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          padding: 16,
        }}
        centered
        cancelButtonProps={{ style: { display: props.closable ? '' : 'none' } }}
        closable={props.closable || false}
        destroyOnClose
        footer={null}
        maskClosable={false}
        okButtonProps={{}}
        onCancel={onCancel}
        title={
          <Label theme={{ fontSize: '20px' }}>{shipment.shipmentTitle}</Label>
        }
        visible={props.visible}
        width={600}
      >
        <ShipmentConfirmDialogBody>
          <div className="no-bottom-border">Shipping label was printed.</div>
          {packingSlipPrintDirectly && (slipPrinted ? (slipPrintError ?
            <>
              <div className="stress-msg">{slipPrintErrorMsg}</div>
              <Row justify="end">
                <Space>
                  <Button type="primary" onClick={reprintPackingSlip}>Reprint Packing Slip</Button>
                </Space>
              </Row>
            </> :
            <div className="no-bottom-border">Packing slip was printed.</div>
          ) : (
            <div className="stress-msg">
              <LoadingIcon size="small" />
              Printing Packing Slip
            </div>
          ))}
          {isTracking ? (
            <>
              <div className="no-bottom-border stress-msg">Scan tracking number to confirm</div>
              <div>
                Tracking number:
                {' '}
                <span className="bold-word">{shipment.trackingNumber}</span>
              </div>
              <FormField error={trackError} errorMessage={trackErrorMsg}>
                <Input
                  ref={trackingRef}
                  size="large"
                  onChange={handleMannualTrackingChange}
                  onKeyDown={handleMannualTrackingKeyDown}
                />
              </FormField>
              <Row justify="space-between">
                <DangerButton
                  size="large"
                  onClick={showErrorHandler}
                >
                  <EllipsisOutlined />
                </DangerButton>
                {/*errorOccurred && (
                  <DangerButton
                    type="primary"
                    size="large"
                    onClick={onTrackingNumberError}
                  >
                    Please check if the printer is jammed or out of paper
                  </DangerButton>
                )*/}
                {mannualTrackEnabled && (
                  <Button
                    type="primary"
                    size="large"
                    onClick={onTrackingNumberCorrect}
                  >
                    I swear the tracking number is correct
                  </Button>
                )}
                <Button
                  size="large"
                  onClick={showManualHandler}
                >
                  <EllipsisOutlined />
                </Button>
              </Row>
            </>
          ) : (
            <div>Tracking number({shipment.trackingNumber}) was confirmed.</div>
          )}
          {(step2 && cartonPrinted) &&
            (cartonLabelPrinted ?
              <div className="stress-msg">Carton label was printed.</div> :
            (cartonPrintError ?
              <>
                <div className="stress-msg">{cartonPrintErrorMsg}</div>
              </> :
              <div className="stress-msg">
                <LoadingIcon size="small" />
                Printing Carton Label
              </div>
            )
          )}
          {step2 && (
            noSlip ? (
              <div className="stress-msg">This shipment has no packing slip.</div>
            ) :
            (slipPrinted ?
              (slipPrintError ?
                <>
                  <div className="stress-msg">{slipPrintErrorMsg}</div>
                  <Row justify="end">
                    <Space>
                      <Button onClick={onCancel}>Close</Button>
                      <Button type="primary" onClick={reprintPackingSlip}>Reprint Packing Slip</Button>
                    </Space>
                  </Row>
                </> :
                <div className="stress-msg">Packing slip was printed.</div>
              ) : (
                <div className="stress-msg">
                  <LoadingIcon size="small" />
                  Printing Packing Slip
                </div>
              )
            )
          )}
          {showShipmentSelector && (
            <>
              <div>Choose Next Shipment:</div>
              <Select size="large" style={{ width: '100%' }} onChange={onSelectShipment}>
                {shipmentList.map(e => (
                  <Select.Option key={e.shipmentNum} value={e.shipmentNum}>
                    {e.shipmentTitle}
                  </Select.Option>
              ))}
              </Select>
            </>
          )}
          {isTrackNumberOk && showOrderScanner && (
            <>
              <div>Scan Next Order or SKU:</div>
              <FormField error={orderError} errorMessage={orderErrorMsg}>
                <Input
                  ref={orderInputRef}
                  size="large"
                  onChange={handleOrderInputChange}
                  onKeyDown={handleOrderInputKeyDown}
                />
              </FormField>
            </>
          )}
          {insertedItems.length > 0 && <ShipmentAttachedItems
            items={insertedItems}
          />}
          {isTrackNumberOk && (showShipmentSelector || showOrderScanner) && (
            <ShipmentConfirmDialogFooter justify="end">
              <Button size="large" onClick={onCancel}>Close</Button>
            </ShipmentConfirmDialogFooter>
)}
        </ShipmentConfirmDialogBody>
      </Modal>
      <Modal
        centered
        closable={false}
        footer={null}
        title=""
        visible={errorOccurred}
        width={460}
      >
        <Col>
          <Row>
            <DangerButton
              type="primary"
              size="large"
              style={{width: '100%'}}
              onClick={onTrackingNumberError}
            >
              Please check if the printer is jammed or out of paper
            </DangerButton>
          </Row>
          <FormVSpacer />
          <Row>
            <Button
              type="primary"
              size="large"
              style={{width: '100%'}}
              onClick={handleContinueTrackingFromError}
            >
              Verify Tracking Number
            </Button>
          </Row>
        </Col>
      </Modal>
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default ShipmentConfirmDialog;
