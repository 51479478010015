import React from 'react';
import {
  Card,
  Col,
  Image,
  Row,
} from 'antd';

type Props = {
  items: StringKAnyVPair[];
  numPerLine?: number;
};

const ShipmentAttachedItems = (props: Props) => {
  const { useState } = React;
  const [attachedItems, setAttachedItems] = useState<StringKAnyVPair[][]>([]);
  const [containerWidth, setContainerWidth] = useState(500);
  const [inited, setInited] = useState(false);
  const [numPerLine, /*setNumPerLine*/] = useState(props.numPerLine || 4);
  const wrapperRef = React.useRef<any>(null);

  const attachedItemRow = (lines: StringKAnyVPair[], rowNum: number) => {
    const { Meta } = Card;
    const width = containerWidth / numPerLine;
    const cols = lines.map(e => <Image src={e.insertItemPictureLink} width={width} height={width} placeholder />)
      .map((e, i) => (<Card
        hoverable
        style={{ width }}
        cover={e}
      >
        <Meta title={lines[i].insertItemName} />
      </Card>))
      .map((e, i) => (<Col key={`${rowNum}-${i}`} style={{width}}>{e}</Col>));

    return (<Row align="middle" justify="start" key={`row-${rowNum}`}>
      {cols}
    </Row>);
  };

  // eslint-disable-next-line
  const sliceItems = () => {
    const { items } = props;
    let lines = [];
    let i = 0;

    while (i < items.length) {
      lines.push(items.slice(i, numPerLine));
      i = i + numPerLine;
    }

    setAttachedItems(lines);
  };

  React.useEffect(() => {
    if (!inited) {
      const width = wrapperRef?.current.offsetWidth;

      sliceItems();
      setInited(true);

      if (typeof width === 'number' && width > 0) {
        setContainerWidth(width);
      }
    }
  }, [inited, props, sliceItems]);

  return (
    <>
      <div ref={wrapperRef}>
        {attachedItems.map((e, i) => attachedItemRow(e, i))}
      </div>
    </>
  );
};

export default ShipmentAttachedItems;
