import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import InspectionToLocation from 'components/Sales/Smart/VirtualWarehouseToLocation/InspectionToLocation';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmarInspectionToLocationScreen(): JSX.Element {
  return (
    <ContentLayout
      titleKey="titles.inspectionToLocation" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
      // title={`Inspection to Location(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <InspectionToLocation />
    </ContentLayout>
  );
}
