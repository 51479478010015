import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Select,
  Table,
  Modal,
  Button,
  Space,
  InputNumber,
  Input,
} from 'antd';
import { ReloadOutlined, PrinterOutlined, CloudDownloadOutlined, FileSearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { DEFAULT_PRINT_MODE, LS_DEFAULT_PRINTER_CONFIG } from 'constants/config';
import {
  fetchPrinterList,
  fetchPrintTemplates,
  PrintLabelExport,
  PrintLabelPreview,
  postPrintLabel,
} from 'services/print';
import { printLabel } from 'actions/printActions';
// import { QuantityTitle } from './style';
import FormLabel from '../FormLabel';
import message from '../message';
import { userProfiles } from 'utils/auth';
import { getProfileSettingValue, formatPrinterToTree } from 'utils';
import moment from 'moment';

interface PrintCodeProps {
  visible: boolean;
  onHide: () => void;
  labelData: PrintLabelTypes[];
  printType: 'location' | 'product' | 'lotNumber' | 'barcode';
  upcList?: string[];
  skuList?: string[];
}

type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};

const printTypeInfo = {
  location: {
    columnTitle: 'Locations/Containers',
    TemplateType: 3,
    title: 'Print Location Labels',
  },
  product: {
    columnTitle: 'SKU / UPC',
    TemplateType: 4,
    title: 'Print Product Label',
  },
  lotNumber: {
    columnTitle: 'Lot Number',
    TemplateType: 3,
    title: 'Print Lot Number',
  },
  barcode: {
    columnTitle: 'SKU',
    TemplateType: 4,
    title: 'Print Barcode',
  },
};
// eslint-disable-next-line
export default (props: PrintCodeProps) => {
  const { visible, onHide, labelData, printType, skuList, upcList } = props;
  const [loading, setLoading] = React.useState(false);
  const [printerList, setPrinterList] = React.useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = React.useState<OptionsTree[]>();
  const [printTemplateList, setPrintTemplateList] = React.useState<
    PrintTemplate[]
  >([]);
  const [data, setData] = React.useState<PrintLabelTypes[]>([]);
  const [refreshPrinter, setRefeshPrinter] = React.useState(true);
  const [refreshTemplate, setRefeshTemplate] = React.useState(true);
  //const ref = React.useRef<HTMLInputElement | null>(null);
  const ref = React.useRef<any>(null);
  const [productLabelType, setProductLabelType] = React.useState('sku');
  const [showHeader, setShowHeader] = useState<boolean>(false);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  //document.body.style.overflowY = 'hidden';

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const columns =
    printType === 'location'
      ? [
        {
          title: printTypeInfo[printType].columnTitle,
          dataIndex: 'code',
          width: '70%',
        },
        {
          title: 'Print Quantity',
          dataIndex: 'quantity',
          width: '30%',
          render: (_: number, record: PrintLabelTypes, index: number) => {
            return (
              <InputNumber
                id={`print_quanity_row_${index}`}
                style={{ width: '100%' }}
                min={1}
                max={999}
                defaultValue={1}
                onChange={(value) => {
                  setData((prev) => {
                    const newArr = [...prev];
                    newArr[index] = { ...record, quantity: value };
                    return [...newArr];
                  });
                }}
              />
            );
          },
        },
      ]
      : [
        {
          title: printTypeInfo[printType].columnTitle,
          dataIndex: 'code',
          width: '35%',
        },
        {
          title: 'Product Name',
          dataIndex: 'title',
          width: '35%',
        },
        {
          title: 'Print Quantity',
          dataIndex: 'quantity',
          width: '30%',
          render: (_: number, record: PrintLabelTypes, index: number) => {
            return (
              <InputNumber
                id={`print_quanity_row_${index}`}
                style={{ width: '100%' }}
                min={1}
                max={999}
                defaultValue={1}
                onChange={(value) => {
                  setData((prev) => {
                    const newArr = [...prev];
                    newArr[index] = { ...record, quantity: value };
                    return [...newArr];
                  });
                }}
              />
            );
          },
        },
      ];

  const onClose = () => {
    //reset data before close modal
    form.resetFields();
    setData([]);
    document.body.style.overflowY = 'unset';
    onHide();
  };

  const getPrinterList = React.useCallback(async () => {
    try {
      const res = await fetchPrinterList();
      setRefeshPrinter(false);
      if (res.isSuccess) {
        setPrinterList(res.data);
        setPrinterTree(formatPrinterToTree(res.data));

      } else {
        message.error({
          content: res.message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
      console.log(err);
    }
  }, []);

  const getPrinterTemplateList = React.useCallback(async () => {
    try {
      const profile = userProfiles.profile;
      const res = await fetchPrintTemplates({
        TemplateType: printTypeInfo[printType].TemplateType,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      setRefeshTemplate(false);
      if (res.isSuccess) {
        setPrintTemplateList(res.data);
      } else {
        message.error({
          content: res.message,
        });
      }
    } catch (err) {
      setRefeshTemplate(false);
      console.log(err);
    }
  }, [printType]);

  const PostPrintTask = async (postType: string) => {
    if (data.length === 0) {
      message.warning({ content: 'not found Label' });
      return;
    }
    const formValues = await form.getFieldsValue();
    if (postType === 'printTask') {
      const hadError = await form.validateFields();
      if (hadError.errorFields) return;
    } else {
      const hadError = await form.validateFields(['PrintTemplateNum','header']);
      if (hadError.errorFields) return;
    }

    setLoading(true);
    localStorage.setItem(LS_DEFAULT_PRINTER_CONFIG, JSON.stringify({ printer: formValues.PrinterNum, template: formValues.PrintTemplateNum, labelType: formValues.LabelType }))
    const profile = userProfiles.profile;
    try {
      switch (postType) {
        case 'printTask': {
          const printMode = await getProfileSettingValue(
            'PrintStationMode',
            DEFAULT_PRINT_MODE
          );
          const selectedPrinter = printerList.filter(
            (item) => item.printerNum === formValues.PrinterNum
          )[0];
          if (printMode === DEFAULT_PRINT_MODE) {
            dispatch(
              printLabel({
                ...formValues,
                Data: data,
                Qty: 1,
                MasterAccountNum: profile.masterAccountNum,
                PrinterAccountNum: selectedPrinter.printerAccountNum,
                TemplateType: printTypeInfo[printType].TemplateType,
                ProfileNum: profile.profileNum,
                User: profile.email,
              })
            );
          } else {
            const res = await postPrintLabel({
              ...formValues,
              Data: data,
              Qty: 1,
              MasterAccountNum: profile.masterAccountNum,
              PrinterAccountNum: selectedPrinter.printerAccountNum,
              TemplateType: printTypeInfo[printType].TemplateType,
              ProfileNum: profile.profileNum,
              User: profile.email,
            });
            if (res.isSuccess) {
              message.success({ content: 'Add printTask success' });
            } else {
              message.info({ content: res.message });
            }
          }
          setLoading(false);
          onClose();
          break;
        }
        case 'pdfExport': {
          const res = await PrintLabelExport({
            ...formValues,
            Data: data,
            Qty: 1,
            MasterAccountNum: profile.masterAccountNum,
            PrinterAccountNum: 0,
            PrinterNum: 0,
            TemplateType: printTypeInfo[printType].TemplateType,
            ProfileNum: profile.profileNum,
            User: profile.email,
          });
          const content = res;
          const blob = new Blob([content], { type: 'application/pdf' });
          const fileName = `labels-${moment().format('YYYYMMDDhhmmss')}.pdf`;
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            //navigator.msSaveBlob(blob, fileName);
          }
          setLoading(false);
          break;
        }
        case 'pdfPreview': {
          const previewRes = await PrintLabelPreview({
            ...formValues,
            Data: data,
            Qty: 1,
            MasterAccountNum: profile.masterAccountNum,
            PrinterAccountNum: 0,
            PrinterNum: 0,
            TemplateType: printTypeInfo[printType].TemplateType,
            ProfileNum: profile.profileNum,
            User: profile.email,
          });
          const w = window.open('about:blank');
          w?.document.write(previewRes);
          setLoading(false);
          break;
        }
        default:
          break;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const ls = localStorage.getItem(LS_DEFAULT_PRINTER_CONFIG);
    const printerConfig = ls ? JSON.parse(ls) : {};
    if (printerConfig) {
      const temp = (printTemplateList || []).filter(i => i.printTemplateNum === printerConfig.template)
      if (temp.length > 0) {
        form.setFieldsValue({ PrintTemplateNum: printerConfig.template });
        if(temp[0].customize === 1){
          setShowHeader(true)
        } else {
          setShowHeader(false)
        }
      }
      if ((printerList || []).findIndex(item => item.printerNum === printerConfig.printer) > -1) {
        form.setFieldsValue({ PrinterNum: printerConfig.printer });
      }
      form.setFieldsValue({ LabelType: printerConfig.labelType });
      setProductLabelType(printerConfig.labelType);
    }
  }, [printTemplateList, printerList, form])

  React.useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }

    if (props.visible) {
      document.body.style.overflowY = 'hidden';
    }
  }, [getPrinterList, props, refreshPrinter]);

  React.useEffect(() => {
    if (refreshTemplate) {
      getPrinterTemplateList();
      setRefeshTemplate(false);
    }
  }, [getPrinterTemplateList, refreshTemplate]);

  React.useEffect(() => {
    setData(labelData);
  }, [labelData]);
  React.useEffect(() => {
    if (printType === 'product' && labelData.length > 0) {
      if (productLabelType === 'sku') {
        const temp = labelData.map((item, index) => {
          return {
            ...item,
            code: skuList ? skuList[index] : '',
          };
        });
        setData(temp);
      }
      if (productLabelType === 'upc') {
        const temp = labelData.map((item, index) => {
          return {
            ...item,
            code: upcList ? upcList[index] : '',
          };
        });
        setData(temp);
      }
      if (productLabelType === 'sku+upc') {
        const temp = labelData.map((item, index) => {
          return {
            ...item,
            title: skuList ? skuList[index] : '',
            code: upcList ? upcList[index] : '',
          };
        });
        setData(temp);
      }
    }
  }, [productLabelType, labelData, printType, skuList, upcList]);



  return (
    <Modal
      title={printTypeInfo[printType].title}
      centered
      forceRender
      maskClosable={false}
      confirmLoading={loading}
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={onClose}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              id="export_to_pdf"
              onClick={() => PostPrintTask('pdfExport')}
            >
              <CloudDownloadOutlined />
              Export to PDF
            </Button>
            {false && <Button onClick={() => PostPrintTask('pdfPreview')}>
              <FileSearchOutlined />
              Preview
            </Button>}
          </Space>

          <Space>
            <Button
              id="print_button"
              key="submit"
              type="primary"
              onClick={() => {
                PostPrintTask('printTask');
              }}
            >
              <PrinterOutlined />
              Print
            </Button>
            <Button id="cancel_button" onClick={onClose} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form form={form} hideRequiredMark {...layout}>
        <Form.Item label={<FormLabel>Select Printer</FormLabel>}>
          <Form.Item
            name="PrinterNum"
            rules={[
              {
                required: true,
                message: 'Please select a printer',
              },
            ]}
            colon={false}
            label={
              <Button
                id="refresh_printer_button"
                icon={
                  <ReloadOutlined
                    spin={refreshPrinter}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshPrinter(true)}
              />
            }
          >
            <Select
              id="printer_select"
              ref={ref}
              placeholder="Select a printer"
              optionLabelProp="label"
            >
              {printerTree?.map((item) => {
                return (
                  <Select.OptGroup
                    key={item.label}
                    label={item.label}
                  >
                    {item.options.map((citem: any) => {
                      return (
                        <Select.Option
                          key={citem.value}
                          value={citem.value}
                          label={`${item.label} - ${citem.label}`}
                        >
                          {citem.label}
                        </Select.Option>
                      );
                    })
                    }
                  </Select.OptGroup>
                );
              })}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item label={<FormLabel>Select Template</FormLabel>}>
          <Form.Item
            name="PrintTemplateNum"
            rules={[
              {
                required: true,
                message: 'Please select a print template',
              },
            ]}
            colon={false}
            label={
              <Button
                id="refresh_print_template_button"
                icon={
                  <ReloadOutlined
                    spin={refreshTemplate}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshTemplate(true)}
              />
            }
          >
            <Select
              id="template_select"
              placeholder="Select a print template"
              onChange={(value)=>{
                if(printType === 'product'){
                  const temp = printTemplateList.filter(i=> i.printTemplateNum === value)
                  if(temp.length > 0 && temp[0].customize === 1) {
                    setProductLabelType('sku')
                    setShowHeader(true)
                  } else {
                    form.setFieldsValue({header: undefined})
                    setShowHeader(false);
                  }
                }
              }}
            >
              {printTemplateList.map((item, index) => (
                <Select.Option key={index} value={item.printTemplateNum}>
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        {printType === 'product' && !showHeader && (
          <Form.Item name="LabelType" label={<FormLabel>Label Type</FormLabel>}>
            <Select
              id="label_type_select"
              defaultValue="sku"
              onChange={(value: string) => setProductLabelType(value)}
            >
              <Select.Option value="sku">SKU</Select.Option>
              <Select.Option value="upc">UPC</Select.Option>
              <Select.Option value="sku+upc">SKU+UPC</Select.Option>
            </Select>
          </Form.Item>
        )}

        {printType === 'product' && showHeader && (
          <Form.Item 
            name="header" 
            label={<FormLabel>Header Text</FormLabel>}             
            rules={[{
              required: true,
              message: 'Please input Header Text',
            }]}
          >
            <Input />
          </Form.Item>
        )}

        <Table
          scroll={{ y: 300 }}
          columns={columns}
          rowKey={(item) => item.id}
          dataSource={data.map((item, index) => {
            return {
              id: index,
              ...item,
            };
          })}
          bordered
          size="small"
          pagination={false}
        />
      </Form>
    </Modal>
  );
};
