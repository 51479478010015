import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playBell } from 'utils';
import { scanSkuReturn } from 'services/smart';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
} from '../../SmartComponent';
import { Spin, Switch, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';

type ScanSKUProps = {
  warehouseId: string;
  warehouseNum: number;
  vWarehouseId: string;
  vWarehouseCode: string;
  onSubmit: (product: SmartPickSKU) => void;
  showLocations:boolean
  setShowLocations: any;
};
export default function ScanSKU(props: ScanSKUProps): JSX.Element {
  const { onSubmit, warehouseId, setShowLocations, showLocations, vWarehouseId, vWarehouseCode } = props;
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  /**
   * handle sku key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        setFetching(true);
        try {
          const res1 = await scanSkuReturn(vWarehouseId, nextValue)
            if (res1.isSuccess) {
              if(res1.data.locations.length === 0){
                setFetching(false);
                setErrorMessage(`cannot not find item [ ${nextValue} ] in [ ${vWarehouseCode} ]`);
              } else {
                const res = await scanSkuReturn(warehouseId, nextValue);
                setFetching(false);
                if (res.isSuccess) {
                  playBell('success');
                  onSubmit(res.data);
                } else {
                  if (res.code === 404) {
                    setErrorMessage(`Item "${nextValue}" is not valid!`);
                  }
                }
              }
            } else {
              setFetching(false);
              if (res1.code === 404) {
                setErrorMessage(`Item "${nextValue}" is not valid!`);
              }
            }
        } catch (error) {
          setFetching(false);
        }
      }
    },
    [onSubmit, warehouseId, vWarehouseCode, vWarehouseId]
  );
  return (
    <>
      <Spin spinning={fetching} style={{ width: '100%' }}>
        <SmartSpace>
          <SmartFormLabel
            style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            addonAfter={
              <Space size="small" style={{ marginLeft: 8 }}>
              <Switch
                  defaultChecked={showLocations}
                  checkedChildren="on"
                  unCheckedChildren="off"
                  onChange={setShowLocations}
                />
                <Typography.Text strong={false} style={{fontSize: 12}}>{t("smart.virtualWarehouseToLocation.returnToLocation.showLocations")}</Typography.Text>
                </Space>
            }
          >
            {t("smart.virtualWarehouseToLocation.returnToLocation.scanItemStart")}
          </SmartFormLabel>
          <SmartScanner
            disabled={fetching}
            id="scanItem"
            isAutoFocus={1}
            style={{ marginTop: 10 }}
            onChangeValue={handleScannerChange}
          />
          {errorMessage && (
            <div style={{ color: 'red', fontSize: 18, marginTop: 5 }}>
              {errorMessage}
            </div>
          )}
        </SmartSpace>
        <SmartBackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Spin>
    </>
  );
}
