import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import WhiteList from 'components/Admin/WhiteList';

function Shipment() {
  return (
    <ContentLayout>
      <SiteContent>
        <WhiteList />
      </SiteContent>
    </ContentLayout>
  );
}

export default Shipment;
