/**
 * catalog service requests are here.
 */
import { digitBridgeApi, erpAPI } from './http';

export const fetchErpSystemCode = (codeName: string): Promise<any> => {
  return erpAPI.get('/api/systemCodes', {params: {SystemCodeName: codeName}});
};

/**
 * fetch the Products
 * @param {[key: string]: any} - the query options, it contains these props:
 *                               $skip - how many records will been skipped
 *                               $top - how many records will been returned
 *                               $sortBy - which field to sort
 *                               $calculateTotal - whether to count the total
 *                               $count - whether to count the records number
 * @returns
 */
export const queryPruducts = async (query?: {
  [key: string]: any;
}): Promise<any> => {
  const params: StringKAnyVPair = {
    $skip: 0,
    $top: 1000,
    $sortBy: 'SKU',
    $calculateTotal: false,
    $count: true,
    $filter: '',
    ...query,
  };
  return await digitBridgeApi.get('/api/products', {
    cancelToken: query?.cancelToken,
    params,
  });
};

export const syncSystemCodes = async (codes: StringKAnyVPair[]) => {
  return await digitBridgeApi.post('/api/systemCodes/sync', codes, {
  });
};

// export const queryStatistics = async (productNums: number[], cancelToken: any): Promise<any> => {
//   const params: StringKAnyVPair = {
//     ProductNums: productNums.toString()
//   };
//   return await digitBridgeApi.get('/api/products/statistics', {
//     cancelToken,
//     params,
//   });
// };
