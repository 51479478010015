import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import ShipmentVoidHistory from 'components/Reports/ShipmentVoidHistory';

function Shipment() {
  return (
    <ContentLayout>
      <SiteContent>
        <ShipmentVoidHistory />
      </SiteContent>
    </ContentLayout>
  );
}

export default Shipment;
