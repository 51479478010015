import { Button, Row, Modal, Card, Checkbox, Col, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchTags, createTag } from 'services/tag';
import { message } from 'components/common';
import { CloseOutlined, FileSearchOutlined, PlusOutlined } from '@ant-design/icons';

const { Search } = Input;
const CheckboxGroup = Checkbox.Group;

type PicklistTagDialogProps = {
  onHide: (tagName?: string) => void;
  onClose: () => void;
};
/**
 * Return ItemDetailDialog
 * @param {ItemDetailDialogProps} props
 * @returns {JSX.Element}
 */
export default function PicklistTagDialog(
  props: PicklistTagDialogProps
): JSX.Element {
  const { onHide, onClose } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<TagRow[]>([]);
  const [checkedList, setCheckedList] = React.useState<any[]>([]);

  const getTagList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchTags(3);
      setLoading(false);
      setTags(res);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const createNewTag = useCallback(
    async (name: string) => {
      try {
        const res = await createTag(name, 3);
        if (res) {
          message.success({ content: 'Created Tag Successfully!' });
          getTagList();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [getTagList]
  );

  useEffect(() => {
    getTagList();
  }, [getTagList]);

  return (
    <>
      <Modal
        title="Tag Pick list"
        bodyStyle={{ padding: 8 }}
        visible
        onCancel={() => onClose()}
        width={800}
        footer={[
          <Button
            disabled={checkedList.length === 0}
            type="primary"
            onClick={() => {
              const temp = tags.filter(
                (item) => item.tagNum === checkedList[0]
              );
              if (temp.length > 0) {
                onHide(temp[0].tagName);
              }
            }}
            icon={<FileSearchOutlined />}
          >
            Tag
          </Button>,
          <Button onClick={() => onHide()} icon={<CloseOutlined />}>Skip Tag</Button>,
        ]}
      >
        <Card
          loading={loading}
          size="small"
          title={
            <Row justify="start">
              <Search
                style={{ width: 400 }}
                placeholder="input tag name"
                allowClear
                enterButton={<><PlusOutlined />New Tag</>}
                onSearch={(value) => {
                  if (value && value.trim().length > 0) {
                    createNewTag(value);
                  }
                }}
              />
            </Row>
          }
        >
          <CheckboxGroup style={{ width: '100%' }} value={checkedList}>
            <Row>
              {tags.map((item) => (
                <Col span={8}>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedList([item.tagNum]);
                      } else {
                        setCheckedList([]);
                      }
                    }}
                    value={item.tagNum}
                  >
                    {item.tagName}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CheckboxGroup>
        </Card>
      </Modal>
    </>
  );
}
