import React, { useCallback } from 'react';
import { Modal, Row, Space, Typography } from 'antd';
import {
  SmartButton,
  SmartRow,
  SmartScanner,
  SmartFormLabel,
  GreenColor,
  //   SmartText,
} from '../../SmartComponent';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type OrderFinishDialogProps = {
  dataInfo: OrderShipment[];
  total: number;
  onSubmit: (order: string | undefined) => void;
};
export default function OrderFinishDialog(
  props: OrderFinishDialogProps
): JSX.Element {
  const { onSubmit, dataInfo, total } = props;
  const { t } = useTranslation()
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (value?: string) => {
      onSubmit(value);
    },
    [onSubmit]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <Text>
          <span role="img" aria-label="donut">
            &#128077;
          </span>{' '}
          {t("smart.confirm.trackingNumberConfirmed", { value: dataInfo[0].trackingNumber })}
        </Text>
      </SmartRow>
    );
  };

  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <Row justify="center">
        <Space>
          <Text strong style={{ fontSize: 20, color: '#40a9ff' }}>
            {t("smart.confirm.total")}:{' '}
            <Text style={{ fontSize: 20, color: '#1c1e21' }}>{total}</Text>
          </Text>
          <Text strong style={{ fontSize: 20, color: '#40a9ff' }}>
            {t("smart.confirm.confirmed")}:{' '}
            <Text style={{ fontSize: 20, color: '#1c1e21' }}>{total}</Text>
          </Text>
        </Space>
      </Row>

      <SmartRow>
        <SmartFormLabel>
          {t("smart.confirm.shipTo")}
        </SmartFormLabel>
        <Text style={{ fontSize: 16, color: '#1c1e21' }}></Text>
      </SmartRow>
      <SmartRow>
        <Space direction="vertical">
          <Text style={{ fontSize: 16, color: '#1c1e21' }}>
            {dataInfo[0].shipToName}
          </Text>
          <Text style={{ fontSize: 16, color: '#1c1e21' }}>
            {[
              dataInfo[0].shipToAddressLine1,
              dataInfo[0].shipToAddressLine2,
              dataInfo[0].shipToAddressLine3,
            ]
              .filter((e) => e)
              .join(', ')}
          </Text>
          <Text style={{ fontSize: 16, color: '#1c1e21' }}>
            {`${dataInfo[0].shipToCity},${dataInfo[0].shipToState} ${dataInfo[0].shipToPostalCode}`}
          </Text>
        </Space>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.confirm.scanNextTracking#")}
        </SmartFormLabel>
      </SmartRow>
      <SmartRow style={{ marginTop: 8 }}>
        <SmartScanner
          id="scanNextTracking"
          onChangeValue={(value) => handleScannerChange(value)}
        />
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            handleScannerChange(undefined);
          }}
        >
          {t("common.close")}
        </SmartButton>
      </SmartRow>
    </Modal>
  );
}
