import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #ececec;
`;

export const BreadTitle = styled.div`
  width: 100%;
  height: 20px;
  text-align: left;
`;

export const WhiteDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TabsBox = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
`;
