import moment from 'moment-timezone';

const getNonHoliday = (date: moment.Moment): moment.Moment => {
  const [isHoliday] = isUSHoliday(date);

  if (isHoliday) {
    const previous = date.clone().add(-1, 'days');

    return getNonHoliday(previous);
  } else {
    return date;
  }
};

/**
 * Get the previous business day of the target date.
 *
 * @param string target - the target date, its format is like: YYYY-MM-DD
 * @param string tz - the time zone, not needed currently
 * @param format string - the format of the target date, it's the UTC time currently
 */
export const getUSPreviousBusinessDay = (
  target: string,
): [moment.Moment, moment.Moment] => {
  const tm = moment(target).endOf('day'); // if no time zone, it's UTC time
  const day = tm.day();
  let date: moment.Moment = tm.clone().startOf('day').add(-1, 'days');

  //console.log('day', day);
  switch (day) {
    case 0:
      // Sunday
      date = tm.clone().startOf('day').add(-2, 'days');
      break;

    case 1:
      // Monday
      date = tm.clone().startOf('day').add(-3, 'days');
      break;

    default:
      break;
  }

  //console.log('dd', date.format());
  return [getNonHoliday(date), tm];
};

export const isUSHoliday = (date: moment.Moment) => {
  const weekday = date.day();
  const day = date.date();
  const month = 1 + date.month();
  //const year = date.year();
  //console.log('--->', year, month, day, weekday);

  if (weekday === 0 || weekday === 6) {
    return [true, 'Weekend'];
  } else {
    if (month === 1 && day === 1) return [true, 'New Year\'s Day'];
    else if (month === 1 && day === 2 && weekday === 1) return [true, 'The date after New Year\'s Day. New Year\'s Day is on Sunday'];
    else if (month === 5 && weekday === 1 && day >= 25) return [true, 'Memorial Day'];
    else if (month === 7 && day === 3 && weekday === 5) return [true, 'The day before Independence Day. Independence day is on Saturday.'];
    else if (month === 7 && day === 4) return [true, 'Independence Day'];
    else if (month === 7 && day === 5 && weekday === 1) return [true, 'The day after Independence Day. Independence Day is on Sunday.'];
    else if (month === 9 && weekday === 1 && day <= 7) return [true, 'Labor Day'];
    else if (month === 11 && weekday === 4 && day >= 22) return [true, 'Thanksgiving'];
    else if (month === 12 && day === 24 && weekday === 5) return [true, 'The day before Christmas. Christmas is on Saturday.'];
    else if (month === 12 && day === 25) return [true, 'Christmas'];
    else if (month === 12 && day === 26 && weekday === 1) return [true, 'The day after Christmas Day. Christmas is on Sunday.'];
    else if (month === 12 && day === 31 && weekday === 5) return [true, 'The day before New Year\'s Day. New Year\'s Day is on Saturday'];
  }

  return [false, 'Weekday'];
};

export const testBusinessDay = () => {
  let date = moment().format();

  console.log('test today', date);
  console.log(getUSPreviousBusinessDay(date).map(e => e.format()));
  date = '2021-12-19';
  console.log('test Sunday', date);
  console.log(getUSPreviousBusinessDay(date).map(e => e.format()));
  date = '2021-12-20';
  console.log('test Monday', date);
  console.log(getUSPreviousBusinessDay(date).map(e => e.format()));
  date = '2021-12-24';
  console.log('test day before Chirstmas', date);
  console.log(getUSPreviousBusinessDay(date).map(e => e.format()));
  date = '2021-12-25';
  console.log('test Chirstmas', date);
  console.log(getUSPreviousBusinessDay(date).map(e => e.format()));
};
