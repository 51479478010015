import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Row,
  Menu,
  Modal,
  Dropdown,
  Avatar,
  Button,
  Space,
  Radio,
  message,
  Typography,
} from 'antd';
import {
  HomeFilled,
  ExclamationCircleOutlined,
  TranslationOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { LS_DEFAULT_WAREHOUSE_KEY, LS_LOCALE_CODE } from 'constants/config';
import { LanguageList } from 'i18n/config';
import { useTranslation } from 'react-i18next';
import { RootState } from 'reducers';
import { userProfiles, logout } from 'utils/auth';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { changeLanguage, switchLanguage } from 'actions/regularActions';
import VersionModal from 'components/common/SmartVersionModal';
import { getWarehouseTypeFromCache } from 'utils';

interface SmartLayoutProps {
  children: ReactNode;
  // title: ReactNode | string;
  titleKey: string;
  value?: string;
}
export default function SmartLayout(props: SmartLayoutProps) {
  const { children, titleKey, value } = props;
  const [languageDialogVisible, setLanguageDialogVisible] = useState(false);
  const [warehouseDialogVisible, setWarehouseDialogVisible] = useState(false);
  const [versionModalVisible, setVersionModalVisible] =
    useState<boolean>(false);
  const { i18n, t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const { locale, selectedLanguage } = useSelector(
    (state: RootState) => state.regular,
    shallowEqual
  );
  const warehouses = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const warehouselist: Array<WarehouseRow> = useMemo(() => {
    return [...warehouses.filter((w: WarehouseRow) => w.warehouseType === 1)];
  }, [warehouses]);
  // const [selectedLanguage, setSelectedLanguage] = useState(
  //   localStorage.getItem(LS_LOCALE_CODE)
  // );
  const warehosueType = useMemo(() => {
    return getWarehouseTypeFromCache(
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    );
  }, []);

  const logoutUser = () => {
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        logout();
        userProfiles.removeProfileFingerprint();
        localStorage.removeItem(LS_LOCALE_CODE);
      },
      onCancel() {},
    });
  };

  const getUserItemLabel = () => {
    const { email, name } = userProfiles.profile;
    const words = [name || '', name ? `(${email})` : email].filter((e) => e);

    return words.join(' ');
  };

  const selectWarehouse = (wid: string) => {
    console.log('w = ', wid);
    try {
      //todo save
      localStorage.setItem(LS_DEFAULT_WAREHOUSE_KEY, wid);
      message.success({ content: 'Switch warehouse successfully' });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      message.error(`Switch warehouse failed, error = ${error}`);
    }
  };

  useEffect(() => {
    if (warehosueType === 5) {
      setWarehouseDialogVisible(true);
    }
  }, [warehosueType]);

  const userMenu = (
    <Menu>
      <Menu.Item key="1">
        <span>{getUserItemLabel()}</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setLanguageDialogVisible(true)}>
        <span>Language</span>
      </Menu.Item>
      <Menu.Item key="5" onClick={() => setWarehouseDialogVisible(true)}>
        <span>Warehouse</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={logoutUser}>
        <span>Logout</span>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => setVersionModalVisible(true)}>
        <span>About</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Row
        style={{
          padding: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'rgb(240,240,240)',
        }}
      >
        <div
          style={{
            maxWidth:
              selectedLanguage && locale !== 'en'
                ? 'calc(100% - 120px)'
                : 'calc(100% - 40px)',
          }}
        >
          <Link to="/smart">
            <HomeFilled style={{ fontSize: 20 }} />
          </Link>
          <span style={{ marginLeft: 10, fontWeight: 600 }}>{t(titleKey, {value})}</span>
        </div>
        <Space>
          {selectedLanguage && locale !== 'en' && (
            <Button
              shape="round"
              icon={<TranslationOutlined />}
              onClick={async () => {
                if (selectedLanguage === 'en') {
                  await i18n.changeLanguage(locale);
                  dispatch(switchLanguage(locale));
                  // localStorage.setItem(LS_LOCALE_CODE, selectedLanguage);
                } else {
                  await i18n.changeLanguage('en');
                  dispatch(switchLanguage('en'));
                  // localStorage.setItem(LS_LOCALE_CODE, 'en');
                }
              }}
            >
              {selectedLanguage === 'en' ? locale : 'en'}
            </Button>
          )}
          <Dropdown
            trigger={['click']}
            overlay={userMenu}
            placement="bottomCenter"
          >
            <Avatar
              size={36}
              style={{
                backgroundColor: '#000',
                fontSize: 36,
              }}
              icon={<i className="bi-person-fill"></i>}
            />
          </Dropdown>
        </Space>
      </Row>
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
        }}
      >
        {children}
      </div>
      {languageDialogVisible && (
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          visible={true}
          footer={null}
          closable={false}
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={async (e) => {
              await i18n.changeLanguage(e.target.value);
              dispatch(changeLanguage(e.target.value));
              localStorage.setItem(LS_LOCALE_CODE, e.target.value);
              setLanguageDialogVisible(false);
            }}
          >
            <Space direction="vertical">
              {LanguageList.map((i) => (
                <Radio.Button
                  style={{ width: 250 }}
                  key={i.code}
                  value={i.code}
                >
                  {i.name}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </Modal>
      )}
      {warehouseDialogVisible && (
        <Modal
          bodyStyle={{ textAlign: 'center' }}
          visible={true}
          footer={null}
          closable={false}
        >
          {getWarehouseTypeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          ) === 5 && (
            <Typography.Paragraph>
              You are currently using a third party warehouse, please select a
              regular warehosue from bellow
            </Typography.Paragraph>
          )}
          <Radio.Group
            buttonStyle="solid"
            onChange={async (e) => {
              selectWarehouse(e.target.value);
            }}
          >
            <Space direction="vertical">
              {warehouselist.map((i) => (
                <Radio.Button style={{ width: 250 }} key={i.id} value={i.id}>
                  {i.warehouseCode}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </Modal>
      )}
      <VersionModal
        show={versionModalVisible}
        onClose={() => setVersionModalVisible(false)}
      />
    </div>
  );
}
