import React from 'react';
import { Col, Tooltip, Row, Space } from 'antd';
import { DeleteButton, Loading, ScreenMask, message, HoverBgButton } from 'components/common';



import { ButtonIcon, Label, MainList } from 'components/common/styles';

import { LOADING_ICON_SIZE1 } from 'constants/config';
import {
  deletePickListProfile,
  fetchPickListProfileList,
} from 'services/sales';

type Props = {
  editProfile: Function;
  newProfileId: number;
  selectProfile: Function;
  visible: boolean;
};

const FilterProfileList = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [lastProfileId, setLastProfileId] = useState(0);
  const [lockScreen, setLockScreen] = useState(false);
  const [profileList, setProfileList] = useState<StringKAnyVPair[]>([]);

  const deleteProfile = async (profile: StringKAnyVPair) => {
    console.log('pid', profile);
    setLockScreen(true);
    try {
      const res = await deletePickListProfile(profile.pickProfileNum);

      console.log('resss', res);
      if (res) {
        getPickListProfileList();
      } else {
        setLockScreen(false);
        message.error(`Delete profile '${profile.productTitle}' failed`);
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Delete profile '${profile.productTitle}' error`);
    }
  };

  const getPickListProfileList = async () => {
    const list = await fetchPickListProfileList();

    console.log(list);
    setLockScreen(false);

    if (list && Array.isArray(list)) {
      setProfileList(list);
    }
  };

  /*
  const handleEditProfile = (profile: StringKAnyVPair) => {
    props.editProfile(profile);
  };*/

  const handleSelectProfile = (profile: StringKAnyVPair) => {
    props.selectProfile(profile);
    setLastProfileId(profile.pickProfileNum);
  };

  React.useEffect(() => {
    const { newProfileId } = props;
    let isFetchingList = false;

    if (!inited) {
      if (props.visible) {
        setLockScreen(true);
        getPickListProfileList();
        setInited(true);
        isFetchingList = true;
      }
    }

    if (newProfileId > 0 && newProfileId !== lastProfileId) {
      setLastProfileId(newProfileId);

      if (!isFetchingList) {
        setLockScreen(true);
        getPickListProfileList();
      }
    }
  }, [inited, lastProfileId, props]);

  return (
    <>
      <Label className="label-bold">Profile Name</Label>
      <MainList
        bordered
        dataSource={profileList}
        renderItem={(item: any) => (
          <MainList.Item>
            <Row
              align="middle"
              justify="space-between"
              style={{ width: '100%' }}
            >
              <Col>{item.productTitle}</Col>
              <Col>
                <Space>
                  <Tooltip
                    title={`Select Filter Profile '${item.productTitle}'`}
                    trigger="hover"
                  >
                    <HoverBgButton
                      hovertype="info"
                      onClick={() => handleSelectProfile(item)}
                    >
                      <ButtonIcon className="bi-hand-index-thumb" />
                      Select
                    </HoverBgButton>
                  </Tooltip>
                  {/*<Button onClick={() => handleEditProfile(item)}>
                    Edit
                    <ButtonIcon className="bi-pencil-fill" />
                  </Button>*/}
                  <Tooltip
                    placement="topRight"
                    title={`Delete Filter Profile '${item.productTitle}'`}
                    trigger="hover"
                  >
                    <DeleteButton
                      confirmContent={`Are you sure you want to delete Filter Profile '${item.productTitle}'?`}
                      confirmTitle="Delete Filter Profile"
                      onClick={() => deleteProfile(item)}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </MainList.Item>
        )}
      />
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
    </>
  );
};

export default FilterProfileList;
