import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import BulkReturn from 'components/Return/BulkReturn';

const BulkReturnPage = () => {
  return (
    <ContentLayout>
      <SiteContent>
        <BulkReturn />
      </SiteContent>
    </ContentLayout>
  );
};

export default BulkReturnPage;
