import React, { useState, useCallback } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { message } from 'components/common';
import {
  createWarehouseAspects,
  editWarehouseAspects,
} from 'services/warehouse';
import { FormLabel } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { WEIGHT_UNITS, DIMENSION_UNITS } from '../../constants/config';

interface WhiteListProps {
  visible: boolean;
  aspect: any;
  warehouse: WarehouseRow;
  onHide: () => void;
  onRefresh: () => void;
}
const AddEditDialog = (props: WhiteListProps) => {
  const { visible, onHide, onRefresh, aspect, warehouse } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const result = aspect
        ? await editWarehouseAspects(
            warehouse.warehouseId,
            aspect?.locationAspectsId,
            {
              ...params,
              MaxLoad: params.MaxLoad || 0,
              TareWeight: params.TareWeight || 0,
              VolumeLength: params.VolumeLength || 0,
              VolumeWidth: params.VolumeWidth || 0,
              VolumeHeight: params.VolumeHeight || 0,
              CapacityLength: params.CapacityLength || 0,
              CapacityWidth: params.CapacityWidth || 0,
              CapacityHeight: params.CapacityHeight || 0,
            }
          )
        : await createWarehouseAspects(warehouse.warehouseId, params);
      setLoading(false);
      if (!result.error) {
        form.resetFields();
        onHide();
        onRefresh();
        message.success({
          content: aspect
            ? 'Edit Location Aspect Successfully！'
            : 'Add Location Aspect Successfully！',
        });
      } else {
        message.error({ content: result.error });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCancel = useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={aspect ? 'Edit Location Aspect' : 'Add Location Aspect'}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreate}
              loading={loading}
              icon={loading ? <LoadingOutlined /> : <PlusOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="LocationAspectsName"
            initialValue={aspect?.locationAspectsName}
            label={<FormLabel>Name</FormLabel>}
            rules={[
              { required: true, message: 'Please input channel order id(s)!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="DimensionUnit"
            initialValue={aspect?.dimensionUnit || 'Inch'}
            label={<FormLabel>Dimension Unit</FormLabel>}
            rules={[
              { required: true, message: 'Please select Dimension Unit!' },
            ]}
          >
            <Select style={{ width: '100%' }}>
              {DIMENSION_UNITS.map((i) => (
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={<FormLabel>Volume Size</FormLabel>}
            rules={[{ required: true, message: '' }]}
          >
            <Input.Group compact>
              <Form.Item
                noStyle
                name="VolumeLength"
                initialValue={aspect?.volumeLength || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="volume_length_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="L"
                />
              </Form.Item>
              <Form.Item
                noStyle
                name="VolumeWidth"
                initialValue={aspect?.volumeWidth || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="volume_width_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="W"
                />
              </Form.Item>
              <Form.Item
                noStyle
                name="VolumeHeight"
                initialValue={aspect?.volumeHeight || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="volume_height_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="H"
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            label={<FormLabel>Capacity Size</FormLabel>}
            rules={[{ required: true, message: '' }]}
          >
            <Input.Group compact>
              <Form.Item
                noStyle
                name="CapacityLength"
                initialValue={aspect?.capacityLength || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="Capacity_length_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="L"
                />
              </Form.Item>
              <Form.Item
                noStyle
                name="CapacityWidth"
                initialValue={aspect?.capacityWidth || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="Capacity_width_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="W"
                />
              </Form.Item>
              <Form.Item
                noStyle
                name="CapacityHeight"
                initialValue={aspect?.capacityHeight || 0}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id="capacity_height_input"
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="H"
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="WeightUnit"
            initialValue={aspect?.weightUnit || 'Pound'}
            label={<FormLabel>Weight Unit</FormLabel>}
            rules={[{ required: true, message: 'Please select Weight Unit!' }]}
          >
            <Select style={{ width: '100%' }}>
              {WEIGHT_UNITS.map((i) => (
                <Select.Option key={i} value={i}>
                  {i}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="TareWeight"
            initialValue={aspect?.tareWeight || 0}
            label={<FormLabel>Tare Weight</FormLabel>}
            rules={[{ required: true, message: 'Please input Tare Weight!' }]}
          >
            <Input type="number" min={0} />
          </Form.Item>

          <Form.Item
            name="MaxLoad"
            initialValue={aspect?.maxLoad || 0}
            label={<FormLabel>Max Load</FormLabel>}
            rules={[{ required: true, message: 'Please input Max Load!' }]}
          >
            <Input type="number" min={0} />
          </Form.Item>

          <Form.Item
            name="Level"
            initialValue={aspect?.level}
            label={<FormLabel>Level</FormLabel>}
          >
            <Input allowClear/>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddEditDialog;
