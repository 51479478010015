/**
 * third party address service requests are here.
 */
import { digitBridgeApi } from './http';

/**
 * @param addressBookType 
 * 1 warehouse  2 supplier 3 channal accout 4 shipping account 5.drop ship third part address
 * @returns 
 */
export const getAddressList = async (addressBookType?: number): Promise<any> => {
  const params = {
    addressBookType,
  }
  return await digitBridgeApi.get('/api/GetAddressList', { params });
};

export const addAddress = async (params: AddressParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/AddAddress', json);
};

export const delAddress = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/address/${id}`);
};

export const updateAddress = async (params: AddressParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/UpdateAddress', json);
};

export const getStates = async (countryNum: number): Promise<any> => {
  const params = {
    countryNum,
  }
  return await digitBridgeApi.get('/api/GetStates', { params });
};

export const getCountrys = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/GetCountrys');
};


type AddressParams = {
  AddressNum: number;
  AddressAlias: string;
  AddressType: number;
  AddressBookType: number;
  ContactName: string;
  Company: string;
  Street1: string;
  Street2: string;
  Street3: string;
  City: string;
  County: string;
  StateNum: number;
  // State: string;
  CountryNum: number;
  // Country: string;
  PostalCode: string;
  Phone: string;
  Email: string;
  Residential: number;
  Note: string;
}