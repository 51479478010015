import React from 'react';
import ContentLayout from 'components/ContentLayout';

function Refund() {
  return (
    <ContentLayout>
      <h1>Refund</h1>
    </ContentLayout>
  );
}

export default Refund;
