/**
 * Transaction reasion related service requests are here.
 */
import { digitBridgeApi } from './http';
/**
 * Fetch  transaction reason list
 */
export const fetchTransactionReasonList = async (): Promise<
  Array<TransactionReasonRow>
> => {
  const list = (await digitBridgeApi.get('/api/transactionReasons')) as any;
  return list.map((item: any) => {
    return {
      id: item.invTransactionReasonNum,
      type: item.invTransactionReasonType,
      name: item.invTransactionReasonName,
      removeFromHolds: item.removeFromHolds,
      masterAccountNum: item.masterAccountNum,
      profileNum: item.profileNum,
      transactionReasonId: item.transactionReasonId
    };
  });
};

type CreateTransactionReason = {
  InvTransactionReasonType: number,
  InvTransactionReasonName: string,
  RemoveFromHolds?: boolean,
}
export const createTransactionReason = async (params: CreateTransactionReason): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/transactionReasons', json)
}

export const delTransactionReasonById = async (id: string): Promise<any> => {
  return await digitBridgeApi.delete(`/api/transactionReasons/${id}`);
}

export const editTransactionReason = async (id: string, params: CreateTransactionReason): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.patch(`/api/transactionReasons/${id}`, json);
}


