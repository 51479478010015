import React, { MutableRefObject, useState } from 'react';
//import ReactDataGrid from '@inovua/reactdatagrid-community';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { TypeComputedProps, TypeSortInfo } from '@inovua/reactdatagrid-enterprise/types';
import PaginationToolbar from '@inovua/reactdatagrid-community/packages/PaginationToolbar';
import '@inovua/reactdatagrid-enterprise/index.css';
import NumberFormat from 'react-number-format';
import { Typography, Button } from 'antd';
import copy from 'copy-text-to-clipboard';
//import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DATA_GRID_SORT_INDICATOR_MARGIN,
  DEFAULT_DATAGRID_ROW_NUM,
  IMAGE_HEIGHT_IN_GRID,
} from 'constants/config';
import { convertTimeByUtc } from 'utils';
import LoadingIcon from './LoadingIcon';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


const { Text } = Typography;

export enum ColumnDataType {
  CURRENCY = 1,
  DATE = 2,
  IMAGE = 3,
}

const SortIndicator = (direction: number | null) => {
  const iconStyle = {
    paddingLeft: DATA_GRID_SORT_INDICATOR_MARGIN,
    color: !direction ? '#C8C8C8' : '',
  };

  const indicatorIcon = () => {
    let icon = faSort;

    if ('number' === typeof direction) {
      if (1 === direction) {
        icon = faSortUp;
      } else if (-1 === direction) {
        icon = faSortDown;
      }
    }

    return icon as IconProp;
  };

  return (
    <span style={iconStyle} key={uuid()}>
      <FontAwesomeIcon icon={indicatorIcon()} />
    </span>
  );
};

type DataGridPlusProps = {
  checkboxOnlyRowSelect?: boolean;
  checkboxColumn?: boolean;
  columnUserSelect?: boolean;
  columns: Array<any>;
  copyableColumns?: Array<string>;
  count?: number;
  dataSource: Array<any>;
  enableSelection?: boolean;
  groups?: Array<any>;
  limit?: number;
  loading?: boolean;
  onChange?: (limit: number, skip: number) => void;
  onEditValueChange?: (edit: any) => void;
  onLimitChange?: Function;
  onReady?: (computedPropsRef: MutableRefObject<TypeComputedProps | null>) => void;
  onSelectionChange?: (data: any) => void;
  onSkipChange?: Function;
  onSortInfoChange?: (sortInfo: TypeSortInfo) => void;
  pageSizes?: number[];
  pagination?: boolean | 'local';
  renderRowContextMenu?: (menuProps: any, context: any) => void;
  rowClassName?: string;
  rowHeight?: number;
  rowKey: string;
  rowStyle?: (param: any) => void;
  selected?: boolean;
  showColumnMenuTool?: boolean;
  showHoverRows?: boolean;
  style?: any;
  virtualized?: boolean;
  showScrollButton?: boolean;
  boxStyle?: any;
};

/**
 * @deprecated please use DataGridPlus
 */
function DataGridPlus(props: DataGridPlusProps): JSX.Element {
  const {
    rowKey,
    loading,
    dataSource,
    pagination,
    onChange,
    count,
    rowClassName,
    rowStyle,
    showHoverRows,
    showScrollButton,
  } = props;
  const [gridRef, setGridRef] = useState<any>(null);
  let { columns } = props;

  columns = columns.map((column) => {
    let newColumn: StringKAnyVPair = {};
    newColumn = {
      defaultFlex: 1,
      draggable: false,
      sortable: true,
      ...column,
      header: column.header || column.title,
      name: column.name || column.dataIndex,
    };
    // customize the renderSortTool
    if (!column.renderSortTool) {
      newColumn.renderSortTool = (direction: number | null) => {
        return SortIndicator(direction);
      };
    }

    if (column.renderHeader && newColumn.sortable) {
      newColumn.renderHeader = (cellProps: any) => {
        const { computedSortInfo } = cellProps;
        let direction: number | null = null;

        if (computedSortInfo && 'object' === typeof computedSortInfo) {
          if ('number' === typeof computedSortInfo.dir) {
            direction = computedSortInfo.dir;
          }
        }

        return (
          <>
            {column.renderHeader(cellProps)}
            {SortIndicator(direction)}
          </>
        );
      };
    }

    //currency type
    if (
      column.dataType &&
      column.dataType === ColumnDataType.CURRENCY &&
      typeof column.render === 'undefined'
    ) {
      newColumn['render'] = (value: any) => {
        const { data } = value;
        const columnValue = data[newColumn.name];
        if (!columnValue) {
          return '';
        }
        return (
          <NumberFormat
            displayType="text"
            value={columnValue}
            thousandSeparator
            prefix="$"
          />
        );
      };
    } else if (
      column.dataType &&
      column.dataType === ColumnDataType.DATE &&
      typeof column.render === 'undefined'
    ) {
      //const format = column.format || 'MM/DD/YYYY hh:mm:ssA';
      const format = column.format || '';
      newColumn['render'] = (value: any) => {
        const { data } = value;
        const columnValue = data[newColumn.name];
        if (!columnValue) {
          return '';
        }
        return <Text>{convertTimeByUtc(columnValue, '', '', format)}</Text>;
      };
    } else if (
      column.dataType &&
      column.dataType === ColumnDataType.IMAGE &&
      typeof column.render === 'undefined'
    ) {
      newColumn['render'] = (value: any) => {
        const { data } = value;
        const columnValue = data[newColumn.name];
        if (columnValue) {
          return (
            <img
              src={columnValue}
              alt=""
              style={{ height: IMAGE_HEIGHT_IN_GRID }}
            />
          );
        }
        return '';
      };
    }
    return newColumn;
  });

  const { limit: oriLimit } = props;
  const [limit, setLimit] = useState(
    'number' === typeof oriLimit && oriLimit > 0
      ? oriLimit
      : DEFAULT_DATAGRID_ROW_NUM
  );
  const [skip, setSkip] = useState(0);
  const getListData = () => {
    return new Promise<any>((resolve) => {
      resolve({
        data: dataSource,
        count: count || dataSource.length,
      });
    });
  };

  const renderLoadMask = (loadProps: StringKAnyVPair) => {
    const { visible, zIndex } = loadProps;

    return visible ? (
      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex,
          background: 'rgba(21, 24, 21, 0.25)',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIcon size="large" />
      </div>
    ) : (
      ''
    );
  };

  // eslint-disable-next-line
  const renderPaginationToolbar = React.useCallback((paginationProps) => {
    const customProps = {
      ...paginationProps,
      remotePagination: false,
      pageSizes: props.pageSizes,
    };

    return <PaginationToolbar {...customProps} bordered={false} />;
  }, [props]);

  const renderRowContextMenu = (menuProps: any, context: any) => {
    const { cellProps } = context;
    const copyableColumns = props.copyableColumns ? props.copyableColumns : [];

    menuProps.autoDismiss = true;
    menuProps.items = [];

    if (copyableColumns.indexOf(cellProps.id) > -1) {
      menuProps.style = {
        ...menuProps.style,
        left: menuProps.alignTo.left,
        top: menuProps.alignTo.top,
      };
      menuProps.items.push({
        label: 'Copy',
        onClick: () => {
          const { data } = cellProps;
          const label = data[cellProps.id];

          if (label) {
            copy(label);
          }
        },
      });
    }

    if (props.renderRowContextMenu) {
      props.renderRowContextMenu(menuProps, context);
    }
  };

  let { style } = props;
  if (!(style && style.height)) {
    let height = 38; //header height:38
    if (!pagination) {
      height += (count || dataSource.length) * 41; //row height:40px,split line height:1px,pagination:41px
    } else {
      let currentPageSize = limit || 10;
      const currentCount = count || dataSource.length;
      if (currentPageSize > currentCount) {
        currentPageSize = currentCount;
      }
      height += currentPageSize * 41 + 41; //row height:40px,split line height:1px,
    }
    style = {
      zIndex: 1,
      ...style,
      height,
      maxHeight: 12 * 41,
    };
  }

  const {
    groups,
    columnUserSelect,
    rowHeight,
    checkboxColumn,
    showColumnMenuTool,
    onSelectionChange,
    checkboxOnlyRowSelect,
    onEditValueChange,
  } = props;

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        marginTop: 4,
        ...props.boxStyle,
      }}
    >
      {showScrollButton && (
        <Button
          icon={<LeftOutlined />}
          style={{
            width: 14,
            height: 'inherit',
          }}
          onClick={() => {
            if (gridRef) {
              const offset = gridRef.current.scrollLeft;
              gridRef.current.setScrollLeft(offset - 100);
            }
          }}
        />
      )}
      <ReactDataGrid
        licenseKey='AppName=multi_app,Company=DigitBridge,ExpiryDate=2022-11-11,LicenseDeveloperCount=2,LicenseType=multi_app,Ref=DigitBridgeLicenseRef,Z=1207408073-10491456647810635194481521851207408073-517868438'
        checkboxColumn={checkboxColumn || false}
        checkboxOnlyRowSelect={checkboxOnlyRowSelect ? true : false}
        columns={columns}
        columnUserSelect={columnUserSelect || false}
        dataSource={() => {
          return getListData();
        }}
        enableSelection={props.enableSelection}
        groups={groups ? groups : []}
        idProperty={rowKey}
        limit={limit}
        loading={typeof loading === 'boolean' ? loading : false}
        onEditValueChange={onEditValueChange}
        onLimitChange={(value) => {
          setLimit(value);

          if ('function' === typeof props.onLimitChange) {
            props.onLimitChange(value);
          }

          if ('function' === typeof onChange) {
            onChange(value, skip);
          }
        }}
        onReady={(ref) => {
          setGridRef(ref)
          props.onReady && props.onReady(ref)
        }}
        onSelectionChange={onSelectionChange}
        onSkipChange={(value) => {
          setSkip(value);

          if ('function' === typeof props.onSkipChange) {
            props.onSkipChange(value);
          }

          if ('function' === typeof onChange) {
            onChange(limit, value);
          }
        }}
        onSortInfoChange={props.onSortInfoChange}
        pageSizes={props.pageSizes}
        pagination={pagination === false ? false : pagination}
        renderLoadMask={renderLoadMask}
        //renderPaginationToolbar={renderPaginationToolbar}
        renderRowContextMenu={renderRowContextMenu}
        rowClassName={rowClassName}
        rowHeight={rowHeight}
        rowStyle={rowStyle}
        selected={props.selected}
        showColumnMenuTool={showColumnMenuTool || false}
        showHoverRows={typeof showHoverRows === 'boolean' ? showHoverRows : true}
        skip={skip}
        style={style}
        virtualized={props.virtualized}
        showCellBorders={false}
      />
      {showScrollButton && (
        <Button
          icon={<RightOutlined />}
          style={{
            width: 14,
            height: 'inherit',
          }}
          onClick={() => {
            if (gridRef) {
              const offset = gridRef.current.scrollLeft;
              gridRef.current.setScrollLeft(offset + 100);
            }
          }}
        />
      )}
    </div>
  );
}

DataGridPlus.defaultProps = {
  limit: undefined,
  loading: undefined,
  groups: undefined,
  columnUserSelect: undefined,
  count: undefined,
  copyableColumns: undefined,
  renderRowContextMenu: undefined,
  rowHeight: undefined,
  showColumnMenuTool: undefined,
  checkboxOnlyRowSelect: undefined,
  checkboxColumn: undefined,
  onSelectionChange: undefined,
  style: undefined,
  pagination: 'local',
  onChange: undefined,
  onEditValueChange: undefined,
  rowClassName: undefined,
  rowStyle: undefined,
  showHoverRows: undefined,
};

export default DataGridPlus;
