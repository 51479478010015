import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';

const { Option } = Select;
type Props = {
  showAll?: boolean;
  width?: number | string;
  transactionType: number;
  value?: TransactionReasonRow;
  onChange?: (reason: TransactionReasonRow) => void;
};
export default function SelectTransactionReason(props: Props): JSX.Element {
  const { onChange, transactionType, width, showAll, value } = props;
  const [data, setData] = useState<Array<TransactionReasonRow>>([]);
  const transactionReasons = useSelector(
    (state: RootState) => state.admin.transactionReasons,
    shallowEqual
  );
  useEffect(() => {
    let reasons = [];
    reasons = transactionReasons.filter((item) => {
      if (transactionType === -1) {
        return true;
      }
      if (item.type === transactionType) {
        return true;
      }
      return false;
    });
    if (showAll) {
      reasons.unshift({
        id: -1,
        type: -1,
        name: 'Any Reason',
        transactionReasonId: '',
      });
    }
    // if (reasons.length === 1 && !value) {
    //   if (typeof onChange === 'function') {
    //     onChange(reasons[0]);
    //   }
    // }
    setData(reasons);
  }, [transactionType, showAll, value, onChange, transactionReasons]);
  let extendProps = {} as any;
  if (width) {
    extendProps = {
      style: { width },
    };
  }
  if (showAll) {
    extendProps = {
      ...extendProps,
      placeholder: 'Any Reason',
    };
  }
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Select
      value={value ? value.id : undefined}
      {...extendProps}
      optionFilterProp="children"
      onChange={(selectValue: number) => {
        if (typeof onChange !== 'undefined') {
          const item = transactionReasons.find((currentItem) => {
            if (currentItem.id === selectValue) {
              return currentItem;
            }
            return null;
          });
          if (item) {
            onChange(item);
          } else {
            onChange({
              id: -1,
              type: -1,
              name: 'Any Reason',
              transactionReasonId: '',
            })
          }
        }
      }}
    >
      {data.map((item: TransactionReasonRow) => {
        return (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        );
      })}
    </Select>
  );
}
SelectTransactionReason.defaultProps = {
  showAll: false,
  width: undefined,
  value: undefined,
  onChange: undefined,
};
