import React, { useCallback, useEffect, useState } from 'react';
import { Row, Modal, Button, Space, Col } from 'antd';
import { DataGridPlus, FormLabel } from 'components/common';
import { CloseOutlined } from '@ant-design/icons';
import { getBundleDetail } from 'services/product';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  //   onRefresh: () => void;
  product: ProductRow;
}

const columns = [
  {
    name: 'componentSKU',
    header: 'Component SKU',
    defaultFlex: 1,
    minWidth: 220,
    maxWidth: 220,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentUPC',
    header: 'Component UPC',
    defaultFlex: 1,
    minWidth: 220,
    maxWidth: 220,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentQuantity',
    header: 'Component Qty',
    minWidth: 150,
    maxWidth: 150,
    userSelect: true,
    sortable: false,
  },
  {
    name: 'componentDescription',
    header: 'Component SKU Description',
    defaultFlex: 1,
    userSelect: true,
    sortable: false,
  },
];

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, product } = props;
  const [loading, setLoading] = useState(false);
  const [bundleList, setBundleList] = useState<any[]>([]);

  const getLsit = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getBundleDetail(product.productId);
      if (res) {
        setBundleList(res);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [product]);

  /* eslint-disable */
  useEffect(() => {
    getLsit();
  }, []);
  /* eslint-enable */

  return (
    <Modal
      title="Bundle"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={() => onHide()}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="edit_add_flat_modal_cancl_button"
              type="primary"
              onClick={() => onHide()}
              icon={<CloseOutlined />}
            >
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <Row>
        <Col span={12}>
          <Space direction="vertical" size={4}>
            <Space>
              <FormLabel>Bundle SKU</FormLabel>
              <span>{product?.sku}</span>
            </Space>

            <Space>
              <FormLabel>Bundle UPC</FormLabel>
              <span>{product?.upc}</span>
            </Space>

            <Space>
              <FormLabel>Bundle Description</FormLabel>
              <span>{product?.productTitle}</span>
            </Space>
          </Space>
        </Col>
      </Row>
      <DataGridPlus
        style={{ maxHeight: 300 }}
        idProperty="componentProductNum"
        columns={columns}
        dataSource={bundleList}
        loading={loading}
        pagination={false}
        {...({} as any)}
      />
    </Modal>
  );
};
