import React, { useState, useCallback } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Row, Tooltip, Popover, Typography } from 'antd';
import message from './message';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import theme from 'assets/styles/theme';

type Props = {
  value: string;
  inGrid?: boolean;
  needToolTip?: boolean;
  style?: any;
  id?: string;
};

export default function CopySpan(props: Props) {
  const { value, style, id, inGrid, needToolTip } = props;
  const [isHover, setIsHover] = useState(false);

  const mouseenter = useCallback((e: any) => {
    // e.stopPropagation();
    setIsHover(true);
  }, []);

  const mouseleave = (e: any) => {
    // e.stopPropagation();
    setIsHover(false);
  };

  return inGrid ? (
    <Row
      align="middle"
      justify="space-between"
      onMouseEnter={mouseenter}
      onMouseLeave={mouseleave}
    >
      {needToolTip ? (
        <Popover placement="topLeft" content={value}>
          <Typography.Text
            ellipsis={{ tooltip: value }}
            id={id}
            style={{ ...style, width: `calc(100% - 14px)` }}
          >
            {value}
          </Typography.Text>
        </Popover>
      ) : (
        <Typography.Text
          ellipsis={{ tooltip: value }}
          id={id}
          style={{ ...style, width: `calc(100% - 14px)` }}
        >
          {value}
        </Typography.Text>
      )}
      <CopyToClipboard
        text={value}
        options={{
          format:'text/plain'
        }}
        onCopy={() => message.info({ content: 'Copied' })}
      >
        <Tooltip placement="top" title="Copy">
          <CopyOutlined
            style={{
              visibility: isHover && value ? 'visible' : 'hidden',
              color: theme['@info-color'],
            }}
          />
        </Tooltip>
      </CopyToClipboard>
    </Row>
  ) : (
    <span onMouseEnter={mouseenter} onMouseLeave={mouseleave}>
      {needToolTip ? (
        <Popover placement="topLeft" content={value}>
          <span id={id} style={style}>
            {value}
          </span>
        </Popover>
      ) : (
        <span id={id} style={style}>
          {value}
        </span>
      )}
      <CopyToClipboard
        text={value}
        options={{
          format:'text/plain'
        }}
        onCopy={() => message.info({ content: 'Copied' })}
      >
        <Tooltip placement="top" title="Copy">
          <CopyOutlined
            style={{
              visibility: isHover && value ? 'visible' : 'hidden',
              color: theme['@info-color'],
              marginLeft: 2,
            }}
          />
        </Tooltip>
      </CopyToClipboard>
    </span>
  );
}
