import React from 'react';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';
import { fetchStoreList } from 'services/storeSetup';
import { sortByString } from 'utils';
import { useTranslation } from 'react-i18next';

type SelectorProps = {
  onChange?: Function;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
};

let channelAccounts: StringKAnyVPair[] = [];

const StoreSelector = (props: SelectorProps) => {
  const { t: trans } = useTranslation();
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [options, setOptions] = useState<StringKAnyVPair[]>([]);
  const [selectedValue, setSelectedValue] = useState<number[]>([]);
  const size = props.size || 'middle';
  const style = props.style || { width: '100%' };

  // eslint-disable-next-line
  const getChannelAccounts = async () => {
    if (channelAccounts.length > 0) {
      setIsLoadingOptions(false);
    } else {
      await getServices();
      setIsLoadingOptions(false);
    }

    setOptions(channelAccounts);
  };

  const getServices = async () => {
    try {
      const res = await fetchStoreList();
      //console.log('accounts', services);
      if (res.isSuccess) {
        channelAccounts = res.data
          .filter((i: any) => i.status === 1)
          .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'));
      }
    } catch (e) {
      message.error(`Fetch channel accounts error: ${e}`);
    }
  };

  const onSelectionChange = (value: any) => {
    setSelectedValue(value);

    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  };

  const selectOptions = (options: StringKAnyVPair[]) => {
    return (
      <>
        {options.map((item) => (
          <Select.Option
            key={item.channelAccountNum}
            value={item.channelAccountNum}
            label={item.channelAccountName}
          >
            {item.channelAccountName}
          </Select.Option>
        ))}
      </>
    );
  };

  React.useEffect(() => {
    if (!inited) {
      setIsLoadingOptions(true);
      getChannelAccounts();
      setInited(true);
    }
  }, [getChannelAccounts, inited]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          //dropdownClassName="scan-ship-select-dropdown-option-container"
          mode="multiple"
          onChange={onSelectionChange}
          optionFilterProp="label"
          placeholder={trans('scanToShip.batchPrint.storeSelectorPlaceholder')}
          showSearch
          size={size}
          style={style}
          value={selectedValue}
        >
          {selectOptions(options)}
        </Select>
      )}
    </Row>
  );
};

export default StoreSelector;
