import React, { useState, useCallback } from 'react';
import {
  Form,
  Row,
  Modal,
  Button,
  Space,
  Typography,
  List,
  Radio,
  DatePicker,
} from 'antd';
import { message, ScanningGunInput } from 'components/common';
import { syncProductToERP, getSyncList } from 'services/product';
import { CloseOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;
const { RangePicker } = DatePicker;

interface AddProps {
  visible: boolean;
  onHide: () => void;
  selectedRow: ProductRow[];
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, selectedRow } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [skus, setSkus] = useState<string[]>(
    selectedRow.map((i: any) => i.sku)
  );
  const [skuLoading, setSkuLoading] = useState(false);
  const [loadSkus, setLoadSkus] = useState<string>('');

  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    if (skus.length === 0) return;
    const { syncProductUpdateExist, syncProductDimension, syncProductWeight } =
      form.getFieldsValue();
    try {
      setSubmitLoading(true);
      const res = await syncProductToERP(
        skus,
        syncProductUpdateExist,
        syncProductDimension,
        syncProductWeight
      );
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Sync successfully' });
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  }, [skus, onHide, form]);

  const getSkusFromApi = useCallback(async () => {
    setSkuLoading(true);
    try {
      const formValue = await form.validateFields();
      if (formValue.errorFields) return;
      const { rangeDate } = formValue;
      const createDate = `${moment(rangeDate[0]).format('YYYY-MM-DD')}@${moment(
        rangeDate[1]
      ).format('YYYY-MM-DD')}`;
      const res = await getSyncList(createDate);
      if (res.isSuccess) {
        if (res.data.length > 0) {
          const str = res.data.map((i: any) => i.sku).join('\n');
          setLoadSkus(str);
        } else {
          message.info('No records found in this time range.')
        }
      }
      setSkuLoading(false);
    } catch (error) {
      setSkuLoading(false);
    }
  }, [form]);

  const handleCheck = async () => {
    const temp = form.getFieldValue('skus');
    if (!temp) return message.info('Please input SKUs!');
    const items: string[] = Array.from(
      new Set(temp.split('\n').map((i: string) => i.trim()))
    );
    if (items.length > 150) {
      return message.error('The number of sku exceeds the limit of 150')
    }
    setSkus(items.filter(item => item));
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      title="Sync Products"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            {skus.length > 0 ? (
              <Button
                id="sync_product_sync_button"
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={submitLoading}
                icon={<SaveOutlined />}
              >
                {submitLoading ? 'Syncing' : 'Sync'}
              </Button>
            ) : (
              <Button
                id="sync_product_continue_button"
                key="submit"
                type="primary"
                disabled={skuLoading}
                onClick={handleCheck}
                icon={<RightOutlined />}
              >
                Continue
              </Button>
            )}
            <Button
              id="sync_product_cancl_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form} style={{ marginTop: 10 }}>
        {skus.length > 0 ? (
          <>
            <List
              size="small"
              style={{ height: 300, overflow: 'auto' }}
              bordered
              dataSource={skus}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
            <Form.Item
              labelCol={{ span: 12 }}
              name="syncProductUpdateExist"
              label="Update existing products"
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 12 }}
              name="syncProductDimension"
              label="Sync Product Dimension information"
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 12 }}
              name="syncProductWeight"
              label="Sync Product Weight information"
              initialValue={false}
            >
              <Radio.Group>
                <Radio value={false}>No</Radio>
                <Radio value={true}>Yes</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        ) : (
          <>
            <Row>
              <Text>
                You can choose to enter the sku list manually, or you can choose
                to get the sku list from the api quickly
              </Text>
            </Row>
            <Row>
              <Form.Item
                name="rangeDate"
                rules={[
                  { required: true, message: 'please selete range date' },
                ]}
              >
                <RangePicker />
              </Form.Item>
              <Button
                style={{ marginLeft: 8 }}
                loading={skuLoading}
                onClick={() => getSkusFromApi()}
              >
                Fetch from ERP
              </Button>
            </Row>
            <Form.Item
              name="skus"
              label=""
            // rules={[{ required: true, message: 'Please input SKUs!' }]}
            >
              <ScanningGunInput
                rows={10}
                placeholder="Up to 150 skus are supported"
                loading={skuLoading}
                defaultValue={loadSkus}
                onChange={(value) => {
                  form.setFieldsValue({ skus: value });
                  setLoadSkus('');
                }}
              />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
