import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ShippingPriority from 'components/ShipmentSettings/ShippingPriority';

const Priority = () => {
  return (
    <ContentLayout>
      <SiteContent>
        <ShippingPriority />
      </SiteContent>
    </ContentLayout>
  );
};

export default Priority;
