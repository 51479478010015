import React, { useCallback, useState } from 'react';
import { Select } from 'antd';
import { LoadingIcon } from 'components/common';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { getWarehouseIdFromCache } from 'utils';
//import { test } from 'services/warehouse';

/**
 * Type definition for the component.
 *
 * @param {?Array<number>} exclude - excluded location list
 * @param {number} warehouseNum - note: -1 means no warehouse is specified
 * @param {?Function} onChange - the callback when select an option or input value change
 * @param {?Function} onChangeOption - the callback when select an options or input change
 * @param {'large' | 'middle' | 'small'} size - the size of select box
 * @param {?boolean} selectAll - whether can select all locations
 * @param {?number} - value - the value of the select box
 */
type Props = {
  warehouseNum: number;
  excludedLocationList?: Array<number>;
  onChange?: Function;
  onChangeOption?: Function;
  selectAll?: boolean;
  size?: 'large' | 'middle' | 'small';
  value?: number;
  showPick?: boolean;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {
    warehouseNum,
    excludedLocationList,
    onChange,
    onChangeOption,
    selectAll,
    showPick,
  } = props;
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [locationOptions, setLocationOptions] = useState<
    WarehouseLocationOption[]
  >([]);
  const [lastWarehouseId, setLastWarehouseId] = useState(-1);
  const [selectedValue, setSelectedValue] = useState<any>(
    'number' === typeof props.value && props.value > -1 ? props.value : null
  );
  const selectboxSize = props.size ? props.size : 'middle';
  const placeholderForLocation = 'Search to select location';
  const placeholderForWarehouse = 'Choose a warehouse at first';
  const warehouseId = getWarehouseIdFromCache(warehouseNum);
  //const [isTest, setIsTest] = React.useState(true);

  /**
   * Fetch the location options for selecting warehouse.
   *
   * @param {number} id - the warehouse id
   * @param {?string} code - the search code of location
   */
  const fetchLocationOptions = React.useCallback(
    async (id: string, code?: string) => {
      try {
        const list = await fetchWarehouseLocationList(
          id,
          showPick ? true : false,
          code
        );

        if (list && Array.isArray(list)) {
          let options = list.map((e) => {
            return {
              id: e.locationNum,
              code: e.locationCode,
              strId: e.locationId,
            };
          });

          if (selectAll) {
            options = [
              {
                id: 0,
                code: 'All',
                strId: '',
              },
              ...options,
            ];
          }

          if (excludedLocationList && Array.isArray(excludedLocationList)) {
            for (let i = options.length; i > 0; i--) {
              if (excludedLocationList.indexOf(options[i - 1].id) > -1) {
                options.splice(i - 1, 1);
              }
            }
          }

          setIsLoadingLocations(false);
          setLastWarehouseId(warehouseNum);
          setLocationOptions(options);
        }
      } catch (e) {
        setIsLoadingLocations(false);
        console.log('fetch location options error:', e);
      }
    },
    [excludedLocationList, selectAll, warehouseNum, showPick]
  );

  /**
   * Send the option to outer caller.
   */
  const onOptionChange = useCallback(
    (value: any) => {
      let option;

      setSelectedValue(value);

      if (onChange && 'function' === typeof onChange) {
        onChange(value);
      }

      for (let i = 0; i < locationOptions.length; i++) {
        if (locationOptions[i].id === value) {
          option = locationOptions[i];
          break;
        }
      }

      if (option) {
        if (onChangeOption && 'function' === typeof onChangeOption) {
          onChangeOption(option);
        }
      }
    },
    [locationOptions, onChange, onChangeOption]
  );

  React.useEffect(() => {
    /*
    if (isTest) {
      test();
      setIsTest(false);
    }
    */
    const { value } = props;

    if (warehouseNum > -1 && warehouseNum !== lastWarehouseId) {
      if (!isLoadingLocations) {
        console.log('fetch ...', warehouseNum, lastWarehouseId);
        //console.log('->', typeof warehouseNum, getWarehouseIdFromCache(warehouseNum));
        setIsLoadingLocations(true);
        setLastWarehouseId(warehouseNum);
        fetchLocationOptions(warehouseId);
      }
    }

    if ('number' === typeof value && value !== selectedValue) {
      console.log('set value', value);
      if (value > -1) {
        onOptionChange(value);
      } else {
        setSelectedValue(null);
      }
    }
  }, [
    fetchLocationOptions,
    isLoadingLocations,
    lastWarehouseId,
    onOptionChange,
    props,
    selectedValue,
    warehouseNum,
    warehouseId,
  ]);

  return (
    <>
      {isLoadingLocations ? (
        <LoadingIcon />
      ) : (
        <Select
          allowClear
          disabled={warehouseNum < 0}
          placeholder={
            warehouseNum > -1 ? placeholderForLocation : placeholderForWarehouse
          }
          showSearch
          size={selectboxSize}
          style={{ width: '100%' }}
          value={selectedValue}
          filterOption={(input, option) => {
            //console.log(input, option);
            /*return (
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );*/
            if (option) {
              const { children } = option;

              if (typeof children === 'string') {
                return (
                  (children as string)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
            }

            return false;
          }}
          onChange={onOptionChange}
        >
          {locationOptions.map((e) => (
            <Select.Option key={e.id} value={e.id}>
              {e.code}
            </Select.Option>
          ))}
        </Select>
      )}
    </>
  );
};
