import { HTTP_STATUS_OK } from 'constants/config';
import { validateHttpStatus } from 'utils';
import { digitBridgeApi } from './http';

export const logout = async ():Promise<any> => {
  const response = await digitBridgeApi.post('/api/logout', null, { validateStatus: (status) => validateHttpStatus([400, 401], status) });
  return response;
};

export const fetchProfileSettings = async (): Promise<any> => {
  const { code, data, isSuccess } = await digitBridgeApi.get('/api/GetProfileSettings') as StringKAnyVPair;

  if (code === HTTP_STATUS_OK && isSuccess) {
    return data;
  }
};

export const fetchPermissions = async (): Promise<any> => {
  const { code, data, isSuccess } = await digitBridgeApi.post('/api/Permissions') as StringKAnyVPair;

  if (code === HTTP_STATUS_OK && isSuccess) {
    return data;
  }
};

export const fetchDatabaseNum = async():Promise<any> => {
  const { code, data, isSuccess } = await digitBridgeApi.get('/api/databaseNum') as StringKAnyVPair;

  if (code === HTTP_STATUS_OK && isSuccess) {
    return data;
  }
};
