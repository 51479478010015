/**
 *import | export center service requests are here.
 */
import { digitBridgeApi } from './http';

/**
 * filejob List
 */
type FileJobParams = {
  fileoperation?: number;
  operatetype: number;
  $skip: number;
  $top: number;
  $sortBy: string;
  $count?: boolean;
  $filter?: string;
}
export const fetchExportFileJobs = async (params: FileJobParams): Promise<any> => {
  return await digitBridgeApi.get('/api/files/export/page', { params });
}

export const fetchImportFileJobs = async (params: FileJobParams): Promise<any> => {
  return await digitBridgeApi.get('/api/files/import/page', { params });
}

export const fetchFileJobs = async (params: FileJobParams): Promise<any> => {
  return await digitBridgeApi.get('/api/files/page', { params });
}
/**
 * import file
 */
export const importFile = async (formData: FormData): Promise<any> => {
  return await digitBridgeApi.post('/api/files/upload', formData)
}

/**
 * export file
 */
type ExportParams = {
  fileoperation: number;
  filesystemname: string;
}
export const exportFile = async (params: ExportParams): Promise<any> => {
  return await digitBridgeApi.get('/api/files/export', { params, responseType: 'blob' })
}

export const downloadExportFile = async (params: ExportParams): Promise<any> => {
  return await digitBridgeApi.get('/api/files/download/export', { params, responseType: 'blob' })
}

/**
 * fetch operation setting
 */
export const fetchOperation = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/files/ListOperation')
}

/**
 * download error log
 */
type DownlaodErrorLogParams = {
  fileNum: number;
}
export const downloadErrorLog = async (params: DownlaodErrorLogParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/files/DownloadFileErrorLogForCustomer/${params.fileNum}`, { responseType: 'blob' })
}

// type downloadLoactionParams = {
//   Operation: number;
//   SelectField: string;
//   OrderByField: string;
//   FilterCondition: any;
// }
// export const exportLocation = async (params: downloadLoactionParams): Promise<any> => {
//   const json = JSON.stringify(params)
//   return await digitBridgeApi.post('/api/files/export/location', json)
// }

/**
 * download template
 */
export const downloadTemplate = async (operationId: number, suffix?: string): Promise<any> => {
  const params = {
    suffix
  }
  return await digitBridgeApi.get(`/api/files/Template/Download/${operationId}`, { params, responseType: 'blob' })
}

/**
 * upload image
 */
export const uploadImage = async (formData: FormData): Promise<any> => {
  return await digitBridgeApi.post('/api/files/upload/image', formData)
}

/**
 * check order right
 */
export const checkOrderFormatRight = async (id: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/channelAccounts/orderright/${id}`)
}

/**
 * export business file
 */
type ExportBusinessFileParams = {
  FilterCondition: any,
  SelectField?: any,
  OrderByField?: string,
  GroupByField?: string,
  timeZoneOffset?: string;
}
export const exportBusinessFile = async (businessType: number, params: ExportBusinessFileParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post(`/api/files/export/${businessType}`, json)
}

export const exportChannelAccount = async (params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/files/export/channelAccount', json)
}