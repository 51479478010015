import { Row, Space } from 'antd';
import React from 'react';
import StatisticsItem from './StatisticsItem';

type StatisticsPanelProps = {
  entity: OrderEntity;
};
/**
 * Return StatisticsPanel
 * @param {StatisticsPanelProps} props
 * @returns {JSX.Element}
 */
export default function StatisticsPanel(
  props: StatisticsPanelProps
): JSX.Element {
  const { entity } = props;
  return (
    <>
      <Row justify="center">
        <Space size={15}>
          <StatisticsItem title="Total" value={entity.count} />
          <StatisticsItem
            title="Total Unique SKUs"
            value={entity.totalUniqueSkus}
          />
          <StatisticsItem title="Total Quantity" value={entity.totalQuantity} />
        </Space>
      </Row>
    </>
  );
}
