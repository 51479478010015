import React from 'react';
//import { useDispatch } from 'react-redux';
//import { Dispatch } from 'redux';
//import { Button, Row, Space } from 'antd';
//import {HeaderTitle} from 'components/common';
import { /*GreyCardWrapper,*/ SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import WarehouseList from 'components/Warehouse/WarehouseList';
//import { createWarehouse } from '../actions/adminActions';

function Warehouse() {
  /*
  const dispatch: Dispatch<any> = useDispatch();
  const createWarehouseEntity = React.useCallback(
    () => dispatch(createWarehouse(true)),
    [dispatch]
  );*/

  return (
    <ContentLayout>
      <SiteContent>
        {/*<h1>Warehouse</h1>*/}

        <WarehouseList />
      </SiteContent>
    </ContentLayout>
  );
}

export default Warehouse;
