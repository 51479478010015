import React, { useCallback } from 'react';
import { Label } from 'components/common/styles';
import ManageShipment from './ManageShipment';
import { ModalDialog } from 'components/common';

type Props = {
  onClose: Function;
  orderId: string;
  resetOrderShipment?: Function;
  selectedShipmentId?: String;
  //setModified: Function;
  visible: boolean;
  warehouseNum: number;
};

const ManageShipmentDialog = (props: Props) => {
  const { useState } = React;
  const [focusedShipment, setFocusedShipment] = useState<StringKAnyVPair>({});
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);

  const refreshBtnRef = React.useRef<any>(null);

  const onCancel = () => {
    props.onClose();

    if (typeof props.resetOrderShipment === 'function') {
      props.resetOrderShipment(focusedShipment.shipmentNum || 0);
    }
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      const W = window.innerWidth;

      return isFullscreen
        ? W - 4
        : W > 1280
          ? W * (W > 1440 ? 0.8 : 0.92)
          : 1280;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  return (
    <ModalDialog
      centered
      destroyOnClose
      className="fullscreen-modal"
      closable={false}
      closeButton={true}
      footer={null}
      fullscreen={true}
      maskClosable={false}
      onClose={onCancel}
      onFullscreen={onFullscreen}
      title={<Label>Manage Shipments (Sale Order: <span className="label-primary">{props.orderId}</span>)</Label>}
      visible={props.visible}
      width={detailDialogWidth()}
    >
      <ManageShipment
        orderId={props.orderId}
        selectedShipmentId={props.selectedShipmentId}
        setModified={() => 0}
        setFocusedShipment={setFocusedShipment}
        warehouseNum={props.warehouseNum}
      />
    </ModalDialog>
  );
};

export default ManageShipmentDialog;
