import { SaveOutlined } from "@ant-design/icons";
import { Card, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Spin, Switch, Typography, message } from "antd";
import { Button, CardWrapper, FormLabel, GreyCardWrapper, HeaderTitle } from "components/common";
import React, { useEffect, useState } from "react";
import { fetchLOTSettings, postLOTSettings } from "services/lotSetting";
import styled from "styled-components";

const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    height: 0;
  }
`

const LOTRadio = styled(Radio)`
  width: 100%;
  margin-bottom: 10px;
`

const LOTSetting = () => {
  const [spinning, setSpinning] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(true);
  const [ruleValue, setRuleValue] = useState<number>(0);
  const [controlTotalLength, setControlTotalLength] = useState<boolean>(true);
  const [prefix, setPrefix] = useState<string>('');
  const [prefixSeparator, setPrefixSeparator] = useState<string>('');
  const [dateFormat, setDateFormat] = useState<string>('');
  const [timeFormat, setTimeFormat] = useState<string>('');
  const [suffixSeparator, setSuffixSeparator] = useState<string>('');
  const [suffix, setSuffix] = useState<string>('');
  const [appendTime, setAppendTime] = useState<boolean>(false);


  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const getLOTSettings = async () => {
    setSpinning(true);
    setSaveLoading(true);
    try {
      const { data, isSuccess, message: msg } = await fetchLOTSettings();
      if (isSuccess) {
        form.setFieldsValue(data);
        setRuleValue(data.rule);
        setPrefix(data.prefix);
        setPrefixSeparator(data.prefixSeparator);
        setDateFormat(data.dateFormat);
        setTimeFormat(data.timeFormat);
        setSuffixSeparator(data.suffixSeparator);
        setSuffix(data.suffix);
        setAppendTime(data.appendTime === 1 ? true : false);
        setControlTotalLength(data.totalLength === 0 ? false : true);
      } else {
        message.error(msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
      setSaveLoading(false);
    }
  }

  const handleSave = async () => {
    const values = await form.validateFields();
    if (values.errorFields) return;
    setSaveLoading(true);
    try {
      const queryParams = {
        ...values,
        appendTime: values.appendTime ? 1 : 0,
        totalLength: controlTotalLength ? values.totalLength : 0,
      }
      const { isSuccess, message: msg } = await postLOTSettings(queryParams);
      if (isSuccess) {
        message.success('Save LOT settings sucessfully!');
        setTimeout(() => {
          getLOTSettings();
        }, 1000);
      } else {
        message.error(msg);
      }
    } catch (error) {

    } finally {
      setSaveLoading(false);
    }
  }

  useEffect(() => {
    getLOTSettings();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'LOT Settings']}>
        <Button
          type="primary"
          loading={saveLoading}
          onClick={() => handleSave()}
          icon={<SaveOutlined />}
        >
          Save Settings
        </Button>
      </HeaderTitle>
      <GreyCardWrapper>
        <CardWrapper>
          <Spin spinning={spinning} >
            <Form form={form} {...layout}>
              <FormItem name="unique" label={<FormLabel>LOT Number Uniqueness</FormLabel>}>
                <Radio.Group>
                  <LOTRadio value={0}>No Uniqueness Control (different SKU can use the same LOT Number)</LOTRadio>
                  <LOTRadio value={1}>Unique across products (different SKU cannot use the same LOT Number)</LOTRadio>
                </Radio.Group>
              </FormItem>
              <FormItem name="generation" label={<FormLabel>LOT Number Generation</FormLabel>}>
                <Radio.Group>
                  <LOTRadio value={0}>User Inputs and System Generates</LOTRadio>
                  <LOTRadio value={1}>System Generates Only (User cannot input LOT Number manually)</LOTRadio>
                  <LOTRadio value={2}>User Inputs Only (System will not generate LOT Number)</LOTRadio>
                </Radio.Group>
              </FormItem>
              <FormItem name="rule" label={<FormLabel>Default LOT Number Rule</FormLabel>}>
                <Radio.Group value={ruleValue} onChange={e => setRuleValue(e.target.value)}>
                  <LOTRadio value={0}>By Sequence</LOTRadio>
                  <LOTRadio value={1}>By Date</LOTRadio>
                </Radio.Group>
              </FormItem>
              {ruleValue === 0 && (
                <Card>
                  <Form.Item name="prefix" label={<FormLabel>Prefix</FormLabel>}>
                    <Input style={{ width: 250 }} />
                  </Form.Item>
                  <Form.Item name="startNumber" label={<FormLabel>Start Number</FormLabel>}>
                    <Input style={{ width: 250 }} />
                  </Form.Item>
                  <Form.Item name="suffix" label={<FormLabel>Suffix</FormLabel>}>
                    <Input style={{ width: 250 }} />
                  </Form.Item>
                  <Form.Item name="" label={<FormLabel>Control Total Length</FormLabel>}>
                    <Switch
                      checked={controlTotalLength}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={e => setControlTotalLength(e)}
                    />
                  </Form.Item>
                  {controlTotalLength && (
                    <>
                      <Form.Item name="totalLength" label={<FormLabel>Total Length</FormLabel>}>
                        <InputNumber
                          style={{ width: 250 }}
                          step={1}
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item name="paddingZero" label={<FormLabel>Zero Padding</FormLabel>}>
                        <Radio.Group>
                          <Radio value={0}>Between Prefix and Date</Radio>
                          <Radio value={1}>Between date and Suffix</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}
                </Card>
              )}
              {ruleValue === 1 && (
                <Card>
                  <Row wrap={false}>
                    <Col span={6}>
                      <div style={{ textAlign: 'right', marginBottom: 10, marginRight: 10 }}><FormLabel>Format</FormLabel></div>
                    </Col>
                    <Col span={14}>
                      <Typography.Title level={5}>
                        {`${prefix}${prefixSeparator}${dateFormat}${appendTime ? timeFormat : ''}${suffixSeparator}${suffix}`}
                      </Typography.Title>
                    </Col>
                  </Row>
                  <Form.Item
                    name="prefix"
                    label={<FormLabel>Prefix</FormLabel>}
                    rules={[{ required: true, message: 'please input Prefix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={prefix}
                      onChange={e => setPrefix(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="prefixSeparator"
                    label={<FormLabel>Prefix Separator</FormLabel>}
                    rules={[{ required: true, message: 'please input Prefix Separator' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={prefixSeparator}
                      onChange={e => setPrefixSeparator(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="dateFormat"
                    label={<FormLabel>Date Format</FormLabel>}
                    rules={[{ required: true, message: 'please select Date Format' }]}
                  >
                    <Radio.Group value={dateFormat} onChange={e => setDateFormat(e.target.value)}>
                      <Radio value="YYYYMMDD">YYYYMMDD</Radio>
                      <Radio value="YYMMDD">YYMMDD</Radio>
                      <Radio value="YYDDD">YYDDD</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="appendTime" label={<FormLabel noColon> </FormLabel>} valuePropName="checked">
                    <Checkbox checked={appendTime} onChange={e => setAppendTime(e.target.checked)}>Append Time</Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="timeFormat"
                    label={<FormLabel noColon> </FormLabel>}
                    rules={[{ required: appendTime, message: 'please select Time Format' }]}
                  >
                    <Radio.Group value={timeFormat} onChange={e => setTimeFormat(e.target.value)}>
                      <Radio value="hhmm">hhmm</Radio>
                      <Radio value="hhmmss">hhmmss</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="suffixSeparator"
                    label={<FormLabel>Suffix Separator</FormLabel>}
                    rules={[{ required: true, message: 'please input Suffix Separator' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={suffixSeparator}
                      onChange={e => setSuffixSeparator(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="suffix"
                    label={<FormLabel>Suffix</FormLabel>}
                    rules={[{ required: true, message: 'please input Suffix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={suffix}
                      onChange={e => setSuffix(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item name="" label={<FormLabel>Control Total Length</FormLabel>}>
                    <Switch
                      checked={controlTotalLength}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={e => setControlTotalLength(e)}
                    />
                  </Form.Item>
                  {controlTotalLength && (
                    <>
                      <Form.Item
                        name="totalLength"
                        label={<FormLabel>Total Length</FormLabel>}
                        rules={[{ required: controlTotalLength, message: 'please input Total Length' }]}
                      >
                        <InputNumber
                          style={{ width: 250 }}
                          step={1}
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item name="paddingZero" label={<FormLabel>Zero Padding</FormLabel>}>
                        <Radio.Group>
                          <Radio value={0}>Between Prefix and Date</Radio>
                          <Radio value={1}>Between date and Suffix</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}
                </Card>
              )}
              {/* <Form.Item name="unpackOverPercentage" label={<FormLabel>Over Unpack Percentage</FormLabel>}>
                <InputNumber
                  step={0.01}
                  min={0}
                  max={100}
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item name="enableCSN" valuePropName="checked" label={<FormLabel>Enable Case Serial Number(CS#)</FormLabel>}>
                <Switch
                  checked={enableCSN}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={e => setEnableCSN(e)}
                />
              </Form.Item>
              {enableCSN && (
                <Card >
                  <Typography.Title level={5}>Case Serial Number Format</Typography.Title>
                  <Form.Item name="csnTotalLength" label={<FormLabel>Total Length</FormLabel>}>
                    <InputNumber
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnTotalLength', e)}
                    />
                  </Form.Item>
                  <Form.Item name="csnPrefix" label={<FormLabel>Prefix</FormLabel>}>
                    <Input
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnPrefix', e.target.value)}
                      maxLength={csnFormat.csnTotalLength - `${csnFormat.csnNumber}`.length - csnFormat.csnSuffix.length}
                    />
                  </Form.Item>
                  <Form.Item name="csnNumber" label={<FormLabel>Starting Number</FormLabel>}>
                    <InputNumber
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnNumber', e)}
                      maxLength={csnFormat.csnTotalLength - csnFormat.csnPrefix.length - csnFormat.csnSuffix.length}
                    />
                  </Form.Item>
                  <Form.Item name="csnSuffix" label={<FormLabel>Suffix</FormLabel>}>
                    <Input
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnSuffix', e.target.value)}
                      maxLength={csnFormat.csnTotalLength - `${csnFormat.csnNumber}`.length - csnFormat.csnPrefix.length}
                    />
                  </Form.Item>
                </Card>
              )}
              {enableCSN && (
                <Card style={{ marginTop: 8 }} >
                  <Typography.Title level={5}>Case Serial Number Enforcement</Typography.Title>
                  <Form.Item valuePropName="checked" name="packCSNRequired" label={<FormLabel>All Pre-Pack Must have CS#</FormLabel>}>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                </Card>
              )} */}
            </Form>
          </Spin>
        </CardWrapper>
      </GreyCardWrapper>
    </>
  )
}

export default LOTSetting;