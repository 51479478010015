import React from 'react';
import { Modal, Row, Typography, Space } from 'antd';
import { APP_VERSION } from 'constants/config';
import { fetchSiteConfig } from 'utils';
import { GreyCardWrapper } from './styles';

const { Title } = Typography;

interface VersionModalProps {
  show: boolean;
  onClose: Function;
}

export default function VersionModal(props: VersionModalProps): JSX.Element {
  const { show, onClose } = props as any;
  const siteCfg = fetchSiteConfig();

  return (
    <Modal
      centered
      visible={show}
      onCancel={onClose}
      maskClosable={true}
      closable={false}
      destroyOnClose={true}
      footer={null}
    >
      <GreyCardWrapper>
        <Row justify='center'>
            <div style={{ padding: 20 }} >
              <Space align='start'><img width={30} height={30} alt="Company Logo" src={siteCfg.logo} />
<Title level={3}>{siteCfg.name}</Title></Space>
              <Title level={5}>{`Version ${APP_VERSION}`}</Title>
            </div>
        </Row>
      </GreyCardWrapper>
    </Modal >
  )
}
