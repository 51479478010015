import styled from 'styled-components';
import { Col, Tabs } from 'antd';
import themes from 'assets/styles/theme';

export const CenterBox = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  font-weight: 400;
`;

export const SearchListGridWrapper = styled(Col)`
  & .InovuaReactDataGrid {
    height: 100% !important;
    min-height: 300px !important;
  }

  & .grid-ctn {
    height: calc(100% - 180px);
  }

  & .grid-ctn > div {
    height: 100%;
  }

  & .search-list-empty-space {
    height: calc(100% - 132px);
  }
`;

export const WarehouseLabel = styled.div`
  width: 100%;
  padding-right: 10px;
  text-align: right;

  & .ant-select-selection-item {
    text-align: left;
  }
`;

export const UnderlineButton = styled.div`
  display: inline-block;  
  color: #1976d2;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  padding: 0px 15px;

  &:hover {
    cursor: pointer;
  }
`
type ArrowButtonProps = {
  type?: string;
};
export const ArrowButton = styled.div<ArrowButtonProps>`
  display: inline-block;  
  background-color: ${(props) => props.type === 'primary' ? themes['@primary-color'] : themes['@info-color']};
  width: 90px;	
  height: 20px;	
  line-height: 20px;
  position: relative;
  border-radius: 3px 0 0 3px;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  padding: 0px 8px;

  &:hover {
    cursor: pointer;
  }

  &:after {
    content: "";	
    position: absolute;	
    width: 14px;	
    height: 14px;	
    top: 3px;	
    left: 83px;	
    z-index: 1;	
    background-color: ${(props) => props.type === 'primary' ? themes['@primary-color'] : themes['@info-color']};
    transform: rotate(45deg);
    border-radius: 0px 2px;
  }
`

export const DarkTabs = styled(Tabs)`
  color: #555e68 !important;
  font-size: 1.2em !important;

  & .ant-tabs-tab {
    background-color: rgba(206, 239, 218, 0.3) !important;
  }

  & .ant-tabs-tab-active {
    background-color: #9cc5a1 !important;
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #555e68 !important;
  }
`