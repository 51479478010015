import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Modal, Space, Typography } from 'antd';
import { playAudio } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import FinishDialog from './finish';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartText,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import {
  fetchOrderShipmentByTrackingNumber,
  postSmartVerify,
} from 'services/sales';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  // getWarehouseIdFromCache,
  getWarehouseCodeFromCache,
} from 'utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const columns = [
  {
    title: 'Item',
    dataIndex: 'sku',
    key: 'sku',
    render: (text: string) => {
      return <Text style={{ fontSize: 14, fontWeight: 700 }}>{text}</Text>;
    },
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    key: 'qty',
    render: (text: string) => {
      return <Text style={{ fontSize: 14, fontWeight: 700 }}>{text}</Text>;
    },
  },
  {
    title: 'Confirmed Qty',
    dataIndex: 'confirmQty',
    key: 'confirmQty',
  },
];

type Item = {
  sku: string;
  upc?: string;
  ean?: string;
  qty: number;
  confirmQty: number;
};

export default function ConfirmByTrackingNumber(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanTracking' | 'scanItem'>('scanTracking');
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);

  // const [trackingNumber, setTrackingNumber] = useState<string>('');

  const [warehouseNum, setWarehouseNum] = useState<number>();
  const [dataInfo, setDataInfo] = useState<OrderShipment[]>([]);
  const [items, setItems] = useState<Item[]>([]);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [confirmQty, setConfirmQty] = useState<number>(0);

  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const getShipmentInfo = useCallback(
    async (trackingNumber: string) => {
      setTotalQty(0);
      setConfirmQty(0);
      setItems([]);
      if (warehouseNum && trackingNumber) {
        try {
          setFetching(true);
          const res = await fetchOrderShipmentByTrackingNumber(
            warehouseNum,
            trackingNumber
          );
          setFetching(false);
          if (res && res.length > 0) {
            setDataInfo(res);
            if (res[0].items.length > 0) {
              let total = 0;
              const temp = res[0].items.map((item: any) => {
                total += item.shippingQty;
                return {
                  sku: item.sku,
                  upc: item.upc,
                  ean: item.ean,
                  qty: item.shippingQty,
                  confirmQty: 0,
                };
              });
              setTotalQty(total);
              setItems(temp);
            }
            playAudio('success');
            setStep('scanItem');
          } else {
            playAudio('error');
            setStep('scanTracking');
            Modal.warning({
              title: `Tracking# not found`,
              content: `Tracking#: ${trackingNumber}`,
            });
            // message.info({
            //   content: `Tracking# [${trackingNumber}] not found`,
            // });
          }
        } catch (error) {
          setFetching(false);
        }
      }
    },
    [warehouseNum]
  );

  const handleConfirm = useCallback(async () => {
    try {
      setFetching(true);
      const res = await postSmartVerify(dataInfo[0].shipmentNum);
      setFetching(false);
      if (res.isSuccess) {
        setFinishDialogVisible(true);
      }
    } catch (error) {
      setFetching(false);
      console.log(error);
    }
  }, [dataInfo]);

  const checkQty = useCallback(
    (itemValue: string) => {
      if (itemValue) {
        const temp = items.filter(
          (item) =>
            item.sku === itemValue ||
            item.upc === itemValue ||
            item.ean === itemValue
        );
        if (temp.length === 0) {
          playAudio('error');
          Modal.warning({
            title: `Err Item [${itemValue}]`,
          });
          return;
        }
        if (temp[0].qty > temp[0].confirmQty) {
          if (totalQty - confirmQty === 1) {
            handleConfirm();
          } else {
            let data = [...items];
            // setItems([]);
            const index = data.findIndex(
              (i) =>
                i.sku === itemValue ||
                i.upc === itemValue ||
                i.ean === itemValue
            );
            data[index].confirmQty = data[index].confirmQty + 1;
            setConfirmQty((prev) => prev + 1);
            setItems([...data]);
            playAudio('success');
          }
        } else {
          playAudio('error');
          return message.info({
            content: `item [${itemValue}] scanned exceeds the limit`,
          });
        }
      }
    },
    [items, totalQty, confirmQty, handleConfirm]
  );

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
    }
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanTracking' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              
              {t("smart.confirm.scanNextTracking#")}
            </SmartFormLabel>
            <SmartScanner
              id="scanTracking"
              style={{ marginTop: 10 }}
              onChangeValue={(value) => getShipmentInfo(value)}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanItem' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              
              {t("smart.confirm.scanItemConfirm")}
            </SmartFormLabel>
            <Row>
              <Col span={14}>
                <SmartScanner
                  id="scanItem"
                  style={{ marginTop: 10 }}
                  isAutoFocus={!finishDialogVisible ? 1 : 0}
                  onChangeValue={(value) => checkQty(value)}
                />
              </Col>
              <Col span={10}></Col>
            </Row>
            <Table
              size="small"
              rowKey={(record)=> record.sku + Date.now()}
              bordered
              columns={columns}
              dataSource={items}
              loading={fetching}
              pagination={false}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </SmartSpace>
          <SmartRow>
            <SmartFormLabel>{t("smart.confirm.shipTo")}</SmartFormLabel>
          </SmartRow>
          <SmartRow style={{ backgroundColor: '#d6e4ff' }}>
            <Space direction="vertical">
              <SmartText>{dataInfo[0].shipToName}</SmartText>
              <SmartText>
                {[
                  dataInfo[0].shipToAddressLine1,
                  dataInfo[0].shipToAddressLine2,
                  dataInfo[0].shipToAddressLine3,
                ]
                  .filter((e) => e)
                  .join(', ')}
              </SmartText>
              <SmartText>
                {`${dataInfo[0].shipToCity},${dataInfo[0].shipToState} ${dataInfo[0].shipToPostalCode}`}
              </SmartText>
            </Space>
          </SmartRow>

          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.requestShipping")}
            </SmartFormLabel>
            <SmartText>{dataInfo[0].requestService}</SmartText>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.storeName")}
            </SmartFormLabel>
            <SmartText>{dataInfo[0].channelAccountName}</SmartText>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.channelOrderId")}
            </SmartFormLabel>
            <SmartText>{dataInfo[0].channelOrderId}</SmartText>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.2ndChannelOrderId")}
            </SmartFormLabel>
            <SmartText>{dataInfo[0].secondaryChannelOrderId}</SmartText>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.sellerOrderId")}

            </SmartFormLabel>
            <SmartText>{dataInfo[0].sellerOrderId}</SmartText>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
            {t("smart.confirm.salesDivision")}
            </SmartFormLabel>
            <SmartText>{dataInfo[0].salesDivision}</SmartText>
          </SmartRow>
          <SmartRow />

          <SmartBackButton
            onClick={() => {
              setStep('scanTracking');
              // history.goBack();
            }}
          />
        </>
      )}

      {finishDialogVisible && (
        <FinishDialog
          dataInfo={dataInfo}
          total={totalQty}
          onSubmit={(value) => {
            setFinishDialogVisible(false);
            setTotalQty(0);
            if (value) {
              getShipmentInfo(value);
            } else {
              setStep('scanTracking');
            }
            console.log(value);
          }}
        />
      )}

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
