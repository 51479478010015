import React, { ReactNode } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid, Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import HeaderBar from './HeaderBar';
import theme from 'assets/styles/theme';
import {
  CONTENTAREA_LEFT_PADDING,
  CONTENTAREA_MARGIN,
  PAGE_MODE_FULL_PAGE,
} from 'constants/config';
import { collapseSider } from 'actions/regularActions';
import { RootState } from 'reducers';

const { Header, Content } = Layout;

const LayoutContainer = styled(Layout)`
  background-color: ${theme['@default-backgroud-color']};
  transition: margin-left 400ms;
  width: max-content !important;
`;

const HeaderContainer = styled(Header)`
  width: 100%;
  height: 55px;
  box-shadow: 0 -1px 8px 0 rgba(125, 140, 166, 0.08);
  border: solid 1px #d8dde6;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-left: 8px;
`;

const FolderBox = styled.span`
  padding: 0 18px;
  margin-bottom: 5px;

  & .anticon {
    font-size: 20px;
    vertical-align: -8px;
    cursor: pointer;
  }
`;

const ContentContainer = styled(Content)`
  margin: ${theme['@section-margin']}px ${theme['@section-margin']}px ${theme['@section-margin']}px 20px;

  & .ant-input-number-handler-wrap {
    opacity: 0.5;
  }

  & .ant-input-number-handler-wrap:hover {
    opacity: 1;
  }
`;

interface Props {
  children: ReactNode;
}

function ContentLayout({ children }: Props) {
  const dispatch: Dispatch<any> = useDispatch();
  const collapsed: boolean = useSelector(
    (state: RootState) => state.regular.siderCollapsed,
    shallowEqual
  );
  const screens = Grid.useBreakpoint();
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode
  );
  const sidebarWidth: number = useSelector(
    (state: RootState) => state.regular.siderWidth
  );
  const contentAreaMarginLeft = React.useMemo(() => {
    let marginLeft = sidebarWidth - CONTENTAREA_LEFT_PADDING;

    if (pageShowMode === PAGE_MODE_FULL_PAGE) {
      marginLeft = 0;
    }

    return marginLeft;
  }, [pageShowMode, sidebarWidth]);
  const contentContainerStyle = React.useMemo(() => {
    let style = {};

    if (pageShowMode === PAGE_MODE_FULL_PAGE) {
      style = { ...style, marginLeft: CONTENTAREA_MARGIN };
    }

    return style;
  }, [pageShowMode]);
  const headerContainerStyle = React.useMemo(() => {
    let style = {};

    if (pageShowMode === PAGE_MODE_FULL_PAGE) {
      style = { ...style, display: 'none' };
    }

    return style;
  }, [pageShowMode]);

  const toggleSidebar = React.useCallback(
    () => dispatch(collapseSider(!collapsed)),
    [dispatch, collapsed]
  );

  React.useEffect(() => {
    if (screens.lg === false) {
      if (!collapsed) {
        toggleSidebar();
      }
    }
  }, [collapsed, screens, toggleSidebar]);

  return (
    <LayoutContainer style={{ marginLeft: contentAreaMarginLeft }}>
      <HeaderContainer style={headerContainerStyle}>
        <FolderBox>
          {collapsed ? (
            <MenuUnfoldOutlined style={{ fontSize: 16 }} onClick={toggleSidebar} />
          ) : (
            <MenuFoldOutlined style={{ fontSize: 16 }} onClick={toggleSidebar} />
          )}
        </FolderBox>
        <HeaderBar />
      </HeaderContainer>
      <ContentContainer style={contentContainerStyle}>
        {children}
      </ContentContainer>
    </LayoutContainer>
  );
}

export default ContentLayout;
