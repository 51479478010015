import React, { useCallback, useState, useEffect } from 'react';
import { Row, Select, Space, Modal, Button } from 'antd';
import {
  DataGridPlus,
  HeaderTitle,
  SectionWrapper,
  FormLabel,
  message,
  GreyCardWrapper,
  TablesFilter,
} from 'components/common';
import { COMMON_CARD_WRAPPER_MARGIN, LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseIdFromCache, sortByNumber } from 'utils';
import {
  fetchWarehouseList,
  getWarehouseAspects,
  deleteWarehouseAspects,
} from 'services/warehouse';
import AddEditDialog from './AddEditDialog';

export default function LocationAspects() {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [warehouseList, setWarehouseList] = useState<WarehouseRow[]>([]);
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [addEditDialogVisible, setAddEditDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const getWarehouseList = async () => {
    try {
      const list = await fetchWarehouseList();
      if (list && Array.isArray(list)) {
        if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
          const temp = list.filter(i => `${i.warehouseNum}` === localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          if (temp.length > 0) {
            setWarehouse(temp[0])
          }
        }
        setWarehouseList(list);
      }
    } catch (error) {
      console.log('error = ', error);
    }
  };

  const getAspentList = useCallback(async () => {
    if (!warehouse) return;
    try {
      setLoading(true);
      const res = await getWarehouseAspects(warehouse?.warehouseId);
      setLoading(false);
      if (res) {
        setData(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [warehouse]);

  const delAspent = useCallback(
    async (aspect: any) => {
      try {
        const res = await deleteWarehouseAspects(
          getWarehouseIdFromCache(aspect.warehouseNum),
          aspect.locationAspectsId
        );
        if (res) {
          message.success({ content: 'Delete succcessfully' });
          getAspentList();
        }
      } catch (error) { }
    },
    [getAspentList]
  );

  useEffect(() => {
    if (warehouse) {
      getAspentList();
    }
  }, [warehouse, getAspentList]);

  useEffect(() => {
    getWarehouseList();
  }, []);

  const columns = [
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      groupBy: false,
      sortable: false,
      maxWidth: 200,
      minWidth: 200,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Space>
            <Button
              id={`action_row_${rowIndex}_edit`}
              type="link"
              onClick={() => {
                setCurrent(data);
                setAddEditDialogVisible(true);
              }}
            >
              Edit
            </Button>
            <Button
              id={`action_row_${rowIndex}_delete`}
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to delete the row ?',
                  content: `name: ${data.locationAspectsName}`,
                  okText: 'Yes',
                  okType: 'default',
                  okButtonProps: { className: 'hover-danger-button' },
                  cancelText: 'No',
                  autoFocusButton: 'cancel',
                  onCancel() { },
                  onOk() {
                    delAspent(data);
                  },
                });
              }}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
    {
      header: 'Name',
      name: 'locationAspectsName',
      defaultFlex: 1,
      minWidth: 220,
      userSelect: true,
      sortable: true,
    },
    {
      header: 'Volume Length',
      name: 'volumeLength',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.volumeLength
          ? `${data.volumeLength} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Volume Width',
      name: 'volumeWidth',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.volumeWidth
          ? `${data.volumeWidth} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Volume Height',
      name: 'volumeHeight',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.volumeHeight
          ? `${data.volumeHeight} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Capacity Length',
      name: 'capacityLength',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.capacityLength
          ? `${data.capacityLength} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Capacity Width',
      name: 'capacityWidth',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.capacityWidth
          ? `${data.capacityWidth} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Capacity Height',
      name: 'capacityHeight',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      render: (value: any) => {
        const { data } = value;
        return data.capacityHeight
          ? `${data.capacityHeight} ${data.dimensionUnit}`
          : '';
      },
    },
    {
      header: 'Tare Weight',
      name: 'tareWeight',
      defaultFlex: 1,
      sortable: true,
      userSelect: true,
      render: (value: any) => {
        const { data } = value;
        return data.tareWeight ? `${data.tareWeight} ${data.weightUnit}` : '';
      },
    },
    {
      header: 'Max Load',
      name: 'maxLoad',
      defaultFlex: 1,
    },
  ];

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Location Aspects']} />
      <SectionWrapper>
        <Row justify="space-between" align="middle">
          <Space>
            <FormLabel>Warehouse</FormLabel>
            <Select
              loading={loading}
              value={warehouse ? warehouse.warehouseNum : undefined}
              onChange={(e) =>
                setWarehouse(warehouseList.find((w) => w.warehouseNum === e))
              }
              placeholder="Please select"
              options={warehouseList
                .sort((a: any, b: any) => sortByNumber(a, b, 'warehouseType'))
                .map((item) => {
                  return {
                    label: item.warehouseCode,
                    value: item.warehouseNum,
                  };
                })}
              style={{ width: 200 }}
            />
            <TablesFilter
              columns={columns}
              dataSource={data}
              setFilteredData={setFilteredData}
            />
          </Space>
          <Space>
            <Button
              type="primary"
              disabled={!warehouse}
              onClick={() => {
                setCurrent(undefined);
                setAddEditDialogVisible(true);
              }}
            >
              Add Location Aspect
            </Button>
          </Space>
        </Row>
      </SectionWrapper>
      {data && (
        <GreyCardWrapper style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
          <DataGridPlus
            name="LocationAspect"
            showScrollButton
            autoWith={false}
            idProperty="LocationAspectsNum"
            pageSizes={[10, 20, 50, 100, 200, 500]}
            columns={columns}
            dataSource={filteredData}
            pagination="local"
            loading={loading}
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </GreyCardWrapper>
      )}
      {addEditDialogVisible && warehouse && (
        <AddEditDialog
          warehouse={warehouse}
          aspect={current}
          visible={true}
          onHide={() => setAddEditDialogVisible(false)}
          onRefresh={() => {
            getAspentList();
          }}
        />
      )}
    </>
  );
}
