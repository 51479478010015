import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import History from 'components/Reports/AuditBatchCloseHistory';

const AduitHistory = () => {
  return (
    <ContentLayout>
      <SiteContent>
        <History />
      </SiteContent>
    </ContentLayout>
  );
};

export default AduitHistory;
