import { digitBridgeApi, digitBridgePrinterApi } from './http';

type FetchReturnsParams = {
  $skip: number;
  $top: number;
  $sortBy: string;
  channelOrderId?: string;
  secondaryChannelOrderId?: string;
  sellerOrderId?: string;
  centralOrderNum?: number;
  channelAccountNum?: number;
  invoice?: string;
  customerName?: string;
  customerAddress?: string;
  customerZipCode?: string;
  sku?: string;
  createDate?: string;
  trackingNumber?: string;
  returnNo?: string;
  referenceNo?: string;
  oper: number;
  status?: number
}

export const fetchReturns = async (warehouseId: string, params: FetchReturnsParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/orderReturns`, { params })
}

export const getReturnsDetail = async (warehouseId: string, returnNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/receive`)
}

// type ReceiveReturnParams = {
//   FulfillmentReturnLineNum: number,
//   ReceiveWarehouseType: number,
//   ReceiveWarehouseNum: number,
//   ReceiveLocationNum: number,
//   ReceiveQty: number
// }
export const receiveReturn = async (warehouseId: string, returnNum: number, params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/receive`, json)
}

export const getOrderReturnReceiveList = async (warehouseId: string, returnNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/receive`)
}

export const getReturnLines = async (warehouseId: string, returnNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/lines`)
}

type MoveReturnItems = {
  FulfillmentReturnLineNum: number,
  ReturnLocationNum: number,
  DamageLocationNum: number,
  ReturnQty: number,
  DamageQty: number,
}

type MoveReturnParams = {
  ReturnWarehouseNum: number,
  DamageWarehouseNum: number,
  Items: MoveReturnItems[],
}


export const moveReturn = async (warehouseId: string, returnNum: number, params: MoveReturnParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/move`, json)
}

export const delReceiveLine = async (warehouseId: string, returnNum: number, returnReceiveNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/receive/${returnReceiveNum}`)
}

export const closeReturn = async (warehouseId: string, returnNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/close`)
}

type FetchRemoteReturnsParams = {
  channelOrderId?: string;
  secondaryChannelOrderId?: string;
  sellerOrderId?: string;
  centralOrderNum?: number;
  channelAccountNum?: number;
  invoice?: string;
  customerName?: string;
  customerAddress?: string;
  customerZipCode?: string;
  sku?: string;
  createDate?: string;
  trackingNumber?: string;
  returnNo?: string;
  referenceNo?: string;
  oper: number;
}

export const fetchRemoteReturns = async (warehouseId: string, params: FetchRemoteReturnsParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/RemoteOrderReturns`, { params })
}

type DownloadRemoteReturnParams = {
  TransUuid: string;
  WarehouseCode: string;
  InvoiceNumber: string;
  InvoiceItemUuid: string;
  Sku: string;
  ReturnQty: number;
  Reason: string;
}

export const downloadRemoteReturn = async (warehouseId: string, params: DownloadRemoteReturnParams[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/RemoteOrderReturns/Download`, json)
}

type FetchBulkReturnsParams = {
  pageIndex: number;
  pageSize: number;
  FulfillmentBulkReturnNum: number,
  FulfillmentBulkReturnId: string;
  ChannelAccountNum: number,
  ReferenceNo: string,
  WarehouseNum: number,
  ReturnDate_From?: string,
  ReturnDate_To?: string,
  TransStatus?: any,
  SyncStatus?: any,
  ControlNo?: string,
  BoxID?: string,
  Sorts: {
    property: string,
    direction: string
  }[],
  Sku?: string,
  UPC?: string,
  Oper?: number,
  CustomerCode?: string,
}

export const fetchBulkReturns = async ({ pageIndex, pageSize, FulfillmentBulkReturnNum, FulfillmentBulkReturnId, ChannelAccountNum, ReferenceNo, WarehouseNum, ReturnDate_From, ReturnDate_To, Sorts, TransStatus, ControlNo, BoxID, SyncStatus, Sku, UPC, Oper, CustomerCode }: FetchBulkReturnsParams): Promise<any> => {
  const params = { pageIndex, pageSize }
  const json = JSON.stringify({
    FulfillmentBulkReturnNum,
    FulfillmentBulkReturnId,
    ChannelAccountNum,
    ReferenceNo,
    WarehouseNum,
    ReturnDate_From,
    ReturnDate_To,
    ControlNo,
    BoxID,
    Sorts,
    TransStatus,
    SyncStatus,
    Sku,
    UPC,
    Oper,
    CustomerCode
  })
  return await digitBridgeApi.post('/api/bulkreturn/list', json, { params })
}

type AddBulkReturnsParams = {
  ChannelAccountNum: number,
  ChannelAccountName: string;
  ChannelNum: number,
  ChannelName: string,
  ReferenceNo: string,
  WarehouseNum: number,
  WarehouseCode: string,
  Notes: string,
  ReturnDate: string,
  ControlNo?: string,
  BoxID?: string,
  CustomerCode?: string,
  CustomerName?: string,
  ClaimTotalQty: number,
}

export const addBulkReturn = async (params: AddBulkReturnsParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/bulkreturn/add', json)
}

export const fetchBulkReturnLines = async (fulfillmentBulkReturnNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/bulkreturn/${fulfillmentBulkReturnNum}/lines`)
}

export const saveBulkReturnLine = async (fulfillmentBulkReturnNum: number, params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/bulkreturn/${fulfillmentBulkReturnNum}/line`, json)
}

export const completeBulkReturn = async (fulfillmentBulkReturnNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/bulkreturn/${fulfillmentBulkReturnNum}/complete`)
}

export const deleteBulkReturn = async (fulfillmentBulkReturnNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/bulkreturn/${fulfillmentBulkReturnNum}/remove`)
}

export const deleteBulkReturnLine = async (fulfillmentBulkReturnNum: number, fulfillmentBulkReturnLineNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/bulkreturn/${fulfillmentBulkReturnNum}/line/${fulfillmentBulkReturnLineNum}`)
}

/**
 * export shipment label pdf by calling printstation API.
 */
export const printReturnShippingLabel = async (
  params: StringKAnyVPair,
): Promise<any> => {
  const api = '/api/PrintReturnShippingLabel';
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post(api, json) as StringKAnyVPair;
};

export const resyncReturn = async (fulfillmentBulkReturnNums: number[]): Promise<any> => {
  const json = JSON.stringify(fulfillmentBulkReturnNums)
  return await digitBridgeApi.post('/api/bulkreturn/resync', json)
}


type updateShippingAddressRequest = {
  Name: string; // ShipToName 
  FirstName?: string;
  LastName?: string;
  Suffix?: string;  //?
  Company?: string;
  CompanyJobTitle?: string;
  Attention?: string;
  DaytimePhone?: string;
  NightPhone?: string;
  AddressLine1: string;
  AddressLine2?: string;
  AddressLine3?: string;
  City: string;
  State: string;
  StateFullName?: string;
  PostalCode: string;
  PostalCodeExt?: string;
  Country: string;
  Email?: string;
}
export const updateShippingAddress = async (warehouseId: string, returnNum: number, params: updateShippingAddressRequest): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/shiptoaddress`, json)
}

// return tracking number sync to erp 
export const syncReturnTrackingNumberToERP = async (fulfillmentReturnNum: number): Promise<any> => {
  return await digitBridgeApi.post('/api/orderReturns/syncshippinglabel', null, {
    params: {
      fulfillmentReturnNum,
    }
  })
}

//delete pendding return 
export const delReturn = async (warehouseId: string, fulfillmentReturnNum: number): Promise<any> => {
  return digitBridgeApi.delete(`/api/warehouses/${warehouseId}/orderReturns/${fulfillmentReturnNum}`)
}

//abandon pendding return 
export const abandonReturn = async (warehouseId: string, fulfillmentReturnNum: number): Promise<any> => {
  return digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${fulfillmentReturnNum}/abandon`)
}


//refresh rma return
export const refeshReturn = async (warehouseId: string, fulfillmentReturnNum: number): Promise<any> => {
  return digitBridgeApi.get(`/api/warehouses/${warehouseId}/orderReturns/${fulfillmentReturnNum}/refresh`)
}

export const getCustomers = async (): Promise<any> => {
  return digitBridgeApi.get(`/api/customers`)
}

//export bulk return csv from api
type ExportBulkReturnParams = {
  fields: string[],
  captions: string[],
  returnNums:number[]
}
export const exportBulkReturn =  async (params: ExportBulkReturnParams): Promise<any> => {
  const json = JSON.stringify(params)
  return digitBridgeApi.post('/api/bulkreturn/export', json, { responseType: 'blob' })
}

//export return csv from api
type ExportReturnParams = {
	FilterCondition: {
		fulfillmentReturnNums: number[],
	},
	SelectField: { [key: string]: string }
}
export const exporReturn =  async (warehouseId: string, params: ExportReturnParams): Promise<any> => {
  const json = JSON.stringify(params)
  return digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/export`, json, { responseType: 'blob' })
}

export const editClaimTotalQty = async(fulfillmentreturnNum:number, ClaimTotalQty: number): Promise<any> => {
  const json = JSON.stringify({ClaimTotalQty})
  return digitBridgeApi.post(`/api/bulkreturn/${fulfillmentreturnNum}/claimtotalqty`, json)
}

export const commitQtytoErp = async(warehouseId: string, returnNum: number ): Promise<any> => {
  return digitBridgeApi.post(`/api/warehouses/${warehouseId}/orderReturns/${returnNum}/commit`)
}

