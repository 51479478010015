import React, { useCallback, useEffect, useState } from 'react';
import { Row, Typography, Modal, Button, Space, Form, Input, Col } from 'antd';
import { fetchWarehouseLocationList } from 'services/warehouse';
import FormLabel from '../FormLabel';
import DataGridPlus from '../DataGridPlus';
import { CloseOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Text } = Typography;
type SelectLocationDialogProps = {
  warehouse: WarehouseRow;
  visible: boolean;
  onHide: () => void;
  onSelect: (record: WarehouseLocationRow) => void;
};
export default function SelectLocationDialog(
  props: SelectLocationDialogProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [code, setCode] = useState<string>('');
  const [dataSource, setDataSource] = useState<Array<WarehouseLocationRow>>([]);
  const { visible, onHide, warehouse, onSelect } = props;
  const columns = [
    {
      header: 'Locations',
      name: 'code',
      textAlign: 'center',
      headerAlign: 'center',
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <Button
            id={`${data.code}_btn`}
            type="text"
            style={{ width: '100%' }}
            onClick={() => {
              onSelect(data);
            }}
          >
            {data['code']}
          </Button>
        );
      },
    },
  ];
  const handleCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      setCode(value);
    },
    []
  );
  /**
   * search locations by code
   */
  const handleSearch = useCallback(() => {
    setLoading(true);
    fetchWarehouseLocationList(warehouse.warehouseId, false, code).then(
      (list) => {
        setDataSource(
          list.map((item: any) => {
            return conver2DataObject(item) as any;
          })
        );
        setLoading(false);
      }
    );
  }, [code, warehouse]);
  /**
   *row conver to data object
   */
  const conver2DataObject = (row: any) => {
    return {
      id: row.locationNum,
      code: row.locationCode,
      parentCode: row.parentCode,
      parent: row.parentNum,
      creator: row.createBy,
      reserved: row.reserved,
      backstock: row.backstock,
      warehouseId: row.warehouseId,
    };
  };
  useEffect(() => {
    if (visible) {
      setLoading(true);
      setDataSource([]);
      fetchWarehouseLocationList(warehouse.warehouseId, false).then((list) => {
        setDataSource(
          list.map((item: any) => {
            return conver2DataObject(item) as any;
          })
        );
        setLoading(false);
      });
    }
  }, [visible, warehouse]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="cancel_select_location" onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Search Location"
      centered
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={onHide}
      bodyStyle={{ paddingBottom: 0 }}
      footer={<Footer />}
    >
      <Form>
        <Form.Item>
          <Row align="middle">
            <Col span={5}>
              <Row justify="end">
                <FormLabel>Location</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={18}>
              <Search
                id="search_by_code"
                placeholder="location code"
                enterButton="Search"
                onChange={handleCodeChange}
                onSearch={handleSearch}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Text>{`Total Items:${dataSource.length}`}</Text>
          <DataGridPlus
            style={{ minHeight: 300 }}
            idProperty="id"
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination="local"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
