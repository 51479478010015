import React, { useCallback, useEffect, useState } from 'react';
import { Spin, Tabs } from 'antd';
import { fetchInventory } from 'services/product';
import { inventoryCheckin2, inventoryVerifyCheckout2 } from 'services/inventory';
import {
  GreyCardWrapper,
  HeaderTitle,
  message,
  SetDefaultWarehouseDialog,
} from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import AddItemsForm from './AddItemsForm';
import HistoryItems from '../HistoryItems';
import LocationItems from './LocationItems';
import { getWarehouseCodeFromCache, getWarehouseIdFromCache, getProfileSettingValue } from 'utils';
import SelectLotUomDialog from './SelectLotUom'

const { TabPane } = Tabs;
export default function InventoryAdd() {
  const [inventory, setInventory] = useState<any[]>([]);
  const [warehouseNum] = React.useState(
    Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  );
  const [warehouseId] = React.useState(
    getWarehouseIdFromCache(
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    )
  );
  const [history, setHistory] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductRow>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [activeKey, setActiveKey] = useState<string>('locations');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [uomEnable, setUomEnable] = useState(false);
  const [lotEnable, setLotEnable] = useState(false);
  const [timeZone, setTimeZone] = useState<string>('');
  const [lotUomInfo, setLotUomInfo] = useState<{uom: string, uomRate: number, lotNumber:string}>();
  const [selectLotUomDialogVisible, setSelectLotUomDialogVisible] = useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);
  /**
   * handle product select change event
   */
  const handleSelectProduct = useCallback(
    (_: string, newProduct?: ProductRow) => {
      setProduct(newProduct);
      if(!newProduct?.productId) {
        setLotUomInfo(undefined)
      }
    },
    []
  );
  /**
   * handle loation select change event
   */
  const handleSelectLocation = useCallback(
    (newLocation: WarehouseLocationRow) => {
      setLocation(newLocation);
    },
    []
  );
  /**
   * handle addItems button event
   */
  const handleAddItems = useCallback(
    async (
      newProduct: ProductRow,
      newLocation: WarehouseLocationRow,
      transactionReason: TransactionReasonRow,
      quantity: number,
      cb: () => void
    ) => {
      if (warehouseNum && warehouseId) {
        setLoading(true);
        //add items
        try {
          const res = await inventoryVerifyCheckout2(
            warehouseId,
            newProduct,
            transactionReason,
            quantity,
            lotUomInfo
          );
          if (res) {
            await inventoryCheckin2(
              warehouseId,
              newProduct,
              newLocation,
              transactionReason,
              quantity,
              lotUomInfo
            );
            history.push([newProduct, newLocation, quantity, new Date(), lotUomInfo]);
            setHistory(JSON.parse(JSON.stringify(history)));
            setActiveKey('history');
            setLoading(false);
            setInventory((prev: any[]) => {
              const a = prev[0];
              a.quantity = Number(a.quantity) + Number(quantity);
              return [a];
            });
            //reset form
            cb();
            message.success({
              content: 'Check In Successfully!',
            });
          } else {
            setLoading(false);
            message.warning({
              content: `No check out record was found, cannot check in this SKU: ${newProduct.sku}`,
            });
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    },
    [warehouseNum, warehouseId, history, lotUomInfo]
  );

  const getInventory = useCallback(async()=>{
    let filter = `sku eq '${product?.sku}' and locationCode eq '${location?.code}'`;
    if(lotUomInfo&&lotUomInfo.lotNumber) filter =`${filter} and lotNumber eq '${lotUomInfo.lotNumber}'`;
    if(lotUomInfo&&lotUomInfo.uom) filter =`${filter} and uom eq '${lotUomInfo.uom}'`
    fetchInventory(
      { id: warehouseNum, warehouseId: warehouseId },
      { $filter: filter }
    ).then((entity) => {
      if (entity.data.length > 0) {
        setInventory(entity.data);
      } else {
        setInventory([
          {
            locationCode: location?.code,
            locationNum: location?.id,
            quantity: 0,
            sku: product?.sku,
            warehouseNum: location?.warehouseId,
          },
        ]);
      }
      setLoading(false);
    });
  },[product, location, lotUomInfo, warehouseId, warehouseNum])

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    const res3 = await getProfileSettingValue('TimeZone');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
    setTimeZone(res3);
  }, []);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  useEffect(()=>{
    if(product && product.sku){
      if(lotEnable || uomEnable){
        setSelectLotUomDialogVisible(true)
      }
    }
  },[product, lotEnable, uomEnable])

  /* eslint-disable */
  useEffect(() => {
    if (warehouseNum) {
      if(!uomEnable&&!lotEnable){
        if (product && location) {
          getInventory()
        }
      } else {
        if(product && location && lotUomInfo){
          getInventory()
        }
      }

    }
  }, [warehouseNum, product, location, ]);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Inventory',
          `Check In  (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      />
      <Spin spinning={loading}>
        <GreyCardWrapper
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <AddItemsForm
            lotUomInfo={lotUomInfo}
            uomEnable={uomEnable}
            lotEnable={lotEnable}
            onSelectLocation={handleSelectLocation}
            // onSelectWarehouse={handleSelectWarehouse}
            onSelectProduct={handleSelectProduct}
            onAddsItem={handleAddItems}
            onSetInventory={(inv) => setInventory(inv)}
            warehouseId={warehouseId || ''}
            warehouseNum={warehouseNum}
          />
        </GreyCardWrapper>
        {(inventory || product) && (
          <GreyCardWrapper style={{ marginTop: 20 }}>
            <Tabs
              activeKey={activeKey}
              onChange={(newActiveKey: string) => {
                setActiveKey(newActiveKey);
              }}
            >
              <TabPane tab="Locations" key="locations">
                <GreyCardWrapper>
                  <LocationItems
                    items={inventory}
                    product={product}
                    lotEnable={lotEnable}
                    uomEnable={uomEnable}
                    lotUomInfo={lotUomInfo}
                    // totalQuantity={inventory.totalQuantity}
                  />
                </GreyCardWrapper>
              </TabPane>
              <TabPane tab="Check In Detail" key="history">
                <GreyCardWrapper>
                  <HistoryItems items={history} lotEnable={lotEnable} uomEnable={uomEnable} />
                </GreyCardWrapper>
              </TabPane>
            </Tabs>
          </GreyCardWrapper>
        )}
      </Spin>
      {selectLotUomDialogVisible && product &&
        <SelectLotUomDialog
          warehouseId={warehouseId}
          scope={1}
          uomEnable={uomEnable}
          lotEnable={lotEnable}
          noNew={true}
          timeZone={timeZone}
          onHide={()=>{
            setSelectLotUomDialogVisible(false)
          }}
          onOk={(data)=>{
            setLotUomInfo(data)
            setSelectLotUomDialogVisible(false)
          }}
          product={product}
        />
      }
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
}
