import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, Input, Form } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import { fetchWarehouseProductList } from 'services/inventory';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getProfileSettingValue, getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const columns = [
  {
    title: 'Item',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'LOT#',
    dataIndex: 'lotNumber',
    key: 'lotNumber',
  },
  {
    title: 'UOM',
    dataIndex: 'uom',
    key: 'uom',
  },
  {
    title: 'Rate',
    dataIndex: 'uomRate',
    key: 'uomRate',
  },
  {
    title: 'Base Qty',
    dataIndex: 'uomBaseQty',
    key: 'uomBaseQty',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

export default function CheckLocation(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation()
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanLocation' | 'result'>('scanLocation');
  const [locationCode, setLocationCode] = useState<string>('');
  const [totalQty, setTotalQty] = useState<number>(0);
  const [uomEnable, setUomEnable] = useState(true);
  const [lotEnable, setLotEnable] = useState(true);

  // const [itemList, setItemList] = useState<any[]>([]);
  // const [total, setTotal] = useState<number>(0);
  // const [current, setCurrent] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [dataInfo, setDataInfo] = useState({
    data: [],
    total: 0,
    current: 1,
    pageSize: 10,
  });
  // const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const getSetting = useCallback(async()=>{
    const res1 = await getProfileSettingValue('EnableLOT', '0')
    const res2 = await getProfileSettingValue('EnableUOM','0')
      setLotEnable(res1 === '1')
      setUomEnable(res2 === '1')
  },[])

  useEffect(()=>{
    getSetting()
  },[getSetting])

  const handleKeyDown = async (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      evt.preventDefault();
      if (evt.target.value) {
        fetchProductsByLocationCode(evt.target.value, 1, 10);
      }
    }
  };

  const fetchProductsByLocationCode = async (
    code: string,
    current: number,
    pageSize: number
  ) => {
    try {
      const query = {
        $skip: (current - 1) * pageSize,
        $top: pageSize,
        $sortBy: 'sku',
        $calculateTotal: true,
        $count: true,
        $filter: `LocationCode eq '${code}'`,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setFetching(false);
      setLocationCode(code);
      setTotalQty(res.totalQuantity);
      setDataInfo({
        data: res.data,
        total: res.count,
        current,
        pageSize,
      });
      setStep('result');
    } catch (error) {
      setFetching(false);
    }
  };

  const getColumns = useCallback(()=>{
    let temp  = [...columns]
    temp = lotEnable ? temp : temp.filter(i => i.dataIndex !== 'lotNumber')
    temp = uomEnable ? temp : temp.filter(i => ['uom','uomRate','uomBaseQty'].indexOf(i.dataIndex) === -1)
    return temp
  },[lotEnable, uomEnable])

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.audit.checkLocation.scanLocation")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) =>
                fetchProductsByLocationCode(value, 1, 10)
              }
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'result' && (
        <>
          <Form.Item
            label={
              <Text style={{ fontSize: 16 }} strong>
                {t("smart.audit.checkLocation.scanLocation")}
              </Text>
            }
          >
            <Input defaultValue={locationCode} onKeyDown={handleKeyDown} />
          </Form.Item>
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.checkLocation.location")}: <Text>{locationCode}</Text>
            </Text>
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.checkLocation.totalQty")}: <Text>{totalQty}</Text>
            </Text>
          </SmartRow>
          <Table
            size="small"
            rowKey="fulfillmentProductNum"
            bordered
            columns={getColumns()}
            dataSource={dataInfo.data}
            loading={fetching}
            pagination={{
              current: dataInfo.current,
              pageSize: dataInfo.pageSize,
              total: dataInfo.total,
            }}
            onChange={(pagination, filters, sorter) => {
              console.log(pagination);
              fetchProductsByLocationCode(
                locationCode,
                pagination.current || 1,
                pagination.pageSize || 10
              );
            }}
            style={{ marginTop: 10, marginBottom: 10 }}
          />

          <SmartBackButton
            onClick={() => {
              setStep('scanLocation');
              setLocationCode('');
              setDataInfo({
                data: [],
                total: 0,
                current: 1,
                pageSize: 10,
              });
            }}
          />
        </>
      )}

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
