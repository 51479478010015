import React, { useCallback } from 'react';
import { Form, FormInstance, FormProps } from 'antd';
import { isFormChanged } from 'utils';

interface FormPlusProps extends FormProps {
  snapshot?: StringKAnyVPair;
  form?: FormInstance;
  onChanged?: (changed: boolean) => void;
}
/**
 * Extend Form component
 * Feature:Automatically compare whether the content of the form has changed.
 */
export default function FormPlus(props: FormPlusProps) {
  const { form } = props;
  const {
    snapshot = form ? form.getFieldsValue() : {},
    onChanged,
    ...other
  } = props;
  /**
   * handle values change event
   */
  const handleValuesChange = useCallback(() => {
    if (typeof onChanged === 'function' && form) {
      const changed = isFormChanged(snapshot || {}, form);
      onChanged(changed);
    }
  }, [snapshot, form, onChanged]);
  return (
    <Form
      form={form}
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      onValuesChange={handleValuesChange}
    />
  );
}
FormPlus.defaultProps = {
  snapshot: undefined,
  form: undefined,
  onChanged: undefined,
};
