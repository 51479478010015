import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';
import { getProfileSettingValue } from 'utils';

export default function Pick(): JSX.Element {
  const { t } = useTranslation();
  const [allowInspection, setAllowInspection] = useState<string>('0');
  const getConfig = useCallback(async () => {
    try {
      const res = await getProfileSettingValue('InspectionToNormal', '0');
      setAllowInspection(res);
    } catch (error) {}
  }, []);
  useEffect(() => {
    getConfig();
  }, [getConfig]);
  return (
    <div>
      <SmartBlock style={{ marginTop: 40 }}>
        <SmartSpace>
          <Link to="/smart/returnToLocation">
            <SmartButton>
              {t('smart.virtualWarehouseToLocation.returnToLocation.title')}
            </SmartButton>
          </Link>

          {allowInspection === '1' && (
            <Link to="/smart/inspectionToLocation">
              <SmartButton>
                {t(
                  'smart.virtualWarehouseToLocation.inspectionToLocation.title'
                )}
              </SmartButton>
            </Link>
          )}

          <Link to="/smart/receivingToLocation">
            <SmartButton>
              {t('smart.virtualWarehouseToLocation.receivingToLocation.title')}
            </SmartButton>
          </Link>
        </SmartSpace>
      </SmartBlock>
    </div>
  );
}
