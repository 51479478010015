import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import ReturnRtoReturnD from 'components/Sales/Smart/MoveMerge/MoveWarehouse/ReturnRtoReturnD';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Page(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.returnRtoReturnD" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}

    // title={`From Return-Resalable to Return-Damage(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <ReturnRtoReturnD />
    </ContentLayout>
  );
}

