import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import MoveAllProducts from 'components/Sales/Smart/MoveMerge/moveAllproducts';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartMove(): JSX.Element {
  return (
    <ContentLayout
    titleKey="titles.moveAllProduct" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    //  title={`Move all products from one location to another warehouse(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
     >
      <MoveAllProducts />
    </ContentLayout>
  );
}
