import React, { useState } from 'react';
import {
  HeaderTitle,
  DataGridPlus,
  ColumnDataType,
  SectionWrapper,
  TablesFilter,
} from 'components/common';
import ConditonsForm from './ConditonsForm';
import { onSelectionChange } from 'utils'

export default function CycleCounting(): JSX.Element {
  const [dataSource, setDataSource] = useState<Array<CycleCountRow>>([]);
  const [selectedRows, setSelectedRows] = useState<Array<CycleCountRow>>([]);
  const [searched, setSearched] = useState(false);
  const [selected, setSelected] = React.useState<any>({});
  const [allTempList, setAllTempList] = useState<any[]>([])
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const columns = [
    {
      header: 'Location/Container',
      name: 'locationCode',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Last Count Date',
      name: 'lastCountDate',
      userSelect: true,
      dataType: ColumnDataType.DATE,
      defaultFlex: 1,
    },
    {
      header: 'Container Drill Down',
      name: 'containerDrillDown',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Type',
      name: 'type',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Quantity',
      name: 'quantity',
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
    },
  ];
  let customProps = {} as any;
  if (selectedRows.length === 0) {
    customProps = {
      ...customProps,
      selected: [],
    };
  }
  return (
    <>
      <HeaderTitle breadcrumb={['Inventory', 'Cycle Counting']} />
      <SectionWrapper>
        <ConditonsForm
          selectedRows={selectedRows}
          columns={columns}
          onChange={(data) => {
            setSearched(true);
            setDataSource(data);
            setSelected({});
            setSelectedRows([]);
          }}
        />
      </SectionWrapper>
      {searched && (
        <SectionWrapper style={{ marginTop: 20 }}>
          <TablesFilter
            columns={columns}
            dataSource={dataSource}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            idProperty="id"
            columns={columns}
            dataSource={filteredData}
            pagination="local"
            checkboxColumn
            checkboxOnlyRowSelect
            selected={selected}
            onSortInfoChange={() => {
              setSelected({})
              setSelectedRows([])
            }}
            onChange={() => {
              setSelected({})
              setSelectedRows([])
            }}
            onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRows, 'id', true, setAllTempList)}
            // onSelectionChange={(props) => {
            //   const { selected, data, unselected } = props;
            //   setSelected(selected);
            //   if (selected === true && !unselected) {
            //     setSelectedRows(data as any);
            //   } else {
            //     if (!unselected) {
            //       setSelectedRows(Object.values(selected as any));
            //     } else {
            //       setSelectedRows((prev) => {
            //         return prev.filter((item) => item.id !== (data as any).id);
            //       });
            //     }
            //   }
            // }}
            /* eslint-disable react/jsx-props-no-spreading */
            {...({ customProps } as any)}
          />
        </SectionWrapper>
      )}
    </>
  );
}
