import React, { useCallback, useState } from 'react';
import {
  ModalPlus as Modal,
  FormCancelButton,
  FormSubmitButton,
  FormPlus,
  ScanningGunInput,
  parseScanningGunResult,
  FormLabel,
} from 'components/common';
import {
  Row,
  Form,
  Alert,
  Button,
  Space,
} from 'antd';
import { fetchOrderByIds } from 'services/orders';
import { useHistory } from 'react-router';
import SaleGrid from './SaleGrid';
import { SaveOutlined } from '@ant-design/icons';

type ScanOrderNumberDialogProps = {
  onClose: () => void;
  onSubmit?: (rows: Array<SaleRow>) => void;
  title?: string;
  warehouseNum: number;
};
/**
 * Returns ScanOrderNumberDialog
 * @param {ScanOrderNumberDialogProps} props
 * @returns {JSX.Element}
 */
export default function ScanOrderNumberDialog(
  props: ScanOrderNumberDialogProps
) {
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState<boolean>(false);
  const {
    onClose,
    onSubmit,
    title = 'Generate Picklist',
    warehouseNum,
  } = props;
  const [form] = Form.useForm();
  const snapshot = { saleIds: '' };
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
  };
  const [data, setData] = useState<Array<SaleRow>>([]);
  const [errorIds, setErrorIds] = useState<Array<string>>([]);
  const history = useHistory();
  /**
   * handle close event
   */
  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);
  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const { saleIds } = form.getFieldsValue();
    const ids = parseScanningGunResult(saleIds);
    setLoading(true);
    const [rows, notFindIds] = await fetchOrderByIds(warehouseNum, ids);
    setData(rows as any);
    setErrorIds(notFindIds as any);
    setLoading(false);

    if (rows.length > 0) {
      form.resetFields();
    }
  }, [form, warehouseNum]);
  /**
   * handle Submit event
   */
  const handleSubmit = useCallback(() => {
    if (typeof onSubmit !== 'function') {
      const orderNums: Array<number> = [];
      data.forEach((item) => {
        orderNums.push(item.fulfillmentOrderNum);
      });
      const url = `/sales/print-generated-picklists?ids=${orderNums.join(',')}`;
      history.push(url);
    } else {
      onSubmit(data);
    }
  }, [data, history, onSubmit]);
  return (
    <>
      <Modal
        title="Scan/Enter Orders"
        centered
        visible
        width={660}
        destroyOnClose
        onClose={handleClose}
        getContainer={false}
        form={form}
        snapshot={snapshot}
        footer={(
          <Row justify="end">
            <Space>
              {data.length === 0 && (
                <FormSubmitButton
                  loading={loading}
                  changed={changed}
                  onSubmit={handleSearch}
                >
                  Search Orders
                </FormSubmitButton>
              )}
              {data.length > 0 && (
                <Button type="primary" onClick={handleSubmit} icon={<SaveOutlined />}>
                  {title}
                </Button>
              )}
              <FormCancelButton
                currentform={form}
                snapshot={snapshot}
                onClick={handleClose}
              />
            </Space>
          </Row>
        )}
      >
        {data.length > 0 && (
          <SaleGrid style={{ marginBottom: 10 }} data={data} />
        )}
        {errorIds.map((id, index) => {
          return (
            <Alert
              key={id}
              style={{
                marginTop: index === 0 ? (data.length > 0 ? 10 : 0) : 10,
                marginBottom: 10,
              }}
              message={(
                <Row align="middle">
                  <div>
                    Not Found '
                    {id}
                    '.
                  </div>
                </Row>
              )}
              description=""
              type="error"
            />
          );
        })}
        {data.length === 0 && (
          <FormPlus
            {...layout}
            form={form}
            snapshot={snapshot}
            onChanged={(changed: boolean) => {
              setChanged(changed);
            }}
          >
            <Row>
              <Form.Item
                labelCol={{ span: 4 }}
                name="saleIds"
                style={{ width: '100%' }}
                label={<FormLabel>Order IDs</FormLabel>}
                rules={[{ required: true, message: 'Please input Sale IDs!' }]}
              >
                <ScanningGunInput autoCount={false} />
              </Form.Item>
            </Row>
          </FormPlus>
        )}
      </Modal>
    </>
  );
}
