import React, { useCallback, useEffect, useState } from 'react';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  PrinterOutlined,
  DesktopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  List,
  Typography,
  Dropdown,
  Menu,
  Row,
  Checkbox,
  Modal,
  Space,
  Empty,
} from 'antd';
import {
  getPrinters,
  delPrintAccount,
  enablePrinter,
  disablePrinter,
} from 'services/printStation';
import { HeaderTitle, message } from 'components/common';

const PrintStation =  () => {
  // const [sourceData, setSourceData] = useState<any[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(false)

  type OptionsTree = {
    label: string;
    online: boolean;
    printerAccountId: string;
    printerOptions: any[];
    scalesOptions: any[];
  };

  const getSourceData = useCallback(async () => {
    try {
      const res = await getPrinters();
      if (res.isSuccess) {
        const { data } = res;
        if (!data || data.length === 0) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false);
        }
        const tree: OptionsTree[] = [];
        const pcIdList: string[] = [];
        data.map((item: Printer) => {
          const i = pcIdList.indexOf(item.printerAccountId);
          if (i < 0) {
            pcIdList.push(item.printerAccountId);
            tree.push({
              label: `${item.pcName} (${item.printerAccountNum})`,
              online: item.online,
              printerAccountId: item.printerAccountId,
              printerOptions: [
                {
                  label: item.printerName,
                  value: item.printerId,
                  deleted: item.deleted,
                },
              ],
              scalesOptions: [],
            });
          } else {
            tree[i].printerOptions.push({
              label: item.printerName,
              value: item.printerId,
              deleted: item.deleted,
            });
          }
          return true;
        });
        // setSourceData(res.data);
        setTreeData(tree);
      }
    } catch (error) {
    }
  }, []);

  const delClient = useCallback(
    async (id: string) => {
      try {
        const res = await delPrintAccount(id);
        if (res) {
          message.success({ content: 'delete successfully' });
          getSourceData();
        }
      } catch (error) {}
    },
    [getSourceData]
  );

  const changePrinterStatus = useCallback(
    async (value: boolean, printerAccountId: string, printerId: string) => {
      try {
        const res = value
          ? await enablePrinter(printerAccountId, printerId)
          : await disablePrinter(printerAccountId, printerId);
        if (res) {
          message.success('Update successfully')
          getSourceData();
        }
      } catch (error) {
      }
    },
    [getSourceData]
  );

  useEffect(() => {
    getSourceData();
  }, [getSourceData]);

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'PrintStation Managment']}>
        <Button
          type="primary"
          onClick={() => {
            window.open(
              process.env.PRINTSTATTION_INSTALL_LINK,
              'mozillaTab'
            );
          }}
        >
          Download PrintStation
        </Button>
      </HeaderTitle>
      <Card size="small" style={{ width: '100%', marginBottom: 12 }}>
        {treeData.length > 0 ? (
          treeData?.map((i) => (
            <Card
              size="small"
              style={{ marginBottom: 8 }}
              key={i.label}
              type="inner"
              title={
                <Space>
                  <DesktopOutlined
                    style={i.online ? { color: '#52c41a' } : {}}
                  />
                  <Typography.Text>{i.label}</Typography.Text>
                </Space>
              }
              extra={
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="2">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          className="hover-danger-button-2"
                          disabled={i.online}
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              title:
                                'Are you sure you want to delete the client ?',
                              content: `Name: ${i.label}`,
                              okText: 'Yes',
                              okType: 'default',
                              okButtonProps: {
                                className: 'hover-danger-button',
                              },
                              cancelText: 'No',
                              autoFocusButton: 'cancel',
                              onOk() {
                                delClient(i.printerAccountId);
                              },
                              onCancel() {},
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <Button icon={<SettingFilled />}>
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
              }
            >
              <List
                size="small"
                header={
                  <Row justify="space-between" align="middle">
                    <Typography.Text type="secondary">Printers</Typography.Text>
                    <Typography.Text style={{ width: 270 }}>
                      Enable
                    </Typography.Text>
                  </Row>
                }
              >
                {i.printerOptions.map((item: any) => (
                  <List.Item
                    key={item.value}
                    extra={
                      <div style={{ width: 250 }}>
                        <Checkbox
                          checked={item.deleted === 0}
                          onChange={(e) => {
                            changePrinterStatus(
                              e.target.checked,
                              i.printerAccountId,
                              item.value
                            );
                          }}
                        />
                      </div>
                    }
                  >
                    <Space size="small" align="center">
                      <PrinterOutlined />
                      <Typography.Text>{item.label}</Typography.Text>
                    </Space>
                  </List.Item>
                ))}
              </List>
            </Card>
          ))
        ) : isEmpty ? (
          <Empty />
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default PrintStation;
