import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import TrackingNumber from 'components/Sales/Smart/ConfirmOrderShipment/TrackingNumber';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Tracking(): JSX.Element {
  return (
    <ContentLayout titleKey="titles.confirmByTracking" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}>
      <TrackingNumber />
    </ContentLayout>
  );
}
