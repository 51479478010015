import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  // EditOutlined,
  ClearOutlined,
  StopOutlined,
  SaveOutlined,
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  message,
  Locker,
  TablesFilter,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  // Space,
  // Tooltip,
  Button,
  Input,
  // Divider,
  Dropdown,
  Menu,
  Space,
  Modal,
  Typography,
  Spin,
  Switch,
  InputNumber,
  Collapse,
  Empty,
} from 'antd';
// import debounce from 'lodash.debounce';
// import EditQtyDialog from './EditDialog';
// import { playAudio } from 'utils';
import {
  COMMON_PADDING_SPACE,
  // LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import theme from 'assets/styles/theme';
import styled from 'styled-components';
import {
  getUnpackLines,
  getUnpackProcessLines,
  ceateUnpackProcessLine,
  delUnpackProcessLine,
} from 'services/unpack';
import { fetchPrepackSettings } from 'services/prepack';
import SearchSelect from './SelectLocation';
import { getWarehouseIdFromCache } from 'utils';
// import EditQtyDialog from './EditLineDialog';
import { fetchWarehouseLocationList } from 'services/warehouse';
import Text from 'antd/lib/typography/Text';

const { Panel } = Collapse;

const TCollapse = styled(Collapse)`
  &.ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    position: relative;
    padding: 0px 16px;
    padding-right: 40px;
  }
`;

interface Props extends ModalProps {
  onClose: Function;
  unpack: Unpack;
}

const PageDialog = (props: Props) => {
  const { unpack } = props;
  const [info, setInfo] = useState<Unpack>();
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [processLines, setProcessLines] = useState<UnpackProcessLine[]>([]); //
  const [processFilterLines, setProcessFilterLines] = useState<
    UnpackProcessLine[]
  >([]);
  const [lines, setLines] = useState<any>([]);
  const [filterlines, setFilterlines] = useState<any>([]);
  const [showAll, setShowAll] = useState(false);
  const [qtyLocker, setQtyLocker] = useState(false);
  const [dLocationLocker, setDLocationLocker] = useState(false);
  const [sLocationLocker, setSLocationLocker] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [processloading, setProcessLoading] = useState(false);
  const [lineLoading, setLineLoading] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [selectedLine, setSelectedLine] = useState<UnpackLine>();
  const [overQty, setOverQty] = useState(false);
  const [enableCSN, setEnableCSN] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (unpack) {
      setInfo(unpack);
    }
  }, [unpack]);

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  // const debounceFn = debounce((val: string) => {
  //   setFilterValue(val);
  // }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const clearForm = useCallback(() => {
    form.setFieldsValue({ sku: undefined });
    setSelectedLine(undefined);
    if (!qtyLocker) {
      form.setFieldsValue({ unpackQty: 1 });
    }
    if (!dLocationLocker) {
      form.setFieldsValue({
        destinationLocationNum: undefined,
        DestinationLocationCode: undefined,
      });
    }
    if (!sLocationLocker) {
      form.setFieldsValue({
        sourceLocationNum: undefined,
        sourceLocationCode: undefined,
      });
    }
  }, [form, qtyLocker, dLocationLocker, sLocationLocker]);

  const fetchProcessLines = useCallback(async (fulfillmentPackNum: number) => {
    try {
      setProcessLoading(true);
      const res = await getUnpackProcessLines(fulfillmentPackNum);
      setProcessLoading(false);
      if (res) {
        setProcessLines(res);
      }
    } catch (error) {
      setProcessLoading(false);
    }
  }, []);

  const getTempLineColumns = useCallback((showCSNumber: boolean) => {
    const temp = [
      {
        name: 'fulfillmentUnpackProcessNum',
        type: 'string',
        header: 'Id',
        defaultVisible: false,
      },
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'upc',
        header: 'UPC',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'caseSerialNumber',
        header: 'CS#',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'unpackQty',
        header: 'Qty',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'sourceLocationCode',
        header: 'From',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'destinationLocationCode',
        header: 'To',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Dropdown
              overlay={
                <Menu>
                  {/* <Menu.Item key="0">
                    <Button
                      id={`grid_action_row_${rowIndex}_dropdown_edit`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setEditQtyDialogVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Menu.Item> */}
                  <Menu.Item key="1">
                    <Button
                      id={`_action_row_${rowIndex}_dropdown_delete`}
                      type="text"
                      // disabled={data.receiveQty !== 0}
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setDeleteDialogVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ];
    return showCSNumber
      ? temp
      : temp.filter((i) => i.name !== 'caseSerialNumber');
  }, []);

  const queryProduct = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.sku.trim()) {
      // return message.warning({ content: 'invaild SKU/LPN' });
      return Modal.warning({title: 'invaild SKU/LPN'})

    }
    const res = lines.filter(
      (i: any) =>
        (i.sku === params.sku.trim() || i.upc === params.sku.trim()) || i.caseSerialNumber === params.sku.trim()
    );
    if (res && res.length > 0) {
      setSelectedLine(res[0]);
      const index = lines.findIndex((item: any) => item.sku === res[0].sku);
      if (index > -1) {
        setLines((prev: any) => {
          const temp = prev;
          temp.unshift(temp.splice(index, 1)[0]);
          return [...temp];
        });
      }
    } else {
      // message.info({ content: `[${params.sku}] not found` });
      Modal.warning({title: `[${params.sku}] not found` })
      form.setFieldsValue({ sku: undefined });
    }
  }, [form, lines]);

  const fetchLines = useCallback(async (id: number, first?: boolean) => {
    try {
      setLineLoading(true);
      const res = await getUnpackLines(id);
      setLineLoading(false);
      if (res) {
        setLines([...res]);
        setFilterlines([...res])
      }
    } catch (error) {
      setLineLoading(false);
    }
  }, []);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (!info) return message.warning('no find unpack');
    if (params.errorFields) return;
    if (params.requestNumber !== info.upackCode) {
      return message.warning(`no match ${info.upackCode}`);
    }
    let tempSelected: any
    if (!selectedLine) {
      const temp = lines.filter(
        (i: any) =>
          (i.sku === params.sku.trim() || i.upc === params.sku.trim() ) || i.caseSerialNumber === params.sku.trim()
      );
      if (temp && temp.length > 0) {
        setSelectedLine(temp[0]);
        tempSelected = temp[0]
        // check over qty
        if (!overQty) {
          if (temp[0].requestQty < temp[0].unpackQty + params.unpackQty) {
            return message.warning('quantity exceeded');
          }
        }
      } else{
        return Modal.warning({title: `SKU [${params.sku}] not found` })
      }
    } else {
      tempSelected = selectedLine
    }

    try {
      setSubmitLoading(true);
      const res = await ceateUnpackProcessLine(info.fulfillmentPackNum, {...params, sku: tempSelected.sku});
      setSubmitLoading(false);
      if (res) {
        message.success('Submit successfully');
        clearForm();
        fetchLines(info.fulfillmentPackNum);
        fetchProcessLines(info.fulfillmentPackNum);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  }, [
    form,
    info,
    fetchProcessLines,
    clearForm,
    selectedLine,
    lines,
    fetchLines,
    overQty,
  ]);

  //check switch
  const checkSwicth = useCallback(
    (ck1, ck2) => {
      if (ck1) {
        const { sourceLocationNum, sourceLocationCode } = form.getFieldsValue();
        if (sourceLocationNum && sourceLocationCode) {
          form.setFieldsValue({
            destinationLocationNum: sourceLocationNum,
            destinationLocationCode: sourceLocationCode,
          });
        }
      }

      if (ck2) {
        //set to $unpack
        const unpackLocation = locations.filter(
          (i) => i.locationCode === '$Unpack'
        );
        if (unpackLocation.length > 0) {
          form.setFieldsValue({
            destinationLocationNum: unpackLocation[0].locationNum,
            destinationLocationCode: unpackLocation[0].locationCode,
          });
        }
      }
    },
    [form, locations]
  );

  const deleteLine = useCallback(
    async (fulfillmentPackNum: number, fulfillmentUnpackProcessNum: number) => {
      try {
        const res = await delUnpackProcessLine(
          fulfillmentPackNum,
          fulfillmentUnpackProcessNum
        );
        if (res) {
          message.success('Delete Successfully');
          setDeleteDialogVisible(false)
          fetchProcessLines(fulfillmentPackNum);
          fetchLines(fulfillmentPackNum)
        }
      } catch (error) {}
    },
    [fetchProcessLines, fetchLines]
  );

  const getUnpackLocation = useCallback(async (data: Unpack) => {
    try {
      const res = await fetchWarehouseLocationList(
        getWarehouseIdFromCache(data.warehouseNum),
        true
      );
      if (res && res.length > 0) {
        setLocations(res);
      }
    } catch (error) {}
  }, []);

  const getPrepackSetting = useCallback(async () => {
    try {
      const res = await fetchPrepackSettings();
      if (res.isSuccess) {
        if (
          res.data.unpackOverPercentage &&
          res.data.unpackOverPercentage > 0
        ) {
          setOverQty(true);
        }
        if (res.data.enableCSN && res.data.enableCSN === 1) {
          setEnableCSN(true);
        }
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (info) {
      getPrepackSetting();
      fetchLines(info.fulfillmentPackNum, true);
      fetchProcessLines(info.fulfillmentPackNum);
      getUnpackLocation(info);
    }
  }, [
    info,
    fetchLines,
    fetchProcessLines,
    getUnpackLocation,
    getPrepackSetting,
  ]);
  useEffect(() => {
    if (checked1) {
      setDLocationLocker(true);
    }
  }, [checked1]);
  useEffect(() => {
    if (checked2) {
      setDLocationLocker(true);
    }
  }, [checked2]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Unpack Repack (Process): ${info?.upackCode}`}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {info && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // backgroundColor: '#fff',
                backgroundColor: theme['@default-backgroud-color'],
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 450,
                  minHeight: 580,
                  height: '100%',
                  // borderRightWidth: 1,
                  borderRadius: `${theme['@border-radius-base']}`,
                  // borderRightColor: ' rgba(0, 0, 0, 0.15)',
                  // borderRightStyle: 'solid',
                  marginRight: COMMON_PADDING_SPACE,
                }}
              >
                <Card size="small" bordered={false} style={{ width: 450 }}>
                  <Spin
                    spinning={submitLoading}
                    indicator={<StopOutlined style={{ fontSize: 20 }} />}
                    // tip="All items have been received."
                  >
                    <Form form={form}>
                      <Form.Item
                        label={
                          <div style={{ width: 120, textAlign: 'left' }}>
                            <FormLabel require>Confirm Request#</FormLabel>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="requestNumber"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Request#!',
                                },
                              ]}
                            >
                              <Input
                                tabIndex={0}
                                onPressEnter={() => {}}
                                onKeyDown={(e) => {}}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}></Col>
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 120, textAlign: 'left' }}>
                            <FormLabel require>SKU or LPN</FormLabel>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="sku"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input SKU or LPN!',
                                },
                              ]}
                            >
                              <Input
                                tabIndex={0}
                                onPressEnter={() => {
                                  if (
                                    qtyLocker &&
                                    sLocationLocker &&
                                    dLocationLocker
                                  ) {
                                    addSubmit();
                                  } else {
                                    queryProduct();
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 9) {
                                    if (
                                      qtyLocker &&
                                      sLocationLocker &&
                                      dLocationLocker
                                    ) {
                                      addSubmit();
                                    } else {
                                      queryProduct();
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}></Col>
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 120, textAlign: 'left' }}>
                            <FormLabel require>Qty</FormLabel>
                          </div>
                        }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="unpackQty"
                              initialValue={0}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Qty!',
                                },
                              ]}
                            >
                              <InputNumber
                                disabled={
                                  selectedLine
                                    ? selectedLine.caseSerialNumber
                                      ? true
                                      : false
                                    : false
                                }
                                min={0}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={qtyLocker}
                              onChange={(value: boolean) => {
                                setQtyLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 120, textAlign: 'left' }}>
                            <FormLabel require>Source Loc#</FormLabel>
                          </div>
                        }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="sourceLocationNum"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Source Loc#!',
                                },
                              ]}
                            >
                              <SearchSelect
                                locations={locations.filter(i=>i.locationCode !== '$Pack')}
                                onChange={(value, row) => {
                                  if (value && row.length > 0) {
                                    form.setFieldsValue({
                                      sourceLocationCode: row[0].locationCode,
                                    });
                                    checkSwicth(checked1, checked2);
                                  }
                                }}
                                // locationType={0}
                                form={form}
                                title="sourceLocationNum"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={sLocationLocker}
                              onChange={(value: boolean) => {
                                setSLocationLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item noStyle name="sourceLocationCode">
                        <Input type="hidden" />
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 235, textAlign: 'left' }}>
                            <FormLabel require>
                              Unpack at source location
                            </FormLabel>
                          </div>
                        }
                      >
                        <Switch
                          checkedChildren="on" 
                          unCheckedChildren="off"
                          checked={checked1}
                          disabled={checked2}
                          onChange={(checked) => {
                            setChecked1(checked);
                            checkSwicth(checked, checked2);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 120, textAlign: 'left' }}>
                            <FormLabel require>Destination Loc#</FormLabel>
                          </div>
                        }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="destinationLocationNum"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Destination Loc#!',
                                },
                              ]}
                            >
                              <SearchSelect
                                locations={locations.filter(i=>i.locationCode !== '$Pack')}
                                disabled={checked1 || checked2}
                                // warehouseId={getWarehouseIdFromCache(info.warehouseNum)}
                                onChange={(value, row) => {
                                  if (value && row.length > 0) {
                                    form.setFieldsValue({
                                      destinationLocationCode:
                                        row[0].locationCode,
                                    });
                                  }
                                }}
                                // locationType={0}
                                form={form}
                                title="destinationLocationNum"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={dLocationLocker}
                              onChange={(value: boolean) => {
                                setDLocationLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>

                      <Form.Item noStyle name="destinationLocationCode">
                        <Input type="hidden" />
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 235, textAlign: 'left' }}>
                            <FormLabel require>Unpack to $Unpack</FormLabel>
                          </div>
                        }
                      >
                        <Switch
                          checkedChildren="on" 
                          unCheckedChildren="off"
                          checked={checked2}
                          disabled={checked1}
                          onChange={(checked) => {
                            setChecked2(checked);
                            checkSwicth(checked1, checked);
                          }}
                        />
                      </Form.Item>

                      <Row
                        justify="center"
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <Space>
                          <Button
                            type="primary"
                            onClick={addSubmit}
                            disabled={
                              selectedLine &&
                              !overQty &&
                              selectedLine.requestQty <= selectedLine.unpackQty
                            } // check over qty
                            icon={<SaveOutlined />}
                          >
                            Submit
                          </Button>
                          <Button onClick={clearForm}>
                            <ClearOutlined />
                            Clear
                          </Button>
                        </Space>
                      </Row>
                      <Row justify="center">
                        {selectedLine && (
                          <Space>
                            <FormLabel>Total Unpacked</FormLabel>
                            <Typography.Text>
                              {selectedLine.unpackQty}
                            </Typography.Text>
                          </Space>
                        )}
                      </Row>
                    </Form>
                  </Spin>
                </Card>
              </div>
              {!showAll && (
                <div
                  style={{
                    height: '100%',
                    minHeight: 580,
                    flexGrow: 1,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    background: '#fff',
                    borderRadius: theme['@border-radius-base'],
                    marginRight: COMMON_PADDING_SPACE,
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      paddingBottom: 8,
                    }}
                  >
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ paddingTop: 8 }}
                    >
                      <TablesFilter
                        dataSource={processLines}
                        setFilteredData={setProcessFilterLines}
                        columns={[{ name: 'sku' }, { name: 'upc' }]}
                      />
                      <Typography.Text strong>Current Process</Typography.Text>
                      <Typography.Text> </Typography.Text>
                    </Row>
                    <DataGridPlus
                      id="fulfillmentUnpackProcessNum"
                      boxStyle={{ height: 'calc(100% - 40px)' }}
                      columns={getTempLineColumns(enableCSN)}
                      dataSource={processFilterLines}
                      autoWith={false}
                      autoHeight={false}
                      loading={processloading}
                      style={{
                        height: detailDialogIsFullscreen ? '100%' : 522,
                      }}
                      pagination={false}
                      {...({} as any)}
                    />
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  width: showAll ? 'calc(100% - 454px)' : 300,
                  height: '100%',
                  minHeight: 580,
                  backgroundColor: '#fff',
                  borderRadius: theme['@border-radius-base'],
                  // borderLeftWidth: 1,
                  // borderLeftColor: ' rgba(0, 0, 0, 0.15)',
                  // borderLeftStyle: 'solid',
                }}
              >
                <Card
                  loading={lineLoading}
                  title={
                    <Row
                      justify="space-between"
                      style={{ paddingLeft: showAll ? 4 : 0 }}
                    >
                      {showAll && (
                        <TablesFilter
                          dataSource={lines}
                          setFilteredData={setFilterlines}
                          columns={[{ name: 'sku' }, { name: 'upc' }]}
                        />
                      )}
                      {!showAll && (
                        <Button
                          type="link"
                          onClick={() => setShowAll(true)}
                        >{`< Expand`}</Button>
                      )}
                      <Typography.Text>All</Typography.Text>
                      {!showAll && <Typography.Text> </Typography.Text>}
                      {showAll && (
                        <Button
                          type="link"
                          onClick={() => setShowAll(false)}
                        >{`callapse >`}</Button>
                      )}
                    </Row>
                  }
                  bordered={false}
                  headStyle={{ padding: 0 }}
                  style={{ width: 'calc(100% - 14px)' }}
                  bodyStyle={{
                    height: 'calc(100% - 70px)',
                    minHeight: 520,
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {filterlines.map((i: any) => (
                    <Card
                      key={i.sku}
                      size="small"
                      type="inner"
                      headStyle={
                        i.unpackQty === i.requestQty
                          ? { backgroundColor: theme['@finished-color'] }
                          : i.unpackQty > i.requestQty
                          ? { backgroundColor: theme['@error-color'] }
                          : {}
                      }
                      title={
                        <Row>
                          <Col span={24}>
                            <Space size="small">
                              <Text strong>SKU:</Text>
                              <CopySpan inGrid={!showAll} value={i.sku || ''} />
                            </Space>
                          </Col>
                        </Row>
                      }
                      style={{ width: '100%', marginBottom: 16 }}
                    >
                      {showAll ? (
                        <TCollapse
                          ghost
                          expandIconPosition="right"
                          expandIcon={({ isActive }) => {
                            return isActive ? (
                              <Typography.Link>
                                <UpOutlined style={{ marginRight: 2 }} />
                                Hide Detail
                              </Typography.Link>
                            ) : (
                              <Typography.Link>
                                <DownOutlined style={{ marginRight: 2 }} />
                                Show Detail
                              </Typography.Link>
                            );
                          }}
                        >
                          <Panel
                            header={
                              <Row style={{ width: '80%' }}>
                                <Col span={6}>
                                  <FormLabel>UPC</FormLabel>
                                  {i.upc || ''}
                                </Col>
                                <Col span={6}>
                                  <FormLabel>Product Name</FormLabel>
                                  {i.productTitle}
                                </Col>
                                <Col span={6}>
                                  <FormLabel>Requested</FormLabel>
                                  {i.requestQty}
                                </Col>
                                <Col span={6}>
                                  <FormLabel>Unpacked</FormLabel>
                                  {i.unpackQty}
                                </Col>
                              </Row>
                            }
                            key="1"
                          >
                            <DataGridPlus
                              name="lineGrid"
                              style={{ minHeight: 200 }}
                              idProperty="code"
                              dataSource={processLines.filter(
                                (item) => item.sku === i.sku
                              )}
                              columns={getTempLineColumns(enableCSN)}
                              /* eslint-disable react/jsx-props-no-spreading */
                              {...({} as any)}
                            />
                          </Panel>
                        </TCollapse>
                      ) : (
                        <Row>
                          <Col span={24}>
                            <span>{`UPC: ${i.upc || ''}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Requested: ${i.requestQty}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Unpacked: ${i.unpackQty}`}</span>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  ))}
                  {filterlines.length === 0 && <Empty />}
                </Card>
              </div>
            </div>
          )}
        </div>
        {current && deleteDialogVisible && (
          <Modal
            title="Are you sure delete this Row?"
            visible
            onOk={() => {
              deleteLine(
                current.fulfillmentUnpackNum,
                current.fulfillmentUnpackProcessNum
              );
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`SKU: ${current.sku}`}</p>
          </Modal>
        )}
        {/* {editQtyDialogVisible && current && (
          <EditQtyDialog
            data={current}
            visible
            upackCode={info?.upackCode || ''}
            lines={lines}
            onSave={(data) => {
              setEditQtyDialogVisible(false);
              fetchLines(data.fulfillmentPackNum);
              fetchProcessLines(data.fulfillmentPackNum);
            }}
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
          />
        )} */}
      </ModalDialog>
    </>
  );
};

export default PageDialog;
