import React from 'react';

export default function useResponsive() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.screen.height,
        width: window.screen.width,
      });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return {
    isMobile: window.screen.width <= 768,
    dimensions,
  };
}
