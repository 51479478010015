import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { changeLanguage } from 'actions/regularActions';
import { DEFAULT_LOCALE, LS_LOCALE_CODE } from 'constants/config';
import en from 'i18n/locales/en';
import es from 'i18n/locales/es';
import zh from 'i18n/locales/zh';
//import zhCN from 'i18n/locales/zh-CN';
import store from 'store';

const getCurrentLocale = () => {
  const lang = localStorage.getItem(LS_LOCALE_CODE) || DEFAULT_LOCALE;

  store.dispatch(changeLanguage(lang));

  return lang;
};

const resources = {
  en: { locale: en },
  es: { locale: es },
  //'zh-CN': { locale: zhCN },
  'zh': { locale: zh },
};

i18next.use(initReactI18next).init({
  //lng: DEFAULT_LOCALE, // if you're using a language detector, do not define the lng option
  lng: getCurrentLocale(),
  debug: false,
  defaultNS: 'locale',
  fallbackLng: DEFAULT_LOCALE,
  resources,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    wait: true,
  },
});

export default i18next;

export const LanguageList = [
  { code: 'en', name: 'English', },
  { code: 'es', name: 'Español', },
  //{code: 'zh-CN', name: '简体中文', },
  { code: 'zh', name: '中文', },
];


export const themeList = [
  { code: 'old', name: 'Classic' },
  { code: 'new', name: 'New' }
]