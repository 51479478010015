import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { message, SetDefaultWarehouseDialog } from 'components/common';
// import FinishDialog from './finish';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../SmartComponent';
import { useTranslation } from 'react-i18next';
import { getPackByBarCode } from 'services/prepack';


const columns = [
    {
      title: 'Item Barcode',
      dataIndex: 'sku',
      key: 'sku',
      render: (text: string, record:any) =><Typography.Paragraph copyable>{`${record.fulfillmentPackLineNum}_${record.sku}`}</Typography.Paragraph>,
    },
    {
      title: 'Pick Progress',
      dataIndex: 'pickedQty',
      key: 'pickedQty',
      render: (text: string, record:any) => <Typography.Text type={record.pickedQty===record.requestQty ?'success' : undefined}>{`${record.pickedQty} / ${record.requestQty}`}</Typography.Text>,
    },
  ]

export default function PickForPack(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [packInfo, setPackInfo] = useState<any>();
  const [lines, setLines] = useState<any[]>([])

  const [dialogClosable, /**setDialogClosable */] = useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] = useState(false);



  const getPackInfo = async (value: string) => {
    setFetching(true);
    try {
      const res = await getPackByBarCode(value);
      if (!res || res.length === 0) {
        message.error('Pack information is not found');
        setPackInfo(undefined)
        setLines([])
        return;
      }
      setPackInfo(res);
      const tempLines: any[] = [];
       res.map((i:any)=>{
        i.packLineItems.map((item:any) => {
          tempLines.push(item)
          return true
        })
        return true
       })
       setLines(tempLines)
    } catch (error) {
      console.log(error);
      setPackInfo(undefined)
      setLines([])
    } finally {
      setFetching(false);
    }
  }

  return (
    <div style={{ marginTop: 20 }}>
      
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.prepack.checkProgress.scanBarCode")}
            </SmartFormLabel>
            <SmartScanner
              id="scanItemNum"
              style={{ marginTop: 10 }}
              onChangeValue={(value) => { getPackInfo(value) }}
            />
          </SmartSpace>

            {packInfo&&<Table
              size="small"
              rowKey="fulfillmentPackLineNum"
              bordered
              columns={columns}
              dataSource={lines}
              loading={fetching}
              pagination={false}
              style={{ marginTop: 10, marginBottom: 10 }}
            />}
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      


      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div >
  );
}
