import { Input, Select, Button, Form, Checkbox } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  GreyCardWrapper,
  FormLabel,
} from 'components/common';
import SelectField from './SelectField';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

type Props = {
  onSearch: (
    field: string,
    key: string,
    warehouse: WarehouseRow | undefined,
    poStatus: Array<number>,
    waitClose: boolean,
  ) => Promise<void>;
};
export default function ConditionForm(props: Props): JSX.Element {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const startTimeStr = searchParams.get('startTime');
  const defaultWarehouse = searchParams.get('warehouse');
  const [loading, setLoading] = useState<boolean>(false);
  const [field, setField] = useState<string>('poNumber');
  // const [status, setStatus] = useState<string>('-1');
  const [poStatus, setPoStatus] = useState<Array<number>>([]);
  const [waitClose, setWaitClose] = useState<boolean>(false);
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [key, setKey] = useState<string>('');
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(defaultWarehouse? defaultWarehouse : undefined);
  const { onSearch } = props;
  /**
   * handle search event
   */
  const handleSearch = useCallback(async (fromDashboard: boolean = false) => {
    setLoading(true);
    if(fromDashboard) {
      await onSearch(field, key, selectedWarehouse, poStatus, true);
    } else {
      await onSearch(field, key, selectedWarehouse, poStatus, waitClose);
    }
    setLoading(false);
  }, [onSearch, field, key, selectedWarehouse, poStatus, waitClose]);

  useEffect(() => {
    if (startTimeStr) {
      setWaitClose(true);
      handleSearch(true)
    }
    // eslint-disable-next-line
  }, [startTimeStr])

  return (
    <GreyCardWrapper>
      <Form layout={'inline'}>
        <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
          <Select
            id="warehouse_select"
            showSearch
            value={selectedWarehouse}
            allowClear
            style={{ width: 200 }}
            onChange={(value) =>{
              setSelectedWarehouse(value)}}
          >
            {whs
              .filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5))
              .map((i) => (
                <Select.Option key={i.id} value={i.code}>
                  {i.code}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <SelectField
              value={field}
              onChange={(value) => {
                setField(value);
              }}
            />
          }
          labelCol={{ style: { display: 'flex', flexDirection: 'row' } }}
        >
          <Input
            value={key}
            placeholder="3 characters minimum"
            allowClear
            onChange={(e) => {
              setKey(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label={<FormLabel>PO Status</FormLabel>}>
          <Select
            id="poStatus_selector"
            mode="multiple"
            allowClear
            style={{ minWidth: 200 }}
            onChange={e => setPoStatus(e)}
          >
            <Select.Option value={1}>Open</Select.Option>
            <Select.Option value={2}>Paritially Received</Select.Option>
            <Select.Option value={3}>Received</Select.Option>
            <Select.Option value={4}>Over Received</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Checkbox id="waitClose_checkbox" checked={waitClose} onChange={e => setWaitClose(e.target.checked)}>Waiting for closing</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loading}
            type={'primary'}
            onClick={()=> handleSearch()}
          >
            <SearchOutlined />
            Search
          </Button>
        </Form.Item>
      </Form>
    </GreyCardWrapper>
  );
}
