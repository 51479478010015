import React, { useContext } from 'react';
import { Button, ButtonProps } from 'antd';
import { ThemeContext } from 'styled-components';

interface ButtonPlusProps extends ButtonProps {}
export default function ButtonPlus(props: ButtonPlusProps): JSX.Element {
  const { children, ...other } = props;
  const { fontSize } = useContext(ThemeContext);
  return (
    <Button style={{ height: 'auto', fontSize }} {...other}>
      {children}
    </Button>
  );
}
type DataGridButtonProps = {
  children: React.ReactNode;
  type?: 'link';
  onClick?: () => void;
};
export const DataGridButton = (props: DataGridButtonProps): JSX.Element => {
  const { children, onClick, type } = props;
  return (
    <div
      style={type === 'link' ? { color: '#1976d2', cursor: 'pointer' } : {}}
      onClick={() => {
        if (type === 'link' && typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};
