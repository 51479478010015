import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Dropdown,
  Input,
  Modal,
  Menu,
  // Popover,
  Row,
  Space,
  Tooltip,
  Form,
  Select,
  InputNumber,
} from 'antd';
import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
  SettingFilled,
  CloudUploadOutlined,
  SearchOutlined,
  ClearOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
//import LocationCodeCell from './LocationCodeCell';
import {
  ButtonIcon,
  BoolCell,
  DataGridPlus,
  ExportCSV,
  ImportDialog,
  Loading,
  PrintLabel as PrintLabelDialog,
  ScreenMask,
  SpaceCell,
  message,
  FormLabel,
  TablesFilter,
} from 'components/common';
import { GreyCardWrapper, deleteIconStyle } from 'components/common/styles';
import {
  DATAGRID_CELL_ICON_WIDTH1,
  // DEFAULT_DATE_TIME_FORMAT,
  LOADING_ICON_SIZE1,
} from 'constants/config';
import {
  batchDeleteWarehouseLocations,
  deleteWarehouseLocationEntity,
  fetchWarehouseLocationChildren,
  // fetchWarehouseLocationEntity,
  fetchWarehouseLocationList,
  // patchWarehouseLocationZone,
} from 'services/warehouse';
import { exportBusinessFile } from 'services/files';
import {
  boolSorter,
  getCommonDialogHeight,
  onSelectionChange,
  formatToInch,
  formatToPound,
  // convertTimeByUtc,
} from 'utils';
// import AssignZone from './AssignZoneDialog';
import SetAspectDialog from './SetAspectDialog';
import AddLocationDialog from './AddLocationDialog';
// import moment from 'moment';

/**
 * The location dialog properties.
 *
 * @param {?boolean} editContainer - whether is edit container
 * @param {?{[key: string]: any} parentLocation - the parent location object
 * @param {number} warehouseId - the warehouse id
 */
type Props = {
  editContainer?: boolean;
  parentLocation?: StringKAnyVPair;
  warehouseNum: number;
  warehouseId: string;
  clickAddLocation?: number;
};

type ColumnRenderType = {
  data: WarehouseLocationRow;
};
export default function EditLocationDialog(props: Props) {
  const { warehouseNum, editContainer, warehouseId, clickAddLocation } = props;
  // const [isSearchLocation, setIsSearchLocation] = React.useState(false);
  const [loadingLocation, setLoadingLocation] = React.useState(false);
  const [locationList, setLocationList] = React.useState<
    WarehouseLocationRow[]
  >([]);
  const [filterLocationList, setFilterLocationList] = React.useState<
    WarehouseLocationRow[]
  >([]);
  // eslint-disable-next-line
  const [locationParentId, setLocationParentId] = React.useState(
    props.parentLocation && 'number' === typeof props.parentLocation.id
      ? props.parentLocation.id
      : -1
  );
  // eslint-disable-next-line
  const [locationStrId, setLocationStrId] = React.useState(
    props.parentLocation?.strId || ''
  );
  const [selected, setSelected] = React.useState<any>({});

  const [lockScreen, setLockScreen] = React.useState(false);
  const [printLabelVisible, setPrintLabelVisible] = React.useState(false);
  const [labelData, setLabelData] = React.useState<PrintLabelTypes[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<WarehouseLocationRow[]>(
    []
  );
  const [allTempList, setAllTempList] = useState<any[]>([]);
  const [keyword, setKeyword] = React.useState<string>('');
  const [importDialogVisible, setImportDialogVisible] = React.useState(false);
  const locationTableHeight = getCommonDialogHeight(890) - 300;
  const searchValue = React.useRef<string>('');
  // const [assignZoneVisible, setAssignZoneVisible] = useState<boolean>(false);
  // const [id, setId] = useState<string>('');
  const history = useHistory();
  const [aspectDialogVisible, setAspectDialogVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<any[]>([]);
  const [addLocationDialogVisible, setAddLocationDialogVisible] =
    useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (clickAddLocation) {
      setAddLocationDialogVisible(true);
    }
  }, [clickAddLocation]);

  // useEffect(() => {
  //   console.log(selectedZoneNum);
  //   if (selectedZoneNum.current) {
  //     setAssignAble(true);
  //   } else {
  //     setAssignAble(false);
  //   }
  // }, [selectedZoneNum]);

  const menu = (data: any) => (
    <>
      <Menu>
        <Menu.Item key="2">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={<SettingFilled />}
            onClick={() => {
              setCurrentLocation([data]);
              setAspectDialogVisible(true);
            }}
          >
            Set Aspect
          </Button>
        </Menu.Item>
        <Menu.Item key="0">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={<PrinterOutlined />}
            onClick={() => {
              setPrintLabelVisible(true);
              setLabelData([
                {
                  code: data.code,
                  quantity: 1,
                },
              ]);
            }}
          >
            Print
          </Button>
        </Menu.Item>
        <Menu.Item key="1">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            className="hover-danger-button-2"
            icon={<DeleteOutlined style={deleteIconStyle} />}
            onClick={() => deleteLocation(data)}
          >
            Delete
          </Button>
        </Menu.Item>
      </Menu>
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
    </>
  );

  const columns = [
    {
      minWidth: 120,
      name: 'locationCode',
      header: 'Location',
      defaultFlex: 1,
      // headerAlign: 'center',
      userSelect: true,
      render: (value: ColumnRenderType) => {
        const { data } = value;

        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.code);
                    message.info(`"${data.code}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            text={data.code}
          />
        );
      },
    },
    {
      minWidth: 100,
      name: 'parentCode',
      header: 'Parent',
      userSelect: true,
      defaultFlex: 1,
      // headerAlign: 'center',
    },
    {
      name: 'locationAspectsName',
      header: 'Aspects Type',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'zoneName',
      header: 'Location Zone',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'reserved',
      header: 'Reserve Location',
      defaultFlex: 1,
      minWidth: 120,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return <BoolCell value={data.reserved} />;
      },
      // renderHeader: (cellProps: any) => {
      //   return (
      //     <Popover placement="topLeft" content={'Reserve Location'}>
      //       {'Reserve Location'}
      //     </Popover>
      //   );
      // },
      sort: boolSorter,
    },
    {
      name: 'backstock',
      header: 'Backstock Location',
      defaultFlex: 1,
      minWidth: 120,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return <BoolCell value={data.backstock} />;
      },
      sort: boolSorter,
    },
    {
      name: 'path',
      header: 'Path',
      defaultFlex: 1,
      sortable: false,
    },
    // {
    //   name: 'Update Date',
    //   header: 'updateDate',
    //   defaultFlex: 1,
    //   needToolTip: true,
    //   render: (row: any) => {
    //     const { data } = row;
    //     if (data.updateDate instanceof Date && !isNaN(data.updateDate.getTime())) {
    //       const str = convertTimeByUtc(moment(data.updateDate).format(DEFAULT_DATE_TIME_FORMAT));
    //       return (
    //         <SpaceCell
    //           icon={
    //             <Tooltip
    //               placement="top"
    //               title="Copy"
    //               trigger={['hover', 'click']}
    //             >
    //               <CopyOutlined
    //                 onClick={() => {
    //                   copy(str);
    //                   message.info(`"${str}" has been copied to clipboard`);
    //                 }}
    //                 style={{ color: theme['@info-color'] }}
    //               />
    //             </Tooltip>
    //           }
    //           iconWidth={DATAGRID_CELL_ICON_WIDTH1}
    //           text={str}
    //         />
    //       );
    //     }
    //   }
    // },
    {
      name: 'createBy',
      header: 'Created By',
      // headerAlign: 'center',
      defaultFlex: 1,
      needToolTip: true,
    },
    {
      name: 'action',
      header: 'Action',
      defaultFlex: 1,
      showColumnMenuTool: false,
      groupBy: false,
      maxWidth: 100,
      minWidth: 100,
      sortable: false,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <Dropdown overlay={menu(data)} trigger={['click']}>
            <Button icon={<SettingFilled />}>
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const filterLocations = useCallback(
    (locations: WarehouseLocationRow[]) => {
      setSelected([]);
      setSelectedRow([]);
      const params = form.getFieldsValue();
      const temp = locations.filter((i: any) => {
        const isLocatonCode = params.locationCode
          // ? params.locationCode. === i.locationCode
          ? i.locationCode.toLowerCase().indexOf((params.locationCode || '').trim().toLowerCase()) === 0
            ? true
            : false
          : true;
        const isZoneName = params.zoneName
          ? params.zoneName === i.zoneName
            ? true
            : false
          : true;
        const isLocationAspectsName = params.locationAspectsName
          ? params.locationAspectsName === i.locationAspectsName
            ? true
            : false
          : true;
        const isMaxLoad =
          params.maxLoad || params.maxLoad === 0
            ? i.locationAspects?.maxLoad || i.locationAspects?.maxLoad === 0
              ? params.maxLoadOperator === 'ge'
                ? Number(params.maxLoad) <=
                  formatToPound(
                    i.locationAspects?.maxLoad,
                    i.locationAspects?.weightUnit
                  )
                  ? true
                  : false
                : Number(params.maxLoad) >=
                  formatToPound(
                    i.locationAspects?.maxLoad,
                    i.locationAspects?.weightUnit
                  )
                  ? true
                  : false
              : false
            : true;
        const isCapacityLength =
          params.capacityLength || params.capacityLength === 0
            ? i.locationAspects?.capacityLength ||
              i.locationAspects?.capacityLength === 0
              ? params.capacityLengthOperator === 'ge'
                ? Number(params.capacityLength) <=
                  formatToInch(
                    i.locationAspects?.capacityLength,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
                : Number(params.capacityLength) >=
                  formatToInch(
                    i.locationAspects?.capacityLength,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
              : false
            : true;
        const isCapacityWidth =
          params.capacityWidth || params.capacityWidth === 0
            ? i.locationAspects?.capacityWidth ||
              i.locationAspects?.capacityWidth === 0
              ? params.capacityWidthOperator === 'ge'
                ? Number(params.capacityWidth) <=
                  formatToInch(
                    i.locationAspects?.capacityWidth,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
                : Number(params.capacityWidth) >=
                  formatToInch(
                    i.locationAspects?.capacityWidth,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
              : false
            : true;
        const isCapacityHeight =
          params.capacityHeight || params.capacityHeight === 0
            ? i.locationAspects?.capacityHeight ||
              i.locationAspects?.capacityHeight === 0
              ? params.capacityHeightOperator === 'ge'
                ? Number(params.capacityHeight) <=
                  formatToInch(
                    i.locationAspects?.capacityHeight,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
                : Number(params.capacityHeight) >=
                  formatToInch(
                    i.locationAspects?.capacityHeight,
                    i.locationAspects?.dimensionUnit
                  )
                  ? true
                  : false
              : false
            : true;
        return (
          isLocatonCode &&
          isZoneName &&
          isLocationAspectsName &&
          isMaxLoad &&
          isCapacityLength &&
          isCapacityWidth &&
          isCapacityHeight
        );
      });
      setFilterLocationList(temp);
    },
    [form]
  );

  const getChildLocationList = useCallback(
    async (
      warehouseId: string,
      warehouseNum: number,
      locationNum: number,
      locationId: string,
      code?: string
    ) => {
      try {
        const list = await fetchWarehouseLocationChildren(
          warehouseId,
          locationId
        );

        setLoadingLocation(false);

        if (list && Array.isArray(list)) {
          let locations = list.map((e) => {
            return {
              id: e.locationNum,
              code: e.locationCode,
              parent: e.parentNum,
              parentCode: e.parentCode,
              quantity: e.quantity,
              creator: e.createBy,
              reserved: e.reserved,
              backstock: e.backstock,
              locationId: e.locationId,
              warehouseNum: e.warehouseNum,
              warehouseId: e.warehouseId,
              locationNum: e.locationNum,
              locationAspectsName: e.locationAspectsName || '',
              ...e,
            };
          });
          if (code && 'string' === typeof code) {
            locations = locations.filter((e) => e.code.indexOf(code) > -1);
          }

          setLocationList(locations);
          filterLocations(locations);
        }
      } catch (e) {
        setLoadingLocation(false);
        message.error('Get containers failed');
      }
    },
    [filterLocations]
  );

  const getWarehouseLocationList = React.useCallback(
    async (id: string, code?: string) => {
      const sortBy = await form.getFieldValue('sortBy');
      try {
        const list = await fetchWarehouseLocationList(id, false, code, undefined, sortBy);
        setLoadingLocation(false);

        if (list && Array.isArray(list)) {
          const temp = list.map((e) => {
            return {
              id: e.locationNum,
              code: e.locationCode,
              parent: e.parentNum,
              parentCode: e.parentCode,
              quantity: e.quantity,
              creator: e.createBy,
              reserved: e.reserved,
              backstock: e.backstock,
              warehouseNum: e.warehouseNum,
              warehouseId: e.warehouseId,
              locationId: e.locationId,
              path: e.path,
              locationAspectsName: e.locationAspectsName || '',
              ...e,
            } as any;
          });
          setLocationList(temp);
          // setFilterLocationList(temp);
          filterLocations(temp);
          setKeyword(code ? code : '');
        }
      } catch (e) {
        setLoadingLocation(false);
        console.log('fetch locations error: ', e);
      }
    },
    [filterLocations, form]
  );

  const downloadWarehouseLocationList = useCallback(
    async (SelectField) => {
      try {
        const job = await exportBusinessFile(1, {
          FilterCondition: {
            warehouseId: warehouseId,
            keyword: keyword,
          },
          SelectField: { warehouseCode: 'Warehouse', ...SelectField },
          OrderByField: 'warehouseCode desc',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, keyword, warehouseId]
  );

  const handleSearchLocationList = React.useCallback(async () => {
    // setIsSearchLocation(true);
    setLoadingLocation(true);

    if (props.editContainer) {
      await getChildLocationList(
        warehouseId,
        warehouseNum,
        locationParentId,
        locationStrId,
        searchValue.current
      );
    } else {
      await getWarehouseLocationList(warehouseId, searchValue.current);
    }

    // setIsSearchLocation(false);
  }, [
    getWarehouseLocationList,
    locationParentId,
    locationStrId,
    searchValue,
    warehouseId,
    warehouseNum,
    props,
    getChildLocationList,
  ]);

  const deleteLocation = useCallback(
    (location: any) => {
      Modal.confirm({
        title: `Do you want to delete location '${location.code}'?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'default',
        okButtonProps: { className: 'hover-danger-button' },
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          setLockScreen(true);
          deleteWarehouseLocationEntity(
            location.warehouseId,
            location.locationId
          )
            .then((res) => {
              setLockScreen(false);

              if (res) {
                // deleteFn(location.id);
                message.success(
                  `Location '${location.code}' was deleted successfully!`
                );
                handleSearchLocationList();
              } else {
                message.error(`Deleting location '${location.code}' failed.`);
              }
            })
            .catch((e) => {
              setLockScreen(false);
              message.error(`Deleted location '${location.code}' error: ${e}`);
              console.log('deleted location error:', e);
            });
        },
        onCancel() { },
      });
    },
    [handleSearchLocationList]
  );

  // const onSelectLocationOption = (option: StringKAnyVPair) => {
  //   // setLocationParentCode(option.code);
  //   if (props.editContainer) {
  //     setLoadingLocation(true);
  //     getChildLocationList(warehouseId, warehouseNum, option.id, option.strId);
  //   }
  // };

  const loadData = useCallback(() => {
    if (editContainer) {
      if (locationParentId > 0) {
        setLoadingLocation(true);
        getChildLocationList(
          warehouseId,
          warehouseNum,
          locationParentId,
          locationStrId
        );
      }
    } else {
      setLoadingLocation(true);
      getWarehouseLocationList(warehouseId);
    }
  }, [
    editContainer,
    locationParentId,
    locationStrId,
    warehouseNum,
    warehouseId,
    getWarehouseLocationList,
    getChildLocationList,
  ]);

  const batchDeleteLocations = async () => {
    setLockScreen(true);
    try {
      const arr = selectedRow.map(loc => loc.locationNum);
      const res = await batchDeleteWarehouseLocations(warehouseId, arr);
      if (res) {
        message.success('Batch delete locations successfully!')
        setTimeout(() => {
          handleSearchLocationList();
        }, 500);
      }
    } catch (err: any) {
      // message.error(`Batch delete locations failed! error: ${error}`);
      const {error} = err.response.data
      if(error && Array.isArray(error)){
        error.map((i: string)=>{
          message.error(i)
          return true
        })
      }


    } finally {
      setLockScreen(false)
    }
  }
  // React.useEffect(() => {
  //   //const dialogHeight = getCommonDialogHeight();
  //   //setGridHeight(dialogHeight - WAREHOUSE_LOCATION_DIALOG_FORM_HEIGHT);
  //   if (!locationInited) {
  //     setLocationInited(true);
  //     loadData();
  //   }
  // }, [locationInited, loadData]);

  useEffect(() => {
    setSelected([]);
    setSelectedRow([]);
  }, [locationList])

  useEffect(() => {
    if (warehouseId) {
      loadData();
    }
  }, [warehouseId, loadData]);
  let customProps = {} as any;
  if (selectedRow.length === 0) {
    customProps = {
      ...customProps,
      selected: [],
    };
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          capacityLengthOperator: 'ge',
          capacityWidthOperator: 'ge',
          maxLoadOperator: 'ge',
          capacityHeightOperator: 'ge',
        }}
      >
        <Row justify="center">
          <Row style={{ width: '100%', maxWidth: 1600 }}>
            <Col span={8}>
              <Form.Item
                name="locationCode"
                label={<FormLabel>Location Code</FormLabel>}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="zoneName"
                label={<FormLabel>Location Zone</FormLabel>}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="locationAspectsName"
                label={<FormLabel>Aspect Type</FormLabel>}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<FormLabel>Max Load</FormLabel>}>
                <Input.Group compact>
                  <Form.Item noStyle name="maxLoadOperator">
                    <Select style={{ width: 65 }}>
                      <Select.Option value="ge">{'>='}</Select.Option>
                      <Select.Option value="le">{'<='}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="maxLoad">
                    <InputNumber
                      min={0}
                      style={{ width: 'calc(100% - 65px)' }}
                      addonAfter="Pound"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<FormLabel>Capacity Length</FormLabel>}>
                <Input.Group compact>
                  <Form.Item noStyle name="capacityLengthOperator">
                    <Select style={{ width: 65 }}>
                      <Select.Option value="ge">{'>='}</Select.Option>
                      <Select.Option value="le">{'<='}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="capacityLength">
                    <InputNumber
                      min={0}
                      style={{ width: 'calc(100% - 65px)' }}
                      addonAfter="Inch"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<FormLabel>Capacity Width</FormLabel>}>
                <Input.Group compact>
                  <Form.Item noStyle name="capacityWidthOperator">
                    <Select style={{ width: 65 }}>
                      <Select.Option value="ge">{'>='}</Select.Option>
                      <Select.Option value="le">{'<='}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="capacityWidth">
                    <InputNumber
                      min={0}
                      style={{ width: 'calc(100% - 65px)' }}
                      addonAfter="Inch"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={<FormLabel>Capacity Height</FormLabel>}>
                <Input.Group compact>
                  <Form.Item noStyle name="capacityHeightOperator">
                    <Select style={{ width: 65 }}>
                      <Select.Option value="ge">{'>='}</Select.Option>
                      <Select.Option value="le">{'<='}</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle name="capacityHeight">
                    <InputNumber
                      min={0}
                      style={{ width: 'calc(100% - 65px)' }}
                      addonAfter="Inch"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="sortBy" initialValue="UpdateDate DESC" label={<FormLabel>Sort By</FormLabel>}>
                <Select>
                  <Select.Option value="UpdateDate DESC">Update Date Descending</Select.Option>
                  <Select.Option value="UpdateDate ASC">Update Date Ascending</Select.Option>
                  <Select.Option value="CreateDate DESC">Create Date Descending</Select.Option>
                  <Select.Option value="CreateDate ASC">Create Date Ascending</Select.Option>
                  <Select.Option value="LocationCode DESC">Location Code Descending</Select.Option>
                  <Select.Option value="LocationCode ASC">Location Code Ascending</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Row justify="center">
          <Space>
            <Button
              id="search_button"
              type="primary"
              onClick={() => {
                // filterLocations(locationList);
                handleSearchLocationList()
              }}
            >
              <SearchOutlined />
              Search
            </Button>
            <Button
              id="clear_button"
              htmlType="button"
              onClick={() => {
                form.resetFields();
                setFilterLocationList([...locationList]);
              }}
            >
              <ClearOutlined />
              Clear
            </Button>
          </Space>
        </Row>
      </Form>
      <GreyCardWrapper>
        <Row justify="space-between">
          <Space>
            <Button
              type="primary"
              disabled={selectedRow.length === 0 ? true : false}
              onClick={() => {
                setPrintLabelVisible(true);
                const data = selectedRow.map((item) => {
                  return {
                    code: item.code + '',
                    quantity: 1,
                  };
                });
                setLabelData(data);
              }}
            >
              <ButtonIcon className="bi-upc-scan" />
              Batch Print
            </Button>
            <Button
              type="primary"
              disabled={selectedRow.length === 0 ? true : false}
              onClick={() => {
                setCurrentLocation([...selectedRow]);
                setAspectDialogVisible(true);
              }}
            >
              {/* <ButtonIcon className="bi-upc-scan" /> */}
              Batch Set Aspect
            </Button>
            <Button
              type="primary"
              danger
              disabled={selectedRow.length === 0 ? true : false}
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to delete these locations?',
                  content: <>
                    {
                      selectedRow.map((loc, index) => (
                        <div key={`batch-delete-loc-${index}`}>{`${index + 1}. ${loc.locationCode}`}</div>
                      ))
                    }
                  </>,
                  okText: 'Yes',
                  okType: 'default',
                  okButtonProps: { className: 'hover-danger-button' },
                  cancelText: 'No',
                  autoFocusButton: 'cancel',
                  onCancel() { },
                  onOk() {
                    batchDeleteLocations();
                  },
                })
              }}
            >
              Batch Delete
            </Button>
            <TablesFilter
              columns={columns}
              dataSource={filterLocationList}
              setFilteredData={setFilteredData}
            />
          </Space>

          {/* <Col span={9}>
            <Input.Search
              placeholder="Search content"
              enterButton="Search"
              loading={isSearchLocation}
              // onChange={(e) => (searchValue.current = e.target.value)}
              onSearch={(value) => {
                searchValue.current = value;
                handleSearchLocationList();
              }}
            />
          </Col> */}

          <Space>
            <ExportCSV
              aliasText='Download All Locations'
              columns={columns.filter((column) => {
                if (column.header === 'Action') {
                  return false;
                }
                return true;
              })}
              data={locationList}
              scope="locations"
              formatColumns={{
                reserved: { '0': 'No', '1': 'Yes' },
              }}
              downloadFromApi={(data) => downloadWarehouseLocationList(data)}
            />
            <Button type="default" onClick={() => setImportDialogVisible(true)}>
              <CloudUploadOutlined />
              Import Location
            </Button>
          </Space>
        </Row>
      </GreyCardWrapper>
      {
        // <Table
        //   bordered
        //   columns={locationColumns}
        //   showSorterTooltip={false}
        //   rowKey={(record: WarehouseLocationRow) => record.id}
        //   dataSource={locationList}
        //   loading={loadingLocation}
        //   style={{ height: locationTableHeight, overflow: 'auto' }}
        // />
      }
      <DataGridPlus
        style={{ height: locationTableHeight }}
        columns={columns}
        // dataSource={filterLocationList.sort((a, b) =>
        //   (b.locationAspectsName || '').localeCompare(
        //     a.locationAspectsName || ''
        //   )
        // )}
        dataSource={filteredData}
        rowKey="id"
        showColumnMenuTool={false}
        pagination="local"
        loading={loadingLocation}
        checkboxColumn
        selected={selected}
        onSortInfoChange={() => {
          setSelected({});
          setSelectedRow([]);
        }}
        onChange={() => {
          setSelected({});
          setSelectedRow([]);
        }}
        onSelectionChange={(props) =>
          onSelectionChange(
            props,
            allTempList,
            setSelected,
            setSelectedRow,
            'id',
            true,
            setAllTempList
          )
        }
        checkboxOnlyRowSelect={true}
        autoWith={false}
        /* eslint-disable react/jsx-props-no-spreading */
        {...({ customProps } as any)}
      />
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
      {printLabelVisible && (
        <PrintLabelDialog
          visible={true}
          onHide={() => {
            setSelectedRow([]);
            setSelected({});
            setPrintLabelVisible(false);
          }}
          printType="location"
          labelData={labelData}
        />
      )}
      {importDialogVisible && (
        <ImportDialog
          onRefesh={() => { }}
          onHide={() => setImportDialogVisible(false)}
          operation={{ key: 2101, value: 'Location', name: 'Location' }}
          showModal
        />
      )}
      {/* {assignZoneVisible && (
        <AssignZone
          visible
          warehouseId={warehouseId}
          onHide={() => {
            setAssignZoneVisible(false);
            setId('');
            clearLocationForm();
            handleSearchLocationList(); //refresh location
          }}
          onRefresh={async (zoneId: string) => {
            const res1 = await patchWarehouseLocationZone(
              warehouseId,
              id,
              Number(zoneId)
            );
            if (res1) {
              message.success({ content: 'Assigned to zone successfully' });
            }
            setAssignZoneVisible(false);
            setId('');
            clearLocationForm();
            handleSearchLocationList(); //refresh location
          }}
        />
      )} */}
      {aspectDialogVisible && currentLocation.length > 0 && (
        <SetAspectDialog
          location={currentLocation}
          visible={true}
          onHide={() => {
            setAspectDialogVisible(false);
            setSelectedRow([]);
            setSelected({});
          }}
          onRefresh={() => {
            handleSearchLocationList();
          }}
        />
      )}
      {addLocationDialogVisible && (
        <AddLocationDialog
          warehouseNum={warehouseNum}
          warehouseId={warehouseId}
          onHide={() => setAddLocationDialogVisible(false)}
          onRefresh={() => {
            setAddLocationDialogVisible(false);
            handleSearchLocationList(); //refresh location
          }}
          visible={true}
        />
      )}
    </div>
  );
}
