import React from 'react';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';
import { fetchShipmentSignature, fetchSignatureOptions } from 'services/sales';
import { ScanToShip } from '.';

type Props = {
  carrierNum: number;
  onChange?: Function;
  shipmentNum: number;
  signature?: number;
  size: GeneralSizeType;
  style?: StringKAnyVPair;
};

let allOptions: StringKAnyVPair[] = [];

const SignatureSelector = (props: Props) => {
  const { useState } = React;
  const [carrierNum, setCarrierNum] = useState(0);
  const [currentOptions, setCurrentOptions] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>();
  const [shipmentNum, setShipmentNum] = useState(0);
  const [signOptions, setSignOptions] = useState<StringKAnyVPair[]>([]);

  const getCarrierOptions = (options: StringKAnyVPair[], carrierNum: number) => {
    let opts = options.filter(e => e.carrierNum === carrierNum);

    if (opts.length > 0) {
      opts = [{ code: -1, name: ' ', carrierSignatureNum: 0 }, ...opts];
    }

    return opts;
  };

  // eslint-disable-next-line
  const getPropsSignatureInAllOptions = () => {
    const { carrierNum, signature } = props;
    let ret = signature;

    if (typeof signature === 'number') {
      const find = allOptions.filter(e => e.code === signature && e.carrierNum === carrierNum);

      //console.log('fi', find, allOptions);
      if (find.length === 0) ret = undefined;
    }

    //console.log('fi->', ret);
    return ret;
  };

  // eslint-disable-next-line
  const getSignatureOptions = async (carrierNum: number) => {
    if (allOptions.length > 0) {
      setSelectorOptions([...allOptions]);
      setCurrentOptionsByCarrier(allOptions, carrierNum);

      return;
    }

    setIsLoadingOptions(true);

    try {
      //const options = await fetchSignatureOptions(props.carrierNum);
      const options = await fetchSignatureOptions();
      // Place the option carrierSignatureNum === 3 at the end
      const targetIndex = (options || []).findIndex((item: any) => item.carrierSignatureNum === 3);
      if (targetIndex > -1) {
        const target = options[targetIndex];
        options.splice(targetIndex, 1);
        options.push(target);
      }

      if (Array.isArray(options) && options.length > 0) {
        setSelectorOptions([...options]);
        setCurrentOptionsByCarrier(options, carrierNum);
        allOptions = [...options];
      }
    } catch (e) {
      message.error(`Fetch signature options error: ${e}`);
    } finally {
      setIsLoadingOptions(false);
    }
  };

  // eslint-disable-next-line
  const onChange = (value: any) => {
    const val = typeof value === 'number' && value >= 0 ? value : undefined;

    setSelectedValue(val);
    //console.log('on ch', val);

    if (typeof props.onChange === 'function') {
      props.onChange(val);
    }
  };

  // eslint-disable-next-line
  const setCurrentOptionsByCarrier = (
    options: StringKAnyVPair[],
    carrierNum: number,
  ) => {
    const current = getCarrierOptions(options, carrierNum);
    const { signature } = props;
    let value = typeof signature === 'number' ? signature : undefined;

    if (typeof signature === 'number') {
      const find = current.filter(e => e.code === signature);

      if (find.length === 0) value = undefined;
    }

    setCurrentOptions(current);
    onChange(value);

    return current;
  };

  // eslint-disable-next-line
  const setSelectorOptions = (options: StringKAnyVPair[]) => {
    setSignOptions(options);
    //allOptions = options;
  };

  // eslint-disable-next-line
  const trySelectValue = async (
    shipmentNum: number,
    carrierNum: number,
  ) => {
    if (shipmentNum < 1 && shipmentNum < 1) return;

    const options = getCarrierOptions(signOptions, carrierNum);

    if (options.length > 0) {
      setIsLoadingOptions(true);
      try {
        // the carrier signature configuration is in catalog
        const signature = await fetchShipmentSignature(shipmentNum, carrierNum);

        setIsLoadingOptions(false);

        if (signature && typeof signature === 'object') {
          const { code } = signature;
          const items = options.filter(e => e.code === code);

          if (items.length > 0) {
            onChange(code);
          }
        } else {
          //console.log('h->', undefined, signature);
          onChange(undefined);
        }
      } catch (e) {
        setIsLoadingOptions(false);
        message.error('fetech shipment signature failed');
      }
    }
  };

  React.useEffect(() => {
    let trySelect = false;

    if (!inited) {
      console.log('ps', props);
      if (props.carrierNum > -1000) {
        getSignatureOptions(props.carrierNum);
        setInited(true);
      }
    }

    //if (signOptions.length > 0 && currentOptions.length > 0 && carrierNum !== props.carrierNum) {
    //if (signOptions.length > 0 && currentOptions.length > 0) {
    if (carrierNum !== props.carrierNum && !isLoadingOptions) {
      setCarrierNum(props.carrierNum);
      setSelectedValue(getPropsSignatureInAllOptions());

      if (signOptions.length > 0) {
        setCurrentOptionsByCarrier(signOptions, props.carrierNum);

        //if (props.signature === undefined) {
        //if (props.signature === undefined || props.signature === -2 || props.carrierNum >= 0) {
        if (props.carrierNum >= 0 && (props.signature === undefined || props.signature === -2 || props.signature === null)) {
          trySelectValue(props.shipmentNum, props.carrierNum);
          trySelect = true;
        }
      }
    }
    //}

    if (shipmentNum !== props.shipmentNum) {
      setShipmentNum(props.shipmentNum);

      if (props.signature === undefined && !trySelect) {
        trySelectValue(props.shipmentNum, props.carrierNum);
      }
    }
  }, [
    carrierNum,
    getPropsSignatureInAllOptions,
    getSignatureOptions,
    inited,
    isLoadingOptions,
    props,
    setCurrentOptionsByCarrier,
    setSelectorOptions,
    shipmentNum,
    signOptions,
    trySelectValue,
  ]);

  return (
    <>
      <Row align="middle">
        {isLoadingOptions ? (
          <LoadingIcon />
        ) : (
          <Select
            allowClear
            id={ScanToShip.labelSignSelectorId}
            onChange={onChange}
            size={props.size}
            style={props.style}
            value={selectedValue}
          >
            {currentOptions.map(e => <Select.Option key={e.carrierSignatureNum} value={e.code}>
              {e.name}
            </Select.Option>)}
          </Select>
        )}
      </Row>
    </>
  );
};

export default SignatureSelector;
