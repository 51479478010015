import { HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment-timezone';
import * as actionTypes from 'actions/actionTypes';
import {
  setSignalConnectionState,
  setSignalConnectionGroup,
} from 'actions/regularActions';
import { pongPrintstation } from 'actions/printActions';
import {
  //setPrintLabelEnd,
  setPrintCartonResult,
  setPrintDocumentResult,
  setPrintLabelMessage,
  setPrintLabelResponse,
  setPrintLabelResult,
  setPrintMultiPackageResult,
  setPrintSlipResult,
  setScaleWeight,
  //setTestPrintMessage,
  setPrintPickListStatus,
  setPrintGroupPackingSlipStatus, //control printpicklist page's loading and refresh
} from 'actions/salesAction';
import {
  //PRINT_CATEGORY_SCAN_SHIP,
  //PRINT_LABEL_END_TYPE,
  PRINT_MESSAGE_TYPE_ERROR,
  PRINT_TYPE_PACK_SLIP,
  SIGNALR_CONNECTION_CONNECTED,
  SIGNALR_CONNECTION_CONNECTING,
  SIGNALR_CONNECTION_DISCONNECTED,
} from 'constants/config';
import { message as showMessage } from 'components/common';
import { printerBaseUrl } from 'services/http';
import store from 'store';
import { selectAuthentiction } from 'store/selector';
import { convertToUtcTime, checkPrintedLabel } from 'utils';
import { userProfiles } from 'utils/auth';

const socketMiddleware = () => {
  // group connection dictionary
  const groupConn: StringKAnyVPair = {};
  const url = printerBaseUrl();
  console.log('sok url ->', url);
  const options = {
    headers: {
      //Authorization: tokenKeeper.apiToken,
      MasterAccountNum: '10001',
      ProfileNum: '10001',
      DeviceId: '1001-10001-DeviceA',
      'x-digitbridge-userid': 'wenchao.ling@hurraymart.com', //TODO no userid. disconnect
    },
  };
  let connection: any = null;

  const connect = () => {
    // eslint-disable-next-line
    const profile = userProfiles.profile;

    //console.log('pp ->', profile, tokenKeeper.apiToken);
    if (profile) {
      //options.headers.Authorization = tokenKeeper.apiToken;
      options.headers.MasterAccountNum = profile.masterAccountNum;
      options.headers.ProfileNum = profile.profileNum;
      options.headers['x-digitbridge-userid'] = profile.email;
    } else return;
    // NOTE HubConnection created
    try {
      connection = new HubConnectionBuilder()
        .withUrl(`${url}/api`, options)
        .withAutomaticReconnect()
        .build();
    } catch (error) {
      console.error('connect hub', error);
    }

    // NOTE Message is received.
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log('Connected!');
          store.dispatch(
            setSignalConnectionState(SIGNALR_CONNECTION_CONNECTED)
          );
          connection.on('newMessage', (message: any) => {
            //console.log('s msg', message);
            switch (message.Type) {
              case 0: {
                let isOnlyMessage = true;

                if (message.Category/*=== PRINT_CATEGORY_SCAN_SHIP*/) {
                  console.log('la ....', message);
                  isOnlyMessage = false;
                  store.dispatch(
                    setPrintLabelResponse({
                      category: message.Category,
                      id: message.Id,
                      message: message.Text,
                      result: message.Type,
                      type: message.PrintType,
                      sender: message.Sender,
                    })
                  );
                }

                switch (message.PrintType) {
                  case PRINT_TYPE_PACK_SLIP:
                    console.log('sl msg', message);
                    store.dispatch(
                      setPrintLabelResponse({
                        id: message.Id,
                        message: message.Text,
                        result: message.Type,
                        type: message.PrintType,
                      })
                    );
                    break;
                  default:
                    break;
                }

                if (isOnlyMessage && message.Text) {
                  //console.log('sss', message);
                  if (message.Text === 'pong') {
                    store.dispatch(pongPrintstation({
                      connectionId: message.ConnectionId,
                      sender: message.Sender,
                      time: (new Date()).getTime(),
                      timestamp: convertToUtcTime(moment().format()),
                    }));
                  } else {
                    showMessage.success({ content: message.Text });
                  }
                }
                break;
              }
              case 1:
                console.log('i msg', message);
                showMessage.warning({ content: message.Text });
                break;

              case 2:
                console.log('e msg', message);
                showMessage.error({ content: message.Text });
                break;

              default:
                break;
            }
          });
          connection.on(
            'newPrintShippingLabelResponse',
            (msg: StringKAnyVPair) => {
              console.log('print label message', msg.Type, msg);
              if (msg.Message && typeof msg.Message === 'string') {
                if (msg.Type === PRINT_MESSAGE_TYPE_ERROR) {
                  store.dispatch(
                    setPrintLabelResponse({
                      category: msg.Category,
                      hold: msg.Hold,
                      id: msg.Id,
                      message: msg.Text,
                      result: msg.Type,
                      type: msg.PrintType,
                    })
                  );
                } else {
                  const obj = JSON.parse(msg.Message);

                  console.log('obj', obj);
                  if (obj && typeof obj === 'object') {
                    obj.Hold = msg.Hold;
                    store.dispatch(setPrintLabelMessage(obj));
                  }

                  if (msg.Hold) {
                    // dispatch the printing response to PrintLabel component,
                    // then PrintLabel can unlock the screen
                    store.dispatch(
                      setPrintLabelResponse({
                        id: msg.Id,
                        type: msg.PrintType,
                      })
                    );
                  }
                }
              }
            }
          );
          connection.onreconnected(() => {
            //console.log('sig reconnected');
            store.dispatch(
              setSignalConnectionState(SIGNALR_CONNECTION_CONNECTED)
            );
          });
          connection.onreconnecting(() => {
            //console.log('sig rec', e);
            store.dispatch(
              setSignalConnectionState(SIGNALR_CONNECTION_CONNECTING)
            );
          });
          connection.onclose(() => {
            store.dispatch(
              setSignalConnectionState(SIGNALR_CONNECTION_DISCONNECTED)
            );
          });
          //console.log('cccc', connection);
        })
        .catch((e: any) => console.log('Connection failed: ', e));
      console.log('connection', connection.connectionState);
    }
  };

  const isConnectionReady = () => {
    let ret = true;

    if (!connection) ret = false;

    if (connection.state !== 'Connected') ret = false;

    return ret;
  };

  const subscribeAuthentication = () => {
    const handleChange = () => {
      const authentication = selectAuthentiction();
      const { authed, userIsValid } = authentication;

      if (authed && userIsValid) {
        if (!connection) {
          connect();
        }
        console.log('or check to reconnect');
      } else {
        if (!connection) {
          connect(); // this code need to remove in the future
        }
        //console.log('determine whether need to disconnect');
      }
    };

    return store.subscribe(handleChange);
  };

  const weightMsgHandler = (msg: any) => {
    //console.log('weight', msg);
    const { Weight } = msg;

    if (Weight && 'object' === typeof Weight) {
      store.dispatch(setScaleWeight(Weight));
    }
  };

  setTimeout(() => {
    subscribeAuthentication();
  }, 0);

  // NOTE Message is sending.
  return (next: any) => async (action: any) => {
    switch (action.type) {
      case actionTypes.PING_PRINTSTATION: {
        // not need await here
        try {
          connection.invoke('Ping', action.payload);
        } catch (e) {
          console.log(`ping error: ${e}`);
        }
        break;
      }
      case actionTypes.PRINT_RECEIVE: {
        console.log('socketMiddleware: ', action);
        const printReceiveResult = await connection.invoke(
          'printReceive',
          action.payload
        ); //test print
        console.log(printReceiveResult);
        if (printReceiveResult.isSuccess) {
          showMessage.success({
            content: printReceiveResult.message || 'Add printTask success',
          });
        } else {
          showMessage.error({ content: printReceiveResult.message });
        }
        break;
      }
      case actionTypes.PRINT_PICK_LIST: {
        console.log('socketMiddleware: ', action);
        try {
          const printPickListResult = await connection.invoke(
            'printPickList',
            action.payload
          );
          console.log(printPickListResult);
          if (printPickListResult.isSuccess) {
            store.dispatch(setPrintPickListStatus(1))
            showMessage.success({
              content: printPickListResult.message || 'Add printTask success',
            });
          } else {
            store.dispatch(setPrintPickListStatus(0))
            showMessage.error({ content: printPickListResult.message });
          }
        } catch (error) {
          console.log(error)
        }
        break;
      }
      case actionTypes.PRINT_RETURN_LIST_DETAIL: {
        try {
          const printReturnListDetailRes = await connection.invoke(
            'PrintRMA',
            action.payload
          )
          console.log(printReturnListDetailRes);
          if (printReturnListDetailRes.isSuccess) {
            showMessage.success({
              content: printReturnListDetailRes.message || 'Add printTask success',
            });
          } else {  
            showMessage.error({ content: printReturnListDetailRes.message });
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case actionTypes.PRINT_BULK_RETURN_DETAIL: {
        try {
          const printBulkReturnDetailRes = await connection.invoke(
            'PrintBulkReturn',
            action.payload
          )
          console.log(printBulkReturnDetailRes);
          if (printBulkReturnDetailRes.isSuccess) {
            showMessage.success({
              content: printBulkReturnDetailRes.message || 'Add printTask success',
            });
          } else {  
            showMessage.error({ content: printBulkReturnDetailRes.message });
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case actionTypes.PRINT_UNPACK_PICK_LIST: {
        try {
          console.log('socketMiddleware: ', action);
          const printUnpackPickListRes = await connection.invoke(
            'PrintUnpackPickList',
            action.payload
          )
          console.log(printUnpackPickListRes);
          if (printUnpackPickListRes.isSuccess) {
            showMessage.success({
              content: printUnpackPickListRes.message || 'Add printTask success',
            });
          } else {  
            showMessage.error({ content: printUnpackPickListRes.message });
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case actionTypes.PRINT_PACK_PICK_LIST: {
        try {
          console.log('socketMiddleware: ', action);
          const printPackPickListRes = await connection.invoke(
            'PrintPrepackPickList',
            action.payload
          )
          console.log(printPackPickListRes);
          if (printPackPickListRes.isSuccess) {
            showMessage.success({
              content: printPackPickListRes.message || 'Add printTask success',
            });
          } else {  
            showMessage.error({ content: printPackPickListRes.message });
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case actionTypes.PRINT_PACK_DETAIL: {
        try {
          console.log('socketMiddleware: ', action);
          const printPackDetailRes = await connection.invoke(
            'PrintPrepackLabel',
            action.payload
          )
          console.log(printPackDetailRes);
          if (printPackDetailRes.isSuccess) {
            showMessage.success({
              content: printPackDetailRes.message || 'Add printTask success',
            });
          } else {  
            showMessage.error({ content: printPackDetailRes.message });
          }
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case actionTypes.PRINT_LABEL: {
        console.log('socketMiddleware: ', action);
        const printLabelResult = await connection.invoke(
          'printLabel',
          action.payload
        ); //test print
        console.log(printLabelResult);
        if (printLabelResult.isSuccess) {
          showMessage.success({
            content: printLabelResult.message || 'Add printTask success',
          });
        } else {
          showMessage.error({ content: printLabelResult.message });
        }
        break;
      }
      case actionTypes.SIGNALR_JOIN_GROUP: {
        console.log('join', action);
        if (!isConnectionReady()) break;
        if (groupConn[action.group]) break;

        const joinRes = await connection.invoke('JoinGroup', action.group);

        if (joinRes && 'object' === typeof joinRes) {
          console.log('join', action.group);
          if (joinRes.code === 200) {
            console.log('join success');
            groupConn[action.group] = true;
            connection.on('newWeight', weightMsgHandler);
            //store.dispatch(setScaleWeight({Lb: 1, Oz: 4.8}));
            store.dispatch(setSignalConnectionGroup(groupConn));
          } else {
            console.log('join group error', joinRes.message);
          }
        } else {
          console.log('join scale group failed', joinRes);
        }
        break;
      }
      case actionTypes.SIGNALR_LEAVE_GROUP: {
        console.log('leave', action.group);
        if (!isConnectionReady()) break;
        if (!groupConn[action.group]) break;

        const leaveRes = await connection.invoke('LeaveGroup', action.group);

        console.log('leave res 1', leaveRes);
        if (leaveRes && 'object' === typeof leaveRes) {
          //console.log('leave res 2', leaveRes);
          if (leaveRes.code === 200) {
            console.log('leave success');
            groupConn[action.group] = false;
            connection.off('newWeight', weightMsgHandler);
            store.dispatch(setScaleWeight({ Lb: null, Oz: null }));
            store.dispatch(setSignalConnectionGroup(groupConn));
          } else {
            console.log('leave group error', leaveRes.message);
          }
        } else {
          console.log('leave scale group failed', leaveRes);
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_CARTON_LABEL: {
        console.log('print carton', action);
        if (!isConnectionReady()) break;

        try {
          const printCartonResult = await connection.invoke(
            'PrintCartonLabel',
            action.payload
          );
          console.log('pr c', printCartonResult);
          checkPrintedLabel(action.payload.ShipmentNum);
          store.dispatch(setPrintCartonResult(printCartonResult));
        } catch (e) {
          console.log(`Print carton label error: ${e}`);
          store.dispatch(setPrintCartonResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_DOCUMENT: {
        console.log('print doc', action);
        if (!isConnectionReady()) break;

        try {
          const printDocResult = await connection.invoke(
            'PrintShipmentDocument',
            action.payload
          );
          console.log('pr c', printDocResult);
          //checkPrintedLabel(action.payload.ShipmentNum);
          store.dispatch(setPrintDocumentResult(printDocResult));
        } catch (e) {
          console.log(`Print document error: ${e}`);
          store.dispatch(setPrintDocumentResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_NOTICE: {
        console.log('print notice', action);
        if (!isConnectionReady()) break;

        try {
          const printNoticeResult = await connection.invoke(
            'PrintNotice',
            action.payload
          );
          //console.log('notice res', printNoticeResult);
          checkPrintedLabel(action.payload.ShipmentNum);
          //store.dispatch(setPrintSlipResult(printNoticeResult));
          store.dispatch(setPrintCartonResult(printNoticeResult));
        } catch (e) {
          console.log('p notice err', e);
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_PACKING_SLIP: {
        console.log('print slip', action);
        if (!isConnectionReady()) break;

        try {
          const printSlipResult = await connection.invoke(
            'PrintPackingSlip',
            action.payload
          );
          //console.log('slip res', printSlipResult);
          store.dispatch(setPrintSlipResult(printSlipResult));
        } catch (e) {
          console.log('p slip err', e);
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.PRINT_CONTAINER_SHIPPING_LABEL: {
        console.log('print container shipping label', action);
        if (!isConnectionReady()) break;
        try {
          const printContainerShippingLabelResult = await connection.invoke(
            'PrintContainerShippingLabel',
            action.payload,
          )
          console.log('pr', printContainerShippingLabelResult);
          store.dispatch(setPrintLabelResult(printContainerShippingLabelResult));
        } catch (e) {
          console.log(`Print label error: ${e}`);
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.REPRINT_CONTAINER_SHIPPING_LABEL: {
        console.log('reprint container shipping label', action);
        if (!isConnectionReady()) break;
        try {
          const reprintContainerShippingLabelResult = await connection.invoke(
            'RePrintShipmentContainer',
            action.payload,
          )
          console.log('pr', reprintContainerShippingLabelResult);
          store.dispatch(setPrintLabelResult(reprintContainerShippingLabelResult));
        } catch (e) {
          console.log(`Print label error: ${e}`);
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_MULTI_PKG_LABEL: {
        console.log('print label', action);
        if (!isConnectionReady()) break;

        try {
          const res = await connection.invoke(
            'PrintShippingLabel2',
            action.payload
          );
          //console.log('pr', res);
          //checkPrintedLabel(action.payload.ShipmentNum);
          store.dispatch(setPrintMultiPackageResult(res));
        } catch (e) {
          console.log(`Print multi-packages error: ${e}`);
          store.dispatch(setPrintMultiPackageResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_PRINT_SHIPMENT_LABEL: {
        console.log('print label', action);
        if (!isConnectionReady()) break;

        try {
          const printLabelResult = await connection.invoke(
            'PrintShipmentLabel',
            action.payload
          );
          console.log('pr', printLabelResult);
          checkPrintedLabel(action.payload.ShipmentNum);
          store.dispatch(setPrintLabelResult(printLabelResult));
        } catch (e) {
          console.log(`Print label error: ${e}`);
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_REPRINT_SHIPMENT: {
        console.log('reprint', action);
        if (!isConnectionReady()) break;

        try {
          const rePrintResult = await connection.invoke(
            'RePrintShipment',
            action.payload
          );
          console.log('pr', rePrintResult);
          store.dispatch(setPrintLabelResult(rePrintResult));
        } catch (e) {
          store.dispatch(setPrintLabelResult({
            code: 500,
            isSuccess: false,
            message: `${e}`,
          }));
        }
        break;
      }

      case actionTypes.SIGNALR_TEST_PRINTER: {
        console.log('test', action);
        if (!isConnectionReady()) break;

        const testPrinterResult = await connection.invoke(
          'TestPrintShipment',
          action.payload
        );
        console.log('pr', testPrinterResult);
        break;
      }
    
      case actionTypes.PRINT_GROUP_PACKING_SLIP: {
        if (!isConnectionReady()) break;
        console.log(action.payload);
        try {
          const printGroupPackingSlipResult = await connection.invoke(
            'PrintGroupPackingSlip',
            action.payload
          );
          console.log('pr', printGroupPackingSlipResult);
          if (printGroupPackingSlipResult.isSuccess) {
            store.dispatch(setPrintGroupPackingSlipStatus(1))
            showMessage.success({
              content: printGroupPackingSlipResult.message || 'Add printTask success',
            });
          } else {
            store.dispatch(setPrintGroupPackingSlipStatus(0))
            showMessage.error({ content: printGroupPackingSlipResult.message });
          }        
        } catch (error) {
          console.log(error)
        }
        break;
      }

      default:
        break;
    }

    return next(action);
  };
};

export default socketMiddleware;
