import React, { useState, useCallback } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space } from 'antd';
import { message } from 'components/common';
import { createTag } from 'services/orderTag';
import { FormLabel } from 'components/common';
import { leaveEditForm } from 'utils';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

interface CreateTagProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
}
// eslint-disable-next-line
export default (props: CreateTagProps) => {
  const { visible, onHide, onRefresh } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const result = await createTag(params.tagName, params.TagDescription);
      setLoading(false);
      if (!result.error) {
        form.resetFields();
        onHide();
        onRefresh();
        message.success({ content: 'Created Tag Successfully！' });
      } else {
        message.error({ content: result.error });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Create Order Tag"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreate}
              loading={loading}
              icon={loading ? <LoadingOutlined /> : <PlusOutlined />}
            >
              {loading ? 'Creating' : 'Create'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="tagName"
            label={<FormLabel>Name</FormLabel>}
            rules={[{ required: true, message: 'Please input tag name!' }]}
          >
            <Input placeholder="input tag name" />
          </Form.Item>

          <Form.Item
            name="TagDescription"
            label={<FormLabel>Description</FormLabel>}
          >
            <Input.TextArea rows={3} placeholder="input description" />
          </Form.Item>

          {/* <Form.Item name="brandDesc" label={<FormLabel>Notes</FormLabel>}>
            <Input.TextArea rows={2} />
          </Form.Item> */}
        </Form>
      </Spin>
    </Modal>
  );
};
