import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Row,
  RowProps,
  ButtonProps,
  Input,
  InputNumber,
  InputProps,
  Spin,
  Tooltip,
  Card,
} from 'antd';
import styled from 'styled-components';
import { ButtonIcon } from 'components/common';
import { useTranslation } from 'react-i18next';


interface SmartScannerProps extends InputProps {
  id?: string;
  isAutoFocus?: number;
  onChangeValue: (value: string) => void;
  onTextChange?: (value: string) => void;
  setNull?: boolean;
  autoSelect?: boolean;
  disabled?: boolean;
  focusNum?: number;
  usualInput?: boolean;
  color?: string;
}
export const SmartScanner = (props: SmartScannerProps): JSX.Element => {
  const { onChangeValue, id, isAutoFocus, onTextChange, setNull = true, autoSelect, disabled, focusNum, usualInput, color, ...other } = props;
  const [value, setValue] = useState('');
  useEffect(() => {
    if (isAutoFocus && id) {
      setTimeout(() => {
        const elem = document.getElementById(id) as HTMLInputElement;
        if (elem) {
          elem.focus();
          if(autoSelect){
            elem.select()
          }
        }
      }, 500);
    }
  }, [id, isAutoFocus, autoSelect]);

  useEffect(() => {
    setValue(props.defaultValue ? `${props.defaultValue}` : '')
  }, [props.defaultValue])
  /**
   * handle  key down event
   */
  const handleKeyDown = useCallback(
    async (evt: any) => {
      if ([9, 13].indexOf(evt.keyCode) > -1) {
        evt.preventDefault();
        onChangeValue(value);
        if (autoSelect && id) {
          const elem = document.getElementById(id) as HTMLInputElement;
          if (elem) {
            elem.select();
          }
        }
        if (setNull) {
          setValue('');
        }
      }
    },
    [onChangeValue, value, setNull, autoSelect, id]
  );

  useEffect(()=>{
    if (focusNum && id) {
      setTimeout(() => {
        const elem = document.getElementById(id) as HTMLInputElement;
        if (elem) {
          elem.focus();
          // if(autoSelect){
          //   elem.select()
          // }
        }
      }, 300);
    }
  },[focusNum, id, autoSelect])
  return (
    usualInput ?
    <SInput
    $color={color}
    id={id}
    autoFocus={isAutoFocus ? true : false}
    allowClear
    onKeyDown={handleKeyDown}
    value={value}
    disabled={disabled}
    onFocus={(e)=>{
      if(autoSelect){
        e.currentTarget.select()
      }
    }}
    onChange={(e) => {
      onTextChange && onTextChange(e.target.value);
      setValue(e.target.value);
    }}
    /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  />:
    <SmartInput
      id={id}
      $color={color}
      autoFocus={isAutoFocus ? true : false}
      allowClear
      onKeyDown={handleKeyDown}
      value={value}
      disabled={disabled}
      onFocus={(e)=>{
        if(autoSelect){
          e.currentTarget.select()
        }
      }}
      onChange={(e) => {
        onTextChange && onTextChange(e.target.value);
        setValue(e.target.value);
      }}
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
    />
  );
};
SmartScanner.defaultProps = {
  id: 'scannerInput',
  isAutoFocus: true,
};
interface SmartRowProps extends RowProps { }
export const SmartRow = (props: SmartRowProps): JSX.Element => {
  const { children, style, ...other } = props;
  return (
    <Row
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...style,
      }}
    >
      {children}
    </Row>
  );
};
interface SmartButtonProps extends ButtonProps {
  type?: 'primary' | 'default';
  color?: string;
  isComingSoon?: boolean;
  backgroundColor?: string;
}
export const SmartButton = (props: SmartButtonProps): JSX.Element => {
  const {
    children,
    style,
    type,
    color = 'white',
    backgroundColor,
    isComingSoon,
    ...other
  } = props;
  const buttonStyle: StringKAnyVPair = {
    width: 250,
    marginBottom: 30,
    minHeight: 45,
    ...props.style
  };
  if (color && backgroundColor) {
    buttonStyle['color'] = color;
    buttonStyle['borderColor'] = backgroundColor;
    buttonStyle['backgroundColor'] = backgroundColor;
  }
  return (
    <Row justify="center" style={style}>
      {isComingSoon ? <Tooltip placement="topRight" title="coming soon">
        <Button disabled {...other} type={type || 'primary'} style={buttonStyle}>
          {children}
        </Button>
      </Tooltip> :
        <Button {...other} type={type || 'primary'} style={buttonStyle}>
          {children}
        </Button>}
    </Row>
  );
};
SmartButton.defaultProps = {
  type: undefined,
  color: undefined,
  backgroundColor: undefined,
};
type SmartFormLabelProps = {
  children?: ReactNode;
  style?: React.CSSProperties;
  addonAfter?: ReactNode;
};
export function SmartFormLabel(props: SmartFormLabelProps): JSX.Element {
  const { children, style, addonAfter } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 500,
        ...style,
      }}
    >
      {children}
      <span>:</span>
      {addonAfter}
    </div>
  );
}
SmartFormLabel.defaultProps = {
  children: undefined,
  style: undefined,
};
type SmartBackButtonProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  type?: 'default' | 'warning';
};
export const SmartBackButton = (props: SmartBackButtonProps): JSX.Element => {
  const { onClick, children, type = 'default' } = props;
  const [more, setMore] = useState(false);
  const { t } = useTranslation()
  return (
    <>
      <SmartText
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setMore(!more);
        }}
      >
        {t("smart.common.moreAction")}
      </SmartText>
      {more && !children && (
        <SmartBlock>
          <SmartButton
            backgroundColor={
              type === 'default' ? 'rgb(0,137,250)' : 'rgb(255,223,138)'
            }
            color={type === 'default' ? 'white' : 'black'}
            onClick={onClick}
          >
            {t("smart.common.goBack")}
          </SmartButton>
        </SmartBlock>
      )}
      {more && children && <>{children}</>}
    </>
  );
};
SmartBackButton.defaultProps = {
  onClick: undefined,
  children: undefined,
  type: undefined,
};
type SmartSpinProps = {
  loading: boolean;
};
export const SmartSpin = (props: SmartSpinProps): JSX.Element => {
  const { loading } = props;
  if (!loading) {
    return <></>;
  }
  return (
    <Row
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Spin spinning />
    </Row>
  );
};
type PickAllProps = {
  style: React.CSSProperties;
  onClick: () => void;
};
export function SmartPickAll(props: PickAllProps): JSX.Element {
  const { onClick, style } = props;
  const [pickedAll, setPickedAll] = useState(false);
  if (pickedAll) {
    return (
      <Button
        style={style}
        type="primary"
        onClick={() => {
          setPickedAll(false);
          onClick();
        }}
      >
        Picked All
      </Button>
    );
  }
  return (
    <ButtonIcon
      style={style}
      className="bi-three-dots"
      onClick={() => {
        setPickedAll(true);
      }}
    />
  );
}
export const SmartSpace = styled(Row)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
export const SmartBlock = styled.div`
  margin-top: 20px;
`;
export const SmartText = styled.span`
  font-size: 20px;
  font-weight: 600;
  /* margin-left: 5px; */
`;
export const SmartLabel = styled.span`
  /* width: 65px; */
  padding-right: 5px;
`;
export const GreenColor = 'rgb(86,149,34)';
export const YellowColor = 'red';
export const SmartInput = styled(Input)<{ $color?: string; }>`
  height: 50px;
  & .ant-input {
    font-size: 26px;
    color: ${props => props.$color || "rgba(0, 0, 0, 0.85)"};
  }
`;
export const SmartInputNumber = styled(InputNumber)`
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & .ant-input-number-input {
    font-size: 26px;
    text-align: center;
  }
`;

export const SmartCard = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
  .ant-card-head {
    padding: 0px;
    min-height: 24px;
  }
  .ant-card-head-title {
    padding: 0px 0px;
  }
`

export const SInput = styled(Input)<{ $color?: string; }>`
  & .ant-input {
    color: ${props => props.$color || "rgba(0, 0, 0, 0.85)"};
  }
`;