/**
 * Warehouse-related service requests are here.
 */
import { digitBridgeApi } from './http';

/**
 * Fetch all products list of warehouse.
 */
export const fetchAllProductList = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/products');
};

/**
 * Fetch the products list of warehouse.
 *
 * @param {number} warehouseId
 * @param {[key: string]: any} - the query options, it contains these props:
 *                               $skip - how many records will been skipped
 *                               $top - how many records will been returned
 *                               $sortBy - which field to sort
 *                               $calculateTotal - whether to count the total
 *                               $count - whether to count the records number
 *                               $filter
 */
export const fetchWarehouseProductList = async (
  warehouseId: string,
  query?: StringKAnyVPair
): Promise<any> => {
  let params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'SKU',
    $calculateTotal: false,
    $count: false,
    hideZero: true,
  };

  if (query && 'object' === typeof query) {
    // // for (const k of Object.keys(params)) {
    // Object.keys(params).forEach((k) => {
    //   if (k in query) {
    //     params[k] = query[k];
    //   }
    // });

    // if (query.$filter) params.$filter = query.$filter;
    // if (query.uniqueCode) params.uniqueCode = query.uniqueCode;
    // if (query.qty) params.qty = query.qty
    params = {
      ...params,
      ...query
    }
  }

  return await digitBridgeApi.get(
    `/api/products/inventories/${warehouseId}`,
    { params }
  );
};

export const fetchWarehouseProductList2 = async (params: {
  warehouseNum: number,
  locationNum?: number,
  uniqueCode: string
}): Promise<any> => {
  return await digitBridgeApi.get('/api/products/lotuom',{params})
}

interface InventoryLogParams {
  StartTime: string;
  EndTime: string;
  SKU?: string;
  SerialNumber?: string;
  LotNumber?: string;
  WarehouseCode?: string;
  LocationCode?: string;
  TransactionType?: number;
  TransactionReasonName?: string;
  SaleID?: string;
  PONumber?: string;
  User?: string;
  $top?: number;
  $skip?: string;
  $count?: boolean;
}
export const fetchInventoryLogs = async (
  params: InventoryLogParams
): Promise<any> => {
  const response = await digitBridgeApi.get('/api/inventoryLog', {
    params,
  });

  return response;
};

/**
 * move product （location to location）
 */
interface moveProductLocationToLocationParams {
  fromWarehouse: string;
  fromLocation: number;
  fulfillmentProductNum: number;
  quantity: number;
  toWarehouse: number;
  toLocation: number;
}
export const moveProductLocationToLocation = async (
  params: moveProductLocationToLocationParams
): Promise<any> => {
  const json = JSON.stringify({
    WarehouseNum: params.toWarehouse,
    LocationNum: params.toLocation,
  });
  return digitBridgeApi.post(
    `/api/warehouses/${params.fromWarehouse}/locations/${params.fromLocation
    }/products/${params.fulfillmentProductNum}/move/${params.quantity}`,
    json
  );
};

interface moveProductLocationToLocationParams2 {
  fromWarehouse: string;
  fromLocation: number;
  fulfillmentProductNum: number;
  quantity: number;
  toWarehouse: number;
  toLocation: number;
  lotNumber?: string;
  uom?: string;
  uomRate?: number;
}
export const moveProductLocationToLocation2 = async (
  params: moveProductLocationToLocationParams2
): Promise<any> => {
  const json = JSON.stringify({
    WarehouseNum: params.toWarehouse,
    LocationNum: params.toLocation,
    LotNumber: params.lotNumber,
    Uom: params.uom,
    UomRate: params.uomRate
  });
  return digitBridgeApi.post(
    `/api/warehouses/${params.fromWarehouse}/locations/${params.fromLocation
    }/products/${params.fulfillmentProductNum}/move2/${params.quantity}`,
    json
  );
};


/**
 * put location
 */
interface putLocationParams {
  warehouseId: string,
  fromLocation: string;
  toWarehouse: number;
  toLocation: number;
}
export const putLocation = async (params: putLocationParams): Promise<any> => {
  const json = JSON.stringify({
    WarehouseNum: params.toWarehouse,
    LocationNum: params.toLocation,
  });
  return digitBridgeApi.put(
    `/api/warehouses/${params.warehouseId}/locations/${params.fromLocation}`,
    json
  );
};

/**
 * merge location
 */
interface mergeLocationParams {
  warehouseId: string;
  fromLocation: number;
  toLocation: number;
}
export const mergeLocation = async (
  params: mergeLocationParams
): Promise<any> => {
  return digitBridgeApi.post(
    `/api/warehouses/${params.warehouseId}/locations/${params.fromLocation}/merge/${params.toLocation}`
  );
};

/**
 * checkin
 * @param warehouse
 * @param product
 * @param location
 * @param transactionReason
 * @param quantity
 * @returns
 */

export const inventoryCheckin = async (
  warehouseId: string,
  product: ProductRow,
  location: WarehouseLocationRow,
  transactionReason: TransactionReasonRow,
  quantity: number
): Promise<boolean> => {
  // const json = JSON.stringify({ TransactionType: transactionReason.name });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations/${location.id
    }/products/${product.id}/checkin/${quantity}`,
    transactionReason.name
  );
};

export const inventoryCheckin2 = async (
  warehouseId: string,
  product: ProductRow,
  location: WarehouseLocationRow,
  transactionReason: TransactionReasonRow,
  quantity: number,
  lotUomInfo: any,
): Promise<boolean> => {
  const json = JSON.stringify({ 
    Reason: transactionReason.name, 
    LotNumber: lotUomInfo?.lotNumber, 
    Uom:lotUomInfo?.uom, 
    UomRate: lotUomInfo?.uomRate 
  });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations/${location.id
    }/products/${product.id}/checkin2/${quantity}`,
    json
  );
};

/**
 * checkout
 * @param warehouse
 * @param product
 * @param location
 * @param transactionReason
 * @param quantity
 * @returns
 */

export const inventoryCheckout = async (
  warehouseId: string,
  product: ProductRow,
  location: WarehouseLocationRow,
  transactionReason: TransactionReasonRow,
  quantity: number
): Promise<boolean> => {
  // const json = JSON.stringify({ TransactionType: transactionReason.name });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations/${location.id
    }/products/${product.id}/checkout/${quantity}`,
    transactionReason.name
  );
};

export const inventoryVerifyCheckout = async (
  warehouseId: string,
  product: ProductRow,
  transactionReason: TransactionReasonRow,
  quantity: number
): Promise<boolean> => {
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/products/${product.id}/verifyCheckout/${quantity}`,
    transactionReason.name
  );
};

export const inventoryCheckout2 = async (
  warehouseId: string,
  product: ProductRow,
  location: any,
  transactionReason: TransactionReasonRow,
  quantity: number,
): Promise<boolean> => {
  const json = JSON.stringify({ 
    Reason: transactionReason.name, 
    LotNumber: location.lotNumber, 
    Uom:location.uom, 
    UomRate: location.uomRate 
  });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations/${location.locationNum
    }/products/${product.id}/checkout2/${quantity}`,
    json
  );
};

export const inventoryVerifyCheckout2 = async (
  warehouseId: string,
  product: ProductRow,
  transactionReason: TransactionReasonRow,
  quantity: number,
  lotUomInfo:any
): Promise<boolean> => {
  const json = JSON.stringify({ 
    Reason: transactionReason.name, 
    LotNumber: lotUomInfo?.lotNumber, 
    Uom:lotUomInfo?.uom, 
    UomRate: lotUomInfo?.uomRate 
  });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/products/${product.id}/verifyCheckout/${quantity}`,
    json
  );
};

/**
 * move item to other location
 */
export const inventoryMove = async (
  warehouseId: string,
  warehouseNum: number,
  locationId: number,
  productId: number,
  toLocationId: number,
  quantity: number,
): Promise<boolean> => {
  const json = JSON.stringify({ WarehouseNum: warehouseNum, LocationNum: toLocationId });
  return await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/locations/${locationId
    }/products/${productId}/move/${quantity}`,
    json
  );
};

/**
 * patch move item to other location
 */
type inventoryBatchMoveParams = {
  LocationNum: number,
  MoveItem: {
    FulfillmentProductNum: number,
    Quantity: number
  }[]
}
export const inventoryBatchMove = async (warehosueId: string, locationNum: number, params: inventoryBatchMoveParams): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/move`, json)
}

type InventoryBatchCountsParams = {
  LocationNotes: string,
  Items: {
    FulfillmentProductNum: number,
    Quantity: number
  }[]
}
export const inventoryBatchCounts = async (warehosueId: string, locationNum: number, params: InventoryBatchCountsParams): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/counts`, json)
}
type InventoryResetZeroParams = {
  LocationNotes: string,
  Reason: string,
}
export const inventoryResetZero = async (warehosueId: string, locationNum: number, params: InventoryResetZeroParams): Promise<boolean> => {
  const json = JSON.stringify({ ...params, Items: [] })
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/resetInventory`, json)
}
type ResetInventoryParams = {
  LocationNotes: string,
  Reason: string,
  Items: {
    SKU: string,
    Quantity: number
  }[]
}
export const resetInventory = async (warehosueId: string, locationNum: number, params: ResetInventoryParams): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/resetInventory`, json)
}

type FetchWarehouseTransferListParams = {
  $skip: number,
  $top: number,
  $sortBy?: string,
  batchNumber?: string,
  fulfillmentOrderNum?: number,
  // warehouseNum?: number,
  sku?: number,
  transferType?: number,
  transferStatus?: number,
  syncStatus?: number,
  transferDate?: string,
  receiveDate?: string,
}
export const fetchWarehouseTransferList = async (warehouseId: string, params: FetchWarehouseTransferListParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/transfer`, { params })
}

type TransferReceiveParams = {
  FulfillmentTransferLineNum: number,
  ReceiveQty: number,
  LocationNum: number,
  Note?: string,
}
export const transferReceive = async (warehouseId: string, transferNum: number, params: TransferReceiveParams[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/transfer/${transferNum}/receive`, json)
}

export const fetchTransferLines = async (warehouseId: string, transferNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/transfer/${transferNum}/lines`)
}

export const getReceivedTransfer = async (warehouseId: string, transferNum: number, batchNumber?: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/transfer/${transferNum}/receive`, { params: { batchNumber } })
}

export const closeTransfer = async (warehouseId: string, transferNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/transfer/${transferNum}/close`)
}

export const deleteReceiveLine = async (warehouseId: string, transferNum: number, fulfillmentReceiveNum: number ): Promise<any> => {
  return await digitBridgeApi.delete(`/api/warehouses/${warehouseId}/transfer/${transferNum}/receive/${fulfillmentReceiveNum}`)
}

export const fetchInventories = async (warehouseId: string, keyword: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/locations`, { params: { keyword } })
}

export const getTotalQuantityByWarehouse = async (productId: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/products/${productId}/inventories/groupbywarehouse`)
}

interface MoveAllProductLocationToLocationParams {
  fromWarehouse: string;
  fromLocation: number;
  toWarehouse: number;
  toLocation: string;
  autoCreate: boolean;
  toZero: boolean;
}
export const moveAllProductLocationToLocation = async (
  params: MoveAllProductLocationToLocationParams
): Promise<any> => {
  const json = JSON.stringify({
    DestinationWarehouseNum: params.toWarehouse,
    DestinationlocationCode: params.toLocation,
    CreateDestinationlocation : params.autoCreate,
    QtyMustBeZero: params.toZero
  });
  return digitBridgeApi.post(
    `/api/warehouses/${params.fromWarehouse}/locationMove/${params.fromLocation}`,
    json
  );
};

type ResetInventory2Params = {
  LocationNotes: string,
  Reason: string,
  Items: {
    SKU: string,
    Quantity: number,
    LotNumber?: string,
    Uom?: string,
    UomRate?: number
  }[]
}
export const resetInventory2 = async (warehosueId: string, locationNum: number, params: ResetInventory2Params): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/resetInventoryTwo`, json)
}

type InventoryBatchCountsParams2 = {
  LocationNotes: string,
  Items: {
    FulfillmentProductNum: number,
    SKU: string,
    Quantity: number,
    LotNumber?: string,
    Uom?: string,
    UomRate?: number,
  }[]
}
export const inventoryBatchCounts2 = async (warehosueId: string, locationNum: number, params: InventoryBatchCountsParams2): Promise<boolean> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/warehouses/${warehosueId}/locations/${locationNum}/auditCounts`, json)
}

export const syncToErp = async (params: {warehouseCode?: string}): Promise<any> => await digitBridgeApi.get('/api/inventories/sync', { params });

