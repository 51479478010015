import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Row, Typography, Spin, Modal, Button, Space } from 'antd';
import { message, FormLabel } from 'components/common';
import { patchProduct, fetchProduct } from 'services/product';
import { leaveEditForm } from 'utils';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

const { Text } = Typography;
type EditProductPartNumberProps = {
  visible: boolean;
  onHide: () => void;
  product?: ProductRow;
};
export default function EditProductPartNumberDialog(
  props: EditProductPartNumberProps
): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<ProductRow | null>();
  const { visible, onHide } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    const fetch = async () => {
      if (props.product && props.visible) {
        setLoading(true);
        const newProduct = await fetchProduct(props.product.productId);
        setProduct(newProduct);
        if (newProduct) {
          form.setFieldsValue({ PartNumber: newProduct.partNumber });
        }
        setLoading(false);
      }
    };
    fetch();
  }, [props, form]);
  const onSubmit = async () => {
    if (!product) {
      return;
    }
    let values: StringKAnyVPair = {};
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }
    setLoading(true);
    await patchProduct(product.productId, values);
    setLoading(false);
    message.success({ content: 'Update product part number success' });
    onHide();
  };
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    if (product) {
      const cancel = await leaveEditForm(
        { PartNumber: product.partNumber },
        form
      );
      if (cancel) {
        onHide();
      }
    } else {
      onHide();
    }
  }, [onHide, product, form]);
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="edit_part_number_button" key="submit" type="primary" onClick={onSubmit} icon={<EditOutlined />}>
            Edit Part Number
          </Button>
          <Button id="cancel_button" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Edit Product Part Number"
      centered
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={handleCancel}
      getContainer={false}
      footer={<Footer />}
    >
      <Spin spinning={loading}>
        <Row>
          <Text>{`Current Part Number:'${product?.partNumber}'`}</Text>
        </Row>
        <Row style={{ marginTop: 20 }} justify="center">
          <Form
            /* eslint-disable react/jsx-props-no-spreading */
            {...layout}
            form={form}
          >
            <Form.Item
              name="PartNumber"
              label={<FormLabel>New Part Number</FormLabel>}
              rules={[{ required: true, message: 'Please input Part Number!' }]}
            >
              <Input id="PartNumber" />
            </Form.Item>
          </Form>
        </Row>
      </Spin>
    </Modal>
  );
}
EditProductPartNumberDialog.defaultProps = {
  product: undefined,
};
