import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import NormalToDamage from 'components/Sales/Smart/MoveMerge/MoveWarehouse/NormalToDamage';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Page(): JSX.Element {
  return (
    <ContentLayout 
      titleKey="titles.normalToDamage" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}

    // title={`From Normal to Damage(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <NormalToDamage />
    </ContentLayout>
  );
}





