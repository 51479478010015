/**
 * Sales service related requests are here.
 */
import { HTTP_STATUS_OK, SHIPMENT_NOT_SHIPPING_STATE } from 'constants/config';
import { validateHttpStatus } from 'utils';
import { digitBridgeApi, digitBridgePrinterApi, getDataListInResponse, digitbridgePrinterSimpleApi } from './http';


/**
 * Create a new pick list profile.
 */
export const addPickListProfile = async (
  params: StringKAnyVPair
): Promise<any> => {
  const json = JSON.stringify(params);
  const res = await digitBridgeApi.post('/api/AddPickListProfile', json);

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }

  return false;
};

/**
 * Calculate the shopping around result.
 */
export const calcShoppingAroundResult = async (
  shipmentNum: number,
  params: StringKAnyVPair,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/shoppingaround`, JSON.stringify(params)) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Clear many items in a shipment.
 */
export const clearManyShipmentItems = async (
  shipmentNum: number,
  data: StringKAnyVPair[],
): Promise<boolean> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/clear`, JSON.stringify(data));
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Clear a item in a shipment.
 */
export const clearShipmentItem = async (
  item: OrderShipmentItem,
): Promise<boolean> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${item.shipmentNum}/lines/${item.shipmentLineNum}/clear`);
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

export const clearVerifiedShipment = async (
  shipmentNum: number,
): Promise<boolean> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/clearmarkverify`);
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Delete a pick list profile.
 */
export const deletePickListProfile = async (
  profileId: number
): Promise<any> => {
  const res = await digitBridgeApi.delete(
    `/api/pickListProfiles/${profileId}`
  );

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }

  return false;
};

/**
 * Get the channel account service list in a warehouse.
 */
export const fetchChannelAccountServiceList = async (
  warehouseNum: number,
  channelAccountNum: number,
  scantoShip = 0,
  markship?: number,
): Promise<any> => {
  let params: StringKAnyVPair = { warehouseNum, channelAccountNum };

  if (scantoShip) params['scantoShip'] = 1;

  if (markship) params['markship'] = 1;

  const res = await digitBridgeApi.get('/api/GetChannelAccountServiceList', {
    params
  });

  return getDataListInResponse(res);
};

/**
 * Get channel service list.
 */
export const fetchChannelServiceList = async (
  channelIds: Array<number>,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.get('/api/GetChannelServiceByChannelNum', { params: { channelNums: channelIds.join(';') } }) as StringKAnyVPair;

  if (isSuccess) {
    if (Array.isArray(data)) {
      return data;
    }
  }
};

/**
 * Get flat rate package list.
 */
export const fetchFlatRatePackageList = async (
  warehouseNum: number,
  carrierNum: number,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.get('/api/GetFlatRatePackageList', { params: { carrierNum, warehouseNum } }) as StringKAnyVPair;

  if (isSuccess) {
    if (Array.isArray(data)) {
      return data;
    }
  }
};

/**
 * Get the shipment list in a order.
 * @param {number} warehouseId
 * @param {string} id - the order id
 * @param {number | undefined} picklistnum
 */
export const fetchOrderShipment = async (
  warehouseId: number,
  id: string,
  picklistnum: number | undefined = undefined,
  all: boolean | undefined = false,
): Promise<{
  message: string,
  shipments: OrderShipment[],
}> => {
  const { isSuccess, data, message } = await digitBridgeApi.get(`/api/Shipments/${warehouseId}`, { params: { id, picklistnum, all: all ? 1 : 0 }, validateStatus: (status) => validateHttpStatus([400, 404], status) }) as StringKAnyVPair;
  let list: OrderShipment[] = [];

  if (isSuccess) {
    if (Array.isArray(data)) list = data as OrderShipment[];
  }

  for (let i = 0; i < list.length; i++) {
    list[i].verifyStatus = list[i].verifyStatus || SHIPMENT_NOT_SHIPPING_STATE;
  }

  return {
    message,
    shipments: list,
  };
};

/**
 * Get shipment list according to the  tracking number.
 * Parameter history controls whether to search from the shipping history.
 */
export const fetchOrderShipmentByTrackingNumber = async (
  warehouseId: number,
  trackNumber: string,
  history = false,
): Promise<OrderShipment[]> => {
  const { code, data } = await digitBridgeApi.get(`/api/Shipments/${warehouseId}/trackingNumber`, { params: { no: trackNumber, history } }) as StringKAnyVPair;

  if (code === HTTP_STATUS_OK) {
    return data as OrderShipment[];
  }

  return [];
};

/**
 * Get the shipment list in a order.
 * @param {number} warehouseId
 * @param {string} id - the order id
 */
export const fetchOrderShipmentList = async (
  warehouseId: number,
  id: string,
): Promise<OrderShipment[]> => {
  const res = await digitBridgeApi.get(`/api/Shipments/${warehouseId}`, { params: { id }, validateStatus: (status) => validateHttpStatus([400, 404], status) });
  const list = getDataListInResponse(res) as OrderShipment[];

  for (let i = 0; i < list.length; i++) {
    list[i].verifyStatus = list[i].verifyStatus || SHIPMENT_NOT_SHIPPING_STATE;
  }

  return list;
};

export const fetchOrderShipmentListFromHistory = async (
  warehouseId: number,
  id: string,
): Promise<OrderShipment[]> => {
  const res = await digitBridgeApi.get(`/api/Shipments/${warehouseId}/histories`, { params: { id }, validateStatus: (status) => validateHttpStatus([400, 404], status) });
  const list = getDataListInResponse(res) as OrderShipment[];

  for (let i = 0; i < list.length; i++) {
    list[i].verifyStatus = list[i].verifyStatus || SHIPMENT_NOT_SHIPPING_STATE;
  }

  return list;
};

/**
 * Fetch count of pending pick list.
 */
export const fetchPendOrderPickListCount = async (
  params: StringKAnyVPair,
  query: StringKAnyVPair,
): Promise<number> => {
  const json = JSON.stringify(query);
  const { isSuccess, page } = await digitBridgeApi.post('/api/PickList/PendingOrders', json, { params }) as StringKAnyVPair;

  if (isSuccess && page && typeof page === 'object') {
    return page.total as number;
  }

  return 0;
};

/**
 * Get pick list profile list.
 */
export const fetchPickListProfileList = async (): Promise<any> => {
  const res = await digitBridgeApi.get('/api/GetPickListProfileList');
  let ret = [];

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = data;
    }
  }

  return ret;
};

/**
 * Get the scale list.
 */
export const fetchScaleList = async (): Promise<StringKAnyVPair[]> => {
  const res = await digitBridgeApi.post('/api/Scales');
  let ret = [];

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = data;
    }
  }

  return ret;
};

/**
 * Get the flat rate package list of a shipment.
 */
export const fetchShipmentFlatRatePackages = async (
  shipmentNum: number,
): Promise<any> => {
  const res = await digitBridgeApi.get(`/api/Shipments/${shipmentNum}/flatratepackages`);

  //console.log('fr res ->', res);
  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }
};

/**
 * Get the default signature of a shipment.
 */
export const fetchShipmentSignature = async (
  shipmentNum: number,
  carrierNum: number,
): Promise<any> => {
  const res = await digitBridgeApi.get(`/api/Shipments/${shipmentNum}/signature/${carrierNum}`);

  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }
};

/**
 * Get the shipment inventory.
 */
export const fetchShipmentInventoryById = async (id: number) => {
  const res = await digitBridgeApi.get(`/api/Shipments/${id}/inventory`);
  let ret: StringKAnyVPair[] = [];

  //console.log('res->', res);
  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = data;
    }

    ret = data;
  }

  return ret;
};

/**
 * Get the shipment entity list by a shipment id.
 */
export const fetchShipmentItemsById = async (
  id: number,
  filterShip0 = false,
): Promise<Array<OrderShipmentItem>> => {
  const res = await digitBridgeApi.get(`/api/Shipments/${id}/lines`);
  let ret: OrderShipmentItem[] = [];

  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = data;
    }

    if (ret.length > 0 && filterShip0) {
      ret = ret.filter(e => e.shippingQty > 0);
    }
  }

  return ret;
};

/**
 * Get shopping around carrier service list.
 */
export const fetchShoppingAroundCarrierServiceList = async (
  warehouseNum: number,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.get('/api/GetShoppingAroundCarrierServiceList', { params: { warehouseNum } }) as StringKAnyVPair;

  if (isSuccess) {
    if (Array.isArray(data)) {
      return data;
    }
  }
};

/**
 * Get signature options by carrier number;
 */
export const fetchSignatureOptions = async (
  carrierNum?: number,
): Promise<any> => {
  const res = await digitBridgeApi.get('/api/carrier/signature', {
    params: { carrierNum }
  });

  return getDataListInResponse(res);
};

/**
 * Get the account service list in a warehouse.
 */
export const fetchWarehouseAccountServiceList = async (
  warehouseNum: number,
): Promise<any> => {
  const res = await digitBridgeApi.get('/api/GetShipAccountAggregateList', {
    params: { warehouseNum }
  });

  return getDataListInResponse(res);
};

/**
 * Get packing slip list in a channel.
 */
export const getChannelPackingSlipList = async (
  channelAccountNum: number,
): Promise<any> => {
  const { code, data } = await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/packingslip`) as StringKAnyVPair;

  if (code === HTTP_STATUS_OK) {
    return data;
  }
}

/**
 * Get the rate of a shipment.
 */
export const getShipmentRate = async (
  shipmentNum: number,
  params: StringKAnyVPair,
): Promise<any> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/rate`, JSON.stringify(params));

  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }

  return false;
};

/**
 * Mark a shipment as shipped.
 *
 * @param {number} shipmentNum - the shipment id
 */
export const markShipmentShipped = async (
  shipmentNum: number,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/markshipped`) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Mark a shipment as verified.
 *
 * @param {number} shipmentNum - the shipment id
 */
export const markShipmentVerified = async (
  shipmentNum: number,
): Promise<boolean> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/markverify`);
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Mark a shipment as verified.
 *
 * @param {number} shipmentNum - the shipment id
 * @param {StringKAnyVPair[]} data - the shipment line data
 */
export const markShipmentVerified2 = async (
  shipmentNum: number,
  data: StringKAnyVPair[],
): Promise<boolean> => {
  const json = JSON.stringify(data);
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/markverify`, json);
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Pend orders to pick list.
 */
export const pendOrderPickList = async (
  params: StringKAnyVPair,
  query: StringKAnyVPair,
): Promise<StringKAnyVPair[]> => {
  const json = JSON.stringify(query);
  const { isSuccess, data } = await digitBridgeApi.post('/api/PickList/PendingOrders', json, { params }) as StringKAnyVPair;

  if (isSuccess) {
    return data as StringKAnyVPair[];
  }

  return [];
};

/**
 * Prepare to print pick list.
 */
export const preparePrintPickList = async (
  query: StringKAnyVPair,
): Promise<any> => {
  const json = JSON.stringify(query);
  const { isSuccess, data } = await digitBridgeApi.post('/api/PickList/PreparePrint', json) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Print shipment carton label by calling printstation API.
 */
export const postPrintShipmentCartonLabel = async (
  printParam: StringKAnyVPair,
  isReprint = false,
): Promise<any> => {
  const api = '/api/PrintShipmentCartonLabel';
  const json = JSON.stringify(printParam);
  const { isSuccess, data } = await digitBridgePrinterApi.post(api, json) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Print shipment label by calling printstation API.
 */
export const postPrintShipmentLabel = async (
  printParam: StringKAnyVPair,
  isReprint = false,
): Promise<any> => {
  const api = isReprint ? '/api/reprintshippinglabel' : '/api/printshipmentlabel';
  const json = JSON.stringify(printParam);
  const { isSuccess, data } = await digitBridgePrinterApi.post(api, json) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Print shipment packing slip by calling printsation API.
 */
export const postPrintShipmentPackingSlip = async (
  printParam: StringKAnyVPair,
): Promise<any> => {
  const api = '/api/PrintShipmentPackingSlip';
  const json = JSON.stringify(printParam);
  const { isSuccess, data } = await digitBridgePrinterApi.post(api, json) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Queue orders to pick list.
 */
export const queueOrderPickList = async (
  params: StringKAnyVPair,
  query: StringKAnyVPair,
): Promise<any> => {
  const json = JSON.stringify(query);
  const { isSuccess, data } = await digitBridgeApi.post('/api/PickList/QueueOrders', json, { params }) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

/**
 * Update the services of a shipment.
 */
export const updateShipmentServices = async (
  shipmentNum: number,
  data: StringKAnyVPair,
): Promise<any> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/service`, JSON.stringify(data));
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }

  return ret;
};

/**
 * Verify many items in a shipment.
 */
export const verifyManyShipmentItems = async (
  shipmentNum: number,
  data: StringKAnyVPair[],
): Promise<boolean> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/verify`, JSON.stringify(data));
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Verify a item in a shipment.
 */
export const verifyShipmentItem = async (
  item: OrderShipmentItem,
): Promise<boolean> => {
  const data = {
    ShipmentNum: item.shipmentNum,
    ShipmentLineNum: item.shipmentLineNum,
    VerifyStatus: 1,
    Quantity: item.verifyQty,
  };
  const res = await digitBridgeApi.post(`/api/Shipments/${item.shipmentNum}/lines/${item.shipmentLineNum}/verify`, JSON.stringify(data));
  let ret = false;

  if (res && typeof res === 'object') {
    const { code, isSuccess } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      ret = !!isSuccess;
    }
  }

  return ret;
};

/**
 * Void the shipment.
 */
export const voidShipment = async (
  shipmentNum: number,
  skipcarrier: number,
): Promise<any> => {
  const res = await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/void`, '', { params: { skipcarrier } });
  if (res && typeof res === 'object') {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data;
    }
  }
};

type ShipmentsListParams = {
  warehouseNum: number,
  pageIndex: number,
  pageSize: number,
  Queued?: number,
  NeedSmartVerify?: number,
  BatchPrint?: number,
  orderFrom?: string,
  orderTo?: string,
  shipFrom?: string,
  shipTo?: string,
  ShipToName?: string,
  shippingCarrier?: string,
  shippingService?: string,
  shipAccountNum?: number,
  trackingNum?: string,
  ChannelAccountName?: number[],
  ChannelOrderId?: string,
  CentralOrderNum?: string,
  SecondChannelOrderId?: string,
  TraceId?: string,
  FulfillmentOrderId?: string,
  SellerOrderId?: string,
  ShipmentId?: string,
  ShippingStatus?: number,
  Sorts?: any[],
  Qty?: number,
  SKU?: string,
  StartCreateDate?: string,
  EndCreateDate?: string,
  ShopAroundStatus?: string,
  DivisionCode?: string;
  DepartmentCode?: string;
  GroupCode?: string;
  SubGroupCode?: string;
  ExcludedSkus?: string;
  ShippingLabelRelease?: number;
  Oper?: number;
  SO?: string;
  customerCode?: string;
  Status?: any[],
  Carrier?: any[],
  ShipService?: any[],
}

export async function fetchShipmentsList(params: ShipmentsListParams): Promise<any> {
  const json = JSON.stringify({
    orderFrom: params.orderFrom,
    orderTo: params.orderTo,
    ShipFrom: params.shipFrom,
    ShipTo: params.shipTo,
    ShipToName: params.ShipToName,
    Queued: params.Queued,
    NeedSmartVerify: params.NeedSmartVerify,
    shippingCarrier: params.shippingCarrier,
    shippingService: params.shippingService,
    shipAccountNum: params.shipAccountNum,
    CentralOrderNum: params.CentralOrderNum,
    TrackingNumber: params.trackingNum,
    ChannelAccountNums: params.ChannelAccountName,
    ChannelOrderId: params.ChannelOrderId,
    SecondChannelOrderId: params.SecondChannelOrderId,
    TraceId: params.TraceId,
    FulfillmentOrderId: params.FulfillmentOrderId,
    SellerOrderId: params.SellerOrderId,
    ShipmentId: params.ShipmentId,
    ShippingStatus: params.ShippingStatus,
    Sorts: params.Sorts,
    SKU: params.SKU,
    Qty: params.Qty,
    StartCreateDate: params.StartCreateDate,
    EndCreateDate: params.EndCreateDate,
    BatchPrint: params.BatchPrint,
    ShopAroundStatus: params.ShopAroundStatus,
    DivisionCode: params.DivisionCode,
    DepartmentCode: params.DepartmentCode,
    GroupCode: params.GroupCode,
    SubGroupCode: params.SubGroupCode,
    ExcludedSkus: params.ExcludedSkus,
    WarehouseNum: params.warehouseNum,
    ShippingLabelRelease: params.ShippingLabelRelease,
    Oper: params.Oper,
    SO: params.SO,
    customerCode: params.customerCode,
    Status: params.Status,
    Carrier: params.Carrier,
    ShipService: params.ShipService
  })
  return await digitBridgeApi.post(
    `/api/Shipments/list/${params.warehouseNum}`, json,
    {
      params: {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
      }
    }
  )
}

export async function fetchShipmentsListWithItem(params: ShipmentsListParams): Promise<any> {
  const json = JSON.stringify({
    orderFrom: params.orderFrom,
    orderTo: params.orderTo,
    shipFrom: params.shipFrom,
    shipTo: params.shipTo,
    ShipToName: params.ShipToName,
    Queued: params.Queued,
    NeedSmartVerify: params.NeedSmartVerify,
    shippingCarrier: params.shippingCarrier,
    shippingService: params.shippingService,
    shipAccountNum: params.shipAccountNum,
    CentralOrderNum: params.CentralOrderNum,
    TrackingNumber: params.trackingNum,
    ChannelAccountNums: params.ChannelAccountName,
    ChannelOrderId: params.ChannelOrderId,
    SecondChannelOrderId: params.SecondChannelOrderId,
    TraceId: params.TraceId,
    FulfillmentOrderId: params.FulfillmentOrderId,
    SellerOrderId: params.SellerOrderId,
    ShipmentId: params.ShipmentId,
    ShippingStatus: params.ShippingStatus,
    Sorts: params.Sorts,
    SKU: params.SKU,
    Qty: params.Qty,
    StartCreateDate: params.StartCreateDate,
    EndCreateDate: params.EndCreateDate,
    BatchPrint: params.BatchPrint,
    ShopAroundStatus: params.ShopAroundStatus,
    ExcludedSkus: params.ExcludedSkus,
    WarehouseNum: params.warehouseNum,
    ShippingLabelRelease: params.ShippingLabelRelease,
    DivisionCode: params.DivisionCode,
    DepartmentCode: params.DepartmentCode,
    GroupCode: params.GroupCode,
    SubGroupCode: params.SubGroupCode,
    Oper: params.Oper,
    SO: params.SO,
    customerCode: params.customerCode,
    Status: params.Status,
    Carrier: params.Carrier,
    ShipService: params.ShipService
  })
  return await digitBridgeApi.post(
    `/api/Shipments/items/${params.warehouseNum}`, json,
    {
      params: {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
      }
    }
  )
}

export async function fetchShipmentsListFromHistory(params: ShipmentsListParams): Promise<any> {
  const json = JSON.stringify({
    orderFrom: params.orderFrom,
    orderTo: params.orderTo,
    shipFrom: params.shipFrom,
    shipTo: params.shipTo,
    ShipToName: params.ShipToName,
    Queued: params.Queued,
    NeedSmartVerify: params.NeedSmartVerify,
    shippingCarrier: params.shippingCarrier,
    shippingService: params.shippingService,
    shipAccountNum: params.shipAccountNum,
    TrackingNumber: params.trackingNum,
    ChannelAccountNums: params.ChannelAccountName,
    ChannelOrderId: params.ChannelOrderId,
    CentralOrderNum: params.CentralOrderNum,
    SecondChannelOrderId: params.SecondChannelOrderId,
    TraceId: params.TraceId,
    FulfillmentOrderId: params.FulfillmentOrderId,
    SellerOrderId: params.SellerOrderId,
    ShipmentId: params.ShipmentId,
    ShippingStatus: params.ShippingStatus,
    Sorts: params.Sorts,
    SKU: params.SKU,
    Qty: params.Qty,
    StartCreateDate: params.StartCreateDate,
    EndCreateDate: params.EndCreateDate,
    BatchPrint: params.BatchPrint,
    ShopAroundStatus: params.ShopAroundStatus,
    ExcludedSkus: params.ExcludedSkus,
    WarehouseNum: params.warehouseNum,
    Oper: params.Oper,
    SO: params.SO,
    customerCode: params.customerCode,
    Status: params.Status,
    Carrier: params.Carrier,
    ShipService: params.ShipService
  })
  return await digitBridgeApi.post(
    `/api/Shipments/list/${params.warehouseNum}/histories`, json,
    {
      params: {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
      }
    }
  )
}

export const postSmartVerify = async (
  shipmentNum: number,
): Promise<any> => {
  return await digitBridgeApi.post(`/api/Shipments/${shipmentNum}/smartverify`);

};

export const fetchChannels = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/bulkreturn/channel')
}

/**
 * export shipment label pdf by calling printstation API.
 */
export const postExportShipmentLabelPdf = async (params: StringKAnyVPair, isReprint = false): Promise<any> => {
  const api = '/api/PrintShippingLabel';
  const json = JSON.stringify(params);
  return await digitbridgePrinterSimpleApi.post(api, json, { responseType: 'blob' });
};

/**
 * Generate New Shipping Label
 */
export const generateNewShippingLabel = async (params: StringKAnyVPair): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitbridgePrinterSimpleApi.post('/api/ShippingLabel', json, { responseType: 'blob' });
};

/**
 * Generate New Shipping Label and Packing Slip
 */
export const generateNewShippingLabelAndPackingSlip = async (params: StringKAnyVPair): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitbridgePrinterSimpleApi.post('/api/ShippingLabelAndPackingSlip', json, { responseType: 'blob' });
};

/**
 * Download Shipping Label
 */
export const downloadShippingLabel = async (shipmentNum: string): Promise<any> => {
  return await digitbridgePrinterSimpleApi.get(`/api/GetShippingLabel?ShipmentNum=${shipmentNum}`, { responseType: 'arraybuffer' });
};

/**
 * Download Shipping Label and Packing Slip
 */
export const downloadShippingLabelAndPackingSlip = async (shipmentNum: string): Promise<any> => {
  return await digitbridgePrinterSimpleApi.get(`/api/GetShippingLabelAndPackingSlip?ShipmentNum=${shipmentNum}`, { responseType: 'arraybuffer' });
};

/**
 * Download Packing Slip
 */
export const downloadPackingSlip = async (shipmentNum: string): Promise<any> => {
  return await digitbridgePrinterSimpleApi.get(`/api/GetPackingSlip?ShipmentNum=${shipmentNum}`, { responseType: 'arraybuffer' });
};
