import React, { useEffect, useState, useCallback, ReactNode } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Col,
  Typography,
  Table,
} from 'antd';
import { sortByString } from 'utils';
import { searchProductByKey } from 'services/product';
import { inventoryBatchMove } from 'services/inventory';
import {
  SelectWarehouse as WarehouseSelect,
  FormLabel,
  message,
} from 'components/common';
import SelectLocation, { fullLocation } from 'components/common/SelectLocation';

import { leaveEditForm } from 'utils';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
const { Text } = Typography;
type FormItemProps = {
  name: string;
  label: string;
  children: ReactNode;
  message?: string;
};
const FormItem = (props: FormItemProps) => {
  const { name, label, children, message } = props;
  const rules = [{ required: message ? true : false, message }];
  let middleNode = children;
  if (Array.isArray(children)) {
    middleNode = children[0];
  }
  return (
    <Row align="middle" style={{ marginTop: -25 }}>
      <Col span={10}>
        <Row>
          <Col span={22}>
            <Row justify="end">
              <FormLabel>{label}</FormLabel>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </Col>
      <Col span={14}>
        <Form.Item name={name} style={{ marginTop: 20 }} rules={rules}>
          {middleNode}
        </Form.Item>
      </Col>
    </Row>
  );
};

type Props = {
  onHide: () => void;
  warehouseNum: number;
  warehouseId: string;
  locationNum: number;
  items: { [key: string]: number };
};
export default function MoveSummaryDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const { onHide, items, warehouseId, locationNum } = props;
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let dataSource: Array<StringKAnyVPair> = [];
      const keys = Object.keys(items);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const product = await searchProductByKey(key);
        dataSource.push({
          sku: key,
          quantity: items[key],
          product,
        });
      }
      setDataSource(dataSource);
      setLoading(false);
    };
    fetch();
  }, [items]);
  /**
   * handle select location
   */
  const handleLocationChange = useCallback(
    (location: WarehouseLocationRow) => {
      setLocation(location);
      form.setFieldsValue({
        location: location.code,
      });
    },
    [form]
  );
  /**
   * handle warehouse select change event
   */
  const handleWarehouseSelectChange = useCallback((warehouse: WarehouseRow) => {
    setWarehouse(warehouse);
  }, []);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    if (!location || !warehouse) {
      return;
    }
    setLoading(true);
    let resultLocation = (await fullLocation(
      warehouse.id,
      warehouse.warehouseId,
      location
    )) as WarehouseLocationRow;
    if (!resultLocation) {
      setLoading(false);
      return;
    }
    try {
      const moveItem = dataSource.map((item) => {
        return {
          FulfillmentProductNum: item.product.id,
          Quantity: item.quantity,
        };
      });
      const res = await inventoryBatchMove(warehouseId, locationNum, {
        LocationNum: location.id,
        MoveItem: moveItem,
      });
      setLoading(false);
      if (res) {
        message.success({ content: 'Move Items Submitted Successfully!' });
        onHide();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [location, form, dataSource, warehouseId, onHide, locationNum, warehouse]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);
  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (value: any, record: any) => {
        if (record['product']) {
          return value;
        } else {
          return (
            <>
              <Row>
                <Text>{value}</Text>
              </Row>
              <Row>
                <Text
                  style={{
                    color: 'white',
                    backgroundColor: 'red',
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Item does not exist
                </Text>
              </Row>
            </>
          );
        }
      },
      sorter: (a: any, b: any) => {
        return sortByString(a, b, 'sku');
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a: any, b: any) => {
        return sortByString(a, b, 'sku');
      },
    },
  ];
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title={`Move Summary`}
      centered
      visible={true}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button id="confirm_btn" key="submit" type="primary" onClick={handleSubmit} icon={<SaveOutlined />}>
              Confirm
            </Button>
            <Button id="cancel_btn" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form}>
          <FormItem name="warehouse" label="Warehouse">
            <WarehouseSelect
              id="change_warehouse_selector"
              disabled
              onChange={handleWarehouseSelectChange}
              width={200}
            />
          </FormItem>
          <FormItem
            name="location"
            label="Location"
            message="Please select location!"
          >
            <SelectLocation
              id="select_location"
              warehouse={warehouse}
              onChange={handleLocationChange}
            />
          </FormItem>
        </Form>
        <Table
          rowKey="sku"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
        />
      </Spin>
    </Modal>
  );
}
