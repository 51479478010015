import React, { useCallback, useEffect, useState } from 'react';
import { Row, Button, Space, Typography, Checkbox, Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { downloadCSV, convertTimeByUtc } from 'utils';
import message from './message';
import Modal from './Modal';
import moment from 'moment';
import { SectionWrapper } from './styles/CustomPropsComponents';

const { Text } = Typography;
type ExportProps = {
  style?: React.CSSProperties;
  title?: string;
  scope: string;
  data: Array<any>;
  selectedRows?: Array<any>;
  showExportSelected?: boolean;
  columns: Array<StringKAnyVPair>;
  downloadFromApi?: (value?: any, groupBy?: string) => void;
  id?: string;
  formatColumns?: any;
  disabled?: boolean;
  aliasText?: string;
  showGroupBy?: boolean;
};
export default function ExportCSV(props: ExportProps): JSX.Element {
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const [visible, setVisible] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const { data, title, style, scope, downloadFromApi, id, formatColumns, showGroupBy, selectedRows, showExportSelected } = props;
  const [columns, setColumns] = useState<any[]>(props.columns)
  const [groupBySku, setGroupBySku] = useState(false)
  useEffect(() => {
    if (data.length === 0 && visible) {
      message.warning('DataGrid No Data!');
    }
  }, [data, visible]);

  useEffect(()=>{
    if(groupBySku){
      setColumns(props.columns.filter(item=>(item.dataIndex || item.name)!=='locationCode' && (item.dataIndex || item.name)!=='zoneName'))
      setSelectedColumns(prev=> prev.filter(i=> i !=='locationCode' && i !== 'zoneName'))
    } else {
      setColumns(props.columns)
    }

  },[groupBySku, props.columns])
  /**
   * handle checkbox item click event
   */
  const handleChange = useCallback(
    (id: string, e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      if (checked) {
        //add item
        const existed = selectedColumns.find((currentId) => {
          if (currentId === id) {
            return currentId;
          }
          return null;
        })
          ? true
          : false;
        if (!existed) {
          selectedColumns.push(id);
        }
        setSelectedColumns(JSON.parse(JSON.stringify(selectedColumns)));
        setCheckAll(true);
      } else {
        //remove item
        const newList = selectedColumns.filter((currentId) => {
          if (currentId === id) {
            return false;
          }
          return true;
        });
        setCheckAll(newList.length > 0);
        setSelectedColumns(JSON.parse(JSON.stringify(newList)));
      }
    },
    [selectedColumns]
  );
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    setVisible(false);
  }, []);
  /**
   * handle download event
   */
  const handleDownload = useCallback(( isSelected: boolean) => {
    const headers = [] as any;
    const lines = [] as any;
    selectedColumns.forEach((dataIndex: string) => {
      const column = columns.find((item) => {
        if (item.name === dataIndex || item.dataIndex === dataIndex) {
          return item;
        }
        return null;
      });
      if (column) {
        headers.push(column.header || column.title);
      }
    });
    lines.push(headers.join(','));
    (isSelected ? (selectedRows||[]) : data).forEach((row) => {
      const items = [] as any;
      selectedColumns.forEach((dataIndex: string) => {
        let value: string;
        value = (formatColumns && formatColumns[dataIndex]) ? formatColumns[dataIndex][`${row[dataIndex]}`] : row[dataIndex];
        if (isNaN(Number(value)) && moment.utc(value).isValid() && (dataIndex.endsWith('Date') || dataIndex.endsWith('DateTime'))) {
          value = convertTimeByUtc(value);
        }
        if (typeof value === 'string') {
          if (value.includes(',')) {
            if (value.includes('"')) {
              value = value.replace(/"/g, '""');
            }
            value = `"${value}"`;
          }
        } else {
          value = (value || value === 0) ? `"${value}"` : '';
        }
        items.push(value);
      });
      lines.push(items.join(','));
    });
    downloadCSV(lines.join('\n'), scope);
  }, [data, selectedColumns, columns, scope, formatColumns, selectedRows]);
  /**
   * handle checkAll event
   */
  const handleCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      const checkedList: Array<string> = [];
      if (checked) {
        columns.forEach((item: any) => {
          checkedList.push(item.dataIndex || item.name);
        });
      }
      setCheckAll(checked);
      setSelectedColumns(checkedList);
    },
    [columns]
  );
  useEffect(() => {
    const checkedList: Array<string> = [];
    columns.forEach((item) => {
      checkedList.push(item.dataIndex || item.name);
    });
    setSelectedColumns(checkedList);
  }, [columns]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          {showExportSelected && <Button
            id={id}
            key="submit"
            type="primary"
            disabled={selectedColumns.length === 0 || (selectedRows && selectedRows.length === 0)}
            onClick={()=>handleDownload(true)}
            icon={<CloudDownloadOutlined />}
          >
            <span>Export Selected</span>
          </Button>}
          {downloadFromApi && (
            <Button
              key="submit"
              type="default"
              disabled={selectedColumns.length === 0}
              onClick={() => {
                const data: { [key: string]: string } = {};
                selectedColumns.map((i) => {
                  const column = columns.find((item) => {
                    if (item.name === i || item.dataIndex === i) {
                      return item;
                    }
                    return null;
                  });
                  if (column) {
                    data[`${i}`] = column.title || column.header;
                  }
                  return true;
                });
                if (downloadFromApi) {
                  downloadFromApi(data, groupBySku ? 'sku': undefined);
                }
              }}
              icon={<CloudDownloadOutlined />}
            >
              <span>Export Search Result</span>
            </Button>
          )}
          <Button
            id={id}
            key="submit"
            type="primary"
            disabled={selectedColumns.length === 0 || groupBySku}
            onClick={()=> handleDownload(false)}
            icon={<CloudDownloadOutlined />}
          >
            <span>{props.aliasText || 'Download Current Page'}</span>
          </Button>
          <Button onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <>
      <Modal
        title={title || 'Export CSV'}
        centered
        visible={visible}
        width={showExportSelected ? 700 : 600}
        destroyOnClose
        onCancel={handleHide}
        footer={<Footer />}
      >
        <SectionWrapper bordered={true}>
          <Row justify="center">
            <Space>
              <Text>Toggle All Columns</Text>
              <Checkbox checked={checkAll} onChange={handleCheckAll} />
            </Space>
            {showGroupBy && <Space style={{marginLeft: 16}}>
              <Text>Group by SKU</Text>
              <Checkbox checked={groupBySku} onChange={e=>setGroupBySku(e.target.checked)} />
            </Space>}
          </Row>
        </SectionWrapper>
        <Row style={{ marginTop: 10 }}>
          {columns.map((item: StringKAnyVPair, index) => {
            const checked = selectedColumns.find((currentItemId) => {
              if (currentItemId === (item.dataIndex || item.name)) {
                return currentItemId;
              }
              return null;
            })
              ? true
              : false;
            return (
              <Col key={index} span={8}>
                <Space>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      handleChange(item.dataIndex || item.name, e);
                    }}
                  />
                  <Text>{item.title || item.header}</Text>
                </Space>
              </Col>
            );
          })}
        </Row>
      </Modal>
      <Button
        style={style}
        disabled={props.disabled}
        onClick={() => {
          setVisible(true);
        }}
      >
        <CloudDownloadOutlined />
        <span>Export CSV</span>
      </Button>
    </>
  );
}
ExportCSV.defaultProps = {
  style: undefined,
  title: undefined,
};
