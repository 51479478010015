import React, { useEffect, useState } from 'react';
import { Row, Button, Space } from 'antd';
import { CloudUploadOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LinkIcon from 'assets/icons/link';
import { fetchWarehouseListPlus } from 'services/warehouse';
import { DataGridPlus, HeaderTitle, ImportDialog, TablesFilter } from 'components/common';
import { useIsMountedRef } from 'hooks';

type ColumnRenderType = {
  data: WarehouseRow;
};
export default function ZoneManagement() {
  const isMountedRef = useIsMountedRef();
  const [warehouses, setWarehouses] = useState<Array<WarehouseRow>>([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [importDialogVisible, setImportDialogVisible] = React.useState(false);

  const columns = [
    {
      header: 'Warehouse Code',
      name: 'code',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Warehouse Name',
      name: 'name',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      maxWidth: 120,

      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <Row justify="center">
            <Link
              to={`/administration/zones?warehouseNum=${data.id}&warehouseId=${data.warehouseId}`}
            >
              <Button icon={<SnippetsOutlined />}>Zones</Button>
            </Link>
          </Row>
        );
      },
    },
  ];
  useEffect(() => {
    setLoading(true);
    fetchWarehouseListPlus().then((newWarehouses) => {
      if (isMountedRef.current) {
        setWarehouses(newWarehouses.filter((w) => (w.warehouseType === 1 || w.warehouseType === 5)));
        setLoading(false);
      }
    });
  }, [isMountedRef]);
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Zone Management']} />
      <Row justify="space-between">
        <Space>
          <Button type="default" onClick={() => setImportDialogVisible(true)}>
            <CloudUploadOutlined />
            Import Zone
          </Button>
          <TablesFilter
            columns={columns}
            dataSource={warehouses}
            setFilteredData={setFilteredData}
          />
        </Space>
        <Link to="/administration/warehouse">
          <Button type="link">Manage Warehouses</Button>
          <LinkIcon style={{ fontSize: 20 }} />
        </Link>
      </Row>
      <DataGridPlus
        name="zoneWarehouse"
        style={{ minHeight: 500 }}
        idProperty="code"
        dataSource={filteredData}
        loading={loading}
        columns={columns}
        /* eslint-disable react/jsx-props-no-spreading */
        {...({} as any)}
      />
      {importDialogVisible && (
        <ImportDialog
          onRefesh={() => { }}
          onHide={() => setImportDialogVisible(false)}
          operation={{ key: 2102, value: 'Zone', name: 'Zone' }}
          showModal
        />
      )}
    </>
  );
}
