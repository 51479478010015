import React, { useState, useEffect } from 'react';
import { Row, Input } from 'antd';
import SelectLocationDialog from './SelectLocationDialog';
// import { fetchInventory } from 'services/product';
// import { message } from 'components/common';

const { Search } = Input;

type SearchLocationProps = {
  warehouseNum: number;
  warehouseId: string;
  placeholder?: string;
  value?: string;
  width?: number;
  sku?: string;
  onChange: (inv: any[], location?: WarehouseLocationRow) => void;
  lotEnable: boolean;
  uomEnable: boolean;
};
/**
 * Return SelectLocation Component
 * @param {SelectLocationPlusProps} props
 * @returns {JSX.Element}
 */
export default function SelectLocationPlus(
  props: SearchLocationProps
): JSX.Element {
  const {
    warehouseNum,
    onChange,
    value: oriValue,
    width,
    sku,
    warehouseId,
    lotEnable,
    uomEnable
  } = props;
  // const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState<boolean>(false);
  useEffect(() => {
    if (typeof oriValue === 'string') {
      setValue(oriValue || '');
    }
  }, [oriValue]);

  const { placeholder = 'location' } = props;
  /**
   * handle press Enter
   */
  // const handlePressEnter = useCallback(async () => {
  //   const filter = `sku eq '${sku}' and locationCode eq '${value}'`;
  //   try {
  //     setLoading(true);
  //     const res = await fetchInventory(
  //       { id: warehouseNum, warehouseId: warehouseId },
  //       { $filter: filter, virtual: false }
  //     );
  //     setLoading(false);
  //     if (res.data.length > 0) {
  //       onChange(res.data, {
  //         id: res.data[0].locationNum,
  //         code: res.data[0].locationCode,
  //         parent: 0,
  //         parentCode: '',
  //         quantity: 0,
  //         creator: '',
  //         reserved: false,
  //         backstock: false,
  //         warehouseNum: res.data[0].warehouseNum,
  //         warehouseId: res.data[0].warehouseNum,
  //         locationId: res.data[0].locationId,
  //         locationNum: res.data[0].locationNum,
  //       });
  //       setLocation({
  //         id: res.data[0].locationNum,
  //         code: res.data[0].locationCode,
  //         parent: 0,
  //         parentCode: '',
  //         quantity: 0,
  //         creator: '',
  //         reserved: false,
  //         backstock: false,
  //         warehouseNum: res.data[0].warehouseNum,
  //         warehouseId: res.data[0].warehouseNum,
  //         locationId: res.data[0].locationId,
  //         locationNum: res.data[0].locationNum,
  //       });

  //       // message.success({ content: 'Location Code matched' });
  //     } else {
  //       message.warning({ content: 'not matched' });
  //       onChange([], undefined);
  //       setLocation(undefined);
  //       setValue('');
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // }, [value, sku, warehouseNum, onChange, warehouseId]);

  const style: StringKAnyVPair = { width: width || 200 };
  return (
    <Row align="middle">
      <Search
        style={style}
        disabled={!sku}
        placeholder={placeholder || 'Location'}
        // loading={loading}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          const { value: currentValue } = e.target;
          setValue(currentValue);
        }}
        onBlur={() => {
          if (
            (value && !location) ||
            (value && location && value !== location.locationCode)
          ) {
            setSelectLocationDialogVisible(true);
          }
        }}
        onPressEnter={() => setSelectLocationDialogVisible(true)}
        onKeyDown={(e) =>
          e.keyCode === 9 && setSelectLocationDialogVisible(true)
        }
        onSearch={() => setSelectLocationDialogVisible(true)}
      />
      {selectLocationDialogVisible && (
        <SelectLocationDialog
          visible={true}
          sku={sku}
          warehouseNum={warehouseNum}
          warehouseId={warehouseId}
          defaultLocationCode={value}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
          onSelect={(row) => {
            setValue(row.locationCode);
            setLocation(row);
            onChange(row);
            setSelectLocationDialogVisible(false);
          }}
          onHide={() => {
            setSelectLocationDialogVisible(false);
          }}
        />
      )}
    </Row>
  );
}
SelectLocationPlus.defaultProps = {
  placeholder: undefined,
  value: undefined,
  width: undefined,
  showSearchButton: undefined,
  onChange: undefined,
};
