import React from 'react';
import { Button, Col, Input, Row } from 'antd';
import { message } from 'components/common';
import {
  LS_TESTING_PROFILE_KEY,
  LS_TESTING_TOKEN_KEY,
} from 'constants/config';

const CypressDoor = () => {
  const { useState } = React;
  const { TextArea } = Input;
  const [token, setToken] = useState('');
  const [profile, setProfile] = useState<StringKAnyVPair>({});

  const handleAuthorization = () => {
    //tokenKeeper.apiToken = token;
    //userProfiles.profiles = [profile];
    localStorage.setItem(LS_TESTING_PROFILE_KEY, JSON.stringify(profile));
    localStorage.setItem(LS_TESTING_TOKEN_KEY, token);
    window.location.reload();
  };

  const onTokenChange = (value: any) => {
    //console.log('v', value.target.value);
    //tokenKeeper.apiToken = value.target.value.trim();
    setToken(value.target.value.trim());
  };

  const onProfileChange = (value: any) => {
    //console.log('pc', value.target.value);
    try {
      const p = JSON.parse(value.target.value);

      //userProfiles.profiles = [profile];
      setProfile(p);
    } catch (e) {
      message.error(`error: ${e}`);
    }
  };

  return (
    <>
      <Row align="middle" justify="center" style={{width: '100%', height: '100vh'}}>
        <Col style={{width: 500}}>
          <span>Token:</span>
          <TextArea rows={5} onChange={onTokenChange} />
          <span>Profile:</span>
          <TextArea rows={5} onChange={onProfileChange} />
          <Row justify="end" style={{marginTop: 16}}>
            <Button type="primary" onClick={handleAuthorization}>Ok</Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CypressDoor;
