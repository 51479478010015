import React, { useCallback, useState } from 'react';
import { Form, Row, Modal, Button, Space, InputNumber } from 'antd';
import { FormLabel } from 'components/common';
import { message } from 'components/common';
import { saveBulkReturnLine } from 'services/return';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onSave: (data: any) => void;
  data: any;
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onSave, data } = props;
  const [inspectionQty, setInspectionQty] = useState<number>(
    data.receiveQty - data.stockQty - data.nonStockQty
  );
  const [showStokMessage, setShowStockMassage] = useState(false);
  const [showNoStokMessage, setShowNoStockMassage] = useState(false);
  const [showMessage, setShowMassage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onInputChange = useCallback(async () => {
    const params = await form.getFieldsValue();
    setInspectionQty(
      (params.receiveQty || 0) -
      (params.stockQty || 0) -
      (params.nonStockQty || 0)
    );
    if (params.stockQty > params.receiveQty) {
      setShowStockMassage(true);
      setShowNoStockMassage(false);
      setShowMassage(false);
      return;
    }

    if (params.nonStockQty > params.receiveQty) {
      setShowStockMassage(false);
      setShowNoStockMassage(true);
      setShowMassage(false);
      return;
    }

    if (
      (params.receiveQty || 0) -
      (params.stockQty || 0) -
      (params.nonStockQty || 0) <
      0
    ) {
      setShowMassage(true);
      setShowStockMassage(false);
      setShowNoStockMassage(false);
    } else {
      setShowMassage(false);
      setShowStockMassage(false);
      setShowNoStockMassage(false);
    }
  }, [form]);

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const handleSave = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setLoading(true);
      const res = await saveBulkReturnLine(data.fulfillmentBulkReturnNum, {
        ...data,
        receiveQty: params.receiveQty,
        stockQty: params.stockQty,
        nonStockQty: params.nonStockQty,
        inspectionQty: params.receiveQty - params.stockQty - params.nonStockQty,
      });
      setLoading(false);
      if (res.isSuccess) {
        onSave(res.data);
        message.success({ content: `Update [${data.sku}] successfully` });
      }
    } catch (error) {
      setLoading(false);
    }
  }, [data, form, onSave]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title={`Edit - (${data.sku})`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              disabled={inspectionQty < 0}
              key="submit"
              loading={loading}
              type="primary"
              onClick={handleSave}
            >
              <SaveOutlined />
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      {data && (
        <Form {...layout} form={form}>
          <Form.Item
            name="receiveQty"
            initialValue={data.receiveQty || 0}
            label={<FormLabel fontSize={20}>Receive Qty</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Please input Resaleable Inspection!',
              },
            ]}
          >
            <InputNumber
              size="large"
              min={0}
              precision={0}
              onChange={onInputChange}
            />
          </Form.Item>

          <Form.Item
            label={<FormLabel fontSize={20}>Inspection Qty</FormLabel>}
            validateStatus={showMessage ? 'error' : undefined}
            help={
              showMessage
                ? 'The inspection qty cannot be negative，please check the input.'
                : undefined
            }
          >
            <span style={{ fontSize: 20 }}>{inspectionQty}</span>
          </Form.Item>

          <Form.Item
            name="stockQty"
            initialValue={data.stockQty || 0}
            label={<FormLabel fontSize={20}>Resaleable Qty</FormLabel>}
            rules={[{ required: true, message: 'Please input Resaleable Qty' }]}
            validateStatus={showStokMessage ? 'error' : undefined}
            help={
              showStokMessage
                ? 'The resaleable qty cannot exceed the receive qty.'
                : undefined
            }
          >
            <InputNumber
              size="large"
              min={0}
              precision={0}
              onChange={onInputChange}
            />
          </Form.Item>

          <Form.Item
            name="nonStockQty"
            initialValue={data.nonStockQty || 0}
            label={<FormLabel fontSize={20}>Damage Qty</FormLabel>}
            rules={[{ required: true, message: 'Please input Damage Qty!' }]}
            validateStatus={showNoStokMessage ? 'error' : undefined}
            help={
              showNoStokMessage
                ? 'The damage qty cannot exceed the receive qty.'
                : undefined
            }
          >
            <InputNumber
              size="large"
              min={0}
              precision={0}
              onChange={onInputChange}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
