import React, { useCallback, useEffect } from 'react';
import { Form, Row, Modal, Button, Space, Select, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormLabel } from 'components/common';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onApply: (data: string) => void;
  defaultValue: string;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, defaultValue, onApply } = props;

  const [form] = Form.useForm();

  const handleCheck = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const { skus, checkType } = params;

    const items: string[] = Array.from(
      new Set(
        skus.split(['\n', ','][checkType]).map((i: string) => i.trim())
      )
    );

    onApply(items.filter((i) => i).toString());
  };

  const formatValue = useCallback((separator)=>{
    const {skus} = form.getFieldsValue();
    if( skus ) {
      if (separator === 1){
        form.setFieldsValue({skus: skus.replaceAll('\n' ,',')})
      } else {
        form.setFieldsValue({skus: skus.replaceAll(',' ,'\n')})
      }
    }
  },[form])

  useEffect(()=>{
    if(defaultValue){
      form.setFieldsValue({skus: defaultValue.replaceAll(',' ,'\n')})
    }
  },[defaultValue, form])

  const layout = {
    wrapperCol: { span: 24 },
  };

  return (
    <Modal
      title="Input List SKU/UPC"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={() => onHide()}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="sync_product_continue_button"
              key="submit"
              type="primary"
              onClick={handleCheck}
            >
              Apply
            </Button>
            <Button
              id="sync_product_cancl_button"
              onClick={() => onHide()}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form} style={{ marginTop: 10 }}>
        <>
          <Row>
            <Form.Item
              name="checkType"
              initialValue={0}
              label={<FormLabel>SKU Separator</FormLabel>}
            >
              <Select style={{ width: 150 }} onChange={(value)=>{
                formatValue(value)
              }}>
                <Select.Option value={0}>Line</Select.Option>
                <Select.Option value={1}>Comma</Select.Option>
                {/* <Select.Option value={1}>Tabs</Select.Option> */}
              </Select>
            </Form.Item>
          </Row>
          <Form.Item
            name="skus"
            label=""
            initialValue={defaultValue}
            rules={[{ required: true, message: 'Please input SKUs!' }]}
          >
            <Input.TextArea rows={10} placeholder="Input SKUs" />
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
};
