import React, { useCallback, useEffect, useState } from 'react';
import { Row, Space, Form, Spin, Modal, Select, Button, Input, Switch} from 'antd';
import {getProductLotList, getProductUomList, getCheckoutProductLotUom} from 'services/product'
import GenerateLotDialog from '../../Po/PoReceive2/GenerateLotDialog';
import moment from 'moment';
import SelectLotDialog from 'components/Catalog/LotNumberView/SelectLotNumberDialog'
import { SearchOutlined } from '@ant-design/icons';

type Props = {
  uomEnable: boolean;
  lotEnable: boolean;
  product?: ProductRow;
  noNew?: boolean;
  timeZone: string;
  onHide: () => void;
  onOk: (data: any)=>void;
  warehouseId?: string;
  scope?: number; //0:all, 1: checkout
};

export default function AddNoteDialog(props: Props): JSX.Element {
  const { onHide, product, onOk, uomEnable, lotEnable, timeZone, noNew, scope, warehouseId } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [lotList, setLotList] = useState<any>([])
  const [lotUomList, setLotUomList] = useState<any[]>([])
  const [filterList, setFilterList] = useState<any>([])
  const [expiredfilter, setExpiredFilter] = useState(true)
  const [uomList, setUomList] = useState<any>([])
  const [generateLotDialogVisible, setGenerateLotDialogVisible] =
    useState(false);
  const [selectLotDialogVisible, setSelectLotDialogVisible] = useState(false);

  const [form] = Form.useForm()


  const getLotNumber = useCallback(async(id: string)=>{
    if(!lotEnable) return
    try {
      setLoading(true)
      const res = await getProductLotList(id)
      setLoading(false)
      if(res.isSuccess){
        setLotList(res.data)
      }
    } catch (error) {
      setLoading(false)
    }
  },[lotEnable])

  const getUomList = useCallback(async(sku:string)=>{
    if(!sku) return
    if(!uomEnable) return
    try {
      setLoading(true)
      const res = await getProductUomList(sku)
      setLoading(false)
      if(res.isSuccess){
        setUomList(res.data)
      }
    } catch (error) {
      setLoading(false)
    }
  },[uomEnable])

  const getCheckoutLotUom = useCallback(async()=>{
    console.log(product,warehouseId)
    if(!product || !warehouseId) return 
    try {
      setLoading(true)
      const res = await getCheckoutProductLotUom(warehouseId, product.fulfillmentProductNum || 0)
      setLoading(false)
      setLotUomList(res)
    } catch (error) {
      setLoading(false)
    }
  },[warehouseId, product])

  useEffect(()=>{
    if(product){
      if(scope === 1){
        getCheckoutLotUom()
      }else{
        getLotNumber(product.productId)
        getUomList(product.sku || '')
      }
    }
  },[product, getLotNumber, getUomList, scope, getCheckoutLotUom])

  useEffect(()=>{
    if(expiredfilter){
      const temp = lotList.filter((i:any) => !(i.expirationDate && moment(i.expirationDate).diff(moment()) < 0))
      setFilterList([...temp])
    } else {
      setFilterList([...lotList])
    }
  },[expiredfilter,lotList])

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if(scope === 1){
      const temp = lotUomList.filter(i => i.inventoryNum === params.lotUom)
      if(temp.length > 0){
        onOk({
          lotNumber: temp[0].lotNumber,
          uom:temp[0].uom,
          uomRate: temp[0].uomRate
        })
      }

    }else {
      onOk(params)
    }
  }, [form, onOk, lotUomList, scope]);
  return (
    <Modal
      title="Select LOT# & UOM"
      centered
      visible
      width={450}
      destroyOnClose
      getContainer={false}
      forceRender
      onCancel={()=> onHide()}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="default"
                onClick={(

            )=>{
              handleSubmit()
            }}
            >OK</Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        {scope===1?(<Form form={form}>
          <Form.Item name="lotUom" label="LOT#(UOM)">
            <Select >
              {lotUomList.map((i:any)=><Select.Option key={i.inventoryNum} value={i.inventoryNum}>{`${i.lotNumber}(${i.uom})`}</Select.Option>)}
            </Select>
          </Form.Item>
        </Form>):(<Form form={form}>
          <Form.Item label="Hide expired LOT#">
            <Switch checked={expiredfilter} onChange={setExpiredFilter} checkedChildren="On" unCheckedChildren="Off"/>
          </Form.Item>
          <Form.Item label="LOT#">
            <Space size="small">
            <Form.Item noStyle name="lotNumber">
              <Select 
                showSearch
                allowClear
                optionFilterProp="label"
                style={{width: noNew ? 327 : 196}}
              >
                {filterList.map((i:any)=><Select.Option key={i.lotNumber} value={i.lotNumber} label={i.lotNumber}>{i.lotNumber}</Select.Option>)}
              </Select>
            </Form.Item>
            <Button icon={<SearchOutlined />} onClick={()=>{setSelectLotDialogVisible(true)}}></Button>
            {!noNew && <Button onClick={() => setGenerateLotDialogVisible(true)}>
                  Generate LOT#
                </Button>}
            </Space>
          </Form.Item>
          <Form.Item name="uom" label="UOM">
            <Select onChange={(value)=>{
              const temp = uomList.filter((j:any) => j.uom === value)
              if(temp.length > 0){
                form.setFieldsValue({
                  uomRate: temp[0].uomRate
                })
              }
            }}>
              {uomList.map((i:any)=><Select.Option key={i.uom} value={i.uom}>{`${i.uom}(${i.uomRate})`}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item name="uomRate" label="Rate">
            <Input disabled bordered={false}/>
          </Form.Item>
        </Form>)}
      </Spin>
      {generateLotDialogVisible && (
        <GenerateLotDialog
          onClose={() => setGenerateLotDialogVisible(false)}
          onSave={(data: { [key: string]: any }) => {
            setGenerateLotDialogVisible(false);
            getLotNumber(product?.productId || '')
          }}
          noUniqueControl={true}
          rows={[{ ...product }]}
          generate={true}
          timeZone={timeZone}
          lotNumberGenerationType={1}
          visible
        />
      )}
      {selectLotDialogVisible && (
        <SelectLotDialog 
          sku={product?.sku || ''} 
          onClose={()=>{setSelectLotDialogVisible(false)}}
          onSelect={(lotNumber)=> {
            form.setFieldsValue({lotNumber})
            setSelectLotDialogVisible(false)
          }}
          visible
        />
      )}
    </Modal>
  );
}
