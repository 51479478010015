import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Select,
  Row,
  Input,
  Card,
  Form,
  Col,
  DatePicker,
  Space,
  Modal,
} from 'antd';
import { getWhiteList, delWhiteList } from 'services/whiteList';
import { PlusOutlined, SearchOutlined, ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  message as showMessage,
  DeleteButton,
  HeaderTitle,
  DataGridPlus,
  GreyCardWrapper,
  FormLabel,
  ColumnDataType,
} from 'components/common';
import CreateDialog from './createDialog';
import { fetchStoreList } from 'services/storeSetup';
import { sortByString, convertToUtcTime, onSelectionChange } from 'utils';
import moment from 'moment';
import styled from 'styled-components';
import themes from 'assets/styles/theme';


const { RangePicker } = DatePicker;

export const DangerIcon = styled(ExclamationCircleOutlined)`
  color: ${themes['@danger-color']} !important;
`;

type ColumnRenderType = {
  data: any;
};
// eslint-disable-next-line
export default () => {
  const [whiteList, setWhiteList] = useState<BrandRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [createlVisible, setCreatelVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const sortString = useRef<string>('CreateDate desc');
  const [tempParams, setTemParams] = useState<any>();
  const [channelAccounts, setChannelAccounts] = useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>({});
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [sortInfo, setSortInfo] = React.useState<any>({});

  //   const [current, setCurrent] = useState<BrandRow>();
  const [form] = Form.useForm();

  const columns = [
    {
      name: 'orderWhiteListNum',
      header: 'ID',
      defaultVisible: false,
    },
    {
      name: 'channelAccountName',
      header: 'Channel Account',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'channelOrderId',
      header: 'Channel Order ID',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'createDate',
      header: 'Create Date',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
    },
    {
      name: 'createBy',
      header: 'Created By',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      sortable: false,
      groupBy: false,
      textAlign: 'center',
      maxWidth: 150,
      minWidth: 150,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <Space>
            {!data.isSysDefined && (
              <DeleteButton
                confirmContent={`channel accoount: ${data.channelAccountName}  channelOrderId: ${data.channelOrderId}`}
                confirmTitle="Are you sure you want to delete the row?"
                onClick={() => delList([data.orderWhiteListNum])}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const getList = async ({
    limit,
    skip,
    sortString,
    fromSearch,
  }: {
    limit?: number;
    skip?: number;
    sortString?: string;
    fromSearch?: boolean;
  }) => {
    setSelected({})
    setSelectedRow([])
    setLoading(true);
    let params: any;
    if (!fromSearch) {
      params = {
        ...tempParams,
        $skip: skip ? skip : 0,
        $top: limit ? limit : 10,
        $sortBy: sortString || 'CreateDate desc',
      };
    } else {
      setSortInfo({})
      const formValue = form.getFieldsValue();
      params = {
        channelAccountNum: formValue.ChannelAccountNum,
        channelOrderId: formValue.ChannelOrderId,
        createdBy: formValue.CreatedBy,
        startDate: formValue.CreateDate
          ? convertToUtcTime(formValue.CreateDate[0].startOf('day').format())
          : undefined,
        endDate: formValue.CreateDate
          ? convertToUtcTime(formValue.CreateDate[1].endOf('day').format())
          : undefined,
        $skip: skip ? skip : 0,
        $top: limit ? limit : 10,
        $sortBy: sortString || 'CreateDate desc',
      };
    }
    try {
      const res = await getWhiteList(params);
      setWhiteList(res.data);
      setTotal(res.count);
      setTemParams(params);
      skipRef.current = skip || 0;
      limitRef.current = limit || 10;
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const delList = async (nums: number[]) => {
    setLoading(true);
    try {
      const res = await delWhiteList(nums);
      if (res) {
        showMessage.success({
          content: `Deleted White List Successfully`,
        });
        getList({
          limit: limitRef.current,
          skip: skipRef.current,
          sortString: sortString.current,
        });
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setChannelAccounts(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Sales Order', 'Order White List']}>
        <Button type="primary" onClick={() => setCreatelVisible(true)}>
          <PlusOutlined />
          Add White List
        </Button>
      </HeaderTitle>

      <Card style={{ width: '100%', minWidth: 400 }}>
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <FormLabel>Channel Account</FormLabel>
              <Form.Item name="ChannelAccountNum">
                <Select allowClear showSearch optionFilterProp="label">
                  {channelAccounts.map((item) => (
                    <Select.Option
                      key={item.channelAccountNum}
                      value={item.channelAccountNum}
                      label={item.channelAccountName}
                    >
                      {item.channelAccountName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Channel Order ID</FormLabel>
              <Form.Item name="ChannelOrderId">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Create Date</FormLabel>
              <Form.Item
                name="CreateDate"
                initialValue={[
                  moment().add(-7, 'days').startOf('day'),
                  moment().endOf('day'),
                ]}
              >
                <RangePicker
                  allowClear
                  style={{ width: '100%' }}
                  format="MM/DD/YYYY"
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Created By</FormLabel>
              <Form.Item name="CreatedBy">
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="center" align="middle" style={{ marginTop: 16 }}>
          <Space>
            <Button
              loading={loading}
              type="primary"
              onClick={() =>
                getList({
                  limit: limitRef.current,
                  skip: 0,
                  fromSearch: true,
                })
              }
            >
              <SearchOutlined />
              <span>Search</span>
            </Button>
            <Button onClick={() => form.resetFields()}>
              <ClearOutlined />
              Reset
            </Button>
          </Space>
        </Row>
      </Card>

      <GreyCardWrapper
        style={{
          marginTop: 20,
        }}
      >
        <Row justify="end">
          <Space>
            <Button
              id="batch_delete_button"
              disabled={selectedRow.length === 0 ? true : false}
              onClick={(e) => {
                Modal.confirm({
                  title: "Are you sure you want to delete the selected rows?",
                  icon: <DangerIcon />,
                  className: 'del-modal-dialog-context',
                  okText: 'Delete',
                  okType: 'danger',
                  content: "",
                  onOk: () => {
                    delList(selectedRow.map((i) => i.orderWhiteListNum));
                  },
                });
                e.stopPropagation();
                return false;
              }}
            >
              <i className="bi-trash" />
              <span style={{ marginLeft: 8 }}>Batch Delete</span>
            </Button>
          </Space>
        </Row>

        <DataGridPlus
          idProperty="orderWhiteListNum"
          columns={columns}
          count={total}
          dataSource={whiteList}
          loading={loading}
          skip={skipRef.current}
          autoWith={false}
          pageSizes={[10, 50, 200, 500, 1000]}
          pagination
          onChange={(limit, skip) => {
            setSelected({});
            setSelectedRow([]);
            limitRef.current = limit;
            skipRef.current = skip;
            getList({ limit, skip, sortString: sortString.current });
          }}
          checkboxColumn
          checkboxOnlyRowSelect={true}
          selected={selected}
          onSelectionChange={(props) => onSelectionChange(props, whiteList, setSelected, setSelectedRow, 'orderWhiteListNum')}
          // onSelectionChange={(props) => {
          //   const { selected, data, unselected } = props;
          //   setSelected(selected);
          //   if (selected === true && !unselected) {
          //     setSelectedRow(data as any);
          //   } else {
          //     if (!unselected) {
          //       setSelectedRow(Object.values(selected as any));
          //     } else {
          //       setSelectedRow((prev) => {
          //         return prev.filter(
          //           (item) =>
          //             item.orderWhiteListNum !== (data as any).orderWhiteListNum
          //         );
          //       });
          //     }
          //   }
          // }}
          sortInfo={sortInfo}
          onSortInfoChange={async (v: any) => {
            setSelected({});
            setSelectedRow([]);
            setSortInfo(v)
            if (v) {
              if (v.dir === 1) {
                sortString.current = `${v.id} asc`;
                getList({
                  limit: limitRef.current,
                  skip: skipRef.current,
                  sortString: sortString.current,
                });
              }
              if (v.dir === -1) {
                sortString.current = `${v.id} desc`;
                getList({
                  limit: limitRef.current,
                  skip: skipRef.current,
                  sortString: sortString.current,
                });
              }
            } else {
              sortString.current = 'CreateDate desc';
              getList({
                limit: limitRef.current,
                skip: skipRef.current,
                sortString: sortString.current,
              });
            }
          }}
          // pagination="local"
          {...({} as any)}
        />
      </GreyCardWrapper>

      {createlVisible && (
        <CreateDialog
          visible
          channelAccounts={channelAccounts}
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => getList({})}
        />
      )}
    </>
  );
};
