import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Space,
  Form,
  DatePicker,
  Modal,
  Typography,
} from 'antd';
import {
  SectionWrapper,
  FormLabel,
  HeaderTitle,
  DataGridPlus,
  ColumnDataType,
  message,
  CopyComponent,
  CopySpan,
  TablesFilter,
} from 'components/common';
import {
  fetchShipmentContainers,
  fetchShippingAccount,
  uploadShipmentContainer,
  deleteShipmentContainer,
  resetShippingLable,
} from 'services/shipmentContainers';
import {
  LS_DEFAULT_WAREHOUSE_KEY,
  LS_SHIPMENT_CONTAINER_PRINTER_KEY,
} from 'constants/config';
import EditDialog from './EditDialog';
import { getAddressList } from 'services/address';
import { fetchCarriers } from 'services/shipment';
import moment from 'moment';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseCodeFromCache } from 'utils';
import DeviceSettingDialog from 'components/Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import SetDefaultPrinter from './SetDefaultPrinter';
import { fetchPrinterList } from 'services/print';
import ShippingLabelDialog from './ShippingLableDialog';
import {
  PRINT_CONTAINER_SHIPPING_LABEL,
  REPRINT_CONTAINER_SHIPPING_LABEL,
} from 'constants/config';
import { setPrintLabelResponse } from 'actions/salesAction';

const { RangePicker } = DatePicker;
const { Text } = Typography;
const statusList = [
  {
    num: 0,
    value: 'Draft',
  },
  {
    num: 1,
    value: 'Shipping Label Created',
  },
  {
    num: 2,
    value: 'Appointment',
  },
  {
    num: 8,
    value: 'Abnormal',
  },
  {
    num: 7,
    value: 'Printing',
  },
  {
    num: 9,
    value: 'Cancel',
  },
];
const statusEnum: any = Object.freeze({
  '0': 'Draft',
  '1': 'Shipping Label Created',
  '2': 'Appointment',
  '7': 'Printing',
  '8': 'Abnormal',
  '9': 'Cancel',
});

export default function SmallPackagesView(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [addressBook, setAddressBook] = useState<AddressRow[]>();
  const [carriers, setCarriers] = useState<CarrierRow[]>();
  const [shipAccounts, setShipAccounts] = useState<any[]>([]);
  const [packsData, setPacksData] = useState<any[]>();
  const [count, setCount] = useState<number>(0);
  const [tempSearchParams, setTempSearchParams] = useState<any>();
  const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>();
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const sortString = useRef('ShipmentContainerNum asc');
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [printerVisible, setPrinterVisible] = React.useState(false);
  const [defaultPrinter, setDefaultPrinter] = React.useState<Printer>();
  const [shippingLabelVisible, setShippingLabelVisible] = React.useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );

  const dispatch = useDispatch();

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (!localStorage.getItem(LS_SHIPMENT_CONTAINER_PRINTER_KEY)) {
      setPrinterVisible(true);
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  const getCarriers = async () => {
    try {
      const res = await fetchCarriers();
      setCarriers(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getShipAccount = async () => {
    if (!localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) return;
    try {
      const res = await fetchShippingAccount(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
        4
      );
      if (res.isSuccess) {
        setShipAccounts(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressBook = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAddressList();
      setLoading(false);
      if (res.isSuccess) {
        setAddressBook(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const getPacks = useCallback(async () => {
    const params = await form.getFieldsValue();
    try {
      setLoading(true);
      const res = await fetchShipmentContainers({
        ...params,
        createTime: params.createTime
          ? `${moment(params.createTime[0]).startOf('days').format()}@${moment(
            params.createTime[1]
          )
            .endOf('days')
            .format()}`
          : undefined,
        $skip: 0,
        $top: 10,
        $sortBy: sortString.current,
      });
      if (res) {
        skipRef.current = 0;
        setPacksData(res.data);
        setCount(res.count);
        setTempSearchParams({
          ...params,
          createTime: params.createTime
            ? `${moment(params.createTime[0])
              .startOf('days')
              .format()}@${moment(params.createTime[1])
                .endOf('days')
                .format()}`
            : undefined,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [form]);

  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      //api
      if (!tempSearchParams) return;
      setLoading(true);
      try {
        const res = await fetchShipmentContainers({
          ...tempSearchParams,
          $skip: skip,
          $top: limit,
          $sortBy: sortString.current,
        });
        if (res) {
          setPacksData(res.data);
          setCount(res.count);
          limitRef.current = limit;
          skipRef.current = skip;
        }
        setLoading(false);
      } catch (error) { }
    },
    [tempSearchParams]
  );

  const getPrinterList = React.useCallback(async () => {
    if (Number(localStorage.getItem(LS_SHIPMENT_CONTAINER_PRINTER_KEY))) {
      try {
        const res = await fetchPrinterList();
        if (res.isSuccess) {
          const temp = res.data.filter(
            (i: any) =>
              i.printerNum ===
              Number(localStorage.getItem(LS_SHIPMENT_CONTAINER_PRINTER_KEY))
          );
          setDefaultPrinter(temp.length > 0 ? temp[0] : undefined);
        } else {
          message.error({
            content: res.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const uploadContainer = React.useCallback(
    async (data: any) => {
      try {
        const res = await uploadShipmentContainer(data.shipmentContainerNum);
        if (res.isSuccess) {
          message.success({ content: 'confirm forecast successfully' });
          getPacks();
        }
      } catch (error) { }
    },
    [getPacks]
  );

  const resetContainer = React.useCallback(
    async (data: any) => {
      try {
        const res = await resetShippingLable(data.shipmentContainerNum);
        if (res.isSuccess) {
          message.success({ content: 'reset state successfully' });
          getPacks();
        }
      } catch (error) { }
    },
    [getPacks]
  );

  const delShipmentContainer = React.useCallback(
    async (data: any) => {
      try {
        const res = await deleteShipmentContainer(data.shipmentContainerId);
        if (res) {
          message.success({ content: 'deleted successfully' });
          getPacks();
        }
      } catch (error) { }
    },
    [getPacks]
  );

  const getColumns = useCallback(() => {
    return [
      {
        header: 'Container ID',
        name: 'containerId',
        defaultFlex: 1,
        defaultLocked: 'start',
        showColumnMenuTool: false,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <CopyComponent value={data.containerId}>
              <Text
                ellipsis={{ tooltip: data.locationCode }}
                style={{
                  cursor: 'pointer',
                  color: '#1976d2',
                  width: `calc(100% - 14px)`,
                }}
                onClick={() => {
                  setCurrent(data);
                  setEditDialogVisible(true);
                }}
              >
                {data.containerId}
              </Text>
            </CopyComponent>
          );
        },
      },
      {
        header: 'Tracking Number',
        name: 'trackingNumber',
        defaultFlex: 1,
        userSelect: true,
        showColumnMenuTool: false,
      },
      {
        header: 'Status',
        name: 'status',
        defaultFlex: 1,
        userSelect: true,
        showColumnMenuTool: false,
        renderEnum: statusEnum,
        render: (value: any) => {
          const { data } = value;
          return <CopySpan inGrid value={statusEnum[`${data.status}`]} />;
        },
      },
      {
        header: 'Carrier Service',
        name: 'carrierServiceName',
        // type: 'number',
        defaultFlex: 1,
        userSelect: true,
        showColumnMenuTool: false,
      },
      {
        header: `Shipper Address`,
        name: 'shipperAddressNum',
        defaultFlex: 1,
        userSelect: true,
        showColumnMenuTool: false,
        render: (value: any) => {
          const { data } = value;
          const temp = (addressBook || []).filter(
            (i) => i.addressNum === data.shipperAddressNum
          );
          return (
            <CopySpan
              inGrid
              value={
                temp.length > 0 ? temp[0].addressAlias : data.shipperAddressNum
              }
            />
          );
        },
      },
      {
        header: 'Consignee Address',
        name: 'consigneeAddressNum',
        defaultFlex: 1,
        showColumnMenuTool: false,
        render: (value: any) => {
          const { data } = value;
          const temp = (addressBook || []).filter(
            (i) => i.addressNum === data.consigneeAddressNum
          );
          return (
            <CopySpan
              inGrid
              value={
                temp.length > 0
                  ? temp[0].addressAlias
                  : data.consigneeAddressNum
              }
            />
          );
        },
      },
      {
        header: 'Carrier',
        name: 'carrierNum',
        defaultFlex: 1,
        showColumnMenuTool: false,
        render: (value: any) => {
          const { data } = value;
          const temp = (carriers || []).filter(
            (i) => i.carrierNum === data.carrierNum
          );
          return (
            <CopySpan
              inGrid
              value={temp.length > 0 ? temp[0].name : data.carrierNum}
            />
          );
        },
      },
      {
        header: 'Create Time',
        name: 'createDate',
        defaultFlex: 1,
        showColumnMenuTool: false,
        dataType: ColumnDataType.DATE,
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
              <Button
                onClick={() => {
                  setCurrent(data);
                  setEditDialogVisible(true);
                }}
              >
                {[7, 8, 9].includes(data.status) ? 'View' : 'Edit'}
              </Button>
              {data.status !== 7 ? (
                <Button
                  disabled={
                    [7, 9].includes(data.status) ||
                    data.shipmentContainerLines.length === 0
                  }
                  onClick={() => {
                    setCurrent(data);
                    setShippingLabelVisible(true);
                  }}
                >
                  {[1, 2, 8].includes(data.status)
                    ? 'Reprint Shipping Lable'
                    : 'Print Shipping Label'}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure you want to reset state ?',
                      okText: 'Yes',
                      content: `Container ID: ${data.containerId}`,
                      onOk: async () => {
                        await resetContainer(data);
                      },
                    });
                  }}
                >
                  Reset State
                </Button>
              )}
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to confirm forecast ?',
                    okText: 'Yes',
                    content: `Container ID: ${data.containerId}`,
                    onOk: async () => {
                      await uploadContainer(data);
                    },
                  });
                }}
                disabled={[0, 2, 7, 9].includes(data.status)}
              >
                {[8].includes(data.status)
                  ? 'Reconfirm forecast'
                  : 'Confirm forecast'}
              </Button>
              <Button
                disabled={[1, 2, 7, 8].includes(data.status)}
                className={[1, 2, 7, 8].includes(data.status) ? '' : "hover-danger-button-2"}
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to delete the row ?',
                    content: `Container ID: ${data.containerId}`,
                    okText: 'Yes',
                    okType: 'default',
                    okButtonProps: { className: "hover-danger-button" },
                    cancelText: 'No',
                    autoFocusButton: 'cancel',
                    onOk() {
                      delShipmentContainer(data);
                    },
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          );
        },
      },
    ];
  }, [
    carriers,
    addressBook,
    uploadContainer,
    delShipmentContainer,
    resetContainer,
  ]);

  useEffect(() => {
    if (printLabelResponse) {
      // console.log('printLabelResponse', printLabelResponse);
      if (
        printLabelResponse.id &&
        printLabelResponse.sender === 'PrintStation' &&
        (printLabelResponse.type === PRINT_CONTAINER_SHIPPING_LABEL ||
          printLabelResponse.type === REPRINT_CONTAINER_SHIPPING_LABEL)
      ) {
        setTimeout(() => {
          message.success({
            content: printLabelResponse.message,
          });
          dispatch(setPrintLabelResponse({}));
        });
      }
    }
  }, [printLabelResponse, dispatch]);

  /* eslint-disable */
  useEffect(() => {
    fetchAddressBook();
    getCarriers();
    getShipAccount();
    getPacks();
    getPrinterList();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Sales Order', 'Consolidate Packages']}>
        <Space>
          <Space>
            {/* <Text type="secondary" style={{fontSize: 12}}>Current Printer:</Text> */}
            <Text type="secondary" style={{ fontSize: 12 }}>
              {defaultPrinter
                ? `${defaultPrinter.pcName}[${defaultPrinter.printerName}] - ${defaultPrinter.online ? 'online' : 'offline'
                }`
                : 'none'}
            </Text>
          </Space>

          <Button onClick={() => setPrinterVisible(true)}>
            Select Printer
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCurrent(undefined);
              setEditDialogVisible(true);
            }}
          >
            Create Collection Forecast
          </Button>
        </Space>
      </HeaderTitle>

      <SectionWrapper>
        <Row justify="start" style={{ maxWidth: 1800 }}>
          <Form form={form} style={{ width: '100%' }}>
            <Row
              align="middle"
              gutter={12}
              justify="start"
              style={{ width: '100%' }}
            >
              <Col span={5}>
                <FormLabel>Container ID</FormLabel>
                <Form.Item name="containerId" noStyle>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <FormLabel>Package Tracking Number</FormLabel>
                <Form.Item name="trackingNumber" noStyle>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <FormLabel>Status</FormLabel>
                <Form.Item name="status" noStyle>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                  // dropdownMatchSelectWidth={false}
                  >
                    {statusList.map((i) => (
                      <Select.Option key={i.num} value={i.num}>
                        {i.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
                <FormLabel>Create Time</FormLabel>
                <Form.Item
                  name="createTime"
                  initialValue={[
                    moment().add(-7, 'day').startOf('day'),
                    moment().endOf('day'),
                  ]}
                  noStyle
                >
                  <RangePicker
                    format="MM/DD/YYYY"
                    placeholder={['Date From', 'Date To']}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      'Last Month': [
                        moment().add(-1, 'month').startOf('month'),
                        moment().add(-1, 'month').endOf('month'),
                      ],
                    }}
                    // showTime
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Space style={{ marginTop: 23 }}>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => getPacks()}
                  >
                    Search
                  </Button>
                  <Button onClick={() => form.resetFields()}>Reset</Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Row>
      </SectionWrapper>
      <SectionWrapper style={{ marginTop: 20 }}>
        {packsData && carriers && addressBook && (
          <>
            <TablesFilter
              columns={getColumns()}
              dataSource={packsData}
              setFilteredData={setFilteredData}
            />
            <DataGridPlus
              name="packsGrid"
              showScrollButton
              skip={skipRef.current}
              pageSizes={[10, 20, 50, 100]}
              count={count}
              dataSource={filteredData}
              onChange={handleDataGridChange}
              onSortInfoChange={async (v: any) => {
                if (v) {
                  if (v.dir === 1) {
                    sortString.current = `${v.id} asc`;
                    await handleDataGridChange(limitRef.current, skipRef.current);
                  }
                  if (v.dir === -1) {
                    sortString.current = `${v.id} desc`;
                    await handleDataGridChange(limitRef.current, skipRef.current);
                  }
                } else {
                  sortString.current = 'ShipmentContainerNum asc';
                  await handleDataGridChange(limitRef.current, skipRef.current);
                }
              }}
              loading={loading}
              columns={getColumns()}
              idProperty="id"
              pagination="remote"
              /* eslint-disable react/jsx-props-no-spreading */
              {...({} as any)}
            />
          </>
        )}
      </SectionWrapper>

      {editDialogVisible && (
        <EditDialog
          onPrinterChanged={() => getPrinterList()}
          shipAccounts={shipAccounts}
          originPack={current}
          carriers={carriers || []}
          printer={defaultPrinter}
          addressBook={addressBook || []}
          onHide={(needUpdate) => {
            if (needUpdate) {
              setCurrent(undefined);
              getPacks();
            }
            setEditDialogVisible(false);
          }}
        />
      )}
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
      {printerVisible && (
        <SetDefaultPrinter
          visible
          onHide={() => setPrinterVisible(false)}
          onSaved={() => {
            setPrinterVisible(false);
            getPrinterList();
          }}
        />
      )}
      {shippingLabelVisible && (
        <ShippingLabelDialog
          onHide={(needUpdate) => {
            if (needUpdate) {
              setCurrent(undefined);
              getPacks();
            }
            setShippingLabelVisible(false);
          }}
          onPrinterChanged={() => getPrinterList()}
          printer={defaultPrinter}
          pack={current}
          warehouseNum={Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))}
          carrier={4}
          carrierName={
            (carriers || []).filter((i) => i.carrierNum === 4)[0].name
          }
        />
      )}
    </>
  );
}
