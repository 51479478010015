import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  InputNumber,
  Select,
  DatePicker,
} from 'antd';
import { EditPoRecieveQty } from 'services/purchaseOrder';
import { FormLabel } from 'components/common';
import { message as showMessage } from 'components/common';
import { CloseOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { sortByString } from 'utils';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/config';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  poInfo: StringKAnyVPair;
  warehouseId: string;
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onRefresh, poInfo, warehouseId } = props;
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);

  const [form] = Form.useForm();

  const getLocations = useCallback(async () => {
    setLoading(true);
    const list = await fetchWarehouseLocationList(warehouseId, false);
    setLocations(list.sort((a, b) => sortByString(a, b, 'locationCode')));
    setLoading(false);
  }, [warehouseId]);

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const res = await EditPoRecieveQty(
        poInfo.poReceiveId,
        params.receiveQty,
        params.locationCode,
        // moment(params.receiveDate).format()
        moment(params.receiveDate).format(DEFAULT_DATE_FORMAT)
      );
      if (res) {
        showMessage.success({
          content: 'Saved Successfully！',
        });
        form.resetFields();
        onRefresh();
        onHide();
      } else {
        showMessage.error({ content: 'Save failedlly' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Edit PO Recieve"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              loading={loading}
            >
              <SaveOutlined />
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {poInfo && (
          <Form {...layout} form={form}>
            <Form.Item label={<FormLabel>PO Recieve ID</FormLabel>}>
              {poInfo.poReceiveId}
            </Form.Item>
            <Form.Item label={<FormLabel>Vendor Code</FormLabel>}>
              {poInfo.vendorCode}
            </Form.Item>

            <Form.Item label={<FormLabel>PO Number</FormLabel>}>
              {poInfo.poNumber}
            </Form.Item>

            <Form.Item label={<FormLabel>SKU</FormLabel>}>
              {poInfo.sku}
            </Form.Item>

            <Form.Item label={<FormLabel>Fulfillment PO Line</FormLabel>}>
              {poInfo.fulfillmentPoLineNum}
            </Form.Item>

            <Form.Item
              name="receiveDate"
              initialValue={
                poInfo.receiveDate ? moment(poInfo.receiveDate) : moment()
              }
              label={<FormLabel>Receive Date</FormLabel>}
            >
              <DatePicker allowClear={false} format="MM/DD/YYYY" />
            </Form.Item>

            <Form.Item
              name="receiveQty"
              initialValue={poInfo.receiveQty}
              label={<FormLabel>Receive Qty</FormLabel>}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              name="locationCode"
              initialValue={poInfo.locationCode}
              label={<FormLabel>Location</FormLabel>}
            >
              <Select showSearch>
                {locations.map((item) => (
                  <Select.Option
                    key={item.locationNum}
                    value={item.locationCode}
                  >
                    {item.locationCode}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};
