import styled from 'styled-components';

export const PrintStateWrapper = styled.div`
  font-size: 30px;
  font-style: italic;
  font-weight: 550;
  width: 500px;
`;

export const ShipMultiPackageWrapper = styled.div`
  height: calc(100vh - 200px);
  max-height: 872px;
  min-height: 472px;
  max-width: 1560px;
  min-width: 1080px;
  width: calc(100vw - 232px);

  & .label-ctn {
    border-left: 1px solid #D9D9D9;
    height: 100%;
    padding: 10px;
    width: 300px;
  }

  & .label-ctn.print-idle {
    background-color: #EDEDED;
  }

  & .label-ctn .print-result-info {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    margin: 6px;
  }

  & .label-ctn .print-result-summary,
  & .label-ctn .shipment-prop-item {
    background-color: #ECECEC;
    font-size: 16px;
    font-weight: 550;
    padding: 8px;
  }

  & .label-ctn .prop-item-label {
    font-weight: 550;
  }

  & .label-ctn .prop-item-label::after {
    content: ': ';
  }

  & .label-ctn .shipment-prop-item {
    background-color: inherit;
    border-bottom: 1px dotted #D9D9D9;
    font-weight: 300;
  }

  & .print-result-info .print-result-alert {
    font-size: 16px;
    font-weight: 550;
    padding: 6px 8px;
  }

  & .print-result-summary .print-fail {
    color: #CB2333;
  }

  & .print-result-summary .print-suc {
    color: #50BB1B;
  }

  & .shipment-ctn {
    height: 100%;
    width: calc(100% - 600px);
  }

  & .smp-content-box {
    height: calc(100% - 52px);
  }

  & .smp-footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #DADADA;
    height: 52px;
    padding-left: 6px;
    padding-right: 6px;
  }

  & .smp-tab-checkbox {
    margin-right: 8px;
  }
`;
