import styled from 'styled-components';
import { Col } from 'antd';
import theme from 'assets/styles/theme';

export const BatchPrintWrapper = styled(Col)`
  & .label-count {
    font-size: 28px;
  }

  & .label-error {
    color: ${theme['@error-color']};
  }

  & .label-success {
    color: ${theme['@success-color']};
  }

  & .grey-block1, .grey-block2, .grey-block3 {
    border-radius: ${theme['@border-radius-base']};
    height: 100%;
  }

  & .grey-block1 {
    background-color: #E8E8E8;
    margin-right: 2px;
  }

  & .grey-block2 {
    border: 1px solid #E8E8E8;
  }

  & .grey-block3 {
    background-color: #E8E8E8;
    margin-left: 2px;
  }

  & .store-selector-wrapper {
    margin-left: 6px;
    width: calc(100% - 90px);
  }
`;
