import React, { useCallback, useState } from 'react';
import { Row, Form, Space } from 'antd';
import {
  TextArea,
  Input,
  FormPlus,
  FormSubmitButton,
  FormCancelButton,
  ModalPlus,
  FormLabel,
} from 'components/common';

type ZoneDialogProps = {
  zone?: ZoneRow;
  onClose: () => void;
  onSubmit: (name: string, desc: string) => Promise<boolean>;
};
/**
 *
 * @param {ZoneDialogProps} props
 * @returns {JSX.Element}
 */
export default function ZoneDialog(props: ZoneDialogProps): JSX.Element {
  const [form] = Form.useForm();
  const { onSubmit, onClose, zone } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [edited, setEdited] = useState(zone ? false : true);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    setSaveLoading(true);
    const values = form.getFieldsValue();
    const success = await onSubmit(values['name'], values['description']);
    if (!success) {
      setSaveLoading(false);
    }
  }, [form, onSubmit]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <FormSubmitButton
            loading={saveLoading}
            changed={changed}
            edited={edited}
            onSubmit={handleSubmit}
            onEdit={() => {
              setEdited(true);
            }}
          />
          <FormCancelButton
            snapshot={zone}
            currentform={form}
            onClick={onClose}
          />
        </Space>
      </Row>
    );
  };
  return (
    <ModalPlus
      visible
      onClose={onClose}
      title={zone ? 'Update Zone' : 'Create Zone'}
      form={form}
      snapshot={zone}
      footer={<Footer />}
    >
      <FormPlus
        form={form}
        layout="vertical"
        initialValues={zone}
        snapshot={zone}
        onChanged={(nextChanged: boolean) => {
          setChanged(nextChanged);
        }}
      >
        <Form.Item
          label={<FormLabel>Zone Name</FormLabel>}
          name="name"
          rules={[{ required: true, message: 'Please input zone name!' }]}
        >
          <Input edited={edited} outline />
        </Form.Item>
        <Form.Item
          label={<FormLabel>Zone Description</FormLabel>}
          name="description"
          rules={[
            { required: true, message: 'Please input zone description!' },
          ]}
        >
          <TextArea rows={4} edited={edited} outline />
        </Form.Item>
      </FormPlus>
    </ModalPlus>
  );
}
ZoneDialog.defaultProps = {
  zone: undefined,
};
