import React, { useEffect, useState } from 'react';
import { Button, Menu, Dropdown, Modal } from 'antd';
import {
  ExclamationCircleOutlined,
  // HistoryOutlined,
  // EditOutlined,
  BankOutlined,
  PrinterOutlined,
  // FileAddOutlined,
  AccountBookOutlined,
  MoneyCollectOutlined,
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
} from '@ant-design/icons';
import { message } from 'components/common';
// import { useHistory } from 'react-router-dom';
import { delProductById } from 'services/product';
import { getPermissionValue } from 'utils';
import { PERMISSION_LEVEL } from 'constants/config';
import { userProfiles } from 'utils/auth';

/**
 * The type definition for ActionCell Properties.
 *
 * @param {ProductRow} product
 */
type ActionCellProps = {
  product: ProductRow;
  onShowDetail: () => void;
  onRefresh: () => void;
  onShowPrintLabel: () => void;
  onShowPackageDialog: () => void;
  onShowFlatRateDialog: () => void;
  onShowSignatureOptionDialog: () => void;
  onShowCarrierDeclareValueDialog: () => void;
  onShowCustomsDialog: () => void;
  onShowStatusDialog: () => void;
  onShowLOTDialog: () => void;
  rowIndex: number;
};
// eslint-disable-next-line
export default (props: ActionCellProps) => {
  const {
    product,
    onShowDetail,
    onRefresh,
    onShowPrintLabel,
    onShowPackageDialog,
    onShowFlatRateDialog,
    onShowSignatureOptionDialog,
    onShowCarrierDeclareValueDialog,
    onShowCustomsDialog,
    onShowStatusDialog,
    onShowLOTDialog,
    rowIndex,
  } = props;
  // const histtory = useHistory();

  const [managePermission, setManagePermission] = useState<number>(PERMISSION_LEVEL['NONE']);
  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT');

  const delProduct = async () => {
    const result = await delProductById(product.productId);
    if (result) {
      message.success({ content: 'Delete operation succeeded' });
      onRefresh();
    } else {
      message.error({ content: 'Delete operation failed' });
    }
  };

  useEffect(() => {
    const checkManageProductPermission = async () => {
      const permission = await getPermissionValue(40003);
      setManagePermission(permission);
    }
    checkManageProductPermission();
  }, [])

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_details`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<ExclamationCircleOutlined />}
          onClick={() => onShowDetail()}
        >
          Details
        </Button>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <Button
          type="text"
          icon={<HistoryOutlined />}
          onClick={() => {
            histtory.push({
              pathname: '/reports/history',
              state: { ...product },
            });
          }}
        >
          History
        </Button>
      </Menu.Item> */}
      {/* <Menu.Item key="2">
        <Button type="text" icon={<EditOutlined />} onClick={() => {}}>
          Buffers
        </Button>
      </Menu.Item> */}
      <Menu.Item key="3">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_print`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<PrinterOutlined />}
          onClick={() => onShowPrintLabel()}
        >
          Print
        </Button>
      </Menu.Item>
      <Menu.Item key="4">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_default_packages`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<AccountBookOutlined />}
          onClick={() => onShowPackageDialog()}
          disabled={managePermission < PERMISSION_LEVEL['VIEW']}
        >
          Package / Weight
        </Button>
      </Menu.Item>
      <Menu.Item key="6">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_flat_rate_option`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<MoneyCollectOutlined />}
          onClick={() => onShowFlatRateDialog()}
        >
          Flat Rate Option
        </Button>
      </Menu.Item>
      <Menu.Item key="7">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_signature_option`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<MoneyCollectOutlined />}
          onClick={() => onShowSignatureOptionDialog()}
        >
          Signature Option
        </Button>
      </Menu.Item>
      <Menu.Item key="111">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_carrier_declare_value`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<MoneyCollectOutlined />}
          onClick={() => onShowCarrierDeclareValueDialog()}
        >
          Carrier Declared Value
        </Button>
      </Menu.Item>
      <Menu.Item key="8">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_customs`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<BankOutlined />}
          onClick={() => onShowCustomsDialog()}
        >
          Customs
        </Button>
      </Menu.Item>
      <Menu.Item key="10">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_status`}
          type="text"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<BankOutlined />}
          onClick={() => onShowStatusDialog()}
        >
          Product Status
        </Button>
      </Menu.Item>
      {/* <Menu.Item key="4">
        <Button type="text" icon={<FileAddOutlined />} onClick={() => {}}>
          Create Variations
        </Button>
      </Menu.Item> */}
      {enableLOT === '1' && (
        <Menu.Item key="11">
          <Button type="text" icon={<BankOutlined />} onClick={onShowLOTDialog}>
            LOT
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="5">
        <Button
          id={`catalog_grid_action_row_${rowIndex}_dropdown_delete`}
          type="text"
          className="hover-danger-button-2"
          style={{ width: '100%', textAlign: 'left' }}
          icon={<DeleteOutlined />}
          onClick={() => {
            Modal.confirm({
              title: 'Are you sure you want to delete this SKU?',
              icon: <ExclamationCircleOutlined />,
              content: `SKU : ${product.sku}`,
              okText: 'Yes',
              okType: 'default',
              okButtonProps: { className: "hover-danger-button" },
              cancelText: 'No',
              autoFocusButton: 'cancel',
              onCancel() { },
              onOk() {
                delProduct();
              },
            });
          }}
        >
          Delete
        </Button>
      </Menu.Item>
      {/* <Menu.Item key="6">
        <Button
          type="text"
          icon={<BookOutlined />}
          onClick={() => {
            histtory.push({ pathname: '/catalog/lots', state: { ...product } });
          }}
        >
          Lots
        </Button>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={!!product.bundleType}>
      <Button id={`catalog_grid_action_row_${rowIndex}_dropdown`} icon={<SettingFilled />}>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
