import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import InventoryRemove from 'components/Inventory/Remove';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <InventoryRemove />
      </SiteContent>
    </ContentLayout>
  );
};
