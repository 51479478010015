import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';
import { getProfileSettingValue } from 'utils';

const { Text } = Typography;
export default function Pick(): JSX.Element {
  const { t } = useTranslation();
  const [allMoveMultiple, setAllMoveMultiple] = useState(false)
  const getAllMoveMultiple = useCallback(async ()=>{
    const res = await getProfileSettingValue('AllowMultipleProductMove', '0')
    setAllMoveMultiple(res === '1')
  },[])

  useEffect(() => {
    getAllMoveMultiple()
  },[getAllMoveMultiple])
  return (
    <SmartBlock>
      <SmartSpace>
        <Link to="/smart/move">
          <SmartButton>
            {t('smart.movemerge.moveIndividualProduct')}
          </SmartButton>
        </Link>
        {allMoveMultiple && <Link to="/smart/multiple-move">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            > {t('smart.movemerge.multipleMove.title')}</Text></SmartButton>
        </Link>}
        <Link to="/smart/merge">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.mergeProduct')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/guided-move">
          <SmartButton> {t('smart.movemerge.guidedMove')}</SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.moveWarehouse.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveAllProducts">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.moveAllProducts.title')}
            </Text>
          </SmartButton>
        </Link>
      </SmartSpace>
    </SmartBlock>
  );
}
