import React, { useEffect } from 'react';
import { Select } from 'antd';

type SelectCountryProps = {
  width?: number;
  value?: number;
  onChange?: (countryNum: number) => void;
  disabled?: boolean;
  size?: GeneralSizeType;
};

const countries = [
  { countryNum: 10000, code: 'US', label: 'United States', },
];

export default function SelectCountry(props: SelectCountryProps): JSX.Element {
  const { Option } = Select;
  const { onChange, value, width, disabled, size } = props;
  const defaultValue = 10000;

  useEffect(() => {
    if (!value) {
      if (typeof onChange === 'function') {
        onChange(defaultValue);
      }
    }
  }, [value, onChange, defaultValue]);

  return (
    <Select
      showSearch
      style={{ width: width || '100%' }}
      defaultValue={`${value || defaultValue}`}
      disabled={disabled}
      placeholder="Select Country"
      size={size || 'middle'}
      optionFilterProp="children"
      onChange={(newValue: string) => {
        if (typeof onChange !== 'undefined') {
          onChange(parseInt(newValue) || 0);
        }
      }}
      filterOption={(input, option) => {
        /*
        return (
          typeof option !== 'undefined' &&
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
        */
        if (option) {
          const { value } = option;

          if (typeof value === 'string') {
            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0; 
          }
        }

        return false;
      }}
    >
      {countries.map(country => {
        return (
          <Option key={country.code} value={`${country.countryNum}`}>
            {country.label}
          </Option>
        );
      })}
    </Select>
  );
}
SelectCountry.defaultProps = {
  width: undefined,
  value: undefined,
  onChange: undefined,
  disabled: undefined,
  size: undefined,
};
