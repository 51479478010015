import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import CustomPackages from 'components/ShipmentSettings/CustomPackages';

export default function ShipmentCustomPackagesScreen(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <CustomPackages />
      </SiteContent>
    </ContentLayout>
  );
}
