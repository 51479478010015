import React, { useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Col, Input } from 'antd';
import {
  message,
  UploadImage,
  FormLabel,
  SelectWarehouse,
} from 'components/common';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
  addChannelAccountInsertItem,
  updateChannelAccountInsertItem,
} from 'services/storeSetup';
import Checkbox from 'antd/lib/checkbox/Checkbox';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  store?: StoreChannelInfo;
  insertItem: any;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, store, insertItem } = props;
  //   const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [fileList, setFileList] = useState<any[]>([]);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields || !store) return;
    const postParams = {
      ChannelAccountInsertItemNum: insertItem
        ? insertItem.channelAccountInsertItemNum
        : 0,
      InsertItemName: params.InsertItemName,
      IsRemindUser: params.IsRemindUser ? 1 : 0,
      InsertItemPictureLink: params.InsertItemPictureLink,
      ChannelAccountNum: store.channelAccountNum,
      WarehouseNum: params.WarehouseNum.warehouseNum || params.WarehouseNum.id,
      InsertItemNote: params.InsertItemNote,
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!insertItem) {
        res = await addChannelAccountInsertItem(postParams);
      } else {
        res = await updateChannelAccountInsertItem(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: insertItem ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  //   useEffect(() => {
  //     if (insertItem) {}
  //   }, [insertItem]);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${insertItem ? 'Edit' : 'Add'} Insert Item to ${store?.channelAccountName
        }`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {insertItem ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${insertItem ? 'Saving' : 'Adding'}`
                : `${insertItem ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Row>
            <Col span={16}>
              <Form.Item
                name="WarehouseNum"
                initialValue={
                  insertItem
                    ? insertItem.warehouseNum
                      ? { id: insertItem.warehouseNum }
                      : undefined
                    : undefined
                }
                label={<FormLabel>Warehouse</FormLabel>}
                rules={[
                  { required: true, message: 'Please select warehouse !' },
                ]}
              >
                <SelectWarehouse placeholder="Select warehouse" />
              </Form.Item>
              <Form.Item
                name="InsertItemName"
                initialValue={insertItem?.insertItemName}
                label={<FormLabel>Insert Item Name</FormLabel>}
                rules={[{ required: true, message: 'Please input name !' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="InsertItemNote"
                initialValue={insertItem?.insertItemNote}
                label={<FormLabel>Note</FormLabel>}
              // rules={[{ required: true, message: 'Please input name !' }]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
              <Form.Item
                name="IsRemindUser"
                initialValue={insertItem?.isRemindUser}
                valuePropName="checked"
                label={<FormLabel noColon>{''}</FormLabel>}
              >
                <Checkbox>User Picture to remind user</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                noStyle
                name="InsertItemPictureLink"
                initialValue={insertItem?.insertItemPictureLink}
                label={<FormLabel noColon>{''}</FormLabel>}
              >
                <UploadImage
                  edited
                  style={{ marginTop: 5 }}
                  onChange={(value) =>
                    form.setFieldsValue({ InsertItemPictureLink: value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
