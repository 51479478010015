import {   ClearOutlined,
  SearchOutlined,CloseOutlined } from "@ant-design/icons";
import {
    Row,
    Card,
    Form,
    Col,
    DatePicker,
    Space,
    Button,
    Input,
  } from 'antd';
import { FormLabel, ModalDialog,TablesFilter, CopySpan, ColumnDataType, DataGridPlus } from "components/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchProductLotList } from 'services/lot';
import moment from 'moment';

type SelctLotNumberDialogProps = {
  sku:string;
  onClose: () => void;
  onSelect: (str: string) => void;
  visible: boolean;
}

const StatusEnum: { [key: string]: string } = {
  '1': 'Active',
  '0': 'InActive',
};

const SelctLotNumberDialog = (props: SelctLotNumberDialogProps) => {
  const { onClose, onSelect, visible, sku } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState<boolean>(false);
  const refreshBtnRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [lotList, setLotList] = useState<ProductLot[]>([]);
  const [count, setCount] = useState<number>(0);
  const [tempParams, setTempParams] = useState<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);


  const limitRef = useRef(10);
  const skipRef = useRef(0);

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;


  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const handleSearch = useCallback(async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    const temp = await form.getFieldsValue();
    const params  = {
      ...temp,
      sku,
      status: 1,
      firstInDate: temp.firstInDate ? {from: temp.firstInDate[0], to: temp.firstInDate[1]} : undefined,
      expirationDate: temp.expirationDate ? {from: temp.expirationDate[0], to: temp.expirationDate[1]} : undefined,
      manufactionDate: temp.manufactionDate ? {from: temp.manufactionDate[0], to: temp.manufactionDate[1]} : undefined,
    }
    try {
      setLoading(true);
      const res = await fetchProductLotList(params, {
        pageIndex: 1,
        pageSize: limitRef.current,
      });
      setLoading(false);
      if (res.isSuccess) {
        setLotList(res.data);
        setCount(res.total);
        setTempParams(params);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [skipRef, limitRef, form, sku]);

  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      try {
        setLoading(true);
        const res = await fetchProductLotList(tempParams, {
          pageIndex: skip / limit + 1,
          pageSize: limit,
        });
        setLoading(false);
        if (res.isSuccess) {
          setLotList(res.data);
          setCount(res.total);
          limitRef.current = limit;
          skipRef.current = skip;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [skipRef, limitRef, tempParams]
  );

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'upc',
      header: 'UPC',
      minWidth: 150,
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'lotNumber',
      header: 'LOT#',
      minWidth: 150,
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'status',
      header: 'Status',
      userSelect: true,
      defaultFlex: 1,
      renderEnum: StatusEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={StatusEnum[`${data.status}`]} />;
      },
    },
    {
      name: 'expirationDate',
      header: 'Expiration Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
    },
    {
      name: 'firstInDate',
      header: 'First In Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
    },
    {
      name: 'manufactionDate',
      header: 'Manufaction Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
    },
    {
      name: 'supplierLot',
      header: 'Supplier Lot',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      //@ts-ignore
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (<Button type="primary" onClick={()=>{
          onSelect(data.lotNumber)
        }}>Select</Button>)
      }
    }
  ];



  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  return (
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton={true}
      fullscreen={true}
      maskClosable={false}
      onClose={onClose}
      onFullscreen={onFullscreen}
      title={`LOT# List - ${sku}`}
      visible={visible}
      width={detailDialogWidth()}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={onClose} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
            <Row justify="center">
        <Card style={{ width: '100%', minWidth: 400 }}>
          <Form form={form}>
            <Row gutter={16}>
              {/* <Col span={8}>
                <FormLabel>SKU</FormLabel>
                <Form.Item name="sku">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Status</FormLabel>
                <Form.Item name="status">
                  <Select defaultValue={1}>
                    <Select.Option value={null}>All</Select.Option>
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={0}>Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <FormLabel>LOT#</FormLabel>
                <Form.Item name="lot">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>First In Date</FormLabel>
                <Form.Item
                  name="firstInDate"
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Expiration Date</FormLabel>
                <Form.Item
                  name="expirationDate"
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Manufaction Date</FormLabel>
                <Form.Item
                  name="manufactionDate"
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Supplier Lot</FormLabel>
                <Form.Item
                  name="supplierLot"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="center">
            <Space>
              <Button type="primary" onClick={() => handleSearch()}>
                <SearchOutlined />
                Search
              </Button>
              <Button onClick={() => form.resetFields()}>
                <ClearOutlined />
                Reset
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
      <Card style={{ marginTop: 20 }}>
        <TablesFilter
          columns={columns}
          dataSource={lotList}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="productLotNum"
          showScrollButton
          loading={loading}
          columns={columns}
          autoWith={false}
          pageSizes={[10, 20, 50, 100]}
          dataSource={filteredData}
          pagination
          style={{ height: 500 }}
          count={count}
          onChange={(limit, skip) => handleDataGridChange(limit, skip)}
          {...({} as any)}
        />
      </Card>
    </ModalDialog>
  )
}

export default SelctLotNumberDialog;