import dayjs from 'dayjs';
import MockAdapter from 'axios-mock-adapter';
//import { string2Date } from 'utils';
import ordersService from 'mocks/orders.json';
import { HTTP_STATUS_OK } from 'constants/config';
import { digitBridgeApi } from './http';

if (process.env.NODE_ENV === 'test') {
  injectMockTest();
}

export interface IOrders {
  channelOrderID: string;
  channelName: string;
  endBuyerName: string;
  sku: string;
  originalOrderDateUtc: string;
  shippingStatusName: string;
  type: string;
}

interface IOrderParams {
  masterAccountNum: number;
  profileNum: number;
  channelName?: string;
  orderDateFrom?: string;
  orderDateTo?: string;
  channelOrderID?: string;
  buyerEmail?: string;
  billingZipCode?: string;
  shippingFirstName?: string;
  shippingLastName?: string;
  shippingAddressLine1?: string;
  shippingAddressLine2?: string;
  orderStatusName?: string;
  trackingNumber?: string;
  channelItemID?: number;
  sku?: string;
  $top?: number;
  $skip?: string;
  $count?: boolean;
}

export const fetchOrders = async (params: IOrderParams): Promise<any> => {
  const response = await digitBridgeApi.get('/v1/orders', {
    params,
  });

  return response;
};

function injectMockTest() {
  const mock = new MockAdapter(digitBridgeApi, { delayResponse: 300 });
  const responseOrders = ordersService.response.orders;
  mock.onGet('/orders').reply(HTTP_STATUS_OK, responseOrders);
}
/**
 * Fetch orders by ids
 * @param {Array<string>} ids
 * @returns {Array<Array<SaleRow> | Array<string>>}
 */
export const fetchOrderByIds = async (
  warehouseNum: number,
  ids: Array<string>,
): Promise<Array<Array<SaleRow> | Array<string>>> => {
  const params = { ids: ids.join(','), warehouseNum };
  const {
    data: { orders, notFindIds },
  } = (await digitBridgeApi.get('/api/getOrderByIds', {
    params,
  })) as any;
  const rows = orders.map((item: any, index: number) => {
    return {
      id: index,
      ...item,
    };
  });
  return [rows, notFindIds];
};
/**
 * Fetch full orders
 * @param {number} top
 * @param {number} skip
 * @param {Array<string>} filters
 * @returns {OrderEntity}
 */
export const fetchFullOrders = async (
  top: number,
  skip: number,
  sorts: string,
  filters: { [key: string]: any },
): Promise<OrderEntity> => {
  const params = {
    $top: top,
    $skip: skip,
    // $filter: filters.join(' and '),
    // $sortBy: 'fulfillmentOrderNum,CreateDate',
    $sortBy: sorts,
    $count: true,
    $calculateTotal: true,
    ...filters
  };
  try {
    const result = (await digitBridgeApi.get('/api/orders', {
      params,
    })) as any;
    result.data = result.data.map((item: any) => {
      item.createDate = dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:SS').toDate();
      return item;
    });
    return result;
  } catch (e) {
    return {
      count: 0,
      data: [],
      totalPrice: 0,
      totalQuantity: 0,
      totalUniqueSkus: 0,
    };
  }
};

export const getInventoriesBySkus = async (warehouseId: string,
  sorts: string,
  skus?: string,
): Promise<any> => {
  const params = {
    // $top: top,
    // $skip: skip,
    $sortBy: sorts,
    $count: true,
    skus
  };
  return await digitBridgeApi.get(`/api/products/inventories/${warehouseId}/bySKUs`, { params })
}

/**
 * search order from history
 * @param tags 
 * @param orders 
 * @returns 
 */
export const fetchFullOrdersFromHistory = async (
  top: number,
  skip: number,
  sorts: string,
  filters: { [key: string]: any },
): Promise<OrderEntity> => {
  const params = {
    $top: top,
    $skip: skip,
    // $filter: filters.join(' and '),
    // $sortBy: 'fulfillmentOrderNum,CreateDate',
    $sortBy: sorts,
    $count: true,
    $calculateTotal: true,
    ...filters
  };
  try {
    const result = (await digitBridgeApi.get('/api/orders/histories', {
      params,
    })) as any;
    result.data = result.data.map((item: any) => {
      item.createDate = dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:SS').toDate();
      return item;
    });
    return result;
  } catch (e) {
    return {
      count: 0,
      data: [],
      totalPrice: 0,
      totalQuantity: 0,
      totalUniqueSkus: 0,
    };
  }
};

/**
 * Fetch full orders with Item
 * @param {number} top
 * @param {number} skip
 * @param {Array<string>} filters
 * @returns {OrderEntity}
 */
export const fetchFullOrdersWithItem = async (
  top: number,
  skip: number,
  sorts: string,
  filters: { [key: string]: any },
): Promise<OrderEntity> => {
  const params = {
    $top: top,
    $skip: skip,
    // $filter: filters.join(' and '),
    // $sortBy: 'fulfillmentOrderNum,CreateDate',
    $sortBy: sorts,
    $count: true,
    $calculateTotal: true,
    ...filters
  };
  try {
    const result = (await digitBridgeApi.get('/api/orders/items', {
      params,
    })) as any;
    result.data = result.data.map((item: any) => {
      item.createDate = dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:SS').toDate();
      return item;
    });
    return result;
  } catch (e) {
    return {
      count: 0,
      data: [],
      totalPrice: 0,
      totalQuantity: 0,
      totalUniqueSkus: 0,
    };
  }
};

/**
 * set Tage to orders
 * @param {Array<TagRow>} tags
 * @param {Array<OrderRow>} orders
 * @returns {boolean}
 */
export const setTagToOrders = async (
  tags: Array<TagRow>,
  orders: Array<OrderRow>
): Promise<boolean> => {
  const orderNums: Array<number> = Array.from(new Set(orders.map((item) => {
    return item.fulfillmentOrderNum;
  })));
  const promiseChain: Array<Promise<any>> = [];
  tags.forEach((tag) => {
    promiseChain.push(
      digitBridgeApi.post('/api/SetTagToOrders', {
        TagNum: tag.tagNum,
        Nums: orderNums,
      })
    );
  });
  await Promise.all(promiseChain);
  return true;
};
/**
 * update orders status
 * @param {Array<OrderRow>} orders
 * @param {number} status
 * @returns {boolean}
 */
export const updateOrdresStatus = async (
  orders: Array<OrderRow>,
  status: number
): Promise<boolean> => {
  const promiseChain: Array<Promise<any>> = [];
  const orderIds: Array<string> = Array.from(new Set(orders.map((item) => {
    return item.fulfillmentOrderId;
  })));
  orderIds.forEach((id) => {
    promiseChain.push(
      digitBridgeApi.put(
        `/api/orders/${id}/status/${status}`
      )
    );
  });
  await Promise.all(promiseChain);
  return true;
};
/**
 * Fetch order
 * @param {number} orderId
 * @returns {Promise<Order>}
 */
export const fetchOrder = async (orderId: string): Promise<Order> => {
  const result = (await digitBridgeApi.get(`/api/orders/${orderId}`)) as any;
  /*
  return {
    ...result,
    updateDate: string2Date(result.updateDate),
  } as any;*/

  if (result && 'object' === typeof result) {
    const { code, data } = result as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data as Order;
    }
  }

  return {} as Order;
};

export const fetchOrderFromHistory = async (orderId: string): Promise<Order> => {
  const result = (await digitBridgeApi.get(`/api/orders/${orderId}/histories`)) as any;
  /*
  return {
    ...result,
    updateDate: string2Date(result.updateDate),
  } as any;*/

  if (result && 'object' === typeof result) {
    const { code, data } = result as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      return data as Order;
    }
  }

  return {} as Order;
};

type UpdateOrderAddressParams = {
  ShipToName?: string,
  ShipToFirstName?: string,
  ShipToLastName?: string,
  // ShipToSuffix: string,
  ShipToCompany?: string,
  ShipToCompanyJobTitle?: string,
  ShipToAttention?: string,
  ShipToDaytimePhone?: string,
  ShipToNightPhone?: string,
  ShipToAddressLine1?: string,
  ShipToAddressLine2?: string,
  ShipToAddressLine3?: string,
  ShipToCity?: string,
  ShipToState?: string,
  ShipToStateFullName?: string,
  ShipToPostalCode?: string,
  ShipToPostalCodeExt?: string,
  // ShipToCounty: string,
  ShipToCountry?: string,
  ShipToEmail?: string,
  MappedShippingCarrier?: string,
  MappedShippingService?: string
}
/**
 * 
 * @param orderId 
 * @param params 
 * @returns any
 */
export const updateOrdreraddress = async (
  orderId: string,
  params: UpdateOrderAddressParams
): Promise<any> => {
  const json = JSON.stringify(params)
  return digitBridgeApi.put(`/api/orders/${orderId}/addresses`, json)
};

export const updateOrdrercarrier = async (
  orderId: string,
  params: UpdateOrderAddressParams
): Promise<any> => {
  const json = JSON.stringify(params)
  return digitBridgeApi.patch(`/api/orders/${orderId}`, json)
};

type editOrderMapserviceParams = {
  ShipAccountNum?: number,
  ShipCarrierNum?: number,
  ShipCarrier?: string,
  ShipServiceNum?: number,
  ShipServiceCode?: string,
  ShipServiceName?: string
}
export const editOrderMapservice = async (orderNum: number, params: editOrderMapserviceParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/orders/${orderNum}/mapservice`, json)
}

type CancelOrderParams = {
  FulfillmentOrderId: string,
  CentralChannelAccountNum: number,
  ChannelOrderId: string,
  CancelReason: string
}
export const cancelOrder = async (params: CancelOrderParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/orders/cancel', json)
}

export const retryAdvanceProcess = async (params: number[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/orders/retryAdvanceProcess', json)
}

type HoldOrderParams = {
  ExpirationDate?: string,
  HoldReason: string,
}
export const holdOrder = async (FulfillmentOrderId: string, params: HoldOrderParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/orders/${FulfillmentOrderId}/hold`, json)
}

type UnHoldOrderParams = {
  UnHoldReason: string,
}
export const unHoldOrder = async (FulfillmentOrderId: string, params: UnHoldOrderParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/orders/${FulfillmentOrderId}/unhold`, json)
}

export const getCancelReasons = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/systemsettings/OrderCancel')
}

/**
 * get error order QTY
 */
export const getErrorOrderQty = async (warehouseNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/orders/error`)
}

/**
 * un-queque orders
 */
export const postUnQueue = async (SelectedPickListOrderNums: number[]): Promise<any> => {
  const json = JSON.stringify({
    SelectedPickListOrderNums,
    warehouseCode:'',
    warehouseNum:0
  })
  return await digitBridgeApi.post('/api/PickList/UnQueueOrders', json)
}

export const fetchAllowRetransmit = async (orderId: string, masterAccountNum: number, profileNum: number) => {
  return await digitBridgeApi.post(`/api/orders/${orderId}/allowRetransmit`);
}

export const batchHoldOrders = async (nums: number[], reason: string): Promise<any> => {
  const json = JSON.stringify({
    fulfillmentOrderNums: nums,
    ExpirationDate: null,
    HoldReason: reason
  })
  return await digitBridgeApi.post('/api/orders/hold', json);
}

export const batchUnholdOrders = async (nums: number[], reason: string): Promise<any> => {
  const json = JSON.stringify({
    FulfillmentOrderNums: nums,
    UnHoldReason: reason,
  })
  return await digitBridgeApi.post('/api/orders/unhold', json);
}

type AbandonOrderParams = {
  FulfillmentOrderId: string,
  Reason: string
}
export const abandonOrder = async (params: AbandonOrderParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/orders/abandon', json)
}

export const  retryServiceMapping = async (ids: string[]):Promise<any> => {
  const json = JSON.stringify([...ids])
  return await digitBridgeApi.post('/api/orders/MappingService', json);
}

export const  getPickOrderDetail = async (orderNum: number):Promise<any> => {
  return await digitBridgeApi.get(`/api/picking/${orderNum}/detail`);
}

type UnPickOrderParams = {
  FulfillmentOrderNum: number;
  PickListNum: number,
  // ShipmentNum: number,
  SKU: string,
  FulfillmentProductNum: number,
  WarehouseNum: number,
  LocationNum: number,
  UnpickQty: number
}

export const unPickOrder = async (orderNum: number, params: UnPickOrderParams[] ):Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/picking/${orderNum}/unpick`, json);
}

export const markUnPickOrder = async (orderNum: number ):Promise<any> => {
  return await digitBridgeApi.post(`/api/picking/${orderNum}/markunpick`);
}

export const markPickOrder = async (orderNum: number ):Promise<any> => {
  return await digitBridgeApi.post(`/api/picking/${orderNum}/markpick`);
}

type pickingByOrderParams2 = {
  FulfillmentOrderNum: number,
  LocationCode: string,
  FulfillmentOrderLineNum: number,
  Title: string,
  SkuOrUpc: string,
  PickQty: number,
  PickedQty: number,
  FulfillmentProductNum: number,
  LotNumber:string,
  UOM: string,
  UomRate: number,
}

export const pickingOrder = async (fulfillmentOrderNum: number,params: pickingByOrderParams2[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/Picking', json, {params:{fulfillmentOrderNum}})
}


export const syncOrderFromERP = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/orders/sync', { timeout: 300000 })
}

