import React, { useCallback, useEffect, useState } from 'react';
import { Modal, InputNumber, Typography, Row, Space, Form, Select } from 'antd';
import { message, FormLabel } from 'components/common';
import {
  SmartButton,
  SmartRow,
  SmartScanner,
  SmartFormLabel,
} from '../../SmartComponent';
import { moveProductLocationToLocation2 } from 'services/inventory';
import { useTranslation } from 'react-i18next';
// import { UnlockFilled, LockFilled } from '@ant-design/icons';

const { Text } = Typography;

type Item = {
  id: string;
  sku: string;
  upc?: string;
  lotNumber?: string;
  uom: string;
  rate: number;
  qty: number;
};

type OrderFinishDialogProps = {
  warehouseId: string;
  warehouseNum: number;
  sLocation: WarehouseLocationRow;
  tLocation: WarehouseLocationRow;
  productList: any[];
  productInfo: any;
  onSubmit: (value: Item) => void;
  onCloseModal: () => void;
  lotEnable: boolean;
  uomEnable: boolean;
  // lotNumberList: any[];
  // uomList: any[];
  // locationUomList: any[];
  // locationLotNumberList: any[];
};

export default function OrderFinishDialog(
  props: OrderFinishDialogProps
): JSX.Element {
  const {
    uomEnable,
    lotEnable,
    sLocation,
    tLocation,
    productList,
    warehouseId,
    warehouseNum,
    onSubmit,
    onCloseModal,
    productInfo,
  } = props;
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [str, setStr] = useState<string>('');
  // const [qtyLock, setQtyLock] = useState(false);
  // const [uomLock, setUomLock] = useState(false);
  // const [lotNumberLock, setLotNumberLock] = useState(false);
  const [selectedLotUom, setSelectedLotUom] = useState<any>();
  const [qty, setQty] = useState<number>(1);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (productList && !inited) {
      if (productList.length === 1 && (uomEnable || lotEnable)) {
        setSelectedLotUom(productList[0]);
      }
      setInited(true);
    }
  }, [productList, inited, uomEnable, lotEnable]);

  /**
   * handle move event
   */
  const moveProduct = useCallback(async () => {
    try {
      if ((uomEnable || lotEnable) && !selectedLotUom) {
        message.info('LOT# or UOM required');
        return;
      }
      setConfirmLoading(true);
      const res = await moveProductLocationToLocation2({
        fromWarehouse: warehouseId,
        fromLocation: sLocation?.locationNum,
        fulfillmentProductNum: selectedLotUom.fulfillmentProductNum,
        quantity: qty,
        toWarehouse: warehouseNum,
        toLocation: tLocation?.locationNum,
        lotNumber: selectedLotUom ? selectedLotUom.lotNumber : undefined,
        uom: selectedLotUom ? selectedLotUom.uom : undefined,
        uomRate: selectedLotUom ? selectedLotUom.uomRate : undefined,
      });
      setConfirmLoading(false);
      if (res) {
        message.success({ content: 'Moved successfully' });
        onSubmit({
          id: `${selectedLotUom.fulfillmentProductNum}_${selectedLotUom.sku}_${selectedLotUom.lotNumber}_${selectedLotUom.uom}`,
          sku: productInfo.sku,
          upc: productInfo.upc,
          lotNumber: selectedLotUom.lotNumber,
          uom: selectedLotUom.uom,
          rate: selectedLotUom ? selectedLotUom.uomRate : undefined,
          qty: qty,
        });
      }
    } catch (error) {
      setConfirmLoading(false);
    }
  }, [
    sLocation,
    tLocation,
    warehouseId,
    qty,
    productInfo,
    selectedLotUom,
    uomEnable,
    lotEnable,
    onSubmit,
    warehouseNum,
  ]);
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        {/* <ButtonIcon className="bi-emoji-smile" /> */}
        <div style={{ marginLeft: 5 }}>{'Move Item'}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        <SmartFormLabel>{t('smart.movemerge.from')}</SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {sLocation?.locationCode}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>{t('smart.movemerge.to')}</SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {tLocation?.locationCode}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>{t('common.title')}</SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {productInfo?.productTitle}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>{t('common.sku')}</SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {productInfo?.sku}
        </Text>
      </SmartRow>
      <SmartRow>
        <SmartFormLabel>{t('common.upc')}</SmartFormLabel>
        <Text strong style={{ marginLeft: 4 }}>
          {productInfo?.upc}
        </Text>
      </SmartRow>

      <SmartRow justify="space-between" style={{ alignItems: 'start' }}>
        {uomEnable && lotEnable && (
          <Form.Item
            label={
              <Text
                style={{
                  fontSize: 14,
                  color: 'rgb(86, 149, 34)',
                  fontWeight: 600,
                }}
              >
                {`${t('common.lotNumber')} [${t('common.uom')}]:`}
              </Text>
            }
          >
            <Select
              defaultValue={
                productList.length === 1
                  ? `${productList[0].lotNumber}_##___##_${productList[0].uom}`
                  : undefined
              }
              style={{ marginLeft: 8, width: '100%', minWidth: 150 }}
              onChange={(value) => {
                const temp = productList.filter(
                  (i) =>
                    i.locationCode === sLocation?.locationCode &&
                    value === `${i.lotNumber}_##___##_${i.uom}`
                );
                if (temp.length > 0) {
                  setSelectedLotUom(temp[0]);
                } else {
                  setSelectedLotUom(undefined);
                }
              }}
            >
              {productList.map((item) => (
                <Select.Option
                  key={`${item.lotNumber}_##___##_${item.uom}`}
                  value={`${item.lotNumber}_##___##_${item.uom}`}
                >{`${item.lotNumber} [${item.uom}]`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {!uomEnable && lotEnable && (
          <Form.Item
            label={
              <Text
                style={{
                  fontSize: 14,
                  color: 'rgb(86, 149, 34)',
                  fontWeight: 600,
                }}
              >
                {`${t('common.lotNumber')}:`}
              </Text>
            }
          >
            <Select
              defaultValue={
                productList.length === 1 ? productList[0].lotNumber : undefined
              }
              style={{ marginLeft: 8, width: '100%', minWidth: 150 }}
              onChange={(value) => {
                const temp = productList.filter(
                  (i) =>
                    i.locationCode === sLocation?.locationCode &&
                    value === i.lotNumber
                );
                if (temp.length > 0) {
                  setSelectedLotUom(temp[0]);
                } else {
                  setSelectedLotUom(undefined);
                }
              }}
            >
              {productList.map((item) => (
                <Select.Option key={item.lotNumber} value={item.lotNumber}>
                  {item.lotNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {uomEnable && !lotEnable && (
          <Form.Item
            label={
              <Text
                style={{
                  fontSize: 14,
                  color: 'rgb(86, 149, 34)',
                  fontWeight: 600,
                }}
              >
                {`${t('common.uom')}`}
              </Text>
            }
          >
            <Select
              defaultValue={
                productList.length === 1 ? productList[0].uom : undefined
              }
              style={{ marginLeft: 8, width: '100%', minWidth: 150 }}
              onChange={(value) => {
                const temp = productList.filter(
                  (i) =>
                    i.locationCode === sLocation?.locationCode &&
                    value === i.uom
                );
                if (temp.length > 0) {
                  setSelectedLotUom(temp[0]);
                } else {
                  setSelectedLotUom(undefined);
                }
              }}
            >
              {productList.map((item) => (
                <Select.Option key={item.uom} value={item.uom}>
                  {item.uom}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </SmartRow>

      {selectedLotUom && (
        <Space size="large" style={{ marginBottom: 8 }}>
          {lotEnable && (
            <Typography.Text type="secondary">{`${t('common.lotNumber')}: ${
              selectedLotUom.lotNumber
            }`}</Typography.Text>
          )}
          {uomEnable && (
            <Typography.Text type="secondary">{`${t('common.uom')}: ${
              selectedLotUom.uom
            }`}</Typography.Text>
          )}
          {uomEnable && (
            <Typography.Text type="secondary">{`${t('common.rate')}: ${
              selectedLotUom.uomRate
            }`}</Typography.Text>
          )}
        </Space>
      )}

      <Row justify="start" style={{ marginBottom: 8 }}>
        <FormLabel>{t('common.qty')}</FormLabel>
        <Space>
          <Form.Item noStyle>
            <InputNumber
              min={1}
              value={qty}
              style={{ width: 100, marginLeft: 4 }}
              onChange={(e) => setQty(e)}
            />
          </Form.Item>
          {/* {qtyLock ? (
                <LockFilled
                  onClick={() => setQtyLock((prev) => !prev)}
                  style={{ color: 'red', fontSize: 24 }}
                />
              ) : (
                <UnlockFilled
                  onClick={() => setQtyLock((prev) => !prev)}
                  style={{ fontSize: 24 }}
                />
              )} */}
          {selectedLotUom && uomEnable && (
            <Text>
              <FormLabel>{t('common.baseQty')}</FormLabel>
              {qty * selectedLotUom.uomRate}
            </Text>
          )}
        </Space>
      </Row>

      <SmartRow>
        <Text type="secondary">
          {t('smart.movemerge.scanDestinationLocationConfirm')}
        </Text>
      </SmartRow>
      <SmartRow style={{ marginTop: 8 }}>
        <SmartScanner
          id="reScanLocationInput"
          isAutoFocus={1}
          onTextChange={(value) => setStr(value)}
          onChangeValue={(value) => {
            if (value) {
              if (value === tLocation?.locationCode) {
                moveProduct();
              } else {
                message.warning(
                  t('smart.movemerge.scanDestinationLocationError') || ''
                );
              }
            }
          }}
        />
      </SmartRow>

      {!showButton && (
        <SmartRow style={{ marginTop: 8 }} justify="center">
          <SmartButton
            style={{ width: 200 }}
            onClick={() => setShowButton(true)}
          >
            ...
          </SmartButton>
        </SmartRow>
      )}

      {showButton && (
        <>
          <SmartRow style={{ marginTop: 8 }} justify="center">
            <SmartButton
              style={{ width: 200, marginBottom: 8 }}
              disabled={str !== tLocation?.locationCode}
              loading={confirmLoading}
              onClick={() => moveProduct()}
            >
              {t('common.confirm')}
            </SmartButton>
          </SmartRow>
          <SmartRow justify="center">
            <SmartButton
              style={{ width: 200 }}
              type="default"
              onClick={() => onCloseModal()}
            >
              {t('smart.common.goBack')}
            </SmartButton>
          </SmartRow>
        </>
      )}
    </Modal>
  );
}
