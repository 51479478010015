import React from 'react';
import { Checkbox, CheckboxProps } from 'antd';

interface CheckboxPlusProps extends CheckboxProps {
  /**
   * can edited
   */
  edited: boolean;
  preventCallback?: (value: boolean) => void;
}
/**
 * Extend Checkbox component
 * Feature:Support readonly status
 */
export default function CheckboxPlus(props: CheckboxPlusProps): JSX.Element {
  const { edited, children, value, onChange, preventCallback, ...other } =
    props as any;
  if (edited) {
    return (
      <Checkbox
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        checked={value}
        onChange={(e) => {
          const nextValue = e.target.checked;
          if (typeof preventCallback === 'function') {
            if (preventCallback(nextValue)) {
              e.preventDefault();
              return;
            }
          }

          if (typeof onChange === 'function') {
            onChange(nextValue);
          }
        }}
      >
        {children}
      </Checkbox>
    );
  } else {
    return (
      <Checkbox
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        checked={value}
        disabled
      >
        {children}
      </Checkbox>
    );
  }
}
