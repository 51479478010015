/**
 * country service requests are here.
 */
import { digitBridgeApi } from './http';

export const getPrinters = async (): Promise<any> => {
    return await digitBridgeApi.post('/api/Printers');
};

export const delPrintAccount = async (id: string): Promise<any> => {
    return await digitBridgeApi.delete(`/api/PrintAccount/${id}`);
};

export const enablePrinter = async (printAcccountid: string, printerId: string): Promise<any> => {
    return await digitBridgeApi.post(`/api/PrintAccount/${printAcccountid}/printer/${printerId}/enable`);
};

export const disablePrinter = async (printAcccountid: string, printerId: string): Promise<any> => {
    return await digitBridgeApi.post(`/api/PrintAccount/${printAcccountid}/printer/${printerId}/disable`);
};
