import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Typography,
  Modal,
  Button,
  Space,
  Form,
  Input,
  Col,
  Tabs,
  Checkbox,
} from 'antd';
import { DataGridPlus, FormLabel } from 'components/common';
import { fetchInventory } from 'services/product';
import LocationInfoModal from './LocationInfoModal';
import { CloseOutlined } from '@ant-design/icons';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseIdFromCache } from 'utils';

const { Text } = Typography;
const { TabPane } = Tabs;
type SelectLocationDialogProps = {
  visible: boolean;
  sku?: string;
  warehouseNum: number;
  warehouseId: string;
  onHide: () => void;
  onSelect: (record: WarehouseLocationRow, inv: any[]) => void;
};
/**
 * Return SelectLocationDialog Component
 * @param {SelectLocationDialogProps} props
 * @returns {JSX.Element}
 */
export default function SelectLocationDialog(
  props: SelectLocationDialogProps
): JSX.Element {
  const { onHide, onSelect, visible, sku, warehouseNum, warehouseId } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [dataSource, setDataSource] = useState<Array<any>>([]);

  const [showLocationInfo, setShowLocationInfo] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [filterValue, setFilterValue] = useState<string>('');
  const [hideZero, setHideZero] = useState<boolean>(true);

  const columns = [
    {
      header: 'Locations',
      name: 'locationCode',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Current SKU Quantity',
      name: 'quantity',
      userSelect: true,
      textAlign: 'end',
      headerAlign: 'end',
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      textAlign: 'center',
      headerAlign: 'center',
      minWidth: 100,
      maxWidth: 100,
      render: (value: any) => {
        const { data: row } = value;
        return (
          <Button
            type="default"
            style={{ width: '100%' }}
            onClick={() => {
              onSelect(
                {
                  id: row.locationNum,
                  locationNum: row.locationNum,
                  code: row.locationCode,
                  parent: 0,
                  parentCode: '',
                  quantity: 0,
                  creator: '',
                  reserved: false,
                  backstock: false,
                  warehouseNum: row.warehouseNum,
                  warehouseId: row.warehouseId,
                  locationId: row.locationId,
                },
                [row]
              );
            }}
          >
            Select
          </Button>
        );
      },
    },
  ];

  const allLocationColumns = [
    {
      header: 'Zone',
      name: 'zoneName',
      userSelect: true,
      defaultFlex: 1,
      // minWidth: 200,
      // maxWidth: 200,
    },
    {
      header: 'Locations',
      name: 'locationCode',
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              setCurrentLocation(data);
              setShowLocationInfo(true);
            }}
          >
            {value.data.locationCode}
          </Button>
        );
      },
    },
    // {
    //   header: 'Qty',
    //   name: 'quantity',
    //   userSelect: true,
    //   defaultFlex: 1,
    //   minWidth: 100,
    //   maxWidth: 100,
    // },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      textAlign: 'center',
      headerAlign: 'center',
      minWidth: 100,
      maxWidth: 100,
      render: (value: any) => {
        const { data: row } = value;
        return (
          <Button
            type="default"
            style={{ width: '100%' }}
            onClick={() => {
              onSelect(
                {
                  id: row.locationNum,
                  locationNum: row.locationNum,
                  code: row.locationCode,
                  parent: 0,
                  parentCode: '',
                  quantity: 0,
                  creator: '',
                  reserved: false,
                  backstock: false,
                  warehouseNum: row.warehouseNum,
                  warehouseId: row.warehouseId,
                  locationId: row.locationId,
                },
                [row]
              );
            }}
          >
            Select
          </Button>
        );
      },
    },
  ];

  const getInventory = useCallback(
    async (getAll: boolean = false) => {
      setFilterValue('');
      const filter = `sku eq '${sku}'`;
      const params = getAll
        ? { $top: 1000, virtual: false }
        : { $filter: filter, $top: 1000, virtual: false };
      try {
        setLoading(true);
        const res = await fetchInventory(
          { id: warehouseNum, warehouseId: warehouseId },
          params
        );
        setLoading(false);
        setData(res.data);
        setDataSource(res.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    },
    [sku, warehouseNum, warehouseId]
  );

  const getLocations = useCallback(async () => {
    setFilterValue('');
    try {
      setLoading(true);
      const list = await fetchWarehouseLocationList(getWarehouseIdFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))), false);
      setData(list);
      setDataSource(list);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [])

  const handleCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      setFilterValue(value);
      if (!value) {
        setData(dataSource);
      }
      const newData = dataSource.filter((item) => {
        const { locationCode } = item;
        if (
          locationCode.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >=
          0
        ) {
          return true;
        }
        return false;
      });
      setData(newData);
    },
    [dataSource]
  );

  useEffect(() => {
    if (sku && warehouseNum) {
      getInventory();
    }
  }, [sku, warehouseNum, getInventory]);

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Search Location"
      centered
      visible={visible}
      width={800}
      destroyOnClose
      onCancel={onHide}
      bodyStyle={{ paddingBottom: 0 }}
      footer={<Footer />}
    >
      <Form>
        <Form.Item>
          <Row align="middle">
            <Col span={3}>
              <Row justify="end">
                <FormLabel>SKU</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={20}>
              <Text strong>{sku}</Text>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row align="middle">
            <Col span={3}>
              <Row justify="end">
                <FormLabel>Location</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={20}>
              <Input placeholder="location code" value={filterValue} onChange={handleCodeChange} />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Tabs
            defaultActiveKey="1"
            type="card"
            onChange={(e) => {
              switch (e) {
                case '1':
                  getInventory(false);
                  break;
                case '2':
                  // getInventory(true);
                  getLocations();
                  break;
                default:
                  break;
              }
            }}
          >
            <TabPane tab="In Stock" key="1" forceRender>
              <Space size={20}>
                <Text>{`Total Location(s):${data.length}`}</Text>
                <Checkbox
                  checked={hideZero}
                  onChange={e => {
                    setHideZero(e.target.checked);
                  }}
                >Hide Zero Quantity Item</Checkbox>
              </Space>
              <DataGridPlus
                style={{ height: 490 }}
                idProperty="code"
                dataSource={hideZero ? data.filter(item => item.quantity > 0) : data}
                loading={loading}
                columns={columns}
                pagination="local"
                defaultSortInfo={{ name: 'quantity', dir: -1 }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...({} as any)}
              />
            </TabPane>
            <TabPane tab="All location" key="2" forceRender>
              <Space size={20}>
                <Text>{`Total Location(s):${data.length}`}</Text>
                {/* <Checkbox
                  checked={hideZero}
                  onChange={e => {
                    setHideZero(e.target.checked);
                  }}
                >Hide Zero Quantity Item</Checkbox> */}
              </Space>
              <DataGridPlus
                style={{ height: 490 }}
                idProperty="code"
                // dataSource={hideZero ? data.filter(item => item.quantity > 0) : data}
                dataSource={data}
                loading={loading}
                columns={allLocationColumns}
                pagination="local"
                defaultSortInfo={{ name: 'zoneName', dir: -1 }}
                /* eslint-disable react/jsx-props-no-spreading */
                {...({} as any)}
              />
            </TabPane>
          </Tabs>
        </Form.Item>
      </Form>
      {showLocationInfo && (
        <LocationInfoModal
          visible={showLocationInfo}
          onHide={() => setShowLocationInfo(false)}
          current={currentLocation}
          warehouseId={warehouseId}
        />
      )}
    </Modal>
  );
}
