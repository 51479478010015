import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Modal, Typography, Button } from 'antd';
import { playAudio } from 'utils';
import { ButtonIcon, message, SetDefaultWarehouseDialog } from 'components/common';
// import FinishDialog from './finish';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
  SmartCard,
  SmartButton,
} from '../SmartComponent';
import { useTranslation } from 'react-i18next';
import { PackItem, getPackByItemNum, pickToPack } from 'services/prepack';
import { fetchWarehouseProductList } from 'services/inventory';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

export default function PickForPack(): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanItemNum' | 'scanLocation'>('scanItemNum');
  const [packItemNum, setPackItemNum] = useState<string>('');
  const [packInfo, setPackInfo] = useState<any>();
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [inventoryList, setInventoryList] = useState<any>();
  const [currentLocation, setCurrentLocation] = useState<any>();
  const [currentSKU, setCurrentSKU] = useState<string>('');
  const [currentQty, setCurrentQty] = useState<number>(0);
  const tempPackItemNum = useRef<string>('')
  // const [qtyFocusNum, setQtyFocusNum]= useState<number>(0)
  const [skuFocusNum, setSkuFocusNum] = useState<number>(0);
  const [color, setColor] = useState('');

  const [dialogClosable, /**setDialogClosable */] = useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] = useState(false);

  const inventoryCol = [
    {
      title: 'Location',
      dataIndex: 'locationCode',
      key: 'locationCode',
      render: (text: string, record: any) => <Typography.Link onClick={() => setCurrentLocation(record)}>{text}</Typography.Link>,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ]

  const getPackInfo = async (value: string) => {
    setFetching(true);
    try {
      const res: PackItem = await getPackByItemNum(value);
      const { line } = res;
      if (!res.fulfillmentPackNum) {
        message.error('Pack information is not found');
        setStep('scanItemNum');
        return;
      }
      if (line.requestQty === line.pickedQty) {
        Modal.info({
          title: <Typography.Text type="success" strong>{t("smart.prepack.pickForPack.hasDoneTitle", { value: value })}</Typography.Text>,
          content: <Typography.Text>{t("smart.prepack.pickForPack.hasDoneText")}</Typography.Text>,
          onOk: () => {
            setStep('scanItemNum');
          }
        })
        return
      }
      setPackItemNum(value);
      setPackInfo({
        fulfillmentPackNum: res.fulfillmentPackNum,
        fulfillmentPackLineNum: line.fulfillmentPackLineNum,
        packCode: res.packCode,
        prepackSKU: line.prepackSKU,
        packQty: res.packQty,
        status: res.status,
        sku: line.sku,
        upc: line.upc,
        // rQty: res.requestQty,
        requestQty: line.requestQty,
        pickedQty: line.pickedQty,
        warehouseNum: res.warehouseNum,
        componentQuantity: line.componentQuantity,
        parentRequestQty: line.parentLine.requestQty,
      });
      // const warehouseId = `${res.databaseNum}-${res.warehouseNum}`;
      setWarehouseId(res.warehouseId);
      await getInventory(line.sku, 1, 10, res.warehouseId);
      setStep('scanLocation');
      playAudio('success');
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  }

  const getInventory = async (code: string, current: number, pageSize: number, whsid: string) => {
    try {
      const query = {
        $skip: (current - 1) * pageSize,
        $top: pageSize,
        $sortBy: 'locationCode',
        $calculateTotal: true,
        $count: true,
        uniqueCode: code,
        virtual: false,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(whsid || warehouseId, query);
      setFetching(false);
      setInventoryList({
        ...{
          data: res.data,
          total: res.count,
          current,
          pageSize,
        },
      });
    } catch (error) {
      setFetching(false);
    }
  };

  const handlePickForPack = async () => {
    const { fulfillmentPackNum, warehouseNum, requestQty, pickedQty, fulfillmentPackLineNum } = packInfo;
    const resQty = requestQty - pickedQty - currentQty;
    if (resQty < 0) {
      return message.error('pick exceeds required quantity')
    }
    setFetching(true);
    try {
      const res = await pickToPack(fulfillmentPackNum, fulfillmentPackLineNum, {
        WarehouseNum: warehouseNum,
        LocationNum: currentLocation.locationNum,
        SKU: currentSKU,
        PickQty: currentQty
      })


      if (res) {
        playAudio('success');
        tempPackItemNum.current = ''
        if (resQty === 0) {
          const matchModal = Modal.confirm({
            title: (
              <>
                <SmartFormLabel style={{ color: GreenColor, fontWeight: 600, fontSize: 18, marginRight: 10 }}  >
                  <SmartRow wrap={false}>
                    {t("smart.prepack.pickForPack.doneTitle", { value: currentSKU })}
                    <ButtonIcon style={{ color: GreenColor }} className="bi-emoji-smile" />
                  </SmartRow>
                </SmartFormLabel>
              </>
            ),
            content: (
              <>
                <SmartFormLabel>
                  {t("smart.prepack.pickForPack.doneText")}
                </SmartFormLabel>
                <SmartFormLabel>
                  {t("smart.prepack.pickForPack.scanNextItemNum")}
                </SmartFormLabel>
                <SmartScanner
                  id="scanItemNum"
                  style={{ marginTop: 10 }}
                  onTextChange={(v) => tempPackItemNum.current = v}
                  onChangeValue={(value) => {
                    initPackInfo();
                    getPackInfo(value);
                    matchModal.destroy();
                  }}
                />
              </>
            ),
            cancelText: 'Close',
            onOk: () => {
              initPackInfo();
              if (tempPackItemNum.current) {
                getPackInfo(tempPackItemNum.current);
              } else {
                setStep('scanItemNum');
              }
            },
            onCancel: () => {
              // setPackItemNum('');
              initPackInfo();
              setStep('scanItemNum');
            }
          })
        }
        if (resQty > 0) {
          message.success('Pick for pack successfully!')
          initPackInfo();
          getPackInfo(packItemNum);
        }
      }
    } catch (error) {
      playAudio('error');
      console.log(error)
    } finally {
      setFetching(false);
    }
  }
  useEffect(() => {
    if (packInfo && currentQty) {
      let c = ''
      if (packInfo.requestQty - packInfo.pickedQty === currentQty) c = '#52c41a';
      if (packInfo.requestQty - packInfo.pickedQty < currentQty) c = '#d9363e';
      setColor(c)
    } else {
      setColor('')
    }
  }, [packInfo, currentQty])


  const initPackInfo = () => {
    setPackInfo(undefined);
    setPackItemNum('')
    setInventoryList([]);
    setCurrentQty(0);
    setCurrentSKU('');
    setCurrentLocation(undefined)
  }

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanItemNum' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.prepack.pickForPack.scanItemNum")}
            </SmartFormLabel>
            <SmartScanner
              id="scanItemNum"
              style={{ marginTop: 10 }}
              onChangeValue={(value) => { getPackInfo(value) }}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanLocation' && packInfo && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.itemNum")}
            </SmartFormLabel>
            <Text>{packInfo.packCode}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.prepackSku")}
            </SmartFormLabel>
            <Text>{`${packInfo.prepackSKU} (${packInfo.parentRequestQty})`}</Text>
          </SmartRow>
          <SmartRow style={{ width: '100%' }}>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.pickedSku")}
            </SmartFormLabel>
            <Text>{packInfo.sku}</Text>
          </SmartRow>
          <SmartRow style={{ width: '100%' }}>
            <SmartFormLabel>
              {t("common.upc")}
            </SmartFormLabel>
            <Text>{packInfo.upc}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.requestedQty")}
            </SmartFormLabel>
            <Text>{`${packInfo.requestQty} (${packInfo.componentQuantity} * ${packInfo.parentRequestQty})`}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.pickedQty")}
            </SmartFormLabel>
            <Text>{packInfo.pickedQty}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
              {t("smart.prepack.pickForPack.pickQty")}
            </SmartFormLabel>
            <Text>{packInfo.requestQty - packInfo.pickedQty}</Text>
          </SmartRow>
          <SmartCard
            bordered={false}
            title="Locations: "
            loading={fetching}
          >
            <Table
              size="small"
              rowKey="locationNum"
              bordered
              columns={inventoryCol}
              dataSource={inventoryList.data}
              loading={fetching}
              pagination={(inventoryList.data || []).length > 10 ? {
                current: inventoryList.current,
                pageSize: inventoryList.pageSize,
                total: inventoryList.total,
              } : false}
              onChange={(pagination, filters, sorter) => {
                getInventory(packInfo.sku, pagination.current || 1, pagination.pageSize || 10, '');
              }}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          </SmartCard>
          {
            currentLocation ? (
              <>
                <SmartRow align="middle">
                  <SmartFormLabel style={{ color: GreenColor, fontWeight: 600, fontSize: 18, marginRight: 10 }}  >
                    {t("smart.prepack.pickForPack.currentLoc")}
                  </SmartFormLabel>
                  <Text style={{ fontWeight: 'bold' }}> {currentLocation.locationCode}</Text>

                </SmartRow>
                <SmartRow wrap={false}>
                  <Col>
                    <SmartFormLabel style={{ color: GreenColor, fontWeight: 600, fontSize: 18, marginRight: 10 }}  >
                      {t("common.sku")}
                    </SmartFormLabel>
                  </Col>
                  <Col>
                    <SmartScanner
                      id="scanSKU"
                      style={{ marginTop: 10 }}
                      setNull
                      focusNum={skuFocusNum}
                      isAutoFocus={1}
                      autoSelect
                      onChangeValue={(value) => {
                        if (value !== packInfo.sku && value !== packInfo.upc) {
                          playAudio('error');
                          message.error('Invalid SKU!');
                          setSkuFocusNum(prev => prev + 1)
                        }
                        else {
                          playAudio('success');
                          setCurrentSKU(packInfo.sku);
                          setCurrentQty(currentQty + 1);
                          setSkuFocusNum(prev => prev + 1)
                          // setQtyFocusNum(prev=> prev + 1)
                        }
                      }}
                    />
                  </Col>
                </SmartRow>
                {currentSKU && (
                  <SmartRow wrap={false} gutter={8}>
                    <Col>
                      <SmartFormLabel style={{ color: GreenColor, fontWeight: 600, fontSize: 18, marginRight: 5 }}  >
                        {t("common.qty")}
                      </SmartFormLabel>
                    </Col>
                    <Col>
                      <SmartScanner
                        id="scanQty"
                        // focusNum={qtyFocusNum}
                        style={{ marginTop: 10 }}
                        color={color}
                        value={currentQty}
                        isAutoFocus={0}
                        autoSelect={false}
                        onChange={e => {
                          if (!isNaN(Number(e.target.value))) setCurrentQty(Number(e.target.value));
                        }}
                        onChangeValue={(value) => {
                          if (!isNaN(Number(value))) setCurrentQty(Number(value));
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        style={{ height: 50, width: 50, marginTop: 10 }}
                        onClick={() => setCurrentQty(currentQty + 1)}
                      >
                        <PlusOutlined />
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        disabled={currentQty === 1}
                        style={{ height: 50, width: 50, marginTop: 10 }}
                        onClick={() => setCurrentQty(currentQty - 1)}
                      >
                        <MinusOutlined />
                      </Button>
                    </Col>
                  </SmartRow>
                )}
                {currentQty > 0 && (
                  <SmartRow justify="center">
                    <SmartButton disabled={color === '#d9363e'} style={{ marginTop: 10 }} type="primary" onClick={handlePickForPack}>Confirm</SmartButton>
                  </SmartRow>
                )}
              </>
            ) : (
              <SmartSpace style={{ marginBottom: 10 }}>
                <SmartFormLabel
                  style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
                >

                  {t("smart.prepack.pickForPack.scanLocation")}
                </SmartFormLabel>
                <Row>
                  <Col span={14}>
                    <SmartScanner
                      id="scanLocation"
                      style={{ marginTop: 10 }}
                      onChangeValue={(value) => {
                        const idx = (inventoryList.data || []).findIndex((item: any) => item.locationCode === value);
                        if (idx === -1) {
                          playAudio('error');
                          message.error('Invalid Loacation!')
                        } else {
                          playAudio('success');
                          setCurrentLocation(inventoryList.data[idx]);
                        }
                      }}
                    />
                  </Col>
                  <Col span={10}></Col>
                </Row>
              </SmartSpace>
            )
          }

          <SmartBackButton
            onClick={() => {
              if (currentLocation) {
                setCurrentLocation(undefined)
                setCurrentQty(0)
              } else {
                setStep('scanItemNum');
                setCurrentQty(0)
                setCurrentSKU('')
              }
            }}
          />
        </>
      )
      }
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div >
  );
}
