import React from 'react';
import { Form, Row, Select, Modal, Button, Space } from 'antd';
import { CloseOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { fetchPrinterList } from 'services/print';
import { FormLabel, message } from 'components/common';
import { LS_SHIPMENT_CONTAINER_PRINTER_KEY } from 'constants/config';
import { formatPrinterToTree } from 'utils';

interface PrintCodeProps {
  visible: boolean;
  onHide: () => void;
  onSaved: (printerNum?: number) => void;
}

type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};

// eslint-disable-next-line
export default (props: PrintCodeProps) => {
  const { visible, onHide, onSaved } = props;
  const [printerTree, setPrinterTree] = React.useState<OptionsTree[]>();
  const [refreshPrinter, setRefeshPrinter] = React.useState(true);
  const ref = React.useRef<any>(null);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onClose = () => {
    //reset data before close modal
    document.body.style.overflowY = 'unset';
    onHide();
  };

  const getPrinterList = React.useCallback(async () => {
    try {
      const res = await fetchPrinterList();
      setRefeshPrinter(false);
      if (res.isSuccess) {
        setPrinterTree(formatPrinterToTree(res.data));
      } else {
        message.error({
          content: res.message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
      console.log(err);
    }
  }, []);

  const handleSave = async () => {
    const formValues = await form.getFieldsValue();
    localStorage.setItem(
      LS_SHIPMENT_CONTAINER_PRINTER_KEY,
      `${formValues.PrinterNum || ''}`
    );
    onSaved(formValues.PrinterNum);
  };

  React.useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }

    if (props.visible) {
      document.body.style.overflowY = 'hidden';
    }
  }, [getPrinterList, props, refreshPrinter]);

  return (
    <Modal
      title="Set Default Printer"
      centered
      forceRender
      maskClosable={false}
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={onClose}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                handleSave();
              }}
            >
              <SaveOutlined />
              Save
            </Button>
          </Space>
          <Space>
            <Button onClick={onClose} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Form form={form} hideRequiredMark {...layout}>
        <Form.Item label={<FormLabel>Select Printer</FormLabel>}>
          <Form.Item
            name="PrinterNum"
            rules={[
              {
                required: true,
                message: 'Please select a printer',
              },
            ]}
            colon={false}
            label={
              <Button
                icon={
                  <ReloadOutlined
                    spin={refreshPrinter}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshPrinter(true)}
              />
            }
          >
            <Select
              ref={ref}
              placeholder="Select a printer"
              optionLabelProp="label"
            >
              {printerTree?.map((item) => {
                return (
                  <Select.OptGroup
                    key={item.label}
                    label={item.label}
                  >
                    {item.options.map((citem: any) => {
                        return (
                          <Select.Option
                            key={citem.value}
                            value={citem.value}
                            label={`${item.label} - ${citem.label}`}
                          >
                            {citem.label}
                          </Select.Option>
                        );
                      })
                      }
                  </Select.OptGroup>
                );
              })}
            </Select>
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};
