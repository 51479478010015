/**
 * printer service requests are here.
 */
import { validateHttpStatus } from 'utils';
import { digitBridgePrinterApi } from './http';

type exportDetailPDFParams = {
	FulfillmentReturnNum: number;
	PrintTemplateNum: number;
}
export const exportDetailPDF = async (params: exportDetailPDFParams): Promise<any> => {
	return await digitBridgePrinterApi.get('/api/RMA/Export', { params, responseType: 'blob' });
}

export const previewDetail = async (params: exportDetailPDFParams): Promise<any> => {
	const json = JSON.stringify(params);
	return await digitBridgePrinterApi.post('/api/RMA/Preview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};

/**
 *  Customer Return（Bulk Return） 
 */

 type exportBulkReutnDetailPDFParams = {
	FulfillmentBulkReturnNum: number;
	PrintTemplateNum: number;
}

export const exportBulkReturnDetailPDF = async (params: exportBulkReutnDetailPDFParams): Promise<any> => {
	return await digitBridgePrinterApi.get('/api/bulkreturn/export', { params, responseType: 'blob' });
};

export const previewBulkReturnDetail = async (params: exportBulkReutnDetailPDFParams): Promise<any> => {
	const json = JSON.stringify(params);
	return await digitBridgePrinterApi.post('/api/bulkreturn/preview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};