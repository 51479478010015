import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { playAudio } from 'utils';
import SKUFinishDialog from './SKUFinishDialog';
import SmartTable from './SmartTable';
import OrderFinishDialog from './OrderFinishDialog';
import SKUFailDialog from './SKUFailDialog';
import { pickingByOrder } from 'services/smart';
import {
  SmartSpace,
  SmartFormLabel,
  SmartLabel,
  SmartRow,
  SmartText,
  SmartScanner,
  SmartInputNumber,
  SmartBlock,
  SmartButton,
  GreenColor,
  YellowColor,
  SmartBackButton,
  SmartPickAll,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type ScanQtyProps = {
  order: SmartPickOrder;
  product: SmartPickOrderItem;
  location: WarehouseLocationRow;
  onChangeOrder: (nextOrder: string | undefined) => void;
  onChangeSKU: (nextProduct: SmartPickOrderItem) => void;
  onBack: (
    step: 'scanOrder' | 'scanSKU' | 'scanLocation' | 'scanQty',
    nextLocation?: SmartLocationRow
  ) => void;
  onFinish: (scannedQty: number) => Promise<void>;
  pickedQty: number;
};
/**
 * Scan Qty
 * @param {ScanQtyProps} props
 * @returns {JSX.Element}
 */
export default function ScanQty(props: ScanQtyProps): JSX.Element {
  const {
    onBack,
    product,
    onFinish,
    location,
    onChangeSKU,
    onChangeOrder,
    order,
    pickedQty,
  } = props;
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [pickQty, setPickQty] = useState(0);
  const [scannedQty, setScannedQty] = useState(0);
  const [scannedAt, setScannedAt] = useState<Date | undefined>();
  const [status, setStatus] = useState<'default' | 'success' | 'fail'>(
    'default'
  );
  const failSKURef = useRef('');
  const [finish, setFinish] = useState<'default' | 'allFinished' | 'finished'>(
    'default'
  );
  useEffect(() => {
    const {fulfillmentOrderLineNum } = product;
    const orderSKU = order.items.find((item) => {
      if (item.fulfillmentOrderLineNum === fulfillmentOrderLineNum) {
        return item;
      }
      return undefined;
    });
    if (orderSKU) {
      setPickQty(orderSKU.pickQty);
      setScannedQty(orderSKU.pickedQty);
    }
  }, [order, product]);

  /**
   * Post qty
   */
  const handleSubmit = useCallback(
    async (qty: number, isAuto?: boolean) => {
      setSubmitting(true);
      try {
        const res = await pickingByOrder({
          FulfillmentOrderNum: order.fulfillmentOrderNum,
          LocationCode: location.locationCode || '',
          FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
          Title: product.title,
          SkuOrUpc: product.sku || product.upc,
          PickQty: product.pickQty,
          PickedQty: qty - pickedQty,
        });
        if (res.isSuccess) {
          setSubmitting(false);
          setStatus('default');
          if (!isAuto) {
            onBack('scanLocation');
          } else {
            const { items } = order;
            let orderFinished = true;
            items.forEach((item) => {
              if (item.pickQty > item.pickedQty) {
                orderFinished = false;
              }
            });
            setFinish(orderFinished ? 'allFinished' : 'finished');
          }
        }
      } catch (error) {
        setStatus('default');
        setScannedQty((prev) => prev - 1);
        onFinish(scannedQty);
        setSubmitting(false);
      }
    },
    [product, location, onBack, order, pickedQty, onFinish, scannedQty]
  );

  const handleSkip = useCallback(async (qty: number)=>{
    try {
      const res = await pickingByOrder({
        FulfillmentOrderNum: order.fulfillmentOrderNum,
        LocationCode: location.locationCode || '',
        FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
        Title: product.title,
        SkuOrUpc: product.sku || product.upc,
        PickQty: product.pickQty,
        PickedQty: qty - pickedQty,
      });
      if (res.isSuccess) {
        setStatus('default');
        setScannedQty(0)
        onBack('scanSKU');
      }
    } catch (error) {
      setStatus('default');
      onBack('scanSKU');
    }
  },[product, location, onBack, order, pickedQty])

  const handleComplete = useCallback(async (qty: number)=>{
    try {
      // todo
      if(qty > 0) {
        const res = await pickingByOrder({
          FulfillmentOrderNum: order.fulfillmentOrderNum,
          LocationCode: location.locationCode || '',
          FulfillmentOrderLineNum: product.fulfillmentOrderLineNum,
          Title: product.title,
          SkuOrUpc: product.sku || product.upc,
          PickQty: product.pickQty,
          PickedQty: qty - pickedQty,
        });
        if (res.isSuccess) {
          setStatus('default');
          setScannedQty(0)
          onBack('scanOrder');
        }
      } else {
        onBack('scanOrder');
      }
    } catch (error) {
      onBack('scanOrder');
    }
  },[product, location, onBack, order, pickedQty])

  /**
   * hanle finish status
   */
  const handleFinishStatus = useCallback(
    async (nextScannedQty: number) => {
      onFinish(nextScannedQty);

      //Whether the SKUS has been scanned
      if (pickQty > 0 && nextScannedQty === pickQty) {
        handleSubmit(nextScannedQty, true);
      }
    },
    [pickQty, handleSubmit, onFinish]
  );
  /**
   * handle scan sku  event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (
        nextValue === product.sku ||
        (nextValue === product.upc && product.upc)
      ) {
        playAudio('success');
        setStatus('success');
        const nextScannedQty =
          scannedQty + 1 >= pickQty ? pickQty : scannedQty + 1;

        setScannedQty(nextScannedQty);
        handleFinishStatus(nextScannedQty);
      } else {
        playAudio('error');
        failSKURef.current = nextValue;
        setStatus('fail');
      }
      setScannedAt(new Date());
    },
    [scannedQty, product, handleFinishStatus, pickQty]
  );
  /**
   * handle finish event
   */
  const handleSKUFinish = useCallback(
    (nextSKU: SmartPickOrderItem) => {
      // onBack('scanLocation');
      onChangeSKU(nextSKU);
    },
    [onChangeSKU]
  );
  const scannedAtStr = dayjs(scannedAt).format('hh:mm:ss A');
  const copywrite = t("smart.pick.skipLocation");
  const successTip = t("smart.pick.successTip", { value : scannedAtStr});
  const failTip = t("smart.pick.failedTip", { value : scannedAtStr});

  return (
    <>
      {finish === 'finished' && (
        <SKUFinishDialog
          order={order}
          product={product}
          onSubmit={handleSKUFinish}
          onSkipOrder={() => {
            onBack('scanOrder');
          }}
        />
      )}
      {finish === 'allFinished' && (
        <OrderFinishDialog
          order={order}
          onSubmit={(value) => onChangeOrder(value)}
        />
      )}
      {status === 'fail' && (
        <SKUFailDialog
          failSKU={failSKURef.current}
          sku={product.sku || ''}
          upc={product.upc || ''}
          onSubmit={(ok) => {
            if (ok) {
              handleScannerChange(product.sku || '');
            } else {
              setStatus('default');
            }
            setTimeout(() => {
              document.getElementById('scanner')?.focus();
            });
          }}
        />
      )}
      <SmartSpin loading={submitting} />
      <SmartSpace>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>{t("common.order")}</SmartFormLabel>
          </SmartLabel>
          <SmartText>{order.fulfillmentOrderNum}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickSku")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickUpc")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <SmartRow>
            <SmartLabel>
              <SmartFormLabel>
                {t("common.location")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartText>{location?.locationCode}</SmartText>
          </SmartRow>
          {/* {scannedQty === 0 && ( */}
          <Button
            size="small"
            onClick={() => {
              onBack('scanLocation');
            }}
          >
            {t("smart.pick.changeLocation")}
          </Button>
          {/* )} */}
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.title")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.title : ''}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.pickQty")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartInputNumber
            value={pickQty}
            readOnly
            style={{ width: 95, backgroundColor: 'rgb(255,253,128)' }}
          />
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.awaitPickQty")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartInputNumber
            value={pickQty - pickedQty}
            readOnly
            style={{ width: 95, backgroundColor: 'rgb(255,253,128)' }}
          />
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("smart.pick.scan")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartScanner
            id="scanner"
            style={{ width: 190 }}
            onChangeValue={handleScannerChange}
          />
          {status === 'success' && (
            <SmartRow style={{ color: GreenColor }}>{successTip}</SmartRow>
          )}
          {status === 'fail' && (
            <SmartRow style={{ color: YellowColor }}>{failTip}</SmartRow>
          )}
        </SmartRow>
        <SmartRow style={{ marginTop: 10, justifyContent: 'space-between' }}>
          <Row>
            <SmartLabel>
              <SmartFormLabel>
                {t("smart.pick.scannedQty")}
              </SmartFormLabel>
            </SmartLabel>
            <SmartInputNumber
              value={scannedQty}
              readOnly
              style={{ width: 95, backgroundColor: 'rgb(208,248,157)' }}
            />
          </Row>
          <SmartPickAll
            style={{ marginLeft: 20 }}
            onClick={() => {
              setScannedQty(pickQty);
              handleFinishStatus(pickQty);
            }}
          />
        </SmartRow>
      </SmartSpace>

      <SmartTable dataSource={order.items} />
      <div style={{ marginTop: 20 }}>
        <SmartBackButton>
          <SmartBlock>
            {scannedQty > 0 && (
              <SmartButton
                backgroundColor="red"
                color="write"
                loading={submitting}
                onClick={() => {
                  // onBack('scanLocation');
                  handleSubmit(scannedQty);
                }}
              >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {copywrite}
              </Typography.Text>
              </SmartButton>
            )}

            {pickQty !== scannedQty && (
              <SmartButton
                type="default"
                backgroundColor="red"
                color='#fff'
                onClick={() => {
                  if(scannedQty > 0){
                    handleSkip(scannedQty)
                  }
                  onBack('scanSKU');
                }}
              >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {t("smart.pick.chooseAnotherSku")}
                </Typography.Text>
              </SmartButton>
            )}
            <SmartButton
              type="default"
              backgroundColor="red"
              onClick={() => {
                handleComplete(scannedQty)
              }}
            >
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {t("smart.pick.chooseAnotherOrder")}
              </Typography.Text>
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </>
  );
}
