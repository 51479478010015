import React, { useRef, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Typography, Form, Modal, Switch, InputNumber } from 'antd';
import { playAudio } from 'utils';
import {
  message,
  SetDefaultWarehouseDialog,
  ButtonIcon,
} from 'components/common';
// import FinishDialog from './finish';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
  SmartButton,
} from '../../SmartComponent';
import { useTranslation } from 'react-i18next';
import { getUnpackByItem, ceateUnpackProcessLine } from 'services/unpack';
import { fetchWarehouseProductList } from 'services/inventory';
// import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import SearchSelect from '../../../../PrePack/Unpack/SelectLocation';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { getWarehouseIdFromCache } from 'utils';

const { Text } = Typography;

export default function PickForPack(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanItemNum' | 'scanLocation'>(
    'scanItemNum'
  );
  const [unpackInfo, setUnpackInfo] = useState<any>();
  const [inventoryList, setInventoryList] = useState<any>();
  const [sLocation, setSLocation] = useState<any>();
  const [tLocation, setTLocation] = useState<any>();
  const [currentQty, setCurrentQty] = useState<number>(0);
  // const [qtyFocusNum, setQtyFocusNum]= useState<number>(0)
  const [skuFocusNum, setSkuFocusNum] = useState<number>(0);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const tempPackItemNum = useRef('');

  const [dialogClosable /**setDialogClosable */] = useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] = useState(false);

  const [form] = Form.useForm();

  const initUnpackInfo = () => {
    setUnpackInfo(undefined);
    // setInventoryList([]);
    setCurrentQty(0);
    setTLocation(undefined);
    setSLocation(undefined);
    setChecked1(false);
    setChecked2(false);
    form.setFieldsValue({
      destinationLocationNum: undefined,
      destinationLocationCode: undefined,
      sourceLocationCode: undefined,
      sourceLocationNum: undefined,
    });
  };

  const getUnpackInfo = async (value: string) => {
    setFetching(true);
    try {
      const res = await getUnpackByItem(value);
      if (res) {
        setUnpackInfo(res.line);
        await getInventory(res.line.sku, 1, 10, res.warehouseId);
        getUnpackLocation(res.warehouseNum);
        setStep('scanLocation');
        playAudio('success');
      } else {
        playAudio('error');
        message.error(`[${value}] not found`);
        setStep('scanItemNum');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const getUnpackLocation = useCallback(async (warehouseNum: number) => {
    try {
      const res = await fetchWarehouseLocationList(
        getWarehouseIdFromCache(warehouseNum),
        true
      );
      if (res && res.length > 0) {
        setLocations(res);
      }
    } catch (error) {}
  }, []);

  const checkSwicth = useCallback(
    (ck1, ck2) => {
      if (ck1) {
        const { sourceLocationNum } = form.getFieldsValue();
        console.log(sourceLocationNum);
        const unpackLocation = locations.filter(
          (i) => i.locationNum === sourceLocationNum
        );
        if (unpackLocation.length > 0) {
          setTLocation(unpackLocation[0]);
          form.setFieldsValue({
            destinationLocationNum: unpackLocation[0].locationNum,
            destinationLocationCode: unpackLocation[0].locationCode,
          });
        }
      }

      if (ck2) {
        //set to $unpack
        const unpackLocation = locations.filter(
          (i) => i.locationCode === '$Unpack'
        );
        if (unpackLocation.length > 0) {
          setTLocation(unpackLocation[0]);
          form.setFieldsValue({
            destinationLocationNum: unpackLocation[0].locationNum,
            destinationLocationCode: unpackLocation[0].locationCode,
          });
        }
      }
    },
    [locations, form]
  );

  const getInventory = async (
    code: string,
    current: number,
    pageSize: number,
    whsid: string
  ) => {
    try {
      const query = {
        $skip: (current - 1) * pageSize,
        $top: pageSize,
        $sortBy: 'locationCode',
        $calculateTotal: true,
        $count: true,
        uniqueCode: code,
        virtual: false,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(whsid, query);
      setFetching(false);
      setInventoryList(res.data);
    } catch (error) {
      setFetching(false);
    }
  };

  const handleSubmit = async () => {
    const res = await ceateUnpackProcessLine(unpackInfo.fulfillmentPackNum, {
      sourceLocationNum: sLocation.locationNum,
      sourceLocationCode: sLocation.locationCode,
      destinationLocationNum: tLocation.locationNum,
      destinationLocationCode: tLocation.locationCode,
      unpackQty: currentQty,
      sku: unpackInfo.sku,
    });
    if (res) {
      const matchModal = Modal.confirm({
        title: (
          <>
            <SmartRow wrap={false}>
              {t('smart.prepack.unpack.unpackItem.doneText', {
                value: unpackInfo.sku,
              })}
              <ButtonIcon
                style={{ color: GreenColor }}
                className="bi-emoji-smile"
              />
            </SmartRow>
          </>
        ),
        icon: undefined,
        content: (
          <>
            <SmartRow>
              <SmartFormLabel>{t('common.sku')}</SmartFormLabel>
              <Text>{unpackInfo.sku}</Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>{t('common.upc')}</SmartFormLabel>
              <Text>{unpackInfo.upc}</Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>{t('common.title')}</SmartFormLabel>
              <Text>{unpackInfo.productTitle}</Text>
            </SmartRow>

            <SmartRow>
              <SmartFormLabel>
                {t('smart.prepack.unpack.unpackItem.requestedQty')}
              </SmartFormLabel>
              <Text>{unpackInfo.requestQty}</Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>
                {t('smart.prepack.unpack.unpackItem.totalUnpackedQty')}
              </SmartFormLabel>
              <Text>{currentQty + unpackInfo.unpackQty}</Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>
                {t('smart.prepack.unpack.unpackItem.from')}
              </SmartFormLabel>
              <Text>{sLocation.locationCode}</Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>
                {t('smart.prepack.unpack.unpackItem.to')}
              </SmartFormLabel>
              <Text>{tLocation.locationCode}</Text>
            </SmartRow>
            <SmartFormLabel
              style={{
                color: GreenColor,
                fontWeight: 600,
                fontSize: 18,
                marginRight: 5,
              }}
            >
              {t('smart.prepack.unpack.unpackItem.scanNextItem')}
            </SmartFormLabel>
            <SmartScanner
              id="scanItemNum"
              style={{ marginTop: 10 }}
              onTextChange={(v) => (tempPackItemNum.current = v)}
              onChangeValue={(value) => {
                initUnpackInfo();
                getUnpackInfo(value);
                matchModal.destroy();
              }}
            />
          </>
        ),
        okText: 'Next',
        onOk: () => {
          initUnpackInfo();
          if (tempPackItemNum.current) {
            getUnpackInfo(tempPackItemNum.current);
          } else {
            setStep('scanItemNum');
          }
        },
        onCancel: () => {
          initUnpackInfo();
          setStep('scanItemNum');
        },
      });
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanItemNum' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.prepack.unpack.unpackItem.scanItemNum')}
            </SmartFormLabel>
            <SmartScanner
              id="scanItemNum"
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                getUnpackInfo(value);
              }}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanLocation' && unpackInfo && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>{t('common.sku')}</SmartFormLabel>
            <Text>{unpackInfo.sku}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>{t('common.upc')}</SmartFormLabel>
            <Text>{unpackInfo.upc}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>{t('common.title')}</SmartFormLabel>
            <Text>{unpackInfo.productTitle}</Text>
          </SmartRow>

          <SmartRow>
            <SmartFormLabel>
              {t('smart.prepack.unpack.unpackItem.requestedQty')}
            </SmartFormLabel>
            <Text>{unpackInfo.requestQty}</Text>
          </SmartRow>
          <SmartRow>
            <SmartFormLabel>
              {t('smart.prepack.unpack.unpackItem.totalUnpackedQty')}
            </SmartFormLabel>
            <Text>{unpackInfo.unpackQty}</Text>
          </SmartRow>

          <SmartRow>
            <SmartFormLabel>
              {t('smart.prepack.unpack.unpackItem.locationInSystem')}
            </SmartFormLabel>
            <div
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                width: '100%',
              }}
            >
              {inventoryList
                .filter((i: any) => i.quantity > 0)
                .map((item: any) => (
                  <Text
                    style={{
                      fontSize: 16,
                      marginRight: 6,
                    }}
                  >
                    <Text strong>{item.locationCode}</Text>
                    {`(${item.quantity})`}
                  </Text>
                ))}
            </div>
          </SmartRow>

          <Form form={form}>
            <SmartRow wrap={false}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 10,
                  }}
                >
                  {t('smart.prepack.unpack.unpackItem.scanProduct')}
                </SmartFormLabel>
              </Col>
              <Col>
                <SmartScanner
                  id="scanSKU"
                  usualInput
                  style={{ marginTop: 10 }}
                  setNull
                  focusNum={skuFocusNum}
                  isAutoFocus={1}
                  autoSelect
                  onChangeValue={(value) => {
                    if (
                      value.trim() !== unpackInfo.sku &&
                      value.trim() !== unpackInfo.upc
                    ) {
                      playAudio('error');
                      message.error('Invalid SKU!');
                    } else {
                      if (
                        unpackInfo.requestQty - unpackInfo.unpackQty <
                        currentQty + 1
                      ) {
                        playAudio('error');
                        message.error('qty overflow!');
                      } else {
                        playAudio('success');
                        setCurrentQty(currentQty + 1);
                      }
                      setSkuFocusNum((prev) => prev + 1);
                    }
                  }}
                />
              </Col>
            </SmartRow>
            <SmartRow wrap={false} gutter={8}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 5,
                  }}
                >
                  {t('common.qty')}
                </SmartFormLabel>
              </Col>
              <Col>
                <InputNumber
                  min={0}
                  max={unpackInfo.requestQty - unpackInfo.unpackQty}
                  precision={0}
                  value={currentQty}
                  style={{ marginLeft: 8, marginTop: 10 }}
                  onChange={(value) => setCurrentQty(value)}
                />
              </Col>
            </SmartRow>

            <SmartRow wrap={false} gutter={8}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 5,
                  }}
                >
                  {t('smart.prepack.unpack.unpackItem.sourceLocation')}
                </SmartFormLabel>
              </Col>
              <Col>
                <Form.Item
                  noStyle
                  name="sourceLocationNum"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Source Loc#!',
                    },
                  ]}
                >
                  <SearchSelect
                    style={{ width: '100%', minWidth: 200, marginTop: 10 }}
                    locations={locations.filter(
                      (i) => i.locationCode !== '$Pack'
                    )}
                    onChange={(value, row) => {
                      if (value && row.length > 0) {
                        form.setFieldsValue({
                          sourceLocationCode: row[0].locationCode,
                        });
                        setSLocation(row[0]);
                        checkSwicth(checked1, checked2);
                      }
                    }}
                    // locationType={0}
                    form={form}
                    title="sourceLocationNum"
                  />
                </Form.Item>
              </Col>
            </SmartRow>
            <SmartRow wrap={false} gutter={8}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 5,
                  }}
                >
                  {t('smart.prepack.unpack.unpackItem.unpackSourceLocation')}
                </SmartFormLabel>
              </Col>
              <Col>
                <Switch
                  checkedChildren="on"
                  unCheckedChildren="off"
                  checked={checked1}
                  disabled={checked2}
                  onChange={(checked) => {
                    setChecked1(checked);
                    checkSwicth(checked, checked2);
                  }}
                />
              </Col>
            </SmartRow>
            <SmartRow wrap={false} gutter={8}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 5,
                  }}
                >
                  {t('smart.prepack.unpack.unpackItem.destinationLocation')}
                </SmartFormLabel>
              </Col>
              <Col>
                <Form.Item
                  noStyle
                  name="destinationLocationNum"
                  rules={[
                    {
                      required: true,
                      message: 'Please input Destination Loc#!',
                    },
                  ]}
                >
                  <SearchSelect
                    style={{ width: '100%', minWidth: 200, marginTop: 10 }}
                    locations={locations.filter(
                      (i) => i.locationCode !== '$Pack'
                    )}
                    // warehouseId={getWarehouseIdFromCache(info.warehouseNum)}
                    onChange={(value, row) => {
                      if (value && row.length > 0) {
                        form.setFieldsValue({
                          destinationLocationCode: row[0].locationCode,
                        });
                        setTLocation(row[0]);
                      }
                    }}
                    // locationType={0}
                    form={form}
                    title="destinationLocationNum"
                  />
                </Form.Item>
              </Col>
            </SmartRow>
            <SmartRow wrap={false} gutter={8}>
              <Col>
                <SmartFormLabel
                  style={{
                    color: GreenColor,
                    fontWeight: 600,
                    fontSize: 18,
                    marginRight: 5,
                  }}
                >
                  {t('smart.prepack.unpack.unpackItem.unpackToUnpack')}
                </SmartFormLabel>
              </Col>
              <Col>
                <Switch
                  checkedChildren="on"
                  unCheckedChildren="off"
                  checked={checked2}
                  disabled={checked1}
                  onChange={(checked) => {
                    setChecked2(checked);
                    checkSwicth(checked1, checked);
                  }}
                />
              </Col>
            </SmartRow>

            <SmartRow justify="center">
              <SmartButton
                disabled={!tLocation || !sLocation || !currentQty}
                style={{ marginTop: 10 }}
                type="primary"
                onClick={handleSubmit}
              >
                Submit
              </SmartButton>
            </SmartRow>
          </Form>

          <SmartBackButton
            onClick={() => {
              setStep('scanItemNum');
            }}
          />
        </>
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
