import React, { useCallback } from 'react';
import { playAudio } from 'utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Typography, Table } from 'antd';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartRow,
  SmartText,
  SmartBackButton,
  SmartBlock,
  SmartButton,
} from '../../../SmartComponent';
// import { fetchWarehouseProductList } from 'services/inventory';
// import { queryLocationsByKeyword } from 'services/warehouse';
import { useTranslation } from 'react-i18next';

const { Text, Link } = Typography;

type ScanLocationProps = {
  product: SmartPickSKU;
  locations: any[];
  warehouseId: string;
  onBack: () => void;
  onSubmit: (location: any, inventory?: any) => void;
  uomEnable: boolean;
  lotEnable: boolean;
};
export default function ScanLocation(props: ScanLocationProps): JSX.Element {
  const { product, onSubmit, onBack, locations, uomEnable, lotEnable } = props;
  const { t } = useTranslation();
  //const [realLocations, setRealLocations] = React.useState();
  // const [realLocations, setRealLocations] = React.useState<StringKAnyVPair[]>();
  // const [loading, setLoading] = React.useState(false);

  // const fetchRealInventory = useCallback(async () => {
  //   try {
  //     const query = {
  //       $skip: 0,
  //       $top: 1000,
  //       $sortBy: 'locationCode',
  //       uniqueCode: product.sku,
  //     };
  //     setLoading(true);
  //     const res = await fetchWarehouseProductList(warehouseId, query);
  //     setLoading(false);
  //     setRealLocations(res.data);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }, [product, warehouseId]);
  /**
   * handle location key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string, recode?: any) => {
      const locationRow = locations.filter((item) => item.code === nextValue);
      if (locationRow.length > 0) {
        const temp = (product.location || []).filter((item) => item.locationCode === nextValue);
        if (temp.length < 1) {
          Modal.confirm({
            title: t("smart.pick.areYouContinue"),
            icon: <ExclamationCircleOutlined />,
            content: t("smart.pick.areYouContinue", { value: nextValue }),
            okText: t("common.yes"),
            onOk() {
              playAudio('success');
              onSubmit(locationRow[0]);
            },
            onCancel() {
              return;
            },
          });
        } else {
          playAudio('success');
          if(temp.length === 1 ) {
            onSubmit(locationRow[0], temp[0]);
          } else {
            onSubmit(locationRow[0], recode);
          }
        }
      } else {
        playAudio('error');
        message.warning(`${nextValue} invalid!`);
      }
    },
    [onSubmit, locations, product, t]
  );

  const getColumns = useCallback(() => {
    let temp: any[] = [
      {
        title: 'LOC#',
        dataIndex: 'locationCode',
        key: 'locationCode',
        render: (text: string, record:any) => <Link onClick={()=>  handleScannerChange(text, record)}>{text}</Link>,
      },
      {
        title: 'LOT#',
        dataIndex: 'lotNumber',
        key: 'lotNumber',
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ];
    temp = lotEnable ? temp : temp.filter((i) => i.dataIndex !== 'lotNumber');
    return temp;
  }, [lotEnable, handleScannerChange]);

  return (
    <>
      <SmartSpace>
        <SmartRow>
          <SmartFormLabel>
            {t("common.sku")}
          </SmartFormLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.upc")}
          </SmartFormLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        {uomEnable && <SmartRow>
          <SmartFormLabel>
            UOM
          </SmartFormLabel>
          <SmartText>{product.uom}</SmartText>
        </SmartRow>}
        <SmartRow>
          <SmartFormLabel>
            {t("common.title")}
          </SmartFormLabel>
          <SmartText>{product.itemTitle}</SmartText>
        </SmartRow>
        {/* <SmartRow>
          <SmartFormLabel>
              {t("smart.pick.locationInSystem")}
          </SmartFormLabel>
        </SmartRow>

        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            width: '100%',
          }}
        >
          {(product.location || [])
            .filter(
              (i) =>
                i.quantity > 0 &&
                i.locationCode !== 'Pick' &&
                i.locationCode !== 'Checkout'
            )
            .map((item: any) => (
              <Text
                style={{
                  fontSize: 16,
                  marginRight: 6,
                }}
              >
                {item.locationCode === 'General' ? (
                  <Link onClick={() => handleScannerChange('General')}>
                    General
                  </Link>
                ) : (
                  <Text strong>{item.locationCode}</Text>
                )}
                {`(${item.quantity})`}
              </Text>
            ))}
        </div> */}
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanLocationContinue")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={(value)=>handleScannerChange(value)}
        />
      </SmartSpace>
      <SmartRow>
            <Text strong style={{ fontSize: 16 }}>
            {t("smart.pick.locationInSystem")}
            </Text>
          </SmartRow>
          <Table
            size="small"
            rowKey={(record) =>
              record.locationCode
            }
            bordered
            columns={getColumns()}
            dataSource={product.location}
            pagination={false}
            style={{ marginTop: 10, marginBottom: 10 }}
          />{' '}
      <div style={{ marginTop: 10 }}>
        <SmartBackButton>
          <SmartBlock>
            {/* <SmartButton
              onClick={() => fetchRealInventory()}
              color="white"
              loading={loading}
            >
              {t("smart.pick.pullRealTimeInventory")}
            </SmartButton> */}
            <SmartButton
              onClick={() => onBack()}
              backgroundColor="red"
              color="white"
            >
              {t("smart.pick.goBack")}
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </>
  );
}
