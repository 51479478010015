import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import Receive from 'components/Inventory/Receive/index';
// eslint-disable-next-line
export default function InventoryReceive() {
  return (
    <ContentLayout>
      <SiteContent>
        <Receive />
      </SiteContent>
    </ContentLayout>
  );
}
