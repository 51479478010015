import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space, Form } from 'antd';
import { FormLabel } from 'components/common';
import SelectLocation from 'components/Inventory/Audit/SelectLocation';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import { createPoLine } from 'services/purchaseOrder';

type Props = {
  warehouseNum: number;
  onHide: () => void;
  onSuccess: (location: WarehouseLocationRow) => void;
  isAll?: boolean
};

const ReceiveAllDialog = (props: Props) => {
  const { onHide, warehouseNum, onSuccess, isAll = true } = props;
  const [selectedLocation, setSelectedLocation] =
    useState<WarehouseLocationRow>();

  const [form] = Form.useForm();

  /**
   * handle Confirm
   */
  const handleConfirm = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (selectedLocation) {
      onSuccess(selectedLocation);
    }
  }, [onSuccess, form, selectedLocation]);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title={isAll ? 'Receive All' : 'Choose Location'}
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              disabled={!selectedLocation}
              onClick={() => handleConfirm()}
              icon={<CheckOutlined />}
            >
              Ok
            </Button>
            <Button onClick={() => handleHide()} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form}>
        <Form.Item
          name="sku"
          rules={[{ required: true, message: 'please select a location' }]}
          label={<FormLabel>Location</FormLabel>}
        >
          <SelectLocation
            warehouseNum={warehouseNum}
            onChangeOption={(option: any) => setSelectedLocation(option)}
            includeVirtual={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReceiveAllDialog;
