import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Modal,
  Button,
  Space,
  InputNumber,
  Typography,
  Select,
} from 'antd';
import { FormLabel } from 'components/common';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onUpdate: (value: any) => void;
  onDelete: (id: string) => void;
  info: any;
  lotEnable: boolean;
  uomEnable: boolean;
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onUpdate, info, lotEnable, uomEnable, onDelete } =
    props;
  const [selectedUom, setSelectedUom] = useState<any>();
  const [qty, setQty] = useState(info.qty);

  const [form] = Form.useForm();

  const handleSave = async () => {
    const formValue = form.getFieldsValue();
    const id = `${info?.sku}-${formValue?.lotNumber || ''}-${
      formValue?.uom || ''
    }`;
    onUpdate({
      ...info,
      id,
      lotNumber: formValue.lotNumber,
      uom: formValue.uom,
      rate: selectedUom?.uomRate || 1,
      qty: qty,
    });
  };

  useEffect(() => {
    if (info.uom) {
      const temp = info.uomList.filter((i: any) => i.uom === info.uom);
      if (temp.length > 0) {
        setSelectedUom(temp[0]);
      }
    }
  }, [info]);

  return (
    <Modal
      title="Edit Location Count"
      centered
      visible={visible}
      maskClosable={false}
      style={{ width: 400 }}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button key="submit" type="primary" onClick={handleSave}>
              Update
            </Button>
            <Button onClick={onHide}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Form form={form}>
        <Row justify="start" style={{ marginBottom: 8 }}>
          <FormLabel>SKU</FormLabel>
          <Typography.Text>{info.sku}</Typography.Text>
        </Row>
        <Row justify="start" style={{ marginBottom: 8 }}>
          <FormLabel>upc</FormLabel>
          <Typography.Text>{info.upc}</Typography.Text>
        </Row>

        {lotEnable && (
          <Row justify="start" style={{ marginBottom: 8 }}>
            <FormLabel>LOT#</FormLabel>
            <Space>
              <Form.Item noStyle name="lotNumber" initialValue={info.lotNumber}>
                <Select style={{ width: 200, marginLeft: 4 }}>
                  {info.lotNumberList.map((i: any) => (
                    <Select.Option key={i.productLotNum} value={i.lotNumber}>
                      {i.lotNumber}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
          </Row>
        )}

        {uomEnable && (
          <Row justify="start" style={{ marginBottom: 8 }}>
            <FormLabel>UOM</FormLabel>
            <Space>
              <Form.Item noStyle name="uom" initialValue={info.uom}>
                <Select
                  style={{ width: 100, marginLeft: 4 }}
                  onChange={(value) => {
                    if (value) {
                      const temp = info.uomList.filter(
                        (i: any) => i.uom === value
                      );
                      setSelectedUom(temp[0]);
                    }
                  }}
                >
                  {info.uomList.map((i: any) => (
                    <Select.Option key={i.productUomNum} value={i.uom}>
                      {i.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedUom && (
                <Typography.Text>
                  <FormLabel>Rate</FormLabel>
                  {selectedUom?.uomRate}
                </Typography.Text>
              )}
            </Space>
          </Row>
        )}

        <Row justify="start" style={{ marginBottom: 8 }}>
          <FormLabel>Qty</FormLabel>
          <Space>
            <Form.Item noStyle>
              <InputNumber
                min={1}
                value={qty}
                style={{ width: 100, marginLeft: 4 }}
                onChange={(e) => setQty(e)}
              />
            </Form.Item>

            {selectedUom && uomEnable && (
              <Typography.Text>
                <FormLabel>Base Qty</FormLabel>
                {qty * selectedUom.uomRate}
              </Typography.Text>
            )}
          </Space>
        </Row>
          <Row>
            <Button danger onClick={() => onDelete(info.id)}>
              Delete
            </Button>
          </Row>
      </Form>
    </Modal>
  );
};
