import React, { useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { fetchZones } from 'services/zone';

const { Option } = Select;
type SelectZoneProps = {
  warehouseId: string;
  width?: number;
  onChange?: (id: string) => void;
  disabled?: boolean;
  size?: GeneralSizeType;
};
export default function SelectZone(props: SelectZoneProps): JSX.Element {
  const { warehouseId, onChange, width, disabled, size } = props;
  const [zoneList, setZoneList] = React.useState<any[]>([]);

  const getZones = useCallback(async () => {
    try {
      const res = await fetchZones(warehouseId);
      if (res) {
        setZoneList(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [warehouseId]);

  useEffect(() => {
    getZones();
  }, [getZones]);

  return (
    <Select
      showSearch
      allowClear
      style={{ width: width || '100%' }}
      disabled={disabled}
      placeholder="Select Zone"
      size={size || 'middle'}
      optionFilterProp="label"
      onChange={(newValue: string) => {
        if (typeof onChange !== 'undefined') {
          onChange(newValue);
        }
      }}
    >
      {zoneList.map((item: any) => {
        return (
          <Option key={item.id} value={item.id} label={item.name}>
            {item.name}
          </Option>
        );
      })}
    </Select>
  );
}
SelectZone.defaultProps = {
  width: undefined,
  value: undefined,
  onChange: undefined,
  disabled: undefined,
  size: undefined,
};
