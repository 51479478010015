import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import { getErrorOrderQty } from 'services/orders';
import { useHistory } from 'react-router-dom';
import { ButtonIcon } from './styles';

type Props = {
  warehouseNum: number;
};

export default function ErrorOrderLine(props: Props) {
  const { warehouseNum } = props;
  const [qty, setQty] = useState<number>(0);
  const [fromDate, setFromDate] = useState<string>();
  const histoty = useHistory();

  const getErrorQty = useCallback(async (warehouseNum: number) => {
    const res = await getErrorOrderQty(warehouseNum);
    if (res.isSuccess) {
      setQty(res.data.total);
      setFromDate(res.data.from);
    }
  }, []);

  useEffect(() => {
    if (warehouseNum && warehouseNum > 0) {
      getErrorQty(warehouseNum);
    } else {
      setQty(0);
    }
  }, [warehouseNum, getErrorQty]);
  return qty ? (
    qty === 1 ? (
      <Alert
        message="There is 1 order processed with error. You may try reprocess it."
        type="error"
        action={
          <Button
            size="small"
            danger
            onClick={() =>
              histoty.push(`/sales/view-orders?fromDate=${fromDate}&orderStatus=${`0|2|8`}&warehouseNum=${warehouseNum}`)
            }
            icon={<ButtonIcon className="bi bi-file-earmark-text" />}
          >
            Detail
          </Button>
        }
      />
    ) : (
      <Alert
        message={`There are ${qty} unfulfilled orders processed with error. You may try reprocess them.`}
        type="error"
        action={
          <Button
            size="small"
            danger
            onClick={() =>
              histoty.push(`/sales/view-orders?fromDate=${fromDate}&orderStatus=${`0|2|8`}&warehouseNum=${warehouseNum}`)
            }
            icon={<ButtonIcon className="bi bi-file-earmark-text" />}
          >
            Detail
          </Button>
        }
      />
    )
  ) : null;
}
