import * as actionTypes from 'actions/actionTypes';

const initialState = {
  needSetDevice: false,
  packingPrinter: {},
  packingPrinter2: {},
  printCartonResult: {},
  printDocumentResult: {},
  printLabelEnd: {},
  printLabelMessage: {},
  printLabelResult: {},
  printLabelResponse: {},
  printMultiPackageResult: {},
  printSlipResult: {},
  printerList: [],
  printerListIsLoading: false,
  printerListLoaded: false,
  printingIsHang: false,
  printstationIsOk: false,
  printstationPong: {},
  scale: {},
  scaleLB: null,
  scaleOZ: null,
  scaleList: [],
  scaleListIsLoading: false,
  scaleListLoaded: false,
  shipmentItem: {},
  shipmentPrinter: {},
  shipmentPrinter4x8: {},
  shipmentPrinterCoton: {},
  testPrintMessage: {},
  printPickListStatus: 0, //control printpickList 11: loading && refresh
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEED_SET_DEVICE:
      return {
        ...state,
        needSetDevice: action.needed,
      };

    case actionTypes.PONG_PRINTSTATION:
      return {
        ...state,
        printstationPong: action.pong,
      };

    case actionTypes.SET_ISLOADING_PRINTER_LIST:
      return {
        ...state,
        printerListIsLoading: action.isLoading,
      };

    case actionTypes.SET_ISLOADING_SCALE_LIST:
      return {
        ...state,
        scaleListIsLoading: action.isLoading,
      };

    case actionTypes.SET_PACKING_PRINTER:
      return {
        ...state,
        packingPrinter: action.printer,
      };

    case actionTypes.SET_PACKING_PRINTER2:
      return {
        ...state,
        packingPrinter2: action.printer,
      };

    case actionTypes.SET_PRINT_CARTON_RESULT:
      return {
        ...state,
        printCartonResult: action.result,
      };

    case actionTypes.SET_PRINT_DOCUMENT_RESULT:
      return {
        ...state,
        printDocumentResult: action.result,
      };

    case actionTypes.SET_PRINT_LABEL_END:
      return {
        ...state,
        printLabelEnd: action.message,
      };

    case actionTypes.SET_PRINT_LABEL_MSG:
      return {
        ...state,
        printLabelMessage: action.message,
      };

    case actionTypes.SET_PRINT_LABEL_RESPONSE:
      return {
        ...state,
        printLabelResponse: action.response,
      };

    case actionTypes.SET_PRINT_LABEL_RESULT:
      return {
        ...state,
        printLabelResult: action.result,
      };

    case actionTypes.SET_PRINT_MULTI_PKG_RESULT:
      return {
        ...state,
        printMultiPackageResult: action.result,
      };

    case actionTypes.SET_PRINT_SLIP_RESULT:
      return {
        ...state,
        printSlipResult: action.result,
      };

    case actionTypes.SET_PRINTER_LIST:
      return {
        ...state,
        printerList: action.printers,
      };

    case actionTypes.SET_PRINTER_LIST_LOADED:
      return {
        ...state,
        printerListLoaded: action.loaded,
      };

    case actionTypes.SET_PRINTSTATION_IS_OK:
      return {
        ...state,
        printstationIsOk: action.isOk,
      };

    case actionTypes.SET_SALES_PRINTING_HANG:
      return {
        ...state,
        printingIsHang: action.hang,
      };

    case actionTypes.SET_SCALE_LIST:
      return {
        ...state,
        scaleList: action.scales,
      };

    case actionTypes.SET_SCALE_LIST_LOADED:
      return {
        ...state,
        scaleListLoaded: action.loaded,
      };

    case actionTypes.SET_SALES_SCALE:
      return {
        ...state,
        scale: action.scale,
      };

    case actionTypes.SET_SALES_SCALE_WEIGHT:
      return {
        ...state,
        scaleLB: action.weight.Lb,
        scaleOZ: action.weight.Oz,
      };

    case actionTypes.SET_SHIPMENT_ITEM:
      return {
        ...state,
        shipmentItem: action.item,
      };

    case actionTypes.SET_SHIPMENT_PRINTER:
      return {
        ...state,
        shipmentPrinter: action.printer,
      };

    case actionTypes.SET_SHIPMENT_PRINTER4x8:
      return {
        ...state,
        shipmentPrinter4x8: action.printer,
      };

    case actionTypes.SET_SHIPMENT_PRINTER_COTON:
      return {
        ...state,
        shipmentPrinterCoton: action.printer,
      };

    case actionTypes.SET_TEST_PRINT_MSG:
      return {
        ...state,
        testPrintMessage: action.message,
      };
    
    case actionTypes.SET_PRINT_PICK_LIST_STATUS:
      return {
        ...state,
        printPickListStatus: action.bitInt,
      }

    default:
      break;
  }

  return state;
};

export default reducer;
