import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Pick from 'components/Sales/Smart/Pick';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartPickScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.pick" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Pick(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <Pick />
    </ContentLayout>
  );
}
