import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, Input, Form, Select, Button, Row } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import {
  SetDefaultWarehouseDialog,
  ButtonIcon,
  message,
} from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import SetZeroDialog from './setZeroDialog';
import {
  fetchWarehouseProductList,
  resetInventory,
  inventoryResetZero,
} from 'services/inventory';
import { queryProductByKeyword } from 'services/product';
import { queryLocationsByKeyword } from 'services/warehouse';
import {
  getWarehouseIdFromCache,
  statItemsQuantity,
  getWarehouseCodeFromCache,
} from 'utils';
import { userProfiles } from 'utils/auth';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useTranslation } from 'react-i18next';


const { Text } = Typography;

export default function ResetInventory(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation()
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const transactionReasons = useSelector(
    (state: RootState) => state.admin.transactionReasons,
    shallowEqual
  );
  const profile = userProfiles.profile;
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<
    'scanLocation' | 'scanItems' | 'review' | 'result'
  >('scanLocation');
  const [tempItems, setTempItems] = useState('');
  const [items, setItems] = useState<any[]>([]);
  const [notes, setNotes] = useState<string>(
    `Set location by ${profile.email}`
  );
  const [reason, setReason] = useState<string>('CycleCount');
  const notesRef = useRef<any>();
  const itemsRef = useRef<any>();
  const [locationCode, setLocationCode] = useState<string>('');
  const [locationNum, setLocationNum] = useState<number>(-1);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [scanedQty, setScanedQty] = useState(0);
  const [inputType, /*setInputType*/] = useState<'single' | 'multiple'>('single')
  const [tempList, setTempList] = useState<{item: string, qty: number}[]>([]);
  const [dataInfo, setDataInfo] = useState({
    data: [],
    total: 0,
    current: 1,
    pageSize: 10,
  });
  // const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [zeroDialogVisible, setZeroDialogVisible] = useState<boolean>(false);
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);


  const [form] = Form.useForm()

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      key: 'upc',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
    },
  ];
  
  const columns1 = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
    },
  ];

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const resetQty = async () => {
    try {
      setFetching(true);
      const itemParams = items.map((item) => {
        return {
          SKU: item.sku,
          Quantity: item.quantity,
        };
      });
      const res = await resetInventory(warehouseId, locationNum, {
        LocationNotes: notes,
        Items: itemParams,
        Reason: reason,
      });
      setFetching(false);
      if (res) {
        fetchProductsByLocationCode(locationCode, 1, 10);
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const resetZero = async () => {
    try {
      setFetching(true);
      const res = await inventoryResetZero(warehouseId, locationNum, {
        LocationNotes: notes,
        Reason: 'Set Location to Zero',
      });
      setFetching(false);
      if (res) {
        fetchProductsByLocationCode(locationCode, 1, 10);
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const checkLocationCode = async (code: string) => {
    try {
      const res = await queryLocationsByKeyword(warehouseId, code, false);
      if (res) {
        setLocationCode(res.locationCode);
        setLocationNum(res.locationNum);
        fetchProductsByLocationCode(code, 1, 10, true);
      }
    } catch (error) {}
  };

  const getValidQty = (data: any[]) => {
    let qty = 0;
    data.map((item: any) => {
      qty += item.quantity;
      return true;
    });
    return qty;
  };

  const checkSkus = async () => {
    try {
      setFetching(true);
      const tempItems = itemsRef.current.resizableTextArea.textArea.value || '';
      const items = statItemsQuantity(tempItems.split('\n'));
      const notes = notesRef.current.resizableTextArea.textArea.value || '';
      let dataSource: {
        fulfillmentProductNum: number;
        sku: string;
        upc: string;
        quantity: number;
      }[] = [];
      const keys = Object.keys(items);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const idx = dataSource.findIndex(j => j.sku ===key || j.upc === key)
        if(idx > -1) {
          dataSource[idx] = {
            ...dataSource[idx],
            quantity:dataSource[idx].quantity + items[key]
          }
        } else {
          const product = await queryProductByKeyword(key);
          if (product.length > 0) {
            dataSource.push({
              fulfillmentProductNum: product[0].fulfillmentProductNum,
              sku: product[0].sku || '',
              upc: product[0].upc || '',
              quantity: items[key],
            });
          }
        }
      }
      setFetching(false);
      if (tempItems.trim()) {
        if (dataSource.length === 0) {
          return message.warning({ content: t("smart.audit.locationCount.noItemsMatched") });
        } else {
          setItems(dataSource);
          setNotes(notes);
          setTempItems(tempItems);
          setStep('review');
        }
      } else {
        setZeroDialogVisible(true);
        setNotes(notes);
      }
    } catch (error) {}
  };

  const checkSkus1 = async () => {
    try {
      setFetching(true);

      let dataSource: {
        fulfillmentProductNum: number;
        sku: string;
        upc: string;
        quantity: number;
      }[] = [];
      for (let i = 0; i < tempList.length; i++) {
        const idx = dataSource.findIndex(j => j.sku ===tempList[i].item || j.upc === tempList[i].item)
        if(idx > -1) {
          dataSource[idx] = {
            ...dataSource[idx],
            quantity:dataSource[idx].quantity + tempList[i].qty
          }
        } else {
          const product = await queryProductByKeyword(tempList[i].item);
          if (product.length > 0) {
            dataSource.push({
              fulfillmentProductNum: product[0].fulfillmentProductNum,
              sku: product[0].sku || '',
              upc: product[0].upc || '',
              quantity: tempList[i].qty,
            });
          }
        }
      }
      setFetching(false);
      console.log(tempList)
      console.log(dataSource)
      if (tempList.length !== 0) {
        if (dataSource.length === 0) {
          return message.warning({ content: t("smart.audit.locationCount.noItemsMatched") });
        } else {
          setItems(dataSource);
          setNotes(notes);
          setStep('review');
        }
      } else {
        setZeroDialogVisible(true);
        setNotes(notes);
      }
    } catch (error) {}
  };

  //{"LocationNotes":"new location note 01","Items":[{"FulfillmentProductNum":13960,"Quantity":1}]}:
  const fetchProductsByLocationCode = async (
    code: string,
    current: number,
    pageSize: number,
    toScanItem?: boolean
  ) => {
    try {
      const query = {
        $skip: (current - 1) * pageSize,
        $top: pageSize,
        $sortBy: 'sku',
        $calculateTotal: true,
        $count: true,
        $filter: `LocationCode eq '${code}'`,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setFetching(false);
      setTotalQty(res.totalQuantity);
      setDataInfo({
        data: res.data,
        total: res.count,
        current,
        pageSize,
      });
      if (toScanItem) {
        setStep('scanItems');
      } else {
        setNotes(`Set location by ${profile.email}`);
        setTempItems('');
        setScanedQty(0);
        setStep('result');
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const onKeyDown = (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      form.setFieldsValue({
        scanValue: undefined,
      });
      evt.preventDefault();
      if (evt.target.value) {
          const index = tempList.findIndex((i) => i.item === evt.target.value);
          if (index > -1) {
            const temp = [...tempList];
            temp[index] = {
              item: evt.target.value,
              qty: temp[index].qty + 1,
            };
            setTempList([...temp]);
          } else {
            setTempList([
              {
                item: evt.target.value,
                qty: 1,
              },
              ...tempList,
            ]);
          }
          setScanedQty(prev=> prev + 1)
      }
    }
  };

  /* eslint-disable */
  useEffect(() => {
    if (step === 'scanLocation') {
      setDataInfo({
        data: [],
        total: 0,
        current: 1,
        pageSize: 10,
      });
      setItems([]);
      setNotes(`Set location by ${profile.email}`);
      setTempItems('');
      setScanedQty(0);
      setLocationNum(-1);
      setLocationCode('');
      setTotalQty(0);
      setTempList([])
    }
  }, [step]);

  /* eslint-enable */

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.audit.locationCount.scanLocation")} 
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={
                (value) => checkLocationCode(value)
                // fetchProductsByLocationCode(value, 1, 10, true)
              }
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanItems' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.location")} : <Text>{locationCode}</Text>
            </Text>
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.totalQty")} : <Text>{totalQty}</Text>
            </Text>
          </SmartRow>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'start',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              {inputType === 'multiple' &&<Form.Item label={<Text strong>{t("smart.audit.locationCount.items")} :</Text>}>
                <Input.TextArea
                  defaultValue={tempItems}
                  ref={itemsRef}
                  rows={8}
                  onChange={(e) => {
                    if (e.target.value) {
                      const temp = e.target.value.split('\n');
                      if (temp[temp.length - 1]) {
                        setScanedQty(temp.length);
                      } else {
                        setScanedQty(temp.length - 1);
                      }
                    } else {
                      setScanedQty(0);
                    }
                  }}
                />
              </Form.Item>}

              {inputType==='single' &&    
              <Form form={form}>             
                    <Form.Item label={<Text strong>{t("smart.audit.locationCount.scanItem")}</Text>} name="scanValue">
                      <Input
                        autoFocus
                        onKeyDown={onKeyDown}
                      />
                    </Form.Item> </Form>  }
              {inputType==='single' && <Table
                rowKey="item"
                scroll={{ y: 120 }}
                style={{marginBottom: 4}}
                dataSource={tempList}
                columns={columns1}
                pagination={false}
              />}
              <Form.Item
                label={
                  <Text strong style={{ fontSize: 14 }}>
                    {t("smart.audit.locationCount.notes")} :
                  </Text>
                }
              >
                <Input.TextArea defaultValue={notes} ref={notesRef} rows={3} />
              </Form.Item>

              <Form.Item
                label={
                  <Text strong style={{ fontSize: 14 }}>
                    {t("smart.audit.locationCount.reason")} :
                  </Text>
                }
              >
                <Select
                  defaultValue={reason}
                  onChange={(value: string) => setReason(value)}
                >
                  {transactionReasons
                    .filter((i) => i.type === 4)
                    .map((item) => (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    ))}
                </Select>{' '}
              </Form.Item>
            </div>
            <div style={{ width: 120, padding: 10 }}>
              <Button
                type="primary"
                style={{ width: '100%', fontSize: 16 }}
                onClick={() => inputType === 'multiple' ? checkSkus(): checkSkus1()}
              >
                {t("smart.audit.locationCount.check")} 
              </Button>
              <Row style={{ marginTop: 6 }}>
                <Text strong style={{ width: '100%', fontSize: 16 }}>
                {t("smart.audit.locationCount.scanedQty")} :
                </Text>
              </Row>
              <Text
                strong
                style={{
                  width: '100%',
                  fontSize: 16,
                  marginTop: 6,
                  color: 'blue',
                }}
              >
                {scanedQty}
              </Text>
            </div>
          </div>
          <SmartBackButton
            onClick={() => {
              setStep('scanLocation');
            }}
          />
        </>
      )}

      {step === 'review' && (
        <>
          <SmartSpin loading={fetching} />

          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.scanedQty")} : <Text>{scanedQty}</Text>
            </Text>
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.validQty")} : <Text>{getValidQty(items)}</Text>
            </Text>
          </SmartRow>
          <Table
            size="small"
            rowKey="fulfillmentProductNum"
            bordered
            columns={columns}
            dataSource={items}
            pagination={false}
            style={{ marginTop: 10, marginBottom: 10 }}
          />
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16, whiteSpace: 'pre-line' }}>
            {t("smart.audit.locationCount.reason")} :{' '}
              <Text>
                {items.length === 0 ? 'Set Location to Zero' : reason}
              </Text>
            </Text>
          </SmartRow>
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16, whiteSpace: 'pre-line' }}>
            {t("smart.audit.locationCount.notes")} : <Text>{notes}</Text>
            </Text>
          </SmartRow>
          <SmartRow
            justify="center"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            {items.length === 0 ? (
              <Button type="primary" danger onClick={() => resetZero()}>
                {t("smart.audit.locationCount.resetInventoryToZero")}
              </Button>
            ) : (
              <Button type="primary" onClick={() => resetQty()}>
                {t("smart.audit.locationCount.setSkuInventory")} 
              </Button>
            )}
          </SmartRow>
          <SmartBackButton
            onClick={() => {
              setStep('scanItems');
            }}
          />
        </>
      )}

      {step === 'result' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.location")} : <Text>{locationCode}</Text>
            </Text>
            <Text style={{ fontSize: 16 }}>
            {t("smart.audit.locationCount.newQty")} : <Text>{totalQty}</Text>
            </Text>
          </SmartRow>
          <SmartRow
            justify="center"
            style={{ marginBottom: 10, marginTop: 10 }}
          >
            <ButtonIcon className="bi-emoji-smile" style={{ fontSize: 16 }} />
            <Text
              style={{ marginLeft: 5, fontSize: 16, color: GreenColor }}
            >{t("smart.audit.locationCount.successMessage")} </Text>
          </SmartRow>
          <Table
            size="small"
            rowKey="fulfillmentProductNum"
            bordered
            columns={columns}
            dataSource={dataInfo.data}
            loading={fetching}
            pagination={{
              current: dataInfo.current,
              pageSize: dataInfo.pageSize,
              total: dataInfo.total,
            }}
            onChange={(pagination, filters, sorter) => {
              console.log(pagination);
              fetchProductsByLocationCode(
                locationCode,
                pagination.current || 1,
                pagination.pageSize || 10
              );
            }}
            style={{ marginTop: 10, marginBottom: 10 }}
          />

          <SmartRow justify="center" style={{ marginBottom: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setStep('scanLocation');
              }}
            >
              {t("smart.audit.locationCount.nextLocation")} 
            </Button>
          </SmartRow>

          <SmartBackButton
            onClick={() => {
              setStep('scanLocation');
            }}
          />
        </>
      )}

      {zeroDialogVisible && locationCode && (
        <SetZeroDialog
          locationCode={locationCode}
          onSubmit={(v: boolean) => {
            if (v) {
              setZeroDialogVisible(false);
              setStep('review');
            } else {
              setZeroDialogVisible(false);
            }
          }}
        />
      )}

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
