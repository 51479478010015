import React, { useState } from 'react';
import {
  Button,
  Space,
  Card,
  Dropdown,
  Menu,
  Row,
  Input,
  // Switch,
  Select,
  DatePicker,
  Form,
  Col,
  Typography,
} from 'antd';
import { ExportOutlined, ImportOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  DataGridPlus,
  ImportDialog,
  HeaderTitle,
  ColumnDataType,
  ClearButton,
  FormLabel,
  SectionWrapper,
  CopyComponent,
  CopySpan,
  TablesFilter,
} from 'components/common';
import { DEFAULT_DATE_FORMAT } from 'constants/config';
import {
  fetchImportFileJobs,
  fetchOperation,
  exportFile,
  downloadErrorLog,
  downloadTemplate,
} from 'services/files';
import { byteConvert, convertToUtcTime } from 'utils';
// import PoImport from './ImportPODialog';

const { RangePicker } = DatePicker;
const dateFormat = 'MM/DD/YYYY';
const { Text } = Typography;

type ColumnRenderType = {
  data: FileJobRow;
};
// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = React.useState(false);
  const [importDialogVisible, setImportDialogVisible] = React.useState(false);
  const [data, setData] = React.useState<FileJobRow[]>([]);
  const [total, setTotal] = React.useState(0);
  const limitRef = React.useRef<number>(10);
  const skipRef = React.useRef<number>(0);
  const [operation, setOperation] = React.useState<OperationType[]>([]);
  const [selectedOperation, setSelectedOperation] =
    React.useState<OperationType>();
  const [inited, setInited] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [limit, setLimit] = React.useState<number>(5);
  // const [skip, setSkip] = React.useState<number>(0);
  // const [sortInfo, setSortInfo] = React.useState<TypeSortInfo>();

  const [form] = Form.useForm();

  const columns = [
    {
      name: 'operationName',
      header: 'Type',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;
      //   const tempOperation = operation.filter((i) => i.key === data.operation);
      //   return tempOperation.length > 0 ? tempOperation[0].value : '';
      // },
    },
    {
      name: 'createDate',
      header: 'Submitted Time',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      sortable: true,
    },
    {
      name: 'originalFileName',
      header: 'File Name',
      userSelect: true,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <CopyComponent value={data.originalFileName}>
            <Text
              ellipsis={{ tooltip: data.originalFileName }}
              style={{
                cursor: 'pointer',
                color: '#1976d2',
                width: `calc(100% - 14px)`,
              }}
              onClick={() => downloadFile(data)}
            >
              {data.originalFileName}
            </Text>
          </CopyComponent>
        );
      },
    },
    {
      name: 'fileSize',
      header: 'File Size',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return <CopySpan inGrid value={byteConvert(data.fileSize)} />;
      },
    },
    {
      name: 'createBy',
      header: 'User',
      userSelect: true,
      defaultFlex: 1,
      // render: (value: ColumnRenderType) => {
      //   const { data } = value;
      //   return data.createBy;
      // },
    },
    {
      name: 'processStatus',
      header: 'Status',
      sortable: false,
      defaultFlex: 1,
      renderEnum: {
        0: 'Queue',
        1: 'Pending',
        2: 'Processing',
        3: 'Complete',
        4: 'Completed with Error',
        5: 'Failed Validation',
        6: 'Call for Help',
        7: 'Template Error',
        8: 'Failed after Max Retry'
      },
      render: (value: ColumnRenderType) => {
        const { data } = value;
        switch (data.processStatus) {
          case 0:
            return <CopySpan inGrid value={'Queue'} />;
          case 1:
            return <CopySpan inGrid value={'Pending'} />;
          case 2:
            return <CopySpan inGrid value={'Processing'} />;
          case 3:
            return <CopySpan inGrid value={'Complete'} />;
          case 4:
            return (
              <CopyComponent value={'Completed with Error'}>
                <Text
                  ellipsis={{ tooltip: 'Completed with Error' }}
                  style={{
                    cursor: 'pointer',
                    color: '#1976d2',
                    width: `calc(100% - 14px)`,
                  }}
                  onClick={() => downloadLog(data)}
                >
                  Completed with Error
                </Text>
              </CopyComponent>
            );
          case 5:
            return (
              <CopyComponent value={'Failed Validation'}>
                <Text
                  ellipsis={{ tooltip: 'Failed Validation' }}
                  style={{
                    cursor: 'pointer',
                    color: '#1976d2',
                    width: `calc(100% - 14px)`,
                  }}
                  onClick={() => downloadLog(data)}
                >
                  Failed Validation
                </Text>
              </CopyComponent>
            );
          case 6:
            return <CopySpan inGrid value={'Call for Help'} />;
          case 7:
            return <CopySpan inGrid value={'Template Error'} />;
          case 8:
            return <CopySpan inGrid value={'Failed after Max Retry'} />;
          default:
            return <CopySpan inGrid value={'Undefined'} />;
        }
      },
    },
  ];

  const downloadFile = async (data: FileJobRow) => {
    try {
      const res = await exportFile({
        fileoperation: data.operation,
        filesystemname: data.systemFileName,
      });
      const content = res;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = data.originalFileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        //navigator.msSaveBlob(blob, data.originalFileName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadLog = async (data: FileJobRow) => {
    try {
      const res = await downloadErrorLog({
        // fileoperation: data.operation,
        fileNum: data.fileNum,
      });
      const content = res;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = data.systemFileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        //navigator.msSaveBlob(blob, data.systemFileName);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadTemplateFile = async (operation: OperationType) => {
    try {
      const res = await downloadTemplate(operation?.key);
      const content = res;
      const blob = new Blob([content]);
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = `${operation?.value}-template.xlsx`;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        //navigator.msSaveBlob(blob, `${operation?.value}-template.xlsx`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOperations = React.useCallback(async () => {
    try {
      const nextOperations = await fetchOperation();
      setOperation(nextOperations);
      setInited(true);
      // const temp: { [key: string]: string } = {};
      // nextOperations.map((item: OperationType) => {
      //   temp[`${item.key}`] = item.value;
      //   return true;
      // });
      // console.log(temp);
      // setOperationKeyValues(temp);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getFileList = React.useCallback(
    async (fromSearchButton?: boolean) => {
      if (!inited) return;
      setLoading(true);
      // const nextOperations = await fetchOperation();
      // setOperation(nextOperations);
      const filterParams = await form.getFieldsValue();
      let filter = '';
      if (filterParams) {
        let [startTime, endTime] = ['', ''];

        if (filterParams.SubmitedDate) {
          const dFormat = DEFAULT_DATE_FORMAT;
          startTime = convertToUtcTime(
            `${filterParams.SubmitedDate[0].format(dFormat)} 00:00:00`
          );
          endTime = convertToUtcTime(
            `${filterParams.SubmitedDate[1].format(dFormat)} 16:00:00`
          );
        }

        /*const startTimeFilter = filterParams.SubmitedDate
        ? `CreateDate ge '${moment(filterParams.SubmitedDate[0]).format(
            'YYYY-MM-DD'
          )} 00:00:00'`
        : '';
      const endTimeFilter = filterParams.SubmitedDate
        ? `CreateDate le '${moment(filterParams.SubmitedDate[1]).format(
            'YYYY-MM-DD'
          )} 16:00:00'`
        : '';*/
        const startTimeFilter = filterParams.SubmitedDate
          ? `CreateDate ge '${startTime}'`
          : '';
        const endTimeFilter = filterParams.SubmitedDate
          ? `CreateDate le '${endTime}'`
          : '';
        const statusFilter = (filterParams.status || filterParams.status === 0)
          ? `processStatus eq ${filterParams.status}`
          : '';
        const userFilter = filterParams.user
          ? `UserEmail eq '${filterParams.user}`
          : '';
        if (startTimeFilter) {
          filter = startTimeFilter;
        }
        if (endTimeFilter) {
          filter = filter
            ? `${filter} and ${endTimeFilter}`
            : `${endTimeFilter}`;
        }
        if (statusFilter) {
          filter = filter ? `${filter} and ${statusFilter}` : `${statusFilter}`;
        }
        if (userFilter) {
          filter = filter ? `${filter} and ${userFilter}` : `${userFilter}`;
        }
      }
      const params = {
        operatetype: 0,
        $skip: fromSearchButton ? 0 : skipRef.current,
        $top: limitRef.current,
        $sortBy: 'FileNum desc',
        $count: true,
        $filter: filter,
      };
      try {
        const res = await fetchImportFileJobs(params);
        setLoading(false);
        if (res.isSuccess) {
          let { data, count } = res.data;
          data = data.map((row: any) => {
            const ops = operation.filter(
              (item: any) => item.key === row.operation
            );
            const operationName = ops.length > 0 ? ops[0].value : '';
            return {
              ...row,
              operationName,
            };
          });
          if (fromSearchButton) {
            skipRef.current = 0;
          }
          setData(data);
          setTotal(count);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [form, operation, inited]
  );

  const menu = (
    <Menu>
      {operation.map((item) => (
        <Menu.Item key={item.key}>
          <Button
            style={{ width: '100%', textAlign: 'left' }}
            type="text"
            onClick={() => {
              setSelectedOperation(item);
              setImportDialogVisible(true);
            }}
          >
            {item.name}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  const templateMenu = (
    <Menu>
      {operation.map((item) => (
        <Menu.Item key={item.key}>
          <Button
            style={{ width: '100%', textAlign: 'left' }}
            type="text"
            onClick={() => {
              downloadTemplateFile(item);
            }}
          >
            {item.name}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  /* eslint-disable */
  React.useEffect(() => {
    getOperations();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Import', 'File Import Center']}>
        <Space>
          <Dropdown overlay={templateMenu} trigger={['hover']}>
            <Button icon={<ExportOutlined />}>Download Template</Button>
          </Dropdown>
          <Dropdown overlay={menu} trigger={['hover']}>
            <Button type="primary" icon={<ImportOutlined />}>Import Files</Button>
          </Dropdown>
        </Space>
      </HeaderTitle>

      <Form
        form={form}
        {...formItemLayout}
        style={{ justifyContent: 'center' }}
      >
        <Card style={{ width: '100%', maxWidth: 1890 }}>
          <Row>
            <Col md={8} sm={24}>
              <Form.Item
                name="SubmitedDate"
                initialValue={[
                  moment().add(-1, 'day').startOf('day'),
                  moment().add(1, 'day').endOf('day'),
                ]}
                label={<FormLabel>Submited Date</FormLabel>}
              >
                <RangePicker
                  format={dateFormat}
                  style={{ width: '100%' }}
                  showTime
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={24}>
              <Form.Item name="user" label={<FormLabel>User</FormLabel>}>
                <Input placeholder="input a user" />
              </Form.Item>
            </Col>
            <Col md={8} sm={24}>
              <Form.Item name="status" label={<FormLabel>Status</FormLabel>}>
                <Select placeholder="Select an option">
                  <Select.Option value={0}>Queue</Select.Option>
                  <Select.Option value={1}>Pending</Select.Option>
                  <Select.Option value={2}>Processing</Select.Option>
                  <Select.Option value={3}>Complete</Select.Option>
                  <Select.Option value={4}>Completed with Error</Select.Option>
                  <Select.Option value={5}>Failed Validation</Select.Option>
                  <Select.Option value={6}>Call for Help</Select.Option>
                  <Select.Option value={7}>Template Error</Select.Option>
                  <Select.Option value={8}>
                    Failed after Max Retry
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} justify="center">
            <Space>
              <Button
                type="primary"
                loading={loading}
                onClick={() => getFileList(true)}
              >
                <SearchOutlined />
                Search
              </Button>
              <ClearButton form={form} />
              <Button onClick={() => getFileList()}>
                <ReloadOutlined spin={loading} />
                Refresh
              </Button>
            </Space>
          </Row>
        </Card>
      </Form>
      <SectionWrapper style={{ marginTop: 20 }}>
        {/* <Card style={{ marginTop: 10 }}> */}
        <TablesFilter
          columns={columns}
          dataSource={data}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          name="locationReport"
          idProperty="fileJobNum"
          autoWith={false}
          // skip={skipRef.current}
          loading={loading}
          columns={columns}
          dataSource={filteredData}
          count={total}
          pagination
          onChange={(limit, skip) => {
            limitRef.current = limit;
            skipRef.current = skip;
            getFileList();
          }}
          {...({} as any)}
        />
      </SectionWrapper>
      {/* </Card> */}
      {importDialogVisible && (
        <ImportDialog
          onRefesh={() => getFileList()}
          onHide={() => setImportDialogVisible(false)}
          operation={selectedOperation}
        />
      )}
    </>
  );
};
