import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import ReturnList from 'components/Return/ReturnList';

function WarehouseTransfer() {
  return (
    <ContentLayout>
      <SiteContent>
        <ReturnList />
      </SiteContent>
    </ContentLayout>
  );
}

export default WarehouseTransfer;
