import { CopyOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import React, { useState } from 'react';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import SpaceCell from './SpaceCell';
import { DATAGRID_CELL_ICON_WIDTH1 } from 'constants/config';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';

type OrderDetailsCellProps = {
  cellText: string;
  rowData: any;

};
export default function OrderDetailsCell(props: OrderDetailsCellProps): JSX.Element {
  const { cellText, rowData } = props;
  // const [loading, setLoading] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const [order, setOrder] = useState<any>();

  const handleClickCell = () => {
    console.log('rowdata = ', rowData);
    if (rowData.fulfillmentOrderId && rowData.fulfillmentOrderNum) {
      setOrder(rowData);
      setDetailVisible(true);
    }
  }

  // if (loading) {
  //   return (
  //     <>
  //       <Spin />
  //     </>
  //   );
  // }
  return (
    <>
      <SpaceCell
        icon={
          <Tooltip
            placement="top"
            title="Copy"
            trigger={['hover', 'click']}
          >
            <CopyOutlined
              onClick={() => {
                copy(cellText);
                message.info(
                  `"${cellText}" has been copied to clipboard`
                );
              }}
              style={{ color: theme['@info-color'] }}
            />
          </Tooltip>
        }
        iconWidth={DATAGRID_CELL_ICON_WIDTH1}
        onTextClick={() => {
          handleClickCell();
        }}
        text={cellText}
        textIsButton
      />
      {detailVisible && order && (
        <OrderDetail
          allowPopupWindow
          isHistory={false}
          fulfillmentOrderId={order.fulfillmentOrderId}
          fulfillmentOrderNum={order.fulfillmentOrderNum}
          onClose={() => setDetailVisible(false)}
        />
      )}
    </>
  );
}