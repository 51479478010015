import React, { ReactNode } from 'react';
import { Button, ButtonProps, Typography } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';

const { Text } = Typography;
interface FormSubmitButtonProps extends ButtonProps {
  id?: string;
  loading?: boolean;
  changed: boolean;
  edited?: boolean;
  onSubmit: () => void;
  onEdit?: () => void;
  children?: ReactNode;
}
/**
 * Extend the button component
 * Feature:Edit->Save change status
 */
export default function FormSubmitButton(
  props: FormSubmitButtonProps
): JSX.Element {
  const {
    id,
    children,
    changed,
    onSubmit,
    edited = true,
    onEdit,
    ...other
  } = props;
  if (edited) {
    return (
      <Button
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        id={id}
        disabled={!changed}
        key="submit"
        type="primary"
        onClick={() => {
          onSubmit();
        }}
      >
        {typeof children !== 'undefined' && (
          <Text style={{ color: changed ? 'white' : 'rgb(175,175,175)' }}>
            {children}
          </Text>
        )}
        {typeof children === 'undefined' && (
          <>
            <SaveOutlined />
            <Text
              style={{
                color: changed ? 'white' : 'rgb(175,175,175)',
                marginRight: 3,
              }}
            >
              Save
            </Text>
          </>
        )}
      </Button>
    );
  }
  return (
    <Button
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      id={id}
      type="primary"
      onClick={() => {
        if (typeof onEdit === 'function') {
          onEdit();
        }
      }}
    >
      <EditOutlined />
      <span>Edit</span>
    </Button>
  );
}
FormSubmitButton.defaultProps = {
  edited: undefined,
  onEdit: undefined,
  children: undefined,
};
