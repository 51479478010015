import React, { useState, useCallback, useEffect } from 'react';
import { Form, Row, Typography, Spin, Modal, Button, Space } from 'antd';
// import { updateProductQuantity } from 'services/product';
import { inventoryMove } from 'services/inventory';
import WarehouseLocationSearchBox from '../Warehouse/WarehouseLocationSearchBox';
import { FormLabel } from 'components/common';
import { message } from 'components/common';
import {
  USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
  SERVER_ERR_MSG_DEFAULT_DISPLAY_DURATION,
} from 'constants/config';
import { CloseOutlined, RetweetOutlined } from '@ant-design/icons';

const { Text } = Typography;

/**
 * Interface for the properties of the component.
 */
interface MoveItemDialogProps {
  visible: boolean;
  onHide: () => void;
  onSuccess?: Function;
  product: InventoryProductRow;
}
// eslint-disable-next-line
export default (props: MoveItemDialogProps) => {
  const { visible, onHide, onSuccess, product } = props;
  const [locationId, setLocationId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const clearState = () => {
    setLocationId(-1);
    setTotalQuantity(0);
  };

  useEffect(() => {
    // note: detect whether the dialog is visible is important
    if (visible) {
      console.log('set', visible, product.warehouseId);
      setTotalQuantity(product.quantity);
    }
  }, [product, visible]);
  /**
   * handle submit event
   * Note: there is a issue. It need to check whether the selected location is the same with the original location.
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    if (locationId /*&& detail*/) {
      setLoading(true);
      try {
        // const update = [
        //   {
        //     FulfillmentProductNum: product.productNum,
        //     WarehouseNum: product.warehouseId,
        //     LocationNum: product.locationId,
        //     Quantity: product.quantity,
        //     SKU: product.sku,
        //     TransactionType: 3,
        //   },
        //   {
        //     FulfillmentProductNum: product.productNum,
        //     WarehouseNum: product.warehouseId,
        //     LocationNum: locationId,
        //     Quantity: product.quantity,
        //     SKU: product.sku,
        //     TransactionType: 2,
        //   },
        // ];
        //const res = await updateLocation(product.productNum, detail, warehouseId, locationId);
        const res = await inventoryMove(
          product.warehouseId,
          product.warehouseNum,
          product.locationNum,
          product.productNum,
          locationId,
          product.quantity
        );
        setLoading(false);
        if (res) {
          message.success({
            content: 'Move item to new location success',
            duration: USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
          });
          if (onSuccess && 'function' === typeof onSuccess) {
            onSuccess();
          }
          document.body.style.overflowY = 'unset';
          onHide();
        } else {
          message.error({
            content: 'Move item to new location failed',
            duration: SERVER_ERR_MSG_DEFAULT_DISPLAY_DURATION,
          });
        }
      } catch (e) {
        console.log('failed to update product location', e);
        setLoading(false);
      }
    }
  }, [product, locationId, form, onHide, onSuccess]);

  const onDialogClose = () => {
    // document.body.style.overflowY = 'unset';
    clearState();
  };

  const onSelectLocation = (id: number) => {
    setLocationId(id);
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  return (
    <>
      <Modal
        title="Move Item to New Location"
        centered
        visible={visible}
        width={650}
        destroyOnClose={true}
        onCancel={() => {
          document.body.style.overflowY = 'unset';
          onHide();
        }}
        getContainer={false}
        afterClose={onDialogClose}
        footer={
          <Row justify="end">
            <Space>
              <Button key="submit" type="primary" onClick={handleSubmit} icon={<RetweetOutlined />}>
                Move
              </Button>
              <Button
                onClick={() => {
                  document.body.style.overflowY = 'unset';
                  onHide();
                }}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        }
      >
        <Spin spinning={loading}>
          <Row>
            <Text>
              Enter location to which you want to move item with quantity '
              {totalQuantity}'.
            </Text>
          </Row>
          <Space>
            <Text>Current Location: </Text>
            <Text strong>{product.locationCode}</Text>
          </Space>
          <Form {...layout} form={form} style={{ marginTop: 20 }}>
            <Form.Item
              name="warehouse"
              label={<FormLabel>Warehouse</FormLabel>}
            >
              <Text>{product.warehouseCode}</Text>
            </Form.Item>

            <Form.Item
              name="location"
              label={<FormLabel>New Location</FormLabel>}
              rules={[{ required: true, message: 'Please select location!' }]}
            >
              <WarehouseLocationSearchBox
                warehouseNum={product.warehouseNum}
                excludedLocationList={[product.locationNum]}
                onChange={onSelectLocation}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
