import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, message, ColumnDataType } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import { fetchTransferLines, closeTransfer } from 'services/inventory';
import { getWarehouseIdFromCache } from 'utils';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  transfer: any;
  onRefresh: () => void;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onRefresh, transfer } = props;
  const [loading, setLoding] = useState(false);
  const [saveLoaing, setSaveLoaidng] = useState(false);
  const [data, setData] = useState([]);
  // const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  // const [selected, setSelected] = React.useState<any>({});

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleClose = useCallback(async () => {
    try {
      setSaveLoaidng(true);
      const res = await closeTransfer(
        getWarehouseIdFromCache(transfer.warehouseNum),
        transfer.transferNum
      );
      setSaveLoaidng(false);
      if (res) {
        message.success({ content: 'closed successfully' });
        onRefresh();
      }
    } catch (error) {
      setSaveLoaidng(false);
    }
  }, [onRefresh, transfer]);

  const getData = useCallback(async () => {
    try {
      setLoding(true);
      const res = await fetchTransferLines(
        getWarehouseIdFromCache(transfer.warehouseNum),
        transfer.transferNum
      );
      setData(res);
      setLoding(false);
    } catch (error) {
      setLoding(false);
    }
  }, [transfer]);

  const columns = [
    // {
    //   name: 'fulfillmentReceiveNum',
    //   header: 'Receive Number',
    //   showColumnMenuTool: true,
    //   defaultFlex: 1,
    // },
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Name',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'transferQty',
      header: 'Transfer Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'shippedQty',
      header: 'Shipped Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Shipped Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      name: 'createBy',
      header: 'Shipped By ',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  useEffect(() => {
    if (transfer) {
      getData();
    }
  }, [getData, transfer]);

  return (
    <Modal
      title={`Complete Transfer - ${transfer.batchNumber}`}
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              disabled={
                transfer.warehouseTransferStatus === 2 || data.length === 0
              }
              loading={saveLoaing}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to complete this transfer?',
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: () => {
                    handleClose();
                  },
                });
              }}
              icon={<SaveOutlined />}
            >
              {saveLoaing ? 'Completing' : 'Complete Transfer'}
            </Button>
            <Button onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        loading={loading}
        dataSource={data}
        idProperty="fulfillmentTransferLineNum"
        pagination="local"
        // checkboxColumn
        // checkboxOnlyRowSelect={true}
        // selected={selected}
        // onSortInfoChange={() => setSelected({})}
        // onChange={() => setSelected({})}
        // onSelectionChange={(props) => {
        //   const { selected, data, unselected } = props;
        //   setSelected(selected);
        //   if (selected === true && !unselected) {
        //     setSelectedRow(data as any);
        //   } else {
        //     if (!unselected) {
        //       setSelectedRow(Object.values(selected as any));
        //     } else {
        //       setSelectedRow((prev) => {
        //         return prev.filter(
        //           (item) =>
        //             item.channelAccountNum !== (data as any).channelAccountNum
        //         );
        //       });
        //     }
        //   }
        // }}
        {...({} as any)}
      />
    </Modal>
  );
};
