import React, { useEffect, useRef, useState } from "react";
import { Input, InputProps } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';

type TablesFilterProps = {
  dataSource: any[];
  setFilteredData: (filteredData: any[]) => void;
  columns: any[];
  inputProps?: InputProps;
  width?: number | string;
  style?: any;
}
const TablesFilter = (props: TablesFilterProps) => {
  const { dataSource = [], setFilteredData, columns, inputProps, width, style = {} } = props;
  const [filterValue, setFilterValue] = useState<string>();
  const ref = useRef<any>(null);

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  useEffect(() => {
    try {
      if (filterValue) {
        const temp: any[] = [];
        dataSource.forEach(item => {
          for (const col of columns) {
            const val = col.renderEnum ? col.renderEnum[item[col.name]] : item[col.name];
            if (val) {
              if ((val.toString() || '').toLowerCase().indexOf((filterValue || '').trim().toLowerCase()) > -1) {
                temp.push(item);
                break;
              }
            }
          }
        })
        setFilteredData([...temp]);
      } else {
        setFilteredData([...dataSource]);
      }
    } catch (error) {
      console.log('error = ', error);
    }
    // eslint-disable-next-line
  }, [filterValue]);

  useEffect(() => {
    ref.current.setState({ value: '' });
    setFilteredData(dataSource);
    // eslint-disable-next-line
  }, [dataSource])

  return (
    <Input
      ref={ref}
      onChange={e => debounceFn(e.target.value)}
      {...inputProps}
      prefix={<FilterOutlined />}
      style={{ width: width || 150, ...style }}
      placeholder="Filter"
      allowClear
    />
  )
}

export default TablesFilter;