import React from 'react';
import { Button, Space, Card, Descriptions, Empty, Typography } from 'antd';
import { HeaderTitle } from 'components/common';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import CreateAddressDialog from './createDialog';
import { message as showMessage } from 'components/common';
import { DeleteButton } from 'components/common';
import { getAddressList, delAddress } from 'services/address';

const { Text } = Typography;

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = React.useState(false);
  const [createlVisible, setCreatelVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<AddressRow>();
  // const [addressBookType, setAddressBookType] = React.useState<number>();
  const [addressList, setAddressList] = React.useState<AddressRow[]>([]);

  const delAddressById = async (address: AddressRow) => {
    try {
      const res = await delAddress(address.addressNum);
      if (res) {
        showMessage.success({ content: 'Address deleted' });
        fetchAddressList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddressList = async () => {
    try {
      setLoading(true);
      const res = await getAddressList(0);
      setLoading(false);
      if (res.isSuccess) {
        setAddressList(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  /* eslint-disable */
  React.useEffect(() => {
    fetchAddressList();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Address Book']}>
        <Button
          type="primary"
          onClick={() => {
            setCurrent(undefined);
            setCreatelVisible(true);
          }}
        >
          <PlusOutlined />
          Add Address
        </Button>
      </HeaderTitle>

      <Card
        // title={
        //   <Space>
        //     <FormLabel>Filter By Address Book Type</FormLabel>
        //     <Select
        //       allowClear
        //       style={{ width: 300 }}
        //       onChange={(value) => setAddressBookType(Number(value))}
        //     >
        //       <Select.Option value={1}>Warehouse</Select.Option>
        //       <Select.Option value={2}>Supplier</Select.Option>
        //       <Select.Option value={3}>Channel Account</Select.Option>
        //       <Select.Option value={4}>Shipping Account</Select.Option>
        //       <Select.Option value={5}>
        //         Drop Ship Third Part Address
        //       </Select.Option>
        //     </Select>
        //   </Space>
        // }
        size="small"
        style={{ width: '100%' }}
        loading={loading}
      >
        {addressList.length > 0 ? (
          addressList.map((item) => (
            <Card
              key={item.addressNum}
              style={{ width: '100%', marginTop: 16 }}
              size="small"
              title={<Text strong>{item.addressAlias}</Text>}
              extra={
                <Space>
                  <Button
                    onClick={() => {
                      setCurrent(item);
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                  <DeleteButton
                    confirmContent={`name: ${item.addressAlias}`}
                    confirmTitle="Are you sure you want to delete the address?"
                    onClick={() => delAddressById(item)}
                  />
                </Space>
              }
            >
              <Descriptions bordered>
                <Descriptions.Item
                  labelStyle={{ width: 400 }}
                  label={
                    <Space direction="vertical">
                      <Text>{item.contactName || ''}</Text>
                      {item.street1 && <Text>{item.street1}</Text>}
                      {item.street2 && <Text>{item.street2}</Text>}
                      {item.street3 && <Text>{item.street3}</Text>}
                      <Text>{`${item.city || ''}, ${item.state || ''} ${item.postalCode || ''
                        }`}</Text>
                      <Text>{item.country || ''}</Text>
                    </Space>
                  }
                >
                  <Space direction="vertical">
                    <Text>{`Company: ${item.company || ''}`}</Text>
                    <Text>{`Phone: ${item.phone || ''}`}</Text>
                    <Text>{`Email: ${item.email || ''}`}</Text>
                    <Text>{`Remark: ${item.note || ''}`}</Text>
                  </Space>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          ))
        ) : (
          <Empty />
        )}
      </Card>

      {createlVisible && (
        <CreateAddressDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => fetchAddressList()}
          address={current}
        />
      )}
    </>
  );
};
