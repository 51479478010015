import React from 'react';
import { Button, Space } from 'antd';
import axios from 'axios';
import {
  message as showMessage,
  DeleteButton,
  HeaderTitle,
  DataGridPlus,
} from 'components/common';
import { queryTags, delTag } from 'services/orderTag';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import CreateDialog from './CreateDialog';
import EditDialog from './EditDialog';
import { sortByString } from 'utils';

type ColumnRenderType = {
  data: TagRow;
};
// eslint-disable-next-line
export default () => {
  const [tagList, setTagList] = React.useState<TagRow[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [createlVisible, setCreatelVisible] = React.useState(false);
  const [current, setCurrent] = React.useState<TagRow>();
  const [editVisible, setEditVisible] = React.useState(false);
  const requestCancelSource = axios.CancelToken.source();

  const columns = [
    {
      name: 'tagNum',
      header: 'ID',
      defaultVisible: false,
    },
    {
      name: 'tagName',
      header: 'Name',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
    },
    {
      name: 'tagDescription',
      header: 'Tag Description',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
    },
    {
      name: 'action',
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      autoWidth: false,
      minWidth: 250,
      textAlign: 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <>
            {
              <Space>
                <Button
                  onClick={() => {
                    setEditVisible(true);
                    setCurrent(data);
                  }}
                >
                  <EditOutlined />
                  Edit
                </Button>
                <DeleteButton
                  confirmContent={`name: ${data.tagName}`}
                  confirmTitle="Are you sure you want to delete the tag ?"
                  onClick={() => delTags(data)}
                />
              </Space>
            }
          </>
        );
      },
    },
  ];

  const getTagList = async () => {
    setLoading(true);
    try {
      const res = await queryTags(requestCancelSource.token);
      if (res.isSuccess) {
        setTagList(
          res.data.sort((a: any, b: any) => sortByString(a, b, 'tagName'))
        );
        setLoading(false);
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const delTags = async (tag: TagRow) => {
    setLoading(true);
    try {
      const res = await delTag(tag.tagNum, tag.tagName);
      if (res) {
        showMessage.success({
          content: `Deleted ${tag.tagName} Successfully`,
        });
        getTagList();
      } else {
        // showMessage.error({ content: 'Delete failed' });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /* eslint-disable */
  React.useEffect(() => {
    getTagList();
    return () => {
      requestCancelSource.cancel();
    };
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Order Tag']}>
        <Button type="primary" onClick={() => setCreatelVisible(true)}>
          <PlusOutlined />
          Create Order Tag
        </Button>
      </HeaderTitle>
      <DataGridPlus
        style={{ minHeight: 500 }}
        idProperty="tagNum"
        columns={columns}
        dataSource={tagList}
        loading={loading}
        pagination="local"
        {...({} as any)}
      />
      {createlVisible && (
        <CreateDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => getTagList()}
        />
      )}
      {editVisible && (
        <EditDialog
          visible
          onHide={() => setEditVisible(false)}
          onRefresh={() => getTagList()}
          tag={current}
        />
      )}
    </>
  );
};
