import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  // DeleteOutlined,
  // SettingFilled,
  // CaretDownOutlined,
  // EditOutlined,
  EllipsisOutlined,
  // UpOutlined,
  // DownOutlined,
  FileExcelOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import {
  // CopySpan,
  // DataGridPlus,
  FormLabel,
  TablesFilter,
  message,
  // ColumnDataType
} from '../../../components/common';
import {
  Card,
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Space,
  Modal,
  // Input,
  // Collapse,
  Empty,
  Typography,
} from 'antd';
import moment from 'moment';
// import styled from 'styled-components';
// import EditQtyDialog from './EditLineDialog';
import PrintDialog from './printDialog';
import PrintPrepack from './PrintPrepackSkuDialog';
import {
  // getPackProcessLines,
  // delPackProcessLine,
  getPackLines,
  cancelPack,
  completePack,
  getPackByNum,
} from 'services/prepack';
// import { fetchPrepackSettings } from 'services/prepack';
import SelectSKUDialog from './SelectPrintSkuDialog';
import Text from 'antd/lib/typography/Text';
import { packStatusKeyValue as statusEnum } from 'constants/enums'
// import Text from 'antd/lib/typography/Text';
// import theme from 'assets/styles/theme';

// const { Panel } = Collapse;

// const TCollapse = styled(Collapse)`
//   &.ant-collapse-icon-position-right
//     > .ant-collapse-item
//     > .ant-collapse-header {
//     position: relative;
//     padding: 0px 16px;
//     padding-right: 40px;
//   }
// `;

interface Props extends ModalProps {
  onClose: Function;
  pack: Pack;
  onProcess: Function;
}

const SYNC_STATUS_MAP: { [key: string]: string } = {
  '0': 'Not sync',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Wait for response',
};

const ProductDetailDialog = (props: Props) => {
  const { pack, onProcess } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  // const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  // const [current, setCurrent] = useState<PackProcessLine>();
  // const [filterValue, setFilterValue] = useState<string>();P
  const [info, setInfo] = useState<Pack>();
  const [lines, setLines] = useState<PackLine[]>([]);
  const [filterlines, setFilterlines] = useState<any>([]);
  // const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  // const [processLines, setProcessLines] = useState<PackProcessLine[]>([]);
  const [printDialogVisible, setPrintDialogVisible] = useState(false);
  const [printPrepackDialogVisible, setPrintPrepackDialogVisible] = useState(false);
  const [selectSKUDialogVisible, setSelectSKUDialogVisible] = useState(false);
  // const [enableCSN, setEnableCSN] = useState(false);
  const [isParent, setIsParent] = useState<boolean>(true);
  // const [confirmAble, setConfirmAble] = useState(false); // check filished


  const closeProductDetailDialog = () => {
    props.onClose();
  };

  // const debounceFn = debounce((val: string) => {
  //   setFilterValue(val);
  // }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  // const fetchProcessLines = useCallback(async (fulfillmentPackNum: number) => {
  //   try {
  //     const res = await getPackProcessLines(fulfillmentPackNum);
  //     if (res) {
  //       setProcessLines(res);
  //     }
  //   } catch (error) {}
  // }, []);

  // const deleteLine = useCallback(
  //   async (fulfillmentPackNum: number, fulfillmentPackProcessNum: number) => {
  //     try {
  //       const res = await delPackProcessLine(
  //         fulfillmentPackNum,
  //         fulfillmentPackProcessNum
  //       );
  //       if (res) {
  //         message.success('Delete Successfully');
  //         fetchProcessLines(fulfillmentPackNum);
  //       }
  //     } catch (error) {}
  //   },
  //   [fetchProcessLines]
  // );

  // const getTempLineColumns = useCallback((showCSNumber: boolean) => {
  //   const temp = [
  //     {
  //       name: 'fulfillmentUnpackProcessNum',
  //       type: 'string',
  //       header: 'Id',
  //       defaultVisible: false,
  //     },
  //     {
  //       name: 'caseSerialNumber',
  //       header: 'CS#',
  //       minWidth: 150,
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //     {
  //       name: 'packQty',
  //       header: 'Qty',
  //       minWidth: 150,
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //     {
  //       dataType: ColumnDataType.DATE,
  //       name: 'createDate',
  //       header: 'Create Date',
  //       userSelect: true,
  //       minWidth: 110,
  //       defaultFlex: 1,
  //     },
  //     {
  //       name: 'createBy',
  //       header: 'Create By',
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //   ];
  //   return showCSNumber
  //     ? temp
  //     : temp.filter((i) => i.name !== 'caseSerialNumber');
  // }, []);

  const fetchLines = useCallback(async (id: number) => {
    const res = await getPackLines(id);
    if (res) {
      setLines(res);
      // let finished = true
      // res.map((i:any) => {
      //   i.packLineItems.map((j:any) =>{
      //     if(j.packQty !== j.requestQty){
      //       finished = false
      //     }
      //     return true
      //   })
      //   return true
      // })
      // if(finished){
      //   setConfirmAble(true)
      // }
    }
  }, []);

  const refreshInfo = useCallback(async (fulfillmentPackNum) => {
    try {
      const res = await getPackByNum(fulfillmentPackNum);
      if (res) {
        setInfo(res);
      }
    } catch (error) {}
  }, []);

  const handleComplete = async (id: number) => {
    try {
      const res = await completePack(id);
      if (res) {
        message.success('Complete successfully');
        //todo refresh
        refreshInfo(id);
      }
    } catch (error) {}
  };

  const handleCancel = async (id: number) => {
    try {
      const res = await cancelPack(id);
      if (res) {
        message.success('cancel successfully');
        //todo refresh
        refreshInfo(id);
      }
    } catch (error) {}
  };

  // const getPrepackSetting = useCallback(async () => {
  //   try {
  //     const res = await fetchPrepackSettings();
  //     if (res.isSuccess) {
  //       if (res.data.enableCSN && res.data.enableCSN === 1) {
  //         setEnableCSN(true);
  //       }
  //     }
  //   } catch (error) {}
  // }, []);

  const getAllItems = (lines: PackLine[]) => {
    let temp: PackLineItem[] = [];
    lines.map((i) => {
      temp = [...temp, ...i.packLineItems];
      return true;
    });
    return temp;
  };

  useEffect(() => {
    if (pack) {
      setInfo(pack);
      // getPrepackSetting();
      fetchLines(pack.fulfillmentPackNum);
      // fetchProcessLines(pack.fulfillmentPackNum);
    }
  }, [pack, fetchLines]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Pack Prepack (View): ${info?.packCode} `}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <Card
            size="small"
            extra={
              <Space>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="11">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setPrintDialogVisible(true);
                          }}
                        >
                          Print Pick List
                        </Button>
                      </Menu.Item>
                      <Menu.Item key="12">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setSelectSKUDialogVisible(true);
                            setIsParent(true)
                          }}
                        >
                          Print Prepack SKU Label
                        </Button>
                      </Menu.Item>
                      <Menu.Item key="10">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setPrintPrepackDialogVisible(true);
                          }}
                        >
                          Print Prepack SKU Detail Label
                        </Button>
                      </Menu.Item>
                      <Menu.Item key="12">
                        <Button
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          onClick={() => {
                            setSelectSKUDialogVisible(true);
                            setIsParent(false)
                          }}
                        >
                          Print Child SKU Label
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<PrinterOutlined />}>Print</Button>
                </Dropdown>
                {info && info?.status !== 2 && info.status !== 3 && (
                  <Button type="primary" onClick={() => onProcess()}>
                    Process
                  </Button>
                )}
                {info && info?.status === 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      Modal.confirm({
                        title: info.requestQty > info.packQty ? 'Not quite finished yet. Are you sure you want to complete the pack ?' :
                        'Are you sure you want to complete the pack ?',
                        content: <Space direction="vertical">
                        <Text>{`ERP Request#: ${info.packCode}`}</Text>
                        <Text>{`Request Qty: ${info.requestQty}`}</Text>
                        <Text>{`Packed Qty: ${info.packQty}`}</Text>
                      </Space>,
                        okText: 'Yes',
                        okType: 'default',
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onOk() {
                          handleComplete(info.fulfillmentPackNum);
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Complete
                  </Button>
                )}
                {info && info?.status === 0 && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button
                            type="text"
                            style={{ width: '100%', textAlign: 'left' }}
                            icon={<FileExcelOutlined />}
                            onClick={() => {
                              Modal.confirm({
                                title:
                                  'Are you sure you want to cancel the pack ?',
                                content: `ERP Request#: ${info.packCode}`,
                                okText: 'Yes',
                                okType: 'default',
                                okButtonProps: {
                                  className: 'hover-danger-button',
                                },
                                cancelText: 'No',
                                autoFocusButton: 'cancel',
                                onOk() {
                                  handleCancel(info.fulfillmentPackNum);
                                },
                                onCancel() {},
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <EllipsisOutlined />
                  </Dropdown>
                )}
              </Space>
            }
          >
            <Row style={{ width: '100%' }}>
              <Col span={6}>
                <FormLabel>Request#</FormLabel>
                {info?.packCode}
              </Col>
              <Col span={6}>
                <FormLabel>Description</FormLabel>
                {info?.description}
              </Col>
              <Col span={6}>
                <FormLabel>Requested by</FormLabel>
                {info?.createBy}
              </Col>
              <Col span={6}>
                <FormLabel>Status</FormLabel>
                {statusEnum[`${info?.status}`]}
              </Col>
              <Col span={6}>
                <FormLabel>Start Date</FormLabel>
                {moment(info?.startDate).format('MM/DD/YYYY')}
              </Col>
              <Col span={6}>
                <FormLabel>End Date</FormLabel>
                {moment(info?.endDate).format('MM/DD/YYYY')}
              </Col>
              <Col span={6}>
                <FormLabel>Sync Status</FormLabel>
                {SYNC_STATUS_MAP[`${info?.syncStatus}`]}
              </Col>
              <Col span={6}>
                <FormLabel>Printed at</FormLabel>
                {info?.printAt ? moment(info?.printAt).format('MM/DD/YYYY') : ''}
              </Col>
              <Col span={6}>
                <FormLabel>Printed by</FormLabel>
                {info?.printBy}
              </Col>
              <Col span={6}>
                <FormLabel>Note</FormLabel>
                {info?.note}
              </Col>
            </Row>
          </Card>

          <Card
            size="small"
            style={{ marginTop: 12 }}
            title={
              <TablesFilter
                dataSource={lines}
                setFilteredData={setFilterlines}
                columns={[{ name: 'prepackSKU' }]}
              />
            }
            bodyStyle={{
              overflowY: 'auto',
            }}
          >
            {filterlines.map((i: PackLine) => (
              <Card key={i.prepackSKU} size="small" 
              // headStyle={confirmAble ? {backgroundColor: theme['@success-color'] }: undefined} 
              title={i.prepackSKU} 
              extra={<Space><Space><FormLabel>Requested</FormLabel><Typography.Text>{i.requestQty}</Typography.Text></Space><Space><FormLabel>Packed</FormLabel><Typography.Text>{i.packQty}</Typography.Text></Space></Space>}
              // extra={<Space><FormLabel>Requested</FormLabel><Text>{i.requestQty}</Text></Space>}
              >
                {i.packLineItems.map((item: PackLineItem) => (
                  <Card
                    size="small"
                    type="inner"
                    key={item.fulfillmentPackLineNum}
                    // headStyle={!confirmAble && item.packQty === item.requestQty ? {backgroundColor: theme['@success-color'] }: undefined}
                    title={item.sku}
                    style={{ marginBottom: 10 }}
                  >
                    <Row style={{ width: '100%' }}>
                      <Col span={8}>
                        <FormLabel>UPC</FormLabel>
                        {item.upc || ''}
                      </Col>
                      <Col span={8}>
                        <FormLabel>Product Name</FormLabel>
                        {item.productTitle}
                      </Col>
                      <Col span={8}>
                        <FormLabel>Requested</FormLabel>
                        {`${item.requestQty} (${item.componentQuantity} * ${ i.requestQty})`}
                      </Col>
                      <Col span={8}>
                        <FormLabel>Picked</FormLabel>
                        {item.pickedQty}
                      </Col>
                      <Col span={8}>
                        <FormLabel>Packed</FormLabel>
                        {`${item.componentQuantity * i.packQty} (${item.componentQuantity} * ${i.packQty})`}
                      </Col>
                    </Row>
                  </Card>
                ))}
              </Card>
            ))}
            {filterlines.length === 0 && <Empty />}
          </Card>
        </div>
        {/* {current && deleteDialogVisible && (
          <Modal
            title="Are you sure delete this Row?"
            visible
            onOk={() => {
              deleteLine(
                current.fulfillmentPackNum,
                current.fulfillmentPackProcessNum
              );
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`SKU: ${current.sku}`}</p>
            <p>{`CS#: ${current.caseSerialNumber}`}</p>
          </Modal>
        )}
        {editQtyDialogVisible && current && (
          <EditQtyDialog
            data={current}
            visible
            packCode={info?.packCode || ''}
            onSave={(data) => {
              setEditQtyDialogVisible(false);
              fetchProcessLines(data.fulfillmentPackNum);
            }}
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
          />
        )} */}
        {printDialogVisible && info && (
          <PrintDialog
            onHide={() => setPrintDialogVisible(false)}
            detailData={info}
            title="Print Pick List"
          />
        )}
        { printPrepackDialogVisible && info && (
          <PrintPrepack
            onHide={() => setPrintPrepackDialogVisible(false)}
            detailData={info}
            sourceLines={lines}
            title="Print Pick List"
          />
        )}
        {selectSKUDialogVisible && info && lines && (
          <SelectSKUDialog
            onHide={() => setSelectSKUDialogVisible(false)}
            detailData={info}
            sourceLines={getAllItems(lines)}
            prepackSkus={lines.map(i=>{return {fulfillmentPackLineNum: i.prepackFulfillmentProductNum, sku: i.prepackSKU, upc: i.upc, productTitle: i.productTitle}})}
            isParent={isParent}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
