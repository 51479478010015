import React from 'react';
// import {
//   Button,
// } from 'antd';
// import {
//   LinkOutlined,
//   RedoOutlined,
// } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../components/common/ModalDialog';
import Audit from './Audit/index';
import {
  POST_MSG_SET_TITLE,
  POST_MSG_SET_STATE,
} from 'constants/config';
import { getPopupWindowUrl } from 'utils';
import { RootState } from 'reducers';
import { shallowEqual, useSelector } from 'react-redux';



/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  product: InventoryProductRow;
}

const ProductDetailDialog = (props: Props) => {
  const { useCallback, useState } = React;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const adminState = useSelector(
    (state: RootState) => state.admin,
    shallowEqual
  );
  console.log('adminState', adminState)


  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const onPopupWindow = (targetWindow: any) => {
    targetWindow.postMessage({
      __action: POST_MSG_SET_TITLE,
      title: 'Audit',
    });
    targetWindow.postMessage({
      __action: POST_MSG_SET_STATE,
      adminState: adminState,
    });
    targetWindow.postMessage({ product: props.product, onclose: undefined, path: 'audit-detail' });
  };

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Audit"
        visible={props.visible}
        bodyHeight={650}
        width={1280}
        allowPopupWindow
        popupWindowUrl={getPopupWindowUrl('audit-detail')}
        onPopupWindow={onPopupWindow}
      >
        <Audit
          modalWarehouseNum={props.product.warehouseNum}
          modalWarehouseId={props.product.warehouseId}
          modalLocationNum={props.product.locationNum}
          modalLocationId={props.product.locationId}
          modalLocationCode={props.product.locationCode}
          modalWarehouseCode={props.product.warehouseCode}
          isModal
        />
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
