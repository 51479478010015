import React, { useEffect, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { isEqualObject } from 'crud-object-diff';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Image,
  Menu,
  Modal,
  Row,
  Space,
} from 'antd';
import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  printCartonLabel,
  printDocument,
  printPackingSlip,
  printShipmentLabelNotice,
  reprintShipment,
} from 'actions/printActions';
import {
  setPrintCartonResult,
  setPrintDocumentResult,
  setPrintLabelResponse,
  setPrintLabelResult,
} from 'actions/salesAction';
import fedexLogo from 'assets/images/fedEx.png';
import purolatorLogo from 'assets/images/purolator.png';
import upsLogo from 'assets/images/ups.png';
import uspsLogo from 'assets/images/usps.png';
import { ScreenMask, TimerLock, message } from 'components/common';
import {
  ButtonIcon,
  DangerButton,
  DangerDropdownButton,
  InfoButton,
} from 'components/common/styles';
import {
  DEFAULT_PRINT_MODE,
  PRINT_MESSAGE_RESPONSE_TIMEOUT,
  PRINT_TYPE_PACK_SLIP,
  PRINT_TYPE_REPRINT_LABEL,
  SHIPMENT_LABEL_SIZE1,
  SHIPMENT_SHIPPING_STATE,
  SHIPPING_LABEL_WAIT_RELEASE,
} from 'constants/config';
import { FALLBACK_IMAGE } from 'constants/image';
import { RootState } from 'reducers';
import {
  postPrintShipmentCartonLabel,
  postPrintShipmentLabel,
  postPrintShipmentPackingSlip,
  voidShipment,
} from 'services/sales';
import { fetchProfileSettings } from 'services/user';
import {
  convertTimeByUtc,
  getProfileSettingValue,
  getShippingFromAddress,
  getShippingToAddress,
  getWeightString,
  isMacos,
  nowTime,
} from 'utils';
import { userProfiles } from 'utils/auth';
import { ShipmentUnit, printShipmentCommonData } from '.';
import AlertDialog from './AlertDialog';
import { LabelFormDropdownItem, MaskMessageBox, PrintLabelFormContainer } from './styles';

type Props = {
  deviceSetting?: Function;
  documentTrack: StringKAnyVPair;
  getPackingSlipType?: Function;
  resetOrderShipment: Function;
  shipment: ShipmentUnit;
};

let keydownHandler: any = null;
let profileSettings: StringKAnyVPair[] = [];

const PrintSummary = (props: Props) => {
  const { t: trans } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const [alertDialogContent, setAlertDialogContent] = useState('');
  const [alertDialogOnClose, setAlertDialogOnClose] = useState<Function>();
  const [alertDialogTitle, setAlertDialogTitle] = useState('');
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [confirmVoidVisible, setConfirmVoidVisible] = useState(false);
  const [currentDocumentTrack, setCurrentDocumentTrack] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [shipmentNum, setShipmentNum] = useState(0);
  const [showReprint, setShowReprint] = useState(false);
  const [isFetchProfileSetting, setIsFetchProfileSetting] = useState(false);
  //const [summary, setSummary] = useState<PrintSummary>();
  const { shipment } = props;
  const cartonPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinterCoton,
    shallowEqual
  );
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode,
    shallowEqual
  );
  const packingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );
  const packingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );
  const printCartonResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printCartonResult,
    shallowEqual
  );
  const printDocumentResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printDocumentResult,
    shallowEqual
  );
  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );
  const printLabelResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResult,
    shallowEqual
  );
  const shipmentPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter,
    shallowEqual
  );
  const shipPrinter4x8: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter4x8,
    shallowEqual
  );
  const shell = React.useRef<any>(null);
  const unlockButton = React.useRef<any>(null);

  const getCarrierLogo = (carrier: string) => {
    const nameDict: StringKAnyVPair = {
      fedex: fedexLogo,
      ups: upsLogo,
      usps: uspsLogo,
      purolator: purolatorLogo,
    };
    const key =
      typeof carrier === 'string' && carrier ? carrier.toLowerCase() : '';
    const path = nameDict[key] || 'error';
    //console.log('cc --->', carrier);

    return (
      <Image
        width={100}
        height={64}
        preview={false}
        src={path}
        fallback={FALLBACK_IMAGE}
      />
    );
  };

  const getDimension = () => {
    const { length, height, width } = shipment;

    if (length === null || height === null || width === null) {
      return ' ';
    }

    return `${length}Lx${width}Wx${height}H`;
  };

  // eslint-disable-next-line
  const getProfileSettings = async () => {
    setLockScreen(true);

    try {
      const settings = await fetchProfileSettings();

      setLockScreen(false);

      if (Array.isArray(settings)) {
        profileSettings = settings;
      }
    } catch (e) {
      message.error(`Fetch profile setting error: ${e}`);
      setLockScreen(false);
    }
  };

  const getShipmentSummaryState = (shipment: StringKAnyVPair) => {
    let ret = '';

    switch (shipment.shippingStatus) {
      case SHIPMENT_SHIPPING_STATE:
        if (shipment.shippingLabelDispensed === SHIPPING_LABEL_WAIT_RELEASE) {
          ret = trans('scanToShip.waitingRelease');
        } else {
          ret = trans('scanToShip.shipped');
        }
        break;
    }

    return ret;
  };

  // eslint-disable-next-line
  const handleKeydown = (evt: any) => {
    if (evt.target.tagName.toLowerCase() !== 'input') {
      //console.log('kkk ccc summary', evt.keyCode);
      if (evt.keyCode === 27) {
        if (unlockButton?.current) {
          unlockButton?.current.click();
        }
      }
    }
  };

  const showVoidModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to void the shipment?',
      okText: 'Yes',
      cancelText: 'No',
      okType: 'default',
      okButtonProps: { className: "hover-danger-button" },
      autoFocusButton: 'cancel',
      onCancel() {
      },
      onOk() {
        handleVoidShipment();
      },
    });

  }

  const handleVoidShipment = async (onlyInDigitBridge?: boolean) => {
    setConfirmVoidVisible(false);

    if (shipmentNum > 0) {
      setLockScreen(true);

      try {
        const res = await voidShipment(shipmentNum, onlyInDigitBridge ? 1 : 0);

        setLockScreen(false);

        if (res && typeof res === 'object') {
          console.log('res', res);
          if (res.shipmentNum === shipmentNum) {
            //props.resetOrderShipment(shipmentNum);
            props.resetOrderShipment(shipmentNum, `${shipment.fulfillmentOrderId}_`);
            const shipFlow = profileSettings.filter(
              (e) => e.settingCode === 'ShipFlow'
            );
            if (shipFlow.length > 0) {
              if (shipFlow[0].settingValue === 'ScanFlow') {
                Modal.info({
                  title: 'Notice',
                  content: (
                    <div>
                      <p>
                        The item has been put back to "Pick" location. You can
                        ship it again.
                      </p>
                      <p>
                        If you don't ship the item anymore, please use our
                        program to put it back to location.
                      </p>
                    </div>
                  ),
                });
              }
              if (shipFlow[0].settingValue === 'SimpleFlow') {
                Modal.info({
                  title: 'Notice',
                  content: (
                    <div>
                      <p>
                        The item has been put back to "General" location. You
                        can ship it again.
                      </p>
                      <p>
                        If you don't ship the item anymore, please put the item
                        back physically.
                      </p>
                    </div>
                  ),
                });
              }
            }
          } else {
            message.error(`Void shipment '${shipment.shipmentTitle}' failed`);
          }
        }
      } catch (e) {
        setLockScreen(false);
        message.error(`Void shipment '${shipment.shipmentTitle}' error: ${e}`);
      }
    } else {
      message.error(`Shipment '${shipment.shipmentTitle}' information error!`);
    }
  };

  const isShipmentMarkShipped = (sm: StringKAnyVPair) => {
    return (
      sm.shippingStatus === SHIPMENT_SHIPPING_STATE &&
      sm.shipCarrierNum === null &&
      sm.shipServiceNum === null
    );
  };

  const needReprintMore = (shipment: StringKAnyVPair) => {
    return !!shipment.isPrintCartonLabel ||
      (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE && shipment.shippingLabelDispensed === SHIPPING_LABEL_WAIT_RELEASE);
  };

  const openAlertDialog = (config: StringKAnyVPair) => {
    setAlertDialogVisible(true);
    setAlertDialogContent(config.content || '');
    setAlertDialogOnClose(
      typeof config.onClose === 'function' ? config.onClose : undefined
    );
    setAlertDialogTitle(config.title || '');
  };

  // eslint-disable-next-line
  const printDocuments = async (shipment: ShipmentUnit) => {
    let printer = packingPrinter;
    const profile = userProfiles.profile;

    if (printer.printerNum === undefined || printer.printerNum <= 0) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    const docs = {
      DocNames: shipment.documents.map(e => e.fileName),
      Reprint: 1,
      ShipmentNum: shipment.shipmentNum,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      //PrinterName: printer.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      Time: nowTime(),
      User: profile.email,
    };
    //console.log('l-->', docs); 
    setLockScreen(true);

    const printMode = await getProfileSettingValue(
      'PrintStationMode',
      DEFAULT_PRINT_MODE
    );

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printDocument(docs));
    } else {
      try {
        //await postPrintShipmentCartonLabel(carton);
      } finally {
        //setLockScreen(false);
      }
    }
  };

  const reprintCarton = async () => {
    let printer = cartonPrinter;
    const profile = userProfiles.profile;

    if (printer.printerNum === undefined || printer.printerNum <= 0) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    const carton = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: shipment.fulfillmentOrderNum,
      ShipmentNum: shipment.shipmentNum,
      Time: nowTime(),
    };

    setLockScreen(true);

    const printMode = await getProfileSettingValue(
      'PrintStationMode',
      DEFAULT_PRINT_MODE
    );

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printCartonLabel(carton));
    } else {
      try {
        await postPrintShipmentCartonLabel(carton);
      } finally {
        setLockScreen(false);
      }
    }
  };

  const reprintDropdownMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => printDocuments(shipment)}>
        <LabelFormDropdownItem>Reprint Documents</LabelFormDropdownItem>
      </Menu.Item>
    </Menu>
  );

  const reprintLabel = async () => {
    let labelPrinter = shipmentPrinter;
    const profile = userProfiles.profile;

    if (
      shipmentPrinter.printerNum === undefined ||
      shipmentPrinter.printerNum <= 0
    ) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    if (props.shipment.labelSize === SHIPMENT_LABEL_SIZE1) {
      if (
        shipPrinter4x8.printerNum === undefined ||
        shipPrinter4x8.printerNum <= 0
      ) {
        if (typeof props.deviceSetting === 'function') {
          props.deviceSetting();
          message.info(`Please select a 4'' * 8'' inch printer to print this label`);
        }

        return;
      }

      labelPrinter = shipPrinter4x8;
    }

    const label = {
      type: 0,
      ShipmentNum: shipment.shipmentNum,
      //PrinterNum: shipmentPrinter.printerNum,
      PrinterNum: labelPrinter.printerNum,
      //PrinterAccountNum: shipmentPrinter.printerAccountNum,
      PrinterAccountNum: labelPrinter.printerAccountNum,
      //PrinterName: shipmentPrinter.printerName,
      PrinterName: labelPrinter.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };

    setLockScreen(true);

    const printMode = await getProfileSettingValue(
      'PrintStationMode',
      DEFAULT_PRINT_MODE
    );

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(reprintShipment(label));
    } else {
      try {
        //const res = await postPrintShipmentLabel(label);
        await postPrintShipmentLabel(label, true);
      } finally {
        setLockScreen(false);
      }
    }
  };

  const reprintMoreDropdownMenu = (shipment: StringKAnyVPair) => (
    <Menu>
      {shipment.shippingLabelDispensed === SHIPPING_LABEL_WAIT_RELEASE && (
        <Menu.Item key="0" onClick={reprintNotice}>
          <LabelFormDropdownItem>{trans('scanToShip.scanTrackingNumber.reprintNotice')}</LabelFormDropdownItem>
        </Menu.Item>
      )}
      {!!shipment.isPrintCartonLabel && (
        <Menu.Item key="1" onClick={reprintCarton}>
          <LabelFormDropdownItem>{trans('scanToShip.scanTrackingNumber.reprintCarton')}</LabelFormDropdownItem>
        </Menu.Item>
      )}
    </Menu>
  );

  const reprintNotice = async () => {
    let printer = shipmentPrinter;
    const profile = userProfiles.profile;

    if (
      shipmentPrinter.printerNum === undefined ||
      shipmentPrinter.printerNum <= 0
    ) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    if (props.shipment.labelSize === SHIPMENT_LABEL_SIZE1) {
      if (
        shipPrinter4x8.printerNum === undefined ||
        shipPrinter4x8.printerNum <= 0
      ) {
        if (typeof props.deviceSetting === 'function') {
          props.deviceSetting();
          message.info(`Please select a 4'' * 8'' inch printer to print this label`);
        }

        return;
      }

      printer = shipPrinter4x8;
    }

    setLockScreen(true);

    const printMode = await getProfileSettingValue(
      'PrintStationMode',
      DEFAULT_PRINT_MODE
    );

    if (printMode === DEFAULT_PRINT_MODE) {
      //dispatch(printShipmentLabelNotice(notice));
      printShipmentCommonData(profile, printer, shipment, dispatch, printShipmentLabelNotice);
    } else {
      try {
        //const res = await postPrintShipmentPackingSlip(notice);
        //await postPrintShipmentPackingSlip(notice);
      } finally {
        setLockScreen(false);
      }
    }
  };

  const reprintSlip = async () => {
    const { getPackingSlipType } = props;
    let printer = packingPrinter;
    const profile = userProfiles.profile;

    if (typeof getPackingSlipType === 'function') {
      const typ = await getPackingSlipType();

      if (typ === 1) printer = packingPrinter2;
    }

    if (printer.printerNum === undefined || printer.printerNum <= 0) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    const slip = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: shipment.fulfillmentOrderNum,
      ShipmentNum: shipment.shipmentNum,
      Time: nowTime(),
    };

    setLockScreen(true);

    const printMode = await getProfileSettingValue(
      'PrintStationMode',
      DEFAULT_PRINT_MODE
    );

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printPackingSlip(slip));
    } else {
      try {
        //const res = await postPrintShipmentPackingSlip(slip);
        await postPrintShipmentPackingSlip(slip);
      } finally {
        setLockScreen(false);
      }
    }
  };

  const shipmentHasDocument = () => {
    if (Array.isArray(shipment.documents)) {
      return shipment.documents.length > 0;
    }

    return false;
  };

  useEffect(() => {
    /*
    const fetch = async () => {
      const summary = await fetchPrintSummary(shipment.shipmentNum);
      console.log('summary', summary, shipment);
      setSummary(summary);
    };*/
    if (profileSettings.length === 0) {
      if (!isFetchProfileSetting) {
        getProfileSettings();
        setIsFetchProfileSetting(true);
      }
    }

    if (!inited) {
      document.addEventListener('keydown', handleKeydown, false);
      keydownHandler = handleKeydown;
      setInited(true);
    }

    if (shipmentNum !== shipment.shipmentNum) {
      //fetch();
      setShipmentNum(shipment.shipmentNum);
    }

    if (printLabelResponse && shipment) {
      const types = [PRINT_TYPE_REPRINT_LABEL, PRINT_TYPE_PACK_SLIP];

      //console.log('tt', types, printLabelResponse);
      if (types.indexOf(printLabelResponse.type) > -1) {
        //console.log('print label end', printLabelResponse, shipment.shipmentNum);
        if (
          printLabelResponse.id === shipment.shipmentNum ||
          printLabelResponse.type === PRINT_TYPE_PACK_SLIP
        ) {
          setTimeout(() => {
            setLockScreen(false);
            //dispatch(setPrintLabelEnd({}));
            dispatch(setPrintLabelResponse({}));
          }, PRINT_MESSAGE_RESPONSE_TIMEOUT);
        }
      }
    }

    if (printCartonResult && Object.keys(printCartonResult).length > 0) {
      const { message, isSuccess } = printCartonResult;

      dispatch(setPrintCartonResult({}));
      setLockScreen(false);

      if (!isSuccess) {
        setLockScreen(false);
        openAlertDialog({
          title: 'Print Error',
          content: message,
        });
      }
    }

    if (printDocumentResult && Object.keys(printDocumentResult).length > 0) {
      const { message, isSuccess } = printDocumentResult;

      dispatch(setPrintDocumentResult({}));
      setLockScreen(false);

      if (!isSuccess) {
        setLockScreen(false);
        openAlertDialog({
          title: 'Print Error',
          content: message,
        });
      }
    }

    if (printLabelResult.code) {
      const { message, isSuccess } = printLabelResult;

      //console.log('p res', printLabelResult);
      dispatch(setPrintLabelResult({}));

      if (!isSuccess) {
        setLockScreen(false);
        openAlertDialog({
          title: 'Print Error',
          content: message,
        });
      }
    }

    if (!isEqualObject(currentDocumentTrack, props.documentTrack)) {
      const dt = props.documentTrack;

      console.log('track changed');
      setCurrentDocumentTrack(dt);

      if (dt.trackingNumber && shipment.shipmentNum === dt.shipmentNum) {
        if (Array.isArray(shipment.documents) && shipment.documents.length > 0) {
          console.log('pr doc');
          printDocuments(shipment);
        }
      }
    }

    return () => {
      setTimeout(() => {
        // eslint-disable-next-line
        if (!shell?.current) {
          if (keydownHandler) {
            document.removeEventListener('keydown', keydownHandler, false);
            keydownHandler = null;
          }
        }
      }, 0);
    };
  }, [
    currentDocumentTrack,
    dispatch,
    isFetchProfileSetting,
    inited,
    printCartonResult,
    printDocumentResult,
    printDocuments,
    printLabelResponse,
    printLabelResult,
    props,
    shipment,
    shipmentNum,
  ]);

  return (
    <>
      <div ref={shell}>
        <Row className="slip-shipment-header" justify="space-between">
          <div>{shipment.shipmentTitle}</div>
          {shipment.shippingStatus === SHIPMENT_SHIPPING_STATE ? (
            <div>{getShipmentSummaryState(shipment)}</div>
          ) : (
            <span />
          )}
        </Row>
      </div>
      <PrintLabelFormContainer pageMode={pageShowMode}>
        <Row className="slip-carrier-row">
          <div className="carrier-logo-wrapper">
            {getCarrierLogo(shipment.shippingCarrier)}
          </div>
          <div className="carrier-info-wrapper">
            <Row className="carrier-field" align="middle">
              {shipment.trackingNumber}
            </Row>
            <Row className="carrier-field" align="middle">
              {shipment.shippingService}
            </Row>
          </div>
        </Row>
        <Row justify="space-between" className="slip-container">
          <Col span={12}>
            <Col className="slip-field">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.shipedFrom')}
              </div>
              <div>{getShippingFromAddress(shipment)}</div>
            </Col>
            <Col className="slip-field">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.shippedTo')}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: getShippingToAddress(shipment, ',\n'),
                }}
              />
            </Col>
          </Col>
          <Col span={12}>
            <Col className="slip-field slip-border-left">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.label.dateShipped')}
              </div>
              <div>{convertTimeByUtc(shipment.updateDate)}</div>
            </Col>
            <Col className="slip-field slip-border-left">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.label.packageType')}
              </div>
              <div>{shipment.packageName}</div>
            </Col>
            <Col className="slip-field slip-border-left">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.label.dimensions')}
              </div>
              <div>{getDimension()}</div>
            </Col>
            <Col className="slip-field slip-border-left">
              <div className="slip-label">
                {trans('scanToShip.scanTrackingNumber.label.weight')}
              </div>
              <div>{getWeightString(shipment.weight)}</div>
            </Col>
          </Col>
        </Row>
      </PrintLabelFormContainer>
      <div className="slip-bottom-btn-row">
        {showReprint ? (
          <Row justify="end">
            <Space>
              {/*!!shipment.isPrintCartonLabel && (
                <InfoButton size="large" onClick={reprintCarton}>
                  <ButtonIcon className="bi-printer" />
                  {trans('scanToShip.scanTrackingNumber.reprintCarton')}
                </InfoButton>
              )*/}
              {needReprintMore(shipment) && (
                <Dropdown
                  overlay={reprintMoreDropdownMenu(shipment)}
                  trigger={['click']}
                >
                  <Button size="large">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              )}
              {!!shipment.isPrintPackingSlip && (
                <InfoButton size="large" onClick={reprintSlip}>
                  <ButtonIcon className="bi-printer" />
                  {trans('scanToShip.scanTrackingNumber.reprintPackingSlip')}
                </InfoButton>
              )}
              <Button type="primary" size="large" onClick={reprintLabel}>
                <ButtonIcon className="bi-printer" />
                {trans('scanToShip.scanTrackingNumber.reprintLabel')}
              </Button>
              {shipmentHasDocument() && (
                <Dropdown overlay={reprintDropdownMenu} trigger={['click']}>
                  <Button size="large">
                    <EllipsisOutlined />
                  </Button>
                </Dropdown>
              )}
            </Space>
          </Row>
        ) : (
          <Row justify="space-between">
            <DangerButton
              disabled={isShipmentMarkShipped(shipment)}
              onClick={() => setShowReprint(true)}
              size="large"
            >
              {trans('scanToShip.scanTrackingNumber.wantToReprint')}
            </DangerButton>
            <Button onClick={() => setConfirmVoidVisible(true)} size="large">
              <EllipsisOutlined />
            </Button>
          </Row>
        )}
      </div>
      <Modal
        centered
        //closable={false}
        //footer={null}
        footer={<Row justify="end"><Button size="large" onClick={() => setConfirmVoidVisible(false)}><CloseOutlined />Cancel</Button></Row>}
        onCancel={() => setConfirmVoidVisible(false)}
        title="&nbsp;"
        visible={confirmVoidVisible}
        width={500}
      >
        <Row justify="center">
          {/*<Button onClick={() => setConfirmVoidVisible(false)} size="large">
            {trans('common.close')}
          </Button>*/}
          {/*<span style={{width: 80}}></span>*/}
          {/*<DangerButton
            onClick={() => handleVoidShipment()}
            size="large"
            type="primary"
          >
            {trans('scanToShip.scanTrackingNumber.voidShipment')}
          </DangerButton>*/}
          {/*<Dropdown.Button*/}
          <DangerDropdownButton
            className="void-shipment-dropdown-btn"
            onClick={() => showVoidModal()}
            overlay={
              <Menu
                onClick={({ key }) => {
                  if (key === '1') {
                    Modal.confirm({
                      title: 'Void Shipping in DigitBridge Only?',
                      icon: <ExclamationCircleOutlined />,
                      content:
                        'This operation will not void the shipping label at carrier.',
                      okText: 'No',
                      okType: 'default',
                      cancelButtonProps: { className: 'hover-danger-button' },
                      cancelText: 'Yes',
                      autoFocusButton: 'ok',
                      onCancel: () => {
                        handleVoidShipment(true);
                      },
                      onOk() { },
                    });
                  }
                }}
              >
                <Menu.Item key="1">Void Shipping in DigitBridge Only (Skip Carrier Voiding)</Menu.Item>
              </Menu>
            }
            size="large"
          >
            {/*<Button size="large">More...</Button>*/}
            {trans('scanToShip.scanTrackingNumber.voidShipment')}
            {/*</Dropdown.Button>*/}
          </DangerDropdownButton>
        </Row>
      </Modal>
      <AlertDialog
        close={() => setAlertDialogVisible(false)}
        content={alertDialogContent}
        onClose={alertDialogOnClose}
        title={alertDialogTitle}
        visible={alertDialogVisible}
      />
      {lockScreen && (
        <ScreenMask>
          <TimerLock>
            <Row align="middle" justify="center" style={{ height: '100%' }}>
              <MaskMessageBox>
                <Row className="message-box">
                  <span>{trans('scanToShip.printLabel.noResponse')}</span>
                </Row>
                <Row className="button-box" justify="center">
                  <Button
                    onClick={() => setLockScreen(false)}
                    ref={unlockButton}
                    size="large"
                    type="primary"
                  >
                    {trans('common.continue')} ({isMacos() ? 'esc' : 'Esc'})
                  </Button>
                </Row>
              </MaskMessageBox>
            </Row>
          </TimerLock>
        </ScreenMask>
      )}
    </>
  );
};

export default PrintSummary;
