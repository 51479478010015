/**
 * The warehouse select box. It provides selection of warehouse code.
 */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Select, Spin } from 'antd';
import { LoadingIcon } from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { fetchWarehouseList } from 'services/warehouse';
import { RootState } from 'reducers';

/**
 * The type definition for warehouse select option.
 */
type WarehouseOption = {
  id: number;
  label: string;
  value: string;
  warehouseId: string;
  warehouseType: number;
};

/**
 * The type definition for the component.
 *
 * @param {?GeneralSizeType} size
 * @param {?boolean} selectAll - whether can select all warehouses
 * @param {?Function} onChange - the onChange handler
 * @param {?number} - value - the value of the select box
 */
type Props = {
  disabled?: boolean;
  selectAll?: boolean;
  size?: GeneralSizeType;
  onChange?: Function;
  value?: number;
  onlyNormal?: boolean;
};

// eslint-disable-next-line
export default (props: Props) => {
  const { onChange, size = 'middle', selectAll, onlyNormal = true } = props;
  const [inited, setInited] = React.useState(false);
  const [isLoadingWarehouseList, setIsLoadingWarehouseList] =
    React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [warehouseOptions, setWarehouseOptions] = React.useState<
    WarehouseOption[]
  >([]);
  const warehouseCacheList: WarehouseRow[] = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );

  /**
   * Handle warehouse selection.
   * The related state(selectedWarehouseIds) will be updated.
   * @param {number|Array<number>} If the warehouse is an array, it indicates that you can select more than one warehouse at a time.
   */
  // eslint-disable-next-line
  const handleWarehouseCodeChange = React.useCallback(
    (value: number | Array<number>) => {
      console.log('v', value);
      setSelectedValue(value);

      if ('function' === typeof onChange) {
        let opt: StringKAnyVPair = {};

        for (let i = 0; i < warehouseOptions.length; i++) {
          const val = parseInt(warehouseOptions[i].value);

          if (val && val === value) {
            opt = JSON.parse(JSON.stringify(warehouseOptions[i]));
            opt.value = val;
            break;
          }
        }

        onChange(value, opt);
      }
    },
    [onChange, warehouseOptions]
  );

  const getWarehouseList = React.useCallback(async () => {
    try {
      const list = await fetchWarehouseList();
      setIsLoadingWarehouseList(false);

      if (list && Array.isArray(list)) {
        let rawWarehouseList = list.map((e) => {
          return {
            id: parseInt(e.warehouseNum),
            label: e.warehouseCode,
            value: e.warehouseNum + '',
            warehouseId: e.warehouseId,
            warehouseType: e.warehouseType,
          };
        });

        if (selectAll) {
          rawWarehouseList = [
            {
              id: 0,
              label: 'All',
              value: 'All',
              warehouseId: '',
              warehouseType: 1,
            },
            ...rawWarehouseList,
          ];
        }

        setWarehouseOptions(rawWarehouseList);
      }
    } catch (e) {
      console.log('err', e);
    }
  }, [selectAll]);

  // eslint-disable-next-line
  const setWarehouseOptionsFromCache = () => {
    let rawWarehouseList = warehouseCacheList.map((e) => {
      return {
        id: e.id,
        label: e.code,
        value: e.id + '',
        warehouseId: e.warehouseId || '',
        warehouseType: e.warehouseType || 0,
      };
    });

    if (selectAll) {
      rawWarehouseList = [
        {
          id: 0,
          label: 'All',
          value: 'All',
          warehouseId: '',
          warehouseType: 1,
        },
        ...rawWarehouseList,
      ];
    }

    setWarehouseOptions(rawWarehouseList);
  };

  /**
   * Map the WarehouseOption Array into warehouse code select options.
   * Note: the label property of the option is needed.
   */
  const warehouseCodeSelectOptions = (options: WarehouseOption[]) => {
    return onlyNormal
      ? warehouseOptions
          .filter((w) => (w.warehouseType === 1 || w.warehouseType === 5))
          .map((e) => (
            <Select.Option key={e.id} label={e.label} value={e.id}>
              {e.label}
            </Select.Option>
          ))
      : warehouseOptions.map((e) => (
          <Select.Option key={e.id} label={e.label} value={e.id}>
            {e.label}
          </Select.Option>
        ));
  };

  React.useEffect(() => {
    const { value } = props;

    if (!inited) {
      if (warehouseCacheList.length > 0) {
        setWarehouseOptionsFromCache();
        setIsLoadingWarehouseList(false);
      } else {
        getWarehouseList();
      }

      if (typeof value === 'undefined') {
        const whid = parseInt(
          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
        );

        if (whid > 0) {
          setSelectedValue(whid);
          handleWarehouseCodeChange(whid);
        }
      }

      setInited(true);
    }

    if ('number' === typeof value && value !== selectedValue) {
      //console.log('vvv', value);
      if (value > -1) {
        if (warehouseOptions.length > 0) {
          handleWarehouseCodeChange(value);
        }
      } else {
        setSelectedValue(null);
      }
    }
  }, [
    getWarehouseList,
    handleWarehouseCodeChange,
    inited,
    isLoadingWarehouseList,
    props,
    selectedValue,
    setWarehouseOptionsFromCache,
    warehouseCacheList,
    warehouseOptions,
  ]);

  return (
    <Row align="middle" style={{ height: '100%' }}>
      {isLoadingWarehouseList ? (
        <Spin indicator={<LoadingIcon />} />
      ) : (
        <Select
          disabled={props.disabled}
          showSearch
          filterOption={(inputValue, option) => {
            const search = inputValue.trim().toLowerCase();
            let label = '';

            if (option && 'object' == typeof option) {
              if ('string' === typeof option.label) {
                label = option.label.toLowerCase();
              }
            }

            return !!label && label.indexOf(search) > -1;
          }}
          onChange={handleWarehouseCodeChange}
          placeholder="Select a Warehouse Code"
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {warehouseCodeSelectOptions(warehouseOptions)}
        </Select>
      )}
    </Row>
  );
};
