import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Typography,
} from 'antd';
import { editWarehouse, get3rdPaties } from 'services/warehouse';
import { FormLabel } from 'components/common';
import { message as showMessage } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { getAddressList } from 'services/address';
import CreateAddressDialog from 'components/Admin/AddressBook/createDialog';

const { Text } = Typography;

interface EditProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  warehouse: WarehouseRow;
  warehouseList: WarehouseRow[];
}
// eslint-disable-next-line
export default (props: EditProps) => {
  const { visible, onHide, onRefresh, warehouse, warehouseList } = props;
  const [loading, setLoading] = useState(false);
  const [isReturn, setIsReturn] = useState(
    warehouse ? [2, 3, 4, 6, 7].indexOf(warehouse.warehouseType || 0) > -1 : false
  );
  const [isThird, setIsThird] = useState(
    warehouse ? warehouse.warehouseType === 5 : false
  );
  const [thirdPaties, setThirdPaties] = useState<any[]>([]);
  const [addressBook, setAddressBook] = useState<AddressRow[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressRow>();
  const [createlVisible, setCreatelVisible] = useState(false);
  const [addressAct, setAddressAct] = useState<'add' | 'editFrom' | 'editReturn'>('add');

  const [form] = Form.useForm();

  const fetchAddressBook = useCallback(async () => {
    try {
      const res = await getAddressList();
      if (res.isSuccess) {
        const targetAddress = res.data.find((address: AddressRow) => address.addressNum === warehouse?.addressNum);
        setAddressBook(res.data);
        setSelectedAddress(targetAddress);
        form.setFieldsValue({ AddressNum: targetAddress ? targetAddress.addressNum : undefined })
      }
    } catch (error) { }
    // eslint-disable-next-line
  }, [warehouse]);

  const getThirdPaties = useCallback(async () => {
    const res = await get3rdPaties();
    if (res.isSuccess) {
      setThirdPaties(res.data);
    }
  }, []);

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!warehouse) return;
    setLoading(true);
    try {
      const res = await editWarehouse(warehouse.warehouseId, {
        ...params,
        CorrespondingWarehouseNum:
          [2, 3, 4, 6, 7].indexOf(params.WarehouseType) > -1
            ? params.CorrespondingWarehouseNum
            : null,
        ThirdPartyCode:
          params.WarehouseType === 5 ? params.ThirdPartyCode : null,
      });
      if (!res.error) {
        showMessage.success({
          content: 'Saved Successfully！',
        });
        form.resetFields();
        onRefresh();
        onHide();
      } else {
        showMessage.error({ content: res.error });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  useEffect(() => {
    getThirdPaties();
    fetchAddressBook();
  }, [getThirdPaties, fetchAddressBook]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Edit Warehouse"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              onClick={() => {
                setAddressAct('add');
                setCreatelVisible(true);
              }}
              icon={<PlusOutlined />}
            >
              New Address
            </Button>
            <Button
              disabled={!selectedAddress}
              onClick={() => {
                setAddressAct('editFrom')
                setCreatelVisible(true);
              }}
              icon={<EditOutlined />}
            >
              Update Address
            </Button>
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              loading={loading}
              icon={<SaveOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="WarehouseCode"
            initialValue={warehouse?.code}
            label={<FormLabel>Code</FormLabel>}
            rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="WarehouseName"
            initialValue={warehouse?.name}
            label={<FormLabel>Name</FormLabel>}
            rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="WarehouseType"
            initialValue={warehouse?.warehouseType}
            label={<FormLabel>Warehouse Type</FormLabel>}
          // rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Select
              onChange={(value) => {
                if ([2, 3, 4, 6, 7].indexOf(value) > -1) {
                  setIsReturn(true);
                  setSelectedAddress(undefined)
                } else {
                  setIsReturn(false);
                }
                if (value === 5) {
                  setIsThird(true);
                } else {
                  setIsThird(false);
                }
              }}
            >
              <Select.Option value={1}>Normal</Select.Option>
              <Select.Option value={2}>Return-Resalable</Select.Option>
              <Select.Option value={3}>Return-Damage</Select.Option>
              <Select.Option value={4}>Return-Inspection</Select.Option>
              <Select.Option value={5}>Third Party</Select.Option>
              <Select.Option value={6}>Receiving</Select.Option>
              <Select.Option value={7}>Damage</Select.Option>
            </Select>
          </Form.Item>

          {isThird && (
            <Form.Item
              name="ThirdPartyCode"
              initialValue={warehouse?.thirdPartyCode}
              label={<FormLabel>Third Party</FormLabel>}
              rules={[
                { required: true, message: 'Please select third party!' },
              ]}
            >
              <Select showSearch optionFilterProp="label">
                {thirdPaties.map((item: any) => (
                  <Select.Option
                    key={item.code}
                    value={item.code}
                    label={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {isReturn && (
            <Form.Item
              name="CorrespondingWarehouseNum"
              initialValue={warehouse.correspondingWarehouseNum}
              label={<FormLabel>Corresponding Normal Warehouse</FormLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please select corresponding normal warehous!',
                },
              ]}
            >
              <Select showSearch optionFilterProp="label">
                {warehouseList
                  .filter(
                    (item) =>
                      (item.warehouseType === 1 || item.warehouseType === 5) &&
                      item.id !== warehouse.id
                  )
                  .map((wh) => (
                    <Select.Option key={wh.id} value={wh.id}>
                      {wh.code}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {!isReturn && (
            <Form.Item
              name="AddressNum"
              initialValue={warehouse?.addressNum}
              label={<FormLabel>Address</FormLabel>}
              rules={[{ required: true, message: 'Please select address!' }]}
            >
              <Select
                optionFilterProp="label"
                onChange={(num) =>
                  setSelectedAddress(
                    addressBook.filter(
                      (item) => item.addressNum === Number(num)
                    )[0]
                  )
                }
              >
                {addressBook.map((item) => (
                  <Select.Option
                    value={item.addressNum}
                    key={item.addressNum}
                    label={item.addressAlias}
                  >
                    <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                      } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                      } ${item.city ? item.city + ',' : ''} ${item.state || ''} ${item.postalCode ? item.postalCode + ',' : ','
                      } ${item.country || ''}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {selectedAddress && !isReturn && (
            <Form.Item label={<FormLabel noColon> </FormLabel>}>
              <Space direction="vertical">
                <Text>{selectedAddress.contactName || ''}</Text>
                {selectedAddress.street1 && (
                  <Text>{selectedAddress.street1}</Text>
                )}
                {selectedAddress.street2 && (
                  <Text>{selectedAddress.street2}</Text>
                )}
                {selectedAddress.street3 && (
                  <Text>{selectedAddress.street3}</Text>
                )}
                <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                  } ${selectedAddress.postalCode || ''}`}</Text>
                <Text>{selectedAddress.country || ''}</Text>
              </Space>
            </Form.Item>
          )}
          <Form.Item
            name="Note"
            initialValue={warehouse?.note}
            label={<FormLabel>Note</FormLabel>}
          // rules={[{ required: true, message: 'Please input code!' }]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
        </Form>
      </Spin>
      {createlVisible && (
        <CreateAddressDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => fetchAddressBook()}
          address={addressAct === 'editFrom' ? selectedAddress : undefined}
        />
      )}
    </Modal>
  );
};
