import React, { useState, useCallback } from 'react';
import { Input } from 'antd';
import { fetchWarehouseLocationList } from 'services/warehouse';
import SelectLocationDialog from './SelectLocationDialog';
import message from '../message';

const { Search } = Input;
type Props = {
  id?: string;
  warehouse: WarehouseRow | undefined;
  placeholder?: string;
  value?: string;
  width?: number;
  onChange?: (location: WarehouseLocationRow) => void;
};
/**
 * @deprecated Please user SelectLocationPlus Component
 */
export default function SelectLocation(props: Props): JSX.Element {
  const { warehouse, onChange, value, width, id } = props;
  const [selectLocationVisible, setSelectLocationVisible] =
    useState<boolean>(false);
  const { placeholder } = props;
  /**
   * show select location dialog
   */
  const handleLocation = useCallback(() => {
    if (!warehouse) {
      message.warning({ content: 'Please Select Warehouse!' });
      return;
    }
    setSelectLocationVisible(true);
  }, [warehouse]);
  /**
   * handle select location
   */
  const handleLocationChange = useCallback(
    (location: WarehouseLocationRow) => {
      if ('function' === typeof onChange) {
        onChange(location);
      }
      setSelectLocationVisible(false);
    },
    [onChange]
  );
  let style = {};
  if (width) {
    style = {
      width,
    };
  }
  return (
    <span>
      <Search
        id={`${id}_search`}
        placeholder={placeholder || 'location'}
        value={value}
        style={style}
        onChange={(e) => {
          const { value: newValue } = e.target;
          if ('function' === typeof onChange) {
            onChange({ code: newValue } as any);
          }
        }}
        onSearch={handleLocation}
      />
      {warehouse && (
        <SelectLocationDialog
          visible={selectLocationVisible}
          warehouse={warehouse}
          onSelect={handleLocationChange}
          onHide={() => {
            setSelectLocationVisible(false);
          }}
        />
      )}
    </span>
  );
}
SelectLocation.defaultProps = {
  placeholder: undefined,
  value: undefined,
  width: undefined,
  onChange: undefined,
};
/**
 * valid location
 * @param {number} warehouseNum
 * @param {WarehouseLocationRow} location
 * @returns {Promise<WarehouseLocationRow|boolean>}
 */
export async function fullLocation(
  warehouseNum: number,
  warehouseId: string,
  location: WarehouseLocationRow
): Promise<WarehouseLocationRow | boolean> {
  let resultLocation = location;
  if (!location.id) {
    const locations = await fetchWarehouseLocationList(
      warehouseId,
      false,
      location.code
    );
    if (locations.length === 0) {
      //invalid location code
      message.warning({
        content: `Location with Code '${location.code}' does not exist.`,
      });
      return false;
    }
    [resultLocation] = locations as any;
  }
  return resultLocation;
}
