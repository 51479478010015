/**
 * reports service related requests are here.
 */
import { downloadCSV } from 'utils';
import { digitBridgeApi } from './http';
/**
 * get channel accounts.
 */
export const fetchReceiveHistory = async (query?: {
  [key: string]: any;
}): Promise<any> => {
  const params: StringKAnyVPair = {
    $skip: 0,
    $top: 10,
    $sortBy: 'TransactionDateTime',
    $count: true,
    type: 0,
    ...query,
  };
  return await digitBridgeApi.get('/api/reports/receiveHistory', { params });
};
/**
 * Fetch Locaitons Report
 * @param {number} warehouseNum
 * @param {number} type
 * @param {string} sortBy
 * @param {number} skip --  top row number of the page
 * @param {number} limit -- page size
 * @returns {Promise<LocationsReportEntity>}
 */
type LocationsReportParams = {
  warehouseId: string,
  type: number,
  sortBy: string,
  skip?: number,
  limit?: number,
  qty?: string,
  locationCode?: string,
  zoneNum?: number,
  capacityLength?: string,
  capacityWidth?: string,
  capacityHeight?: string,
  maxLoad?: string,
}
export async function fetchLocationsReport(
 query: LocationsReportParams,
): Promise<LocationsReportEntity> {
  const params: StringKAnyVPair = {
    $skip: query.skip || 0,
    $top: query.limit || 10,
    $sortBy: query.sortBy || 'warehouseCode',
    $count: true,
    type: query.type || 0,
    qty: query.qty || undefined,
    capacityLength: query.capacityLength || undefined,
    capacityWidth: query.capacityWidth || undefined,
    capacityHeight: query.capacityHeight || undefined,
    maxLoad: query.maxLoad || undefined,
    locationCode: query.locationCode || undefined,
    zoneNum: query.zoneNum || undefined,
  };
  const result = (await digitBridgeApi.get(
    `/api/reports/warehouses/${query.warehouseId}/locations`,
    { params }
  )) as any;
  if (query.type === 0) {
    return {
      count: result.count,
      data: result.data.map((item: any, index: number) => {
        return {
          id: index,
          ...item,
        };
      }),
    };
  }
  downloadCSV(result, 'locations-report');
  return '' as any;
}

/**
 * query checkout quantity
 * @param warehouseId 
 * @param productId 
 * @returns 
 */
export const queryCheckoutProducts = async (warehouseId: string, specialLocation: number, productId?: number): Promise<any> => {
  if (productId) {
    return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/checkouts/products/${productId}`, { params: { specialLocation } });
  } else {
    return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/checkouts/products/`, { params: { specialLocation } });
  }
};

export const queryCheckoutDetail = async (warehouseId: string, productId: number, startTime?: string, endTime?: string): Promise<any> => {
  const params = {
    startTime,
    endTime
  }
  return await digitBridgeApi.get(`api/warehouses/${warehouseId}/transactionHistories/${productId}`, { params })
};

export const queryReprintHistory = async (warehouseNum: number, params: any, json: string): Promise<any> => {
  return await digitBridgeApi.post(`api/shippinglabel/printlog/${warehouseNum}`, json, { params })
};

/**
 * query checkout quantity
 * @param params 
 * @returns 
 */
interface EmployeeShipmentRequest {
  dataSource: number;
  shipDate: string;
  employee?: string;
  group?: number;
  warehouseNum?: number;
}
export const fetchEmployeeShipment = async (params: EmployeeShipmentRequest): Promise<any> => {
  return await digitBridgeApi.get('api/reports/employeeShipment', { params })
}

interface ShipmentVoidHistoryParams {
  ShipmentId?: string,
  ChannelOrderId?: string,
  TrackingNumber?: string,
  Start?: string,
  End?: string,
  CarrierNum?: number,
  VoidBy?: string,
  Sorts?:
  {
    property: string,
    direction: string
  }[]
}
export const fetchShipmentVoidHistory = async (warehouseNum: number, pageIndex: number, pageSize: number, params: ShipmentVoidHistoryParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`api/shippinglabel/voidlog/${warehouseNum}`, json, { params: { pageIndex, pageSize } })
}

interface ShipmentPackageHistoryParams {
  Begin: string,
  End: string,
  WarehouseNum: number,
  ChannelAccountNum: number,
  Customer: string
}
export const fetchShipmentPackageHistory = async ($skip: number, $top: number, $sortBy:string, params: ShipmentPackageHistoryParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('api/reports/shipment/packages', json, { params: { $skip, $top, $count: true, $sortBy} })
}

export const exportShipmentPackageHistory = async (type: number, $sortBy:string, params: ShipmentPackageHistoryParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('api/reports/shipment/packages/export', json, { params: { type, $sortBy}, responseType: 'blob' })
}