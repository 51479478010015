import React, { useState, useCallback, useEffect } from 'react';
import { PrinterOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Input,
  Typography,
  Alert,
} from 'antd';
import { message, FormLabel, CellNumberInput } from 'components/common';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import {
  fetchWarehouseServiceList,
  fetchWarehousePackageList,
  fetchHeaderByCarrier,
} from 'services/shipmentContainers';
import { v4 as uuid } from 'uuid';
import { userProfiles } from 'utils/auth';
import { getProfileSettingValue } from 'utils';
import { RootState } from 'reducers';
import {
  DEFAULT_PRINT_MODE,
  // PRINT_CONTAINER_SHIPPING_LABEL,
  // REPRINT_CONTAINER_SHIPPING_LABEL,
  PRINT_MESSAGE_TIMEOUT
} from 'constants/config';
import {
  printContainerShippingLabel,
  reprintContainerShippingLabel,
} from 'actions/printActions';
import {
  // setPrintLabelResponse,
  setPrintLabelResult,
  setPrintLabelMessage,
} from 'actions/salesAction';
import SetDefaultPrinter from './SetDefaultPrinter';

const { Text } = Typography;
let timeoutId: any;

type PackageOption = {
  group: string;
} & ShipmentPackageRow;

interface ShippingLabelProps {
  onHide: (needUpdate?: boolean) => void;
  pack: any;
  warehouseNum: number;
  carrier: number;
  carrierName: string;
  printer?: Printer;
  onPrinterChanged: () => void;
}

// eslint-disable-next-line
export default (props: ShippingLabelProps) => {
  const { onHide, pack, printer, onPrinterChanged } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [serviceOptions, setServiceOptions] = useState<StringKAnyVPair[]>([]);
  const [packageOptions, setPackageOptions] = useState<PackageOption[]>([]);
  const [printerVisible, setPrinterVisible] = React.useState(false);
  // const printLabelResponse: StringKAnyVPair = useSelector(
  //   (state: RootState) => state.sales.printLabelResponse,
  //   shallowEqual
  // );
  const printLabelMessage: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelMessage,
    shallowEqual
  );
  const printLabelResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResult,
    shallowEqual
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getServices = useCallback(async () => {
    try {
      const services = await fetchWarehouseServiceList(props.warehouseNum);
      setLoading(false);

      if (Array.isArray(services) && services.length > 0) {
        setServiceOptions([...services]);
      }
    } catch (e) {
      message.error(`Fetch services error: ${e}`);
    }
  }, [props]);

  const serviceSelectGroups = (options: StringKAnyVPair[]) => {
    return options.map((e, i) => (
      <Select.OptGroup label={e.carrier} key={i}>
        {e.items.map((f: StringKAnyVPair) => (
          //<React.Fragment key={f.shipAccountNum}>
          <React.Fragment key={uuid()}>
            {e.items.length > 1 ? (
              <Select.Option
                className="scan-verify-ship-service-account-option"
                disabled
                //key={f.shipAccountNum}
                key={uuid()}
                label={f.shipAccountName}
                value={f.shipAccountNum}
              >
                {f.shipAccountName}
              </Select.Option>
            ) : (
              ''
            )}
            {serviceSelectOptions(f.items)}
          </React.Fragment>
        ))}
      </Select.OptGroup>
    ));
  };

  const serviceSelectOptions = (options: StringKAnyVPair[]) => {
    return options.map((e) => (
      <Select.Option
        className="scan-verify-ship-service-option"
        //key={e.carrierServiceNum}
        key={uuid()}
        label={e.carrierServiceName}
        //value={e.carrierServiceNum}
        value={uniqueServiceId(e)}
      >
        {e.carrierServiceName}
      </Select.Option>
    ));
  };

  const uniqueServiceId = (svr: StringKAnyVPair) => {
    return `${svr.shipAccountNum}_${svr.carrierServiceNum}`;
  };

  const generatePackageSelectionGroup = (
    //list: ShipmentPackageRow[],
    list: PackageOption[],
    groupLabel: string
  ) => {
    return list.length > 0 ? (
      <Select.OptGroup label={groupLabel} key={uuid()}>
        {list.map((e) => (
          <Select.Option
            className="scan-verify-ship-service-option"
            key={e.shippingPackageNum}
            label={e.name}
            value={e.shippingPackageNum}
          >
            {e.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    ) : (
      ''
    );
  };

  const getBeforeServicePackage = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchHeaderByCarrier(4);
      setLoading(false);
      if (res) {
        form.setFieldsValue({
          Service: (res.shipAccountNum && res.carrierServiceNum) ? `${res.shipAccountNum}_${res.carrierServiceNum}` : undefined,
          // Package: res.shipPackageNum? res.shipPackageNum : undefined,
        });

      }
    } catch (error) {
      setLoading(false);
    }
  }, [form]);

  const packageSelectOptions = (options: PackageOption[]) => {
    const list1 = options.filter((e) => e.group === props.carrierName);
    const list2 = options.filter((e) => e.group !== props.carrierName);

    return (
      <>
        {generatePackageSelectionGroup(list1, props.carrierName)}
        {generatePackageSelectionGroup(list2, 'Custom')}
      </>
    );
  };

  const getPackages = useCallback(async () => {
    try {
      console.log(props);
      const pkg = await fetchWarehousePackageList(
        props.warehouseNum,
        props.carrier
      );

      if (pkg) {
        const { carrierPackages, customPackages } = pkg;
        let packages: PackageOption[] = [];

        if (Array.isArray(carrierPackages)) {
          const tpkgs = carrierPackages.map((e) => ({
            ...e,
            group: props.carrierName,
          }));
          packages = [...packages, ...tpkgs];
        }

        if (Array.isArray(customPackages)) {
          const tpkgs = customPackages.map((e) => ({ ...e, group: 'Custom' }));
          packages = [...packages, ...tpkgs];
        }

        if (Array.isArray(packages) && packages.length > 0) {
          setPackageOptions([...packages]);
        }
      }
    } catch (e) {
      message.error(`Fetch packages error: ${e}`);
    }
  }, [props]);

  const handlePrintShippingLabel = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const profile = userProfiles.profile;
    try {
      if (!printer) {
        return message.warning({ content: 'Set default print first.' });
      }
      const printMode = await getProfileSettingValue(
        'PrintStationMode',
        DEFAULT_PRINT_MODE
      );
      setSubmitLoading(true);
      if (printMode === DEFAULT_PRINT_MODE) {
        if ([1, 2, 8].includes(pack?.status)) {
          dispatch(
            reprintContainerShippingLabel({
              ShipmentContainerNum: pack?.shipmentContainerNum,
              MasterAccountNum: profile.masterAccountNum,
              PrinterAccountNum: printer.printerAccountNum,
              PrinterNum: printer.printerNum,
              PrinterName: printer.printerName,
              ProfileNum: profile.profileNum,
            })
          );
        } else {
          dispatch(
            printContainerShippingLabel({
              ShipmentContainerNum: pack?.shipmentContainerNum,
              ShipAccountNum: Number(params.Service.split('_')[0]),
              PrinterAccountNum: printer.printerAccountNum,
              PrinterNum: printer.printerNum,
              PrinterName: printer.printerName,
              ShipperAddressNum: pack?.shipperAddressNum,
              ConsigneeAddressNum: pack?.consigneeAddressNum,
              ShipServiceNum: Number(params.Service.split('_')[1]),
              ShipPackageNum: params.Package,
              Length: params.Length,
              Width: params.Width,
              Height: params.Height,
              Weight: params.LB + params.OZ * 0.0625,
              BillToType: 0,
              BillThirdPartyAccount: '',
              BillThirdPartyCountryNum: 0,
              BillThirdPartyCountry: '',
              BillThirdPartyPostalCode: '',
              DutyBillToType: null,
              DutyPayorAccount: null,
              DutyPayorCountryNum: null,
              DutyPayorCountry: null,
              IncludeAlcohol: false,
              SaturdayDelivery: false,
              Code: false,
              CodType: 0,
              CodAmount: null,
              CodCurrency: null,
              Confirmation: -2,
              MaxShippingCost: 0,
              MasterAccountNum: profile.masterAccountNum,
              ProfileNum: profile.profileNum,
            })
          );
        }
      } else {
        setSubmitLoading(false);
      }
    } catch (err) {
      setSubmitLoading(false);
      console.log(err);
    }
  }, [form, dispatch, pack, printer]);

  const handleCancel = () => {
    if (submitLoading) return;
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  React.useEffect(() => {
    if (printLabelResult.code) {
      dispatch(setPrintLabelResult({}));
      if (!printLabelResult.isSuccess) {
        setSubmitLoading(false);
        message.error({ content: printLabelResult.message });
      } else {
        if ([1, 2, 8].includes(pack?.status)) {
          message.info({ content: 'Reprint job has been generate' });
          onHide();
        }
      }
    }
  }, [printLabelResult, dispatch, onHide, pack]);

  React.useEffect(() => {
    if (printLabelMessage) {
      // console.log('printLabelMessage', printLabelMessage);
      if (printLabelMessage.TrackingNumber) {
        setTimeout(() => {
          setSubmitLoading(false);
          message.success({
            content: 'Print job has been generated',
          });
          onHide(true);
          dispatch(setPrintLabelMessage({}));
        });
      }
    }
  }, [printLabelMessage, dispatch, onHide]);

  // useEffect(() => {
  //   if (printLabelResponse) {
  //     // console.log('printLabelResponse', printLabelResponse);
  //     if (
  //       printLabelResponse.id && printLabelResponse.sender==='PrintStation' &&
  //       printLabelResponse.type === REPRINT_CONTAINER_SHIPPING_LABEL
  //     ) {
  //       setTimeout(() => {
  //         onHide(true)
  //         dispatch(setPrintLabelResponse({}));
  //       });
  //     }
  //   }
  // }, [printLabelResponse, dispatch, onHide]);

  useEffect(() => {
    getServices();
    getPackages();
  }, [getPackages, getServices]);

  useEffect(() => {
    if (!pack?.trackingNumber) {
      getBeforeServicePackage()
    }
  }, [getBeforeServicePackage, pack]);

  useEffect(() => {
    if (submitLoading) {
      timeoutId = setTimeout(() => {
        setSubmitLoading(false)
        message.warning({ content: 'Possible errors in  printing, you can reprint it later.' })
      }, PRINT_MESSAGE_TIMEOUT)
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [submitLoading])

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  return (
    <Modal
      title={
        [1, 2, 8].includes(pack?.status)
          ? 'Reprint Shipping Label'
          : 'Print Shipping Label'
      }
      centered
      closable={!submitLoading}
      visible={true}
      maskClosable={false}
      width={700}
      destroyOnClose={true}
      bodyStyle={{ padding: 12 }}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              type="primary"
              disabled={!printer}
              loading={submitLoading}
              onClick={() => {
                handlePrintShippingLabel();
              }}
            >
              <PrinterOutlined />
              {[1, 2, 8].includes(pack?.status)
                ? 'Reprint Shipping Label'
                : 'Print Shipping Label'}
            </Button>
          </Space>
          <Space>
            <Button disabled={submitLoading} onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading || submitLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form form={form} {...layout}>
          {!printer && (
            <Alert
              type="warning"
              showIcon
              message="Please set default print first."
              action={
                <Button size="small" type="text" onClick={() => setPrinterVisible(true)}>
                  Set now
                </Button>
              }
            />
          )}
          <Form.Item label={<FormLabel>Container ID</FormLabel>}>
            <Text strong>{pack?.containerId}</Text>
          </Form.Item>

          <Row
            justify="start"
            style={{ marginBottom: 10, marginTop: 10, paddingLeft: 225 }}
          >
            <Space>
              <Form.Item
                noStyle
                initialValue={parseInt(pack?.weight)}
                name="LB"
                rules={[
                  {
                    required: true,
                    message: 'Weight is required',
                  },
                ]}
              >
                <CellNumberInput
                  disabled={[1, 2, 8].includes(pack?.status)}
                  align="center"
                  style={{ width: 150 }}
                  fontSize={48}
                  height={50}
                  min={0}
                  paddingleft={4}
                  precision={0}
                />
              </Form.Item>
              <span className="weight-unit">(LB)</span>
              <Form.Item
                noStyle
                initialValue={(pack?.weight - parseInt(pack?.weight)) * 16}
                name="OZ"
                rules={[
                  {
                    required: true,
                    message: 'Weight is required',
                  },
                ]}
              >
                <CellNumberInput
                  disabled={[1, 2, 8].includes(pack?.status)}
                  align="center"
                  style={{ width: 150 }}
                  fontSize={48}
                  height={50}
                  min={0}
                  paddingleft={4}
                  precision={1}
                />
              </Form.Item>
              <span className="weight-unit">(OZ)</span>
            </Space>
          </Row>

          <Form.Item
            label={<FormLabel>Service</FormLabel>}
            name="Service"
            initialValue={
              pack?.carrierServiceNum
                ? `${pack?.shipAccountNum}_${pack?.carrierServiceNum}`
                : undefined
            }
            rules={[
              {
                required: true,
                message: 'Service Address is required',
              },
            ]}
          >
            <Select
              disabled={[1, 2, 8].includes(pack?.status)}
              style={{ width: '100%' }}
            >
              {serviceSelectGroups(serviceOptions)}
            </Select>
          </Form.Item>

          <Form.Item
            label={<FormLabel>Package</FormLabel>}
            name="Package"
            initialValue={pack?.shipPackageNum}
            rules={[
              {
                required: true,
                message: 'Package is required',
              },
            ]}
          >
            <Select
              disabled={[1, 2, 8].includes(pack?.status)}
              style={{ width: '100%' }}
            >
              {packageSelectOptions(packageOptions)}
            </Select>
          </Form.Item>

          <Form.Item
            label={<FormLabel>{`Size (in)`}</FormLabel>}
          // rules={[{ required: true, message: '' }]}
          >
            <Input.Group compact>
              <Form.Item
                noStyle
                initialValue={pack?.length}
                name="Length"
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  disabled={[1, 2, 8].includes(pack?.status)}
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="L"
                />
              </Form.Item>
              <Form.Item
                noStyle
                initialValue={pack?.width}
                name="Width"
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  disabled={[1, 2, 8].includes(pack?.status)}
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="W"
                />
              </Form.Item>
              <Form.Item
                noStyle
                initialValue={pack?.height}
                name="Height"
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  disabled={[1, 2, 8].includes(pack?.status)}
                  type="number"
                  min={0}
                  style={{ width: '33%' }}
                  addonBefore="H"
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </Spin>
      {printerVisible && (
        <SetDefaultPrinter
          visible
          onHide={() => setPrinterVisible(false)}
          onSaved={() => {
            setPrinterVisible(false);
            onPrinterChanged();
          }}
        />
      )}
    </Modal>
  );
};
