import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Button, Space, Typography, Checkbox, Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { downloadCSV, convertTimeByUtc } from 'utils';
import message from 'components/common/message';
import Modal from 'components/common/Modal';
import moment from 'moment';
import { SectionWrapper } from 'components/common/styles/CustomPropsComponents';
import { exportBulkReturn } from 'services/return'

const { Text } = Typography;
type ExportProps = {
  style?: React.CSSProperties;
  title?: string;
  scope: string;
  data: Array<any>;
  columns: Array<StringKAnyVPair>;
  downloadFromApi?: (value?: any) => void;
  id?: string;
  formatColumns?: any;
  disabled?: boolean;
  aliasText?: string;
};
export default function ExportCSV(props: ExportProps): JSX.Element {
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);
  const [visible, setVisible] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [exportLoading, setExportLoading] = useState(false)
  const { data, title, style, scope, downloadFromApi, id, formatColumns } = props;
  const columns = useMemo(()=>[
    ...props.columns,
    {
      header: 'SKU',
      name: 'sku',
    },
    {
      header: 'UPC',
      name: 'upc',
    }
  ],[props.columns])
  useEffect(() => {
    if (data.length === 0 && visible) {
      message.warning('DataGrid No Data!');
    }
  }, [data, visible]);


  const downLoadCSVFile = useCallback(async () => {

    setExportLoading(true);
    try {
      const captions = [] as any;
      const fields = [] as any;
      selectedColumns.forEach((dataIndex: string) => {
        const column = columns.find((item) => {
          if (item.name === dataIndex || item.dataIndex === dataIndex) {
            return item;
          }
          return null;
        });
        if (column) {
          captions.push(column.header || column.title);
          fields.push(column.name)
        }
      });
      const res = await exportBulkReturn({
        fields,
        captions,
        returnNums: data.map(i=> i.fulfillmentBulkReturnNum)
      })
      const content = res;
      const blob = new Blob([content], { type: 'text/csv' });
      const fileName = `CustomerReturn.csv`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('err = ', error);
    }
    setExportLoading(false);
  },[columns, selectedColumns, data])

  /**
   * handle checkbox item click event
   */
  const handleChange = useCallback(
    (id: string, e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      if (checked) {
        //add item
        const existed = selectedColumns.find((currentId) => {
          if (currentId === id) {
            return currentId;
          }
          return null;
        })
          ? true
          : false;
        if (!existed) {
          selectedColumns.push(id);
        }
        setSelectedColumns(JSON.parse(JSON.stringify(selectedColumns)));
        setCheckAll(true);
      } else {
        //remove item
        const newList = selectedColumns.filter((currentId) => {
          if (currentId === id) {
            return false;
          }
          return true;
        });
        setCheckAll(newList.length > 0);
        setSelectedColumns(JSON.parse(JSON.stringify(newList)));
      }
    },
    [selectedColumns]
  );
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    setVisible(false);
  }, []);
  /**
   * handle download event
   */
  const handleDownload = useCallback(() => {
    if(selectedColumns.filter(i=> i==='sku' || i ==='upc').length > 0){
      downLoadCSVFile()
    }else{
      const headers = [] as any;
      const lines = [] as any;
      selectedColumns.forEach((dataIndex: string) => {
        const column = columns.find((item) => {
          if (item.name === dataIndex || item.dataIndex === dataIndex) {
            return item;
          }
          return null;
        });
        if (column) {
          headers.push(column.header || column.title);
        }
      });
      lines.push(headers.join(','));
      data.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          value = (formatColumns && formatColumns[dataIndex]) ? formatColumns[dataIndex][`${row[dataIndex]}`] : row[dataIndex];
          if (isNaN(Number(value)) && moment.utc(value).isValid() && (dataIndex.endsWith('Date') || dataIndex.endsWith('DateTime'))) {
            value = convertTimeByUtc(value);
          }
          if (typeof value === 'string') {
            if (value.includes(',')) {
              if (value.includes('"')) {
                value = value.replace(/"/g, '""');
              }
              value = `"${value}"`;
            }
          } else {
            value = (value || value === 0) ? `"${value}"` : '';
          }
          items.push(value);
        });
        lines.push(items.join(','));
      });
      downloadCSV(lines.join('\n'), scope);
    }
  }, [data, selectedColumns, columns, scope, formatColumns, downLoadCSVFile]);
  /**
   * handle checkAll event
   */
  const handleCheckAll = useCallback(
    (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      const checkedList: Array<string> = [];
      if (checked) {
        columns.forEach((item: any) => {
          checkedList.push(item.dataIndex || item.name);
        });
      }
      setCheckAll(checked);
      setSelectedColumns(checkedList);
    },
    [columns]
  );
  useEffect(() => {
    const checkedList: Array<string> = [];
    props.columns.forEach((item) => {
      checkedList.push(item.dataIndex || item.name);
    });
    setSelectedColumns(checkedList);
  }, [props.columns]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          {downloadFromApi && (
            <Button
              key="submit"
              type="default"
              disabled={selectedColumns.length === 0}
              onClick={() => {
                const data: { [key: string]: string } = {};
                selectedColumns.map((i) => {
                  const column = columns.find((item) => {
                    if (item.name === i || item.dataIndex === i) {
                      return item;
                    }
                    return null;
                  });
                  if (column) {
                    data[`${i}`] = column.title || column.header;
                  }
                  return true;
                });
                if (downloadFromApi) {
                  downloadFromApi(data);
                }
              }}
              icon={<CloudDownloadOutlined />}
            >
              <span>Export Search Result</span>
            </Button>
          )}
          <Button
            id={id}
            key="submit"
            type="primary"
            loading={exportLoading}
            disabled={selectedColumns.length === 0}
            onClick={handleDownload}
            icon={<CloudDownloadOutlined />}
          >
            <span>{props.aliasText || 'Download Current Page'}</span>
          </Button>
          <Button onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <>
      <Modal
        title={title || 'Export CSV'}
        centered
        visible={visible}
        width={600}
        destroyOnClose
        onCancel={handleHide}
        footer={<Footer />}
      >
        <SectionWrapper bordered={true}>
          <Row justify="center">
            <Space>
              <Text>Toggle All Columns</Text>
              <Checkbox checked={checkAll} onChange={handleCheckAll} />
            </Space>
          </Row>
        </SectionWrapper>
        <Row style={{ marginTop: 10 }}>
          {columns.map((item: StringKAnyVPair, index) => {
            const checked = selectedColumns.find((currentItemId) => {
              if (currentItemId === (item.dataIndex || item.name)) {
                return currentItemId;
              }
              return null;
            })
              ? true
              : false;
            return (
              <Col key={index} span={8}>
                <Space>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      handleChange(item.dataIndex || item.name, e);
                    }}
                  />
                  <Text>{item.title || item.header}</Text>
                </Space>
              </Col>
            );
          })}
        </Row>
      </Modal>
      <Button
        style={style}
        disabled={props.disabled}
        onClick={() => {
          setVisible(true);
        }}
      >
        <CloudDownloadOutlined />
        <span>Export CSV</span>
      </Button>
    </>
  );
}
ExportCSV.defaultProps = {
  style: undefined,
  title: undefined,
};
