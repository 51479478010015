import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { ButtonIcon } from 'components/common';
// import { playAudio } from 'utils';
import { SmartRow, SmartScanner, SmartButton } from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type FinishDialogProps = {
  product: SmartPickSKU;
  onSubmit: (location: string) => void;
};
export default function FinishDialog(props: FinishDialogProps): JSX.Element {
  const { product, onSubmit } = props;
  const { t } = useTranslation();
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    (nextValue: string) => {
      onSubmit(nextValue);
    },
    [onSubmit]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    const { sku } = product;
    return (
      <SmartRow justify="center">
        <ButtonIcon className="bi-emoji-smile" />
        <div style={{ marginLeft: 5 }}>{`${sku} was done!`}</div>
      </SmartRow>
    );
  };

  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        {t("smart.pick.finishTip")}
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }}>
        <SmartScanner onChangeValue={handleScannerChange} />
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            handleScannerChange('');
          }}
        >
          {t("smart.pick.nextSkuUpc")}
        </SmartButton>
      </SmartRow>
    </Modal>
  );
}
