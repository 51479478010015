import React, { useCallback, useEffect, useState } from 'react';
import ScanItem from './ScanItem';
import ScanLocation from './ScanLocation';
import { SmartSpin } from '../../SmartComponent';
import {
  LS_DEFAULT_WAREHOUSE_KEY,
  SMART_SHOW_LOCATIONS_RETURN,
} from 'constants/config';
import { SetDefaultWarehouseDialog } from 'components/common';
import NotFoundLocationDialog from './NotfoundLocationDialog';
import NotFoundItemDialog from './NotfoundItemDialog';
import FinishDialog from './FinishDialog';
import { scanSkuReturn } from 'services/smart';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  getWarehouseIdFromCache,
  getWarehouseCodeFromCache,
  getVirtualWarehouseIdFromCache,
  getVirtualWarehouseCodeFromCache,
} from 'utils';

export default function ReturnToLocation(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanItem' | 'scanLocaiton'>('scanItem');
  const [product, setProduct] = useState<any>();
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  // const [currentItem, setCurrentItem] = useState('');
  const [notFoundLocationDialogVisible, setNotFoundLocationDialogVisible] =
    useState(false);
  const [notFoundItemDialogVisible, setNotFoundItemDialogVisible] =
    useState(false);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [focusNum, setFocusNum] = useState(1);
  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };
  const [showLocations, setShowLocations] = useState(
    localStorage.getItem(SMART_SHOW_LOCATIONS_RETURN) === 'false' ? false : true
  );

  React.useEffect(() => {
    localStorage.setItem(
      SMART_SHOW_LOCATIONS_RETURN,
      showLocations ? 'true' : 'false'
    );
  }, [showLocations]);

  const refreshProduct = useCallback(async () => {
    if (product && product.sku) {
      try {
        setFetching(true);
        const res = await scanSkuReturn(warehouseId, product.sku);
        setFetching(false);
        if (res.isSuccess) {
          setProduct(res.data);
        }
      } catch (error) {
        setFetching(false);
      }
    }
  }, [product, warehouseId]);

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <SmartSpin loading={fetching} />
      {step === 'scanItem' && (
        <ScanItem
          showLocations={showLocations}
          setShowLocations={setShowLocations}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          vWarehouseId={getVirtualWarehouseIdFromCache(warehouseNum, 2)}
          vWarehouseCode={getVirtualWarehouseCodeFromCache(warehouseNum, 2)}
          onSubmit={(nextProduct: any) => {
            setProduct(nextProduct);
            setStep('scanLocaiton');
          }}
        />
      )}
      {step === 'scanLocaiton' && warehouseId && (
        <ScanLocation
          showLocations={showLocations}
          product={product}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          focusNum={focusNum}
          onSubmit={(status, nextLocation) => {
            if (status === 1) {
              refreshProduct();
              setCurrentLocation(nextLocation);
              setFinishDialogVisible(true);
            }
            if (status === 2) {
              setCurrentLocation(nextLocation);
              setNotFoundLocationDialogVisible(true);
            }
            if (status === 3) {
              setNotFoundItemDialogVisible(true);
            }
          }}
          onBack={() => {
            setProduct(undefined);
            setStep('scanItem');
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setFetching(false);
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
      {notFoundLocationDialogVisible && (
        <NotFoundLocationDialog
          location={currentLocation}
          product={product}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          onSubmit={(status, location) => {
            setNotFoundLocationDialogVisible(false);
            if (status === 1) {
              setCurrentLocation(location);
              setFinishDialogVisible(true);
            }
            if (status === 3) {
              setCurrentLocation(location);
              setNotFoundItemDialogVisible(true);
            }
          }}
          onBack={() => {
            setNotFoundLocationDialogVisible(false);
            setCurrentLocation('');
          }}
        />
      )}
      {notFoundItemDialogVisible && (
        <NotFoundItemDialog
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          product={product}
          onSubmit={(data) => {
            setProduct(data);
            setNotFoundItemDialogVisible(false);
            setStep('scanLocaiton');
            setFocusNum((prev) => prev + 1);
          }}
          onBack={() => {
            setNotFoundItemDialogVisible(false);
            setProduct(undefined);
            setStep('scanItem');
          }}
        />
      )}
      {finishDialogVisible && (
        <FinishDialog
          showLocations={showLocations}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          vWarehouseId={getVirtualWarehouseIdFromCache(warehouseNum, 2)}
          vWarehouseCode={getVirtualWarehouseCodeFromCache(warehouseNum, 2)}
          product={product}
          location={currentLocation}
          onSubmit={(data) => {
            setProduct(data);
            setStep('scanLocaiton');
            setFinishDialogVisible(false);
            setFocusNum((prev) => prev + 1);
          }}
          onBack={() => {
            setFinishDialogVisible(false);
            setProduct(undefined);
            setStep('scanItem');
          }}
        />
      )}
    </div>
  );
}
