import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  // SaveOutlined,
  // EditOutlined,
  PlusOutlined,
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Input,
  Col,
  Card,
  Typography,
  Table,
  Popconfirm,
} from 'antd';
import { message, FormLabel } from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import {
  editShipmentContainers,
  AddShipmentContainers,
  fetchHeaderByCarrier,
  uploadShipmentContainer,
  voidShippingLable,
  getShipmentContainerById,
  resetShippingLable,
  checkTrackingNumber,
} from 'services/shipmentContainers';
// import CreateAddressDialog from 'components/Admin/AddressBook/createDialog';
// import { fetchShipmentsList } from 'services/sales';
import ShippingLabelDialog from './ShippingLableDialog';
import {
  WEIGHT_UNITS as weightUnitEnum,
  DIMENSION_UNITS as unitEnum,
} from 'constants/config';

const { Text } = Typography;
const statusEnum: any = Object.freeze({
  '0': 'Draft',
  '1': 'Shipping Label Created',
  '2': 'Appointment',
  '7': 'Printing',
  '8': 'Abnormal',
  '9': 'Cancel',
});

interface ShipmentContainer {
  bol?: any;
  carrierNum?: number;
  carrierServiceName?: string;
  carrierServiceNum?: number;
  consigneeAddressNum?: number;
  containerId?: string;
  dimensionUnit?: number;
  fedExTime?: any;
  fee?: any;
  height?: number;
  length?: number;
  trackingNumber?: string;
  shipmentContainerId?: string;
  shipmentContainerLines?: any[];
  shipmentContainerNum?: number;
  shipperAddressNum?: number;
  status?: number;
  uploadTime?: any;
  weight?: number;
  weightUnit?: number;
  width?: number;
  warehouseNum?: number;
  packageShippingAccountNum?: number;
}

interface AddProps {
  onHide: (needUpdate?: boolean) => void;
  originPack?: ShipmentContainer;
  carriers: any[];
  addressBook: any[];
  shipAccounts: any[];
  printer?: Printer;
  onPrinterChanged: () => void;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { onHide, originPack, carriers, addressBook, printer, shipAccounts, onPrinterChanged } =
    props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initing, setIniting] = useState(false);
  const [pack, setPack] = useState<ShipmentContainer>();
  const [selectedCarrier, setSelectedCarrier] = useState<number>(4);
  // const [loading1, setLoaing1] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressRow>();
  const [selectedAddress1, setSelectedAddress1] = useState<AddressRow>();
  const [shipmentContainerId, setShipmentContainerId] = useState<string>();
  // const [selectedShipAccount, setSelectedShipAccount] = useState<number>(0);
  const [status, setStatus] = useState<number>(0);
  const [shippingLabelVisible, setShippingLabelVisible] = React.useState(false);
  const [lines, setLines] = useState<any[]>([]);
  const scanInputRef = useRef<any>();
  const [scanValue, setScanValue] = useState<string>();
  const [hadChanged, setHadChanged] = useState(false); // need refresh index/table
  const [hadChangedAfterSave, setHadChangedAfterSave] = useState(false);
  const isAdding = useRef(false);

  const [form] = Form.useForm();

  const initData = useCallback(
    (data: ShipmentContainer) => {
      setIniting(true);
      setPack(data);
      setHadChangedAfterSave(false);
      setSelectedCarrier(data.carrierNum || 4);
      // setSelectedShipAccount(data.packageShippingAccountNum || 0)
      setSelectedAddress(
        addressBook.filter((i) => i.addressNum === data.shipperAddressNum)[0]
      );
      setSelectedAddress1(
        addressBook.filter((i) => i.addressNum === data.consigneeAddressNum)[0]
      );
      setLines(data.shipmentContainerLines || []);
      setStatus(data.status || 0);
      setShipmentContainerId(data.shipmentContainerId);
      setIniting(false);
    },
    [
      setPack,
      setSelectedCarrier,
      setSelectedAddress,
      setSelectedAddress1,
      setLines,
      setStatus,
      setShipmentContainerId,
      addressBook,
      setIniting,
      setHadChangedAfterSave,
    ]
  );

  const refreshPage = React.useCallback(async () => {
    try {
      setLoading(true);
      const res = await getShipmentContainerById(
        pack?.shipmentContainerId || ''
      );
      setLoading(false);
      if (res) {
        initData({ ...pack, ...res });
      }
    } catch (error) {
      setLoading(false);
    }
  }, [pack, initData]);

  const uploadContainer = React.useCallback(
    async (data: any) => {
      try {
        const res = await uploadShipmentContainer(data.shipmentContainerNum);
        if (res.isSuccess) {
          message.success({ content: 'confirm forecast successfully' });
          setHadChanged(true);
          refreshPage();
        }
      } catch (error) { }
    },
    [refreshPage]
  );

  const getBeforeAddress = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchHeaderByCarrier(4);
      setLoading(false);
      if (res) {
        form.setFieldsValue({
          ShipperAddressNum: res.shipperAddressNum,
          ConsigneeAddressNum: res.consigneeAddressNum,
        });
        setSelectedAddress(
          addressBook.filter((i) => i.addressNum === res.shipperAddressNum)[0]
        );
        setSelectedAddress1(
          addressBook.filter((i) => i.addressNum === res.consigneeAddressNum)[0]
        );
      }
    } catch (error) {
      setLoading(false);
    }
  }, [addressBook, form]);

  useEffect(() => {
    if (!originPack) {
      getBeforeAddress();
    }
    initData(originPack ? originPack : {});
  }, [originPack, initData, getBeforeAddress]);

  const handleAddPackage = useCallback(async () => {
    if (!scanValue || !scanValue.trim()) {
      scanInputRef.current.focus();
      return message.info({
        content: 'Please input value',
      });
    }
    if (!Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))) {
      return message.info({
        content: 'Please set default warehouse first',
      });
    }
    if (isAdding.current) return
    try {
      isAdding.current = true;
      const res = await checkTrackingNumber(scanValue || '', Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)), selectedCarrier)
      if (res && !res.error) {
        if (
          lines.findIndex(
            (i) => i.shipmentNum === res.shipmentNum
          ) < 0
        ) {
          setLines((prev) => [
            ...prev,
            {
              shipmentNum: res.shipmentNum,
              channelOrderId: res.channelOrderId,
              trackingNumber: res.trackingNumber,
              // packageNum: res.data.data[0].packageNum,
            },
          ]);
          if (pack) {
            setHadChangedAfterSave(true)
          }
          setScanValue(undefined);
          message.info({
            content: 'added successfully',
          });
          scanInputRef.current.blur();
          scanInputRef.current.focus();
        } else {
          message.info({
            content: `Cannot be added repeatedly`,
          });
        }
      } else {
        setScanValue(undefined);
        message.info({
          content: 'no match shipments',
        });
        scanInputRef.current.blur();
        scanInputRef.current.focus();
      }
      isAdding.current = false;
    } catch (error: any) {
      isAdding.current = false;
      message.error({ content: error?.response?.data?.error })
    }
  }, [scanInputRef, selectedCarrier, lines, scanValue, pack]);

  const handleDeletePackage = useCallback(async (selectedLine: any) => {
    setLines((prev) => {
      const temp = prev.filter(
        (i) => i.shipmentNum !== selectedLine.shipmentNum
      );
      return [...temp];
    });
    if (pack) {
      setHadChangedAfterSave(true)
    }
  }, [pack]);

  const voidLabel = useCallback(async () => {
    try {
      const res = await voidShippingLable(pack?.shipmentContainerNum || -1);
      if (res.isSuccess) {
        setHadChanged(true);
        message.success({ content: 'Void shipping lable successfully' });
        // setPack((prev) => {
        //   return { ...prev, status: 0 };
        // });
        // setStatus(0);
        refreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  }, [refreshPage, pack]);

  const handleSaveSubmit = async (after?: boolean) => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (
      params.ShipperAddressNum &&
      params.ShipperAddressNum === params.ConsigneeAddressNum
    ) {
      message.warning({
        content: "The shipper's address must diff with the consignee's address",
      });
      return;
    }

    try {
      setSubmitLoading(true);
      let res;

      if (!shipmentContainerId) {
        res = await AddShipmentContainers({
          ...params,
          CarrierNum: selectedCarrier,
          ShipmentContainerLines: lines.map((i: any) => {
            return {
              ShipmentNum: i.shipmentNum,
              TrackingNumber: i.trackingNumber,
            };
          }),
          DimensionUnit: 0,
          WeightUnit: 0,
          WarehouseNum: Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
        });
      } else {
        if (!pack) return;
        res = await editShipmentContainers(pack?.shipmentContainerId || '', {
          Length: pack.length,
          Width: pack.width,
          Height: pack.height,
          Weight: pack.weight,
          ContainerId: params.ContainerId,
          WarehouseNum:
            pack?.warehouseNum ||
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
          ShipperAddressNum: params.ShipperAddressNum,
          ConsigneeAddressNum: params.ConsigneeAddressNum,
          DimensionUnit: pack?.dimensionUnit,
          WeightUnit: pack?.weightUnit,
          BOL: pack?.bol,
          Fee: pack?.fee,
          FedExTime: pack?.fedExTime,
          ShipmentContainerLines: lines.map((i) => {
            return {
              ShipmentNum: i.shipmentNum,
              TrackingNumber: i.trackingNumber,
            };
          }),
        });
      }
      setSubmitLoading(false);
      setHadChanged(true);
      if (res.error) {
        message.error({ content: res.error });
      } else {
        message.success({
          content: shipmentContainerId
            ? 'Saved successfully'
            : 'Created successfully',
        });
        initData(res);
        if (after) {
          setShippingLabelVisible(true);
        }
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel && !hadChangedAfterSave) {
      onHide(hadChanged);
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide(hadChanged);
        },
      });
    }
  };

  const resetContainer = React.useCallback(async () => {
    try {
      const res = await resetShippingLable(pack?.shipmentContainerNum || -1);
      if (res.isSuccess) {
        setHadChanged(true);
        message.success({ content: 'reset state successfully' });
        refreshPage();
      }
    } catch (error) { }
  }, [refreshPage, pack]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const columns = [
    {
      title: 'Channel Order ID',
      dataIndex: 'channelOrderId',
      key: 'channelOrderId',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
    },
    {
      title: 'Shipment Num',
      dataIndex: 'shipmentNum',
      key: 'shipmentNum',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space>
          <Popconfirm
            disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
            placement="rightTop"
            title="Are you sure to delete this row?"
            onConfirm={() => handleDeletePackage(record)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ className: 'danger-btn', icon: <CheckOutlined /> }}
            cancelButtonProps={{ icon: <CloseOutlined /> }}
          >
            <Button type="link" className="hover-danger-button-2">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Modal
      // title="Collection Forecast"
      title={
        <Row justify="space-between" style={{ height: 32 }}>
          <Col span={12}>
            <Text>Collection Forecast</Text>
          </Col>

          <Col span={12}>
            {pack && (
              <Form.Item
                {...layout}
                label={<FormLabel>Container Status</FormLabel>}
              >
                <Text>{statusEnum[`${pack?.status}`]}</Text>
              </Form.Item>
            )}
          </Col>
        </Row>
      }
      centered
      visible={true}
      maskClosable={false}
      width={1200}
      destroyOnClose={true}
      bodyStyle={{ padding: 12, minHeight: 400 }}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            {shipmentContainerId && status === 0 && (
              <Button
                type="primary"
                onClick={() => handleSaveSubmit()}
                loading={submitLoading}
              >
                Save Container
              </Button>
            )}

            {!shipmentContainerId && status === 0 && (
              <Button
                type="primary"
                onClick={() => handleSaveSubmit()}
                loading={submitLoading}
              >
                Create Container
              </Button>
            )}

            {shipmentContainerId && status === 7 && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: 'Are you sure you want to reset state ?',
                    okText: 'Yes',
                    content: `Container ID: ${pack?.containerId}`,
                    onOk: async () => {
                      await resetContainer();
                    },
                  });
                }}
              >
                Reset State
              </Button>
            )}

            {shipmentContainerId && (status === 1 || status === 2) && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title:
                      status === 2
                        ? 'This container has been forecast. are you sure you want to void shipping label ?'
                        : 'Are you sure you want to void shipping label ?',
                    okText: 'Yes',
                    content: `Container ID: ${pack?.containerId}`,
                    onOk: async () => {
                      await voidLabel();
                    },
                  });
                }}
                loading={submitLoading}
              >
                Void Shipping Label
              </Button>
            )}

            {status === 0 && (
              <Button
                type="primary"
                disabled={
                  !shipmentContainerId ||
                  (pack?.shipmentContainerLines || []).length === 0
                }
                onClick={() => {
                  if (hadChangedAfterSave || form.isFieldsTouched()) {
                    handleSaveSubmit(true)
                  } else {
                    setShippingLabelVisible(true);
                  }
                }}
              >
                Container Shipping Label
              </Button>
            )}

            {(status === 1 || status === 2) && (
              <Button
                type="primary"
                onClick={() => {
                  setShippingLabelVisible(true);
                }}
              >
                Reprint Container Shipping Label
              </Button>
            )}

            <Button
              type="primary"
              disabled={!(status === 1 && shipmentContainerId)}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to confirm forecast ?',
                  okText: 'Yes',
                  content: `Container ID: ${pack?.containerId}`,
                  onOk: async () => {
                    await uploadContainer(pack);
                  },
                });
              }}
            >
              Confirm Forecast
            </Button>
          </Space>
          <Space>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        style={{ minHeight: 300 }}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {!(initing || submitLoading) && pack && (
          <>
            <Form {...layout} form={form}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="PackageShippingAccountNum"
                    initialValue={pack?.packageShippingAccountNum}
                    label={<FormLabel>Shipping Account</FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a Shipping Account!',
                      },
                    ]}
                  >
                    <Select
                      disabled={shipmentContainerId ? true : false}
                    // onChange={(value) => {
                    //   console.log(value);
                    //   setSelectedCarrier(value);
                    //   // setLines([]);
                    // }}
                    >
                      {shipAccounts.map((item) => (
                        <Select.Option
                          key={item.shipAccountNum}
                          value={item.shipAccountNum}
                        >
                          {`${carriers.filter(
                            (i) => i.carrierNum === item.carrierNum
                          )[0].name
                            }-${item.name}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ContainerId"
                    initialValue={pack?.containerId}
                    label={<FormLabel>Container ID</FormLabel>}
                    rules={[
                      { required: true, message: 'Please select a carrier!' },
                    ]}
                  >
                    <Input
                      disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ShipperAddressNum"
                    initialValue={pack?.shipperAddressNum}
                    label={<FormLabel>Shipper Address</FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'Shipper Address is required',
                      },
                    ]}
                  >
                    <Select
                      dropdownMatchSelectWidth={false}
                      optionLabelProp="label"
                      disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Row justify="center">
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, textAlign: 'right' }}
                      >
                        {`${selectedAddress.contactName
                          ? selectedAddress.contactName + ','
                          : ''
                          } ${selectedAddress.street1 || ''} ${selectedAddress.street2 || ''
                          } ${selectedAddress.street3
                            ? selectedAddress.street3 + ','
                            : ','
                          } ${selectedAddress.city ? selectedAddress.city + ',' : ''
                          } ${selectedAddress.state || ''} ${selectedAddress.postalCode
                            ? selectedAddress.postalCode + ','
                            : ','
                          } ${selectedAddress.country || ''}`}
                      </Text>
                    </Row>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="ConsigneeAddressNum"
                    initialValue={pack?.consigneeAddressNum}
                    label={<FormLabel>Consignee Address</FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'Consignee Address is required',
                      },
                    ]}
                  >
                    <Select
                      dropdownMatchSelectWidth={false}
                      disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
                      optionLabelProp="label"
                      onChange={(num) =>
                        setSelectedAddress1(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress1 && (
                    <Row justify="center">
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, textAlign: 'right' }}
                      >
                        {`${selectedAddress1.contactName
                          ? selectedAddress1.contactName + ','
                          : ''
                          } ${selectedAddress1.street1 || ''} ${selectedAddress1.street2 || ''
                          } ${selectedAddress1.street3
                            ? selectedAddress1.street3 + ','
                            : ','
                          } ${selectedAddress1.city
                            ? selectedAddress1.city + ','
                            : ''
                          } ${selectedAddress1.state || ''} ${selectedAddress1.postalCode
                            ? selectedAddress1.postalCode + ','
                            : ','
                          } ${selectedAddress1.country || ''}`}
                      </Text>
                    </Row>
                  )}
                </Col>
                <Col span={12}>
                  <Form.Item label={<FormLabel>Size</FormLabel>}>
                    {pack?.length || pack?.width || pack?.height ? (
                      <Row>
                        <Space>
                          {' '}
                          <span>{`L: ${pack?.length || 0} ${unitEnum[pack?.dimensionUnit || 0]
                            }`}</span>
                          <span>{`W: ${pack?.width || 0} ${unitEnum[pack?.dimensionUnit || 0]
                            }`}</span>
                          <span>{`H: ${pack?.height || 0} ${unitEnum[pack?.dimensionUnit || 0]
                            }`}</span>
                        </Space>
                      </Row>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={<FormLabel>Weight</FormLabel>}>
                    {pack?.weight ? (
                      <Space>
                        <span>{(pack?.weight || 0).toFixed(2)}</span>
                        <span>{weightUnitEnum[pack?.weightUnit || 0]}</span>
                      </Space>
                    ) : null}
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={<FormLabel>Container Shipping Service</FormLabel>}
                  >
                    <Text>{pack?.carrierServiceName}</Text>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={<FormLabel>Container Tracking Number</FormLabel>}
                  >
                    <Text>{pack?.trackingNumber}</Text>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Card
              style={{ marginTop: 10, marginLeft: 50, marginRight: 50 }}
              size="small"
              title={
                <Row justify="space-between">
                  <Space>
                    <FormLabel>Scan Package to Container</FormLabel>
                    <Input
                      style={{ width: 350 }}
                      disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
                      ref={scanInputRef}
                      value={scanValue}
                      onChange={(e) => setScanValue(e.target.value)}
                      onKeyDown={(evt: any) => {
                        if ([9, 13].indexOf(evt.keyCode) > -1) {
                          evt.preventDefault();
                          handleAddPackage();
                        }
                      }}
                    />
                    <Button
                      disabled={[1, 2, 7, 8, 9].includes(pack?.status || 100)}
                      onClick={() => handleAddPackage()}
                    >
                      <PlusOutlined />
                      Add
                    </Button>
                  </Space>
                  <Space>
                    <Text type="secondary">Total Count: </Text>
                    <Text type="danger">{lines.length}</Text>
                  </Space>
                </Row>
              }
            >
              <Table
                size="small"
                rowKey="channelOrderId"
                bordered
                scroll={{ y: 150 }}
                columns={columns}
                dataSource={lines}
                pagination={false}
              />
            </Card>
          </>
        )}
      </Spin>
      {shippingLabelVisible && (
        <ShippingLabelDialog
          onHide={(close) => {
            setShippingLabelVisible(false);
            if (close) {
              refreshPage();
            }
          }}
          onPrinterChanged={onPrinterChanged}
          printer={printer}
          pack={pack}
          warehouseNum={Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))}
          carrier={selectedCarrier || 4}
          carrierName={
            carriers.filter((i) => i.carrierNum === selectedCarrier)[0].name
          }
        />
      )}
    </Modal>
  );
};
