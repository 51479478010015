import React from 'react';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';
import { fetchFlatRatePackageList } from 'services/sales';

type Props = {
  carrierNum: number;
  disabled?: boolean;
  flatRatePkgs?: StringKAnyVPair[];
  onChange?: Function;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  warehouseNum: number;
};

const FlatRateSelector = (props: Props) => {
  const { useState } = React;
  const { carrierNum, warehouseNum } = props;
  const [flatRateOptions, setFlatRateOptions] = useState<StringKAnyVPair[]>([]);
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<number>();
  const size = props.size || 'middle';
  const style = props.style || {};

  const rateSelectOptions = (options: StringKAnyVPair[]) => {
    return flatRateOptions.map((e) => (
      <Select.Option
        key={e.shippingPackageNum}
        value={e.shippingPackageNum}
      >
        {e.name}
      </Select.Option>
    ));
  };

  // eslint-disable-next-line
  const getFlatRates = async () => {
    try {
      const packages = await fetchFlatRatePackageList(warehouseNum, carrierNum);

      //console.log('packages -->', packages);
      setIsLoadingOptions(false);

      if (Array.isArray(packages)) {
        setFlatRateOptions(packages);

        if (props.flatRatePkgs && props.flatRatePkgs.length > 0) {
          trySetFlatRateValue(packages, props.flatRatePkgs);
        }
      }
    } catch(e) {
      message.error(`Fetch flat rate package list error ${e}`);
    }
  };

  const onSelectionChange = (
    value: any,
    options = flatRateOptions,
  ) => {
    const sels = options.filter(e => e.shippingPackageNum === value);

    setSelectedValue(value);

    if (typeof props.onChange === 'function') {
      if (sels.length > 0) {
        props.onChange(sels[0]);
      }
    }
  };

  const trySetFlatRateValue = (
    pkgs: StringKAnyVPair[],
    frPkgs: StringKAnyVPair[],
  ) => {
    const frNums = frPkgs.map(e => e.shippingPackageNum);

    if (frNums.length > 0) {
      const existNums: number[] = [];

      pkgs.forEach(e => {
        if (frNums.indexOf(e.shippingPackageNum) > -1) {
          existNums.push(e.shippingPackageNum);
        }
      });

      console.log('p f ->', frNums, existNums);
      if (existNums.length === 1) {
        onSelectionChange(existNums[0], pkgs);
      }
    }
  };

  React.useEffect(() => {
    if (!inited) {
      setIsLoadingOptions(true);
      getFlatRates();
      setInited(true);
    }
  }, [
    carrierNum,
    getFlatRates,
    inited,
  ]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          disabled={props.disabled}
          dropdownClassName="scan-ship-select-dropdown-option-container"
          onChange={val => onSelectionChange(val)}
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {rateSelectOptions(flatRateOptions)}
        </Select>
      )}
    </Row>
  );
};

export default FlatRateSelector;
