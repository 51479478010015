import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Button,
  Space,
  Form,
  Card,
  Input,
  Typography,
  DatePicker,
  Select,
  InputNumber,
} from 'antd';
import { DiffOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  DataGridPlus,
  ButtonIcon,
  message,
  HeaderTitle,
  SelectWarehouse,
  FormLabel,
  ColumnDataType,
  TablesFilter,
} from 'components/common';
import SelectLocationDialog from 'components/common/SelectLocationPlus/SelectLocationDialog';
import { GridIconBox } from 'components/common/styles';
import {
  fetchWarehouseTransferList,
  fetchTransferLines,
  getReceivedTransfer,
} from 'services/inventory';
import { fetchWarehouseLocationList } from 'services/warehouse';
import ReceiveDialog from './ReceiveDialog';
// import ScanToReceiveDialog from './ScanToReceive';
import ScanToReceiveDialog from '../../Po/PoReceive2/ScanToReceive2';
import SelectPoDialog from './SelectPoDialog';
import ReceiveAllDialog from './ReceiveAllDialog';
import { getWarehouseIdFromCache } from 'utils';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import {
  warehouseTransferStatusEnum as statusEnum
} from 'constants/enums'

const { Text } = Typography;
const { Search } = Input;

type ColumnRenderType = {
  data: any;
  rowIndex: number;
};
// eslint-disable-next-line
export default () => {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSearch = searchParams.get('batchNumber');
  const defaultWarehouseNum = searchParams.get('warehouseNum');
  const [selectPoDialogVisible, setSelectPoDialogVisible] = useState(false);
  const [receiveDialog, setReceiveDialog] = useState(false);
  const [scanToReceiveDialogVisible, setScanToReceiveDialogVisible] =
    useState(false);

  const [transferList, setTransferList] = useState<any[]>([]);
  const [selectedTransfer, setSelectedTransfer] = useState<any>();
  const [lines, setLines] = useState<any[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseRow | undefined
  >(
    defaultWarehouseNum
      ? {
        id: Number(defaultWarehouseNum),
        code: '',
        name: '',
        isExternal: false,
        totalQuantity: 0,
        isEnabled: true,
        warehouseId: '',
      }
      : undefined
  );
  const [loading, setLoading] = useState(false);
  const [receiptDate, setReceiptDate] = useState<moment.Moment>(moment());
  const [selectSearchType, setSelectSearchType] =
    useState<string>('batchNumber');
  const [current, setCurrent] = useState<any>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [receiveAllDialogVisible, setReceiveAllDialogVisible] =
    useState<boolean>(false);
  const [inited, setInited] = useState(false);
  const [historyData, setHistoryData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredHistoryData, setFilteredHistoryData] = useState<any[]>([]);
  /**
   * handle hide event
   */

  const historyColumns = [
    // {
    //   name: 'fulfillmentReceiveNum',
    //   header: 'Receive Number',
    //   showColumnMenuTool: true,
    //   defaultFlex: 1,
    // },
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Name',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Received Qty',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'warehouseCode',
      header: 'Warehouse',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      name: 'locationCode',
      header: 'Location',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
    {
      header: 'Received Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      name: 'createBy',
      header: 'Received By ',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Name',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'transferQty',
      header: 'Transfer Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Received Qty',
      showColumnMenuTool: true,
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
    },
    {
      name: 'receiving',
      header: 'Receiving Qty',
      sortable: false,
      showColumnMenuTool: true,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <InputNumber
            id={`set_${data.sku}_receivingQty`}
            value={data.receivingQty}
            onChange={(qty) =>
              setLines((prev) => {
                const temp = prev;
                const index = prev.findIndex(
                  (item) =>
                    item.fulfillmentTransferLineNum ===
                    data.fulfillmentTransferLineNum
                );
                if (index > -1) {
                  temp[index] = {
                    ...temp[index],
                    receivingQty: qty,
                  };
                  return [...temp];
                } else {
                  return prev;
                }
              })
            }
          />
        );
      },
    },
    // {
    //   name: 'shippedQty',
    //   header: 'Shipped Qty',
    //   showColumnMenuTool: true,
    //   sortable: false,
    //   defaultFlex: 1,
    // },
    // {
    //   name: 'warehouseCode',
    //   header: 'Warehouse',
    //   sortable: false,
    //   defaultFlex: 1,
    // },
    {
      name: 'receivingLocationCode',
      header: 'Location',
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <>
            {data.receivingLocationCode || ''}
            <GridIconBox>
              <EditOutlined
                onClick={() => {
                  setCurrent(data);
                  setSelectLocationDialogVisible(true);
                }}
              />
            </GridIconBox>
          </>
        );
      },
    },
    // {
    //   name: 'waitCloseQty',
    //   header: 'Waiting to Close Qty',
    //   showColumnMenuTool: true,
    //   defaultFlex: 1,
    // },
    // {
    //   name: 'description',
    //   header: 'Description',
    //   showColumnMenuTool: true,
    //   defaultFlex: 1,
    // },
    {
      name: 'notes',
      header: 'Note',
      showColumnMenuTool: false,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <Input
            id={`row_${data.sku}_note_input`}
            value={data.notes}
            onChange={(e) =>
              setLines((prev) => {
                const temp = prev;
                const index = prev.findIndex(
                  (item) =>
                    item.fulfillmentTransferLineNum ===
                    data.fulfillmentTransferLineNum
                );
                if (index > -1) {
                  temp[index] = {
                    ...temp[index],
                    notes: e.target.value,
                  };
                  return [...temp];
                } else {
                  return prev;
                }
              })
            }
          />
        );
      },
    },
  ];

  const getHistoryData = useCallback(async () => {
    try {
      const res = await getReceivedTransfer(
        getWarehouseIdFromCache(selectedTransfer.warehouseNum),
        selectedTransfer.transferNum
      );
      setHistoryData(res);
    } catch (error) { }
  }, [selectedTransfer]);

  const getLocations = async (id: string) => {
    const list = await fetchWarehouseLocationList(id, false);
    setLocations(
      list.map((row: any) => {
        return {
          id: row.locationNum,
          code: row.locationCode,
          parentCode: row.parentCode,
          parent: row.parentNum,
          creator: row.createBy,
          reserved: row.reserved,
          backstock: row.backstock,
          warehouseId: row.warehouseId,
          ...row,
        };
      })
    );
  };

  const getTransferList = useCallback(
    async (poNumberSearchValue) => {
      if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
      setInited(true);
      try {
        const queryparams = {
          $skip: 0,
          $top: 10000,
          transferType: 0,
          $sortBy: 'TransferNum desc',
          batchNumber: poNumberSearchValue,
        };
        const transferList = await fetchWarehouseTransferList(
          selectedWarehouse.warehouseId,
          queryparams
        );
        // const polist = await fetchPoList(
        //   'PoNumber',
        //   poNumberSearchValue || '',
        //   -1,
        //   'fulfillmentPoNum desc',
        //   0,
        //   100
        // );
        if (transferList.data.length === 0) {
          return message.warning({ content: 'no data matched' });
        }
        setTransferList(
          transferList.data.map((item: any) => {
            return { ...item, receivingQty: 0 };
          })
        );
        if (transferList.data.length > 1) {
          setSelectPoDialogVisible(true);
        } else {
          setSelectedTransfer(transferList.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [selectedWarehouse]
  );

  const getPoLines = useCallback(
    async (transferNum: number) => {
      if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
      try {
        setLoading(true);
        const lines = await fetchTransferLines(
          selectedWarehouse.warehouseId,
          transferNum
        );
        setLoading(false);
        setLines(
          lines.map((item: any) => {
            return {
              ...item,
              receivingLocationCode: undefined,
              receivingLocationNum: undefined,
            };
          })
        );
      } catch (err) {
        setLoading(false);
      }
    },
    [selectedWarehouse]
  );

  const getTransferListBySku = useCallback(
    async (sku) => {
      if (!selectedWarehouse || !selectedWarehouse.warehouseId) return;
      try {
        // const sku = form.getFieldValue('SearchSku');
        const queryparams = {
          $skip: 0,
          $top: 10000,
          $sortBy: 'TransferNum desc',
          sku,
        };
        const transferList = await fetchWarehouseTransferList(
          selectedWarehouse?.warehouseId,
          queryparams
        );
        if (transferList.data.length === 0) {
          return message.warning({ content: 'no data matched' });
        }
        setTransferList(
          transferList.data.map((item: any) => {
            return { ...item, receivingQty: 0 };
          })
        );
        if (transferList.data.length > 1) {
          setSelectPoDialogVisible(true);
        } else {
          setSelectedTransfer(transferList.data[0]);
        }
      } catch (error) { }
    },
    [selectedWarehouse]
  );

  /**
   * receive all event
   */
  const handelReceiveAll = useCallback((location: WarehouseLocationRow) => {
    setLines((prev: any[]) => {
      const temp = prev.map((item) => {
        return {
          ...item,
          receivingLocationCode: location.code,
          receivingLocationNum: location.id,
          receivingQty: item.transferQty,
        };
      });
      return [...temp];
    });
  }, []);

  /**
   * handel warehouse change event
   */
  // const handleWarehouseChange = useCallback((warehouse: WarehouseRow) => {
  //   setSelectedWarehouse(warehouse);
  //   getLocations(warehouse.warehouseId);
  //   //clear setted location
  //   setLines((prev) => {
  //     const temp = prev.map((item) => {
  //       return {
  //         ...item,
  //         receivingLocationCode: undefined,
  //         receivingLocationNum: undefined,
  //       };
  //     });
  //     return [...temp];
  //   });
  // }, []);

  useEffect(() => {
    if (selectedTransfer && selectedTransfer.transferNum) {
      getPoLines(selectedTransfer.transferNum);
      getHistoryData();
    }
  }, [selectedTransfer, getPoLines, getHistoryData]);

  useEffect(() => {
    if (defaultSearch && !inited) {
      getTransferList(defaultSearch);
    }
  }, [defaultSearch, getTransferList, inited]);

  return (
    <>
      <HeaderTitle breadcrumb={['Warehouse Transfer', 'Receive']} />
      <Card style={{ justifyContent: 'center' }}>
        <Row justify="center">
          <Space>
            <>
              <FormLabel>Warehouse</FormLabel>
              <SelectWarehouse
                id="warehouse_selector"
                value={selectedWarehouse}
                width={250}
                onChange={(warehouse) => {
                  setSelectedWarehouse(warehouse);
                  setSelectedTransfer(undefined);
                  setLines([]);
                  getLocations(warehouse.warehouseId);
                }}
              />
            </>
            <Input.Group compact style={{ width: 550 }}>
              <Select
                id="searchType_selector"
                defaultValue="batchNumber"
                onChange={(value) => setSelectSearchType(value)}
                style={{ width: '20%' }}
              >
                <Select.Option value="batchNumber">Batch #</Select.Option>
                <Select.Option value="sku">SKU</Select.Option>
              </Select>
              <Search
                id="search_content"
                style={{ width: '80%' }}
                defaultValue={defaultSearch ? defaultSearch : undefined}
                loading={loading}
                placeholder={
                  selectSearchType === 'batchNumber'
                    ? 'Please input batch number.'
                    : 'Please input SKU'
                }
                enterButton={<><SearchOutlined />Show Transfer List</>}
                onSearch={(value) => {
                  if (selectSearchType === 'batchNumber') {
                    getTransferList(value);
                  } else {
                    getTransferListBySku(value);
                  }
                }}
              />
            </Input.Group>
          </Space>
        </Row>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <Card>
          <Row justify="center" align="middle">
            <Space size={50}>
              <Form.Item label={<FormLabel>Current Batch #</FormLabel>}>
                <Text>{selectedTransfer?.batchNumber}</Text>
              </Form.Item>
              <Form.Item label={<FormLabel>Status</FormLabel>}>
                <Text>
                  {selectedTransfer
                    ? statusEnum[selectedTransfer.warehouseTransferStatus]
                    : ''}
                </Text>
              </Form.Item>
              {/* <Form.Item label={<FormLabel>Supplier</FormLabel>}>
                <Text>{selectedTransfer?.vendorName}</Text>
              </Form.Item>
              <Form.Item label={<FormLabel>Public Notes</FormLabel>}>
                <Text>{selectedTransfer?.publicNote}</Text>
              </Form.Item> */}
            </Space>
          </Row>

          <Row justify="center" align="middle">
            <Space>
              <FormLabel>Receipt Date</FormLabel>
              <DatePicker
                id="receiptDate_picker"
                allowClear={false}
                style={{ marginLeft: 10 }}
                defaultValue={moment()}
                format="MM/DD/YYYY"
                onChange={(date) => setReceiptDate(date || moment())}
              />
              <Button
                id="receive_btn"
                type="primary"
                disabled={
                  !selectedWarehouse ||
                  !selectedTransfer ||
                  selectedTransfer.warehouseTransferStatus === 2
                }
                onClick={() => {
                  setReceiveDialog(true);
                }}
                icon={<DiffOutlined />}
              >
                Receive
              </Button>
              <Button
                id="scanToReceive_btn"
                disabled={
                  !selectedTransfer ||
                  selectedTransfer.warehouseTransferStatus === 2
                }
                onClick={() => setScanToReceiveDialogVisible(true)}
              >
                <ButtonIcon className="bi-upc-scan" />
                Scan to Receive
              </Button>
              {/* <Button
            disabled={!selectedTransfer}
            onClick={() => setReceiveToLotDialogVisible(true)}
          >
            Scan to Lot
          </Button> */}
            </Space>
          </Row>
        </Card>
        <Card
          title={
            <Row align="middle">
              <Space>
                <Text>Transfer lines</Text>
                {/* <Button
                disabled={!selectedTransfer}
                onClick={() => setAddItemDialogVisible(true)}
              >
                Add Line Item
              </Button> */}
                <TablesFilter
                  columns={columns}
                  dataSource={lines}
                  setFilteredData={setFilteredData}
                />
              </Space>
            </Row>
          }
          extra={
            <Button.Group>
              <Button
                id="receiveAll_btn"
                disabled={!selectedWarehouse || lines.length === 0}
                onClick={() => setReceiveAllDialogVisible(true)}
                icon={<DiffOutlined />}
              >
                Receive All
              </Button>
            </Button.Group>
          }
        >
          {selectedWarehouse && (
            <DataGridPlus
              columns={columns}
              loading={loading}
              dataSource={filteredData}
              idProperty="fulfillmentTransferLineNum"
              showColumnMenuTool={false}
              autoWith={false}
              {...({} as any)}
            />
          )}
        </Card>
        <Card
          size="small"
          type="inner"
          style={{ marginTop: 8 }}
          title={
            <Row>
              <Space>
                <Text>Receive History</Text>
                <TablesFilter
                  columns={historyColumns}
                  dataSource={historyData}
                  setFilteredData={setFilteredHistoryData}
                />
              </Space>
            </Row>
          }
        >
          <DataGridPlus
            columns={historyColumns}
            loading={loading}
            dataSource={filteredHistoryData}
            idProperty="fulfillmentReceiveNum"
            showColumnMenuTool={false}
            {...({} as any)}
            autoWith={false}
          />
        </Card>
      </Card>
      {selectPoDialogVisible && (
        <SelectPoDialog
          onHide={() => {
            setSelectPoDialogVisible(false);
          }}
          onOk={(po) => {
            setSelectPoDialogVisible(false);
            if (po.transferNum) {
              setSelectedTransfer(po);
            } else {
              getTransferList(po.batchNumber);
            }
          }}
          poList={transferList}
        />
      )}

      {receiveDialog && selectedTransfer && selectedWarehouse && (
        <ReceiveDialog
          warehouse={selectedWarehouse}
          transferNum={selectedTransfer?.transferNum}
          lines={lines.filter(
            (item) =>
              item.receivingLocationCode &&
              item.receivingQty &&
              item.receivingLocationNum
          )}
          dateString={moment(receiptDate).toString()}
          onRefresh={() => {
            getPoLines(selectedTransfer.transferNum);
            getHistoryData();
          }}
          onHide={() => setReceiveDialog(false)}
        />
      )}

      {selectedTransfer && scanToReceiveDialogVisible && (
        <ScanToReceiveDialog
          targetStrs={lines.map((i) => {
            return {
              sku: i.sku,
              upc: i.upc || ''
            }
          })}
          onHide={() => setScanToReceiveDialogVisible(false)}
          onSuccess={(st) => {
            setLines((prev) => {
              const tmp = prev;
              const skus = prev.map((item) => item.sku);
              st.map((item, index) => {
                const i = skus.indexOf(item.sku);
                if (i > -1) {
                  tmp[i].receivingQty = tmp[i].receivingQty
                    ? item.quantity + (tmp[i].receivingQty || 0)
                    : item.quantity;
                }
                return true;
              });
              return [...tmp];
            });
            setScanToReceiveDialogVisible(false);
          }}
        />
      )}
      {/* {receiveToLotDialogVisible && (
        <ReceiveToLotDialog
          onHide={() => setReceiveToLotDialogVisible(false)}
          skulist={lines.map((item) => item.sku)}
          onSubmit={(lot) => {
            setSelectedLot(lot);
            setReceiveToLotDialogVisible(false);
            setScanToLotVisible(true);
          }}
          onCreateLot={() => {
            setReceiveToLotDialogVisible(false);
            setCreateLotDialogVisible(true);
          }}
        />
      )} */}
      {/* {scanToLotVisible && (
        <ScantoLotDialog
          onHide={() => setScanToLotVisible(false)}
          lot={selectedLot}
          onSubmit={(st) => {
            setLines((prev) => {
              const tmp = prev;
              prev.map((item, index) => {
                if (st[item.sku]) {
                  tmp[index].receivingQty = st[item.sku];
                }
                return true;
              });
              return [...tmp];
            });
            setScanToLotVisible(false);
          }}
        />
      )}
      {createDialogVisible && (
        <CreateProductDialog onHide={() => setCreateDialogVisible(false)} />
      )} */}
      {selectLocationDialogVisible && current && (
        <SelectLocationDialog
          visible={true}
          dataSource={locations}
          onSelect={(value) => {
            setLines((prev) => {
              const temp = prev;
              const index = prev.findIndex(
                (item) =>
                  item.fulfillmentTransferLineNum ===
                  current.fulfillmentTransferLineNum
              );
              if (index > -1) {
                temp[index] = {
                  ...temp[index],
                  receivingLocationCode: value.code,
                  receivingLocationNum: value.id,
                };
                return [...temp];
              } else {
                return temp;
              }
            });
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
          onHide={() => {
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}
      {receiveAllDialogVisible && selectedWarehouse && (
        <ReceiveAllDialog
          onHide={() => setReceiveAllDialogVisible(false)}
          onSuccess={(location) => {
            setReceiveAllDialogVisible(false);
            handelReceiveAll(location);
          }}
          warehouseNum={selectedWarehouse.id}
        />
      )}
    </>
  );
};
