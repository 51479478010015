import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import LocationAspects from 'components/LocationAspects';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <LocationAspects />
      </SiteContent>
    </ContentLayout>
  );
};
