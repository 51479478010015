import React, { useState, useRef } from 'react';
import { Alert, Col, Modal, Row, Result, Typography } from 'antd';
import { SaveOutlined, WarningOutlined } from '@ant-design/icons';
import { FormLabel, SelectWarehouse } from 'components/common';
import { Label } from 'components/common/styles';
import {
  FormVSpacer,
  SectionWrapper,
} from 'components/common/styles/CustomPropsComponents';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { userProfiles } from 'utils/auth';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';

const { Text } = Typography;

/**
 * Type defintion for the dialog properties.
 * @param {boolean|unefined} closable - default value is false, it means can not be closed.
 */
type Props = {
  close: Function;
  closable?: boolean;
  title?: string;
  visible: boolean;
  warehouseNum?: number;
};

//let keydownHandler: any = null;

const DeviceSettingDialog = (props: Props) => {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const shell = useRef<any>(null);
  const warehouseNum: number = props.warehouseNum || -1;
  const title =
    whs.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5)).length === 0
      ? 'Warning'
      : props.title || (warehouseNum > 0 ? 'Device Setting' : 'Setting');

  const onCancel = () => {
    props.close();
  };

  const onSave = () => {
    localStorage.setItem(LS_DEFAULT_WAREHOUSE_KEY, `${warehouse?.id}`);
    // localStorage.setItem(LS_DEFAULT_WAREHOUSE_CODE, `${warehouse?.code}`);
    // localStorage.setItem(
    //   LS_DEFAULT_WAREHOUSE_WAREHOUSEID,
    //   `${warehouse?.warehouseId}`
    // );
    userProfiles.saveCurrentProfileToBrowser();
    window.location.reload();
    props.close();
  };

  const onSelectWarehouse = (warehouse: WarehouseRow) => {
    console.log(warehouse);
    setWarehouse(warehouse);
  };

  return (
    <>
      <Modal
        bodyStyle={{ backgroundColor: '#ECECEC', fontSize: 16 }}
        centered
        okButtonProps={{
          style: {
            display:
              whs.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5)).length ===
                0
                ? 'none'
                : '',
          },
        }}
        cancelButtonProps={{
          style: {
            display: props.closable
              ? ''
              : whs.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5))
                .length === 0
                ? ''
                : 'none',
          },
        }}
        closable={
          whs.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5)).length === 0
            ? true
            : props.closable || false
        }
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        okText={
          <>
            <SaveOutlined /> Save
          </>
        }
        cancelText="Close"
        onCancel={onCancel}
        onOk={onSave}
        title={<Label theme={{ fontSize: '20px' }}>{title}</Label>}
        visible={props.visible}
        width={800}
      >
        {whs.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5)).length === 0 ? (
          <Col>
            <Result
              style={{ padding: 10 }}
              status="warning"
              icon={<WarningOutlined />}
              title={
                <Text style={{ fontSize: 20 }}>
                  Please add warehouse at first, or ask your administrator to
                  assign the warehouse to you.
                </Text>
              }
            />
          </Col>
        ) : (
          <Col ref={shell}>
            {warehouseNum < 1 && (
              <>
                <Alert
                  type="warning"
                  message={
                    <Label theme={{ fontSize: '20px' }}>
                      Please select the warehouse.
                    </Label>
                  }
                />
                <FormVSpacer />
                <FormVSpacer />
                <SectionWrapper>
                  <Row align="middle" justify="space-between">
                    <FormLabel>Warehouse</FormLabel>
                    <SelectWarehouse
                      onChange={onSelectWarehouse}
                      value={warehouse}
                    />
                  </Row>
                </SectionWrapper>
                <FormVSpacer />
                <FormVSpacer />
              </>
            )}
          </Col>
        )}
      </Modal>
    </>
  );
};

export default DeviceSettingDialog;
