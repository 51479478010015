import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Space } from 'antd';
// import qs from 'qs';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { fetchWarehouseEntity } from 'services/warehouse';
import { fetchZones, postZone, patchZone, deleteZone } from 'services/zone';
import { useLocation } from 'react-router-dom';
import {
  DeleteButton,
  SectionWrapper,
  DataGridPlus,
  HeaderTitle,
  message,
} from 'components/common';
import { useIsMountedRef } from 'hooks';
import ZoneDialog from './ZoneDialog';

type ColumnRenderType = {
  data: ZoneRow;
};
export default function Zones(): JSX.Element {
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState<Array<ZoneRow>>([]);
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const warehouseNum = Number(searchParams.get('warehouseNum'));
  const warehouseId = searchParams.get('warehouseId') || '';
  const [visible, setVisible] = useState(false);
  const [zone, setZone] = useState<ZoneRow>();
  const columns = [
    {
      header: 'Zone Name',
      name: 'name',
      userSelect: true,
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;
        return (
          <Button
            type="link"
            onClick={() => {
              setZone(data);
              setVisible(true);
            }}
          >
            {data.name}
          </Button>
        );
      },
    },
    {
      header: 'Zone Description',
      name: 'description',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Locations',
      name: 'locationCount',
      userSelect: true,
      sortable: false,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      showColumnMenuTool: false,
      groupBy: false,
      name: 'action',
      defaultFlex: 1,
      sortable: false,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        const { id, name } = data;
        return (
          <Space>
            <Link
              to={`/administration/zone-location?warehouseNum=${warehouseNum}&warehouseId=${warehouseId}&zoneId=${id}`}
            >
              <Button type="link">Edit Locations</Button>
            </Link>

            <DeleteButton
              type="link"
              showIcon={false}
              confirmTitle="Are you sure you want to delete the zone?"
              confirmContent={`name: ${name}`}
              onClick={() => {
                handleDelete(id, name);
              }}
            />
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    const fetch = async () => {
      if (!warehouseNum || !warehouseId) {
        message.warning({ content: 'Please select warehouse!' });
        return;
      }
      if (isMountedRef.current) {
        setLoading(true);
      }
      //fetch warehouse
      const newWarehouse = await fetchWarehouseEntity(warehouseId);
      if (isMountedRef.current) {
        setWarehouse(newWarehouse);
      }
      if (!newWarehouse) {
        if (isMountedRef.current) {
          setLoading(false);
        }
        message.warning({ content: 'Please select warehouse!' });
        return;
      }
      // fetch zones
      const newZones = await fetchZones(warehouseId);
      setLoading(false);
      if (isMountedRef.current) {
        setZones(newZones);
      }
    };
    fetch();
  }, [warehouseNum, isMountedRef, warehouseId]);
  /**
   * handle create zone form submit event
   */
  const handleSubmit = useCallback(
    async (name, desc): Promise<boolean> => {
      let success = false;
      if (zone) {
        //update zone
        success = await patchZone(warehouseId, zone.id, {
          ZoneName: name,
          ZoneDesc: desc,
        });
      } else {
        success = await postZone(warehouseId, name, desc);
      }
      if (!success) {
        message.warning({ content: `zone "${name}" exists already` });
        return false;
      } else {
        // fetch zones
        message.success({
          content: `${
            zone ? 'Updated' : 'Created'
          } zone "${name}" successfully`,
        });
        setVisible(false);
        setLoading(true);
        const newZones = await fetchZones(warehouseId);
        setZones(newZones);
        setLoading(false);
        return true;
      }
    },
    [zone, warehouseId]
  );
  /**
   * handle delete event
   */
  const handleDelete = useCallback(
    async (zoneId: number, zoneName: string) => {
      await deleteZone(warehouseId, zoneId);
      message.success({
        content: `Deleted "${zoneName}" successfully`,
      });
      // fetch zones
      setLoading(true);
      const newZones = await fetchZones(warehouseId);
      if (isMountedRef.current) {
        setZones(newZones);
        setLoading(false);
      }
    },
    [isMountedRef, warehouseId]
  );
  return (
    <>
      <HeaderTitle
        backEnabled
        breadcrumb={[
          'Admin',
          {
            type: 'link',
            content: 'Zone Management',
            url: '/administration/zone-management',
          },
          warehouse && (warehouse as any).warehouseName,
        ]}
      />
      <SectionWrapper>
        <Row style={{ marginTop: 20, marginBottom: 20 }}>
          <Button
            type="primary"
            onClick={() => {
              setZone(undefined);
              setVisible(true);
            }}
          >
            <PlusOutlined />
            <span>Create Zone</span>
          </Button>
        </Row>
      </SectionWrapper>
      <SectionWrapper style={{ marginTop: 10 }}>
        <DataGridPlus
          name="zones"
          idProperty="id"
          loading={loading}
          dataSource={zones}
          columns={columns}
          pagination={false}
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
        {visible && (
          <ZoneDialog
            zone={zone}
            onSubmit={handleSubmit}
            onClose={() => {
              setVisible(false);
            }}
          />
        )}
      </SectionWrapper>
    </>
  );
}
