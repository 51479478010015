import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col, Space, Typography, Divider, Radio, Empty, Table, Spin, InputNumber, Checkbox } from 'antd';
import { FormLabel, message } from 'components/common';
import {
  getPickOrderDetail,
  markUnPickOrder,
  markPickOrder,
  pickingOrder,
} from 'services/orders';
// import {pickingByOrder2} from 'services/smart';
import { fetchWarehouseProductList } from 'services/inventory';
import { userProfiles } from 'utils/auth';
// import { fetchWarehouseLocationList } from 'services/warehouse';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../../components/common/ModalDialog';

interface CreateGroupTicketsProps extends ModalProps  {
  onHide: () => void;
  onRefresh: () => void;
  orderInfo: Order;
}

const pickStatusKeyValue: { [key: string]: string } = {
  '0': 'Not Picked',
  '1': 'Picked',
  '2': 'Partially Picked',
};

// eslint-disable-next-line
export default (props: CreateGroupTicketsProps) => {
  const { onHide, orderInfo, onRefresh } = props;
  const shipFlowValue = userProfiles.getProfileSettingValue('ShipFlow');
  const [loading, setLoading] = useState(false);
  const [pickingLoading, setPickingLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [detail, setDetail] = useState<any[]>([]);
  // const [pickLocation, setPickLocation] = useState<any>();
  const [current, setCurrent] = useState<any>();
  const [inventoryList, setInventoryList] = useState<any>(undefined);
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const [hasOver, setHasOver] = useState<boolean>(false);;
  // const [isAllMatch, setIsAllMatch] = useState<boolean>(false);
  const [withoutAdjustment, setWithoutAdjustment] = useState<boolean>(shipFlowValue === 'ScanFlow' ? false : true);
  const adjustmentMode = shipFlowValue === 'ScanFlow' && orderInfo.pickStatus !== 1 && !withoutAdjustment;
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM");
  const enableLOT = userProfiles.getProfileSettingValue("EnableLOT");
  // const [isFilter, setIsFilter] = useState(true)
  const [filterArr, setFilterArr] = useState<any>([]);

  const [form] = Form.useForm();

  // useEffect(()=>{
  //   const temp = detail.filter((i:any)=> i.orderQty > i.pickedQty + i.pickingQty)
  //   if(temp.length === 0 && detail.length > 0){
  //     setIsAllMatch(true)
  //   } else {
  //     setIsAllMatch(false)
  //   }
  // },[detail])

  const handleAdjustment = useCallback((e: any, record: any) => {
    try {
      const { pickingQty, locationId, lotNumber } = record;
      const diff = e - pickingQty;
      const inventoryListTemp = JSON.parse(JSON.stringify(inventoryList));
      const skuTemp = inventoryListTemp[current.fulfillmentOrderLineNum];
      // let { fromList = [], toList = [] } = skuTemp;
      let fromList = skuTemp?.fromList || [];
      let toList = skuTemp?.toList || [];

      const arr1: any[] = [];
      fromList.forEach((item: any) => {
        if (item.locationId === locationId && item.lotNumber === lotNumber) {
          arr1.push({
            ...item,
            pickingQty: e,
            totalQty: item.quantity + e
          })
        } else {
          arr1.push(item);
        }
      })
      const tempArr2 = [...toList]
      const index = toList.findIndex((i:any)=> i.lotNumber === lotNumber)
      if(index > -1){
        tempArr2[index] = {
          ...tempArr2[index],
          pickingQty: tempArr2[index].pickingQty - diff,
          totalQty: tempArr2[index].totalQty - diff
        }
      } else {
        tempArr2.push({
          ...toList[0],
          lotNumber: lotNumber,
          pickingQty: 0 - diff,
          totalQty: 0 - diff
        })
      }

      // toList.forEach((item: any) => {
      //   if (item.lotNumber === lotNumber) {
      //     arr2.push({
      //       ...item,
      //       pickingQty: toList[0].pickingQty - diff,
      //       totalQty: toList[0].totalQty - diff
      //     })
      //   } 
      // })

      // arr2.push({
      //   ...toList[0],
      //   pickingQty: toList[0].pickingQty - diff,
      //   totalQty: toList[0].totalQty - diff
      // })

      inventoryListTemp[current.fulfillmentOrderLineNum] = { fromList: arr1, toList: tempArr2 }
      setInventoryList(inventoryListTemp);
    } catch (error) {
      console.log(error);
    }
  }, [inventoryList, current])

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 1,
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 1,
    },
    {
      title: 'Rate',
      dataIndex: 'uomRate',
      key: 1,
    },
    {
      title: 'Ordered',
      dataIndex: 'orderQty',
      key: 2,
    },
    {
      title: 'Picked',
      dataIndex: 'pickedQty',
      key: 3,
    },
    {
      title: 'Picking',
      dataIndex: 'pickingQty',
      key: 4,
      render: (_: number, record: any) => {
        if (inventoryList) {
          let sum = 0;
          const fromList = inventoryList[record.fulfillmentOrderLineNum]?.fromList || [];
          fromList.forEach((item: any) => {
            sum -= item.pickingQty
          })
          return sum;
        } else {
          return 0;
        }
      }
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 5,
      render: (_: number, record: any) => {
        return (
          <Radio disabled={record.pickedQty === record.orderQty} checked={record.isChecked} />
        )
      }
    },
  ];

  const pickingFromColumns = useCallback(() => {
    return [
      {
        title: enableLOT ? 'Location [#LOT] (qty)': 'Location (qty)',
        dataIndex: 'locationCode',
        key: 1,
        width: 400,
        render: (_: number, record: any) => {
          return enableLOT ? `${record.locationCode} [${record.lotNumber}] (${record.quantity})` : `${record.locationCode} (${record.quantity})`
        }
      },
      {
        title: 'Picking',
        dataIndex: 'pickingQty',
        width: 150,
        key: 2,
        render: (_: number, record: any) => {
          return (
            <InputNumber
              type="number"
              max={0}
              step={1}
              min={0 - record.quantity}
              value={record.pickingQty}
              style={{ width: '100%' }}
              onChange={e => handleAdjustment(e, record)}
            />
          )
        }
      },
      {
        title: 'Total',
        dataIndex: 'totalQty',
        key: 3,
      },
    ]
  }, [handleAdjustment, enableLOT])

  const pickingToColumns = [
    {
      title: enableLOT ? 'Location [#LOT] (qty)' : 'Location (qty)',
      dataIndex: 'locationCode',
      width: 400,
      key: 1,
      render: (_: number, record: any) => {
        return enableLOT ? `${record.locationCode} [${record.lotNumber}] (${record.quantity})` : `${record.locationCode} (${record.quantity})`
      }
    },
    {
      title: 'Picking',
      dataIndex: 'pickingQty',
      width: 150,
      key: 2,
    },
    {
      title: 'Total',
      dataIndex: 'totalQty',
      key: 3,
    },
  ]

  const handelMarkUnpick = useCallback(async () => {
    try {
      setSaving(true);
      const res = await markUnPickOrder(orderInfo.fulfillmentOrderNum);
      setSaving(false);
      if (res.isSuccess) {
        message.success('Mark as Not Picked successfully');
        onRefresh();
      }
    } catch (error) {
      setSaving(false);
    }
  }, [onRefresh, orderInfo]);

  // const onClickMarkPick = async () => {
  //   setLoading(true);
  //   if (adjustmentMode && isMatch) {
  //     await handelMarkpick();
  //     Promise.all(moveProductFromLocationA2B());
  //     // await Promise.all(handlePickItemBySKU());
  //   } else {
  //     handelMarkpick();
  //   }
  //   setLoading(false);
  // }

  const moveProductFromLocationA2B = useCallback(async() => {
    const params:any[] = []
    try {
      for (const key in inventoryList) {
        if (Object.prototype.hasOwnProperty.call(inventoryList, key)) {
          const { fromList = [] } = inventoryList[key];
          fromList.forEach((item: any) => {
            if (Math.abs(item.pickingQty) > 0) {
              const queryParams = {
                FulfillmentOrderNum: orderInfo.fulfillmentOrderNum,
                LocationCode: item.locationCode,
                FulfillmentOrderLineNum: item.fulfillmentOrderLineNum,
                Title: item.title,
                SkuOrUpc: item.sku,
                PickQty: item.pickQty,
                PickedQty: Math.abs(item.pickingQty),
                FulfillmentProductNum: item.fulfillmentProductNum,
                LotNumber:item.lotNumber,
                UOM: item.uom,
                UomRate: item.uomRate,
              }
              params.push(queryParams);
            }
          });
        }
      }
      if(params.length === 0) {
        message.info('There is no data to save.')
        return
      }
      setLoading(true)
      // console.log(params)
      const res = await pickingOrder(orderInfo.fulfillmentOrderNum, params)
      setLoading(false)
      if(res.isSuccess){
        message.success('Save successfully')
        onRefresh();
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    } 
  },[inventoryList, onRefresh, orderInfo])

  // const handlePickItemBySKU = () => {
  //   const apiList: Promise<any>[] = []
  //   try {
  //     for (const key in inventoryList) {
  //       if (Object.prototype.hasOwnProperty.call(inventoryList, key)) {
  //         const { fromList = [] } = inventoryList[key];
  //         fromList.forEach((item: any) => {
  //           const queryParams = {
  //             PickListNum: orderInfo.pickListNum,
  //             LocationCode: item.locationCode,
  //             SKU: item.sku,
  //             PickedQty: Math.abs(item.pickingQty)
  //           }
  //           if (Math.abs(item.pickingQty) > 0) {
  //             apiList.push(pickingItemBySKU(queryParams));
  //           }
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     return apiList;
  //   }
  // }

  const handelMarkpick = useCallback(async () => {
    try {
      setSaving(true);
      const res = await markPickOrder(orderInfo.fulfillmentOrderNum);
      setSaving(false);
      if (res.isSuccess) {
        message.success('Mark as Picked successfully');
        onRefresh();
      }
    } catch (error) {
      setSaving(false);
    }
  }, [onRefresh, orderInfo])

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const loadDetail = async () => {
    setLoading(true);
    try {
      const { data = {} } = await getPickOrderDetail(orderInfo.fulfillmentOrderNum);
      if (Array.isArray(data.order.orderLineList)) {
        const temp: { [keyL: string]: any } = {};
        const tempList = data.order.orderLineList.filter((i:any)=> i.pickedQty < i.orderQty)
        const tempFilterList = data.order.orderLineList.filter((i:any)=> i.pickedQty === i.orderQty)
        setDetail(tempList.map((item: any) => {
          temp[item.fulfillmentOrderLineNum] = { fromList: [], toList: [] };
          return {
            ...item,
            isChecked: false,
          }
        }));
        setInventoryList(temp);
        setFilterArr(tempFilterList.map((i:any)=>{return {...i, isChecked: false}}))
      }
    } catch (e) {
      console.log('e = ', e);
    } finally {
      setLoading(false);
    }
  };
  const filterList = useCallback((bl:boolean)=>{
    const tempArr = bl ? detail.filter((i:any)=> i.pickedQty < i.orderQty) : [...detail, ...filterArr]
    const tempFilterArr = bl? detail.filter((i:any)=> i.pickedQty === i.orderQty) : []
    setDetail(tempArr)
    setFilterArr(tempFilterArr)
  },[detail, filterArr])

  // useEffect(()=>{
  //   if(isFilter){
  //     filterList(isFilter)
  //   }
  // },[isFilter, filterList])

  const getInventory = async (skip: number, top: number, product: any) => {
    // console.log('current',current)
    if ((inventoryList[product.fulfillmentOrderLineNum]?.toList || []).length > 0) return;
    setPickingLoading(true);
    try {
      let query: StringKAnyVPair = enableUOM ? {
        $skip: skip,
        $top: top,
        $sortBy: 'sku',
        $calculateTotal: false,
        $count: true,
        uniqueCode: product.sku,
        uom: product.uom,
        uomRate: product.uomRate,
      } : {
        $skip: skip,
        $top: top,
        $sortBy: 'sku',
        $calculateTotal: false,
        $count: true,
        uniqueCode: product.sku,
      };
      const { databaseNum, warehouseNum } = orderInfo;
      const warehouseId = `${databaseNum}-${warehouseNum}`;
      const { data = [] } = await fetchWarehouseProductList(warehouseId, query);
      let temp = { ...inventoryList };
      // fromList part
      const arr: any = [];
      const arr2: any = [];
      data.filter((i:any)=> !i.virtual && i.quantity > 0).forEach((item: any) => {
        // if (!['Pick', 'PickMissing','Checkout'].includes(item.locationCode) && item.quantity > 0) {
          arr.push({
            quantity: item.quantity,
            locationCode: item.locationCode,
            locationId: item.locationId,
            locationNum: item.locationNum,
            sku: item.sku,
            lotNumber: item.lotNumber || '',
            fulfillmentOrderLineNum: current.fulfillmentOrderLineNum,
            fulfillmentProductNum: current.fulfillmentProductNum,
            pickingQty: 0,
            totalQty: item.quantity,
            uom: product.uom || 'EA',
            uomRate: product.uomRate || 1,
            pickQty: current.orderQty,
            title: item.productTitle,
          })
        // }
      })
      temp[product.fulfillmentOrderLineNum].fromList = arr;

      // toList part
      const target = data.filter((item: any) => item.locationCode === 'Pick');
      if(target.length> 0){
        target.map((i:any) => {
          arr2.push({
            quantity: i.quantity,
            locationCode: i.locationCode,
            locationId: i.locationId,
            locationNum: i.locationNum,
            sku: i.sku,
            lotNumber: i.lotNumber || '',
            fulfillmentOrderLineNum: current.fulfillmentOrderLineNum,
            fulfillmentProductNum: current.fulfillmentProductNum,
            pickingQty: 0,
            totalQty: i.quantity
          })
          return true;
        })
      }else {
        arr2.push({
          quantity: 0,
          locationCode: 'Pick',
          sku: current.sku,
          lotNumber: '',
          fulfillmentOrderLineNum: current.fulfillmentOrderLineNum,
          fulfillmentProductNum: current.fulfillmentProductNum,
          pickingQty: 0,
          totalQty: 0,
        })
      }
      temp[product.fulfillmentOrderLineNum].toList = arr2;


      // if (target) {
      //   temp[product.fulfillmentOrderLineNum].toList = [{
      //     quantity: target.quantity,
      //     locationCode: target.locationCode,
      //     locationId: target.locationId,
      //     locationNum: target.locationNum,
      //     sku: target.sku,
      //     lotNumber: target.lotNumber,
      //     fulfillmentOrderLineNum: current.fulfillmentOrderLineNum,
      //     fulfillmentProductNum: current.fulfillmentProductNum,
      //     pickingQty: 0,
      //     totalQty: target.quantity
      //   }];
      // } else {
      //   temp[product.fulfillmentOrderLineNum].toList = [{
      //     quantity: 0,
      //     locationCode: 'Pick',
      //     sku: current.sku,
      //     lotNumber: current.lotNumber,
      //     fulfillmentOrderLineNum: current.fulfillmentOrderLineNum,
      //     fulfillmentProductNum: current.fulfillmentProductNum,
      //     pickingQty: 0,
      //     totalQty: 0,
      //   }]
      // }
      setInventoryList(temp);
    } catch (error) {
      console.log(error);
    } finally {
      setPickingLoading(false);
    }
  }

  // const getPickLocationInfo = async () => {
  //   try {
  //     const { databaseNum, warehouseNum } = orderInfo;
  //     const warehouseId = `${databaseNum}-${warehouseNum}`;
  //     const res = await fetchWarehouseLocationList(
  //       warehouseId,
  //       true,
  //       'Pick',
  //       0
  //     );
  //     setPickLocation(res[0]);
  //   } catch (error) { }
  // }

  /* eslint-disable */
  useEffect(() => {
    if (orderInfo && orderInfo.warehouseNum && orderInfo.fulfillmentOrderNum) {
      loadDetail();
      // getPickLocationInfo();
    }
  }, [orderInfo]);

  useEffect(() => {
    if (current) {
      getInventory(0, 1000, current);
    }
  }, [current])

  useEffect(() => {
    let isMatch = true;
    let hasOver = false
    const newDetails = detail.map(item => {
      let sum = 0;
      // console.log('inventoryList', inventoryList)
      if (inventoryList && inventoryList[item.fulfillmentOrderLineNum]) {
        console.log('here')
        const fromList = inventoryList[item.fulfillmentOrderLineNum].fromList || [];
        fromList.forEach((item: any) => {
          sum -= item.pickingQty;
        })
      }
      if (item.orderQty !== item.pickedQty + sum) isMatch = false;
      if (item.orderQty < item.pickedQty + sum) hasOver = true;
      return {
        ...item,
        pickingQty: sum
      }
    })
    // console.log(newDetails)
    setDetail(newDetails);
    setIsMatch(isMatch);
    setHasOver(hasOver);
  }, [inventoryList])

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  return (
    <ModalDialog
    centered
    fullscreen={true}
    title="Change Pick Status"
    maskClosable={false}
    closeButton={true}
    closable={false}
    visible={true}
    bodyHeight={630}
    onClose={handleCancel}
    destroyOnClose={true}
    getContainer={false}
    footer={<Row justify="end">
    <Space>
      {orderInfo.pickStatus !== 1 && shipFlowValue === 'ScanFlow' && <Checkbox
        checked={withoutAdjustment}
        onChange={e => {
          if(e.target.checked){
            Modal.confirm({
              title: `Are you sure you want to proceed?`,
              content: `Further processing will be blocked if no sufficient quantity can be found in the "Pick" location.'`,
              onOk() {
                setWithoutAdjustment(e.target.checked)
              },
            });
          } else {
            setWithoutAdjustment(e.target.checked)
          }
        }}
      >
        Continue without any inventory adjustment
      </Checkbox>}
      {orderInfo.pickStatus === 1 && <Button
        loading={saving}
        type="primary"
        disabled={loading}
        onClick={() => {
          handelMarkUnpick();
        }}
      >
        Mark as Not Picked
      </Button>}
      {orderInfo.pickStatus !== 1 && withoutAdjustment && <Button
        loading={saving}
        disabled={loading}
        type="primary"
        onClick={() => {
          handelMarkpick();
        }}
      >
        Mark as Picked
      </Button>}
      {orderInfo.pickStatus !== 1 && !withoutAdjustment && <Button
        loading={saving}
        disabled={loading || hasOver}
        type="primary"
        onClick={() => {
          moveProductFromLocationA2B();
        }}
      >
        {isMatch ? 'Mark as Picked' : 'Save Adjustment'}
      </Button>}
    </Space>
  </Row>}
  >
    {/* <Modal
      title="Change Pick Status"
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      bodyStyle={{ maxHeight: 600, overflowY: 'auto' }}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            {orderInfo.pickStatus !== 1 && shipFlowValue === 'ScanFlow' && <Checkbox
              checked={withoutAdjustment}
              onChange={e => setWithoutAdjustment(e.target.checked)}
            >
              Continue without any inventory adjustment
            </Checkbox>}
            {orderInfo.pickStatus === 1 && <Button
              loading={saving}
              type="primary"
              disabled={loading}
              onClick={() => {
                handelMarkUnpick();
              }}
            >
              Mark as Not Picked
            </Button>}
            {orderInfo.pickStatus !== 1 && <Button
              loading={saving}
              disabled={loading || (!withoutAdjustment && !isMatch)}
              type="primary"
              onClick={() => {
                onClickMarkPick();
              }}
            >
              Mark as Picked
            </Button>}
          </Space>
        </Row>
      }
    > */}
      <Spin spinning={loading}>
        <Row>
          <Col span={12}>
            <Form.Item {...layout} label={<FormLabel>Channel Order Id</FormLabel>}>
              <Typography.Text>{orderInfo.channelOrderId}</Typography.Text>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item {...layout} label={<FormLabel>Pick Status</FormLabel>}>
              <Typography.Text>{pickStatusKeyValue[orderInfo.pickStatus]}</Typography.Text>
            </Form.Item>
          </Col>
        </Row>
        {adjustmentMode && (
          <Spin spinning={pickingLoading}>
            <Divider style={{ margin: '8px 0px' }} />
            <Checkbox defaultChecked onChange={(e)=> filterList(e.target.checked)}>hide picked item</Checkbox>
            <div style={{ maxHeight: 150, overflow: 'auto' }}>
              <Table
                bordered
                columns={columns}
                showSorterTooltip={false}
                rowKey={(record: any) => record.fulfillmentOrderLineNum}
                rowClassName={(record) => {
                  return record.orderQty === (record.pickedQty + record.pickingQty) ? 'match-row' : ''
                }}
                dataSource={detail}
                // pagination={detail.length > 5 ? { position: ['bottomRight'] } : false}
                pagination={false}
                onRow={(row, index) => {
                  return {
                    onClick: () => {
                      if(row.pickedQty === row.orderQty) return;
                      const obj = { ...row, isChecked: true };
                      setCurrent(obj);
                      setDetail(detail.map((item, idx) => {
                        if (idx === index) return obj;
                        return {
                          ...item,
                          isChecked: false,
                        }
                      }))
                    }
                  }
                }}
              />
            </div>
            {current && (inventoryList[current.fulfillmentOrderLineNum]?.fromList || []).length > 0 && (
              <>
                <Divider style={{ margin: '8px 0px' }} >{`Picking: ${current.sku}`}</Divider>
                <Typography.Title level={5}>From</Typography.Title>
                <div style={{ maxHeight: 150, overflow: 'auto' }}>
                  <Table
                    bordered
                    columns={pickingFromColumns()}
                    showSorterTooltip={false}
                    rowKey={(record) => `${record.locationId}---${record.lotNumber}`}
                    dataSource={inventoryList[current.fulfillmentOrderLineNum]?.fromList || []}
                    scroll={(inventoryList[current.fulfillmentOrderLineNum]?.fromList || []).length > 4 ? { y: 150 } : undefined}
                    // pagination={detail.length > 10 ? { position: ['bottomRight'] } : false}
                    pagination={false}
                  />
                </div>
                <Typography.Title level={5}>To</Typography.Title>
                <div style={{ maxHeight: 150, overflow: 'auto' }}>
                  <Table
                    bordered
                    columns={pickingToColumns}
                    showSorterTooltip={false}
                    rowKey={(record) => `${record.locationId}---${record.lotNumber}`}
                    dataSource={inventoryList[current.fulfillmentOrderLineNum]?.toList || []}
                    // pagination={detail.length > 10 ? { position: ['bottomRight'] } : false}
                    pagination={false}
                  />
                </div>
              </>
            )}
            {current && (inventoryList[current.fulfillmentOrderLineNum]?.fromList||[]).length === 0 && (
              <Empty style={{ marginTop: 8 }} description={`No in-stock can be found for ${current.sku} in [${orderInfo.warehouseCode}]`} />
            )}
          </Spin>)}
      </Spin>
    {/* </Modal> */}
    </ModalDialog>
  );
};
