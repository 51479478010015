import { Button, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ModalPlus as Modal, DataGridPlus, message } from 'components/common';
import { RootState } from 'reducers';
import EditTagsDialog from './TagsDialog';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { onSelectionChange } from 'utils';

type SelectTagsProps = {
  onSubmit: (rows: Array<TagRow>) => void;
  onHide: () => void;
};
/**
 * Return Select Tags Dialog
 * @param {SelectTagsProps} props
 * @returns {JSX.Element}
 */
export default function SelectTagsDialog(props: SelectTagsProps): JSX.Element {
  const { onHide, onSubmit } = props;
  const [editTagsDialogVisible, setEditTagsDialogVisible] = useState(false);
  const tags = useSelector(
    (state: RootState) => state.admin.tags,
    shallowEqual
  );
  const [selectedRows, setSelectedRows] = useState<Array<TagRow>>([]);
  const [selected, setSelected] = React.useState<any>({});
  const [allTempList, setAllTempList] = useState<any[]>([])

  const columns = [
    {
      header: 'Name',
      name: 'tagName',
      defaultFlex: 1,
    },
  ];
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    if (selectedRows.length === 0) {
      message.warning('Please select tags');
      return;
    }
    onSubmit(selectedRows);
  }, [onSubmit, selectedRows]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="space-between">
        <Button
          id="editTags_btn"
          onClick={() => {
            setEditTagsDialogVisible(true);
          }}
          icon={<EditOutlined />}
        >
          Edit Tags
        </Button>
        <Space>
          <Button id="confirm_btn" type="primary" onClick={handleSubmit} icon={<CheckOutlined />}>
            Confirm
          </Button>
          <Button id="cancel_btn" onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <>
      {editTagsDialogVisible && (
        <EditTagsDialog
          onHide={() => {
            setEditTagsDialogVisible(false);
          }}
        />
      )}
      <Modal title="Order Tags" visible onCancel={onHide} footer={<Footer />}>
        <DataGridPlus
          checkboxColumn
          checkboxOnlyRowSelect
          idProperty="tagNum"
          columns={columns}
          dataSource={tags}
          pagination={false}
          onSortInfoChange={() => {
            setSelected({})
            setSelectedRows([])
          }}
          onChange={() => {
            setSelected({})
            setSelectedRows([])
          }}
          selected={selected}
          onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRows, 'tagNum', true, setAllTempList)}
          // onSelectionChange={(props) => {
          //   const { selected, data, unselected } = props;
          //   setSelected(selected);
          //   if (selected === true && !unselected) {
          //     setSelectedRows(data as any);
          //   } else {
          //     if (!unselected) {
          //       setSelectedRows(Object.values(selected as any));
          //     } else {
          //       setSelectedRows((prev) => {
          //         return prev.filter(
          //           (item) => item.tagNum !== (data as any).id
          //         );
          //       });
          //     }
          //   }
          // }}
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
      </Modal>
    </>
  );
}
