import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import ReturnToLocation from 'components/Sales/Smart/VirtualWarehouseToLocation/ReturnToLocation';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseCodeFromCache } from 'utils';

export default function SmartReturnToLocationScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.returnToLocation" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Resalable to Location(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <ReturnToLocation />
    </ContentLayout>
  );
}
