import React, { useEffect, useState, useCallback } from 'react';
import {
	Form,
	Input,
	Row,
	Spin,
	Button,
	Modal,
	Space,
	Select,
} from 'antd';
import { message, FormLabel } from 'components/common';
import { CloseOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { updateShippingAddress } from 'services/return';
import { getCountrys, getStates } from 'services/address';
import { sortByString } from 'utils';

interface AddServiceProps {
	visible: boolean;
	onHide: () => void;
	onRefresh: () => void;
	returnData: any;
	warehouseId: string;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
	const { visible, onHide, onRefresh, returnData, warehouseId } = props;
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [stateList, setStateList] = useState<StateRow[]>([]);
	const [countryList, setCountryList] = useState<CountryRow[]>([]);

	const [form] = Form.useForm();

	const handleSubmit = async () => {
		const params = await form.validateFields();
		if (params.errorFields) return;

		try {
			setSubmitLoading(true);
			let res = await updateShippingAddress(warehouseId, returnData.fulfillmentReturnNum, params)
			setSubmitLoading(false);
			if (res) {
				message.success({
					content: 'Updated successfully',
				});
				onRefresh();
				onHide();
			}
		} catch (error) {
			setSubmitLoading(false);
		}
	};

	const fetchStateList = useCallback(
		async (id: number, first?: boolean) => {
			//   if (!selectedCountry) return;
			try {
				setLoading(true);
				const res = await getStates(id);
				setLoading(false);
				if (res.isSuccess) {
					setStateList(res.data.sort((a:any,b:any)=> sortByString(a, b, 'code')));
					if (first) {
						const temp = res.data.filter(
							(i: StateRow) => i.code === returnData.shipToState
						);
						if (temp.length > 0) {
							form.setFieldsValue({
								State: temp[0].code,
							});
						}
					}
				}
			} catch (error) {
				setLoading(false);
				console.log(error);
			}
		},
		[returnData, form]
	);

	const fetchCountryList = async (first?: boolean) => {
		try {
			setLoading(true);
			const res = await getCountrys();
			setLoading(false);
			if (res.isSuccess) {
				setCountryList(res.data);
				if (first) {
					const temp = res.data.filter(
						(i: CountryRow) => i.iso2 === returnData.shipToCountry
					);
					if (temp.length > 0) {
						fetchStateList(temp[0].countryNum, true);
					}
				}
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleCancel = () => {
		const cancel = form.isFieldsTouched();
		if (!cancel) {
			onHide();
		} else {
			Modal.confirm({
				title: 'Leave / Refresh Page?',
				okText: 'Leave',
				cancelText: 'Cancel',
				content: `Changes you made may not be saved.`,
				onOk() {
					onHide();
				},
			});
		}
	};

	useEffect(() => {
		fetchCountryList(true);
		// eslint-disable-next-line
	}, [])

	const layout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 14 },
	};
	return (
    <Modal
      title={'Edit Shipping Address'}
      centered
      visible={visible}
      maskClosable={false}
      width={720}
      bodyStyle={{ height: 450, overflowY: 'auto' }}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_address"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              <SaveOutlined />
              {submitLoading ? 'Saving' : 'Save'}
            </Button>
            <Button
              id="cancel_edit_address"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="Country"
            initialValue={returnData.shipToCountry}
            label={<FormLabel>Country</FormLabel>}
            rules={[{ required: true, message: 'please select Country' }]}
          >
            <Select
              id="country_selector"
              onChange={(value) => {
                form.setFieldsValue({
                  State: undefined,
                });
                const temp = countryList.filter((i) => i.iso2 === value)[0];
                fetchStateList(
                  temp.countryNum,
                  returnData.shipToCountry === temp.iso2 ||
                    returnData.shipToCountry === temp.name
                );
              }}
            >
              {countryList.map((item) => (
                <Select.Option key={item.countryNum} value={item.iso2}>
                  {`${item.name}(${item.iso2})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="Name"
            initialValue={returnData.shipToName}
            label={<FormLabel>Name</FormLabel>}
            rules={[{ required: true, message: 'please input Name' }]}
          >
            <Input id="name_input" maxLength={34} />
          </Form.Item>
          <Form.Item
            name="FirstName"
            initialValue={returnData.shipToFirstName}
            label={<FormLabel>First Name</FormLabel>}
            // rules={[{ required: true, message: 'please input First Name' }]}
          >
            <Input id="firstname_input" />
          </Form.Item>
          <Form.Item
            name="LastName"
            initialValue={returnData.shipToLastName}
            label={<FormLabel>Last Name</FormLabel>}
            // rules={[{ required: true, message: 'please input Last Name' }]}
          >
            <Input id="lastname_input" />
          </Form.Item>

          <Form.Item
            name="Company"
            initialValue={returnData.shipToCompany}
            label={<FormLabel>Company</FormLabel>}
          >
            <Input id="company_input" />
          </Form.Item>

          <Form.Item
            name="CompanyJobTitle"
            initialValue={returnData.shipToCompanyJobTitle}
            label={<FormLabel>Company Job Title</FormLabel>}
          >
            <Input id="company_job_title_input" />
          </Form.Item>

          <Form.Item
            name="AddressLine1"
            initialValue={returnData.shipToAddressLine1}
            label={<FormLabel>Address Line 1</FormLabel>}
            rules={[{ required: true, message: 'please input Address 1' }]}
          >
            <Input id="address_line1_input" />
          </Form.Item>
          <Form.Item
            name="AddressLine2"
            initialValue={returnData.shipToAddressLine2}
            label={<FormLabel>Address Line 2</FormLabel>}
          >
            <Input id="address_line2_input" />
          </Form.Item>
          <Form.Item
            name="AddressLine3"
            initialValue={returnData.shipToAddressLine3}
            label={<FormLabel>Address Line 3</FormLabel>}
          >
            <Input id="address_line3_input" />
          </Form.Item>

          <Form.Item
            name="City"
            initialValue={returnData.shipToCity}
            label={<FormLabel>City</FormLabel>}
            rules={[{ required: true, message: 'please input city' }]}
          >
            <Input id="city_input" />
          </Form.Item>

          <Form.Item
            name="State"
            initialValue={returnData.shipToState}
            label={<FormLabel>State</FormLabel>}
            rules={[{ required: true, message: 'please select state' }]}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                if (option) {
                  const { children } = option;

                  if (typeof children === 'string') {
                    return (
                      (children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                }

                return false;
              }}
              id="state_selector"
            >
              {stateList.map((item) => (
                <Select.Option key={item.stateNum} value={item.code}>
                  {`${item.name}(${item.code})`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="PostalCode"
            initialValue={returnData.shipToPostalCode}
            label={<FormLabel>Zip Code</FormLabel>}
            rules={[{ required: true, message: 'please input Zip Code' }]}
          >
            <Input id="zipCode_input" />
          </Form.Item>

          <Form.Item
            name="PostalCodeExt"
            initialValue={returnData.shipToPostalCodeExt}
            label={<FormLabel>Zip Code Ext</FormLabel>}
          >
            <Input id="zipCodeExt_input" />
          </Form.Item>

          <Form.Item
            name="DaytimePhone"
            initialValue={returnData.shipToDaytimePhone}
            label={<FormLabel>Phone #</FormLabel>}
          >
            <Input id="phone_input" />
          </Form.Item>
          {/* <Form.Item
                name="ShipToNightPhone"
                initialValue={returnData.shipToNightPhone}
                label={<FormLabel> Phone #</FormLabel>}
              >
                <Input />
              </Form.Item> */}
          <Form.Item
            name="Email"
            initialValue={returnData.shipToEmail}
            label={<FormLabel>Email</FormLabel>}
          >
            <Input id="email_input" />
          </Form.Item>
          <Form.Item
            name="Attention"
            initialValue={returnData.shipToAttention}
            label={<FormLabel>Attention</FormLabel>}
          >
            <Input id="attention_input" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
