import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, Row, Switch } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
  SmartButton,
  SmartInput,
} from '../../SmartComponent';
import {
  fetchWarehouseProductList,
  moveAllProductLocationToLocation,
} from 'services/inventory';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';
import { useTranslation } from 'react-i18next';
import { SelectWarehouse } from 'components/common';
import moment from 'moment';

const { Text } = Typography;
const columns = [
  {
    title: 'Item',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'selectWarehouse' | 'selectLocation'>(
    'selectWarehouse'
  ); //
  const [sLocation, setSLocation] = useState<string>('');
  const [sLocationNum, setSLocationNum] = useState<number>(0);
  const [tLocation, setTLocation] = useState<string>('');
  const [tWarehouse, setTWarehouse] = useState<WarehouseRow>();
  // const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseCode, setWarehouseCode] = useState<string>('');
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [isCreateLocation, setIsCreateLocation] = useState(false);
  const [isToZero, setIsToZero] = useState(true);
  const [isAutoSubmit, setIsAutoSubmit] = useState(true);
  const [isSameLocation, setIsSameLocation] = useState(true);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [dataInfo, setDataInfo] = useState({
    data: [],
    total: 0,
    current: 1,
    pageSize: 10,
  });
  const [submitting, setSubmitting] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [lastStr, setLastStr] = useState('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      // setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
      setWarehouseCode(
        getWarehouseCodeFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const onSubmit = useCallback(
    async (lNum: number, tCode: string) => {
      if (!lNum) {
        return message.error({ content: 'Parameter error' });
      }
      if (!tWarehouse || !tWarehouse.warehouseNum) {
        return message.error({ content: 'Parameter error' });
      }
      if (!tCode) {
        return message.error({ content: 'Parameter error' });
      }
      try {
        setSubmitting(true);
        const res = await moveAllProductLocationToLocation({
          fromWarehouse: warehouseId,
          fromLocation: lNum,
          toWarehouse: tWarehouse.warehouseNum,
          toLocation: tCode,
          autoCreate: isCreateLocation,
          toZero: isToZero,
        });
        setSubmitting(false);
        if (res) {
          message.success({ content: 'Moved/Merged Successfully!' });
          setLastStr(
            `Last Move: Source ${sLocation}, Destination ${tLocation}, Time ${moment().format(
              'hh:mm:ssA'
            )}`
          );
          setTLocation('');
          setSLocation('');
          setSLocationNum(0);
          setTotalQty(0);
          setDataInfo({
            data: [],
            total: 0,
            current: 1,
            pageSize: 10,
          });
        } else {
          message.error({ content: 'Moved/Merged Failed!' });
        }
      } catch (error) {
        setSubmitting(false);
      }
    },
    [isCreateLocation, isToZero, tWarehouse, warehouseId, sLocation, tLocation]
  );

  const checkSubmit = useCallback(
    (lNum: number, tCode: string) => {
      if (isAutoSubmit) {
        onSubmit(lNum, tCode);
      }
    },
    [isAutoSubmit, onSubmit]
  );

  const fetchProductsByLocationCode = useCallback(
    async (
      code: string,
      current: number,
      pageSize: number,
      fromGrid?: boolean
    ) => {
      try {
        const query = {
          $skip: (current - 1) * pageSize,
          $top: pageSize,
          $sortBy: 'sku',
          $calculateTotal: true,
          $count: true,
          $filter: `LocationCode eq '${code}'`,
        };
        setFetching(true);
        const res = await fetchWarehouseProductList(warehouseId, query);
        setFetching(false);
        setDataInfo({
          data: res.data,
          total: res.count,
          current,
          pageSize,
        });
        if (!fromGrid) {
          if (res.data.length === 0) {
            setSLocation('');
            setTotalQty(0);
            setSLocationNum(0);
            return message.error({ content: `no items found` });
          }
          setSLocation(code);
          setTotalQty(res.totalQuantity);
          if (res.data.length > 0) {
            setShowTable(true);
            setSLocationNum(res.data[0].locationNum);
          }
          let temp = tLocation;
          if (isSameLocation && !tLocation) {
            setTLocation(code);
            temp = code;
          }
          if (temp && res.data.length > 0) {
            checkSubmit(res.data[0].locationNum, temp);
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [isSameLocation, tLocation, warehouseId, checkSubmit]
  );

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'selectWarehouse' && (
        <>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.createLocation')}
            </Text>
            <Switch checked={isCreateLocation} onChange={setIsCreateLocation} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.mustBeZero')}
            </Text>
            <Switch checked={isToZero} onChange={setIsToZero} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.autoSubmit')}
            </Text>
            <Switch checked={isAutoSubmit} onChange={setIsAutoSubmit} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.sameLocation')}
            </Text>
            <Switch checked={isSameLocation} onChange={setIsSameLocation} />
          </Row>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.moveAllProducts.destinationWarehouse')}
            </SmartFormLabel>
            <SelectWarehouse
              size="large"
              placeholder="Please select warehouse"
              withoutDefaultWarehouse
              onChange={(warehouseRow) => setTWarehouse(warehouseRow)}
              value={tWarehouse}
            />
          </SmartSpace>
          <SmartButton
            type="primary"
            onClick={() => {
              setShowTable(false);
              setStep('selectLocation');
            }}
          >
            {t('smart.movemerge.next')}
          </SmartButton>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}
      {step === 'selectLocation' && (
        <>
          <SmartSpin loading={fetching || submitting} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.moveAllProducts.sourceLocation')}
            </SmartFormLabel>
            <SmartScanner
              id="scanSorceLocation"
              style={{ marginTop: 10 }}
              defaultValue={sLocation}
              setNull={false}
              autoSelect
              onChangeValue={(value) => {
                if (value) {
                  fetchProductsByLocationCode(value, 1, 10);
                } else {
                  setSLocation('');
                  setSLocationNum(0);
                  setDataInfo({
                    data: [],
                    total: 0,
                    current: 1,
                    pageSize: 10,
                  });
                }
              }}
            />
          </SmartSpace>
          <SmartSpace>
            <SmartFormLabel
              style={{
                color: GreenColor,
                fontWeight: 600,
                fontSize: 18,
                marginBottom: 10,
              }}
            >
              {t('smart.movemerge.moveAllProducts.destinationLocation')}
            </SmartFormLabel>
            <SmartInput
              id="destinationLocation"
              value={tLocation}
              onChange={(e) => setTLocation(e.target.value)}
              allowClear
              onKeyDown={(e) => {
                if ([9, 13].indexOf(e.keyCode) > -1) {
                  e.preventDefault();
                  if (sLocationNum && tLocation) {
                    checkSubmit(sLocationNum, tLocation);
                  }
                }
              }}
            />
          </SmartSpace>
          <SmartButton
            type="primary"
            loading={submitting}
            disabled={!(tLocation && sLocationNum)}
            style={{ marginBottom: 0 }}
            onClick={() => {
              onSubmit(sLocationNum, tLocation);
            }}
          >
            {t('smart.movemerge.submit')}
          </SmartButton>
          {lastStr && (
            <Typography.Paragraph style={{ fontSize: 12 }} type="secondary">
              {lastStr}
            </Typography.Paragraph>
          )}
          {showTable && (
            <SmartRow
              justify="space-between"
              style={{ marginTop: 10, paddingLeft: 4, paddingRight: 4 }}
            >
              <Text style={{ fontSize: 16 }}>
                {t('smart.movemerge.moveAllProducts.totalSku')}:{' '}
                <Text>{dataInfo.total}</Text>
              </Text>
              <Text style={{ fontSize: 16 }}>
                {t('smart.movemerge.moveAllProducts.totalQty')}:{' '}
                <Text>{totalQty}</Text>
              </Text>
            </SmartRow>
          )}
          {showTable && (
            <Table
              size="small"
              rowKey="fulfillmentProductNum"
              bordered
              columns={columns}
              dataSource={dataInfo.data}
              loading={fetching}
              pagination={{
                current: dataInfo.current,
                pageSize: dataInfo.pageSize,
                total: dataInfo.total,
              }}
              onChange={(pagination, filters, sorter) => {
                fetchProductsByLocationCode(
                  sLocation,
                  pagination.current || 1,
                  pagination.pageSize || 10,
                  true
                );
              }}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          )}
          <Row
            justify="start"
            style={{ marginBottom: 8, marginTop: lastStr ? 0 : 8 }}
          >
            <Text strong>
              {t('smart.movemerge.moveAllProducts.sourceWarehouse')}:{' '}
              <Text style={{ fontWeight: 700 }}>{warehouseCode}</Text>
            </Text>
          </Row>
          <Row justify="start" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.destinationWarehouse')}:{' '}
              <Text style={{ fontWeight: 700 }}>
                {tWarehouse?.warehouseCode}
              </Text>
            </Text>
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.createLocation')}
            </Text>
            <Switch disabled checked={isCreateLocation} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.mustBeZero')}
            </Text>
            <Switch disabled checked={isToZero} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.autoSubmit')}
            </Text>
            <Switch disabled checked={isAutoSubmit} />
          </Row>
          <Row justify="space-between" style={{ marginBottom: 8 }}>
            <Text strong>
              {t('smart.movemerge.moveAllProducts.sameLocation')}
            </Text>
            <Switch disabled checked={isSameLocation} />
          </Row>
          <SmartBackButton>
            <SmartButton
              type="primary"
              onClick={() => {
                setTLocation('');
                setSLocation('');
                setSLocationNum(0);
                setTotalQty(0);
                setDataInfo({
                  data: [],
                  total: 0,
                  current: 1,
                  pageSize: 10,
                });
                setShowTable(false);
                setLastStr('');
                setStep('selectWarehouse');
              }}
            >
              {t('smart.common.goBack')}
            </SmartButton>
          </SmartBackButton>
        </>
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
