/**
 * Shipment related service requests are here.
 */
import dayjs from 'dayjs';
import { HTTP_STATUS_OK } from 'constants/config';
import { validateHttpStatus } from 'utils';
import { digitBridgeApi, digitBridgePrinterApi } from './http';
import moment from 'moment';

/**
 * Auto split shipment.
 */
export const autoSplitShipment = async (
  shipmentNum: number,
  items: Array<{ [key: string]: number }>
): Promise<any> => {
  const json = JSON.stringify({
    FromShipmentNum: shipmentNum,
    ToShipmentNum: 0,
    Items: items,
  });
  const { code, data } = (await digitBridgeApi.post(
    `/api/Shipments/${shipmentNum}/autoshipment`,
    json,
    { validateStatus: (status: any) => validateHttpStatus([], status) }
  )) as any;

  if (code === HTTP_STATUS_OK) {
    return data;
  }
};

/**
 * Export shipment packing slip.
 */
export const exportShipmentPackingSlip = async (
  fulfillmentOrderNum: number,
  ShipmentNum: number,
  time: string,
): Promise<any> => {
  const params = {
    fulfillmentOrderNum,
    ShipmentNum,
    time,
  };

  return await digitBridgePrinterApi.get('/api/PackingSlip/Export', { params, responseType: 'arraybuffer' });
};

/**
 * Export shipment packing slip.
 */
export const exportShipmentGroupPackingSlip = async (
  fulfillmentOrderNum: number,
  ShipmentNum: number,
  time: string,
): Promise<any> => {
  const params = {
    fulfillmentOrderNum,
    ShipmentNum,
    time,
  };

  return await digitBridgePrinterApi.get('/api/GroupPackingSlip/Export', { params, responseType: 'arraybuffer' });
};

/**
 * Export shipment documents.
 */
 export const exportShipmentDocument = async (
  blobName: string,
  ShipmentNum: number,
): Promise<any> => {
  return await digitBridgeApi.get(`/api/shipments/${ShipmentNum}/document/${blobName}`, { responseType: 'arraybuffer' });
};

/**
 * Fetch carriers
 */
export const fetchCarriers = async (): Promise<Array<CarrierRow>> => {
  const { data } = await digitBridgeApi.get('/api/GetCarrierList');
  return data;
};

/**
 * Create ShipmentPackage
 * @param {string} packageType
 * @param {string} name
 * @param {number} length
 * @param {number} height
 * @param {string} LinearUnit
 * @param {number} weight
 * @param {string} WeightUnit
 * @param {number} scanBarcode
 * @param {string} barcode
 * @param {string} packagePictureLink
 * @param {number} usePictureToRemindUser
 * @returns {boolean}
 */
export const addCustomPackage = async (
  packageType: number,
  name: string,
  length: number,
  width: number,
  height: number,
  LinearUnit: number,
  weight: number,
  WeightUnit: number,
  scanBarcode: number,
  barcode: string,
  packagePictureLink: string,
  usePictureToRemindUser: number,
  isCalculateWeight: number,
  isCalculateDimension: number,
): Promise<boolean> => {
  const params = {
    name,
    packageType,
    length,
    width,
    height,
    LinearUnit,
    weight,
    WeightUnit,
    scanBarcode,
    barcode,
    status: 1,
    packagePictureLink,
    usePictureToRemindUser,
    isCalculateWeight,
    isCalculateDimension,
  };
  const json = JSON.stringify(params);
  await digitBridgeApi.post('/api/AddShippingCustomPackage', json);
  return true;
};

/**
 * Update ShipmentPackage
 * @param {number} shippingPackageNum
 * @param {number} packageType
 * @param {string} name
 * @param {number} length
 * @param {number} height
 * @param {string} LinearUnit
 * @param {number} weight
 * @param {string} WeightUnit
 * @param {number} scanBarcode
 * @param {string} barcode
 * @param {number} status
 * @param {string} packagePictureLink
 * @param {number} usePictureToRemindUser
 * @returns {boolean}
 */
export const updateCustomPackage = async (
  shippingPackageNum: number,
  packageType: number,
  name: string,
  length: number,
  width: number,
  height: number,
  LinearUnit: number,
  weight: number,
  WeightUnit: number,
  scanBarcode: number,
  barcode: string,
  status: number,
  packagePictureLink: string,
  usePictureToRemindUser: number,
  isCalculateWeight: number,
  isCalculateDimension: number,
): Promise<any> => {
  const params = {
    shippingCustomPackageNum: shippingPackageNum,
    packageType,
    name,
    length,
    width,
    height,
    LinearUnit,
    weight,
    WeightUnit,
    scanBarcode,
    barcode,
    status,
    packagePictureLink,
    usePictureToRemindUser,
    isCalculateWeight,
    isCalculateDimension,
  };
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/UpdateShippingCustomPackage', json);
};

/**
 * Fetch all shipment packages
 */
export const getAllPackageList = async (): Promise<
  Array<ShipmentPackageRow>
> => {
  //const params = { carrier: carrier.name };
  const { data } = await digitBridgeApi.get('/api/GetAllPackageList');
  return data.map((item: any) => {
    return {
      ...item,
      createDate: item.createDate
        ? dayjs(item.updateDate, 'YYYY-MM-DDTHH:mm:00z').toDate()
        : undefined,
    };
  });
};

/**
 * Fetch my shipment packages
 */
export const getCustomPackageList = async (): Promise<
  Array<ShipmentPackageRow>
> => {
  const params = {};
  const { data } = await digitBridgeApi.get('/api/GetCustomPackageList', {
    params,
  });
  return data.map((item: any) => {
    return {
      ...item,
      shippingPackageNum: item.shippingCustomPackageNum,
      createDate: item.createDate
        ? dayjs(item.updateDate, 'YYYY-MM-DDTHH:mm:00z').toDate()
        : undefined,
    };
  });
};

/**
 * Fetch aggregate package list.
 */
export const fetchPackageAggregateListByCarrier = async (
  warehouseNum: number,
  carrierNum?: number,
  channelAccountNum?: number,
): Promise<any> => {
  const params = { carrierNum, channelAccountNum, warehouseNum };
  const { data } = await digitBridgeApi.get('/api/GetPackageAggregateList', {
    params,
  });

  if (data) {
    return data;
  }
  return null;
};

/**
 * Fetch inserted items in a shipment.
 */
export const fetchShipmentInsertitems = async (
  shipmentNum: number,
): Promise<any> => {
  const { code, data } = await digitBridgeApi.get(`/api/Shipments/${shipmentNum}/insertitems`) as StringKAnyVPair;

  if (code === HTTP_STATUS_OK) {
    return data;
  }
};

/**
 * Fetch shipment packages of a carrier
 */
export const fetchShipmentPackageByCarrier = async (
  carrier: string
): Promise<StringKAnyVPair | null> => {
  const params = { carrier };
  const { data } = await digitBridgeApi.get('/api/GetPackageListByCarrier', {
    params,
  });

  if (data && typeof data === 'object') {
    return data;
  }

  return null;
};

/**
 * Fetch global shipment packages
 * @param {WarehouseRow} warehouse
 * @param {CarrierRow} carrier
 * @returns {Promise<Array<ShipmentPackageRow>>}
 */
export const getCarrierPackageList = async (
  warehouse: WarehouseRow,
  carrier: CarrierRow | undefined
): Promise<Array<ShipmentPackageRow>> => {
  let data = [];
  let carrierPackageList = [];
  if (carrier) {
    const res = await digitBridgeApi.get('/api/GetCarrierPackageList', {
      params: { carrierNum: carrier.carrierNum },
    });
    data = res.data;

    const res1 = await digitBridgeApi.get(
      '/api/GetShippingCarrierPackageList',
      {
        params: { carrierNum: carrier.carrierNum, warehouseNum: warehouse.id },
      }
    );
    carrierPackageList = res1.data;
  } else {
    const res = await digitBridgeApi.get('/api/GetCustomPackageList');
    data = res.data;
    const res1 = await digitBridgeApi.get('/api/GetShippingCustomPackageList', {
      params: { warehouseNum: warehouse.id },
    });
    carrierPackageList = res1.data;
  }
  const packages = data.map((item: any) => {
    return {
      ...item,
      checked: false,
    };
  });
  //merge status
  for (let i = 0; i < carrierPackageList.length; i++) {
    const item = carrierPackageList[i];
    const current = packages.find((currentPackage: any) => {
      if (
        currentPackage.carrierPackageNum === item.carrierPackageNum ||
        currentPackage.shippingCustomPackageNum ===
        item.shippingCustomPackageNum
      ) {
        return currentPackage;
      }
      return undefined;
    });
    if (current) {
      current.checked = item.status === 1;
      current.maxShippingFee = item.maxShippingFee;
      current.flatRate = item.flatRate;
    }
  }
  return packages;
};
/**
 * Delete packages
 * @param {number} packageNum
 * @returns {Promise<boolean>}
 */
export const deleteCustomPackage = async (
  packageNum: number
): Promise<boolean> => {
  await digitBridgeApi.delete(
    `/api/shippingCustomPackages/${packageNum}`
  );
  return true;
};

/**
 * update packages state
 * @param {Array<ShipmentPackageRow>} packages
 * @returns {Primise<boolean>}
 */
export const updateCarrierPackages = async (
  warehouse: WarehouseRow,
  packages: Array<ShipmentPackageRow>
): Promise<boolean> => {
  const json = JSON.stringify(
    packages.map((item) => {
      const data = {
        warehouseNum: warehouse.id,
        status: item.checked ? 1 : 0,
        maxShippingFee: item.maxShippingFee,
      } as any;
      const { carrierPackageNum, shippingCustomPackageNum } = item as any;
      if (carrierPackageNum) {
        data['carrierPackageNum'] = carrierPackageNum;
        data['packageClass'] = 2;
      } else {
        data['shippingCustomPackageNum'] = shippingCustomPackageNum;
        data['packageClass'] = 1;
      }
      return data;
    })
  );
  await digitBridgeApi.post('/api/SetPackages', json);
  return true;
};

/**
 * Fetch the detail of a shipment.
 * @param {number} shipmentNum
 * @return {object|undefined} return undefined when not found shipment
 */
export const fetchShipmentDetail = async (
  shipmentNum: number,
): Promise<any> => {
  const { data, isSuccess } = await digitBridgeApi.get(`/api/Shipments/${shipmentNum}/detail`) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  } else {
    return null
  }
};

export const fetchShipmentHistoryDetail = async (
  shipmentNum: number,
): Promise<any> => {
  const { data, isSuccess } = await digitBridgeApi.get(`/api/Shipments/${shipmentNum}/histories/detail`) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  } else {
    return null
  }
};

/**
 * Move lines to a shipment.
 * @param {number} shipmentNum
 * @param {Array<{ [key: string]: number }>} items
 * @returns {Primise<boolean>}
 */
export const moveLinesToShipment = async (
  sourceShipmentNum: number,
  targetShipmentNum: number,
  items: Array<{ [key: string]: number }>
): Promise<number> => {
  const json = JSON.stringify({
    FromShipmentNum: sourceShipmentNum,
    ToShipmentNum: targetShipmentNum,
    Items: items,
  });
  const { code, data } = (await digitBridgeApi.post(
    `/api/Shipments/${sourceShipmentNum}/movelines`,
    json,
    { validateStatus: (status: any) => validateHttpStatus([], status) }
  )) as StringKAnyVPair;

  if (code === 500) {
    return -1;
  }

  return data;
};

/**
 * new shipment
 * @param {number} shipmentNum
 * @param {Array<{ [key: string]: number }>} items
 * @returns {Primise<boolean>}
 */
export const newShipment = async (
  shipmentNum: number,
  items: Array<{ [key: string]: number }>
): Promise<number> => {
  const json = JSON.stringify({
    FromShipmentNum: shipmentNum,
    ToShipmentNum: 0,
    Items: items,
  });
  const { code, data } = (await digitBridgeApi.post(
    `/api/Shipments/${shipmentNum}/movelines`,
    json,
    { validateStatus: (status: any) => validateHttpStatus([], status) }
  )) as any;
  if (code === 500) {
    return -1;
  }
  return data;
};

/**
 * remove shipment
 * @param {number} shipmentNum
 * @returns {Primise<boolean>}
 */
export const removeShipment = async (shipmentNum: number): Promise<boolean> => {
  const { code } = (await digitBridgeApi.post(
    `/api/Shipments/${shipmentNum}/remove`,
    null,
    { validateStatus: (status: any) => validateHttpStatus([500], status) }
  )) as any;
  if (code === 500) {
    return false;
  }
  return true;
};
/**
 * get package List by Carrier
 */
export const getPackageListByCarrier = async (
  carrier: string
): Promise<any> => {
  const params = {
    carrier,
  };
  return await digitBridgeApi.get('/api/GetPackageListByCarrier', { params });
};

/**
 * Validate address
 * @param {number} shipmentNum
 * @returns {Promise<OrderShipment>}
 */
export const validateAddress = async (
  shipmentNum: number
): Promise<OrderShipment> => {
  const { data } = await digitBridgeApi.post(
    `/api/Shipments/${shipmentNum}/verifyaddress`
  );
  return data;
};

export const getCarrierServices = async (carrierNum: number): Promise<any> => {
  return await digitBridgeApi.get('/api/GetCarrierServiceList', { params: { carrierNum } });
};

/**
 * Get the service list in a carrier.
 */
export const getCarrierServiceList = async (
  carrierNum: number,
): Promise<any> => {
  const { isSuccess, data } = await digitBridgeApi.get('/api/GetCarrierServiceList', { params: { carrierNum } }) as StringKAnyVPair;

  if (isSuccess) {
    if (Array.isArray(data)) {
      return data;
    }
  }
};

export const getCarrierPackages = async (carrierNum: number): Promise<any> => {
  return await digitBridgeApi.get('/api/GetCarrierPackageList', { params: { carrierNum } });
};

/**
 * mark shipped
 */
type MarkShippedfromShippingParams = {
  TrackingNumber: string,
  SecondaryTrackingNumber?: string,
  ShippingCost: number,
  ShipDate: Date,
}

export const markShippedfromShipping = async (shipmentNum: number, params: MarkShippedfromShippingParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/shipments/${shipmentNum}/shipped`, json)
}
type MarkShippedfromPendingParams = {
  ShipAccountNum: number,
  CarrierNum: number,
  Carrier: string,
  ServiceNum: number,
  ServiceCode: string,
  Service: string,
  PackageNum: number,
  PackageCode: string,
  Package: string,
  Signature: number,
  BillToType: number,
  BillThirdPartyAccount?: string,
  BillThirdPartyPostalCode?: string,
  BillThirdPartyCountry?: string,
  TrackingNumber: string,
  SecondaryTrackingNumber?: string,
  ShippingCost: number,
  ShipDate: Date,
  Note?: string,
}
export const markShippedfromPending = async (shipmentNum: number, params: MarkShippedfromPendingParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/shipments/${shipmentNum}/shipped`, json)
}

export const resetShipmentPending = async (shipmentNum: number): Promise<any> => {
  return await digitBridgeApi.post(`/api/shipments/${shipmentNum}/reset`)
}

/**
 * query format is like:
 * {
 *   sku: string;
 *   order_begin: string;
 *   order_end: string;
 *   enter_begin: string;
 *   enter_end: string;
 *   channelAccounts: number[];
 * }
 */
export const queryShipmentList = async (
  warehouseNum: number,
  query: StringKAnyVPair,
): Promise<any> => {
  const params = { ...query };
  //const { data, isSuccess } = await digitBridgeApi.get(`/api/shipments/${warehouseNum}/pending`, { params }) as StringKAnyVPair;
  const { data, isSuccess } = await digitBridgeApi.post(`/api/shipments/${warehouseNum}/pending`, JSON.stringify(params)) as StringKAnyVPair;

  if (isSuccess) {
    return data;
  }
};

export const cancelShipment = async (shipmentNum: number, Reason: string): Promise<any> => {
  const json = JSON.stringify({
    ShipmentNum: shipmentNum,
    Reason
  })
  return await digitBridgeApi.post(`/api/shipments/${shipmentNum}/cancel`, json)
}

export const getPackageTypes = async ():Promise<any> => await digitBridgeApi.get('/api/packagetype');

export const retryShopAround = async (shipments: number[]): Promise<any> => {
  const json = JSON.stringify(shipments)
  return await digitBridgeApi.post('api/shipments/retryshoparound', json)
};

/**
 * Save lots of lines in shipment.
 */
export const saveShipmentLineLots = async (
  sn: number,
  d: any
) => {
  const { isSuccess } = await digitBridgeApi.post(`/api/Shipments/${sn}/lines/items`, d) as StringKAnyVPair;

  if (isSuccess) {
    return true;
  }
};

export const setShippingLabelRelease = async (shipmentNum: number[]): Promise<any> => {
  return await digitBridgeApi.post(`api/Shipments/${shipmentNum}/release`)
}

export const resendtoErp = async (shipmentNum: number): Promise<any> => {
  return await digitBridgeApi.post(`api/shipments/${shipmentNum}/resync`)
}

export const resync3pl = async (shipmentNum: number[]): Promise<any> => {
  return await digitBridgeApi.post(`api/shipments/${shipmentNum}/resync3pl`)
}

export const holdShipment = async (shipmentNum: number, reason: string): Promise<any> => {
  const json = JSON.stringify({
    ExpirationDate: moment().format(),
    HoldReason: reason
  })
  return await digitBridgeApi.post(`api/Shipments/${shipmentNum}/hold`, json)
}

export const unholdShipment = async (shipmentNum: number, reason: string): Promise<any> => {
  const json = JSON.stringify({
    UnHoldReason: reason
  })
  return await digitBridgeApi.post(`api/Shipments/${shipmentNum}/unhold`, json)
}

export const batchResendToErp = async (params: number[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('api/shipments/resync', json)
}

export const syncToErp = async (params: number[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('api/shipments/sync', json, { timeout: 300000 })
}
