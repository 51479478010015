import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Card, Space } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
  SmartButton,
} from '../../SmartComponent';
import { queryProductByKeyword } from 'services/product';
import { queryLocationsByKeyword } from 'services/warehouse';
import { fetchWarehouseProductList2 } from 'services/inventory';
import FinishDialog from './Finish';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  getWarehouseIdFromCache,
  getWarehouseCodeFromCache,
  getProfileSettingValue,
} from 'utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type Item = {
  id: string;
  sku: string;
  upc?: string;
  lotNumber?: string;
  uom: string;
  rate: number;
  qty: number;
};

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  // const [locations, setLocations] = useState<WarehouseLocationRow[]>([]);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [step, setStep] = useState<string>('sourceLocation');
  const [sLocation, setSLocation] = useState<WarehouseLocationRow>();
  const [tLocation, setTLocation] = useState<WarehouseLocationRow>();
  const [productList, setProductList] = useState<any[]>([]);
  const [productInfo, setProductInfo] = useState<any>();
  const [moveHistory, setMoveHistory] = useState<Item[]>([]);
  const [uomEnable, setUomEnable] = useState(true);
  const [lotEnable, setLotEnable] = useState(true);
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  const queryLocation = useCallback(
    async (value: string, step: string, callback: (value: any) => void) => {
      try {
        setFetching(true);
        const res = await queryLocationsByKeyword(warehouseId, value, true);
        setFetching(false);

        if (!res.error) {
          callback(res);
          if (step.length > 0) {
            setStep(step);
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId]
  );

  const fetchProductBySKU = useCallback(
    async (value: string) => {
      try {
        const info = await queryProductByKeyword(value);
        if (info && info.length > 0) {
          const params = {
            warehouseNum: warehouseNum,
            locationNum: sLocation?.locationNum || 0,
            uniqueCode: value,
          };
          const res = await fetchWarehouseProductList2(params);
          if (res && res.length > 0) {
            setProductInfo(info[0]);
            setProductList(res);
            setFinishDialogVisible(true);
          } else {
            message.error(
              `[${value}] not found in [${sLocation?.locationCode}]`
            );
          }
        } else {
          message.error(`[${value}] not found`);
        }
      } catch (error) {}
    },
    [warehouseNum, sLocation]
  );

  const getTotalQty = useCallback(() => {
    let totalQty = 0;
    moveHistory.map((i) => (totalQty += i.qty));
    return totalQty;
  }, [moveHistory]);

  useEffect(() => {
    if (step === 'sourceLocation' || step === 'targetLocation') {
      setMoveHistory([]);
      setProductList([]);
      setProductInfo(undefined);
    }
  }, [step]);

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'sourceLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.scanSourceLocationtoStart')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, 'targetLocation', setSLocation);
              }}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}
      {step === 'targetLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>{t('smart.movemerge.from')}</SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {sLocation?.locationCode}
            </Text>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.scanDestinationLocation')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                if (value === sLocation?.locationCode) {
                  return message.error('Cannot use the same location');
                } else {
                  queryLocation(value, 'scanItem', setTLocation);
                }
              }}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              setStep('sourceLocation');
            }}
          />
        </>
      )}
      {step === 'scanItem' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartRow>
              <SmartFormLabel>{t('smart.movemerge.from')}</SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>{t('smart.movemerge.to')}</SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {tLocation?.locationCode}
              </Text>
            </SmartRow>
          </SmartSpace>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.scanItem')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                fetchProductBySKU(value);
              }}
            />
          </SmartSpace>
          <SmartRow>
            <SmartFormLabel>
              {t('smart.movemerge.totalMovedQty')}
            </SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {getTotalQty()}
            </Text>
          </SmartRow>
          {/* <Table
            size="small"
            columns={columns}
            dataSource={moveHistory}
            bordered
            pagination={false}
          /> */}
          {moveHistory.length > 0 && (
            <Card bodyStyle={{ padding: 4 }}>
              {moveHistory.map((item) => (
                <Card
                  type="inner"
                  style={{ marginBottom: 4 }}
                  key={item.id}
                  bodyStyle={{ padding: 4 }}
                >
                  <Space direction="vertical">
                    <Typography.Text>{`${t('common.sku')}: ${
                      item.sku
                    }`}</Typography.Text>
                    <Typography.Text>{`${t('common.upc')}: ${
                      item.upc
                    }`}</Typography.Text>
                    {lotEnable && (
                      <Typography.Text>{`${t('common.lotNumber')}: ${
                        item.lotNumber
                      }`}</Typography.Text>
                    )}
                    {uomEnable && (
                      <Space>
                        <Typography.Text>{`${t('common.uom')}: ${
                          item.uom
                        }`}</Typography.Text>
                        <Typography.Text>{`${t('common.rate')}: ${
                          item.rate
                        }`}</Typography.Text>
                      </Space>
                    )}
                    <Space>
                      <Typography.Text>{`${t('common.qty')}: ${
                        item.qty
                      }`}</Typography.Text>
                      {uomEnable && (
                        <Typography.Text>{`${t('common.baseQty')}: ${
                          item.qty * item.rate
                        }`}</Typography.Text>
                      )}
                    </Space>
                  </Space>
                </Card>
              ))}
            </Card>
          )}

          <SmartBackButton>
            <SmartButton
              type="primary"
              onClick={() => {
                setStep('sourceLocation');
              }}
            >
              {t('smart.movemerge.startFromNewSourceLocation')}
            </SmartButton>
            <SmartButton
              type="primary"
              onClick={() => {
                setStep('targetLocation');
              }}
            >
              {t('smart.movemerge.startFromNewDestination')}
            </SmartButton>
          </SmartBackButton>
        </>
      )}
      {finishDialogVisible && tLocation && sLocation && (
        <FinishDialog
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          tLocation={tLocation}
          sLocation={sLocation}
          productList={productList}
          productInfo={productInfo}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
          // lotNumberList={[]}
          // uomList={[]}
          // locationUomList={[]}
          // locationLotNumberList={[]}
          onCloseModal={() => setFinishDialogVisible(false)}
          onSubmit={(value: Item) => {
            const tempIndex = moveHistory.findIndex((i) => i.id === value.id);
            if (tempIndex > -1) {
              const tempItem = {
                ...value,
                qty: moveHistory[tempIndex].qty + value.qty,
              };
              const temp = [...moveHistory];
              temp.splice(tempIndex, 1);
              setMoveHistory([{ ...tempItem }, ...temp]);
            } else {
              setMoveHistory((prev) => [{ ...value }, ...prev]);
            }
            setFinishDialogVisible(false);
            setProductInfo(undefined);
            setProductList([]);
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
