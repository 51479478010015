import { digitBridgeApi } from './http';

export const addWhiteList = async (params: {
    ChannelAccountNum: number,
    ChannelOrderId: string
}[]): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post('/api/orderWhiteList', json);
};

export const getWhiteList = async (params: {
    $skip: number,
    $top: number,
    $sortBy: string,
    channelAccountNum?: number,
    channelOrderId?: string
}): Promise<any> => {
    return await digitBridgeApi.get('/api/orderWhiteList', { params });
};

export const delWhiteList = async (nums: number[]): Promise<any> => {
    const json = JSON.stringify(nums)
    return await digitBridgeApi.delete('/api/orderWhiteList', { data: json });
};

export const getWhiteListHistory = async (params: {}): Promise<any> => {
    return await digitBridgeApi.get('/api/orderWhiteList/history', { params })
}