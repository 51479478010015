import React from 'react';

function Link({
  style = {},
  height = 16,
  width = 16,
  viewBox = '0 0 1024 1024',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      height={height}
      viewBox={viewBox}
      width={width}
    >
      <path
        d="M912 1008.512H15.488V112h448.256v96H111.488v704.512H816V560.256h96z"
        p-id="3306"
      />
      <path
        d="M918.208 37.888l67.904 67.904L545.984 545.92l-67.904-67.84z"
        p-id="3307"
      />
      <path d="M1007.168 310.656h-96V112h-208V16h304z" />
    </svg>
  );
}

export default Link;
