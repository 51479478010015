import React, { useCallback, useEffect, useState } from 'react';
import { message, HeaderTitle } from 'components/common';
import {
  fetchWarehouseEntity,
  fetchWarehouseLocationList,
} from 'services/warehouse';
import { fetchZone, fetchZones } from 'services/zone';
// import { getRouterParameters } from 'utils';
import ZoneLocationRelations from './Relations';
import { useLocation } from 'react-router-dom';

export default function ZoneLocation(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [zone, setZone] = useState<ZoneRow>();
  const [locations, setLocations] = useState<Array<LocationZoneRow>>([]);
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  // const query = getRouterParameters();
  const warehouseNum = Number(searchParams.get('warehouseNum'));
  const warehouseId = searchParams.get('warehouseId');
  const zoneId = Number(searchParams.get('zoneId'));
  const [zoneKeyValue, setZoneKeyValue] = useState<{ [key: string]: string }>(
    {}
  );
  /**
   * handle reload data
   */
  const handleReload = useCallback(async () => {
    if (!zone || !warehouseId) {
      return;
    }
    setLoading(true);
    // const cacheZones = { [zone.id]: zone.name };
    //fetch locations
    const newLocations = await fetchWarehouseLocationList(warehouseId, false);
    //set zoneName
    // for (let i = 0; i < newLocations.length; i++) {
    //   const location = newLocations[i];
    //   const { zoneNum } = location;
    //   location.zoneName = '';
    //   if (typeof zoneNum === 'number' && zoneNum > 1) {
    //     const cacheName = cacheZones[zoneNum];
    //     if (cacheName) {
    //       location.zoneName = cacheName;
    //     } else {
    //       /* eslint-disable no-await-in-loop */
    //       const currentZone = await fetchZone(warehouseId, zoneNum);
    //       if (currentZone) {
    //         location.zoneName = currentZone.name;
    //         cacheZones[currentZone.id] = currentZone.name;
    //       }
    //     }
    //   }
    // }
    setLocations(newLocations);
    setLoading(false);
  }, [zone, warehouseId]);
  useEffect(() => {
    const fetch = async () => {
      if (!warehouseNum || !warehouseId) {
        message.warning({ content: 'Please Select Warehouse!' });
        return;
      }
      setLoading(true);
      //fetch warehouse
      const newWarehouse = await fetchWarehouseEntity(warehouseId);
      setWarehouse(newWarehouse);
      if (!newWarehouse) {
        setLoading(false);
        message.warning({ content: 'Please Select Warehouse!' });
        return;
      }
      if (!zoneId) {
        setLoading(false);
        message.warning({ content: 'Please Select Zone!' });
        return;
      }
      //fetch all zone name
      const zones = await fetchZones(warehouseId);
      const temp: { [key: string]: string } = {};
      zones.map((i) => {
        temp[i.id] = i.name;
        return true;
      });
      setZoneKeyValue(temp);
      //fetch zone
      const newZone = await fetchZone(warehouseId, zoneId);
      setZone(newZone);
      // const cacheZones = { [newZone.id]: newZone.name };
      //fetch locations
      const newLocations = await fetchWarehouseLocationList(warehouseId, false);
      //set zoneName
      // for (let i = 0; i < newLocations.length; i++) {
      //   const location = newLocations[i];
      //   const { zoneNum } = location;
      //   location.zoneName = '';
      //   if (typeof zoneNum === 'number' && zoneNum > 1) {
      //     const cacheName = cacheZones[zoneNum];
      //     if (cacheName) {
      //       location.zoneName = cacheName;
      //     } else {
      //       /* eslint-disable no-await-in-loop */
      //       const currentZone = await fetchZone(warehouseId, zoneNum);
      //       if (currentZone) {
      //         location.zoneName = currentZone.name;
      //         cacheZones[currentZone.id] = currentZone.name;
      //       }
      //     }
      //   }
      // }
      setLocations(newLocations);
      setLoading(false);
    };
    fetch();
  }, [zoneId, warehouseNum, warehouseId]);
  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Admin',
          {
            type: 'link',
            content: 'Zone Management',
            url: '/administration/zone-management',
          },
          {
            type: 'link',
            content: warehouse && (warehouse as any).warehouseName,
            url: `/administration/zones?warehouseId=${warehouseId}&warehouseNum=${warehouseNum}`,
          },
          zone && (zone as any).name,
        ]}
      />
      {zone && (
        <ZoneLocationRelations
          loading={loading}
          locations={locations}
          warehouseNum={warehouseNum}
          warehouseId={warehouseId || ''}
          zone={zone}
          zoneKeyValue={zoneKeyValue}
          onReload={handleReload}
        />
      )}
    </>
  );
}
