import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Unpack from 'components/Sales/Smart/Prepack/unpack/index';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartMove(): JSX.Element {
  return (
    <ContentLayout 
        titleKey="titles.prePack" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
     >
      <Unpack />
    </ContentLayout>
  );
}
