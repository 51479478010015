import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ExclamationCircleOutlined,
  LockFilled,
  UnlockFilled,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  Typography,
  InputNumber,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Button,
} from 'antd';
import {
  getWarehouseIdFromCache,
  playAudio,
  getWarehouseCodeFromCache,
} from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import { queryLocationsByKeyword } from 'services/warehouse';
import {
  fetchWarehouseProductList,
  moveProductLocationToLocation,
} from 'services/inventory';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';

const { Text } = Typography;

type SkuItem = {
  fulfillmentProductNum: number;
  sku: string;
  upc: string;
  sourceQty: number;
  moveQty: number;
};

type LastItem = {
  sku: string;
  qty: number;
  time: string;
  totalSku: number;
  totalQty: number;
};

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<string>('sourceLocation'); //'sourceLocation' | 'targetLocation' | 'scanQty'
  const [sLocation, setSLocation] = useState<WarehouseLocationRow>();
  const [tLocation, setTLocation] = useState<WarehouseLocationRow>();
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [skuList, setSkuList] = useState<SkuItem[]>([]);
  const [qtyLock, setQtyLock] = useState(false);
  const [lastItem, setLastItem] = useState<LastItem>();

  const [form] = Form.useForm();

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const delLine = (line: SkuItem) => {
    const temp = [...skuList];
    const newTemp = temp.filter(
      (i) => i.fulfillmentProductNum !== line.fulfillmentProductNum
    );
    setSkuList([...newTemp]);
    let totalQty = 0;
    newTemp.map((i) => {
      totalQty = totalQty + i.moveQty;
      return true;
    });
    setLastItem((prev:any) => {
      return {
        ...prev,
        totalSku: newTemp.length,
        totalQty: totalQty,
      }
    })
  };

  const columns: ColumnsType<any> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      key: 'upc',
    },
    {
      title: 'Qty',
      dataIndex: 'moveQty',
      key: 'moveQty',
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            onClick={() => {
              Modal.confirm({
                title: t('smart.movemerge.multipleMove.confirmDelete'),
                icon: <ExclamationCircleOutlined />,
                content: (
                  <>
                    <p>{t('smart.movemerge.multipleMove.removeRecode')}</p>
                    <Space size="small" direction="vertical">
                      <span>
                        {t('smart.movemerge.multipleMove.lastItemFrom', {
                          value: sLocation?.locationCode || '',
                        })}
                      </span>
                      <span>
                        {t('smart.movemerge.multipleMove.lastItemTo', {
                          value: tLocation?.locationCode || '',
                        })}
                      </span>
                      <span>{`SKU: ${record.sku}`}</span>
                      <span>{`UPC: ${record.upc}`}</span>
                      <span>
                        {t('smart.movemerge.multipleMove.lastItemQty', {
                          value: `${record.moveQty || ''}`,
                        })}
                      </span>
                    </Space>
                  </>
                ),
                okText: t('common.yes'),
                okButtonProps: { className: 'hover-danger-button' },
                cancelText: t('common.no'),
                onOk() {
                  delLine(record);
                },
                onCancel() {},
              });
            }}
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  const moveProduct = useCallback(async () => {
    try {
      setFetching(true);
      const r = skuList.map(async (item) => {
        return await moveProductLocationToLocation({
          fromWarehouse: warehouseId,
          fromLocation: sLocation?.locationNum || -1,
          fulfillmentProductNum: item.fulfillmentProductNum,
          quantity: item.moveQty,
          toWarehouse: warehouseNum,
          toLocation: tLocation?.locationNum || -1,
        });
      });
      const resPromise = await Promise.all(r);
      setFetching(false);
      if (resPromise) {
        message.success({ content: 'Move successfully' });
        //reset
        if (!qtyLock) {
          form.setFieldsValue({ qty: 1 });
        }
        setLastItem(undefined);
        setSkuList([]);
      }
    } catch (error) {
      setFetching(false);
    }
  }, [sLocation, tLocation, warehouseId, warehouseNum, qtyLock, skuList, form]);

  const fetchProductsByLocationCode = async (code: string) => {
    try {
      const query = {
        $skip: 0,
        $top: 10000,
        $sortBy: 'locationCode',
        $calculateTotal: true,
        $count: true,
        uniqueCode: code,
        locationNum: sLocation?.locationNum,
      };
      const { qty } = form.getFieldsValue();
      setFetching(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setFetching(false);
      if (res.data.length > 0) {
        const tempNums = skuList.map((i) => i.fulfillmentProductNum);
        const index = tempNums.indexOf(res.data[0].fulfillmentProductNum);
        const temp = [...skuList];
        if (index > -1) {
          if (res.data[0].quantity >= temp[index].moveQty + qty) {
            playAudio('success');
            temp[index] = {
              ...temp[index],
              sourceQty: res.data[0].quantity,
              moveQty: temp[index].moveQty + qty,
            };
            setSkuList([...temp]);
            let totalQty = 0;
            temp.map((i) => {
              totalQty = totalQty + i.moveQty;
              return true;
            });
            setLastItem({
              sku: res.data[0].sku,
              qty: qty,
              time: moment().format('hh:mm:ss'),
              totalSku: temp.length,
              totalQty: totalQty,
            });
          } else {
            playAudio('error');
            message.info('Not enough stock');
          }
        } else {
          if (res.data[0].quantity >= qty) {
            playAudio('success');
            temp.push({
              fulfillmentProductNum: res.data[0].fulfillmentProductNum,
              sku: res.data[0].sku,
              upc: res.data[0].upc,
              sourceQty: res.data[0].quantity,
              moveQty: qty,
            });
            setSkuList([...temp]);
            let totalQty = 0;
            temp.map((i) => {
              totalQty = totalQty + i.moveQty;
              return true;
            });
            setLastItem({
              sku: res.data[0].sku,
              qty: qty,
              time: moment().format('hh:mm:ss'),
              totalSku: temp.length,
              totalQty: totalQty,
            });
          } else {
            playAudio('error');
            message.info('Not enough stock');
          }
        }
      } else {
        playAudio('error');
        message.error({ content: 'not found Item' });
      }
      form.setFieldsValue(
        qtyLock ? { item: undefined } : { item: undefined, qty: 1 }
      );
    } catch (error) {
      setFetching(false);
    }
  };

  const handleKeyDown = async (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      evt.preventDefault();
      if (evt.target.value) {
        //query
        fetchProductsByLocationCode(evt.target.value);
      }
    }
  };

  const queryLocation = useCallback(
    async (value: string, step: string, callback: (value: any) => void) => {
      try {
        setFetching(true);
        const res = await queryLocationsByKeyword(warehouseId, value, true);
        setFetching(false);

        if (!res.error) {
          callback(res);
          if (step.length > 0) {
            setStep(step);
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId]
  );

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'sourceLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.scanSourceLocationtoStart')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, 'targetLocation', setSLocation);
              }}
            />
          </SmartSpace>

          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'targetLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow justify="space-between">
            <Text>
              {t('smart.movemerge.from')}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </Text>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.movemerge.scanDestinationLocation')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, 'scanQty', setTLocation);
              }}
            />
          </SmartSpace>

          <SmartBackButton
            onClick={() => {
              setStep('sourceLocation');
            }}
          />
        </>
      )}

      {step === 'scanQty' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow justify="start">
            <Text>
              {t('smart.movemerge.from')}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </Text>
          </SmartRow>
          <SmartRow justify="start">
            <Text>
              {t('smart.movemerge.to')}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {tLocation?.locationCode}
              </Text>
            </Text>
          </SmartRow>
          <Form form={form}>
            <Form.Item
              label={
                <Text
                  style={{
                    fontSize: 18,
                    color: 'rgb(86, 149, 34)',
                    fontWeight: 600,
                  }}
                >
                  {t('common.qty')}:
                </Text>
              }
            >
              <Space size="small">
                <Form.Item noStyle name="qty" initialValue={1}>
                  <InputNumber style={{ marginLeft: 8 }} min={1} />
                </Form.Item>
                {qtyLock ? (
                  <LockFilled
                    onClick={() => setQtyLock((prev) => !prev)}
                    style={{ color: 'red', fontSize: 24 }}
                  />
                ) : (
                  <UnlockFilled
                    onClick={() => setQtyLock((prev) => !prev)}
                    style={{ fontSize: 24 }}
                  />
                )}
              </Space>
            </Form.Item>

            <Form.Item
              name="item"
              label={
                <Text
                  style={{
                    fontSize: 18,
                    color: 'rgb(86, 149, 34)',
                    fontWeight: 600,
                  }}
                >
                  {t('smart.movemerge.scanItem')}:
                </Text>
              }
            >
              <Input
                autoFocus
                style={{ marginLeft: 8 }}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </Form>

          {lastItem && (
            <>
              <SmartRow>
                <Text>{t('smart.movemerge.multipleMove.lastItem')}</Text>
              </SmartRow>
              <SmartRow>
                <Space>
                  <Text>
                    {t('smart.movemerge.multipleMove.lastItemSku', {
                      value: lastItem?.sku,
                    })}
                  </Text>
                  <Text>
                    {t('smart.movemerge.multipleMove.lastItemQty', {
                      value: `${lastItem?.qty || ''}`,
                    })}
                  </Text>
                  <Text>
                    {t('smart.movemerge.multipleMove.lastItemTime', {
                      value: lastItem?.time,
                    })}
                  </Text>
                </Space>
              </SmartRow>
              <SmartRow>
                <Space>
                  <Text>
                    {t('smart.movemerge.multipleMove.lastItemSkuCount', {
                      value: `${lastItem?.totalSku || ''}`,
                    })}
                  </Text>
                  <Text>
                    {t('smart.movemerge.multipleMove.lastItemTotal', {
                      value: `${lastItem?.totalQty || ''}`,
                    })}
                  </Text>
                </Space>
              </SmartRow>
            </>
          )}

          {skuList.length > 0 && (
            <Button
              type="primary"
              onClick={() => {
                Modal.confirm({
                  title: t('smart.movemerge.multipleMove.confirmDone'),
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <p>{t('smart.movemerge.multipleMove.isConfirmDone')}</p>
                      <Space size="small" direction="vertical">
                        <span>
                          {t('smart.movemerge.multipleMove.lastItemFrom', {
                            value: sLocation?.locationCode || '',
                          })}
                        </span>
                        <span>
                          {t('smart.movemerge.multipleMove.lastItemTo', {
                            value: tLocation?.locationCode || '',
                          })}
                        </span>
                        <span>
                          {t('smart.movemerge.multipleMove.lastItemSkuCount', {
                            value: `${lastItem?.totalSku || ''}`,
                          })}
                        </span>
                        <span>
                          {t('smart.movemerge.multipleMove.lastItemTotal', {
                            value: `${lastItem?.totalQty || ''}`,
                          })}
                        </span>
                      </Space>
                    </>
                  ),
                  okText: t('common.yes'),
                  cancelText: t('common.no'),
                  onOk() {
                    moveProduct();
                  },
                  onCancel() {},
                });
              }}
            >
              {t('smart.movemerge.multipleMove.done')}
            </Button>
          )}

          {skuList.length > 0 && (
            <Table
              size="small"
              rowKey="fulfillmentProductNum"
              bordered
              columns={columns}
              dataSource={skuList ? skuList : []}
              pagination={false}
              style={{ marginTop: 10, marginBottom: 10 }}
            />
          )}

          <SmartBackButton
            onClick={() => {
              setStep('targetLocation');
            }}
          />
        </>
      )}

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
