import { Button, Dropdown, List, Tag, Tabs,Card } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import themes from 'assets/styles/theme';
import { LS_THEME_CODE, REGULAR_SECTION_ROW_VERTICAL_SPACING } from 'constants/config';

const theme = localStorage.getItem(LS_THEME_CODE) || '';

export const BadgeBox = styled.span`
  color: #fff;
  font-size: 0.6em;
  font-weight: 500;
  background-color: #635e5e;
  border-radius: ${themes['@border-radius-base']};
  padding: 2px;
`;

export const BlockButtonLeftSpace = styled.span`
  display: inherit;
  padding-left: 2px;
`;

export const ButtonBox = styled.div`
  & .ant-btn {
    margin: 4px;
  }
`;

export const ButtonIcon = styled.i`
  margin-right: 8px;
`;

export const CardWrapper = styled.div`
  padding: 10px;
`;

export const CompactButton = styled(Button)`
  &.ant-btn {
    padding: 4px 2px;
  }
`;

export const DangerButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${themes['@danger-color']};
    border-color: #bd2130;
  }

  &.ant-btn:disabled {
    background-color: inherit !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const DangerDropdownButton = styled(Dropdown.Button)`
  &.ant-dropdown-button > .ant-btn:first-child {
    color: #fff;
    background-color: ${themes['@danger-color']};
    border-color: #bd2130;
  }

  &.ant-dropdown-button > .ant-btn:nth-child(2):hover {
    border-color: ${themes['@danger-color']};
    color: ${themes['@danger-color']};
  }

  &.ant-dropdown-button > .ant-btn:disabled {
    background-color: inherit !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-dropdown-button .anticon {
    font-size: 1.2em;
  }

  & .ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const DangerIcon = styled(ExclamationCircleOutlined)`
  color: ${themes['@danger-color']} !important;
`;

export const WarningIcon = styled(ExclamationCircleOutlined)`
  color: ${themes['@warning-color']} !important;
`;

export const DetailBox = styled(Tag)`
  &:hover {
    background-color: #55acee;
    color: #fff;
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.span`
  color: #f00;
`;

export const GreyCardWrapper = theme === 'new' ?
  styled.div`
    background-color: ${(props) => props.color || '#FFF'};
    padding: 20px;
    background: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 1px rgba(51, 95, 226, 0.2);
  ` :
  styled.div`
    padding: 8px;
    background-color: ${(props) => props.color || '#FFF'};
    border-radius: ${themes['@border-radius-base']};
  `

  export const ClassicCard = styled(Card)`
    padding: 24px;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: unset;
  `

export const GridIconBox = styled.span`
  margin-left: 10px;
  color: ${(props) => props.color || '#000'};

  &:hover {
    color: ${(props) => props.color || '#00F'};
    cursor: pointer;
  }
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.fontSize || '1em'};
    
  & .clickable {
    cursor: pointer;
  }

  &.label-primary {
    color: ${themes['@primary-color']};
  }

  & span {
    font-size: ${(props) => props.theme.fontSize || '1em'};
  }

  &.label-bold,
  & .label-bold {
    font-weight: 600;
  }

  &.label-danger,
  & .label-danger {
    color: ${themes['@danger-color']};
  }

  &.label-grey,
  & .label-grey {
    color: #666;
  }

  & .label-primary {
    color: ${themes['@primary-color']};
  }
`;

export const HeaderTitleBar = styled.div`
  background-color: #fff;
  padding: 6px 10px;
  border-radius: ${themes['@border-radius-base']};

  & h1,
  .ant-breadcrumb {
    font-family: Lato, sans-serif;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 0;
  }

  & h1 {
    color: #2d3f5d;
  }

  & .act-box {
    color: #131313;
  }

  & .act-box:hover {
    color: #49a8ff;
    cursor: pointer;
  }

  & .anticon-arrow-left {
    font-size: 24px;
    padding: 0 20px 0 0;
  }
`;

export const InfoButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${themes['@info-color']};
    border-color: #117a8b;
  }

  &.ant-btn:disabled {
    background-color: inherit !important;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, .25);
  }

  &.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
  }
`;

export const LinkText = styled.span`
  cursor: pointer;
`;

export const MainList = styled(List)`
  & .ant-list-item {
    padding: 6px;
  }

  & .ant-list-item:nth-child(odd) {
    background-color: #ececec;
  }
`;

export const MainTabs = styled(Tabs)`
  &.ant-tabs > .ant-tabs-nav {
    margin-bottom: 0;
  }
`;

export const ModalDialogBodyCtn = styled.div`
  height: 100%;
  position: relative;
`;

export const ModalTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

export const SuccessButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${themes['@success-color']};
    border-color: #1e7e34;
  }
`;

export const SecondaryButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${themes['@secondary-color']};
    border-color: #545b62;
  }
`;

export const SiteContent = styled.div`
  min-height: calc(100vh - 75px);

  &.common-hcontent {
    height: calc(100vh - 75px);
  }

  & .common-hcontent-box {
    height: calc(100vh - 75px);
  }

  & .font-btn-icon {
    margin-left: 4px;
  }
`;

export const TextPaddingBox = styled.div`
  padding: ${(props) => props.padding || '8px'};
`;

export const WarningButton = styled(Button)`
  &.ant-btn {
    color: #fff;
    background-color: ${themes['@warning-color']};
    border-color: #d9d9d9;
  }

  &.ant-btn[disabled], &.ant-btn[disabled]:hover, &.ant-btn[disabled]:focus, &.ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
`;

export const deleteIconStyle = {
  color: '#F00',
};

export const searchBoxInputErrorStyle = {
  border: '1px solid #F00',
  borderRight: 'none',
  borderRadius: 4,
};

export const loadingSpinStyle = {
  fontSize: 24,
  color: themes['@primary-color'],
};

export const messageStyle = {
  textAlign: 'right',
  marginRight: 8,
};

export const verticalSpace = {
  marginTop: REGULAR_SECTION_ROW_VERTICAL_SPACING,
};

export const OpenInNewWindowIcon = styled.i`
  display: inline-block;
  height: 14px;
  width: 14px;
  position: relative;
  margin-right: 8px;

  &:before {
    content: "";	
    position: absolute;
    top: 3px;
    z-index: 2;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #626262;
    border-radius: 2.5px;
    height: 10px;
    width: 10px;
  }

  &:after {
    content: "";	
    position: absolute;
    bottom: -2px;
    left: 5px;
    z-index: 1;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #626262;
    border-radius: 2.5px;
    height: 10px;
    width: 10px;
  }

`;
