import React from 'react';
import { Link } from 'react-router-dom';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';


export default function Prepack(): JSX.Element {
  const { t } = useTranslation()
  return (
    <SmartBlock>
      <SmartSpace>
        <Link to="/smart/pickForPack">
          <SmartButton>
            {t('smart.prepack.pickForPack.title')}
          </SmartButton>
        </Link>
        <Link to="/smart/unpack">
          <SmartButton>
            {t('smart.prepack.unpack.title')}
          </SmartButton>
        </Link>
        <Link to="/smart/checkProgress">
          <SmartButton>
            {t('smart.prepack.checkProgress.title')}
          </SmartButton>
        </Link>
      </SmartSpace>
    </SmartBlock>
  );
}
