import React from 'react';
import {
  Form,
  Button,
  Space,
  Modal,
  Spin,
  Select,
  Row,
  Typography,
} from 'antd';
import { SaveOutlined, EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { FormLabel } from 'components/common';
import { message } from 'components/common';
import { getAddressList } from 'services/address';
import { editWarehouse, fetchWarehouseEntity } from 'services/warehouse';
import CreateAddressDialog from 'components/Admin/AddressBook/createDialog';

const { Text } = Typography;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type WarehouseAddressFormProps = {
  warehouse: WarehouseRow;
  onHide: (forceRefresh: boolean, warehouse: WarehouseRow) => void;
};

export default function WarehouseAddressDialog(
  props: WarehouseAddressFormProps
): JSX.Element {
  const { onHide, warehouse } = props;
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [addressList, setAddressList] = React.useState<AddressRow[]>([]);
  const [address, setAddress] = React.useState<AddressRow>();
  const [warehouseEntity, setWarehouseEntity] = React.useState<WarehouseRow>();
  const [editAble, setEditAble] = React.useState<boolean>(false);
  const [createlVisible, setCreatelVisible] = React.useState(false);
  const [addressAct, setAddressAct] = React.useState<'add' | 'edit'>('add');

  const [form] = Form.useForm();

  const fetchAddressList = async () => {
    try {
      setLoading(true);
      const res = await getAddressList(0);
      setLoading(false);
      if (res.isSuccess) {
        setAddressList(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchWarehouseAddressNum = async () => {
    try {
      setLoading1(true);
      const res = await fetchWarehouseEntity(warehouse.warehouseId);
      setLoading1(false);
      setWarehouseEntity(res);
    } catch (error) {
      setLoading1(false);
    }
  };

  /**
   * handle cancel event
   */
  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide(false, warehouse);
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide(false, warehouse);
        },
      });
    }
  };
  /**
   * handle submit event
   */
  const handleSubmit = async () => {
    if (!editAble) {
      setEditAble(true);
      return;
    }
    const params = await form.validateFields();
    if (params.errorFields) return;
    try {
      setSubmitLoading(true);
      const res = await editWarehouse(warehouse.warehouseId, params);
      if (res) {
        message.success({ content: 'Saved successfully' });
        onHide(true, warehouse);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* eslint-disable */
  React.useEffect(() => {
    fetchWarehouseAddressNum();
    fetchAddressList();
  }, []);
  React.useEffect(() => {
    if (addressList.length > 0 && warehouseEntity) {
      const temp = addressList.filter(
        (item: AddressRow) => item.addressNum === warehouseEntity.addressNum
      );
      if (temp.length > 0) {
        setAddress(temp[0]);
      }
    }
  }, [addressList, warehouseEntity]);
  /* eslint-enable */
  return (
    <Modal
      title={`Set Warehouse Address - ${warehouse.code}`}
      centered
      visible
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button
              onClick={() => {
                setAddressAct('add');
                setCreatelVisible(true);
              }}
            >
              <PlusOutlined />
              New Address
            </Button>
            <Button
              disabled={!address}
              onClick={() => {
                setAddressAct('edit');
                setCreatelVisible(true);
              }}
            >
              <EditOutlined />
              Update Address
            </Button>
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {!editAble ? <EditOutlined /> : <SaveOutlined />}
              {!editAble ? 'Edit' : submitLoading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading || loading1}>
        <Form
          /* eslint-disable react/jsx-props-no-spreading */
          {...layout}
          form={form}
          className="compact-form"
          onFinish={handleSubmit}
        >
          {warehouseEntity && (
            <Form.Item
              name="addressNum"
              initialValue={warehouseEntity?.addressNum}
              label={<FormLabel>Warehouse Address</FormLabel>}
              rules={[{ required: true, message: 'Address is required' }]}
            // extra={
            //   address
            //     ? `${address?.contactName}, ${address?.street1} ${address?.street2} ${address?.street3}, ${address?.city}, ${address?.state} ${address?.postalCode}, ${address?.country}`
            //     : ''
            // }
            >
              <Select
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                disabled={!editAble}
                allowClear
                onChange={(num) => {
                  setAddress(
                    addressList.filter(
                      (item) => item.addressNum === Number(num)
                    )[0]
                  );
                }}
              >
                {addressList.map((item) => (
                  <Select.Option
                    value={item.addressNum}
                    key={item.addressNum}
                    label={item.addressAlias}
                  >
                    <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                      } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                      } ${item.city ? item.city + ',' : ''} ${item.state || ''} ${item.postalCode ? item.postalCode + ',' : ','
                      } ${item.country || ''}`}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {address && (
            <Form.Item label={<FormLabel noColon> </FormLabel>} name="">
              <Space direction="vertical">
                <Text>{address.contactName || ''}</Text>
                {address.street1 && <Text>{address.street1}</Text>}
                {address.street2 && <Text>{address.street2}</Text>}
                {address.street3 && <Text>{address.street3}</Text>}
                <Text>{`${address.city || ''}, ${address.state || ''} ${address.postalCode || ''
                  }`}</Text>
                <Text>{address.country || ''}</Text>
              </Space>
            </Form.Item>
          )}
        </Form>
      </Spin>
      {createlVisible && (
        <CreateAddressDialog
          visible
          onHide={() => setCreatelVisible(false)}
          onRefresh={() => fetchAddressList()}
          address={addressAct === 'add' ? undefined : address}
        />
      )}
    </Modal>
  );
}
