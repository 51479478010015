import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Content from 'components/Sales/Smart/Pick/Order/PickList2';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseCodeFromCache } from 'utils';

export default function SmartOrderPickListScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.orderPicklist" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Pick By Order(Pick List)(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <Content />
    </ContentLayout>
  );
}
