import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Row,
  Form,
  Col,
  Space,
  DatePicker,
  Input,
  Select,
  Menu,
  Dropdown,
  message,
  Modal,
  Tooltip
} from 'antd';
import {
  SettingFilled,
  CaretDownOutlined,
  FileExcelOutlined,
  SearchOutlined,
  CopyOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  ClearButton,
  ColumnDataType,
  DataGridPlus,
  ExportCSV,
  FormLabel,
  HeaderTitle,
  GreyCardWrapper,
  CopySpan,
  SelectWarehouse,
  SpaceCell,
} from 'components/common';
import { DATE_TIME_NO_SECOND_FORMAT, DATAGRID_CELL_ICON_WIDTH1 } from 'constants/config';
import { trimValues, convertToUtcTime } from 'utils';
import ProcessDialog from './ProcessDialog';
import ViewDialog from './ViewDialog';
import { cancelPack, completePack } from 'services/prepack';
import { queryPackList } from 'services/prepack';
import { ClassicCard } from 'components/common/styles';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import Text from 'antd/lib/typography/Text';
import { packStatusKeyValue as statusEnum } from 'constants/enums'

const { RangePicker } = DatePicker;

const SYNC_STATUS_MAP: { [key: string]: string } = {
  '0': 'Not sync',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Wait for response'
}

export default function LocationReport(): JSX.Element {
  const [initing, setIniting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Pack[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [tempParams, setTempParams] = useState<any>();
  const [keyWordType, setKeyWordType] = useState<any>('1');
  const limitRef = useRef(10);
  const skipRef = useRef(0);
  const sortString = React.useRef<string>('FulfillmentPackNum desc');
  const [processDialogVisible, setProcessDialogVisible] = useState(false);
  const [viewDialogVisible, setViewDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();

  const [form] = Form.useForm();

  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number, sort: string) => {
      if (!tempParams) return;
      const queryparams = {
        $top: limit ? limit : 10,
        $skip: skip ? skip : 0,
        $sortBy: sort || 'FulfillmentPackNum desc',
        ...tempParams,
      };
      try {
        setLoading(true);
        //todo request
        const res = await queryPackList(queryparams);
        setLoading(false);
        if (res) {
          setData(res.data);
          setTotal(res.count);
          skipRef.current = skip || 0;
          limitRef.current = limit || 10;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [tempParams]
  );

  const handleSearch = useCallback(
    async (limit?: number, skip?: number, sortString?: string) => {
      const params = trimValues(await form.validateFields());
      const queryparams = {
        warehouseNum: params.warehouse ? (params.warehouse.warehouseNum || undefined) : undefined,
        startDate: params.startDate
          ? `${convertToUtcTime(
            params.startDate[0].format('YYYY-MM-DD HH:mm')
          )}@${convertToUtcTime(
            params.startDate[1].format('YYYY-MM-DD HH:mm')
          )}`
          : undefined,
        endDate: params.endDate
          ? `${convertToUtcTime(
            params.endDate[0].format('YYYY-MM-DD HH:mm')
          )}@${convertToUtcTime(
            params.endDate[1].format('YYYY-MM-DD HH:mm')
          )}`
          : undefined,
        prepackSKU:
          keyWordType === '1' || keyWordType === '2'
            ? params.keyword
              ? params.keyword
              : undefined
            : undefined,
        childSKU:
          keyWordType === '3' || keyWordType === '4'
            ? params.keyword
              ? params.keyword
              : undefined
            : undefined,
        oper: params.keyword
          ? keyWordType === '1' || keyWordType === '3'
            ? 0
            : 1
          : undefined,
        status: params.status || params.status === 0 ? params.status : undefined,
      };
      try {
        setLoading(true);
        //todo request
        const res = await queryPackList({
          ...queryparams,
          $top: limit ? limit : 10,
          $skip: skip ? skip : 0,
          $sortBy: sortString || 'FulfillmentPackNum desc',
        });
        setIniting(false);
        setLoading(false);
        if (res) {
          setData(res.data)
          setTotal(res.count)
          skipRef.current = skip || 0;
          limitRef.current = limit || 10;
          setTempParams({
            ...queryparams,
          });
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [form, keyWordType]
  );

  const handleCancel = async (id: number) => {
    try {
      const res = await cancelPack(id)
      if (res) {
        message.success('cancel successfully')
        handleSearch()
      }
    } catch (error) {

    }
  }

  const handleComplete = async (id: number) => {
    try {
      const res = await completePack(id)
      if (res) {
        message.success('Complete successfully')
        handleSearch()
      }
    } catch (error) {

    }
  }

  const columns = [
    {
      header: 'ERP Request#',
      name: 'packCode',
      userSelect: true,
      sortable: true,
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;

        return (
          <SpaceCell
            id={`catalog_grid_product_sku_row_${rowIndex}`}
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.packCode);
                    message.info(`"${data.packCode}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => {
              setCurrent(data);
              setViewDialogVisible(true);
            }}
            text={data.packCode}
            textIsButton
          />
        );
      },
    },
    {
      header: 'Description',
      name: 'description',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      minWidth: 160,
      defaultFlex: 1,
    },
    {
      header: 'Due Date',
      name: 'enterDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Status',
      name: 'status',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: statusEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={statusEnum[`${data.status}`]} />;
      },
    },
    {
      header: 'Request Qty',
      name: 'requestQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Packed Qty',
      name: 'packQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Sync Status',
      name: 'syncStatus',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      renderEnum: SYNC_STATUS_MAP,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={SYNC_STATUS_MAP[`${data.syncStatus}`]} />;
      },
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      minWidth: 220,
      maxWidth: 220,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Space>
            {(data.status !== 2 && data.status !== 3) && (
              <Button
                style={{ width: 80 }}
                onClick={() => {
                  setCurrent(data);
                  setProcessDialogVisible(true);
                }}
              >
                Process
              </Button>
            )}
            {(data.status === 2 || data.status === 3) && (
              <Button
                style={{ width: 80 }}
                onClick={() => {
                  setCurrent(data);
                  setViewDialogVisible(true);
                }}
              >
                View
              </Button>
            )}
            {(data.status === 0 || data.status === 1) && <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Button
                      id="grid_detail_button"
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setViewDialogVisible(true);
                      }}
                    >
                      View
                    </Button>
                  </Menu.Item>
                  {data.status === 1 && <Menu.Item key="2">
                    <Button
                      id="grid_complete_button"
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: data.requestQty > data.packQty ? 'Not quite finished yet. Are you sure you want to complete the pack ?' :
                          'Are you sure you want to complete the pack ?',
                          content: <Space direction="vertical">
                          <Text>{`ERP Request#: ${data.packCode}`}</Text>
                          <Text>{`Request Qty: ${data.requestQty}`}</Text>
                          <Text>{`Packed Qty: ${data.packQty}`}</Text>
                        </Space>,
                          okText: 'Yes',
                          okType: 'default',
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onOk() {
                            handleComplete(data.fulfillmentPackNum);
                          },
                          onCancel() { },
                        });
                      }}
                    >
                      Complete
                    </Button>
                  </Menu.Item>}

                  {data.status === 0 && <Menu.Item key="3">
                    <Button
                      id="grid_cancel_button"
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title:
                            'Are you sure you want to cancel the pack ?',
                          content: `ERP Request#: ${data.packCode}`,
                          okText: 'Yes',
                          okType: 'default',
                          okButtonProps: {
                            className: 'hover-danger-button',
                          },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onOk() {
                            handleCancel(data.fulfillmentPackNum);
                          },
                          onCancel() { },
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Menu.Item>}
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                More
                <CaretDownOutlined />
              </Button>
            </Dropdown>}
          </Space>
        );
      },
    },
  ];

  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };
  return (
    <>
      <HeaderTitle breadcrumb={['Pre-Pack', 'Pack', 'List']} />
      <GreyCardWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form
          form={form}
          {...formItemLayout}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <ClassicCard style={{ width: '100%' }} bodyStyle={{ width: '100%' }}>
            <Row>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="warehouse"
                  label={<FormLabel>Warehouse</FormLabel>}
                >
                  <SelectWarehouse showAll />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 9 },
                    style: {
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                    },
                  }}
                  name="keyword"
                  label={
                    <>
                      <Select
                        onChange={(value) => {
                          setKeyWordType(value);
                          form.setFieldsValue({
                            keyWord: '',
                          });
                        }}
                        id="form_select_label_keyword"
                        style={{ padding: 0, fontWeight: 900 }}
                        size="small"
                        bordered={false}
                        dropdownMatchSelectWidth={false}
                        value={keyWordType}
                        defaultValue="1"
                      >
                        <Select.Option value="1">
                          Pre-Pack SKU/UPC Equals
                        </Select.Option>
                        <Select.Option value="2">
                          Pre-Pack SKU Starts
                        </Select.Option>
                        <Select.Option value="3">
                          Child SKU/UPC Equals
                        </Select.Option>
                        <Select.Option value="4">
                          Child SKU Starts
                        </Select.Option>
                      </Select>
                    </>
                  }
                >
                  <Input id="form_upc_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="startDate"
                  label={<FormLabel>Start Date</FormLabel>}
                >
                  <RangePicker
                    id="form_crete_date_range_picker"
                    style={{ width: '100%' }}
                    allowClear
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="endDate"
                  label={<FormLabel>End Date</FormLabel>}
                >
                  <RangePicker
                    id="form_crete_date_range_picker"
                    style={{ width: '100%' }}
                    allowClear
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item name="status" label={<FormLabel>Status</FormLabel>}>
                  <Select dropdownMatchSelectWidth={false} allowClear>
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>Processing</Select.Option>
                    <Select.Option value={2}>Completed</Select.Option>
                    <Select.Option value={3}>Canceled</Select.Option>
                    {/* <Select.Option value={4}>Packed</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Space>
                <Button
                  id="form_search_button"
                  type="primary"
                  onClick={() => {
                    handleSearch();
                  }}
                  loading={loading}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
                <ExportCSV
                  id="form_export_button"
                  data={data || []}
                  columns={columns.filter((item) => item.header !== 'Action')}
                  scope="pack"
                  formatColumns={{ status: statusEnum, syncStatus: SYNC_STATUS_MAP }}
                />
                <ClearButton id="form_clean_button" onClick={handleReset} />
              </Space>
            </Row>
          </ClassicCard>
        </Form>
      </GreyCardWrapper>

      {!initing && data && (
        <GreyCardWrapper
          style={{
            marginTop: 20,
          }}
        >
          <DataGridPlus
            skip={skipRef.current}
            limit={limitRef.current}
            name="UnpackReport"
            autoWith={false}
            showScrollButton
            count={total}
            onSortInfoChange={(v: any, d: any) => {
              if (v) {
                if (v.dir === 1) {
                  sortString.current = `${v.id} asc`;
                  handleDataGridChange(
                    limitRef.current,
                    skipRef.current,
                    sortString.current
                  );
                }
                if (v.dir === -1) {
                  sortString.current = `${v.id} desc`;
                  handleDataGridChange(
                    limitRef.current,
                    skipRef.current,
                    sortString.current
                  );
                }
              } else {
                sortString.current = 'FulfillmentPackNum desc';
                handleDataGridChange(
                  limitRef.current,
                  skipRef.current,
                  sortString.current
                );
              }
            }}
            dataSource={data}
            onChange={(limit, skip) => {
              limitRef.current = limit;
              skipRef.current = skip;
              handleDataGridChange(limit, skip, sortString.current);
            }}
            pageSizes={[10, 20, 50, 100]}
            loading={loading}
            columns={columns}
            idProperty="fulfillmentPackNum"
            pagination="remote"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </GreyCardWrapper>
      )}
      {processDialogVisible && current && (
        <ProcessDialog
          visible
          onClose={() => {
            setProcessDialogVisible(false);
          }}
          pack={current}
        />
      )}
      {viewDialogVisible && current && (
        <ViewDialog
          onProcess={() => {
            setViewDialogVisible(false)
            setProcessDialogVisible(true)
          }}
          onClose={() => {
            setViewDialogVisible(false);
          }}
          visible
          pack={current}
        />
      )}
    </>
  );
}
