import React from 'react';
import SimpleSelect from './SimpleSelect';
import { SelectProps } from 'antd';

interface SelectOrderStatusProps extends SelectProps {
  id?: string;
  showAll: boolean;
  value?: string;
  onChange?: (value: string | number) => void;
};
/**
 * Returns select Order Fulfillment Status Component
 * Support Form.Item
 * @param {SelectOrderStatusProps} props
 * @returns {JSX.Element}
 */
export default function SelectOrderStatus(
  props: SelectOrderStatusProps
): JSX.Element {
  const { value, showAll, onChange, id } = props;
  const options = [
    {
      id: 0,
      label: 'Pending',
    },
    {
      id: 1,
      label: 'Shipped',
    },
    {
      id:250,
      label: 'Processing',
    },
    {
      id: 2,
      label: 'Partially Shipped',
    },
    {
      id: 8,
      label: 'Held',
    },
    // {
    //   id: 9,
    //   label: 'Printing',
    // },
    {
      id: 16,
      label: 'Cancelled',
    },
    {
      id: 32,
      label: 'Abandoned',
    },
  ];
  if (showAll) {
    options.unshift({
      id: -1,
      label: 'All',
    });
  }
  return (
    <SimpleSelect
      {...props}
      id={id}
      placeholder="Order Fulfillment Status"
      value={value}
      onChange={onChange}
      data={options}
    />
  );
}
SelectOrderStatus.defaultProps = {
  value: undefined,
  onChange: undefined,
};
