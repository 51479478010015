import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Space, Result } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { HeaderTitle, TablesFilter } from 'components/common';
// import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
// import {FormLabel} from 'components/common';
import { DataGridPlus } from 'components/common';
// import { getSystemSettingList } from 'services/systemSetting';
import {
  getShippingProviderAccounts,
  delShippingProviderAccount,
  getShippingProviderAccountsConfig,
} from 'services/shippingProviderAccount';
// import { fetchCarriers } from 'services/shipment';
// import { fetchWarehouseList } from 'services/warehouse';
// import AccountDetailDialog from './AccountDetailDialog';
import { message } from 'components/common';
import { DeleteButton } from 'components/common';
import { HoverBgButton } from 'components/common';
import CreateEditDialog from './CreateEditDialog';

// const { Title } = Typography;

const ShippingProviderAccount = () => {
  // const [warehouseId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [createAccountVisible, setCreateAccountVisible] = useState(false);
  const [configInfo, setConfigInfo] = useState<any>();
  const [providers, setProvides] = useState<StringKAnyVPair>({});
  const [accounts, setAccounts] = useState<any[]>([]);
  const [current, setCurrent] = useState<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const getConfigInfo = useCallback(async () => {
    try {
      setConfigLoading(true);
      const res = await getShippingProviderAccountsConfig();
      if (res.isSuccess) {
        setConfigInfo(res.data);
        if (res.data.providers && Array.isArray(res.data.providers)) {
          const obj: StringKAnyVPair = {};
          res.data.providers.forEach((item: { key: number; value: string }) => {
            obj[item.key] = item.value;
          });
          console.log('obj', obj)
          setProvides(obj);
        }
        setConfigLoading(false);
      }
    } catch (error) {
      setConfigLoading(true);
    }
  }, []);

  const getAccountList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getShippingProviderAccounts();
      setLoading(false);
      if (res.isSuccess) {
        setAccounts(res.data);
      } else {
        message.error({ content: res.message });
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const delAccount = useCallback(
    async (account) => {
      try {
        const res = await delShippingProviderAccount(
          account.shippingProviderAccountNum
        );
        if (res) {
          message.success({ content: `Deleted ${account.name} Successfully` });
          getAccountList();
        } else {
          // message.error({ content: 'Delete failed' });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [getAccountList]
  );

  //   const a = {
  //     shippingProviderAccountNum: 2,
  //     shipProviderType: 1,
  //     name: 'EasyPostTest',
  //     account: 'easypost@vibesbase.com',
  //     appKey: 'EZAKbb457d2a524c46d2af290b16eaea6b57Dxksm2cu3mVnwMwTepBO9g',
  //     attr01: null,
  //     attr02: null,
  //     databaseNum: 200,
  //     masterAccountNum: 10001,
  //     profileNum: 10001,
  //     createBy: null,
  //     createDate: '2023-03-31T09:01:31.5',
  //     updateBy: null,
  //     updateDate: '2023-03-31T09:01:31.5',
  //   };

  const getColumn = useCallback(() => {
    return [
      {
        header: 'Name',
        name: 'name',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
      },
      {
        header: 'Type',
        name: 'shipProviderType',
        resizable: true,
        draggable: true,
        userSelect: true,
        sortable: true,
        defaultFlex: 1,
        renderEnum: providers,
        render: (value: any) => {
          const { data } = value;
          return providers[data.shipProviderType] || '';
        },
      },
      // {
      //   header: 'Account',
      //   name: 'account',
      //   defaultFlex: 1,
      //   resizable: true,
      //   draggable: true,
      //   userSelect: true,
      //   sortable: true,
      // },
      // {
      //   header: 'App Key',
      //   name: 'appKey',
      //   resizable: true,
      //   draggable: true,
      //   userSelect: true,
      //   sortable: true,
      //   defaultFlex: 1,
      // },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        maxWidth: 300,
        minWidth: 300,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Space>
              <HoverBgButton
                hovertype="info"
                id={`shipping_account_grid_action_row_${rowIndex}_edit_button`}
                onClick={() => {
                  setCurrent(data)
                  setCreateAccountVisible(true)
                }}
              >
                <EditOutlined />
                Edit
              </HoverBgButton>
              <DeleteButton
                id={`shipping_account_grid_action_row_${rowIndex}_delete_button`}
                confirmContent={`name: ${data.name}`}
                confirmTitle="Are you sure you want to delete the account?"
                onClick={() => delAccount(data)}
              />
            </Space>
          );
        },
      },
    ];
  }, [providers, delAccount])

  //   useEffect(() => {
  //     getCarriers();
  //   }, []);

  useEffect(() => {
    if (configInfo && configInfo.enableShippingProvider) {
      getAccountList();
    }
  }, [getAccountList, configInfo]);

  useEffect(() => {
    getConfigInfo();
  }, [getConfigInfo]);

  return (
    <>
      <HeaderTitle
        breadcrumb={['Administration', 'Shipping', 'Shipping Provider']}
      >
        {configInfo && configInfo.enableShippingProvider && <Button
          id="shipping_account_add_button"
          type="primary"
          onClick={() => {
            setCurrent(undefined)
            setCreateAccountVisible(true)
          }}
        >
          <PlusOutlined />
          Add Shipping Provider
        </Button>}
      </HeaderTitle>
      <Card loading={configLoading}>
        <TablesFilter
          columns={getColumn()}
          dataSource={accounts}
          setFilteredData={setFilteredData}
        />
        {
          configInfo && configInfo.enableShippingProvider ? <DataGridPlus
            name="shippingProviderAccount"
            style={{ minHeight: 500 }}
            idProperty="shippingProviderAccountNum"
            columns={getColumn()}
            dataSource={filteredData}
            loading={loading}
            pagination="local"
            {...({} as any)}
          />
            : <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
            />
        }

      </Card>

      {createAccountVisible &&
        <CreateEditDialog
          visible
          onHide={() => setCreateAccountVisible(false)}
          onRefresh={() => getAccountList()}
          configInfo={configInfo}
          account={current}
        />
      }
    </>
  );
};

export default ShippingProviderAccount;
