import React, { useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { getCommonDialogHeight } from 'utils';
import Detail from '../PoDetail/index';

const DIALOG_BODY_MAX_HEIGHT = 750;

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  poInfo: any;
}

const ShipmentDetailDialog = (props: Props) => {
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);

  const bodyStyle = {
    height: getCommonDialogHeight(DIALOG_BODY_MAX_HEIGHT) - 140,
  };

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`PO Detail - ${props.poInfo.poNumber}`}
        visible={props.visible}
        width={detailDialogWidth()}
        bodyStyle={bodyStyle}
        dialogSize={'large'}
      >
        <Detail isModal poInfo={props.poInfo} />
      </ModalDialog>
    </>
  );
};

export default ShipmentDetailDialog;
