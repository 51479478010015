import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Tooltip } from 'antd';
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import { RootState } from 'reducers';
import { PAGE_MODE_FULL_PAGE, PAGE_MODE_NORMAL } from 'constants/config';
import { setPageShowMode } from 'actions/regularActions';
import { useTranslation } from 'react-i18next';
//import { ButtonIcon } from './styles';

// eslint-disable-next-line
export default () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { t: trans } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode,
    shallowEqual
  );
  const fullPageTootip = React.useMemo(() => {
    return PAGE_MODE_FULL_PAGE === pageShowMode
      ? trans('common.compactMode')
      : trans('common.expandMode');
      // eslint-disable-next-line
  }, [pageShowMode]);
  // eslint-disable-next-line
  const iconCls = React.useMemo(() => {
    return PAGE_MODE_FULL_PAGE === pageShowMode
      ? 'bi-fullscreen-exit'
      : 'bi-fullscreen';
  }, [pageShowMode]);
  const toggleIcon = React.useMemo(() => {
    return PAGE_MODE_FULL_PAGE === pageShowMode ? (
      <CompressOutlined />
    ) : (
      <ExpandOutlined />
    );
  }, [pageShowMode]);

  const toggleFullPage = () => {
    const mode =
      PAGE_MODE_FULL_PAGE === pageShowMode
        ? PAGE_MODE_NORMAL
        : PAGE_MODE_FULL_PAGE;

    setTooltipVisible(false);
    dispatch(setPageShowMode(mode));
  };

  return (
    <Tooltip
      placement="bottomRight"
      title={fullPageTootip}
      visible={tooltipVisible}
    >
      <Button
        icon={toggleIcon}
        onClick={toggleFullPage}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
      />
    </Tooltip>
  );
};
