import React, { useEffect } from 'react';
import { Button, Modal, Row, Space } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import {
  CloseOutlined,
  //FullscreenExitOutlined,
  //FullscreenOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
//import { PrinterOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ExternalLink from 'assets/icons/external-link';
import { POST_MSG_SET_ENV } from 'constants/config';
import { tokenKeeper } from 'services/http';
//import { flexDialogWidth } from 'utils';
import { userProfiles } from 'utils/auth';
import { OpenInNewWindowIcon } from './styles';

const ModalDialogBodyCtn = styled.div`
  height: 100%;
  position: relative;
  overflow-y: auto;
`;

const ModalTitle = styled.h3`
  font-weight: bold;
  margin-bottom: 0;
`;

interface Props extends ModalProps {
  allowPopupWindow?: boolean;
  bodyHeight?: number;
  children?: React.ReactNode;
  closeButton?: boolean;
  footerHeight?: number;
  fullscreen?: boolean;
  popupWindowUrl?: string;
  onClose: Function;
  onFullscreen?: Function;
  onPopupWindow?: Function;
  titleRightCrumb?: React.ReactNode;
  printButton?: boolean;
  onPrint?: Function;
  dialogSize?: 'small' | 'medium' | 'large';
  modalHeight?: Function;
}

const SIZE_MAP = {
  'small': 0.6,
  'medium': 0.8,
  'large': 1
}

const ModalDialog = (props: Props) => {
  enum Dialog {
    FooterHeight = 54,
    HeaderHeight = 58,
  };
  const { useState } = React;
  // const {width: propsWidth} = props
  const [bodyHeight, setBodyHeight] = useState(props.bodyHeight);
  const [dialogIsFullscreen, setDialogIsFullscreen] = useState(false);
  const [inited, setInited] = useState(false);
  const [width, setWidth] = useState(props.width);

  const closeDialog = () => {
    setDialogIsFullscreen(false);
    setBodyHeight(dialogNonFullscreenHeight());
    setWidth(dialogNonFullscreenWidth());
    props.onClose();
  };

  // eslint-disable-next-line
  const dialogNonFullscreenHeight = () => {
    const { bodyHeight, dialogSize = 'large' } = props;
    const wh = (window.innerHeight - 100 - Dialog.HeaderHeight) * SIZE_MAP[dialogSize];
    let h = bodyHeight || wh;
    return h < wh ? h : wh;
  };

  // eslint-disable-next-line
  const dialogNonFullscreenWidth = () => {
    const dw = window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
    const w = props.width || dw;

    //return props.width ? props.width : dw;
    return w < window.innerWidth - 100 ? w : dw;
    //return flexDialogWidth();
  };

  const handlePrint = () => {
    const { printButton, onPrint } = props;
    if (printButton && typeof onPrint === 'function') onPrint();
  };

  const popupWindow = () => {
    const w = window.innerWidth;
    const h = window.innerHeight + ((window.screen as StringKAnyVPair).availTop || 0);
    //const h = window.screen.height;
    //console.log('open', window.screen.availHeight, window.screen.height);
    const win = window.open(props.popupWindowUrl || '', '_blank', `location=no,toolbar=no,width=${w},height=${h},popup=yes`);

    if (win && typeof props.onPopupWindow === 'function') {
      win.addEventListener('DOMContentLoaded', () => {
        //console.log('dom content loaded ->');
        win.postMessage({
          __action: POST_MSG_SET_ENV,
          data: {
            apiToken: tokenKeeper.apiToken,
            profiles: userProfiles.profiles,
            profileIndex: userProfiles.profileIndex,
          },
        });

        if (typeof props.onPopupWindow === 'function') {
          props.onPopupWindow(win);
        }

        setTimeout(props.onClose, 100);
      }, false);
    }
  };

  const openTabWindow = () => {
    const win = window.open(props.popupWindowUrl || '');

    if (win && typeof props.onPopupWindow === 'function') {
      win.addEventListener('DOMContentLoaded', () => {
        //console.log('dom content loaded ->');
        win.postMessage({
          __action: POST_MSG_SET_ENV,
          data: {
            apiToken: tokenKeeper.apiToken,
            profiles: userProfiles.profiles,
            profileIndex: userProfiles.profileIndex,
          },
        });

        if (typeof props.onPopupWindow === 'function') {
          props.onPopupWindow(win);
        }

        setTimeout(props.onClose, 100);
      }, false);
    }
  };

  const toggleDialogFullscreen = () => {
    setDialogIsFullscreen(!dialogIsFullscreen);

    if (dialogIsFullscreen) {
      //setBodyHeight(props.bodyHeight);
      setBodyHeight(dialogNonFullscreenHeight());
      //setWidth(props.width);
      setWidth(dialogNonFullscreenWidth());
    } else {
      const footerHeight = props.footerHeight || (props.footer ? Dialog.FooterHeight : 0);

      setBodyHeight(window.innerHeight - Dialog.HeaderHeight - footerHeight);
      setWidth(window.innerWidth - 4);
    }

    if (typeof props.onFullscreen === 'function') {
      props.onFullscreen(!dialogIsFullscreen);
    }
  };

  useEffect(() => {
    /*if (dialogIsFullscreen) {
      const footerHeight = props.footerHeight || 0;

      setBodyHeight(window.innerHeight - 66 - footerHeight);
      setWidth(window.innerWidth - 4);
    } else {
      if (props.width) {
        setWidth(props.width);
        //setBodyHeight(props.bodyHeight);
        setBodyHeight(dialogNonFullscreenHeight());
      } else {
        //setBodyHeight(props.bodyHeight);
        setBodyHeight(dialogNonFullscreenHeight());
        setWidth(window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200);
      }
    }*/
    if (!inited) {
      setBodyHeight(dialogNonFullscreenHeight());
      setWidth(dialogNonFullscreenWidth());
      setInited(true);
    }
  }, [
    //dialogIsFullscreen,
    dialogNonFullscreenHeight,
    dialogNonFullscreenWidth,
    inited,
    props,
  ]);

  useEffect(() => {
    if (typeof props.modalHeight === 'function') {
      props.modalHeight(bodyHeight);
    }
    // eslint-disable-next-line
  }, [bodyHeight])

  return (
    <>
      <Modal
        {...props}
        bodyStyle={{
          backgroundColor: '#F5F9FF',
          padding: 12,
          height: bodyHeight,
        }}
        centered
        className="fullscreen-modal"
        onCancel={closeDialog}
        style={{ paddingBottom: 0 }}
        title={
          <Row align="middle" justify="space-between">
            <ModalTitle>{props.title || ''}</ModalTitle>
            <Space>
              {props.titleRightCrumb}
              {props.printButton && (
                <Button onClick={handlePrint}>
                  <PrinterOutlined /> Print
                </Button>
              )}
              {props.allowPopupWindow && (
                <Button onClick={popupWindow}>
                  {/* <ButtonIcon className="bi bi-front" /> */}
                  <OpenInNewWindowIcon />
                  Open in New Window
                </Button>
              )}
              {
                props.popupWindowUrl && (
                  <Button onClick={openTabWindow}>
                    <span className="anticon">
                      <ExternalLink height={14} viewBox="0 0 22 22" width={14} />
                    </span>
                    Open in New Tab
                  </Button>
                )}
              {props.fullscreen && (
                <Button onClick={toggleDialogFullscreen}>
                  {dialogIsFullscreen ? (
                    <i className="bi bi-fullscreen-exit" style={{marginRight: 8}} />
                  ) : (
                    <i className="bi bi-fullscreen" style={{marginRight: 8}} />
                  )}
                  {dialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
                </Button>
              )}
              {props.closeButton && (
                <Button onClick={closeDialog}>
                  <CloseOutlined />
                  Close
                </Button>
              )}
            </Space>
          </Row>
        }
        visible={props.visible}
        width={width}
      >
        <ModalDialogBodyCtn>{props.children}</ModalDialogBodyCtn>
      </Modal>
    </>
  );
};

ModalDialog.defaultProps = {
  dialogSize: 'large'
};

export default ModalDialog;
