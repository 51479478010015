import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space, Form, InputNumber, Input } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import {
  message,
  SelectProduct,
  FormLabel,
} from 'components/common';
import { createPoLine } from 'services/purchaseOrder';

type Props = {
  PoId: string;
  onHide: () => void;
  onRefresh: () => void;
};

const CreateLine = (props: Props) => {
  const { onHide, PoId, onRefresh } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleConfirm = useCallback(async() => {
    try {
      setLoading(true)
      const params = await form.validateFields();
      if (params.errorFields) return;
      const res = await createPoLine(PoId, params)
      setLoading(false)
      if(res) {
        message.success('Add successfully')
        onRefresh()
      }
    } catch (error) {
      setLoading(false)
    }
  }, [onRefresh, PoId, form]);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title="Add Line Item"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              loading={loading}
              onClick={() => handleConfirm()}
              icon={<SaveOutlined />}
            >
              {loading ? 'Adding' : 'Add'}
            </Button>
            <Button onClick={() => handleHide()} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form}>
        <Form.Item name="SKU" label={<FormLabel>SKU</FormLabel>} rules={[{ required: true, message: 'SKU is required' }]}>
          <SelectProduct
            width={357}
            onBlurAble
            onChange={(sku, product) => {
              form.setFieldsValue({ SKU: product.sku, Title: product.productTitle });
            }}
          />
        </Form.Item>
        <Form.Item hidden name="Title">
          <Input />
        </Form.Item>
        <Form.Item name="PoQty" initialValue={1} label={<FormLabel>Qty</FormLabel>}>
        <InputNumber min={1} step={1} precision={0} />
      </Form.Item>
      </Form>

    </Modal>
  );
};

export default CreateLine