import React, { ChangeEvent, useCallback, useState } from 'react';
import { Row, Space, Input, Button, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;
type Props = {
  value: string;
  width?: number;
  placeholder?: string;
  showIcon?: boolean;
  onSubmit: (value: string) => Promise<void>;
};
const EditText = (props: Props) => {
  const [value, setValue] = useState<string>('');
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { value: oldValue, onSubmit, width, placeholder, showIcon } = props;
  /**
   * handle change event
   */
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.target;
    setValue(newValue);
  }, []);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit(value || oldValue);
    setLoading(false);
    setInputVisible(false);
  }, [onSubmit, value, oldValue]);
  let style = {};
  if (width) {
    style = {
      ...style,
      width,
    };
  }
  return (
    <>
      {!inputVisible && (
        <Space>
          <Text>{value || oldValue}</Text>
          {showIcon && (
            <EditOutlined
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInputVisible(true);
              }}
            />
          )}
        </Space>
      )}
      {inputVisible && (
        <Row>
          <Space>
            <Input
              style={style}
              placeholder={placeholder}
              value={value || oldValue}
              onChange={handleChange}
            />
            <span>
              <Button onClick={handleSubmit} loading={loading}>
                Ok
              </Button>
              <Button
                onClick={() => {
                  setInputVisible(false);
                }}
              >
                Cancel
              </Button>
            </span>
          </Space>
        </Row>
      )}
    </>
  );
};

EditText.defaultProps = {
  showIcon: true,
  width: undefined,
  placeholder: undefined,
};

export default EditText;
