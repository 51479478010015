import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import ShipmentsList from 'components/Sales/ShipmentList';

function Shipment() {
  return (
    <ContentLayout>
      <SiteContent>
        <ShipmentsList />
      </SiteContent>
    </ContentLayout>
  );
}

export default Shipment;
