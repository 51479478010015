import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Spin,
  Modal,
  Input,
  Select,
  Form,
  Tooltip,
  Tag,
  Dropdown,
  Menu,
  Divider,
  Empty,
} from 'antd';
import styled from 'styled-components';
import { WarningIcon } from 'components/common/styles';
import {
  DataGridPlus,
  FormLabel,
  LoadingIcon,
  // ModalPlus,
  // TablePlus,
  message,
  CopySpan,
  CopyComponent,
  DescriptionsFilter,
  TablesFilter,
  // ModalDialog,
} from 'components/common';
// import {
//   fetchOrderShipmentList,
//   fetchOrderShipmentListFromHistory,
// } from 'services/sales';
import {
  fetchOrder,
  fetchOrderFromHistory,
  holdOrder,
  unHoldOrder,
  getCancelReasons,
  fetchAllowRetransmit,
  cancelOrder,
  abandonOrder,
} from 'services/orders';
import {
  //getCommonDialogHeight,
  convertTimeByUtc,
  // getShipmentTitle,
  /*getWarehouseIdFromCache,*/
  checkSqlmintime,
} from 'utils';
// import { SHIPMENT_ITEM_VERIFY_STATE } from 'constants/config';
// import ShipmentDetailDialog from './ShipmentDetailDialog';
// import ShipmentAddress from '../ShipmentDetailDialog/ShipmentAddress';
import { OrderDetailTabs, MiniButton } from '../styles';
import EditOrderAddressDialog from './editOrderAddressDialog';
import ManageShipment from 'components/Sales/ScanVerifyPrint/ManageShipment/ManageShipment';
import {
  CheckOutlined,
  CloseOutlined,
  UpOutlined,
  DownOutlined,
  DashOutlined
} from '@ant-design/icons';
import BundleModal from 'components/Catalog/CatalogView/BundleModal';
import UnpickModal from './UnpickDialog'
import { userProfiles } from 'utils/auth';
import {
  orderProcessStatusKeyValue as processStatusKeyValue,
  orderStatusKeyValue,
} from 'constants/enums'

const { Text } = Typography;

const pickListStatusKeyValue: { [key: string]: string } = {
  '0': 'pending',
  '1': 'queued',
  '2': 'printed',
};

const pickStatusKeyValue: { [key: string]: string } = {
  '0': 'Not Picked',
  '1': 'Picked',
  '2': 'Partially Picked',
};

type OrderDetailDialogProps = {
  isHistory?: boolean;
  fulfillmentOrderId: string;
  fulfillmentOrderNum: number;
  // shipmentList: Array<OrderShipment>;
  onClose: () => void;
  order?: Order;
  selectShipment?: string;
  // onChangeShipment: (shipment: OrderShipment) => void;
};

//const DIALOG_BODY_MAX_HEIGHT = 750;

const CardPlus = styled(Card)`
  & .ant-card-body {
    padding: 5px;
  }
`;

export default function OrderDetail(
  props: OrderDetailDialogProps
): JSX.Element {
  const { TabPane } = OrderDetailTabs;
  // const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order>();
  // const [shipmentNum, setShipmentNum] = useState(-1);
  // const [shipments, setShipments] = useState<Array<OrderShipment>>([]);
  // const [visible, setVisible] = useState(false);
  const [editType, setEditType] = useState<'address' | 'carrier'>('address');
  const {
    //onClose,
    fulfillmentOrderId,
    isHistory,
    selectShipment /*onChangeShipment*/,
  } = props;
  const [editOrderAddressDialogVisible, setEditOrderAddressDialogVisible] =
    useState(false);
  const [reasonList, setReasonList] = useState<any>([]);
  const [retransmitAllowed, setRetransmitAllowed] = useState<boolean>(false);
  const reason = useRef<string>('');
  const [cancelDialogVisible, setCancelDialogVisible] =
    useState<boolean>(false);
  const [abandonDialogVisible, setAbandonDialogVisible] = useState(false);
  const [cancelReasonType, setCancelReasonType] = useState<string>('');
  const [abandonReasonType, setAbandonReasonType] = useState<string>('');
  const [lineItems, setLineItems] = useState<any[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<any>({});
  const [bundleModalVisible, setBundleModalVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [collapse, setCollapse] = useState(false);
  const [abandonLoading, setAbandonLoading] = useState(false);
  const [detailInfoFilteredLabels, setDetailInfoFilteredLabels] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [unpickDialogVisible, setUnpickDialogVisible] = useState(false);

  const enableUOM = userProfiles.getProfileSettingValue('EnableUOM') === '1';

  const [cancelForm] = Form.useForm();
  const [abandonForm] = Form.useForm();

  const itemColumns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
      render: (row: any) => {
        const { data } = row;
        return data.isbundle ? (
          <CopyComponent value={data.sku}>
            <Typography.Link
              onClick={() => {
                console.log(data);
                setCurrent({
                  productId: data.bundleProductId,
                  sku: data.sku,
                  upc: data.upc,
                  productTitle: data.productTitle,
                });
                setBundleModalVisible(true);
              }}
            >
              {data.sku}
            </Typography.Link>
          </CopyComponent>
        ) : (
          <CopyComponent value={data.sku}>
            <span>{data.sku}</span>
          </CopyComponent>
        );
      },
    },
    {
      name: 'upc',
      header: 'UPC',
      defaultFlex: 1,
      minWidth: 220,
      maxWidth: 220,
      userSelect: true,
      sortable: false,
    },
    {
      name: 'itemTitle',
      header: 'Product Name',
      userSelect: true,
      defaultFlex: 1,
      sortable: false,
    },
    {
      name: 'orderQty1',
      header: 'Quantity',
      defaultFlex: 1,
      minWidth: 150,
      maxWidth: 150,
      userSelect: true,
      sortable: false,
      render: (row: any) => {
        const { data } = row;
        return data.isbundle ? (
          <CopyComponent value={data.orderQty1}>
            <span>
              {data.orderQty1}
              <Tag style={{ marginLeft: 4 }} color="#2db7f5">
                Bundle
              </Tag>
            </span>
          </CopyComponent>
        ) : (
          <CopyComponent value={data.orderQty1}>
            <span>{data.orderQty1}</span>
          </CopyComponent>
        );
      },
    },
    {
      name: 'uom',
      header: 'UOM',
      defaultFlex: 1,
      minWidth: 220,
      maxWidth: 220,
      userSelect: true,
      sortable: false,
      visible: enableUOM,
      render: (row: any) => {
        const { uom = '' } = row.data;
        return uom ? uom : 'EA';
      }
    },
    {
      name: 'uomRate',
      header: 'Rate',
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
      visible: enableUOM,
      render: (row: any) => {
        const { uomRate, uom = '' } = row.data;
        return uom ? uomRate : 1
      }
    },
    {
      name: 'uomBaseQty',
      header: 'Base Qty',
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
      visible: enableUOM,
    },
  ];

  const groupFormatItems = (items: any[]) => {
    const arr1: any[] = [];
    const groupArr: any[] = [];
    const expands: any = {};
    items.map((i: any) => {
      if (i.bundleType === 1) {
        const index = arr1.indexOf(i.bundleSKU);
        if (index > -1) {
          groupArr[index].nodes.push({
            ...i,
            id: i.fulfillmentOrderLineNum,
            orderQty1: `${i.orderQty} (${i.bundleQty}x${i.orderQty / i.bundleQty
              })`,
          });
        } else {
          arr1.push(i.bundleSKU);
          expands[`${i.bundleProductNum}`] = true;
          groupArr.push({
            id: i.bundleProductNum,
            folder: true,
            sku: i.bundleSKU,
            upc: i.bundleUPC,
            isbundle: i.bundleQty,
            itemTitle: i.bundleTitle,
            productTitle: i.bundleTitle,
            bundleProductId: i.bundleProductId,
            orderQty1: i.bundleQty,
            nodes: [
              {
                ...i,
                id: i.fulfillmentOrderLineNum,
                orderQty1: `${i.orderQty} (${i.bundleQty}x${i.orderQty / i.bundleQty
                  })`,
              },
            ],
          });
        }
      } else {
        arr1.push(i.sku);
        groupArr.push({
          ...i,
          id: i.fulfillmentOrderLineNum,
          isbundle: '',
          orderQty1: i.orderQty,
        });
      }
      return true;
    });
    setExpandedNodes(expands);
    setLineItems(groupArr);
  };

  /**
   * handle loading shipments
   */
  const handleLoad = useCallback(async () => {
    // setFetching(true);
    setLoading(true);

    try {
      const nextOrder = isHistory
        ? await fetchOrderFromHistory(fulfillmentOrderId)
        : await fetchOrder(fulfillmentOrderId);

      if (!nextOrder.warehouseNum) {
        message.warning({ content: 'Order has no warehouse information' });
      } else {
        setOrder(nextOrder);
        groupFormatItems(nextOrder.orderLineList);

        // const nextShipments = isHistory
        //   ? await fetchOrderShipmentListFromHistory(
        //       nextOrder.warehouseNum,
        //       `_${props.fulfillmentOrderId}_`
        //     )
        //   : await fetchOrderShipmentList(
        //       nextOrder.warehouseNum,
        //       `_${props.fulfillmentOrderId}_`
        //     );

        // setShipments(nextShipments);
      }

      setLoading(false);
      // setFetching(false);
    } catch (e) {
      message.error(`Fetch order detail error: ${e}`);
      setLoading(false);
      // setFetching(false);
    }
  }, [fulfillmentOrderId, isHistory]);

  /**
   * cancel order
   */
  const postCancelOrder = useCallback(async () => {
    const params = await cancelForm.validateFields();
    if (params.errorFields) return;
    if (!order) return;
    try {
      const res = await cancelOrder({
        FulfillmentOrderId: fulfillmentOrderId,
        CentralChannelAccountNum: order.centralOrderNum,
        ChannelOrderId: order.channelOrderId,
        CancelReason: reason.current,
      });
      setCancelDialogVisible(false);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Order had cancelled' });
        handleLoad();
      }
    } catch (error) {
      reason.current = '';
      console.log(error);
    }
  }, [order, reason, fulfillmentOrderId, handleLoad, cancelForm]);

  /**
   * abandon order
   */
  const postAbandonOrder = useCallback(async () => {
    const params = await abandonForm.validateFields();
    if (params.errorFields) return;
    if (!order) return;
    try {
      setAbandonLoading(true)
      const res = await abandonOrder({
        FulfillmentOrderId: fulfillmentOrderId,
        Reason: reason.current,
      });
      setAbandonLoading(false)
      setAbandonDialogVisible(false);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Order had abandoned' });
        handleLoad();
      }
    } catch (error) {
      setAbandonLoading(false)
      reason.current = '';
      console.log(error);
    }
  }, [order, reason, fulfillmentOrderId, handleLoad, abandonForm]);

  /**
   * Hold or unHold
   */
  const postHoldOrder = useCallback(
    async (isHold: boolean) => {
      if (!order) return;
      if (isHold) {
        try {
          const res = await unHoldOrder(fulfillmentOrderId, {
            UnHoldReason: reason.current,
          });
          reason.current = '';
          if (res) {
            message.success({ content: 'Order has unhold' });
            handleLoad();
          }
        } catch (error) {
          reason.current = '';
          console.log(error);
        }
      } else {
        try {
          const res = await holdOrder(fulfillmentOrderId, {
            HoldReason: reason.current,
          });
          reason.current = '';
          if (res) {
            message.success({ content: 'Order has hold' });
            handleLoad();
          }
        } catch (error) {
          reason.current = '';
          console.log(error);
        }
      }
    },
    [order, reason, fulfillmentOrderId, handleLoad]
  );

  const fetchReasons = useCallback(async () => {
    try {
      const res = await getCancelReasons();
      if (res.isSuccess) {
        setReasonList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const allowRetransmit = async () => {
    if (order) {
      const { masterAccountNum, profileNum, fulfillmentOrderId } = order;
      try {
        const res = await fetchAllowRetransmit(
          fulfillmentOrderId,
          masterAccountNum,
          profileNum
        );
        if (res) {
          setRetransmitAllowed(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);
  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);
  // const columns: Array<StringKAnyVPair> = [
  //   {
  //     title: 'Shipment Number',
  //     dataIndex: 'shipmentNum',
  //     render: (value: number, row: OrderShipment) => {
  //       const title = getShipmentTitle(value, shipments);
  //       return (
  //         <Row
  //           style={{
  //             display: 'flex',
  //             flexDirection: 'row',
  //             alignItems: 'center',
  //           }}
  //         >
  //           <i
  //             className={
  //               row.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
  //                 ? 'bi-printer'
  //                 : 'bi-upc-scan'
  //             }
  //           />
  //           <Tooltip title={value}>
  //             <Button
  //               style={{ marginLeft: -10 }}
  //               type="link"
  //               onClick={() => {
  //                 setShipmentNum(value);
  //                 setVisible(true);
  //               }}
  //             >
  //               {title}
  //             </Button>
  //           </Tooltip>
  //         </Row>
  //       );
  //     },
  //   },
  // ];
  /**
   * Dialog Title
   * @returns {JSX.Element}
   */

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  // const Footer = (): JSX.Element => {
  //   return (
  //     <Row justify="end">
  //       <Button
  //         id="close_dialog_btn"
  //         type="primary"
  //         onClick={onClose}
  //         icon={<CloseOutlined />}
  //       >
  //         Close
  //       </Button>
  //     </Row>
  //   );
  // };

  const detailLabels = [
    {
      label: 'Fulfillment Order ID',
      value: <CopySpan value={order ? order.fulfillmentOrderId : ''} />,
      position: 'left'
    },
    {
      label: 'Channel Name',
      value: <CopySpan value={order ? order.channelName : ''} />,
      position: 'right'
    },
    {
      label: 'Order Date',
      value: <CopySpan
        value={
          order && order.originalOrderDate
            ? convertTimeByUtc(order.originalOrderDate)
            : ''
        }
      />,
      position: 'left'
    },
    {
      label: 'Order Fulfillment Status',
      value: <>
        {
          order && (
            <Text
              type={
                order?.orderStatus === 8 ||
                  order?.orderStatus === 16 ||
                  order?.orderStatus === 32
                  ? 'danger'
                  : 'success'
              }
            >
              {order?.orderStatus === 0 && (
                <CopySpan
                  value={
                    `${orderStatusKeyValue[`${order.orderStatus}`]
                    }` +
                    `${order.unHoldReason
                      ? ` (Unhold reason: ${order.unHoldReason})`
                      : ''
                    }`
                  }
                />
              )}
              {order?.orderStatus === 8 && (
                <CopySpan
                  value={
                    `${orderStatusKeyValue[`${order.orderStatus}`]
                    }` +
                    `${order.holdReason
                      ? ` (Hold reason: ${order.holdReason})`
                      : ''
                    }`
                  }
                />
              )}
              {![0, 8].includes(order.orderStatus) && (
                <CopySpan
                  value={`${orderStatusKeyValue[`${order.orderStatus}`]
                    }`}
                />
              )}
            </Text>
          )
        }
      </>,
      position: 'right'
    },
    {
      label: 'Channel Account',
      value: <CopySpan value={order ? order.channelAccountName : ''} />,
      position: 'left'
    },
    {
      label: 'Process Status',
      value: <Text>
        <CopyComponent
          value={
            order && order.advanceProcess
              ? processStatusKeyValue[`${order.advanceProcess}`]
              : ''
          }
        >
          <Text>
            {order && order.advanceProcess
              ? processStatusKeyValue[`${order.advanceProcess}`]
              : ''}
            {order?.advanceProcessNote && (
              <Tooltip
                placement="top"
                title={order?.advanceProcessNote || ''}
              >
                <MiniButton
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    Modal.error({
                      title: 'Processed With Error',
                      content: (
                        <Text copyable>
                          {order?.advanceProcessNote}
                        </Text>
                      ),
                    });
                  }}
                >
                  View
                </MiniButton>
              </Tooltip>
            )}
          </Text>
        </CopyComponent>
      </Text>,
      position: 'right'
    },
    {
      label: 'Channel Order ID',
      value: <CopySpan value={order ? order.channelOrderId : ''} />,
      position: 'left'
    },
    {
      label: 'Warehouse Code',
      value: <CopySpan value={order ? order.warehouseCode : ''} />,
      position: 'right'
    },
    {
      label: 'Secondary Channel Order ID',
      value: <CopySpan
        value={
          order ? `${order.secondaryChannelOrderId}` : ''
        }
      />,
      position: 'left'
    },
    {
      label: 'Diff ID',
      value: <CopySpan value={order ? order.diffId : ''} />,
      position: 'right'
    },
    {
      label: 'Sales Order#',
      value: <CopySpan
        value={order ? `${order.erpSalesOrderNumber}` : ''}
      />,
      position: 'left'
    },
    {
      label: 'Pick List Number',
      value: <CopySpan
        value={order ? `${order.pickListNum}` : ''}
      />,
      position: 'right'
    },
    {
      label: 'Seller Order ID',
      value: <CopySpan
        value={order ? `${order.sellerOrderId}` : ''}
      />,
      position: 'left'
    },
    {
      label: 'Pick List Tag',
      value: <CopySpan
        value={order ? order.pickListTag || '' : ''}
      />,
      position: 'right'
    },
    {
      label: 'Central Order#',
      value: <CopySpan
        value={order ? `${order.centralOrderNum}` : ''}
      />,
      position: 'left'
    },
    {
      label: 'Pick List Status',
      value: <CopySpan
        value={
          order
            ? pickListStatusKeyValue[order.pickListStatus]
            : ''
        }
      />,
      position: 'right'
    },
    {
      label: 'Pick Status',
      value: <CopySpan
        value={
          order
            ? pickStatusKeyValue[order.pickStatus]
            : ''
        }
      />,
      position: 'right'
    },
    // {
    //   label: 'Customer # / Name',
    //   value: <CopySpan
    //     value={
    //       order
    //         ? order.customerCode
    //           ? order.customerName
    //             ? `${order.customerCode} / ${order.customerName}`
    //             : order.customerCode
    //           : ''
    //         : ''
    //     }
    //   />,
    //   position: 'left'
    // },
    {
      label: 'Customer Code',
      value: <CopySpan
        value={order?.customerCode||''}
      />,
      position: 'left'
    },
    {
      label: 'Customer Name',
      value: <CopySpan
        value={order?.customerName||''}
      />,
      position: 'left'
    },
    {
      label: 'Buyer/Recipient Address',
      value: <Row justify="center">
        <CardPlus style={{ width: '90%', minWidth:150 }}>
          <Col>
            <Row justify="space-between">
              <span>{order?.shipToName}</span>
            </Row>
            <Row>
              <span>{order?.shipToAddressLine1}</span>
            </Row>
            <Row>
              <span>{order?.shipToAddressLine2}</span>
            </Row>
            <Row>
              <span>{order?.shipToAddressLine3}</span>
            </Row>
            {order &&
              ['United States', 'US'].includes(
                order.shipToCountry
              ) ? (
              <Row>
                <span>{`${order?.shipToCity},${order?.shipToState
                  } ${order?.shipToPostalCode}${order?.shipToPostalCodeExt
                    ? `-${order.shipToPostalCodeExt}`
                    : ''
                  }`}</span>
              </Row>
            ) : (
              <Row>
                <span>{`${order?.shipToCity},${order?.shipToState
                  },${order?.shipToPostalCode}${order?.shipToPostalCodeExt
                    ? `-${order.shipToPostalCodeExt}`
                    : ''
                  }`}</span>
              </Row>
            )}
            <Row>{order?.shipToCountry}</Row>
            <Row>
              <span>Tel: {order?.shipToDaytimePhone}</span>
            </Row>
          </Col>
        </CardPlus>
      </Row>,
      position: 'right'
    },
    {
      label: 'Sales Division',
      value: <CopySpan
        value={order ? `${order.salesDivision}` : ''}
      />,
      position: 'left'
    },
    {
      label: 'Ship Date',
      value: <CopySpan
        value={
          order && order.latestShipDate
            ? checkSqlmintime(order.latestShipDate)
              ? ''
              : convertTimeByUtc(order.latestShipDate)
            : ''
        }
      />,
      position: 'left'
    },
    {
      label: 'Request Shipping Carrier',
      value: <CopySpan
        value={order ? order.requestShippingCarrier : ''}
      />,
      position: 'left'
    },
    {
      label: 'Request Shipping Service',
      value: <CopySpan
        value={order ? order.requestShippingService : ''}
      />,
      position: 'left'
    },
    {
      label: 'Signature Flag',
      value: <CopySpan
        value={order ? `${order.signatureFlag ?? ''}` : ''}
      />,
      position: 'left'
    },
    {
      label: 'Seller Public Notes',
      value: <Text
        style={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxWidth: '90%',
        }}
      >
        {order && order.sellerPublicNotes
          ? order.sellerPublicNotes
          : ''}
      </Text>,
      position: 'left'
    },
    {
      label: 'Seller Private Notes',
      value: <Text
        style={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          maxWidth: '90%',
        }}
      >
        {order && order.sellerPrivateNotes
          ? order.sellerPrivateNotes
          : ''}
      </Text>,
      position: 'left'
    },
    {
      label: 'Update Date',
      value: <CopySpan
        value={
          order && order.updateDate
            ? convertTimeByUtc(order.updateDate)
            : ''
        }
      />,
      position: 'right'
    },
  ]


  const labelWidth = 200;

  return (
    <>
      {/* {visible && (
        <ShipmentDetailDialog
          shipmentNum={shipmentNum}
          shipmentList={shipments}
          onClose={() => {
            setVisible(false);
          }}
        />
      )} */}
      <Card>
        <Spin indicator={<LoadingIcon size="large" />} spinning={loading}>
          <OrderDetailTabs
            defaultActiveKey={selectShipment ? '2' : '1'}
            type="card"
          >
            <TabPane
              className="order-detail"
              style={
                {
                  // height: getCommonDialogHeight(DIALOG_BODY_MAX_HEIGHT) - 230,
                }
              }
              key="1"
              tab="Information"
            >
              <Row justify="space-between">
                {order && (
                  <Space>
                    <Button
                      id="edit_shipping_address"
                      type="primary"
                      disabled={
                        isHistory ||
                        ['Canceled', 'Shipped', 'Abandoned'].includes(
                          orderStatusKeyValue[order.orderStatus]
                        )
                      }
                      onClick={() => {
                        setEditType('address');
                        setEditOrderAddressDialogVisible(true);
                      }}
                    >
                      Edit Shipping Address
                    </Button>
                    <Button
                      id="edit_shipping_service"
                      type="primary"
                      disabled={
                        isHistory ||
                        ['Canceled', 'Shipped', 'Abandoned'].includes(
                          orderStatusKeyValue[order.orderStatus]
                        )
                      }
                      onClick={() => {
                        setEditType('carrier');
                        setEditOrderAddressDialogVisible(true);
                      }}
                    >
                      Edit Shipping Service
                    </Button>
                    {[0, 2].indexOf(order.orderStatus) > -1 && (<Tooltip title={order.pickListNum === 0 ? 'order not queued': order.pickListStatus !== 2 ? 'order picklist not print':''}><Button
                        id="unpick"
                        type="primary"
                        disabled={!(order.pickListStatus === 2 && order.pickListNum > 0)}
                        onClick={() => {
                          setUnpickDialogVisible(true)
                        }}
                      >
                        Change Pick Status
                      </Button></Tooltip>)}
                    {[0, 2, 8].indexOf(order.orderStatus) > -1 && (
                      <Button
                        id="set_hold"
                        type="primary"
                        onClick={() => {
                          Modal.confirm({
                            title: (
                              <span>
                                <div>
                                  Are you sure you want to{' '}
                                  {order.orderStatus === 8 ? 'unhold' : 'hold'}{' '}
                                  the order?
                                </div>
                                <br />
                                <div>
                                  {order.orderStatus === 8
                                    ? 'Unhold Reason'
                                    : 'Hold Reason'}
                                  :{' '}
                                </div>
                              </span>
                            ),
                            okText: 'Yes',
                            cancelText: 'No',
                            okType: 'default',
                            okButtonProps: { className: 'hover-danger-button' },
                            autoFocusButton: 'cancel',
                            content: (
                              <div style={{ paddingRight: 38 }}>
                                <Input.TextArea
                                  id="set_hold_reason"
                                  rows={2}
                                  style={{ marginTop: -2 }}
                                  onChange={(event) =>
                                    (reason.current = event.target.value)
                                  }
                                />
                              </div>
                            ),
                            onCancel() {
                              reason.current = '';
                            },
                            onOk() {
                              postHoldOrder(order.orderStatus === 8);
                            },
                          });
                        }}
                      >
                        {order.orderStatus === 8 ? 'Unhold' : 'Hold'}
                      </Button>
                    )}
                    <DescriptionsFilter
                      labels={detailLabels}
                      dataSource={order}
                      setFilteredLabels={setDetailInfoFilteredLabels}
                    />
                  </Space>
                )}
                <Space>
                  {order &&
                    (order?.orderStatus === 8 || order?.orderStatus === 0) && (
                      <Button
                        id="abandon_order"
                        danger
                        type="primary"
                        disabled={isHistory}
                        onClick={() => {
                          setAbandonDialogVisible(true);
                        }}
                      >
                        Modify
                      </Button>
                    )}

                  {order && order.orderStatus !== 1 && <Dropdown
                    disabled={order?.orderStatus === 5 || order?.orderStatus === 32}
                    overlay={
                      <Menu>
                        {order &&
                          order?.orderStatus !== 5 &&
                          order?.orderStatus !== 32 &&
                          order?.orderStatus !== 16 && (
                            <Menu.Item key={2}>
                              <Button
                                id="cancel_order"
                                className="hover-danger-button-2"
                                type="text"
                                disabled={
                                  isHistory ||
                                  orderStatusKeyValue[order.orderStatus] === 'Shipped'
                                }
                                onClick={() => {
                                  setCancelDialogVisible(true);
                                }}
                              >
                                <Text type="danger">Cancel Order</Text>
                              </Button>
                            </Menu.Item>
                          )}
                        {order?.orderStatus === 16 && (
                          <Menu.Item key={3}>
                            <Button
                              disabled={
                                retransmitAllowed || order?.allowRetransmit !== 0
                              }
                              type="text"
                              onClick={() => {
                                Modal.confirm({
                                  title:
                                    'Are you sure you want to re-transmit the order?',
                                  okText: 'Yes',
                                  cancelText: 'No',
                                  onOk() {
                                    allowRetransmit();
                                  },
                                });
                              }}
                            >
                              Allow Re-transmit Order
                            </Button>
                          </Menu.Item>
                        )}
                      </Menu>
                    }>
                    <Button icon={<DashOutlined />} />
                  </Dropdown>}
                </Space>
              </Row>

              <Row style={{ marginTop: 5 }}>
                {detailInfoFilteredLabels.length === 0 ? (
                  <Row justify="center" style={{ width: '100%' }}>
                    <Empty />
                  </Row>
                ) : (
                  <>
                    {collapse ? (
                      <>
                        <Col span={12}>
                          {
                            detailInfoFilteredLabels.map((label, index: number) => {
                              if (label.position === 'left') return (
                                <Row key={`detail_info_row${index}`}>
                                  <div style={{ width: labelWidth }}>
                                    <FormLabel>{label.label}</FormLabel>
                                  </div>
                                  {label.value}
                                </Row>
                              )
                              return null;
                            })
                          }
                        </Col>
                        <Col span={12}>
                          {
                            detailInfoFilteredLabels.map((label, index: number) => {
                              if (label.position === 'right') return (
                                <Row key={`detail_info_row${index}`}>
                                  <div style={{ width: labelWidth }}>
                                    <FormLabel>{label.label}</FormLabel>
                                  </div>
                                  {label.value}
                                </Row>
                              )
                              return null;
                            })
                          }
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={12}>
                          {
                            detailInfoFilteredLabels.map((label, index: number) => {
                              if (label.position === 'left' && index < 8) return (
                                <Row key={`detail_info_row${index}`}>
                                  <div style={{ width: labelWidth }}>
                                    <FormLabel>{label.label}</FormLabel>
                                  </div>
                                  {label.value}
                                </Row>
                              )
                              return null;
                            })
                          }
                        </Col>
                        <Col span={12}>
                          {
                            detailInfoFilteredLabels.map((label, index: number) => {
                              if (label.position === 'right' && index < 8) return (
                                <Row key={`detail_info_row${index}`}>
                                  <div style={{ width: labelWidth }}>
                                    <FormLabel>{label.label}</FormLabel>
                                  </div>
                                  {label.value}
                                </Row>
                              )
                              return null;
                            })
                          }
                        </Col>
                      </>
                    )}
                  </>
                )}
                {detailLabels.length === detailInfoFilteredLabels.length ? (
                  <Divider orientation="center" plain={false}>
                    <Button
                      type="link"
                      onClick={() => setCollapse(!collapse)}
                      icon={!collapse ? <DownOutlined /> : <UpOutlined />}
                    >
                      {!collapse ? 'Expand' : 'Collapse'}
                    </Button>
                  </Divider>
                ) : <Divider orientation="center" plain={false} />}
                {order &&
                  order.orderLineList &&
                  order.orderLineList.length > 0 && (
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {/* <Title level={4}>Items</Title> */}
                      <TablesFilter
                        columns={itemColumns}
                        dataSource={lineItems}
                        setFilteredData={setFilteredData}
                      />
                      <DataGridPlus
                        name="locationReport"
                        // idProperty="id"
                        loading={loading}
                        autoWith={false}
                        treeColumn="sku"
                        style={{ height: collapse ? 550 : 400 }}
                        columns={itemColumns}
                        showColumnMenuTool={false}
                        expandedNodes={expandedNodes}
                        onExpandedNodesChange={({ expandedNodes: ens }) => {
                          setExpandedNodes(ens);
                        }}
                        dataSource={filteredData}
                        pagination={false}
                        {...({} as any)}
                      />
                    </Space>
                  )}
              </Row>
            </TabPane>
            <TabPane
              tab="Shipments"
              key="2"
            // style={{
            //   height: getCommonDialogHeight(DIALOG_BODY_MAX_HEIGHT) - 230,
            // }}
            >
              {/* <TablePlus
                size="small"
                style={{ marginTop: 5, height: 'calc(100% - 160px)' }}
                loading={fetching}
                columns={columns as any}
                rowKey="shipmentNum"
                scroll={{
                  y: getCommonDialogHeight(DIALOG_BODY_MAX_HEIGHT) - 280,
                }}
                dataSource={shipments}
                pagination={false}
              /> */}
              {order && (
                <ManageShipment
                  orderId={`_${props.fulfillmentOrderId}_`}
                  warehouseNum={order.warehouseNum}
                  setModified={() => 0}
                  selectedShipmentId={selectShipment}
                  showDetailButton
                  all
                />
              )}
            </TabPane>
          </OrderDetailTabs>
        </Spin>
        {editOrderAddressDialogVisible && order && (
          <EditOrderAddressDialog
            visible
            onRefresh={() => {
              handleLoad();
            }}
            onHide={() => setEditOrderAddressDialogVisible(false)}
            orderInfo={order}
            orderId={fulfillmentOrderId}
            editType={editType}
          />
        )}
        {order && unpickDialogVisible && <UnpickModal
          visible
          onHide={() => setUnpickDialogVisible(false)}
          onRefresh={() => {
            setUnpickDialogVisible(false)
            handleLoad()
          }}
          orderInfo={order}
        />}
        {bundleModalVisible && current && (
          <BundleModal
            visible
            onHide={() => setBundleModalVisible(false)}
            product={current}
          />
        )}
      </Card>
      <Modal
        title={
          <>
            <WarningIcon /> Are you sure you want to cancel this order?
          </>
        }
        centered
        visible={cancelDialogVisible}
        onCancel={() => setCancelDialogVisible(false)}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <Row justify="end">
            <Space>
              <Button
                onClick={() => setCancelDialogVisible(false)}
                icon={<CloseOutlined />}
                autoFocus={true}
              >
                No
              </Button>
              <Button
                key="submit"
                // type="primary"
                className="hover-danger-button"
                onClick={postCancelOrder}
                icon={<CheckOutlined />}
              >
                Yes
              </Button>
            </Space>
          </Row>
        }
        width={500}
      >
        <Row>
          <Typography.Text>{`If warehouse will never ship this order, "cancel" is the correct action.`}</Typography.Text>
        </Row>
        <Form
          form={cancelForm}
          style={{ width: 300 }}
          labelCol={{ span: 1, offset: 1 }}
        >
          <Form.Item
            name="cancelReasonType"
            rules={[{ required: true, message: 'Please select a reason' }]}
            label={<></>}
          >
            <Select
              id="reason_selector"
              onChange={(value) => {
                setCancelReasonType(value);
                reason.current = `${value}`;
              }}
              placeholder="Please select a reason"
            >
              {reasonList.map((item: any) => (
                <Select.Option key={item.settingCode} value={item.settingCode}>
                  {item.settingName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {cancelReasonType === 'Others' && (
            <Form.Item
              name="otherReason"
              label={<></>}
            // rules={[{ required: true, message: 'please input reason' }]}
            >
              <Input.TextArea
                rows={2}
                placeholder="Please input the cancel reason"
                onChange={(event) =>
                  (reason.current = 'Other: ' + event.target.value)
                }
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
      <Modal
        title={
          <>
            <WarningIcon /> Are you sure you want to modify this order?
          </>
        }
        centered
        visible={abandonDialogVisible}
        onCancel={() => setAbandonDialogVisible(false)}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <Row justify="end">
            <Space>
              <Button
                disabled={abandonLoading}
                onClick={() => setAbandonDialogVisible(false)}
                icon={<CloseOutlined />}
                autoFocus={true}
              >
                No
              </Button>
              <Button
                key="submit"
                // type="primary"
                loading={abandonLoading}
                className="hover-danger-button"
                onClick={postAbandonOrder}
                icon={<CheckOutlined />}
              >
                Yes
              </Button>
            </Space>
          </Row>
        }
        width={500}
      >
        <Row style={{ marginBottom: 8 }}>
          <Typography.Text>{`If you want to modify this order, it will be unlocked for editing in ERP. This action will abandon the current fulfillment process for this order.`}</Typography.Text>
        </Row>
        <Form
          form={abandonForm}
          style={{ width: 300 }}
          labelCol={{ span: 1, offset: 1 }}
        >
          <Form.Item
            name="abandonReasonType"
            rules={[{ required: true, message: 'Please select a reason' }]}
            label={<></>}
          >
            <Select
              id="reason_selector"
              onChange={(value) => {
                setAbandonReasonType(value);
                reason.current = `${value}`;
              }}
              placeholder="Please select a reason"
            >
              <Select.Option key="Re-submit" value="Re-submit">
                Re-submit
              </Select.Option>
              {reasonList.map((item: any) => (
                <Select.Option key={item.settingCode} value={item.settingCode}>
                  {item.settingName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {abandonReasonType === 'Others' && (
            <Form.Item
              name="otherReason"
              label={<></>}
            // rules={[{ required: true, message: 'please input reason' }]}
            >
              <Input.TextArea
                rows={2}
                placeholder="Please input the modify reason"
                onChange={(event) =>
                  (reason.current = 'Other: ' + event.target.value)
                }
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}
