import React from 'react';
import { MultipleSelect } from 'components/common';

type LocationTypeProps = {
  onChange?: (value: Array<StringKAnyVPair>) => void;
};
export default function LocationType(props: LocationTypeProps): JSX.Element {
  const options = [
    {
      id: 1,
      label: 'Reserve',
    },
    {
      id: 2,
      label: 'Backstock',
    },
    {
      id: 3,
      label: 'Picking',
    },
  ];
  const { onChange } = props;
  return (
    <div style={{ width: 150 }}>
      <MultipleSelect
        width="100%"
        // value={value || options}
        data={options}
        valueField="id"
        labelField="label"
        onChange={onChange}
      />
    </div>
  );
}
LocationType.defaultProps = {
  onChange: undefined,
};
