import React from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import Restock from '../ReturnStock/index';
import { Row } from 'antd';

interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
}

const ProductDetailDialog = (props: Props) => {

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
      </Row>
    );
  };

  return (
    <>
      <ModalDialog
        centered
        fullscreen={true}
        title="Restock"
        maskClosable={false}
        closeButton={true}
        closable={false}
        visible={true}
        bodyHeight={630}
        onClose={closeProductDetailDialog}
        destroyOnClose={true}
        getContainer={false}
        footer={<Footer />}
      >
        <Restock isModal modalReturn={props.modalReturn} goto={(key, newData)=> props.onClose(key, newData)}/>
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
