import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Descriptions,
  Divider,
} from 'antd';
import { FormLabel } from 'components/common';
import { message as showMessage } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  patchWarehouseLocationAspect,
  getWarehouseAspects,
  batchWarehouseLocationAspect
} from 'services/warehouse';

/**
 * The type definition for CreateWarehouseDialog.
 *
 * @{boolean} triggerCreation - whether trigger creation action
 * @{Function} onCreateWarehouse - callback function after warehouse being created
 */
interface CreateProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  location: WarehouseLocationRow[];
}

export default function CreateWarehouseDialog(props: CreateProps) {
  const { visible, onHide, onRefresh, location } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [selectedAspect, setSelectedAspect] = useState<any>();

  const [form] = Form.useForm();

  const getAspentList = useCallback(async () => {
    if (!location) return;
    try {
      setLoading(true);
      const res = await getWarehouseAspects(location[0].warehouseId);
      setLoading(false);
      if (res) {
        if (location.length === 1 && location[0].locationAspectsNum) {
          const temp = res.filter(
            (i: any) => i.locationAspectsNum === location[0].locationAspectsNum
          );
          if (temp.length > 0) {
            setSelectedAspect(temp[0]);
          }
        }
        setData(res);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [location]);

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const res = location.length > 1 ? await batchWarehouseLocationAspect(
        location[0].warehouseId,
        params.LocationAspectsNum || 0,
        location.map(i => i.locationNum)
      ) : await patchWarehouseLocationAspect(
        location[0].warehouseId,
        location[0].locationId,
        params.LocationAspectsNum || 0
      );
      if (res) {
        showMessage.success({
          content: 'Save successfully！',
        });
        form.resetFields();
        onRefresh();
        onHide();
      } else {
        showMessage.error({ content: 'Save failedlly' });
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      // showMessage.error({ content: err.response.data.error });
      console.log(err);
    }
  };

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  useEffect(() => {
    getAspentList();
  }, [getAspentList]);

  //   const layout = {
  //     labelCol: { span: 8 },
  //     wrapperCol: { span: 14 },
  //   };
  return (
    <Modal
      title={location.length > 1 ? 'Batch Set Aspect' :`Set Aspect - ${location[0].locationCode}`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={
                location.length === 1 &&
                selectedAspect?.locationAspectsNum ===
                location[0].locationAspectsNum
              }
              onClick={handleSave}
              loading={loading}
              icon={<SaveOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="LocationAspectsNum"
            initialValue={location.length === 1 ? location[0].locationAspectsNum ? location[0].locationAspectsNum  : undefined : undefined}
            label={<FormLabel>Select Aspect</FormLabel>}
            // rules={[{ required: true, message: 'Please select aspect' }]}
          >
            <Select
              style={{ width: 300 }}
              allowClear
              onChange={(value) => {
                const temp = data.filter(
                  (i: any) => i.locationAspectsNum === value
                );
                if (temp.length > 0) {
                  setSelectedAspect(temp[0]);
                } else {
                  setSelectedAspect(undefined);
                }
              }}
            >
              {data.map((i: any) => (
                <Select.Option
                  key={i.locationAspectsNum}
                  value={i.locationAspectsNum}
                >
                  {i.locationAspectsName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          {selectedAspect && (
            <Descriptions title="Selected Aspect Info">
              <Descriptions.Item label="Volume Size">{`${
                selectedAspect.volumeLength || 0
              } x ${selectedAspect.volumeWidth || 0} x ${
                selectedAspect.volumeHeight || 0
              } (${selectedAspect.dimensionUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Capacity Size">{`${
                selectedAspect.capacityLength || 0
              } x ${selectedAspect.capacityWidth || 0} x ${
                selectedAspect.capacityHeight || 0
              } (${selectedAspect.dimensionUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Tare Weight">{`${
                selectedAspect.tareWeight || 0
              } (${selectedAspect.weightUnit})`}</Descriptions.Item>
              <Descriptions.Item label="Max Load">
                {selectedAspect.maxLoad}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Form>
      </Spin>
    </Modal>
  );
}
