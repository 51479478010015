import React, { useCallback, useState } from 'react';
import { Row, Col, Button, Alert } from 'antd';
import { message } from 'components/common';
import { validateAddress } from 'services/shipment';

type ShipmentAddressProps = {
  shipment: OrderShipment;
  onChangeShipment: (shipment: OrderShipment) => void;
};
export default function ShipmentAddress(
  props: ShipmentAddressProps
): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { onChangeShipment, shipment } = props;
  /**
   * handle validate address
   */
  const handleValidateAddress = useCallback(async () => {
    setLoading(true);
    const newShipment = await validateAddress(shipment.shipmentNum);
    if (newShipment) {
      message.success('Validate address successfully!');
      onChangeShipment(newShipment);
    }
    setLoading(false);
  }, [shipment, onChangeShipment]);
  const address = `${shipment.shipToCity},${shipment.shipToState} ${shipment.shipToPostalCode}`;
  const Action = (): JSX.Element => {
    return (
      <Button size="small" loading={loading} onClick={handleValidateAddress}>
        Validate Address
      </Button>
    );
  };
  return (
    <Col>
      <Row justify="space-between">
        <span>{shipment.shipToName}</span>
      </Row>
      <Row>
        <span>{shipment.shipToAddressLine1}</span>
      </Row>
      <Row>
        <span>{shipment.shipToAddressLine2}</span>
      </Row>
      <Row>
        <span>{shipment.shipToAddressLine3}</span>
      </Row>
      <Row>
        <span>{address}</span>
      </Row>
      <Row>{shipment.shipToCountry}</Row>
      <Row>
        <span>{shipment.shipToNightPhone}</span>
      </Row>
      {!shipment.shipToAddressVerified ? (
        <Alert
          style={{ marginTop: 5 }}
          message="Warning"
          showIcon
          description="Insufficient or Incorrect Address Data"
          type="warning"
          action={<Action />}
        />
      ) : (
        <Alert
          style={{ top: -1, right: -1, position: 'absolute' }}
          message="The address is verified."
          type="success"
          showIcon
        />
      )}
    </Col>
  );
}
