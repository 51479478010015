import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Row, Space } from 'antd';
import { ButtonIcon, DataGridPlus } from 'components/common';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import { fetchOrder } from 'services/orders'
import { queryProductByKeyword } from 'services/product';
import { CloseOutlined } from '@ant-design/icons';

type SelectorProps = {
  current: any;
  setVisible: (flag: boolean) => void;
};

const CarrierSelector = (props: SelectorProps) => {
  const { current, setVisible } = props;
  const { fulfillmentOrderId } = current;
  const [loading, setLoading] = useState<boolean>(true);
  const [detailBtnLoading, setDetailBtnLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [product, setProduct] = useState<any>();
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const columns = [
    {
      header: 'SKU',
      name: 'sku',
      defaultFlex: 1,
      minWidth: 220,
    },
    {
      header: 'UPC',
      name: 'upc',
      defaultFlex: 1,
    },
    {
      header: 'Product Name',
      name: 'itemTitle',
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      textAlign: 'center',
      render: (row: any) => {
        const { data } = row;
        return (
          <Button
            type='primary'
            onClick={() => getProducts(data.sku)}
            loading={detailBtnLoading}
            icon={<ButtonIcon className="bi bi-file-earmark-text" />}
          >
            Detail
          </Button>
        )
      }
    }
  ]

  const getProducts = useCallback(async (key: string) => {
    setDetailBtnLoading(true)
    try {
      const res: any = await queryProductByKeyword(key)
      setDetailBtnLoading(false);
      if (res.length > 0) {
        setProduct(res[0]);
        setDetailVisible(true);
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getOrderDetail = useCallback(async (fulfillmentOrderId: string) => {
    try {
      const res = await fetchOrder(fulfillmentOrderId);
      setData(res.orderLineList || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [])

  useEffect(() => {
    if (fulfillmentOrderId) {
      getOrderDetail(fulfillmentOrderId)
    }
  }, [fulfillmentOrderId, getOrderDetail])

  return (
    <>
      <Modal
        title="SKU List"
        visible={true}
        centered
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        footer={
          <Row justify="end">
            <Space>
              <Button onClick={() => setVisible(false)} icon={<CloseOutlined />}>Close</Button>
            </Space>
          </Row>
        }
        width={800}
      >
        <DataGridPlus
          idProperty="code"
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination="local"
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
        {
          detailVisible && product && (
            <ProductDetail
              visible={detailVisible}
              onHide={() => setDetailVisible(false)}
              productId={product.productId}
            />
          )
        }
      </Modal>
    </>
  );
};

export default CarrierSelector;
