import React, { useCallback, useRef, useState } from 'react';
import { FormInstance, Select } from 'antd';
import { fetchWarehouseLocationList } from 'services/warehouse';

type SelectProps = {
  warehouseId?: string;
  locationType: number;
  onChange: (locationNum?: number, row?: any) => void;
  value?: number;
  style?: any;
  showPick?: boolean;
  form?: FormInstance<any>;
  title?: string;
};

// eslint-disable-next-line
export default (props: SelectProps) => {
  const { warehouseId, locationType, value, onChange, showPick, style, form, title } = props;
  const [data, setData] = useState<any[]>([]);
  const timerRef = useRef<any>(null);

  const fetchLocations = useCallback(
    async (value: string) => {
      // init filed value
      if (title && form) {
        let obj: any = {};
        obj[title] = null;
        form?.setFieldsValue(obj);
      }
      try {
        if (!warehouseId) return;
        const res = await fetchWarehouseLocationList(
          warehouseId,
          showPick ? true : false,
          value,
          locationType
        );
        // if (title && res.length > 0 && value.toUpperCase() === res[0].locationCode.toUpperCase()) {
        //   let obj: any = {};
        //   obj[title] = res[0].locationNum
        //   form?.setFieldsValue(obj);
        // }
        if (title && res.length > 0) {
          if (res.find(item => item.locationCode.toUpperCase().includes(value.toUpperCase()))) {
            let obj: any = {};
            obj[title] = res[0].locationNum
            form?.setFieldsValue(obj);
          }
        }
        setData(res);
      } catch (error) { }
    },
    [locationType, warehouseId, showPick, form, title]
  );

  const handleSearch = (value: string) => {
    if (value) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      timerRef.current = setTimeout(() => fetchLocations(value), 300);
    } else {
      setData([]);
    }
  };

  return (
    <Select
      showSearch
      allowClear
      disabled={!warehouseId}
      defaultValue={value}
      placeholder="input search text"
      style={style ? { width: '100%', ...style } : { width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      value={value}
      filterOption={false}
      onSearch={handleSearch}
      // onSelect={(value:any) => console.log('%$$$$$$$',value)}
      onSelect={(value: number) => {
        const row = data.filter((i) => i.locationNum === value);
        onChange(value, row);
      }}
      notFoundContent={(null)}
    >
      {data.map((item) => (
        <Select.Option key={item.locationNum} value={item.locationNum}>
          {item.locationCode}
        </Select.Option>
      ))}
    </Select>
  );
};
