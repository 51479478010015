import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Typography, Modal, Button, Space, Form, Input, Col } from 'antd';
import { DataGridPlus, FormLabel } from 'components/common';
import { fetchInventory } from 'services/product';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;
type SelectLocationDialogProps = {
  visible: boolean;
  sku?: string;
  warehouseNum: number;
  warehouseId: string;
  onHide: () => void;
  onSelect: (record: any) => void;
  defaultLocationCode?: string;
  lotEnable: boolean;
  uomEnable: boolean;
};
/**
 * Return SelectLocationDialog Component
 * @param {SelectLocationDialogProps} props
 * @returns {JSX.Element}
 */
export default function SelectLocationDialog(
  props: SelectLocationDialogProps
): JSX.Element {
  const {
    onHide,
    onSelect,
    visible,
    sku,
    warehouseNum,
    warehouseId,
    lotEnable,
    uomEnable,
  } = props;
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState<Array<any>>([]);
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [filterData, setFilterData] = useState<Array<any>>([]);
  const [filterUom, setFilterUom] = useState<string>();
  const [filterLot, setFilterLot] = useState<string>();
  const [filterLocation, setFilterLocation] = useState<string | undefined>(
    props.defaultLocationCode
  );

  const columns = useMemo(() => {
    const cls = [
      {
        header: 'Locations',
        name: 'locationCode',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'LOT#',
        name: 'lotNumber',
        userSelect: true,
        defaultFlex: 1,
        lotColumn: true,
      },
      {
        header: 'Quantity',
        name: 'quantity',
        userSelect: true,
        textAlign: 'end',
        headerAlign: 'end',
        defaultFlex: 1,
      },
      {
        header: 'UOM',
        name: 'uom',
        userSelect: true,
        defaultFlex: 1,
        uomColumn: true,
      },
      {
        header: 'Rate',
        name: 'uomRate',
        userSelect: true,
        defaultFlex: 1,
        uomColumn: true,
      },
      {
        header: 'Base Qty',
        name: 'uomBaseQty',
        userSelect: true,
        defaultFlex: 1,
        uomColumn: true,
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        headerAlign: 'center',
        minWidth: 100,
        maxWidth: 100,
        render: (value: any) => {
          const { data: row } = value;
          return (
            <Button
              type="default"
              style={{ width: '100%' }}
              onClick={() => {
                onSelect(row);
              }}
            >
              Select
            </Button>
          );
        },
      },
    ];
    let temp = [...cls];
    temp = lotEnable ? [...temp] : temp.filter((i) => !i.lotColumn);
    temp = uomEnable ? [...temp] : temp.filter((i) => !i.uomColumn);
    return temp;
  }, [lotEnable, uomEnable, onSelect]);

  const getInventory = useCallback(async () => {
    const filter = `sku eq '${sku}'`;
    try {
      setLoading(true);
      const res = await fetchInventory(
        { id: warehouseNum, warehouseId: warehouseId },
        { $filter: filter, $top: 1000, virtual: false }
      );
      setLoading(false);
      setDataSource(res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [sku, warehouseNum, warehouseId]);

  useEffect(() => {
    let temp = [...dataSource];
    if (filterLocation && filterLocation.trim()) {
      temp = temp.filter(
        (i) =>
          i.locationCode
            .toLocaleLowerCase()
            .indexOf(filterLocation.trim().toLocaleLowerCase()) >= 0
      );
    }
    if (filterLot && filterLot.trim()) {
      temp = temp.filter(
        (i) =>
          i.lotNumber
            .toLocaleLowerCase()
            .indexOf(filterLot.trim().toLocaleLowerCase()) >= 0
      );
    }
    if (filterUom && filterUom.trim()) {
      temp = temp.filter(
        (i) =>
          i.uom
            .toLocaleLowerCase()
            .indexOf(filterUom.trim().toLocaleLowerCase()) >= 0
      );
    }
    setFilterData([...temp]);
  }, [dataSource, filterLot, filterLocation, filterUom]);

  // const handleCodeChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     const {
  //       target: { value },
  //     } = e;
  //     if (!value) {
  //       setData(dataSource);
  //     }
  //     const newData = dataSource.filter((item) => {
  //       const { locationCode } = item;
  //       if (
  //         locationCode.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >=
  //         0
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     });
  //     setData(newData);
  //   },
  //   [dataSource]
  // );
  useEffect(() => {
    if (sku && warehouseNum) {
      getInventory();
    }
  }, [sku, warehouseNum, getInventory]);

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button onClick={onHide} icon={<CloseOutlined />}>
            Cancel
          </Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Search Location"
      centered
      visible={visible}
      width={900}
      destroyOnClose
      onCancel={onHide}
      bodyStyle={{ paddingBottom: 0 }}
      footer={<Footer />}
    >
      <Form>
        <Form.Item>
          <Row align="middle">
            <Col span={3}>
              <Row justify="end">
                <FormLabel>SKU</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={20}>
              <Text strong>{sku}</Text>
            </Col>
          </Row>
        </Form.Item>
        {lotEnable && (
          <Form.Item>
            <Row align="middle">
              <Col span={3}>
                <Row justify="end">
                  <FormLabel>LOT#</FormLabel>
                </Row>
              </Col>
              <Col span={1} />
              <Col span={20}>
                <Input
                  style={{ width: 200 }}
                  placeholder="LOT#"
                  onChange={(e) => setFilterLot(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        {uomEnable && (
          <Form.Item>
            <Row align="middle">
              <Col span={3}>
                <Row justify="end">
                  <FormLabel>UOM</FormLabel>
                </Row>
              </Col>
              <Col span={1} />
              <Col span={20}>
                <Input
                  style={{ width: 200 }}
                  placeholder="UOM"
                  onChange={(e) => setFilterUom(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Item>
        )}
        <Form.Item>
          <Row align="middle">
            <Col span={3}>
              <Row justify="end">
                <FormLabel>Location</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={20}>
              <Input
                defaultValue={filterLocation}
                style={{ width: 200 }}
                placeholder="location code"
                onChange={(e) => setFilterLocation(e.target.value)}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Text>{`Total Items:${filterData.length}`}</Text>
          <DataGridPlus
            style={{ height: 490 }}
            idProperty="code"
            dataSource={filterData}
            loading={loading}
            columns={columns}
            pagination="local"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
