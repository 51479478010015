import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Input, Row } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { ThemeContext } from 'styled-components';

export enum NumberType {
  PositiveNumber = 1,
  NaturalNumber = 2,
  Integer = 3,
}
type InputIntegerProps = {
  type?: NumberType;
  value?: string;
  width?: number;
  disabled?: boolean;
  onChange?: (value: string) => void;
  id?: string;
  isTabwork?: boolean;
  oriValue?:number
};
export default function InputInteger(props: InputIntegerProps): JSX.Element {
  const themeContext = useContext(ThemeContext);
  const fontSize = themeContext ? themeContext.fontSize : 14;
  const [value, setValue] = useState<string>();
  const [plus, setPlus] = useState<boolean>(false);
  const inputRef = React.useRef<any>(null);
  const { onChange, value: oriValue, width, disabled, type: oriType, id, isTabwork } = props;
  let type = oriType;
  if (typeof type === 'undefined') {
    type = NumberType.PositiveNumber;
  }
  //set default value
  useEffect(() => {
    if (oriValue || `${oriValue}` === '0') {
      setValue(`${oriValue}`);
    } else {
      setValue('');
    }
  }, [oriValue]);
  /**
   * callback change
   */
  const callbackChange = useCallback(
    (newValue: string) => {
      if (typeof onChange === 'function') {
        onChange(newValue);
      }
    },
    [onChange]
  );
  /**
   * judge whether it is an integer
   * @param {string} str
   * @returns boolean
   */
  const isInteger = (str: string) => {
    const n = parseInt(str, 10);
    return n !== Infinity && `${n}` === str;
  };
  /**
   * judge whether it is an natural number
   * @param {string} str
   * @returns boolean
   */
  const isNaturalNumber = (str: string) => {
    const n = parseInt(str, 10);
    return n !== Infinity && `${n}` === str && n >= 0;
  };
  /**
   * judge whether it is an positive number
   * @param {string} str
   * @returns boolean
   */
  const isPositiveNumber = (str: string) => {
    const n = parseInt(str, 10);
    return n !== Infinity && `${n}` === str && n > 0;
  };
  /**
   * judge valid number
   */
  const isValid = useCallback(
    (newValue: string) => {
      if (type === NumberType.PositiveNumber) {
        return isPositiveNumber(newValue);
      } else if (type === NumberType.NaturalNumber) {
        return isNaturalNumber(newValue);
      } else {
        return isInteger(newValue);
      }
    },
    [type]
  );
  /**
   * handle change event
   */
  const handleChange = useCallback(
    (e: any) => {
      const { value: newValue } = e.target;
      console.log('value', newValue);

      callbackChange(newValue);

      // else {
      //   if (isValid(newValue)) {
      //     callbackChange(newValue);
      //   } else {
      //     callbackChange('');
      //   }
      // }
    },
    [callbackChange]
  );
  /**
   * handle substract event
   */
  const handleSubtract = useCallback(() => {
    if (value) {
      const newValue = parseInt(value, 10) - 1;
      if (isValid(`${newValue}`)) {
        callbackChange(`${newValue}`);
      }
    }
  }, [value, callbackChange, isValid]);
  /**
   * handle add event
   */
  const handleAdd = useCallback(() => {
    if (value) {
      const newValue = parseInt(value, 10) + 1;
      if (isValid(`${newValue}`)) {
        callbackChange(`${newValue}`);
      }
    } else {
      callbackChange('1');
    }
  }, [value, callbackChange, isValid]);
  let newWidth = width || 100;
  if (disabled) {
    newWidth += 60;
  }
  let style: StringKAnyVPair = { width: newWidth };
  if (!disabled) {
    style = {
      ...style,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
    };
  }
  return (
    <Row>
      <Input
        id={id}
        tabIndex={isTabwork ? 0 : -1}
        ref={inputRef}
        style={{ ...style, fontSize }}
        value={value}
        onBlur={() => {
          const newValue = inputRef.current?.state.value;
          if (isValid(newValue)) {
            callbackChange(newValue);
          } else {
            callbackChange(type === 1 ? '1' : '0');
          }
        }}
        disabled={disabled}
        onChange={handleChange}
        onFocus={() => inputRef.current?.select()}
        maxLength={9}
      />
      {!disabled && (
        <Button
          tabIndex={isTabwork ? 0 : -1}
          style={{ borderRadius: 0, height: 'auto' }}
          onClick={handleSubtract}
          icon={<MinusOutlined style={{ fontSize }} />}
        />
      )}
      {!disabled && (
        <Button
          tabIndex={isTabwork ? 0 : -1}
          style={{
            borderTopLeftRadius: 0,
            borderLeftWidth: plus ? 1 : 0,
            borderBottomLeftRadius: 0,
            height: 'auto',
          }}
          onMouseEnter={() => {
            setPlus(true);
          }}
          onMouseLeave={() => {
            setPlus(false);
          }}
          onClick={handleAdd}
          icon={<PlusOutlined style={{ fontSize }} />}
        />
      )}
    </Row>
  );
}
InputInteger.defaultProps = {
  type: undefined,
  value: undefined,
  width: undefined,
  disabled: undefined,
  onChange: undefined,
};
