import React, { useState, useCallback } from 'react';
import ScanSKU from './ScanSKU';
import ScanLocation from './ScanLocation';
import ScanQty from './ScanQty';
import { SetDefaultWarehouseDialog } from 'components/common';
import { fetchPickingBySKU } from 'services/smart';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache, getProfileSettingValue } from 'utils';

export default function PickList(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [locations, setLocations] = useState<any[]>([]);
  const [product, setProduct] = useState<SmartPickSKU>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [step, setStep] = useState<'scanSKU' | 'scanLocaiton' | 'scanQty'>(
    'scanSKU'
  );
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [uomEnable, setUomEnable] = useState(true);
  const [lotEnable, setLotEnable] = useState(true);
  const [lotNumberList, setLotNumberList] = useState<string[]>([])
  const [defaultLotNumber, setDefaultLotNumber] = useState<string>()

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  const getSettings = useCallback(async()=>{
    const res1 = await getProfileSettingValue('EnableLOT', '0')
    const res2 = await getProfileSettingValue('EnableUOM','0')
      setLotEnable(res1 === '1')
      setUomEnable(res2 === '1')
  },[])

  const fetchLocations = async () => {
    try {
      const res = await fetchWarehouseLocationList(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        ),
        false
      );
      setLocations(res);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshProduct = useCallback(async () => {
    try {
      const res = await fetchPickingBySKU(
        `${product?.sku}_${product?.pickListNum}_${product?.uom}`
      );

      if (res.isSuccess) {
        setProduct(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [product]);

  const initLotNumbers = useCallback(()=>{
    if(product  && location){
      const temp = product.location.filter(i => i.uom === product.uom && i.locationCode === location.locationCode && i.lotNumber).map(k=>k.lotNumber)
      setLotNumberList(temp)
      setStep('scanQty');
    }
  },[product, location])

  React.useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      fetchLocations();
    }
  }, []);

  React.useEffect(() => {
    getSettings()
  },[getSettings])

  React.useEffect(() => {
    initLotNumbers()
  },[location, initLotNumbers])

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanSKU' && (
        <ScanSKU
          onSubmit={(nextProduct: SmartPickSKU) => {
            setProduct(nextProduct);
            setStep('scanLocaiton');
          }}
        />
      )}
      {step === 'scanLocaiton' && product && (
        <ScanLocation
          uomEnable={uomEnable}
          lotEnable={lotEnable}
          product={product}
          locations={locations}
          warehouseId={getWarehouseIdFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}
          onSubmit={(nextLocation, inventory) => {
            if(inventory && inventory.lotNumber){
              setDefaultLotNumber(inventory.lotNumber)
            }
            setLocation(nextLocation);
          }}
          onBack={() => {
            setProduct(undefined);
            setStep('scanSKU');
          }}
        />
      )}
      {step === 'scanQty' && product && location && (
        <ScanQty
          uomEnable={uomEnable}
          lotEnable={lotEnable}
          product={product}
          location={location}
          lotNumbers={lotNumberList}
          defaultLotNumber={defaultLotNumber}
          lotControl={product.lotControl || 0}
          onSubmit={(nextProduct: SmartPickSKU) => {
            setLocation(undefined);
            setDefaultLotNumber(undefined)
            setProduct(nextProduct);
            setStep('scanLocaiton');
          }}
          onBack={(nextStep) => {
            setLocation(undefined);
            setDefaultLotNumber(undefined)
            if (nextStep !== 'scanLocaiton') {
              setProduct(undefined);
            }
            if (nextStep === 'scanLocaiton') {
              refreshProduct();
            }
            setStep(nextStep);
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
