/**
 * store setup service requests are here.
 * 
 */
import { digitBridgeApi, digitBridgePrinterApi } from './http';

export const fetchStoreList = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/ChannelAccounts');
};

export const getStoreByID = async (id: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${id}`);
};

interface updateStoreInfoParams {
  ChannelAccountNum: number,
  ChannelAccountName: string,
  TimeZone: string,
  Status: number,
  DropShip: number,
  CompanyName: string,
  Phone: string,
  Email: string,
  // Logo: string,
  WebSite: string,
  BillToType: number,
  // PackingSlipTemplateNum: number,
  // PackingSlipFooter: string,
  PrintTemplateNum: number,
  AllowSplitOrder: number,
  // IsPrintPackingSlip: number,
  IsLabelSeparately: number,
  IsHandleLabelSeparately: number,
  IsRequireInsertItem: number,
  IsAllowOrderImport: number,
  ImportFileFormat: string,
  RetailerId: string,
  OnlyMappedServiceAndPackage: number,
  EnableSync: number,
  SendShipmentsOneBatch: number,
  RequireSmartVerify: number,
  PreassignedShippingPackage: number,
  IsDisplayPrice?: number,
  FollowProductSeparatelyRule?: number,
  EnableCarrierDeclareValue?: number,
  HideFromSelection:number,
}
export const updateStoreInfo = async (params: updateStoreInfoParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateChannelAccount', json);
};

interface updatePackageAndServiceShipAccountParams {
  ChannelAccountServiceNum: number,
  Name: string,
  ChannelAccountNum: number,
  ShipAccountNum: number,
  CarrierNum?: number,
  Carrier?: string,
  CarrierServiceNum: number,
  Service: string,
  Package?: string,
  SignatureRequired?: number,
  SaturdayDelivery: number,
  PackageNum?: number,
  WarehouseNum: number,
  ChannelAccountBillThirdPartyNum?: number,
  // BillType: number,
}

export const updatePackageAndServiceShipAccount = async (params: updatePackageAndServiceShipAccountParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdatePackageAndServiceShipAccount', json);
};


export const delShipServiceById = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/ChannelAccountServices/${id}`);
};

export const updateChannelAccountStatus = async (id: number, status: number): Promise<any> => {
  const json = JSON.stringify({
    ChannelAccountNum: id,
    Status: status
  })
  return await digitBridgeApi.post('/api/UpdateChannelAccountStatus', json);
};

export const fetchPackageAndServiceList = async (shipAccountNum: number, channelAccountNum: number): Promise<any> => {
  const params = {
    shipAccountNum,
    channelAccountNum
  }
  return await digitBridgeApi.get('/api/GetPackageAndServiceList', { params });
};

export const fetchChannelAccountShipAccountList = async (channelAccountNum: number): Promise<any> => {
  const params = {
    channelAccountNum
  }
  return await digitBridgeApi.get('/api/GetChannelAccountShipAccountList', { params });
};

type ChannelShipParams = {
  ChannelAccountShipAccountNum: number;
  ChannelAccountNum: number;
  WarehouseNum: number;
  ShipAccountNum: number;
  IsDefault: number;
}

export const addChannelAccountShipAccount = async (params: ChannelShipParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/AddChannelAccountShipAccount', json);
};

export const updateChannelAccountShipAccount = async (params: ChannelShipParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateChannelAccountShipAccount', json);
};

export const delChannelAccountShipAccount = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/channelAccountShipAccounts/${id}`);
};

export const exportShipAccountToChannelAccount = async (channelAccountNum: number): Promise<any> => {
  const params = {
    channelAccountNum
  }
  return await digitBridgeApi.get('/api/ExportShipAccountToChannelAccount', { params });
};


export const CopyChannelServiceToChannelAccountService = async (channelAccountNum: number, warehouseNum: number, shipAccountNum: number): Promise<any> => {
  const params = {
    channelAccountNum,
    warehouseNum,
    shipAccountNum
  }
  return await digitBridgeApi.get('/api/CopyChannelServiceToChannelAccountService', { params });
};

type ChannelAccountInsertParams = {
  ChannelAccountInsertItemNum: number,
  InsertItemName: string,
  IsRemindUser: number,
  InsertItemPictureLink: string,
  ChannelAccountNum: number,
  WarehouseNum: number,
  InsertItemNote: string,
}
export const addChannelAccountInsertItem = async (params: ChannelAccountInsertParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/AddChannelAccountInsertItem', json)
}

export const delChannelAccountInsertItemById = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/channelAccountInsertItems/${id}`)
}

export const getChannelAccountInsertItem = async (channelAccountNum: number): Promise<any> => {
  const params = {
    channelAccountNum
  }
  return await digitBridgeApi.get('/api//GetChannelAccountInsertItemList', { params })
}

export const updateChannelAccountInsertItem = async (params: ChannelAccountInsertParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/UpdateChannelAccountInsertItem', json)
}

export const getShipAccountListWithShoppingAround = async (channelAccountNum: number, warehouseNum: number): Promise<any> => {
  const params = {
    channelAccountNum,
    warehouseNum
  }
  return await digitBridgeApi.get('/api/GetShipAccountListWithShoppingAround', { params })
}

// type CreateChannelAccountParams = {
//   ChannelName: string,
//   ChannelAccountName: string,
//   CentralChannelNum: number,
//   CentralChannelAccountNum: number,
//   CompanyName: string,
//   Logo: string,
//   WebSite: string,
//   Phone: string,
//   Email: string,
//   ReturnPolicy: string,
//   TimeZone: string,
//   BillToType: number,
//   AllowSplitOrder: number,
//   PackingSlipTemplateNum: number,
//   PackingSlipFooter: string,
//   Note: string,
//   Status: number,
//   IsPrintPackingSlip: number,
//   IsLabelSeparately: number,
//   IsHandleLabelSeparately: number,
//   IsRequireInsertItem: number,
//   IsAllowOrderImport: number,
//   ImportFileFormat: string,
//   DropShip: number,
//   PrintTemplateNum: number,
//   NotUseProductSignature: number,
//   RetailerId: string
// }
export const createChannelAccount = async (params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/channelAccounts', json)
}

export const deleteChannelAccount = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`api/ChannelAccount/${channelAccountNum}`)
}

export const syncChannelAccount = async (): Promise<any> => {
  return await digitBridgeApi.get('/api/channelAccountSync')
}

export const getSlipList = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/packingslip`)
}
type PostSlipParams = {
  channelAccountPackingSlipNum: number,
  centralChannelNum: number,
  channelAccountNum: number,
  salesDivision: string,
  packingSlipFooter: string,
  logo: string,
  packingSlipTemplateNum: number,
  isPrintPackingSlip: number,
}
export const postSlip = async (params: PostSlipParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/ChannelAccounts/${params.channelAccountNum}/packingslip`, json)
}
export const deleteSlip = async (channelAccountNum: number, packingslipNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`api/ChannelAccounts/${channelAccountNum}/packingslip/${packingslipNum}`)
}
export const copySlip = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/packingslip/copy`)
}

type PreviewSlipParams = {
  ChannelAccountPackingSlipNum: number;
  PackingSlipTemplateNum: number;
  Footer: string;
  Logo: string;
  Time: string;
}

export const previewSlipTemplate = async (params: PreviewSlipParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgePrinterApi.post('api/PackingSlip/PreviewTemplate', json)
}

export const getCartonLableList = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/cartonlabel`)
}

type PostCartonLableParams = {
  channelAccountCartonLabelNum: number,
  centralChannelNum: number,
  channelAccountNum: number,
  salesDivision: string,
  cartonLabelFooter: string,
  logo: string,
  cartonLabelTemplateNum: number,
  isPrintCartonLabel: number,
}
export const postCartonLable = async (params: PostCartonLableParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/ChannelAccounts/${params.channelAccountNum}/cartonlabel`, json)
}

export const deleteCartonLable = async (channelAccountNum: number, cartonLabelNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`api/ChannelAccounts/${channelAccountNum}/cartonlabel/${cartonLabelNum}`)
}

type previewCartonLabelParams = {
  ChannelAccountCartonLabelNum: number;
  CartonLabelTemplateNum: number;
  ChannelAccountNum: number;
  Footer: string;
  Logo: string;
  Time: string;
}

export const previewCartonLabel = async (params: previewCartonLabelParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgePrinterApi.post('api/CartonLabel/PreviewTemplate', json)
}

export const fetchChannelAccountSettings = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`api/channelaccounts/${channelAccountNum}/settings`);
}


type SaveChannelAccountSettingsParamsItem =
    {
        SettingCode: string,
        SettingValue: string,
        ChannelAccountNum: number,
    }
export const saveChannelAccountSettings = async (channelAccountNum: number, params: SaveChannelAccountSettingsParamsItem[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`api/channelaccounts/${channelAccountNum}/settings`, json);
}

export const fetchSettings = async (channelNum: number): Promise<any> => {
  return await digitBridgeApi.get(`api/channelAccounts/${channelNum}/defsettings`);
}

export const getGroupSlipList = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/grouppackingslip`)
}
type PostGroupSlipParams = {
  channelAccountPackingSlipNum: number,
  centralChannelNum: number,
  channelAccountNum: number,
  salesDivision: string,
  packingSlipFooter: string,
  logo: string,
  packingSlipTemplateNum: number,
  isPrintPackingSlip: number,
}
export const postGroupSlip = async (params: PostGroupSlipParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/ChannelAccounts/${params.channelAccountNum}/grouppackingslip`, json)
}
export const deleteGroupSlip = async (channelAccountNum: number, packingslipNum: number): Promise<any> => {
  return await digitBridgeApi.delete(`api/ChannelAccounts/${channelAccountNum}/grouppackingslip/${packingslipNum}`)
}
export const copyGroupSlip = async (channelAccountNum: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/ChannelAccounts/${channelAccountNum}/grouppackingslip/copy`)
}

type PreviewGroupSlipTemplateParams = {
  ChannelAccountPackingSlipNum: number;
  PackingSlipTemplateNum: number;
  Footer: string;
  Logo: string;
  Time: string;
}

export const previewGroupSlipTemplate = async (params: PreviewGroupSlipTemplateParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgePrinterApi.post('api/GroupPackingSlip/PreviewTemplate', json)
}

type PreviewGroupSlipParams = {
  fulfillmentOrderNum: number;
  shipmentNums: string;
  Time: string;
}

export const previewGroupSlip = async (params: PreviewGroupSlipParams): Promise<any> => {
  return await digitBridgePrinterApi.get('api/GroupPackingSlip/Preview', { params })
}

export const exportGroupSlip = async (params: PreviewGroupSlipParams): Promise<any> => {
  return await digitBridgePrinterApi.get('api/GroupPackingSlip/Export', { params, responseType: 'blob' })
}
