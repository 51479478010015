import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button,
  Select,
  Row,
  Input,
  Card,
  Form,
  Col,
  DatePicker,
  Space,
} from 'antd';
import { fetchShipmentVoidHistory } from 'services/reports';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import {
  HeaderTitle,
  DataGridPlus,
  GreyCardWrapper,
  FormLabel,
  ColumnDataType,
  SelectWarehouse,
  TablesFilter,
} from 'components/common';
import { fetchCarriers } from 'services/shipment';
import { convertToUtcTime, trimValues } from 'utils';
import moment from 'moment';

const { RangePicker } = DatePicker;

const inventoryUpdateEnum: { [key: string]: string } = {
  '0': 'Not Updated',
  '1': 'Updated',
  '255': 'Processing',
};

const carrierVoidedEnum: { [key: string]: string } = {
  1: 'Carrier Voided',
  0: 'Skip Carrier Void',
};

// eslint-disable-next-line
export default () => {
  const [whiteList, setWhiteList] = useState<BrandRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [carriers, setCarriers] = useState<any[]>([]);
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const [tempParams, setTemParams] = useState<any>();
  const [sortInfo, setSortInfo] = React.useState<any>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const sortId = useRef('');
  const sortStatus = useRef(false);

  const [form] = Form.useForm();
  //   const [current, setCurrent] = useState<BrandRow>();

  const columns = [
    {
      name: 'shipmentVoidLogNum',
      header: 'ID',
      defaultVisible: false,
    },
    {
      name: 'carrier',
      header: 'Carrier',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'trackingNumber',
      header: 'Tracking #',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'createDate',
      header: 'Void Date',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
    },
    {
      name: 'channelOrderId',
      header: 'Channel Order ID',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'shipmentId',
      header: 'Shipment ID',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'carrierVoided',
      header: 'Carrier Voided',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: carrierVoidedEnum,
      render: (row: any) => {
        const { data } = row;
        return carrierVoidedEnum[data.carrierVoided];
      }
    },
    {
      name: 'createBy',
      header: 'Voided By',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'inventoryUpdate',
      header: 'Inventory Update Status',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: inventoryUpdateEnum,
      render: (value: any) => {
        const { data } = value;
        return inventoryUpdateEnum[`${data.inventoryUpdate as string}`];
      },
    },
  ];

  const handleSearch = async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    setLoading(true);
    const params = trimValues(await form.getFieldsValue());
    const queryParams = {
      ShipmentId: params.shipmentId,
      ChannelOrderId: params.channelOrderId,
      TrackingNumber: params.trackingNumber,
      CarrierNum: params.carrierNum,
      InventoryUpdate: params.inventoryUpdate,
      CarrierVoided: params.CarrierVoided,
      VoidBy: params.voidBy,
      Start: params.voidDate
        ? convertToUtcTime(params.voidDate[0].startOf('day').format())
        : undefined,
      End: params.voidDate
        ? convertToUtcTime(params.voidDate[1].endOf('day').format())
        : undefined,
      Sorts: sortId.current
        ? [
          {
            property: sortId.current,
            direction: sortStatus.current ? 'asc' : 'desc',
          },
        ]
        : [],
    };
    if (queryParams.CarrierVoided === '-1') {
      delete queryParams.CarrierVoided;
    }
    try {
      const res = await fetchShipmentVoidHistory(
        params.warehouseNum.warehouseNum || params.warehouseNum.id,
        1,
        limitRef.current,
        queryParams
      );
      setLoading(false);
      if (res.isSuccess) {
        setWhiteList(res.data.data);
        setTotal(res.data.count);
        setTemParams(params);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      setLoading(true);
      const queryParams = {
        ShipmentId: tempParams.shipmentId,
        ChannelOrderId: tempParams.channelOrderId,
        TrackingNumber: tempParams.trackingNumber,
        CarrierNum: tempParams.carrierNum,
        InventoryUpdate: tempParams.inventoryUpdate,
        CarrierVoided: tempParams.CarrierVoided,
        VoidBy: tempParams.voidBy,
        Start: tempParams.voidDate
          ? convertToUtcTime(tempParams.voidDate[0].startOf('day').format())
          : undefined,
        End: tempParams.voidDate
          ? convertToUtcTime(tempParams.voidDate[1].endOf('day').format())
          : undefined,
        Sorts: sortId.current
          ? [
            {
              property: sortId.current,
              direction: sortStatus.current ? 'asc' : 'desc',
            },
          ]
          : [],
      };
      if (queryParams.CarrierVoided === '-1') {
        delete queryParams.CarrierVoided;
      }
      try {
        const res = await fetchShipmentVoidHistory(
          tempParams.warehouseNum.warehouseNum || tempParams.warehouseNum.id,
          skip / limit + 1,
          limit,
          queryParams
        );
        setLoading(false);
        if (res.isSuccess) {
          setWhiteList(res.data.data);
          setTotal(res.data.count);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    },
    [tempParams]
  );

  const getCarrierList = async () => {
    try {
      const res = await fetchCarriers();
      setCarriers(res);
    } catch (error) { }
  };
  /* eslint-disable */
  useEffect(() => {
    getCarrierList();
  }, []);
  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Shipment Void History']} />
      <Card style={{ width: '100%', minWidth: 400 }}>
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <FormLabel>Warehouse</FormLabel>
              <Form.Item name="warehouseNum">
                <SelectWarehouse />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Carrier</FormLabel>
              <Form.Item name="carrierNum">
                <Select allowClear>
                  {carriers.map((item) => (
                    <Select.Option
                      key={item.carrierNum}
                      value={item.carrierNum}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Tracking Number</FormLabel>
              <Form.Item
                name="trackingNumber"
              // initialValue={[
              //   moment().add(-3, 'days').startOf('day'),
              //   moment().endOf('day'),
              // ]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Void Date</FormLabel>
              <Form.Item
                name="voidDate"
                initialValue={[
                  moment().add(-7, 'days').startOf('day'),
                  moment().endOf('day'),
                ]}
              >
                <RangePicker
                  allowClear
                  style={{ width: '100%' }}
                  format="MM/DD/YYYY"
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Channel Order ID</FormLabel>
              <Form.Item name="channelOrderId">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Shipment ID</FormLabel>
              <Form.Item name="shipmentId">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Voided by</FormLabel>
              <Form.Item name="voidBy">
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Inventory Update Status</FormLabel>
              <Form.Item name="inventoryUpdate">
                <Select>
                  <Select.Option value="0">Not Updated</Select.Option>
                  <Select.Option value="1">Updated</Select.Option>
                  <Select.Option value="255">Processing</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Void Type</FormLabel>
              <Form.Item name="CarrierVoided">
                <Select defaultValue={'-1'}>
                  <Select.Option value="-1">All</Select.Option>
                  <Select.Option value="0">Skip Carrier Void</Select.Option>
                  <Select.Option value="1">Carrier Voided</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="center" align="middle" style={{ marginTop: 16 }}>
          <Space>
            <Button
              loading={loading}
              type="primary"
              onClick={() => {
                setSortInfo({});
                handleSearch();
              }}
            >
              <SearchOutlined />
              <span>Search</span>
            </Button>
            <Button onClick={() => form.resetFields()}>
              <ClearOutlined />
              Reset
            </Button>
          </Space>
        </Row>
      </Card>

      <GreyCardWrapper
        style={{
          marginTop: 20,
        }}
      >
        <TablesFilter
          dataSource={whiteList}
          columns={columns}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="shipmentVoidLogNum"
          showScrollButton
          columns={columns}
          count={total}
          dataSource={filteredData}
          loading={loading}
          skip={skipRef.current}
          autoWith={false}
          pageSizes={[10, 50, 200, 500, 1000]}
          pagination
          onChange={(limit, skip) => {
            limitRef.current = limit;
            skipRef.current = skip;
            handleDataGridChange(limit, skip)
          }}
          sortInfo={sortInfo}
          onSortInfoChange={async (v: any) => {
            setSortInfo(v);
            if (v) {
              if (v.dir === 1) {
                sortId.current = v.id;
                sortStatus.current = true;
                handleSearch();
              }
              if (v.dir === -1) {
                sortId.current = v.id;
                sortStatus.current = false;
                handleSearch();
              }
            } else {
              sortId.current = '';
              sortStatus.current = false;
              handleSearch();
            }
          }}
          // pagination="local"
          {...({} as any)}
        />
      </GreyCardWrapper>
    </>
  );
};
