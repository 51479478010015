/**
 * Transaction Type related service requests are here.
 */
import { digitBridgeApi } from './http';
/**
 * Fetch  transaction type list
 */
export const fetchTransactionTypeList = async (): Promise<
  Array<TransactionTypeRow>
> => {
  const list = (await digitBridgeApi.get('/api/transactionType')) as any;
  return list.map((item: any) => {
    return {
      id: item.value,
      name: item.name,
      usage: item.usage,
    };
  });
};
