import { Button, Row, Space, Form, DatePicker, Input, Modal } from 'antd';
import React, { useCallback, useState } from 'react';
import { FormLabel } from 'components/common';
import SelectOrderStatus from '../SelectOrderStatus';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

type ChangeStatusDialogProps = {
  onHide: () => void;
  onSubmit: (status: number) => Promise<void>;
};
/**
 * Returns Change Status Dialog Component
 * @param {ChangeStatusDialogProps} props
 * @returns {JSX.Element}
 */
export default function ChangeStatusDialog(
  props: ChangeStatusDialogProps
): JSX.Element {
  const { onHide, onSubmit } = props;
  const [status, setStatus] = useState('');
  const [form] = Form.useForm();
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    onSubmit(form.getFieldValue('status'));
  }, [form, onSubmit]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="save_btn" type="primary" onClick={handleSubmit} icon={<SaveOutlined />}>
            Save
          </Button>
          <Button id="cancel_btn" onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Change Orders Status"
      visible
      onCancel={onHide}
      footer={<Footer />}
    >
      <Form
        form={form}
        onValuesChange={(changedValues) => {
          const { status } = changedValues;
          if (status) {
            setStatus(status);
          }
        }}
      >
        <Form.Item
          style={{ marginTop: 10 }}
          name="status"
          label={<FormLabel>Order Fulfillment Status</FormLabel>}
          rules={[{ required: true, message: 'Please Select Status!' }]}
        >
          <SelectOrderStatus showAll={false} />
        </Form.Item>
        {status === '8' && (
          <Form.Item
            style={{ marginTop: 10 }}
            name="expireDate"
            label={<FormLabel>Expire Date</FormLabel>}
            rules={[{ required: true, message: 'Please Select Expire date!' }]}
          >
            <DatePicker id="expireDate_picker" style={{ width: 255 }} />
          </Form.Item>
        )}
        {status === '16' && (
          <Form.Item
            style={{ marginTop: 10 }}
            name="reason"
            rules={[{ required: true, message: 'Please Input Reason!' }]}
          >
            <Input.TextArea id="reason_input" rows={4} placeholder="Reason for Cancellation" />
          </Form.Item>
        )}
        {status === '0' && (
          <Form.Item
            style={{ marginTop: 10 }}
            name="note"
            rules={[{ required: true, message: 'Please Input Notes!' }]}
          >
            <Input.TextArea id="notes_input" rows={4} placeholder="Notes" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}
