import { Row, Select, Space, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons'
import { FormLabel, HeaderTitle, SectionWrapper } from "components/common";
import React, { useEffect, useState } from "react";
import { fetchWarehouseList } from "services/warehouse";
import { FormVSpacer } from "components/common/styles/CustomPropsComponents";
import { LS_DEFAULT_WAREHOUSE_KEY, REGULAR_SECTION_VERTICAL_SPACING } from "constants/config";
import EditLocationDialog from "components/Warehouse/EditLocationDialog";
import { sortByNumber } from "utils";


const LocationManagement = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [warehouseList, setWarehouseList] = useState<WarehouseRow[]>([]);
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [clickIndex, setClickIndex] = useState<number>(0);

  const getWarehouseList = async () => {
    setLoading(true);
    try {
      const list = await fetchWarehouseList();
      if (list && Array.isArray(list)) {
        const warehouses = list.map((e) => {
          return {
            id: e.warehouseNum,
            code: e.warehouseCode,
            name: e.warehouseName,
            isExternal: e.external,
            ...e,
          };
        });
        const defaultWarehouseNum = Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY));
        if (!isNaN(defaultWarehouseNum)) setWarehouse(warehouses.find(w => w.id === defaultWarehouseNum));
        setWarehouseList(warehouses);
      }
    } catch (error) {
      console.log('error = ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getWarehouseList();
  }, []);

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Location Management']} />
      <SectionWrapper>
        <Row align="middle" justify="space-between">
          <Space>
            <FormLabel>Warehouse</FormLabel>
            <Select
              loading={loading}
              value={warehouse?.id}
              onChange={(e) =>
                setWarehouse(warehouseList.find((w) => w.id === e))
              }
              placeholder="Please select"
              options={warehouseList
                .sort((a: any, b: any) => sortByNumber(a, b, 'warehouseType'))
                .map((item) => {
                  return { label: item.code, value: item.id };
                })}
              style={{ width: 200 }}
            />
          </Space>
          <Space>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              block
              onClick={() => setClickIndex((prev) => prev + 1)}
            >
              Add Location
            </Button>
          </Space>
        </Row>
      </SectionWrapper>
      <FormVSpacer height={REGULAR_SECTION_VERTICAL_SPACING} />
      {warehouse && (
        <SectionWrapper>
          <EditLocationDialog
            clickAddLocation={clickIndex}
            warehouseId={warehouse?.warehouseId}
            warehouseNum={warehouse.id}
          />
        </SectionWrapper>
      )}
    </>
  );
}

export default LocationManagement;