import React, { useCallback, useState, useRef } from 'react';
import { Row, Card, Form, Col, DatePicker, Space, Button } from 'antd';
import { ClearOutlined, CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  ColumnDataType,
  FormLabel,
  HeaderTitle,
  // SetDefaultWarehouseDialog,
  SelectWarehouse,
  TablesFilter,
} from 'components/common';
// import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { fetchReceiveHistory } from 'services/reports';
import { convertToUtcTime } from 'utils';
import { userProfiles } from 'utils/auth';
import DataGridPlus from 'components/common/DataGrid';

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState<PoReceiveHistory[]>([]);
  const [count, setCount] = useState<number>(0);
  const [paramLimit, setParamLimit] = React.useState<number>(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const skipRef = useRef<number>(0);

  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM") === '1';
  // const [dialogClosable, setDialogClosable] = React.useState(true);
  // const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
  //   React.useState(false);

  // const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
  //   setDialogClosable(
  //     typeof config.closable === 'boolean' ? config.closable : true
  //   );
  //   setDefaultWarehouseVisible(true);
  // };

  // React.useEffect(() => {
  //   if (
  //     !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
  //     !getWarehouseCodeFromCache(
  //       Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  //     )
  //   ) {
  //     openSetDefaultWarehouseDialog({ closable: false });
  //   }
  // }, []);

  const [form] = Form.useForm();
  const columns = useCallback(() => {
    return [
      {
        dataType: ColumnDataType.DATE,
        name: 'transactionDateTime',
        header: 'Local Time',
        userSelect: true,
        // headerAlign: 'center' as 'center',
        minWidth: 110,
        defaultFlex: 1,
      },
      {
        name: 'sku',
        header: 'SKU',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        header: 'LOT#',
        name: 'lotNumber',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
        visible: enableLOT
      },
      {
        header: 'UOM',
        name: 'uom',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
        visible: enableUOM,
      },
      {
        header: 'Rate',
        name: 'uomRate',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
        visible: enableUOM,
      },
      {
        header: 'Base Qty',
        name: 'uomBaseQty',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
        visible: enableUOM,
      },
      {
        name: 'productTitle',
        header: 'Product Name',
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'quantity',
        header: 'Quantity',
        userSelect: true,
        resizable: false,
        textAlign: 'center',
        type: 'number',
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'qtyForOther',
        header: 'Quantity TO 3PL',
        resizable: false,
        userSelect: true,
        textAlign: 'center',
        type: 'number',
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'transactionQuantity',
        header: 'Total Receive',
        resizable: false,
        userSelect: true,
        textAlign: 'center',
        type: 'number',
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'batchNumber',
        header: 'Received Batch',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'serialNumber',
        header: 'Serial Number',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'receivedFrom',
        header: 'Received From',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'locationCode',
        header: 'Received To',
        defaultFlex: 1,
        userSelect: true,
        minWidth: 110,
      },
      {
        name: 'Unit Cost',
        header: 'Unit Cost',
        resizable: false,
        textAlign: 'center',
        userSelect: true,
        type: 'number',
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'user',
        header: 'User',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
    ];
  }, [enableLOT, enableUOM])

  const getHistory = useCallback(
    async (skip: number, top: number, ty: number) => {
      const { startDate, endDate, warehouse } = await form.getFieldsValue();
      let query: StringKAnyVPair = {
        $skip: ty ? 0 : skip,
        $top: ty ? 1000 : top,
        /*$filter: `TransactionDateTime ge '${moment(startDate).format(
          'YYYY-MM-DD'
        )}' and TransactionDateTime le '${moment(endDate).format(
          'YYYY-MM-DD'
        )}'`,*/
        $filter: `TransactionDateTime ge '${convertToUtcTime(
          startDate.format()
        )}' and TransactionDateTime le '${convertToUtcTime(endDate.format())}'`,
        type: ty,
        warehouseCode: warehouse.warehouseCode,
      };
      try {
        setLoading(true);
        const res = await fetchReceiveHistory(query);
        setLoading(false);
        if (ty) {
          const content = res;
          const blob = new Blob([content], { type: 'application/octet-stream' });
          const fileName = `ReceiveingHistory ${moment(startDate).format('MM-DD-YYYY')}~${moment(
            endDate
          ).format('MM-DD-YYYY')}.csv`;
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            //navigator.msSaveBlob(blob, fileName);
          }
        } else {
          setCount(res.count);
          setHistoryList(res.data);
        }
      } catch (err) {
        setLoading(false);
      }
    },
    [form]
  );

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Report',
          'Receiving History'
        ]}
      />
      <Row justify="center">
        <Card style={{ width: '100%', minWidth: 400 }}>
          <Form form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <FormLabel>Warehouse</FormLabel>
                <Form.Item name="warehouse">
                  <SelectWarehouse />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Start Date</FormLabel>
                <Form.Item
                  name="startDate"
                  initialValue={moment().subtract(1, 'days').startOf('day')}
                >
                  <DatePicker allowClear={false} style={{ width: '100%' }} format="MM/DD/YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>End Date</FormLabel>
                <Form.Item
                  name="endDate"
                  initialValue={moment().add(1, 'days').endOf('day')}
                >
                  <DatePicker allowClear={false} style={{ width: '100%' }} format="MM/DD/YYYY" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="center" style={{ marginTop: 16 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  skipRef.current = 0;
                  getHistory(0, paramLimit, 0)
                }
                }
              >
                <SearchOutlined />
                Search
              </Button>
              <Button onClick={() => getHistory(0, paramLimit, 1)}>
                <CloudDownloadOutlined />
                Export CSV
              </Button>
              <Button onClick={() => form.resetFields()}>
                <ClearOutlined />
                Reset
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
      <Card style={{ marginTop: 20 }}>
        <TablesFilter
          dataSource={historyList}
          columns={columns()}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="sku"
          skip={skipRef.current}
          showScrollButton
          autoWith={false}
          loading={loading}
          columns={columns()}
          pageSizes={[10, 20, 50, 100]}
          dataSource={filteredData}
          pagination
          style={{ height: 500 }}
          count={count}
          onChange={(limit, skip) => {
            setParamLimit(limit);
            // setParamSkip(skip);
            skipRef.current = skip;
            getHistory(skip, limit, 0);
          }}
          {...({} as any)}
        />
      </Card>
    </>
  );
};
