import {
  Button,
  Typography,
  Input,
  DatePicker,
  Form,
  Row,
  Modal,
  Col,
  Card,
  Space,
} from 'antd';
import React, { useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import {
  DataGridPlus,
  SectionWrapper,
  HeaderTitle,
  FormLabel,
  // message,
  ColumnDataType,
  ExportCSV,
  SelectWarehouse,
  TablesFilter,
} from 'components/common';
import {
  getPoReceiveBatchHistory,
  getPoReceiveBatchHistorySync,
} from 'services/purchaseOrder';
import { exportBusinessFile } from 'services/files';
import moment from 'moment';
import {
  // getWarehouseIdFromCache,
  convertToUtcTime,
  trimValues,
} from 'utils';
import { DEFAULT_US_DATE_FORMAT } from 'constants/config';
import { userProfiles } from 'utils/auth';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function LocationReport(): JSX.Element {
  const [history, setHistory] = useState<any[]>([]);
  const [syncHistory, setSyncHistory] = useState<any[]>([]);
  const [loading1, setLoading1] = useState<boolean>(false);
  const [loading2, setLoading2] = useState<boolean>(false);
  const [paramLimit, setParamLimit] = React.useState<number>(10);
  const [paramSkip, setParamSkip] = React.useState(0);
  const [paramLimit1, setParamLimit1] = React.useState<number>(10);
  const [paramSkip1, setParamSkip1] = React.useState(0);
  const [total, setTotal] = useState<number>(0);
  const [total1, setTotal1] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [filteredSyncData, setFilteredSyncData] = useState<any[]>([]);

  const sortString = useRef('CreateDate desc');
  const sortString1 = useRef('CreateDate desc');

  const [form] = Form.useForm();
  const routerHistory = useHistory();

  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM") === '1';

  const columns = [
    {
      header: 'Vendor Code',
      name: 'vendorCode',
      userSelect: true,
      defaultFlex: 1,
      sortable: true,
      showColumnMenuTool: true,
    },
    {
      header: 'PO Number',
      name: 'poNumber',
      defaultFlex: 1,
      userSelect: true,
      sortable: true,
      showColumnMenuTool: true,
    },
    {
      header: 'SKU',
      name: 'sku',
      defaultFlex: 1,
      minWidth: 220,
      userSelect: true,
      sortable: true,
      showColumnMenuTool: true,
    },
    {
      header: 'LOT#',
      name: 'lotNumber',
      userSelect: true,
      defaultFlex: 1,
      visible: enableLOT
    },
    {
      header: 'UOM',
      name: 'uom',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      header: 'Rate',
      name: 'uomRate',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      header: 'Base Qty',
      name: 'uomBaseQty',
      userSelect: true,
      defaultFlex: 1,
      visible: enableUOM,
    },
    {
      header: 'Fulfillment PO Line Num',
      name: 'fulfillmentPoLineNum',
      defaultFlex: 1,
      showColumnMenuTool: true,
      defaultVisible: false,
      sortable: true,
    },
    {
      header: 'Receive Qty',
      name: 'receiveQty',
      defaultFlex: 1,
      type: 'number',
      showColumnMenuTool: true,
    },
    {
      header: 'Batch Number',
      name: 'batchNumber',
      userSelect: true,
      defaultFlex: 1,
      showColumnMenuTool: true,
    },
    {
      header: 'Receive Date',
      name: 'receiveDate',
      defaultFlex: 1,
      sortable: true,
      userSelect: true,
      showColumnMenuTool: true,
      render: (row: any) => {
        const { data } = row;
        return moment(data.receiveDate).format(DEFAULT_US_DATE_FORMAT)
      }
    },
    {
      header: 'Enter Date',
      name: 'createDate',
      defaultFlex: 1,
      sortable: true,
      userSelect: true,
      dataType: ColumnDataType.DATE,
      showColumnMenuTool: true,
    },
  ];

  const syncedColumns = [
    ...columns,
    {
      header: 'Sync Date',
      name: 'updateDate',
      userSelect: true,
      dataType: ColumnDataType.DATE,
      defaultFlex: 1,
      showColumnMenuTool: true,
    },
  ];

  const getHistory = useCallback(
    async (skip: number, top: number) => {
      // const warehouseId = getWarehouseIdFromCache(
      //   Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      // );
      // if (!warehouseId) {
      //   return message.warning({ content: 'Not found default warehouse' });
      // }
      const params = form.getFieldsValue();
      try {
        const endDate = moment(params.closeTime[1].format('YYYY-MM-DD'));
        setLoading1(true);
        const res = await getPoReceiveBatchHistory(
          params.warehouse.warehouseId,
          {
            $skip: skip,
            $top: top,
            $sortBy: sortString.current,
            sku: params.sku ? params.sku : undefined,
            vendorCode: params.vendorCode ? params.vendorCode : undefined,
            poNumber: params.poNumber ? params.poNumber : undefined,
            batchNumber: params.batchNumber ? params.batchNumber : undefined,
            startDate: params.closeTime
              ? convertToUtcTime(params.closeTime[0].format(), '', '', 'YYYY-MM-DD')
              : undefined,
            endDate: params.closeTime
              ? convertToUtcTime(endDate.add(1, 'days').format(), '', '', 'YYYY-MM-DD')
              : undefined,
          }
        );
        setLoading1(false);
        if (res.isSuccess) {
          setHistory(res.data.data);
          setTotal(res.data.count);
        }
      } catch (error) {
        setLoading1(false);
      }
    },
    [form]
  );

  const getHistorySync = useCallback(
    async (skip: number, top: number) => {
      const params = form.getFieldsValue();
      try {
        const endDate = moment(params.closeTime[1].format('YYYY-MM-DD'));
        setLoading2(true);
        const res = await getPoReceiveBatchHistorySync(
          params.warehouse.warehouseId,
          {
            $skip: skip,
            $top: top,
            $sortBy: sortString.current,
            sku: params.sku ? params.sku : undefined,
            vendorCode: params.vendorCode ? params.vendorCode : undefined,
            poNumber: params.poNumber ? params.poNumber : undefined,
            batchNumber: params.batchNumber ? params.batchNumber : undefined,
            startDate: params.closeTime
              ? convertToUtcTime(params.closeTime[0].format(), '', '', 'YYYY-MM-DD')
              : undefined,
            endDate: params.closeTime
              ? convertToUtcTime(endDate.add(1, 'days').format(), '', '', 'YYYY-MM-DD')
              : undefined,
          }
        );
        setLoading2(false);
        if (res.isSuccess) {
          setSyncHistory(res.data.data);
          setTotal1(res.data.count);
        }
      } catch (error) {
        console.log(error);
        setLoading2(false);
      }
    },
    [form]
  );

  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    form.setFieldsValue({ ...trimValues(form.getFieldsValue()) });
    // const warehouseId = getWarehouseIdFromCache(
    //   Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    // );
    // if (!warehouseId) {
    //   return message.warning({ content: 'Not found default warehouse' });
    // }
    // setParamSkip(0);
    // setParamSkip1(0);
    // sortString.current = 'CreateDate desc';
    // sortString1.current = 'CreateDate desc';
    getHistory(paramSkip, paramLimit);
    getHistorySync(paramSkip1, paramLimit1);
  }, [
    form,
    paramSkip,
    paramLimit,
    paramSkip1,
    paramLimit1,
    getHistory,
    getHistorySync,
  ]);

  const downLoadAll = useCallback(
    async (SelectField) => {
      try {
        const params = form.getFieldsValue();
        const job = await exportBusinessFile(8, {
          FilterCondition: {
            warehouseId: params.warehouse.warehouseId,
            sku: params.sku ? params.sku : undefined,
            vendorCode: params.vendorCode ? params.vendorCode : undefined,
            poNumber: params.poNumber ? params.poNumber : undefined,
            batchNumber: params.batchNumber ? params.batchNumber : undefined,
            startDate: params.closeTime
              ? convertToUtcTime(params.closeTime[0].format())
              : undefined,
            endDate: params.closeTime
              ? convertToUtcTime(params.closeTime[1].format())
              : undefined,
          },
          SelectField,
          OrderByField: 'sku asc',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              routerHistory.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [routerHistory, form]
  );

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Report',
          'PO Receive Batch History'
        ]}
      />
      <Card style={{ width: '100%', minWidth: 400 }}>
        <Form form={form}>
          <Row gutter={16}>
            <Col span={8}>
              <FormLabel>Warehouse</FormLabel>
              <Form.Item name="warehouse">
                <SelectWarehouse />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Vendor Code</FormLabel>
              <Form.Item name="vendorCode">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>PO Number</FormLabel>
              <Form.Item name="poNumber">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>SKU</FormLabel>
              <Form.Item name="sku">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>PO Receive Batch Number</FormLabel>
              <Form.Item name="batchNumber">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormLabel>Receive Date</FormLabel>
              <Form.Item
                name="closeTime"
                initialValue={[moment().add(-1, 'month').startOf('day'), moment().add(1, 'day').endOf('day')]}
              >
                <RangePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  format={DEFAULT_US_DATE_FORMAT}
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="center" align="middle" style={{ marginTop: 16 }}>
          <Space>
            <Button
              loading={loading1 && loading2}
              type="primary"
              onClick={handleSearch}
            >
              <SearchOutlined />
              <span>Search</span>
            </Button>
            <Button onClick={() => form.resetFields()}>
              <ClearOutlined />
              Reset
            </Button>
          </Space>
        </Row>
      </Card>

      <SectionWrapper style={{ marginTop: 16 }}>
        <Row justify="space-between" align="middle" style={{ marginBottom: 4 }}>
          <Title level={5}>
            <Space size="middle">
              <span>
                Synced records
              </span>
              <TablesFilter
                dataSource={history}
                columns={columns}
                setFilteredData={setFilteredData}
              />
            </Space>
          </Title>
          <ExportCSV
            columns={columns}
            data={history}
            scope="syncedRecords"
            downloadFromApi={(data) => downLoadAll(data)}
          />
        </Row>
        <DataGridPlus
          style={{ minHeight: 500, zIndex: 100 }}
          showScrollButton
          columns={columns}
          autoWith={false}
          pageSizes={[10, 20, 50, 100]}
          count={total}
          columnUserSelect={true}
          dataSource={filteredData}
          loading={loading1}
          idProperty="id"
          pagination="remote"
          onSortInfoChange={async (v: any) => {
            if (v) {
              if (v.dir === 1) {
                sortString1.current = `${v.id} asc`;
                await getHistory(paramSkip, paramLimit);
              }
              if (v.dir === -1) {
                sortString1.current = `${v.id} desc`;
                await getHistory(paramSkip, paramLimit);
              }
            } else {
              sortString1.current = 'CreateDate desc';
              await getHistory(paramSkip, paramLimit);
            }
          }}
          showColumnMenuTool={false}
          onChange={(limit, skip) => {
            setParamLimit(limit);
            setParamSkip(skip);
            getHistory(skip, limit);
          }}
          {...({} as any)}
        />
        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: 10, marginBottom: 4 }}
        >
          <Title style={{ marginTop: 10 }} level={5}>
            <Space size="middle">
              <span>
                Records waiting to be synced
              </span>
              <TablesFilter
                dataSource={syncHistory}
                columns={syncedColumns}
                setFilteredData={setFilteredSyncData}
              />
            </Space>
          </Title>
          <ExportCSV
            columns={syncedColumns}
            data={syncHistory}
            scope="recordsWaitingToBeSynced"
          // downloadFromApi={() => {}}
          />
        </Row>

        <DataGridPlus
          style={{ minHeight: 500 }}
          showScrollButton
          columns={syncedColumns}
          autoWith={false}
          pageSizes={[10, 20, 50, 100]}
          count={total1}
          columnUserSelect={true}
          dataSource={filteredSyncData}
          loading={loading2}
          idProperty="id"
          pagination="remote"
          onChange={(limit, skip) => {
            setParamLimit1(limit);
            setParamSkip1(skip);
            getHistorySync(skip, limit);
          }}
          onSortInfoChange={async (v: any) => {
            if (v) {
              if (v.dir === 1) {
                sortString.current = `${v.id} asc`;
                await getHistorySync(paramSkip1, paramLimit1);
              }
              if (v.dir === -1) {
                sortString.current = `${v.id} desc`;
                await getHistorySync(paramSkip1, paramLimit1);
              }
            } else {
              sortString.current = 'CreateDate desc';
              await getHistorySync(paramSkip1, paramLimit1);
            }
          }}
          {...({} as any)}
        />
      </SectionWrapper>
    </>
  );
}
