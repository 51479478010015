import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import SmartPrepackComponent from 'components/Sales/Smart/Prepack';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartPrepack(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.prePack" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Prepack(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <SmartPrepackComponent />
    </ContentLayout>
  );
}
