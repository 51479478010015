import React, { useCallback, useEffect, useRef, useState } from 'react';
//import ReactDataGrid from '@inovua/reactdatagrid-community';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
//import { TypeDataGridProps } from '@inovua/reactdatagrid-community/types';
import { TypeDataGridProps } from '@inovua/reactdatagrid-enterprise/types';
import PaginationToolbar from '@inovua/reactdatagrid-community/packages/PaginationToolbar';
import '@inovua/reactdatagrid-enterprise/index.css';
import { DEFAULT_DATAGRID_ROW_NUM, LS_DATAGRID_CACHE_KEY } from 'constants/config';
import crc32 from 'crc-32';
import CheckboxColumn from './CheckboxColumn';
import LoadMask from './LoadMask';
import AutoHeight from './AutoHeight';
import RowContextMenu from './RowContextMenu';
import Columns from './Columns';
import copy from 'copy-text-to-clipboard';

//get real string width in dom
const computedTextWidth = (text: string) => {
  if (text) {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.fontSize = '14px';
    span.style.fontWeight = '700';
    span.style.fontFamily =
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
    span.style.display = 'inline-block';
    document.body.appendChild(span);
    span.innerHTML = text;
    const realWidth = span.offsetWidth;
    try {
      document.getElementsByTagName('body')[0].removeChild(span);
    } catch (error) {
      console.log(error);
    }
    return realWidth + 10;
  } else {
    return 0;
  }
};

const formatColumns = (columns: any[]) => {
  const tempColumns = columns.map((item: any) => {
    const realWidth = computedTextWidth(item.header);
    return item.unFormat
      ? item
      : {
        ...item,
        minWidth:
          item.minWidth > (item.sortable ? realWidth + 50 : realWidth + 30)
            ? item.minWidth
            : item.sortable
              ? realWidth + 50
              : realWidth + 30,
        textWidth: item.sortable ? realWidth + 20 : realWidth,
      };
  });
  return tempColumns;
};

/**
 * @param {number} count --datagrid count.support remote pagenation
 * @param {Array<string>} copyableColumns --copy menu appears automatically after selection
 * @param {Function} onChange --skip/limit change merged into onchange event
 * @param {boolean} autoWith --Whether to turn on automatic width
 */
interface DataGridPlusProps extends TypeDataGridProps {
  autoWith?: boolean;
  autoHeight?: boolean;
  count?: number;
  showScrollButton?: boolean;
  isGroupAble?: boolean;
  copyableColumns?: Array<string>;
  onChange?: (limit: number, skip: number) => void;
  rowContextMenu?: Function;
  boxStyle?: any;
  cacheKey?: string;
}

export enum ColumnDataType {
  CURRENCY = 1,
  DATE = 2,
  IMAGE = 3,
}

/**
 * Return ReactDataGridDataGrid Extend Component
 * @param {DataGridPlusProps} props
 * @returns {JSX.Element}
 */
export default function DataGridPlus(props: DataGridPlusProps): JSX.Element {
  const {
    dataSource,
    count,
    onChange,
    checkboxColumn,
    limit: oriLimit = DEFAULT_DATAGRID_ROW_NUM,
    showColumnMenuTool,
    skip: oriSkip,
    autoWith = true,
    pagination,
    showScrollButton,
  } = props;
  const [currentLimit, setCurrentLimit] = useState(oriLimit);
  const [currentSkip, setCurrentSkip] = useState(oriSkip || 0);
  const [gridRef, setGridRef] = useState<any>(null);
  const [pagerHandlerTimer, setPagerHandlerTimer] = useState(0);
  //set default name
  const gridName = `${crc32.str(
    JSON.stringify(
      props.columns.map((item: any) => {
        return {
          name: item.name || item.dateIndex,
          title: item.header || item.title,
        };
      })
    )
  )}`;
  // const tempColumns = props.columns.map((item: any) => {
  //   const realWidth = computedTextWidth(item.header);
  //   return {
  //     ...item,
  //     minWidth: item.sortable ? realWidth + 50 : realWidth + 30,
  //     textWidth: item.sortable ? realWidth + 20 : realWidth,
  //   };
  // });
  const [refreshed, setRefreshed] = useState(false);
  const columnsRef = useRef(Columns(formatColumns(props.columns), gridName));
  //exclude custom props
  const others = {} as any;
  const keys = Object.keys(props);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (
      !(
        key === 'count' ||
        key === 'copyableColumns' ||
        key === 'onChange' ||
        key === 'rowContextMenu' ||
        key === 'columns'
      )
    ) {
      const value = (props as any)[key];
      others[key] = value;
    }
  }

  //skip/limit change merged into onchange event
  const [limit, setLimit] = useState(oriLimit || DEFAULT_DATAGRID_ROW_NUM);
  const [skip, setSkip] = useState(props.skip || 0);

  //merge local/remote datasource
  const getDataSource = () => {
    return new Promise<any>((resolve) => {
      resolve({
        data: dataSource,
        count: count || (dataSource as any).length,
      });
    });
  };

  //pagenation extend
  let extendProps: StringKAnyVPair = {};
  extendProps = {
    onLimitChange: (value: number) => {
      if (typeof props.onLimitChange === 'function') {
        props.onLimitChange(value);
      } else {
        setLimit(value);
        // if ('function' === typeof onChange) {
        //   onChange(value, skip);
        // }
      }
    },
    onSkipChange: (value: number) => {
      if (typeof props.onSkipChange === 'function') {
        props.onSkipChange(value);
      } else {
        setSkip(value);
        // if ('function' === typeof onChange) {
        //   onChange(limit, value);
        // }
      }
    },
  };

  const onColumnVisibleChange = (evt: any) => {
    //console.log('ch ->', evt);
    const ck = props.cacheKey;
    const ds = localStorage.getItem(LS_DATAGRID_CACHE_KEY);

    if (ds && ck) {
      const { column, visible } = evt;
      try {
        const cd = JSON.parse(ds);
        const col: Record<string, any> = cd[ck] || {};
        const display: Record<string, any> = col.display || {};
        const k = column.name;

        if (k) {
          display[k] = visible ? undefined : false;
          col.display = display;
          cd[ck] = col;
          localStorage.setItem(LS_DATAGRID_CACHE_KEY, JSON.stringify(cd));
        } else {
          console.log('Key not exist in column:', column);
        }
      } catch (e) {
        console.log(`${visible ? 'Show' : 'Hide'} column error: ${e}`);
      }
    }
  };

  // @ts-ignore
  const findCellGridText = (p: any) => {
    // console.log('p = ', p);
    try {
      const res = p.children;
      const copyableType = ['string', 'number'];
      if (copyableType.includes(typeof p.cellText)) return p.cellText;
      if (copyableType.includes(typeof p.value)) return p.value;
      if (copyableType.includes(typeof p.text)) return p.text;
      if (res) {
        if (copyableType.includes(typeof res)) return res;
        if (typeof res === 'object' && res.props) {
          return findCellGridText(res.props)
        };
        if (res.isGrid && copyableType.includes(typeof res.value)) return res.value;
      }
    } catch (error) {
      console.log('error = ', error);
    }
  }
  const renderRowContextMenu = useCallback((menuProps: any, context: any) => {
    const { rowProps, cellProps } = context;
    menuProps.autoDismiss = true;

    RowContextMenu(props, menuProps, context);
    if (typeof props.rowContextMenu === 'function') {
      props.rowContextMenu(menuProps, context);
    }

    const text = findCellGridText(cellProps.render(rowProps).props);

    menuProps.items.push(
      {
        label: 'Copy This Cell',
        onClick: () => {
          if (typeof cellProps.render === 'function' && text) copy(text.toString());
        },
        disabled: !text
      },
      // {
      //   label: 'Copy All to Excel',
      //   // todo
      // },
    )
    // eslint-disable-next-line
  }, [findCellGridText])

  useEffect(() => {
    if (typeof onChange === 'function') {
      if (limit !== currentLimit || skip !== currentSkip) {
        setCurrentLimit(limit);
        setCurrentSkip(skip);

        if (pagerHandlerTimer) {
          window.clearTimeout(pagerHandlerTimer);
          setPagerHandlerTimer(0);
        }

        setPagerHandlerTimer(window.setTimeout(() => {
          onChange(limit, skip);
          setPagerHandlerTimer(0);
        }, 800));
      }
    }
  }, [currentLimit, currentSkip, limit, onChange, skip, pagerHandlerTimer]);

  useEffect(() => {
    if (typeof oriSkip === 'number') {
      setSkip(oriSkip);
    }
  }, [oriSkip]);

  //auto width
  const handleResetColumnWidth = useCallback(() => {
    const columns = columnsRef.current;
    const diff = checkboxColumn ? 1 : 0;
    columns.forEach((column, index) => {
      // let minWidth = 20 * (column.header.length / 2);
      let minWidth = 0;
      for (let i = 0; i < 10; i++) {
        const elem = document.getElementById(
          `cell${gridName}${i}${index + diff}`
        ) as any;
        if (elem) {
          const { offsetWidth } = elem;
          if (minWidth < offsetWidth + 20) {
            minWidth = offsetWidth + 20;
          }
        }
      }
      column.minWidth = column.minWidth < minWidth ? minWidth : column.minWidth;

      // column.minWidth =
      //   column.autoWidth !== false
      //     ? column.minWidth + 40
      //     : column.minWidth + 20;

      //column autoWidth default is true
      // if (column.autoWidth !== false) {
      //   column.minWidth = minWidth + 20;
      // }
    });
    // let cache = localStorage.getItem('dataGridWidth') as any;
    // if (!cache) {
    //   cache = {} as any;
    // } else {
    //   cache = JSON.parse(cache) as any;
    // }
    // cache[gridName] = columns.map((column) => {
    //   return column.minWidth;
    // });
    // localStorage.setItem('dataGridWidth', JSON.stringify(cache));
    columnsRef.current = Columns(columns, gridName);
    setRefreshed(true);
  }, [gridName, checkboxColumn]);

  // eslint-disable-next-line
  const renderPaginationToolbar = React.useCallback(
    (paginationProps) => {
      //console.log('p-->', paginationProps);
      const customProps = {
        ...paginationProps,
        remotePagination: false,
        pageSizes: props.pageSizes,
      };

      return <PaginationToolbar {...customProps} bordered={false} />;
    },
    [props]
  );

  useEffect(() => {
    if (!autoWith) {
      return;
    }
    if (refreshed) {
      return;
    }
    // if (inter) {
    //   clearInterval(inter);
    // }
    const inter = setInterval(() => {
      const aimElem = document.getElementById(`cell${gridName}01`) as any;
      if (aimElem) {
        clearInterval(inter);
        handleResetColumnWidth();
      }
    }, 2000);
    function handleResize() {
      handleResetColumnWidth();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      if (inter) {
        clearInterval(inter);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [autoWith, refreshed, gridName, handleResetColumnWidth]);

  useEffect(() => {
    //force refresh
    setRefreshed(false);
    const dataLen = (dataSource as Array<any>).length;
    if (pagination === 'local' && dataLen - skip < 0) {
      //console.log('len -> ->', dataLen, limit, skip, limit);
      //compatible delete last record.datagrid auto skip prev page
      setSkip(0);
      /*const currentPageData = (dataSource as Array<any>).slice(
        0,
        limit
      );

      if (currentPageData.length === 0 && skip >= limit) {
        setSkip(skip - limit);
      }*/
    }
  }, [dataSource, pagination, skip, limit, gridRef]);

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        marginTop: 10,
        ...props.boxStyle,
      }}
    >
      {showScrollButton && (
        <Button
          icon={<LeftOutlined />}
          style={{
            width: 14,
            height: 'inherit',
          }}
          onClick={() => {
            if (gridRef) {
              const offset = gridRef.current.scrollLeft;
              gridRef.current.setScrollLeft(offset - 100);
            }
          }}
        />
      )}
      <ReactDataGrid
        onReady={setGridRef}
        licenseKey="AppName=multi_app,Company=DigitBridge,ExpiryDate=2022-11-11,LicenseDeveloperCount=2,LicenseType=multi_app,Ref=DigitBridgeLicenseRef,Z=1207408073-10491456647810635194481521851207408073-517868438"
        /* eslint-disable react/jsx-props-no-spreading */
        {...others}
        columns={columnsRef.current}
        defaultGroupBy={[]}
        hideGroupByColumns={false}
        disableGroupByToolbar={
          props.disableGroupByToolbar === false ? false : true
        }
        checkboxColumn={checkboxColumn ? CheckboxColumn : false}
        style={props.autoHeight ? AutoHeight(props, limit) : AutoHeight(props, 9)}
        dataSource={
          props.pagination === 'local'
            ? props.dataSource
            : () => {
              return getDataSource();
            }
        }
        showColumnMenuTool={showColumnMenuTool === false ? false : true}
        // enableColumnAutosize
        renderLoadMask={LoadMask}
        //renderPaginationToolbar={renderPaginationToolbar}
        skip={skip}
        limit={limit || DEFAULT_DATAGRID_ROW_NUM}
        renderRowContextMenu={renderRowContextMenu}
        onColumnVisibleChange={onColumnVisibleChange}
        showCellBorders={false}
        {...extendProps}
      />
      {showScrollButton && (
        <Button
          icon={<RightOutlined />}
          style={{
            width: 14,
            height: 'inherit',
          }}
          onClick={() => {
            if (gridRef) {
              const offset = gridRef.current.scrollLeft;
              gridRef.current.setScrollLeft(offset + 100);
            }
          }}
        />
      )}
    </div>
  );
}
DataGridPlus.defaultProps = {
  count: undefined,
  copyableColumns: undefined,
  onChange: undefined,
  name: undefined,
  autoWith: true,
  autoHeight: true,
  showScollButton: false,
};
