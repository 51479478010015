import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ShoppingAround from 'components/ShipmentSettings/ShippingAround';

export default function ShippingAroundScreen(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <ShoppingAround />
      </SiteContent>
    </ContentLayout>
  );
}
