import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Modal, Button, Space, InputNumber } from 'antd';
import { FormLabel } from 'components/common';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onSave: (data: any) => void;
  data: any;
  lines: any[];
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onSave, data, lines } = props;
  const [form] = Form.useForm();
  const [maxQty, setMaxQty] = useState<number>(0)


  useEffect(()=>{
    const index = lines.findIndex((i:any)=>i.sku === data.sku)

    if(index > -1){
        setMaxQty(lines[index].returnQty - lines[index].receiveQty)
    }
  },[lines, data])

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const handleSave = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    onSave({...data, receiveQty: params.receiveQty})
  }, [data, form, onSave]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title={`Edit - (${data.sku})`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
            >
              <SaveOutlined />
              Save
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      {data && (
        <Form {...layout} form={form}>
          <Form.Item
            name="receiveQty"
            initialValue={data.receiveQty || 0}
            label={<FormLabel fontSize={20}>Receive Qty</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Please input Resaleable Inspection!',
              },
            ]}
          >
            <InputNumber
              size="large"
              min={0}
              max={maxQty}
              precision={0}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
