import React from 'react';
import { Link } from 'react-router-dom';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';

export default function Pick(): JSX.Element {
  const { t } = useTranslation()
  return (
    <SmartBlock>
      <SmartSpace>
        <Link to="/smart/checkLocation">
          <SmartButton>{t("smart.audit.checkLocation.title")}</SmartButton>
        </Link>
        <Link to="/smart/checkItem">
          <SmartButton>{t("smart.audit.checkItem.title")}</SmartButton>
        </Link>
        <Link to="/smart/resetInventory">
          <SmartButton>{t("smart.audit.locationCount.title")}</SmartButton>
        </Link>
      </SmartSpace>
    </SmartBlock>
  );
}
