import React, { useCallback, useRef, useState } from 'react';
import { FormInstance, Modal, ModalProps, Typography } from 'antd';
import Draggable from 'react-draggable';
import { leaveEditForm } from 'utils';
// import { ThemeContext } from 'styled-components';

const { Text } = Typography;
interface ModalPlusProps extends ModalProps {
  snapshot?: StringKAnyVPair;
  form?: FormInstance<any>;
  onClose?: () => void;
  onCancel?: () => void;
  titleBar?: React.ReactNode;
  children?: React.ReactNode;
}
/**
 * Extend the modal component
 * Feature 1:Cancel event and automatically compare the content of the form, and give a confirmation box if there is a change.
 * Feature 2:Support drag.Code reference https://ant.design/components/modal/
 */
export default function ModalPlus(props: ModalPlusProps): JSX.Element {
  // const { fontSize } = useContext(ThemeContext);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState<StringKAnyVPair>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef();
  const { form } = props;
  const {
    children,
    snapshot = form ? form.getFieldsValue() : {},
    onClose,
    onCancel,
    title,
    titleBar,
    ...other
  } = props;
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    if (form) {
      const cancel = await leaveEditForm(snapshot || {}, form);
      if (cancel) {
        if (typeof onClose === 'function') {
          onClose();
        }
      }
    } else {
      if (typeof onCancel === 'function') {
        onCancel();
      }
    }
  }, [onClose, onCancel, snapshot, form]);
  /**
   * handle drag stat event
   */
  const handleStart = useCallback((_, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = (draggleRef.current as any).getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  }, []);
  /**
   * Modal Title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <div
        style={{
          width: '100%',
          cursor: 'move',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        // fix eslintjsx-a11y/mouse-events-have-key-events
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
        onFocus={() => {}}
        onBlur={() => {}}
        // end
      >
        <Text style={{ fontSize: 16 }}>{title}</Text>
        {typeof titleBar === 'object' && (
          <span style={{ marginRight: 30 }}>{titleBar}</span>
        )}
      </div>
    );
  };
  return (
    <Modal
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      onCancel={handleCancel}
      title={<Title />}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => handleStart(event, uiData)}
        >
          <div ref={draggleRef as any}>{modal}</div>
        </Draggable>
      )}
    >
      {children}
    </Modal>
  );
}
ModalPlus.defaultProps = {
  snapshot: undefined,
  form: undefined,
  onClose: undefined,
  onCancel: undefined,
  titleBar: undefined,
  children: undefined,
};
