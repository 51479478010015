import React from 'react';
import { Col, Divider, Modal, Row, Typography } from 'antd';
import { APP_VERSION } from 'constants/config';
import { fetchSiteConfig } from 'utils';
import { GreyCardWrapper } from './styles';

const { Title } = Typography;

interface VersionModalProps {
  show: boolean;
  onClose: Function;
}

export default function VersionModal(props: VersionModalProps): JSX.Element {
  const { show, onClose } = props as any;
  const siteCfg = fetchSiteConfig();

  return (
    <Modal
      centered
      visible={show}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      <GreyCardWrapper>
        <Row wrap={false}>
          <Col>
            <div style={{ padding: 20 }} >
              <img className="logo" alt="Company Logo" src={siteCfg.logo} width="100" />
            </div>
          </Col>
          <Col>
            <Divider type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col>
            <div style={{ padding: 20 }} >
              <Title level={2}>{siteCfg.name}</Title>
              <Title level={5}>{`Version ${APP_VERSION}`}</Title>
            </div>
          </Col>
        </Row>
      </GreyCardWrapper>
    </Modal >
  )
}
