import React, { useState, useCallback, useEffect, useRef, Dispatch } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import { CopySpan, FormLabel, ModalDialog } from 'components/common';
import theme from 'assets/styles/theme';
import { Button, Card, Col, Descriptions, Form, InputNumber, Row, Space, Spin } from 'antd';
import { v4 as uuid } from 'uuid';
import { COMMON_PADDING_SPACE, LS_DEFAULT_WAREHOUSE_KEY, MIN_FEDEX_SMART_POST_WEIGHT, SERVICE_FEDEX_SMARTPOST_NUM } from 'constants/config';
import { convertTimeByUtc, formatAddress, getWarehouseCodeFromCache } from 'utils';
import { FilePdfOutlined, FrownOutlined, LoadingOutlined } from '@ant-design/icons';
import WeightBoard from 'components/Sales/ScanVerifyPrint/WeightBoard';
import AlertDialog from 'components/Sales/ScanVerifyPrint/AlertDialog';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { FormVSpacer } from 'components/common/styles/CustomPropsComponents';
import ServiceSelector from 'components/Sales/ScanVerifyPrint/ServiceSelector';
import PackageSelector from 'components/Sales/ScanVerifyPrint/PackageSelector';
import { SizeInputRow, UnitLabel } from 'components/Sales/ScanVerifyPrint/styles';
import { userProfiles } from 'utils/auth';
import { printReturnShippingLabel } from 'services/return';

const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

interface LabelPdfModalProps extends ModalProps {
  onClose: Function;
  onRefresh: () => void;
  shipment: any;
  markVerified: boolean;
}

// eslint-disable-next-line
export default function LabelPdfModal(props: LabelPdfModalProps) {
  const { onClose, shipment } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();
  const selectedShipment = props.shipment;
  const [loading, setLoading] = useState(false);
  // const [activeShipOptionPanelKey, setActiveShipOptionPanelKey] = useState<
  //   string[]
  // >([]);
  const [BTPAccount, setBTPAccount] = useState('');
  // const [BTPCountry, setBTPCountry] = useState<number>();
  const [BTPPostCode, setBTPPostCode] = useState('');
  const [currentShipmentNum, setCurrentShipmentNum] = useState(0);
  // eslint-disable-next-line
  const [inited, setInited] = useState(false);
  const [insuredValue, setInsuredValue] = useState<number>();
  const [needBTP, setNeedBTP] = useState(false);
  const [packageHeight, setPackageHeight] = useState<number>();
  const [packageLength, setPackageLength] = useState<number>();
  // const [packageName, setPackageName] = useState('');
  const [packageNum, setPackageNum] = useState(-1);
  // const [packageType, setPackageType] = useState('');
  const [packageUpdateTag, setPackageUpdateTag] = useState<string>();
  const [packageWeight, setPackageWeight] = useState(0);
  const [packageWidth, setPackageWidth] = useState<number>();

  const [alertDialogContent, setAlertDialogContent] = useState('');
  const [alertDialogOnClose, setAlertDialogOnClose] = useState<Function>();
  const [alertDialogTitle, setAlertDialogTitle] = useState('');
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);

  const [serviceCode, setServiceCode] = useState<string>();
  const [serviceUpdateTag, setServiceUpdateTag] = useState<string>();
  const [shipCarrier, setShipCarrier] = useState('');
  const [shipCarrierNum, setShipCarrierNum] = useState(0);
  const [shipFrom, setShipFrom] = useState(-1);
  // eslint-disable-next-line
  // eslint-disable-next-line
  const [shipService, setShipService] = useState('');
  const [shipServiceNum, setShipServiceNum] = useState(0);
  const [shipAccountNum, setShipAccountNum] = useState(0);
  const [shipWeight, setShipWeight] = useState<number>();
  useState(false);
  const [shoppingAroundSizeLocked, setShoppingAroundSizeLocked] =
    useState(false);
  const [useScale, setUseScale] = useState(true);
  const [canNotChangeSize, setCanNotChangeSize] = useState(false);
  // const optionPanel = React.useRef<any>(null);
  const shell = React.useRef<any>(null);

  const pageWidth: number = useSelector(
    (state: RootState) => state.regular.pageWidth,
    shallowEqual
  );
  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );
  const printLabelResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResult,
    shallowEqual
  );
  const selectedScale: Scale = useSelector(
    (state: RootState) => state.sales.scale,
    shallowEqual
  );

  const refreshBtnRef = useRef<any>(null);
  const [form] = Form.useForm();

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const onShippingServiceChange = (svr: StringKAnyVPair) => {
    if (typeof svr.shipAccountNum === 'undefined') {
      setPackageNum(-1);
      setPackageUpdateTag(uuid());
      // wether need to clear some status at here
      setShipCarrierNum(0);
      return;
    }
    setShipAccountNum(svr.shipAccountNum);
    setShipCarrier(svr.carrierName);
    setShipCarrierNum(svr.carrierNum);
    setShipService(svr.carrierServiceCode);
    setShipServiceNum(svr.carrierServiceNum);

    if (svr.carrierServiceNum === SERVICE_FEDEX_SMARTPOST_NUM) {
      if (
        typeof shipWeight === 'number' &&
        shipWeight < MIN_FEDEX_SMART_POST_WEIGHT
      ) {
        setShipWeight(MIN_FEDEX_SMART_POST_WEIGHT);
      }
    } else {
      // setTopMessage('');
    }

    if (svr.billToType === 2) {
      // setActiveShipOptionPanelKey(['1']);
      setNeedBTP(true);
      setBTPAccount(svr.billThirdPartyAccount || '');
      setBTPPostCode(svr.billThirdPartyPostalCode || '');
      // setBTPCountry(svr.billThirdPartyCountryNum || undefined);
    } else {
      // setActiveShipOptionPanelKey([]);
      setNeedBTP(false);
      setBTPAccount('');
      setBTPPostCode('');
      // setBTPCountry(undefined);
    }
  };

  const selectedShipmentNum = React.useMemo(() => {
    return selectedShipment ? selectedShipment.shipmentNum : 0;
  }, [selectedShipment]);
  // const selectedShipmentSignature = React.useMemo(() => {
  //   return selectedShipment &&
  //     typeof selectedShipment.signatureRequired === 'number'
  //     ? selectedShipment.signatureRequired
  //     : undefined;
  // }, [selectedShipment]);
  const shipmentPrintable = React.useMemo(() => {
    return (
      !!(
        (selectedShipment && selectedShipment.isLabelSeparately !== 1)
        // selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
      ) && props.markVerified
    );
  }, [props, selectedShipment]);

  const onShippingPackageChange = (pkg: StringKAnyVPair) => {
    if (pkg.shippingPackageNum > -1) {
      let heightExist = false;
      let lengthExist = false;
      let widthExist = false;

      if (selectedShipment) {
        const { height, length, width } = selectedShipment;

        heightExist = typeof height === 'number' && height > 0;
        lengthExist = typeof length === 'number' && length > 0;
        widthExist = typeof width === 'number' && width > 0;
      }

      // setPackageName(pkg.name);
      setPackageNum(pkg.shippingPackageNum);
      // setPackageType(pkg.code || '');

      // set for non General Package
      if (pkg.shippingPackageNum !== 0) {
        let sizeExist =
          heightExist ||
          lengthExist ||
          widthExist ||
          (typeof pkg.height === 'number' && pkg.height > 0) ||
          (typeof pkg.length === 'number' && pkg.length > 0) ||
          (typeof pkg.width === 'number' && pkg.width > 0);
        setCanNotChangeSize(sizeExist);
      } else {
        setCanNotChangeSize(false);
      }

      if (shoppingAroundSizeLocked) {
        setShoppingAroundSizeLocked(false);
      } else {
        if (!heightExist) setPackageHeight(pkg.height || undefined);
        if (!lengthExist) setPackageLength(pkg.length || undefined);
        if (!widthExist) setPackageWidth(pkg.width || undefined);
      }
    }
  };

  // const onCollapseShipOptions = (key: string | string[]) => {
  //   if (Array.isArray(key)) {
  //     setActiveShipOptionPanelKey(key);

  //     if (key.length > 0) {
  //       setTimeout(() => {
  //         optionPanel?.current?.scrollIntoView({ behavior: 'smooth' });
  //       }, 1000);
  //     }
  //   }
  // };

  // const onBTPAccountChange = (evt: any) => {
  //   setBTPAccount(evt.target.value);
  // };

  // const onBTPPostCodeChange = (evt: any) => {
  //   setBTPPostCode(evt.target.value);
  // };

  const openAlertDialog = (config: StringKAnyVPair) => {
    setAlertDialogVisible(true);
    setAlertDialogContent(config.content || '');
    setAlertDialogOnClose(
      typeof config.onClose === 'function' ? config.onClose : undefined
    );
    setAlertDialogTitle(config.title || '');
  };

  const handleExportLabelPdf = async () => {
    if (shipFrom < 0) {
      openAlertDialog({ title: 'Error', content: 'Please select a warehouse' });
      return;
    }

    if (shipAccountNum <= 0 || shipServiceNum <= 0) {
      openAlertDialog({ title: 'Error', content: 'Please select a service' });
      return;
    }

    if (packageWeight <= 0) {
      openAlertDialog({ title: 'Error', content: 'Please specify the weight' });
      return;
    }

    if (packageNum < 0) {
      openAlertDialog({ title: 'Error', content: 'Please select a package' });

      return;
    }

    if (needBTP) {
      let invalidBTP = false;

      if (!BTPAccount) {
        invalidBTP = true;
      }

      if (!BTPPostCode) {
        invalidBTP = true;
      }

      if (invalidBTP) {
        openAlertDialog({
          title: 'Error',
          content: 'Please provide valid third party content',
        });
        return;
      }
    }

    const profile = userProfiles.profile;
    const pd = {
      // isBatch: false,
      // ShipmentNum: selectedShipmentNum,
      // ShippingAccountNum: shipAccountNum,
      // ShipFrom: shipFrom,
      // PrinterAccountNum: 0,
      // PrinterNum: 0,
      // PrinterName: '0',
      // Carrier: shipCarrier,
      // CarrierNum: shipCarrierNum,
      // Confirmation: shipConfirmation,
      // Service: shipService,
      // PackageName: packageName,
      // PackageType: packageType,
      // ShipPackageNum: packageNum,
      // ShipServiceNum: shipServiceNum,
      // Height: packageHeight,
      // Length: packageLength,
      // Weight: packageWeight,
      // Width: packageWidth,
      // MasterAccountNum: profile.masterAccountNum,
      // ProfileNum: profile.profileNum,
      // InsuredValue: insuredValue,
      // BillToType: needBTP ? 2 : 0,
      // BillThirdPartyAccount: BTPAccount,
      // BillThirdPartyCountry: BTPCountry || null,
      // BillThirdPartyPostalCode: BTPPostCode,

      FulfillmentReturnNum: selectedShipment.fulfillmentReturnNum,
      ShippingAccountNum: shipAccountNum,
      // Carrier: '',
      CarrierNum: shipCarrierNum,
      ShipServiceNum: shipServiceNum,
      ShipPackageNum: packageNum,
      Weight: packageWeight,
      Length: packageLength,
      Width: packageWidth,
      Height: packageHeight,
      Confirmation: null,
      // InsuredValue: null,
      PrinterAccountNum: 0,
      // PrinterNum: 0,
      PrinterName: 'Printer',
      // BillToType: '',
      // BillThirdPartyAccount: '',
      // BillThirdPartyCountry: '',
      // BillThirdPartyPostalCode: '',
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };
    try {
      setLoading(true);
      const res = await printReturnShippingLabel(pd);
      setLoading(false);
      const content = res;
      const blob = new Blob([content], {
        type: pd.CarrierNum === 2 ? ' image/png' : 'application/pdf',
      });
      const fileName =
        pd.CarrierNum === 2
          ? `RMA_${selectedShipment.returnNo}.png`
          : `RMA_${selectedShipment.returnNo}.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
      props.onClose();
    } catch (error) {
      setLoading(false);
    }
  };

  const clearFormValue = () => {
    setShipAccountNum(0);
    setShipCarrier('');
    setShipCarrierNum(0);
    setShipService('');
    setShipServiceNum(0);
    // setPackageName('');
    setPackageNum(-1);
    // setPackageType('');
    setPackageHeight(undefined);
    setPackageLength(undefined);
    setPackageWidth(undefined);
    setPackageWeight(0);
    // setTopMessage('');
  };

  const fillFormValueByShipment = (shipment: StringKAnyVPair) => {
    const {
      height,
      length,
      // packageCode,
      // packageName,
      carrierNum,
      shipPackageNum,
      shipAccountNum,
      shippingCarrier,
      shippingServiceCode,
      width,
    } = shipment;

    // if (packageCode) setPackageType(packageCode);
    // if (packageName) setPackageName(packageName);
    if (carrierNum) setShipCarrierNum(carrierNum);
    if (typeof shipPackageNum === 'number') setPackageNum(shipPackageNum);
    if (shipAccountNum) setShipAccountNum(shipAccountNum);
    if (shippingCarrier) setShipCarrier(shippingCarrier);
    if (shippingServiceCode) setShipService(shippingServiceCode);

    setPackageHeight(height || undefined);
    setPackageLength(length || undefined);
    setPackageWidth(width || undefined);
    setShipWeight(
      selectedShipment ? selectedShipment.weight || undefined : undefined
    );
    setServiceCode(
      selectedShipment
        ? selectedShipment.shippingServiceCode || undefined
        : undefined
    );
    setPackageUpdateTag(uuid());
    setServiceUpdateTag(uuid());
  };

  /* eslint-disable */
  useEffect(() => {
    if (!inited) {
      const warehouseId = parseInt(
        localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
      );
      setShipFrom(warehouseId);
      setShipWeight(
        selectedShipment ? selectedShipment.weight || undefined : undefined
      );
      setInited(true);
    }
    if (currentShipmentNum !== selectedShipmentNum) {
      setCurrentShipmentNum(selectedShipmentNum);
      clearFormValue();
      fillFormValueByShipment(selectedShipment || {});
      setInsuredValue(props.shipment ? props.shipment.insuredValue : undefined);
    }

    setUseScale(!!(selectedScale && selectedScale.scaleNum > 0));
  }, [
    currentShipmentNum,
    dispatch,
    inited,
    pageWidth,
    printLabelResponse,
    printLabelResult,
    props,
    selectedScale,
    selectedShipment,
    selectedShipmentNum,
    shell,
    useScale,
  ]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={onClose}
        onFullscreen={onFullscreen}
        title={`Export Shipping Label PDF (Warehouse: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))) || 'unselected'})`}
        visible={props.visible}
        width={detailDialogWidth()}
        bodyHeight={680}
      >
        <Row style={{ height: '100%' }}>
          {/* left part */}
          <Col span={8}>
            <Card
              style={{
                height: detailDialogIsFullscreen ? '100%' : 650,
                overflowX: 'auto',
                marginRight: COMMON_PADDING_SPACE,
                borderRadius: theme['@border-radius-base']
              }}
              title={'OrderInfo'}
            >
              <Descriptions
                column={1}
                labelStyle={{ display: 'inline-block', width: 150, textAlign: 'right' }}
                colon={false}
                style={{ padding: COMMON_PADDING_SPACE }}
                size={'small'}
              >
                <Descriptions.Item label={<FormLabel>Custom Name</FormLabel>}>
                  <CopySpan value={shipment.shipToName || shipment.endBuyerName} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>RMA#</FormLabel>}>
                  <CopySpan value={shipment.returnNo} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Channel Order ID</FormLabel>}>
                  <CopySpan value={shipment.channelOrderId} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Invoice #</FormLabel>}>
                  <CopySpan value={shipment.invoiceNo} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Return Date</FormLabel>}>
                  <CopySpan value={convertTimeByUtc(shipment.transDate, '', '', 'MM/DD/YYYY')} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Order Qty</FormLabel>}>
                  <CopySpan value={shipment.orderQty} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Claim Qty</FormLabel>}>
                  <CopySpan value={shipment.returnQty} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Receive Qty</FormLabel>}>
                  <CopySpan value={shipment.receiveQty} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Resaleable Qty</FormLabel>}>
                  <CopySpan value={shipment.stockQty} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Damage Qty</FormLabel>}>
                  <CopySpan value={shipment.nonStockQty} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Status</FormLabel>}>
                  <CopySpan value={statusEnum[shipment.status]} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Enter Date</FormLabel>}>
                  <CopySpan value={convertTimeByUtc(shipment.createDate, '', '', 'MM/DD/YYYY')} />
                </Descriptions.Item>
                <Descriptions.Item label={<FormLabel>Ship to</FormLabel>}>
                  {/* <span>
                    <div>
                      <CopySpan value={`
                        ${shipment.shipToName ? (shipment.shipToName + ', ') : ''}
                        ${shipment.shipToCompany ? (shipment.shipToCompany + ', ') : ''}
                        ${shipment.shipToAddressLine1 ? (shipment.shipToAddressLine1 + ', ') : ''}
                        ${shipment.shipToAddressLine2 ? (shipment.shipToAddressLine2 + ', ') : ''}
                        ${shipment.shipToAddressLine3 ? (shipment.shipToAddressLine3 + ', ') : ''}
                        ${shipment.shipToCity ? (shipment.shipToCity + ', ') : ''}
                        ${shipment.shipToState ? (shipment.shipToState + ' ') : ''}
                        ${shipment.shipToPostalCode ? shipment.shipToPostalCode : ''}
                      `} />
                    </div>
                    <div>
                      <CopySpan value={`${shipment.shipToCountry}`} />
                    </div>
                  </span> */}
                  {formatAddress(
                    'col',
                    shipment.shipToName,
                    shipment.shipToCompany,
                    shipment.shipToAddressLine1,
                    shipment.shipToAddressLine2,
                    shipment.shipToAddressLine3,
                    shipment.shipToCity,
                    shipment.shipToState,
                    shipment.shipToPostalCode,
                    shipment.shipToCountry
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          {/* right part */}
          <Col span={16}>
            <Card
              title={'Create Label'}
              style={{
                height: detailDialogIsFullscreen ? '100%' : 650,
                overflowX: 'auto',
                backgroundColor: shipment.warehouseNum !== shipFrom ? 'rgba(0,0,0,0.1)' : '#FFF',
                borderRadius: theme['@border-radius-base']
              }}
            >
              <Spin
                size="large"
                spinning={loading || shipment.warehouseNum !== shipFrom}
                indicator={loading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : <FrownOutlined />}
                tip={shipment.warehouseNum !== shipFrom ? 'Warehouse Unmatched' : 'Loading...'}
              >
                <Row justify="center">
                  <div style={{ width: '85%', padding: COMMON_PADDING_SPACE }}>
                    <WeightBoard
                      onChange={(weight: number) => setPackageWeight(weight)}
                      shipmentNum={selectedShipment.shipmentNum || 0}
                      weight={shipWeight}
                    />
                    <Form form={form} requiredMark={false} style={{ marginTop: 20 }}>
                      <Row gutter={20}>
                        <Col span={12}>
                          <Form.Item label={<FormLabel>Service</FormLabel>} >
                            <ServiceSelector
                              channelAccountNum={
                                selectedShipment
                                  ? selectedShipment.channelAccountNum
                                  : 0
                              }
                              onChange={onShippingServiceChange}
                              serviceCode={serviceCode}
                              shipAccountNum={shipAccountNum}
                              shipmentNum={selectedShipmentNum}
                              updateTag={serviceUpdateTag}
                              warehouseNum={shipFrom}
                              shipment={selectedShipment}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label={<FormLabel>Package</FormLabel>} >
                            <PackageSelector
                              carrier={shipCarrier}
                              carrierNum={shipCarrierNum}
                              channelAccountNum={
                                selectedShipment
                                  ? selectedShipment.channelAccountNum
                                  : 0
                              }
                              onChange={onShippingPackageChange}
                              packageNum={
                                packageNum >= 0 ? packageNum : undefined
                              }
                              shipmentNum={selectedShipmentNum}
                              updateTag={packageUpdateTag}
                              warehouseNum={shipFrom}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <FormVSpacer />
                      <Row>
                        <Col>
                          <Form.Item label={<FormLabel>Size</FormLabel>} >
                            <SizeInputRow align="middle">
                              <Col style={{ width: '31%' }}>
                                <Space size={0}>
                                  <InputNumber
                                    disabled={canNotChangeSize}
                                    min={0}
                                    onChange={(value: number) =>
                                      setPackageLength(value)
                                    }
                                    style={{ width: '100%' }}
                                    value={packageLength}
                                  />
                                  <UnitLabel>L</UnitLabel>
                                </Space>
                              </Col>
                              <Col style={{ width: '31%' }}>
                                <Space size={0}>
                                  <InputNumber
                                    disabled={canNotChangeSize}
                                    min={0}
                                    onChange={(value: number) =>
                                      setPackageWidth(value)
                                    }
                                    style={{ width: '100%' }}
                                    value={packageWidth}
                                  />
                                  <UnitLabel>W</UnitLabel>
                                </Space>
                              </Col>
                              <Col style={{ width: '38%' }}>
                                <Space size={0}>
                                  <InputNumber
                                    disabled={canNotChangeSize}
                                    min={0}
                                    onChange={(value: number) =>
                                      setPackageHeight(value)
                                    }
                                    style={{ width: '100%' }}
                                    value={packageHeight}
                                  />
                                  <UnitLabel>H(in)</UnitLabel>
                                </Space>
                              </Col>
                            </SizeInputRow>
                          </Form.Item>
                        </Col>
                      </Row>
                      <FormVSpacer />
                      <Row gutter={20}>
                        {/* <Col span={12}>
                          <Form.Item
                            label={<FormLabel>Signature</FormLabel>}
                          >
                            <SignatureSelector
                              carrierNum={shipCarrierNum}
                              onChange={(value: number) => setShipConfirmation(value)}
                              shipmentNum={selectedShipmentNum}
                              signature={selectedShipmentSignature}
                              size="middle"
                            />
                          </Form.Item>
                        </Col> */}
                        <Col span={12}>
                          <Form.Item
                            label={<FormLabel>Carrier Declared Value</FormLabel>}
                          >
                            <InputNumber
                              onChange={(value: number) => setInsuredValue(value)}
                              min={0}
                              value={insuredValue}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <FormVSpacer />
                    </Form>
                    {/* <Collapse
                      activeKey={activeShipOptionPanelKey}
                      onChange={onCollapseShipOptions}
                    >
                      <PrintOptionPanel
                        header={
                          <div className="collapse-bar">
                            <Row align="middle" justify="start">
                              <Label>
                                <span className="label-bold">
                                  Other Shipping Options
                                </span>
                              </Label>
                            </Row>
                          </div>
                        }
                        key="1"
                      >
                        <legend>
                          <Checkbox
                            checked={needBTP}
                            onChange={(evt: any) =>
                              setNeedBTP(evt.target.checked)
                            }
                          >
                            Bill To Third Party
                          </Checkbox>
                        </legend>
                        <Form style={{ padding: '0 20px' }}>
                          <Row gutter={10}>
                            <Col span={8}>
                              <Form.Item label={<FormLabel>Account #</FormLabel>}>
                                <Input
                                  disabled={!needBTP}
                                  onChange={onBTPAccountChange}
                                  value={BTPAccount}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label={<FormLabel>Postal Code</FormLabel>}>
                                <Input
                                  disabled={!needBTP}
                                  onChange={onBTPPostCodeChange}
                                  value={BTPPostCode}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item label={<FormLabel>Country</FormLabel>}>
                                <CountrySelector
                                  disabled={!needBTP}
                                  onChange={(country: number) =>
                                    setBTPCountry(country)
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </PrintOptionPanel>
                    </Collapse> */}
                    <Row justify="end" style={{ width: '100%', marginTop: 16 }}>
                      <Button
                        type="primary"
                        disabled={!shipmentPrintable}
                        loading={loading}
                        onClick={handleExportLabelPdf}
                      >
                        <FilePdfOutlined />
                        Export Shipping Label PDF
                      </Button>
                    </Row>
                  </div>
                </Row>
              </Spin>
              <AlertDialog
                close={() => setAlertDialogVisible(false)}
                content={alertDialogContent}
                onClose={alertDialogOnClose}
                title={alertDialogTitle}
                visible={alertDialogVisible}
              />
            </Card>
          </Col>
        </Row>
      </ModalDialog>
    </>
  );
}
