import React, { useCallback, useState, useEffect } from 'react';
import {
  Form,
  Select,
  Row,
  Input,
  DatePicker,
  Button,
  Col,
  Radio,
  Checkbox,
  Modal,
  Table,
  Typography,
} from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  ClearButton,
  FormLabel,
  ExportCSV,
  SelectWarehouse,
  message,
  SearchCollapse,
} from 'components/common';
import {
  setTagToOrders,
  updateOrdresStatus,
  retryAdvanceProcess,
  postUnQueue,
  retryServiceMapping,
} from 'services/orders';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
//import FormLabelPlus from './FormLabelPlus';
import SelectOrderStatus from '../SelectOrderStatus';
import SelectTagsDialog from '../Tag/SelectTagsDialog';
import ChangeStatusDialog from '../ChangeStatusDialog';
// import MultipleSelectChannelAccount from '../MultipleSelectChannelAccount';
import { fetchStoreList } from 'services/storeSetup';
import {
  convertToUtcTime,
  sortByString,
  convertTimeByUtc,
  trimValues,
  getLocaleTimezoneOffset,
} from 'utils';
import { getPickList } from 'services/printPickList';
import { SearchOutlined } from '@ant-design/icons';
import { ConditionColumns } from 'components/common/SearchCollapse';
import { useHistory } from 'react-router-dom';
import { exportBusinessFile } from 'services/files';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import theme from 'assets/styles/theme';
import { ClassicCard } from 'components/common/styles';
import {
  orderProcessStatusKeyValue as processStatusMap,
  orderStatusKeyValue as orderStatusMap,
} from 'constants/enums'

// import { fetchProfileSettings } from 'services/user';
// import {
//   LS_DEFAULT_WAREHOUSE_CODE,
//   LS_DEFAULT_WAREHOUSE_KEY,
// } from 'constants/config';

type KeyWordType =
  | 'channelOrderId'
  | 'salesOrderNumber'
  | '2ndChannelOrderId'
  // | 'sku/upc'
  | 'sellerOrderId'
  | 'traceId'
  | 'fulfillmentOrderId'
  | 'centralOrderNum';

type AdvancedKeyWordType =
  | 'divisionCode'
  | 'departmentCode'
  | 'groupCode'
  | 'subGroupCode';

const KeyWordTypeMap = {
  channelOrderId: 'Channel Order ID',
  salesOrderNumber: 'Sales Order#',
  '2ndChannelOrderId': '2nd Channel Order ID',
  sellerOrderId: 'Seller Order ID',
  traceId: 'Trace ID',
  fulfillmentOrderId: 'Fulfillment Order ID',
  centralOrderNum: 'Central Order#',
};

const AdvancedKeyWordTypeMap = {
  divisionCode: 'Division Code',
  departmentCode: 'Department Code',
  groupCode: 'Group Code',
  subGroupCode: 'Subgroup Code',
};

const lineCountMap = {
  0: 'No Limit',
  1: 'Equal 1',
  2: 'More than 1',
};
const queueStatusMap = {
  0: 'All',
  1: 'Queued',
  2: 'Non-queued',
};
const sortByMap = {
  'enterDate desc': 'Enter Date Descending',
  'enterDate asc': 'Enter Date Ascending',
  'originalOrderDate desc': 'Order Date Descending',
  'originalOrderDate asc': 'Order Date Ascending',
  'channelOrderId desc': 'Channel Order ID Descending',
  'channelOrderId asc': 'Channel Order ID Ascending',
};

const { RangePicker } = DatePicker;

type ActionssPanelProps = {
  loading: boolean;
  selectedRows: Array<OrderRow>;
  warehouseCode: string;
  exportdata: any;
  columns: any[];
  clickedButton: number;
  onSearch: (
    top: number,
    skip: number,
    searchFrom: number,
    isShowItem: boolean,
    query: { [key: string]: any }
  ) => void;
  onReaload: () => void;
  onResetClickButton: () => void;
};
/**
 * Returns filters and action form
 * @param {ActionssPanelProps} props
 * @returns {JSX.Element}
 */
export default function ActionsPanel(props: ActionssPanelProps): JSX.Element {
  const {
    onSearch,
    loading,
    selectedRows,
    onReaload,
    onResetClickButton,
    clickedButton,
    exportdata,
    columns,
    // warehouseCode,
  } = props;
  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const dwarehouseNum = searchParams.get('warehouseNum')
  const fromDate = searchParams.get('fromDate');
  const defaultEnterDate = searchParams.get('enterDate')
    ? searchParams.get('enterDate')?.split('@')
    : undefined;
  const defaultQueueStatus = searchParams.get('queueStatus');
  const defaultProcessStatus = searchParams.get('advanceProcess');
  const defaultOrderStatus = searchParams.get('orderStatus')?.split('|').map(item => {
    // @ts-ignore
    return { label: orderStatusMap[item], value: item };
  });
  const [init, setInit] = useState<boolean>(false);
  const [keyWordType, setKeyWordType] = useState<KeyWordType>('channelOrderId');
  const [changeStatusDialogVisible, setChangeStatusDialogVisible] =
    useState(false);
  const [selectTagsDialogVisible, setSelectTagsDialogVisible] = useState(false);
  const [data, setData] = useState<Array<ChannelAccountRow>>([]);
  const [isFrom, setIsFrom] = useState<number>(0);
  const [pickList, setPickList] = React.useState<any[]>([]);
  const [rangeDateAble, setRangeDateAble] = React.useState<boolean>(false);
  const [viewOrderEnterDateRange, setViewOrderEnterDateRange] =
    useState<number>(0);
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<boolean>(false);
  const [advancedKeyWordType, setAdvancedKeyWordType] =
    useState<AdvancedKeyWordType>('divisionCode');
  const [conditionColumns, setConditionColumns] = useState<ConditionColumns[]>(
    []
  );
  const [factor, setFactor] = useState<boolean>(false);
  const [currentWarehouse, setCurrentWarehouse] = useState<WarehouseRow>();
  const [tempQuery, setTempQuery] = useState<{ [key: string]: any }>({});
  const [tempSearchFrom, setTempSearchFrom] = useState(false);
  const [tempIsShowItem, setTempIsShowItem] = useState(false);
  const [oper, setOper] = useState<number>(0);
  const [searchLimit, setSearchLimit] = useState<any>({ enterDate: 'default' });
  const [form] = Form.useForm();
  const history = useHistory();

  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'ViewOrderEnterDateRange'
      );
      if (temp.length > 0) {
        setViewOrderEnterDateRange(temp[0].settingValue);
      }
    }
  }, [userProfileSettings]);

  // const getProfileSettings = useCallback(async () => {
  //   try {
  //     const settings = await fetchProfileSettings();
  //     if (Array.isArray(settings)) {
  //       const temp = settings.filter(
  //         (e) => e.settingCode === 'ViewOrderEnterDateRange'
  //       );
  //       if (temp.length > 0) {
  //         setViewOrderEnterDateRange(temp[0].settingValue)
  //       }
  //     }
  //   } catch (e) {
  //     message.error(`Fetch profile setting error: ${e}`)
  //   }
  // }, []);

  /**
   * download all data
   */
  const downLoadAll = useCallback(
    async (SelectField) => {
      try {
        const job = await exportBusinessFile(9, {
          FilterCondition: {
            ...tempQuery,
            searchType: tempSearchFrom ? 1 : tempIsShowItem ? 2 : 0,
            // searchFrom,
            // isShowItem,
            timeZoneOffset: getLocaleTimezoneOffset(),
          },
          SelectField,
          OrderByField: 'fulfillmentOrderNum desc',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, tempQuery, tempIsShowItem, tempSearchFrom]
  );

  const changeShowAdvancedFilters = () => {
    if (showAdvancedFilters) {
      const advancedKeyword = form.getFieldValue('advancedKeyword');
      if (advancedKeyword) {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'The advanced filter will be reset',
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            form.setFieldsValue({
              advancedKeyword: '',
            });
            setShowAdvancedFilters(false);
          },
        });
      } else {
        setShowAdvancedFilters(false);
      }
      return;
    }
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  /**
   * handle search event
   */
  const handleSearch = useCallback(() => {
    const values = trimValues(form.getFieldsValue());
    console.log('values = ', values);
    form.setFieldsValue({ ...values });
    const {
      keyWord,
      reciepientName,
      orderDate,
      enterDate,
      storeName,
      orderStatus = [],
      processStatus = [],
      LineCount,
      warehouse,
      pickListNum,
      queueStatus,
      sortBy,
      searchFrom,
      isShowItem,
      uniqueCode,
      advancedKeyword,
      excludedSKU,
      duplicateOrder,
      customerCode,
    } = values;

    if (!warehouse) {
      return message.info('please select warehouse!');
    }
    // const enterStart = convertToUtcTime(enterDate[0].format());
    // const enterEnd = convertToUtcTime(enterDate[1].format());

    const query: { [key: string]: any } = {
      warehouseNum: warehouse.warehouseNum || warehouse.id,
      //enterDate: `${enterDate[0].format()}@${enterDate[1].format()}`,
      enterDate:
        enterDate && enterDate[0] && enterDate[1]
          ? `${convertToUtcTime(enterDate[0].format())}@${convertToUtcTime(
            enterDate[1].format()
          )}`
          : undefined,
    };
    if (keyWord) {
      query.enterDate = undefined;
      if (keyWordType === 'fulfillmentOrderId') {
        query.fulfillmentOrderId = keyWord;
      }
      if (keyWordType === 'channelOrderId') {
        query.channelOrderId = keyWord;
      }
      if (keyWordType === '2ndChannelOrderId') {
        query.secondaryChannelOrderId = keyWord;
      }
      // if (keyWordType === 'sku/upc') {
      //   query.uniqueCode = keyWord;
      // }
      if (keyWordType === 'traceId') {
        query.traceId = keyWord;
      }
      if (keyWordType === 'salesOrderNumber') {
        query.salesOrderNumber = keyWord;
      }
      if (keyWordType === 'sellerOrderId') {
        query.sellerOrderId = keyWord;
      }
      if (keyWordType === 'centralOrderNum') {
        query.centralOrderNum = keyWord;
      }
      query.oper = oper;
    }
    // if (tag) {
    //   query.push(`TagName cn '${tag}'`);
    // }
    if (uniqueCode) {
      query.uniqueCode = uniqueCode;
    }
    if (orderStatus.length > 0) {
      const orderStatusArr = orderStatus.map((item: any) => item.value);
      query.orderStatus = orderStatusArr.join(',');
    }
    if (processStatus.length > 0) {
      const processStatusArr = processStatus.map((item: any) => item.value);
      query.advanceProcess = processStatusArr.join(',');
    }
    if (reciepientName) {
      // query.push(`ShipToName  eq '${reciepientName}'`);
      query.shipToName = reciepientName;
    }
    if (customerCode) {
      query.customerCode = `${customerCode}`.trim()
    }
    if (storeName && storeName.length > 0) {
      const storeArr = storeName.map((item: any) => item.value);
      query.channelAccountNums = storeArr.join(',');
    }
    if (LineCount) {
      // query.push('LineCount eq 1');
      query.itemQty = LineCount;
    }
    if (queueStatus) {
      query.queueStatus = queueStatus;
    }
    if (pickListNum && pickListNum.value) {
      query.pickListNum = pickListNum.value;
    }
    if (sortBy) {
      query.sortBy = sortBy;
    }
    query.duplicateOrder = duplicateOrder ? 1 : 0;

    if (orderDate && orderDate.length > 0) {
      const start = convertToUtcTime(orderDate[0].format());
      const end = convertToUtcTime(orderDate[1].format());

      query.orderDate = `${start}@${end}`;
    }
    if (advancedKeyword) {
      query[advancedKeyWordType] = advancedKeyword;
    }
    if (excludedSKU) {
      let str: string = (excludedSKU || '')
        .trim()
        .replace(/[\r\n]/g, ',')
        .replace(/,+/g, ',')
        .replace('/,$/gi', '');
      str =
        str.substring(str.length - 1) === ','
          ? str.substring(0, str.length - 1)
          : str;
      query.excludedSKU = str;
    }
    getConditionsColumns(values);
    setTempQuery(query);
    setTempSearchFrom(searchFrom ? true : false);
    setTempIsShowItem(isShowItem ? true : false);
    onSearch(10, 0, searchFrom, isShowItem, query);
    // eslint-disable-next-line
  }, [form, keyWordType, onSearch, advancedKeyWordType, oper]);
  /**
   * handle set order's tag
   */
  const handleChangeTagsSubmit = useCallback(
    async (tags: Array<TagRow>) => {
      await setTagToOrders(tags, selectedRows);
      setSelectTagsDialogVisible(false);
      onReaload();
    },
    [selectedRows, onReaload]
  );

  /**
   *  get picklist
   */
  const getPickListbyWarehouse = async (warehouseNum: number) => {
    try {
      if (warehouseNum === 0) {
        form.setFieldsValue({ pickListNum: undefined });
        return;
      }
      const res = await getPickList(warehouseNum);
      if (res.isSuccess) {
        setPickList(res.data.filter((i: any) => i.pickListNum !== 0));
      }
    } catch (error) { }
  };

  /**
   * handle set order's status
   */
  const handleChangeStatusSubmit = useCallback(
    async (status: number) => {
      await updateOrdresStatus(selectedRows, status);
      setChangeStatusDialogVisible(false);
      onReaload();
    },
    [selectedRows, onReaload]
  );

  const handleRetryAdvanceProcess = useCallback(async () => {
    try {
      const orderNums = Array.from(
        new Set(
          selectedRows.map((item) => {
            return item.fulfillmentOrderNum;
          })
        )
      );
      await retryAdvanceProcess(orderNums);
      onReaload();
    } catch (error) { }
  }, [selectedRows, onReaload]);

  const handleUnquequeOrders = useCallback(async () => {
    try {
      const orderNums = Array.from(
        new Set(
          selectedRows.map((item) => {
            return item.fulfillmentOrderNum;
          })
        )
      );
      const res = await postUnQueue(orderNums);
      if (res.isSuccess) {
        // message.success({ content: 'un-queue orders successfully' });
        Modal.info({
          title: <>
            <span style={{ color: theme['@danger-color'], fontWeight: 'bold' }}>Successful un-queued orders.</span><br />
            <span style={{ color: theme['@danger-color'], fontWeight: 'bold' }}>Please inform warehouse scrap the printed pick list, if any.</span>
          </>,
        })
      }
      onReaload();
    } catch (error) { }
  }, [selectedRows, onReaload]);

  const handleRetryServiceMasspingOrders = useCallback(async () => {
    try {
      const orderIds = Array.from(
        new Set(
          selectedRows.map((item) => {
            return item.fulfillmentOrderId;
          })
        )
      );
      const res = await retryServiceMapping(orderIds)
      if (res.isSuccess) {
        // message.success({ content: 'retry service mapping successfully' });
        Modal.info({
          title: 'Retry service mapping results',
          width: 600,
          content: (
            <Table
              columns={[
                {
                  title: 'Order ID',
                  dataIndex: 'fulfillmentOrderId',
                  key: 'fulfillmentOrderId',
                },
                {
                  title: 'Result',
                  dataIndex: 'success',
                  key: 'success',
                  render(value, record) {
                    return value ? (
                      <Typography.Text type="success">Success</Typography.Text>
                    ) : (
                      <Typography.Text>
                        Fail:{' '}
                        <Typography.Text type="danger">
                          {record.error}
                        </Typography.Text>
                      </Typography.Text>
                    );
                  },
                },
              ]}
              dataSource={res.data}
              pagination={false}
            />
          ),
        });
      }
      onReaload();
    } catch (error) {

    }
  }, [onReaload, selectedRows])

  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setData(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (clickedButton) {
      switch (clickedButton) {
        case 1:
          setChangeStatusDialogVisible(true);
          onResetClickButton();
          break;
        case 2:
          setSelectTagsDialogVisible(true);
          onResetClickButton();
          break;
        case 3:
          handleRetryAdvanceProcess();
          onResetClickButton();
          break;
        case 4:
          handleUnquequeOrders();
          onResetClickButton();
          break;
        case 5:
          handleRetryServiceMasspingOrders();
          onResetClickButton();
          break;
        default:
          onResetClickButton();
          break;
      }
    }
  }, [
    clickedButton,
    handleRetryAdvanceProcess,
    handleUnquequeOrders,
    onResetClickButton,
    handleRetryServiceMasspingOrders,
  ]);

  const initWarehouse = useCallback(() => {
    form.setFieldsValue({
      warehouse: { id: Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) },
    });
  }, [form]);

  useEffect(() => {
    if ((fromDate || defaultEnterDate) && !init) {
      initWarehouse();
      handleSearch();
      setInit(true);
    }
  }, [fromDate, defaultEnterDate, init, handleSearch, initWarehouse]);

  // useEffect(() => {
  //   if (!fromDate && !defaultEnterDate && !init)
  //     getProfileSettings()
  //   setInit(true);
  // }, [getProfileSettings, defaultEnterDate, fromDate, init])

  useEffect(() => {
    if (viewOrderEnterDateRange && !defaultEnterDate && !fromDate) {
      form.setFieldsValue({
        enterDate: [
          moment().subtract(viewOrderEnterDateRange, 'days').startOf('days'),
          moment().add(1, 'days').endOf('days'),
        ],
      });
    }
  }, [viewOrderEnterDateRange, form, defaultEnterDate, fromDate]);

  useEffect(() => {
    if (keyWordType === 'fulfillmentOrderId' || keyWordType === 'centralOrderNum') {
      setOper(0)
    }
  }, [keyWordType])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  const getConditionsColumns = (formValues: any) => {
    const {
      keyWord,
      warehouse,
      reciepientName,
      uniqueCode,
      orderDate,
      enterDate = [],
      storeName = [],
      orderStatus = [],
      processStatus = [],
      LineCount,
      queueStatus,
      pickListNum = {},
      sortBy,
      searchFrom,
      isShowItem,
      advancedKeyword,
      duplicateOrder,
      excludedSKU,
      customerCode,
    } = formValues;
    const orderDateStr = orderDate
      ? `${orderDate[0].format('YYYY/MM/DD')} - ${orderDate[1].format(
        'YYYY/MM/DD'
      )}`
      : undefined;
    const enterDateStr = (enterDate && enterDate[0])
      ? `${enterDate[0].format('YYYY/MM/DD')} - ${enterDate[1].format(
        'YYYY/MM/DD'
      )}`
      : undefined;
    const storeNameStr = storeName.map((item: any) => item.label).join(', ');
    const orderStatusStr = orderStatus.map((item: any) => item.label).join(', ');
    const processStatusStr = processStatus.map((item: any) => item.label).join(', ');
    setConditionColumns([
      { label: KeyWordTypeMap[keyWordType], value: keyWord },
      { label: 'Warehouse', value: warehouse.warehouseCode || warehouse.code },
      { label: 'Recipient Name', value: reciepientName },
      { label: 'uniqueCode', value: uniqueCode },
      { label: 'Order Date', value: orderDateStr },
      { label: 'Enter Date', value: enterDateStr },
      { label: 'Store Name', value: storeNameStr },
      { label: 'Order Fulfillment Status', value: orderStatusStr },
      // @ts-ignore
      { label: 'Process Status', value: processStatusStr },
      // @ts-ignore
      { label: 'Order Item(s) QTY', value: lineCountMap[LineCount] },
      // @ts-ignore
      { label: 'Queued Status', value: queueStatusMap[queueStatus] },
      { label: 'Pick List', value: pickListNum.label },
      // @ts-ignore
      { label: 'Sort By', value: sortByMap[sortBy] },
      { label: 'Search From', value: searchFrom === 0 ? 'Current' : 'History' },
      { label: 'Item View', value: isShowItem ? 'Yes' : 'No' },
      { label: 'Duplicate Order', value: duplicateOrder ? 'Yes' : 'No' },
      {
        label: AdvancedKeyWordTypeMap[advancedKeyWordType],
        value: advancedKeyword,
      },
      { label: 'Excluded SKU', value: excludedSKU },
      { label: 'Customer #', value: customerCode }
    ]);
  };

  // const rangePickerDisabledDate = (current: any) => {
  //   let result = false;
  //   if (current) {
  //     if (
  //       current < moment().subtract(30, 'days') ||
  //       current > moment().add(1, 'days').endOf('day')
  //     ) {
  //       result = true;
  //     } else {
  //       result = false;
  //     }
  //   }
  //   return result;
  // };
  return (
    <SearchCollapse conditionColumns={conditionColumns} factor={factor}>
      {selectTagsDialogVisible && (
        <SelectTagsDialog
          onHide={() => {
            setSelectTagsDialogVisible(false);
          }}
          onSubmit={handleChangeTagsSubmit}
        />
      )}
      {changeStatusDialogVisible && (
        <ChangeStatusDialog
          onHide={() => {
            setChangeStatusDialogVisible(false);
          }}
          onSubmit={handleChangeStatusSubmit}
        />
      )}
      <Form
        form={form}
        {...formItemLayout}
        style={{ justifyContent: 'center' }}
      >
        <ClassicCard>
          <Row>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 9 },
                  style: { display: 'flex', flexDirection: 'row', justifyContent: 'end' }
                }}
                label={
                  <>
                    <Select
                      id="keywordType_selector"
                      size="small"
                      style={{ padding: 0 }}
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      value={keyWordType}
                      defaultValue="fulfillmentOrderId"
                      onChange={(value) => {
                        setKeyWordType(value as KeyWordType);
                        form.setFieldsValue({
                          keyWord: ''
                        });
                      }}
                    >
                      {Object.entries(KeyWordTypeMap).map((opt, index) => {
                        return (
                          <Select.Option value={opt[0]} key={index}>
                            {opt[1]}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </>
                }
              >
                <Form.Item noStyle name="keyWord">
                  <Input
                    addonBefore={
                      <Select value={oper} onChange={(value) => setOper(value)} dropdownMatchSelectWidth={100} disabled={keyWordType === 'centralOrderNum' || keyWordType === 'fulfillmentOrderId'}>
                        <Select.Option value={0}>equals</Select.Option>
                        <Select.Option value={1}>starts with</Select.Option>
                      </Select>
                    }
                    id="keyword_input"
                    onChange={(e) => {
                      const iptVal = keyWordType === 'centralOrderNum' ? e.target.value.replace(/\D/g, '') : e.target.value;
                      setSearchLimit({ ...searchLimit, keyWord: iptVal });
                      form.setFieldsValue({
                        keyWord: iptVal
                      });
                      if (iptVal) {
                        if (!rangeDateAble) {
                          setRangeDateAble(true);
                          form.setFieldsValue({
                            enterDate: [undefined, undefined],
                          });
                        }
                      } else {
                        if (rangeDateAble) {
                          setRangeDateAble(false);
                          form.setFieldsValue({
                            enterDate: [
                              moment()
                                .subtract(viewOrderEnterDateRange, 'days')
                                .startOf('days'),
                              moment().add(1, 'days').endOf('days'),
                            ],
                          });
                        }
                      }
                    }}
                    allowClear={
                      searchLimit.orderDate || searchLimit.enterDate
                    }
                  />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Warehouse</FormLabel>}
                name="warehouse"
              >
                <SelectWarehouse
                  defaultWarehouseNum={Number(dwarehouseNum)}
                  showAll={true}
                  id="warehouse_selector"
                  onChange={(row) => {
                    if (row.id === 0) {
                      form.setFieldsValue({ isShowItem: false });
                    }
                    setCurrentWarehouse(row);
                    getPickListbyWarehouse(row.id);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Recipient Name</FormLabel>}
                name="reciepientName"
              >
                <Input id="reciepientName_input" />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>SKU / UPC</FormLabel>}
                name="uniqueCode"
              >
                <Input id="uniqueCode_input" />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Order Date</FormLabel>}
                name="orderDate"
              >
                <RangePicker
                  id="ordDate_picker"
                  style={{ width: '100%' }}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00:00', 'hh:mm:ss'),
                      moment('23:59:59', 'hh:mm:ss'),
                    ],
                  }}
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  format="MM/DD/YYYY hh:mm:ssA"
                  // disabledDate={rangePickerDisabledDate}
                  onChange={e => setSearchLimit({ ...searchLimit, orderDate: e })}
                  allowClear={
                    searchLimit.enterDate || searchLimit.keyWord
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Enter Date</FormLabel>}
                initialValue={
                  defaultEnterDate
                    ? [moment(defaultEnterDate[0]), moment(defaultEnterDate[1])]
                    : [
                      fromDate
                        ? moment(fromDate).subtract(1, 'days').startOf('days')
                        : moment().subtract(1, 'days').startOf('days'),
                      moment().add(1, 'days').endOf('days'),
                    ]
                }
                name="enterDate"
              >
                <RangePicker
                  id="enterDate_picker"
                  style={{ width: '100%' }}
                  disabled={rangeDateAble}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment('00:00:00', 'hh:mm:ss'),
                      moment('23:59:59', 'hh:mm:ss'),
                    ],
                  }}
                  ranges={{
                    Today: [moment().startOf('day'), moment().endOf('day')],
                    Yesterday: [
                      moment().add(-1, 'day').startOf('day'),
                      moment().add(-1, 'day').endOf('day'),
                    ],
                    '2 days ago': [
                      moment().add(-2, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    '3 days ago': [
                      moment().add(-3, 'day').startOf('day'),
                      moment().endOf('day'),
                    ],
                    'This Week': [
                      moment().startOf('week'),
                      moment().endOf('week'),
                    ],
                    'Last Week': [
                      moment().add(-1, 'week').startOf('week'),
                      moment().add(-1, 'week').endOf('week'),
                    ],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  format="MM/DD/YYYY hh:mm:ssA"
                  onChange={e => setSearchLimit({ ...searchLimit, enterDate: e })}
                  allowClear={
                    searchLimit.orderDate || searchLimit.keyWord
                  }
                // allowClear={false}
                // disabledDate={rangePickerDisabledDate}
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Store Name</FormLabel>}
                initialValue={[]}
                name="storeName"
              >
                <Select
                  id="storeName_picker"
                  mode="multiple"
                  showSearch
                  placeholder="Please select"
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                  labelInValue
                  onChange={() => setFactor(!factor)}
                >
                  {data.filter(i => i.hideFromSelection === 0).map((item) => (
                    <Select.Option
                      key={item.channelAccountNum}
                      value={item.channelAccountNum}
                      label={item.channelAccountName}
                    >
                      {item.channelAccountName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                initialValue={
                  defaultOrderStatus ? defaultOrderStatus : undefined
                }
                label={<FormLabel>Order Fulfillment Status</FormLabel>}
                name="orderStatus"
              >
                <SelectOrderStatus labelInValue mode="multiple" id="ordStatus_selector" onChange={() => setFactor(!factor)} showAll={false} />
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                label={<FormLabel>Process Status</FormLabel>}
                name="processStatus"
                initialValue={fromDate ? [{ value: 2, label: processStatusMap['2'] }] : defaultProcessStatus ? [{ value: Number(defaultProcessStatus), label: processStatusMap[defaultProcessStatus] }] : undefined}
              >
                <Select id="processStatus_selector" labelInValue allowClear mode="multiple" onChange={() => setFactor(!factor)}>
                  {Object.entries(processStatusMap).map((item, index) => (
                    <Select.Option value={Number(item[0])} key={index}>
                      {item[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                name="LineCount"
                label={<FormLabel>Order Item(s) QTY</FormLabel>}
                initialValue={0}
              >
                <Select id="lineCount_selector">
                  {Object.entries(lineCountMap).map((item, index) => (
                    <Select.Option key={index} value={Number(item[0])}>
                      {item[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                name="queueStatus"
                label={<FormLabel>Queued Status</FormLabel>}
                initialValue={
                  defaultQueueStatus ? Number(defaultQueueStatus) : 0
                }
              >
                <Select id="queueStatus_selector">
                  {Object.entries(queueStatusMap).map((item, index) => (
                    <Select.Option key={index} value={Number(item[0])}>
                      {item[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item
                name="pickListNum"
                label={<FormLabel>Pick List</FormLabel>}
              >
                <Select
                  disabled={currentWarehouse?.id === 0}
                  id="pickList_selector"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  labelInValue
                >
                  {pickList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.pickListNum}>
                        {`${item.pickListTag ? `[${item.pickListTag}]` : ''}#${item.pickListNum
                          } by ${item.createBy} on ${convertTimeByUtc(
                            item.createDate
                          )}`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <Form.Item name="sortBy" initialValue="enterDate desc" label={<FormLabel>Sort By</FormLabel>}>
                <Select
                  id="sortBy_selector"
                  dropdownMatchSelectWidth={false}
                  allowClear
                >
                  {Object.entries(sortByMap).map((item, index) => (
                    <Select.Option key={index} value={item[0]}>
                      {item[1]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isFrom === 0 && (
              <Col lg={8} md={12} sm={24}>
                <Row justify="center" align='middle' style={{ paddingTop: 6 }}>
                  <Form.Item
                    noStyle
                    name="isShowItem"
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={currentWarehouse && currentWarehouse.id === 0}
                      id="isShowItem_checkbox"
                    >
                      Item View
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name="duplicateOrder"
                    valuePropName="checked"
                  >
                    <Checkbox
                      id="isShowDuplicateOrder_checkbox"
                    >
                      Duplicate Order
                    </Checkbox>
                  </Form.Item>
                </Row>

              </Col>
            )}
            <Col
              lg={8}
              md={12}
              sm={24}
              style={{ display: showAdvancedFilters ? 'block' : 'none' }}
            >
              <Form.Item
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 9 },
                  style: { display: 'flex', flexDirection: 'row', justifyContent: 'end' }
                }}
                label={
                  <Select
                    style={{ padding: 0 }}
                    size="small"
                    bordered={false}
                    dropdownMatchSelectWidth={false}
                    value={advancedKeyWordType}
                    onChange={(value) => {
                      setAdvancedKeyWordType(value as AdvancedKeyWordType);
                    }}
                  >
                    {Object.entries(AdvancedKeyWordTypeMap).map(
                      (item, index) => (
                        <Select.Option key={index} value={item[0]}>
                          {item[1]}
                        </Select.Option>
                      )
                    )}
                  </Select>
                }
              >
                <Form.Item noStyle name="advancedKeyword">
                  <Input type="text" allowClear />
                </Form.Item>
              </Form.Item>
            </Col>

            <Col
              lg={8}
              md={12}
              sm={24}
              style={{ display: showAdvancedFilters ? 'block' : 'none' }}
            >
              <Form.Item
                name="excludedSKU"
                label={<FormLabel>Excluded SKU</FormLabel>}
              >
                <Input.TextArea rows={1} />
              </Form.Item>
            </Col>
            <Col
              lg={8}
              md={12}
              sm={24}
              style={{ display: showAdvancedFilters ? 'block' : 'none' }}
            >
              <Form.Item
                name="customerCode"
                label={<FormLabel>Customer #</FormLabel>}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row
            style={{ marginTop: 10, paddingRight: 70 }}
            justify="center"
            align="middle"
            gutter={16}
          >
            <Col>
              <Button type="link" onClick={() => changeShowAdvancedFilters()}>
                {showAdvancedFilters
                  ? 'Hide Advanced Filter'
                  : 'Show Advanced Filter'}
              </Button>
            </Col>
            <Col>
              <Form.Item
                name="searchFrom"
                initialValue={0}
                label={<FormLabel>Search From</FormLabel>}
                style={{ height: 32, margin: 0 }}
              >
                <Radio.Group
                  style={{ width: 170 }}
                  id="searchFfrom_radioGroup"
                  onChange={(e: any) => setIsFrom(e.target.value)}
                >
                  <Radio value={0} style={{ display: 'inline' }}>
                    Current
                  </Radio>
                  <Radio value={1} style={{ display: 'inline' }}>
                    History
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col>
              <Button
                id="search_btn"
                type="primary"
                loading={loading}
                onClick={handleSearch}
                icon={!loading && <SearchOutlined />}
              >
                Search
              </Button>
            </Col>
            <Col>
              <ExportCSV
                id="exportCSV_btn"
                data={exportdata ? exportdata.data : []}
                columns={columns}
                selectedRows={selectedRows}
                showExportSelected
                scope="Orders"
                formatColumns={{
                  orderStatus: orderStatusMap,
                  advanceProcess: processStatusMap,
                  pickStatus: {
                    '0': 'Not Picked',
                    '1': 'Picked',
                    '2': 'Partially Picked',
                  },
                  pickType: {
                    '1': 'System',
                    '2': 'Manual',
                    '0': '-'
                  }
                }}
                downloadFromApi={(data) => downLoadAll(data)}
              />
            </Col>
            <Col>
              <ClearButton
                id="clear_btn"
                onClick={() => {
                  form.resetFields();
                  setRangeDateAble(false);
                }}
              />
            </Col>
          </Row>
        </ClassicCard>
      </Form>
    </SearchCollapse>
  );
}
