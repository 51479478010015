import React, { useState } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'components/common';
import { createTransactionReason } from 'services/transactionReason';
import { FormLabel } from 'components/common';
import { leaveEditForm } from 'utils';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  types: TransactionTypeRow[];
}
export default function CreateReasonDialog(props: CreateBrandProps) {
  const { visible, onHide, onRefresh, types } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleCreate = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    setLoading(true);
    try {
      const result = await createTransactionReason({
        ...params,
        RemoveFromHolds: true,
      });
      setLoading(false);
      if (!result.error) {
        form.resetFields();
        onHide();
        onRefresh();
        message.success({ content: 'Created Reason Successfully！' });
      } else {
        message.error({ content: result.error });
      }
    } catch (err) {
      setLoading(false);
      message.error({ content: 'Create Failedlly' });
    }
  };

  const handleCloseModal = React.useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Create Transaction Reason"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleCreate}
              loading={loading}
              icon={<PlusOutlined />}
            >
              {loading ? 'Creating' : 'Create'}
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="InvTransactionReasonName"
            label={<FormLabel>Name</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Please Input Transaction Reason',
              },
            ]}
          >
            <Input placeholder="Input Transaction Reason" />
          </Form.Item>

          <Form.Item
            name="InvTransactionReasonType"
            label={<FormLabel>Transaction Type</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Please select Transaction Type',
              },
            ]}
          // initialValue="2"
          >
            <Select>
              {types.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
