/**
 * order tag service requests are here.
 */
import { CancelToken } from 'axios';
import { digitBridgeApi } from './http';


export const queryTags = async (cancelToken: CancelToken): Promise<any> => {
  return await digitBridgeApi.get('/api/tags', { cancelToken, params: { TagType: 1 } });
};

export const createTag = async (tagName: string, TagDescription: string): Promise<any> => {
  const json = JSON.stringify([{ tagNum: 0, tagName, TagDescription, Action: 'create', TagType: 1 }])
  return await digitBridgeApi.post('/api/SetTags', json)
}

export const editTag = async (tagNum: number, tagName: string, TagDescription: string): Promise<any> => {
  const json = JSON.stringify([{ tagNum, tagName, TagDescription, Action: 'update' }])
  return await digitBridgeApi.post('/api/SetTags', json)
}

export const delTag = async (tagNum: number, tagName: string): Promise<any> => {
  const json = JSON.stringify([{ tagNum, tagName, Action: 'delete' }])
  return await digitBridgeApi.post('/api/SetTags', json)
}

type DelTagParams = {
  TagNum: number,
  Nums: number[],
}
export const delOrderTag = async (params: DelTagParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/RemoveOrderTags', json);
}

export const taging = async (TagNum: number, Nums: number[]): Promise<any> => {
  const json = JSON.stringify({
    TagNum,
    Nums
  })
  return await digitBridgeApi.patch('/api/SetTagToOrders', json);
}
