import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Row,
  Space,
  Form,
  Col,
  Input,
  DatePicker,
  TimePicker,
  Checkbox,
} from 'antd';
import moment from 'moment';
import {
  FormLabel,
  ModalPlus as Modal,
  FormCancelButton,
} from 'components/common';
import { SaveOutlined } from '@ant-design/icons';

const { TextArea } = Input;
type FormItemProps = {
  name?: string;
  label: string;
  children: ReactNode;
  message?: string;
};
const FormItem = (props: FormItemProps): JSX.Element => {
  const { name, label, children, message } = props;
  const rules = [{ required: message ? true : false, message }];
  let middleNode = children;
  let lastNode = null;
  if (Array.isArray(children)) {
    [middleNode, lastNode] = children;
  }
  return (
    <Row className="compact-row">
      <Col span={9}>
        <Row>
          <Col span={22}>
            <Row justify="end">
              <FormLabel>{label}</FormLabel>
            </Row>
          </Col>
          <Col span={2} />
        </Row>
      </Col>
      <Col span={12}>
        <Form.Item name={name} rules={rules}>
          {middleNode}
        </Form.Item>
        {lastNode}
      </Col>
      <Col span={3} />
    </Row>
  );
};
FormItem.defaultProps = {
  message: undefined,
  name: undefined,
};
type MakeCountedDialogProps = {
  onClose: () => void;
  onSubmit: (date: Date, time: Date, note: string) => Promise<void>;
};
export default function MakeCountedDialog(
  props: MakeCountedDialogProps
): JSX.Element {
  const { onClose, onSubmit } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCurrentTime, setIsCurrentTime] = useState(true);
  const [form] = Form.useForm();
  const initDate = useRef(moment());
  const snapshot = useRef({
    date: initDate.current,
    time: initDate.current,
    notes: '',
  });
  useEffect(() => {
    form.setFieldsValue({
      ...snapshot.current,
    });
  }, [form, snapshot]);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const values = form.getFieldsValue();
    setIsSubmitting(true);
    await onSubmit(values.date, values.time, values.notes);
  }, [form, onSubmit]);
  const layout = {
    wrapperCol: { span: 24 },
  };
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleSubmit}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
          <FormCancelButton
            onClick={onClose}
            currentform={form}
            snapshot={snapshot.current}
          />
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Mark Counted"
      centered
      visible
      width={650}
      destroyOnClose
      getContainer={false}
      form={form}
      snapshot={snapshot.current}
      onClose={onClose}
      footer={<Footer />}
    >
      <Form
        /* eslint-disable react/jsx-props-no-spreading */
        {...layout}
        form={form}
        style={{ marginTop: 10 }}
      >
        <FormItem label="Modify Date & Time">
          <Checkbox
            style={{ position: 'absolute', top: 0 }}
            checked={!isCurrentTime}
            onChange={(e) => {
              const { checked } = e.target;
              setIsCurrentTime(!checked);
            }}
          />
        </FormItem>
        <FormItem name="date" label="Date">
          <DatePicker
            disabled={isCurrentTime}
            format="MM/DD/YYYY"
            allowClear={false}
          />
        </FormItem>
        <FormItem name="time" label="Time">
          <TimePicker
            disabled={isCurrentTime}
            format="h:mm A"
            style={{ width: 135 }}
            allowClear={false}
          />
        </FormItem>
        <FormItem name="notes" label="Count Note" message="Please Input Note!">
          <TextArea rows={4} maxLength={1000} />
        </FormItem>
      </Form>
    </Modal>
  );
}
