import styled from 'styled-components';
import { Button, InputNumber, Tag } from 'antd';
import theme from 'assets/styles/theme';
import {
  COMMON_PADDING_SPACE,
  COMMON_FORM_LABEL_RIGHT_PADDING_SPACE,
  LS_THEME_CODE,
} from 'constants/config';

const selectedTheme = localStorage.getItem(LS_THEME_CODE) || '';

type CellNumberInputProps = {
  align?: 'center' | 'left' | 'right';
  fontSize?: number;
  height: number;
  paddingleft?: number;
  paddingright?: number;
  radius?: number;
};

export const CellNumberInput = styled(InputNumber) <CellNumberInputProps>`
  height: ${(props) => props.height}px;
  border-radius: ${(props) =>
    'number' === typeof props.radius
      ? props.radius
      : theme['@border-radius-base']}px;

  & .ant-input-number-input-wrap,
  .ant-input-number-input {
    height: 100%;
    text-align: ${(props) => props.align || 'left'};
  }

  & .ant-input-number-input {
    border-width: 0;
    font-size: ${(props) => props.fontSize || 14}px;
    padding: 0 11px;
    padding-left: ${(props) =>
    typeof props.paddingleft === 'undefined' ? 11 : props.paddingleft}px;
    padding-right: ${(props) =>
    typeof props.paddingright === 'undefined' ? 11 : props.paddingright}px;
  }
`;

type FormRightAlignProps = {
  paddingRight?: number;
};

export const FormRightAlign = styled.div<FormRightAlignProps>`
  padding-right: ${(props) =>
    props.paddingRight || COMMON_FORM_LABEL_RIGHT_PADDING_SPACE}px;
  text-align: right;

  &.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

type FormVSpacerProps = {
  height?: number;
};

export const FormVSpacer = styled.div<FormVSpacerProps>`
  height: ${(props) => props.height || 8}px;
`;

type ColorTheme =
  | 'danger'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning';

type HoverBgButtonProps = {
  hovercolor?: string;
  hovertype?: ColorTheme;
};

const hoverColor = (props: any) => {
  const { hovercolor, hovertype } = props;
  const colorDict = {
    danger: theme['@danger-color'],
    info: theme['@info-color'],
    primary: theme['@primary-color'],
    secondary: theme['@secondary-color'],
    success: theme['@success-color'],
    warning: theme['@warning-color'],
  };
  let color = 'none';

  if (hovertype in colorDict) color = colorDict[hovertype as ColorTheme];

  if (hovercolor) color = hovercolor;

  return 'string' === typeof color ? color : '';
};

export const HoverBgButton = styled(Button) <HoverBgButtonProps>`
  &.ant-btn:focus,
  &.ant-btn:hover {
    background-color: ${(props) => hoverColor(props)};
    border-color: ${(props) => hoverColor(props)};
    color: #fff;
  }

  &.ant-btn:focus {
    background-color: inherit;
    color: inherit;
  }
`;

type SectionWrapperProps = {
  bgColor?: string;
  padding?: number;
  bordered?: boolean;
};
export const SectionWrapper = selectedTheme === 'new' ?
  styled.div<SectionWrapperProps>`
    background-color: ${(props) => props.bgColor || '#FFF'};
    border: ${(props) => props.bordered ? '1px solid #f0f0f0' : 'none'};

    padding: 20px;
    background: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 1px rgba(51, 95, 226, 0.2);

    &.non-top-section {
      margin-top: ${theme['@section-margin']}px;
    }
  ` :
  styled.div<SectionWrapperProps>`
    padding: ${(props) => props.padding || COMMON_PADDING_SPACE}px;
    background-color: ${(props) => props.bgColor || '#FFF'};
    border: ${(props) => props.bordered ? '1px solid #f0f0f0' : 'none'};
    border-radius: ${theme['@border-radius-base']};

    &.non-top-section {
      margin-top: ${theme['@section-margin']}px;
    }
  `;

type TagButtonProps = {
  disabled?: boolean;
  size?: number;
  paddingH?: number;
};

export const TagButton = styled(Tag) <TagButtonProps>`
  background-color: ${(props) => (props.disabled ? 'inherit !important' : '')};
  border-color: ${(props) => (props.disabled ? '#d9d9d9' : '')};
  color: ${(props) => (props.disabled ? 'rgba(0, 0, 0, .25)' : '')};
  font-size: ${(props) => props.size || 14}px;
  margin-right: 0;
  padding: 0 ${(props) => (props.paddingH ? props.paddingH : 7)}px;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
