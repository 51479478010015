import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ViewOrders from 'components/Sales/ViewOrders';
// eslint-disable-next-line
export default function SalesViewOrders(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <ViewOrders />
      </SiteContent>
    </ContentLayout>
  );
}
