import React, { useCallback } from 'react';
import { playAudio } from 'utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartRow,
  SmartText,
  SmartBackButton,
  SmartBlock,
  SmartButton,
} from '../../../SmartComponent';
import { fetchWarehouseProductList } from 'services/inventory';
// import { queryLocationsByKeyword } from 'services/warehouse';
import { useTranslation } from 'react-i18next';

const { Text, Link } = Typography;

type ScanLocationProps = {
  product: SmartPickSKU;
  locations: any[];
  warehouseId: string;
  onBack: () => void;
  onSubmit: (location: any) => void;
};
export default function ScanLocation(props: ScanLocationProps): JSX.Element {
  const { product, onSubmit, onBack, locations, warehouseId } = props;
  const { t } = useTranslation();
  //const [realLocations, setRealLocations] = React.useState();
  const [realLocations, setRealLocations] = React.useState<StringKAnyVPair[]>();
  const [loading, setLoading] = React.useState(false);

  const fetchRealInventory = useCallback(async () => {
    try {
      const query = {
        $skip: 0,
        $top: 1000,
        $sortBy: 'locationCode',
        uniqueCode: product.sku,
      };
      setLoading(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setLoading(false);
      setRealLocations(res.data);
    } catch (error) {
      setLoading(false);
    }
  }, [product, warehouseId]);
  /**
   * handle location key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      const locationRow = locations.filter((item) => item.code === nextValue);
      if (locationRow.length > 0) {
        const temp = (
          realLocations ? realLocations : product.location || []
        ).filter((item) => item.locationCode === nextValue);
        if (temp.length < 1) {
          Modal.confirm({
            title: t("smart.pick.areYouContinue"),
            icon: <ExclamationCircleOutlined />,
            content: t("smart.pick.areYouContinue", { value: nextValue }),
            okText: t("common.yes"),
            onOk() {
              playAudio('success');
              onSubmit(locationRow[0]);
            },
            onCancel() {
              return;
            },
          });
        } else {
          playAudio('success');
          onSubmit(locationRow[0]);
        }
      } else {
        playAudio('error');
        message.warning(`${nextValue} invalid!`);
      }
    },
    [onSubmit, locations, product, realLocations, t]
  );
  return (
    <>
      <SmartSpace>
        <SmartRow>
          <SmartFormLabel>
            {t("common.sku")}
          </SmartFormLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.upc")}
          </SmartFormLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.title")}
          </SmartFormLabel>
          <SmartText>{product.itemTitle}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {realLocations ? 
              t("smart.pick.realTimeLocationInSystem")
            : 
              t("smart.pick.locationInSystem")}
          </SmartFormLabel>
        </SmartRow>

        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            width: '100%',
          }}
        >
          {(realLocations ? realLocations : product.location || [])
            .filter(
              (i) =>
                i.quantity > 0 &&
                i.locationCode !== 'Pick' &&
                i.locationCode !== 'Checkout'
            )
            .map((item: any) => (
              <Text
                style={{
                  fontSize: 16,
                  marginRight: 6,
                }}
              >
                {item.locationCode === 'General' ? (
                  <Link onClick={() => handleScannerChange('General')}>
                    General
                  </Link>
                ) : (
                  <Text strong>{item.locationCode}</Text>
                )}
                {/* <Link onClick={() => handleScannerChange(item.locationCode)}>
                  {item.locationCode}
                </Link> */}
                {`(${item.quantity})`}
              </Text>
            ))}
        </div>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanLocationContinue")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <div style={{ marginTop: 10 }}>
        <SmartBackButton>
          <SmartBlock>
            <SmartButton
              onClick={() => fetchRealInventory()}
              color="white"
              loading={loading}
            >
              {t("smart.pick.pullRealTimeInventory")}
            </SmartButton>
            <SmartButton
              onClick={() => onBack()}
              backgroundColor="red"
              color="white"
            >
              {t("smart.pick.goBack")}
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </>
  );
}
