export const AUDIT_DESELECT_PRODUCT_ROWS = 'AUDIT_DESELECT_PRODUCT_ROWS';
export const AUDIT_EDIT_SELECTED_PRODUCT_ROWS =
  'AUDIT_EDIT_SELECTED_PRODUCT_ROWS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAE';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const COLLAPSE_SIDE_MENU = 'COLLAPSE_SIDE_MENU';
export const COMPLETE_AUTHENTICATION = 'COMPLETE_AUTHENTICATION';
// export const COUNTRY_LIST = 'COUNTRY_LIST';
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const EDIT_WAREHOUSE_ADDRESS = 'EDIT_WAREHOUSE_ADDRESS';
export const EDIT_WAREHOUSE_LOCATION = 'EDIT_WAREHOUSE_LOCATION';
export const EXPIRE_AUTHENTICATION = 'EXPIRE_AUTHENTICATION';
export const HOLD_CURRENT_PAGE = 'HOLD_CURRENT_PAGE';
export const LOAD_PRINTER_LIST = 'LOAD_PRINTER_LIST';
export const LOAD_SCALE_LIST = 'LOAD_SCALE_LIST';
export const NEED_SET_DEVICE = 'NEED_SET_DEVICE';
export const PASS_AUTHENTICATION = 'PASS_AUTHENTICATION';
export const PING_PRINTSTATION = 'PING_PRINTSTATION';
export const PONG_PRINTSTATION = 'PONG_PRINTSTATION';
export const POSTATUS_LIST = 'POSTATUS_LIST';
export const SET_ISLOADING_PRINTER_LIST = 'SET_ISLOADING_PRINTER_LIST';
export const SET_ISLOADING_SCALE_LIST = 'SET_ISLOADING_SCALE_LIST';
export const SET_URL_PATH = 'SET_URL_PATH';
export const SET_PACKING_PRINTER = 'SET_PACKING_PRINTER';
export const SET_PACKING_PRINTER_BY_ID = 'SET_PACKING_PRINTER_BY_ID';
export const SET_PACKING_PRINTER2 = 'SET_PACKING_PRINTER2';
export const SET_PACKING_PRINTER2_BY_ID = 'SET_PACKING_PRINTER2_BY_ID';
export const SET_PAGE_SHOW_MODE = 'SET_PAGE_SHOW_MODE';
export const SET_PAGE_HEIGHT = 'SET_PAGE_HEIGHT';
export const SET_PAGE_WIDTH = 'SET_PAGE_WIDTH';
export const SET_PRINT_CARTON_RESULT = 'SET_PRINT_CARTON_RESULT';
export const SET_PRINT_DOCUMENT_RESULT = 'SET_PRINT_DOCUMENT_RESULT';
export const SET_PRINT_LABEL_END = 'SET_PRINT_LABEL_END';
export const SET_PRINT_LABEL_MSG = 'SET_PRINT_LABEL_MSG';
export const SET_PRINT_LABEL_RESPONSE = 'SET_PRINT_LABEL_RESPONSE';
export const SET_PRINT_LABEL_RESULT = 'SET_PRINT_LABEL_RESULT';
export const SET_PRINT_MULTI_PKG_RESULT = 'SET_PRINT_MULTI_PKG_RESULT';
export const SET_PRINT_SLIP_RESULT = 'SET_PRINT_SLIP_RESULT';
export const SET_PRINTER_LIST = 'SET_PRINTER_LIST';
export const SET_PRINTER_LIST_LOADED = 'SET_PRINTER_LIST_LOADED';
export const SET_PRINTSTATION_IS_OK = 'SET_PRINTSTATION_IS_OK';
export const SET_SALES_PRINTING_HANG = 'SET_SALES_PRINTING_HANG';
export const SET_SALES_SCALE = 'SET_SALES_SCALE';
export const SET_SALES_SCALE_BY_ID = 'SET_SALES_SCALE_BY_ID';
export const SET_SALES_SCALE_WEIGHT = 'SET_SALES_SCALE_WEIGHT';
export const SET_SCALE_LIST = 'SET_SCALE_LIST';
export const SET_SCALE_LIST_LOADED = 'SET_SCALE_LIST_LOADED';
export const SET_SHIPMENT_ITEM = 'SET_SHIPMENT_ITEM';
export const SET_SHIPMENT_PRINTER = 'SET_SHIPMENT_PRINTER';
export const SET_SHIPMENT_PRINTER4x8 = 'SET_SHIPMENT_PRINTER4x8';
export const SET_SHIPMENT_PRINTER_COTON = 'SET_SHIPMENT_PRINTER_COTON';
export const SET_SHIPMENT_PRINTER_BY_ID = 'SET_SHIPMENT_PRINTER_BY_ID';
export const SET_SHIPMENT_PRINTER4x8_BY_ID = 'SET_SHIPMENT_PRINTER4x8_BY_ID';
export const SET_SHIPMENT_PRINTER_COTON_BY_ID = 'SET_SHIPMENT_PRINTER_COTON_BY_ID';
export const SET_SIDER_WIDTH = 'SET_SIDER_WIDTH';
export const SET_SIGNAL_CONNECTION_GROUP = 'SET_SIGNAL_CONNECTION_GROUP';
export const SET_SIGNAL_CONNECTION_STATE = 'SET_SIGNAL_CONNECTION_STATE';
export const SET_TEST_PRINT_MSG = 'SET_TEST_PRINT_MSG';
export const SIGNALR_JOIN_GROUP = 'SIGNALR_JOIN_GROUP';
export const SIGNALR_LEAVE_GROUP = 'SIGNALR_LEAVE_GROUP';
export const SIGNALR_PRINT_CARTON_LABEL = 'SIGNALR_PRINT_CARTON_LABEL';
export const SIGNALR_PRINT_DOCUMENT = 'SIGNALR_PRINT_DOCUMENT';
export const SIGNALR_PRINT_MULTI_PKG_LABEL = 'SIGNALR_PRINT_MULTI_PKG_LABEL';
export const SIGNALR_PRINT_NOTICE = 'SIGNALR_PRINT_NOTICE';
export const SIGNALR_PRINT_PACKING_SLIP = 'SIGNALR_PRINT_PACKING_SLIP';
export const SIGNALR_PRINT_SHIPMENT_LABEL = 'SIGNALR_PRINT_SHIPMENT_LABEL';
export const SIGNALR_REPRINT_SHIPMENT = 'SIGNALR_REPRINT_SHIPMENT';
export const SIGNALR_TEST_PRINTER = 'SIGNALR_TEST_PRINTER';
export const TRANSACTION_REASON_LIST = 'TRANSACTION_REASON_LIST';
export const TRANSACTION_TYPE_LIST = 'TRANSACTION_TYPE_LIST';
export const WAREHOUSE_LIST = 'WAREHOUSE_LIST';
export const TAG_LIST = 'TAG_LIST';
export const SET_PRINT_PICK_LIST_STATUS = 'SET_PRINT_PICK_LIST_STATUS';
export const SET_PRINT_GROUP_PACKING_SLIP_STATUS = 'SET_PRINT_GROUP_PACKING_SLIP_STATUS';
export const USER_PROFILE_SETTINGS = 'USER_PROFILE_SETTINGS';
export const DEFAULT_SPECIAL_WAREHOSUE_INFO = 'DEFAULT_SPECIAL_WAREHOSUE_INFO';
export const USE_PERMISSIONS = 'USET_PERMISSIONS';
export const SET_ADMIN_STATE = 'SET_ADMIN_STATE';
export const SET_DATABASENUM = 'SET_DATABASENUM'
/**
 * Print Contain
 */
export const PRINT_RECEIVE = 'PRINT_RECEIVE';
export const PRINT_LABEL = 'PRINT_LABEL';
export const PRINT_PICK_LIST = 'PRINT_PICK_LIST';
export const PRINT_CONTAINER_SHIPPING_LABEL = 'PRINT_CONTAINER_SHIPPING_LABEL'
export const REPRINT_CONTAINER_SHIPPING_LABEL = 'REPRINT_CONTAINER_SHIPPING_LABEL'
export const PRINT_RETURN_LIST_DETAIL = 'PRINT_RETURN_LIST_DETAIL';
export const PRINT_BULK_RETURN_DETAIL = 'PRINT_BULK_RETURN_DETAIL';
export const PRINT_GROUP_PACKING_SLIP = 'PRINT_GROUP_PACKING_SLIP';
export const PRINT_UNPACK_PICK_LIST = 'PRINT_UNPACK_PICK_LIST';
export const PRINT_PACK_PICK_LIST = 'PRINT_PACK_PICK_LIST';
export const PRINT_PACK_DETAIL = 'PRINT_PACK_DETAIL';