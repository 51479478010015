import React from 'react';
import { Link } from 'react-router-dom';
import { SmartButton, SmartSpace } from './SmartComponent';
import { useTranslation } from 'react-i18next';

export default function Smart(): JSX.Element {
  const { t } = useTranslation();
  return (
    <SmartSpace style={{ marginTop: 40 }}>
      <Link to="/smart/pick">
        <SmartButton>
          {t('smart.pick.title')}
        </SmartButton>
      </Link>
      <Link to="/smart/audit">
        <SmartButton>
          {t('smart.audit.title')}
        </SmartButton>
      </Link>
      <Link to="/smart/virtualWarehouseToLocation">
        <SmartButton>
          {t('smart.virtualWarehouseToLocation.title')}
        </SmartButton>
      </Link>
      <Link to="/smart/movemerge">
        <SmartButton>
          {t('smart.movemerge.title')}
        </SmartButton>
      </Link>
      <Link to="/smart/confirm">
        <SmartButton>
          {t('smart.confirm.title')}
        </SmartButton>
      </Link>
      <Link to="/smart/prepack">
        <SmartButton>
          {t('smart.prepack.title')}
        </SmartButton>
      </Link>
    </SmartSpace>
  );
}
