import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Modal,
  Row,
  Button,
  Space,
  Form,
  Select,
  message,
  InputNumber,
} from 'antd';
import {
  CloseOutlined,
  FileSearchOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { HTTP_STATUS_OK } from 'constants/config';
import { userProfiles } from 'utils/auth';
import { fetchPrintTemplates, fetchPrinterList } from 'services/print';
import { exportPackDetailPDF, previewPackDetailList } from 'services/prepack';

import { FormLabel, DataGridPlus } from 'components/common';
import { useDispatch } from 'react-redux';
import { printPackDetail } from 'actions/printActions';
import { formatPrinterToTree, onSelectionChange } from 'utils';

const FormItemRow = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: center;
  verticalalign: center;
`;

type Props = {
  onHide: () => void;
  detailData?: any;
  sourceLines: any[];
  title?: string;
};
type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, detailData, sourceLines } = props;
  const [exportLoading, setExportLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  // template part
  const [refreshTemplate, setRefeshTemplate] = useState(true);
  const [printTemplateList, setPrintTemplateList] = useState<PrintTemplate[]>(
    []
  );
  // printer part
  const [refreshPrinter, setRefeshPrinter] = useState(true);
  const [printerList, setPrinterList] = useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = useState<OptionsTree[]>();
  const [selected, setSelected] = useState<any>(true);
  const [lines, setLines] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);

  const [form] = Form.useForm();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const temp = sourceLines.map((i) => {
      return {
        ...i,
        printQty: i.requestQty,
      };
    });
    setLines(temp);
    setSelectedRow(temp);
  }, [sourceLines]);

  const getPrinterList = useCallback(async () => {
    try {
      const { isSuccess, data = [] } = await fetchPrinterList();
      setRefeshPrinter(false);
      if (isSuccess) {
        setPrinterList(data);
        setPrinterTree(formatPrinterToTree(data));
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
    }
  }, []);

  const getPrinterTemplateList = useCallback(async () => {
    try {
      const profile = userProfiles.profile;
      const {
        isSuccess,
        data = [],
        message,
      } = await fetchPrintTemplates({
        TemplateType: 15,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      setRefeshTemplate(false);
      if (isSuccess) {
        setPrintTemplateList(data || []);
        if (data.length > 0) {
          form.setFieldsValue({ PrintTemplateNum: data[0].printTemplateNum });
        }
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshTemplate(false);
    }
  }, [form]);

  useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }
  }, [getPrinterList, refreshPrinter]);

  useEffect(() => {
    if (refreshTemplate) {
      getPrinterTemplateList();
      setRefeshTemplate(false);
    }
  }, [getPrinterTemplateList, refreshTemplate]);

  const exportPDF = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const Lines: { [key: string]: any } = {};
    const selectedNums = selectedRow.map(j=> j.fulfillmentPackLineNum)
    const selectedLine = lines.filter(k=> selectedNums.indexOf(k.fulfillmentPackLineNum) > -1)
    selectedLine.map((i) => {
      Lines[`${i.fulfillmentPackLineNum}`] = i.printQty;
      return true;
    });
    const formValues = await form.getFieldsValue();
    const { fulfillmentPackNum, packCode = '' } = detailData;
    const { PrintTemplateNum } = formValues;
    const profile = userProfiles.profile;
    setExportLoading(true);
    try {
      const res = await exportPackDetailPDF({
        FulfillmentPackNum: fulfillmentPackNum,
        PrintTemplateNum,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
        Lines,
      });
      const content = res;
      const blob = new Blob([content], { type: 'application/pdf' });
      const fileName = `pack-${packCode}.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('err = ', error);
    }
    setExportLoading(false);
  };

  /**
   * Preview  Return:  POST api/RMA/Preview   ,
      Body: 
        {
            "FulfillmentReturnNum": 10071,
            "PrintTemplateNum":9000
        }
   */
  const preview = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const Lines: { [key: string]: any } = {};
    const selectedNums = selectedRow.map(j=> j.fulfillmentPackLineNum)
    const selectedLine = lines.filter(k=> selectedNums.indexOf(k.fulfillmentPackLineNum) > -1)
    selectedLine.map((i) => {
      Lines[`${i.fulfillmentPackLineNum}`] = i.printQty;
      return true;
    });
    const formValues = await form.getFieldsValue();
    const profile = userProfiles.profile;
    const { fulfillmentPackNum } = detailData;
    const { PrintTemplateNum } = formValues;
    setPreviewLoading(true);
    try {
      const previewRes = await previewPackDetailList({
        FulfillmentPackNum: fulfillmentPackNum,
        PrintTemplateNum,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
        Lines,
      });

      if (
        typeof previewRes === 'object' &&
        typeof previewRes.code === 'number' &&
        previewRes.code !== HTTP_STATUS_OK
      ) {
        if (previewRes.message) {
          message.info(previewRes.message);
        }
      } else {
        const w = window.open('about:blank');
        w?.document.write(previewRes);
      }
    } catch (err) {
      console.log(err);
    }
    setPreviewLoading(false);
  };

  const print = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    if (!formValues.PrinterNum) {
      message.warning({ content: 'Please select a printer' });
      return;
    }
    const Lines: { [key: string]: any } = {};
    const selectedNums = selectedRow.map(j=> j.fulfillmentPackLineNum)
    const selectedLine = lines.filter(k=> selectedNums.indexOf(k.fulfillmentPackLineNum) > -1)
    selectedLine.map((i) => {
      Lines[`${i.fulfillmentPackLineNum}`] = i.printQty;
      return true;
    });
    setPrintLoading(true);
    try {
      const profile = userProfiles.profile;
      const { fulfillmentPackNum } = detailData;
      const selectedPrinter = printerList.filter(
        (item) => item.printerNum === formValues.PrinterNum
      )[0];
      const params = {
        ...formValues,
        FulfillmentPackNum: fulfillmentPackNum,
        PrinterAccountNum: selectedPrinter.printerAccountNum,
        ProfileNum: profile.profileNum,
        User: profile.email,
        MasterAccountNum: profile.masterAccountNum,
        Lines,
        // Time: moment().format('YYYY-MM-DD hh:mm:ss')
      };
      dispatch(printPackDetail(params));
    } catch (err) {
      console.log(err);
    }
    setPrintLoading(false);
  };

  const columns = [
    {
      name: 'prepackSKU',
      header: 'Prepack SKU',
      minWidth: 220,
      userSelect: true,
      showColumnMenuTool: false,
      defaultFlex: 1,
    },
    {
      name: 'printQty',
      header: 'Print Qty',
      sortable: false,
      showColumnMenuTool: false,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        const { printQty } = data;
        return (
          <Space>
            <InputNumber
              value={printQty}
              min={0}
              // max={requestQty}
              onChange={(qty) => {
                setLines((prev) => {
                  const temp = prev;
                  const index = prev.findIndex(
                    (item) =>
                      item.fulfillmentPackLineNum ===
                      data.fulfillmentPackLineNum
                  );
                  if (index > -1) {
                    temp[index] = {
                      ...temp[index],
                      printQty: qty,
                    };
                    return [...temp];
                  } else {
                    return prev;
                  }
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  useEffect(()=>{
    console.log('selectedRow',selectedRow)
  },[selectedRow])

  useEffect(()=>{
    console.log('lines',lines)
  },[lines])

  return (
    <Modal
      title={props.title ? props.title : 'Print'}
      centered
      visible={true}
      width={800}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button id="close_button" onClick={onHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
          <Space>
          {false &&<Button
              style={{ marginRight: 12 }}
              onClick={() => preview()}
              loading={previewLoading}
            >
              <FileSearchOutlined />
              Preview
            </Button>}
            <Button
              type="primary"
              style={{ marginRight: 12 }}
              loading={printLoading}
              onClick={() => print()}
            >
              <PrinterOutlined />
              Print
            </Button>
            <Button loading={exportLoading} onClick={() => exportPDF()}>
              Export to PDF
            </Button>
          </Space>
        </Row>
      }
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label={
            <FormItemRow style={{ alignItems: 'center' }}>
              <FormLabel>Select A Template</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshTemplate(true)}
              />
            </FormItemRow>
          }
          name="PrintTemplateNum"
          rules={[
            {
              required: true,
              message: 'Please select a template',
            },
          ]}
        >
          <Select placeholder="Select a print template" style={{ width: 300 }}>
            {printTemplateList.map((item, index) => (
              <Select.Option key={index} value={item.printTemplateNum}>
                {item.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <FormItemRow style={{ alignItems: 'center' }}>
              <FormLabel>Printer</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{
                      fontSize: 12,
                      color: 'green',
                    }}
                  />
                }
                onClick={() => setRefeshPrinter(true)}
              />
            </FormItemRow>
          }
          name="PrinterNum"
        >
          <Select
            ref={ref}
            placeholder="Select a printer"
            style={{ width: 300 }}
            optionLabelProp="label"
          >
            {printerTree?.map((item) => {
              return (
                <Select.OptGroup key={item.label} label={item.label}>
                  {item.options.map((citem: any) => {
                    return (
                      <Select.Option
                        key={citem.value}
                        value={citem.value}
                        label={`${item.label} - ${citem.label}`}
                      >
                        {citem.label}
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })}
          </Select>
        </Form.Item>
        {/* <Form.Item name="LineNums" label={<FormLabel>Choose which ones to print</FormLabel>} initialValue={lines.map(i=>i.fulfillmentPackLineNum)}>
          <Select mode="multiple">
            {lines.map(i=><Select.Option key={i.fulfillmentPackLineNum} value={i.fulfillmentPackLineNum}>{i.prepackSKU}</Select.Option>)}
          </Select>
        </Form.Item> */}

        <DataGridPlus
          autoWith={false}
          autoHeight={false}
          style={{ height: 250 }}
          showHoverRows={false}
          columns={columns}
          dataSource={lines}
          idProperty="fulfillmentPackLineNum"
          showColumnMenuTool={false}
          selected={selected}
          checkboxColumn
          onSelectionChange={(props) =>
            onSelectionChange(
              props,
              lines,
              setSelected,
              setSelectedRow,
              'fulfillmentPackLineNum'
            )
          }
          checkboxOnlyRowSelect={true}
          {...({} as any)}
        />
      </Form>
    </Modal>
  );
};
