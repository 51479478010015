import React, { useState, useCallback } from 'react';
import ScanSKU from './ScanSKU';
import ScanLocation from './ScanLocation';
import ScanQty from './ScanQty';
import { SetDefaultWarehouseDialog } from 'components/common';
import { fetchPickingBySKU } from 'services/smart';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';

export default function PickList(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [locations, setLocations] = useState<any[]>([]);
  const [product, setProduct] = useState<SmartPickSKU>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [step, setStep] = useState<'scanSKU' | 'scanLocaiton' | 'scanQty'>(
    'scanSKU'
  );
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  const fetchLocations = async () => {
    try {
      const res = await fetchWarehouseLocationList(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        ),
        false
      );
      setLocations(res);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshProduct = useCallback(async () => {
    try {
      const res = await fetchPickingBySKU(
        `${product?.sku}_${product?.pickListNum}`
      );

      if (res.isSuccess) {
        setProduct(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [product]);

  React.useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      fetchLocations();
    }
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanSKU' && (
        <ScanSKU
          onSubmit={(nextProduct: SmartPickSKU) => {
            setProduct(nextProduct);
            setStep('scanLocaiton');
          }}
        />
      )}
      {step === 'scanLocaiton' && product && (
        <ScanLocation
          product={product}
          locations={locations}
          warehouseId={getWarehouseIdFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}
          onSubmit={(nextLocation) => {
            setLocation(nextLocation);
            setStep('scanQty');
          }}
          onBack={() => {
            setProduct(undefined);
            setStep('scanSKU');
          }}
        />
      )}
      {step === 'scanQty' && product && location && (
        <ScanQty
          product={product}
          location={location}
          onSubmit={(nextProduct: SmartPickSKU) => {
            setProduct(nextProduct);
            setStep('scanLocaiton');
          }}
          onBack={(nextStep) => {
            setLocation(undefined);
            if (nextStep !== 'scanLocaiton') {
              setProduct(undefined);
            }
            if (nextStep === 'scanLocaiton') {
              refreshProduct();
            }
            setStep(nextStep);
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
