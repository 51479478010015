import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import lazyCache from 'middlewares/lazyCacheMiddleware';
import signalR from 'middlewares/signalRMiddleware';
import reducers from 'reducers';

const middleware = [lazyCache, signalR, thunk];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profiles', 'routes'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  //reducers,
  persistedReducer,
  //applyMiddleware(...middleware),
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
