import React, { useEffect, useCallback } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
//import { MessageOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import {
  SectionWrapper,
  ExtendableText,
  FormLabel,
  HelpMessage,
  HeaderTitle,
  Loading,
  message,
  PrintLabel as PrintLabelDialog,
} from 'components/common';
import {
  ButtonBox,
  ButtonIcon,
  Label,
  TextPaddingBox,
} from 'components/common/styles';
import {
  COMMON_PADDING_SPACE,
  LOADING_ICON_SIZE1,
  // LOADING_ICON_SIZE3,
  LS_DEFAULT_WAREHOUSE_KEY,
  //USER_ERR_MSG_DEFAULT_DISPLAY_DURATION,
} from 'constants/config';
import { fetchCountNotes } from 'services/audits';
import { fetchWarehouseProductList } from 'services/inventory';
import { fetchWarehouseLocationParents } from 'services/warehouse';
import {
  convertTimeByUtc,
  getWarehouseIdFromCache,
  getWarehouseCodeFromCache,
} from 'utils';
import AuditContent from './AuditContent';
import AddNoteDialog from './AddNoteDialog';
import MarkCountedDialog from './MarkCountedDialog';
// import PrintLocationLabelDialog from './PrintLocationDialog';
import ScanToMoveDialog from './ScanToMoveDialog';
import { AuditContentWrapper } from './style';
import WarehouseSelectBox from './SelectWarehouse';
import WarehouseLocationSearchBox from './SelectLocation';
import { SetDefaultWarehouseDialog } from 'components/common';
import { PrinterOutlined, PushpinOutlined, SearchOutlined } from '@ant-design/icons';
//import { searchMockAuditProducts } from '../../../mocks/inventory';

type AuditPorps = {
  modalWarehouseNum?: number;
  modalWarehouseId?: string;
  modalLocationNum?: number;
  modalLocationId?: string;
  modalLocationCode?: string;
  modalWarehouseCode?: string;
  isModal?: boolean;
};

// eslint-disable-next-line
export default (props: AuditPorps) => {
  const {
    modalWarehouseNum,
    modalLocationNum,
    modalLocationId,
    modalLocationCode,
    modalWarehouseCode,
    isModal,
  } = props;
  /**
   * Add Note dialog visible state.
   */
  const [addNoteDialogVisible, setAddNoteDialogVisible] = React.useState(false);
  /**
   * Whether the inventory list has been loaded.
   */
  const [contentReady, setContentReady] = React.useState(false);
  const [warehouseNum, setWarehouseNum] = React.useState(
    isModal
      ? modalWarehouseNum || -1
      : Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) || -1
  );
  const [isLoadingLastCount, setIsLoadingLastCount] = React.useState(false);
  /**
   * Whether is loading the location's path.
   */
  // const [isLoadingLocationPath, setIsLoadingLocationPath] =
  //   React.useState(false);
  const [isLoadingProduct /*setIsLoadingProduct*/] = React.useState(false);
  const [isSearchingProduct, setIsSearchingProduct] = React.useState(false);
  const [lastCount, setLastCount] = React.useState<CountNoteRow>(
    {} as CountNoteRow
  );
  const [locationNum, setLocationNum] = React.useState(modalLocationNum || -1);
  const [locationId, setLocationId] = React.useState<string | undefined>(modalLocationId || undefined);
  const [locationPath, setLocationPath] = React.useState<
    {
      [key: string]: number | string;
    }[]
  >([]);
  const [markCountedDialogVisible, setMarkCountedDialogVisible] =
    React.useState(false);
  // const [paramSkip, setParamSkip] = React.useState(0);
  // const [paramTop, setParamTop] = React.useState(10);
  const [paramSortBy /*setParamSortBy*/] = React.useState('ProductTitle');
  const [paramCalculateTotal /*setParamCalculateTotal*/] = React.useState(true);
  const [paramCount /*setParamCount*/] = React.useState(true);
  const [printLocationLabelDialogVisible, setPrintLocationLabelDialogVisible] =
    React.useState(false);
  const [scanToMoveDialogVisible, setScanToMoveDialogVisible] =
    React.useState(false);
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState<{
    [key: string]: any;
  }>(
    isModal
      ? {
        id: modalLocationNum,
        code: modalLocationCode,
        strId: modalLocationId,
        warehouseNum: modalWarehouseNum,
      }
      : {}
  );
  const [productList, setProductList] = React.useState<InventoryProductRow[]>(
    []
  );
  const [paramLimit, setParamLimt] = React.useState<number>(10);
  const [paramSkip, setParamSkip] = React.useState<number>(0);
  const [count, setCount] = React.useState(0);
  const [searchType, setSearchType] = React.useState('SKU');
  const [searchContent, setSearchContent] = React.useState('');

  const [selectedInventoryList, setSelectedInventoryList] = React.useState<InventoryProductRow[]>([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState<Array<number>>([]);
  const [upcList, setUpcList] = React.useState<string[]>([]);
  const [skuList, setSkuList] = React.useState<string[]>([]);

  const searchInputRef = React.useRef<any>(null);
  const locationItemSearchPlaceholder =
    locationNum > -1
      ? !!searchType
        ? 'Search content'
        : ''
      : 'Choose a location at first';
  const filterStyle = { paddingRight: COMMON_PADDING_SPACE };

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  const setSelectedInventoriesByIds = React.useCallback(
    (ids: Array<number>, list?: InventoryProductRow[]) => {
      const prdList = list ? list : productList;

      setSelectedInventoryList(
        prdList.filter((e, index) => ids.indexOf(index) > -1)
      );
      const skus = prdList.map((item) => item.sku || '');
      const upcs = prdList.map((item) => item.upc || '');
      setSkuList(skus);
      setUpcList(upcs);
    },
    [productList]
  );


  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  /**
   * Get the product filter. The filter is like sku cn 'xxx'.
   *
   * @return {string}
   */
  const getProductSearchFilter = useCallback(
    (keyword: string, id: number = locationNum) => {
      // const keys = ['SKU', 'LotNumber', 'ProductTitle'];
      const filters =
        searchType === 'SKU'
          ? `(SKU eq '${keyword}' or UPC eq '${keyword}' or FNSKU eq '${keyword}')`
          : searchType === 'SKU contains'
            ? `SKU cn '${keyword}'`
            : `${searchType} cn '${keyword}'`;
      const locationClause = `LocationNum eq ${id}`;
      return keyword ? `${locationClause} and ${filters}` : locationClause;
      // return '';
    },
    [locationNum, searchType]
  );

  /**
   * Get the path of current loation.
   *
   * @param {number} id - the location id
   */
  const getLocationPath = useCallback(
    async (id: string) => {
      if (warehouseNum <= 0) {
        return message.error('Default warehouse Info no found');
      }

      // setIsLoadingLocationPath(true);

      try {
        const parents = await fetchWarehouseLocationParents(
          getWarehouseIdFromCache(warehouseNum),
          id
        );
        const path = parents.reverse();

        // setIsLoadingLocationPath(false);
        //console.log('path', path);
        setLocationPath(path);
      } catch (e) {
        // setIsLoadingLocationPath(false);
        message.error(`Fetch location path error: ${e}`);
        console.log('fetch location parents error: ', e);
      }
    },
    [warehouseNum]
  );

  /**
   * Get the last count note.
   * Currently the API returns a count note list, this is not efficient.
   */
  const getLastCountNote = useCallback(
    async (warehouseNum: number, locationNum: number) => {
      try {
        const list = await fetchCountNotes(
          getWarehouseIdFromCache(warehouseNum),
          locationNum
        );

        setIsLoadingLastCount(false);
        console.log('count list', list);
        if (list.length > 0) {
          setLastCount(list[0]);
        } else {
          setLastCount({} as CountNoteRow);
        }
      } catch (e) {
        setLastCount({} as CountNoteRow);
        setIsLoadingLastCount(false);
        console.log('fetch last count note error:', e);
      }
    },
    []
  );

  /**
   * Get the product list for selected warehouses.
   *
   * @param {number} warehouseId - the id of warehouse, note: 0 is all warehouses
   * @param {?string} filter - SQL filter
   */
  const getWarehouseProductList = useCallback(
    async (
      warehouseNum: number,
      //warehouseId: string,
      skip: number,
      top: number,
      filter?: string,
      sort?: string
    ) => {
      let query: StringKAnyVPair = {
        $skip: skip,
        $top: top,
        $sortBy: sort || paramSortBy,
        $calculateTotal: paramCalculateTotal,
        $count: paramCount,
      };

      if (filter) {
        query.$filter = filter;
        console.log('filter', filter);
      }

      let products;

      try {
        products = await fetchWarehouseProductList(
          getWarehouseIdFromCache(warehouseNum),
          query
        );
        if (products && 'object' === typeof products) {
          setContentReady(true);
          setCount(products.count);
          setProductList(
            products.data.map((e: any, i: number) => {
              return {
                ...e,
                id: i,
                uuid: uuid(),
                code: 'code',
                sku: e.sku,
                upc: e.upc,
                title: e.productTitle,
                location: e.locationCode,
                locationId: locationId,
                locationNum: e.locationNum,
                lotNumber: e.lotNumber,
                warehouse: e.warehouseCode,
                warehouseId: e.warehouseNum,
                quantity: e.quantity,
                partNumber: e.partNumber,
                picture: e.pictureUrl,
                productNum: e.fulfillmentProductNum,
                productId: e.productId,               
              };
            })
          );
        }
      } catch (e) { }
    },
    [locationId, paramCalculateTotal, paramCount, paramSortBy]
  );

  /**
   * Select loation according the path breadcrumb.
   */
  // const handleLocationPathRedirect = (id: number) => {
  //   if (id > 0 && id !== locationNum) {
  //     console.log('resel loc', id, locationNum);
  //     setLocationNum(id);
  //   }
  // };

  /**
   * Search items in a special locations.
   */
  const handleSearchLocationItem = useCallback(
    async (search: string, evt: any, force?: boolean) => {
      //console.log('search', warehouseId, locationId, search);
      //const searchValue = search.trim();

      if (!force && 'INPUT' === evt.currentTarget.tagName) {
        return;
      }
      if (searchContent) {
        if (!searchType) {
          message.error('Please select a search type');
          return;
        }
      }

      const filter = getProductSearchFilter(search.trim());
      setIsSearchingProduct(true);
      await getWarehouseProductList(warehouseNum, 0, 10, filter);
      setIsSearchingProduct(false);
      console.log('pl', productList);
      setIsLoadingLastCount(true);
      getLastCountNote(warehouseNum, locationNum);
      emptySelectedRows();

      if (locationPath.length === 0) {
        getLocationPath(locationId || '');
      }
      /*
    setTimeout(() => {
      // use mock data
      const products = searchMockAuditProducts();
      console.log("p", products);
      setProductList(products);
      setIsSearchingProduct(false);
    }, 1500);
    */
    },
    [
      getLastCountNote,
      getLocationPath,
      getProductSearchFilter,
      getWarehouseProductList,
      locationId,
      locationNum,
      locationPath,
      productList,
      searchContent,
      searchType,
      warehouseNum,
    ]
  );

  const emptySelectedRows = () => {
    setSelectedRowIds([]);
    setUpcList([]);
    setSkuList([]);
    setSelectedInventoryList([]);
  }

  // const onSelectLocation = async (id: number) => {
  //   setLocationId(id);
  //   setContentReady(false);
  //   setProductList([]);
  //   setSearchType('');
  //   setSearchContent('');
  //   /*
  //   const filter = getProductSearchFilter('', id);
  //   setIsLoadingProduct(true);
  //   await getWarehouseProductList(warehouseId, 0, 10, filter);
  //   setIsLoadingProduct(false);
  //   getLocationPath(id);
  //   setIsLoadingLastCount(true);
  //   getLastCountNote(warehouseNum, id);
  //   */
  // };

  const onSelectLocationOption = (option: StringKAnyVPair) => {
    if (option) {
      setLocationNum(option.id);
      setLocationId(option.strId);
    } else {
      setLocationNum(-1);
      setLocationId(undefined);
    }

    setContentReady(false);
    setProductList([]);
    setSearchContent('');
    console.log(option);
    setSelectedLocation({ ...option, warehouseNum });
  };

  const onSelectWarehouse = (id: number) => {
    setWarehouseNum(id);
    setLocationNum(-1);
    setLocationId(undefined);
    setContentReady(false);
    setProductList([]);
    setSearchContent('');
    setSelectedLocation({});
  };

  const openAddNoteDialog = () => {
    setAddNoteDialogVisible(true);
  };

  const openMarkCountedDialog = () => {
    setMarkCountedDialogVisible(true);
  };

  const openPrintLocationLabelDialog = () => {
    setPrintLocationLabelDialogVisible(true);
  };

  const openScanToMoveDialog = () => {
    setScanToMoveDialogVisible(true);
  };

  /**
   * Refresh the last count. When mark counted, this function is needed.
   */
  const refreshLastCount = useCallback(async () => {
    setIsLoadingLastCount(true);
    await getLastCountNote(warehouseNum, locationNum);
  }, [getLastCountNote, locationNum, warehouseNum]);

  /**
   * Refresh the product list. When add a new product, this function is needed.
   */
  const refreshProductList = useCallback(
    async (skip: number, limit: number, sort?: string) => {
      const filter = getProductSearchFilter(searchContent);
      await getWarehouseProductList(warehouseNum, skip, limit, filter, sort);
    },
    [
      getProductSearchFilter,
      getWarehouseProductList,
      searchContent,
      warehouseNum,
    ]
  );

  useEffect(() => {
    if (isModal) {
      refreshProductList(0, 10);
      refreshLastCount();
    }
  }, [isModal, refreshProductList, refreshLastCount]);

  return (
    <>
      <Col>
        {!isModal && (
          <HeaderTitle
            breadcrumb={['Inventory', 'Audit']}
            padding={0}
            transparent={true}
          />
        )}
        <SectionWrapper>
          <Row justify="start">
            <Row align="middle" justify="start" style={{ width: '100%' }}>
              <Col xl={6} md={12} sm={24} style={filterStyle}>
                <FormLabel>Warehouse</FormLabel>
                {isModal ? (
                  <Input disabled value={modalWarehouseCode} />
                ) : (
                  <WarehouseSelectBox
                    id="warehouse_selector"
                    onChange={onSelectWarehouse}
                  />
                )}
              </Col>
              <Col xl={6} md={12} sm={24} style={filterStyle}>
                <FormLabel>Location</FormLabel>
                {isModal ? (
                  <Input disabled value={modalLocationCode} />
                ) : (
                  <WarehouseLocationSearchBox
                    id="warehouse_location_search"
                    warehouseNum={warehouseNum}
                    // onChange={onSelectLocation}
                    onChangeOption={onSelectLocationOption}
                    value={locationNum}
                    checkFlow={true}
                  />
                )}
              </Col>
              <Col xl={6} md={12} sm={24} style={filterStyle}>
                <FormLabel>Search Type</FormLabel>
                <Select
                  id="search_type_selector"
                  allowClear
                  disabled={locationNum < 0}
                  style={{ width: '100%' }}
                  defaultValue={searchType}
                  onChange={(value) => {
                    setSearchType(value);
                    //setSearchContent('');
                  }}
                >
                  <Select.Option key={1} value="SKU">
                    SKU / UPC equals
                  </Select.Option>
                  <Select.Option key={2} value="SKU contains">
                    SKU contains
                  </Select.Option>
                  {/*<Select.Option key={2} value="LotNumber">
                  Lot Number
                </Select.Option>*/}
                  <Select.Option key={3} value="ProductTitle">
                    Product Title
                  </Select.Option>
                </Select>
              </Col>
              <Col xl={6} md={12} sm={24}>
                <FormLabel>Search Value</FormLabel>
                <HelpMessage placement="bottom" helpId={4} marginLeft={5} />
                <Input.Search
                  id="content_search"
                  ref={searchInputRef}
                  allowClear
                  disabled={locationNum < 0 /*|| !searchType*/}
                  enterButton={
                    <Button
                      id="search_btn"
                      className="ant-search-btn"
                      disabled={locationNum < 0 /*|| !searchType*/}
                      type="primary"
                    >
                      <SearchOutlined />
                      {/* <span>Search</span> */}
                    </Button>
                  }
                  loading={isSearchingProduct}
                  placeholder={locationItemSearchPlaceholder}
                  value={searchContent}
                  onChange={(evt) => {
                    setSearchContent(evt.target.value);
                  }}
                  onSearch={handleSearchLocationItem}
                  onPressEnter={(evt) =>
                    handleSearchLocationItem(searchContent, evt, true)
                  }
                />
              </Col>
            </Row>
          </Row>
        </SectionWrapper>
        {contentReady ? (
          <SectionWrapper className="non-top-section">
            {/* <Row>
              {isLoadingLocationPath ? (
                <Loading size={LOADING_ICON_SIZE3} />
              ) : (
                <PathBreadcrumb>
                  <Breadcrumb separator=">">
                    {locationPath.map((e, i) => (
                      <Breadcrumb.Item
                        key={i}
                        onClick={() =>
                          handleLocationPathRedirect(e.locationNum as number)
                        }
                      >
                        {e.locationCode}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </PathBreadcrumb>
              )}
            </Row> */}
            <Row align="middle" justify="center">
              <Col span={15}>
                <Row align="middle" justify="center">
                  <ButtonBox>
                    <Button onClick={openScanToMoveDialog} id="scan_to_move" icon={<ButtonIcon className="bi-upc-scan" />}>
                      Scan to Move
                    </Button>
                    <Button
                      onClick={openPrintLocationLabelDialog}
                      id="print_location_label"
                      icon={<PrinterOutlined />}
                    >
                      Print Location Label
                    </Button>
                    <Button
                      type="default"
                      onClick={openAddNoteDialog}
                      id="location_note"
                      icon={<ButtonIcon className="bi-journal-text" />}
                    >
                      Location Note
                    </Button>
                    <Button onClick={openMarkCountedDialog} id="mark_counted" icon={<PushpinOutlined />}>
                      Mark Counted
                    </Button>
                    {/*<Button>
                      View Location Notes
                      <ButtonIcon className="bi-journal-text" />
                    </Button>
                    <Button>
                      View Mark Counted Notes
                      <ButtonIcon className="bi-journal-text" />
                    </Button>*/}
                  </ButtonBox>
                </Row>
              </Col>
              <Col span={9}>
                <Row align="middle" justify="center">
                  {isLoadingLastCount ? (
                    <Loading />
                  ) : (
                    <>
                      <Col span={12}>
                        <TextPaddingBox style={{ padding: 2 }}>
                          <Label>
                            <span className="label-bold">Last Count Date:</span>
                            <br />
                            <ExtendableText className="label-grey" rows={1}>
                              {lastCount.id > 0
                                ? convertTimeByUtc(lastCount.noteTime)
                                : 'Never'}
                            </ExtendableText>
                          </Label>
                        </TextPaddingBox>
                      </Col>
                      <Col span={12}>
                        <TextPaddingBox style={{ padding: 2 }}>
                          <Label>
                            <span className="label-bold">Last Count Note:</span>{' '}
                            <br />
                            <ExtendableText className="label-grey" rows={1}>
                              {lastCount.notes}
                            </ExtendableText>
                          </Label>
                        </TextPaddingBox>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
            <div>
              <AuditContentWrapper>
                <AuditContent
                  warehouseNum={warehouseNum}
                  location={selectedLocation}
                  refreshProductFn={(edit) => {
                    if (edit) {
                      return refreshProductList(paramSkip, paramLimit);
                    } else {
                      return refreshProductList(0, 10);
                    }
                  }}
                  productList={productList}
                  count={count}
                  setLocationId={setLocationNum}
                  setSelectedInventoriesByIds={setSelectedInventoriesByIds}
                  selectedInventoryList={selectedInventoryList}
                  skuList={skuList}
                  upcList={upcList}
                  selectedRowIds={selectedRowIds}
                  setSelectedRowIds={setSelectedRowIds}
                  onPageChange={(limit, skip) => {
                    setParamLimt(limit);
                    setParamSkip(skip);
                    refreshProductList(skip, limit);
                  }}
                />
              </AuditContentWrapper>
            </div>
          </SectionWrapper>
        ) : isLoadingProduct ? (
          <Loading size={LOADING_ICON_SIZE1} style={{ margin: '30px 0' }} />
        ) : (
          ''
        )}
      </Col>
      {addNoteDialogVisible && warehouseNum > 0 && locationId && (
        <AddNoteDialog
          warehouseId={getWarehouseIdFromCache(warehouseNum)}
          locationId={locationId}
          onHide={() => setAddNoteDialogVisible(false)}
        />
      )}
      {markCountedDialogVisible && (
        <MarkCountedDialog
          warehouseId={getWarehouseIdFromCache(warehouseNum)}
          locationId={locationId || ''}
          locationNum={locationNum}
          onHide={() => setMarkCountedDialogVisible(false)}
          refreshLastCountFn={refreshLastCount}
        />
      )}
      {/* <PrintLocationLabelDialog
        visible={printLocationLabelDialogVisible}
        onHide={() => setPrintLocationLabelDialogVisible(false)}
      /> */}
      {scanToMoveDialogVisible && warehouseNum > 0 && (
        <ScanToMoveDialog
          warehouseId={getWarehouseIdFromCache(warehouseNum)}
          warehouseNum={warehouseNum}
          locationNum={locationNum}
          onSuccess={() => {
            setScanToMoveDialogVisible(false);
            refreshProductList(0, 10);
          }}
          onHide={() => setScanToMoveDialogVisible(false)}
        />
      )}
      {printLocationLabelDialogVisible && (
        <PrintLabelDialog
          visible={true}
          onHide={() => setPrintLocationLabelDialogVisible(false)}
          printType="location"
          labelData={[
            {
              code: selectedLocation.code,
              title: '',
              quantity: 1,
            },
          ]}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
};
