import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import CatalogView from 'components/Catalog/CatalogView';

// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <CatalogView />
      </SiteContent>
    </ContentLayout>
  );
};
