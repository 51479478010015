import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  font-size: 1.2em;
  font-weight: 400;
  height: 100%;
`;
export const TitleBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ececec;
`;
export const StatisticsBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
`;
export const StatisticsItem = styled.div`
  flex-grow: 1;
  height: 60px;
  display: flex;
  flex-direction: column;
`;
export const StatisticsName = styled.div`
  flex-grow: 1;
  text-align: left;
  padding-left: 4px;
  font-size: 1em;
  font-weight: 600;
`;
export const StatisticsValue = styled.div`
  flex-grow: 1;
  text-align: left;
  padding-left: 4px;
  font-size: 1em;
  background-color: #fafafa; ;
`;
export const TabTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TableHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  background-color: #fafafa;
`;
export const TipBox = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`;
export const Tip = styled.div`
  width: 820px;
  height: 120px;
  background-color: #d6e4ff;
  position: relative;
  border-radius: 5px;
  border-color: #85a5ff;
  border-width: 1px;
  padding: 20px 10px 20px 10px;
`;
export const CloseTip = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #d6e4ff;
`;

export const LineBox = styled.div`
  width: 380px;
`;
export const LineView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  width: 380px;
  height: 30px;
`;
export const Tr = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px -1px -1px 0px;
`;
