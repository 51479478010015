import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const Toggler = styled.span`
  float: right;
  color: #40a9ff;

  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
  rows?: number;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { Paragraph } = Typography;
  const { children, className } = props;
  const rows = props.rows || 1;
  const [ellipsis, setEllipsis] = React.useState(false);
  const [inited, setInited] = React.useState(false);
  const [overLength, setOverLength] = React.useState(false);

  const handleEllipsis = () => {
    setEllipsis(!ellipsis);
  };

  React.useEffect(() => {
    if (!inited) {
      setEllipsis(true);
      setInited(true);
    }
  }, [inited]);

  return (
    <Paragraph
      className={className}
      ellipsis={
        ellipsis
          ? {
            rows,
            expandable: false,
            onEllipsis: (ellipsis) => {
              setOverLength(true);
            },
          }
          : false
      }
    >
      {overLength && ellipsis ? (
        <Toggler onClick={handleEllipsis}>more</Toggler>
      ) : (
        ''
      )}
      {children}
      {overLength && !ellipsis ? (
        <Toggler onClick={handleEllipsis}>less</Toggler>
      ) : (
        ''
      )}
    </Paragraph>
  );
};
