import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Row, Card, Form, Col, DatePicker, Space, Button, Select } from 'antd';
import {
  ClearOutlined,
  CloudDownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  FormLabel,
  HeaderTitle,
  // SetDefaultWarehouseDialog,
  SelectWarehouse,
  TablesFilter,
  // CopySpan,
} from 'components/common';
// import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import {
  fetchShipmentPackageHistory,
  exportShipmentPackageHistory,
} from 'services/reports';
import { convertToUtcTime, sortByString } from 'utils';
// import { userProfiles } from 'utils/auth';
import DataGridPlus from 'components/common/DataGrid';
import { getCustomers } from 'services/return';
import { fetchStoreList } from 'services/storeSetup';
// import { getPackageTypes } from 'services/shipment';

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState<PoReceiveHistory[]>([]);
  const [count, setCount] = useState<number>(0);
  const [paramLimit, setParamLimit] = React.useState<number>(10);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const skipRef = useRef<number>(0);
  const [customers, setCustomers] = useState<any[]>([]);
  const [channelAccounts, setChannelAccounts] = useState<any[]>([]);
  // const [packageTypes, setPackageTypes] = useState<any[]>([]);

  const [form] = Form.useForm();
  const columns = useCallback(() => {
    return [
      {
        name: 'customerName',
        header: 'Customer Name',
        userSelect: true,
        minWidth: 110,
        defaultFlex: 1,
      },
      {
        name: 'customerCode',
        header: 'Customer Code',
        userSelect: true,
        minWidth: 110,
        defaultFlex: 1,
      },
      {
        name: 'channelAccountName',
        header: 'Store Name',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
      {
        name: 'package',
        header: 'Package Name',
        userSelect: true,
        minWidth: 110,
        defaultFlex: 1,
      },
      {
        header: 'Package Type',
        name: 'packageType',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
        // render: (value: any) => {
        //   const { data } = value;
        //   return <CopySpan inGrid value={packageTypes[data.packageType]} />;
        // },
      },
      {
        header: 'Count',
        name: 'qty',
        userSelect: true,
        defaultFlex: 1,
        minWidth: 110,
      },
    ];
  }, []);

  const getHistory = useCallback(
    async (skip: number, top: number, ty: number) => {
      const { startDate, endDate, warehouse, CustomerCode, channelAccountNum } =
        await form.getFieldsValue();
      const $skip = ty ? 0 : skip;
      const $top = ty ? 1000 : top;

      const query = {
        Begin: convertToUtcTime(startDate.format()),
        End: convertToUtcTime(endDate.format()),
        WarehouseNum: warehouse.warehouseNum,
        ChannelAccountNum: channelAccountNum,
        Customer: CustomerCode,
      };
      try {
        setLoading(true);
        let res;
        if (ty) {
          res = await exportShipmentPackageHistory(
            ty,
            'customername desc',
            query
          );
        } else {
          res = await fetchShipmentPackageHistory(
            $skip,
            $top,
            'customername desc',
            query
          );
        }
        setLoading(false);
        if (ty) {
          const content = res;
          const blob = new Blob([content], {
            type: 'application/octet-stream',
          });
          const fileName = 'PackageMaterialSummary.csv';
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            //navigator.msSaveBlob(blob, fileName);
          }
        } else {
          setCount(res.total);
          setHistoryList(res.data);
        }
      } catch (err) {
        setLoading(false);
      }
    },
    [form]
  );

  const getCustomerInfo = useCallback(async () => {
    try {
      const res = await getCustomers();
      if (res.isSuccess) {
        setCustomers(res.data);
      }
    } catch (error) {}
  }, []);

  // const getTypes = useCallback(async () => {
  //   try {
  //     const res = await getPackageTypes();
  //     if (res.isSuccess) {
  //       const temp: any[] = [];
  //       res.data.map((i: any) => {
  //         temp[i.value] = i.name;
  //         return true;
  //       });
  //       setPackageTypes(temp);
  //     }
  //   } catch (error) {}
  // }, []);

  useEffect(() => {
    getCustomerInfo();
    // getTypes();
  }, [getCustomerInfo]);

  /* eslint-disable */
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setChannelAccounts(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);
  /* eslint-enable */

  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Package Material Summary']} />
      <Row justify="center">
        <Card style={{ width: '100%', minWidth: 400 }}>
          <Form form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <FormLabel>Warehouse</FormLabel>
                <Form.Item name="warehouse">
                  <SelectWarehouse showAll />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Start Date</FormLabel>
                <Form.Item
                  name="startDate"
                  initialValue={moment().subtract(1, 'days').startOf('day')}
                >
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>End Date</FormLabel>
                <Form.Item
                  name="endDate"
                  initialValue={moment().add(1, 'days').endOf('day')}
                >
                  <DatePicker
                    allowClear={false}
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Customer</FormLabel>
                <Form.Item name="CustomerCode">
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option) {
                        const { children = '', value = '' } = option;
                        return (
                          (typeof children === 'string' &&
                            children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0) ||
                          (typeof value === 'string' &&
                            value.toLowerCase().indexOf(input.toLowerCase()) >=
                              0)
                        );
                      }

                      return false;
                    }}
                  >
                    {customers.map((item) => (
                      <Select.Option
                        key={item.customerCode}
                        value={item.customerCode}
                      >
                        {item.customerName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Store</FormLabel>
                <Form.Item name="channelAccountNum">
                  <Select
                    showSearch
                    allowClear
                    placeholder="Please select"
                    style={{ width: '100%' }}
                    optionFilterProp="label"
                  >
                    {channelAccounts.map((item) => (
                      <Select.Option
                        key={item.channelAccountNum}
                        value={item.channelAccountNum}
                        label={item.channelAccountName}
                      >
                        {item.channelAccountName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="center" style={{ marginTop: 16 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  skipRef.current = 0;
                  getHistory(0, paramLimit, 0);
                }}
              >
                <SearchOutlined />
                Search
              </Button>
              <Button onClick={() => getHistory(0, paramLimit, 1)}>
                <CloudDownloadOutlined />
                Export CSV
              </Button>
              <Button onClick={() => form.resetFields()}>
                <ClearOutlined />
                Reset
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
      <Card style={{ marginTop: 20 }}>
        <TablesFilter
          dataSource={historyList}
          columns={columns()}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="_id"
          skip={skipRef.current}
          showScrollButton
          autoWith={false}
          loading={loading}
          columns={columns()}
          pageSizes={[10, 20, 50, 100]}
          dataSource={filteredData}
          pagination
          style={{ height: 500 }}
          count={count}
          onChange={(limit, skip) => {
            setParamLimit(limit);
            // setParamSkip(skip);
            skipRef.current = skip;
            getHistory(skip, limit, 0);
          }}
          {...({} as any)}
        />
      </Card>
    </>
  );
};
