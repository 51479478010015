import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

type Props = {
  value: boolean;
};

export default function BoolCell(props: Props) {
  const { value } = props;

  return <>{value ? <CheckOutlined /> : ''}</>;
}
