import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
//import { createStore, applyMiddleware, Store } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
//import storage from 'redux-persist/lib/storage';
//import thunk from 'redux-thunk';
//import { composeWithDevTools } from 'redux-devtools-extension';
//import signalR from './middlewares/signalRMiddleware';
//import message from 'components/common/message';
import { POPUP_WINDOW_LOCATION_PREFIX } from 'constants/config';
import PopupWindow from 'screens/PopupWindow';
import ResetApp from 'screens/ResetApp';
import authentication, { config } from 'utils/auth';
//import reducers, { RootState } from './reducers';
import App from 'App';
import store from 'store';

const RESET_PATH = '/reset';

const persistor = persistStore(store);

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
  window.addEventListener('error', (evt) => {
    //message.error(`Unkown error: ${evt}`);
    console.error(evt);
    evt.preventDefault();
  }, false);
};

const renderPopupWindow = () => {
  ReactDOM.render(<Provider store={store}>
      <PopupWindow />
    </Provider>,
    document.getElementById('root'),
  );
};

const renderResetApp = () => {
  ReactDOM.render(<Provider store={store}>
      <ResetApp />
    </Provider>,
    document.getElementById('root'),
  );
};

authentication.initialize(config);

//if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
//if (process.env.NODE_ENV === 'development') {
if (window.location.pathname.indexOf(`${POPUP_WINDOW_LOCATION_PREFIX}/`) > -1) {
  renderPopupWindow();
} else if (process.env.NODE_ENV === 'local') {
  renderApp();
} else if (process.env.NODE_ENV === 'staging' && window.location.pathname === '/test') {
  //console.log('->', window.location.pathname);
  renderApp();
} if (RESET_PATH === window.location.pathname) {
  renderResetApp();
} else {
  //console.log(window.location.pathname);
  if (!(process.env.NODE_ENV === 'staging' && window.location.pathname === '/test')) {
    authentication.run(renderApp);
  }
}
