import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Col, Dropdown, Modal, Menu, Tooltip, Row, Tabs } from 'antd';
import {
  CaretDownOutlined,
  // DeleteOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  PlusOutlined,
  PrinterOutlined,
  //SettingFilled,
} from '@ant-design/icons';
import { deselectAuditProductRows } from 'actions/inventoryActions';
import theme from 'assets/styles/theme';
import {
  Loading,
  PrintLabel as PrintLabelDialog,
  ScreenMask,
  SectionWrapper,
  TablesFilter,
  message,
} from 'components/common';
import {
  ButtonBox,
  Label,
  TextPaddingBox,
  // deleteIconStyle,
} from 'components/common/styles';
import { getWarehouseIdFromCache } from 'utils';
import { updateProductQuantity } from 'services/product';
import { TabPanelContentWrapper } from './style';
import AddItemDialog from './AddItemDialog2';
import AuditContainer from './AuditContainer';
import DeductItemDialog from './DeductItemDialog';
import LocationCountDialog from './LocationCountDialog2';
// import ProductGrid from './ProductGrid';
// import ProductInovuaGrid from './ProductInovuaGrid';
import MoveItemDialog from './MoveItemDialog';
import ProductTable from './ProductTable';

/**
 * The type definitin for the properties of the component.
 */
type Props = {
  warehouseNum: number;
  location: StringKAnyVPair;
  productList: InventoryProductRow[];
  count: number;
  setLocationId: Function;
  onPageChange: (limit: number, skip: number, srot?: string) => void;
  refreshProductFn?: (edit?: boolean) => Promise<void>;
  setSelectedInventoriesByIds: (ids: Array<number>, list?: InventoryProductRow[]) => void;
  selectedInventoryList: InventoryProductRow[];
  upcList: string[];
  skuList: string[];
  selectedRowIds: number[];
  setSelectedRowIds: Function;
};

// eslint-disable-next-line
export default (props: Props) => {
  const { useState } = React;
  const dispatch: Dispatch<any> = useDispatch();
  const TAB_CONTAINER_KEY = '2';
  const {
    location,
    refreshProductFn,
    productList,
    warehouseNum,
    count,
    onPageChange,
    setSelectedInventoriesByIds,
    selectedInventoryList,
    upcList,
    skuList,
    selectedRowIds,
    setSelectedRowIds
  } = props;
  const [actionButtonError, setActionButtonError] = useState(false);
  const [addItemDialogVisible, setAddItemDialogVisible] = useState(false);
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [locationContainerVisible, setLocationContainerVisible] =
    useState(false);
  const [locationCountDialogVisible, setLocationCountDialogVisible] =
    useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [editingInventoryList, setEditingInventoryList] = useState<
    InventoryProductRow[]
  >([]);
  const [editMoveQuantityDict, setEditMoveQuantityDict] = useState<{
    [key: number]: number;
  }>({});
  const [deductItemDialogVisible, setDeductItemDialogVisible] = useState(false);
  const [moveItemDialogVisible, setMoveItemDialogVisible] = useState(false);
  //const [productList, setProductList] = useState<InventoryProductRow[]>([]);
  const [printLabelVisible, setPrintLabelVisible] = React.useState(false);
  const warehouseId = getWarehouseIdFromCache(warehouseNum);
  const [filteredData, setFilteredData] = React.useState<InventoryProductRow[]>(
    []
  );
  const [columns, setColumns] = useState<any[]>([]);
  /**
   * The theme of the label.
   */
  const labelTheme = {
    fontSize: '1.2em',
  };

  const dropMenu = (
    <>
      <Menu>
        <Menu.Item key="0">
          <Button
            id="drop_menu_print_btn"
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={<PrinterOutlined />}
            onClick={() => printSelectedInventories()}
          >
            Print
          </Button>
        </Menu.Item>
        <Menu.Item key="1">
          <Button
            id="drop_menu_move_btn"
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={<ImportOutlined />}
            onClick={() => moveSelectedInventories()}
          >
            Move
          </Button>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <Button
            type="text"
            style={{ width: '100%', textAlign: 'left' }}
            icon={<DeleteOutlined style={deleteIconStyle} />}
            onClick={() => deductSelectedInventories()}
          >
            Deduct
          </Button>
        </Menu.Item> */}
      </Menu>
    </>
  );

  // const deductSelectedInventories = () => {
  //   if (selectedInventoryList.length > 0) {
  //     //console.log('deduct sel', selectedInventoryList);
  //     setEditingInventoryList(selectedInventoryList);
  //     setDeductItemDialogVisible(true);
  //   } else {
  //     message.info('Please select the rows need to deduct');
  //     setActionButtonError(true);
  //   }

  //   setDropdownMenuVisible(false);
  // };

  const getEditingInventoryList = () => {
    return selectedInventoryList
      .filter((e) => editMoveQuantityDict[e.id] > 0)
      .map((e) => {
        let ret = { ...e };

        ret.quantity = editMoveQuantityDict[e.id];

        return ret;
      });
  };

  const onActionDropdownVisibleChange = (visible: boolean) => {
    setDropdownMenuVisible(visible);

    if (visible) {
      setActionButtonError(false);
    }
  };

  /**
   * The callback for changing active tab panel.
   */
  const onChangeTab = (tabKey: string) => {
    setLocationContainerVisible(TAB_CONTAINER_KEY === tabKey);
  };

  const onEditMoveQuantity = (id: number, quantity: number) => {
    let dict = { ...editMoveQuantityDict };

    dict[id] = quantity;

    if (quantity <= 0) {
      delete dict[id];
    }

    setEditMoveQuantityDict(dict);
  };

  /**
   * Refresh the product list. It is used as a callback function for the product table grid component.
   */
  const refreshProductList = async (edit?: boolean) => {
    if ('function' === typeof refreshProductFn) {
      let dict = { ...editMoveQuantityDict };

      setIsLoadingProduct(true);
      await refreshProductFn(edit);
      setIsLoadingProduct(false);
      editingInventoryList.forEach((e) => delete dict[e.id]);
      setEditMoveQuantityDict(dict);
    }
  };

  const moveSelectedInventories = () => {
    if (selectedInventoryList.length > 0) {
      console.log('move sel', selectedInventoryList);
      setEditingInventoryList(selectedInventoryList);
      setMoveItemDialogVisible(true);
      /*
      const list = getEditingInventoryList();

      if (list.length > 0) {
        setEditingInventoryList(list);
        setMoveItemDialogVisible(true);
      } else {
        message.info('Please set the quantity need to move');
      }*/
    } else {
      message.info('Please select the rows need to move');
      setActionButtonError(true);
    }

    setDropdownMenuVisible(false);
  };

  const openAddItemDialog = () => {
    setAddItemDialogVisible(true);
  };

  const openLocationCountDialog = () => {
    setLocationCountDialogVisible(true);
  };

  const printSelectedInventories = () => {
    if (selectedInventoryList.length > 0) {
      message.info('Ready to print');
      console.log('print sel', selectedInventoryList);
      setPrintLabelVisible(true);
    } else {
      setActionButtonError(true);
      message.info('Please select the rows need to print');
    }

    setDropdownMenuVisible(false);
  };

  // eslint-disable-next-line
  const removeSelectedInventories = () => {
    if (selectedInventoryList.length > 0) {
      const list = getEditingInventoryList();

      if (list.length > 0) {
        console.log('remove', list);
        Modal.confirm({
          title: `Do you want to remove selected inventories?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            const update = list.map((e) => ({
              FulfillmentProductNum: e.productNum,
              WarehouseNum: e.warehouseId,
              LocationNum: e.locationId,
              Quantity: e.quantity,
              SKU: e.sku,
              TransactionType: 3,
            }));
            setLockScreen(true);
            console.log('up', update);
            updateProductQuantity(update)
              .then(async (res) => {
                setLockScreen(false);

                if (res) {
                  let dict = { ...editMoveQuantityDict };

                  message.success(`Remove inventories successfully`);
                  await refreshProductList();
                  list.forEach((e) => delete dict[e.id]);
                  setEditMoveQuantityDict(dict);
                  dispatch(
                    deselectAuditProductRows(
                      true,
                      list.map((e) => e.id)
                    )
                  );
                  setTimeout(() => {
                    dispatch(deselectAuditProductRows(false));
                  }, 100);
                } else {
                  message.error(`Remove inventories failded.`);
                }
              })
              .catch((e) => {
                setLockScreen(false);
                message.error(`Remove inventories error: ${e}`);
              });
          },
          onCancel() { },
        });
      } else {
        message.info('Please set the quantity need to remove');
      }
    } else {
      message.info('Please select the rows need to remove');
    }

    setDropdownMenuVisible(false);
  };

  return (
    <>
      <Tabs type="card" onChange={onChangeTab}>
        <Tabs.TabPane tab="Products" key="1">
          <TabPanelContentWrapper>
            <SectionWrapper>
              <Row align="middle" justify="space-between">
                <TablesFilter
                  dataSource={productList}
                  setFilteredData={setFilteredData}
                  columns={columns}
                />
                <Col>
                  <TextPaddingBox>
                    <Label theme={labelTheme}>
                      Location:{' '}
                      <span className="label-grey">{location.code}</span>
                    </Label>
                  </TextPaddingBox>
                </Col>
                <Col>
                  <TextPaddingBox>
                    <Label theme={labelTheme}>
                      Total Quantity:{' '}
                      <span className="label-grey">
                        {productList.length > 0
                          ? productList.reduce(
                            (acc, cur) => acc + cur.quantity,
                            0
                          )
                          : ''}
                      </span>
                    </Label>
                  </TextPaddingBox>
                </Col>
                <Col>
                  <Row justify="end" style={{ textAlign: 'right' }}>
                    <ButtonBox>
                      <Button onClick={openAddItemDialog} id="add_item_btn" icon={<PlusOutlined />}>Add Item</Button>
                      <Button onClick={openLocationCountDialog} id="location_count_btn">
                        Location Count
                      </Button>
                      <Tooltip
                        color={theme['@warning-color']}
                        placement="topRight"
                        title={'Please select rows to do related operation'}
                        visible={actionButtonError}
                      >
                        <Dropdown
                          onVisibleChange={onActionDropdownVisibleChange}
                          overlay={dropMenu}
                          trigger={['click']}
                          visible={dropdownMenuVisible}
                        >
                          <Button icon={<CaretDownOutlined />} id="more_action_btn">
                            More Actions
                          </Button>
                        </Dropdown>
                      </Tooltip>
                    </ButtonBox>
                  </Row>
                </Col>
              </Row>
            </SectionWrapper>
            <ProductTable
              count={count}
              isLoading={isLoadingProduct}
              locationId={location.strId}
              onEditMoveQuantity={onEditMoveQuantity}
              onSelectInventoryIdList={setSelectedInventoriesByIds}
              onPageChange={onPageChange}
              onSuccess={refreshProductList}
              productList={productList}
              filteredData={filteredData}
              quantityList={editMoveQuantityDict}
              warehouseId={warehouseId}
              selectedRowIds={selectedRowIds}
              setSelectedRowIds={setSelectedRowIds}
              setColumns={setColumns}
            />
            {locationCountDialogVisible && (
              <LocationCountDialog
                warehouseId={warehouseId}
                warehouseNum={warehouseNum}
                location={location}
                onSuccess={()=>{
                  setLocationCountDialogVisible(false)
                  refreshProductList(true)
                }}
                onHide={() => setLocationCountDialogVisible(false)}
              />
            )}
            {addItemDialogVisible && (
              <AddItemDialog
                warehouseId={warehouseId}
                warehouseNum={warehouseNum}
                location={location}
                visible={addItemDialogVisible}
                onHide={() => setAddItemDialogVisible(false)}
                onSuccess={refreshProductList}
              />
            )}
          </TabPanelContentWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Containers" key={TAB_CONTAINER_KEY}>
          <TabPanelContentWrapper>
            <AuditContainer
              warehouseNum={warehouseNum}
              warehouseId={warehouseId}
              location={location}
              setLocationId={props.setLocationId}
              visible={locationContainerVisible}
            />
          </TabPanelContentWrapper>
        </Tabs.TabPane>
        {/*<Tabs.TabPane tab="Products Inovua Grid" key="3">
        <TabPanelContentWrapper>
          <ProductInovuaGrid productList={productList} />
        </TabPanelContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Products Data Grid" key="4">
        <TabPanelContentWrapper>
          <ProductGrid productList={productList} />
        </TabPanelContentWrapper>
      </Tabs.TabPane>*/}
      </Tabs>
      <DeductItemDialog
        inventoryList={editingInventoryList}
        location={location}
        onHide={() => setDeductItemDialogVisible(false)}
        onSuccess={refreshProductList}
        visible={deductItemDialogVisible}
      />
      <MoveItemDialog
        inventoryList={editingInventoryList}
        warehouseId={warehouseId}
        location={location}
        onHide={() => setMoveItemDialogVisible(false)}
        onSuccess={refreshProductList}
        visible={moveItemDialogVisible}
      />
      {lockScreen ? (
        <ScreenMask>
          <Loading size={48} />
        </ScreenMask>
      ) : (
        ''
      )}
      {printLabelVisible && (
        <PrintLabelDialog
          visible={true}
          onHide={() => setPrintLabelVisible(false)}
          printType="product"
          labelData={selectedInventoryList.map((item) => {
            return {
              code: item.sku + '',
              title: item.title,
              quantity: 1,
            };
          })}
          upcList={upcList}
          skuList={skuList}
        />
      )}
    </>
  );
};
