import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Modal,
  Row,
  Space,
  Spin,
  Form,
  Col,
  Typography,
  Input,
  DatePicker,
  TimePicker,
  Table,
  Checkbox,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { convertTimeByUtc, sortByDate, sortByString, leaveEditForm } from 'utils';
import { message, FormLabel } from 'components/common';
import { fetchCountNotes, postCountNote } from 'services/audits';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TextArea } = Input;
type FormItemProps = {
  name?: string;
  label: string;
  children: ReactNode;
  message?: string;
  style?: React.CSSProperties;
};
const FormItem = (props: FormItemProps): JSX.Element => {
  const { name, label, children, message, style } = props;
  const rules = [{ required: message ? true : false, message }];
  let middleNode = children;
  let lastNode = null;
  if (Array.isArray(children)) {
    middleNode = children[0];
    lastNode = children[1];
  }
  return (
    <Row className="compact-row" style={style}>
      <Col span={9}>
        <Row>
          <Col span={22}>
            <Row justify="end">
              <FormLabel>{label}</FormLabel>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </Col>
      <Col span={12}>
        <Form.Item name={name} rules={rules}>
          {middleNode}
        </Form.Item>
        {lastNode}
      </Col>
      <Col span={3}></Col>
    </Row>
  );
};
type MarkCountedDialogProps = {
  warehouseId: string;
  locationId: string;
  locationNum: number;
  onHide: () => void;
  refreshLastCountFn?: () => Promise<void>;
};
export default function MarkCountedDialog(
  props: MarkCountedDialogProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [isCurrentTime, setIsCurrentTime] = useState(true);
  const [dataSource, setDataSource] = useState<Array<CountNoteRow>>([]);
  const [form] = Form.useForm();
  const { onHide, warehouseId, refreshLastCountFn, locationNum } = props;
  const currentDate = useMemo(() => {
    return moment();
  }, []);
  document.body.style.overflowY = 'hidden';

  const columns = [
    {
      title: 'Date/Time',
      dataIndex: 'noteTime',
      width: '40%',
      render: (value: string) => {
        //return dayjs(value).format('MM/DD/YYYY h:mm A');
        return convertTimeByUtc(value);
      },
      sorter: (a: any, b: any) => {
        return sortByDate(a, b, 'date');
      },
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      width: '60%',
      sorter: (a: any, b: any) => {
        return sortByString(a, b, 'notes');
      },
    },
  ];

  /**
   * fetch datasource
   */
  const fetchNotes = useCallback(async () => {
    const dataSource = await fetchCountNotes(warehouseId, locationNum);
    setDataSource(dataSource);
  }, [warehouseId, locationNum]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm(
      {
        date: currentDate,
        time: currentDate,
        notes: '',
      },
      form
    );
    if (cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    }
  }, [onHide, form, currentDate]);
  useEffect(() => {
    setLoading(true);
    form.setFieldsValue({
      date: currentDate,
      time: currentDate,
      notes: '',
    });
    fetchNotes().then(() => {
      setLoading(false);
    });
  }, [fetchNotes, form, currentDate]);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    if (note.length === 0) {
      message.warning({ content: 'Please Input Count Note' });
      return;
    }
    setLoading(true);
    const date = form.getFieldValue('date').toDate();
    const time = form.getFieldValue('time').toDate();
    const dateStr =
      dayjs(date).format('YYYY/MM/DD') + ' ' + dayjs(time).format('HH:mm:00');
    const newDate = dayjs(dateStr, 'YYYY/MM/DD HH:mm:ss').toDate();
    await postCountNote(warehouseId, locationNum, newDate, note);
    await fetchNotes();
    form.setFieldsValue({ notes: '', date: moment(), time: moment() });
    setLoading(false);
    message.success({ content: 'Created Count Note Successfully!' });
    if ('function' === typeof refreshLastCountFn) {
      refreshLastCountFn();
    }
    document.body.style.overflowY = 'unset';
    onHide();
  }, [
    form,
    note,
    onHide,
    warehouseId,
    locationNum,
    fetchNotes,
    refreshLastCountFn,
  ]);
  /**
   * handle notes change events
   */
  const handleNoteChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setNote(value);
    },
    []
  );
  const layout = {
    wrapperCol: { span: 24 },
  };
  return (
    <Modal
      forceRender
      title="Mark Counted"
      centered
      visible={true}
      width={650}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="confirm_btn"
              loading={loading}
              key="submit"
              type="primary"
              onClick={handleSubmit}
              icon={<CheckOutlined />}
            >
              Confirm
            </Button>
            <Button id="cancel_btn" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form} style={{ marginTop: 10 }}>
          <FormItem label="Modify Date & Time">
            <Checkbox
              id="current_time_checkbox"
              style={{ position: 'absolute', top: 0 }}
              checked={!isCurrentTime}
              onChange={(e) => {
                const { checked } = e.target;
                setIsCurrentTime(!checked);
              }}
            />
          </FormItem>
          <FormItem name="date" label="Date">
            <DatePicker
              id="date_picker"
              disabled={isCurrentTime}
              format="MM/DD/YYYY"
              allowClear={false}
            />
          </FormItem>
          <FormItem name="time" label="Time">
            <TimePicker
              id="time_picker"
              disabled={isCurrentTime}
              format="h:mm A"
              style={{ width: 135 }}
              allowClear={false}
            />
          </FormItem>
          <FormItem name="notes" label="Count Note">
            <TextArea id="note_change_input" rows={4} maxLength={1000} onChange={handleNoteChange} />
            <Text>Characters Used:{note.length} of 1000</Text>
          </FormItem>
        </Form>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 4 }}
          size="small"
        />
      </Spin>
    </Modal>
  );
}
