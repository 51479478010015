import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * Use modal to confirm whether leave current page or dialog.
 * @param {Function} leaveFn - the callback when leave page/dialog
 */
export const confirmLeave = (leaveFn: Function, message?: string) => {
  Modal.confirm({
    title: 'Leave / Refresh Page?',
    content: message || 'Changes you made may not be saved.',
    icon: <ExclamationCircleOutlined />,
    okText: 'Leave',
    okType: 'primary',
    cancelText: 'Cancel',
    onOk() {
      leaveFn();
    },
    onCancel() {},
  });
};
