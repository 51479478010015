import React from 'react';
import {
  BackTop,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  //InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from 'antd';
import {
  FileSearchOutlined,
  //PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { isEqualObject } from 'crud-object-diff';
import moment from 'moment';
import {
  FormLabel,
  FormField,
  HeaderTitle,
  HelpMessage,
  Loading,
  ScreenMask,
  message,
  //ButtonIcon,
  MainTabs,
  InfoButton,
  //SecondaryButton,
  ErrorOrderLine,
} from 'components/common';
import {
  FormVSpacer,
  SectionWrapper,
} from 'components/common/styles/CustomPropsComponents';
import {
  COMMON_DATE_RANGE_FORMAT,
  COMMON_PADDING_SPACE,
  LOADING_ICON_SIZE1,
  LS_DEFAULT_WAREHOUSE_KEY,
  REGULAR_SECTION_VERTICAL_SPACING,
} from 'constants/config';
//import { queryBrands } from 'services/brand';
import {
  addPickListProfile,
  deletePickListProfile,
  fetchPendOrderPickListCount,
  //fetchChannelAccounts,
  //pendOrderPickList,
} from 'services/sales';
//import { fetchAllShipAccountServices } from 'services/ship';
//import { newCancelToken } from 'services/http';
import { convertToUtcTime, makeJoinString, splitStringToArray } from 'utils';
import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
// eslint-disable-next-line
import CarrierSelector from './CarrierSelector';
import ChannelAccountSelector from './ChannelAccountSelector';
//import CheckboxGroupWrapper from './CheckboxGroupWrapper';
//import DateInputSelector from './DateInputSelector';
import FilterProfileList from './FilterProfileList';
import MappedServiceSelector from './MappedServiceSelector';
import PreviewPickList from './PreviewPickList';
//import RequestedServiceSelector from './RequestedServiceSelector';
import SaveFilterProfileDialog from './SaveFilterProfileDialog';
//import UserSelectBox from './UserSelectBox';
import {
  notFoundProductOptions,
  orderLineQtyOptions,
  outOfStockOptions,
  //printStateOptions,
  productKitStateOptions,
} from './PickOptions';
import { SectionHeader } from './styles';
import ScanOrderNumberDialog from './ScanOrderNumberDialog';
import { getCountrys } from 'services/address';
import { getWarehouseCodeFromCache } from 'utils';
import { ClassicCard } from 'components/common/styles';

/**
 * The type definition for Queue Picking list action.
 * 1 is Preview Picking List.
 * 2 is operation on filter profile.
 */
type QueuePickActionType = 1 | 2;

const QueuePickList = () => {
  const EDIT_FILTER_MODE_ADD = 1;
  const EDIT_FILTER_MODE_UPDATE = 2;
  const PROFILE_FILTER_TAB_KEY = '1';
  const PROFILE_LIST_TAB_KEY = '2';
  const { useState } = React;
  const { RangePicker } = DatePicker;
  const { TabPane } = MainTabs;
  const [allowReprint, setAllowReprint] = useState(false);
  const [duplicateOrder, setDuplicateOrder] = useState(false);
  const [brands, setBrands] = useState<number[]>([]);
  const [carrierNum, setCarrierNum] = useState<number>();
  const [codeOrSku, setCodeOrSku] = useState<string>();
  const [codeOrSkuError, setCodeOrSkuError] = useState(false);
  const [channelAccounts, setChannelAccounts] = useState<number[]>([]);
  const [currentProfileId, setCurrentProfileId] = useState(0);
  //const [editFilterProfile, setEditFilterProfile] = useState(true);
  const [departmentCode, setDepartmentCode] = useState('');
  const [departmentCodeError, setDepartmentCodeError] = useState(false);
  const [divisionCode, setDivisionCode] = useState('');
  const [divisionCodeError, setDivisionCodeError] = useState(false);
  const [editFilterProfileMode, setEditFilterProfileMode] =
    useState(EDIT_FILTER_MODE_ADD);
  const [editFilterProfileDialogVisible, setEditFilterProfileDialogVisible] =
    useState(false);
  const [excludeRequestService, setExcludeRequestService] = useState(false);
  const [filterProfileFormSnapshot, setFilterProfileFormSnapshot] =
    useState<StringKAnyVPair>({});
  const [filterProfileName, setFilterProfileName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [groupCodeError, setGroupCodeError] = useState(false);
  const [holdOrder, setHoldOrder] = useState(false);
  const [inited, setInited] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [mappedServices, setMappedServices] = useState<string[]>([]);
  const [multipleWarehouseOrder, setMultipleWarehouseOrder] = useState(false);
  const [notFoundProductState, setNotFoundProductState] = useState<number>();
  const [notFoundProductStateError, setNotFoundProductStateError] =
    useState(false);
  //const [orderDateInputMode, setOrderDateInputMode] = useState(2);
  const [orderDateEnd, setOrderDateEnd] = useState<string | null>(null);
  const [orderDateStart, setOrderDateStart] = useState<string | null>(null);
  const [orderDateType, setOrderDateType] = useState<number | null>(null);
  const [orderLineQtyType, setOrderLineQtyType] = useState<number>();
  const [orderLineQtyTypeError, setOrderLineQtyTypeError] = useState(false);
  //const [orderDateSomeDaysAgo, setOrderDateSomeDaysAgo] = useState(5);
  const [outOfStockState, setOutOfStockState] = useState<number>();
  const [outOfStockStateError, setOutOfStockStateError] = useState(false);
  //const [pickList, setPickList] = useState<StringKAnyVPair[]>([]);
  const [pickListPrintState, setPickListPrintState] = useState<number>();
  //const [pickListPrintStateError, setPickListPrintStateError] = useState(false);
  const [previewListCount, setPreviewListCount] = useState(0);
  const [previewPickListVisible, setPreviewPickListVisible] = useState(false);
  const [previewQueryObject, setPreviewQueryObject] =
    useState<StringKAnyVPair>();
  //const [printDateInputMode, setPrintDateInputMode] = useState(2);
  const [printUser, setPrintUser] = useState<string>();
  //const [printUserError, setPrintUserError] = useState(false);
  const [productKitState, setProductKitState] = useState<number>();
  const [productKitStateError, setProductKitStateError] = useState(false);
  const [requestServiceString, setRequestServiceString] = useState('');
  const [requestServices, setRequestServices] = useState<string[]>([]);
  const [subGroupCode, setSubGroupCode] = useState('');
  const [excludeSKUS, setExcludeSKUS] = useState('');
  const [subGroupCodeError, setSubGroupCodeError] = useState(false);
  const [tabsActiveKey, setTabsActiveKey] = useState('1');
  const [warehouse, setWarehouse] = useState<string>();
  const [warehouseError, setWarehouseError] = useState(false);
  const [keywordType, setKeywordType] = useState<'ERPSalesOrderNumber' | 'ChannelOrderId'>('ChannelOrderId');
  const [keywordValue, setKeywordValue] = useState<string>('');
  const [orderDateValue, setOrderDateValue] = useState<any>();
  const [scanOrderNumberDialogVisible, setScanOrderNumberDialogVisible] =
    useState(false);
  const [warehouseNum, setWarehouseNum] = useState(
    getWarehouseCodeFromCache(
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    )
      ? Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      : -1
  );
  const previewPickListWrapperRef = React.useRef<any>(null);
  //const cancelToken = newCancelToken();
  const filterStyle = { paddingRight: COMMON_PADDING_SPACE };
  const [orderDateValueType, setOrderDateValueType] = useState<number>(0);
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  const [countries, setCountries] = useState<string[]>([]);

  // eslint-disable-next-line
  const getFilterProfileForm = () => {
    return {
      brands,
      channelAccounts,
      codeOrSku,
      holdOrder,
      mappedServices,
      multipleWarehouseOrder,
      notFoundProductState,
      orderLineQtyType,
      outOfStockState,
      pickListPrintState,
      printUser,
      productKitState,
      requestServices,
      warehouse,
      warehouseNum,
      excludeSKUS,
      countries,
      duplicateOrder,
    };
  };

  const isFilterProfileFormChanged = React.useMemo(() => {
    const form = JSON.parse(JSON.stringify(getFilterProfileForm()));

    //console.log('ff', form, filterProfileFormSnapshot);
    return isEqualObject(filterProfileFormSnapshot, form);
  }, [filterProfileFormSnapshot, getFilterProfileForm]);

  const fetchCountryList = async () => {
    try {
      const res = await getCountrys();
      if (res.isSuccess) {
        setCountryList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPickListCount = async (query: StringKAnyVPair) => {
    const params = {
      pageIndex: 1,
      pageSize: 1,
      total: 0,
    };

    return await fetchPendOrderPickListCount(params, query);
  };

  /*const getRequestedServicesOptions = async () => {
    let options = await getServicesOptions();

    if (options.length > 0) {
      options = [
        {
          label: 'All Requested Services',
          value: 0,
          isAll: true,
        },
        ...options,
      ];
    }

    return options;
  };

  const getServicesOptions = async () => {
    let options: any[] = [];

    try {
      const accounts = await fetchAllShipAccountServices();

      //console.log('account', accounts);
      if (accounts && Array.isArray(accounts)) {
        options = accounts.map((e) => ({
          label: e.service,
          value: e.service,
          isAll: false,
        }));
      }
    } catch (e) {
      console.log('fetch brands error', e);
    }

    return options;
  };*/

  const handlePreviewPickList = async () => {
    if (warehouse === undefined) {
      message.error('Please select a Warehouse');
      setWarehouseError(true);
      return false;
    }

    const utcFormat1 = 'YYYY-MM-DDTHH:mm:00';
    const utcFormat2 = 'YYYY-MM-DDTHH:mm:59';
    let str: string = (excludeSKUS || '')
      .trim()
      .replace(/[\r\n]/g, ',')
      .replace(/,+/g, ',')
      .replace('/,$/gi', '');
    str =
      str.substring(str.length - 1) === ','
        ? str.substring(0, str.length - 1)
        : str;
    const query = {
      allowReprint: allowReprint,
      MasterAccountNum: 0,
      ProfileNum: 0,
      WarehouseNum: warehouseNum,
      WarehouseCode: warehouse,
      MutipleWarehouseOrder: 0,
      SKU: codeOrSku,
      DivisionCode: divisionCode.trim(),
      DepartmentCode: departmentCode.trim(),
      GroupCode: groupCode.trim(),
      SubGroupCode: subGroupCode.trim(),
      OrderLineQtyType: orderLineQtyType,
      CustomOrderLineQty: 0,
      NotFoundProductStatus: notFoundProductState,
      OutOfStockStatus: outOfStockState,
      ProductKitStatus: productKitState,
      HoldStatus: 0,
      ChannelAccounts: channelAccounts.length > 0 ? channelAccounts : null,
      //"ReqeustServices": requestServices.length > 0 ? requestServices : null,
      RequestServices: requestServiceString.split('\n').filter((e) => e.trim()),
      ExcludeRequestServices: excludeRequestService,
      MappedCarrier: carrierNum,
      MappedServices: mappedServices.length > 0 ? mappedServices : null,
      OrderDateType: orderDateType,
      LastDayCount: 0,
      OrderStartDate: orderDateStart
        ? convertToUtcTime(orderDateStart, '', '', utcFormat1)
        : null,
      OrderEndDate: orderDateEnd
        ? convertToUtcTime(orderDateEnd, '', '', utcFormat2)
        : null,
      OrderDateValueType: orderDateValueType,
      SelectedPickListOrderNums: null,
      Countries: countries,
      ExcludeSKUS: str ? Array.from(new Set(str.split(','))) : undefined,
      DuplicateOrder: duplicateOrder ? 1 : 0,
      ChannelOrderId: keywordType === 'ChannelOrderId' && keywordValue ? keywordValue.trim() : undefined,
      ERPSalesOrderNumber: keywordType === 'ERPSalesOrderNumber' && keywordValue ? keywordValue.trim() : undefined
    };

    try {
      setLockScreen(true);
      // data count should be in a independent func
      const count = await getPickListCount(query);
      //console.log('post prepare', params, query, count);
      setPreviewListCount(count);
      setPreviewQueryObject(query);
      //const list = await pendOrderPickList(params, query);

      setLockScreen(false);
      //setPickList(list);
      setPreviewPickListVisible(true);
      setTimeout(() => {
        previewPickListWrapperRef?.current.scrollIntoView({
          behavior: 'smooth',
        });
      }, 300);
    } catch (e) {
      setLockScreen(false);
    }
  };

  const onAllowReprintChange = (evt: any) => {
    setAllowReprint(evt.target.checked);
  };

  const onDuplicateOrderChange = (checked: boolean) => {
    console.log(checked);
    setDuplicateOrder(checked);
  };

  /*const onChangeOrderDateSomeDaysAgo = (value: number) => {
    setOrderDateSomeDaysAgo(value);
  };*/

  const onChangeTab = (tabKey: string) => {
    setTabsActiveKey(tabKey);
  };

  /*const onCheckHoldOrder = (evt: any) => {
    setHoldOrder(evt.target.checked);
  };

  const onCheckMultipleWarehouseOrder = (evt: any) => {
    setMultipleWarehouseOrder(evt.target.checked);
  };*/

  const onEditProfile = (profile: StringKAnyVPair) => {
    setProfileFormValue(profile);
    setTabsActiveKey(PROFILE_FILTER_TAB_KEY);
  };

  const onInputDepartmentCode = (value: string) => {
    setDepartmentCode(value);
    setDepartmentCodeError(false);
  };

  const onInputDivisionCode = (value: string) => {
    setDivisionCode(value);
    setDivisionCodeError(false);
  };

  const onInputGroupCode = (value: string) => {
    setGroupCode(value);
    setGroupCodeError(false);
  };

  const onInputSkuOrCode = (value: string) => {
    setCodeOrSku(value);
    setCodeOrSkuError(false);
  };

  const onInputSubGroupCode = (value: string) => {
    setSubGroupCode(value);
    setSubGroupCodeError(false);
  };

  const onInputExcludeSKUS = (value: string) => {
    setExcludeSKUS(value);
  };

  /*const onSelectBrands = (values: Array<number>) => {
    setBrands(values);
  };*/

  const onRequestServiceInputChange = (value: string) => {
    if (value && typeof value === 'string') {
      setRequestServiceString(value);
    } else {
      setRequestServiceString('');
    }
  };

  const onRequestServiceTypeChange = (value: number) => {

    setExcludeRequestService(value === 1);
  };

  // eslint-disable-next-line
  const onSelectCarrier = (carrierId: number) => {
    setCarrierNum(carrierId);
    //setMappedServices([]);
  };

  const onSelectChannelAccounts = (values: Array<number>) => {
    setChannelAccounts(values);
    setRequestServices([]);
  };

  const onSelectDateRange = (dateArr: any, strArr: any) => {
    console.log('on range', dateArr, strArr);
    //const [startString, endString] = strArr;
    setOrderDateValue(dateArr);
    if (dateArr) {
      const [startDate, endDate] = dateArr;
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

      setOrderDateType(0);
      //setOrderDateStart(startString);
      //setOrderDateEnd(endString);
      setOrderDateStart(startDate.format(dateFormat));
      setOrderDateEnd(endDate.format(dateFormat));
    } else {
      setOrderDateStart(null);
      setOrderDateEnd(null);
    }
  };

  // eslint-disable-next-line
  const onSelectMappedServices = (values: Array<string>) => {
    setMappedServices(values);
  };

  const onSelectNotFoundProductStatus = (value?: number) => {
    setNotFoundProductState(value);

    if ('number' === typeof value) {
      setNotFoundProductStateError(false);
    }
  };

  const onSelectOrderLineQty = (value?: number) => {
    setOrderLineQtyType(value);

    if ('number' === typeof value) {
      setOrderLineQtyTypeError(false);
    }
  };

  const onselectCountry = (value: any) => {
    setCountries(value);
  };

  /*const onSelectOrderLineQtyRadio = (evt: any) => {
    if (typeof evt.target.value === 'number') {
      onSelectOrderLineQty(evt.target.value);
    }
  };*/

  const onSelectOutOfStockStatus = (value?: number) => {
    setOutOfStockState(value);

    if ('number' === typeof value) {
      setOutOfStockStateError(false);
    }
  };

  /*const onSelectPrintStatus = (value?: number) => {
    setPickListPrintState(value);

    if ('number' === typeof value) {
      setPickListPrintStateError(false);
    }
  };*/

  const onSelectProductKitStatus = (value?: number) => {
    setProductKitState(value);

    if ('number' === typeof value) {
      setProductKitStateError(false);
    }
  };

  const onSelectProfile = (profile: StringKAnyVPair) => {
    const form = setProfileFormValue(profile);

    setTabsActiveKey(PROFILE_FILTER_TAB_KEY);
    setFilterProfileFormSnapshot(JSON.parse(JSON.stringify(form)));
  };

  // eslint-disable-next-line
  const onSelectRequestServices = (values: Array<string>) => {
    setRequestServices(values);
  };

  /**
   * The callback for selecting warehouse option.
   */
  const onSelectWarehouse = (id: number, wh: StringKAnyVPair) => {
    setWarehouseNum(id);
    setWarehouse(wh.label);
    //console.log('sel', id, wh);

    if (id >= 0) {
      setWarehouseError(false);
    }
  };

  const prepareSaveFilterProfile = (mode: number) => {
    if (!validateUserInput(2)) {
      return;
    }

    setEditFilterProfileMode(mode);
    setEditFilterProfileDialogVisible(true);

    if (EDIT_FILTER_MODE_ADD === mode) {
      setFilterProfileName('');
    }
  };

  /**
   * Save profile filter.
   * @param {string} name - the profile name
   */
  const saveFilterProfile = async (name: string) => {
    const profile = {
      MutipleWarehouseOrder: multipleWarehouseOrder ? 1 : 0,
      OrderLineQtyType: orderLineQtyType,
      NotFoundProductStatus: notFoundProductState,
      OutOfStockStatus: outOfStockState,
      ProductKitStatus: productKitState,
      PickListPrintStatus: pickListPrintState,
      Hold: holdOrder ? 1 : 0,
      Warehouse: warehouse,
      WarehouseNum: warehouseNum,
      ChannelAccounts: makeJoinString(channelAccounts),
      Brands: makeJoinString(brands),
      ReqeustServices: makeJoinString(requestServices),
      MappedServices: makeJoinString(mappedServices),
      FulfillmentProductNum: 0,
      SKU: codeOrSku,
      ProductTitle: name,
      OrderDateType: 0,
      PrintDateType: 0,
      PrintBy: printUser,
    };

    console.log('profile', profile);
    setLockScreen(true);
    try {
      if (editFilterProfileMode === EDIT_FILTER_MODE_UPDATE) {
        try {
          // for updating, delete it first and then create a new
          const res = await deletePickListProfile(currentProfileId);

          if (!res) {
            message.error('Remove filter profile failed');
            return;
          }
        } catch (e) {
          message.error('Remove filter profile error');
          return;
        }
      }

      const save = await addPickListProfile(profile);

      if (save && 'object' === typeof save) {
        if (save.pickProfileNum) {
          const action =
            editFilterProfileMode === EDIT_FILTER_MODE_ADD ? 'Save' : 'Update';
          setCurrentProfileId(save.pickProfileNum as number);
          snapshotFilterProfileForm();
          message.success(`${action} filter profile successfully`);
        } else {
          message.error('Save filter profile failed');
        }
      } else {
        message.error('Save filter profile failed');
      }
    } catch (e) {
      message.error('Save filter profile error');
    }
    setLockScreen(false);
  };

  const saveFilterProfileDialogCallback = async (name: string) => {
    await saveFilterProfile(name);
    setTabsActiveKey(PROFILE_LIST_TAB_KEY);
  };

  /**
   * Open scan order number dialog and ready to scan.
   */
  // eslint-disable-next-line
  const scanOrderNumber = () => {
    setScanOrderNumberDialogVisible(true);
  };

  /**
   * Fill filter profile form with the special value.
   */
  const setProfileFormValue = (profile: StringKAnyVPair) => {
    //console.log('profile', profile);
    const form = {
      brands: splitStringToArray(profile.brands).map((e) => parseInt(e)),
      channelAccounts: splitStringToArray(profile.channelAccounts).map((e) =>
        parseInt(e)
      ),
      codeOrSku: profile.sku,
      holdOrder: profile.hold,
      mappedServices: splitStringToArray(profile.mappedServices),
      multipleWarehouseOrder: profile.mutipleWarehouseOrder,
      notFoundProductState: profile.notFoundProductStatus,
      orderLineQtyType: profile.orderLineQtyType,
      outOfStockState: profile.outOfStockStatus,
      pickListPrintState: profile.pickListPrintStatus,
      printUser: profile.printBy,
      productKitState: profile.productKitStatus,
      requestServices: splitStringToArray(profile.reqeustServices),
      warehouse: profile.warehouse,
      warehouseNum: profile.warehouseNum,
    };

    setCurrentProfileId(profile.pickProfileNum);
    setFilterProfileName(profile.productTitle);
    setPickListPrintState(form.pickListPrintState);
    setCodeOrSku(form.codeOrSku);
    setProductKitState(form.productKitState);
    setOutOfStockState(form.outOfStockState);
    setNotFoundProductState(form.notFoundProductState);
    setWarehouseNum(form.warehouseNum);
    setWarehouse(form.warehouse);
    setChannelAccounts(form.channelAccounts);
    setBrands(form.brands);
    setRequestServices(form.requestServices);
    setMappedServices(form.mappedServices);
    setOrderLineQtyType(form.orderLineQtyType);
    setPrintUser(form.printUser);
    setMultipleWarehouseOrder(form.multipleWarehouseOrder);
    setHoldOrder(form.holdOrder);

    return form;
  };

  // eslint-disable-next-line
  const snapshotFilterProfileForm = () => {
    setFilterProfileFormSnapshot(
      JSON.parse(JSON.stringify(getFilterProfileForm()))
    );
  };

  /**
   * Validate the user input, return false to indicate that the input is invalide.
   */
  const validateUserInput = (inputType: QueuePickActionType) => {
    /*if (pickListPrintState === undefined) {
      message.error('Please select Printed Orders');
      setPickListPrintStateError(true);
      return false;
    }*/

    if (warehouse === undefined) {
      message.error('Please select a Warehouse');
      setWarehouseError(true);
      return false;
    }

    if (inputType === 1 && !codeOrSku) {
      message.error('Please input the UPC or SKU');
      setCodeOrSkuError(true);
      return false;
    }

    if (orderLineQtyType === undefined) {
      message.error('Please select a Show Order');
      setOrderLineQtyTypeError(true);
      return false;
    }

    if (outOfStockState === undefined) {
      message.error('Please select a Out of Stock');
      setOutOfStockStateError(true);
      return false;
    }

    if (notFoundProductState === undefined) {
      message.error('Please select a Not Found Product');
      setNotFoundProductStateError(true);
      return false;
    }

    if (productKitState === undefined) {
      message.error('Please select Kit Orders');
      setProductKitStateError(true);
      return false;
    }

    /*
    if (printUser === undefined) {
      message.error('Please select a user of the print operation');
      setPrintUserError(true);
      return false;
    }*/

    return true;
  };

  const onKeywordValueChange = (e: any) => {
    const { value: keywordValue } = e.target;
    if (keywordValue) {
      onInputSkuOrCode('');
      onInputDivisionCode('');
      onInputDepartmentCode('');
      onInputGroupCode('');
      onInputSubGroupCode('');
      onSelectOrderLineQty(undefined);
      onselectCountry([]);
      onInputExcludeSKUS('');
      onSelectDateRange(null, ['', '']);
      onDuplicateOrderChange(false);
      onSelectOutOfStockStatus(undefined);
      onSelectNotFoundProductStatus(undefined);
      onSelectProductKitStatus(undefined);
      onSelectChannelAccounts([]);
      onRequestServiceTypeChange(0);
      onRequestServiceInputChange('');
      onSelectMappedServices([]);
    }
    setKeywordValue(keywordValue);
  }

  React.useEffect(() => {
    if (!inited) {
      snapshotFilterProfileForm();
      fetchCountryList();
      setInited(true);
    }
  }, [inited, snapshotFilterProfileForm]);

  return (
    <>
      {scanOrderNumberDialogVisible && (
        <ScanOrderNumberDialog
          warehouseNum={warehouseNum}
          onClose={() => {
            setScanOrderNumberDialogVisible(false);
          }}
        />
      )}
      <BackTop />
      <Col>
        <HeaderTitle
          breadcrumb={['Sales Order', 'Queue Pick List']}
          padding={0}
          transparent
        />
        <ErrorOrderLine warehouseNum={warehouseNum} />
        <MainTabs type="card" activeKey={tabsActiveKey} onChange={onChangeTab}>
          <TabPane tab="Filter Settings" key="1">
            <ClassicCard>
              <SectionHeader>Filter Settings</SectionHeader>
              <FormVSpacer />
              <Row>
                <Space>
                  <FormLabel noWrap>Warehouse</FormLabel>
                  <FormField error={warehouseError}>
                    <div style={{ width: 300 }}>
                      <WarehouseSelectBox
                        value={warehouseNum}
                        onChange={onSelectWarehouse}
                      />
                    </div>
                  </FormField>
                  <Checkbox onChange={onAllowReprintChange}>
                    Allow Reprint
                  </Checkbox>
                </Space>
              </Row>
              <FormVSpacer />
              <Row>
                <Space>
                  <FormLabel noWrap>UPC or SKU</FormLabel>
                  <FormField error={codeOrSkuError}>
                    <Input
                      onChange={e => onInputSkuOrCode(e.target.value)}
                      style={{ width: '100%' }}
                      value={codeOrSku}
                    />
                  </FormField>
                  <FormLabel noWrap>Division Code</FormLabel>
                  <FormField error={divisionCodeError}>
                    <Input
                      onChange={e => onInputDivisionCode(e.target.value)}
                      style={{ width: '100%' }}
                      value={divisionCode}
                    />
                  </FormField>
                  <FormLabel noWrap>Department Code</FormLabel>
                  <FormField error={departmentCodeError}>
                    <Input
                      onChange={e => onInputDepartmentCode(e.target.value)}
                      style={{ width: '100%' }}
                      value={departmentCode}
                    />
                  </FormField>
                  <FormLabel noWrap>Group Code</FormLabel>
                  <FormField error={groupCodeError}>
                    <Input
                      onChange={e => onInputGroupCode(e.target.value)}
                      style={{ width: '100%' }}
                      value={groupCode}
                    />
                  </FormField>
                  <FormLabel noWrap>Subgroup Code</FormLabel>
                  <FormField error={subGroupCodeError}>
                    <Input
                      onChange={e => onInputSubGroupCode(e.target.value)}
                      style={{ width: '100%' }}
                      value={subGroupCode}
                    />
                  </FormField>
                  {/*<Button
                    disabled={warehouseNum <= 0}
                    type="primary"
                    onClick={scanOrderNumber}
                  >
                    Scan Multiple Codes
                    <ButtonIcon className="bi-upc-scan" />
                  </Button>*/}
                </Space>
              </Row>
              <FormVSpacer />
              {/*<Row>
                <Space>
                  <FormLabel>Show Orders</FormLabel>
                  <Radio.Group
                    onChange={onSelectOrderLineQtyRadio}
                    value={orderLineQtyType}
                  >
                    <Radio value={0}>Show All</Radio>
                    <Radio value={1}>Show Item Orders</Radio>
                    <Radio value={2}>Multiple Items</Radio>
                    <Radio value={3}>Single Item Single Qty Order</Radio>
                    <Radio value={4}>Single Item Multiple Qty</Radio>
                    <Radio value={5}>
                      Single Item 
                      {' '}
                      <InputNumber />
                      {' '}
                      Qty
                    </Radio>
                  </Radio.Group>
                </Space>
              </Row>
              <FormVSpacer />*/}
              <Row>
                <Space>
                  <FormLabel noWrap>Show Orders</FormLabel>
                  <FormField error={orderLineQtyTypeError}>
                    <Select
                      allowClear
                      onChange={onSelectOrderLineQty}
                      options={orderLineQtyOptions}
                      style={{ width: '100%', minWidth: 150 }}
                      value={orderLineQtyType}
                      dropdownMatchSelectWidth={false}
                    />
                  </FormField>

                  <FormLabel noWrap>Country</FormLabel>
                  <FormField error={false}>
                    <Select
                      showSearch
                      mode="multiple"
                      optionFilterProp="label"
                      onChange={onselectCountry}
                      value={countries}
                      style={{ width: '100%', minWidth: 150 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {countryList.map((item) => (
                        <Select.Option
                          key={item.countryNum}
                          value={item.iso2}
                          label={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormField>
                  <FormLabel noWrap>Excluded SKU</FormLabel>
                  <FormField error={false}>
                    <Input.TextArea
                      rows={1}
                      value={excludeSKUS}
                      onChange={e => onInputExcludeSKUS(e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </FormField>
                  <Select
                    bordered={false}
                    value={keywordType}
                    options={[
                      { label: <FormLabel noWrap>Channel Order Id</FormLabel>, value: 'ChannelOrderId' },
                      { label: <FormLabel noWrap>Sales Order#</FormLabel>, value: 'ERPSalesOrderNumber' }
                    ]}
                    onChange={setKeywordType}
                  />
                  <FormField error={false}>
                    <Input
                      onChange={e => onKeywordValueChange(e)}
                      style={{ width: '100%' }}
                      value={keywordValue}
                    />
                  </FormField>
                </Space>
              </Row>
              <FormVSpacer />
              <Row>
                <Space>
                  {/* <FormLabel>Order Date</FormLabel> */}
                  <Select
                    bordered={false}
                    dropdownMatchSelectWidth={false}
                    defaultValue={0}
                    onChange={setOrderDateValueType}
                  >
                    <Select.Option value={0}><FormLabel noWrap>Order Date</FormLabel></Select.Option>
                    <Select.Option value={1}><FormLabel noWrap>Order Enter Date</FormLabel></Select.Option>
                  </Select>
                  :
                  <RangePicker
                    format={COMMON_DATE_RANGE_FORMAT}
                    onChange={onSelectDateRange}
                    value={orderDateValue}
                    placeholder={['Order Date From', 'Order Date To']}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      'Last Month': [
                        moment().add(-1, 'month').startOf('month'),
                        moment().add(-1, 'month').endOf('month'),
                      ],
                    }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'HH:mm:ss'),
                        moment('23:59:59', 'HH:mm:ss'),
                      ],
                    }}
                    style={{ width: '100%' }}
                  />
                  <Checkbox
                    id="isShowDuplicateOrder_checkbox"
                    onChange={e => onDuplicateOrderChange(e.target.checked)}
                    checked={duplicateOrder}
                  >
                    Duplicate Order Only
                  </Checkbox>
                </Space>
              </Row>
              <FormVSpacer />
              {/*<Row>
                <Space>
                  <FormLabel>Order Date</FormLabel>
                  <DateInputSelector
                    mode={orderDateInputMode}
                    onSwitch={setOrderDateInputMode}
                    tooltip1="Click to set several days ago"
                    tooltip2="Click to set date range"
                  />
                  {orderDateInputMode === 1 ? (
                    <RangePicker
                      style={{ width: '100%' }}
                      placeholder={['Order Date From', 'Order Date To']}
                      ranges={{
                        Today: [moment(), moment()],
                        'This Month': [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                      }}
                      showTime
                      format={COMMON_DATE_RANGE_FORMAT}
                    />
                  ) : (
                    <Space>
                      Order from
                      <InputNumber
                        min={0}
                        max={10000}
                        onChange={onChangeOrderDateSomeDaysAgo}
                        value={orderDateSomeDaysAgo}
                      />
                      days ago
                    </Space>
                  )}
                </Space>
              </Row>
              <FormVSpacer />*/}
              <Row>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Out of Stock (OOS)</FormLabel>
                  <HelpMessage placement="bottom" helpId={4} />
                  <FormField error={outOfStockStateError}>
                    <Select
                      allowClear
                      onChange={onSelectOutOfStockStatus}
                      options={outOfStockOptions}
                      style={{ width: '100%' }}
                      value={outOfStockState}
                    />
                  </FormField>
                </Col>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Not Found Products (NFP)</FormLabel>
                  <HelpMessage placement="bottom" helpId={4} />
                  <FormField error={notFoundProductStateError}>
                    <Select
                      allowClear
                      onChange={onSelectNotFoundProductStatus}
                      options={notFoundProductOptions}
                      style={{ width: '100%' }}
                      value={notFoundProductState}
                    />
                  </FormField>
                </Col>
                <Col span={8}>
                  <FormLabel>Show Kit Orders</FormLabel>
                  <HelpMessage placement="bottom" helpId={4} />
                  <FormField error={productKitStateError}>
                    <Select
                      allowClear
                      onChange={onSelectProductKitStatus}
                      options={productKitStateOptions}
                      style={{ width: '100%' }}
                      value={productKitState}
                    />
                  </FormField>
                </Col>
              </Row>
              <FormVSpacer />
              <Row>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Channel Accounts</FormLabel>
                  <FormField>
                    <ChannelAccountSelector
                      onChange={onSelectChannelAccounts}
                      value={channelAccounts}
                    />
                  </FormField>
                </Col>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Requested Services</FormLabel>
                  &nbsp;&nbsp;
                  <Radio.Group
                    onChange={e => onRequestServiceTypeChange(e.target.value)}
                    value={excludeRequestService ? 1 : 0}
                  >
                    <Radio value={0}>Include</Radio>
                    <Radio value={1}>Exclude</Radio>
                  </Radio.Group>
                  <FormField>
                    <Input.TextArea
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      onChange={e => onRequestServiceInputChange(e.target.value)}
                      placeholder="Service name"
                      value={requestServiceString}
                    />
                  </FormField>
                </Col>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Mapped Services</FormLabel>
                  <FormField>
                    <MappedServiceSelector
                      carrierNum={carrierNum}
                      onChange={onSelectMappedServices}
                      value={mappedServices}
                    />
                  </FormField>
                </Col>
              </Row>
              <FormVSpacer />
              {/*<Row>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Carrier</FormLabel>
                  <FormField>
                    <CarrierSelector
                      onChange={onSelectCarrier}
                      value={carrierNum}
                    />
                  </FormField>
                </Col>
                <Col span={8} style={filterStyle}>
                  <FormLabel>Mapped Services</FormLabel>
                  <FormField>
                    <MappedServiceSelector
                      carrierNum={carrierNum}
                      onChange={onSelectMappedServices}
                      value={mappedServices}
                    />
                  </FormField>
                </Col>
              </Row>
              <FormVSpacer />*/}
              <Row justify="center">
                <Space>
                  <Button onClick={handlePreviewPickList} type="primary">
                    <FileSearchOutlined />
                    Preview
                  </Button>
                  {/*<InfoButton
                    disabled={!!isFilterProfileFormChanged}
                    onClick={() =>
                      prepareSaveFilterProfile(EDIT_FILTER_MODE_ADD)}
                  >
                    Save as a new Filter Profile
                    <PlusOutlined />
                  </InfoButton>*/}
                  {currentProfileId > 0 && false ? (
                    <InfoButton
                      disabled={!!isFilterProfileFormChanged}
                      onClick={() =>
                        prepareSaveFilterProfile(EDIT_FILTER_MODE_UPDATE)
                      }
                    >
                      <SaveOutlined />
                      Update Existing Filter Profile
                    </InfoButton>
                  ) : (
                    ''
                  )}
                </Space>
              </Row>
              <FormVSpacer />
            </ClassicCard>
          </TabPane>
          <TabPane tab="Saved Profiles" key="2">
            <ClassicCard>
              <FilterProfileList
                editProfile={onEditProfile}
                newProfileId={currentProfileId}
                selectProfile={onSelectProfile}
                visible={tabsActiveKey === PROFILE_LIST_TAB_KEY}
              />
            </ClassicCard>
          </TabPane>
        </MainTabs>
        {previewPickListVisible ? (
          <>
            <FormVSpacer height={REGULAR_SECTION_VERTICAL_SPACING} />
            <SectionWrapper ref={previewPickListWrapperRef}>
              <PreviewPickList
                getOrderCount={getPickListCount}
                pickListCount={previewListCount}
                queryObject={previewQueryObject}
              />
            </SectionWrapper>
          </>
        ) : (
          ''
        )}
      </Col>
      {editFilterProfileDialogVisible ? (
        <SaveFilterProfileDialog
          close={() => setEditFilterProfileDialogVisible(false)}
          editMode={editFilterProfileMode}
          profileName={filterProfileName}
          save={saveFilterProfileDialogCallback}
          visible={editFilterProfileDialogVisible}
        />
      ) : (
        ''
      )}
      {lockScreen ? (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      ) : (
        ''
      )}
    </>
  );
};

export default QueuePickList;
