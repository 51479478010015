import React, { useState } from 'react';
import { Row, Typography, Button, Space, Table, Radio, Col, Modal } from 'antd';
import { sortByString } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import Modal from '../../Modal';

const { Text } = Typography;
type SelectCodeDialogProps = {
  products: Array<ProductRow>;
  // title: string;
  field: string;
  onSelect: (product: ProductRow) => void;
  onHide: () => void;
};
/**
 * Return SelectCode Dialog
 * @param {SelectCodeDialogProps} props
 * @returns {JSX.Element}
 */
export default function SelectCodeDialog(
  props: SelectCodeDialogProps
): JSX.Element {
  const [selectedItem, setSelectedItem] = useState<ProductRow>();
  const { onHide, products, onSelect, field } = props;
  const columns = [
    {
      render: (_: any, record: ProductRow) => {
        return (
          <Radio
            checked={selectedItem && record.id === selectedItem.id}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedItem(record);
              }
            }}
          />
        );
      },
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      sorter: (a: any, b: any) => {
        return sortByString(a, b, field);
      },
    },
    {
      title: 'Title',
      dataIndex: 'productTitle',
      sorter: (a: any, b: any) => {
        return sortByString(a, b, field);
      },
    },
  ];
  /**
   * Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button
            type="primary"
            disabled={!selectedItem}
            onClick={() => {
              if (selectedItem) {
                onSelect(selectedItem);
              }
            }}
            icon={<CheckOutlined />}
          >
            Use Selected SKU
          </Button>
          <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Select SKU"
      centered
      visible
      width={600}
      destroyOnClose
      onCancel={onHide}
      footer={<Footer />}
    >
      <Row>
        <Col span={24}>
          <Text>Select the product SKU to use:</Text>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <Table
            rowKey="id"
            dataSource={products}
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
    </Modal>
  );
}
