import React, { useCallback } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus } from 'components/common';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  onOk: (selectedPo: any) => void;
  poList: any[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, poList, onOk } = props;
  // const [selected, setSelected] = useState<PoRow>();
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  // const handleOk = useCallback(() => {
  //   if (selected) {
  //     onOk(selected);
  //     onHide();
  //   }
  // }, [selected, onHide, onOk]);
  const columns = [
    // {
    //   header: '',
    //   dataIndex: 'po',
    //   maxWidth: 50,
    //   render: (value: any) => {
    //     const { data } = value;
    //     return (
    //       <Radio
    //         checked={data.poNumber === selected?.poNumber}
    //         onClick={() => {
    //           console.log(data);
    //           setSelected(data);
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      header: 'Batch #',
      name: 'batchNumber',
      defaultFlex: 1,
    },
    // {
    //   header: 'Supplier',
    //   name: 'vendorName',
    //   defaultFlex: 1,
    // },
  ];
  return (
    <Modal
      title="Transfer List"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button id="hideDialog_btn" onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
            {/* <Button type="primary" onClick={handleOk}>
              Use Selected
            </Button> */}
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        onRowClick={(row) => {
          onOk(row.data);
        }}
        dataSource={poList}
        idProperty="transferNum"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
