import React, { useEffect, useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select, Input } from 'antd';
// import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import { SelectWarehouse } from 'components/common';
import {
  addShippingProviderAccount,
  editShippingProviderAccount,
} from 'services/shippingProviderAccount';
// import { getCarrierServices } from 'services/shipment';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  account: any;
  configInfo: any;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, account, configInfo} = props;
  const [loading, setLoading] = useState(false);
  const [shipProviderType, setShipProviderType] = useState<number>()

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  useEffect(()=>{
    if(account){
      setShipProviderType(account.shipProviderType)
    }
  },[account])

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
        ShippingProviderAccountNum: account? account.shippingProviderAccountNum : 0,
      ...params,
    };
    try {
      setLoading(true);
      let res;
      if (!account) {
        res = await addShippingProviderAccount(postParams);
      } else {
        res = await editShippingProviderAccount(postParams);
      }
      setLoading(false);
      if (res.isSuccess) {
        document.body.style.overflowY = 'unset';
        onHide();
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Modal
      title={`${account ? 'Edit' : 'Add'} Shipping Provider`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_or_add_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              {account ? <SaveOutlined /> : <PlusOutlined />}
              {loading
                ? `${account ? 'Saving' : 'Adding'}`
                : `${account ? 'Save' : 'Add'}`}
            </Button>
            <Button id="cancel_button" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="ShipProviderType"
            initialValue={
              account ? account.shipProviderType : undefined
            }
            label={<FormLabel>Type</FormLabel>}
            rules={[{ required: true, message: 'please select type' }]}
          >
            <Select id="ShipProviderType" onChange={setShipProviderType}>
                {
                    configInfo.providers.map((i: any)=> (
                        <Select.Option key={i.key} value={i.key}>{i.value}</Select.Option>
                    ))
                }
            </Select>
          </Form.Item>

          <Form.Item
            name="Name"
            initialValue={
              account
                ? account.name
                : undefined
            }
            label={<FormLabel>Name</FormLabel>}
            rules={[
              {
                required: true,
                message: 'please input name',
              },
            ]}
          >
            <Input id="name" />
          </Form.Item>

          <Form.Item
            name="Account"
            initialValue={account ? account.account : undefined}
            label={<FormLabel>{shipProviderType===3 ? 'User Name' : 'Account'}</FormLabel>}
            rules={[{ required: true, message: 'Please input!' }]}
          >
            <Input id="account" />
          </Form.Item>

          <Form.Item
            name="AppKey"
            initialValue={account ? account.appKey : undefined}
            label={<FormLabel>{shipProviderType ===3 ? 'Password' : 'APP Key'}</FormLabel>}
            rules={[{ required: true, message: 'please input' }]}
          >
            <Input type='password' id="appKey" />
          </Form.Item>

          {shipProviderType === 3 && <Form.Item
            name="Attr01"
            initialValue={account ? account.attr01 : undefined}
            label={<FormLabel>Integration ID</FormLabel>}
            rules={[{ required: true, message: 'please input' }]}
          >
            <Input id="Attr01" />
          </Form.Item>}

        </Form>
      </Spin>
    </Modal>
  );
};
