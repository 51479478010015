/**
 * restriction service requests are here.
 */
import { digitBridgeApi } from './http';


type CarrierServiceRestrictionParams = {
    CarrierServiceRestrictionNum: number,
    CarrierServiceNum: number,
    StateRestrictionNum: number,
    WeightRangeMin: number,
    WeightRangeMax: number,
    WeightUnit: number,
    LengthRangeMin: number,
    LengthRangeMax: number,
    DimensionUnit: number,
    WarehouseNum: number,
    LengthGirthRangeMin: number,
    LengthGirthRangeMax: number,
}

export const addCarrierServiceRestriction = async (params: CarrierServiceRestrictionParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/AddCarrierServiceRestriction', json);
}

export const copyCarrierServiceRestrictionList = async (warehouseNum: number): Promise<any> => {
    return await digitBridgeApi.get('/api/CopyCarrierServiceRestrictionList', { params: { warehouseNum } });
}

export const delCarrierServiceRestrictionById = async (id: number): Promise<any> => {
    return await digitBridgeApi.delete(`/api/carrierServiceRestrictions/${id}`);
}

export const getCarrierServiceRestrictionList = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/GetCarrierServiceRestrictionList');
}

export const updateCarrierServiceRestriction = async (params: CarrierServiceRestrictionParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/UpdateCarrierServiceRestriction', json);
}

export const getStateRestrictionList = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/GetStateRestrictionList');
}

