import React, { Dispatch, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog/OrderDetail';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetail';
import ShipmentDetail from 'components/Sales/ShipmentList/ShipmentDetail';
import BulkReturnDetail from 'components/Return/BulkReturn/ReturnDetail';
import {
  POPUP_WINDOW_LOCATION_PREFIX,
  POST_MSG_SET_ENV,
  POST_MSG_SET_TITLE,
  POST_MSG_SET_STATE,
} from 'constants/config';
import { tokenKeeper } from 'services/http';
import { userProfiles } from 'utils/auth';
import Audit from 'components/Inventory/Audit/index';
import { useDispatch } from 'react-redux';
import { setNewWindowAdminState } from 'actions/adminActions';

const PopupWindow = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const POPUP_PREFIX = POPUP_WINDOW_LOCATION_PREFIX;
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [propsData, setPropsData] = useState<Record<string, any>>();

  const initAdminState = useCallback(async (adminState: any)=>{
    await dispatch(setNewWindowAdminState(adminState))

  },[dispatch])
  React.useEffect(() => {
    if (!inited) {
      if (window.opener) {
        window.addEventListener('message', evt => {
          console.log('msg', evt);
          if (evt.origin !== window.location.origin) return;

          if (evt.data) {
            if (typeof evt.data === 'object' && evt.data.__action) {
              switch (evt.data.__action) {
                case POST_MSG_SET_ENV:
                  const pd = evt.data.data;

                  tokenKeeper.apiToken = pd.apiToken;
                  userProfiles.profiles = pd.profiles;
                  userProfiles.profileIndex = pd.profileIndex;
                  break;

                case POST_MSG_SET_TITLE:
                  window.document.title = evt.data.title;
                  break;
                
                case POST_MSG_SET_STATE:
                  initAdminState(evt.data.adminState)
                  break;

                default:
                  break;
              }
            } else {
              setPropsData(JSON.parse(JSON.stringify(evt.data)));
            }
          }
        }, false);
      }

      setInited(true);
    }
  }, [inited, initAdminState]);

  // const orderDetail = () => {
  //   return propsData ? (
  //     <OrderDetail
  //       fulfillmentOrderId={propsData.fulfillmentOrderId}
  //       fulfillmentOrderNum={propsData.fulfillmentOrderNum}
  //       onClose={propsData.onClose}
  //       {...propsData}
  //     />
  //   ) : (
  //     <div></div> //can not use null here
  //   );
  // };

  const routeComponent = () => {
    console.log('propsData', propsData)
    if (!propsData) return <div></div>
    const { path = '' } = propsData;
    if (path === 'product-detail') {
      return (
        <ProductDetail
          visible={propsData.visible}
          onHide={propsData.onHide}
          productId={propsData.productId}
        />
      )
    }
    if (path === 'shipment-detail') {
      return (
        <ShipmentDetail
          shipmentNum={propsData.shipmentNum}
          isHistory={propsData.isHistory}
        />
      )
    }
    if (path === 'bulk-return-detail') {
      return (
        <BulkReturnDetail
          onClose={propsData.onClose}
          modalReturn={propsData.modalReturn}
          receiveDisabled={propsData.receiveDisabled}
          specialInfo={propsData.receiveDisabled}
          defaultLocationCode={propsData.defaultLocationCode}
          defaultWarehouseType={propsData.defaultWarehouseType}
          {...propsData}
        />
      )
    }
    if (path === 'audit-detail') {
      return (
        <Audit
          modalWarehouseNum={propsData.product.warehouseNum}
          modalWarehouseId={propsData.product.warehouseId}
          modalLocationNum={propsData.product.locationNum}
          modalLocationId={propsData.product.locationId}
          modalLocationCode={propsData.product.locationCode}
          modalWarehouseCode={propsData.product.warehouseCode}
          isModal
        />
      )
    }
    return propsData ? (
      <OrderDetail
        fulfillmentOrderId={propsData.fulfillmentOrderId}
        fulfillmentOrderNum={propsData.fulfillmentOrderNum}
        onClose={propsData.onClose}
        {...propsData}
      />
    ) : (
      <div></div> //can not use null here
    );
  };

  return (<>
    <Router>
      <Switch>
        <Route
          // path={`${POPUP_PREFIX}/order-detail`}
          path={`${POPUP_PREFIX}/${propsData?.path || 'order-detail'}`}
          // component={orderDetail}
          component={routeComponent}
        />
      </Switch>
    </Router>
  </>);
};

export default PopupWindow;
