import React from 'react';
import ContentLayout from 'components/ContentLayout';
import { SiteContent } from 'components/common/styles';
import WarehouseTransferList from 'components/Inventory/WarehouseTransferList';

function WarehouseTransfer() {
  return (
    <ContentLayout>
      <SiteContent>
        <WarehouseTransferList />
      </SiteContent>
    </ContentLayout>
  );
}

export default WarehouseTransfer;
