import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, FormLabel, message } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import { receiveReturn } from 'services/return';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const warehouseTypeEnum: { [key: string]: string } = {
  '2': 'Return-Resalable',
  '3': 'Return-Damage',
  '4': 'Return-Inspection',
};

type Props = {
  onHide: () => void;
  lines: any[];
  returnNum: number;
  warehouse: WarehouseRow;
  onRefresh: () => void;
  dateString: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, lines, onRefresh, dateString, returnNum, warehouse } = props;
  const [loading, setLoding] = useState(false);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleReceive = useCallback(async () => {
    setLoding(true);
    const Items = lines.map((item) => {
      return {
        FulfillmentReturnLineNum: item.fulfillmentReturnLineNum,
        ReceiveQty: item.receivingQty,
        ReceiveWarehouseType: item.receivingWarehouseType,
        ReceiveWarehouseNum: item.receivingWarehouseNum,
        ReceiveLocationNum: item.receivingLocationNum,
      };
    });
    try {
      const res = await receiveReturn(warehouse.warehouseId, returnNum, Items);
      setLoding(false);
      if (res) {
        message.success({ content: 'receive successful' });
        onRefresh();
        onHide();
      } else {
        message.error({ content: 'receive failed' });
      }
    } catch (error: any) {
      // message.error({ content: error.response.data.error });
      setLoding(false);
    }
  }, [onHide, returnNum, lines, onRefresh, warehouse]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receivingQty',
      header: 'Receiving Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receivingWarehouseType',
      header: 'WarehouseType',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
      render: (value: any) => {
        const { data } = value;
        return warehouseTypeEnum[`${data.receivingWarehouseType}`] || '';
      },
    },
    {
      name: 'receivingWarehouseCode',
      header: 'Warehouse',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
      maxWidth: 200,
      minWidth: 200,
    },
    {
      name: 'receivingLocationCode',
      header: 'Location',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  return (
    <Modal
      title="Receive"
      centered
      visible={true}
      width={1000}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="receive_button"
              disabled={lines.length === 0}
              type="primary"
              loading={loading}
              onClick={() => handleReceive()}
              icon={<CheckOutlined />}
            >
              {loading ? 'Receiving' : 'Receive'}
            </Button>
            <Button id="close_button" onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Space>
        <FormLabel>Receipt Date</FormLabel>
        {moment(dateString).format('MM/DD/YYYY')}
      </Space>
      <DataGridPlus
        columns={columns}
        dataSource={lines}
        idProperty="fulfillmentTransferLineNum"
        {...({} as any)}
      />
    </Modal>
  );
};
