import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Space, Tabs, Empty } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { HeaderTitle, TablesFilter } from 'components/common';
// import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
// import {FormLabel} from 'components/common';
import { DataGridPlus } from 'components/common';
import CreateAccountDialog from './CreateShipAccountDialog';
import SelectCarrierDialog from './SelectCarrierDialog';
// import { getSystemSettingList } from 'services/systemSetting';
import {
  fetchShipAccountList,
  deleteShipAccount,
  // setDefaultShipAccount,
} from 'services/ship';
import { fetchCarriers } from 'services/shipment';
import { fetchWarehouseList } from 'services/warehouse';
import ShippingOptionsDialog from './ShippingOptionsDialog';
// import AccountDetailDialog from './AccountDetailDialog';
import { message } from 'components/common';
import { DeleteButton } from 'components/common';
import { HoverBgButton } from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import {
  getShippingProviderAccountsConfig,
} from 'services/shippingProviderAccount';

// const { Title } = Typography;
const { TabPane } = Tabs;

const Carriers = () => {
  // const [warehouseId] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [switchLoading, setSwitchLoading] = useState(false);
  const [actionType, setActionType] = useState<'add' | 'edit'>('add');
  const [createAccountVisible, setCreateAccountVisible] = useState(false);
  const [selectCarrierVisible, setSelectCarrierVisible] = useState(false);
  const [optionVisible, setOptionVisible] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState<number>(1);
  const [carriers, setCarriers] = useState<ShipAccountRow[]>([]);
  const [selected, setSelected] = useState<ShipAccountRow>();
  const [warehouses, setWarehouse] = useState<SimpleWarehouse[]>([]);
  const [tabCarries, setTabCarriers] = useState<ShipAccountRow[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [carrierKeyValue, setCarrierKeyValue] = useState<{
    [key: string]: any;
  }>({});
  // const [defaultShip, setDefaultShip] = useState<{ [key: string]: number }>({});
  const [configInfo, setConfigInfo] = useState<any>();

  const getWarehouses = async () => {
    try {
      const res = await fetchWarehouseList();
      const temp = res.filter((i: any) => i.warehouseType === 1 || i.warehouseType === 5)
      setWarehouse(temp);
      setActiveTab(
        localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || temp[0].warehouseNum
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getCarriers = async () => {
    try {
      const res = await fetchCarriers();
      const temp: { [key: string]: any } = {};
      res.map((item) => {
        temp[`${item.carrierNum}`] = item.name;
        return true;
      });
      // add DHL
      temp['5'] = 'DHL'
      setCarrierKeyValue(temp);
    } catch (error) { }
  };

  const getConfigInfo = useCallback(async () => {
    try {
      const res = await getShippingProviderAccountsConfig();
      if (res.isSuccess) {
        setConfigInfo(res.data);
      }
    } catch (error) {
    }
  }, []);

  const getAccountList = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchShipAccountList('');
      if (res.isSuccess) {
        setCarriers(
          res.data.map((item: ShipAccountRow) => {
            return {
              ...item,
              warehouseDtos: item.warehouseDtos.map((i) => i.warehouseNum),
            };
          })
        );
        // const arr: SimpleWarehouse[] = [];
        const ids: number[] = [];
        const defaultShipAccount: { [key: string]: number } = {};
        res.data.map((item: ShipAccountRow) => {
          if (item.warehouseDtos && item.warehouseDtos.length > 0) {
            item.warehouseDtos.map((i) => {
              if (ids.indexOf(i.warehouseNum) < 0) {
                if (i.isDefault) {
                  defaultShipAccount[`warehouse${i.warehouseNum}`] =
                    item.shipAccountNum;
                }
                // arr.push(i);
                ids.push(i.warehouseNum);
              }
              return true;
            });
          }
          return true;
        });
        // setWarehouse(arr);
        // setDefaultShip(defaultShipAccount);
        // if (arr.length > 0) {
        //   setActiveTab(`${arr[0].warehouseNum}`);
        // }
        setLoading(false);
      } else {
        message.error({ content: res.message });
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  // const getSeviceList = async () => {
  //   try {
  //     const res1 = await getCarrierServices('FedEx', 'ShipServices');
  //     const res2 = await getCarrierServices('UPS', 'ShipServices');
  //     const res3 = await getCarrierServices('USPS', 'ShipServices');
  //     if (res1.isSuccess && res2.isSuccess && res3.isSuccess) {
  //       setShipServiceList({
  //         FedEx: res1.data,
  //         UPS: res2.data,
  //         USPS: res3.data,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const delAccount = useCallback(
    async (account) => {
      try {
        const res = await deleteShipAccount(account.shipAccountNum);
        if (res) {
          message.success({ content: `Deleted ${account.name} Successfully` });
          getAccountList();
        } else {
          // message.error({ content: 'Delete failed' });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [getAccountList]
  );

  // const setDefaultShipforWarehouse = async (
  //   ShipAccountNum: number,
  //   WarehouseNum: number
  // ) => {
  //   try {
  //     const res = await setDefaultShipAccount({ ShipAccountNum, WarehouseNum });
  //     if (res.isSuccess) {
  //       message.success({ content: 'Set successfully' });
  //       getAccountList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const columns: Array<StringKAnyVPair> = [
    {
      header: 'Name',
      name: 'name',
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data, rowIndex } = value;
      //   return (
      //     <span id={`shipping_account_grid_name_row_${rowIndex}`}>
      //       {data.name}
      //     </span>
      //   );
      // },
    },
    {
      header: 'Carrier',
      name: 'carrierNum',
      defaultFlex: 1,
      renderEnum: carrierKeyValue,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <span id={`shipping_account_grid_carrier_row_${rowIndex}`}>
            {carrierKeyValue[data.carrierNum]}
          </span>
        );
      },
    },
    {
      header: 'Service',
      sortable: false,
      name: 'serviceCount',
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Button
            id={`shipping_account_grid_service_row_${rowIndex}_button`}
            type="link"
            onClick={() => {
              setSelected(data);
              setOptionVisible(true);
            }}
          >
            {`${data.serviceCount} services`}
          </Button>
        );
      },
    },
    // {
    //   header: 'Is Defalt',
    //   sortable: false,
    //   name: 'isDefalt',
    //   textAlign: 'center',
    //   maxWidth: 150,
    //   minWidth: 150,
    //   render: (value: any) => {
    //     const { data } = value;
    //     return (
    //       <Space>
    //         {defaultShip[`warehouse${activeTab}`] === data.shipAccountNum ? (
    //           <span>Default</span>
    //         ) : (
    //           <Popconfirm
    //             title={`Are you sure you want to set [${data.name}] as default?`}
    //             onConfirm={() =>
    //               setDefaultShipforWarehouse(
    //                 data.shipAccountNum,
    //                 Number(activeTab)
    //               )
    //             }
    //             onCancel={() => {}}
    //             okText="Yes"
    //             cancelText="No"
    //           >
    //             <Button type="link">Set as default</Button>
    //           </Popconfirm>
    //         )}
    //       </Space>
    //     );
    //   },
    // },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      groupBy: false,
      sortable: false,
      textAlign: 'center',
      maxWidth: 300,
      minWidth: 300,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Space>
            <HoverBgButton
              hovertype="info"
              id={`shipping_account_grid_action_row_${rowIndex}_edit_button`}
              onClick={() => {
                setActionType('edit');
                setSelectedCarrier(data.carrierNum);
                setSelected(data);
                setCreateAccountVisible(true);
              }}
            >
              <EditOutlined />
              Edit
            </HoverBgButton>
            <DeleteButton
              id={`shipping_account_grid_action_row_${rowIndex}_delete_button`}
              confirmContent={`name: ${data.name}`}
              confirmTitle="Are you sure you want to delete the account?"
              onClick={() => delAccount(data)}
            />
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    // getSeviceList();
    getWarehouses();
    getCarriers();
  }, []);

  useEffect(() => {
    getConfigInfo();
  }, [getConfigInfo])

  useEffect(() => {
    getAccountList();
  }, [getAccountList]);

  useEffect(() => {
    if (activeTab && carriers.length > 0) {
      setSwitchLoading(true);
      if (activeTab === 'all') {
        setTabCarriers(carriers);
      } else {
        const temp = carriers.filter(
          (item) => item.warehouseDtos.indexOf(Number(activeTab)) > -1
        );
        setTabCarriers(temp);
      }
      setSwitchLoading(false);
    }
  }, [activeTab, carriers]);
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Shipping', 'Shipping Account']}>
        <Button
          id="shipping_account_add_button"
          type="primary"
          onClick={() => {
            setSelected(undefined);
            setSelectedCarrier(1);
            setActionType('add');
            setSelectCarrierVisible(true);
          }}
        >
          <PlusOutlined />
          Add Shipping Account
        </Button>
      </HeaderTitle>

      {warehouses ? (
        <Card loading={loading}>
          {warehouses.length > 0 && activeTab && (
            <Tabs
              // activeKey={activeTab}
              defaultActiveKey={activeTab}
              type="card"
              size="large"
              tabPosition="top"
              style={{ width: '100%', maxWidth: 1400 }}
              // style={{ width: 0 }}
              onChange={(activeKey) => setActiveTab(activeKey)}
            >
              {warehouses.map((item, index) => (
                <TabPane
                  id={`shipping_account_tab_${index}`}
                  tab={item.warehouseCode}
                  key={item.warehouseNum}
                ></TabPane>
              ))}
              <TabPane
                id={`shipping_account_tab_all`}
                tab="All"
                key="all"
              ></TabPane>
            </Tabs>
          )}
          {warehouses.length > 0 && (
            <>
              <TablesFilter
                columns={columns}
                dataSource={tabCarries}
                setFilteredData={setFilteredData}
              />
              <DataGridPlus
                name="shipAccount"
                style={{ minHeight: 500 }}
                idProperty="settingNum"
                columns={columns}
                dataSource={filteredData}
                loading={switchLoading}
                pagination="local"
                {...({} as any)}
              />
            </>
          )}
        </Card>
      ) : (
        <Empty />
      )}
      {/* <Card
        // title="Shipping services automatically set up for you and paid from your balance."
        style={{ marginTop: 10 }}
      >
        <DataGridPlus
          name="shipAccount"
          style={{ minHeight: 500 }}
          idProperty="settingNum"
          columns={columns}
          dataSource={carriers}
          loading={loading}
          pagination="local"
          {...({} as any)}
        /> */}
      {/* {carriers.map((item: ShipAccountRow, index: number) => (
          <CarriersItem key={index}>
            <Text strong>{item.carrier}</Text>
            <Button
              type="link"
              onClick={() => {
                setSelected(item);
                setOptionVisible(true);
              }}
            >{`${shipServiceList[`${item.carrier}`].length} services`}</Button>
            <Button type="link">Settings</Button>
          </CarriersItem>
        ))} */}
      {/* </Card> */}

      {createAccountVisible && (
        <CreateAccountDialog
          visible
          onClose={(refresh: boolean) => {
            setCreateAccountVisible(false)
            if (refresh) {
              getAccountList()
            }
          }}
          carrier={selectedCarrier}
          actionType={actionType}
          accountInfo={selected}
          carrierKeyValue={carrierKeyValue}
        />
      )}
      {selectCarrierVisible && (
        <SelectCarrierDialog
          visible
          isShow={configInfo && configInfo.enableShippingProvider}
          onHide={() => setSelectCarrierVisible(false)}
          onRefresh={(value) => {
            setSelectedCarrier(value);
            setSelectCarrierVisible(false);
            setCreateAccountVisible(true);
          }}
        />
      )}
      {optionVisible && selected && (
        <ShippingOptionsDialog
          visible
          onHide={() => setOptionVisible(false)}
          onRefresh={() => getAccountList()}
          account={selected}
          carrierKeyValue={carrierKeyValue}
        // carrierServiceList={shipServiceList[`${selected.carrier}`]}
        // warehouseId={warehouseId}
        />
      )}
    </>
  );
};

export default Carriers;
