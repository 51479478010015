import React, { useCallback, useState } from 'react';
import { playBell } from 'utils';
import { postProductRuturnInspectionToLocation } from 'services/smart';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
  SmartRow,
  SmartLabel,
  SmartText,
} from '../../SmartComponent';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

type ScanLocationProps = {
  onSubmit: (status: number, location: string) => void;
  onBack: () => void;
  product: any;
  warehouseId: string;
  warehouseNum: number;
  focusNum: number;
  showLocations: boolean;
};
export default function ScanLocation(props: ScanLocationProps): JSX.Element {
  const { onBack, onSubmit, product, warehouseId, focusNum, showLocations } = props;
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  /**
   * handle location key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      // playAudio('success');

      if (nextValue.length > 0) {
        try {
          setFetching(true);
          const res = await postProductRuturnInspectionToLocation(
            warehouseId,
            product.fulfillmentProductNum,
            nextValue
          );
          setFetching(false);
          if (res.isSuccess) {
            playBell('success');
            onSubmit(1, nextValue);
          } else {
            // playBell('error');
            if (res.code === 404) {
              onSubmit(2, nextValue);
            }
            if (res.code === 409 || res.code === 400) {
              onSubmit(3, nextValue);
            }
          }
        } catch (error) {
          // playBell('error');
          setFetching(false);
          // if (error.response.status === 404) {
          //   onSubmit(2, nextValue);
          // }
          // if (error.response.status === 409) {
          //   onSubmit(3, nextValue);
          // }
        }
      }
    },
    [product, warehouseId, onSubmit]
  );

  return (
    <>
      <Spin spinning={fetching} style={{ width: '100%' }}>
        <SmartSpace>
          <SmartFormLabel
            style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
          >
            {t("smart.virtualWarehouseToLocation.inspectionToLocation.scanLocation")}
          </SmartFormLabel>
          <SmartScanner
            style={{ marginTop: 10 }}
            id="scanLocation"
            isAutoFocus={focusNum}
            onChangeValue={handleScannerChange}
          />
        </SmartSpace>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.sku")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.sku : ''}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.upc")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.upc : ''}</SmartText>
        </SmartRow>
        <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.title")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product ? product.productTitle : ''}</SmartText>
        </SmartRow>
        {showLocations && <SmartRow style={{ marginTop: 10 }}>
          <SmartLabel>
            <SmartFormLabel>
              {t("common.location")}
            </SmartFormLabel>
          </SmartLabel>
          <SmartText>{product.locations.join(', ')}</SmartText>
        </SmartRow>}
        <SmartBackButton
          onClick={() => {
            onBack();
          }}
        />
      </Spin>
    </>
  );
}
