import React from 'react';
import { Checkbox } from 'antd';

/**
 * Custom checkbox column style
 */
const CheckboxColumn = {
  /**
   * render checkbox
   * @param {any} checkboxProps
   * @returns {JSX.Element}
   */
  renderCheckbox: (checkboxProps: any): JSX.Element => {
    const { onChange, checked, isIndeterminate } = checkboxProps;

    return (
      <>
        {isIndeterminate ? (
          <Checkbox
            indeterminate
            onClick={(e) => {
              e.stopPropagation();
              onChange(!checked);
            }}
          />
        ) : (
          <Checkbox
            checked={checked}
            onClick={(e) => {
              e.stopPropagation();
              onChange(!checked);
            }}
          />
        )}
      </>
    );
  },
};

export default CheckboxColumn;
