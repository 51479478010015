import React, { useState, useCallback } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Spin,
  Modal,
  Button,
  Select,
  DatePicker,
  Card,
  Row,
  InputNumber,
} from 'antd';
import { FormLabel, SelectWarehouse } from 'components/common';
import { message as showMessage } from 'components/common';
import { LoadingOutlined } from '@ant-design/icons';
import { addBulkReturn } from 'services/return';
import moment from 'moment';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

interface CreateProps extends ModalProps {
  onClose: Function;
  goReceive: Function;
  stores: any[];
  receiveDisabled: boolean;
  customers: any[];
}

export default function CreateWarehouseDialog(props: CreateProps) {
  const { stores, goReceive, receiveDisabled, customers } = props;
  const [loading, setLoading] = useState(false);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [needRefresh, setNeddRefresh] = useState(false);
  const [isERP, setIsERP] = useState<boolean>(false);
  const [form] = Form.useForm();

  const closeProductDetailDialog = () => {
    props.onClose(needRefresh);
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getCoustomerName = (code: string, list: { customerCode: string, customerName: string }[]) => {
    if (code) {
      const temp = list.filter(i => i.customerCode === code)
      if (temp.length > 0) {
        return temp[0].customerName
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    // console.log(params.channelAccountNum);
    const channelaccount = stores.filter(
      (i) => i.channelAccountNum === params.ChannelAccountNum
    );
    if (channelaccount.length === 0) {
      return showMessage.info({
        content: 'not found channelAccount info！',
      });
    }
    setLoading(true);
    try {
      const res = await addBulkReturn({
        ChannelAccountNum: channelaccount[0].channelAccountNum,
        ChannelAccountName: channelaccount[0].channelAccountName,
        ChannelNum: channelaccount[0].centralChannelNum,
        ChannelName: channelaccount[0].channelName,
        ReferenceNo: params.ReferenceNo,
        WarehouseNum: params.WarehouseNum.warehouseNum,
        WarehouseCode: params.WarehouseNum.warehouseCode,
        Notes: params.Notes,
        ReturnDate: params.ReturnDate,
        ControlNo: params.ControlNo,
        BoxID: params.BoxID,
        CustomerCode: params.CustomerCode,
        CustomerName: getCoustomerName(params.CustomerCode, customers),
        ClaimTotalQty: params.ClaimTotalQty,
      });
      setLoading(false);
      if (res.isSuccess) {
        setNeddRefresh(true);
        form.resetFields();
        if (
          params.WarehouseNum.warehouseNum ===
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          !receiveDisabled
        ) {
          Modal.confirm({
            title: `Customer Return ${res.data.fulfillmentBulkReturnNum} was Created.`,
            icon: <InfoCircleOutlined />,
            content: 'You can receive product now.',
            centered: true,
            okText: 'Receive Now',
            cancelText: 'Receive Later',
            onOk() {
              goReceive(res.data);
            },
            onCancel() {
              closeProductDetailDialog();
            },
          });
        } else {
          showMessage.success({
            content: 'Created successfully！',
          });
        }
      } else {
        showMessage.error({ content: 'Created failedlly' });
      }
    } catch (err: any) {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  // const tailLayout = {
  //   wrapperCol: { offset: 6, span: 14 },
  // };
  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Create Customer Return (Bulk Return)"
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <Card style={{ height: '100%' }}>
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          >
            <Form
              {...layout}
              form={form}
              style={{ marginTop: 20 }}
              onFinish={handleSave}
            >
              <Form.Item
                name="ChannelAccountNum"
                label={<FormLabel>Store(Channel)</FormLabel>}
                rules={[{ required: true, message: 'Please select Store!' }]}
              >
                <Select
                  id="bulk_return_create_dialog_form_store_name_select"
                  showSearch
                  allowClear
                  placeholder="Please select store"
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                  onChange={e => {
                    const target = stores.find(store => store.channelAccountNum === e);
                    if (target && target.channelAccountName === 'ERP') {
                      setIsERP(true);
                    } else {
                      setIsERP(false);
                    }
                  }}
                >
                  {stores.map((item) => (
                    <Select.Option
                      key={item.channelAccountNum}
                      value={item.channelAccountNum}
                      label={item.channelAccountName}
                    >
                      {item.channelAccountName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="ReferenceNo"
                label={<FormLabel>Reference No</FormLabel>}
                rules={[
                  { required: true, message: 'Please input Reference No!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="WarehouseNum"
                label={<FormLabel>Warehouse</FormLabel>}
                rules={[{ required: true, message: 'Please warehouse!' }]}
              >
                <SelectWarehouse />
              </Form.Item>

              <Form.Item
                name="ReturnDate"
                label={<FormLabel>Return Date</FormLabel>}
                initialValue={moment()}
                rules={[
                  { required: true, message: 'Please select return date!' },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="CustomerCode"
                label={<FormLabel>Customer</FormLabel>}
                rules={[
                  { required: isERP, message: 'Please select customer!' },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option) {
                      const { children = '', value = '' } = option;
                      return (typeof children === 'string' && children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                        || (typeof value === 'string' && value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }
                    return false;
                  }}
                >
                  {customers.map(item =>
                    <Select.Option key={item.customerCode} value={item.customerCode}>
                      {item.customerName}
                    </Select.Option>

                  )}
                </Select>
              </Form.Item>

              <Form.Item
                name="ClaimTotalQty"
                label={<FormLabel>Claimed Total Qty</FormLabel>}
                rules={[
                  { required: true, message: 'Please input Claimed Total Qty!' },
                ]}
              >
                <InputNumber precision={0} min={0}/>
              </Form.Item>

              <Form.Item
                name="ControlNo"
                label={<FormLabel>Control No</FormLabel>}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="BoxID"
                label={<FormLabel>Box ID</FormLabel>}
              >
                <Input />
              </Form.Item>

              <Form.Item name="Notes" label={<FormLabel>Note</FormLabel>}>
                <Input.TextArea rows={3} />
              </Form.Item>
              <Row justify="end">
                <Button type="primary" htmlType="submit">
                  {loading ? 'Creating' : 'Create'}
                </Button>
              </Row>
              {/* <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  {loading ? 'Creating' : 'Create'}
                </Button>
              </Form.Item> */}
            </Form>
          </Spin>
        </Card>
      </ModalDialog>
    </>
  );
}
