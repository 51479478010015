import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Row } from 'antd';
//import { ArrowLeftOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { REGULAR_HEADER_TITLE_MARGIN_BOTTOM } from 'constants/config';
import { HeaderTitleBar } from './styles';

/**
 * Type definition for the properties of the components.
 *
 * @param {?boolean} backEnabled - whether history back button is enabled
 * @param {?Array<string>} breadcrumb - A breadcrumb displays the current location within a hierarchy.
 * @param {?React.ReactNode} children - the right side child nodes components
 * @param {?number} marginBottom - the margin bottom space for the component
 * @param {?number} padding - set the padding of the header section
 * @param {?boolean} transparent - is it transparent
 */
type Props = {
  backEnabled?: boolean;
  breadcrumb?: Array<HeaderTitleCrumb>;
  children?: React.ReactNode;
  marginBottom?: number;
  padding?: number;
  transparent?: boolean;
};

const HeaderTitle = (props: Props) => {
  const { children, marginBottom } = props;
  const history = useHistory();
  const transparentStyle = {
    background: 'none',
  };
  const transparent =
    'boolean' === typeof props.transparent ? props.transparent : true;
  let headerTitleStyle = {
    padding: 0,
    marginBottom:
      'number' === typeof marginBottom
        ? marginBottom
        : REGULAR_HEADER_TITLE_MARGIN_BOTTOM,
  };
  let breadcrumb: Array<string | StringKAnyVPair> = props.breadcrumb || [];

  if (transparent) {
    headerTitleStyle = {
      ...headerTitleStyle,
      ...transparentStyle,
    };
  }

  if ('number' === typeof props.padding) {
    headerTitleStyle = {
      ...headerTitleStyle,
      ...{ padding: props.padding },
    };
  }

  if (breadcrumb && props.backEnabled) {
    breadcrumb = [
      {
        type: 'action',
        //content: <ArrowLeftOutlined />,
        content: <FontAwesomeIcon icon={faArrowAltCircleLeft as IconProp} />,
        callback: () => history.go(-1),
      },
      ...breadcrumb,
    ];
  }

  const getItemContent = (
    item: string | StringKAnyVPair,
    index: number,
    length: number
  ) => {
    if ('string' === typeof item) {
      return item;
    } else {
      if (index + 1 === length) {
        if (item && 'object' === typeof item) {
          return item.content;
        } else {
          return '';
        }
      } else {
        switch (item.type) {
          case 'link':
            return <Link to={item.url}>{item.content}</Link>;

          case 'action':
            return (
              <span
                className="act-box"
                onClick={() => handleBreadcrumbAction(item)}
              >
                {item.content}
              </span>
            );

          default:
            if (item && 'object' === typeof item) {
              return item.content;
            }

            return '';
        }
      }
    }
  };

  const handleBreadcrumbAction = (item: StringKAnyVPair) => {
    if ('function' === typeof item.callback) {
      item.callback();
    }
  };

  return (
    <HeaderTitleBar style={headerTitleStyle}>
      <Row align="middle" justify="space-between">
        <Breadcrumb>
          {breadcrumb &&
            breadcrumb.length &&
            breadcrumb.map((e, i) => (
              <Breadcrumb.Item key={i}>
                {getItemContent(e, i, breadcrumb.length)}
              </Breadcrumb.Item>
            ))}
        </Breadcrumb>
        {children}
      </Row>
    </HeaderTitleBar>
  );
};

export default HeaderTitle;
