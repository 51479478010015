/**
 * Audits related service requests are here.
 */
import moment from 'moment';
import { convertToUtcTime } from 'utils';
import { digitBridgeApi } from './http';

/**
 * Post countNotes
 * @param {number} warehouseId
 * @param {number} locationId
 * @param {Date} time
 * @param {string} note
 */
export const postCountNote = async (
  warehouseId: string,
  locationNum: number,
  time: Date,
  note: string
): Promise<boolean> => {
  const params = {
    // eslint-disable-next-line
    //NoteTime: convertToUtcTime(dayjs(time).format('YYYY-MM-DDTHH:mm:ss')),
    NoteTime: convertToUtcTime(moment(time).format()),
    Notes: note,
  };
  const json = JSON.stringify(params);
  try {
    await digitBridgeApi.post(
      `/api/warehouses/${warehouseId}/locations/${locationNum}/countNotes`,
      json
    );
    return true;
  } catch (e) {
    return false;
  }
};
/**
 * Fetch countNotes
 * @param {number} warehouseId
 * @param {number} locationId
 */
export const fetchCountNotes = async (
  warehouseId: string,
  locationNum: number
): Promise<Array<CountNoteRow>> => {
  try {
    const list = (await digitBridgeApi.get(
      `/api/warehouses/${warehouseId}/locations/${locationNum}/countNotes`
    )) as any;

    return list.map((item: any) => ({
      id: item.rowNum,
      noteTime: item.noteTime,
      notes: item.notes,
    }));
    // eslint-disable-next-line
  } catch (e) { }
  return [];
};

type FetchAuditBatchParams = {
  transactionType?: number,
  locationNum?: number,
  sku?: string,
  startDate?: string,
  endDate?: string,
}
export const fetchAuditBatch = async (warehouseId: string, params: FetchAuditBatchParams): Promise<any> => {
  return digitBridgeApi.get(`/api/auditBatch/${warehouseId}`, { params })
}

export const closeAuditBatch = async (warehouseId: string, AuditNums: number[]): Promise<any> => {
  const json = JSON.stringify({
    AuditNums
  })
  return digitBridgeApi.post(`/api/auditBatch/${warehouseId}/close`, json)
}

type AuditBatchHistoryParams = {
  $skip: number;
  $top: number;
  $sortBy: string;
  transactionType?: number;
  sku?: string;
  startDate?: string;
  endDate?: string;
}
export const getAuditBatchHistory = async (warehuseId: string, params: AuditBatchHistoryParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/auditBatch/${warehuseId}/history`, { params });
};

export const getAuditBatchHistorySync = async (warehuseId: string, params: AuditBatchHistoryParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/auditBatch/${warehuseId}/sync`, { params });
};

