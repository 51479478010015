import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import ReturnDtoDamage from 'components/Sales/Smart/MoveMerge/MoveWarehouse/ReturnDtoDamage';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Page(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.returnDtoDamage" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}

    // title={`From Return-Damage to Damage(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <ReturnDtoDamage />
    </ContentLayout>
  );
}



