import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { SmartBlock, SmartSpace, SmartButton } from '../../SmartComponent';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
export default function Pick(): JSX.Element {
  const { t } = useTranslation();
  return (
    <SmartBlock>
      <SmartSpace>
        <Link to="/smart/moveWarehouse/returnRtoReturnD">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.moveWarehouse.RRtoRD.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse/returnRtoDamage">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.moveWarehouse.RRtoD.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse/normalToDamage">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {' '}
              {t('smart.movemerge.moveWarehouse.NtoD.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse/returnDtoDamage">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {t('smart.movemerge.moveWarehouse.RdtoD.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse/returnItoReturnR">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {' '}
              {t('smart.movemerge.moveWarehouse.RItoRR.title')}
            </Text>
          </SmartButton>
        </Link>
        <Link to="/smart/moveWarehouse/returnItoReturnD">
          <SmartButton style={{ height: 55, marginBottom: 30 }}>
            <Text
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#fff',
                whiteSpace: 'normal',
                width: 230,
              }}
            >
              {' '}
              {t('smart.movemerge.moveWarehouse.RItoRD.title')}
            </Text>
          </SmartButton>
        </Link>
      </SmartSpace>
    </SmartBlock>
  );
}
