import React, { useEffect, useState, useCallback } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import {
  Typography,
  Card,
  DatePicker,
  Table,
  Row,
  Space,
  Button,
  Col,
  Collapse,
  Spin,
  message,
  Alert,
  // message,
} from 'antd';
import { Chart } from 'react-google-charts';
import { ErrorOrderLine, ButtonIcon } from 'components/common';
import {
  getDashboardInventoryInfo,
  getDashboardOrderInfo,
  getDashboardOrderPending,
  getDashboardShipmentsPending,
  getDashboardShipmentsShipped,
  getDashboardShipmentsSpecial,
} from 'services/dashboard';
import moment from 'moment';
import { convertToUtcTime } from 'utils';
import { getUSPreviousBusinessDay } from 'utils/BusinessRange';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Link, useHistory } from 'react-router-dom';
// import { fetchProfileSettings } from 'services/user';
import ErrorDetail from './ErrorDetail';

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width };
};

const shippedColumns = [
  {
    title: 'Carrier',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name, 'en'),
    key: '1',
  },
  {
    title: 'Qty',
    dataIndex: 'value',
    align: 'right' as const,
    sorter: (a: any, b: any) => a.value - b.value,
    key: '2',
  },
];
const statusColumns = [
  {
    title: 'Status',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name, 'en'),
    key: '3',
  },
  {
    title: 'Qty',
    dataIndex: 'value',
    align: 'right' as const,
    sorter: (a: any, b: any) => a.value - b.value,

    key: '4',
  },
];
const QueuedColumns = [
  {
    title: 'Service',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name, 'en'),
    key: '5',
  },
  {
    title: 'Queued Qty',
    dataIndex: 'queued',
    align: 'right' as const,
    sorter: (a: any, b: any) => a.queued - b.queued,
    key: '6',
  },
];
const nonQueuedColumns = [
  {
    title: 'Service',
    dataIndex: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name, 'en'),
    key: '7',
  },
  {
    title: 'Non-Queued Qty',
    dataIndex: 'unqueued',
    align: 'right' as const,
    sorter: (a: any, b: any) => a.unqueued - b.unqueued,
    key: '8',
  },
];

const [startTempDate, endTempDate] = getUSPreviousBusinessDay(
  moment().endOf('day').format()
);

type InventoryInfoResponse = {
  audit: number;
  audit_hours: number;
  audit_time: string;
  poreceive: number;
  poreceive_hours: number;
  poreceive_time: string;
  return_WarehouseNum:number;
  return_audit: number;
  return_audit_time: string;
}

type DashboardProps = {
  warehouse: WarehouseRow
  showOrderInfo: boolean;
}

export default function Dashboard(props: DashboardProps): JSX.Element {
  const {warehouse, showOrderInfo} = props;
  const defaultWarehouseNum = Number(
    warehouse.warehouseNum
  );

  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const { width } = useViewport();
  const [rangeDate, setRangeDate] = useState<any>([
    moment(startTempDate).add(-1, 'days'),
    endTempDate,
  ]);
  const [rangeDate1, setRangeDate1] = useState<any>([
    moment(startTempDate).add(1, 'day').startOf('day'),
    moment().endOf('day'),
  ]);
  const [rangeDate2, setRangeDate2] = useState<any>([
    moment(startTempDate).add(1, 'day').startOf('day'),
    moment().endOf('day'),
  ]);
  const [rangeDate3, setRangeDate3] = useState<any>([
    moment(startTempDate).add(1, 'day').startOf('day'),
    moment().endOf('day'),
  ]);
  const [rangeDate4, setRangeDate4] = useState<any>([
    moment(startTempDate).add(1, 'day').startOf('day'),
    moment().endOf('day'),
  ]);

  const [orderStatusInfo, setOrderStatusInfo] = useState<any>();
  const [orderInfoTotal, setOrderInfoTotal] = useState<number>(0);
  const [orderPending, setOrderPending] = useState<any>();
  const [shipmentSpecial, setShipmentSpecial] = useState<any>();
  const [shipmentPending, setShipmentPending] = useState<any>();
  const [shipmentShipped, setShipmentShipped] = useState<any>();
  const [reqQueuedQty, setReqQueuedQty] = useState(0);
  const [reqQueuedunQty, setReqQueuedunQty] = useState(0);
  const [spQueuedQty, setSpQueuedQty] = useState(0);
  const [spQueuedunQty, setSpQueuedunQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [refreshAll, setRefreshAll] = useState(false);
  const [showShipped, setShowShipped] = useState(false);
  const [showPendding, setShowPendding] = useState(false);
  const [inventoryInfo, setInventoryInfo] = useState<InventoryInfoResponse>();
  const [detailVisible, setDetailVisible] = useState(false);

  const history = useHistory();

  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'DashboardPendingRange'
      );
      if (temp.length > 0) {
        setRangeDate([
          moment(startTempDate).add(-Number(temp[0].settingValue), 'days'),
          endTempDate,
        ]);
      }
      setRefreshAll(true);
    }
  }, [userProfileSettings]);

  //first part
  const getOrderPending = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getDashboardOrderPending(defaultWarehouseNum, {
        start: convertToUtcTime(rangeDate[0].startOf('day').format()),
        end: convertToUtcTime(rangeDate[1].endOf('day').format()),
      });
      setLoading(false);
      if (res.isSuccess) {
        setOrderPending(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [defaultWarehouseNum, rangeDate]);

  //part two
  const getShipmentSpecial = useCallback(async () => {
    try {
      setLoading1(true);
      const res = await getDashboardShipmentsSpecial(defaultWarehouseNum, {
        start: convertToUtcTime(rangeDate1[0].startOf('day').format()),
        end: convertToUtcTime(rangeDate1[1].endOf('day').format()),
      });
      setLoading1(false);
      if (res.isSuccess) {
        setShipmentSpecial(res.data);
      }
    } catch (error) {
      setLoading1(false);
    }
  }, [defaultWarehouseNum, rangeDate1]);

  const getInventoryInfo = async () => {
    try {
      const { data, isSuccess, message: msg } = await getDashboardInventoryInfo(defaultWarehouseNum);
      if (data && isSuccess) {
        setInventoryInfo(data);
      } else {
        message.error(msg || 'Fetch inventory info failed!');
      }
    } catch (error) {
      console.log('error = ', error);
    }
  };

  const getShipmentPending = useCallback(async () => {
    try {
      setLoading2(true);
      const res = await getDashboardShipmentsPending(defaultWarehouseNum, {
        start: convertToUtcTime(rangeDate2[0].startOf('day').format()),
        end: convertToUtcTime(rangeDate2[1].endOf('day').format()),
      });
      setLoading2(false);
      if (res.isSuccess) {
        setShipmentPending(res.data);
        if (
          res.data.pending &&
          res.data.pending.requestservice &&
          res.data.pending.requestservice.length > 0
        ) {
          let tempReqTotalQueued = 0;
          let tempReqTotalunQueued = 0;
          res.data.pending.requestservice.map((item: any) => {
            tempReqTotalQueued += item.queued;
            tempReqTotalunQueued += item.unqueued;
            return true;
          });
          setReqQueuedQty(tempReqTotalQueued);
          setReqQueuedunQty(tempReqTotalunQueued);
        }
        if (
          res.data.pending &&
          res.data.pending.shippingservice &&
          res.data.pending.shippingservice.length > 0
        ) {
          let tempSpTotalQueued = 0;
          let tempSpTotalunQueued = 0;
          res.data.pending.shippingservice.map((item: any) => {
            tempSpTotalQueued += item.queued;
            tempSpTotalunQueued += item.unqueued;
            return true;
          });
          setSpQueuedQty(tempSpTotalQueued);
          setSpQueuedunQty(tempSpTotalunQueued);
        }
      }
    } catch (error) {
      setLoading2(false);
    }
  }, [defaultWarehouseNum, rangeDate2]);

  const getShipmentShipped = useCallback(async () => {
    try {
      setLoading3(true);
      const res = await getDashboardShipmentsShipped(defaultWarehouseNum, {
        start: convertToUtcTime(rangeDate3[0].startOf('day').format()),
        end: convertToUtcTime(rangeDate3[1].endOf('day').format()),
      });
      setLoading3(false);
      if (res.isSuccess) {
        setShipmentShipped(res.data);
      }
    } catch (error) {
      setLoading3(false);
    }
  }, [defaultWarehouseNum, rangeDate3]);

  const getOrderStatusInfo = useCallback(async () => {
    try {
      setLoading4(true);
      const res = await getDashboardOrderInfo(defaultWarehouseNum, {
        start: convertToUtcTime(rangeDate4[0].startOf('day').format()),
        end: convertToUtcTime(rangeDate4[1].endOf('day').format()),
      });
      setLoading4(false);
      if (res.isSuccess) {
        if (res.data.advanceProcess && res.data.advanceProcess.length > 0) {
          let tempTotal = 0;
          res.data.advanceProcess.map((item: any) => {
            tempTotal += item.value;
            return true;
          });
          setOrderInfoTotal(tempTotal);
        }
        setOrderStatusInfo(res.data);
      }
    } catch (error) {
      setLoading4(false);
    }
  }, [defaultWarehouseNum, rangeDate4]);

  const renderDatePicker = (defaultValue: any, onChange: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <RangePicker
          size="small"
          style={{ width: 230 }}
          allowClear={false}
          value={defaultValue}
          format="MM/DD/YYYY"
          // onOpenChange={()=>{
          //     document.addEventListener('click', (e) => {
          //       e.stopPropagation();
          //     }, { once: true });
          // }}
          ranges={{
            Today: [moment().startOf('day'), moment().endOf('day')],
            Yesterday: [
              moment().add(-1, 'day').startOf('day'),
              moment().add(-1, 'day').endOf('day'),
            ],
            '2 days ago': [
              moment().add(-2, 'day').startOf('day'),
              moment().endOf('day'),
            ],
            '3 days ago': [
              moment().add(-3, 'day').startOf('day'),
              moment().endOf('day'),
            ],
            'This Week': [moment().startOf('week'), moment().endOf('week')],
            'Last Week': [
              moment().add(-1, 'week').startOf('week'),
              moment().add(-1, 'week').endOf('week'),
            ],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={(dates) => onChange(dates)}
        />
      </div>
    );
  };

  /* eslint-disable */
  useEffect(() => {
    if (refreshAll) {
      getOrderPending();
      getShipmentSpecial();
      getInventoryInfo();
      if (showPendding) {
        getShipmentPending();
      }
      if (showShipped) {
        getShipmentShipped();
      }
      if (showOrderInfo) {
        getOrderStatusInfo();
      }
      setRefreshAll(false);
    }
  }, [refreshAll]);

  useEffect(() => {
    if (showPendding && !shipmentPending) {
      getShipmentPending();
    }
  }, [showPendding]);

  useEffect(() => {
    if (showShipped && !shipmentShipped) {
      getShipmentShipped();
    }
  }, [showShipped]);

  useEffect(() => {
    if (showOrderInfo && !orderStatusInfo) {
      getOrderStatusInfo();
    }
  }, [showOrderInfo]);
  /* eslint-enable */
  return (
    <>
      <Card title={<Text strong style={{fontSize: 18}}>{`Warehouse: ${warehouse.warehouseCode}`}</Text>} bodyStyle={{padding: 8}} style={{ marginBottom: 20 }}>
        <Row gutter={[0, 5]} style={{ marginBottom: 10 }}>
          <Col span={24}>
            <ErrorOrderLine
              warehouseNum={Number(warehouse.warehouseNum)}
            />
          </Col>
          {inventoryInfo && inventoryInfo.audit > 0 && (
            <Col span={24}>
              <Alert
                message={`There are ${inventoryInfo.audit} Audit Batch records that has not been closed for more than ${inventoryInfo.audit_hours} hour(s).`}
                type="warning"
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      history.push(`/inventory/audit-batch?endTime=${inventoryInfo.audit_time}&warehouse=${warehouse.warehouseNum}`)
                    }
                    icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                  >
                    Detail
                  </Button>
                }
              />
            </Col>
          )}
          {!!inventoryInfo && !!inventoryInfo.return_WarehouseNum && inventoryInfo.return_audit > 0 && (
            <Col span={24}>
              <Alert
                message={`There are ${inventoryInfo.return_audit} Return Audit Batch records that has not been closed for more than 8 hour(s).`}
                type="warning"
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      history.push(`/inventory/audit-batch?endTime=${inventoryInfo.return_audit_time}&warehouse=${inventoryInfo.return_WarehouseNum}`)
                    }
                    icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                  >
                    Detail
                  </Button>
                }
              />
            </Col>
          )}
          {inventoryInfo && inventoryInfo.poreceive > 0 && (
            <Col span={24}>
              <Alert
                message={`There are ${inventoryInfo.poreceive} PO Receive records that has not been closed for more than ${inventoryInfo.poreceive_hours} hour(s).`}
                type="warning"
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      history.push(`/po/list?startTime=${inventoryInfo.poreceive_time}&warehouse=${warehouse.warehouseCode}`)
                    }
                    icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                  >
                    Detail
                  </Button>
                }
              />
            </Col>
          )}

          {shipmentSpecial && shipmentSpecial.shopAroundFailed && shipmentSpecial.shopAroundFailed.count !== 0 && (
            <Col span={24}>
              <Alert
                message={`There are ${shipmentSpecial.shopAroundFailed.count} shipments that "shopping around" failed. You may try reprocess them.`}
                type="error"
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      history.push(`/sales/shipments?shopAroundStatus=2&status=0|250|8|9|10|32&warehouseNum=${warehouse.warehouseNum}&s=${shipmentSpecial.shopAroundFailed.begin}&e=${shipmentSpecial.shopAroundFailed.end}`)
                    }
                    icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                  >
                    Detail
                  </Button>
                }
              />
            </Col>
          )}

          {shipmentSpecial && shipmentSpecial.transferFailed > 0 && (
            <Col span={24}>
              <Alert
                message={`There are ${shipmentSpecial.transferFailed} third party warehouse shipments that has not been synchronized for more than 12 hours after they were created.`}
                type="error"
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      setDetailVisible(true)
                    }
                    icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                  >
                    Detail
                  </Button>
                }
              />
            </Col>
          )}
        </Row>
        <Row justify="space-between" gutter={16}>
          <Col span={12}>
            <Card
              size="small"
              loading={loading}
              title={<Title level={5}>Pending Orders</Title>}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: 150,
              }}
              extra={
                <Space size="small">
                  {renderDatePicker(rangeDate, setRangeDate)}
                  <Button
                    size="small"
                    onClick={() => {
                      getOrderPending();
                    }}
                  >
                    <ReloadOutlined
                      spin={false}
                      style={{ fontSize: 12, color: 'green', marginLeft: 4 }}
                    />
                    {width >= 1280 ? 'Refresh' : ''}
                  </Button>
                </Space>
              }
            >
              {/* <Space direction="vertical" style={{ width: '50%' }}> */}
              <Row justify="center" style={{ width: '100%' }} gutter={10}>
                <Col span={16}>
                  <Card
                    style={{
                      // borderTopWidth: 3,
                      //    borderTopColor: '#1AFFD5',
                      backgroundColor: '#1890ff',
                      borderRadius: 8,
                      height: 135,
                    }}
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 10,
                      height: '100%',
                      justifyContent: 'space-around',
                      fontSize: 18,
                    }}
                  >
                    <Row justify="space-between">
                      <Text style={{ fontSize: 22, color: '#fff' }}>Total</Text>
                      <Link
                        to={`/sales/view-orders?orderStatus=0&warehouseNum=${warehouse.warehouseNum}&enterDate=${rangeDate[0].format(
                          'YYYY-MM-DD'
                        )}@${moment(rangeDate[1])
                          .endOf('day')
                          .format('YYYY-MM-DD HH:mm:ss')}`}
                      >
                        <Text underline style={{ fontSize: 22, color: '#FFF' }}>
                          {orderPending?.total || 0}
                        </Text>
                      </Link>
                    </Row>
                    <Row justify="space-between">
                      <Text style={{ color: '#fff' }}>Process Pending</Text>
                      <Link
                        to={`/sales/view-orders?orderStatus=0&warehouseNum=${warehouse.warehouseNum}&advanceProcess=0&enterDate=${rangeDate[0].format(
                          'YYYY-MM-DD'
                        )}@${moment(rangeDate[1])
                          .endOf('day')
                          .format('YYYY-MM-DD HH:mm:ss')}`}
                      >
                        <Text underline style={{ color: '#fff' }}>
                          {orderPending?.processpending || 0}
                        </Text>
                      </Link>
                    </Row>
                    <Row justify="space-between">
                      <Text style={{ color: '#fff' }}>Queued</Text>
                      <Link
                        to={`/sales/view-orders?orderStatus=0&warehouseNum=${warehouse.warehouseNum}&queueStatus=1&enterDate=${rangeDate[0].format(
                          'YYYY-MM-DD'
                        )}@${moment(rangeDate[1])
                          .endOf('day')
                          .format('YYYY-MM-DD HH:mm:ss')}`}
                      >
                        <Text underline style={{ color: '#fff' }}>
                          {orderPending?.queued || 0}
                        </Text>
                      </Link>
                    </Row>
                    <Row justify="space-between">
                      <Text style={{ color: '#fff' }}>Non-Queued</Text>
                      <Link
                        to={`/sales/view-orders?orderStatus=0&warehouseNum=${warehouse.warehouseNum}&queueStatus=2&advanceProcess=1&enterDate=${rangeDate[0].format(
                          'YYYY-MM-DD'
                        )}@${moment(rangeDate[1])
                          .endOf('day')
                          .format('YYYY-MM-DD HH:mm:ss')}`}
                      >
                        <Text underline style={{ color: '#fff' }}>
                          {orderPending?.nonqueued || 0}
                        </Text>
                      </Link>
                    </Row>
                  </Card>
                </Col>
                {!!orderPending?.requireSmartVerify?.total && (
                  <Col span={6}>
                    <Card
                      style={{
                        // borderTopWidth: 3,
                        //    borderTopColor: '#FF881A',
                        borderRadius: 8,
                        backgroundColor: '#1890ff',
                        height: 100,
                      }}
                      bodyStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        padding: 1,
                        alignItems: 'space-around',
                        fontSize: 14,
                        height: '100%',
                      }}
                    >
                      <Text
                        ellipsis={{ tooltip: 'Require Smart Verified' }}
                        style={{ color: '#fff', alignSelf: 'center' }}
                      >
                        Smart Verify
                      </Text>
                      <Row
                        justify="space-between"
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                      >
                        <Text style={{ color: '#fff' }}>Total</Text>
                        <Text style={{ color: '#fff' }}>
                          {orderPending?.requireSmartVerify?.total || 0}
                        </Text>
                      </Row>
                      <Row
                        justify="space-between"
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                      >
                        <Text style={{ color: '#fff' }}>Verified</Text>
                        <Text style={{ color: '#fff' }}>
                          {orderPending?.requireSmartVerify?.verified || 0}
                        </Text>
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              loading={loading1}
              size="small"
              title={<Title level={5}>Shipment</Title>}
              bodyStyle={{
                height: 150,
                display: 'flex',
                alignItems: 'center',
              }}
              extra={
                <Space>
                  {renderDatePicker(rangeDate1, setRangeDate1)}
                  <Button
                    size="small"
                    onClick={() => {
                      getShipmentSpecial();
                    }}
                  >
                    <ReloadOutlined
                      spin={false}
                      style={{ fontSize: 12, color: 'green', marginLeft: 4 }}
                    />
                    Refresh
                  </Button>
                </Space>
              }
            >
              <Row style={{ width: '100%' }} gutter={4}>
                <Col span={shipmentSpecial?.batch?.total ? 6 : 8}>
                  <Card
                    style={{
                      // borderTopWidth: 3,
                      //    borderTopColor: '#881AFF',
                      borderRadius: 8,
                      backgroundColor: '#1890ff',
                      height: 100,
                    }}
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      padding: 1,
                      alignItems: 'center',
                      fontSize: 14,
                      height: '100%',
                    }}
                  >
                    <Text
                      ellipsis={{ tooltip: 'Shipped' }}
                      style={{ color: '#fff', marginTop: -8 }}
                    >
                      Shipped
                    </Text>
                    <Text style={{ fontSize: 22, color: '#FFF' }}>
                      {shipmentSpecial?.shipped?.count || 0}
                    </Text>
                  </Card>
                </Col>
                {!!shipmentSpecial?.batch?.total && (
                  <Col span={6}>
                    <Card
                      style={{
                        // borderTopWidth: 3,
                        //    borderTopColor: '#90FF1A',
                        borderRadius: 8,
                        backgroundColor: '#1890ff',
                        height: 100,
                      }}
                      bodyStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        padding: 1,
                        alignItems: 'space-around',
                        fontSize: 14,
                        height: '100%',
                      }}
                    >
                      <Text
                        ellipsis={{ tooltip: 'Batch Print' }}
                        style={{ color: '#fff', alignSelf: 'center' }}
                      >
                        Batch Print
                      </Text>
                      <Row
                        justify="space-between"
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                      >
                        <Text style={{ color: '#fff' }}>Total</Text>
                        <Text style={{ color: '#fff' }}>
                          {shipmentSpecial?.batch?.total || 0}
                        </Text>
                      </Row>
                      <Row
                        justify="space-between"
                        style={{ paddingLeft: 8, paddingRight: 8 }}
                      >
                        <Text style={{ color: '#fff' }}>Verified</Text>
                        <Text style={{ color: '#fff' }}>
                          {shipmentSpecial?.batch?.verified || 0}
                        </Text>
                      </Row>
                    </Card>
                  </Col>
                )}
                <Col span={shipmentSpecial?.batch?.total ? 6 : 8}>
                  <Card
                    style={{
                      // borderTopWidth: 3,
                      //    borderTopColor: '#FF3C1A',
                      borderRadius: 8,
                      backgroundColor: '#1890ff',
                      height: 100,
                    }}
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      padding: 1,
                      alignItems: 'center',
                      fontSize: 14,
                      height: '100%',
                    }}
                  >
                    <Text
                      ellipsis={{ tooltip: 'Label Printing' }}
                      style={{ color: '#fff', marginTop: -8 }}
                    >
                      Label Printing
                    </Text>
                    <Text style={{ fontSize: 22, color: '#FFF' }}>
                      {shipmentSpecial?.shipping?.count || 0}
                    </Text>
                  </Card>
                </Col>
                <Col span={shipmentSpecial?.batch?.total ? 6 : 8}>
                  <Card
                    style={{
                      // borderTopWidth: 3,
                      //    borderTopColor: '#FF881A',
                      borderRadius: 8,
                      backgroundColor: '#1890ff',
                      height: 100,
                    }}
                    bodyStyle={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      padding: 1,
                      alignItems: 'center',
                      fontSize: 14,
                      height: '100%',
                    }}
                  >
                    <Text
                      ellipsis={{ tooltip: 'Cancelled' }}
                      style={{ color: '#fff', marginTop: -8 }}
                    >
                      Cancelled
                    </Text>
                    <Text style={{ fontSize: 22, color: '#FFF' }}>
                      {shipmentSpecial?.cancelled?.count || 0}
                    </Text>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Collapse
          style={{ marginTop: 12, paddingRight: 24 }}
          onChange={() => setShowPendding((prev) => !prev)}
        >
          <Collapse.Panel
            header={<Title level={5}>Pending Shipment</Title>}
            key="1"
            extra={
              <Space>
                {renderDatePicker(rangeDate2, setRangeDate2)}

                <Button
                  size="small"
                  disabled={!showPendding}
                  onClick={(e) => {
                    getShipmentPending();
                    e.stopPropagation()
                  }}
                >
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green', marginLeft: 4 }}
                  />
                  Refresh
                </Button>
              </Space>
            }
          >
            <Spin spinning={loading2}>
              <Text type="secondary" strong style={{ fontSize: 16 }}>
                Mapped Shipping Service
              </Text>
              <Row gutter={16} style={{ marginBottom: 12 }}>
                <Col span={12}>
                  <Card>
                    {shipmentPending && (
                      <Chart
                        chartType="PieChart"
                        data={[
                          ['Task', 'qty per Total'],
                          ...(shipmentPending?.pending?.shippingservice || [])
                            .sort((a: any, b: any) => b.queued - a.queued)
                            .map((i: any) => [i.name, i.queued]),
                        ]}
                        options={{
                          // legend: 'none',
                          title: `Total Qty:  ${spQueuedQty}`,
                          is3D: true,
                        }}
                        width={'100%'}
                        height={'180px'}
                      />
                    )}
                    <Table
                      style={{ width: '100%' }}
                      scroll={{ y: 180 }}
                      rowKey="name"
                      size="small"
                      columns={QueuedColumns}
                      dataSource={(
                        shipmentPending?.pending?.shippingservice || []
                      )
                        .filter((i: any) => i.queued > 0)
                        .sort((a: any, b: any) => b.queued - a.queued)}
                      pagination={false}
                      bordered
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    {shipmentPending && (
                      <Chart
                        chartType="PieChart"
                        data={[
                          ['Task', 'qty per Total'],
                          ...(shipmentPending?.pending?.shippingservice || [])
                            .sort((a: any, b: any) => b.unqueued - a.unqueued)
                            .map((i: any) => [i.name, i.unqueued]),
                        ]}
                        options={{
                          // legend: 'none',
                          title: `Total Qty:  ${spQueuedunQty}`,
                          is3D: true,
                        }}
                        width={'100%'}
                        height={'180px'}
                      />
                    )}

                    <Table
                      style={{ width: '100%' }}
                      scroll={{ y: 180 }}
                      rowKey="name"
                      size="small"
                      columns={nonQueuedColumns}
                      dataSource={(
                        shipmentPending?.pending?.shippingservice || []
                      )
                        .filter((i: any) => i.unqueued > 0)
                        .sort((a: any, b: any) => b.unqueued - a.unqueued)}
                      pagination={false}
                      bordered
                    />
                  </Card>
                </Col>
              </Row>
              <Text type="secondary" strong style={{ fontSize: 16 }}>
                Request Shipping Service
              </Text>
              <Row gutter={16}>
                <Col span={12}>
                  <Card>
                    {shipmentPending && (
                      <Chart
                        chartType="PieChart"
                        data={[
                          ['Task', 'qty per Total'],
                          ...(shipmentPending?.pending?.requestservice || [])
                            .sort((a: any, b: any) => b.queued - a.queued)
                            .map((i: any) => [i.name, i.queued]),
                        ]}
                        options={{
                          // legend: 'none',
                          title: `Total Qty:  ${reqQueuedQty}`,
                          is3D: true,
                        }}
                        width={'100%'}
                        height={'180px'}
                      />
                    )}
                    <Table
                      style={{ width: '100%' }}
                      scroll={{ y: 180 }}
                      rowKey="name"
                      size="small"
                      columns={QueuedColumns}
                      dataSource={(
                        shipmentPending?.pending?.requestservice || []
                      )
                        .filter((i: any) => i.queued > 0)
                        .sort((a: any, b: any) => b.queued - a.queued)}
                      pagination={false}
                      bordered
                    />
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    {shipmentPending && (
                      <Chart
                        chartType="PieChart"
                        data={[
                          ['Task', 'qty per Total'],
                          ...(shipmentPending?.pending?.requestservice || [])
                            .sort((a: any, b: any) => b.unqueued - a.unqueued)
                            .map((i: any) => [i.name, i.unqueued]),
                        ]}
                        options={{
                          // legend: 'none',
                          title: `Total Qty:  ${reqQueuedunQty}`,
                          is3D: true,
                        }}
                        width={'100%'}
                        height={'180px'}
                      />
                    )}
                    <Table
                      style={{ width: '100%' }}
                      scroll={{ y: 180 }}
                      rowKey="name"
                      size="small"
                      columns={nonQueuedColumns}
                      dataSource={(
                        shipmentPending?.pending?.requestservice || []
                      )
                        .filter((i: any) => i.unqueued > 0)
                        .sort((a: any, b: any) => b.unqueued - a.unqueued)}
                      pagination={false}
                      bordered
                    />
                  </Card>
                </Col>
              </Row>
            </Spin>
          </Collapse.Panel>
        </Collapse>

        <Collapse
          style={{ marginTop: 12, paddingRight: 24 }}
          onChange={() => setShowShipped((prev) => !prev)}
        >
          <Collapse.Panel
            header={<Title level={5}>Shipped Shipment</Title>}
            key="1"
            extra={
              <Space>
                {renderDatePicker(rangeDate3, setRangeDate3)}

                <Button
                  size="small"
                  disabled={!showShipped}
                  onClick={(e) => {
                    getShipmentShipped();
                    e.stopPropagation();
                  }}
                >
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green', marginLeft: 4 }}
                  />
                  Refresh
                </Button>
              </Space>
            }
          >
            <Spin spinning={loading3}>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexDirection: 'row',
                }}
              >
                <div style={{ width: '50%' }}>
                  {shipmentPending && (
                    <Chart
                      chartType="PieChart"
                      data={[
                        ['Task', 'qty per Total'],
                        ...(shipmentShipped?.shipped?.carriers || [])
                          .sort((a: any, b: any) => b.value - a.value)
                          .map((i: any) => [i.name, i.value]),
                      ]}
                      options={{
                        title: `Total Shipped Shipments : ${shipmentShipped?.shipped?.count || 0
                          }`,
                        is3D: true,
                      }}
                      width={'100%'}
                      height={'180px'}
                    />
                  )}
                </div>
                <Table
                  style={{ width: '50%' }}
                  scroll={{ y: 180 }}
                  rowKey="name"
                  size="small"
                  columns={shippedColumns}
                  dataSource={(shipmentShipped?.shipped?.carriers || []).sort(
                    (a: any, b: any) => b.value - a.value
                  )}
                  pagination={false}
                  bordered
                />
              </Row>
            </Spin>
          </Collapse.Panel>
        </Collapse>

        {showOrderInfo && (
          <Card
            loading={loading4}
            size="small"
            style={{ marginTop: 12, paddingRight: 24 }}
            title={<Title level={5}>Orders Info </Title>}
            // bodyStyle={{
            //   height: 200,
            // }}
            extra={
              <Space>
                {renderDatePicker(rangeDate4, setRangeDate4)}

                <Button
                  size="small"
                  onClick={() => {
                    getOrderStatusInfo();
                  }}
                >
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green', marginLeft: 4 }}
                  />
                  Refresh
                </Button>
              </Space>
            }
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'start',
                flexDirection: 'row',
              }}
            >
              <div style={{ width: '50%' }}>
                <Chart
                  chartType="PieChart"
                  data={[
                    ['Task', 'qty per Total'],
                    ...(orderStatusInfo?.advanceProcess || [])
                      .sort((a: any, b: any) => b.value - a.value)
                      .map((i: any) => [i.name, i.value]),
                  ]}
                  options={{
                    title: `Total Orders : ${orderInfoTotal}`,
                    is3D: true,
                  }}
                  width={'100%'}
                  height={'180px'}
                />
              </div>
              <Table
                style={{ width: '50%' }}
                scroll={{ y: 180 }}
                size="small"
                rowKey="name"
                columns={statusColumns}
                dataSource={(orderStatusInfo?.advanceProcess || []).sort(
                  (a: any, b: any) => b.value - a.value
                )}
                pagination={false}
                bordered
              />
            </Row>
          </Card>
        )}
      </Card>
      {
        detailVisible && <ErrorDetail
        visible
        onHide={()=> setDetailVisible(false)}
        params={{
          start: convertToUtcTime(rangeDate1[0].startOf('day').format()),
          end: convertToUtcTime(rangeDate1[1].endOf('day').format()),
        }}
        />
      }
    </>
  );
}
