import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from 'antd';
import {
  ExclamationCircleOutlined,
  PauseCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { printPackingSlip, printShipmentLabel } from 'actions/printActions';
import { setPrintLabelResult } from 'actions/salesAction';
import {
  FormLabel,
  Loading,
  ScreenMask,
  message,
} from 'components/common';
import {
  ButtonIcon,
  InfoButton,
  WarningButton,
  Label,
} from 'components/common/styles';
import { FormVSpacer } from 'components/common/styles/CustomPropsComponents';
import { DEFAULT_PRINT_MODE, LOADING_ICON_SIZE1 } from 'constants/config';
import { RootState } from 'reducers';
import { queryProductByKeyword } from 'services/product';
import {
  getChannelPackingSlipList,
  postPrintShipmentLabel,
  postPrintShipmentPackingSlip,
} from 'services/sales';
import { fetchShipmentDetail, queryShipmentList } from 'services/shipment';
import {
  convertToUtcTime,
  getProfileSettingValue,
  nowTime,
  playBell,
} from 'utils';
import { userProfiles } from 'utils/auth';
import StoreSelector from './StoreSelector';
import { BatchPrintWrapper } from './styles';
import { isSkipPrintPackSlip } from '..';

type Props = {
  deviceSetting?: Function;
  onClose: Function;
  visible: boolean;
  warehouseNum: number;
};

const channelPackingSlips: StringKAnyVPair = {};
let continuousPrintInterval = 1000;

const BatchPrintDialog = (props: Props) => {
  const PRINT_STATE_IDLE = 0;
  const PRINT_STATE_READY = 1;
  const PRINT_STATE_PRINTING = 2;
  const PRINT_STATE_PAUSED = 3;
  const PRINT_STATE_PRINTED = 4;
  const { useState } = React;
  const { RangePicker } = DatePicker;
  const { t: trans } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const { warehouseNum } = props;
  const [enterDateEnd, setEnterDateEnd] = useState<any>(null);
  const [enterDateStart, setEnterDateStart] = useState<any>(null);
  const [inited, setInited] = useState(false);
  const [lastShipmentIndex, setLastShipmentIndex] = useState(-1);
  const [lockScreen, setLockScreen] = useState(false);
  const [nextPrintTimer, setNextPrintTimer] = useState<any>(null);
  const [orderDateEnd, setOrderDateEnd] = useState<any>(null);
  const [orderDateStart, setOrderDateStart] = useState<any>(null);
  const [planPrintCount, setPlanPrintCount] = useState<number>();
  const [printFailedCount, setPrintFailedCount] = useState(0);
  const [printState, setPrintState] = useState(PRINT_STATE_IDLE);
  const [printSuccessCount, setPrintSuccessCount] = useState(0);
  const [product, setProduct] = useState<StringKAnyVPair>({});
  const [productFound, setProductFound] = useState(false);
  const [productSearchEnd, setProductSearchEnd] = useState(false);
  const [scanText, setScanText] = useState('');
  const [selectedStores, setSelectedStores] = useState<number[]>([]);
  const [shipmentList, setShipmentList] = useState<StringKAnyVPair[]>([]);
  const [shipmentCount, setShipmentCount] = useState(0);
  const skuInputRef = React.useRef<any>(null);
  const packingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );
  const packingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );
  const printLabelResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResult,
    shallowEqual
  );
  const shipPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter,
    shallowEqual
  );

  /**
   * Search production and try to search order list.
   */
  const findOrderListByKey = async (key: string) => {
    setLockScreen(true);

    try {
      const prd = await getProductInfoByKey(key);

      if (prd) {
        let param: StringKAnyVPair = { sku: key };

        if (orderDateEnd && orderDateStart) {
          param.order_begin = orderDateStart;
          param.order_end = orderDateEnd;
        }

        if (enterDateEnd && enterDateStart) {
          param.enter_begin = enterDateStart;
          param.enter_end = enterDateEnd;
        }

        if (Array.isArray(selectedStores) && selectedStores.length > 0) {
          param.channelAccounts = selectedStores;
        }

        //console.log('---> p -->', param);
        await getShipmentListInfo(param);
      }
    } finally {
      setLockScreen(false);
      setProductSearchEnd(true);
    }
  };

  const focusScanInput = () => {
    skuInputRef?.current?.focus();
  };

  const getLastShipmentOrderId = () => {
    if (shipmentList[lastShipmentIndex]) {
      const { shipment } = shipmentList[lastShipmentIndex];

      if (shipment && typeof shipment === 'object') {
        return shipment.channelOrderId;
      };
    }
  };

  const getProductInfoByKey = async (key: string) => {
    const res = await queryProductByKeyword(key);
    let found = false;
    let prd = {} as StringKAnyVPair;

    if (Array.isArray(res) && res.length > 0) {
      found = true;
      prd = res[0];
    }

    setProductFound(found);
    setProduct(prd);

    if (prd.fulfillmentProductNum > 0) {
      return prd;
    }
  };

  const getShipment = async (shipmentNum: number) => {
    const shipment = await fetchShipmentDetail(shipmentNum);

    if (shipment) {
      return shipment;
    }
  };

  const getShipmentListInfo = async (query: StringKAnyVPair) => {
    const res = await queryShipmentList(warehouseNum, query);
    const { total, shipments } = res as StringKAnyVPair;
    console.log('res--->', res);

    if (total && typeof total === 'number') {
      setShipmentCount(total);

      if (Array.isArray(shipments)) {
        //setShipmentList(shipments);
        setShipmentList(shipments.map(e => ({
          printed: false,
          printSuccess: false,
          printTime: 0,
          shipmentLoaded: false,
          shipment: null, // Object | null
          shipmentNum: e,
        })));
      } else {
        setShipmentList([]);
      }
    }
  };

  const getShipmentPackingSlipType = async (shipment: StringKAnyVPair) => {
    let ret = 0;

    try {
      const channelAccountNum = shipment.channelAccountNum || 0;
      let slips = channelPackingSlips[channelAccountNum];

      if (!slips) {
        slips = await getChannelPackingSlipList(channelAccountNum);

        if (Array.isArray(slips)) {
          channelPackingSlips[channelAccountNum] = slips;
        }
        //console.log('get from remote');
      }

      //console.log('ccount num', channelAccountNum, slips, currentOrder.salesDivision, '<--');
      if (Array.isArray(slips)) {
        const finds = slips.filter(
          (e) => e.salesDivision === shipment.salesDivision
        );

        if (finds.length > 0) {
          ret = finds[0].packingSlipSize;
        }
      }
    } catch (e) {
      message.error(`Get packing slip error ${e}`);
    }

    //console.log('ret ->', ret);
    return ret;
  };

  // eslint-disable-next-line
  const handleBatchPrint = async (start: number) => {
    let idx = start;
    let count = planPrintCount || 0;
    let ctx: StringKAnyVPair = {};

    if (
      shipPrinter.printerNum === undefined ||
      shipPrinter.printerNum <= 0
    ) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    while (idx < count) {
      ctx = shipmentList[idx];

      if (ctx) {
        if (!ctx.shipmentLoaded) {
          break;
        }
      } else {
        break;
      }

      idx++;
    }

    console.log('idx --->', idx, planPrintCount);
    if (ctx.shipmentNum && !ctx.shipmentLoaded) {
      const shipment = await getShipment(ctx.shipmentNum);

      if (shipment) {
        ctx.shipment = shipment;
        ctx.shipmentLoaded = true;
        setLastShipmentIndex(idx);
        setShipmentList([...shipmentList]);
        setPrintState(PRINT_STATE_PRINTING);
        await printShippingLabel(ctx);
      } else {
        message.error(`Shipment for ${ctx.shipmentNum} not existed`);
        setPrintState(PRINT_STATE_READY);
      }
    }
  };

  const handleScanTextChange = (evt: any) => {
    setScanText(evt.target.value.trim());
  };

  const handleScanTextKeyDown = (evt: any) => {
    const { keyCode } = evt;

    //if ([9, 13].indexOf(keyCode) > -1) {
    if ([9].indexOf(keyCode) > -1) {
      const tn = skuInputRef?.current.input.value.trim();

      playBell('bell');

      if (tn) {
        setInitialState();
        findOrderListByKey(tn);
      }
    }
  };

  const handleSearchOrderList = (val: any) => {
    let value = '';

    if (val && typeof val === 'string') value = val.trim();
    //const tn = skuInputRef?.current.input.value.trim();
    //if (scanText) {
    if (value) {
      setInitialState();
      findOrderListByKey(scanText);
      //findOrderListByKey(tn);
    } else {
      playBell('bell');
      focusScanInput();
    }
  };

  const isInPrint = () => {
    return [
      PRINT_STATE_PAUSED,
      PRINT_STATE_PRINTING,
    ].indexOf(printState) > -1;
  };

  const isPrintInterrupt = () => {
    return [
      PRINT_STATE_PAUSED,
      PRINT_STATE_PRINTED,
    ].indexOf(printState) > -1;
  };

  const onChangeEnterDate = (dates: any) => {
    if (dates) {
      const [start, end] = dates;

      //console.log('d -->', start, end);
      setEnterDateEnd(convertToUtcTime(end.format()));
      setEnterDateStart(convertToUtcTime(start.format()));
    } else {
      //console.log('not select enter date');
      setEnterDateEnd(null);
      setEnterDateStart(null);
    }
  };

  const onChangeOrderDate = (dates: any) => {
    if (dates) {
      const [start, end] = dates;

      //console.log('d -->', start, end);
      setOrderDateEnd(convertToUtcTime(end.format()));
      setOrderDateStart(convertToUtcTime(start.format()));
    } else {
      //console.log('not select order date');
      setOrderDateEnd(null);
      setOrderDateStart(null);
    }
  };

  const onChangeStore = (stores: number[]) => {
    //console.log('stores -->', stores);
    setSelectedStores(stores);
  };

  const onClose = () => {
    if (
      printState === PRINT_STATE_PAUSED ||
      printState === PRINT_STATE_PRINTING
    ) {
      Modal.confirm({
        title: trans('scanToShip.batchPrint.closeTitle'),
        icon: <ExclamationCircleOutlined />,
        content: trans('scanToShip.batchPrint.closeContent'),
        okText: trans('common.exit'),
        cancelText: trans('common.cancel'),
        onOk: () => {
          if (nextPrintTimer) {
            clearTimeout(nextPrintTimer);
          }

          setInitialState();
          dispatch(setPrintLabelResult({}));
          props.onClose();
        }
      });
    } else {
      setInitialState();
      dispatch(setPrintLabelResult({}));
      props.onClose();
    }
  };

  const onPrintCountChange = (val: number) => {
    let state = PRINT_STATE_IDLE;

    setPlanPrintCount(val);

    if (val > 0) {
      state = PRINT_STATE_READY;
    }

    setPrintState(state);
  };

  const pausePrint = () => {
    setPrintState(PRINT_STATE_PAUSED);
  };

  const printContinue = () => {
    if (printState === PRINT_STATE_PAUSED) {
      handleBatchPrint(lastShipmentIndex + 1);
      setPrintState(PRINT_STATE_PRINTING);
    } else if (printState === PRINT_STATE_PRINTED) {
      setInitialState();
      setScanText('');
      focusScanInput();
    }
  };

  // eslint-disable-next-line
  const printShippingLabel = async (ctx: StringKAnyVPair) => {
    const { shipment } = ctx;
    const profile = userProfiles.profile;
    //console.log('print sh', shipment);
    const pd = {
      isBatch: true,
      ShipmentNum: shipment.shipmentNum,
      ShippingAccountNum: shipment.shippingAccountNum,
      ShipFrom: warehouseNum,
      Carrier: shipment.shippingCarrier,
      CarrierNum: shipment.shipCarrierNum,
      Confirmation: shipment.deliveryConfirmation,
      Service: shipment.shippingServiceCode,
      PackageName: shipment.packageName,
      PackageType: shipment.packageCode,
      PrinterName: shipPrinter.printerName,
      PrinterNum: shipPrinter.printerNum,
      PrinterAccountNum: shipPrinter.printerAccountNum,
      ShipPackageNum: shipment.shipPackageNum,
      ShipServiceNum: shipment.shipServiceNum,
      Height: product.boxHeight,
      Length: product.boxLength,
      Weight: product.grossWeight,
      Width: product.boxWidth,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      InsuredValue: shipment.insuredValue,
      BillToType: shipment.billToType,
      BillThirdPartyAccount: shipment.billThirdPartyAccount,
      BillThirdPartyCountry: shipment.billThirdPartyCountry,
      BillThirdPartyPostalCode: shipment.billThrdPartyPostalcode,
    };
    console.log('pd --->', pd);
    const printMode = await getProfileSettingValue('PrintStationMode', DEFAULT_PRINT_MODE);

    if (printMode === DEFAULT_PRINT_MODE) {
      dispatch(printShipmentLabel(pd));
      //dispatch(setPrintingHang(true));
    } else {
      try {
        const res = await postPrintShipmentLabel(pd);

        if (res) {
          console.log('p res', res);
          setTimeout(() => {
            /*tryWaitShipmentShipped(async () => {
              if (typeof props.onMarkShipped === 'function') {
                await props.onMarkShipped();

                if (shipmentNeedPrintPackingSlip) {
                  await printShipmentPackingSlip();
                }
              }
            });*/
          }, 0);
        }
      } finally {
        setLockScreen(false);
      }
    }
  };

  // eslint-disable-next-line
  const printSlip = async (shipment: StringKAnyVPair) => {
    let printer = packingPrinter;
    const profile = userProfiles.profile;
    const typ = await getShipmentPackingSlipType(shipment);

    if (typ === 1) printer = packingPrinter2;

    const slip = {
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
      //PrinterName: packingPrinter.printerName,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      User: profile.email,
      FulfillmentOrderNum: shipment.fulfillmentOrderNum,
      ShipmentNum: shipment.shipmentNum,
      Time: nowTime(),
    };

    const printMode = await getProfileSettingValue('PrintStationMode', DEFAULT_PRINT_MODE);

    console.log('slip --->', slip);
    if (printMode === DEFAULT_PRINT_MODE) {
      const skipPS = isSkipPrintPackSlip();
      const sm = shipment as StringKAnyVPair;

      if (!(sm.allowSkipPrintPackingSlip && skipPS)) {
        dispatch(printPackingSlip(slip));
      }
    } else {
      try {
        //const res = await postPrintShipmentPackingSlip(slip);
        await postPrintShipmentPackingSlip(slip);
      } catch (e) {
        message.error(trans('scanToShip.batchPrint.printPackingSlipError', { usage: shipment.shipmentNum, error: e }) || 'Error');
      }
    }
  };

  // the scan text is not set at here
  const setInitialState = () => {
    setLastShipmentIndex(-1);
    setNextPrintTimer(null);
    setPlanPrintCount(undefined);
    setPrintFailedCount(0);
    setPrintState(PRINT_STATE_IDLE);
    setPrintSuccessCount(0);
    setProduct({});
    setProductFound(false);
    setProductSearchEnd(false);
    setShipmentCount(0);
    setShipmentList([]);
  };

  React.useEffect(() => {
    if (!inited) {
      setTimeout(() => {
        skuInputRef?.current?.focus();
      }, 1000);
      setInited(true);
    }

    if (printLabelResult.code) {
      const { code, isSuccess } = printLabelResult;
      const ctx = shipmentList[lastShipmentIndex];

      if (typeof code === 'number' && ctx) {
        let nextTimeout = 0;

        ctx.printed = true;
        dispatch(setPrintLabelResult({}));

        if (isSuccess) {
          setPrintSuccessCount(printSuccessCount + 1);

          if (ctx) {
            ctx.printSuccess = true;
            nextTimeout = continuousPrintInterval;

            if (ctx.shipment.isPrintPackingSlip) {
              printSlip(ctx.shipment);
            }
          }
        } else {
          setPrintFailedCount(printFailedCount + 1);

          if (printLabelResult.message) {
            message.error(printLabelResult.message);
          }
          console.log('last idx --->', lastShipmentIndex);
        }

        setShipmentList([...shipmentList]);

        if (printState === PRINT_STATE_PRINTING) {
          const timer = setTimeout(() => {
            handleBatchPrint(lastShipmentIndex + 1);
          }, nextTimeout);

          setNextPrintTimer(timer);
        }
      }
    }

    if (printSuccessCount + printFailedCount === planPrintCount) {
      setPrintState(PRINT_STATE_PRINTED);
    }
  }, [
    dispatch,
    handleBatchPrint,
    inited,
    lastShipmentIndex,
    planPrintCount,
    printFailedCount,
    printLabelResult,
    printSlip,
    printState,
    printSuccessCount,
    shipmentList,
  ]);

  return (
    <Modal
      centered
      footer={
        //<Button onClick={onClose}>Cancel</Button>
        null
      }
      maskClosable={false}
      onCancel={onClose}
      title={trans('scanToShip.batchPrint.title')}
      visible={props.visible}
      width={500}
    >
      <>
        <BatchPrintWrapper>
          <Row align="middle" justify="space-between">
            <FormLabel>{trans('scanToShip.batchPrint.orderDate')}</FormLabel>
            <div className="store-selector-wrapper">
              <RangePicker
                placeholder={[trans('common.startDate'), trans('common.endDate')]}
                onChange={onChangeOrderDate}
                style={{ width: '100%' }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'hh:mm:ss'),
                    moment('23:59:59', 'hh:mm:ss'),
                  ],
                }}
                ranges={{
                  Today: [moment().startOf('day'), moment().endOf('day')],
                  Yesterday: [
                    moment().add(-1, 'day').startOf('day'),
                    moment().add(-1, 'day').endOf('day'),
                  ],
                  '2 days ago': [
                    moment().add(-2, 'day').startOf('day'),
                    moment().endOf('day'),
                  ],
                  '3 days ago': [
                    moment().add(-3, 'day').startOf('day'),
                    moment().endOf('day'),
                  ],
                  'This Week': [
                    moment().startOf('week'),
                    moment().endOf('week'),
                  ],
                  'Last Week': [
                    moment().add(-1, 'week').startOf('week'),
                    moment().add(-1, 'week').endOf('week'),
                  ],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
                format="MM/DD/YYYY hh:mm:ssA"
              // disabledDate={rangePickerDisabledDate}
              />
            </div>
          </Row>
          <FormVSpacer />
          <Row align="middle" justify="space-between">
            <FormLabel>{trans('scanToShip.batchPrint.enterDate')}</FormLabel>
            <div className="store-selector-wrapper">
              <RangePicker
                placeholder={[trans('common.startDate'), trans('common.endDate')]}
                onChange={onChangeEnterDate}
                style={{ width: '100%' }}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'hh:mm:ss'),
                    moment('23:59:59', 'hh:mm:ss'),
                  ],
                }}
                ranges={{
                  Today: [moment().startOf('day'), moment().endOf('day')],
                  Yesterday: [
                    moment().add(-1, 'day').startOf('day'),
                    moment().add(-1, 'day').endOf('day'),
                  ],
                  '2 days ago': [
                    moment().add(-2, 'day').startOf('day'),
                    moment().endOf('day'),
                  ],
                  '3 days ago': [
                    moment().add(-3, 'day').startOf('day'),
                    moment().endOf('day'),
                  ],
                  'This Week': [
                    moment().startOf('week'),
                    moment().endOf('week'),
                  ],
                  'Last Week': [
                    moment().add(-1, 'week').startOf('week'),
                    moment().add(-1, 'week').endOf('week'),
                  ],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
                format="MM/DD/YYYY hh:mm:ssA"
              // disabledDate={rangePickerDisabledDate}
              />
            </div>
          </Row>
          <FormVSpacer />
          <Row align="middle" justify="space-between">
            <FormLabel>{trans('scanToShip.batchPrint.storeName')}</FormLabel>
            <div className="store-selector-wrapper">
              <StoreSelector onChange={onChangeStore} />
            </div>
          </Row>
          <FormVSpacer />
          <Row justify="space-between">
            <Input.Search
              allowClear
              //defaultValue={scanText}
              disabled={printState > PRINT_STATE_READY}
              enterButton={<i className="bi-search" />}
              onChange={handleScanTextChange}
              onKeyDown={handleScanTextKeyDown}
              onSearch={handleSearchOrderList}
              placeholder={trans('scanToShip.batchPrint.skuPlaceholder') || ''}
              ref={skuInputRef}
              size="large"
              value={scanText}
            />
          </Row>
          {productFound && (<>
            <FormVSpacer />
            <Row>
              <Typography.Paragraph ellipsis={{ rows: 1 }}>
                <Label className="label-bold">{product.productTitle}</Label>
              </Typography.Paragraph>
            </Row>
            <Row justify="space-between">
              <Col>
                <Label className="label-bold">{trans('scanToShip.batchPrint.label.weight')}:</Label>
                <Label>{product.grossWeight}</Label>
              </Col>
              <Col>
                <Label className="label-bold">{trans('scanToShip.batchPrint.label.length')}:</Label>
                <Label>{product.boxLength}</Label>
              </Col>
              <Col>
                <Label className="label-bold">{trans('scanToShip.batchPrint.label.width')}:</Label>
                <Label>{product.boxWidth}</Label>
              </Col>
              <Col>
                <Label className="label-bold">{trans('scanToShip.batchPrint.label.height')}:</Label>
                <Label>{product.boxHeight}</Label>
              </Col>
            </Row>
          </>)}
          {productSearchEnd && !productFound && (<>
            <FormVSpacer />
            <Row>
              <Alert message={trans('scanToShip.batchPrint.noProduct')} style={{ width: '100%' }} type="warning" />
            </Row>
          </>)}
          {shipmentCount > 0 && (<>
            <FormVSpacer />
            <Row>
              <Col span={12}>
                <div className="grey-block1">
                  <Row>
                    <Label className="label-bold">{trans('scanToShip.batchPrint.qualifiedOrderCount')}:</Label>
                  </Row>
                  <Row align="middle" justify="center">
                    <Label className="label-count">{shipmentCount}</Label>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div className="grey-block2">
                  <Row>
                    <Label className="label-bold">{trans('scanToShip.batchPrint.print')}:</Label>
                  </Row>
                  <FormVSpacer />
                  <Row>
                    <InputNumber
                      disabled={printState > PRINT_STATE_READY}
                      onChange={onPrintCountChange}
                      max={shipmentCount}
                      min={0}
                      size="large"
                      style={{ width: '100%' }}
                      value={planPrintCount}
                    />
                  </Row>
                </div>
              </Col>
            </Row>
            <FormVSpacer height={2} />
            <Row>
              <Col span={12}>
                <div className="grey-block2">
                  <Row>
                    <Label className="label-bold">{trans('scanToShip.batchPrint.printedSuccessfully')}:</Label>
                  </Row>
                  <FormVSpacer />
                  <Row align="middle" justify="center">
                    <Label className="label-success label-count">{printSuccessCount}</Label>
                  </Row>
                </div>
              </Col>
              <Col span={12}>
                <div className="grey-block3">
                  <Row>
                    <Label className="label-bold">{trans('scanToShip.batchPrint.printFailed')}:</Label>
                  </Row>
                  <FormVSpacer />
                  <Row align="middle" justify="center">
                    <Label className="label-error label-count">{printFailedCount}</Label>
                  </Row>
                </div>
              </Col>
            </Row>
            {isInPrint() && (<>
              <FormVSpacer />
              <Row align="middle">
                {printState === PRINT_STATE_PAUSED && <PauseCircleOutlined />}
                {printState === PRINT_STATE_PRINTING && <PrinterOutlined />}
                &nbsp;
                {getLastShipmentOrderId()}
              </Row>
            </>)}
            <FormVSpacer />
            <Row justify="end">
              {printState < PRINT_STATE_PRINTING && <Button
                disabled={printState === PRINT_STATE_IDLE}
                onClick={() => handleBatchPrint(0)}
                type="primary"
              >
                <ButtonIcon className="bi-printer" />
                {trans('scanToShip.batchPrint.title')}
              </Button>}
              {printState === PRINT_STATE_PRINTING && <WarningButton
                onClick={pausePrint}
                type="primary"
              >
                <ButtonIcon className="bi-pause-circle-fill" />
                {trans('scanToShip.batchPrint.pausePrint')}
              </WarningButton>}
              {isPrintInterrupt() && <InfoButton
                onClick={printContinue}
                type="primary"
              >
                <ButtonIcon className="bi-printer" />
                {trans('common.continue')}
              </InfoButton>}
            </Row>
          </>)}
          {productSearchEnd && productFound && shipmentCount === 0 && (<>
            <FormVSpacer />
            <Row>
              <Alert message={trans('scanToShip.batchPrint.noOrder')} style={{ width: '100%' }} type="warning" />
            </Row>
          </>)}
        </BatchPrintWrapper>
        {lockScreen && (
          <ScreenMask>
            <Loading size={LOADING_ICON_SIZE1} />
          </ScreenMask>
        )}
      </>
    </Modal>
  );
};

export default BatchPrintDialog;
