import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Typography, Table } from 'antd';
import { sortByString, leaveEditForm } from 'utils';
import { searchProductByKey } from 'services/product';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { Text } = Typography;

type Props = {
  onHide: (data: any) => void;
  reback: () => void;
  items: { [key: string]: number };
};

// eslint-disable-next-line
export default (props: Props) => {
  const [loading, setLoading] = useState(false);
  const { onHide, items } = props;
  const [dataSource, setDataSource] = useState<Array<any>>([]);
  const [commitDisabled, setCommitDisabled] = useState(false)
  const [form] = Form.useForm();
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let dataSource: Array<StringKAnyVPair> = [];
      const keys = Object.keys(items);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const product = await searchProductByKey(key);
        dataSource.push({
          sku: key,
          quantity: items[key],
          product,
        });
        if(!product) {
          setCommitDisabled(true)
        }
      }
      setDataSource(dataSource);
      setLoading(false);
    };
    fetch();
  }, [items]);

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    const data = [];
    for (let i = 0; i < dataSource.length; i++) {
      let item = dataSource[i];
      if (!item['product']) {
        continue;
      }
      data.push(item);
    }
    onHide(data);
  }, [dataSource, onHide]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide('');
    }
  }, [onHide, form]);
  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (value: any, record: any) => {
        if (record['product']) {
          return (<Text 
            style={{width:350}}
            ellipsis={{ tooltip: value }}
            >{value}</Text>);
        } else {
          return (
            <>
              <Row>
                <Text
                  style={{width:350}}
                  ellipsis={{ tooltip: value }}
                >{value}</Text>
              </Row>
              <Row>
                <Text
                  style={{
                    color: 'white',
                    backgroundColor: 'red',
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  Item does not exist
                </Text>
              </Row>
            </>
          );
        }
      },
      sorter: (a: any, b: any) => {
        return sortByString(a, b, 'sku');
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: (a: any, b: any) => {
        return sortByString(a, b, 'sku');
      },
    },
  ];

  return (
    <Modal
      title={`Scan Result Preview`}
      centered
      visible={true}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="space-between">
          <Space>
            <Button onClick={()=>props.reback()} >
              Back
            </Button>
          </Space>
          <Space>
            <Button disabled={commitDisabled || loading} key="submit" type="primary" onClick={handleSubmit} icon={<CheckOutlined />}>
              Confirm
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Table
          style={{width:'100%'}}
          rowKey="sku"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
        />
      </Spin>
    </Modal>
  );
};
