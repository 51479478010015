import React from 'react';
import { isEqualObject } from 'crud-object-diff';
import { Row, Select } from 'antd';
import { LoadingIcon, message } from 'components/common';

//import { fetchAllShipAccountServices } from 'services/ship';
import { fetchCarriers } from 'services/shipment';
import { getCarrierServiceList } from 'services/shipment';

type SelectorProps = {
  carrierNum?: number;
  onChange?: Function;
  size?: GeneralSizeType;
  style?: StringKAnyVPair;
  value?: Array<string>;
};

const carrierList: StringKAnyVPair[] = [];
const carrierServiceDict: {[key: number]: any;} = {};

// eslint-disable-next-line
export default (props: SelectorProps) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [accountOptions, setAccountOptions] = useState<StringKAnyVPair[]>([]);
  const [carrierNum, setCarrierNum] = useState<number>();
  const [currentValues, setCurrentValues] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const size = props.size || 'middle';
  const style = props.style || {};

  // eslint-disable-next-line
  const getAllServices = async () => {
    if (carrierList.length > 0) {
      let options: StringKAnyVPair[] = [];

      carrierList.forEach(e => {
        if (typeof e.carrierNum === 'number') {
          const cid = e.carrierNum;

          if (carrierServiceDict[cid]) {
            options = [...options, ...carrierServiceDict[cid]];
          }
        }
      });
      setAccountOptions(options);
      setIsLoadingOptions(false);

      return;
    }

    try {
      let carriers: StringKAnyVPair[] = [];

      if (carrierList.length === 0) {
        carriers = await fetchCarriers();
      }

      //console.log('carriers ----->', carriers);
      if (Array.isArray(carriers) && carriers.length > 0) {
        carriers.forEach(e => carrierList.push(e));
      }
    } catch (e) {
      message.error(`Fetch carriers error: ${e}`);
    }

    let options: StringKAnyVPair[] = [];

    for (let i = 0; i < carrierList.length; i++) {
      if (typeof carrierList[i].carrierNum === 'number') {
        const cid = carrierList[i].carrierNum;

        try {
          const services = await getCarrierServiceList(cid);

          if (Array.isArray(services) && services.length > 0) {
            if (!carrierServiceDict[cid]) carrierServiceDict[cid] = [...services];
            options = [...options, ...services]
          }
        } catch(e) {
          message.error(`Fetch packages error: ${e}`);
        }
      }
    }

    if (options.length > 0) {
      setAccountOptions(options);
    }

    setIsLoadingOptions(false);
  };

  // eslint-disable-next-line
  const getServices = async (carrierId: number | undefined) => {
    const cid = typeof carrierId === 'undefined' ? -1 : carrierId;

    if (carrierServiceDict[cid]) {
      setIsLoadingOptions(false);
      return;
    }

    try {
      const accounts = await getCarrierServiceList(cid);
      console.log('map svrs', accounts);
      setIsLoadingOptions(false);

      if (Array.isArray(accounts) && accounts.length > 0) {
        setAccountOptions([...accounts, ...accountOptions]);
        carrierServiceDict[cid] = [...accounts];
      } else {
        setAccountOptions([]);
      }
    } catch (e) {
      message.error(`Fetch packages error: ${e}`);
    }
  };

  const accountSelectOptions = (options: StringKAnyVPair[]) => {
    return options.map((e, i) => (
      <Select.Option key={`${e.carrierServiceNum}_${i}`} label={e.name} value={e.name}>
        {e.name}
      </Select.Option>
    ));
  };

  const carrierSelectOptions = () => {
    return carrierList.map((e, i) => (
      carrierServiceDict[e.carrierNum] ?
      <Select.OptGroup key={`e.carrierNum_${i}`} label={e.name}>
        {accountSelectOptions(carrierServiceDict[e.carrierNum])}
      </Select.OptGroup> : null
    ));
  };

  const onSelectionChange = (value: string[]) => {
    setSelectedValues(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      //getServices();
      setIsLoadingOptions(true);
      getAllServices();
      setInited(true);
    }

    if (carrierNum !== props.carrierNum) {
      setIsLoadingOptions(true);
      getServices(props.carrierNum);
      setCarrierNum(props.carrierNum);
    }

    if (props.value) {
      if (!isEqualObject(props.value, currentValues)) {
        setSelectedValues([...props.value]);
        setCurrentValues([...props.value]);
      }
    }
  }, [
    carrierNum,
    currentValues,
    getServices,
    inited,
    props,
    selectedValues
  ]);

  return (
    <Row align="middle" style={style}>
      {isLoadingOptions ? (
        <LoadingIcon />
      ) : (
        <Select
          dropdownClassName="scan-ship-select-dropdown-option-container"
          mode="multiple"
          onChange={onSelectionChange}
          size={size}
          style={{ width: '100%' }}
          value={selectedValues}
        >
          {carrierList.length > 0 ? carrierSelectOptions() : accountSelectOptions(accountOptions)}
        </Select>
      )}
    </Row>
  );
};
