import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Row,
  Form,
  Col,
  Space,
  DatePicker,
  Input,
  Select,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  ClearButton,
  ColumnDataType,
  DataGridPlus,
  ExportCSV,
  FormLabel,
  HeaderTitle,
  SelectWarehouse,
  GreyCardWrapper,
  CopySpan,
  ButtonIcon,
  TablesFilter,
  // SpaceCell,
} from 'components/common';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import {
  convertToUtcTime,
} from 'utils';
import { fetchWarehouseTransferList } from 'services/inventory';
import OrderDetail from 'components/Sales/ScanVerifyPrint/OrderDetailDialog';
import CloseModal from './CloseTransferModal';
// import ReceiveDetail from './ReceiveDetailModal';
import CloseDetailModal from './CloseDetailDialog';
import DetailModal from './DetailDialog';
import { DiffOutlined, SearchOutlined } from '@ant-design/icons';
import { ClassicCard } from 'components/common/styles';
import {
  warehouseTransferStatusEnum as statusEnum
} from 'constants/enums'
const { RangePicker } = DatePicker;

const transferTypeEnum: { [key: string]: string } = {
  '0': 'Inbound',
  '1': 'Outbound',
};

export default function LocationReport(): JSX.Element {
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(12);
  const [loading, setLoading] = useState(false);
  const [tempParams, setTempParams] = useState<any>();
  const [keyWordType, setKeyWordType] = useState<any>('BatchNumber');
  const limitRef = useRef(10);
  const skipRef = useRef(0);
  const sortString = React.useRef<string>('TransferNum desc');
  const [current, setCurrent] = React.useState<any>();
  const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);
  const [closeModalVisible, setCloseModalVisible] = useState(false);
  // const [receiveDetailVisible, setReceiveDetailVisible] = useState(false);
  const [detaiModallVisible, setDetaiModallVisiblee] = useState(false);
  const [closeDetailModalVisible, setCloseDetailModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [form] = Form.useForm();

  const columns = [
    {
      header: 'Batch Number',
      name: 'batchNumber',
      userSelect: true,
      sortable: true,
      resizable: true,
      draggable: true,
      defaultFlex: 1,
    },
    {
      header: 'Inbound / Outbound',
      name: 'transferType',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: transferTypeEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={transferTypeEnum[`${data.transferType}`]} />;
      },
    },
    {
      header: 'From #',
      name: 'fromWarehouseCode',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'To #',
      name: 'toWarehouseCode',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'SKU Count',
      name: 'count',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Quantity',
      name: 'quantity',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Processed Qty',
      name: 'shipedQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.transferType === 1 ? data.shipedQty : data.receiveQty} />;
      },
    },
    {
      header: 'Status',
      name: 'warehouseTransferStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: statusEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={statusEnum[`${data.warehouseTransferStatus}`]} />;
      },
    },
    {
      header: 'Enter Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Complete Date',
      name: 'completeDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Update Date',
      name: 'updateDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },

    // {
    //   header: 'Order Number',
    //   name: 'fulfillmentOrderNum',
    //   resizable: true,
    //   draggable: true,
    //   sortable: true,
    //   defaultFlex: 1,
    //   render: (row: any) => {
    //     const { data } = row;

    //     return data.fulfillmentOrderNum > 0 ? (
    //       <SpaceCell
    //         onTextClick={() => onClickChannelOrderID(data)}
    //         text={data.fulfillmentOrderNum}
    //         textIsButton
    //       />
    //     ) : (
    //       data.fulfillmentOrderNum
    //     );
    //   },
    // },
    // {
    //   header: 'Receive Number',
    //   name: 'transferReceiveNum',
    //   resizable: true,
    //   draggable: true,
    //   sortable: true,
    //   userSelect: true,
    //   defaultFlex: 1,
    //   render: (row: any) => {
    //     const { data } = row;
    //     return data.transferReceiveNum ? (
    //       <Link
    //         to={`/warehouse-transfer/receive?batchNumber=${data.batchNumber}&warehouseNum=${data.warehouseNum}`}
    //       >
    //         {data.transferReceiveNum}
    //       </Link>
    //     ) : (
    //       ''
    //     );
    //   },
    // },
    // {
    //   header: 'Sync Status',
    //   name: 'syncStatus',
    //   resizable: true,
    //   draggable: true,
    //   sortable: true,
    //   userSelect: true,
    //   defaultFlex: 1,
    //   render: (value: any) => {
    //     const { data } = value;
    //     return syncStatusEnum[`${data.syncStatus}`];
    //   },
    // },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      maxWidth: 300,
      minWidth: 300,
      render: (value: any) => {
        const { data } = value;
        return (
          <Space>
            <Button
              id={`row_${data.batchNumber}_detail`}
              onClick={() => {
                setCurrent(data);
                if (data.transferType === 0) {
                  setDetaiModallVisiblee(true);
                } else {
                  setDetailDialogVisible(true);
                }
              }}
              type="primary"
              icon={<ButtonIcon className="bi bi-file-earmark-text" />}
            >
              Detail
            </Button>
            {data.transferType === 0 && data.warehouseTransferStatus !== 2 && (
              <Link
                to={`/warehouse-transfer/receive?batchNumber=${data.batchNumber}&warehouseNum=${data.warehouseNum}`}
              >
                <Button type="primary" id={`row_${data.batchNumber}_receive`}>
                  Receive
                </Button>
              </Link>
            )}

            <Button
              id={`row_${data.batchNumber}_${data.warehouseTransferStatus === 2 && data.transferType === 0
                ? 'history'
                : 'complete'
                }`}
              disabled={data.warehouseTransferStatus === 2 && data.transferType !== 0}
              onClick={() => {
                setCurrent(data);
                if (data.transferType === 0) {
                  setCloseModalVisible(true);
                } else {
                  setCloseDetailModalVisible(true);
                }
              }}
              type="primary"
              icon={<DiffOutlined />}
            >
              {data.warehouseTransferStatus === 2 && data.transferType === 0
                ? 'Receive History'
                : 'Complete'}
            </Button>
          </Space>
        );
      },
    },
  ];

  // const onClickChannelOrderID = (data: Order) => {
  //   const selObj = window.getSelection();
  //   let open = selObj && selObj.toString() ? false : true;

  //   if (open) {
  //     setCurrent(data);
  //     setDetailDialogVisible(true);
  //   }
  // };

  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);
  /**

  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    const params = await form.validateFields();

    const queryparams = {
      $skip: 0,
      $top: limitRef.current,
      $sortBy: sortString.current,
      batchNumber: keyWordType === 'BatchNumber' ? params.keyWord : undefined,
      fulfillmentOrderNum:
        keyWordType === 'OrderNumber' ? params.keyWord : undefined,
      // warehouseNum: params.WarehouseNum.warehouseNum,
      sku: params.SKU,
      transferType: params.TransferType,
      transferStatus: params.TransferStatus,
      syncStatus: params.SyncStatus,
      transferDate: params.TransferDate
        ? `${convertToUtcTime(params.TransferDate[0].startOf('day').format())}@${convertToUtcTime(params.TransferDate[1].endOf('day').format())}`
        : undefined,
      completeDate: params.ReceiveDate
        ? `${convertToUtcTime(params.ReceiveDate[0].startOf('day').format())}@${convertToUtcTime(params.ReceiveDate[1].endOf('day').format())}`
        : undefined,
    };
    try {
      setLoading(true);
      const res = await fetchWarehouseTransferList(
        params.WarehouseNum.warehouseId,
        queryparams
      );
      setLoading(false);

      if (res) {
        setData(res.data);
        setTotal(res.count);
        setTempParams({
          batchNumber:
            keyWordType === 'BatchNumber' ? params.keyWord : undefined,
          fulfillmentOrderNum:
            keyWordType === 'OrderNumber' ? params.keyWord : undefined,
          warehouseId: params.WarehouseNum.warehouseId,
          sku: params.SKU,
          transferType: params.TransferType,
          transferStatus: params.TransferStatus,
          syncStatus: params.SyncStatus,
          transferDate: params.TransferDate
            ? `${moment(params.TransferDate[0]).format('YYYY-MM-DD')}@${moment(
              params.TransferDate[1]
            ).format('YYYY-MM-DD')}`
            : undefined,
          completeDate: params.ReceiveDate
            ? `${moment(params.ReceiveDate[0]).format('YYYY-MM-DD')}@${moment(
              params.ReceiveDate[1]
            ).format('YYYY-MM-DD')}`
            : undefined,
        });
      }
    } catch (error) {
      //setLoading(false);
      setLoading(false);
    }
  }, [form, keyWordType]);
  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number, sort: string) => {
      if (!tempParams) return;
      const queryparams = {
        $skip: skip,
        $top: limit,
        $sortBy: sort,
        ...tempParams,
      };
      try {
        setLoading(true);
        const res = await fetchWarehouseTransferList(
          tempParams.warehouseId,
          queryparams
        );
        setLoading(false);
        if (res) {
          setData(res.data);
          setTotal(res.count);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [tempParams]
  );

  /* eslint-disable */

  /* eslint-enable */

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };
  return (
    <>
      <HeaderTitle breadcrumb={['Warehouse Transfer', 'List']} />
      <GreyCardWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form
          form={form}
          {...formItemLayout}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <ClassicCard style={{ width: '100%' }}>
            <Row>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 9 },
                    style: { display: 'flex', flexDirection: 'row', justifyContent: 'end' }
                  }}
                  label={
                    <Select
                      id="keyword_type_selector"
                      style={{ padding: 0 }}
                      size="small"
                      bordered={false}
                      dropdownMatchSelectWidth={false}
                      value={keyWordType}
                      defaultValue="BatchNumber"
                      onChange={(value) => {
                        setKeyWordType(value);
                      }}
                    >
                      <Select.Option value="BatchNumber">
                        Batch Number
                      </Select.Option>
                      <Select.Option value="OrderNumber">
                        Order Number
                      </Select.Option>
                      <Select.Option value="R0Number">R0 Number</Select.Option>
                    </Select>
                  }
                >
                  <Form.Item noStyle name="keyWord">
                    <Input id="keyword_input" type="text" allowClear />
                  </Form.Item>
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="WarehouseNum"
                  label={<FormLabel>Warehouse</FormLabel>}
                >
                  <SelectWarehouse id="warehouse_selector" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item name="SKU" label={<FormLabel>SKU / UPC</FormLabel>}>
                  <Input id="sku_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="TransferDate"
                  label={<FormLabel>Enter Date</FormLabel>}
                >
                  <RangePicker
                    id="enter_date_picker"
                    style={{ width: '100%' }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="ReceiveDate"
                  label={<FormLabel>Complete Date</FormLabel>}
                >
                  <RangePicker
                    id="complete_date_picker"
                    style={{ width: '100%' }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="TransferType"
                  label={<FormLabel>Transfer Type</FormLabel>}
                >
                  <Select
                    id="transfer_type_selector"
                    dropdownMatchSelectWidth={false}
                    allowClear
                  >
                    <Select.Option value={0}>Inbound</Select.Option>
                    <Select.Option value={1}>Outbound</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="TransferStatus"
                  label={<FormLabel>Transfer Status</FormLabel>}
                >
                  <Select
                    id="transfer_status_selector"
                    dropdownMatchSelectWidth={false}
                    allowClear
                  >
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>Processing</Select.Option>
                    <Select.Option value={2}>Complete</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="SyncStatus"
                  label={<FormLabel>Sync Status</FormLabel>}
                >
                  <Select
                    id="sync_status_selector"
                    dropdownMatchSelectWidth={false}
                    allowClear
                  >
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>Success</Select.Option>
                    <Select.Option value={2}>Failed</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="center">
              <Space>
                <Button
                  id="search_btn"
                  type="primary"
                  onClick={() => {
                    handleSearch();
                  }}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
                <ExportCSV
                  data={data}
                  columns={columns.filter((item) => item.header !== 'Action')}
                  scope="Shipment"
                  formatColumns={{
                    transferType: transferTypeEnum,
                    warehouseTransferStatus: statusEnum,
                  }}
                />
                <ClearButton onClick={handleReset} />
              </Space>
            </Row>
          </ClassicCard>
        </Form>
      </GreyCardWrapper>

      <GreyCardWrapper
        style={{
          marginTop: 20,
        }}
      >
        <TablesFilter
          columns={columns}
          dataSource={data}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          name="locationReport"
          autoWith={false}
          showScrollButton
          skip={skipRef.current}
          count={total}
          onSortInfoChange={(v: any, d: any) => {
            if (v) {
              if (v.dir === 1) {
                sortString.current = `${v.id} asc`;
                handleDataGridChange(
                  limitRef.current,
                  skipRef.current,
                  sortString.current
                );
              }
              if (v.dir === -1) {
                sortString.current = `${v.id} desc`;
                handleDataGridChange(
                  limitRef.current,
                  skipRef.current,
                  sortString.current
                );
              }
            } else {
              sortString.current = 'TransferNum desc';
              handleDataGridChange(
                limitRef.current,
                skipRef.current,
                sortString.current
              );
            }
          }}
          dataSource={filteredData}
          onChange={(limit, skip) =>
            handleDataGridChange(limit, skip, sortString.current)
          }
          pageSizes={[10, 50, 200, 500, 1000]}
          loading={loading}
          columns={columns}
          idProperty="transferNum"
          pagination="remote"
          /* eslint-disable react/jsx-props-no-spreading */
          {...({} as any)}
        />
      </GreyCardWrapper>
      {detailDialogVisible && current && (
        <OrderDetail
          allowPopupWindow
          isHistory={false}
          fulfillmentOrderId={current.fulfillmentOrderId}
          fulfillmentOrderNum={current.fulfillmentOrderNum}
          onClose={() => setDetailDialogVisible(false)}
        />
      )}
      {closeModalVisible && current && (
        <CloseModal
          transfer={current}
          onHide={() => setCloseModalVisible(false)}
          onRefresh={() => {
            setCloseModalVisible(false);
            handleDataGridChange(
              limitRef.current,
              skipRef.current,
              sortString.current
            );
          }}
        />
      )}
      {/* {receiveDetailVisible &&
        <ReceiveDetail
          transfer={current}
          onHide={() => setReceiveDetailVisible(false)}
        />
      } */}
      {detaiModallVisible && (
        <DetailModal
          transfer={current}
          onHide={() => setDetaiModallVisiblee(false)}
        />
      )}
      {closeDetailModalVisible && (
        <CloseDetailModal
          transfer={current}
          onHide={() => setCloseDetailModalVisible(false)}
          onRefresh={() => {
            setCloseDetailModalVisible(false);
            handleDataGridChange(
              limitRef.current,
              skipRef.current,
              sortString.current
            );
          }}
        />
      )}
    </>
  );
}
