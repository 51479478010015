import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { message } from 'components/common';
import { FormLabel } from 'components/common';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
  addAddress,
  updateAddress,
  getCountrys,
  getStates,
} from 'services/address';
import { sortByString } from 'utils'

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  address?: AddressRow;
  addressType?: number;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, address, addressType } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [stateList, setStateList] = useState<StateRow[]>([]);
  const [countryList, setCountryList] = useState<CountryRow[]>([]);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      AddressNum: address ? address.addressNum : 0,
      AddressAlias: params.addressAlias,
      AddressType: 1,
      AddressBookType: addressType ? addressType : 0,
      ContactName: params.contactName,
      Company: params.company,
      Street1: params.street1,
      Street2: params.street2,
      Street3: params.street3,
      City: params.city,
      County: '',
      StateNum: params.stateNum,
      CountryNum: params.countryNum,
      PostalCode: params.postalCode,
      Phone: params.phone,
      Email: params.email,
      Residential: 0,
      Note: params.note,
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!address) {
        res = await addAddress(postParams);
      } else {
        res = await updateAddress(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: address ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const fetchCountryList = async () => {
    try {
      setLoading(true);
      const res = await getCountrys();
      setLoading(false);
      if (res.isSuccess) {
        setCountryList(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchStateList = async (id: number) => {
    try {
      setLoading(true);
      const res = await getStates(id);
      setLoading(false);
      if (res.isSuccess) {
        // setStateList(res.data);
        setStateList(res.data.sort((a:any,b:any)=> sortByString(a, b, 'code')));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    fetchCountryList();
    if (address) {
      fetchStateList(address.countryNum);
    }
  }, [address]);
  /* eslint-enable */

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${address ? 'Edit' : 'Add'} Address`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {address ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${address ? 'Saving' : 'Adding'}`
                : `${address ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="addressAlias"
            initialValue={address?.addressAlias}
            label={<FormLabel>Address Alias</FormLabel>}
            rules={[{ required: true, message: 'please input address alias' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="countryNum"
            initialValue={address?.countryNum}
            label={<FormLabel>Country</FormLabel>}
            rules={[{ required: true, message: 'please select Country' }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              onChange={(value: number) => {
                form.setFieldsValue({
                  state: undefined,
                });
                fetchStateList(value);
              }}
            >
              {countryList.map((item) => (
                <Select.Option
                  key={item.countryNum}
                  value={item.countryNum}
                  label={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item
            name="addressBookType"
            initialValue={address?.addressBookType}
            label={<FormLabel>Address Book Type</FormLabel>}
          >
            <Select>
              <Select.Option value={1}>Warehouse</Select.Option>
              <Select.Option value={2}>Supplier</Select.Option>
              <Select.Option value={3}>Channel Account</Select.Option>
              <Select.Option value={4}>Shipping Account</Select.Option>
              <Select.Option value={5}>
                Drop Ship Third Part Address
              </Select.Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            name="contactName"
            initialValue={address?.contactName}
            label={<FormLabel>Contact Name</FormLabel>}
            rules={[{ required: true, message: 'please input Contact Name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company"
            initialValue={address?.company}
            label={<FormLabel>Company</FormLabel>}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="street1"
            initialValue={address?.street1}
            label={<FormLabel>Address Line 1</FormLabel>}
            rules={[{ required: true, message: 'please input Address 1' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="street2"
            initialValue={address?.street2}
            label={<FormLabel>Address Line 2</FormLabel>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="street3"
            initialValue={address?.street3}
            label={<FormLabel>Address Line 3</FormLabel>}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="city"
            initialValue={address?.city}
            label={<FormLabel>City</FormLabel>}
            rules={[{ required: true, message: 'please input city' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="stateNum"
            initialValue={address?.stateNum}
            label={<FormLabel>State</FormLabel>}
            rules={[{ required: true, message: 'please select state' }]}
          >
            <Select showSearch optionFilterProp="label">
              {stateList.map((item) => (
                <Select.Option
                  key={item.stateNum}
                  value={item.stateNum}
                  label={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item
            name="county"
            initialValue={address?.county}
            label={<FormLabel>County</FormLabel>}
          >
            <Input />
          </Form.Item> */}

          <Form.Item
            name="postalCode"
            initialValue={address?.postalCode}
            label={<FormLabel>Zip Code</FormLabel>}
            rules={[{ required: true, message: 'please input Zip Code' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            initialValue={address?.phone}
            label={<FormLabel>Phone #</FormLabel>}
            rules={[{ required: true, message: 'please input phone #' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            initialValue={address?.email}
            label={<FormLabel>Email</FormLabel>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="note"
            initialValue={address?.note}
            label={<FormLabel>Remark</FormLabel>}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
