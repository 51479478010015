import React, { useState, useCallback } from 'react';
import { Input } from 'antd';
import {
  // fetchProducts,
  searchProductByKey,
  fetchProducts,
} from 'services/product';
import message from '../message';
import SelectProductDialog from './SelectProductDialog';

const { Search } = Input;
type SelectProductProps = {
  value?: string;
  width?: number;
  placeholder?: string;
  onChange: (fieldName: string, product: ProductRow) => void;
  noControl?: boolean;
  onBlurAble?: boolean;
};
/**
 * Return SelectProduct Diallog
 * @param {SelectProductProps} props
 * @returns {JSX.Element}
 */
export default function SelectProduct(props: SelectProductProps): JSX.Element {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductRow>();
  const {
    placeholder,
    onChange,
    value: oriValue,
    noControl,
    onBlurAble,
    width = '100%',
  } = props;
  React.useEffect(() => {
    if (typeof oriValue === 'string') {
      setValue(oriValue || '');
    }
  }, [oriValue]);
  /**
   * show search code by team dialog
   */
  const handleIdentifier = useCallback(() => {
    setVisible(true);
  }, []);

  /**
   * handle press Enter
   */
  const handlePressEnter = useCallback(async () => {
    try {
      // const query = {
      //   // $filter: `SKU eq '${value}' or UPC eq '${value}'`,
      //   uniqueCode: value,
      // };
      setLoading(true);
      const res = await fetchProducts({uniqueCode: value});
      // const { products: data } = await fetchProducts(query);
      setLoading(false);
      if (res.products.length > 0) {
        onChange('sku', { ...res.products[0], id: res.products[0].fulfillmentProductNum, productId: res.products[0].productId });
        setProduct({ ...res.products[0], id: res.products[0].fulfillmentProductNum });
        setValue(value);
      } else {
        message.warning({ content: 'SKU/UPC not found' });
        onChange('sku', { id: -1, productId: '' });
        setProduct(undefined);
        setValue('');
      }
    } catch (error) {
      setLoading(false);
    }
  }, [value, onChange]);

  /**
   * handle select product event
   */
  const handleSelectProductChange = useCallback(
    (fieldName: string, product: ProductRow) => {
      onChange(fieldName, product);
      setValue((product as any)[fieldName]);
      setVisible(false);
    },
    [onChange]
  );
  let style = {};
  if (width) {
    style = {
      width,
    };
  }
  return (
    <>
      {visible && (
        <SelectProductDialog
          onChange={handleSelectProductChange}
          onHide={() => {
            setVisible(false);
          }}
        />
      )}
      <Search
        style={style}
        placeholder={placeholder || 'SKU'}
        value={value}
        loading={loading}
        defaultValue={value}
        onChange={(e) => {
          const { value: currentValue } = e.target;
          setValue(currentValue);
          if (!noControl) {
            onChange('', { sku: currentValue } as any);
          }
        }}
        onBlur={
          onBlurAble
            ? () => {
                if (
                  (value && !product) ||
                  (value &&
                    product &&
                    value !== product.sku &&
                    value !== product.upc)
                ) {
                  handlePressEnter();
                } else {
                  product && onChange('sku', product);
                }
              }
            : () => {}
        }
        onPressEnter={() => handlePressEnter()}
        // onKeyDown={(e) => e.keyCode === 9 && handlePressEnter()}
        onSearch={handleIdentifier}
      />
    </>
  );
}
SelectProduct.defaultProps = {
  value: undefined,
  width: undefined,
  placeholder: undefined,
};
/**
 * valid product
 * @param {ProductRow} product
 * @returns {Promise<boolean>}
 */
export async function fullProduct(
  product: ProductRow,
  msg?: string
): Promise<ProductRow | boolean> {
  let skuProduct = product;
  if (!product.id) {
    try {
      skuProduct = (await searchProductByKey(product.sku as any)) as any;
      if (!skuProduct) {
        message.warning({
          content: msg
            ? msg
            : `Product with SKU '${product.sku}' does not exist.`,
        });
        return false;
      }
    } catch (error) {
      return false;
    }

  }
  return skuProduct;
}
