import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import TransactionHistory from 'components/Reports/TransactionHistory';
// eslint-disable-next-line
export default function TransactionHistoryScreen(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <TransactionHistory />
      </SiteContent>
    </ContentLayout>
  );
}
