import React, { useEffect, useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select } from 'antd';
// import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { SelectWarehouse } from 'components/common';
import {
  addWarehouseShoppingAround,
  updateWarehouseShoppingAround,
} from 'services/shoppingAround';
import { getCarrierServices } from 'services/shipment';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  carries: any[];
  shipAround?: any;
  isAll?: boolean;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, carries, shipAround, isAll } = props;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [services, setServices] = useState<any[]>([]);
  const [aroundServices, setAroundServices] = useState<any[]>([]);

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      WarehouseShoppingAroundNum: shipAround
        ? shipAround.warehouseShoppingAroundNum
        : 0,
      ...params,
      WarehouseNum: params.WarehouseNum.warehouseNum || params.WarehouseNum.id,
    };
    try {
      setLoading(true);
      setSubmitLoading(true);
      let res;
      if (!shipAround) {
        res = await addWarehouseShoppingAround(postParams);
      } else {
        res = await updateWarehouseShoppingAround(postParams);
      }
      setLoading(false);
      setSubmitLoading(false);
      if (res.isSuccess) {
        document.body.style.overflowY = 'unset';
        onHide();
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onOk() {
          onHide();
        },
      });
    }
  };

  const getServices = async (carrierNum: number) => {
    try {
      setLoading(true);
      const res = await getCarrierServices(carrierNum);
      setLoading(false);
      if (res.isSuccess) {
        setServices(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAroundServices = async () => {
    try {
      setLoading1(true);
      const res = await getCarrierServices(0);
      setLoading1(false);
      if (res.isSuccess) {
        setAroundServices(res.data);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    getAroundServices();
  }, []);

  useEffect(() => {
    if (shipAround) {
      getServices(shipAround.carrierNum);
    }
  }, [shipAround]);

  return (
    <Modal
      title={`${shipAround ? 'Edit' : 'Add'} Shopping Around`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_or_add_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {shipAround ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${shipAround ? 'Saving' : 'Adding'}`
                : `${shipAround ? 'Save' : 'Add'}`}
            </Button>
            <Button id="cancel_button" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading || loading1}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="WarehouseNum"
            initialValue={
              shipAround ? { id: shipAround.warehouseNum } : undefined
            }
            label={<FormLabel>Warehouse</FormLabel>}
            rules={[{ required: true, message: 'please select warehouse' }]}
          >
            <SelectWarehouse
              id="warehouse_select"
              disabled={shipAround ? (isAll ? false : true) : false}
              width={437}
              placeholder="please select warehouse"
            />
          </Form.Item>

          <Form.Item
            name="ShoppingAroundCarrierServiceNum"
            initialValue={
              shipAround
                ? shipAround.shoppingAroundCarrierServiceNum
                : undefined
            }
            label={<FormLabel>Shopping Around Carrier Service</FormLabel>}
            rules={[
              {
                required: true,
                message: 'please select Shopping Around Carrier Service',
              },
            ]}
          >
            <Select id="shipping_around_carrier_service_select" showSearch optionFilterProp="label">
              {aroundServices.map((item) => (
                <Select.Option
                  key={item.carrierServiceNum}
                  value={item.carrierServiceNum}
                  label={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="CarrierNum"
            initialValue={shipAround ? shipAround.carrierNum : undefined}
            label={<FormLabel>Carrier</FormLabel>}
            rules={[{ required: true, message: 'Please select Carrier!' }]}
          >
            <Select
              id="carrier_select"
              onChange={(i) => {
                getServices(Number(i));
                form.setFieldsValue({
                  CarrierServiceNum: undefined,
                });
              }}
            >
              {carries
                .filter((i) => i.carrierNum !== 0)
                .map((item) => (
                  <Select.Option key={item.carrierNum} value={item.carrierNum}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="CarrierServiceNum"
            initialValue={shipAround ? shipAround.carrierServiceNum : undefined}
            label={<FormLabel>Service</FormLabel>}
            rules={[{ required: true, message: 'please select service' }]}
          >
            <Select
              id="carrier_service_select"
              disabled={!form.getFieldValue('CarrierNum')}
              showSearch
              optionFilterProp="label"
            >
              {services.map((item) => (
                <Select.Option
                  key={item.carrierServiceNum}
                  value={item.carrierServiceNum}
                  label={item.name}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
