import React, { useCallback } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Input,
  //InputNumber,
  Menu,
  Modal,
  Row,
  //Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  CloseOutlined,
  ContainerOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  LikeOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';
import {
  /*DataGrid,*/
  FullPageButton,
  HeaderTitle,
  HoverBgButton,
  Loading,
  LoadingIcon,
  SignalRLinkLight,
  ScreenMask,
  message,
} from 'components/common';
import DataGrid from 'components/common/DataGrid0';
//import { FormLabel } from 'components/common';
import {
  CellNumberInput,
  FormVSpacer,
  SectionWrapper,
  TagButton,
} from 'components/common/styles/CustomPropsComponents';
import {
  ButtonIcon,
  //CompactButton,
  InfoButton,
  Label,
} from 'components/common/styles';
import { RootState } from 'reducers';
import {
  COMMON_DATE_RANGE_FORMAT,
  HEADER_TITLE_ICON_SIZE,
  LOADING_ICON_SIZE1,
  LOADING_ICON_SIZE5,
  LS_DEFAULT_WAREHOUSE_KEY,
  LS_PACKING_PRINTER_KEY,
  LS_PACKING_PRINTER2_KEY,
  LS_SCALE_KEY,
  LS_SHIPMENT_PRINTER_KEY,
  LS_SHIPMENT_PRINTER_4x8_KEY,
  LS_SHIPMENT_PRINTER_COTON_KEY,
  LS_SKIP_PRINT_PACK_SLIP,
  MESSAGE_BAR_POS_TOP1,
  PAGE_MODE_FULL_PAGE,
  PAGE_MODE_NORMAL,
  PRINT_MESSAGE_RESPONSE_TIMEOUT,
  REGULAR_SECTION_VERTICAL_SPACING,
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_ITEM_NOT_VERIFY_STATE,
  SHIPMENT_ITEM_VERIFY_STATE,
  SHIPMENT_SHIPPING_STATE,
  SIGNALR_CONNECTION_CONNECTED,
  SIGNALR_CONNECTION_DISCONNECTED,
  USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
} from 'constants/config';
import { printCartonLabel, printPackingSlip } from 'actions/printActions';
import { setPageShowMode } from 'actions/regularActions';
import {
  loadPrinters,
  loadScales,
  setPackingPrinterById,
  setPackingPrinter2ById,
  setPrintLabelMessage,
  setPrintingHang,
  setScale,
  setScaleById,
  setShipmentPrinterById,
  setShipmentPrinter4x8ById,
  setShipmentPrinterCotonById,
} from 'actions/salesAction';
import fallbackImage from 'assets/images/logo192.png';
import { fetchOrder } from 'services/orders';
import {
  clearManyShipmentItems,
  clearShipmentItem,
  clearVerifiedShipment,
  fetchOrderShipment,
  fetchOrderShipmentByTrackingNumber,
  fetchShipmentItemsById,
  getChannelPackingSlipList,
  markShipmentVerified,
  verifyManyShipmentItems,
  verifyShipmentItem,
} from 'services/sales';
import { autoSplitShipment, fetchShipmentDetail } from 'services/shipment';
import { fetchWarehouseEntity } from 'services/warehouse';
import {
  getWarehouseCodeFromCache,
  getWarehouseIdFromCache,
  nowTime,
  playBell,
} from 'utils';
import { userProfiles } from 'utils/auth';
import AlertDialog from './AlertDialog';
import BatchPrintDialog from './BatchPrint/BatchPrintDialog';
import DeviceSettingDialog from './DeviceSettingDialog';
import LabelQueue from './LabelQueue';
//import ManageShipmentDialog from './ManageShipment';
import ManageShipmentDialog from './ManageShipment/ManageShipmentDialog';
import ShipMultiPackages from './ShipMultiPackages';
import ShipmentConfirmDialog from './ShipmentConfirmDialog';
import OrderDetailDialog from './OrderDetailDialog';
import ShipmentDetailDialog from './ShipmentDetailDialog';
import PrintLabel from './PrintLabel';
import PrintSummary from './PrintSummary';
import PriorityPicklistDialog from './PriorityPicklistDialog';
//import PrinterSelector from './PrinterSelector';
//import ScaleSelector from './ScaleSelector';
import {
  BottomButtonRow,
  HeaderGroup,
  LARGE_WIDTH_LIMIT,
  LabelFormDropdownItem,
  MainContainer,
  MIDDLE_WIDTH_LIMIT,
  ShipmentTabs,
  VerifyContainer,
} from './styles';
import BundleModal from '../../Catalog/CatalogView/BundleModal';

export enum ScanToShip {
  labelCDVInputId = 'label-carrier-declare-value',
  labelHeightInputId = 'label-height',
  labelLengthInputId = 'label-length',
  labelPkgSelectorId = 'label-sel-pkg',
  labelPrintBtnId = 'label-btn-print',
  labelSignSelectorId = 'label-signature',
  labelShopAroundBtnId = 'label-btn-shoparound',
  labelSvrSelectorId = 'label-sel-srv',
  labelWidthInputId = 'label-width',
  weightLbInputId = 'label-weight-lb',
  weightOzInputId = 'label-weight-oz',
};

export type PrintedLabel = {
  isOnly: boolean; // whether is the only one shipment
  orderId: string;
  shipmentNum: number;
  shipmentTitle: string;
  printDate: string;
  uuid: string;
};

export type ShipmentItem = {
  scanError: boolean;
  scanMessage: string;
} & OrderShipmentItem;

export type ShipmentItemDict = {
  [key: number]: ShipmentItem[];
};

export type ShipmentUnit = {
  shipmentTitle: string;
} & OrderShipment;

const tabKeyQueue: string[] = [
  ScanToShip.weightLbInputId,
  ScanToShip.weightOzInputId,
  ScanToShip.labelSvrSelectorId,
  ScanToShip.labelPkgSelectorId,
  ScanToShip.labelLengthInputId,
  ScanToShip.labelWidthInputId,
  ScanToShip.labelHeightInputId,
  ScanToShip.labelSignSelectorId,
  ScanToShip.labelCDVInputId,
  ScanToShip.labelShopAroundBtnId,
  ScanToShip.labelPrintBtnId,
];

const channelPackingSlips: StringKAnyVPair = {};
let bottomMessageTimer: any = null;
let keydownHandler: any = null;
let screenMasked: boolean = false;
let skipPrintPackSlip: boolean = false;

// eslint-disable-next-line
export default () => {
  const ICON_SIZE1 = 26;
  const MAIN_BOARD_ID = 'main-board-ctn';
  const SEARCH_BOX1_WIDTH = 380;
  const SEARCH_BOX2_WIDTH = 420;
  //const SELECTBOX_TYPE2_WIDTH = 176;
  const PRINT_FORM_TYPE_LABEL = 1;
  const PRINT_FORM_TYPE_SUMMARY = 2;
  const VERIFY_BUTTON_COLOR = '#2db7f5';
  const { useState } = React;
  const dispatch: Dispatch<any> = useDispatch();
  const i18n = useTranslation();
  const [alertDialogContent, setAlertDialogContent] = useState('');
  const [alertDialogOnClose, setAlertDialogOnClose] = useState<Function>();
  const [alertDialogTitle, setAlertDialogTitle] = useState('');
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [batchPrintDialogVisible, setBatchPrintDialogVisible] = useState(false);
  const [commonFormButtonSize, setCommonFormButtonSize] =
    useState<GeneralSizeType>('middle');
  const [confirmClearVisible, setConfirmClearVisible] = useState(false);
  const [currentFulfillmentOrderId, setCurrentFulfillmentOrderId] =
    useState('');
  const [currentOrder, setCurrentOrder] = useState<Order>({} as Order);
  const [currentPageMode, setCurrentPageMode] = useState(PAGE_MODE_FULL_PAGE);
  const [currentPageWidth, setCurrentPageWidth] = useState(0);
  const [currentSignalConnection, setCurrentSignalConnection] = useState(
    SIGNALR_CONNECTION_DISCONNECTED
  );
  const [deviceChecked, setDeviceChecked] = useState(false);
  const [deviceDialogClosable, setDeviceDialogClosable] = useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = useState(false);
  const [documentTrack, setDocumentTrack] = useState<StringKAnyVPair>({});
  const [inited, setInited] = useState(false);
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [labelQueue, setLabelQueue] = useState<PrintedLabel[]>([]);
  const [lockScreen, setLockScreen] = useState(false);
  const [noPrinterMsgPopped, setNoPrinterMsgPopped] = useState(false);
  const [orderFound, setOrderFound] = useState(false);
  const [findOrderId, setFindOrderId] = useState('');
  const [orderQtyColumnWidth, setOrderQtyColumnWidth] = useState(140);
  const [orderShipmentList, setOrderShipmentList] = useState<ShipmentUnit[]>(
    []
  );
  const [orderTrackNumber, setOrderTrackNumber] = useState('');
  const [printFormType, setPrintFormType] = useState(PRINT_FORM_TYPE_LABEL);
  const [printHistoryTimer, setPrintHistoryTimer] = useState<any>(null);
  const [printHistoryVisible, setPrintHistoryVisible] = useState(false);
  const [printPackingSlipType, setPrintPackingSlipType] = useState(0);
  // eslint-disable-next-line
  const [priorityPicklistItem, setPriorityPicklistItem] = useState<StringKAnyVPair>({});
  const [priorityPicklistNum, setPriorityPicklistNum] = useState<number>();
  const [priorityPicklistVisible, setPriorityPicklistVisible] =
    useState<boolean>(false);
  const [scanColumnWidth, setScanColumnWidth] = useState(140);
  const [scanTrackNumberVisible, setScanTrackNumberVisible] = useState(false);
  const [searchCodeCount, setSearchCodeCount] = useState(0);
  const [searchId, setSearchId] = useState('');
  //const [searchOrderId, setSearchOrderId] = useState('');
  const [selectedShipmentNum, setSelectedShipmentNum] = useState(0);
  const [shipMultiPkgDialogVisible, setShipMultiPkgDialogVisible] = useState(false);
  const [shipmentDialogVisible, setShipmentDialogVisible] = useState(false);
  const [orderDetailDialogVisible, setOrderDetailDialogVisible] =
    useState(false);
  const [shipmentDetailDialogVisible, setShipmentDetailDialogVisible] =
    useState(false);
  const [shipmentConfirmDialogVisible, setShipmentConfirmDialogVisible] =
    useState(false);
  const [shipmentItemDict, setShipmentItemDict] = useState<ShipmentItemDict>(
    {}
  );
  const [shipmentTabsWidth, setShipmentTabsWidth] = useState(769);
  const [skipPrintingPackSlip, setSkipPrintingPackSlip] = useState(false);
  //const [verifyButtonSize, setVerifyButtonSize] = useState('middle');
  const [verifyCode, setVerifyCode] = useState('');
  const [verifyMessageBarStyle, setVerifyMessageBarStyle] =
    useState<StringKAnyVPair>({ top: MESSAGE_BAR_POS_TOP1 });
  const [verifyRowHeight, setVerifyRowHeight] = useState(80);
  //const [warehouse, setWarehouse] = useState<StringKAnyVPair>({});
  const [warehouseCode, setWarehouseCode] = useState('');
  const [warehouseId, setWarehouseId] = useState(-1);
  const filterSectionRef = React.useRef<any>(null);
  const findOrderRef = React.useRef<any>(null);
  const itemInputRef = React.useRef<any>(null);
  const markVerifyBtnRef = React.useRef<any>(null);
  const printerFormColRef = React.useRef<any>(null);
  const scanInputIdRef = React.useRef<any>(null);
  const scanTrackNumberRef = React.useRef<any>(null);
  const shipmentTabsRef = React.useRef<any>(null);
  const verifyAllBtnRef = React.useRef<any>(null);
  const [bundleModalVisible, setBundleModalVisible] = useState(false);
  const [current, setCurrent] = useState<any>();

  const cartonPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinterCoton,
    shallowEqual
  );
  const packingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );
  const packingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );
  const pageShowMode: number = useSelector(
    (state: RootState) => state.regular.pageShowMode,
    shallowEqual
  );
  const pageWidth: number = useSelector(
    (state: RootState) => state.regular.pageWidth,
    shallowEqual
  );
  const printLabelMessage: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelMessage,
    shallowEqual
  );
  const printerList: Printer[] = useSelector(
    (state: RootState) => state.sales.printerList,
    shallowEqual
  );
  const printerListLoaded: boolean = useSelector(
    (state: RootState) => state.sales.printerListIsLoading,
    shallowEqual
  );
  const printingIsHang: boolean = useSelector(
    (state: RootState) => state.sales.printingIsHang,
    shallowEqual
  );
  const scaleList: Scale[] = useSelector(
    (state: RootState) => state.sales.scaleList,
    shallowEqual
  );
  const scaleListLoaded: boolean = useSelector(
    (state: RootState) => state.sales.scaleListIsLoading,
    shallowEqual
  );
  const signalConnection: number = useSelector(
    (state: RootState) => state.regular.signalConnection,
    shallowEqual
  );
  /*const locale: string =*/ useSelector(
    (state: RootState) => state.regular.locale,
    shallowEqual
  );

  const headerTitle = React.useMemo(() => {
    const ret: Array<HeaderTitleCrumb> = ['Orders', 'Scan to Ship'];

    if (PAGE_MODE_FULL_PAGE === pageShowMode) {
      ret.splice(0, 0, {
        type: 'action',
        content: (
          <MenuUnfoldOutlined style={{ fontSize: HEADER_TITLE_ICON_SIZE }} />
        ),
        callback: () => dispatch(setPageShowMode(PAGE_MODE_NORMAL)),
      });
    }

    return ret;
  }, [dispatch, pageShowMode]);
  const selectedShipment = React.useMemo(() => {
    const list = orderShipmentList.filter(
      (e) => selectedShipmentNum > 0 && e.shipmentNum === selectedShipmentNum
    );

    return list.length === 1 ? list[0] : null;
  }, [orderShipmentList, selectedShipmentNum]);
  const selectedShipmentAllCleared = React.useMemo(() => {
    const shipments = shipmentItemDict[selectedShipmentNum] || [];

    return shipments.every((e) => e.verifyStatus === 0);
  }, [selectedShipmentNum, shipmentItemDict]);
  const selectedShipmentAllVerified = React.useMemo(() => {
    const shipments = shipmentItemDict[selectedShipmentNum] || [];

    return shipments.every(
      (e) => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
    );
  }, [selectedShipmentNum, shipmentItemDict]);
  const selectedShipmentCancelled = React.useMemo(() => {
    let ret = false;
    const list = orderShipmentList.filter(
      (e) => selectedShipmentNum > 0 && e.shipmentNum === selectedShipmentNum
    );
    const shipment = list.length === 1 ? list[0] : null;

    if (shipment) {
      ret = shipment.shippingStatus === SHIPMENT_CANCELLED_STATE;
    }

    return ret;
  }, [orderShipmentList, selectedShipmentNum]);
  const selectedShipmentManageEnable = React.useMemo(() => {
    const isShipmentManagable = (shipment: ShipmentUnit) => {
      const ret =
        shipment.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE ||
        shipment.shippingStatus === SHIPMENT_CANCELLED_STATE;

      return ret;
    };

    let ret = !!selectedShipment;

    if (ret && selectedShipment) {
      if (orderShipmentList.length < 2) {
        const items = shipmentItemDict[selectedShipmentNum] || [];

        ret = items.length > 1;

        if (ret) {
          //ret = selectedShipment.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE &&
          ret =
            isShipmentManagable(selectedShipment) &&
            items.some((e) => e.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE);
        } else if (items.length === 1) {
          ret =
            selectedShipment.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE &&
            items[0].verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE &&
            items[0].shippingQty > 1;

          if (!ret) ret = isShipmentManagable(selectedShipment);
        }
      } else {
        ret = orderShipmentList.some((e) => isShipmentManagable(e));
      }
    }

    return ret;
  }, [
    orderShipmentList,
    selectedShipment,
    selectedShipmentNum,
    shipmentItemDict,
  ]);
  const selectedShipmentInputVerifyEnable = React.useMemo(() => {
    let ret = !!selectedShipment;

    if (ret && selectedShipment) {
      ret = !(selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE);

      if (ret) {
        const items = shipmentItemDict[selectedShipmentNum] || [];
        //ret = (shipmentItemDict[selectedShipmentNum] || []).length > 0;
        ret =
          items.length > 0
            ? !items.every((e) => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE)
            : false;
      }
    }

    return ret;
  }, [selectedShipment, selectedShipmentNum, shipmentItemDict]);
  const selectedShipmentMarkVerifyEnable = React.useMemo(() => {
    let ret = false;

    for (let i = 0; i < orderShipmentList.length; i++) {
      if (orderShipmentList[i].shipmentNum === selectedShipmentNum) {
        if (selectedShipmentAllVerified) {
          ret =
            orderShipmentList[i].verifyStatus ===
            SHIPMENT_ITEM_NOT_VERIFY_STATE;

          if (!ret) {
            ret = !orderShipmentList[i].verifyStatus;
          }
        }

        break;
      }
    }

    return ret;
  }, [orderShipmentList, selectedShipmentAllVerified, selectedShipmentNum]);
  const selectedShipmentOrder = React.useMemo(() => {
    return selectedShipment ? selectedShipment.channelOrderId : '';
  }, [selectedShipment]);
  /*const selectedShipmentShipToAddress = React.useMemo(() => {
    return selectedShipment ? selectedShipment.shipToAddressLine1 : '';
  }, [selectedShipment]);*/
  const selectedShipmentItemsUnscannedQuantity = React.useMemo(() => {
    const items = shipmentItemDict[selectedShipmentNum] || [];

    //return items.reduce((acc, cur) => acc + cur.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE ? (cur.shippingQty - cur.verifyQty) : 0, 0);
    return items.reduce(
      (acc, cur) => acc + (cur.shippingQty - cur.verifyQty),
      0
    );
  }, [selectedShipmentNum, shipmentItemDict]);
  const selectedShipmentShipToPostalCode = React.useMemo(() => {
    return selectedShipment ? selectedShipment.shipToPostalCode : '';
  }, [selectedShipment]);
  const selectedShipmentSplitItems = React.useMemo(() => {
    if (selectedShipment) {
      if (selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE)
        return [];

      if (selectedShipmentItemsUnscannedQuantity === 0) return [];
    } else return [];

    const items = shipmentItemDict[selectedShipmentNum] || [];
    //const lines = items.filter(e => e.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE || !e.verifyStatus)
    const lines = items
      .filter((e) => e.verifyQty > 0)
      .map((e) => ({
        Quantity: e.verifyQty,
        ShipmentLineNum: e.shipmentLineNum,
      }))
      .filter((e) => e.Quantity > 0);

    return lines;
  }, [
    selectedShipment,
    selectedShipmentItemsUnscannedQuantity,
    selectedShipmentNum,
    shipmentItemDict,
  ]);
  const selectedShipmentTitle = React.useMemo(() => {
    const len = orderShipmentList.length;
    let ret = '';

    for (let i = 0; i < orderShipmentList.length; i++) {
      if (orderShipmentList[i].shipmentNum === selectedShipmentNum) {
        ret = `${orderShipmentList[i].shipmentTitle} of ${len}`;
        break;
      }
    }

    return ret;
  }, [orderShipmentList, selectedShipmentNum]);
  const selectedShipmentShipped = React.useMemo(() => {
    return selectedShipment &&
      selectedShipment.shippingStatus === SHIPMENT_SHIPPING_STATE
      ? true
      : false;
    // eslint-disable-next-line
  }, [orderShipmentList, selectedShipment]); // orderShipmentList is needed
  const selectedShipmentVerified = React.useMemo(() => {
    return selectedShipment &&
      selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
      ? true
      : false;
    // eslint-disable-next-line
  }, [orderShipmentList, selectedShipment]); // orderShipmentList is needed
  const selectedShipmentVerifiedNotShipped = React.useMemo(() => {
    return selectedShipment &&
      selectedShipment.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE &&
      selectedShipment.shippingStatus !== SHIPMENT_SHIPPING_STATE
      ? true
      : false;
    // eslint-disable-next-line
  }, [orderShipmentList, selectedShipment]);

  const shipmentFormEnabled: boolean = React.useMemo(() => {
    return selectedShipmentVerified && !printingIsHang;
  }, [printingIsHang, selectedShipmentVerified]);
  const shippedShipments: ShipmentUnit[] = React.useMemo(() => {
    return orderShipmentList.filter(
      (e) => e.shippingStatus === SHIPMENT_SHIPPING_STATE
    );
  }, [orderShipmentList]);
  const signalIsReady: boolean = React.useMemo(() => {
    return signalConnection === SIGNALR_CONNECTION_CONNECTED;
  }, [signalConnection]);
  const tabShipmentList = React.useMemo(() => {
    const list = orderShipmentList.filter((e) => {
      return !(
        e.shipmentNum !== selectedShipmentNum &&
        e.shippingStatus === SHIPMENT_SHIPPING_STATE
      );
    });

    if (list.length > 1 && list[0].shippingStatus === SHIPMENT_SHIPPING_STATE) {
      if (list[0].shipmentNum !== selectedShipmentNum) {
        list.splice(0, 1);
      }
    }

    return list;
  }, [orderShipmentList, selectedShipmentNum]);
  const verifyGridHeight = React.useMemo(() => {
    const gap = currentPageWidth > LARGE_WIDTH_LIMIT ? 320 : 301;
    let topMenuHGap = PAGE_MODE_NORMAL === pageShowMode ? 54 : 0;

    //if (window.innerWidth > LARGE_WIDTH_LIMIT && PAGE_MODE_NORMAL === pageShowMode) {
    if (PAGE_MODE_NORMAL === pageShowMode) {
      if (currentPageWidth > LARGE_WIDTH_LIMIT) {
        topMenuHGap = 56;
      } else if (currentPageWidth > MIDDLE_WIDTH_LIMIT) {
        topMenuHGap = 50;
      }
    }

    return `calc(100vh - ${gap + topMenuHGap}px)`;
  }, [currentPageWidth, pageShowMode]);

  const columns = [
    /*{
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      resizable: false,
      textAlign: 'center',
      render() {
        return <Checkbox />;
      },
      renderHeader() {
        return <Checkbox />;
      },
    },*/
    {
      cellProps: {
        className: 'InovuaReactDataGrid__thumbnail-cell',
        style: { borderRight: 'none' },
      },
      headerProps: { className: 'thumbnail-col-header' },
      maxWidth: orderQtyColumnWidth,
      minWidth: orderQtyColumnWidth,
      sortable: false,
      resizable: false,
      render: (value: any) => {
        const { data } = value;

        return (
          <Row align="middle" justify="center">
            <Image
              fallback={fallbackImage}
              width={64}
              height={64}
              preview={false}
              src={data.thumbnail || 'error'}
            />
          </Row>
        );
      },
    },
    {
      cellProps: { className: 'verify-item-info-cell' },
      headerProps: { className: 'verify-item-info-col-header' },
      resizable: false,
      sortable: false,
      userSelect: true,
      render: (value: any) => {
        const { data } = value;

        return (
          <Row align="middle" justify="start">
            <Col>
              <Row>
                <Label className="label-bold">{data.title}</Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">SKU: </span>
                  <span className="label-grey">{data.sku}</span>
                </Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">UPC: </span>
                  <span className="label-grey">{data.upc}</span>
                </Label>
              </Row>
              {data.bundleType === 1 && <Row>
                <Label>
                  <span className="label-grey">Component of <Typography.Link onClick={()=>{
                    setCurrent({
                      productId: data.bundleProductId,
                      sku: data.bundleSKU,
                      upc: data.bundleUPC,
                      productTitle: data.bundleTitle,
                    })
                    setBundleModalVisible(true);
                  }}>{data.bundleSKU}</Typography.Link><span>{`(${data.bundleQty}x${data.orderQty / data.bundleQty})`}</span></span>
                </Label>
              </Row>}
            </Col>
          </Row>
        );
      },
      renderHeader() {
        return selectedShipmentTitle;
      },
    },
    {
      maxWidth: 200,
      minWidth: 200,
      resizable: false,
      sortable: false,
      textAlign: 'center',
      renderHeader() {
        return (
          <Row>
            <Space>
              <TagButton
                color={VERIFY_BUTTON_COLOR}
                disabled={selectedShipmentAllVerified}
                onClick={handleVerifyAllShipmentItems}
                ref={verifyAllBtnRef}
              >
                <Trans i18nKey="scanToShip.verifyAll" /> (A)
              </TagButton>
              <TagButton
                disabled={
                  selectedShipmentVerified || selectedShipmentAllCleared
                }
                onClick={handleClearAllShipmentItems}
              >
                <Trans i18nKey="scanToShip.clearAll" />
              </TagButton>
            </Space>
          </Row>
        );
      },
      render(value: any) {
        const { data } = value;

        return (
          <Space>
            {data.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE ? (
              <LikeOutlined style={{ fontSize: LOADING_ICON_SIZE5 }} />
            ) : (
              <TagButton
                color={VERIFY_BUTTON_COLOR}
                disabled={data.verifyStatus === 1}
                onClick={() => handleVerifyShipmentItem(data)}
              >
                <Trans i18nKey="common.verify" />
              </TagButton>
            )}
            <TagButton
              disabled={
                selectedShipmentVerified ||
                data.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE
              }
              onClick={() => handleClearShipmentItem(data)}
            >
              <Trans i18nKey="common.clear" />
            </TagButton>
          </Space>
        );
      },
    },
    {
      header: i18n.t('common.qty'),
      cellProps: { style: { fontSize: 46 } },
      maxWidth: orderQtyColumnWidth,
      minWidth: orderQtyColumnWidth,
      name: 'shippingQty',
      headerEllipsis: false,
      headerProps: { className: 'scanned-col-header' },
      resizable: false,
      sortable: false,
      textAlign: 'center',
      type: 'number',
    },
    {
      header: i18n.t('scanToShip.scanned'),
      cellProps: { className: 'scanned-col', style: { padding: 0 } },
      headerProps: { className: 'scanned-col-header' },
      maxWidth: scanColumnWidth,
      minWidth: scanColumnWidth,
      resizable: false,
      sortable: false,
      textAlign: 'center',
      textEllipsis: false,
      type: 'number',
      render(value: any) {
        const { data } = value;

        return data.verifyStatus === 1 ? (
          <Row align="middle" justify="center" className="verified-cell">
            {data.verifyQty}
          </Row>
        ) : (
          <Tooltip
            color="volcano"
            placement="top"
            title={data.scanMessage}
            visible={data.scanError}
          >
            <CellNumberInput
              align="center"
              height={verifyRowHeight}
              id={`scan_${data.shipmentLineNum}`}
              key={data.shipmentLineNum}
              min={0}
              paddingleft={4}
              radius={0}
              size="large"
              style={{ width: '100%' }}
              value={data.verifyQty}
              onChange={(val) => handleScannedQuantityChange(val, data)}
              onFocus={(evt) => handleFocusScanNumberInput(evt, data)}
            />
          </Tooltip>
        );
      },
    },
  ];

  // eslint-disable-next-line
  const resetOrderShipment = async (selectedNum = 0, orderId = '') => {
    const order = orderId || selectedShipmentOrder;

    setOrderShipmentList([]);
    setShipmentItemDict({});
    setSelectedShipmentNum(0);
    setDocumentTrack({});
    await handleFindOrder(`_${order}`);
    setSelectedShipmentNum(selectedNum || selectedShipmentNum);
  };

  const handleAutoSplitShipment = React.useCallback(async () => {
    setLockScreen(true);

    try {
      const res = await autoSplitShipment(
        selectedShipmentNum,
        selectedShipmentSplitItems
      );

      setLockScreen(false);

      if (res && typeof res === 'object') {
        resetOrderShipment(res.shipmentNum);
        message.success('Auto splited shipment successfully');
      } else {
        message.error('Auto splited shipment failed');
      }
    } catch (e) {
      setLockScreen(false);
      message.error('Auto splited shipment failed');
    }
  }, [resetOrderShipment, selectedShipmentNum, selectedShipmentSplitItems]);

  const handleClearVerifiedMark = async () => {
    setConfirmClearVisible(false);
    setLockScreen(true);

    try {
      await clearVerifiedShipment(selectedShipmentNum);
      await updateShipment(selectedShipmentNum);
      setLockScreen(false);
      await handleClearAllShipmentItemsData();
      playBell('success');
      tryFocusItemInput();
    } catch (e) {
      setLockScreen(false);
      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: i18n.t('scanToShip.clearVerifiedMarkError', { usage: e })
      });
    }
  };

  // eslint-disable-next-line
  const adjustLayout = () => {
    if (!filterSectionRef?.current) return;

    if (window.innerWidth > LARGE_WIDTH_LIMIT) {
      setCommonFormButtonSize('large');
      setOrderQtyColumnWidth(160);
      setScanColumnWidth(160);
      setVerifyRowHeight(90);
    } else {
      setCommonFormButtonSize('middle');
      setOrderQtyColumnWidth(140);
      setScanColumnWidth(140);
      setVerifyRowHeight(80);
    }
  };

  // eslint-disable-next-line
  const adjustShipmentTabsSize = () => {
    if (
      filterSectionRef?.current &&
      printerFormColRef?.current &&
      shipmentTabsRef?.current
    ) {
      const space =
        filterSectionRef?.current.offsetWidth -
        printerFormColRef?.current.offsetWidth -
        16;
      const shipmentTabsOffsetWidth = shipmentTabsRef?.current.offsetWidth;

      if (shipmentTabsOffsetWidth !== space) {
        setShipmentTabsWidth(space);
        setTimeout(adjustShipmentTabsSize, 16);
      }
    }
  };

  const changeOrderShipmentList = (shipments: OrderShipment[]) => {
    setOrderShipmentList(
      shipments.map((e, i) => ({
        ...e,
        shipmentTitle: `Shipment ${i + 1}`,
      }))
    );
    setSelectedShipmentNum(0);

    if (shipments.length > 0) {
      let num = shipments[0].shipmentNum;

      for (let i = 0; i < shipments.length; i++) {
        if (shipments[i].shippingStatus !== SHIPMENT_SHIPPING_STATE) {
          num = shipments[i].shipmentNum;
          break;
        }
      }

      setSelectedShipmentNum(num);
    }
  };

  // eslint-disable-next-line
  const checkDevice = async () => {
    const scaleId = parseInt(localStorage.getItem(LS_SCALE_KEY) || '0');
    const shipmentPrinterId = parseInt(
      localStorage.getItem(LS_SHIPMENT_PRINTER_KEY) || '0'
    );
    const shipmentPrinter4x8Id = parseInt(
      localStorage.getItem(LS_SHIPMENT_PRINTER_4x8_KEY) || '0'
    );
    const shipmentPrinterCotonId = parseInt(
      localStorage.getItem(LS_SHIPMENT_PRINTER_COTON_KEY) || '0'
    );
    const packingPrinterId = parseInt(
      localStorage.getItem(LS_PACKING_PRINTER_KEY) || '0'
    );
    const packingPrinter2Id = parseInt(
      localStorage.getItem(LS_PACKING_PRINTER2_KEY) || '0'
    );

    // checking optional device do not need to count the valid devices
    if (shipmentPrinter4x8Id > 0) {
      if (isPrinterIdValid(shipmentPrinter4x8Id)) {
        // validCount += 1; // not count optional printer
        dispatch(setShipmentPrinter4x8ById(shipmentPrinter4x8Id));
      }
    }

    if (shipmentPrinterCotonId > 0) {
      if (isPrinterIdValid(shipmentPrinterCotonId)) {
        // validCount += 1; // not count optional printer
        dispatch(setShipmentPrinterCotonById(shipmentPrinterCotonId));
      }
    }

    if (/*scaleId ||*/ shipmentPrinterId || packingPrinterId) {
      let validCount = 0;

      if (scaleId > 0) {
        if (isScaleIdValid(scaleId)) {
          dispatch(setScaleById(scaleId));
        }
      }

      if (shipmentPrinterId > 0) {
        if (isPrinterIdValid(shipmentPrinterId)) {
          validCount += 1;
          dispatch(setShipmentPrinterById(shipmentPrinterId));
        }
      }



      if (packingPrinterId > 0) {
        if (isPrinterIdValid(packingPrinterId)) {
          validCount += 1;
          dispatch(setPackingPrinterById(packingPrinterId));
        }
      }

      if (packingPrinter2Id > 0) {
        if (isPrinterIdValid(packingPrinter2Id)) {
          validCount += 1;
          dispatch(setPackingPrinter2ById(packingPrinter2Id));
        }
      }

      // valid when 3 printers are Okay
      if (validCount === 3) {
        focusFindOrderInput();
      } else {
        openDeviceSettingDialog({ closable: false });
      }
    } else {
      openDeviceSettingDialog({ closable: false });
    }
  };

  // eslint-disable-next-line
  const checkPrintHoldLabel = (shipment: StringKAnyVPair) => {
    const needCarton = shipment.isPrintCartonLabel;
    const skipPS = isSkipPrintPackSlip();
    const needSlip = shipment.isPrintPackingSlip && !(shipment.allowSkipPrintPackingSlip && skipPS);

    // maybe need to check needSlip && printPackingSlipConfirmation
    if (needSlip) {
      printShipmentPackingSlip(shipment);
    }

    if (needCarton) {
      printShipmentCartonLabel(shipment);
    }
  };

  const clearShipmentItemScanError = (item: ShipmentItem) => {
    item.scanError = false;
    item.scanMessage = '';
  };

  const closeShipMultiPackagesDialog = () => {
    setShipMultiPkgDialogVisible(false);
  };

  const closeScanTrackingNumberDialog = () => {
    setScanTrackNumberVisible(false);
    //setOrderTrackNumber('');
  };

  const confirmAutoShipment = () => {
    //setConfirmShipVisible(false);
    const modal = Modal.confirm({
      title: 'Auto Shipment Confirmation',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure to split selected items to a new shipment and mark as verified?`,
      okText: 'Auto Shipment',
      onOk: () => {
        handleAutoSplitShipment();
        modal.destroy();
      },
      cancelText: 'Cancel',
    });
  };

  // eslint-disable-next-line
  const enterFullPage = () => {
    dispatch(setPageShowMode(PAGE_MODE_FULL_PAGE));
  };

  // eslint-disable-next-line
  const exitFullPage = () => {
    dispatch(setPageShowMode(PAGE_MODE_NORMAL));
  };

  const findMoreDropdownMenu = () => (
    <Menu>
      <Menu.Item key="0" onClick={openScanTrackingNumberDialog}>
        <LabelFormDropdownItem><Trans i18nKey="scanToShip.findMore.scanTrackingNumber" /></LabelFormDropdownItem>
      </Menu.Item>
      <Menu.Item key="1" onClick={openBatchPrintDialog}>
        <LabelFormDropdownItem><Trans i18nKey="scanToShip.findMore.shippingLabelBatchPrint" /></LabelFormDropdownItem>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setPriorityPicklistVisible(true)}>
        <LabelFormDropdownItem><Trans i18nKey="scanToShip.findMore.priorityPickList" /></LabelFormDropdownItem>
      </Menu.Item>
    </Menu>
  );

  const findOrderPriorityPickListSuffix = () => (
    <Tooltip title={`Find Order In Pick List ${priorityPicklistItem.sessionId}`}>
      <ContainerOutlined />
    </Tooltip>
  );

  const focusFindOrderInput = () => {
    findOrderRef?.current?.focus();
  };

  const getMultiPackageShipments = () => {
    /*return tabShipmentList.filter(e => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE)
      .filter(e => e.weight > 0);*/
    return tabShipmentList.filter(e => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE);
  };

  // eslint-disable-next-line
  const getOrderInfo = async (fulfillmentOrderId: string) => {
    setIsFetchingOrder(true);

    try {
      const order = await fetchOrder(fulfillmentOrderId);

      setCurrentOrder(order);
      setIsFetchingOrder(false);
    } catch (e) {
      setIsFetchingOrder(false);
      message.error(
        i18n.t('scanToShip.fetchInfoByOrderNumError', { usageA: selectedShipmentOrder, usageB: e }) || ''
      );
    }
  };

  const getOrderShipmentIcon = (shipment: OrderShipment) => {
    let ret = 'bi-printer';

    if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
      ret = 'bi-truck';
    } else if (shipment.shippingStatus === SHIPMENT_CANCELLED_STATE) {
      ret = 'bi-folder-x';
    } else {
      if (shipment.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE) {
        ret = 'bi-upc-scan';
      }
    }

    return ret;
  };

  const getShipmentDropdown = (list: ShipmentUnit[]) => {
    return (
      <Menu onClick={handleClickShippedShipment}>
        {list.map((e) => (
          <Menu.Item key={e.shipmentNum}>
            <i className={getOrderShipmentIcon(e)} />
            &nbsp;
            {e.shipmentTitle}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  // eslint-disable-next-line
  const getShipmentItemsById = async (id: number) => {
    setLockScreen(true);

    try {
      const list = await fetchShipmentItemsById(id, true);
      const items = list.map((e) => ({
        ...e,
        scanError: false,
        scanMessage: '',
      }));

      if (
        searchId[0] !== '_' &&
        orderShipmentList.length === 1 &&
        items.length === 1 &&
        items[0].shippingQty === 1
      ) {
        const isVerified =
          orderShipmentList[0].verifyStatus === SHIPMENT_ITEM_VERIFY_STATE;

        items[0].verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
        items[0].verifyQty = items[0].shippingQty;

        if (!isVerified) {
          orderShipmentList[0].verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
          setOrderShipmentList([...orderShipmentList]);
          setSelectedShipmentNum(0);
          setTimeout(() => {
            setSelectedShipmentNum(orderShipmentList[0].shipmentNum);
            setTimeout(handleMarkShipmentVerified, 0);
          }, 0);
        }
      } else if (searchId[0] !== '_') {
        if (searchCodeCount === 0) {
          setSearchCodeCount(searchCodeCount + 1);
          items.forEach((e) => {
            if (e.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE) {
              if (e.sku === searchId || e.upc === searchId) {
                e.verifyQty = 1;

                if (e.verifyQty === e.shippingQty) {
                  e.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
                }
              }
            }
          });
        }
      }

      // adjust the line data
      items.forEach(e => {
        if (e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE) {
          e.verifyQty = e.shippingQty;
        }
      });

      setShipmentItemDict({
        ...shipmentItemDict,
        [id]: [...items],
      });
      setLockScreen(false);
      //playBell('success');
      tryFocusItemVerify();
    } catch (e) {
      message.error(`Fetch shipment items: ${e}`);
      setLockScreen(false);
    }
  };

  const getSelectedShipmentPackingSlipType = async () => {
    let ret = 0;

    try {
      const channelAccountNum = selectedShipment
        ? selectedShipment.channelAccountNum
        : 0;
      let slips = channelPackingSlips[channelAccountNum];

      if (!slips) {
        slips = await getChannelPackingSlipList(channelAccountNum);

        if (Array.isArray(slips)) {
          channelPackingSlips[channelAccountNum] = slips;
        }
      }

      if (Array.isArray(slips)) {
        const finds = slips.filter(
          (e) => e.salesDivision === currentOrder.salesDivision
        );

        if (finds.length > 0) {
          ret = finds[0].packingSlipSize;
        }
      }
    } catch (e) {
      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: i18n.t('scanToShip.getPackingSlipError', { usage: e })
      });
    }

    return ret;
  };

  // eslint-disable-next-line
  const getWarehouseById = async (id: string) => {
    try {
      const warehouse = await fetchWarehouseEntity(id);

      if (warehouse) {
        //setWarehouse(warehouse);

        if (warehouse.warehouseCode !== warehouseCode) {
          setWarehouseCode(warehouse.warehouseCode);
          // localStorage.setItem(
          //   LS_DEFAULT_WAREHOUSE_CODE,
          //   warehouse.warehouseCode
          // );
        }
      }
    } catch (e) {
      setWarehouseId(-1);
      openDeviceSettingDialog({ closable: false });
    }
  };

  const handleChangeShipmentTab = (key: string | number) => {
    const num = typeof key === 'number' ? key : parseInt(key);

    setSelectedShipmentNum(num);
    setVerifyCode('');
  };

  const handleClearAllShipmentItems = () => {
    if (selectedShipmentVerified || selectedShipmentAllCleared) {
      return;
    }

    const items = shipmentItemDict[selectedShipmentNum] || [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE) {
        item.verifyQty = 0;
        item.verifyStatus = SHIPMENT_ITEM_NOT_VERIFY_STATE;

        if (item.scanError) {
          clearShipmentItemScanError(item);
        }
      }
    }

    setShipmentItemDict({ ...shipmentItemDict });
    playBell('success');
  };

  // eslint-disable-next-line
  const handleClearAllShipmentItemsData = async () => {
    const items = shipmentItemDict[selectedShipmentNum] || [];
    const params = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].verifyStatus === 1) {
        const item = items[i];

        params.push({
          ShipmentNum: item.shipmentNum,
          ShipmentLineNum: item.shipmentLineNum,
          ScanVerifyType: 1,
          VerifyStatus: 0,
          Quantity: 0,
        });
      }
    }

    if (params.length > 0) {
      try {
        setLockScreen(true);
        const res = await clearManyShipmentItems(selectedShipmentNum, params);
        setLockScreen(false);

        if (res) {
          //message.info('Cleared successfully');

          for (let i = 0; i < items.length; i++) {
            items[i].verifyStatus = 0;
            items[i].verifyQty = 0;
          }

          setShipmentItemDict({ ...shipmentItemDict });
        } else {
          message.error('Clear items data failed');
        }
      } catch (e) {
        setLockScreen(false);
        message.error(`Cleared items error ${e}`);
      }
    } else {
      message.error('No item need to be cleared');
    }
  };

  const handleClearShipmentItem = (item: ShipmentItem) => {
    if (
      selectedShipmentVerified ||
      item.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE
    ) {
      return;
    }

    item.verifyQty = 0;
    item.verifyStatus = 0;
    setShipmentItemDict({ ...shipmentItemDict });
    playBell('success');
  };

  // eslint-disable-next-line
  const handleClearShipmentItem2 = async (item: ShipmentItem) => {
    setLockScreen(true);

    try {
      const cleared = await clearShipmentItem(item);

      if (cleared) {
        item.verifyQty = 0;
        item.verifyStatus = 0;
        setShipmentItemDict({ ...shipmentItemDict });
      }

      setLockScreen(false);
    } catch (e) {
      setLockScreen(false);
      message.error(`Clear item ${e}`);
    }
  };

  const handleClickShippedShipment = (data: StringKAnyVPair) => {
    const { key } = data;
    const shipmentId = parseInt(key);

    if (shipmentId && !Number.isNaN(shipmentId)) {
      setSelectedShipmentNum(shipmentId);
    }
  };

  /**
   * Close shipment confirm dialog.
   */
  const handleCloseShipmentConfirmDialog = () => {
    setShipmentConfirmDialogVisible(false);
  };

  const handleFindOrder = async (orderId: string, evt?: any) => {
    if (evt && 'INPUT' === evt.currentTarget.tagName) {
      return;
    }

    if (!orderId.trim()) {
      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: i18n.t('scanToShip.noSearchInputError'),
        onClose: () => () => findOrderRef?.current.focus(),
      });

      return;
    }

    /*if (orderId === searchOrderId) {
      setFindOrderId('');

      if (orderFound) {
        message.info(`'${orderId}' has been found`);
      }
      return;
    }*/

    setLockScreen(true);
    setOrderFound(false);

    try {
      setSearchId(orderId);
      setSearchCodeCount(0);
      setVerifyCode('');
      const { message, shipments } = await fetchOrderShipment(
        warehouseId,
        orderId,
        priorityPicklistNum,
      );
      /*const {message, shipments} = await fetchOrderShipment(
        getWarehouseIdFromCache(warehouseId),
        orderId,
      );*/
      //const shipments = await fetchOrderShipmentList(wrehouseId, orderId);

      setLockScreen(false);
      setSelectedShipmentNum(0);
      setShipmentItemDict({});
      setDocumentTrack({});
      changeOrderShipmentList(shipments);
      //setSearchOrderId(orderId);
      setFindOrderId('');
      setOrderFound(shipments.length > 0);

      if (shipments.length === 0) {
        let msg = i18n.t('scanToShip.connotFindOrderError', { usage: orderId }) || '';
        if (message && typeof message === 'string') {
          msg = `${msg}: ${message}`;
        }

        openAlertDialog({
          title: i18n.t('common.error') || '',
          content: msg,
          onClose: () => () => tryFocusOrderInput(),
        });
      } else {
        playBell('success');
      }
    } catch (e) {
      let errMsg = i18n.t('scanToShip.connotFindOrderError', { usage: orderId }) || '';

      setLockScreen(false);
      playBell('error');

      if (e) {
        const eObj = e as StringKAnyVPair;

        if (eObj && eObj.message) {
          errMsg = eObj.message;
        } else {
          errMsg = `${errMsg}, ${e}`;
        }
      }

      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: errMsg,
        onClose: () => () => tryFocusOrderInput(),
      });
    }
  };

  const handleFindOrderByTrackingNumber = async (
    tn: string,
    history = false
  ) => {
    setLockScreen(true);

    try {
      const shipments = await fetchOrderShipmentByTrackingNumber(
        warehouseId,
        tn,
        history
      );

      setLockScreen(false);

      if (shipments.length > 0) {
        const { shipmentNum, channelOrderId } = shipments[0];

        closeScanTrackingNumberDialog();
        resetOrderShipment(shipmentNum, channelOrderId);
      } else {
        if (history) {
          openAlertDialog({
            title: i18n.t('common.error') || '',
            content: i18n.t('scanToShip.trackingNumberNotFound', { usage: tn }),
            onClose: () => () => {
              scanTrackNumberRef?.current.focus();
              scanTrackNumberRef?.current.select();
            },
          });
        } else {
          handleFindOrderByTrackingNumber(tn, true);
        }
      }
    } catch (e) {
      setLockScreen(false);
      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: i18n.t('scanToShip.trackingNumberError', { usage: e }),
        onClose: () => () => {
          scanTrackNumberRef?.current.focus();
          scanTrackNumberRef?.current.select();
        },
      });
    }
  };

  // eslint-disable-next-line
  const handleFocusScanNumberInput = (evt: any, item: ShipmentItem) => {
    const t = evt.target;

    if (scanInputIdRef.current !== t.id) {
      const end = evt.target.value.length;

      scanInputIdRef.current = t.id;
      t.setSelectionRange(end, end);
      t.select();
      //console.log('focused --', t.id);
    }
  };

  const handleHidePrintHistory = () => {
    setPrintHistoryVisible(false);

    if (printHistoryTimer) {
      clearTimeout(printHistoryTimer);
      setPrintHistoryTimer(null);
    }
  };

  // eslint-disable-next-line
  const handleKeydown = (evt: any) => {
    if (screenMasked) return;

    if (evt.target.tagName.toLowerCase() !== 'input') {
      if (evt.keyCode === 27) {
        setConfirmClearVisible(false);
      }

      if (evt.keyCode === 65) {
        const verifyAllBtn = verifyAllBtnRef?.current;

        if (verifyAllBtn) {
          verifyAllBtn.click();
        }
      }

      if (evt.keyCode === 86) {
        const markBtn = markVerifyBtnRef?.current;

        if (markBtn) {
          if (!markBtn.disabled) {
            markBtn.click();
          }
        }
      }

      switch (evt.key) {
        case 'E':
        case 'e':
          playBell('bell');
          tryFocusItemInput();
          break;

        case 'F':
        case 'f':
          playBell('bell');
          tryFocusOrderInput();
          break;

        //default:
      }
    }

    if (evt.keyCode === 9) {
      handleTabKey(evt.target);
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  const handleMarkShipmentVerified = async () => {
    try {
      setLockScreen(true);
      const marked = await markShipmentVerified(selectedShipmentNum);
      setLockScreen(false);

      if (marked) {
        setVerifyMessageBarStyle({ top: 0 });

        if (selectedShipment) {
          for (let i = 0; i < orderShipmentList.length; i++) {
            if (orderShipmentList[i].shipmentNum === selectedShipmentNum) {
              orderShipmentList[i].verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
              break;
            }
          }

          selectedShipment.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
          setOrderShipmentList([...orderShipmentList]);
        }

        playBell('success');
        bottomMessageTimer = setTimeout(() => {
          onVerifyMessageBarClick();
        }, 3000);
      }
    } catch (e) {
      setLockScreen(false);
    }
  };

  const handleManageShipment = () => {
    setShipmentDialogVisible(true);
    maskScreen(true);
  };

  const handleMouseEnterPrintHistory = () => {
    if (printHistoryTimer) {
      clearTimeout(printHistoryTimer);
      setPrintHistoryTimer(null);
    }
  };

  const handleMouseLeavePrintHistoryTrigger = () => {
    const timer = setTimeout(() => {
      handleHidePrintHistory();
    }, 1000);

    setPrintHistoryTimer(timer);
  };

  const handleScanShipmentItem = (text: string, evt?: any) => {
    if (evt && 'INPUT' === evt.currentTarget.tagName) {
      return;
    }

    if (!text.trim()) {
      openAlertDialog({
        title: i18n.t('common.error') || '',
        content: i18n.t('scanToShip.noSkuInputError'),
        onClose: () => () => setTimeout(tryFocusItemInput, 500),
      });

      return;
    }

    const shipmentItems = shipmentItemDict[selectedShipmentNum];
    let needFocus = false;
    let needSound = true;
    let soundType: BellSoundType = 'success';

    if (shipmentItems) {
      let match = false;
      const matchedItems: ShipmentItem[] = [];
      let notVerified = false;

      shipmentItems.forEach((e) => {
        const code = text.trim();

        if (e.sku === code || e.upc === code || isCodeValid(code, e.codes)) {
          match = true;

          if (e.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE) {
            setVerifyCode('');
            matchedItems.push(e);

            if (e.verifyQty < e.shippingQty) {
              notVerified = true;
            }
          } else {
            needSound = false;
          }
        }
      });
      setShipmentItemDict({ ...shipmentItemDict });

      if (!notVerified) {
        setVerifyCode('');
        openAlertDialog({
          title: i18n.t('common.error') || '',
          content: i18n.t('scanToShip.itemVerifiedError', { usage: text }),
          onClose: () => () => setTimeout(tryFocusItemInput, 500),
        });
      } else if (matchedItems.length > 0) {
        for (let i = 0; i < matchedItems.length; i++) {
          const e = matchedItems[i];

          e.verifyQty += 1;

          if (e.shippingQty === e.verifyQty) {
            handleVerifyShipmentItem(e);
          }

          break;
        }
      }

      if (
        shipmentItems.every(
          (e) => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
        )
      ) {
        setTimeout(() => {
          handleMarkShipmentVerified();
        }, 0);
      } else {
        needFocus = true;
      }

      if (!match) {
        needFocus = false;
        needSound = false;
        openAlertDialog({
          title: i18n.t('common.error') || '',
          content: i18n.t('scanToShip.verifyCodeNotMatchError', { usage: verifyCode }),
          onClose: () => () => setTimeout(tryFocusItemInput, 500),
        });
      }
    } else {
      needFocus = true;
      soundType = 'error';
      message.error('Their is no item to verify');
    }

    if (needSound) {
      playBell(soundType);
    }

    if (needFocus) {
      setTimeout(tryFocusItemInput, 100);
    }
  };

  const handleScannedQuantityChange = (value: any, item: ShipmentItem) => {
    if (typeof value === 'number') {
      item.verifyQty = parseInt(`${value}`) || 0;

      if (value > item.shippingQty) {
        item.scanError = true;
        item.scanMessage =
          'The scanned quantity cannot exceed the shipped quantity';
        playBell('error');
      } else {
        playBell('success');

        if (item.verifyQty === item.shippingQty) {
          item.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
          // check whether all items are verified
          // if all items are verified, mark shipment as verified
          // setTimeout
          //handleMarkShipmentVerified();
        }

        if (item.scanError) {
          item.scanError = false;
          item.scanMessage = '';
        }

        if (scanInputIdRef.current) {
          setTimeout(() => {
            const ni = window.document.getElementById(scanInputIdRef.current);

            //console.log('--->', scanInputIdRef.current);
            if (ni) {
              ni.focus();
            }
          }, 0);
        }
      }

      setShipmentItemDict({ ...shipmentItemDict });
    }
  };

  const handleScanTrackNumberChange = (evt: any) => {
    setOrderTrackNumber(evt.target.value.trim());
  };

  const handleScanTrackNumberKeyDown = (evt: any) => {
    const { keyCode } = evt;

    if ([9, 13].indexOf(keyCode) > -1) {
      const tn = scanTrackNumberRef?.current.input.value.trim();

      playBell('bell');

      if (tn) {
        handleFindOrderByTrackingNumber(tn);
      }
    }
  };

  const handleSearchOrderByTrackingNumber = () => {
    if (orderTrackNumber) {
      handleFindOrderByTrackingNumber(orderTrackNumber);
    } else {
      playBell('bell');
    }
  };

  const handleTabKey = (target: any) => {
    let id = '';

    if (target) id = target.getAttribute('id');

    if (indexOfTabNavQueue(id) > -1) {
      const nextDom = nextTabNavDom(id);

      if (nextDom) {
        nextDom.focus();
      }
    }
  };

  const handleTogglePrintHistory = () => {
    handleMouseEnterPrintHistory();
    setPrintHistoryVisible(!printHistoryVisible);
  };

  const handleVerifyAllShipmentItems = () => {
    if (selectedShipmentAllVerified) return;

    const items = shipmentItemDict[selectedShipmentNum] || [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (
        item.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE ||
        !item.verifyStatus
      ) {
        item.verifyQty = item.shippingQty;
        item.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;

        if (item.scanError) {
          clearShipmentItemScanError(item);
        }
      }
    }

    setShipmentItemDict({ ...shipmentItemDict });
    playBell('success');
  };

  // eslint-disable-next-line
  const handleVerifyAllShipmentItemsInBackend = async () => {
    const items = shipmentItemDict[selectedShipmentNum] || [];
    const params = [];
    const canVerify = true;
    let ret = false;

    for (let i = 0; i < items.length; i++) {
      if (items[i].verifyStatus === SHIPMENT_ITEM_VERIFY_STATE) {
        const item = items[i];

        if (items[i].shippingQty === items[i].verifyQty) {
          params.push({
            ShipmentNum: item.shipmentNum,
            ShipmentLineNum: item.shipmentLineNum,
            ScanVerifyType: 1,
            VerifyStatus: 1,
            Quantity: item.verifyQty,
          });
        }
      }
    }

    if (canVerify) {
      if (params.length > 0) {
        try {
          setLockScreen(true);
          const res = await verifyManyShipmentItems(
            selectedShipmentNum,
            params
          );
          setLockScreen(false);

          if (res) {
            ret = res;
          } else {
            message.error('Verified failed');
          }
        } catch (e) {
          setLockScreen(false);
          message.error(`Verified items error ${e}`);
        }
      } else {
        message.error('No item need to be verified');
      }
    } else {
      setShipmentItemDict({ ...shipmentItemDict });
    }

    return ret;
  };

  const handleVerifyShipmentItem = (item: ShipmentItem) => {
    item.verifyQty = item.shippingQty;
    item.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;

    if (item.scanError) {
      clearShipmentItemScanError(item);
    }

    setShipmentItemDict({ ...shipmentItemDict });
    playBell('success');
  };

  // eslint-disable-next-line
  const handleVerifyShipmentItem2 = async (item: ShipmentItem) => {
    if (item.verifyQty <= 0) {
      item.scanError = true;
      item.scanMessage = 'Please specify the valid quantity to be verified';
      setShipmentItemDict({ ...shipmentItemDict });
    } else if (item.shippingQty !== item.verifyQty) {
      item.scanError = true;
      item.scanMessage =
        'The scanned quantity is not equal with the shipping quantity';
      setShipmentItemDict({ ...shipmentItemDict });
    } else {
      setLockScreen(true);

      try {
        const verified = await verifyShipmentItem(item);

        if (verified) {
          // item.verifyQty = item.scanQuantity;
          item.verifyStatus = 1;
          setShipmentItemDict({ ...shipmentItemDict });
        }

        setLockScreen(false);
      } catch (e) {
        setLockScreen(false);
        message.error(`Verify item ${e}`);
      }
    }
  };

  /*
  const handleVerifyInputBlur = () => {
    setVerifyButtonSize('middle');
    setTimeout(() => {
      setVerifyGridHeight('calc(100vh - 242px)');
    }, 100);
  };

  const handleVerifyInputFocus = () => {
    setVerifyButtonSize('large');
    setTimeout(() => {
      setVerifyGridHeight('calc(100vh - 250px)');
    }, 100);
  };
  */

  const isCodeValid = (code: string, codes?: StringKAnyVPair) => {
    let ret = false;

    if (codes && typeof codes === 'object') {
      for (let k in codes) {
        ret = code === codes[k];

        if (ret) {
          break;
        }
      }
    }

    return ret;
  };

  /**
   * Test whether the order has been in the label queue.
   */
  // eslint-disable-next-line
  const isInLabelQueue = (orderId: string, shipmentNum: number) => {
    const found = labelQueue.filter(
      (e) => e.orderId === orderId && e.shipmentNum === shipmentNum
    );

    return found.length > 0;
  };

  /**
   * Check whether the printer id is valid.
   */
  const isPrinterIdValid = (printerId: number) => {
    let ret = false;

    for (let i = 0; i < printerList.length; i++) {
      if (printerId === printerList[i].printerNum) {
        ret = true;
        break;
      }
    }

    return ret;
  };

  /**
   * Check whether the scale id is valid.
   */
  const isScaleIdValid = (scaleId: number) => {
    let ret = false;

    for (let i = 0; i < scaleList.length; i++) {
      if (scaleId === scaleList[i].scaleNum) {
        ret = true;
        break;
      }
    }

    return ret;
  };

  const indexOfTabNavQueue = (id: string) => {
    return tabKeyQueue.indexOf(id);
  };

  const maskScreen = (mask: boolean) => {
    screenMasked = mask;
  };

  const nextTabNavDom = (currentId: string) => {
    const idx = indexOfTabNavQueue(currentId);
    let ni = -1;
    let ret = null;

    if (idx > -1) {
      if (idx === tabKeyQueue.length - 1) {
        ni = 0;
      } else {
        ni = idx + 1;
      }
    }

    if (ni > -1) {
      const nid = tabKeyQueue[ni];

      ret = document.querySelector(`#${nid}`) as HTMLElement | null;
    }

    return ret;
  };

  const onCloseDeviceSettingDialog = () => {
    setDeviceSettingVisible(false);
    tryFocusOrderInput();
  };

  const onFindOrderChange = (evt: any) => {
    setFindOrderId(evt.target.value);
  };

  const onFindOrderKeyDown = (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      if (findOrderId) {
        handleFindOrder(findOrderId);
      } else {
        if (evt.keyCode === 9) {
          findOrderRef?.current?.blur();
        } else {
          playBell('bell');
          //tryFocusItemInput();
          tryFocusOrderInput();
        }
      }
    }
  };

  /**
   * Callback for printing label successfully.
   */
  const onPrintLabelSuccess = () => {
    if (selectedShipment) {
      selectedShipment.shippingStatus = SHIPMENT_SHIPPING_STATE;
      setOrderShipmentList([...orderShipmentList]);
    }
  };

  const onSelectPrintHistoryItem = async (item: PrintedLabel) => {
    if (item.orderId === selectedShipmentOrder) {
      if (item.shipmentNum !== selectedShipmentNum) {
        setSelectedShipmentNum(item.shipmentNum);
      }
    } else {
      await handleFindOrder(`_${item.orderId}`);
      setSelectedShipmentNum(item.shipmentNum);
    }
  };

  const onSelectPriorityPicklist = (value: any) => {
    const { pendingCount } = value || {};

    if (pendingCount && typeof pendingCount === 'number' && pendingCount > 0) {
      setPriorityPicklistItem(value);
      setPriorityPicklistNum(value.pickListNum);
    } else {
      setPriorityPicklistItem({});
      setPriorityPicklistNum(undefined);
    }
  };

  // maybe this function can be used as a property for a component
  const onSkipPrintPackingSlip = (evt: any) => {
    const checked = evt.target.checked;

    if (checked) {
      message.info({
        content: 'Printing packing slip will be skipped',
        duration: USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
      });
    } else {
      message.info({
        content: 'Packing slip will be printed',
        duration: USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
      });
    }

    setSkipPrintPackSlip(checked);
    setSkipPrintingPackSlip(checked);
  };

  const onVerifyItemChange = (evt: any) => {
    setVerifyCode(evt.target.value.trim());
  };

  const onVerifyItemKeyDown = (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      if (verifyCode) {
        handleScanShipmentItem(verifyCode);
        evt.preventDefault();
      } else {
        if (evt.keyCode === 9) {
          itemInputRef?.current?.blur();
        } else {
          playBell('bell');
          tryFocusItemInput();
        }
      }
    }
  };

  /**
   * Callback for clicking the verification message bar.
   */
  const onVerifyMessageBarClick = () => {
    if (bottomMessageTimer) {
      clearTimeout(bottomMessageTimer);
      bottomMessageTimer = null;
    }

    setVerifyMessageBarStyle({ top: MESSAGE_BAR_POS_TOP1 });
  };

  const openBatchPrintDialog = () => {
    setOrderFound(false);
    setOrderShipmentList([]);
    setShipmentItemDict({});
    setSelectedShipmentNum(0);
    setBatchPrintDialogVisible(true);
  };

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  const openScanTrackingNumberDialog = () => {
    setScanTrackNumberVisible(true);
    setOrderTrackNumber('');
    setTimeout(() => {
      scanTrackNumberRef?.current?.focus();
    }, 500);
  };

  const openShipMultiPackages = () => {
    setShipMultiPkgDialogVisible(true);
  };

  /**
   * handle change shipment
   */
  const handleChangeShipment = useCallback(
    (nextShipment: OrderShipment) => {
      //update shipToAddressVerified
      const newOrderShipmentList = orderShipmentList.map((item) => {
        if (item.shipmentNum === nextShipment.shipmentNum) {
          return {
            ...item,
            shipToAddressVerified: nextShipment.shipToAddressVerified,
          };
        }
        return item;
      });
      setOrderShipmentList(newOrderShipmentList as any);
    },
    [orderShipmentList]
  );

  const openAlertDialog = (config: StringKAnyVPair) => {
    setAlertDialogVisible(true);
    setAlertDialogContent(config.content || '');
    setAlertDialogOnClose(
      typeof config.onClose === 'function' ? config.onClose : undefined
    );
    setAlertDialogTitle(config.title || '');
  };

  // eslint-disable-next-line
  const openShipmentConfirmDialog = async () => {
    const slipType = await getSelectedShipmentPackingSlipType();

    setPrintPackingSlipType(slipType);
    setShipmentConfirmDialogVisible(true);
  };

  const printShipmentCartonLabel = (shipment: StringKAnyVPair) => {
    const profile = userProfiles.profile;
    const printer = cartonPrinter;
    
    printShipmentCommonData(profile, printer, shipment, dispatch, printCartonLabel);
  };

  const printShipmentPackingSlip = (shipment: StringKAnyVPair) => {
    const profile = userProfiles.profile;
    const slipType = printPackingSlipType;
    const printer = slipType ? packingPrinter2 : packingPrinter;
    printShipmentCommonData(profile, printer, shipment, dispatch, printPackingSlip);
  };

  const scanToShipSettingMenu = () => {
    return (
      <Menu>
        <Menu.Item key="0">
          <Checkbox
            checked={skipPrintingPackSlip}
            onChange={onSkipPrintPackingSlip}
          >
          {/*<Checkbox onClick={e => e.stopPropagation()}>*/}
            {/*<LabelFormDropdownItem onClick={e => e.stopPropagation()}>Skip printing packing slip</LabelFormDropdownItem>*/}
            <LabelFormDropdownItem onClick={e => e.stopPropagation()}>Skip printing packing slip</LabelFormDropdownItem>
          </Checkbox>
        </Menu.Item>
      </Menu>
    );
  };

  // eslint-disable-next-line
  const shipmentTableRowStyle = (param: any) => {
    const { data } = param;

    if (data && typeof data === 'object') {
      if (
        data.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE ||
        data.shippingQty === data.verifyQty
      ) {
        return {
          backgroundColor: 'rgba(206, 239, 218, .3)',
        };
      } else {
        if (data.scanError) {
          return {
            backgroundColor: 'rgba(249, 152, 152, .5)',
          };
        }
      }
    }
  };

  const syncShipmentProps = (shipmentNum: number, props: StringKAnyVPair) => {
    let found = false;

    for (let i = 0; i < orderShipmentList.length; i++) {
      const s: StringKAnyVPair = orderShipmentList[i];

      if (s.shipmentNum === shipmentNum) {
        for (let k in props) {
          if (k in s) {
            s[k] = props[k];
          }
        }

        found = true;
        break;
      }
    }

    if (found) {
      setOrderShipmentList([...orderShipmentList]);
      console.log('u->', props);
    }
  };

  const tryFocusItemInput = () => {
    if (itemInputRef?.current) {
      setTimeout(() => {
        itemInputRef?.current.focus();
        setTimeout(() => {
          itemInputRef?.current.focus();
        }, 100);
      }, 100);
    }
  };

  const tryFocusItemVerify = () => {
    setTimeout(() => {
      if (!itemInputRef?.current) return;

      if (itemInputRef?.current?.props?.disabled) {
        findOrderRef?.current?.blur();
      } else {
        tryFocusItemInput();
      }
    }, 600);
    setTimeout(() => {
      if (!itemInputRef?.current) return;

      if (itemInputRef?.current?.props?.disabled) {
        findOrderRef?.current?.blur();
      } else {
        tryFocusItemInput();
      }
    }, 1000);
  };

  const tryFocusOrderInput = () => {
    if (findOrderRef?.current) {
      setTimeout(() => {
        findOrderRef?.current.focus();
        setTimeout(() => {
          findOrderRef?.current.focus();
        }, 100);
      }, 100);
    }
  };

  const tryUpdateShipment = () => {
    if (selectedShipment) {
      updateShipment(selectedShipment.shipmentNum);
    }
  };

  // eslint-disable-next-line
  const updateShipment = async (shipmentNum: number, update: StringKAnyVPair = {}) => {
    try {
      const shipment = await fetchShipmentDetail(shipmentNum);

      if (shipment) {
        const list = [...orderShipmentList];

        for (let i = 0; i < list.length; i++) {
          if (list[i].shipmentNum === shipment.shipmentNum) {
            list[i] = {
              ...shipment,
              ...update,
              shipmentTitle: `Shipment ${i + 1}`,
            };
            setOrderShipmentList(list);
            break;
          }
        }

        if (update.trackingNumber) {
          if (Array.isArray(shipment.documents) && shipment.documents.length > 0) {
            //printDocuments(shipment);
            setDocumentTrack({
              shipmentNum: shipment.shipmentNum,
              trackingNumber: update.trackingNumber,
            });
          }
        }
      }
    } catch (e) {
      message.error(`Fetch shipment error ${e}`);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      const whNum = parseInt(
        localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
      );
      const whCode = getWarehouseCodeFromCache(whNum);
      const skipPackSlip = initialSkipPrintPackSlip();

      //filterSectionRef?.current?.scrollIntoView({behavior: 'smooth'});
      //enterFullPage();
      focusFindOrderInput();
      //window.addEventListener('resize', adjustLayout, false);
      setLockScreen(true);
      setSkipPrintingPackSlip(skipPackSlip);
      setWarehouseCode(whCode);
      document.addEventListener('keydown', handleKeydown, false);
      keydownHandler = handleKeydown;
      setInited(true);
      setTimeout(enterFullPage, 0);

      if (whNum > 0 /*&& whid*/) {
        const whId = getWarehouseIdFromCache(whNum);

        setWarehouseId(whNum);

        if (whId && !whCode) {
          getWarehouseById(whId);
        }
      } else {
        openDeviceSettingDialog({ closable: false });
      }

      if (!signalIsReady) {
      }
    }

    if (currentPageWidth !== pageWidth) {
      adjustLayout();
      setCurrentPageWidth(pageWidth);
    }

    if (printLabelMessage) {
      //console.log('pm->', printLabelMessage);
      if (printLabelMessage.ShipmentNum > 0) {
        //if (printLabelMessage.TrackingNumber > 0 && selectedShipment) {
        //console.log('plm->', printLabelMessage);
        if (printLabelMessage.TrackingNumber && selectedShipment) {
          const trackingNumber = printLabelMessage.TrackingNumber;

          selectedShipment.trackingNumber = trackingNumber;
          selectedShipment.shippingStatus = SHIPMENT_SHIPPING_STATE;
          setOrderShipmentList([...orderShipmentList]);

          if (
            !isInLabelQueue(
              selectedShipment.channelOrderId,
              selectedShipment.shipmentNum
            )
          ) {
            setLabelQueue([
              ...labelQueue,
              {
                isOnly: orderShipmentList.length === 1,
                orderId: selectedShipment.channelOrderId,
                shipmentNum: selectedShipment.shipmentNum,
                shipmentTitle: selectedShipment.shipmentTitle,
                printDate: moment().format(COMMON_DATE_RANGE_FORMAT),
                uuid: uuid(),
              },
            ]);
          }

          if (printLabelMessage.Hold) {
            checkPrintHoldLabel(selectedShipment);
            openAlertDialog({
              title: i18n.t('scanToShip.shippingLabelHeld') || '',
              content: i18n.t('scanToShip.shippingLabelHeldTips', { orderStr: selectedShipmentOrder }) || '',
              onClose: () => () => tryFocusOrderInput(),
            });
          } else {
            openShipmentConfirmDialog();
          }
          // there needs a method to update shipment, maybe after receive the print label message
          setTimeout(() => {
            if (!printLabelMessage.Hold) {
              dispatch(setPrintingHang(false));
            }

            updateShipment(selectedShipment.shipmentNum, {
              shippingStatus: SHIPMENT_SHIPPING_STATE,
              trackingNumber,
            });
          }, PRINT_MESSAGE_RESPONSE_TIMEOUT);
        }

        dispatch(setPrintLabelMessage({}));
      }
    }

    if (!printerListLoaded) {
      dispatch(loadPrinters());
    }

    if (!scaleListLoaded) {
      dispatch(loadScales());
    }

    if (currentSignalConnection !== signalConnection) {
      setCurrentSignalConnection(signalConnection);

      if (!signalIsReady) {
        console.log('try to reconnect');
      }
    }

    if (orderFound) {
      adjustShipmentTabsSize();
    }

    if (currentPageMode !== pageShowMode) {
      if (filterSectionRef?.current && printerFormColRef?.current) {
        adjustShipmentTabsSize();
      }

      setCurrentPageMode(pageShowMode);
    }

    if (selectedShipment) {
      const { fulfillmentOrderId } = selectedShipment;

      if (selectedShipmentShipped) {
        setPrintFormType(PRINT_FORM_TYPE_SUMMARY);
      } else {
        setPrintFormType(PRINT_FORM_TYPE_LABEL);
      }

      if (currentFulfillmentOrderId !== fulfillmentOrderId) {
        setCurrentOrder({} as Order);

        if (fulfillmentOrderId) {
          getOrderInfo(fulfillmentOrderId);
        }

        setCurrentFulfillmentOrderId(selectedShipment.fulfillmentOrderId);
      }
    }

    if (
      /*scaleList.length > 0 &&*/
      printerList.length > 0 &&
      /* && signalConnection === SIGNALR_CONNECTION_CONNECTED */
      !deviceChecked
    ) {
      setLockScreen(false);
      checkDevice();
      setDeviceChecked(true);
    } else if (printerList.length === 0) {
      if (!noPrinterMsgPopped) {
        message.error({
          content:
            'No printer detected, please use Print Station to connect the usable printers.',
          duration: 30,
        });
        setNoPrinterMsgPopped(true);
        setLockScreen(false);
        checkDevice();
      }
    }

    if (selectedShipmentNum > 0 && !shipmentItemDict[selectedShipmentNum]) {
      setShipmentItemDict({
        ...shipmentItemDict,
        [selectedShipmentNum]: [],
      });
      if (selectedShipment) {
        setTimeout(() => {
          getShipmentItemsById(selectedShipmentNum);
        }, 0);
      }
    }

    return () => {
      setTimeout(() => {
        // eslint-disable-next-line
        if (!filterSectionRef?.current) {
          //window.removeEventListener('resize', adjustLayout, false);
          document.removeEventListener('keydown', keydownHandler, false);
          keydownHandler = null;
          exitFullPage();
          dispatch(setScale({}));
        }
      }, 0);
    };
  }, [
    adjustLayout,
    adjustShipmentTabsSize,
    checkDevice,
    checkPrintHoldLabel,
    currentFulfillmentOrderId,
    currentPageMode,
    currentPageWidth,
    currentSignalConnection,
    deviceChecked,
    dispatch,
    enterFullPage,
    exitFullPage,
    getOrderInfo,
    getShipmentItemsById,
    getWarehouseById,
    handleKeydown,
    i18n,
    inited,
    isInLabelQueue,
    labelQueue,
    //locale,
    noPrinterMsgPopped,
    openShipmentConfirmDialog,
    orderFound,
    pageShowMode,
    pageWidth,
    orderShipmentList,
    printLabelMessage,
    printerList,
    printerListLoaded,
    scaleList,
    scaleListLoaded,
    selectedShipment,
    selectedShipmentNum,
    selectedShipmentOrder,
    selectedShipmentShipped,
    shipmentItemDict,
    signalConnection,
    signalIsReady,
    updateShipment,
  ]);

  return (
    <>
      <MainContainer id={MAIN_BOARD_ID} pagemode={pageShowMode}>
        <HeaderTitle breadcrumb={headerTitle}>
          {warehouseId > 0 && (
            <span className="header-warehouse">
              {warehouseCode || <LoadingIcon />}
            </span>
          )}
          <HeaderGroup align="center">
            {skipPrintingPackSlip && (
              <Tooltip placement="bottom" title="Skip printing packing slip">
                <Tag className="skip-pack-slip-tag" color="#CD201F">Skip</Tag>
              </Tooltip>
            )}
            <SignalRLinkLight />
            {labelQueue.length > 0 ? (
              <Dropdown
                overlay={
                  <LabelQueue
                    hide={handleHidePrintHistory}
                    onMouseEnter={handleMouseEnterPrintHistory}
                    onSelect={onSelectPrintHistoryItem}
                    queue={labelQueue}
                    shipment={selectedShipment || undefined}
                  />
                }
                //trigger={['click']}
                placement="bottomCenter"
                visible={printHistoryVisible}
              >
                <Badge size="small" count={labelQueue.length} offset={[-28, 2]}>
                  {/* eslint-disable-next-line */}
                  <i
                    className="bi-printer"
                    onMouseLeave={handleMouseLeavePrintHistoryTrigger}
                    onClick={handleTogglePrintHistory}
                    style={{ cursor: 'pointer', fontSize: ICON_SIZE1 }}
                  />
                </Badge>
              </Dropdown>
            ) : (
              <Badge
                size="small"
                count={labelQueue.length}
                offset={[-28, 2]}
                showZero
              >
                <i className="bi-printer" style={{ fontSize: ICON_SIZE1 }} />
              </Badge>
            )}
            {/*<Button onClick={() => openDeviceSettingDialog({ closable: true })}>
              <SettingOutlined />
              <Trans i18nKey="scanToShip.device.config" />
            </Button>*/}
            <Dropdown.Button
              //icon={<SettingOutlined />}
              onClick={() => openDeviceSettingDialog({ closable: true })}
              overlay={scanToShipSettingMenu()}
            >
              <SettingOutlined />
              <Trans i18nKey="scanToShip.device.config" />
            </Dropdown.Button>
            <FullPageButton />
          </HeaderGroup>
        </HeaderTitle>
        <SectionWrapper ref={filterSectionRef}>
          <Row align="middle" justify="space-between">
            <Space>
              <Input.Search
                allowClear
                enterButton={
                  <>
                    {/* <ButtonIcon className="bi-search" /> */}
                    <SearchOutlined />
                    <Trans i18nKey="scanToShip.findOrder">Find Order</Trans>
                  </>
                }
                onChange={onFindOrderChange}
                onKeyDown={onFindOrderKeyDown}
                //onPressEnter={(evt) => {}}
                onSearch={handleFindOrder}
                placeholder={i18n.t('scanToShip.searchPlaceholder') || ''}
                ref={findOrderRef}
                size={commonFormButtonSize}
                style={{ width: SEARCH_BOX1_WIDTH }}
                suffix={priorityPicklistItem.pendingCount ? findOrderPriorityPickListSuffix() : null}
                value={findOrderId}
              />
              <Dropdown overlay={findMoreDropdownMenu()} trigger={['click']}>
                <HoverBgButton
                  hovertype="info"
                  size={commonFormButtonSize}
                  style={{ padding: '4px 8px' }}
                >
                  <EllipsisOutlined />
                </HoverBgButton>
              </Dropdown>
            </Space>
            {orderFound ? (
              <>
                <Space>
                  <InfoButton
                    disabled={!selectedShipmentManageEnable}
                    onClick={handleManageShipment}
                    size={commonFormButtonSize}
                  >
                    <Trans i18nKey="scanToShip.manageShipments" />
                  </InfoButton>
                </Space>
                <Space>
                  <Label>
                    <Space>
                      {isFetchingOrder && <LoadingIcon size="small" />}
                      {currentOrder.channelAccountName && (
                        <span className="label-bold">
                          {currentOrder.channelAccountName} Order#:
                        </span>
                      )}
                      <span
                        className="label-primary clickable"
                        onClick={() => setOrderDetailDialogVisible(true)}
                      >
                        {selectedShipmentOrder}
                      </span>
                      <span className="label-bold"><Trans i18nKey="scanToShip.zip" />:</span>
                      <span className="label-grey">
                        {selectedShipmentShipToPostalCode}
                      </span>
                      <span className="label-grey">
                        {currentOrder.requestShippingService || ''}
                      </span>
                    </Space>
                  </Label>
                </Space>
              </>
            ) : (
              ''
            )}
          </Row>
        </SectionWrapper>
        <FormVSpacer height={REGULAR_SECTION_VERTICAL_SPACING} />
        {orderFound ? (
          <SectionWrapper>
            <VerifyContainer>
              <Col md={14} lg={15} xxl={15}>
                <Row align="middle" justify="space-between">
                  <Input.Search
                    allowClear
                    disabled={!selectedShipmentInputVerifyEnable}
                    enterButton={
                      <>
                        <ButtonIcon className="bi-check-circle" />
                        <Trans i18nKey="scanToShip.verifyItem" />
                      </>
                    }
                    onChange={onVerifyItemChange}
                    onKeyDown={onVerifyItemKeyDown}
                    //onPressEnter={(evt) => {}}
                    onSearch={handleScanShipmentItem}
                    placeholder={i18n.t('scanToShip.verifyPlaceholder') || ''}
                    ref={itemInputRef}
                    size="large"
                    style={{ width: SEARCH_BOX2_WIDTH }}
                    value={verifyCode}
                  />
                  <Space className="verify-info-space">
                    <Label
                      className={[
                        'shipment-item-remaining-number',
                        'label-bold',
                        selectedShipmentItemsUnscannedQuantity < 0
                          ? 'label-danger'
                          : '',
                      ].join(' ')}
                    >
                      {selectedShipmentItemsUnscannedQuantity}
                    </Label>
                    <Label className="shipment-item-remaining-board">
                      <span className="label-grey"><Trans i18nKey="scanToShip.remaining" /></span>
                    </Label>
                  </Space>
                </Row>
                <FormVSpacer />
                <DataGrid
                  key={verifyRowHeight}
                  columns={columns}
                  dataSource={shipmentItemDict[selectedShipmentNum] || []}
                  rowHeight={verifyRowHeight}
                  rowKey="uuid"
                  rowStyle={shipmentTableRowStyle}
                  pagination={false}
                  showHoverRows={false}
                  style={{ height: verifyGridHeight }}
                />
                <div ref={shipmentTabsRef} style={{ width: shipmentTabsWidth }}>
                  <ShipmentTabs
                    activeKey={`${selectedShipmentNum}`}
                    moreIcon={
                      <>
                        {i18n.t('common.more')}
                        <EllipsisOutlined />
                      </>
                    }
                    onChange={handleChangeShipmentTab}
                    tabPosition="bottom"
                    type="card"
                  >
                    {tabShipmentList.map((e, i) => (
                      <ShipmentTabs.TabPane
                        tab={
                          <>
                            {selectedShipmentNum === e.shipmentNum ? (
                              <div className="ant-popover-arrow">
                                <span className="ant-popover-arrow-content" />
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className="tab-item"
                              data-sm-verified={
                                e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
                                  ? true
                                  : false
                              }
                              data-sm-shipped={
                                e.shippingStatus === SHIPMENT_SHIPPING_STATE
                                  ? true
                                  : false
                              }
                            >
                              <i className={getOrderShipmentIcon(e)} />
                              {e.shipmentTitle}
                            </div>
                          </>
                        }
                        key={e.shipmentNum}
                      />
                    ))}
                  </ShipmentTabs>
                </div>
                <div className="verify-bottom-bar">
                  <BottomButtonRow
                    align="middle"
                    justify="space-between"
                    className="verify-btn-row"
                  >
                    <Space size={16}>
                      <Space>
                        <Label className="label-grey total-shipment-label">
                          <Trans i18nKey="common.total" />
                        </Label>
                        <Label className="label-bold total-shipment-qty">
                          {orderShipmentList.length}
                        </Label>
                      </Space>
                      <Dropdown
                        disabled={shippedShipments.length === 0}
                        overlay={getShipmentDropdown(shippedShipments)}
                        trigger={['click']}
                      >
                        <Button
                          disabled={shippedShipments.length === 0}
                          size="large"
                        >
                          <ButtonIcon className="bi-truck" />
                          ({shippedShipments.length}) <Trans i18nKey="scanToShip.shipped" />
                          {/*<FontAwesomeIcon icon={faShippingFast} className="font-btn-icon" />*/}
                        </Button>
                      </Dropdown>
                    </Space>
                    {selectedShipmentCancelled && (
                      <Space size={10}>
                        <Tooltip
                          placement="top"
                          title={`${selectedShipmentTitle} was cancelled`}
                        >
                          <Label className="label-danger total-shipment-label">
                            Cancelled
                          </Label>
                        </Tooltip>
                      </Space>
                    )}
                    {!selectedShipmentCancelled && (
                      <Space size={10}>
                        {selectedShipment &&
                          selectedShipment.verifyStatus !==
                          SHIPMENT_ITEM_VERIFY_STATE && (
                            <InfoButton
                              disabled={selectedShipmentSplitItems.length === 0}
                              onClick={confirmAutoShipment}
                              size="large"
                              type="primary"
                            >
                              <FileAddOutlined />
                              <Trans i18nKey="scanToShip.autoShipment" />
                            </InfoButton>
                          )}
                        <Button
                          className="mark-verify-btn"
                          type="primary"
                          ref={markVerifyBtnRef}
                          size="large"
                          disabled={!selectedShipmentMarkVerifyEnable}
                          onClick={handleMarkShipmentVerified}
                        >
                          <ButtonIcon className="bi-check-circle-fill" />
                          <Trans i18nKey="scanToShip.markAsVerify" />
                        </Button>
                        {/*<Dropdown overlay={rightDropdownMenu} trigger={['click']}>
                        <Button size="large"><EllipsisOutlined /></Button>
                      </Dropdown>*/}
                        {selectedShipmentVerifiedNotShipped && (
                          <Button
                            onClick={() => setConfirmClearVisible(true)}
                            size="large"
                          >
                            <EllipsisOutlined />
                          </Button>
                        )}
                      </Space>
                    )}
                  </BottomButtonRow>
                  <div className="verify-msg-bar" style={verifyMessageBarStyle}>
                    <Row>
                      <Alert
                        type="success"
                        message={
                          <>
                            <i className="bi-check-lg left-icon success-icon" />
                            <Label>
                              {i18n.t('scanToShip.verifySuccess')}
                            </Label>
                          </>
                        }
                        onClick={onVerifyMessageBarClick}
                      />
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={10} lg={9} xxl={9}>
                <Row justify="center" ref={printerFormColRef}>
                  <div
                    className="label-container"
                    data-type={
                      printFormType === PRINT_FORM_TYPE_LABEL ? 'label' : 'slip'
                    }
                  >
                    <div className="label-form-wrapper">
                      {printFormType === PRINT_FORM_TYPE_LABEL ? (
                        <PrintLabel
                          changeShipment={handleChangeShipmentTab}
                          deviceSetting={() => openDeviceSettingDialog({})}
                          getPackingSlipType={
                            getSelectedShipmentPackingSlipType
                          }
                          onMarkShipped={tryUpdateShipment}
                          onPrintLabelSuccess={onPrintLabelSuccess}
                          openShipMultiPackages={openShipMultiPackages}
                          markVerified={!selectedShipmentMarkVerifyEnable}
                          shipment={selectedShipment}
                          syncShipmentProps={syncShipmentProps}
                          multiPkgShipments={getMultiPackageShipments()}
                        />
                      ) : (
                        printFormType === PRINT_FORM_TYPE_SUMMARY &&
                        selectedShipment && (
                          <PrintSummary
                            deviceSetting={() => openDeviceSettingDialog({})}
                            documentTrack={documentTrack}
                            getPackingSlipType={
                              getSelectedShipmentPackingSlipType
                            }
                            resetOrderShipment={resetOrderShipment}
                            shipment={selectedShipment}
                          />
                        )
                      )}
                    </div>
                    {shipmentFormEnabled && !selectedShipmentCancelled ? (
                      ''
                    ) : (
                      <div className="label-container-mask" />
                    )}
                  </div>
                </Row>
              </Col>
            </VerifyContainer>
          </SectionWrapper>
        ) : (
          ''
        )}
      </MainContainer>
      {deviceSettingVisible && (
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={onCloseDeviceSettingDialog}
          visible={deviceSettingVisible}
          warehouseNum={warehouseId}
        />
      )}
      {/*shipmentDialogVisible && (
        <ManageShipmentDialog
          onClose={() => setShipmentDialogVisible(false)}
          reloadShipment={(shipmentNum: number) => {
            setShipmentDialogVisible(false);
            resetOrderShipment(shipmentNum);
          }}
          resetOrderShipment={resetOrderShipment}
          shipmentList={orderShipmentList}
          shipmentNum={selectedShipmentNum}
        />
      )*/}
      {shipmentDialogVisible && (
        <ManageShipmentDialog
          onClose={() => {
            setShipmentDialogVisible(false);
            maskScreen(false);
          }}
          orderId={selectedShipmentOrder}
          resetOrderShipment={resetOrderShipment}
          selectedShipmentId={
            selectedShipment ? selectedShipment.shipmentId : undefined
          }
          visible={shipmentDialogVisible}
          warehouseNum={warehouseId}
        />
      )}
      {orderDetailDialogVisible && selectedShipment && (
        <OrderDetailDialog
          fulfillmentOrderId={selectedShipment.fulfillmentOrderId}
          fulfillmentOrderNum={selectedShipment.fulfillmentOrderNum}
          // shipmentList={orderShipmentList}
          // onChangeShipment={handleChangeShipment}
          onClose={() => setOrderDetailDialogVisible(false)}
          order={currentOrder}
        />
      )}
      {shipmentDetailDialogVisible && (
        <ShipmentDetailDialog
          shipment={selectedShipment as any}
          onChangeShipment={handleChangeShipment}
          onClose={() => setShipmentDetailDialogVisible(false)}
        />
      )}
      {shipmentConfirmDialogVisible && selectedShipment && (
        <ShipmentConfirmDialog
          close={handleCloseShipmentConfirmDialog}
          isTracking
          changeOrder={handleFindOrder}
          changeShipment={handleChangeShipmentTab}
          onPrintError={tryUpdateShipment}
          packingSlipType={printPackingSlipType}
          shipment={selectedShipment}
          shipmentList={tabShipmentList}
          visible={shipmentConfirmDialogVisible}
        />
      )}
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
      <Modal
        centered
        closable={false}
        footer={null}
        title=""
        visible={confirmClearVisible}
        width={400}
      >
        <Row justify="space-between">
          <Button onClick={() => setConfirmClearVisible(false)} size="large">
            {i18n.t('common.close')}
          </Button>
          <Button onClick={handleClearVerifiedMark} size="large" type="primary">
            {i18n.t('scanToShip.clearVerifiedMark')}
          </Button>
        </Row>
      </Modal>
      {scanTrackNumberVisible && (
        <Modal
          centered
          footer={
            <Button onClick={closeScanTrackingNumberDialog} icon={<CloseOutlined />}>{i18n.t("common.cancel")}</Button>
          }
          maskClosable={false}
          onCancel={closeScanTrackingNumberDialog}
          title={i18n.t('scanToShip.scanTrackingNumber.title')}
          visible={scanTrackNumberVisible}
          width={500}
        >
          <Row justify="space-between">
            <Input.Search
              allowClear
              defaultValue={orderTrackNumber}
              enterButton={<i className="bi-search" />}
              onChange={handleScanTrackNumberChange}
              onKeyDown={handleScanTrackNumberKeyDown}
              onSearch={handleSearchOrderByTrackingNumber}
              placeholder={i18n.t('scanToShip.scanTrackingNumber.title') || ''}
              ref={scanTrackNumberRef}
              size="large"
            />
          </Row>
        </Modal>
      )}
      {batchPrintDialogVisible && (
        <BatchPrintDialog
          deviceSetting={() => openDeviceSettingDialog({})}
          onClose={() => {
            setBatchPrintDialogVisible(false);
            tryFocusOrderInput();
          }}
          visible={batchPrintDialogVisible}
          warehouseNum={warehouseId}

        />
      )}
      {priorityPicklistVisible && (
        <PriorityPicklistDialog
          onHide={() => {
            setPriorityPicklistVisible(false);
            tryFocusOrderInput();
          }}
          onSave={(value) => {
            setPriorityPicklistVisible(false);
            onSelectPriorityPicklist(value);
            tryFocusOrderInput();
          }}
          priorityPicklistNum={priorityPicklistNum}
          visible={priorityPicklistVisible}
        />
      )}
      {shipMultiPkgDialogVisible && (
        <ShipMultiPackages
          deviceSetting={() => openDeviceSettingDialog({})}
          onClose={closeShipMultiPackagesDialog}
          openAlertDialog={openAlertDialog}
          orderShipmentList={getMultiPackageShipments()}
          resetOrderShipment={resetOrderShipment}
          visible={shipMultiPkgDialogVisible}
        />
      )}
      <AlertDialog
        close={() => setAlertDialogVisible(false)}
        content={alertDialogContent}
        onClose={alertDialogOnClose}
        title={alertDialogTitle}
        visible={alertDialogVisible}
      />
      {
        bundleModalVisible && current && (
          <BundleModal 
            visible
            onHide={()=> setBundleModalVisible(false)}
            product={current}
          />
        )
      }
    </>
  );
};

export const initialSkipPrintPackSlip = () => {
  const val = localStorage.getItem(LS_SKIP_PRINT_PACK_SLIP) || '0';
  let skip = parseInt(val) || 0;

  return !!skip;
};

export const isSkipPrintPackSlip = () => {
  return skipPrintPackSlip;
};

export const printShipmentCommonData = (
  profile: StringKAnyVPair,
  printer: StringKAnyVPair,
  shipment: StringKAnyVPair,
  dispatch: Function,
  dispatchFn: Function,
) => {
  const pd = {
    MasterAccountNum: profile.masterAccountNum,
    ProfileNum: profile.profileNum,
    //PrinterName: packingPrinter.printerName,
    PrinterNum: printer.printerNum,
    PrinterAccountNum: printer.printerAccountNum,
    User: profile.email,
    FulfillmentOrderNum: shipment.fulfillmentOrderNum,
    ShipmentNum: shipment.shipmentNum,
    Time: nowTime(),
  };

  dispatch(dispatchFn(pd));
};

export const setSkipPrintPackSlip = (skip: boolean) => {
  skipPrintPackSlip = skip;
  localStorage.setItem(LS_SKIP_PRINT_PACK_SLIP, skip ? '1' : '0');
};
