import React, { useEffect, useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select, Input } from 'antd';
// import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { SelectWarehouse, message } from 'components/common';
import {
  addCarrierServiceRestriction,
  updateCarrierServiceRestriction,
  getStateRestrictionList,
} from 'services/carrierServiceRestrictions';
import { getCarrierServices } from 'services/shipment';

interface AddRestrictionProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  carries: any[];
  warehouseNum: number;
  restriction?: any;
  isAll?: boolean;
}

// eslint-disable-next-line
export default (props: AddRestrictionProps) => {
  const {
    visible,
    onHide,
    onRefresh,
    carries,
    warehouseNum,
    restriction,
    isAll,
  } = props;
  const [loading, setLoading] = useState(false);
  // const [loading1, setLoading1] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [services, setServices] = useState<any[]>([]);
  // const [aroundServices, setAroundServices] = useState<any[]>([]);
  const [status, setStatus] = useState<any[]>([]);

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const minValueValidate = (rule: any, value: any) => {
    if (!value && value !== 0) {
      return Promise.reject('Min is required');
    }
    const { getFieldValue } = form;
    const { field } = rule;
    if (field === 'WeightRangeMin') {
      if (value > getFieldValue('WeightRangeMax')) {
        return Promise.reject('Min must less than Max');
      }
    }
    if (field === 'LengthRangeMin') {
      if (value > getFieldValue('LengthRangeMax')) {
        return Promise.reject('Min must less than Max');
      }
    }
    if (field === 'LengthGirthRangeMin') {
      if (value > getFieldValue('LengthGirthRangeMax')) {
        return Promise.reject('Min must less than Max');
      }
    }
    return Promise.resolve();
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      CarrierServiceRestrictionNum: restriction
        ? restriction.carrierServiceRestrictionNum
        : 0,
      CarrierServiceNum: params.CarrierServiceNum,
      StateRestrictionNum: params.StateRestrictionNum,
      WeightRangeMin: params.WeightRangeMin,
      WeightRangeMax: params.WeightRangeMax,
      WeightUnit: params.WeightUnit,
      LengthRangeMin: params.LengthRangeMin,
      LengthRangeMax: params.LengthRangeMax,
      DimensionUnit: params.DimensionUnit,
      WarehouseNum: params.WarehouseNum.id,
      LengthGirthRangeMin: params.LengthGirthRangeMin,
      LengthGirthRangeMax: params.LengthGirthRangeMax,
    };
    try {
      setLoading(true);
      setSubmitLoading(true);
      let res;
      if (!restriction) {
        res = await addCarrierServiceRestriction(postParams);
      } else {
        res = await updateCarrierServiceRestriction(postParams);
      }
      setLoading(false);
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: `${restriction ? 'Save' : 'Add'} successfully`,
        });
        document.body.style.overflowY = 'auto';
        onHide();
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onOk() {
          onHide();
        },
      });
    }
  };

  const getstatusList = async () => {
    try {
      const res = await getStateRestrictionList();
      if (res.isSuccess) {
        setStatus(res.data);
      }
    } catch (error) { }
  };

  const getServices = async (carrierNum: number) => {
    try {
      setLoading(true);
      const res = await getCarrierServices(carrierNum);
      setLoading(false);
      if (res.isSuccess) {
        setServices(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // const getAroundServices = async () => {
  //   try {
  //     setLoading1(true);
  //     const res = await getCarrierServices(0);
  //     setLoading1(false);
  //     if (res.isSuccess) {
  //       setAroundServices(res.data);
  //     }
  //   } catch (error) {
  //     setLoading1(false);
  //   }
  // };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    getstatusList();
    // getAroundServices();
  }, []);

  useEffect(() => {
    if (restriction && restriction.carrierNum) {
      getServices(restriction.carrierNum);
    }
  }, [restriction]);

  return (
    <Modal
      title={`${restriction ? 'Edit' : 'Add'} Service Restriction Setting`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_or_add_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {restriction ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${restriction ? 'Saving' : 'Adding'}`
                : `${restriction ? 'Save' : 'Add'}`}
            </Button>
            <Button id="cancel_button" onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="WarehouseNum"
            initialValue={
              restriction
                ? { id: restriction.warehouseNum }
                : warehouseNum > 0
                  ? { id: warehouseNum }
                  : undefined
            }
            label={<FormLabel>Warehouse</FormLabel>}
            rules={[{ required: true, message: 'please select warehouse' }]}
          >
            <SelectWarehouse
              id="warehouse_select"
              disabled={restriction ? (isAll ? false : true) : false}
              width={500}
              placeholder="please select warehouse"
            />
          </Form.Item>

          <Form.Item
            name="CarrierNum"
            initialValue={restriction ? restriction.carrierNum : undefined}
            label={<FormLabel>Carrier</FormLabel>}
            rules={[{ required: true, message: 'Please select Carrier!' }]}
          >
            <Select
              id="carrier_select"
              onChange={(i) => {
                getServices(Number(i));
                form.setFieldsValue({
                  CarrierServiceNum: undefined,
                });
              }}
            >
              {carries
                .filter((i) => i.carrierNum !== 0)
                .map((item) => (
                  <Select.Option key={item.carrierNum} value={item.carrierNum}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="CarrierServiceNum"
            initialValue={
              restriction ? restriction.carrierServiceNum : undefined
            }
            label={<FormLabel>Service</FormLabel>}
            rules={[{ required: true, message: 'please select service' }]}
          >
            <Select id="service_select" disabled={!form.getFieldValue('CarrierNum')}>
              {services.map((item) => (
                <Select.Option
                  key={item.carrierServiceNum}
                  value={item.carrierServiceNum}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="StateRestrictionNum"
            initialValue={
              restriction ? restriction.stateRestrictionNum : undefined
            }
            label={<FormLabel>State Restriction</FormLabel>}
            rules={[
              { required: true, message: 'please select state restriction' },
            ]}
          >
            <Select id="state_select">
              {status.map((item) => (
                <Select.Option
                  key={item.stateRestrictionNum}
                  value={item.stateRestrictionNum}
                >
                  {item.stateRestrictionType}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={<FormLabel>Weight</FormLabel>}
            rules={[{ required: true, message: '' }]}
          >
            <Space>
              <Space>
                <FormLabel noColon>Min</FormLabel>
                <Form.Item
                  noStyle
                  name="WeightRangeMin"
                  initialValue={restriction?.weightRangeMin}
                  rules={[{ validator: minValueValidate }]}
                >
                  <Input id="min_weight_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
              <Space>
                <FormLabel noColon>Max</FormLabel>
                <Form.Item
                  noStyle
                  name="WeightRangeMax"
                  initialValue={restriction?.weightRangeMax}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input id="max_weight_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
              <Space>
                <FormLabel noColon>Weight Unit</FormLabel>
                <Form.Item
                  noStyle
                  name="WeightUnit"
                  initialValue={restriction?.weightUnit}
                  rules={[{ required: true, message: '' }]}
                >
                  <Select id="weight_unit_select" style={{ width: 100 }}>
                    <Select.Option value={0}>Pound</Select.Option>
                    <Select.Option value={1}>Ounce</Select.Option>
                    <Select.Option value={2}>Kilogram</Select.Option>
                    <Select.Option value={3}>Gram</Select.Option>
                  </Select>
                </Form.Item>
              </Space>
            </Space>
          </Form.Item>
          <Form.Item
            label={<FormLabel>Length</FormLabel>}
            rules={[{ required: true, message: 'please select service' }]}
          >
            <Space>
              <Space>
                <FormLabel noColon>Min</FormLabel>
                <Form.Item
                  noStyle
                  name="LengthRangeMin"
                  initialValue={restriction?.lengthRangeMin}
                  rules={[{ validator: minValueValidate }]}
                >
                  <Input id="min_length_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
              <Space>
                <FormLabel noColon>Max</FormLabel>
                <Form.Item
                  noStyle
                  name="LengthRangeMax"
                  initialValue={restriction?.lengthRangeMax}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input id="max_length_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
              <Space>
                <FormLabel noColon>Length Unit</FormLabel>
                <Form.Item
                  noStyle
                  name="DimensionUnit"
                  initialValue={restriction?.dimensionUnit}
                  rules={[{ required: true, message: '' }]}
                >
                  <Select id="length_unit_select" style={{ width: 100 }}>
                    <Select.Option value={0}>Inch</Select.Option>
                    <Select.Option value={1}>Feet</Select.Option>
                    <Select.Option value={2}>Centimeter</Select.Option>
                    <Select.Option value={3}>Meter</Select.Option>
                    <Select.Option value={4}>Millimeter</Select.Option>
                  </Select>
                </Form.Item>
              </Space>
            </Space>
          </Form.Item>
          <Form.Item
            label={<FormLabel>Length and Girth</FormLabel>}
            rules={[{ required: true, message: '' }]}
          >
            <Space>
              <Space>
                <FormLabel noColon>Min</FormLabel>
                <Form.Item
                  noStyle
                  name="LengthGirthRangeMin"
                  initialValue={restriction?.lengthGirthRangeMin}
                  rules={[{ validator: minValueValidate }]}
                >
                  <Input id="min_length_and_girth_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
              <Space>
                <FormLabel noColon>Max</FormLabel>
                <Form.Item
                  noStyle
                  name="LengthGirthRangeMax"
                  initialValue={restriction?.lengthGirthRangeMax}
                  rules={[{ required: true, message: '' }]}
                >
                  <Input id="max_length_and_girth_input" type="number" min={0} style={{ width: 100 }} />
                </Form.Item>
              </Space>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
