import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Modal, Row, Tabs } from 'antd';
import { PlayCircleOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import {
  FormLabel,
  //Loading,
  ScreenMask,
  TimerLock,
  WarehouseSelector,
  message,
} from 'components/common';
import { Label } from 'components/common/styles';
import {
  FormVSpacer,
  HoverBgButton,
  SectionWrapper,
} from 'components/common/styles/CustomPropsComponents';
//import WarehouseSelectBox from 'components/Warehouse/WarehouseSelectBox';
import { testPrinter } from 'actions/printActions';
import {
  setScale,
  setPackingPrinter,
  setPackingPrinter2,
  setPrintLabelResponse,
  setShipmentPrinter,
  setShipmentPrinter4x8,
  setShipmentPrinterCoton,
  setTestPrintMessage,
} from 'actions/salesAction';
import {
  //LOADING_ICON_SIZE1,
  LS_DEFAULT_WAREHOUSE_KEY,
  LS_PACKING_PRINTER_KEY,
  LS_PACKING_PRINTER2_KEY,
  LS_SCALE_KEY,
  LS_SHIPMENT_PRINTER_KEY,
  LS_SHIPMENT_PRINTER_4x8_KEY,
  LS_SHIPMENT_PRINTER_COTON_KEY,
  PRINT_MESSAGE_RESPONSE_TIMEOUT,
  PRINT_MESSAGE_TYPE_ERROR,
  PRINT_TYPE_TEST_LABEL,
} from 'constants/config';
import { loadPrinterList } from 'middlewares/lazyCacheMiddleware';
import { RootState } from 'reducers';
import { isMacos } from 'utils';
import { userProfiles } from 'utils/auth';
import {
  DeviceSettingAlertRow,
  DeviceSettingScaleRow,
  DeviceSettingPrinterRow,
  DeviceSettingTabs,
  MaskMessageBox,
  deviceSettingDialogBodyStyle,
} from './styles';
import WeightBoard from './WeightBoard';
import ScaleSelector from './ScaleSelector';
import PrinterSelector from './PrinterSelector';

/**
 * Type defintion for the dialog properties.
 * @param {boolean|unefined} closable - default value is false, it means can not be closed.
 */
type Props = {
  close: Function;
  closable?: boolean;
  title?: string;
  visible: boolean;
  warehouseNum?: number;
};

//let keydownHandler: any = null;

const DeviceSettingDialog = (props: Props) => {
  const { useRef, useState } = React;
  const i18n = useTranslation();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [inited, setInited] = useState(false);
  const [labelPrinterReady, setLabelPrinterReady] = useState(false);
  const [labelPrinter4x8Ready, setLabelPrinter4x8Ready] = useState(false);
  const [labelPrinterCotonReady, setLabelPrinterCotonReady] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [slipPrinterReady, setSlipPrinterReady] = useState(false);
  const [slipPrinter2Ready, setSlipPrinter2Ready] = useState(false);
  const shell = useRef<any>(null);
  const unlockButton = useRef<any>(null);
  const warehouseNum: number = props.warehouseNum || -1;
  const okBtnStyle = warehouseNum > -1 ? {} : { display: 'none' };
  const title = props.title || (warehouseNum > 0 ? i18n.t('scanToShip.device.setting') : 'Setting');

  const selectedPackingPrinter: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter,
    shallowEqual
  );
  const selectedPackingPrinter2: Printer = useSelector(
    (state: RootState) => state.sales.packingPrinter2,
    shallowEqual
  );
  const selectedScale: Scale = useSelector(
    (state: RootState) => state.sales.scale,
    shallowEqual
  );
  const selectedShipmentPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter,
    shallowEqual
  );
  const selectedShipmentPrinter4x8: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter4x8,
    shallowEqual
  );
  const selectedShipmentPrinterCoton: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinterCoton,
    shallowEqual
  );
  const printerList: Printer[] = useSelector(
    (state: RootState) => state.sales.printerList,
    shallowEqual
  );
  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );
  const testPrintMsg: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.testPrintMessage,
    shallowEqual
  );
  const useScale: boolean = React.useMemo(() => {
    return !!(selectedScale && selectedScale.scaleNum > 0);
  }, [selectedScale]);

  const handleKeydown = (evt: any) => {
    if (evt.target.tagName.toLowerCase() !== 'input') {
      if (evt.keyCode === 27) {
        if (unlockButton?.current) {
          unlockButton?.current.click();
        }
      }
    }
  };

  const onCancel = () => {
    setLabelPrinterReady(false);
    props.close();
  };

  const onSave = () => {
    const scaleId = selectedScale.scaleNum || 0;
    const shipmentPrinterId = selectedShipmentPrinter.printerNum || 0;
    const shipmentPrinter4x8Id = selectedShipmentPrinter4x8.printerNum || 0;
    const shipmentPrinterCotonId = selectedShipmentPrinterCoton.printerNum || 0;
    const packingPrinterId = selectedPackingPrinter.printerNum || 0;
    const packingPrinter2Id = selectedPackingPrinter2.printerNum || 0;

    if (scaleId > 0) {
      localStorage.setItem(LS_SCALE_KEY, `${scaleId}`);
    } else {
      localStorage.removeItem(LS_SCALE_KEY);
    }

    if (shipmentPrinterId > 0) {
      localStorage.setItem(LS_SHIPMENT_PRINTER_KEY, `${shipmentPrinterId}`);
    } else {
      localStorage.removeItem(LS_SHIPMENT_PRINTER_KEY);
    }

    if (shipmentPrinter4x8Id > 0) {
      localStorage.setItem(LS_SHIPMENT_PRINTER_4x8_KEY, `${shipmentPrinter4x8Id}`);
    } else {
      localStorage.removeItem(LS_SHIPMENT_PRINTER_4x8_KEY);
    }

    if (shipmentPrinterCotonId > 0) {
      localStorage.setItem(LS_SHIPMENT_PRINTER_COTON_KEY, `${shipmentPrinterCotonId}`);
    } else {
      localStorage.removeItem(LS_SHIPMENT_PRINTER_COTON_KEY);
    }

    if (packingPrinterId > 0) {
      localStorage.setItem(LS_PACKING_PRINTER_KEY, `${packingPrinterId}`);
    } else {
      localStorage.removeItem(LS_PACKING_PRINTER_KEY);
    }

    if (packingPrinter2Id > 0) {
      localStorage.setItem(LS_PACKING_PRINTER2_KEY, `${packingPrinter2Id}`);
    } else {
      localStorage.removeItem(LS_PACKING_PRINTER2_KEY);
    }

    message.success(i18n.t('scanToShip.device.successMsg') || '');
    props.close();
  };

  const onSelectPackingPrinter = (printer: Printer) => {
    dispatch(setPackingPrinter(printer));
    setSlipPrinterReady(false);
  };

  const onSelectPackingPrinter2 = (printer: Printer) => {
    dispatch(setPackingPrinter2(printer));
    setSlipPrinter2Ready(false);
  };

  const onSelectScale = (scale: Scale) => {
    dispatch(setScale(scale));
  };

  const onSelectShipmentPrinter = (printer: Printer) => {
    dispatch(setShipmentPrinter(printer));
    setLabelPrinterReady(false);
  };

  const onSelectShipmentPrinter4x8 = (printer: Printer) => {
    dispatch(setShipmentPrinter4x8(printer));
    setLabelPrinterReady(false);
  };

  const onSelectShipmentPrinterCoton = (printer: Printer) => {
    dispatch(setShipmentPrinterCoton(printer));
    setLabelPrinterReady(false);
  };

  const onSelectWarehouse = (id: number, wh: StringKAnyVPair) => {
    if (id > 0) {
      localStorage.setItem(LS_DEFAULT_WAREHOUSE_KEY, `${id}`);
      //window.location.reload();
      userProfiles.saveCurrentProfileToBrowser();
      history.go(0);
    }
  };

  const refreshPrinterList = async () => {
    setLockScreen(true);

    try {
      await loadPrinterList(dispatch);
      setLockScreen(false);
    } catch (e) {
      setLockScreen(false);
      message.error(`Load printer list error: ${e}`);
    }
  };

  /**
   * Test the label printer.
   */
  const testCotonPrinter = () => {
    const printer = selectedShipmentPrinterCoton;
    const profile = userProfiles.profile;
    const param = {
      type: 0,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      PrinterName: printer.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };
    dispatch(testPrinter(param));
    setLabelPrinterCotonReady(false);
    setLockScreen(true);
  };

  /**
   * Test the label printer.
   */
  const testLabelPrinter = () => {
    const printer = selectedShipmentPrinter;
    const profile = userProfiles.profile;
    const param = {
      type: 0,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      PrinterName: printer.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };
    dispatch(testPrinter(param));
    setLabelPrinterReady(false);
    setLockScreen(true);
  };

  /**
   * Test the label printer.
   */
  const testLabelPrinter4x8 = () => {
    const printer = selectedShipmentPrinter4x8;
    const profile = userProfiles.profile;
    const param = {
      type: 0,
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      PrinterName: printer.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };
    dispatch(testPrinter(param));
    setLabelPrinter4x8Ready(false);
    setLockScreen(true);
  };

  const testPackingPrinter = () => {
    if (selectedPackingPrinter2.printerNum) {
      testSlipPrinter(selectedPackingPrinter);
      setSlipPrinterReady(false);
    } else {
      message.error('Pleae select a printer.');
    }
  };

  const testPackingPrinter2 = () => {
    if (selectedPackingPrinter2.printerNum) {
      testSlipPrinter(selectedPackingPrinter2);
      setSlipPrinter2Ready(false);
    } else {
      message.error('Please select a printer.');
    }
  };

  /**
   * Test the packing slip printer.
   */
  const testSlipPrinter = (printer: StringKAnyVPair) => {
    //console.log(selectedPackingPrinter);
    //const printer = selectedPackingPrinter;
    const profile = userProfiles.profile;
    const param = {
      PrinterNum: printer.printerNum,
      PrinterAccountNum: printer.printerAccountNum,
      PrinterName: printer.printerName,
      MasterAccountNum: profile.masterAccountNum,
      ProfileNum: profile.profileNum,
    };
    dispatch(testPrinter(param));
    //setSlipPrinterReady(false);
    setLockScreen(true);
  };

  React.useEffect(() => {
    if (!inited) {
      document.addEventListener('keydown', handleKeydown, false);
      //keydownHandler = handleKeydown;
      setInited(true);
    }

    const types = [PRINT_TYPE_TEST_LABEL];

    //console.log('response', types, printLabelResponse);
    if (types.indexOf(printLabelResponse.type) > -1) {
      dispatch(setPrintLabelResponse({}));
      setTimeout(() => {
        setLockScreen(false);
      }, PRINT_MESSAGE_RESPONSE_TIMEOUT);

      if (printLabelResponse.type === PRINT_TYPE_TEST_LABEL) {
        if (printLabelResponse.result !== PRINT_MESSAGE_TYPE_ERROR) {
          setTimeout(() => {
            const printerId = printLabelResponse.id;

            if (printerId === selectedShipmentPrinter.printerNum) {
              setLabelPrinterReady(true);
            }

            if (printerId === selectedShipmentPrinter4x8.printerNum) {
              setLabelPrinter4x8Ready(true);
            }

            if (printerId === selectedShipmentPrinterCoton.printerNum) {
              setLabelPrinterCotonReady(true);
            }

            if (printerId === selectedPackingPrinter.printerNum) {
              setSlipPrinterReady(true);
            }

            if (printerId === selectedPackingPrinter2.printerNum) {
              setSlipPrinter2Ready(true);
            }
            /*switch(printLabelResponse.id) {
              case selectedShipmentPrinter.printerNum:
                setLabelPrinterReady(true);
                break;

              case selectedPackingPrinter.printerNum:
                setSlipPrinterReady(true);
                break;

              case selectedPackingPrinter2.printerNum:
                setSlipPrinter2Ready(true);
                break;
            }*/
          }, PRINT_MESSAGE_RESPONSE_TIMEOUT);
        }
      }
    }

    const msg = { ...testPrintMsg };

    if (msg.printerNum > 0) {
      if (msg.type === 0) {
        setLabelPrinterReady(true);
      }
    }

    dispatch(setTestPrintMessage({}));
  }, [
    dispatch,
    inited,
    printLabelResponse,
    selectedPackingPrinter,
    selectedPackingPrinter2,
    selectedShipmentPrinter,
    selectedShipmentPrinter4x8,
    selectedShipmentPrinterCoton,
    testPrintMsg,
  ]);

  return (
    <>
      <Modal
        bodyStyle={deviceSettingDialogBodyStyle}
        centered
        cancelButtonProps={{ style: { display: props.closable ? '' : 'none' } }}
        closable={props.closable || false}
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        okText={
          <>
            <SaveOutlined /> <Trans i18nKey="common.save" />
          </>
        }
        okButtonProps={{ style: okBtnStyle }}
        onCancel={onCancel}
        onOk={onSave}
        title={<Label theme={{ fontSize: '20px' }}>{title}</Label>}
        visible={props.visible}
        width={800}
      >
        <Col ref={shell}>
          {warehouseNum < 1 && (
            <>
              <Alert
                type="warning"
                message={
                  <Label theme={{ fontSize: '20px' }}>
                    Please select the warehouse.
                  </Label>
                }
              />
              <FormVSpacer />
              <FormVSpacer />
              <SectionWrapper>
                <DeviceSettingScaleRow align="middle" justify="space-between">
                  <FormLabel>Warehouse</FormLabel>
                  <WarehouseSelector
                    size="large"
                    onChange={onSelectWarehouse}
                  />
                </DeviceSettingScaleRow>
              </SectionWrapper>
              <FormVSpacer />
              <FormVSpacer />
            </>
          )}
          {warehouseNum > 0 && (
            <>
              {printerList.length > 0 ? (
                <DeviceSettingAlertRow justify="space-between">
                  <Alert
                    type="warning"
                    message={
                      <Label theme={{ fontSize: '20px' }}>
                        {i18n.t('scanToShip.device.choose.tips')}
                      </Label>
                    }
                  />
                  <Button
                    className="refresh-btn"
                    onClick={refreshPrinterList}
                  >
                    <ReloadOutlined />
                  </Button>
                </DeviceSettingAlertRow>
              ) : (
                <DeviceSettingAlertRow justify="space-between">
                  <Alert
                    className="msg-box-no-printer"
                    message={
                      <Label theme={{ fontSize: '20px' }}>
                        Please use Print Station to connect a working printer.
                      </Label>
                    }
                    type="error"
                  />
                  <Button
                    className="refresh-btn"
                    onClick={refreshPrinterList}
                  >
                    <ReloadOutlined />
                  </Button>
                </DeviceSettingAlertRow>
              )}
              <FormVSpacer />
              <FormVSpacer />
              <DeviceSettingTabs defaultActiveKey="1" type="card">
                <Tabs.TabPane tab={i18n.t('scanToShip.device.tab.general') || ''} key="1">
                  <SectionWrapper>
                    <DeviceSettingPrinterRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.shippingLabel') || ''}</FormLabel>
                      <PrinterSelector
                        onChange={onSelectShipmentPrinter}
                        placeholder={i18n.t('scanToShip.device.select.printer', { usage: i18n.t('scanToShip.device.label.shippingLabel') || '' }) || ''}
                        size="large"
                        value={selectedShipmentPrinter.printerNum || 0}
                      />
                      <HoverBgButton
                        hovertype="info"
                        size="large"
                        onClick={testLabelPrinter}
                      >
                        <PlayCircleOutlined />
                        <Trans i18nKey="common.test" />
                      </HoverBgButton>
                    </DeviceSettingPrinterRow>
                    {labelPrinterReady && (
                      <DeviceSettingPrinterRow>
                        <Alert
                          type="success"
                          onClick={() => setLabelPrinterReady(false)}
                          message={
                            <Label theme={{ fontSize: '20px' }}>
                              Shipping Label Printer{' '}
                              {selectedShipmentPrinter.printerName} is Ready.
                            </Label>
                          }
                        />
                      </DeviceSettingPrinterRow>
                    )}
                    <FormVSpacer />
                    <DeviceSettingPrinterRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.packingSlip') || ''}</FormLabel>
                      <PrinterSelector
                        onChange={onSelectPackingPrinter}
                        placeholder={i18n.t('scanToShip.device.select.printer', { usage: i18n.t('scanToShip.device.label.packingSlip') || '' }) || ''}
                        size="large"
                        value={selectedPackingPrinter.printerNum || 0}
                      />
                      <HoverBgButton
                        hovertype="info"
                        size="large"
                        onClick={testPackingPrinter}
                      >
                        <PlayCircleOutlined />
                        <Trans i18nKey="common.test" />
                      </HoverBgButton>
                    </DeviceSettingPrinterRow>
                    {slipPrinterReady && (
                      <DeviceSettingPrinterRow>
                        <Alert
                          type="success"
                          message={
                            <Label theme={{ fontSize: '20px' }}>
                              Packing Slip Printer{' '}
                              {selectedPackingPrinter.printerName} is Ready.
                            </Label>
                          }
                        />
                      </DeviceSettingPrinterRow>
                    )}
                    <FormVSpacer />
                    <DeviceSettingPrinterRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.subPackingSlip') || ''}</FormLabel>
                      <PrinterSelector
                        onChange={onSelectPackingPrinter2}
                        placeholder={i18n.t('scanToShip.device.select.printer', { usage: i18n.t('scanToShip.device.label.subPackingSlip') || '' }) || ''}
                        size="large"
                        value={selectedPackingPrinter2.printerNum || 0}
                      />
                      <HoverBgButton
                        hovertype="info"
                        size="large"
                        onClick={testPackingPrinter2}
                      >
                        <PlayCircleOutlined />
                        <Trans i18nKey="common.test" />
                      </HoverBgButton>
                    </DeviceSettingPrinterRow>
                    {slipPrinter2Ready && (
                      <DeviceSettingPrinterRow>
                        <Alert
                          type="success"
                          message={
                            <Label theme={{ fontSize: '20px' }}>
                              Packing Slip Printer(4'' * 6''){' '}
                              {selectedPackingPrinter2.printerName} is Ready.
                            </Label>
                          }
                        />
                      </DeviceSettingPrinterRow>
                    )}
                  </SectionWrapper>
                  <FormVSpacer />
                  <FormVSpacer />
                  <SectionWrapper>
                    <DeviceSettingScaleRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.scale') || ''}</FormLabel>
                      <ScaleSelector
                        size="large"
                        onChange={onSelectScale}
                        value={selectedScale.scaleNum || 0}
                      />
                    </DeviceSettingScaleRow>
                    {useScale ? (
                      <>
                        <FormVSpacer />
                        <WeightBoard />
                      </>
                    ) : (
                      ''
                    )}
                  </SectionWrapper>
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t('scanToShip.device.tab.optional') || ''} key="2">
                  <SectionWrapper>
                    <DeviceSettingPrinterRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.subShippingLabel') || ''}</FormLabel>
                      <PrinterSelector
                        onChange={onSelectShipmentPrinter4x8}
                        placeholder={i18n.t('scanToShip.device.select.printer', { usage: i18n.t('scanToShip.device.label.subShippingLabel') || '' }) || ''}
                        size="large"
                        value={selectedShipmentPrinter4x8.printerNum || 0}
                      />
                      <HoverBgButton
                        hovertype="info"
                        size="large"
                        onClick={testLabelPrinter4x8}
                      >
                        <PlayCircleOutlined />
                        <Trans i18nKey="common.test" />
                      </HoverBgButton>
                    </DeviceSettingPrinterRow>
                    {labelPrinter4x8Ready && (
                      <DeviceSettingPrinterRow>
                        <Alert
                          type="success"
                          onClick={() => setLabelPrinter4x8Ready(false)}
                          message={
                            <Label theme={{ fontSize: '20px' }}>
                              Shipping Label(4'' * 8'') Printer{' '}
                              {selectedShipmentPrinter4x8.printerName} is Ready.
                            </Label>
                          }
                        />
                      </DeviceSettingPrinterRow>
                    )}
                    <FormVSpacer />
                    <DeviceSettingPrinterRow align="middle" justify="space-between">
                      <FormLabel>{i18n.t('scanToShip.device.label.cartonLabel') || ''}</FormLabel>
                      <PrinterSelector
                        onChange={onSelectShipmentPrinterCoton}
                        placeholder={i18n.t('scanToShip.device.select.printer', { usage: i18n.t('scanToShip.device.label.cartonLabel') || '' }) || ''}
                        size="large"
                        value={selectedShipmentPrinterCoton.printerNum || 0}
                      />
                      <HoverBgButton
                        hovertype="info"
                        size="large"
                        onClick={testCotonPrinter}
                      >
                        <PlayCircleOutlined />
                        <Trans i18nKey="common.test" />
                      </HoverBgButton>
                    </DeviceSettingPrinterRow>
                    {labelPrinterCotonReady && (
                      <DeviceSettingPrinterRow>
                        <Alert
                          type="success"
                          onClick={() => setLabelPrinterCotonReady(false)}
                          message={
                            <Label theme={{ fontSize: '20px' }}>
                              Coton Label Printer{' '}
                              {selectedShipmentPrinterCoton.printerName} is Ready.
                            </Label>
                          }
                        />
                      </DeviceSettingPrinterRow>
                    )}
                    <FormVSpacer />
                  </SectionWrapper>
                </Tabs.TabPane>
              </DeviceSettingTabs>
            </>
          )}
        </Col>
      </Modal>
      {lockScreen && (
        <ScreenMask>
          <TimerLock>
            <Row align="middle" justify="center" style={{ height: '100%' }}>
              <MaskMessageBox>
                <Row className="message-box">
                  <span>No response for a long time</span>
                </Row>
                <Row className="button-box" justify="center">
                  <Button
                    onClick={() => setLockScreen(false)}
                    ref={unlockButton}
                    size="large"
                    type="primary"
                  >
                    Continue ({isMacos() ? 'esc' : 'Esc'})
                  </Button>
                </Row>
              </MaskMessageBox>
            </Row>
          </TimerLock>
        </ScreenMask>
      )}
    </>
  );
};

export default DeviceSettingDialog;
