import { Space } from 'antd';
import React from 'react';
import { ModalPlus } from 'components/common';
import ShipmentAddress from './ShipmentAddress';

type ShipmentDetailDialogProps = {
  shipment: OrderShipment;
  onChangeShipment: (shipment: OrderShipment) => void;
  onClose: () => void;
};
export default function ShipmentDetailDialog(
  props: ShipmentDetailDialogProps
): JSX.Element {
  const { onClose, onChangeShipment, shipment } = props;
  /**
   * Dialog Title
   * @returns {JSX.Element}
   */
  const DialogTitle = (): JSX.Element => {
    return (
      <Space>
        <span>View Buyer/Recipient Information</span>
      </Space>
    );
  };
  return (
    <>
      <ModalPlus
        centered
        destroyOnClose
        maskClosable={false}
        onCancel={() => {
          onClose();
        }}
        title={<DialogTitle />}
        visible
        width={600}
        footer={null}
      >
        <ShipmentAddress
          onChangeShipment={onChangeShipment}
          shipment={shipment}
        />
      </ModalPlus>
    </>
  );
}
