import React, { useEffect, useState } from 'react';
import { FormLabel } from 'components/common';
import {
  Card,
  Tabs,
  Form,
  Typography,
  Button,
  message,
  Result,
  Row,
} from 'antd';
// import { fetchSettings } from 'services/userProfileSetting';
import {
  fetchSettings,
  fetchChannelAccountSettings,
  saveChannelAccountSettings,
} from 'services/storeSetup';
// import { userProfiles } from 'utils/auth';
import { isJsonString } from 'utils';
import moment from 'moment';
import uiTypeRender from 'utils/uiTypeRender';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

interface ChannelAccountSettingsProps {
  // channelNum: number;
  channelAccountNum: number;
}

// eslint-disable-next-line
export default (props: ChannelAccountSettingsProps) => {
  const { channelAccountNum } = props;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [settings, setSettings] = useState<any[]>([]);
  const [userSetting, setUserSetting] = useState<{ [key: string]: any }>({});
  const [activeTab, setActiveTab] = useState<string>();

  const [form] = Form.useForm();

  const getSettings = async () => {
    try {
      setLoading(true);
      const res = await fetchSettings(0); // not channelNum
      setLoading(false);
      if (res.isSuccess) {
        setSettings(res.data);
        if (!activeTab && res.data.length > 0) {
          setActiveTab(res.data[0].category);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUserSettings = async () => {
    try {
      setLoading1(true);
      const res = await fetchChannelAccountSettings(channelAccountNum);
      setLoading1(false);
      if (res.isSuccess) {
        const temp: { [key: string]: any } = {};
        res.data.map((item: any) => {
          temp[item.settingCode] = item.settingValue;
          return true;
        });
        setUserSetting({ ...temp, isReady: true });
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const saveSettings = async () => {
    // const { masterAccountNum, profileNum } = userProfiles.profile;
    const params = await form.validateFields();
    const postParams = [];
    for (let i in params) {
      postParams.push({
        SettingCode: i,
        SettingValue: params[i],
        ChannelAccountNum: channelAccountNum,
        // MasterAccountNum: masterAccountNum,
        // ProfileNum: profileNum,
      });
    }
    try {
      setSaveLoading(true);
      const res = await saveChannelAccountSettings(
        channelAccountNum,
        postParams
      );
      setSaveLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Saved Successfully' });
        getUserSettings();
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    getSettings();
    getUserSettings();
  }, []);
  /* eslint-enable */
  return (
    <>
      <Row justify="end">
        <Button
          type="primary"
          loading={saveLoading}
          disabled={settings.length === 0}
          onClick={() => saveSettings()}
        >
          Save
        </Button>
      </Row>

      <Form
        form={form}
        labelCol={{
          style: { width: 300, display: 'flex', justifyContent: 'start' },
        }}
        wrapperCol={{
          style: { width: 'calc(100% - 300px)' },
        }}
      >
        <Card
          style={{ width: '100%', marginTop: 16, minHeight: 500 }}
          loading={loading || loading1}
          bodyStyle={{ padding: 8 }}
        >
          {settings.length > 0 ? (
            userSetting &&
            userSetting.isReady &&
            activeTab && (
              <Tabs
                activeKey={activeTab}
                size="middle"
                type="card"
                onChange={(value) => setActiveTab(value)}
              >
                {settings.map((i: any) => (
                  <TabPane tab={i.category} key={i.category}>
                    {i.scopes.map((m: any, idx2: number) => (
                      <Card
                        key={`${m.scope}${idx2}`}
                        size="small"
                        bordered={false}
                        style={{ marginBottom: 10 }}
                        title={<Title level={5}>{m.scope}</Title>}
                      >
                        {m.scopeItems.map((f: any, index: number) => {
                          return !f.isVisibleByCustomer ? null : (
                            <div key={`${f.settingCode}${index}`}>
                              <Form.Item
                                name={f.settingCode}
                                initialValue={
                                  f.settingDefUI.uiType === 3 ||
                                  f.settingDefUI.uiType === 11
                                    ? moment(userSetting[f.settingCode])
                                    : f.settingDefUI.uiType === 7
                                    ? isJsonString(userSetting[f.settingCode])
                                      ? JSON.parse(userSetting[f.settingCode])
                                      : []
                                    : f.settingDefUI.uiType === 12
                                    ? undefined
                                    : userSetting[f.settingCode]
                                }
                                label={
                                  <FormLabel noColon>{f.settingName}</FormLabel>
                                }
                                rules={[
                                  {
                                    required:
                                      f.settingDefUI.uiType === 12 &&
                                      userSetting[f.settingCode]
                                        ? false
                                        : true,
                                    message: `${f.settingName} is required`,
                                  },
                                ]}
                              >
                                {uiTypeRender(
                                  f.settingDefUI,
                                  f.canBeModifiedByCustomer
                                )}
                              </Form.Item>
                              {f.note && (
                                <Text
                                  style={{ marginLeft: 20 }}
                                  type="secondary"
                                >
                                  {f.note}
                                </Text>
                              )}
                            </div>
                          );
                        })}
                      </Card>
                    ))}
                  </TabPane>
                ))}
              </Tabs>
            )
          ) : (
            <Result
              status="error"
              title="Failed to extract configuration data."
              subTitle="Please contact the system administrator."
            ></Result>
          )}
        </Card>
      </Form>
    </>
  );
};
