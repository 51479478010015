import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import PrinterManage from 'components/Admin/PrinterManage';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <PrinterManage />
      </SiteContent>
    </ContentLayout>
  );
};
