import React, { useCallback, useEffect, useState } from "react";
import { Modal, Row, Space, Button, Form, Typography, Select, message, Spin } from "antd";
import { WarningIcon } from "components/common/styles";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { cancelShipment } from "services/shipment";
import { getCancelReasons } from "services/orders";

type CancelShipmentDialogProps = {
  shipmentInfo: any;
  visible: boolean;
  onClose: Function;
  refreshShipment: Function;
}
const CancelShipmentDialog = (props: CancelShipmentDialogProps) => {
  const { shipmentInfo, visible, onClose, refreshShipment } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [reason, setReason] = useState<string>('');
  const [reasonList, setReasonList] = useState<any>([]);

  const [cancelForm] = Form.useForm();

  const handleClose = () => {
    onClose();
  }

  const handleCancelShipment = async () => {
    const params = await cancelForm.validateFields();
    if (params.errorFields) return;
    setLoading(true);

    try {
      const res = await cancelShipment(
        shipmentInfo?.shipmentNum,
        // reason.current
        reason
      );
      handleClose();
      setReason('');
      if (res.isSuccess) {
        message.success({ content: 'Cancel successfully' });
        //refresh
        refreshShipment();
      }
    } catch (error) {
      setReason('');
    } finally {
      setLoading(false);
    }
  };

  const fetchReasons = useCallback(async () => {
    if (reasonList.length > 0) return;
    try {
      const res = await getCancelReasons();
      if (res.isSuccess) {
        setReasonList(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [reasonList]);

  useEffect(() => {
    fetchReasons();
    // eslint-disable-next-line
  }, [])

  return (

    <Modal
      title={
        <>
          <WarningIcon /> Are you sure want to cancel shipment?
        </>
      }
      centered
      visible={visible}
      onCancel={handleClose}
      maskClosable={false}
      destroyOnClose={true}
      footer={
        <Row justify="end">
          <Space>
            <Button
              onClick={handleClose}
              icon={<CloseOutlined />}
            >
              No
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={handleCancelShipment}
              icon={<CheckOutlined />}
            >
              Yes
            </Button>
          </Space>
        </Row>
      }
      width={500}
    >
      <Spin spinning={loading}>
        <Form form={cancelForm} style={{ width: 300 }}>
          <Row style={{ marginBottom: 16 }}>
            <Typography.Text type="secondary">
              This operation may not be restored
            </Typography.Text>
          </Row>
          <Form.Item
            name="cancelReasonType"
            rules={[{ required: true, message: 'Please select a reason' }]}
            label={<></>}
          >
            <Select
              id="reason_selector"
              onChange={(value) => {
                ;
                setReason(value);
              }}
              placeholder="Please select a reason"
              value={reason}
            >
              {reasonList.map((item: any) => (
                <Select.Option key={item.settingCode} value={item.settingCode}>
                  {item.settingName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default CancelShipmentDialog;