import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, ColumnDataType } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import { fetchTransferLines } from 'services/inventory';
import { getWarehouseIdFromCache } from 'utils';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  transfer: any;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, transfer } = props;
  const [loading, setLoding] = useState(false);
  const [data, setData] = useState([]);
  // const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  // const [selected, setSelected] = React.useState<any>({});

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const getData = useCallback(async () => {
    try {
      setLoding(true);
      const res = await fetchTransferLines(
        getWarehouseIdFromCache(transfer.warehouseNum),
        transfer.transferNum
      );
      setData(res);
      setLoding(false);
    } catch (error) {
      setLoding(false);
    }
  }, [transfer]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      showColumnMenuTool: true,
      minWidth: 220,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'title',
      header: 'Product Name',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'transferQty',
      header: 'Transfer Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Receive Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Receive Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      name: 'createBy',
      header: 'Receive By ',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  useEffect(() => {
    if (transfer) {
      getData();
    }
  }, [getData, transfer]);

  return (
    <Modal
      title={`Detail - ${transfer.batchNumber}`}
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button type="primary" onClick={handleHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        loading={loading}
        dataSource={data}
        idProperty="fulfillmentTransferLineNum"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
