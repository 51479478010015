/**
 * The warehouse select box. It provides selection of warehouse code.
 */
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Row, Select, Spin } from 'antd';
import { LoadingIcon } from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { fetchWarehouseList } from 'services/warehouse';
import { RootState } from 'reducers';

/**
 * The type definition for warehouse select option.
 */
type WarehouseOption = {
  id: number;
  label: string;
  value: string;
  warehouseType: number;
};

/**
 * The type definition for the component.
 *
 * @param {?GeneralSizeType} size
 * @param {?boolean} selectAll - whether can select all warehouses
 * @param {?Function} onChange - the onChange handler
 * @param {?number} - value - the value of the select box
 */
type Props = {
  disabled?: boolean;
  selectAll?: boolean;
  size?: GeneralSizeType;
  onChange?: Function;
  value?: number;
  onlyNormal?: boolean;
  style?: any;
};

// eslint-disable-next-line
export default (props: Props) => {
  const {
    onChange,
    size = 'middle',
    selectAll,
    onlyNormal = true,
    value,
    style
  } = props;
  const [inited, setInited] = React.useState(false);
  const [isLoadingWarehouseList, setIsLoadingWarehouseList] =
    React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [warehouseOptions, setWarehouseOptions] = React.useState<
    WarehouseOption[]
  >([]);
  const warehouseCacheList: WarehouseRow[] = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const databaseNum = useSelector(
    (state: RootState) => state.admin.databaseNum,
    shallowEqual
  )

  /**
   * Handle warehouse selection.
   * The related state(selectedWarehouseIds) will be updated.
   * @param {number|Array<number>} If the warehouse is an array, it indicates that you can select more than one warehouse at a time.
   */
  // eslint-disable-next-line
  const handleWarehouseCodeChange = React.useCallback(
    (value: number | Array<number>) => {
      // setSelectedValue(value > 0 ? value : undefined);
      setSelectedValue(value)
      // if ('function' === typeof onChange) {
      // for (let i = 0; i < warehouseOptions.length; i++) {
      //   const val = parseInt(warehouseOptions[i].value);

      //   if (val && val === value) {
      //     opt = JSON.parse(JSON.stringify(warehouseOptions[i]));
      //     opt.value = val;
      //     break;
      //   }
      // }
      const temp = warehouseOptions.filter((i) => i.id === value);
      const opt = temp.length > 0 ? temp[0] : {};

      onChange && onChange(value, opt);
      // }
    },
    [onChange, warehouseOptions]
  );

  const getWarehouseList = React.useCallback(async () => {
    try {
      const list = await fetchWarehouseList();
      setIsLoadingWarehouseList(false);

      if (list && Array.isArray(list)) {
        let rawWarehouseList = list.map((e) => {
          return {
            id: parseInt(e.warehouseNum),
            label: e.warehouseCode,
            value: e.warehouseNum + '',
            warehouseType: e.warehouseType,
            warehouseId: e.warehouseId,
          };
        })

        if (selectAll) {
          rawWarehouseList = [
            {
              id: 0,
              label: 'All',
              value: 'All',
              warehouseType: 1,
              warehouseId: `${databaseNum}-0`,
            },
            ...rawWarehouseList.sort((a, b) => a.label.localeCompare(b.label)),
          ];
        }

        setWarehouseOptions(rawWarehouseList);
      }
    } catch (e) {
      console.log('err', e);
    }
  }, [selectAll, databaseNum]);

  // eslint-disable-next-line
  const setWarehouseOptionsFromCache = () => {
    let rawWarehouseList = warehouseCacheList.map((e) => {
      return {
        id: e.id,
        label: e.code,
        value: e.id + '',
        warehouseType: e.warehouseType || 0,
        warehouseId: e.warehouseId,
      };
    });

    if (selectAll) {
      rawWarehouseList = [
        {
          id: 0,
          label: 'All',
          value: 'All',
          warehouseType: 1,
          warehouseId: `${databaseNum}-0`,
        },
        ...rawWarehouseList.sort((a, b) => a.label.localeCompare(b.label)),
      ];
    }

    setWarehouseOptions(rawWarehouseList);
  };

  /**
   * Map the WarehouseOption Array into warehouse code select options.
   * Note: the label property of the option is needed.
   */
  const warehouseCodeSelectOptions = (options: WarehouseOption[]) => {
    return onlyNormal
      ? warehouseOptions
        .filter((i) => (i.warehouseType === 1 || i.warehouseType === 5))
        .map((e) => (
          <Select.Option key={e.id} label={e.label} value={e.id}>
            {e.label}
          </Select.Option>
        ))
      : warehouseOptions.map((e) => (
        <Select.Option key={e.id} label={e.label} value={e.id}>
          {e.label}
        </Select.Option>
      ));
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (!inited) {
      if (warehouseCacheList.length > 0) {
        setWarehouseOptionsFromCache();
        setIsLoadingWarehouseList(false);
      } else {
        getWarehouseList();
      }
      setInited(true);
    }
    if (warehouseOptions.length < 1) return;
  }, [
    inited,
    warehouseCacheList,
    getWarehouseList,
    setWarehouseOptionsFromCache,
    warehouseOptions,
  ]);

  React.useEffect(() => {
    if (warehouseOptions.length < 1) return;
    if (!inited) return;
    // if ('number' === typeof value && value !== selectedValue) {
    if (value) {
      if (value !== selectedValue) {
        handleWarehouseCodeChange(value);
      }
    }

    if (!value) {
      if(selectAll){
        handleWarehouseCodeChange(0);
      } else {
        const whid = parseInt(
          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
        );
        if (whid > 0 && whid !== selectedValue) {
          handleWarehouseCodeChange(whid);
        }
      }
    }
  }, [
    // handleWarehouseCodeChange,
    // inited,
    // value,
    // selectedValue,
    warehouseOptions,
    // onChange,
  ]);
  /* eslint-enable */

  return (
    <Row align="middle" style={style ? style : { height: '100%' }}>
      {isLoadingWarehouseList ? (
        <Spin indicator={<LoadingIcon />} />
      ) : (
        <Select
          disabled={props.disabled}
          showSearch
          filterOption={(inputValue, option) => {
            const search = inputValue.trim().toLowerCase();
            let label = '';

            if (option && 'object' == typeof option) {
              if ('string' === typeof option.label) {
                label = option.label.toLowerCase();
              }
            }

            return !!label && label.indexOf(search) > -1;
          }}
          onChange={handleWarehouseCodeChange}
          placeholder="Select a Warehouse Code"
          size={size}
          style={{ width: '100%' }}
          value={selectedValue}
        >
          {warehouseCodeSelectOptions(warehouseOptions)}
        </Select>
      )}
    </Row>
  );
};
