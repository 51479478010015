/**
 * ship service requests are here.
 */
import { HTTP_STATUS_OK } from 'constants/config';
import { digitBridgeApi } from './http';

/**
 * Get all ship account services.
 */
export const fetchAllShipAccountServices = async (): Promise<any> => {
  let ret = [];
  const res = await digitBridgeApi.get('/api/GetAllShipAccountServices');

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      if (data && Array.isArray(data)) {
        ret = data;
      }
    }
  }

  return ret;
};

/**
 * Get all ship account list
 */
export const fetchAllShipAccountList = async (): Promise<StringKAnyVPair[]> => {
  const res = await digitBridgeApi.get(
    '/api/GetShipAccountList'
  );
  let ret: StringKAnyVPair[] = [];

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      if (data && Array.isArray(data)) {
        ret = [...data];
      }
    }
  }

  return ret;
};

/**
 * Get mapped ship account services.
 */
export const fetchMappedShipAccountServicesByCarrier = async (
  carrierNum: number,
): Promise<any> => {
  let ret = [];
  const params = { carrierNum };
  const res = await digitBridgeApi.get('/api/GetAllShipAccountServices', { params });

  if (res && 'object' === typeof res) {
    const { code, data } = res as StringKAnyVPair;

    if (code === HTTP_STATUS_OK) {
      if (data && Array.isArray(data)) {
        ret = data;
      }
    }
  }

  return ret;
};

/**
* ship Account list
* @param {number} warehouseId
*/
export const fetchShipAccountList = async (
  carrier: string
): Promise<any> => {
  const params = { carrier };
  return await digitBridgeApi.get(
    '/api/GetShipAccountList', { params }
  )
};

/**
 * create ship account
 */
type FedexAccountInfo = {
  Account: string,
  Password: string,
  MeterNum: string,
  Key: string,
  HubId: string
}
type UPSAccountInfo = {
  LicenseNumber: string,
  UserId: string,
  Password: string,
  ShipperNumber: string,
  ServiceDescription: string
}
type USPSAccountInfo = {
  IntegrationID: string,
  Username: string,
  Password: string
}
type PurolatorInfo = {
  ClientID: string,
  Username: string,
  Password:string,
}
// type AddressDto = {
//   AddressNum: number,
//   Name: string,
//   Company: string,
//   Street1: string,
//   Street2: string,
//   Street3: string,
//   City: string,
//   County: string,
//   State: string,
//   Country: string,
//   PostalCode: string,
//   Phone: string,
//   Email: string,
//   Residential: number
// }
type CreateShipAccountParams = {
  ShipAccountNum: number;
  CarrierNum: number,
  Name: string,
  Status: number,
  ShipAccountType?: number,
  // WarehouseNum: number,
  WarehouseNums: any[],
  AccountUsage: number,
  FedexAccountInfo?: FedexAccountInfo,
  UPSAccountInfo?: UPSAccountInfo,
  USPSAccountInfo?: USPSAccountInfo,
  AddressNum?: number,
  NotToChannelAccount: number,
  PurolatorAccountInfo?: PurolatorInfo, 
  OthersAccountInfo?: any,
  ShipingProviderAccountInfo?: any;
  SandBox: number;
  Mode?: number;
  // AddressDto: AddressDto
}
export const SetShipAccount = async (params: CreateShipAccountParams): Promise<boolean> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/SetShipAccount', json);
}

/**
 * get ship account by id
 * @param id 
 * @returns 
 */
export const getShipAccountById = async (id: number): Promise<any> => {
  return await digitBridgeApi.get(`/api/shipAccounts/${id}`);
}

/**
 *  delete ship account
 * @param shipAccountNum 
 * @returns 
 */
export const deleteShipAccount = async (id: number): Promise<boolean> => {
  return await digitBridgeApi.delete(`/api/shipAccounts/${id}`);
}

/**
 * fetch Service List By ShipAccount
 */
export const fetchServiceListByShipAccount = async (shipAccountNum: number): Promise<any> => {
  const params = { shipAccountNum }
  return await digitBridgeApi.get('/api/GetServiceListByShipAccount', { params })
}

/**
 * fetch Service List By ShipAccount
 */
// export const fetchServiceListByWarehouse = async (shipAccountNum: number, warehouseNum: number): Promise<any> => {
//   const params = { shipAccountNum, warehouseNum }
//   return await digitBridgeApi.get('/api/GetServiceListByWarehouse', { params })
// }

/**
 * fetch Service List By ChannelAccount
 */
// export const fetchServiceListByChannelAccount = async (shipAccountNum: number, channelAccountNum: number): Promise<any> => {
//   const params = { shipAccountNum, channelAccountNum }
//   return await digitBridgeApi.get('/api/GetServiceListByChannelAccount', { params })
// }

/**
 *  set Service List By ShipAccount
 */
type UpdateServiceByShipAccount = {
  ShipAccountNum: number,
  Name: string,
  CarrierServiceNum: number,
  Status: number,
}
export const saveServiceListByShipAccount = async (params: UpdateServiceByShipAccount[]): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/UpdateServicesByShipAccount', json)
}

// type UpdateServiceByWarehouse = {
//   WarehouseNum: number,
//   WarehouseCode: string,
//   ShipAccountNum: number,
//   Carrier: string,
//   Service: string,
//   Primary: number,
//   Status: number,
// }
// export const saveServicesListByWarehouse = async (params: UpdateServiceByWarehouse[]): Promise<boolean> => {
//   const json = JSON.stringify(params);
//   return await digitBridgeApi.post('/api/UpdateServicesByWarehouse', json)
// }

export const getAddressById = async (id: number): Promise<any> => {
  return digitBridgeApi.get(`/api/shipmentAddress/${id}`)
}

export const getShipAccountByWarehouse = async (warehouseNum: number): Promise<any> => {
  const params = {
    warehouseNum
  }
  return digitBridgeApi.get('/api/GetShipAccountByWarehose', { params })
}

/**
 * Set default shipAccount for warehouse
 */
export const setDefaultShipAccount = async (params: { ShipAccountNum: number, WarehouseNum: number }): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/SetShipAccountDefaultWarehouse', json)
}

export const getShipAccountWithShoppingAround = async (warehouseNum: number, carrierNum: number, channelAccountNum: number): Promise<any> => {
  const params = {
    warehouseNum,
    carrierNum,
    channelAccountNum,
  }
  return digitBridgeApi.get('/api/GetShipAccountWithShoppingAround', { params })
}
