import React from 'react';
import NumberFormat from 'react-number-format';
import { v4 as uuid } from 'uuid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { convertTimeByUtc } from 'utils';
import {
  DATA_GRID_SORT_INDICATOR_MARGIN,
  IMAGE_HEIGHT_IN_GRID,
} from 'constants/config';
import CopySpan from '../CopySpan';

enum ColumnDataType {
  CURRENCY = 1,
  DATE = 2,
  IMAGE = 3,
}
/**
 * custom sort style
 * @param direction
 * @returns
 */
const SortIndicator = (direction: number | null) => {
  const iconStyle = {
    paddingLeft: DATA_GRID_SORT_INDICATOR_MARGIN,
    color: !direction ? '#C8C8C8' : '',
  };
  const indicatorIcon = () => {
    let icon = faSort;

    if ('number' === typeof direction) {
      if (1 === direction) {
        icon = faSortUp;
      } else if (-1 === direction) {
        icon = faSortDown;
      }
    }
    return icon as IconProp;
  };
  return (
    <span style={iconStyle} key={uuid()}>
      <FontAwesomeIcon icon={indicatorIcon()} />
    </span>
  );
};
/**
 * custom columns
 * @param {Array<StringKAnyVPair>} columns
 * @param {string} gridName
 * @returns {Array<StringKAnyVPair>}
 */
export default function Columns(
  columns: Array<StringKAnyVPair>,
  gridName: string
): Array<StringKAnyVPair> {
  //read localstore cache width
  // const cache = localStorage.getItem('dataGridWidth');
  // let columnWidths: Array<number> = [];
  // if (cache) {
  //   try {
  //     const cacheDataGridWidth = JSON.parse(cache) as any;
  //     columnWidths = cacheDataGridWidth[gridName] || [];
  //   } catch (e) {}
  // }
  //custom columns.include currency/date/image
  columns = columns.map((column: any, index) => {
    const newColumn = {
      sortable: true,
      resizable: true,
      draggable: true,

      //userSelect: true, // reserve the default actitude of the data grid
      ...column,
      header: column.header || column.title,
      name: column.name || column.dataIndex,
    } as any;
    if (!newColumn.header) {
      newColumn['sortable'] = false;
    }
    // customize the renderSortTool
    if (!column.renderSortTool) {
      newColumn.renderSortTool = (direction: number | null) => {
        return SortIndicator(direction);
      };
    }

    if (column.renderHeader && newColumn.sortable) {
      newColumn.renderHeader = (cellProps: any) => {
        const { computedSortInfo } = cellProps;
        let direction: number | null = null;
        if (computedSortInfo && 'object' === typeof computedSortInfo) {
          if ('number' === typeof computedSortInfo.dir) {
            direction = computedSortInfo.dir;
          }
        }
        return (
          <>
            {column.renderHeader(cellProps)}
            {SortIndicator(direction)}
          </>
        );
      };
    }
    if (column.render) {
      newColumn['render'] = (value: any): JSX.Element => {
        const { rowIndex, columnIndex } = value;
        return (
          <span id={`cell${gridName}${rowIndex}${columnIndex}`}>
            {column.render(value)}
          </span>
        );
      };
    } else {
      if (column.dataType === ColumnDataType.CURRENCY) {
        newColumn['render'] = (value: any): JSX.Element => {
          const { data, rowIndex, columnIndex } = value;
          const columnValue = data[newColumn.name];
          if (!columnValue) {
            return <span id={`cell${gridName}${rowIndex}${columnIndex}`} />;
          }
          return (
            <span id={`cell${gridName}${rowIndex}${columnIndex}`}>
              <NumberFormat
                displayType="text"
                value={columnValue}
                thousandSeparator
                prefix="$"
              />
            </span>
          );
        };
      } else if (column.dataType === ColumnDataType.DATE) {
        newColumn['render'] = (value: any): JSX.Element => {
          const { data, rowIndex, columnIndex } = value;
          const columnValue = data[newColumn.name];
          if (!columnValue) {
            return <span id={`cell${gridName}${rowIndex}${columnIndex}`} />;
          }

          if ((new Date(columnValue)).getTime() < (new Date('2000-01-01')).getTime()) {
            return (<></>);
          }

          return (
            // <span id={`cell${gridName}${rowIndex}${columnIndex}`}>
            //   <Text>
            //     {convertTimeByUtc(columnValue, '', '', column.format)}
            //   </Text>
            // </span>
            <CopySpan inGrid id={`cell${gridName}${rowIndex}${columnIndex}`} value={convertTimeByUtc(columnValue, '', '', column.format)} />
          );
        };
      } else if (column.dataType === ColumnDataType.IMAGE) {
        newColumn['render'] = (value: any): JSX.Element => {
          const { data, rowIndex, columnIndex } = value;
          const columnValue = data[newColumn.name];
          if (columnValue) {
            return (
              <span id={`cell${gridName}${rowIndex}${columnIndex}`}>
                <img
                  src={columnValue}
                  alt=""
                  style={{ height: IMAGE_HEIGHT_IN_GRID }}
                />
              </span>
            );
          }
          return <span id={`cell${gridName}${rowIndex}${columnIndex}`} />;
        };
      } else {
        newColumn['render'] = (value: any): JSX.Element => {
          const { data, rowIndex, columnIndex } = value;
          const columnValue = data[newColumn.name];
          return (
            <CopySpan inGrid needToolTip={column.needToolTip} id={`cell${gridName}${rowIndex}${columnIndex}`} value={columnValue} />
          );
        };
      }
    }
    // const cacheWidth = columnWidths[index];
    // if (typeof cacheWidth === 'number') {
    // if (newColumn.autoWidth !== false) {
    //   newColumn.minWidth = 50;
    // }
    // }
    return newColumn;
  });
  return columns;
}
