import React, { useEffect, useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import {
  Typography,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import { HeaderTitle } from 'components/common';

import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import WarehouseDashboard from './warehouseDashboard'
import { sortWarehouse } from 'utils/index'

const { Text } = Typography;


export default function Dashboard(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const sortedwhs = sortWarehouse(whs.filter(k=> !k.correspondingWarehouseNum))
  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [value, setValue] = useState(new Date());


  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: 999,
          bottom: 20,
          right: 20,
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Button
                  type="text"
                  onClick={() => setShowOrderInfo((prev) => !prev)}
                >
                  {showOrderInfo ? 'Hide Order Info' : 'Show Order Info'}
                </Button>
              </Menu.Item>
              {/* <Menu.Item key="2">
                <Button type="text" onClick={() => {}}>
                  Refresh Page
                </Button>
              </Menu.Item> */}
            </Menu>
          }
          placement="topCenter"
        >
          <Button type="primary" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
      <div style={{ paddingBottom: 20 }}>
        <HeaderTitle
          breadcrumb={[
            'Dashboard',
            'Overview',
          ]}
        >
          <Text
            strong
            style={{ color: '#1890ff', fontSize: 18, paddingRight: 4 }}
          >
            {moment(value)
              .tz(moment.tz.guess())
              .format('MM/DD/YYYY hh:mmA Z z')}
          </Text>
        </HeaderTitle>

        {
          sortedwhs.map(i=>(
            <WarehouseDashboard warehouse={i} showOrderInfo={showOrderInfo} />
          ))
        }

      </div>
    </>
  );
}
