import * as actionTypes from './actionTypes';

export const deselectAuditProductRows = (edit, idList = []) => ({
  type: actionTypes.AUDIT_DESELECT_PRODUCT_ROWS,
  edit,
  rowIds: idList,
});

export const editSelectedProductRows = (edit) => ({
  type: actionTypes.AUDIT_EDIT_SELECTED_PRODUCT_ROWS,
  edit,
});
