import { digitBridgeApi, digitBridgePrinterApi } from './http';
import { validateHttpStatus } from 'utils';

type QuaryUnpackParams = {
    $skip: number;
    $top: number;
    $sortBy: string;
    warehouseNum?: number;
    startDate?: string;
    endDate?: string;
    prepackSKU?: string;
    childSKU?: string;
    oper?: number;
    status?: number;
}
type SaveLineParams = {
    sku: string;
    productTitle: string;
    fulfillmentProductNum: number;
    requestQty: number;
    lpnNumer: string;
    unpackQty: number;
}

type CeateUnpackProcessLineParams = {
    sku: string,
    sourceLocationNum: number,
    sourceLocationCode: string,
    destinationLocationNum: number,
    destinationLocationCode: string,
    unpackQty: number
}
export const queryUnpack = async (params: QuaryUnpackParams): Promise<any> => {
  return await digitBridgeApi.get('/api/prepack/unpacks', { params });
};

export const getUnpackByNum = async (fulfillmentPackNum: number): Promise<any> => {
    return await digitBridgeApi.get(`/api/prepack/unpacks/${fulfillmentPackNum}`);
  };

export const getUnpackLines = async (fulfillmentPackNum: number): Promise<any> => {
    return await digitBridgeApi.get(`/api/prepack/unpacks/${fulfillmentPackNum}/lines`);
};

export const saveLine = async (fulfillmentPackNum: number, params: SaveLineParams): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post(`/api/prepack/unpacks/${fulfillmentPackNum}/lines`, json);
};

export const delUnpackLine = async (fulfillmentPackNum: number, fulfillmentPackLineNum: number): Promise<any> => {
    return await digitBridgeApi.delete(`/api/prepack/unpacks/${fulfillmentPackNum}/lines/${fulfillmentPackLineNum}`);
};

// export const editUnpackLine = async (fulfillmentPackNum: number, fulfillmentPackLineNum: number, params: EditLineParams): Promise<any> => {
//     const json = JSON.stringify(params);
//     return await digitBridgeApi.patch(`/api/prepack/unpacks/${fulfillmentPackNum}/lines/${fulfillmentPackLineNum}`, json);
// };


export const cancelUnpack = async (fulfillmentPackNum: number): Promise<any> => {
    return await digitBridgeApi.post(`/api/prepack/unpacks/${fulfillmentPackNum}/cancel`);
};

export const completeUnpack = async (fulfillmentPackNum: number): Promise<any> => {
    return await digitBridgeApi.post(`/api/prepack/unpacks/${fulfillmentPackNum}/complete`);
};

export const delUnpackProcessLine = async (fulfillmentPackNum: number, fulfillmentUnpackProcessNum: number): Promise<any> => {
    return await digitBridgeApi.delete(`/api/prepack/unpacks/${fulfillmentPackNum}/process/${fulfillmentUnpackProcessNum}`);
};

export const ceateUnpackProcessLine = async (fulfillmentPackNum: number, params: CeateUnpackProcessLineParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/prepack/unpacks/${fulfillmentPackNum}/process`, json);
};

export const getUnpackProcessLines = async (fulfillmentPackNum: number): Promise<any> => {
    return await digitBridgeApi.get(`/api/prepack/unpacks/${fulfillmentPackNum}/process`);
};

export const editUnpackProcessLineQty = async (fulfillmentPackNum: number, fulfillmentUnpackProcessNum: number, qty: number, requestQty: number): Promise<any> => {
    const json = JSON.stringify({
        UnpackQty: qty,
        RequestQty: requestQty
    });
    return await digitBridgeApi.patch(`/api/prepack/unpacks/${fulfillmentPackNum}/process/${fulfillmentUnpackProcessNum}`, json);
};

type ExprotUnpickPickListParams = {
    FulfillmentUnpackNum:number,
    PrintTemplateNum: number,
    MasterAccountNum:number,
    ProfileNum: number
}

export const exportUnpackPickListPDF = async (params: ExprotUnpickPickListParams): Promise<any> => {
    const json = JSON.stringify(params);
	return await digitBridgePrinterApi.post('/api/unpack/picklist/export', json, { responseType: 'blob' });
};

export const previewUnpackPickList = async (params: ExprotUnpickPickListParams): Promise<any> => {
	const json = JSON.stringify(params);
	return await digitBridgePrinterApi.post('api/unpack/picklist/preview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};

export const getUnpackByItem = async (itemNum: string): Promise<any> => {
    return await digitBridgeApi.get(`/api/prepack/unpacks/${itemNum}`);
  };

    