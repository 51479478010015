import React, { useCallback } from 'react';
import { Select } from 'antd';

type SelectFieldProps = {
  value?: string;
  onChange?: (value: string) => void;
};
export default function SelectField(props: SelectFieldProps): JSX.Element {
  const options = [
    { title: 'PO#', key: 'poNumber' },
    { title: 'SKU / UPC equals', key: 'SKU' },
    { title: 'SKU starts with', key: 'SKUStartsWith' },
    { title: 'Product Name', key: 'title' },
    { title: 'MPN', key: 'mpn' },
    { title: 'Supplier #', key: 'VendorCode' },
    { title: 'Container #', key: 'container' },
  ];
  const { onChange, value } = props;
  const handleChange = useCallback(
    (newValue: string) => {
      if ('function' === typeof onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );
  return (
    <Select
      value={value}
      onChange={handleChange}
      dropdownMatchSelectWidth={false}
    >
      {options.map((option) => {
        return (
          <Select.Option key={option.key} value={option.key}>
            {option.title}
          </Select.Option>
        );
      })}
    </Select>
  );
}
SelectField.defaultProps = {
  value: undefined,
  onChange: undefined,
};
