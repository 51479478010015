import * as actionTypes from 'actions/actionTypes';

const initialState = {
  deselectAuditProductRowIds: [],
  isEditingAuditProductRow: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUDIT_DESELECT_PRODUCT_ROWS:
      return {
        ...state,
        deselectAuditProductRowIds: [...action.rowIds],
        isEditingAuditProductRow: action.edit,
      };

    case actionTypes.AUDIT_EDIT_SELECTED_PRODUCT_ROWS:
      return {
        ...state,
        isEditingAuditProductRow: action.edit,
      };

    default:
      break;
  }

  return state;
};

export default reducer;
