import React from 'react';
import { Transfer, Table, TransferProps } from 'antd';
import difference from 'lodash/difference';
import { ColumnsType } from 'antd/lib/table';

interface TableTransferProps extends TransferProps<LocationZoneRow> {
  leftColumns: ColumnsType<LocationZoneRow>;
  rightColumns: ColumnsType<LocationZoneRow>;
  loading: boolean;
}
/**
 * Table Transfer
 * @param props
 * @returns {JSX.Element}
 */
function TableTransfer(props: TableTransferProps): JSX.Element {
  const { leftColumns, rightColumns, loading, ...restProps } = props;
  return (
    <Transfer
      /* eslint-disable react/jsx-props-no-spreading */
      {...restProps}
      showSelectAll={false}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys,
      }) => {
        const rowSelection = {
          onSelectAll(selected: boolean, selectedRows: Array<LocationZoneRow>) {
            const nextSelectedKeys = selectedRows.map(
              (item: LocationZoneRow) => {
                if (item) {
                  return `${item.locationId}`;
                }
                return '';
              }
            );
            const diffKeys = selected
              ? difference(nextSelectedKeys, selectedKeys)
              : difference(selectedKeys, nextSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect(item: LocationZoneRow, selected: boolean) {
            onItemSelect(`${item.locationId}`, selected);
          },
          selectedRowKeys: selectedKeys,
        };

        return (
          <Table
            loading={loading}
            rowSelection={rowSelection}
            columns={direction === 'left' ? leftColumns : rightColumns}
            dataSource={filteredItems}
            size="small"
          />
        );
      }}
    </Transfer>
  );
}

export default TableTransfer;
