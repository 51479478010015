import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { ButtonIcon } from 'components/common';
import { playBell } from 'utils';
import {
  SmartRow,
  SmartSpace,
  SmartScanner,
  SmartBackButton,
} from '../../SmartComponent';
import { postProductRuturnInspectionToLocation } from 'services/smart';
import { Spin, Space } from 'antd';
import { useTranslation } from 'react-i18next';

type FailDialogProps = {
  location: string;
  warehouseId: string;
  warehouseNum: number;
  product: any;
  onSubmit: (status: number, location: string) => void;
  onBack: () => void;
};
export default function FailDialog(props: FailDialogProps): JSX.Element {
  const { location, onSubmit, warehouseId, product, onBack } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [notFoundValue, setNotFoundValue] = useState(location);
  /**
   * handle scan changed event
//    */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        try {
          const res = await postProductRuturnInspectionToLocation(
            warehouseId,
            product.fulfillmentProductNum,
            nextValue
          );
          setLoading(false);
          if (res.isSuccess) {
            playBell('success');
            onSubmit(1, nextValue);
          } else {
            // playBell('error');
            if (res.code === 404) {
              setNotFoundValue(nextValue);
            }
            if (res.code === 409 || res.code === 400) {
              onSubmit(3, nextValue);
            }
          }
        } catch (error) {
          // playBell('error');
          setLoading(false);
          // if (error.response.status === 404) {
          //   setNotFoundValue(nextValue);
          // }
          // if (error.response.status === 409) {
          //   onSubmit(3, nextValue);
          // }
        }
      }
    },
    [product, warehouseId, onSubmit]
  );

  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <Spin spinning={loading} style={{ width: '100%' }}>
          <Space>
            <ButtonIcon
              className="bi-emoji-frown"
              style={{ width: 20, height: 20, color: 'red', fontSize: 28 }}
            />
            <div
              style={{ marginLeft: 5, color: 'red', fontSize: 16 }}
            >{`Lcoation "${notFoundValue}" is not valid!`}</div>
          </Space>

          <SmartSpace>
            <div
              style={{
                // color: 'blue',
                fontWeight: 600,
                fontSize: 16,
                marginTop: 5,
              }}
            >
              {t("smart.virtualWarehouseToLocation.inspectionToLocation.scanOtherLocation")}
            </div>
            <SmartScanner
              style={{ marginTop: 10 }}
              id="scanLocation1"
              isAutoFocus={1}
              onChangeValue={handleScannerChange}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              onBack();
            }}
          />
        </Spin>
      </SmartRow>
    );
  };
  return (
    <Modal
      visible
      width={300}
      title={<Title />}
      footer={null}
      closable={false}
    />
  );
}
