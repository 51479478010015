import React, { useCallback, useState, useEffect } from 'react';
import {
  Modal,
  Row,
  Space,
  Button,
  Typography,
  Steps,
  Tabs,
  Form,
  Input,
  Card,
  Col,
  Select,
  InputNumber,
  Radio,
} from 'antd';
import { DataGridPlus } from 'components/common';
import { getProfileSettingValue } from 'utils';
import { EditFilled } from '@ant-design/icons';
import EditModal from './EditItem';
import { UnlockFilled, LockFilled } from '@ant-design/icons';
import {
  queryProductByKeyword,
  getProductLotList,
  getProductUomList,
} from 'services/product';
import { inventoryBatchCounts2 } from 'services/inventory';

const { Text } = Typography;
type Props = {
  warehouseNum: number;
  warehouseId: string;
  // locationNum: number;
  location: StringKAnyVPair;
  onHide: () => void;
  onSuccess: () => void;
};

type Item = {
  id: string;
  productNum: number;
  sku: string;
  upc?: string;
  lotNumber?: string;
  uom: string;
  rate: number;
  qty: number;
  uomList?: any[];
  lotNumberList?: any[];
  valid: boolean;
};

type Info = {
  productId: string;
  fulfillmentProductNum: number;
  sku: string;
  upc: string;
  uomList: {
    productUomNum: number;
    name: string;
    uom: string;
    rate: number;
  }[];
  lotNumberList: any[];
};

export default function LocationCount(props: Props): JSX.Element {
  const { onHide, location, warehouseId, onSuccess } = props;
  const [step, setStep] = useState<number>(0);
  const [current, setCurrent] = useState<Item>();
  const [activeKey, setActiveKey] = useState('copyPaste');
  const [lotEnable, setLotEnable] = useState(true);
  const [uomEnable, setUomEnable] = useState(true);
  const [previewList, setPreviewList] = useState<Item[]>([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [itemInfo, setItemInfo] = useState<Info>();
  const [itemList, setItemList] = useState<Item[]>([]);
  const [lotNumberLock, setLotNumberLock] = useState(false);
  const [uomLock, setUomLock] = useState(false);
  const [qtyLock, setQtyLock] = useState(false);
  const [selectedUom, setSelectedUom] = useState<any>();
  const [qty, setQty] = useState<number>(1);
  const [totalInfo, setTotalInfo] = useState({
    skuCount: 0,
    totalQty: 0,
    totalBaseQty: 0,
  });
  const [saveLoaing, setSaveLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [tempFormValues, setTempFormValues] = useState<any>();
  const [splitType, setSplitType] = useState<number>(0);

  const [form] = Form.useForm();

  const reFreshTotalInfo = useCallback(() => {
    const skuArr: string[] = [];
    let totalQty = 0;
    let totalBaseQty = 0;
    itemList.map((i) => {
      skuArr.push(i.sku || '');
      totalQty = totalQty + i.qty;
      totalBaseQty = totalBaseQty + i.qty * i.rate;
      return true;
    });
    setTotalInfo({
      skuCount: [...new Set(skuArr)].length,
      totalQty,
      totalBaseQty,
    });
  }, [itemList]);

  useEffect(() => {
    reFreshTotalInfo();
  }, [itemList, reFreshTotalInfo]);

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  React.useEffect(() => {
    getSetting();
  }, [getSetting]);

  const checkPasteValue = useCallback(async () => {
    const value = form.getFieldValue('copyPasteValue');
    if (!value) return;
    try {
      setCheckLoading(true);
      const temp: Item[] = [];
      const ids: string[] = [];
      const qtyValues: { [key: string]: number } = {};
      const arr = value.split('\n').filter((i: string) => !!i);
      const r = arr.map(async (item: string) => {
        const index = ids.indexOf(item);
        if (index > -1) {
          qtyValues[item] = qtyValues[item] + 1;
        } else {
          ids.push(item);
          qtyValues[item] = 1;
          const rowInfo = item.split(splitType ? '\t' : ',');
          if (rowInfo.length === 4) {
            if (rowInfo[0]) {
              const res1 = await queryProductByKeyword(rowInfo[0]);
              if (res1 && res1.length === 1) {
                let uomValid = true;
                let lotValid = true;
                if (lotEnable) {
                  if (rowInfo[1]) {
                    const res2 = await getProductLotList(res1[0].productId);
                    const tLot = res2.data.filter(
                      (i: any) => i.lotNumber === rowInfo[1]
                    );
                    if (tLot.length === 0) {
                      lotValid = false;
                    }
                  }
                }
                if (uomEnable) {
                  if (!rowInfo[2] || !rowInfo[3]) {
                    uomValid = false;
                  }
                  if (rowInfo[2] && rowInfo[3]) {
                    const res3 = await getProductUomList(res1[0].sku);
                    const tUom = res3.data.filter(
                      (i: any) =>
                        i.uom === rowInfo[2] && i.uomRate === Number(rowInfo[3])
                    );
                    if (tUom.length === 0) {
                      uomValid = false;
                    }
                  }
                }
                if (uomValid && lotValid) {
                  temp.push({
                    id: item,
                    productNum: res1[0].fulfillmentProductNum,
                    sku: rowInfo[0],
                    upc: '',
                    lotNumber: rowInfo[1],
                    uom: rowInfo[2],
                    rate: Number(rowInfo[3]),
                    qty: 1,
                    uomList: undefined,
                    lotNumberList: undefined,
                    valid: true,
                  });
                } else {
                  temp.push({
                    id: item,
                    productNum: 0,
                    sku: rowInfo[0],
                    upc: '',
                    lotNumber: rowInfo[1],
                    uom: rowInfo[2],
                    rate: Number(rowInfo[3]),
                    qty: 1,
                    uomList: undefined,
                    lotNumberList: undefined,
                    valid: false,
                  });
                }
              }
            } else {
              temp.push({
                id: item,
                productNum: 0,
                sku: rowInfo[0],
                upc: '',
                lotNumber: rowInfo[1],
                uom: rowInfo[2],
                rate: Number(rowInfo[3]),
                qty: 1,
                uomList: undefined,
                lotNumberList: undefined,
                valid: false,
              });
            }
          } else {
            temp.push({
              id: item,
              productNum: 0,
              sku: item,
              upc: '',
              lotNumber: '',
              uom: '',
              rate: 0,
              qty: 1,
              uomList: undefined,
              lotNumberList: undefined,
              valid: false,
            });
          }
        }
      });
      await Promise.all(r);
      setCheckLoading(false);
      setPreviewList(
        temp.map((i) => {
          return {
            ...i,
            qty: qtyValues[i.id],
          };
        })
      );
      setTempFormValues(form.getFieldsValue());
      setStep(1);
    } catch (error) {
      setCheckLoading(false);
    }
  }, [lotEnable, uomEnable, form, splitType]);

  const next = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (activeKey === 'copyPaste') {
      checkPasteValue();
    } else {
      setPreviewList([...itemList]);
      setTempFormValues(params);
      setStep(step + 1);
    }
  };

  const prev = () => {
    setStep(step - 1);
  };

  const getColumns = useCallback(() => {
    const columns = [
      {
        name: 'sku',
        header: 'SKU',
        defaultFlex: 1,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {data.sku}
            </Text>
          );
        },
      },
      {
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {data.lotNumber}
            </Text>
          );
        },
      },
      {
        name: 'qty',
        header: 'Quantity',
        defaultFlex: 1,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {data.qty}
            </Text>
          );
        },
      },
      {
        name: 'uom',
        header: 'UOM',
        isUom: true,
        defaultFlex: 1,
        userSelect: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {data.uom}
            </Text>
          );
        },
      },
      {
        name: 'rate',
        header: 'Rate',
        sortable: false,
        defaultFlex: 1,
        isUom: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {data.rate}
            </Text>
          );
        },
      },
      {
        name: 'uomBaseQty',
        header: 'Base Qty',
        sortable: false,
        defaultFlex: 1,
        isUom: true,
        render: (value: any) => {
          const { data } = value;
          return (
            <Text delete={!data.valid} type={data.valid ? undefined : 'danger'}>
              {(data.qty || 1) * (data.rate || 1)}
            </Text>
          );
        },
      },
    ];
    let temp = [...columns];
    temp = lotEnable ? temp : temp.filter((i) => i.name !== 'lotNumber');
    temp = uomEnable ? temp : temp.filter((i) => !i.isUom);
    return temp;
  }, [lotEnable, uomEnable]);

  const onFinish = useCallback(async () => {
    try {
      setSaveLoading(true);
      const params = {
        LocationNotes:
          activeKey === 'copyPaste'
            ? tempFormValues.copyPasteNote
            : tempFormValues.scanNote,
        Items: previewList
          .filter((i) => i.valid)
          .map((item) => {
            return {
              FulfillmentProductNum: item.productNum,
              SKU: item.sku,
              Quantity: item.qty,
              LotNumber: item.lotNumber,
              Uom: item.uom,
              UomRate: item.rate,
            };
          }),
      };
      const res = await inventoryBatchCounts2(warehouseId, location.id, params);
      setSaveLoading(false);
      if (res) {
        setStep(2);
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, [activeKey, location, previewList, warehouseId, tempFormValues]);

  const onAddItem = useCallback(() => {
    const formValue = form.getFieldsValue();
    const temp = [...itemList];
    const id = `${itemInfo?.sku}-${formValue?.lotNumber || ''}-${
      formValue?.uom || ''
    }`;
    const index = itemList.findIndex((i) => i.id === id);
    if (index === -1) {
      temp.push({
        id,
        productNum: itemInfo?.fulfillmentProductNum || 0,
        sku: itemInfo?.sku || '',
        upc: itemInfo?.upc,
        lotNumber: formValue.lotNumber,
        uom: formValue.uom,
        rate: selectedUom?.uomRate || 1,
        qty: qty,
        uomList: itemInfo?.uomList,
        lotNumberList: itemInfo?.lotNumberList,
        valid: true,
      });
    } else {
      temp[index] = {
        ...temp[index],
        qty: temp[index].qty + qty,
      };
    }
    setItemList([...temp]);
    setQty(1);
  }, [form, itemInfo, itemList, selectedUom, qty]);

  const resetForm = useCallback(() => {
    if (!qtyLock) {
      setQty(1);
    }
    setUomLock(false);
    setLotNumberLock(false);
    form.setFieldsValue({
      lotNumber: undefined,
      uom: undefined,
    });
    setSelectedUom(undefined);
  }, [form, qtyLock]);

  const onFindItemKeyDown = useCallback(
    async (evt: any) => {
      if ([9, 13].indexOf(evt.keyCode) > -1) {
        const value = form.getFieldValue('item');
        if (value === itemInfo?.sku || value === itemInfo?.upc) {
          if (lotNumberLock && uomLock && qtyLock) {
            onAddItem();
          } else {
            setQty((prev) => prev + 1);
          }
        } else {
          resetForm();
          try {
            const res = await queryProductByKeyword(value);
            if (res && res.length === 1) {
              const res2 = await getProductLotList(res[0].productId);
              const res3 = await getProductUomList(res[0].sku);
              setItemInfo({
                ...res[0],
                lotNumberList: res2.isSuccess ? res2.data : [],
                uomList: res3.isSuccess ? res3.data : [],
              });
              if (res2.isSuccess && res2.data.length === 1) {
                form.setFieldsValue({
                  lotNumber: res2.data[0].lotNumber,
                });
              }
            }
          } catch (error) {}
          form.setFieldsValue({ item: undefined });
        }
      }
    },
    [form, itemInfo, lotNumberLock, qtyLock, uomLock, onAddItem, resetForm]
  );

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  return (
    <Modal
      title={`Location '${location.code}' Count`}
      centered
      visible={true}
      width={800}
      maskClosable={false}
      onCancel={onHide}
      footer={
        <Row justify="space-between">
          <Space>
            {step !== 2 && <Button onClick={onHide}>Cancel</Button>}
          </Space>
          <Space>
            {step === 1 && <Button onClick={prev}>Previous</Button>}
            {step === 0 && (
              <Button type="primary" loading={checkLoading} onClick={next}>
                Next
              </Button>
            )}
            {step === 1 && (
              <Button
                type="primary"
                loading={saveLoaing}
                onClick={() => onFinish()}
              >
                Done
              </Button>
            )}
            {step === 2 && (
              <Button type="primary" onClick={onSuccess}>
                Close
              </Button>
            )}
          </Space>
        </Row>
      }
    >
      <Steps current={step}>
        <Steps.Step stepNumber={0} title="Scan" />
        <Steps.Step stepNumber={1} title="Review" />
        <Steps.Step stepNumber={2} title="Finish" />
      </Steps>
      {step === 0 && (
        <Form {...layout} form={form} style={{ width: '100%' }}>
          <Tabs activeKey={activeKey} onChange={setActiveKey}>
            <Tabs.TabPane tab="Copy Paste" key="copyPaste">
              <Row justify='space-between'>
                <Space>
                  <Text strong>Item</Text>
                  <Text strong>LOT#</Text>
                  <Text strong>UOM</Text>
                  <Text strong>Rate</Text>
                </Space>
                <Space>
                  <Text strong>Delimiter:</Text>
                  <Radio.Group name="radiogroup" value={splitType} onChange={(e)=>setSplitType(e.target.value)}>
                    <Radio value={0}>Comma</Radio>
                    <Radio value={1}>Tabs</Radio>
                  </Radio.Group>
                </Space>
              </Row>
              <Form.Item noStyle name="copyPasteValue">
                <Input.TextArea rows={5} />
              </Form.Item>
              <Row>
                <Space>
                  <Text strong>Location Notes:</Text>
                </Space>
              </Row>
              <Form.Item
                name="copyPasteNote"
                rules={[
                  {
                    required: activeKey === 'copyPaste',
                    message: 'please input location notes',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Scan" key="scan">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                  height: 250,
                }}
              >
                <div
                  style={{
                    width: 400,
                  }}
                >
                  <Row justify="start">
                    <Col span={12}>
                      <Form.Item label="Item" name="item">
                        <Input
                          allowClear
                          style={{ width: 200 }}
                          onKeyDown={onFindItemKeyDown}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {itemInfo && (
                    <Row>
                      <Col span={12}>
                        <Form.Item label="SKU">{itemInfo?.sku}</Form.Item>
                      </Col>
                      {lotEnable && (
                        <Col span={12}>
                          <Form.Item label="LOT#">
                            <Input.Group compact>
                              <Form.Item noStyle name="lotNumber">
                                <Select
                                  style={{ width: 100 }}
                                  dropdownMatchSelectWidth={false}
                                >
                                  {itemInfo.lotNumberList.map((i) => (
                                    <Select.Option
                                      key={i.productLotNum}
                                      value={i.lotNumber}
                                    >
                                      {i.lotNumber}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {lotNumberLock ? (
                                <Button
                                  icon={<LockFilled />}
                                  onClick={() =>
                                    setLotNumberLock((prev) => !prev)
                                  }
                                />
                              ) : (
                                <Button
                                  icon={<UnlockFilled />}
                                  onClick={() =>
                                    setLotNumberLock((prev) => !prev)
                                  }
                                />
                              )}
                            </Input.Group>
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}

                  {itemInfo && uomEnable && (
                    <Row>
                      <Col span={12}>
                        <Form.Item label="UOM">
                          <Input.Group compact>
                            <Form.Item noStyle name="uom">
                              <Select
                                style={{ width: 100 }}
                                onChange={(value) => {
                                  if (value) {
                                    const temp = itemInfo.uomList.filter(
                                      (i) => i.uom === value
                                    );
                                    setSelectedUom(temp[0]);
                                  } else {
                                    setSelectedUom(undefined);
                                  }
                                }}
                              >
                                {itemInfo.uomList.map((i) => (
                                  <Select.Option
                                    key={i.productUomNum}
                                    value={i.uom}
                                  >
                                    {i.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            {uomLock ? (
                              <Button
                                icon={<LockFilled />}
                                onClick={() => setUomLock((prev) => !prev)}
                              />
                            ) : (
                              <Button
                                icon={<UnlockFilled />}
                                onClick={() => setUomLock((prev) => !prev)}
                              />
                            )}
                          </Input.Group>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Rate">
                          {selectedUom?.uomRate}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  {itemInfo && (
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Qty">
                          <Input.Group compact>
                            <InputNumber
                              style={{ width: 100 }}
                              min={1}
                              value={qty}
                              onChange={(e) => setQty(e)}
                            />
                            {qtyLock ? (
                              <Button
                                icon={<LockFilled />}
                                onClick={() => setQtyLock((prev) => !prev)}
                              />
                            ) : (
                              <Button
                                icon={<UnlockFilled />}
                                onClick={() => setQtyLock((prev) => !prev)}
                              />
                            )}
                          </Input.Group>
                        </Form.Item>
                      </Col>
                      {uomEnable && selectedUom && (
                        <Col span={12}>
                          <Form.Item label="Base Qty">
                            {qty * selectedUom.uomRate}
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                  {itemInfo && (
                    <Row>
                      {itemList.length > 0 && (
                        <Space>
                          <span>{`SKU: ${totalInfo.skuCount}`}</span>
                          <span>{`Qty: ${totalInfo.totalQty}`}</span>
                          <span>{`Total Base Qty: ${totalInfo.totalBaseQty}`}</span>
                        </Space>
                      )}
                    </Row>
                  )}
                  {itemInfo && (
                    <Row>
                      <Button
                        type="primary"
                        onClick={() => {
                          onAddItem();
                        }}
                      >
                        Submit
                      </Button>
                    </Row>
                  )}
                </div>
                <div style={{ width: 350, height: 250, overflowY: 'auto' }}>
                  {itemList.length > 0 && (
                    <Card bodyStyle={{ padding: 4 }}>
                      {itemList.map((item) => (
                        <Card
                          type="inner"
                          style={{ marginBottom: 4 }}
                          key={item.id}
                          bodyStyle={{ padding: 4 }}
                        >
                          <div
                            style={{
                              width: 20,
                              height: 20,
                              position: 'absolute',
                              right: 8,
                              top: 4,
                            }}
                          >
                            <Button
                              size="small"
                              onClick={() => {
                                setCurrent(item);
                                setEditModalVisible(true);
                              }}
                              icon={<EditFilled />}
                            ></Button>
                          </div>
                          <Space direction="vertical">
                            <Typography.Text>{`SKU: ${item.sku}`}</Typography.Text>
                            <Typography.Text>{`UPC: ${item.upc}`}</Typography.Text>
                            {lotEnable && (
                              <Typography.Text>{`LOT#: ${
                                item.lotNumber || ''
                              }`}</Typography.Text>
                            )}
                            {uomEnable && (
                              <Space>
                                <Typography.Text>{`UOM: ${
                                  item.uom || ''
                                }`}</Typography.Text>
                                <Typography.Text>{`Rate: ${
                                  item.rate || ''
                                }`}</Typography.Text>
                              </Space>
                            )}
                            <Space>
                              <Typography.Text>{`Qty: ${item.qty}`}</Typography.Text>
                              {uomEnable && (
                                <Typography.Text>{`Base Qty: ${
                                  item.rate * item.qty
                                }`}</Typography.Text>
                              )}
                            </Space>
                          </Space>
                        </Card>
                      ))}
                    </Card>
                  )}
                </div>
              </div>
              <Row>
                <Space>
                  <Text strong>Location Notes:</Text>
                </Space>
              </Row>
              <Form.Item
                name="scanNote"
                rules={[
                  {
                    required: activeKey === 'scan',
                    message: 'please input location notes',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      )}
      {step === 1 && (
        <DataGridPlus
          idProperty="id"
          columns={getColumns()}
          dataSource={previewList}
          pagination={false}
          {...({} as any)}
        />
      )}

      {step === 2 && (
        <>
          <Row justify="start" style={{ marginTop: 4 }}>
            <Text>Location Inventory Updated Successfully:</Text>
          </Row>
          <DataGridPlus
            pagination={false}
            idProperty="id"
            dataSource={previewList.filter((i) => i.valid)}
            columns={getColumns()}
            {...({} as any)}
          />
        </>
      )}

      {editModalVisible && current && (
        <EditModal
          visible
          onHide={() => setEditModalVisible(false)}
          onUpdate={(value) => {
            const temp = itemList.filter((i) => i.id !== value.id);
            setItemList([value, ...temp]);
            setEditModalVisible(false);
          }}
          onDelete={(id) => {
            if (id) {
              const temp = itemList.filter((i) => i.id !== id);
              setItemList([...temp]);
            }
            setEditModalVisible(false);
          }}
          info={current}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
        />
      )}
    </Modal>
  );
}
