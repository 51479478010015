/**
 * PO-status service requests are here.
 */
import { digitBridgeApi } from './http';
/**
 * Fetch  Po list.
 */
export const fetchPoStatusList = async (): Promise<Array<PoStatusRow>> => {
  const list = (await digitBridgeApi.get('/api/poStatus')) as any;
  return list.map((item: any) => {
    return {
      id: item.poStatusNum,
      title: item.statusValue,
    };
  });
};
