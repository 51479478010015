import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Smart from 'components/Sales/Smart';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseCodeFromCache } from 'utils';

export default function SmartScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.smart" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Smart Process(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <Smart />
    </ContentLayout>
  );
}
