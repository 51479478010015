import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Typography } from 'antd';
import { BaseType } from 'antd/lib/typography/Base';

const { Text } = Typography;

const FLabel = styled(Text)`
  font-family: Lato, sans-serif;
  font-weight: 600;
  line-height: 18px;
`;
type Props = {
  labels: Array<string>;
  strong?: boolean;
  type?: BaseType | undefined;
  noColon?: boolean;
  fontSize?: number;
  require?: boolean;
};
export default function WrapFormLabel(props: Props): JSX.Element {
  let { fontSize } = useContext(ThemeContext);
  const { strong, type, noColon, labels, require } = props;

  if (props.fontSize) fontSize = props.fontSize || 14;

  return (
    <>
      {require && (
        <span
          style={{
            display: 'inline-block',
            marginRight: 4,
            color: '#bc0000',
            fontSize: fontSize,
            lineHeight: 1,
          }}
        >
          *
        </span>
      )}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: 4
      }}>
        {
          labels.map(label => {
            return (
              <FLabel strong={strong} type={type} style={{ fontSize }}>
                {label}
              </FLabel>
            )
          })
        }
      </div>
      <FLabel>{!noColon && ' :'}</FLabel>
    </>
  );
}
