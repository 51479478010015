import React, { useState, useRef, useCallback, useMemo } from 'react';
import {
  Button,
  Space,
  Form,
  Select,
  Row,
  Card,
  Spin,
  Switch,
  Col,
  Typography,
  Input,
} from 'antd';
import {
  Loading3QuartersOutlined,
  ClearOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {
  // SelectWarehouse,
  SelectProduct,
  InputInteger as InputNumber,
  HeaderTitle,
  FormLabel,
  Locker,
  message,
  SelectWarehouse,
  CopyComponent,
  DataGrid,
  CopySpan,
  TablesFilter,
} from 'components/common';
import { FormItemLeft, LockerBox } from './style';
import { COMMON_PADDING_SPACE, LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import DeviceSettingDialog from 'components/Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import {
  moveProductLocationToLocation2,
  moveAllProductLocationToLocation,
  putLocation,
  mergeLocation,
} from 'services/inventory';
import SearchSelect from './SearchSelect';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseCodeFromCache, getWarehouseIdFromCache, getProfileSettingValue } from 'utils';
import { fetchWarehouseProductList } from 'services/inventory';
import moment from 'moment';
import SelectLotUomDialog from '../Add/SelectLotUom'

const { Text } = Typography
type ColumnRenderType = {
  cellProps?: any;
  data: InventoryProductRow;
};

// eslint-disable-next-line
export default () => {
  const defaultWarehouseNum = Number(
    localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)
  );
  // const defaultWarehouse
  const defaultWarehouseCode = getWarehouseCodeFromCache(
    Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  );
  const defaultWarehouseId = getWarehouseIdFromCache(
    Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  );
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const [selectedAction, setSelectedAction] = useState('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductRow>();

  const [quantityLock1, setQuantityLock1] = useState(false);
  const [SLocationLock1, setSLocationLock1] = useState(false);
  const [DLocationLock1, setDLocationLock1] = useState(false);

  const [quantityLock6, setQuantityLock6] = useState(false);
  const [SLocationLock6, setSLocationLock6] = useState(false);
  const [DLocationLock6, setDLocationLock6] = useState(false);
  const [selectedProduct6, setSelectedProduct6] = useState<ProductRow>();
  const [selectedSWarehouse6, setSelectedSWarehouse6] = useState<WarehouseRow>(); 
  const [selectedWarehouse6, setSelectedWarehouse6] = useState<WarehouseRow>();
  const [lastStr, setLastStr] = useState('');

  const [DLocationLock3, setDLocationLock3] = useState(false);

  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  const [invWarehouseCrossMove, setInvWarehouseCrossMove] = useState<any>();
  const [productList, setProductList] = useState<
    InventoryProductRow[] | undefined
  >();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [isLoadingProductList, setIsLoadingProductList] = useState(false);
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const sortString = useRef('sku asc');
  const sLocationRef = useRef();

  const [isCreateDestinationLocation, setIsCreateDestinationLocation] =
    useState(false);
  const [
    isDestinationLocationQtyMustBeZero,
    setIsDestinationLocationQtyMustBeZero,
  ] = useState(true);
  const [isMoveToTheSameLocation, setIsMoveToTheSameLocation] = useState(true);
  const [selectedWarehouse7, setSelectedWarehouse7] = useState<WarehouseRow>();

  const [uomEnable, setUomEnable] = useState(false);
  const [lotEnable, setLotEnable] = useState(false);
  const [lotUomInfo, setLotUomInfo] = useState<{uom: string, uomRate: number, lotNumber:string}>();
  const [selectLotUomDialogVisible, setSelectLotUomDialogVisible] = useState(false);
  const [qtyRef, setQtyRef] = useState(0)

  const columns =useMemo(()=>{
    const cls = [
      {
        name: 'sku',
        header: 'SKU',
        defaultFlex: 1,
        minWidth: 220,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.sku} />;
        },
      },
      {
        name: 'upc',
        header: 'UPC',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.upc || ''} />;
        },
      },
      {
        minWidth: 130,
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        lotColumn: true,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.lotNumber || ''} />;
        },
      },
      {
        name: 'quantity',
        header: 'Quantity',
        defaultFlex: 1,
        // maxWidth: 110,
        minWidth: 110,
        resizable: false,
        type: 'number',
        headerAlign: 'center' as 'center',
      },
      {
        minWidth: 130,
        name: 'uom',
        header: 'UOM',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomRate',
        header: 'UOM Rate',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        minWidth: 130,
        name: 'uomBaseQty',
        header: 'Base Qty',
        defaultFlex: 1,
        uomColumn: true,
        userSelect: true,
      },
      {
        name: 'productTitle',
        header: 'Product Name',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.productTitle || ''} />;
        },
      },
      {
        minWidth: 120,
        name: 'productStatus',
        header: 'Product Status',
        defaultFlex: 1,
        userSelect: true,
        renderEnum: { 0: 'Active', 1: 'Archive', },
        render: (row: any) => {
          const { data } = row;
          const map = {
            0: 'Active',
            1: 'Archive',
          };
          return (
            // @ts-ignore
            <CopyComponent value={map[data.productStatus]}>
              {/* @ts-ignore */}
              <Typography.Text>{map[data.productStatus]}</Typography.Text>
            </CopyComponent>
          );
        },
      },
    ];
    let temp = [...cls]
    temp = lotEnable ? [...temp] : temp.filter(i => !i.lotColumn)
    temp = uomEnable ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  },[lotEnable, uomEnable]) 

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (!defaultWarehouseNum || !defaultWarehouseCode) {
      openDeviceSettingDialog({ closable: false });
    }
  }, [defaultWarehouseCode, defaultWarehouseNum]);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'InvWarehouseCrossMove'
      );
      if (temp.length > 0) {
        setInvWarehouseCrossMove(temp[0].settingValue);
      }
    }
  }, [userProfileSettings]);

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  React.useEffect(() => {
    getSetting();
  }, [getSetting]);

  React.useEffect(()=>{
      if(lotEnable || uomEnable){
        if((selectedProduct&& selectedProduct.productId) || (selectedProduct6 && selectedProduct6.productId)){
          setSelectLotUomDialogVisible(true)
        } else {
          setLotUomInfo(undefined)
        }
      }
  },[selectedProduct, selectedProduct6, lotEnable, uomEnable])

  const handleSubmit = async () => {
    const formParams = await form.validateFields();
    if (formParams.errorFields) return;
    // console.log(formParams);
    let r: any;
    switch (selectedAction) {
      case '1':
        if (!selectedProduct) {
          message.error({ content: 'Parameter error' });
          return;
        }
        if (formParams.SLocation1 === formParams.DLocation1) {
          message.warning({ content: 'Cannot merge or move to itemself' });
          return;
        }
        r = moveProductLocationToLocation2({
          fromWarehouse: defaultWarehouseId,
          fromLocation: formParams.SLocation1,
          fulfillmentProductNum: selectedProduct.id,
          quantity: formParams.Quantity,
          toWarehouse: defaultWarehouseNum,
          toLocation: formParams.DLocation1,
          lotNumber: lotUomInfo?.lotNumber,
          uom: lotUomInfo?.uom,
          uomRate: lotUomInfo?.uomRate,
        });
        break;

      case '2':
        if (formParams.SLicensePlate2 === formParams.DLicensePlate2) {
          message.warning({ content: 'Cannot merge or move to itemself' });
          return;
        }
        r = mergeLocation({
          warehouseId: defaultWarehouseId,
          fromLocation: formParams.SLicensePlate2,
          toLocation: formParams.DLicensePlate2,
        });
        break;

      case '3':
        if (formParams.LicensePlate === formParams.DLocation3) {
          message.warning({ content: 'Cannot merge or move to itemself' });
          return;
        }
        r = putLocation({
          warehouseId: defaultWarehouseId,
          fromLocation: formParams.LicensePlate,
          toWarehouse: defaultWarehouseNum,
          toLocation: formParams.DLocation3,
        });
        break;
      case '5':
        if (formParams.SLicensePlate5 === formParams.DLicensePlate5) {
          message.warning({ content: 'Cannot merge or move to itemself' });
          return;
        }
        r = mergeLocation({
          warehouseId: defaultWarehouseId,
          fromLocation: formParams.SLicensePlate5,
          toLocation: formParams.DLicensePlate5,
        });
        break;
      case '6':
        if (!selectedSWarehouse6) {
          message.error({ content: 'Parameter error' });
          return;
        }
        if (!selectedProduct6) {
          message.error({ content: 'Parameter error' });
          return;
        }
        if (!selectedWarehouse6) {
          message.error({ content: 'Parameter error' });
          return;
        }
        if (
          formParams.SLocation6 === formParams.DLocation6 &&
          selectedWarehouse6.warehouseId === defaultWarehouseId
        ) {
          message.warning({ content: 'Cannot merge or move to itemself' });
          return;
        }
        r = moveProductLocationToLocation2({
          fromWarehouse: selectedSWarehouse6.warehouseId,
          fromLocation: formParams.SLocation6,
          fulfillmentProductNum: selectedProduct6.id,
          quantity: formParams.Quantity6,
          toWarehouse: selectedWarehouse6.id,
          toLocation: formParams.DLocation6,
          lotNumber: lotUomInfo?.lotNumber,
          uom: lotUomInfo?.uom,
          uomRate: lotUomInfo?.uomRate
        });
        break;
      case '7':
        if (!selectedWarehouse7) {
          message.error({ content: 'Parameter error' });
          return;
        }
        r = moveAllProductLocationToLocation({
          fromWarehouse: defaultWarehouseId,
          fromLocation: formParams.SLocation7,
          toWarehouse: selectedWarehouse7.id,
          toLocation: formParams.DLocation7,
          autoCreate: isCreateDestinationLocation,
          toZero: isDestinationLocationQtyMustBeZero,
        });
        break;
      default:
        break;
    }
    try {
      setLoading(true);
      const res = await r;
      if (res) {
        message.success({ content: 'Moved/Merged Successfully!' });
        if (selectedAction === '7') {
          setLastStr(
            `Last Move: Source ${selectedCode}, Destination ${formParams.DLocation7}, Time ${moment().format(
              'hh:mm:ssA'
            )}`
          );
        }
        reSetForm();
      } else {
        message.error({ content: 'Moved/Merged Failed!' });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const reSetForm = () => {
    switch (selectedAction) {
      case '1':
        setSelectedProduct(undefined);
        setQtyRef(0)
        form.setFieldsValue({
          Identifer: '',
        });
        if (!quantityLock1) {
          form.setFieldsValue({
            Quantity: 1,
          });
        }
        if (!SLocationLock1) {
          form.setFieldsValue({
            SLocation1: undefined,
          });
        }
        if (!DLocationLock1) {
          form.setFieldsValue({
            DLocation1: undefined,
          });
        }
        break;
      case '2':
        form.resetFields();
        break;
      case '3':
        form.setFieldsValue({
          LicensePlate: undefined,
        });
        // if (!DWarehouseLock3) {
        //   setSWarehouse1(undefined);
        //   form.setFieldsValue({
        //     DWarehouse3: '',
        //   });
        // }
        if (!DLocationLock3) {
          form.setFieldsValue({
            DLocation3: undefined,
          });
        }
        break;

      case '5':
        form.resetFields();
        break;
      case '6':
        setSelectedProduct6(undefined);
        setQtyRef(0)
        form.setFieldsValue({
          Identifer6: '',
        });
        if (!quantityLock6) {
          form.setFieldsValue({
            Quantity6: 1,
          });
        }
        if (!SLocationLock6) {
          form.setFieldsValue({
            SLocation6: undefined,
          });
        }
        if (!DLocationLock6) {
          form.setFieldsValue({
            DLocation6: undefined,
          });
        }
        break;
      case '7':
        form.resetFields();
        setProductList(undefined);
        setSelectedCode('');
        break;
      default:
        break;
    }
  };

  const getWarehouseProductList = useCallback(
    async (code: string, skip: number, limit: number) => {
      let query: StringKAnyVPair = {
        $skip: skip,
        $top: limit,
        $sortBy: sortString.current,
        $calculateTotal: true,
        $count: true,
        $filter: `locationCode eq ${code}`,
      };
      setIsLoadingProductList(true);
      try {
        const res = await fetchWarehouseProductList(defaultWarehouseId, query);
        setIsLoadingProductList(false);
        setCount(res.count);
        setProductList(res.data);
        setTotalQty(res.totalQuantity);
        setSelectedCode(code);
        skipRef.current = skip || 0;
        limitRef.current = limit || 10;
      } catch (e) {
        setIsLoadingProductList(false);
      }
    },
    [defaultWarehouseId]
  );

  const formItemLayout = {
    labelCol: {
      sm: { span: 8 },
    },
    wrapperCol: {
      sm: { span: 16 },
    },
  };
  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Inventory',
          `Move/Merge (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      ></HeaderTitle>

      <Row justify="center">
        <Card style={{ width: '50%', minWidth: 740 }}>
          <Form.Item
            name="selected"
            label={<FormLabel>I want to</FormLabel>}
            initialValue=""
          >
            <Select
              disabled={loading}
              onChange={(value) => {
                setSelectedAction(`${value}`);
                setLastStr('');
                reSetForm();
              }}
              placeholder="Select an action..."
            >
              <Select.Option value="1">
                Move product from one location(container) to another.
              </Select.Option>
              <Select.Option value="2">
                Merge all products from one location to another.
              </Select.Option>
              <Select.Option value="3">
                Move a container to a new location(container).
              </Select.Option>
              {/* <Select.Option value="4">
                Nest a container inside another container
              </Select.Option> */}
              <Select.Option value="5">
                Merge contents of one container with another.
              </Select.Option>
              {invWarehouseCrossMove === '1' && (
                <Select.Option value="6">
                  Move product from one warehouse to another.
                </Select.Option>
              )}
              <Select.Option value="7">
                Move all products from one location to another warehouse.
              </Select.Option>
            </Select>
          </Form.Item>
          {selectedAction === '7' && (
            <Row>
              <Col xxl={8} xl={12} sm={12} xs={24}>
                <Form.Item
                  label={<FormLabel>Create destination location</FormLabel>}
                >
                  <Switch
                    checked={isCreateDestinationLocation}
                    onChange={setIsCreateDestinationLocation}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={12} sm={12} xs={24} style={{minWidth: 260}}>
                <Form.Item
                  label={
                    <FormLabel>Desination location qty must be zero</FormLabel>
                  }
                >
                  <Switch
                    checked={isDestinationLocationQtyMustBeZero}
                    onChange={setIsDestinationLocationQtyMustBeZero}
                  />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={12} sm={12} xs={24}>
                <Form.Item
                  label={<FormLabel>Move to the same location</FormLabel>}
                >
                  <Switch
                    checked={isMoveToTheSameLocation}
                    onChange={(ckd)=>{
                      setIsMoveToTheSameLocation(ckd)
                      if(ckd && sLocationRef.current){
                        form.setFieldsValue({DLocation7: sLocationRef.current})
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Card>
      </Row>
      <Form {...formItemLayout} form={form}>
        <Row justify="center" style={{ marginTop: 20 }}>
          {selectedAction === '1' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title="Source">
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="Identifer"
                        label={<FormLabel>Identifier</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Identifier!',
                          },
                        ]}
                      >
                        <SelectProduct
                          onBlurAble
                          onChange={(filename, product) => {
                            setSelectedProduct(product);
                            form.setFieldsValue({ Identifer: product.sku });
                          }}
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                  {lotUomInfo && <Form.Item wrapperCol={{...formItemLayout.wrapperCol, offset: 8}}>
                    <Space size="large">
                      {lotEnable&&<Text><Text strong>LOT#: </Text><Text>{lotUomInfo?.lotNumber}</Text></Text>}
                      {uomEnable&&<Text><Text strong>UOM: </Text><Text>{lotUomInfo?.uom}</Text></Text>}
                      {uomEnable&&<Text><Text strong>Rate: </Text><Text>{lotUomInfo?.uomRate}</Text></Text>}
                    </Space>
                  </Form.Item>}
                  <Row>
                    <FormItemLeft>
                    <Form.Item label={<FormLabel>Quantity</FormLabel>}>
                      <Space>
                      <Form.Item
                        name="Quantity"
                        noStyle
                        rules={[
                          { required: true, message: 'Please input Quantity!' },
                        ]}
                      >
                        <InputNumber onChange={(value) => value && setQtyRef(Number(value))}/>
                      </Form.Item>
                      {uomEnable && <Text>{`Base Qty: ${(lotUomInfo?.uomRate || 1) * Number(qtyRef || 0)}`}</Text>}
                      </Space>
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={quantityLock1}
                        onChange={(value) => setQuantityLock1(value)}
                      />
                    </LockerBox>
                  </Row>

                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SLocation1"
                        label={<FormLabel>Location (Location Name)</FormLabel>}
                        rules={[
                          { required: true, message: 'Please input Location!' },
                        ]}
                      >
                        {/* <SelectLocation
                          showPick
                          warehouse={{
                            id: defaultWarehouseNum,
                            code: '',
                            name: '',
                            isExternal: true,
                            totalQuantity: 0,
                            isEnabled: true,
                            warehouseId: defaultWarehouseId || '',
                          }}
                          onChange={(location) => {
                            setSLocation1(location);
                            form.setFieldsValue({ SLocation1: location.code });
                          }}
                        /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          form={form}
                          title="SLocation1"
                          showPick
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={SLocationLock1}
                        onChange={(value) => setSLocationLock1(value)}
                      />
                    </LockerBox>
                  </Row>
                </Card>

                <Card title="Destination" style={{ marginTop: 20 }}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLocation1"
                        label={<FormLabel>Location (Location Name)</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        {/* <SelectLocation
                          showPick
                          warehouse={{
                            id: defaultWarehouseNum,
                            code: '',
                            name: '',
                            isExternal: true,
                            totalQuantity: 0,
                            isEnabled: true,
                            warehouseId: defaultWarehouseId || '',
                          }}
                          onChange={(location) => {
                            setDLocation1(location);
                            form.setFieldsValue({ DLocation1: location.code });
                          }}
                        /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          form={form}
                          title="DLocation1"
                          showPick
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={DLocationLock1}
                        onChange={(value) => setDLocationLock1(value)}
                      />
                    </LockerBox>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
            </Card>
          )}

          {selectedAction === '2' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title="Source">
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SLicensePlate2"
                        label={<FormLabel>Location</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        {/* <Input placeholder="License Plate" /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={1}
                          form={form}
                          title="SLicensePlate2"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                </Card>
                <Card title="Destination" style={{ marginTop: 20 }}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLicensePlate2"
                        label={<FormLabel>Location</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        {/* <Input placeholder="License Plate" /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={1}
                          form={form}
                          title="DLicensePlate2"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
            </Card>
          )}

          {selectedAction === '3' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title="Container Detail">
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="LicensePlate"
                        label={<FormLabel>Location Name</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        {/* <Input placeholder="License Plate" /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={2}
                          form={form}
                          title="LicensePlate"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                </Card>
                <Card title="Destination" style={{ marginTop: 20 }}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLocation3"
                        label={<FormLabel>Location (Location Name)</FormLabel>}
                        rules={[
                          { required: true, message: 'Please input Location!' },
                        ]}
                      >
                        {/* <SelectLocation
                          warehouse={{
                            id: defaultWarehouseNum,
                            code: '',
                            name: '',
                            isExternal: true,
                            totalQuantity: 0,
                            isEnabled: true,
                            warehouseId: defaultWarehouseId || '',
                          }}
                          onChange={(location) => {
                            setDLocation3(location);
                            form.setFieldsValue({ DLocation3: location.code });
                          }}
                        /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          form={form}
                          title="DLocation3"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={DLocationLock3}
                        onChange={(value) => setDLocationLock3(value)}
                      />
                    </LockerBox>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
            </Card>
          )}

          {selectedAction === '5' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title="Source">
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SLicensePlate5"
                        label={<FormLabel>Location Name</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location Name!',
                          },
                        ]}
                      >
                        {/* <Input placeholder="License Plate" /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          form={form}
                          title="SLicensePlate5"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                </Card>
                <Card title="Destination" style={{ marginTop: 20 }}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLicensePlate5"
                        label={<FormLabel>Location Name</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location Name!',
                          },
                        ]}
                      >
                        {/* <Input placeholder="License Plate" /> */}
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          form={form}
                          title="DLicensePlate5"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
            </Card>
          )}

          {selectedAction === '6' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title="Source">
                <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SWarehouse6"
                        label={<FormLabel>Warehouse</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please select warehouse!',
                          },
                        ]}
                      >
                        <SelectWarehouse
                          placeholder="Please select warehouse"
                          onlyNormal={false}
                          onChange={(warehouseRow) =>{
                            setSelectedSWarehouse6(warehouseRow)
                            form.setFieldsValue({SLocation6: undefined})
                          }}
                          value={selectedSWarehouse6}
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="Identifer6"
                        label={<FormLabel>Identifier</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Identifier!',
                          },
                        ]}
                      >
                        <SelectProduct
                          onBlurAble
                          onChange={(filename, product) => {
                            setSelectedProduct6(product);
                            form.setFieldsValue({ Identifer: product.sku });
                          }}
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>
                  {lotUomInfo && <Form.Item wrapperCol={{...formItemLayout.wrapperCol, offset: 8}}>
                    <Space size="large">
                      {lotEnable&&<Text><Text strong>LOT#: </Text><Text>{lotUomInfo?.lotNumber}</Text></Text>}
                      {uomEnable&&<Text><Text strong>UOM: </Text><Text>{lotUomInfo?.uom}</Text></Text>}
                      {uomEnable&&<Text><Text strong>Rate: </Text><Text>{lotUomInfo?.uomRate}</Text></Text>}
                    </Space>
                  </Form.Item>}
                  <Row>
                    <FormItemLeft>
                    <Form.Item label={<FormLabel>Quantity</FormLabel>}>
                    <Space>
                      <Form.Item
                        name="Quantity6"
                        noStyle
                        // label={<FormLabel>Quantity</FormLabel>}
                        rules={[
                          { required: true, message: 'Please input Quantity!' },
                        ]}
                      >
                        <InputNumber onChange={(value) => value && setQtyRef(Number(value))} />
                      </Form.Item>
                      {uomEnable && <Text>{`Base Qty: ${(lotUomInfo?.uomRate || 1) * Number(qtyRef || 0)}`}</Text>}
                      </Space>
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={quantityLock6}
                        onChange={(value) => setQuantityLock6(value)}
                      />
                    </LockerBox>
                  </Row>

                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SLocation6"
                        label={<FormLabel>Location (Location Name)</FormLabel>}
                        rules={[
                          { required: true, message: 'Please input Location!' },
                        ]}
                      >
                        <SearchSelect
                          warehouseId={selectedSWarehouse6?.warehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          showPick
                          form={form}
                          title="SLocation6"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={SLocationLock6}
                        onChange={(value) => setSLocationLock6(value)}
                      />
                    </LockerBox>
                  </Row>
                </Card>

                <Card title="Destination" style={{ marginTop: 20 }}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DWarehouse6"
                        label={<FormLabel>Warehouse</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        <SelectWarehouse
                          placeholder="Please select warehouse"
                          onlyNormal={false}
                          onChange={(warehouseRow) =>
                            setSelectedWarehouse6(warehouseRow)
                          }
                          value={selectedWarehouse6}
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox></LockerBox>
                  </Row>

                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLocation6"
                        label={<FormLabel>Location (Location Name)</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        <SearchSelect
                          warehouseId={selectedWarehouse6?.warehouseId}
                          onChange={(value) => console.log(value)}
                          locationType={0}
                          showPick
                          form={form}
                        // title="DLocation6"
                        />
                      </Form.Item>
                    </FormItemLeft>
                    <LockerBox>
                      <Locker
                        value={DLocationLock6}
                        onChange={(value) => setDLocationLock6(value)}
                      />
                    </LockerBox>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
            </Card>
          )}

          {selectedAction === '7' && (
            <Card style={{ width: '50%', minWidth: 740 }}>
              <Spin
                spinning={loading}
                indicator={
                  <Loading3QuartersOutlined
                    style={{ color: '#006DFF', fontSize: 36 }}
                    spin
                  />
                }
              >
                <Card title={`Source warehouse (${defaultWarehouseCode})`}>
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="SLocation7"
                        label={<FormLabel>Location</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        <SearchSelect
                          warehouseId={defaultWarehouseId}
                          onChange={(value, row) => {
                            if (isMoveToTheSameLocation) {
                              form.setFieldsValue({ DLocation7: row[0].code });
                            }
                            if (value && row.length === 1) {
                              sLocationRef.current = row[0].code
                              getWarehouseProductList(
                                row[0].code,
                                0,
                                limitRef.current
                              );
                            }
                          }}
                          locationType={0}
                          showPick
                          form={form}
                          title="SLocation7"
                        />
                      </Form.Item>
                    </FormItemLeft>
                  </Row>
                </Card>

                <Card
                  title={
                    <Space>
                      <Typography.Text className="ant-card-head-title">
                        Destination
                      </Typography.Text>

                      <SelectWarehouse
                        width={200}
                        placeholder="Please select warehouse"
                        withoutDefaultWarehouse
                        onChange={(warehouseRow) =>
                          setSelectedWarehouse7(warehouseRow)
                        }
                        value={selectedWarehouse7}
                      />
                    </Space>
                  }
                  style={{ marginTop: 20 }}
                >
                  <Row>
                    <FormItemLeft>
                      <Form.Item
                        name="DLocation7"
                        label={<FormLabel>Location</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Please input Location!',
                          },
                        ]}
                      >
                        <Input placeholder="input location" disabled={isMoveToTheSameLocation} />
                      </Form.Item>
                    </FormItemLeft>
                  </Row>
                </Card>
              </Spin>
              <Row justify="center" style={{ marginTop: 20 }}>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    icon={<FormOutlined />}
                  >
                    Submit
                  </Button>
                  <Button onClick={() => reSetForm()}>
                    <ClearOutlined />
                    Clear
                  </Button>
                </Space>
              </Row>
              {lastStr && (
                <Row justify="center">
                  <Typography.Paragraph type="secondary">
                    {lastStr}
                  </Typography.Paragraph>
                </Row>
              )}
              {productList && (
                <Row style={{ marginTop: 8, width: '100%' }}>
                  <Row justify="space-between" style={{ width: '100%', marginBottom: COMMON_PADDING_SPACE }}>
                    <Col>
                      <TablesFilter
                        dataSource={productList}
                        setFilteredData={setFilteredData}
                        columns={columns}
                        inputProps={{ size: "small" }}
                      />
                    </Col>
                    <Col>
                      <Space size="small" split=" | ">
                        <>
                          <FormLabel>Total SKUs</FormLabel>
                          <Typography.Text>{count}</Typography.Text>
                        </>
                        <>
                          <FormLabel>Total Quantity on Hand</FormLabel>
                          <Typography.Text>{totalQty}</Typography.Text>
                        </>
                      </Space>
                    </Col>
                  </Row>
                  <div style={{width: '100%'}}>
                  <DataGrid
                    key={selectedCode}
                    columns={columns}
                    dataSource={filteredData}
                    idProperty="sku"
                    skip={skipRef.current}
                    limit={limitRef.current}
                    loading={isLoadingProductList}
                    pagination
                    pageSizes={[10, 20, 50, 100]}
                    count={count}
                    onChange={(limit, skip) => {
                      limitRef.current = limit;
                      skipRef.current = skip;
                      getWarehouseProductList(
                        selectedCode,
                        skipRef.current,
                        limitRef.current
                      );
                    }}
                    onSortInfoChange={async (v: any) => {
                      if (v) {
                        if (v.dir === 1) {
                          sortString.current = `${v.id} asc`;
                          getWarehouseProductList(
                            selectedCode,
                            skipRef.current,
                            limitRef.current
                          );
                        }
                        if (v.dir === -1) {
                          sortString.current = `${v.id} desc`;
                          getWarehouseProductList(
                            selectedCode,
                            skipRef.current,
                            limitRef.current
                          );
                        }
                      } else {
                        sortString.current = 'sku asc';
                        getWarehouseProductList(
                          selectedCode,
                          skipRef.current,
                          limitRef.current
                        );
                      }
                    }}
                    // style={{ height: gridHeight }}
                    {...({} as any)}
                  />
                  </div>
                </Row>
              )}
            </Card>
          )}
        </Row>
      </Form>
      {selectLotUomDialogVisible && (selectedProduct6 || selectedProduct) &&
        <SelectLotUomDialog
          uomEnable={uomEnable}
          lotEnable={lotEnable}
          noNew={true}
          timeZone=''
          onHide={()=>{
            setSelectLotUomDialogVisible(false)
          }}
          onOk={(data)=>{
            setLotUomInfo(data)
            setSelectLotUomDialogVisible(false)
          }}
          product={selectedAction === '6' ? selectedProduct6 : selectedProduct}
        />
      }
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
    </>
  );
};
