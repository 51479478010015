import React, { useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import ReturnDetail from './ReturnDetail';
import { POST_MSG_SET_TITLE } from 'constants/config';
import { getPopupWindowUrl } from 'utils';

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  receiveDisabled: boolean;
  specialInfo: any;
  defaultLocationCode: string;
  defaultWarehouseType: number;
}

const DetailDialog = (props: Props) => {
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const onPopupWindow = (targetWindow: any) => {
    targetWindow.postMessage({
      __action: POST_MSG_SET_TITLE,
      title: `Customer Return (Bulk Return) Detail`,
    });
    targetWindow.postMessage({ 
      modalReturn: props.modalReturn, 
      onClose: undefined, 
      receiveDisabled: props.receiveDisabled, 
      specialInfo: props.specialInfo,
      defaultLocationCode: props.defaultLocationCode,
      defaultWarehouseType: props.defaultWarehouseType,
      path: 'bulk-return-detail' 
    });
  };

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Customer Return (Bulk Return) Detail"
        visible={props.visible}
        width={detailDialogWidth()}
        allowPopupWindow
        popupWindowUrl={getPopupWindowUrl('bulk-return-detail')}
        onPopupWindow={onPopupWindow}
      >
        <ReturnDetail {...props} />
      </ModalDialog>
    </>
  );
};

export default DetailDialog;
