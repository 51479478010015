
/**
 * Smart related service requests are here.
 */
import { digitBridgeApi } from './http';

export const fetchSettings = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/GetProfileSettingDefs');
}

export const fetchUserSettings = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/GetProfileSettings');
}
type SaveUserSettingsParamsItem =
    {
        SettingCode: string,
        SettingValue: string,
        MasterAccountNum: number,
        ProfileNum: number,
    }

export const saveUserSettings = async (params: SaveUserSettingsParamsItem[]): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/SaveProfileSettings', json);
}

