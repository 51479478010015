import copy from 'copy-text-to-clipboard';

/**
 * custom props,support copy
 * @param {StringKAnyVPair} props
 * @param {any} menuProps
 * @param {any} context
 * @returns {void}
 */
export default function RowContextMenu(
  props: StringKAnyVPair,
  menuProps: any,
  context: any
): void {
  const { cellProps } = context;
  const copyableColumns = props.copyableColumns ? props.copyableColumns : [];
  menuProps.autoDismiss = true;
  menuProps.items = [];

  if (copyableColumns.indexOf(cellProps.id) > -1) {
    menuProps.style = {
      ...menuProps.style,
      //left: menuProps.alignTo.left,
      //top: menuProps.alignTo.top,
    };
    menuProps.items.push({
      label: 'Copy',
      onClick: () => {
        const { data } = cellProps;
        const label = data[cellProps.id];

        if (label) {
          copy(label);
        }
      },
    });
  }

  if (props.renderRowContextMenu) {
    props.renderRowContextMenu(menuProps, context);
  }
}
