import * as actionTypes from './actionTypes';

export const pingPrintstation = (payload: number) => ({
  type: actionTypes.PING_PRINTSTATION,
  payload,
});

export const pongPrintstation = (payload: any) => ({
  type: actionTypes.PONG_PRINTSTATION,
  pong: payload,
});

export const printCartonLabel = (cartonLabel: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_CARTON_LABEL,
  payload: cartonLabel,
});

export const printDocument = (document: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_DOCUMENT,
  payload: document,
});

export const printMultiPackageLabel = (payload: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_MULTI_PKG_LABEL,
  payload,
});

export const printReceive = (payload: any) => ({
  type: actionTypes.PRINT_RECEIVE,
  payload,
});

export const printLabel = (payload: any) => ({
  type: actionTypes.PRINT_LABEL,
  payload,
});

export const printContainerShippingLabel = (payload: any) => ({
  type: actionTypes.PRINT_CONTAINER_SHIPPING_LABEL,
  payload,
});

export const reprintContainerShippingLabel = (payload: any) => ({
  type: actionTypes.REPRINT_CONTAINER_SHIPPING_LABEL,
  payload,
});

export const printPackingSlip = (slip: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_PACKING_SLIP,
  payload: slip,
});

export const printShipmentLabel = (shipmentLabel: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_SHIPMENT_LABEL,
  payload: shipmentLabel,
});

export const printShipmentLabelNotice = (notice: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_PRINT_NOTICE,
  payload: notice,
});

export const reprintShipment = (shipment: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_REPRINT_SHIPMENT,
  payload: shipment,
});

export const setPrintstationIsOk = (isOk: boolean) => ({
  type: actionTypes.SET_PRINTSTATION_IS_OK,
  isOk,
});

export const testPrinter = (param: StringKAnyVPair) => ({
  type: actionTypes.SIGNALR_TEST_PRINTER,
  payload: param,
});

export const printPickList = (payload: any) => ({
  type: actionTypes.PRINT_PICK_LIST,
  payload,
});

export const printReturnListDetail = (payload: any) => ({
  type: actionTypes.PRINT_RETURN_LIST_DETAIL,
  payload,
})

export const printBulkReturnDetail = (payload: any) => ({
  type: actionTypes.PRINT_BULK_RETURN_DETAIL,
  payload,
})

export const printGroupPackingSlip = (payload: any) => ({
  type: actionTypes.PRINT_GROUP_PACKING_SLIP,
  payload,
})

export const printUnpackPickList = (payload: any) => ({
  type: actionTypes.PRINT_UNPACK_PICK_LIST,
  payload,
})

export const printPackPickList = (payload: any) => ({
  type: actionTypes.PRINT_PACK_PICK_LIST,
  payload,
})

export const printPackDetail = (payload: any) => ({
  type: actionTypes.PRINT_PACK_DETAIL,
  payload,
})