import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space, Typography } from 'antd';
import { DataGridPlus, message } from 'components/common';
import { closeReturn } from 'services/return';
import { getWarehouseIdFromCache } from 'utils';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  onRefresh: () => void;
  current: any;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, current, onRefresh } = props;
  const [saveLoaing, setSaveLoaidng] = useState(false);
  const [noFullReceiveSkus, setNoFullReceiveSkus] = useState<string[]>([]);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onRefresh();
    onHide();
  }, [onHide, onRefresh]);

  const handleClose = useCallback(async () => {
    try {
      setSaveLoaidng(true);
      const res = await closeReturn(
        getWarehouseIdFromCache(current.warehouseNum),
        current.fulfillmentReturnNum
      );
      setSaveLoaidng(false);
      if (res) {
        message.success({ content: 'closed successfully' });
        onRefresh();
      }
    } catch (error) {
      setSaveLoaidng(false);
    }
  }, [onRefresh, current]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'returnQty',
      header: 'Claim Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Received Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'inspectionQty',
      header: 'Inspection Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'stockQty',
      header: 'Resaleable Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'nonStockQty',
      header: 'Damage Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
  ];

  useEffect(() => {
    if (current.items) {
      setNoFullReceiveSkus(
        current.items
          .filter((i: any) => i.receiveQty < i.returnQty)
          .map((k: any) => k.sku)
      );
    }
  }, [current.items]);

  return (
    <Modal
      title="Complete Return"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="complete_button"
              type="primary"
              loading={saveLoaing}
              disabled={
                current.status === 2 ||
                // current.stockQty + current.nonStockQty < current.receiveQty ||
                // current.returnQty > current.stockQty + current.nonStockQty + current.inspectionQty || // FE-2780
                current.receiveQty <= 0 //FE-8147
              }
              onClick={() => {
                if (noFullReceiveSkus.length > 0) {
                  Modal.confirm({
                    title:
                      'Some items not full receive. are you sure to complete?',
                    okText: 'Yes',
                    cancelText: 'No',
                    content: (
                      <Typography.Paragraph>
                        {noFullReceiveSkus.map((i) => (
                          <Typography.Text
                            type="secondary"
                            style={{ marginRight: 6 }}
                          >
                            {i}
                          </Typography.Text>
                        ))}
                      </Typography.Paragraph>
                    ),
                    onOk: () => {
                      handleClose();
                    },
                  });
                } else {
                  handleClose();
                }
              }}
              icon={<SaveOutlined />}
            >
              Complete
            </Button>
            <Button onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        dataSource={current.items || []}
        idProperty="sku"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
