import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import CycleCounting from 'components/Inventory/CycleCounting';
// eslint-disable-next-line
export default function InventoryCycleCounting(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <CycleCounting />
      </SiteContent>
    </ContentLayout>
  );
}
