/**
 * printer service requests are here.
 */
import { validateHttpStatus } from 'utils';
import { digitBridgeApi, digitBridgePrinterApi } from './http';


type QueuedOrderParams = {
    WarehouseNum: number,
    WarehouseCode: string,
    MutipleWarehouseOrder?: number,
    SKU?: string,
    OrderLineQtyType?: number,
    CustomOrderLineQty?: number,
    NotFoundProductStatus?: number,
    OutOfStockStatus?: number,
    ProductKitStatus?: number,
    HoldStatus?: number,
    ChannelAccounts?: number[],
    ReqeustServices?: string[],
    MappedCarrier?: string,
    MappedServices?: string[]
    OrderDateType?: number,
    LastDayCount?: number,
    OrderStartDate?: Date,
    OrderEndDate?: Date,
    SelectedPickListOrderNums?: number[]

}
export const getQueuedOrders = async (params: QueuedOrderParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/PickList/QueuedOrders', json)
}

export const PreParePrint = async (params: QueuedOrderParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/PickList/PreparePrint', json)
}

type PrintPickListParams = {
    MasterAccountNum: number,
    ProfileNum: number,
    WarehouseNum: number,
    WarehouseCode: string,
    PickListPrintQueueNum: number,
    PickListGroupBy: number,
    PickListOrderBy: number,
    SkuPerOrderType: number,
    SkuLocationType: number,
    ShowNotFoundItem: number,
    KitBreakDown: number,
    ShowOutofStockItem: number,
    LoctionType: number,
    LoctionSortType: number,
    LocationLimit: number,
    MutipleItemPiority: number,
    OrdersPerPage: number,
    SkusPerPage: number,
    PrinterAccountNum: number,
    PrintTemplateNum: number,
    SelectedPickListOrderNums: number[];
    ZoneSelectType: number,
    Zones?: number[],
    IncludeZones?: number[],
    MappedServices: string[],
    ChannelOrderId: string,
    FulfillmentOrderId: string,
    CentralOrderId: string,

}
export const printPicklistPreview = async (
    params: PrintPickListParams
): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgePrinterApi.post('/api/PickListPreview', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};
export const postPrintPicklist = async (
    params: PrintPickListParams
): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgePrinterApi.post('/api/PrintPickList', json, { validateStatus: (status) => validateHttpStatus([400], status) });
};

export const printPicklistExport = async (
    params: PrintPickListParams
): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgePrinterApi.post('/api/PickListExport', json, {
        responseType: 'blob',
    });
};

export const printPicklistExportAndMark = async (
    params: PrintPickListParams
): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgePrinterApi.post('/api/PickListExport?markprint=1', json, {
        responseType: 'blob',
    });
};

export const getPickList = async (
    warehouseId: number
): Promise<any> => {
    return await digitBridgeApi.get('/api/PickList/PrintQueues', { params: { warehouseNum: warehouseId } });
};

export const getPickListPreviewList = async (params: any): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/PickList/PreviewOrders', json)
}
