import {
  Button,
  Row,
  Select,
  Input,
  Modal,
  message,
  Form,
  Space,
  Col,
  InputNumber,
} from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { ClearOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { fetchLocationsReport } from 'services/reports';
import { exportBusinessFile } from 'services/files';
import { fetchZones } from 'services/zone';
import { fetchInventories } from 'services/inventory';
import {
  ExportCSV,
  DataGridPlus,
  SectionWrapper,
  SelectWarehouse,
  HeaderTitle,
  FormLabel,
  CopyComponent,
  TablesFilter,
} from 'components/common';
import AuditModal from '../../Inventory/AuditModalDialog';
import { SearchOutlined } from '@ant-design/icons';
//import { getCommonGridMinHeight } from 'utils';

// import SelectOrder from './SelectOrder';

const { Option } = Select;

export default function LocationReport(): JSX.Element {
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [reportEntity, setReportEntity] = useState<LocationsReportEntity>();
  const [loading, setLoading] = useState<boolean>(false);
  const [zones, setZones] = useState<any[]>([]);
  const [product, setProduct] = useState<any>();
  const [auditModalVisible, setAuditModalVisible] = useState<boolean>(false);
  const limitRef = useRef<number>(10);
  const skipRef = useRef<number>(0);
  const sortString = useRef('locationCode asc');
  const [tempParams, setTempParams] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const history = useHistory();
  const [form] = Form.useForm();

  const columns = [
    {
      header: 'Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Zone',
      name: 'zoneName',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Location',
      name: 'locationCode',
      userSelect: true,
      defaultFlex: 1,
      render: (row: any) => {
        const { data } = row;
        return (
          <CopyComponent value={data.locationCode}>
            <Button
              type="link"
              onClick={() => {
                getProduct(data);
              }}
            >
              {data.locationCode}
            </Button>
          </CopyComponent>
        );
      },
    },
    {
      header: 'Item Qty',
      name: 'quantity',
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
    },
    {
      header: 'Loc Seq',
      userSelect: true,
      name: 'seq',
      defaultFlex: 1,
    },
    {
      header: 'Created By',
      name: 'createBy',
      userSelect: true,
      defaultFlex: 1,
    },
  ];

  const getProduct = async (data: any) => {
    if (warehouse) {
      const { locationCode = '' } = data;
      const { warehouseId = '', warehouseCode = '' } = warehouse;
      const res = await fetchInventories(warehouseId, locationCode);
      if (res.length > 0) {
        const { warehouseNum, locationCode, locationId, locationNum } = res[0];
        setProduct({
          warehouseNum,
          warehouseCode,
          warehouseId,
          locationNum,
          locationCode,
          locationId,
        });
        setAuditModalVisible(true);
      } else {
        message.warning('Location not found.');
      }
    }
  };

  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    const params = form.getFieldsValue();
    if (warehouse) {
      try {
        setLoading(true);
        const entity = await fetchLocationsReport({
          warehouseId: warehouse.warehouseId,
          type: 0,
          sortBy: sortString.current,
          skip: 0,
          limit: limitRef.current,
          qty:
            params.qty || params.qty === 0
              ? params.qtyOperator === 'ge'
                ? `ge ${Number(params.qty)}`
                : `le ${Number(params.qty)}`
              : undefined,
          locationCode: params.locationCode || undefined,
          zoneNum: params.zone,
          capacityLength:
            params.capacityLength || params.capacityLength === 0
              ? params.capacityLengthOperator === 'ge'
                ? `ge ${Number(params.capacityLength)}`
                : `le ${Number(params.capacityLength)}`
              : undefined,
          capacityWidth:
            params.capacityWidth || params.capacityWidth === 0
              ? params.capacityWidthOperator === 'ge'
                ? `ge ${Number(params.capacityWidth)}`
                : `le ${Number(params.capacityWidth)}`
              : undefined,
          capacityHeight:
            params.capacityHeight || params.capacityHeight === 0
              ? params.capacityHeightOperator === 'ge'
                ? `ge ${Number(params.capacityHeight)}`
                : `le ${Number(params.capacityHeight)}`
              : undefined,
          maxLoad:
            params.maxLoad || params.maxLoad === 0
              ? params.maxLoadOperator === 'ge'
                ? `ge ${Number(params.maxLoad)}`
                : `le ${Number(params.maxLoad)}`
              : undefined,
        });
        setReportEntity(entity);
        skipRef.current = 0;
        setTempParams(params);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      message.warning('warehouse is required');
    }
  }, [warehouse, sortString, form]);
  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (warehouse) {
        //api
        try {
          setLoading(true);
          const params = { ...tempParams };
          const entity = await fetchLocationsReport({
            warehouseId: warehouse.warehouseId,
            type: 0,
            sortBy: sortString.current,
            skip: skip,
            limit: limit,
            qty:
              params.qty || params.qty === 0
                ? params.qtyOperator === 'ge'
                  ? `ge ${Number(params.qty)}`
                  : `le ${Number(params.qty)}`
                : undefined,
            locationCode: params.locationCode || undefined,
            zoneNum: params.zone,
            capacityLength:
              params.capacityLength || params.capacityLength === 0
                ? params.capacityLengthOperator === 'ge'
                  ? `ge ${Number(params.capacityLength)}`
                  : `le ${Number(params.capacityLength)}`
                : undefined,
            capacityWidth:
              params.capacityWidth || params.capacityWidth === 0
                ? params.capacityWidthOperator === 'ge'
                  ? `ge ${Number(params.capacityWidth)}`
                  : `le ${Number(params.capacityWidth)}`
                : undefined,
            capacityHeight:
              params.capacityHeight || params.capacityHeight === 0
                ? params.capacityHeightOperator === 'ge'
                  ? `ge ${Number(params.capacityHeight)}`
                  : `le ${Number(params.capacityHeight)}`
                : undefined,
            maxLoad:
              params.maxLoad || params.maxLoad === 0
                ? params.maxLoadOperator === 'ge'
                  ? `ge ${Number(params.maxLoad)}`
                  : `le ${Number(params.maxLoad)}`
                : undefined,
          });
          setReportEntity(entity);
          limitRef.current = limit;
          skipRef.current = skip;
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } else {
        message.warning('warehouse is required');
      }
    },
    [warehouse, tempParams, sortString]
  );

  const downLoadAll = useCallback(
    async (SelectField) => {
      try {
        const params = { ...tempParams };
        const job = await exportBusinessFile(3, {
          FilterCondition: {
            warehouseId: warehouse?.warehouseId,
            type: 0,
            qty:
              params.qty || params.qty === 0
                ? params.qtyOperator === 'ge'
                  ? `ge ${Number(params.qty)}`
                  : `le ${Number(params.qty)}`
                : undefined,
            capacityLength:
              params.capacityLength || params.capacityLength === 0
                ? params.capacityLengthOperator === 'ge'
                  ? `ge ${Number(params.capacityLength)}`
                  : `le ${Number(params.capacityLength)}`
                : undefined,
            capacityWidth:
              params.capacityWidth || params.capacityWidth === 0
                ? params.capacityWidthOperator === 'ge'
                  ? `ge ${Number(params.capacityWidth)}`
                  : `le ${Number(params.capacityWidth)}`
                : undefined,
            capacityHeight:
              params.capacityHeight || params.capacityHeight === 0
                ? params.capacityHeightOperator === 'ge'
                  ? `ge ${Number(params.capacityHeight)}`
                  : `le ${Number(params.capacityHeight)}`
                : undefined,
            maxLoad:
              params.maxLoad || params.maxLoad === 0
                ? params.maxLoadOperator === 'ge'
                  ? `ge ${Number(params.maxLoad)}`
                  : `le ${Number(params.maxLoad)}`
                : undefined,

            locationCode: params.locationCode || undefined,
            zone: params.zone,
          },
          SelectField,
          OrderByField: 'locationCode asc',
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [history, tempParams, warehouse]
  );

  const getZones = async (nextWarehouse: any) => {
    setZones([]);
    form.setFieldsValue({ zone: undefined });
    const { warehouseId = '' } = nextWarehouse;
    const res = await fetchZones(warehouseId);
    if ((res || []).length > 0) setZones(res);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Locations Report']} />
      <SectionWrapper
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={{
            qtyOperator: 'ge',
            capacityLengthOperator: 'ge',
            capacityWidthOperator: 'ge',
            maxLoadOperator: 'ge',
            capacityHeightOperator: 'ge',
          }}
        >
          <Row justify="center">
            <Row style={{ width: '100%', maxWidth: 1600 }}>
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>Warehouse</FormLabel>}
                  name="warehouse"
                >
                  <SelectWarehouse
                    // value={warehouse}
                    onChange={(nextWarehouse) => {
                      setWarehouse(nextWarehouse);
                      getZones(nextWarehouse);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>Zone</FormLabel>} name="zone">
                  <Select allowClear disabled={!warehouse}>
                    {zones.map((item, index) => (
                      <Option key={`${item.zoneNum}-${index}`} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={<FormLabel>Location Code</FormLabel>}
                  name="locationCode"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>qty</FormLabel>}>
                  <Input.Group compact>
                    <Form.Item noStyle name="qtyOperator">
                      <Select style={{ width: 65 }}>
                        <Select.Option value="ge">{'>='}</Select.Option>
                        <Select.Option value="le">{'<='}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="qty">
                      <InputNumber
                        min={0}
                        style={{ width: 'calc(100% - 65px)' }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>Max Load</FormLabel>}>
                  <Input.Group compact>
                    <Form.Item noStyle name="maxLoadOperator">
                      <Select style={{ width: 65 }}>
                        <Select.Option value="ge">{'>='}</Select.Option>
                        <Select.Option value="le">{'<='}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="maxLoad">
                      <InputNumber
                        min={0}
                        style={{ width: 'calc(100% - 65px)' }}
                        addonAfter="Pound"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>Capacity Length</FormLabel>}>
                  <Input.Group compact>
                    <Form.Item noStyle name="capacityLengthOperator">
                      <Select style={{ width: 65 }}>
                        <Select.Option value="ge">{'>='}</Select.Option>
                        <Select.Option value="le">{'<='}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="capacityLength">
                      <InputNumber
                        min={0}
                        style={{ width: 'calc(100% - 65px)' }}
                        addonAfter="Inch"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>Capacity Width</FormLabel>}>
                  <Input.Group compact>
                    <Form.Item noStyle name="capacityWidthOperator">
                      <Select style={{ width: 65 }}>
                        <Select.Option value="ge">{'>='}</Select.Option>
                        <Select.Option value="le">{'<='}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="capacityWidth">
                      <InputNumber
                        min={0}
                        style={{ width: 'calc(100% - 65px)' }}
                        addonAfter="Inch"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={<FormLabel>Capacity Height</FormLabel>}>
                  <Input.Group compact>
                    <Form.Item noStyle name="capacityHeightOperator">
                      <Select style={{ width: 65 }}>
                        <Select.Option value="ge">{'>='}</Select.Option>
                        <Select.Option value="le">{'<='}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="capacityHeight">
                      <InputNumber
                        min={0}
                        style={{ width: 'calc(100% - 65px)' }}
                        addonAfter="Inch"
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
          </Row>
          <Row justify="center" style={{ marginTop: 8 }}>
            <Space>
              <Button loading={loading} type="primary" onClick={handleSearch}>
                <SearchOutlined />
                <span>Search</span>
              </Button>
              <Button
                id="clear_button"
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                }}
              >
                <ClearOutlined />
                Clear
              </Button>
              <ExportCSV
                data={reportEntity ? reportEntity.data : []}
                disabled={!(reportEntity && reportEntity.data.length > 0)}
                columns={columns}
                scope="Location Report"
                downloadFromApi={(data) => downLoadAll(data)}
              />
            </Space>
          </Row>
        </Form>
      </SectionWrapper>
      {reportEntity && (
        <SectionWrapper style={{ marginTop: 20 }}>
          <TablesFilter
            columns={columns}
            dataSource={reportEntity.data}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            name="locationReport"
            autoWith={false}
            skip={skipRef.current}
            pageSizes={[10, 20, 50, 100]}
            count={reportEntity.count}
            dataSource={filteredData}
            onChange={handleDataGridChange}
            onSortInfoChange={async (v: any) => {
              if (v) {
                if (v.dir === 1) {
                  sortString.current = `${v.id} asc`;
                  await handleSearch();
                }
                if (v.dir === -1) {
                  sortString.current = `${v.id} desc`;
                  await handleSearch();
                }
              } else {
                sortString.current = 'locationCode asc';
                await handleSearch();
              }
            }}
            loading={loading}
            columns={columns}
            idProperty="id"
            pagination="remote"
            //style={{ minHeight: getCommonGridMinHeight(256) }}
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </SectionWrapper>
      )}
      {product && auditModalVisible && (
        <AuditModal
          product={product}
          visible={true}
          onClose={() => setAuditModalVisible(false)}
        />
      )}
    </>
  );
}
