import React from 'react';
import { Col, Typography } from 'antd';
import styled from 'styled-components';
import theme from 'assets/styles/theme';

const dangerBorder = `1px solid ${theme['@danger-color']} !important`;

const FieldCol = styled(Col)`
  & .error-message {
    width: 100%;
    color: ${theme['@danger-color']};
  }

  &.field-error .ant-btn,
  &.field-error .ant-input,
  &.field-error .ant-select-selector,
  &.field-error .ant-input-number-input-wrap {
    border: ${dangerBorder};
  }
`;

type Props = {
  children: React.ReactNode;
  error?: boolean;
  errorMessage?: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { Text } = Typography;
  const { error, errorMessage } = props;

  return (
    <FieldCol className={error ? 'field-error' : ''}>
      {props.children}
      {error && errorMessage ? (
        <Text className="error-message" ellipsis>
          {errorMessage}
        </Text>
      ) : (
        ''
      )}
    </FieldCol>
  );
};
