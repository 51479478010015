import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { HeaderTitle, GreyCardWrapper, ClearButton, FormLabel, ExportCSV, DataGridPlus, SelectWarehouse, TablesFilter } from 'components/common';
import { Form, Row, Col, Button, Space, Select, Radio, DatePicker, Input } from 'antd';
import { convertToUtcTime } from 'utils';
import { fetchEmployeeShipment } from 'services/reports';
import { SearchOutlined } from '@ant-design/icons';

export default function EmployeeShipment() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [reports, setReports] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      startTime: moment().subtract(7, 'days').startOf('day'),
      endTime: moment().add(1, 'days').endOf('day'),
      dataSource: 1,
    })
  }, [form]);

  const getReports = useCallback(async () => {
    const formValues = await form.validateFields();
    if (formValues.errorFields) return;
    setReports([]);
    setLoading(true);
    // const { startTime, endTime, warehouse } = formValues;
    // const { warehouseNum } = warehouse;
    // delete formValues.startTime;
    // delete formValues.endTime;
    // delete formValues.warehouse;
    // const query = {
    //   ...formValues,
    //   warehouseNum,
    //   shipDate: `${convertToUtcTime(startTime.format('YYYY-MM-DD'))}@${convertToUtcTime(endTime.format('YYYY-MM-DD'))}`,
    // }
    try {
      const { startTime, endTime, warehouse, employee: oriEmployee = '', group, dataSource } = formValues;
      const employee = oriEmployee.split(/[\n\s+,]/g).filter((item: any) => !!item).join(',');
      const { warehouseNum } = warehouse;
      const query = {
        warehouseNum,
        dataSource,
        employee,
        group,
        shipDate: `${convertToUtcTime(startTime.format('YYYY-MM-DD'))}@${convertToUtcTime(endTime.format('YYYY-MM-DD'))}`,
      }
      const res = await fetchEmployeeShipment(query);
      if (res.length > 0) {
        setReports(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [form])

  useEffect(() => {
    handleReset();
  }, [handleReset])

  const getColumns = useCallback(() => {
    const map: any = {
      'employee': 'Employee',
      'totalNonBatchPrint': 'Total Non-Batch Print',
      'singleItem': 'Single Item',
      'multiItem': 'Multiple Item',
      'batchPrint': 'Batch Print'
    }
    reports.forEach((rep: any) => {
      for (const key in rep) {
        if (map[key] === undefined) {
          map[key] = key;
        }
      }
    })

    const columns = [];
    for (const key in map) {
      if (key === 'employee') {
        columns.push({
          header: map[key],
          name: key,
          userSelect: true,
          defaultFlex: 1,
        })
      } else {
        columns.push({
          header: map[key],
          name: key,
          userSelect: true,
          defaultFlex: 1,
          sort: (a: number, b: number) => a - b,
        })
      }
    }
    return columns;
  }, [reports])

  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Employee Shipment']} />
      <GreyCardWrapper>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={6}>
              <Form.Item name="startTime" label={<FormLabel>Start</FormLabel>}>
                <DatePicker
                  allowClear={false}
                  showTime
                  format="MM/DD/YYYY"
                  style={{ width: '90%' }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="endTime" label={<FormLabel>End</FormLabel>}>
                <DatePicker
                  allowClear={false}
                  showTime
                  format="MM/DD/YYYY"
                  style={{ width: '90%' }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<FormLabel>Group By</FormLabel>}
                name="group"
              >
                <Select style={{ width: '90%' }} allowClear >
                  <Select.Option value={1}>Division Code</Select.Option>
                  <Select.Option value={2}>Department Code</Select.Option>
                  <Select.Option value={3}>Group Code</Select.Option>
                  <Select.Option value={4}>Subgroup Code</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item
                label={<FormLabel>Warehouse</FormLabel>}
                name="warehouse"
              >
                <SelectWarehouse width={'90%'} id="warehouse_selector" />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="employee" label={<FormLabel>User</FormLabel>}>
                <Input.TextArea style={{ maxWidth: '90%' }} autoSize />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 20 }}>
            <Space>
              <Form.Item name="dataSource">
                <FormLabel>Search From </FormLabel>
                <Radio.Group defaultValue={1} style={{ marginLeft: 10 }} onChange={e => form.setFieldsValue({ dataSource: e.target.value })}>
                  <Radio value={1}>Current</Radio>
                  <Radio value={2}>History</Radio>
                  <Radio value={3}>Current and History</Radio>
                </Radio.Group>
              </Form.Item>
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  getReports();
                }}
              >
                <SearchOutlined />
                Search
              </Button>
              <ExportCSV
                title="Export Employee Shipment"
                scope="Employee Shipment Report"
                columns={getColumns()}
                data={reports}
              />
              <ClearButton onClick={handleReset} />
            </Space>
          </Row>
        </Form>
      </GreyCardWrapper>
      <GreyCardWrapper style={{ marginTop: 20 }}>
        <TablesFilter
          columns={getColumns()}
          dataSource={reports}
          setFilteredData={setFilteredData}
        />
        {!loading &&
          <DataGridPlus
            name="employeeShipments"
            autoWith={false}
            showScollButton
            idProperty="employee"
            pageSizes={[10, 50, 200, 500, 1000]}
            columns={getColumns()}
            loading={loading}
            dataSource={filteredData}
            pagination="local"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        }
        {/* fakeGrid: Rerender the Grid to solve the problem that DataGridPlus will block the change of columns  */}
        {
          loading &&
          <DataGridPlus
            name="fakeGrid"
            columns={[]}
            loading={loading}
            dataSource={[]}
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        }
      </GreyCardWrapper>
    </>
  );
}
