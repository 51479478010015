/**
 *Order tag-related service requests are here.
 */
import dayjs from 'dayjs';
import { digitBridgeApi } from './http';
/**
 * Fetch tags
 * @returns {Promise<Array<TagRow>>}
 */
export const fetchTags = async (tagType?: number): Promise<Array<TagRow>> => {
  const params = tagType ? {
    tagName: '',
    tagType,
  } : {
    tagName: '',
  }
  const { data } = (await digitBridgeApi.get('/api/tags', {
    params
  })) as any;
  return data.map((item: any) => {
    return {
      ...item,
      createDate: dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
    };
  });
};
/**
 * create tag
 * @param {string} name
 * @returns {Promise<boolean>}
 */
export const createTag = async (name: string, tagType?: number): Promise<boolean> => {
  await digitBridgeApi.post('/api/SetTags', [
    {
      TagNum: 0,
      TagName: name,
      Action: 'create',
      TagType: tagType ? tagType : 1,
    },
  ]);
  return true;
};
/**
 * delete tag
 * @param {number} tagNum
 * @returns {Promise<boolean>}
 */
export const deleteTag = async (tagNum: number, tagType?: number): Promise<boolean> => {
  await digitBridgeApi.post('/api/SetTags', [
    {
      TagNum: tagNum,
      Action: 'delete',
      TagType: tagType ? tagType : 1,
    },
  ]);
  return true;
};
