import React, { useCallback, useState } from 'react';
import { Form, Input, Row, Modal, Button, Space, Select } from 'antd';
import { message } from 'components/common';
import { FormLabel } from 'components/common';
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { createWarehouseUserAuthEntity } from 'services/admin';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  warehouseList: any[];
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, warehouseList } = props;
  const [submitLoading, setSubmitLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    let hasSuccess = false;
    setSubmitLoading(true);
    for (let i = 0; i < params.warehouseIds.length; i++) {
      const id = params.warehouseIds[i];
      try {
        const temp = warehouseList.filter((h) => h.warehouseId === id);
        const res = await createWarehouseUserAuthEntity(id, params.eamil);
        if (res.error) {
          message.info(res.error);
        } else {
          message.success(
            `[${temp[0].warehouseCode}]: asssign to successfully `
          );
          hasSuccess = true;
        }
      } catch (error) {
        continue;
      }
    }
    setSubmitLoading(false);
    if (hasSuccess) {
      onRefresh();
    }
  }, [warehouseList, form, onRefresh]);

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Assign to User"
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              <SaveOutlined />
              {submitLoading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form {...layout} form={form} style={{ marginTop: 20 }}>
        <Form.Item
          name="eamil"
          label={<FormLabel>Email</FormLabel>}
          rules={[{ required: true, message: 'please input address alias' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="warehouseIds"
          label={<FormLabel>warehosue</FormLabel>}
          rules={[{ required: true, message: 'please select warehouse' }]}
        >
          <Select mode="multiple">
            {warehouseList.map((item: any) => (
              <Select.Option key={item.warehouseId} value={item.warehouseId}>
                {item.warehouseCode}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
