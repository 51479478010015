import * as actionTypes from 'actions/actionTypes';
import {
  isBooleanAction,
  isDictAction,
  isNumberAction,
  isStringAction,
} from 'utils';
import {
  DEFAULT_LOCALE,
  DEFAULT_SIDERBAR_WIDTH,
  PAGE_MODE_NORMAL,
  SIGNALR_CONNECTION_DISCONNECTED,
} from 'constants/config';

const isRegularAuthState = (state: any): state is RegularAuthState => {
  let ret = false;

  if (state && 'object' === typeof state) {
    ret =
      'boolean' === typeof state.authed &&
      'boolean' === typeof state.userIsExpired &&
      'boolean' === typeof state.pageIsHeld &&
      'boolean' === typeof state.userIsValid;
  }

  return ret;
};

const isRegularAuthAction = (action: any): action is RegularAuthAction => {
  let ret = false;

  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isRegularAuthState(action.state);
  }

  return ret;
};

const initialState: RegularState = {
  authed: false,
  locale: DEFAULT_LOCALE,
  selectedLanguage: DEFAULT_LOCALE,
  urlPath: '',
  userIsExpired: false,
  userIsValid: false,
  pageHeight: window.innerHeight,
  pageWidth: window.innerWidth,
  pageIsHeld: false,
  pageShowMode: PAGE_MODE_NORMAL,
  siderCollapsed: false,
  siderWidth: DEFAULT_SIDERBAR_WIDTH,
  signalConnection: SIGNALR_CONNECTION_DISCONNECTED,
  signalConnectionGroup: {},
};

const reducer = (
  state: RegularState = initialState,
  action: RegularAction
): RegularState => {
  switch (action.type) {
    case actionTypes.CHANGE_LANGUAGE:
      if (isStringAction(action)) {
        return {
          ...state,
          locale: action.value,
          selectedLanguage: action.value,
        };
      }
      break;
    case actionTypes.SWITCH_LANGUAGE:
      if (isStringAction(action)) {
        return {
          ...state,
          selectedLanguage: action.value,
        };
      }
      break;

    case actionTypes.COLLAPSE_SIDE_MENU:
      if (isBooleanAction(action)) {
        return {
          ...state,
          siderCollapsed: action.value,
        };
      }
      break;

    case actionTypes.COMPLETE_AUTHENTICATION:
      if (isRegularAuthAction(action)) {
        return {
          ...state,
          ...action.state,
        };
      }
      break;

    case actionTypes.HOLD_CURRENT_PAGE:
      if (isBooleanAction(action)) {
        return {
          ...state,
          pageIsHeld: action.value,
        };
      }
      break;

    case actionTypes.PASS_AUTHENTICATION:
      if (isRegularAuthAction(action)) {
        return {
          ...state,
          userIsValid: true,
        };
      }
      break;
    case actionTypes.EXPIRE_AUTHENTICATION:
      if (isRegularAuthAction(action)) {
        return {
          ...state,
          userIsValid: false,
        };
      }
      break;

    case actionTypes.SET_PAGE_HEIGHT:
      if (isNumberAction(action)) {
        return {
          ...state,
          pageHeight: action.value,
        };
      }
      break;

    case actionTypes.SET_PAGE_SHOW_MODE:
      if (isNumberAction(action)) {
        return {
          ...state,
          pageShowMode: action.value,
        };
      }
      break;

    case actionTypes.SET_PAGE_WIDTH:
      if (isNumberAction(action)) {
        return {
          ...state,
          pageWidth: action.value,
        };
      }
      break;

    case actionTypes.SET_SIDER_WIDTH:
      if (isNumberAction(action)) {
        return {
          ...state,
          siderWidth: action.value,
        };
      }
      break;

    case actionTypes.SET_SIGNAL_CONNECTION_GROUP:
      if (isDictAction(action)) {
        return {
          ...state,
          signalConnectionGroup: { ...(action.value as StringKAnyVPair) },
        };
      }
      break;

    case actionTypes.SET_SIGNAL_CONNECTION_STATE:
      if (isNumberAction(action)) {
        return {
          ...state,
          signalConnection: action.value,
        };
      }
      break;

    case actionTypes.SET_URL_PATH:
      if (isStringAction(action)) {
        return {
          ...state,
          urlPath: action.value,
        };
      }
      break;

    default:
      break;
  }

  return state;
};

export default reducer;
