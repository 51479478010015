import React, { useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Input, Divider } from 'antd';
import { message, FormLabel, DataGridPlus } from 'components/common';
import { ClearOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { setCommodityDescription } from 'services/product';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  rows: ProductRow[];
}

const columns = [
  {
    name: 'sku',
    header: 'SKU',
    minWidth: 220,
    userSelect: true,
    sortable: true,
    defaultFlex: 1,
  },
  {
    name: 'productTitle',
    header: 'Product Name',
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
    minWidth: 300,
  },
  {
    name: 'upc',
    header: 'UPC',
    userSelect: true,
    sortable: true,
    defaultFlex: 1,
    minWidth: 200,
  },
  {
    name: 'commodityDescription',
    header: 'Commodity Description',
    userSelect: true,
    sortable: true,
    defaultFlex: 1,
  },
];

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, rows } = props;
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();

    Modal.confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      content: `The ${rows.length} product(s) Commodity Description will be reset with [${params.CommodityDescription}]`,
      onOk: async () => {
        try {
          setSubmitLoading(true);
          const res = await setCommodityDescription(
            rows.map((i) => i.id),
            params.CommodityDescription
          );
          setSubmitLoading(false);
          if (res) {
            message.success({
              content: 'Resetted successfully',
            });
            onRefresh();
            onHide();
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      },
    });
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  let customProps = {} as any;
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title="Commodity Description"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="commodity_modal_reset_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              icon={<ClearOutlined />}
            >
              {submitLoading ? 'Resetting' : 'Reset'}
            </Button>
            <Button id="commodity_modal_cancel_button" onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="CommodityDescription"
            label={
              <FormLabel>
                Reset the Commodity Description of this Product(s) with
              </FormLabel>
            }
            rules={[
              { required: true, message: 'Please input Commodity Description' },
            ]}
          >
            <Input id="CommodityDescription" />
          </Form.Item>
        </Form>
        <Divider />
        <DataGridPlus
          idProperty="sku"
          columns={columns}
          dataSource={rows}
          pagination="local"
          /* eslint-disable react/jsx-props-no-spreading */
          {...({ customProps } as any)}
        />
      </Spin>
    </Modal>
  );
};
