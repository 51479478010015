import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography, Button, Row, InputNumber, Form, Input, Modal, Space } from 'antd';
import {
  getWarehouseIdFromCache,
  playAudio,
  getWarehouseCodeFromCache,
} from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import { queryLocationsByKeyword } from 'services/warehouse';
import {
  fetchWarehouseProductList,
  moveProductLocationToLocation,
} from 'services/inventory';
import moment from 'moment';
import FinishDialog from './Finish';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  // const [locations, setLocations] = useState<WarehouseLocationRow[]>([]);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [step, setStep] = useState<string>('sourceLocation'); //'sourceLocation' | 'targetLocation' | 'scanItem' | 'scanQty'
  const [sLocation, setSLocation] = useState<WarehouseLocationRow>();
  const [tLocation, setTLocation] = useState<WarehouseLocationRow>();
  const [productLocations, setProductLocations] = useState<any[]>([]);
  const [qty, setQty] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [scanTime, setScanTime] = useState<string>('');
  // const [showUsePick, setShowUsePick] = useState(false);
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const handleKeyDown = async (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      setInputValue('');
      evt.preventDefault();
      if (evt.target.value) {
        if (
          evt.target.value === productLocations[0].sku ||
          evt.target.value === productLocations[0].upc
        ) {
          playAudio('success');
          setQty((prev) => prev + 1);
          setScanTime(moment().format('hh:mm:ssA'));
        } else {
          playAudio('error');
          message.info({ content: 'not match item' });
        }
      }
    }
  };

  const moveProduct = useCallback(async () => {
    try {
      setFetching(true);
      const res = await moveProductLocationToLocation({
        fromWarehouse: warehouseId,
        fromLocation: sLocation?.locationNum || -1,
        fulfillmentProductNum: productLocations[0].fulfillmentProductNum,
        quantity: qty,
        toWarehouse: warehouseNum,
        toLocation: tLocation?.locationNum || -1,
      });
      setFetching(false);
      if (res) {
        setFinishDialogVisible(true);
        // message.success({ content: 'Move successfully' });
      }
    } catch (error) {
      setFetching(false);
    }
  }, [sLocation, tLocation, warehouseId, productLocations, qty, warehouseNum]);

  const fetchProductsByLocationCode = async (code: string) => {
    try {
      const query = {
        $skip: 0,
        $top: 10000,
        $sortBy: 'locationCode',
        $calculateTotal: true,
        $count: true,
        uniqueCode: code,
      };
      setFetching(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setFetching(false);
      if (res.data.length > 0) {
        const temp = res.data.filter(
          (item: any) => item.locationCode === sLocation?.locationCode
        );
        if (temp.length > 0) {
          setProductLocations(
            res.data.filter((item: any) => item.locationCode !== 'Checkout')
          );
          setStep('targetLocation');
        } else {
          message.error({
            content: `not found Item from '${sLocation?.locationCode}'`,
          });
        }
      } else {
        message.error({ content: 'not found Item' });
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const queryLocation = useCallback(
    async (value: string, step: string, callback: (value: any) => void) => {
      try {
        setFetching(true);
        const res = await queryLocationsByKeyword(warehouseId, value, true);
        setFetching(false);

        if (!res.error) {
          callback(res);
          if (step.length > 0) {
            setStep(step);
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId]
  );

  // const getLocations = useCallback(async () => {
  //   try {
  //     setFetching(true);
  //     const res = await fetchWarehouseLocationList(warehouseId, true);
  //     setFetching(false);
  //     setLocations(res);
  //   } catch (error) {
  //     setFetching(false);
  //   }
  // }, [warehouseId]);

  /* eslint-disable */
  // useEffect(() => {
  //   if (warehouseId && locations.length === 0) {
  //     getLocations();
  //   }
  // }, [warehouseId]);
  /* eslint-enable */

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'sourceLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanSourceLocationtoStart")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, 'scanItem', setSLocation);

                // const temp = locations.filter((i) => i.code === value);
                // if (temp.length > 0) {
                //   setSLocation(temp[0]);
                //   setStep('scanItem');
                // } else {
                //   message.error('No matching location');
                // }
              }}
            />
          </SmartSpace>
          {/* <SmartRow justify="space-between">
            <Button
              type="text"
              onClick={() => {
                queryLocation('General', 'scanItem', setSLocation);
                // const temp = locations.filter((i) => i.code === 'General');
                // if (temp.length > 0) {
                //   setSLocation(temp[0]);
                //   setStep('scanItem');
                // } else {
                //   message.error('No matching location [General]');
                // }
              }}
            >
              Use General
            </Button>
            <Button type="text" onClick={() => setShowUsePick((prev) => !prev)}>
              ...
            </Button>
          </SmartRow> */}
          <Row style={{ marginTop: 10 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('Pick', 'scanItem', setSLocation);
                }}
              >
                Use Pick
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('General', 'scanItem', setSLocation);
                }}
              >
                Use General
              </Button>
            </Space>
          </Row>

          {/* <Row justify="center" style={{ height: 100, alignItems: 'center' }}>
            {showUsePick && (
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('Pick', 'scanItem', setSLocation);

                  // const temp = locations.filter((i) => i.code === 'Pick');
                  // if (temp.length > 0) {
                  //   setSLocation(temp[0]);
                  //   setStep('scanItem');
                  // } else {
                  //   message.error('No matching location [Pick]');
                  // }
                }}
              >
                Use Pick
              </Button>
            )}
          </Row> */}

          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanItem' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>
              {t("smart.movemerge.from")}
            </SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {sLocation?.locationCode}
            </Text>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanItem")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10, marginBottom: 8 }}
              onChangeValue={(value) => fetchProductsByLocationCode(value)}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              setStep('sourceLocation');
            }}
          />
        </>
      )}
      {step === 'targetLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>
              {t("smart.movemerge.from")}
            </SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {sLocation?.locationCode}
            </Text>
          </SmartRow>
          <SmartRow justify="space-between">
            <Text>
              {t("common.sku")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {productLocations[0]?.sku}
              </Text>
            </Text>

            <Text>
              {t("common.upc")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {productLocations[0]?.upc}
              </Text>
            </Text>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanLocation")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                if (value === sLocation?.locationCode) {
                  return message.error('Cannot use the same location');
                } else {
                  const temp1 = productLocations.filter(
                    (j) => j.locationCode === value && j.quantity > 0
                  );
                  if (temp1.length > 0) {
                    queryLocation(value, 'scanQty', setTLocation);
                  } else {
                    Modal.confirm({
                      title: 'Are you sure to continue？',
                      icon: <ExclamationCircleOutlined />,
                      content: (
                        <Text>
                          Scanned location [<Text type="danger">{value}</Text>]
                          didn't have record of this item [
                          <Text type="danger">{productLocations[0]?.sku}</Text>]
                        </Text>
                      ),
                      okText: 'Yes',
                      onOk() {
                        queryLocation(value, 'scanQty', setTLocation);
                      },
                      onCancel() {
                        return;
                      },
                    });
                  }
                }
              }}
            />
          </SmartSpace>
          <SmartRow>
            <Text strong style={{ fontSize: 16 }}>
              {t("common.location")}:
            </Text>
          </SmartRow>
          <div
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: 8,
              width: '100%',
            }}
          >
            {productLocations
              .filter((i) => i.quantity > 0)
              .map((item: any) => (
                <Text
                  key={item.locationCode}
                  style={{
                    fontSize: 16,
                    marginRight: 6,
                  }}
                >
                  <Text strong>{item.locationCode}</Text>
                  {`(${item.quantity})`}
                </Text>
              ))}
          </div>
          <SmartBackButton
            onClick={() => {
              setStep('scanItem');
            }}
          />
        </>
      )}
      {step === 'scanQty' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow justify="space-between">
            <Text>
              {t("smart.movemerge.from")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </Text>

            <Text>
              {t("smart.movemerge.to")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {tLocation?.locationCode}
              </Text>
            </Text>
          </SmartRow>
          <SmartRow justify="space-between">
            <Text>
              {t("common.sku")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {productLocations[0]?.sku}
              </Text>
            </Text>

            <Text>
              {t("common.upc")}:
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {productLocations[0]?.upc}
              </Text>
            </Text>
          </SmartRow>
          <SmartRow>
            <Form.Item
              label={
                <Text
                  style={{
                    fontSize: 18,
                    color: 'rgb(86, 149, 34)',
                    fontWeight: 600,
                  }}
                >
                  {t("smart.movemerge.scanItem")}:
                </Text>
              }
            >
              <Input
                autoFocus
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ marginLeft: 8 }}
                onKeyDown={handleKeyDown}
              />
            </Form.Item>
          </SmartRow>
          <SmartRow justify="space-between" style={{ alignItems: 'start' }}>
            <Form.Item
              label={
                <Text
                  style={{
                    fontSize: 18,
                    color: 'rgb(86, 149, 34)',
                    fontWeight: 600,
                  }}
                >
                  {t("common.qty")}:
                </Text>
              }
            >
              <InputNumber
                min={0}
                value={qty}
                style={{ marginLeft: 8 }}
                onChange={(value) => setQty(value)}
              />
            </Form.Item>
            <Button type="primary" onClick={() => moveProduct()}>
              {t("common.confirm")}
            </Button>
          </SmartRow>
          <SmartRow>
            <Text>
              {/* Last scan at: <Text>{scanTime}</Text> */}
              {t("smart.movemerge.lastScanAt", { value: scanTime })}
              :
            </Text>
          </SmartRow>
          <SmartRow>
            <Text strong style={{ fontSize: 16 }}>
              {t("common.location")}
              :
            </Text>
          </SmartRow>
          <div
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: 8,
              width: '100%',
            }}
          >
            {productLocations
              .filter((i) => i.quantity > 0)
              .map((item: any) => (
                <Text
                  key={item.locationCode}
                  style={{
                    fontSize: 16,
                    marginRight: 6,
                  }}
                >
                  <Text strong>{item.locationCode}</Text>
                  {`(${item.quantity})`}
                </Text>
              ))}
          </div>

          <SmartBackButton
            onClick={() => {
              setStep('targetLocation');
            }}
          />
        </>
      )}
      {finishDialogVisible && tLocation && sLocation && (
        <FinishDialog
          tLocation={tLocation.locationCode || ''}
          sLocation={sLocation.locationCode || ''}
          sku={productLocations[0].sku}
          upc={productLocations[0].upc}
          qty={qty}
          onSubmit={(value) => {
            setFinishDialogVisible(false);
            if (value === 'home') {
              history.push('/smart');
            }
            if (value === 'sourceLocation') {
              setSLocation(undefined);
              setTLocation(undefined);
              setQty(0);
              setScanTime('');
              setStep('sourceLocation');
            }
            if (value === 'item') {
              setTLocation(undefined);
              setQty(0);
              setScanTime('');
              setStep('scanItem');
            }
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
