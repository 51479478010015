import React from 'react';
import { Checkbox, Col, Row } from 'antd';
import { isEqualObject } from 'crud-object-diff';
import styled from 'styled-components';

const OptionBox = styled.div`
  height: calc(100% - 22px);
  overflow-y: auto;
`;

/**
 * The type definition for the properties of CheckContainer.
 *
 * @param {StringKAnyVPair[]} - each option is like: {label: xxx, value: xxx, isAll: boolean}
 */
type Props = {
  options: StringKAnyVPair[];
  col?: number;
  onChange?: Function;
  onSelectOption?: Function;
  value?: any[];
};
// eslint-disable-next-line
function CheckContainer(props: Props): JSX.Element {
  const { useState } = React;
  const { col = 2 } = props;
  const [allOption, setAllOption] = useState<StringKAnyVPair>({});
  const [items, setItems] = useState<StringKAnyVPair[]>([]);
  const [lastSelectedValues, setLastSelectedValues] = useState<any[]>([]);
  const [optionNum, setOptionNum] = useState(0);
  const [options, setOptions] = useState<StringKAnyVPair[][]>([]);
  const [selectedValues, setSelectedValues] = useState<any[]>([]);

  const isPartOfOptionSelected = () => {
    return selectedValues.length > 0 && selectedValues.length < optionNum;
  };

  const onCheckOption = (option: StringKAnyVPair) => {
    let selected: any[] = [];

    if (option.isAll) {
      if (selectedValues.length !== optionNum) {
        selected = items.filter((e) => !e.isAll).map((e) => e.value);
      }
    } else {
      const idx = selectedValues.indexOf(option.value);

      if (idx > -1) {
        selectedValues.splice(idx, 1);
        selected = [...selectedValues];
      } else {
        selected = [...selectedValues, option.value];
      }
    }

    setSelectedValues(selected);

    if ('function' === typeof props.onChange) {
      props.onChange(selected);
    }

    if ('function' === typeof props.onSelectOption) {
      const selOpts: StringKAnyVPair[] = [];

      items.forEach((e) => {
        if (selected.indexOf(e.value) > -1) {
          selOpts.push({ value: e.value, label: e.lable });
        }
      });

      props.onSelectOption(selOpts);
    }
  };

  React.useEffect(() => {
    if (!isEqualObject(items, props.options)) {
      const opts: StringKAnyVPair[][] = [];
      const valItems: StringKAnyVPair[] = [];

      setItems(props.options);

      props.options.forEach((e) => {
        if (e.isAll) {
          setAllOption(e);
        } else {
          valItems.push(e);
        }
      });
      valItems.forEach((e, i) => {
        if (i % col === 0) {
          opts.push([e]);
        } else {
          opts[opts.length - 1].push(e);
        }
      });
      setOptionNum(valItems.length);
      setOptions(opts);
    }

    if (props.value && !isEqualObject(lastSelectedValues, props.value)) {
      setLastSelectedValues(JSON.parse(JSON.stringify(props.value)));
      setSelectedValues(JSON.parse(JSON.stringify(props.value)));
    }
  }, [col, items, lastSelectedValues, props]);

  return (
    <Col>
      {allOption.isAll ? (
        <Row justify="center">
          <div className="check-all">
            <Checkbox
              checked={selectedValues.length === optionNum}
              indeterminate={isPartOfOptionSelected()}
              onChange={() => onCheckOption(allOption)}
            >
              {allOption.label}
            </Checkbox>
          </div>
        </Row>
      ) : (
        ''
      )}
      <OptionBox>
        {options.map((row, i) => (
          <Row key={i}>
            {row.map((opt, j) => (
              <Col span={24 / col} key={j}>
                <Checkbox
                  checked={selectedValues.indexOf(opt.value) > -1}
                  onChange={() => onCheckOption(opt)}
                >
                  {opt.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        ))}
      </OptionBox>
    </Col>
  );
}
CheckContainer.defaultProps = {
  col: 2,
  onChange: undefined,
  onSelectOption: undefined,
  value: [],
};
export default CheckContainer;
