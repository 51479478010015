import styled from 'styled-components';

export const AuditContentWrapper = styled.div`
  margin-top: 10px;
  padding: 2px;
  background-color: #f5f5f5;
  border-radius: 4px;

  & .ant-tabs {
    overflow: visible;
  }

  & .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 2px;
  }

  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: #DCDCDC;
  }

  & .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #FFF;
  }

  & .ant-tabs-tabpane {
    height: calc(100vh - 332px);
    overflow-y: auto;
  }
`;

export const PathBreadcrumb = styled.div`
  & .ant-breadcrumb {
    color: #40a9ff;
    font-size: 1.2em;
  }

  & .ant-breadcrumb-link {
    cursor: pointer;
  }

  & .ant-breadcrumb > span:last-child .ant-breadcrumb-link {
    cursor: default;
  }
`;

export const TabPanelContentWrapper = styled.div`
  padding: 0 10px 10px 10px;
  background-color: #fff;
`;

export const TitlebarCheckboxContainer = styled.span`
  margin: 0 10px;
`;
