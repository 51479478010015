import React, { useCallback, useEffect, useState } from 'react';
import { Row, Space, Form, Spin } from 'antd';
import {
  patchWarehouseLocation,
  fetchWarehouseLocationEntity,
} from 'services/warehouse';
import {
  FormSubmitButton,
  TextArea,
  ModalPlus as Modal,
  FormCancelButton,
  FormPlus,
  message,
} from 'components/common';

type Props = {
  warehouseId: string;
  locationId: string;
  onHide: () => void;
};

export default function AddNoteDialog(props: Props): JSX.Element {
  const { onHide, warehouseId, locationId } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const layout = {
    wrapperCol: { span: 24 },
  };
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const locationEntity = await fetchWarehouseLocationEntity(
        warehouseId,
        locationId
      );
      if (locationEntity) {
        form.setFieldsValue({ note: locationEntity.note });
      }
      setLoading(false);
    };
    if (warehouseId && locationId) {
      fetch();
    }
  }, [form, warehouseId, locationId]);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      form.validateFields();
    } catch (e) {
      return;
    }
    if (!warehouseId) {
      return message.success({ content: 'WarehouseInfo no found' });
    }
    setSaveLoading(true);
    const notes = form.getFieldValue('note');
    const ok = await patchWarehouseLocation(warehouseId, locationId, notes);
    if (ok) {
      setSaveLoading(false);
      message.success({ content: 'Updated Location Note Successfully!' });
      onHide();
    }
  }, [form, warehouseId, locationId, onHide]);
  return (
    <Modal
      title="Location Notes"
      centered
      visible
      width={450}
      destroyOnClose
      getContainer={false}
      forceRender
      form={form}
      onClose={() => {
        onHide();
      }}
      footer={
        <Row justify="end">
          <Space>
            <FormSubmitButton
              id="edit_btn"
              loading={saveLoading}
              changed={changed}
              edited={edited}
              onSubmit={handleSubmit}
              onEdit={() => {
                setEdited(true);
              }}
            />
            <FormCancelButton
              id="cancel_btn"
              currentform={form}
              onClick={() => {
                onHide();
              }}
            />
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <FormPlus
          {...layout}
          form={form}
          className="compact-form"
          onChanged={(changed: boolean) => {
            setChanged(changed);
          }}
        >
          <Form.Item
            name="note"
            label=""
            rules={[{ required: true, message: 'Please input Notes!' }]}
          >
            <TextArea autoFocus edited={edited} rows={5} />
          </Form.Item>
        </FormPlus>
      </Spin>
    </Modal>
  );
}
