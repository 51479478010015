import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Row,
  Tooltip,
  Modal,
  Button,
  Space,
  Select,
  Card,
  Tag,
  Spin,
} from 'antd';
// import { shallowEqual, useSelector } from 'react-redux';
import { editRouteRules, getRuleByID } from 'services/routeRule';
// import { leaveEditForm } from 'utils';
// import { SelectCountry } from 'components/common';
// import { queryState } from 'services/country';
// import { SelectWarehouse } from 'components/common';
// import { RootState } from 'reducers';
import {
  PlusOutlined,
  DeleteOutlined,
  CaretUpOutlined,
  LoadingOutlined,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons';
// import {Loading} from 'components/common';
import { FormLabel } from 'components/common';
import { message } from 'components/common';
import { getAllWarehouse } from 'services/warehouse';
import { getCountrys, getStates } from 'services/address';
import { sortByString } from 'utils'

type ItemType = {
  item1: number;
  item2: string;
};
interface CreateProps {
  visible: boolean;
  channels: StoreChannelRow[];
  onHide: () => void;
  rule: any;
  onRefresh: () => void;
}
const EditDialog = (props: CreateProps) => {
  const { visible, onHide, onRefresh, channels, rule } = props;
  const [loading, setLoading] = useState(false);
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [ruleInfo, setRultInfo] = useState<any>();

  const [warehouseList, setWareHouseList] = useState<WarehouseRow[]>([]);
  const [states, setStates] = useState<string[]>([]);
  const [zips, setZips] = useState<string[]>([]);
  const [channelList, setChannelList] = useState<StoreChannelRow[]>([]);
  // const [stateList, setStateList] = useState<string[]>([]);
  const [stateList, setStateList] = useState<StateRow[]>([]);
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  // const warehouses = useSelector(
  //   (state: RootState) => state.admin.warehouses,
  //   shallowEqual
  // );
  const [warehouses, setWarehouses] = React.useState<any[]>();

  const [form] = Form.useForm();

  const getWarehouses = async () => {
    try {
      const res = await getAllWarehouse();
      setWarehouses(
        res.map((item: any) => {
          return { ...item, id: item.warehouseNum, code: item.warehouseCode };
        })
      );
    } catch (error) { }
  };

  const fetchCountryList = async () => {
    try {
      setLoading(true);
      const res = await getCountrys();
      setLoading(false);
      if (res.isSuccess) {
        setCountryList(res.data);
      }
      if (rule.country) {
        const temp = res.data.filter((i: any) => i.name === rule.country);
        if (temp.length > 0) {
          fetchStateList(temp[0].countryNum);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchStateList = async (id: number) => {
    try {
      setLoading(true);
      const res = await getStates(id);
      setLoading(false);
      if (res.isSuccess) {
        // setStateList(res.data);
        setStateList(res.data.sort((a:any,b:any)=> sortByString(a, b, 'code')));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getRuleiInfo = async () => {
    try {
      setLoading(true);
      const res = await getRuleByID(rule.routeRuleId);
      setLoading(false);
      if (res.isSuccess) {
        setRultInfo(res.data);
        setStates(res.data.routeStatesList.map((item: ItemType) => item.item2));
        setZips(res.data.routeZipCodeList.map((item: ItemType) => item.item2));
        setChannelList(() => {
          const a = res.data.routeChannelList.map(
            (item: ItemType) => item.item2
          );
          return channels.filter((item) => a.includes(item.channelAccountName));
        });
        setWareHouseList(
          res.data.routeWarehouseList
            .map((item: ItemType) => {
              return warehouses?.filter((i) => i.code === item.item2)[0];
            })
            .filter((item: any) => item)
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;

    const tempZips = ruleInfo.routeZipCodeList.map(
      (item: ItemType) => item.item2
    );
    const addZips = zips.map((item) => {
      if (tempZips.indexOf(item) === -1) {
        return { ZipCode: item };
      } else {
        return null;
      }
    });
    const delZips = tempZips.map((item: string, index: number) => {
      if (zips.indexOf(item) === -1) {
        return {
          RouteZipCodeNum: ruleInfo.routeZipCodeList[index].item1,
        };
      } else {
        return null;
      }
    });
    const postZips = [...addZips, ...delZips].filter((item) => item);

    const tempStates = ruleInfo.routeStatesList.map(
      (item: ItemType) => item.item2
    );
    const addStates = states.map((item) => {
      if (tempStates.indexOf(item) === -1) {
        return { States: item };
      } else {
        return null;
      }
    });
    const delStates = tempStates.map((item: string, index: number) => {
      if (states.indexOf(item) === -1) {
        return {
          RouteStatesNum: ruleInfo.routeStatesList[index].item1,
        };
      } else {
        return null;
      }
    });
    const postStates = [...addStates, ...delStates].filter((item) => item);

    const addChannels = channelList.map((item) => {
      return {
        ChannelNum: item.channelAccountNum,
      };
    });
    const delChannels = ruleInfo.routeChannelList.map((item: ItemType) => {
      return { RouteChannelNum: item.item1 };
    });

    const postChannels = [...delChannels, ...addChannels];

    const addWarehouse = warehouseList.map((item, index) => {
      return {
        WarehouseNum: item.id,
        Priority: index + 1,
      };
    });
    const delWarehouse = ruleInfo.routeWarehouseList.map((item: ItemType) => {
      return { RouteWarehouseNum: item.item1 };
    });
    const postWarehouse = [...delWarehouse, ...addWarehouse];
    const postParams = {
      RuleName: ruleInfo.default ? ruleInfo.ruleName : params.RuleName,
      Country: ruleInfo.default ? ruleInfo.country : params.Country,
      Seq: ruleInfo.seq,
      default: ruleInfo.default,
      RouteZipCodeList: postZips,
      RouteStatesList: postStates,
      RouteChannelList: postChannels,
      RouteWarehouseList: postWarehouse,
    };

    console.log(postParams);

    setLoading(true);
    try {
      const result = await editRouteRules(ruleInfo.routeRuleId, postParams);
      setLoading(false);
      if (result) {
        form.resetFields();
        onHide();
        onRefresh();
        message.success({ content: 'Save Rule Successfully！' });
      } else {
        message.error({ content: 'Save was failed' });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCancel = useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  // const handleWarehouseSelectChange = useCallback((wh: WarehouseRow) => {
  //   setWarehouse(wh);
  // }, []);

  const handleWarehouseSelectChange = useCallback(
    (id) => {
      setWarehouse(
        warehouses?.filter((item) => item.warehouseNum === Number(id))[0]
      );
    },
    [warehouses]
  );

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  /* eslint-disable */
  useEffect(() => {
    getWarehouses();
    // fetchStateList();
    fetchCountryList();
    // getRuleiInfo();
  }, []);
  useEffect(() => {
    if (warehouses) {
      getRuleiInfo();
    }
  }, [warehouses]);

  /* eslint-enable */

  return (
    <Modal
      title={rule.default ? 'Edit Routing Rule (default)' : 'Edit Routing Rule'}
      centered
      visible={visible}
      maskClosable={false}
      bodyStyle={{
        padding: 8,
      }}
      width={900}
      destroyOnClose
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              loading={loading}
              icon={loading ? <LoadingOutlined /> : <SaveOutlined />}
            >
              {loading ? 'Saving' : 'Save'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        style={{ width: '100%' }}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {ruleInfo && (
          <Form {...layout} form={form}>
            {!ruleInfo.default && (
              <Card size="small" title="Condition options">
                <Form.Item
                  name="RuleName"
                  initialValue={ruleInfo.ruleName}
                  label={<FormLabel>Rule Name</FormLabel>}
                  rules={[
                    { required: true, message: 'Please Input Rule Name!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="Country"
                  initialValue={ruleInfo.country}
                  label={<FormLabel>Country</FormLabel>}
                  rules={[
                    { required: true, message: 'Please Select Country!' },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="label"
                    onChange={(value: number, option: any) => {
                      form.setFieldsValue({
                        state: undefined,
                      });
                      fetchStateList(option.key);
                    }}
                  >
                    {countryList
                      .filter((i) => i.name === 'United States')
                      .map((item) => (
                        <Select.Option
                          key={item.countryNum}
                          value={item.name}
                          label={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label={<FormLabel>State</FormLabel>}>
                  <Form.Item name="state" noStyle>
                    <Select
                      style={{ width: 320 }}
                      showSearch
                      optionFilterProp="label"
                    >
                      {stateList.map((item, index) => (
                        <Select.Option
                          value={item.name}
                          key={`state${index}`}
                          label={item.name}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      if (!form.getFieldValue('state')) return;
                      if (!states.includes(form.getFieldValue('state'))) {
                        setStates((prev) => [
                          ...prev,
                          form.getFieldValue('state'),
                        ]);
                      }
                      form.setFieldsValue({ state: '' });
                    }}
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>

                <Form.Item label={<FormLabel noColon>{''}</FormLabel>}>
                  <Card
                    bodyStyle={{ padding: 4, height: 30, overflow: 'auto' }}
                  >
                    {states.map((item) => (
                      <Tag
                        key={item}
                        closable
                        onClose={() => {
                          const a = states.filter((tag) => tag !== item);
                          setStates([...a]);
                        }}
                      >
                        {item}
                      </Tag>
                    ))}
                  </Card>
                </Form.Item>

                <Form.Item label={<FormLabel>Zip Code</FormLabel>}>
                  <Form.Item name="zip" noStyle>
                    <Input style={{ width: 320 }} />
                  </Form.Item>
                  <Button
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      if (!form.getFieldValue('zip')) return;
                      if (!zips.includes(form.getFieldValue('zip'))) {
                        setZips((prev) => [...prev, form.getFieldValue('zip')]);
                      }
                      form.setFieldsValue({ zip: undefined });
                    }}
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>

                <Form.Item label={<FormLabel noColon>{''}</FormLabel>}>
                  <Card
                    bodyStyle={{ padding: 4, height: 30, overflow: 'auto' }}
                  >
                    {zips.map((item) => (
                      <Tag
                        key={item}
                        closable
                        onClose={() => {
                          const a = zips.filter((tag) => tag !== item);
                          setZips([...a]);
                        }}
                      >
                        {item}
                      </Tag>
                    ))}
                  </Card>
                </Form.Item>

                <Form.Item label={<FormLabel>Channel Account</FormLabel>}>
                  <Form.Item name="channel" noStyle>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      style={{ width: 320 }}
                    >
                      {channels.map((item, index) => (
                        <Select.Option
                          value={item.channelAccountNum}
                          key={`channel${index}`}
                          label={item.channelAccountName}
                        >
                          {item.channelAccountName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      if (!form.getFieldValue('channel')) return;
                      const a = channelList.map((item) => {
                        return item.channelAccountNum;
                      });
                      if (!a.includes(form.getFieldValue('channel'))) {
                        setChannelList((prev) => [
                          ...prev,
                          channels.filter(
                            (i) =>
                              i.channelAccountNum ===
                              form.getFieldValue('channel')
                          )[0],
                        ]);
                      }
                      form.setFieldsValue({ channel: '' });
                    }}
                  >
                    <PlusOutlined />
                    Add
                  </Button>
                </Form.Item>

                <Form.Item label={<FormLabel noColon>{''}</FormLabel>}>
                  <Card
                    bodyStyle={{ padding: 4, height: 30, overflow: 'auto' }}
                  >
                    {channelList.map((item) => (
                      <Tag
                        key={item.channelAccountNum}
                        closable
                        onClose={() => {
                          const a = channelList.filter(
                            (tag) =>
                              tag.channelAccountNum !== item.channelAccountNum
                          );
                          setChannelList([...a]);
                        }}
                      >
                        {item.channelAccountName}
                      </Tag>
                    ))}
                  </Card>
                </Form.Item>
              </Card>
            )}
            <Card size="small" title="Priority" style={{ marginTop: 10 }}>
              <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                <Form.Item name="warehouse" noStyle>
                  {/* <SelectWarehouse
                    width={160}
                    placeholder="Select Warehouse"
                    onChange={handleWarehouseSelectChange}
                  /> */}
                  <Select
                    style={{ width: 320 }}
                    onChange={handleWarehouseSelectChange}
                    showSearch
                    optionFilterProp="label"
                  >
                    {warehouses?.map((item) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.code}
                      >
                        {item.code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() => {
                    if (!warehouse) return;
                    const a = warehouseList.map((item) => item.id);
                    if (!a.includes(warehouse.id)) {
                      setWareHouseList((prov) => [...prov, warehouse]);
                    }
                    form.setFieldsValue({ warehouse: '' });
                  }}
                >
                  <PlusOutlined />
                  Add
                </Button>
              </Form.Item>

              <Form.Item label={<FormLabel noColon>{''}</FormLabel>}>
                <Card
                  bodyStyle={{
                    padding: 4,
                    height: ruleInfo.default ? 100 : 50,
                    overflow: 'auto',
                  }}
                >
                  {warehouseList.map((item, index) => (
                    <Row
                      key={`warehouse${index}`}
                      justify="space-between"
                      style={{ marginBottom: 3 }}
                    >
                      <Tag
                        onClose={() => {
                          const a = warehouseList.filter(
                            (tag) => tag.id !== item.id
                          );
                          setWareHouseList([...a]);
                        }}
                      >
                        {item?.code}
                      </Tag>
                      <Space>
                        {index !== 0 && (
                          <Tooltip
                            title="Increase priority"
                            placement="topRight"
                          >
                            <Button
                              icon={<CaretUpOutlined />}
                              onClick={() => {
                                setWareHouseList((prev) => {
                                  const t = [...prev];
                                  const temp = t[index];
                                  t[index] = t[index - 1];
                                  t[index - 1] = temp;
                                  return [...t];
                                });
                              }}
                            />
                          </Tooltip>
                        )}
                        <Tooltip title="Remove Item" placement="topRight">
                          <Button
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              const a = warehouseList.filter(
                                (tag) => tag.id !== item.id
                              );
                              setWareHouseList([...a]);
                            }}
                          />
                        </Tooltip>
                      </Space>
                    </Row>
                  ))}
                </Card>
              </Form.Item>
            </Card>
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default EditDialog;
