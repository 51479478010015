import React from 'react';
import { Row, Select } from 'antd';

type LocationOrContainerProps = {
  value?: string;
  onChange?: (value: string) => void;
};
export default function LocationOrContainer(
  props: LocationOrContainerProps
): JSX.Element {
  const { onChange, value } = props;
  return (
    <Row>
      <Select
        style={{ width: 150 }}
        value={value || ''}
        onChange={(newValue) => {
          onChange?.(newValue);
        }}
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value="locations">Only Locations</Select.Option>
        <Select.Option value="containers">Only Containers</Select.Option>
      </Select>
    </Row>
  );
}
LocationOrContainer.defaultProps = {
  value: undefined,
  onChange: undefined,
};
