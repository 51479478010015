import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import InventoryMoveMerhge from 'components/Inventory/MoveMerge';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <InventoryMoveMerhge />
      </SiteContent>
    </ContentLayout>
  );
};
