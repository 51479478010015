import React, { useCallback } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { playAudio } from 'utils';
import { message } from 'components/common';
import SmartTable from './SmartTable';
import { Modal, Typography } from 'antd';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartRow,
  SmartText,
  SmartBackButton,
  SmartBlock,
  SmartButton,
} from '../../../SmartComponent';
import { fetchWarehouseProductList } from 'services/inventory';
// import { queryLocationsByKeyword } from 'services/warehouse';
import { useTranslation } from 'react-i18next';

const { Text, Link } = Typography;

type ScanLocationProps = {
  order: SmartPickOrder;
  product: SmartPickOrderItem;
  locations: WarehouseLocationRow[];
  warehouseId: string;
  onBack: () => void;
  onSubmit: (location: WarehouseLocationRow) => void;
};
export default function ScanLocation(props: ScanLocationProps): JSX.Element {
  const { order, onSubmit, onBack, product, locations, warehouseId } = props;
  const { t } = useTranslation();
  //const [realLocations, setRealLocations] = React.useState();
  // try to fix error of TS2532
  const [realLocations, setRealLocations] = React.useState<StringKAnyVPair[]>();
  const [loading, setLoading] = React.useState(false);

  const fetchRealInventory = useCallback(async () => {
    try {
      const query = {
        $skip: 0,
        $top: 1000,
        $sortBy: 'locationCode',
        uniqueCode: product.sku,
      };
      setLoading(true);
      const res = await fetchWarehouseProductList(warehouseId, query);
      setLoading(false);
      setRealLocations(res.data);
    } catch (error) {
      setLoading(false);
    }
  }, [product, warehouseId]);

  /**
   * handle location key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        //Check whether the sku is in the order range
        const pickLocation = locations.filter(
          (item) => item.code === nextValue
        );
        if (pickLocation.length < 1) {
          playAudio('error');
          message.warning(`'${nextValue}' invalid!`);
          return;
        } else {
          playAudio('success');
          const temp = (
            realLocations ? realLocations : product.location || []
          ).filter((item) => item.locationCode === nextValue);
          if (temp.length < 1) {
            Modal.confirm({
              title: t("smart.pick.areYouContinue"),
              icon: <ExclamationCircleOutlined />,
              content: t("smart.pick.areYouContinueContent",{ value: nextValue }),
              okText: t("common.yes"),
              onOk() {
                onSubmit(pickLocation[0]);
              },
              onCancel() {
                return;
              },
            });
          } else {
            onSubmit(pickLocation[0]);
          }
        }
      }
    },
    [onSubmit, locations, product, realLocations, t]
  );
  return (
    <>
      <SmartSpace>
        <SmartRow>
          <SmartFormLabel>
            {t("common.order")}
          </SmartFormLabel>
          <SmartText>{order.fulfillmentOrderNum}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.sku")}
          </SmartFormLabel>
          <SmartText>{product.sku}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.upc")}
          </SmartFormLabel>
          <SmartText>{product.upc}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {t("common.title")}
          </SmartFormLabel>
          <SmartText>{product.title}</SmartText>
        </SmartRow>
        <SmartRow>
          <SmartFormLabel>
            {realLocations ? 
              t("smart.pick.realTimeLocationInSystem")
            : 
              t("smart.pick.locationInSystem")
            }
          </SmartFormLabel>
        </SmartRow>
        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            width: '100%',
          }}
        >
          {(realLocations ? realLocations : product.location || [])
            .filter(
              (i) =>
                i.quantity > 0 &&
                i.locationCode !== 'Pick' &&
                i.locationCode !== 'Checkout'
            )
            .map((item: any) => (
              <Text
                key={item.locationCode}
                style={{
                  fontSize: 16,
                  marginRight: 6,
                }}
              >
                {item.locationCode === 'General' ? (
                  <Link onClick={() => handleScannerChange('General')}>
                    General
                  </Link>
                ) : (
                  <Text strong>{item.locationCode}</Text>
                )}
                {/* <Link onClick={() => handleScannerChange(item.locationCode)}>
                  {item.locationCode}
                </Link> */}
                {`(${item.quantity})`}
              </Text>
            ))}
        </div>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanLocationContinue")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartTable dataSource={[...order.items]} />
      <div style={{ marginTop: 10 }}>
        <SmartBackButton>
          <SmartBlock>
            <SmartButton
              onClick={() => fetchRealInventory()}
              color="write"
              loading={loading}
            >
              {t("smart.pick.pullRealTimeInventory")}
            </SmartButton>
            <SmartButton onClick={onBack} backgroundColor="red" color="write">
              <Typography.Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  whiteSpace: 'normal',
                  width: 230,
                  color: '#fff'
                }}
              >
                {t("smart.pick.chooseAnotherSku")}
                </Typography.Text>
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </>
  );
}
