import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import CheckLocation from 'components/Sales/Smart/Audit/CheckLocation';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartMerge(): JSX.Element {
  return (
    <ContentLayout titleKey="titles.checkLocation" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}>
      <CheckLocation />
    </ContentLayout>
  );
}
