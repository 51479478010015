import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playAudio } from 'utils';
import { fetchPickingBySKU } from 'services/smart';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type ScanSKUProps = {
  onSubmit: (product: SmartPickSKU) => void;
};
export default function ScanSKU(props: ScanSKUProps): JSX.Element {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  /**
   * handle sku key down event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        setFetching(true);
        try {
          const res = await fetchPickingBySKU(nextValue);
          setFetching(false);
          if (res.isSuccess) {
            if (res.data.pickQty <= 0) {
              playAudio('error');
              message.warning(`'${nextValue}' had done!`);
            } else {
              playAudio('success');
              onSubmit(res.data);
            }
          } else {
            playAudio('error');
            message.warning(`'${nextValue}' not found!`);
          }
        } catch (error) {
          setFetching(false);
        }
      }
    },
    [onSubmit]
  );
  return (
    <>
      <SmartSpin loading={fetching} />
      <SmartSpace>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanToStart")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartBackButton
        onClick={() => {
          history.goBack();
        }}
      />
    </>
  );
}
