import { digitBridgeApi } from './http';

type ParamsType = {
    start: string;
    end: string;
}
// export const getDashboardOrderInfo = async (warehouseNum: number, params: ParamsType): Promise<any> => {
//     const json = JSON.stringify(params)
//     return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/orders`, json);
// };

// export const getDashboardShipmentsInfo = async (warehouseNum: number, params: ParamsType): Promise<any> => {
//     const json = JSON.stringify(params)
//     return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/shipments`, json);
// };

export const getDashboardOrderInfo = async (warehouseNum: number, params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/orders/info`, json);
};

export const getDashboardOrderPending = async (warehouseNum: number, params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/orders/pending`, json);
};

export const getDashboardShipmentsPending = async (warehouseNum: number, params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/shipments/pending`, json);
};

export const getDashboardShipmentsShipped = async (warehouseNum: number, params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/shipments/shipped`, json);
};

export const getDashboardShipmentsSpecial = async (warehouseNum: number, params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/shipments/special`, json);
};

export const getDashboardInventoryInfo = async (warehouseNum: number): Promise<any> => {
    return await digitBridgeApi.post(`/api/dashboard/${warehouseNum}/inventory`);
};

export const getDashboardTransferFailed = async ( params: ParamsType): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/dashboard/shipments/transfer/failed`, json);
};
