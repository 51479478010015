import React from 'react';

function WarehouseTransferSVG({ style = {}, height = 16, width = 16, viewBox = '0 0 24 24', className = '' }) {
  return (
    <svg
      className={className}
      height={height}
      viewBox={viewBox}
      width={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.0175 7.47374L9.26192 3.47571L1.50635 7.47374M17.0175 7.47374L9.26192 11.4718M17.0175 7.47374V12.4043M9.26192 11.4718L1.50635 7.47374M9.26192 11.4718V21.4669L1.50635 17.4688V7.47374M22.5063 15.9636H12.305L13.8632 12.7896M12.305 19.3018H22.5063L20.9481 22.4757" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
}

export default WarehouseTransferSVG;
