import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import MoveWarehouse from 'components/Sales/Smart/MoveMerge/MoveWarehouse';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Page(): JSX.Element {
  return (
    <ContentLayout 
      titleKey="titles.moveWarehouse" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}

    // title={`Move products from one warehouse to another(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <MoveWarehouse />
    </ContentLayout>
  );
}




