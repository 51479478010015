import React, { useState, useCallback } from 'react';
import {
  Form,
  Input,
  Spin,
  Button,
  DatePicker,
  Card,
  Space,
  message,
  Select,
} from 'antd';
import { FormLabel } from 'components/common';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { editProductLot } from 'services/lot'

interface EditProps extends ModalProps {
  onClose: Function;
  onSuccess: Function;
  lotInfo: ProductLot
}

export default function Dialog(props: EditProps) {
  const { onSuccess, lotInfo } = props;
  const [loading, setLoading] = useState(false);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);

  const [form] = Form.useForm();

  const closeDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    console.log(params);
    try {
      setLoading(true);
      //todo generate request
      const res = await editProductLot({
        ...lotInfo,
        ...params,
      });
      setLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Save successfully' });  
        onSuccess()
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={[
          <Space>
            <Button onClick={() => closeDetailDialog()}>Cancel</Button>
            <Button type="primary" onClick={()=>handleSave()}>
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Space>,
        ]}
        fullscreen={true}
        maskClosable={false}
        onClose={closeDetailDialog}
        onFullscreen={onFullscreen}
        title="Edit LOT#"
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <Card style={{ height: '100%' }}>
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          >
            <Form
              {...layout}
              form={form}
              style={{ marginTop: 20 }}
              onFinish={handleSave}
            >

              <Form.Item name="lotNumber" initialValue={lotInfo.lotNumber} label={<FormLabel>LOT#</FormLabel>}>
                <Input disabled/>
              </Form.Item>

              <Form.Item name="status" initialValue={lotInfo.status} label={<FormLabel>Status</FormLabel>}>
                <Select>
                  <Select.Option value={0}>Inactive</Select.Option>
                  <Select.Option value={1}>Active</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="firstInDate"
                initialValue={lotInfo.firstInDate ? moment(lotInfo.firstInDate) : undefined}
                label={<FormLabel>First In Date</FormLabel>}
                rules={[
                  { required: true, message: 'Please input First In Date!' },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="expirationDate"
                initialValue={lotInfo.expirationDate ? moment(lotInfo.expirationDate) : undefined}
                label={<FormLabel>Expiration Date</FormLabel>}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="manufactionDate"
                initialValue={lotInfo.manufactionDate ? moment(lotInfo.manufactionDate) : undefined}
                label={<FormLabel>Manufacuring Date</FormLabel>}
                extra="You can set the default manufacturing date to the current date upon receipt and override it to the date of manufacture at the supplier, if applicable."
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="supplierLot"
                initialValue={lotInfo.supplierLot}
                label={<FormLabel>Supplier LOT#</FormLabel>}
              >
                <Input />
              </Form.Item>

              <Form.Item name="note" initialValue={lotInfo.note} label={<FormLabel>Note</FormLabel>}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </ModalDialog>
    </>
  );
}
