/**
 * lot manage service requests are here.
 */
import { digitBridgeApi } from './http';

type FetchProductLotListParams = {
  sku?: string;
  lot?: string;
  status?: number;
}

export const fetchProductLotList = async (parms: FetchProductLotListParams, pagination: { pageIndex: number, pageSize: number }): Promise<any> => {
  const json = JSON.stringify(parms)
  return await digitBridgeApi.post('api/products/lots/list', json, { params: { ...pagination } });
}

export const editProductLot = async (params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.patch('api/products/lots', json);
}

export const deleteProductLot = async (parms: any): Promise<any> => {
  const json = JSON.stringify(parms)
  return await digitBridgeApi.delete('api/products/lots/remove', { data: json });
}

type CreateLotNumberParams = {
  TimeZone: string,
  Generate: boolean,
  Unique: boolean,
  Items: {
    // FulfillmentProductNum: number,
    SKU: string,
    LotNumber?: string,
    FirstInDate: string,
    ExpirationDate?: string,
    ManufactionDate?: string,
    SupplierLot?: string,
    Note?: string
  }[],
}

export const createLotNumber = async (params: CreateLotNumberParams): Promise<any> => await digitBridgeApi.post('/api/products/lots', JSON.stringify(params));
