import React from 'react';
import { Button, ButtonProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface CreateButtonProps extends ButtonProps {
  title?: string;
}
export default function CreateButton(props: CreateButtonProps): JSX.Element {
  const { title = 'Add', ...other } = props;
  return (
    <Button
      type="primary"
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
    >
      <PlusOutlined />
      {title}
    </Button>
  );
}
CreateButton.defaultProps = {
  title: undefined,
};
