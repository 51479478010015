import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Card,
  Form,
  Col,
  Tabs,
  Typography,
  Spin,
  Space,
  Button,
  Menu,
  Dropdown,
  Modal,
  Descriptions,
} from 'antd';
import {
  ExclamationCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  CaretDownOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  fetchPo,
  patchPo,
  fetchPoLineList,
  // delPurchaseOrder,
  poControlStatusClose,
  poControlStatusOpen,
  abandonPurchaseOrder,
} from 'services/purchaseOrder';
import { convertTimeByUtc, getRouterParameters } from 'utils';
import { useHistory } from 'react-router-dom';
import {
  ColumnDataType,
  ExportCSV,
  FormSubmitButton,
  GreyCardWrapper,
  message,
  FormLabel,
  HeaderTitle,
  DataGridPlus,
  TextArea,
  FormPlus,
  CopySpan,
} from 'components/common';
import { poControlStatus, poStatusMap } from 'constants/enums'
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/config';

const { Text } = Typography;
type PoDetailProps = {
  isModal?: boolean;
  poInfo?: any;
}

const PoDetail = (props:PoDetailProps) => {
  const [data, setData] = useState<Array<PoLineRow>>([]);
  const [record, setRecord] = useState<PoRow | null>();
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const [values, setValues] = useState<StringKAnyVPair>({});
  const [snapshot, setSnapshot] = useState<StringKAnyVPair>({});
  const [form] = Form.useForm();
  const history = useHistory();
  const detailLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const columns = [
    {
      header: 'SKU',
      name: 'sku',
      minWidth: 220,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Part Number',
      name: 'partNumber',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Product Name',
      name: 'title',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Quantity',
      name: 'quantity',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Picture',
      name: 'thumbnailUrl',
      userSelect: true,
      dataType: ColumnDataType.IMAGE,
    },
    {
      header: 'Public Notes',
      name: 'publicNote',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Private Notes',
      name: 'privateNote',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Received Quantity',
      name: 'humanReceiveQty',
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data: row } = value;
        const { humanReceiveQty, quantity } = row;
        return <Text>{`${humanReceiveQty}/${quantity}`}</Text>;
      },
    },
    {
      header: 'Waiting for Closing',
      name: 'waitCloseQty',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Closing',
      name: 'closingQty',
      userSelect: true,
      defaultFlex: 1,
    },
  ];
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      let poNumber = '';
      if(props.isModal && props.poInfo){
        poNumber = props.poInfo.poNumber
      } else {
        const parameters = getRouterParameters();
        poNumber = parameters['poNumber']
      }
    
      const newRecord = await fetchPo(poNumber);

      setRecord(newRecord);
      if (newRecord) {
        const newData = await fetchPoLineList(newRecord.poId);
        setData(newData);
        setValues(newRecord);
      } else {
        message.error('Fetch PO detail failed');
      }
      setLoading(false);
    };
    fetch();
  }, [props]);
  /**
   * delete Po
   */
  // const deletePo = useCallback(async () => {
  //   if (!record) {
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const res = await delPurchaseOrder(record.poId);
  //     setLoading(false);
  //     if (res) {
  //       message.success({ content: 'Deleted successfully' });
  //       history.push('/po/list');
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }, [record, history]);

  /**
   * Abandon Po
   */
  const abandonPo = useCallback(async () => {
    if (!record) {
      return;
    }
    try {
      setLoading(true);
      const res = await abandonPurchaseOrder(record.poId);
      setLoading(false);
      if (res) {
        message.success({ content: 'Abandoned successfully' });
        history.push('/po/list');
      }
    } catch (error) {
      setLoading(false);
    }
  }, [record, history]);

  /**
   * 
   */
  const updatePoControlStatus = useCallback(async (type: 'close' | 'open') => {
    if (!record) {
      return;
    }
    try {
      setLoading(true);
      const res = type === 'close' ? await poControlStatusClose(record.poId) : await poControlStatusOpen(record.poId);
      if (res) {
        message.success({ content: `${type === 'close' ? 'Close' : 'Reopen'} successfully` });
        const parameters = getRouterParameters();
        const newRecord = await fetchPo(parameters['poNumber']);
        setRecord(newRecord);
        if (newRecord) {
          setValues(newRecord);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [record]);
  /**
   * handle save publicNote/privateNote
   */
  const handleSave = useCallback(async () => {
    if (!record) {
      return;
    }
    setSaveLoading(true);
    await patchPo(record.poId, values.publicNote, values.privateNote);
    //renew record&status
    const parameters = getRouterParameters();
    const newRecord = await fetchPo(parameters['poNumber']);
    setRecord(newRecord);
    if (newRecord) {
      setValues(newRecord);
    }
    setSaveLoading(false);
    setEdited(false);
    setChanged(false);
    message.success({ content: 'Update Purchase Order Successfully!' });
  }, [record, values]);

  useEffect(() => {
    setSnapshot({ ...record });
    form.setFieldsValue(values);
  }, [record, form, values]);

  return record ?(
    <>
      {!props.isModal && <HeaderTitle
        breadcrumb={[
          'PO',
          {
            type: 'link',
            content: 'Purchase Order Details',
            url: '/po/list',
          },
          `${record.poNumber}`,
        ]}
      />}
      <Spin spinning={loading}>
        <Card>
          <Descriptions size="small" column={3} labelStyle={{ display: 'inline-block', width: 200, textAlign: 'right' }}>
            <Descriptions.Item label={<FormLabel noColon>PO#</FormLabel>}>
              <CopySpan value={record.poNumber} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Warehouse</FormLabel>}>
              <CopySpan value={record.warehouseCode} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Container #</FormLabel>}>
              <CopySpan value={record.container} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>PO Status</FormLabel>}>
              <CopySpan value={poStatusMap[record?.poStatusNum]} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Supplier</FormLabel>}>
              <CopySpan value={record.vendorName} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Created By</FormLabel>}>
              <CopySpan value={record.createBy} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Order Date</FormLabel>}>
              <CopySpan value={record.poDate ? convertTimeByUtc(dayjs(record.poDate).format(DEFAULT_DATE_TIME_FORMAT)) : ''} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Date Created</FormLabel>}>
              <CopySpan value={record.createDate ? convertTimeByUtc(dayjs(record.createDate).format(DEFAULT_DATE_TIME_FORMAT)) : ''} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Terms</FormLabel>}>
              <CopySpan value={record.terms} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Date Last Updated</FormLabel>}>
              <CopySpan value={record.updateDate ? convertTimeByUtc(dayjs(record.updateDate).format(DEFAULT_DATE_TIME_FORMAT)) : ''} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Order Cancel Date</FormLabel>}>
              <CopySpan value={record.cancelAfterDate ? convertTimeByUtc(dayjs(record.cancelAfterDate).format(DEFAULT_DATE_TIME_FORMAT)) : ''} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Control Status</FormLabel>}>
              <CopySpan value={poControlStatus[`${record.controlStatus}`] || ''} />
            </Descriptions.Item>
            <Descriptions.Item label={<FormLabel noColon>Doc ID</FormLabel>}>
              <CopySpan value={record.poUuid || ''} />
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Space>
            <ExportCSV
              columns={columns}
              data={data}
              scope="Purchase Order Line Items"
              title="Export Purchase Order Line Items"
            />
            <Dropdown
              overlay={
                <Menu>
                  {[0, 1].includes(record.controlStatus) && !record.receivedQuantity && (
                    <Menu.Item key="6">
                      <Button
                        id={`po_detail_more_abandon`}
                        type="text"
                        style={{ width: '100%', textAlign: 'left' }}
                        className="hover-danger-button-2"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          Modal.confirm({
                            title: 'Are you sure you want Abandon this PO?',
                            icon: <ExclamationCircleOutlined />,
                            content: `PO Number： ${record?.poNumber}`,
                            okText: 'Yes',
                            okType: 'default',
                            okButtonProps: { className: 'hover-danger-button' },
                            cancelText: 'No',
                            autoFocusButton: 'cancel',
                            onOk() {
                              abandonPo();
                            },
                            onCancel() { },
                          });
                        }}
                      >
                        Abandon
                      </Button>
                    </Menu.Item>
                  )}
                  {/* <Menu.Item key="4">
                    <Button
                      id={`po_detail_more_delete`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want delete this PO?',
                          icon: <ExclamationCircleOutlined />,
                          content: `PO Number： ${record?.poNumber}`,
                          okText: 'Yes',
                          okType: 'default',
                          okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onOk() {
                            deletePo();
                          },
                          onCancel() { },
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item> */}
                  <Menu.Item key="5">
                    {
                      record.controlStatus === 2 ?
                        <Button
                          id={`po_detail_more_open`}
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          icon={<RedoOutlined />}
                          onClick={() => {
                            updatePoControlStatus('open');
                          }}
                        >
                          Reopen
                        </Button> :
                        <Button
                          id={`po_detail_more_close`}
                          type="text"
                          style={{ width: '100%', textAlign: 'left' }}
                          className="hover-danger-button-2"
                          icon={<CloseOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              title: 'Are you sure you want to close this PO?',
                              icon: <ExclamationCircleOutlined />,
                              content: `PO Number： ${record?.poNumber}`,
                              okText: 'Yes',
                              okType: 'default',
                              okButtonProps: { className: 'hover-danger-button' },
                              cancelText: 'No',
                              autoFocusButton: 'cancel',
                              onOk() {
                                updatePoControlStatus('close');
                              },
                              onCancel() { },
                            });
                          }}
                        >
                          Close
                        </Button>
                    }
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button id={`po_detail_more_button`}>
                <CaretDownOutlined />
                More
              </Button>
            </Dropdown>
          </Space>
        </Row>
        <GreyCardWrapper style={{ marginTop: 20 }}>
          <Tabs defaultActiveKey="items" type="card">
            <Tabs.TabPane tab="Line Items" key="items">
              <GreyCardWrapper>
                <Row justify='start'>
                  <Button onClick={()=>{
                    const skulist = Array.from(new Set(data.map(i=>i.sku))).toString()
                    history.push(`/catalog/view?skulist=${skulist}`)
                  }}>Show items in Catalog</Button>
                </Row>
                <DataGridPlus
                  dataSource={data}
                  columns={columns}
                  idProperty="poLineNum"
                  loading={loading}
                  pagination={false}
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...({} as any)}
                />
              </GreyCardWrapper>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Details" key="details">
              <GreyCardWrapper>
                <FormPlus
                  className="compact-form"
                  /* eslint-disable react/jsx-props-no-spreading */
                  {...detailLayout}
                  snapshot={snapshot}
                  form={form}
                  onChanged={(changed: boolean) => {
                    setChanged(changed);
                    setValues(form.getFieldsValue());
                  }}
                >
                  <Row>
                    <Col span={12}>
                      <Card>
                        <Form.Item
                          label={<FormLabel>Shipping Carrier</FormLabel>}
                        >
                          <Text />
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Shipping Class</FormLabel>}
                        >
                          <Text />
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Ship To Warehouse</FormLabel>}
                        >
                          <Text>{record.warehouseCode}</Text>
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Ship To Address</FormLabel>}
                        >
                          <Text />
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Requested Ship Date</FormLabel>}
                        >
                          <Text>
                            {record.requestShipDate
                              ? dayjs(record.requestShipDate).format(
                                'MM/DD/YYYY'
                              )
                              : ''}
                          </Text>
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Actual Ship Date</FormLabel>}
                        >
                          <Text />
                        </Form.Item>
                        <Form.Item
                          label={<FormLabel>Arrival Due Date</FormLabel>}
                        >
                          <Text>
                            {record.arrvalDueDate
                              ? dayjs(record.arrvalDueDate).format('MM/DD/YYYY')
                              : ''}
                          </Text>
                        </Form.Item>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card>
                        <Row justify="end" style={{ marginBottom: 16 }}>
                          <FormSubmitButton
                            loading={saveLoading}
                            changed={changed}
                            edited={edited}
                            onEdit={() => {
                              setEdited(true);
                            }}
                            style={{ width: 180 }}
                            onSubmit={handleSave}
                          />
                        </Row>
                        <Form.Item
                          name="publicNote"
                          label={<FormLabel>Public Notes</FormLabel>}
                        >
                          <TextArea autoFocus rows={4} edited={edited} />
                        </Form.Item>
                        <Form.Item
                          name="privateNote"
                          label={<FormLabel>Private Notes</FormLabel>}
                        >
                          <TextArea rows={4} edited={edited} />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                </FormPlus>
              </GreyCardWrapper>
            </Tabs.TabPane>
          </Tabs>
        </GreyCardWrapper>
      </Spin>
    </>
  ): null;
};

export default PoDetail;
