import { Button, Input, Row, Space, Form } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  ModalPlus as Modal,
  DataGridPlus,
  DeleteButton,
} from 'components/common';
import { createTag, deleteTag } from 'services/tag';
import { useCache } from 'hooks';
import { RootState } from 'reducers';
import { CloseOutlined } from '@ant-design/icons';

type TagDialogProps = {
  onHide: () => void;
};
/**
 * Tags Dialog
 * list/remove/add
 * @param {TagDialogProps} props
 * @returns {JSX.Element}
 */
export default function TagDialog(props: TagDialogProps): JSX.Element {
  const { realodTagListCache } = useCache();
  const { onHide } = props;
  const [form] = Form.useForm();
  const tags = useSelector(
    (state: RootState) => state.admin.tags,
    shallowEqual
  );
  const columns = [
    {
      header: 'Name',
      name: 'tagName',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: '',
      defaultFlex: 0,
      render: (value: any) => {
        const { data: row } = value;
        return (
          <DeleteButton
            id="delete_btn"
            type="link"
            confirmContent={`name: ${row.tagName}`}
            confirmTitle="Are you sure you want to delete the tag ?"
            onClick={async () => {
              //delete tag
              await deleteTag(row.tagNum);
              //reload
              realodTagListCache();
            }}
          />
        );
      },
    },
  ];

  /**
   * handle add tag event
   */
  const handleAddTag = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const tag = form.getFieldValue('tag');
    await createTag(tag);
    //reload
    realodTagListCache();
    form.resetFields();
  }, [form, realodTagListCache]);
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="hideDialog_btn" onClick={onHide} icon={<CloseOutlined />}>
            Cancel
          </Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal title="Order Tags" visible onCancel={onHide} footer={<Footer />}>
      <DataGridPlus
        idProperty="tagNum"
        columns={columns}
        dataSource={tags}
        pagination={false}
        /* eslint-disable react/jsx-props-no-spreading */
        {...({} as any)}
      />
      <Form form={form}>
        <Row align="top" style={{ marginTop: 20 }}>
          <Form.Item
            name="tag"
            rules={[{ required: true, message: 'Please input tag!' }]}
          >
            <Input id="tag_input" style={{ width: 350 }} />
          </Form.Item>
          <Button
            id="addTag_btn"
            style={{ marginLeft: 15 }}
            onClick={handleAddTag}
          >
            Add Tag
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}
