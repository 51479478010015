import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import VirtualWarehouseToLocation from 'components/Sales/Smart/VirtualWarehouseToLocation';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartPickScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.virtualWarehouseToLocation" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}

    // title={`Virtual Warehouse to Location(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <VirtualWarehouseToLocation />
    </ContentLayout>
  );
}
