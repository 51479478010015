import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps, Modal } from 'antd';
import theme from 'assets/styles/theme';
import { ButtonIcon, DangerIcon } from './styles';

const DeleteButton = styled(Button)`
  &.ant-btn:hover {
    background-color: ${theme['@danger-color']};
    border-color: ${theme['@danger-color']};
    color: ${theme['@white']};
  }
`;

const DeleteLink = styled(Button)`
  &.ant-btn:hover {
    color: ${theme['@danger-color']};
  }
`;

const DeleteText = styled(Button)`
&.ant-btn:hover {
  color: ${theme['@danger-color']};
}
`;

interface DeleteButtonPlusProps extends ButtonProps {
  confirmContent: React.ReactNode;
  confirmTitle: string;
  showIcon?: boolean;
  title?: string;
}

export default function DeleteButtonPlus(
  props: DeleteButtonPlusProps
): JSX.Element {
  const {
    onClick,
    confirmTitle,
    confirmContent,
    showIcon = true,
    title = 'Delete',
    ...other
  } = props;
  const deleteLabel = title;

  /**
   * handle click event
   */
  const handleClick = useCallback(
    (e) => {
      Modal.confirm({
        title: confirmTitle,
        icon: <DangerIcon />,
        className: 'del-modal-dialog-context',
        okText: deleteLabel,
        okType: 'danger',
        content: confirmContent,
        onOk: () => {
          if (typeof onClick === 'function') {
            onClick(e);
          }
        },
      });
      e.stopPropagation();
      return false;
    },
    [onClick, confirmTitle, confirmContent, deleteLabel]
  );

  if (props.type === 'link') {
    return (
      <DeleteLink
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        onClick={handleClick}
        type="link"
      >
        {showIcon && <ButtonIcon className="bi-trash" />}
        <span style={showIcon ? { marginLeft: 8 } : {}}>{title}</span>
      </DeleteLink>
    )
  }

  if (props.type === 'text') {
    return (
      <DeleteText
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        onClick={handleClick}
        type="text"
      >
        {showIcon && <ButtonIcon className="bi-trash" />}
        <span style={showIcon ? { marginLeft: 8 } : {}}>{title}</span>
      </DeleteText>
    )
  }

  return (
    <DeleteButton
      /* eslint-disable react/jsx-props-no-spreading */
      {...other}
      onClick={handleClick}
    >
      {showIcon && <ButtonIcon className="bi-trash" />}
      <span style={showIcon ? { marginLeft: 8 } : {}}>{title}</span>
    </DeleteButton>
  );
}
DeleteButtonPlus.defaultProps = {
  showIcon: undefined,
  title: undefined,
};
