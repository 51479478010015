import React, { useCallback } from 'react';
import { Select, Checkbox, Divider, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type MultipleSelectProps = {
  data: Array<any>;
  valueField: string;
  labelField: string;
  disabled?: boolean;
  width?: number | string;
  loading?: boolean;
  title?: string;
  onChange?: (data: Array<any>) => void;
};
export default function MultipleSelect(
  props: MultipleSelectProps
): JSX.Element {
  const {
    onChange,
    data,
    valueField,
    labelField,
    width,
    // value = [],
    loading = false,
    title = 'All',
    disabled,
  } = props;
  const defaultChecked = data.map((i) => i[valueField]);
  const [checkedList, setCheckedList] = React.useState(defaultChecked);
  const [checkAll, setCheckAll] = React.useState(true);
  const [indeterminate, setIndeterminate] = React.useState(false);

  /**
   * handle check event
   */
  // const handleCheckChange = useCallback(
  //   (checked: boolean, item: any) => {
  //     if (checked) {
  //       value.push(item);
  //       if (typeof onChange === 'function') {
  //         onChange(JSON.parse(JSON.stringify(value)));
  //       }
  //     } else {
  //       const newData = value.filter((currentItem) => {
  //         if (currentItem[valueField] === item[valueField]) {
  //           return false;
  //         }
  //         return true;
  //       });
  //       if (typeof onChange === 'function') {
  //         onChange(JSON.parse(JSON.stringify(newData)));
  //       }
  //     }
  //   },
  //   [value, valueField, onChange]
  // );

  const onChangeX = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < defaultChecked.length);
    setCheckAll(list.length === defaultChecked.length);
    if (typeof onChange === 'function') {
      onChange(data.filter((i) => list.indexOf(i[valueField]) > -1));
    }
  };
  /**
   * handle check all event
   */
  const handleCheckAllChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const { checked } = e.target;
      setCheckedList(checked ? defaultChecked : []);
      setIndeterminate(false);
      setCheckAll(checked);
      if (typeof onChange === 'function') {
        onChange(checked ? data : []);
      }
    },
    [data, onChange, defaultChecked]
  );
  const style: StringKAnyVPair = { width: width || '100%' };

  return (
    <Select
      style={style}
      loading={loading}
      disabled={disabled}
      placeholder={
        checkedList.length === data.length
          ? title
          : data
              .map((i) => {
                if (checkedList.indexOf(i[valueField]) > -1) {
                  return i[labelField];
                } else {
                  return null;
                }
              })
              .filter((n) => n) + ''
      }
      dropdownRender={() => {
        return (
          <>
            <Row style={{ marginTop: 5, paddingLeft: 10 }}>
              {/* <Col span={2} /> */}
              <Checkbox
                onChange={handleCheckAllChange}
                checked={checkAll}
                indeterminate={indeterminate}
              >
                All
              </Checkbox>
            </Row>
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <Checkbox.Group value={checkedList} onChange={onChangeX}>
              {data.map((item) => (
                <Row
                  key={item[valueField]}
                  style={{ marginTop: 5, paddingLeft: 10 }}
                >
                  <Checkbox value={item[valueField]}>
                    {item[labelField]}
                  </Checkbox>
                </Row>
              ))}
            </Checkbox.Group>

            {/* {data.map((item) => {
              const checked = value.find((current) => {
                if (current[valueField] === item[valueField]) {
                  return current;
                }
                return null;
              })
                ? true
                : false;
              return (
                <Row key={item[valueField]} style={{ marginTop: 5 }}>
                  <Col span={2} />
                  <Checkbox
                    checked={checked}
                    indeterminate={!checked}
                    onChange={(e: CheckboxChangeEvent) => {
                      handleCheckChange(e.target.checked, item);
                    }}
                  >
                    {item[labelField]}
                  </Checkbox>
                </Row>
              );
            })} */}
          </>
        );
      }}
    />
  );
}
MultipleSelect.defaultProps = {
  // value: undefined,
  width: undefined,
  loading: undefined,
  title: undefined,
  onChange: undefined,
};
