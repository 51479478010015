import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ImportCenter from 'components/ImportCenter';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <ImportCenter />
      </SiteContent>
    </ContentLayout>
  );
};
