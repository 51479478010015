import React, { useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Input, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  FileSearchOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import TextEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  message,
  UploadImage,
  FormLabel,
  HelpMessage,
} from 'components/common';
import { postGroupSlip, previewGroupSlipTemplate } from 'services/storeSetup';
import { nowTime } from 'utils';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  store: any;
  slip?: any;
  templates: any[];
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, templates, slip, store } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  // const [fileList, setFileList] = useState<any[]>([]);
  //   const [showImg, setShowImg] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      channelAccountPackingSlipNum: slip
        ? slip.channelAccountPackingSlipNum
        : 0,
      centralChannelNum: store?.centralChannelNum,
      channelAccountNum: store?.channelAccountNum,
      salesDivision: params.salesDivision,
      packingSlipFooter: params.packingSlipFooter,
      logo: params.logo,
      packingSlipTemplateNum: params.packingSlipTemplateNum,
      isPrintPackingSlip: params.isPrintPackingSlip ? 1 : 0,
    };
    try {
      setSubmitLoading(true);
      const res = await postGroupSlip(postParams);
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: slip ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const previewPackingSlip = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      ChannelAccountPackingSlipNum: slip
        ? slip.channelAccountPackingSlipNum
        : 0,
      Footer: params.packingSlipFooter,
      Logo: params.logo,
      PackingSlipTemplateNum: params.packingSlipTemplateNum,
      Time: nowTime(),
    };
    try {
      setLoading(true);
      const res = await previewGroupSlipTemplate(postParams);
      setLoading(false);
      const w = window.open('about:blank');
      w?.document.write(res);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  //   useEffect(() => {
  //     if (insertItem) {}
  //   }, [insertItem]);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${slip ? 'Edit' : 'Add'} Group Packing Slip Template`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
          {false &&<Button
              type="default"
              loading={loading}
              onClick={() => previewPackingSlip()}
            >
              <FileSearchOutlined />
              Preview
            </Button>}
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {slip ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${slip ? 'Saving' : 'Adding'}`
                : `${slip ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="salesDivision"
            initialValue={slip?.salesDivision}
            label={<FormLabel>Sales Division</FormLabel>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="packingSlipTemplateNum"
            initialValue={slip?.packingSlipTemplateNum}
            label={<FormLabel>Group Packing Slip Template</FormLabel>}
            rules={[
              {
                required: true,
                message: 'Group Packing Slip Template is required',
              },
            ]}
          >
            <Select showSearch optionFilterProp="label">
              {templates.map((item) => (
                <Select.Option
                  value={item.printTemplateNum}
                  key={item.printTemplateNum}
                  label={item.templateName}
                >
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="packingSlipFooter"
            initialValue={slip?.packingSlipFooter || ''}
            label={
              <>
                <FormLabel noColon>Group Packing Slip Footer</FormLabel>
                <HelpMessage helpId={2} />:
              </>
            }
          >
            {/* <Input.TextArea rows={3} /> */}
            <TextEditor theme="snow" style={{ minHeight: 200 }} />
          </Form.Item>
          <Form.Item
            name="logo"
            initialValue={slip?.logo}
            label={<FormLabel>Store Logo</FormLabel>}
          >
            {/* <Input readOnly={!edited} /> */}
            <UploadImage style={{ marginTop: 5, width: 180 }} />
          </Form.Item>
          <Form.Item
            name="isPrintPackingSlip"
            initialValue={slip ? slip.isPrintPackingSlip : true}
            valuePropName="checked"
            label={<FormLabel noColon> </FormLabel>}
          >
            <Checkbox>Print Group Packing Slip</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
