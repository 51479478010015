import React, { useState, useEffect, Dispatch } from 'react';
import { Spin, Select } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchTransactionTypeList } from 'services/transactionType';
import { transactionTypeList } from 'actions/adminActions';
import { RootState } from 'reducers';

const { Option } = Select;
type SelectTransactionProps = {
  value?: number;
  onChange?: (transactionType: TransactionTypeRow | undefined) => void;
};
export default function SelectTransactionType(
  props: SelectTransactionProps
): JSX.Element {
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);
  const { onChange, value } = props;
  const transactionTypes = useSelector(
    (state: RootState) => state.admin.transactionTypes,
    shallowEqual
  );
  useEffect(() => {
    if (transactionTypes.length === 0) {
      setLoading(true);
      fetchTransactionTypeList().then((data) => {
        setLoading(false);
        // data.unshift({
        //   id: -1,
        //   name: 'Any Type',
        //   usage: 1,
        // });
        dispatch(transactionTypeList(data));
      });
    }
  }, [transactionTypes, dispatch]);
  if (loading) {
    return (
      <>
        <Spin />
      </>
    );
  }
  return (
    <Select
      style={{ width: '90%' }}
      placeholder="Any Type"
      allowClear
      optionFilterProp="children"
      value={value}
      onChange={(newValue: number) => {
        if (typeof onChange !== 'undefined') {
          const item = transactionTypes.find((currentItem) => {
            if (currentItem.id === newValue) {
              return currentItem;
            }
            return null;
          });
          if (item) {
            onChange(item);
          }else {
            onChange(undefined);
          }
        }
      }}
    >
      {transactionTypes.map((transactionType: TransactionTypeRow) => {
        return (
          <Option key={transactionType.id} value={transactionType.id}>
            {transactionType.name}
          </Option>
        );
      })}
    </Select>
  );
}
SelectTransactionType.defaultProps = {
  value: undefined,
  onChange: undefined,
};
