import React, { useCallback, useEffect, useState } from 'react';
import { Form, Button, Row, Space } from 'antd';
import {
  ClearButton,
  Locker,
  InputInteger,
  SelectTransactionReason,
  SelectProduct,
} from 'components/common';
import SelectLocationPlus from './searchLocation';
import AddItemsFormItem from './AddItemsFormItem';
import Text from 'antd/lib/typography/Text';

type AddItemFormProps = {
  onAddsItem: (
    product: ProductRow,
    location: WarehouseLocationRow,
    reason: TransactionReasonRow,
    quantity: number,
    cb: () => void
  ) => void;
  warehouseId: string;
  warehouseNum: number;
  onSelectLocation: (location: WarehouseLocationRow) => void;
  // onSelectWarehouse: (warehouse: WarehouseRow) => void;
  onSetInventory: (inv: any[]) => void;
  onSelectProduct: (fieldName: string, product?: ProductRow) => void;
  lotUomInfo: any;
  lotEnable:boolean;
  uomEnable: boolean;
};
export default function AddItemsForm(props: AddItemFormProps) {
  // const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductRow>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [quantity, setQuantity] = useState<string>('');
  const [transactionReason, setTransactionReason] =
    useState<TransactionReasonRow>();
  const [productLocked, setProductLocked] = useState<boolean>(false);
  const [locationLocked, setLocationLocked] = useState<boolean>(false);
  const [reasonLocked, setReasonLocked] = useState<boolean>(false);
  const [quantityLocked, setQuantityLocked] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    warehouseNum,
    warehouseId,
    onSelectProduct,
    onSelectLocation,
    onAddsItem,
    // onSetInventory,
    lotUomInfo,
    lotEnable,
    uomEnable
  } = props;
  useEffect(() => {
    form.setFieldsValue({ quantity: 1 });
  }, [form]);

  /**
   * handle transaction reason select change event
   */
  const handleTransactionReasonChange = useCallback(
    (transactionReason: TransactionReasonRow) => {
      setTransactionReason(transactionReason);
    },
    []
  );

  /**
   * handle select product
   */
  const handleSelectProductChange = useCallback(
    (fieldName: string, newProduct: any) => {
      setProduct(newProduct);
      if (newProduct.id) {
        onSelectProduct(fieldName, newProduct);
      }
      form.setFieldsValue({
        identifier: newProduct.sku,
      });
    },
    [form, onSelectProduct]
  );
  /**
   * handle select location
   */
  const handleLocationChange = useCallback(
    (newLocation?: WarehouseLocationRow) => {
      if (!newLocation) return;
      setLocation(newLocation);
      form.setFieldsValue({
        location: newLocation.code,
      });
      if (newLocation.id) {
        onSelectLocation(newLocation);
      }
    },
    [onSelectLocation, form]
  );
  /**
   * handle add item
   */
  const handleAddItemSubmit = useCallback(async () => {
    console.log(form.getFieldsValue());
    let values: StringKAnyVPair = {};
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }
    if (location && product && transactionReason) {
      // setLoading(true);
      // //judge product'id is empty
      // const skuProduct = (await fullProduct(product)) as ProductRow;
      // if (!skuProduct) {
      //   setLoading(false);
      //   return;
      // }
      // //reset parent page product picture
      // onSelectProduct('sku', skuProduct);

      onAddsItem(
        product,
        location,
        transactionReason,
        values['quantity'],
        () => {
          form.setFieldsValue({
            identifier: productLocked ? values.identifier : '',
            location: locationLocked ? values.location : '',
            reason: reasonLocked ? values.reason : '',
            quantity: quantityLocked ? values.quantity : '',
          });
          // setLoading(false);
        }
      );
    }
  }, [
    location,
    product,
    form,
    transactionReason,
    onAddsItem,
    productLocked,
    locationLocked,
    quantityLocked,
    reasonLocked,
  ]);
  return (
    <>
      <Form
        form={form}
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <AddItemsFormItem
          name="identifier"
          label="SKU / UPC"
          message="SKU / UPC is required!"
        >
          <SelectProduct
            width={265}
            placeholder="SKU / UPC"
            value={product?.sku}
            onChange={handleSelectProductChange}
            noControl
            onBlurAble
          />
          <Locker
            size={16}
            value={productLocked}
            onChange={(value: boolean) => {
              setProductLocked(value);
            }}
          />
        </AddItemsFormItem>
        <AddItemsFormItem
          name="location"
          label="Location"
          message="Location is required!"
        >
          <SelectLocationPlus
            width={265}
            warehouseId={warehouseId}
            warehouseNum={warehouseNum}
            sku={product ? product.sku : ''}
            placeholder="Location"
            onChange={handleLocationChange}
          />
          <Locker
            size={16}
            value={locationLocked}
            onChange={(value: boolean) => {
              setLocationLocked(value);
            }}
          />
        </AddItemsFormItem>
        <AddItemsFormItem
          name="reason"
          label="Reason"
          message="Reason is required!"
        >
          <SelectTransactionReason
            width={265}
            transactionType={8}
            onChange={handleTransactionReasonChange}
          />
          <Locker
            size={16}
            value={reasonLocked}
            onChange={(value: boolean) => {
              setReasonLocked(value);
            }}
          />
        </AddItemsFormItem>
        {lotUomInfo && <Row justify='center' style={{marginBottom: 8}}>
          <Space size="large">
            {lotEnable&&<Text><Text strong>LOT#: </Text><Text>{lotUomInfo?.lotNumber}</Text></Text>}
            {uomEnable&&<Text><Text strong>UOM: </Text><Text>{lotUomInfo?.uom}</Text></Text>}
            {uomEnable&&<Text><Text strong>Rate: </Text><Text>{lotUomInfo?.uomRate}</Text></Text>}
          </Space>
        </Row>}
        <AddItemsFormItem
          name="quantity"
          label="Quantity"
          message="Quantity is required!"
        >
          <InputInteger
            width={120}
            value={quantity}
            onChange={(value: string) => {
              setQuantity(value);
            }}
          />
          <Space>
          <Locker
            size={16}
            value={quantityLocked}
            onChange={(value: boolean) => {
              setQuantityLocked(value);
            }}
          />
          {uomEnable && <Text>{`Base Qty: ${(lotUomInfo?.uomRate || 1) * Number(quantity || 1)}`}</Text>}
          </Space>
        </AddItemsFormItem>
        <Row justify="center" style={{ marginTop: 10 }}>
          <Space>
            <Button
              type="primary"
              disabled={!product || !location || !transactionReason}
              onClick={handleAddItemSubmit}
            // loading={loading}
            >
              Check In
            </Button>
            <ClearButton form={form} />
          </Space>
        </Row>
      </Form>
    </>
  );
}
