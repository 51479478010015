import React, { useCallback, useState } from 'react';
import { Upload, message, Row, Button, Image } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { getErrorImage } from 'utils';
import { userRoleHeaders, authParams } from 'services/http';
import qs from 'qs';

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
type UploadImageProps = {
  edited?: boolean;
  value?: string;
  onChange?: (url: string) => void;
  style?: React.CSSProperties;
};
export default function UploadImage(props: UploadImageProps): JSX.Element {
  const { style, value, edited = true, onChange } = props;
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState<string>('error');

  React.useEffect(() => {
    if (value) {
      setImgUrl(value);
    }
  }, [value]);

  const handleChange = useCallback(
    (info) => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // const [
        //   {
        //     response: { data },
        //   },
        // ] = info.fileList;
        const [{ url }] = info.fileList[info.fileList.length - 1].response.data;
        if (typeof onChange === 'function') {
          onChange(url);
        }
        setLoading(false);
        setImgUrl(url);
      }
    },
    [onChange]
  );
  const UploadButton = (): JSX.Element => {
    return (
      <Row align="middle">
        {loading && <LoadingOutlined />}
        {!loading && (
          <Button id="uplaod_button" disabled={!edited} icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        )}
      </Row>
    );
  };
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Image
        width={75}
        height={75}
        preview={true}
        src={imgUrl}
        fallback={getErrorImage()}
        style={{ cursor: 'pointer' }}
      />
      <div style={{ marginTop: 5 }}>
        <Upload
          disabled={!edited}
          accept=".png,.jpg,.jpeg"
          name="avatar"
          showUploadList={false}
          action={`${
            process.env.BASE_URL
          }/api/files/upload/image?${qs.stringify(authParams())}`}
          headers={userRoleHeaders()}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          <UploadButton />
        </Upload>
      </div>
    </div>
  );
}
