import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus } from 'components/common';
import { CloseOutlined } from '@ant-design/icons';
import { getProductUomList } from 'services/product'

type Props = {
  onHide: () => void;
  onOk: (row: any) => void;
  data: PoLineRow;
};

const uomColumns = [
    {
      name: 'uom',
      header: 'Case Pack',
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
    },
    {
      name: 'name',
      header: 'Pack Name',
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
    },
    {
      name: 'uomRate',
      header: 'Rate',
      defaultFlex: 1,
      userSelect: true,
      sortable: false,
    }
  ]
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onOk, data } = props;
  const [uomList, setUomlist] = useState<any[]>([]);
  const [loading, setLoaidng] = useState(false);

  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const getUomList = useCallback(async()=>{
    try {
        setLoaidng(true)
        const res = await getProductUomList(data.sku)
        setLoaidng(false)
        if(res.isSuccess){
            setUomlist(res.data)
        }
    } catch (error) {
        setLoaidng(false)
    }
  },[data])


  useEffect(()=>{
    getUomList()
  },[getUomList])

  return (
    <Modal
      title="Select UOM"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={uomColumns}
        onRowClick={(row) => {
          onOk(row.data);
        }}
        dataSource={uomList}
        idProperty="productUomNum"
        loading={loading}
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
