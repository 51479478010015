import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Modal,
  Row,
  Button,
  Space,
  Form,
  Select,
  message,
  InputNumber,
} from 'antd';
import {
  CloseOutlined,
  FileSearchOutlined,
  PrinterOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { DataGridPlus, FormLabel } from 'components/common';
import { onSelectionChange } from 'utils';
import { formatPrinterToTree } from 'utils';
import { useDispatch } from 'react-redux';
import {
  fetchPrintTemplates,
  fetchPrinterList,
  PrintLabelExport,
  PrintLabelPreview,
} from 'services/print';
import { userProfiles } from 'utils/auth';
import { HTTP_STATUS_OK } from 'constants/config';
import styled from 'styled-components';
import { printLabel } from 'actions/printActions';

const FormItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  onHide: () => void;
  detailData?: any;
  sourceLines: PackLineItem[];
  prepackSkus: any[];
  isParent: boolean;
};
type ColumnRenderType = {
  data: PackLineItem;
  rowIndex: number;
};
type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, sourceLines, prepackSkus, isParent } = props;
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [lines, setLines] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  // template part
  const [refreshTemplate, setRefeshTemplate] = useState(true);
  const [printTemplateList, setPrintTemplateList] = useState<PrintTemplate[]>(
    []
  );
  // printer part
  const [refreshPrinter, setRefeshPrinter] = useState(true);
  const [printerList, setPrinterList] = useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = useState<OptionsTree[]>();
  const ref = useRef<any>(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const getPrinterList = useCallback(async () => {
    try {
      const { isSuccess, data = [] } = await fetchPrinterList();
      setRefeshPrinter(false);
      if (isSuccess) {
        setPrinterList(data);
        setPrinterTree(formatPrinterToTree(data));
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
    }
  }, []);

  const getPrinterTemplateList = useCallback(async () => {
    try {
      const profile = userProfiles.profile;
      const {
        isSuccess,
        data = [],
        message,
      } = await fetchPrintTemplates({
        TemplateType: 4,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      setRefeshTemplate(false);
      if (isSuccess) {
        setPrintTemplateList(data || []);
        if (data.length > 0) {
          form.setFieldsValue({ PrintTemplateNum: data[0].printTemplateNum });
        }
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshTemplate(false);
    }
  }, [form]);

  useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }
  }, [getPrinterList, refreshPrinter]);

  useEffect(() => {
    if (refreshTemplate) {
      getPrinterTemplateList();
      setRefeshTemplate(false);
    }
  }, [getPrinterTemplateList, refreshTemplate]);

  const formartData = useCallback(
    (type: string) => {
      let temp: any[] = [];
      const slectedNums = selectedRow.map((i) => i.fulfillmentPackLineNum);
      const tempLines = lines.filter(
        (i) => slectedNums.indexOf(i.fulfillmentPackLineNum) > -1
      );
      // console.log(tempLines);
      if (tempLines.length === 0) return temp;
      switch (type) {
        case 'sku':
          temp = tempLines.map((i) => {
            return {
              title: i.productTitle,
              code: i.sku,
              quantity: i.printQty,
            };
          });
          break;
        case 'upc':
          temp = tempLines.map((i) => {
            return {
              title: i.productTitle,
              code: i.upc,
              quantity: i.printQty,
            };
          });
          break;
        case 'sku+upc':
          temp = tempLines.map((i) => {
            return {
              title: i.sku,
              code: i.upc,
              quantity: i.printQty,
            };
          });
          break;
        default:
          break;
      }
      return temp;
    },
    [selectedRow, lines]
  );

  const exportPDF = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    const { PrintTemplateNum, LabelType } = formValues;
    const data = formartData(LabelType);
    const profile = userProfiles.profile;
    setExportLoading(true);
    try {
      const res = await PrintLabelExport({
        Data: data,
        Qty: 1,
        MasterAccountNum: profile.masterAccountNum,
        PrinterAccountNum: 0,
        PrinterNum: 0,
        TemplateType: 4,
        ProfileNum: profile.profileNum,
        User: profile.email,
        PrintTemplateNum,
      });
      const content = res;
      const blob = new Blob([content], { type: 'application/pdf' });
      const fileName = `Pack-Label.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('err = ', error);
    }
    setExportLoading(false);
  };

  const preview = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    const profile = userProfiles.profile;
    const { PrintTemplateNum, LabelType } = formValues;
    const data = formartData(LabelType);
    setPreviewLoading(true);
    try {
      const previewRes = await PrintLabelPreview({
        Data: data,
        Qty: 1,
        MasterAccountNum: profile.masterAccountNum,
        PrinterAccountNum: 0,
        PrinterNum: 0,
        TemplateType: 4,
        ProfileNum: profile.profileNum,
        User: profile.email,
        PrintTemplateNum,
      });

      if (
        typeof previewRes === 'object' &&
        typeof previewRes.code === 'number' &&
        previewRes.code !== HTTP_STATUS_OK
      ) {
        if (previewRes.message) {
          message.info(previewRes.message);
        }
      } else {
        const w = window.open('about:blank');
        w?.document.write(previewRes);
      }
    } catch (err) {
      console.log(err);
    }
    setPreviewLoading(false);
  };

  const print = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    if (!formValues.PrinterNum) {
      message.warning({ content: 'Please select a printer' });
      return;
    }
    setPrintLoading(true);
    try {
      const profile = userProfiles.profile;
      const selectedPrinter = printerList.filter(
        (item) => item.printerNum === formValues.PrinterNum
      )[0];
      const { PrintTemplateNum, LabelType, PrinterNum } = formValues;
      const data = formartData(LabelType);
      const params = {
        Data: data,
        Qty: 1,
        MasterAccountNum: profile.masterAccountNum,
        PrinterAccountNum: selectedPrinter.printerAccountNum,
        TemplateType: 4,
        PrinterNum,
        PrintTemplateNum,
        ProfileNum: profile.profileNum,
        User: profile.email,
      };
      dispatch(printLabel(params));
    } catch (err) {
      console.log(err);
    }
    setPrintLoading(false);
  };

  useEffect(() => {
    if(isParent){
      const temp = prepackSkus.map((i) => {
        return {
          ...i,
          printQty: 1,
        };
      });
      setLines(temp);
      setSelectedRow(temp);
    } else {
      const temp = sourceLines.map((i) => {
        return {
          ...i,
          printQty: i.requestQty,
        };
      });
      setLines(temp);
      setSelectedRow(temp);
    }

  }, [sourceLines, prepackSkus, isParent]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      userSelect: true,
      showColumnMenuTool: false,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      userSelect: true,
      showColumnMenuTool: false,
      defaultFlex: 1,
    },
    {
      name: 'requestQty',
      header: 'Requested Qty',
      userSelect: true,
      showColumnMenuTool: false,
      defaultFlex: 1,
    },
    {
      name: 'printQty',
      header: 'Print Qty',
      sortable: false,
      showColumnMenuTool: false,
      defaultFlex: 1,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        const { printQty } = data;
        return (
          <Space>
            <InputNumber
              value={printQty}
              min={0}
              // max={requestQty}
              onChange={(qty) => {
                setLines((prev) => {
                  const temp = prev;
                  const index = prev.findIndex(
                    (item) =>
                      item.fulfillmentPackLineNum ===
                      data.fulfillmentPackLineNum
                  );
                  if (index > -1) {
                    temp[index] = {
                      ...temp[index],
                      printQty: qty,
                    };
                    return [...temp];
                  } else {
                    return prev;
                  }
                });
              }}
            />
          </Space>
        );
      },
    },
    {
      name: 'productTitle',
      header: 'Product Name',
      userSelect: true,
      showColumnMenuTool: false,
      defaultFlex: 1,
    },
  ];

  return (
    <Modal
      title="Print SKU Label"
      centered
      visible={true}
      width={800}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="space-between">
          <Space>
            <Button id="close_button" onClick={onHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
          <Space>
          {false &&<Button
              style={{ marginRight: 12 }}
              onClick={() => preview()}
              loading={previewLoading}
            >
              <FileSearchOutlined />
              Preview
            </Button>}
            <Button
              type="primary"
              style={{ marginRight: 12 }}
              loading={printLoading}
              onClick={() => print()}
            >
              <PrinterOutlined />
              Print
            </Button>
            <Button loading={exportLoading} onClick={() => exportPDF()}>
              Export to PDF
            </Button>
          </Space>
        </Row>
      }
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label={
            <FormItemRow>
              <FormLabel>Select A Template</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshTemplate(true)}
              />
            </FormItemRow>
          }
          name="PrintTemplateNum"
          rules={[
            {
              required: true,
              message: 'Please select a template',
            },
          ]}
        >
          <Select placeholder="Select a print template" style={{ width: 300 }}>
            {printTemplateList.map((item, index) => (
              <Select.Option key={index} value={item.printTemplateNum}>
                {item.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <FormItemRow>
              <FormLabel>Printer</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{
                      fontSize: 12,
                      color: 'green',
                    }}
                  />
                }
                onClick={() => setRefeshPrinter(true)}
              />
            </FormItemRow>
          }
          name="PrinterNum"
        >
          <Select
            ref={ref}
            placeholder="Select a printer"
            style={{ width: 300 }}
            optionLabelProp="label"
          >
            {printerTree?.map((item) => {
              return (
                <Select.OptGroup key={item.label} label={item.label}>
                  {item.options.map((citem: any) => {
                    return (
                      <Select.Option
                        key={citem.value}
                        value={citem.value}
                        label={`${item.label} - ${citem.label}`}
                      >
                        {citem.label}
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="LabelType"
          initialValue="sku"
          label={<FormLabel>Label Type</FormLabel>}
        >
          <Select style={{ width: 300 }} id="label_type_select">
            <Select.Option value="sku">SKU</Select.Option>
            <Select.Option value="upc">UPC</Select.Option>
            <Select.Option value="sku+upc">SKU+UPC</Select.Option>
          </Select>
        </Form.Item>
      </Form>

      <DataGridPlus
        autoWith={false}
        autoHeight={false}
        style={{ height: 250 }}
        showHoverRows={false}
        columns={isParent ? columns.filter(i =>i.name !== 'requestQty') : columns}
        dataSource={lines}
        idProperty="fulfillmentPackLineNum"
        showColumnMenuTool={false}
        selected={selected}
        checkboxColumn
        onSelectionChange={(props) =>
          onSelectionChange(
            props,
            lines,
            setSelected,
            setSelectedRow,
            'fulfillmentPackLineNum'
          )
        }
        checkboxOnlyRowSelect={true}
        {...({} as any)}
      />
    </Modal>
  );
};
