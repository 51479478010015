import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { CopySpan, DataGridPlus, FormLabel } from '../../../components/common';
import { Button, Card, Col, Descriptions, Input, message, Row } from 'antd';
import { fetchBulkReturnLines, completeBulkReturn } from 'services/return';
import debounce from 'lodash.debounce';
import { convertTimeByUtc } from 'utils';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import { FilterOutlined } from '@ant-design/icons';

/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
}

const statusEnum: { [key: string]: string } = {
  '0': '4',
  '1': 'Proccessing',
  '2': 'Complete',
};

const syncStatusEnum: { [key: string]: string } = {
  '0': 'Pending ',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Proccessing',
};

const DetailDialog = (props: Props) => {
  const { modalReturn } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [completing, setCompleting] = useState(false);
  const [lines, setLines] = useState<any[]>([]);
  // const [completable, setCompletable] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterList, setFilterList] = useState<any[]>([]);

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getBulkReturnLines = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchBulkReturnLines(
        modalReturn.fulfillmentBulkReturnNum
      );
      setLoading(false);
      if (res.isSuccess && res.data.length > 0) {
        //check completable
        // const index = res.data.findIndex((i: any) => i.inspectionQty !== 0);
        // setCompletable(index === -1);
        setLines(
          res.data.map((i: any) => {
            return {
              id: i.sku,
              ...i,
            };
          })
        );
      }
    } catch (error) {
      setLoading(false);
    }
  }, [modalReturn]);

  const handleCompleteReturn = useCallback(async () => {
    try {
      setCompleting(true);
      const res = await completeBulkReturn(
        modalReturn.fulfillmentBulkReturnNum
      );
      setCompleting(false);
      if (res.isSuccess) {
        message.success({ content: 'Completed successfully' });
        props.onClose(true);
      }
    } catch (error) {
      setCompleting(false);
    }
  }, [modalReturn, props]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 180,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 180,
    },
    {
      name: 'receiveQty',
      header: 'Receive Qty',
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'inspectionQty',
      header: 'Inspection',
      minWidth: 70,
      showColumnMenuTool: false,
      sortable: true,
      userSelect: true,
      type: 'number',
      defaultFlex: 1,
    },
    {
      name: 'stockQty',
      header: 'Resaleable Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'nonStockQty',
      header: 'Damage Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
  ];

  const list = useMemo(() => {
    return [
      {
        label: 'WMS Return ID',
        render: <CopySpan value={modalReturn.fulfillmentBulkReturnId} />
      },
      {
        label: 'Store(Channel)',
        render: <CopySpan value={modalReturn.channelAccountName} />
      },
      {
        label: 'Reference No',
        render: <CopySpan value={modalReturn.referenceNo} />
      },
      {
        label: 'Warehouse',
        render: <CopySpan value={modalReturn.warehouseCode} />
      },
      {
        label: 'Status',
        render: <CopySpan value={statusEnum[`${modalReturn.transStatus}`]} />
      },
      {
        label: 'Sync Status',
        render: <CopySpan value={syncStatusEnum[`${modalReturn.syncStatus}`]} />
      },
      {
        label: 'Return Date',
        render: <CopySpan value={convertTimeByUtc(modalReturn.returnDate, '', '', DATE_TIME_NO_SECOND_FORMAT)} />
      },
      {
        label: 'Create Date',
        render: <CopySpan value={convertTimeByUtc(modalReturn.createDate, '', '', DATE_TIME_NO_SECOND_FORMAT)} />
      },
      {
        label: 'Create By',
        render: <CopySpan value={modalReturn.createBy} />
      },
      {
        label: 'Receive Qty',
        render: <CopySpan value={modalReturn.receiveQty} />
      },
      {
        label: 'Resaleable Qty',
        render: <CopySpan value={modalReturn.stockQty} />
      },
      {
        label: 'Damage Qty',
        render: <CopySpan value={modalReturn.nonStockQty} />
      },
      {
        label: 'Inspection Qty',
        render: <CopySpan value={modalReturn.inspectionQty} />
      },
      {
        label: 'Note',
        render: <CopySpan value={modalReturn.notes} />
      }
    ]
  }, [modalReturn])

  useEffect(() => {
    if (filterValue) {
      const temp = list.filter(
        (i: any) =>
          i.label.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
      );
      setFilterList([...temp]);
    } else {
      setFilterList([...list]);
    }
  }, [filterValue, list]);


  useEffect(() => {
    if (modalReturn.fulfillmentBulkReturnNum) {
      getBulkReturnLines();
    }
  }, [modalReturn, getBulkReturnLines]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Customer Return (Bulk Return) - Complete"
        visible={props.visible}
        width={detailDialogWidth()}
        titleRightCrumb={
          <Button
            type="primary"
            disabled={lines.length === 0 || !(modalReturn.returnQty === modalReturn.stockQty + modalReturn.nonStockQty + modalReturn.inspectionQty)}
            loading={completing}
            onClick={handleCompleteReturn}
          >
            Complete
          </Button>
        }
      >
        <Card>
          <Row>
            <Col span={5}>
              <Input
                prefix={<FilterOutlined />}
                size="small"
                placeholder="Filter"
                allowClear
                onChange={(e) => {
                  debounceFn(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Descriptions
            size="small"
            column={3}
            labelStyle={{ width: 130, textAlign: 'right', display: 'inline-block' }}
            style={{ margin: '8px 0' }}
          >
            {
              filterList.map((item: any, index) => {
                const { label, render } = item;
                return (
                  <Descriptions.Item key={index} label={<FormLabel noColon>{label}</FormLabel>} >
                    {render}
                  </Descriptions.Item>
                )
              })
            }
          </Descriptions>
        </Card>
        <DataGridPlus
          loading={loading}
          columns={columns}
          dataSource={lines}
          idProperty="id"
          pagination="local"
          {...({} as any)}
        />
      </ModalDialog>
    </>
  );
};

export default DetailDialog;
