import React, { useCallback, useState } from 'react';
import {
  Form,
  Row,
  Modal,
  Button,
  Space,
  InputNumber,
  Col,
  Divider,
  Typography,
  Radio,
} from 'antd';
import { FormLabel } from 'components/common';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

interface CreateBrandProps {
  visible: boolean;
  onHide: () => void;
  onSave: (data: any) => void;
  data: PackLineItem;
  packCode: string;
}
// eslint-disable-next-line
export default (props: CreateBrandProps) => {
  const { visible, onHide, onSave, data, packCode } = props;
  const [action, setAction] = useState<0 | 1 | 2>(2);
  const [form] = Form.useForm();

  /**
   * handle cancel event
   */
  const handleCloseModal = React.useCallback(async () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  }, [onHide, form]);

  const handleSave = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const qty =
      action === 0
        ? params.quantity
        : action === 1
        ? data.packingQty + params.quantity
        : data.packingQty - params.quantity;
    // console.log(action, qty)
    onSave({ ...data, packingQty: qty });
  }, [data, form, onSave, action]);

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 10 },
  };
  return (
    <Modal
      title={`Edit Pack`}
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button key="submit" type="primary" onClick={handleSave}>
              <SaveOutlined />
              Update
            </Button>
            <Button onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      {data && (
        <>
          <Row>
            <Col span={12}>
              <FormLabel strong>Request#</FormLabel>
              <Typography.Text strong>{packCode}</Typography.Text>
            </Col>
            <Col span={12}>
              <FormLabel strong>Pre-Pack SKU</FormLabel>
              <Typography.Text strong>{data.prepackSKU}</Typography.Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={12}>
              <FormLabel>SKU</FormLabel>
              {data.sku}
            </Col>
            <Col span={12}>
              <FormLabel>Product Name</FormLabel>
              {data?.productTitle}
            </Col>
            <Col span={12}>
              <FormLabel>Requested</FormLabel>
              {data?.componentQuantity}
            </Col>
            <Col span={12}>
              <FormLabel>Packing Qty</FormLabel>
              {data.packingQty}
            </Col>
          </Row>
          <Divider />
          <Form {...layout} form={form}>
            <Form.Item label={<FormLabel fontSize={20}>Action</FormLabel>}>
              <Radio.Group
                value={action}
                onChange={(e) => setAction(e.target.value)}
              >
                <Radio value={1}>Add</Radio>
                <Radio value={2}>Remove</Radio>
                <Radio value={0}>New Total</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="quantity"
              label={<FormLabel fontSize={20}>Quantity</FormLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please input Quantity!',
                },
              ]}
            >
              <InputNumber
                size="large"
                max={action === 2 ? data.packingQty : data?.componentQuantity}
                min={0}
                precision={0}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
};
