import React from 'react';
import {
  Button,
  Modal,
  Row,
} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { isMacos, playBell } from 'utils';
import { AlertContainer } from './styles';
import { Trans } from 'react-i18next';

type Props = {
  close: Function;
  content?: string;
  onClose?: Function;
  title?: string;
  visible: boolean;
};

const AlertDialog = (props: Props) => {
  const { useState } = React;
  const { content, title } = props;
  const [currentVisible, setCurrentVisible] = useState(props.visible);

  const handleContinue = () => {
    if (typeof props.onClose === 'function') {
      setTimeout(props.onClose, 500);
    }

    props.close();
  };

  // eslint-disable-next-line
  const handleKeydown = (evt: any) => {
    //console.log('k d', evt);
    if (!props.visible) return;

    if (evt.key === 'Escape') {
      handleContinue();
    }

    evt.preventDefault();
    evt.stopPropagation();
  };

  React.useEffect(() => {
    if (currentVisible !== props.visible) {
      if (props.visible) {
        playBell('error');
      }

      setCurrentVisible(props.visible);
    }

    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [currentVisible, handleKeydown, props]);

  return (
    <>
      <Modal
        bodyStyle={{ padding: 0 }}
        centered
        closable={false}
        destroyOnClose
        footer={null}
        maskClosable={false}
        visible={props.visible}
        width={400}
      >
        <AlertContainer>
          <Row className="icon-box" justify="center">
            <ExclamationCircleFilled className="alert-icon" />
          </Row>
          {title && (<Row className="title-box" justify="center">{title}</Row>)}
          {content && (<Row className="message-box" justify="center">{content}</Row>)}
          <Row className="button-box" justify="center">
            <Button
              onClick={handleContinue}
              size="large"
              type="primary"
            >
              <Trans i18nKey="common.continue" /> (
              {isMacos() ? 'esc' : 'Esc'}
              )
            </Button>
          </Row>
        </AlertContainer>
      </Modal>
    </>
  );
};

export default AlertDialog;
