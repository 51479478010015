import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { playAudio } from 'utils';
import { fetchPickingByOrder } from 'services/smart';
import { message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

type ScanOrderProps = {
  onSubmit: (order: SmartPickOrder, str: string) => void;
};
export default function ScanOrder(props: ScanOrderProps): JSX.Element {
  const { onSubmit } = props;
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  /**
   * handle scanner change event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      setFetching(true);
      try {
        const res = await fetchPickingByOrder(nextValue);
        setFetching(false);
        if (res.isSuccess) {
          playAudio('success');
          onSubmit(res.data, nextValue);
        } else {
          message.warning(`${nextValue} invalid!`);
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [onSubmit]
  );
  return (
    <>
      <SmartSpin loading={fetching} />
      <SmartSpace>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t("smart.pick.scanOrderToStart")}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      <SmartBackButton
        onClick={() => {
          history.goBack();
        }}
      />
    </>
  );
}
