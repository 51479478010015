import React, { useCallback, useEffect, useState } from 'react';
import { Row, Typography, Modal, Button, Space, Form, Input, Col, Checkbox } from 'antd';
import FormLabel from '../FormLabel';
// import DataGridPlus from '../DataGridPlus';
import { CloseOutlined } from '@ant-design/icons';
import DataGrid from '../DataGrid';

const { Text } = Typography;
type SelectLocationDialogProps = {
  visible: boolean;
  onHide: () => void;
  onSelect: (record: WarehouseLocationRow, applyToAll?: boolean) => void;
  dataSource: Array<WarehouseLocationRow>;
  mode?: 'poReceive' | string;
};
/**
 * Return SelectLocationDialog Component
 * @param {SelectLocationDialogProps} props
 * @returns {JSX.Element}
 */
export default function SelectLocationDialog(
  props: SelectLocationDialogProps
): JSX.Element {
  const { onHide, onSelect, dataSource, visible, mode = '' } = props;
  const [data, setData] = useState<Array<WarehouseLocationRow>>([]);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);

  const getColumns = useCallback(() => {
    console.log('applyToAll = ', applyToAll);
    return [
      {
        header: 'Locations',
        name: 'code',
        textAlign: 'center',
        headerAlign: 'center',
        defaultFlex: 1,
        render: (value: any) => {
          const { data: row } = value;
          return (
            <Button
              id={`${row['code']}_btn`}
              type="text"
              style={{ width: '100%' }}
              onClick={() => {
                onSelect(row, applyToAll);
              }}
            >
              {row['code']}
            </Button>
          );
        },
      },
    ];
  }, [applyToAll, onSelect])

  const handleCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = e;
      if (!value) {
        setData(dataSource);
      }
      const newData = dataSource.filter((item) => {
        const { code } = item;
        if (code.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0) {
          return true;
        }
        return false;
      });
      setData(newData);
    },
    [dataSource]
  );

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="hideDialog_btn" onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Search Location"
      centered
      visible={visible}
      width={800}
      destroyOnClose
      onCancel={onHide}
      bodyStyle={{ paddingBottom: 0 }}
      footer={<Footer />}
    >
      <Form>
        <Form.Item>
          <Row align="middle">
            <Col span={3}>
              <Row justify="end">
                <FormLabel>Location</FormLabel>
              </Row>
            </Col>
            <Col span={1} />
            <Col span={20}>
              <Input id="locationCode_input" placeholder="location code" onChange={handleCodeChange} />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row align="middle" justify="space-between">
            {mode !== 'poReceive' ? <Col><Text>{`Total Items:${data.length}`}</Text></Col> : <div></div>}
            {mode === 'poReceive' && (
              <Col>
                <Checkbox
                  checked={applyToAll}
                  onChange={e => setApplyToAll(e.target.checked)}
                >
                  Apply to All PO Line
                </Checkbox>
              </Col>
            )}
          </Row>
          <DataGrid
            style={{ height: 490 }}
            idProperty="code"
            dataSource={data}
            columns={getColumns()}
            pagination="local"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
