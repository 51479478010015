import React, { useState, useEffect, useCallback } from 'react';
import {
  PlusOutlined,
  MinusCircleOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Card,
  Tabs,
  Empty,
  Row,
  Space,
  Button,
  Typography,
  Form,
  Select,
} from 'antd';
import {
  fetchWarehouseList,
  getServicesByWarehouse,
  getStoresByWarehouse,
} from 'services/warehouse';
import { GreyCardWrapper, HeaderTitle, message } from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getShippriority, postShippriority } from 'services/shippingPriority';
import { ClassicCard } from 'components/common/styles';

const { TabPane } = Tabs;
const { Text } = Typography;

export default function ShoppingAround(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [warehouses, setWarehouse] = useState<SimpleWarehouse[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [stores, setStores] = useState<any[]>([]);
  const [services, setServices] = useState<any[]>([]);
  // const [group0Prioritys, setGroup0Prioritys] = useState<any[]>();
  // const [group1Prioritys, setGroup1Prioritys] = useState<any[]>();
  const [checked, setChecked] = useState<boolean>(true); // true is group 0

  const [form] = Form.useForm();

  const getWarehouses = async () => {
    try {
      setLoading(true);
      const res = await fetchWarehouseList();
      const temp = res.filter((i:any) => i.warehouseType === 1 || i.warehouseType === 5)
      setLoading(false);
      if (temp.length > 0) {
        setActiveTab(
          localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
          `${temp[0].warehouseNum}`
        );
      } else {
        setActiveTab('-1');
      }
      setWarehouse(temp);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getChannelAccounts = useCallback(async (warehouseNum: number) => {
    try {
      const res = await getStoresByWarehouse(warehouseNum);
      if (res.isSuccess) {
        setStores(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getServices = useCallback(async (warehouseNum: number) => {
    if (warehouseNum) {
      try {
        const res = await getServicesByWarehouse(warehouseNum);
        if (res.isSuccess) {
          setServices(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  const getPrioritys = useCallback(
    async (warehouseNum: number) => {
      try {
        setLoading1(true);
        const res = await getShippriority(warehouseNum);
        setLoading1(false);
        if (res.isSuccess) {
          if (res.data.length > 0) {
            if (res.data[0].group === 0) {
              if (res.data[0].referenceType === 0) {
                setChecked(true);
              } else {
                setChecked(false);
              }
            } else {
              if (res.data[0].referenceType === 1) {
                setChecked(false);
              } else {
                setChecked(true);
              }
            }
          }
        }
        // setGroup0Prioritys(
        //   res.data
        //     .filter((i: any) => i.referenceType === 0)
        //     .sort((a: any, b: any) => b.seq - a.seq)
        //     .map((item: any) => item.referenceNum)
        // );
        // setGroup1Prioritys(
        //   res.data
        //     .filter((i: any) => i.referenceType === 1)
        //     .sort((a: any, b: any) => b.seq - a.seq)
        //     .map((item: any) => item.referenceNum)
        // );
        form.setFieldsValue({
          channelAccount: res.data
            .filter((i: any) => i.referenceType === 0)
            .sort((a: any, b: any) => a.seq - b.seq)
            .map((item: any) => item.referenceNum),
          ShippingService: res.data
            .filter((i: any) => i.referenceType === 1)
            .sort((a: any, b: any) => a.seq - b.seq)
            .map((item: any) => item.referenceNum),
        });
      } catch (error) {
        setLoading1(false);
      }
    },
    [form]
  );

  const handleSave = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const arr0 = Array.from(new Set(params.channelAccount));
    const arr1 = Array.from(new Set(params.ShippingService));
    if (
      arr0.length !== params.channelAccount.length ||
      arr1.length !== params.ShippingService.length
    ) {
      return message.info({
        content: 'Can not set duplicates， please check again',
      });
    }
    const temp0 = params.channelAccount.map((i: any, index: number) => {
      return {
        ReferenceType: 0,
        ReferenceNum: i,
        ReferenceName: 'string',
        Group: checked ? 0 : 1,
        Seq: index,
        WarehouseNum: Number(activeTab),
        // MasterAccountNum: 0,
        // ProfileNum: 0
      };
    });
    const temp1 = params.ShippingService.map((i: any, index: number) => {
      return {
        ReferenceType: 1,
        ReferenceNum: i,
        ReferenceName: 'string',
        Group: checked ? 1 : 0,
        Seq: index,
        WarehouseNum: Number(activeTab),
        // MasterAccountNum: 0,
        // ProfileNum: 0
      };
    });
    try {
      setLoading2(true);
      const res = await postShippriority(Number(activeTab), [
        ...temp0,
        ...temp1,
      ]);
      setLoading2(false);
      if (res.isSuccess) {
        message.success({ content: 'Saved Successfully' });
        getPrioritys(Number(activeTab));
      }
    } catch (error) {
      setLoading2(false);
    }
  }, [activeTab, checked, form, getPrioritys]);

  /* eslint-disable */
  useEffect(() => {
    if (activeTab) {
      // setGroup0Prioritys(undefined);
      // setGroup1Prioritys(undefined);
      setChecked(true);
      getServices(Number(activeTab));
      getChannelAccounts(Number(activeTab));
      getPrioritys(Number(activeTab));
    }
  }, [activeTab]);
  useEffect(() => {
    getWarehouses();
  }, []);
  /* eslint-enable */

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const renderChannelAccount = (margint: number) => (
    <Card
      style={{ marginTop: margint }}
      title={
        <Row justify="space-between">
          <Text style={{ fontSize: 20 }}>Channel Account (store)</Text>
          <Space>
            <Text>{checked ? 'First Priority' : 'Second Priority'}</Text>
            {checked ? (
              <DownCircleOutlined
                id="DownCircleOutlined_icon"
                style={{
                  color: '#999',
                  fontSize: 24,
                }}
                onClick={() => setChecked((prev) => !prev)}
              />
            ) : (
              <UpCircleOutlined
                id="UpCircleOutlined_icon"
                style={{
                  color: '#999',
                  fontSize: 24,
                }}
                onClick={() => setChecked((prev) => !prev)}
              />
            )}
          </Space>
        </Row>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <div
          style={{
            width: 200,
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
          }}
        >
          <Card
            size="small"
            bodyStyle={{
              padding: '0 11 0 11',
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text>Channel Account priority</Text>
          </Card>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Form.List
            name="channelAccount"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least 1 priority'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...formItemLayout}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select channel account',
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        id="channel_account_select"
                        style={{ width: '50%' }}
                      >
                        {stores.map((item: any) => (
                          <Select.Option
                            key={item.channelAccountNum}
                            value={item.channelAccountNum}
                          >
                            {item.channelAccountName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Space
                        style={{
                          position: 'relative',
                          top: 4,
                          marginLeft: 8,
                        }}
                      >
                        <MinusCircleOutlined
                          id="MinusCircleOutlined_icon"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => remove(field.name)}
                        />

                        <UpCircleOutlined
                          id="UpCircleOutlined_icon_1"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => {
                            let arr = form.getFieldValue('channelAccount');
                            if (index !== 0) {
                              const temp = arr[index];
                              arr[index] = arr[index - 1];
                              arr[index - 1] = temp;
                              form.setFieldsValue({
                                channelAccount: arr,
                              });
                            }
                          }}
                        />
                        <DownCircleOutlined
                          id="DownCircleOutlined_icon_1"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => {
                            let arr = form.getFieldValue('channelAccount');

                            if (index !== fields.length - 1) {
                              const temp = arr[index];
                              arr[index] = arr[index + 1];
                              arr[index + 1] = temp;
                              form.setFieldsValue({
                                channelAccount: arr,
                              });
                            }
                          }}
                        />
                      </Space>
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item style={{ justifyContent: 'center' }}>
                  <Button
                    id="add_button"
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '50%' }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>
    </Card>
  );

  const renderService = (margint: number) => (
    <Card
      style={{ marginTop: margint }}
      title={
        <Row justify="space-between">
          <Text style={{ fontSize: 20 }}>Shipping Service</Text>
          <Space>
            <Text>{!checked ? 'First Priority' : 'Second Priority'}</Text>
            {!checked ? (
              <DownCircleOutlined
                id="DownCircleOutlined_icon_2"
                style={{
                  color: '#999',
                  fontSize: 24,
                }}
                onClick={() => setChecked((prev) => !prev)}
              />
            ) : (
              <UpCircleOutlined
                id="UpCircleOutlined_icon_2"
                style={{
                  color: '#999',
                  fontSize: 24,
                }}
                onClick={() => setChecked((prev) => !prev)}
              />
            )}
          </Space>
        </Row>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <div
          style={{
            width: 200,
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
          }}
        >
          <Card
            size="small"
            bodyStyle={{
              padding: '0 11 0 11',
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text>Shipping Service priority</Text>
          </Card>
        </div>
        <div style={{ flexGrow: 1 }}>
          <Form.List
            name="ShippingService"
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least 1 priority'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    {...formItemLayout}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message: 'Please select service',
                        },
                      ]}
                      noStyle
                    >
                      <Select id="service_select" style={{ width: '50%' }}>
                        {services.map((item: any) => (
                          <Select.Option
                            key={item.carrierServiceNum}
                            value={item.carrierServiceNum}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <Space
                        style={{
                          position: 'relative',
                          top: 4,
                          marginLeft: 8,
                        }}
                      >
                        <MinusCircleOutlined
                          id="MinusCircleOutlined_icon_3"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => remove(field.name)}
                        />
                        <UpCircleOutlined
                          id="UpCircleOutlined_icon_3"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => {
                            let arr = form.getFieldValue('ShippingService');
                            if (index !== 0) {
                              const temp = arr[index];
                              arr[index] = arr[index - 1];
                              arr[index - 1] = temp;
                              form.setFieldsValue({
                                ShippingService: arr,
                              });
                            }
                          }}
                        />
                        <DownCircleOutlined
                          id="DownCircleOutlined_icon_3"
                          style={{
                            color: '#999',
                            fontSize: 24,
                          }}
                          onClick={() => {
                            let arr = form.getFieldValue('ShippingService');

                            if (index !== fields.length - 1) {
                              const temp = arr[index];
                              arr[index] = arr[index + 1];
                              arr[index + 1] = temp;
                              form.setFieldsValue({
                                ShippingService: arr,
                              });
                            }
                          }}
                        />
                      </Space>
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item style={{ justifyContent: 'center' }}>
                  <Button
                    id="add_button_2"
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '50%' }}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>
    </Card>
  );

  return (
    <>
      <HeaderTitle
        breadcrumb={['Administration', 'Shipping', 'Shipping Priority']}
      ></HeaderTitle>

      <GreyCardWrapper style={{ marginTop: 20 }}>
        {warehouses ? (
          <ClassicCard loading={loading || loading1}>
            {warehouses.length > 0 && (
              <Tabs
                activeKey={activeTab}
                // defaultActiveKey={activeTab || `${warehouses[0].warehouseNum}`}
                type="card"
                size="large"
                tabPosition="top"
                style={{ width: '100%', maxWidth: 1400 }}
                // style={{ width: 0 }}
                onChange={(activeKey) => {
                  setActiveTab(activeKey);
                }}
              >
                {warehouses.map((item) => (
                  <TabPane
                    tab={item.warehouseCode}
                    key={`${item.warehouseNum}`}
                  >
                    <ClassicCard
                      size="small"
                      loading={loading1}
                      title={
                        <Row justify="end">
                          <Space>
                            <Button
                              id="save_button"
                              loading={loading2}
                              type="primary"
                              onClick={() => handleSave()}
                              icon={<SaveOutlined />}
                            >
                              Save
                            </Button>
                          </Space>
                        </Row>
                      }
                    >
                      <Form form={form}>
                        {checked ? (
                          <>
                            {renderChannelAccount(0)}
                            {renderService(10)}
                          </>
                        ) : (
                          <>
                            {renderService(0)}
                            {renderChannelAccount(10)}
                          </>
                        )}
                      </Form>
                    </ClassicCard>
                  </TabPane>
                ))}
              </Tabs>
            )}
          </ClassicCard>
        ) : (
          <Empty />
        )
        }
      </GreyCardWrapper >
    </>
  );
}
