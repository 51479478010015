import React from 'react';
import LoadingIcon from '../LoadingIcon';
/**
 * Return LoadMast component
 * @param {StringKAnyVPair} props
 * @returns {JSX.Element}
 */
export default function LoadMask(props: StringKAnyVPair): JSX.Element {
  const { visible, zIndex } = props;
  return visible ? (
    <div
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex,
        background: 'rgba(21, 24, 21, 0.25)',
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingIcon size="large" />
    </div>
  ) : (
    <></>
  );
}
