import React from 'react';
import { Alert, Col, Input, Modal, Row } from 'antd';
import { FormLabel, FormField, message } from 'components/common';


import {
  FormRightAlign,
  FormVSpacer,
} from 'components/common/styles/CustomPropsComponents';

type Props = {
  close: Function;
  editMode: number;
  profileName: string;
  visible: boolean;
  save: Function;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { useState } = React;
  const [profileName, setProfileName] = useState(props.profileName);
  const [profileNameError, setProfileNameError] = useState(false);
  const saveButtonLabel = props.editMode === 1 ? 'Save' : 'Update';
  const title =
    props.editMode === 1
      ? 'Save as a new Filter Profile'
      : 'Update Existing Filter Profile';

  const handleCancel = () => {
    props.close();
  };

  const handleSave = async () => {
    if (profileName) {
      await props.save(profileName);
      props.close();
    } else {
      message.error('Please input your profile name');
      setProfileNameError(true);
    }
  };

  const onProfileNameChange = (evt: any) => {
    const name = evt.target.value.trim();

    setProfileName(name);

    if (name) {
      setProfileNameError(false);
    }
  };

  return (
    <Modal
      title={title}
      destroyOnClose
      visible={props.visible}
      maskClosable={false}
      okText={saveButtonLabel}
      onOk={handleSave}
      onCancel={handleCancel}
    >
      <Alert
        message="Start Date & End Date filters will be saved based on the offset from the current saved date."
        type="warning"
      />
      <FormVSpacer height={24} />
      <Row align="middle">
        <Col offset={3} span={6}>
          <FormRightAlign paddingRight={12}>
            <FormLabel>Profile Name</FormLabel>
          </FormRightAlign>
        </Col>
        <Col span={12}>
          <FormField error={profileNameError}>
            <Input
              maxLength={128}
              onChange={onProfileNameChange}
              style={{ width: '100%' }}
              value={profileName}
            />
          </FormField>
        </Col>
      </Row>
    </Modal>
  );
};
