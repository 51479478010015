import React from 'react';
import { Col } from 'antd';
import { Loading } from 'components/common';
import { LOADING_ICON_SIZE1, MASK_BLOCK_INTERVAL } from 'constants/config';

type Props = {
  children?: React.ReactNode;
};

const TimerLock = (props: Props) => {
  const MASK_BLOCK_TIMER = 1000 * MASK_BLOCK_INTERVAL;
  const { useState } = React;
  const { children } = props;
  const [inited, setInited] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const shell = React.useRef(null);

  React.useEffect(() => {
    if (!inited) {
      setInited(true);
      setTimeout(() => {
        if (shell?.current) {
          setIsTimeout(true);
        }
      }, MASK_BLOCK_TIMER);
    }
  }, [MASK_BLOCK_TIMER, inited]);

  return (
    <>
      <Col ref={shell} style={{ height: '100%' }}>
        { isTimeout ? children :
          <Loading size={LOADING_ICON_SIZE1} />
        }
      </Col>
    </>
  );
};

export default TimerLock;
