import React, { useCallback, useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Row,
  Modal,
  Button,
  Space,
  Select,
  Card,
  Image,
  Col,
  Typography,
  Checkbox,
  Radio,
} from 'antd';
import {
  SaveOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { message } from 'components/common';
import { FormLabel } from 'components/common';
import { shallowEqual, useSelector } from 'react-redux';
import { SetShipAccount, getShipAccountById } from 'services/ship';
import { TwoCol } from './style';
import { RootState } from 'reducers';
// import {SelectWarehouse} from 'components/common';
import UPSIcon from 'assets/images/ups.png';
import FedexIcon from 'assets/images/fedEx.png';
import USPSIcon from 'assets/images/usps.png';
import Purolator from 'assets/images/logotrans.gif';
import DHLIcon from 'assets/images/dhl.png'
import Other from 'assets/images/otherShipping.png';
import { getAddressList } from 'services/address';
import CreateAddressDialog from 'components/Admin/AddressBook/createDialog';
import { COMMON_CARD_WRAPPER_MARGIN } from 'constants/config';
import theme from 'assets/styles/theme';
import {
  getShippingProviderAccounts,
  getShippingProviderAccountsCarrierInfo,
} from 'services/shippingProviderAccount';

const { Text } = Typography;

interface CreateLotProps extends ModalProps {
  onClose: Function;
  carrier: number;
  carrierKeyValue: { [key: string]: any };
  actionType: 'add' | 'edit';
  accountInfo?: ShipAccountRow;
}

// eslint-disable-next-line
export default (props: CreateLotProps) => {
  const { carrier, actionType, accountInfo, carrierKeyValue } = props;
  const [loading, setLoading] = useState(false);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [info, setInfo] = useState<any>();
  const warehouses = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [mode, setMode] = useState<number>(0);
  const [addressBook, setAddressBook] = useState<AddressRow[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressRow>();
  const [addressAct, setAddressAct] = useState<'add' | 'edit'>('add');
  const [createlVisible, setCreatelVisible] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const refreshBtnRef = useRef<any>(null);
  const [useProviderAccount, setUseProviderAccount] = useState(carrier === 5 ? true : false);
  const [providerAccounts, setProviderAccounts] = useState<any[]>([]);
  const [selectedProviderAccount, setSelectedProviderAccount] =
    useState<number>();
  const [carrierAccounts, setCarrierAccounts] = useState<any[]>([]);
  const [selectedUsage, setSelectedUsage] = useState(0)

  const [form] = Form.useForm();

  useEffect(()=>{
    setSelectedUsage(info? info.accountUsage: 0)
  },[info])

  const fetchProviderAccounts = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getShippingProviderAccounts();
      setLoading(false);
      if (res.isSuccess) {
        setProviderAccounts(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if ([1, 2, 3, 5].indexOf(carrier) > -1) {
      fetchProviderAccounts();
    }
  }, [carrier, fetchProviderAccounts]);

  const closeProductDetailDialog = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: { className: 'hover-danger-button' },
        content: `Changes you made may not be saved.`,
        onCancel() {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  // const onMarkShipOnlyChange = (evt: any) => {
  //   setMarkShipOnly(evt.target.checked);
  // };

  const getAccountInfo = useCallback(async () => {
    if (!accountInfo) return;
    try {
      setLoading(true);
      const res = await getShipAccountById(accountInfo.shipAccountNum);
      setLoading(false);
      if (res.isSuccess) {
        setInfo(res.data);
        setMode(res.data.mode);
        setSelectedProviderAccount(
          res.data?.shipingProviderAccountInfo?.shippingProviderAccountNum
        );
        setUseProviderAccount(
          res.data.shipingProviderAccountInfo ? true : false
        );
      } else {
        message.error({ content: res.message });
      }
    } catch (error) {
      setLoading(false);
    }
  }, [accountInfo]);

  const fetchAddressBook = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAddressList();
      setLoading(false);
      if (res.isSuccess) {
        setAddressBook(res.data);
        setSelectedAddress((prev) => {
          const temp = res.data.filter(
            (i: AddressRow) => i.addressNum === prev?.addressNum
          );
          if (temp.length > 0) {
            return temp[0];
          } else {
            return undefined;
          }
        });
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const getProviderAccountInfo = useCallback(
    async (accountNum: number, carrierNum: number) => {
      form.setFieldsValue({ CarrierAccountNum: undefined });
      try {
        const res = await getShippingProviderAccountsCarrierInfo(
          accountNum,
          carrierNum
        );
        if (res.isSuccess) {
          setCarrierAccounts(res.data);
        }
      } catch (error) {
        return undefined;
      }
    },
    [form]
  );

  useEffect(() => {
    if (selectedProviderAccount) {
      getProviderAccountInfo(selectedProviderAccount, carrier);
    }
  }, [selectedProviderAccount, carrier, getProviderAccountInfo]);
  //   account
  // :
  // "276258362"
  // billingType
  // :
  // "carrier"
  // carrierType
  // :
  // "FedexAccount"
  // id
  // :
  // "ca_6a079a0672464c1ca1b34a80fc5e52ad"
  // name
  // :
  // "FedEx"

  const handleCreate = async () => {
    const params = await form.validateFields();
    console.log(params);
    if (params.errorFields) return;
    const WarehouseNums: any[] = [];
    warehouses.map((i) => {
      if (
        (params.shipIds || []).indexOf(i.id) > -1 ||
        (params.returnIds || []).indexOf(i.id) > -1
      )
        WarehouseNums.push({
          WarehouseNum: i.id,
          Ship: (params.shipIds || []).indexOf(i.id) > -1 ? 1 : 0,
          Return: (params.returnIds || []).indexOf(i.id) > -1 ? 1 : 0,
        });
      return true;
    });
    if (WarehouseNums.length === 0)
      return message.warning({
        content: 'At least one warehouse must be checked.',
      });
    setSaveLoading(true);
    try {
      let res: any = null;
      let providerInfo: any = undefined;
      if (useProviderAccount && params.CarrierAccountNum) {
        const temp = carrierAccounts.filter(
          (item) => item.id === params.CarrierAccountNum
        );
        if (temp.length > 0) {
          providerInfo = temp[0];
        }
      }
      switch (carrierKeyValue[`${carrier}`]) {
        case 'FedEx':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            // WarehouseNum: params.Common_WarehouseNum.warehouseNum,
            // WarehouseNums: params.Common_WarehouseNums.map((item: number) => {
            //   return { WarehouseNum: item };
            // }),
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            FedexAccountInfo: useProviderAccount
              ? undefined
              : {
                Account: params.FedEx_Account,
                Password:
                  params.FedEx_Password === '******'
                    ? null
                    : params.FedEx_Password,
                MeterNum: params.FedEx_Meter,
                Key: params.FedEx_Key === '******' ? null : params.FedEx_Key,
                HubId: params.FedEx_HubId,
              },
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
            Mode: params.FedEx_Mode,
            ShipingProviderAccountInfo: useProviderAccount
              ? {
                ShippingProviderAccountNum: params.ShippingProviderAccountNum,
                Name: params.Common_AccountNickname,
                Account: providerInfo.id,
                UserName: providerInfo.name,
                SandBox: params.Common_SandBox ? 1 : 0,
                Mode: params.FedEx_Mode,
              }
              : undefined,
          });
          break;
        case 'UPS':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            // WarehouseNums: params.Common_WarehouseNums.map((item: number) => {
            //   return { WarehouseNum: item };
            // }),
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            UPSAccountInfo: useProviderAccount
              ? undefined
              : {
                LicenseNumber: params.UPS_Mode === 0 ? params.UPS_License : '#',
                UserId: params.UPS_User_ID,
                Password:
                  params.UPS_Password === '******'
                    ? null
                    : params.UPS_Password,
                ShipperNumber: params.UPS_Shipper,
                ServiceDescription: params.UPS_Service_Dsecription,
              },
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
            Mode: params.UPS_Mode,
            ShipingProviderAccountInfo: useProviderAccount
              ? {
                ShippingProviderAccountNum: params.ShippingProviderAccountNum,
                Name: params.Common_AccountNickname,
                Account: providerInfo.id,
                UserName: providerInfo.name,
              }
              : undefined,
          });
          break;
        case 'USPS':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            // WarehouseNums: params.Common_WarehouseNums.map((item: number) => {
            //   return { WarehouseNum: item };
            // }),
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            USPSAccountInfo: useProviderAccount
              ? undefined
              : {
                Username: params.USPS_User_Name,
                Password:
                  params.USPS_Password === '******'
                    ? null
                    : params.USPS_Password,
                IntegrationID: params.USPS_Integration_ID,
              },
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
            ShipingProviderAccountInfo: useProviderAccount
              ? {
                ShippingProviderAccountNum: params.ShippingProviderAccountNum,
                Name: params.Common_AccountNickname,
                Account: providerInfo.id,
                UserName: providerInfo.name,
              }
              : undefined,
          });
          break;
        case 'Purolator':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            // WarehouseNums: params.Common_WarehouseNums.map((item: number) => {
            //   return { WarehouseNum: item };
            // }),
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            PurolatorAccountInfo: {
              ClientID: params.Purolator_ClientID,
              Username: params.Purolator_UserName,
              Password:
                params.Purolator_Password === '******'
                  ? null
                  : params.Purolator_Password,
            },
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
          });
          break;
        case 'DHL':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
            ShipingProviderAccountInfo: {
              ShippingProviderAccountNum: params.ShippingProviderAccountNum,
              Name: params.Common_AccountNickname,
              Account: providerInfo.id,
              UserName: providerInfo.name,
            }
          });
          break;
        case 'Others':
          res = await SetShipAccount({
            ShipAccountNum: actionType === 'add' ? 0 : info?.shipAccountNum,
            CarrierNum: carrier,
            Status: 1,
            Name: params.Common_AccountNickname,
            ShipAccountType: 1,
            // WarehouseNums: params.Common_WarehouseNums.map((item: number) => {
            //   return { WarehouseNum: item };
            // }),
            WarehouseNums,
            AccountUsage: params.Common_AccountUsage,
            OthersAccountInfo: {
              Username: params.Other_Customer,
              Phone: params.Others_Phone,
              Note: params.Others_note,
            },
            AddressNum: params.FromAddressNum,
            NotToChannelAccount: params.Common_NotToChannelAccount ? 1 : 0,
            SandBox: params.Common_SandBox ? 1 : 0,
          });
          break;
      }
      setSaveLoading(false);
      if (res.isSuccess) {
        message.success({
          content:
            actionType === 'add'
              ? 'Add ShipAccount successful'
              : 'Save ShipAccount successful',
        });
        props.onClose(true);
      } else {
        message.error({ content: res.message });
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  // eslint-disable-next-line
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const getForm = useCallback(() => {
    return (
      <Form
        {...layout}
        form={form}
        onFieldsChange={() => setIsFormTouched(true)}
      >
        {carrierKeyValue[`${carrier}`] === 'FedEx' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      <Select.Option value={0}>
                        Ship And Bill Third Party
                      </Select.Option>
                      <Select.Option value={1}>Ship Only</Select.Option>
                      <Select.Option value={2}>
                        Bill to Third Party Only
                      </Select.Option>
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 ||
                              i.warehouseType === 5 ||
                              i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={(info?.warehouses || [])
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={(info?.warehouses || [])
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                {/* <Col span={12}>
                <Form.Item
                  name="Common_WarehouseNums"
                  initialValue={
                    info
                      ? info.warehouseNums.filter((i: number) => i !== 0)
                      : undefined
                  }
                  label={<FormLabel>Warehouse </FormLabel>}
                  rules={[
                    { required: true, message: 'please select warehouse' },
                  ]}
                >
                  <Select
                    id="Common_WarehouseNums"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                  >
                    {warehouses
                      .filter((i) => (i.warehouseType === 1 || i.warehouseType === 5))
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.code}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col> */}
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_address_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                headStyle={{ justifyContent: 'center', alignContent: 'center' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        width={90}
                        src={FedexIcon}
                        alt="FedEx"
                      />
                    }
                    <span>Sign In Account Information</span>
                  </Space>
                }
              >
                <Row justify="center" style={{ marginBottom: 8 }}>
                  <Radio.Group
                    value={useProviderAccount}
                    buttonStyle="solid"
                    onChange={(e) => setUseProviderAccount(e.target.value)}
                  >
                    <Radio.Button disabled={info} value={false}>
                      FedEx Account
                    </Radio.Button>
                    <Radio.Button disabled={info} value={true}>
                      Provider Account
                    </Radio.Button>
                  </Radio.Group>
                </Row>
                {useProviderAccount ? (
                  <>
                    <Form.Item
                      name="ShippingProviderAccountNum"
                      initialValue={
                        info?.shipingProviderAccountInfo
                          ?.shippingProviderAccountNum
                      }
                      label={<FormLabel>Provider Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please select provider account!',
                        },
                      ]}
                    >
                      <Select onChange={setSelectedProviderAccount}>
                        {providerAccounts.map((item: any) => (
                          <Select.Option
                            key={item.shippingProviderAccountNum}
                            value={item.shippingProviderAccountNum}
                          >
                            {`${item.name} (${item.account})`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="CarrierAccountNum"
                      initialValue={info?.shipingProviderAccountInfo?.account}
                      label={<FormLabel>Carrier Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please select carrier account!',
                        },
                      ]}
                    >
                      <Select disabled={!selectedProviderAccount}>
                        {carrierAccounts.map((item) => (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                          >{`${item.name} (${item.account})`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="FedEx_Mode"
                      initialValue={info?.mode || 0}
                      label={<FormLabel>Mode</FormLabel>}
                    >
                      <Select
                        onChange={e => setMode(e)}
                        options={[
                          { label: 'Web Service', value: 0 },
                          { label: 'Rest Api', value: 1 }
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name="FedEx_Account"
                      initialValue={info?.account}
                      label={<FormLabel>FedEx Account No</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please input FedEx Account No!',
                        },
                      ]}
                    >
                      <Input id="FedEx_Account" />
                    </Form.Item>
                    <Form.Item
                      name="FedEx_Key"
                      initialValue={info ? '******' : undefined}
                      label={<FormLabel>{mode === 0 ? 'Key' : 'App Id'}</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please input Key!',
                        },
                      ]}
                    >
                      <Input id="FedEx_Key" />
                    </Form.Item>
                    <Form.Item
                      name="FedEx_Password"
                      initialValue={info ? '******' : undefined}
                      label={<FormLabel>{mode === 0 ? 'Password' : 'App Secret'}</FormLabel>}
                      rules={[
                        { required: selectedUsage !==3, message: 'Please input password!' },
                      ]}
                    >
                      <Input id="FedEx_Password" type="password" />
                    </Form.Item>
                    <Form.Item
                      name="FedEx_Meter"
                      initialValue={info?.meterNum}
                      label={<FormLabel>Meter #</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please input Meter!',
                        },
                      ]}
                    >
                      <Input id="FedEx_Meter" />
                    </Form.Item>
                    <Form.Item
                      name="FedEx_HubId"
                      initialValue={info?.hubId}
                      label={<FormLabel>Hub ID</FormLabel>}
                    >
                      <Input id="FedEx_HubId" />
                    </Form.Item>
                  </>
                )}
              </Card>
            </TwoCol>
          </>
        )}

        {carrierKeyValue[`${carrier}`] === 'UPS' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      <Select.Option value={0}>
                        Ship And Bill Third Party
                      </Select.Option>
                      <Select.Option value={1}>Ship Only</Select.Option>
                      <Select.Option value={2}>
                        Bill to Third Party Only
                      </Select.Option>
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 || i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  // extra={
                  //   selectedAddress
                  //     ? `${selectedAddress?.contactName}, ${selectedAddress?.street1} ${selectedAddress?.street2} ${selectedAddress?.street3}, ${selectedAddress?.city}, ${selectedAddress?.state} ${selectedAddress?.postalCode}, ${selectedAddress?.country}`
                  //     : ''
                  // }
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_address_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        width={90}
                        src={UPSIcon}
                        alt="UPS"
                      />
                    }
                    <span>Sign In Account Information</span>
                  </Space>
                }
              >
                <Row justify="center" style={{ marginBottom: 8 }}>
                  <Radio.Group
                    value={useProviderAccount}
                    buttonStyle="solid"
                    onChange={(e) => setUseProviderAccount(e.target.value)}
                  >
                    <Radio.Button disabled={info} value={false}>
                      UPS Account
                    </Radio.Button>
                    <Radio.Button disabled={info} value={true}>
                      Provider Account
                    </Radio.Button>
                  </Radio.Group>
                </Row>
                {useProviderAccount ? (
                  <>
                    <Form.Item
                      name="ShippingProviderAccountNum"
                      initialValue={
                        info?.shipingProviderAccountInfo
                          ?.shippingProviderAccountNum
                      }
                      label={<FormLabel>Provider Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please select provider account!',
                        },
                      ]}
                    >
                      <Select onChange={setSelectedProviderAccount}>
                        {providerAccounts.map((item: any) => (
                          <Select.Option
                            key={item.shippingProviderAccountNum}
                            value={item.shippingProviderAccountNum}
                          >
                            {`${item.name} (${item.account})`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="CarrierAccountNum"
                      initialValue={info?.shipingProviderAccountInfo?.account}
                      label={<FormLabel>Carrier Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please select carrier account!',
                        },
                      ]}
                    >
                      <Select disabled={!selectedProviderAccount}>
                        {carrierAccounts.map((item) => (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                          >{`${item.name} (${item.account})`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="UPS_Mode"
                      initialValue={info?.mode || 0}
                      label={<FormLabel>Mode</FormLabel>}
                    >
                      <Select
                        onChange={e => setMode(e)}
                        options={[
                          { label: 'Web Service', value: 0 },
                          { label: 'Rest Api', value: 1 }
                        ]}
                      />
                    </Form.Item>
                    {mode===0 && <Form.Item
                      name="UPS_License"
                      initialValue={info?.licenseNumber}
                      label={<FormLabel>License #</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please input License!',
                        },
                      ]}
                    >
                      <Input id="UPS_License" />
                    </Form.Item>}
                    <Form.Item
                      name="UPS_User_ID"
                      initialValue={info?.userId}
                      label={<FormLabel>{mode === 0 ? 'User ID' : 'Client Id'}</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: mode === 0 ? 'Please input User ID!' : 'Please input Client Id!',
                        },
                      ]}
                    >
                      <Input id="UPS_User_ID" />
                    </Form.Item>
                    <Form.Item
                      name="UPS_Password"
                      initialValue={info ? '******' : undefined}
                      label={<FormLabel>{mode===0 ? 'Password': 'Client Secret'}</FormLabel>}
                      rules={[
                        { required: selectedUsage !==3, message: mode===0? 'Please input password!': 'Please input client secret!' },
                      ]}
                    >
                      <Input id="UPS_Password" type="password" />
                    </Form.Item>

                    <Form.Item
                      name="UPS_Shipper"
                      initialValue={info?.shipperNumber}
                      label={<FormLabel>Shipper #</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !==3,
                          message: 'Please input Shipper!',
                        },
                      ]}
                    >
                      <Input id="UPS_Shipper" />
                    </Form.Item>
                    <Form.Item
                      name="UPS_Service_Dsecription"
                      initialValue={info?.serviceDescription}
                      label={<FormLabel>Service Dsecription</FormLabel>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please input Service Dseription!',
                    //   },
                    // ]}
                    >
                      <Input id="UPS_Service_Dsecription" />
                    </Form.Item>
                  </>
                )}
              </Card>
            </TwoCol>
          </>
        )}

        {carrierKeyValue[`${carrier}`] === 'USPS' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      {/* <Select.Option value={0}>
                      Ship And Bill Third Party
                    </Select.Option> */}
                      <Select.Option value={1}>Ship Only</Select.Option>
                      {/* <Select.Option value={2}>Bill Third Party Only</Select.Option>  */}
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 || i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  // extra={
                  //   selectedAddress
                  //     ? `${selectedAddress?.contactName}, ${selectedAddress?.street1} ${selectedAddress?.street2} ${selectedAddress?.street3}, ${selectedAddress?.city}, ${selectedAddress?.state} ${selectedAddress?.postalCode}, ${selectedAddress?.country}`
                  //     : ''
                  // }
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_address_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        width={90}
                        src={USPSIcon}
                        alt="USPS"
                      />
                    }
                    <span>Sign In Account Information</span>
                  </Space>
                }
              >
                <Row justify="center" style={{ marginBottom: 8 }}>
                  <Radio.Group
                    value={useProviderAccount}
                    buttonStyle="solid"
                    onChange={(e) => setUseProviderAccount(e.target.value)}
                  >
                    <Radio.Button disabled={info} value={false}>
                      USPS Account
                    </Radio.Button>
                    <Radio.Button disabled={info} value={true}>
                      Provider Account
                    </Radio.Button>
                  </Radio.Group>
                </Row>
                {useProviderAccount ? (
                  <>
                    <Form.Item
                      name="ShippingProviderAccountNum"
                      initialValue={
                        info?.shipingProviderAccountInfo
                          ?.shippingProviderAccountNum
                      }
                      label={<FormLabel>Provider Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !== 3,
                          message: 'Please select provider account!',
                        },
                      ]}
                    >
                      <Select onChange={setSelectedProviderAccount}>
                        {providerAccounts.map((item: any) => (
                          <Select.Option
                            key={item.shippingProviderAccountNum}
                            value={item.shippingProviderAccountNum}
                          >
                            {`${item.name} (${item.account})`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="CarrierAccountNum"
                      initialValue={info?.shipingProviderAccountInfo?.account}
                      label={<FormLabel>Carrier Account</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !== 3,
                          message: 'Please select carrier account!',
                        },
                      ]}
                    >
                      <Select disabled={ !selectedProviderAccount}>
                        {carrierAccounts.map((item) => (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                          >{`${item.name} (${item.account})`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="USPS_User_Name"
                      initialValue={info?.username}
                      label={<FormLabel>User Name</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !== 3,
                          message: 'Please input User Name!',
                        },
                      ]}
                    >
                      <Input id="USPS_User_Name"/>
                    </Form.Item>
                    <Form.Item
                      name="USPS_Password"
                      initialValue={info ? '******' : undefined}
                      label={<FormLabel>Password</FormLabel>}
                      rules={[
                        { required: selectedUsage !== 3, message: 'Please input password!' },
                      ]}
                    >
                      <Input id="USPS_Password" type="password" />
                    </Form.Item>
                    <Form.Item
                      name="USPS_Integration_ID"
                      initialValue={info?.integrationID}
                      label={<FormLabel>Integration ID</FormLabel>}
                      rules={[
                        {
                          required: selectedUsage !== 3,
                          message: 'Please input Integration ID!',
                        },
                      ]}
                    >
                      <Input id="USPS_Integration_ID" />
                    </Form.Item>
                  </>
                )}
              </Card>
            </TwoCol>
          </>
        )}

        {carrierKeyValue[`${carrier}`] === 'Purolator' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      {/* <Select.Option value={0}>
                      Ship And Bill Third Party
                    </Select.Option> */}
                      <Select.Option value={1}>Ship Only</Select.Option>
                      {/* <Select.Option value={2}>Bill Third Party Only</Select.Option>  */}
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 || i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  // extra={
                  //   selectedAddress
                  //     ? `${selectedAddress?.contactName}, ${selectedAddress?.street1} ${selectedAddress?.street2} ${selectedAddress?.street3}, ${selectedAddress?.city}, ${selectedAddress?.state} ${selectedAddress?.postalCode}, ${selectedAddress?.country}`
                  //     : ''
                  // }
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_adress_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        width={90}
                        src={Purolator}
                        alt="USPS"
                      />
                    }
                    <span>Sign In Account Information</span>
                  </Space>
                }
              >
                <Form.Item
                  name="Purolator_ClientID"
                  initialValue={info?.clientID}
                  label={<FormLabel>Client ID</FormLabel>}
                  rules={[
                    {
                      required: selectedUsage !== 3,
                      message: 'Please input  client ID!',
                    },
                  ]}
                >
                  <Input id="Purolator_ClientID" />
                </Form.Item>

                <Form.Item
                  name="Purolator_UserName"
                  initialValue={info?.username}
                  label={<FormLabel>User Name</FormLabel>}
                  rules={[
                    {
                      required: selectedUsage !== 3,
                      message: 'Please input User Name!',
                    },
                  ]}
                >
                  <Input id="Purolator_UserName" />
                </Form.Item>
                <Form.Item
                  name="Purolator_Password"
                  initialValue={info ? '******' : undefined}
                  label={<FormLabel>Password</FormLabel>}
                  rules={[
                    { required: selectedUsage !== 3, message: 'Please input password!' },
                  ]}
                >
                  <Input id="Purolator_Password" type="password"/>
                </Form.Item>
              </Card>
            </TwoCol>
          </>
        )}

        {carrierKeyValue[`${carrier}`] === 'DHL' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      <Select.Option value={0}>
                        Ship And Bill Third Party
                      </Select.Option>
                      <Select.Option value={1}>Ship Only</Select.Option>
                      <Select.Option value={2}>
                        Bill to Third Party Only
                      </Select.Option>
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 ||
                              i.warehouseType === 5 ||
                              i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={(info?.warehouses || [])
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={(info?.warehouses || [])
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_address_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                headStyle={{ justifyContent: 'center', alignContent: 'center' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        width={90}
                        src={DHLIcon}
                        alt="DHL"
                      />
                    }
                    <span>Sign In Account Information</span>
                  </Space>
                }
              >
                <>
                  <Form.Item
                    name="ShippingProviderAccountNum"
                    initialValue={
                      info?.shipingProviderAccountInfo
                        ?.shippingProviderAccountNum
                    }
                    label={<FormLabel>Provider Account</FormLabel>}
                    rules={[
                      {
                        required: selectedUsage !== 3,
                        message: 'Please select provider account!',
                      },
                    ]}
                  >
                    <Select onChange={setSelectedProviderAccount}>
                      {providerAccounts.map((item: any) => (
                        <Select.Option
                          key={item.shippingProviderAccountNum}
                          value={item.shippingProviderAccountNum}
                        >
                          {`${item.name} (${item.account})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="CarrierAccountNum"
                    initialValue={info?.shipingProviderAccountInfo?.account}
                    label={<FormLabel>Carrier Account</FormLabel>}
                    rules={[
                      {
                        required: selectedUsage !== 3,
                        message: 'Please select carrier account!',
                      },
                    ]}
                  >
                    <Select disabled={!selectedProviderAccount}>
                      {carrierAccounts.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                        >{`${item.name} (${item.account})`}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              </Card>
            </TwoCol>
          </>
        )}

        {carrierKeyValue[`${carrier}`] === 'Others' && (
          <>
            <Card>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="Common_AccountNickname"
                    initialValue={info?.name}
                    label={<FormLabel>Name</FormLabel>}
                    rules={[{ required: true, message: 'please input Name' }]}
                  >
                    <Input id="Common_AccountNickname" />
                  </Form.Item>
                  <Form.Item
                    name="Common_AccountUsage"
                    initialValue={info?.accountUsage}
                    label={<FormLabel>Account Usage </FormLabel>}
                    rules={[
                      {
                        required: true,
                        message: 'please select account usage',
                      },
                    ]}
                  >
                    <Select id="Common_AccountUsage" onChange={setSelectedUsage}>
                      {/* <Select.Option value={0}>
                      Ship And Bill Third Party
                    </Select.Option> */}
                      <Select.Option value={1}>Ship Only</Select.Option>
                      {/* <Select.Option value={2}>Bill Third Party Only</Select.Option>  */}
                      <Select.Option value={3}>
                        Mark Ship Only
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="Common_NotToChannelAccount"
                    initialValue={info?.notToChannelAccount ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_NotToChannelAccount">
                      Don't copy to Channel account
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="Common_SandBox"
                    initialValue={info?.sandBox ? true : false}
                    valuePropName="checked"
                    label={<FormLabel noColon> </FormLabel>}
                  >
                    <Checkbox id="Common_SandBox">
                      SandBox
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Card
                    size="small"
                    title={
                      <Row>
                        <Col span={12}>Warehouse</Col>
                        <Col span={6}>Ship</Col>
                        <Col span={6}>Return</Col>
                      </Row>
                    }
                    bodyStyle={{ height: 100, overflowY: 'auto' }}
                  >
                    <Row>
                      <Col span={12}>
                        {warehouses
                          .filter(
                            (i) =>
                              i.warehouseType === 1 || i.warehouseType === 5
                          )
                          .map((item) => (
                            <Row id={item.code} key={item.code}>
                              <Typography.Text>{item.code}</Typography.Text>
                            </Row>
                          ))}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="shipIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.ship)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_ship`}
                                  key={`${item.code}_ship`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="returnIds"
                          initialValue={info?.warehouses
                            .filter((i: any) => i.return)
                            .map((i: any) => i.warehouseNum)}
                        >
                          <Checkbox.Group style={{ width: '100%' }}>
                            {warehouses
                              .filter(
                                (i) =>
                                  i.warehouseType === 1 || i.warehouseType === 5
                              )
                              .map((item) => (
                                <Row
                                  id={`${item.code}_return`}
                                  key={`${item.code}_return`}
                                >
                                  <Checkbox value={item.id} />
                                </Row>
                              ))}
                          </Checkbox.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Card>
            <TwoCol style={{ marginTop: COMMON_CARD_WRAPPER_MARGIN }}>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                bodyStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: 250,
                }}
                title="Contact Address"
              >
                <>
                  <Form.Item
                    name="FromAddressNum"
                    initialValue={info?.addressDto?.addressNum}
                    label={<FormLabel>Address</FormLabel>}
                    rules={[{ required: true, message: 'Address is required' }]}
                  // extra={
                  //   selectedAddress
                  //     ? `${selectedAddress?.contactName}, ${selectedAddress?.street1} ${selectedAddress?.street2} ${selectedAddress?.street3}, ${selectedAddress?.city}, ${selectedAddress?.state} ${selectedAddress?.postalCode}, ${selectedAddress?.country}`
                  //     : ''
                  // }
                  >
                    <Select
                      id="FromAddressNum"
                      optionLabelProp="label"
                      allowClear
                      onChange={(num) =>
                        setSelectedAddress(
                          addressBook.filter(
                            (item) => item.addressNum === Number(num)
                          )[0]
                        )
                      }
                    >
                      {addressBook.map((item) => (
                        <Select.Option
                          value={item.addressNum}
                          key={item.addressNum}
                          label={item.addressAlias}
                        >
                          <span>{`${item.addressAlias || ''} - ${item.contactName ? item.contactName + ',' : ''
                            } ${item.street1 || ''} ${item.street2 || ''} ${item.street3 ? item.street3 + ',' : ','
                            } ${item.city ? item.city + ',' : ''} ${item.state || ''
                            } ${item.postalCode ? item.postalCode + ',' : ','} ${item.country || ''
                            }`}</span>{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {selectedAddress && (
                    <Form.Item label={<FormLabel noColon> </FormLabel>}>
                      <Space direction="vertical">
                        <Text>{selectedAddress.contactName || ''}</Text>
                        {selectedAddress.street1 && (
                          <Text>{selectedAddress.street1}</Text>
                        )}
                        {selectedAddress.street2 && (
                          <Text>{selectedAddress.street2}</Text>
                        )}
                        {selectedAddress.street3 && (
                          <Text>{selectedAddress.street3}</Text>
                        )}
                        <Text>{`${selectedAddress.city || ''}, ${selectedAddress.state || ''
                          } ${selectedAddress.postalCode || ''}`}</Text>
                        <Text>{selectedAddress.country || ''}</Text>
                      </Space>
                    </Form.Item>
                  )}
                </>
                <Space>
                  <Button
                    id="add_address_button"
                    onClick={() => {
                      setAddressAct('add');
                      setCreatelVisible(true);
                    }}
                  >
                    <PlusOutlined />
                    New Address
                  </Button>
                  <Button
                    id="edit_address_button"
                    disabled={!selectedAddress}
                    onClick={() => {
                      setAddressAct('edit');
                      setCreatelVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Update Address
                  </Button>
                </Space>
              </Card>
              <Card
                size="small"
                style={{ minWidth: 500, width: 'calc(50% - 8px)' }}
                title={
                  <Space>
                    {
                      <Image
                        preview={false}
                        style={{ height: 50 }}
                        src={Other}
                        alt="Other"
                      />
                    }
                    <span>Account Information</span>
                  </Space>
                }
              >
                <Form.Item
                  name="Other_Customer"
                  initialValue={info?.username}
                  label={<FormLabel>Account No.</FormLabel>}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input  Customer!',
                //   },
                // ]}
                >
                  <Input id="Other_Customer" />
                </Form.Item>

                <Form.Item
                  name="Others_Phone"
                  initialValue={info?.phone}
                  label={<FormLabel>Tel</FormLabel>}
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input contact number!',
                //   },
                // ]}
                >
                  <Input id="Others_Phone" />
                </Form.Item>
                <Form.Item
                  name="Others_note"
                  initialValue={info?.note}
                  label={<FormLabel>Note</FormLabel>}
                >
                  <Input />
                </Form.Item>
              </Card>
            </TwoCol>
          </>
        )}
      </Form>
    );
  }, [
    addressBook,
    carrier,
    carrierAccounts,
    carrierKeyValue,
    mode,
    form,
    info,
    layout,
    providerAccounts,
    selectedAddress,
    selectedProviderAccount,
    useProviderAccount,
    warehouses,
    selectedUsage
  ]);

  useEffect(() => {
    if (actionType === 'edit') {
      getAccountInfo();
    }
  }, [actionType, getAccountInfo]);
  useEffect(() => {
    fetchAddressBook();
  }, [fetchAddressBook]);

  useEffect(() => {
    if (addressBook.length > 0 && info) {
      setSelectedAddress(
        addressBook.filter(
          (item) => item.addressNum === info.addressDto?.addressNum
        )[0]
      );
    }
  }, [addressBook, info]);

  return (
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton={true}
      footer={<Space>
        <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={saveLoading}
          onClick={handleCreate}
          icon={actionType === 'add' ? <PlusOutlined /> : <SaveOutlined />}
          disabled={!isFormTouched}
        >
          {actionType === 'add' ? 'Add' : 'Save'}
        </Button>
      </Space>}
      fullscreen={true}
      maskClosable={false}
      onClose={closeProductDetailDialog}
      onFullscreen={onFullscreen}
      title={`${actionType === 'add' ? 'Add' : 'Edit'} ${carrierKeyValue[`${carrier}`]
        } Account`}
      visible={props.visible}
      width={detailDialogWidth()}
      // titleRightCrumb={
      //   <Space>
      //     {isFormTouched && (
      //       <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
      //         Cancel
      //       </Button>
      //     )}
      //     <Button
      //       type="primary"
      //       loading={saveLoading}
      //       onClick={handleCreate}
      //       icon={actionType === 'add' ? <PlusOutlined /> : <SaveOutlined />}
      //       disabled={!isFormTouched}
      //     >
      //       {actionType === 'add' ? 'Add' : 'Save'}
      //     </Button>
      //   </Space>
      // }
    >
      {/* <Modal
      title={`${actionType === 'add' ? 'Add' : 'Edit'} ${
        carrierKeyValue[`${carrier}`]
      } Account`}
      centered
      visible={visible}
      maskClosable={false}
      width={1100}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="add_or_save_button"
              key="submit"
              type="primary"
              onClick={handleCreate}
            >
              {actionType === 'add' ? <PlusOutlined /> : <SaveOutlined />}
              {actionType === 'add' ? 'Add' : 'Save'}
            </Button>
            <Button
              id="cancel_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    > */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          // backgroundColor: '#fff',
          backgroundColor: theme['@default-backgroud-color'],
        }}
      >
        <Card
          bodyStyle={{ padding: 8, overflowY: 'auto', height: '100%' }}
          style={{ width: '100%', height: '100%' }}
          loading={loading}
        >
          {actionType === 'add'
            ? getForm()
            : info && addressBook.length > 0 && getForm()}
        </Card>
        {createlVisible && (
          <CreateAddressDialog
            visible
            onHide={() => setCreatelVisible(false)}
            onRefresh={() => fetchAddressBook()}
            address={addressAct === 'add' ? undefined : selectedAddress}
          />
        )}
      </div>
    </ModalDialog>
  );
};
