import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Typography,
  Input,
  InputNumber,
  DatePicker,
  Checkbox,
  Tabs,
  Card,
  Col,
} from 'antd';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

import { getCountrys } from 'services/address';
import {
  fetchChannelAccountServiceList,
  fetchSignatureOptions,
  fetchShipmentItemsById,
} from 'services/sales';
import {
  fetchPackageAggregateListByCarrier,
  markShippedfromShipping,
  markShippedfromPending,
  holdShipment,
  unholdShipment,
} from 'services/shipment';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  LoadingIcon,
  message,
} from 'components/common';
import { WarningButton } from 'components/common/styles';

import CancelShipmentDialog from './CancelShipmentDialog';
import WrapFormLabel from './WrapFormLabel';
import { WrapFormItem } from './styles';
import {
  shipmentStatusKeyValue as statusKeyValue,
} from 'constants/enums'

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const labelWidth = 230;

const CardPlus = styled(Card)`
  & .ant-card-body {
    padding: 5px;
  }
`;

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  shipmentInfo: any;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, shipmentInfo } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingHold, setLoadingHold] = useState(false);
  const [defaultPackageList, setDefaultPackageList] = useState<any[]>([]);
  const [userPackageList, setUserPackageList] = useState<any[]>([]);
  const [selectedCarrierNum, setSelectedCarrierNum] = useState(-1000);
  const [serviceOptions, setServiceOptions] = useState<StringKAnyVPair[]>([]);
  const [signatureOptions, setSignatureOptions] = useState<StringKAnyVPair[]>(
    []
  );
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  const [isThird, setIsThird] = useState(false);
  const [selectedService, setSelectedService] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const [cancelDialogVisible, setCancelDialogVisible] = useState<boolean>(false);
  const reason = useRef<string>('');

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const isCancelledOrAbandoned = shipmentInfo.shippingStatus === 16 || shipmentInfo.shippingStatus === 32;

  const getItemColumns = (hasBundle: boolean) => {
    const columns = [
      {
        name: 'sku',
        header: 'SKU',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
        minWidth: 220,
      },
      {
        name: 'upc',
        header: 'UPC',
        defaultFlex: 1,
        userSelect: true,
        sortable: false,
      },
      {
        name: 'shippingQty',
        header: 'Quantity',
        defaultFlex: 1,
      },
    ];

    return hasBundle ? [...columns, {
      name: 'bundleSKU',
      header: 'Bundle',
      defaultFlex: 1,
      sortable: false,
      render: (row: any) => {
        const { data } = row;
        return data.bundleSKU ? `${data.bundleSKU}(${data.bundleQty}x${data.orderQty / data.bundleQty})` : ''
      }
    }] : columns;
  }

  const getServices = async () => {
    try {
      setLoading2(true);
      const res = await fetchChannelAccountServiceList(
        shipmentInfo.warehouseNum,
        shipmentInfo.channelAccountNum,
        1,
        1
      );
      setLoading2(false);
      setServiceOptions([...res]);
    } catch (e) {
      console.log(e);
      setLoading2(false);
    }
  };

  const getPackages = async () => {
    try {
      setLoading1(true);
      const res = await fetchPackageAggregateListByCarrier(
        shipmentInfo.warehouseNum,
        shipmentInfo.carrierNum
      );
      setLoading1(false);
      if (res) {
        setDefaultPackageList(res.carrierPackages ? res.carrierPackages : []);
        setUserPackageList(res.customPackages ? res.customPackages : []);
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const getShipmentItems = async () => {
    try {
      const res = await fetchShipmentItemsById(shipmentInfo.shipmentNum);
      setItems(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHoldShipment = useCallback(async () => {
    try {
      setLoadingHold(true);
      const res = await holdShipment(shipmentInfo.shipmentNum, reason.current);
      setLoadingHold(false);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Hold successfully' });
        document.body.style.overflowY = 'unset';
        onRefresh();
        onHide();
      }
    } catch (error) {
      reason.current = '';
      setLoadingHold(false);
    }
  }, [reason, shipmentInfo, onHide, onRefresh]);

  const handleUnholdShipment = useCallback(async () => {
    try {
      setLoadingHold(true);
      const res = await unholdShipment(
        shipmentInfo.shipmentNum,
        reason.current
      );
      setLoadingHold(false);
      reason.current = '';
      if (res.isSuccess) {
        message.success({ content: 'Unhold successfully' });
        document.body.style.overflowY = 'unset';
        onRefresh();
        onHide();
      }
    } catch (error) {
      reason.current = '';
      setLoadingHold(false);
    }
  }, [reason, shipmentInfo, onHide, onRefresh]);

  const handleSubmit = async () => {
    const params = await form.validateFields();

    try {
      setSubmitLoading(true);
      let res;
      if (shipmentInfo.shippingStatus === 9) {
        res = await markShippedfromShipping(shipmentInfo.shipmentNum, params);
      } else {
        const temp = defaultPackageList.filter(
          (i) => i.shippingPackageNum === params.shippingPackageNum
        );
        const temp1 = userPackageList.filter(
          (i) => i.shippingPackageNum === params.shippingPackageNum
        );
        res = await markShippedfromPending(shipmentInfo.shipmentNum, {
          ShipAccountNum: selectedService.shipAccountNum,
          CarrierNum: selectedService.carrierNum,
          Carrier: selectedService.carrierName,
          ServiceNum: selectedService.carrierServiceNum,
          ServiceCode: selectedService.carrierServiceCode,
          Service: selectedService.carrierServiceName,
          PackageNum:
            temp.length > 0
              ? temp[0].shippingPackageNum
              : temp1[0].shippingPackageNum,
          PackageCode: temp.length > 0 ? temp[0].code : temp1[0].code,
          Package: temp.length > 0 ? temp[0].name : temp1[0].name,
          Signature:
            params.shippingSignature || params.shippingSignature === 0
              ? params.shippingSignature
              : -2,
          BillToType: isThird ? 2 : 0,
          BillThirdPartyAccount: isThird ? params.account : undefined,
          BillThirdPartyPostalCode: isThird ? params.postalCode : undefined,
          BillThirdPartyCountry: isThird ? params.countryNum : undefined,
          TrackingNumber: params.TrackingNumber,
          SecondaryTrackingNumber: params.SecondaryTrackingNumber,
          ShippingCost: params.ShippingCost,
          ShipDate: params.ShipDate,
          Note: params.Note,
        });
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Mark shipped successfully' });
        document.body.style.overflowY = 'unset';
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const fetchCountryList = async () => {
    try {
      setLoading(true);
      const res = await getCountrys();
      setLoading(false);
      if (res.isSuccess) {
        setCountryList(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSignatureOptions = async () => {
    try {
      setLoading3(true);
      const options = await fetchSignatureOptions();
      setLoading3(false);
      if (Array.isArray(options) && options.length > 0) {
        setSignatureOptions([...options]);
      }
    } catch (e) {
      setLoading3(false);
      message.error(`Fetch signature options error: ${e}`);
    }
  };

  const findOptionByService = (
    services: StringKAnyVPair[],
    value: string | number
  ): StringKAnyVPair | undefined => {
    let found = false;
    let sel: StringKAnyVPair = {};

    services.every((e) => {
      e.items.every((f: StringKAnyVPair) => {
        if (Array.isArray(f.items)) {
          f.items.every((g) => {
            if (
              g.carrierServiceCode === value ||
              g.carrierServiceNum === value
            ) {
              //sel = g;
              sel = getUserOption(g, f);
              //if (sel) sel.carrier = e.carrier; // set carrier manual
              found = true;

              return false;
            }

            return !found;
          });
        }

        return !found;
      });

      return !found;
    });

    if (found) {
      return sel;
    }
  };
  const getUserOption = (opt: StringKAnyVPair, parent: StringKAnyVPair) => {
    return {
      ...opt,
      billThirdPartyAccount: parent.billThirdPartyAccount,
      billThirdPartyCountry: parent.billThirdPartyCountry,
      billThirdPartyCountryNum: parent.billThirdPartyCountryNum,
      billThirdPartyPostalCode: parent.billThirdPartyPostalCode,
      billToType: parent.billToType,
    };
  };

  const serviceSelectOptions = (options: StringKAnyVPair[]) => {
    return options.map((e) => (
      <Select.Option
        className="scan-verify-ship-service-option"
        //key={e.carrierServiceNum}
        key={uuid()}
        label={e.carrierServiceName}
        value={e.carrierServiceNum}
      >
        {e.carrierServiceName}
      </Select.Option>
    ));
  };
  /* eslint-disable */
  useEffect(() => {
    if (shipmentInfo && shipmentInfo.shippingStatus === 0) {
      getServices();
      getPackages();
      getSignatureOptions();
      fetchCountryList();
      // getShipmentItems();
      // handleLoad();
    }
  }, [shipmentInfo]);

  useEffect(() => {
    form.setFieldsValue({
      shippingPackageNum: undefined,
      shippingSignature: undefined,
    });
  }, [selectedCarrierNum]);

  useEffect(() => {
    if (shipmentInfo) {
      getShipmentItems();
    }
  }, [shipmentInfo]);

  const SimpleShipmentInfo = () => {
    const REASON_ENUM: { [key: number]: string } = {
      0: 'unHoldReason',
      8: 'holdReason',
      16: 'cancelReason',
    };
    const { shippingStatus: ss } = shipmentInfo || {};
    const isPending = ss === 0;
    const label = `${isPending ? 'Unhold' : statusKeyValue[ss]} Reason`
    const reasonAttr = REASON_ENUM[ss];
    return (
      <Row style={{ marginTop: 5 }}>
        <Col span={12}>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Shipment ID</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.shipmentId} />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Store Name</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.channelAccountName} />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Channel Order ID</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.channelOrderId} />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Fulfillment Order ID</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.fulfillmentOrderId} />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Status</FormLabel>
            </div>
            <Typography.Text type={[8, 16, 32].includes(ss) ? 'danger' : 'success'}>
              <CopySpan value={statusKeyValue[`${ss}`]} />
            </Typography.Text>
          </Row>
          {[0, 8, 16].includes(ss) && (
            <Row>
              <div style={{ width: labelWidth }}>
                <FormLabel>{label}</FormLabel>
              </div>
              <Typography.Text type={isPending ? 'success' : 'danger'}>
                <CopySpan value={shipmentInfo[reasonAttr]} />
              </Typography.Text>
            </Row>
          )}
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Order Date</FormLabel>
            </div>
            <CopySpan
              value={
                shipmentInfo.orderDate
                  ? moment(shipmentInfo.orderDate).format(
                    'MM/DD/YYYY hh:mm:ssA'
                  )
                  : ''
              }
            />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Carrier</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.shippingCarrier} />
          </Row>
          <Row>
            <div style={{ width: labelWidth }}>
              <FormLabel>Sevice</FormLabel>
            </div>
            <CopySpan value={shipmentInfo.shippingService} />
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col style={{ width: labelWidth }}>
              <FormLabel>Buyer/Recipient Address</FormLabel>
            </Col>
          </Row>
          <Row justify="center">
            <CardPlus style={{ width: '90%' }}>
              <Col>
                <Row justify="space-between">
                  <span>{shipmentInfo?.shipToName}</span>
                </Row>
                <Row>
                  <span>{shipmentInfo?.shipToAddressLine1}</span>
                </Row>
                <Row>
                  <span>{shipmentInfo?.shipToAddressLine2}</span>
                </Row>
                <Row>
                  <span>{shipmentInfo?.shipToAddressLine3}</span>
                </Row>
                {shipmentInfo &&
                  ['US', 'United States'].includes(
                    shipmentInfo.shipToCountry
                  ) ? (
                  <Row>
                    <span>{`${shipmentInfo?.shipToCity},${shipmentInfo?.shipToState
                      } ${shipmentInfo?.shipToPostalCode}${shipmentInfo?.shipToPostalCodeExt
                        ? `-${shipmentInfo.shipToPostalCodeExt}`
                        : ''
                      }`}</span>
                  </Row>
                ) : (
                  <Row>
                    <span>{`${shipmentInfo?.shipToCity},${shipmentInfo?.shipToState
                      },${shipmentInfo?.shipToPostalCode}${shipmentInfo?.shipToPostalCodeExt
                        ? `-${shipmentInfo.shipToPostalCodeExt}`
                        : ''
                      }`}</span>
                  </Row>
                )}
                {/* <Row>
                <span>{`${shipmentInfo?.shipToCity},${
                  shipmentInfo?.shipToState
                },${shipmentInfo?.shipToPostalCode}${
                  shipmentInfo?.shipToPostalCodeExt
                    ? `-${shipmentInfo.shipToPostalCodeExt}`
                    : ''
                }`}</span>
              </Row> */}
                <Row>{shipmentInfo?.shipToCountry}</Row>
                <Row>
                  <span>{shipmentInfo?.shipToDaytimePhone}</span>
                </Row>
              </Col>
            </CardPlus>
          </Row>
        </Col>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={4}>Items</Title>
          <DataGridPlus
            name="locationReport"
            idProperty="fulfillmentOrderLineNum"
            loading={loading}
            columns={getItemColumns(items.find(i => i.bundleType === 1))}
            dataSource={items}
            pagination="local"
            {...({} as any)}
          />
        </Space>
      </Row>
    )
  }
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title="Mark Shipped & Cancel & Hold"
      centered
      visible={visible}
      maskClosable={false}
      width={1000}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      // footer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Mark Shipped" key="1">
          <Spin
            spinning={
              submitLoading || loading || loading1 || loading2 || loading3
            }
            indicator={<LoadingIcon />}
          >
            <Form {...layout} form={form}>
              <Form.Item label={<FormLabel>Shipment #</FormLabel>}>
                <Text>{shipmentInfo.shipmentNum}</Text>
              </Form.Item>
              {shipmentInfo.shippingStatus === 0 && (
                <>
                  <Form.Item
                    name="shippingServiceNum"
                    label={<FormLabel>Service</FormLabel>}
                    rules={[
                      { required: true, message: 'please select a service' },
                    ]}
                  >
                    <Select
                      onChange={(value: string) => {
                        const sel = findOptionByService(serviceOptions, value);
                        setSelectedCarrierNum(sel ? sel.carrierNum : -1000);
                        setSelectedService(sel ? sel : undefined);
                      }}
                    >
                      {serviceOptions.map((e: any, i: number) => (
                        <Select.OptGroup label={e.carrier} key={i}>
                          {e.items.map((f: StringKAnyVPair) => (
                            //<React.Fragment key={f.shipAccountNum}>
                            <React.Fragment key={uuid()}>
                              {e.items.length > 1 ? (
                                <Select.Option
                                  className="scan-verify-ship-service-account-option"
                                  disabled
                                  //key={f.shipAccountNum}
                                  key={uuid()}
                                  label={f.shipAccountName}
                                  value={f.shipAccountNum}
                                >
                                  {f.shipAccountName}
                                </Select.Option>
                              ) : (
                                ''
                              )}
                              {serviceSelectOptions(f.items)}
                            </React.Fragment>
                          ))}
                        </Select.OptGroup>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="shippingPackageNum"
                    label={<FormLabel>Package</FormLabel>}
                    rules={[
                      { required: true, message: 'please select a package' },
                    ]}
                  >
                    <Select allowClear disabled={selectedCarrierNum < -999}>
                      <Select.OptGroup label="Custom">
                        {userPackageList.map((item) => (
                          <Select.Option
                            value={item.shippingPackageNum}
                            key={item.shippingPackageNum}
                          >
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label="System">
                        {defaultPackageList
                          .filter((i) => i.carrierNum === selectedCarrierNum)
                          .map((item) => (
                            <Select.Option
                              value={item.shippingPackageNum}
                              key={item.shippingPackageNum}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="shippingSignature"
                    label={<FormLabel>Signature</FormLabel>}
                  // rules={[
                  //   { required: true, message: 'please select a signature' },
                  // ]}
                  >
                    <Select disabled={selectedCarrierNum < -999}>
                      {signatureOptions
                        .filter((i) => i.carrierNum === selectedCarrierNum)
                        .map((e) => (
                          <Select.Option
                            key={e.carrierSignatureNum}
                            value={e.code}
                          >
                            {e.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label=" ">
                    <Checkbox onChange={(e) => setIsThird(e.target.checked)}>
                      Bill To Third Party
                    </Checkbox>
                  </Form.Item>
                  {isThird && (
                    <>
                      <Form.Item
                        name="account"
                        label={<FormLabel>Third Party Account</FormLabel>}
                        rules={[
                          {
                            required: true,
                            message: 'Third Party Account is required',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="postalCode"
                        label={<FormLabel>Zip Code</FormLabel>}
                        rules={[
                          { required: true, message: 'Zip Code is required' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="countryNum"
                        label={<FormLabel>Country</FormLabel>}
                        rules={[
                          { required: true, message: 'please select Country' },
                        ]}
                      >
                        <Select>
                          {countryList.map((item) => (
                            <Select.Option
                              key={item.countryNum}
                              value={item.iso2}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </>
              )}
              <WrapFormItem
                name="TrackingNumber"
                initialValue={shipmentInfo.trackingNumber}
                label={
                  <WrapFormLabel
                    labels={['PRO Number', 'Tracking Number']}
                  ></WrapFormLabel>
                }
                rules={[
                  { required: true, message: 'Please input Tracking Number!' },
                ]}
              >
                <Input />
              </WrapFormItem>

              <WrapFormItem
                name="SecondaryTrackingNumber"
                initialValue={shipmentInfo.secondaryTrackingNumber}
                label={
                  <WrapFormLabel
                    labels={[
                      'Bill Of Lading Number',
                      'Secondary Tracking Number',
                    ]}
                  ></WrapFormLabel>
                }
              // rules={[{ required: true, message: 'Please input Tracking Number!' }]}
              >
                <Input />
              </WrapFormItem>
              <Form.Item
                name="ShippingCost"
                // initialValue={shipmentInfo.totalShippingCost}
                label={<FormLabel>Shipping Cost</FormLabel>}
              // rules={[
              //   { required: true, message: 'Please input Shipping Cost!' },
              // ]}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                name="ShipDate"
                initialValue={
                  shipmentInfo.actualShipDate
                    ? moment(shipmentInfo.actualShipDate)
                    : undefined
                }
                label={<FormLabel>Ship Date</FormLabel>}
                rules={[
                  { required: true, message: 'Please select Ship Date!' },
                ]}
              >
                <DatePicker format={'MM/DD/YYYY'} />
              </Form.Item>

              <Form.Item
                name="Note"
                initialValue={shipmentInfo.note}
                label={<FormLabel>Note</FormLabel>}
              // rules={[{ required: true, message: 'Please input Tracking Number!' }]}
              >
                <Input />
              </Form.Item>

              <Row justify="center">
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    Modal.confirm({
                      title: 'Are you sure want to mark shipment shipped?',
                      okText: 'Yes',
                      cancelText: 'No',
                      content: 'This operation may not be restored',
                      onOk() {
                        handleSubmit();
                      },
                    });
                  }}
                  disabled={isCancelledOrAbandoned}
                  loading={submitLoading}
                >
                  {submitLoading ? 'Marking' : 'Mark Shipped'}
                </Button>
              </Row>
            </Form>
          </Spin>
        </TabPane>
        <TabPane tab="Cancel Shipment" key="2">
          <SimpleShipmentInfo />
          <Row justify="center" style={{ marginTop: 8 }}>
            <Button
              danger
              key="submit"
              type="primary"
              onClick={() => setCancelDialogVisible(true)}
              disabled={isCancelledOrAbandoned}
            >
              Cancel Shipment
            </Button>
          </Row>
        </TabPane>
        <TabPane tab="Hold / Unhold Shipment" key="3">
          <SimpleShipmentInfo />
          {
            shipmentInfo.shippingStatus !== 1 &&
            <Row justify="center" style={{ marginTop: 8 }}>
              <WarningButton
                key="submit"
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    title: <span>
                      <div>Do you want to {shipmentInfo.shippingStatus === 8 ? 'unhold' : 'hold'} this shipment?</div>
                      <div>Channel Order ID: {shipmentInfo.channelOrderId}</div>
                      <div>Shipment ID: {shipmentInfo.shipmentNum}</div>
                      <br />
                      <div>{shipmentInfo.shippingStatus === 8 ? 'Unhold Reason' : 'Hold Reason'}: </div>
                    </span>,
                    okText: 'Yes',
                    cancelText: 'No',
                    okType: 'default',
                    okButtonProps: { className: "hover-danger-button" },
                    autoFocusButton: 'cancel',
                    content: (
                      <div style={{ paddingRight: 38 }}>
                        <Input.TextArea
                          rows={2}
                          style={{ marginTop: -2 }}
                          placeholder={
                            shipmentInfo.shippingStatus === 8
                              ? 'Please input the unhold reason'
                              : 'Please input the hold reason'
                          }
                          onChange={(event) =>
                            (reason.current = event.target.value)
                          }
                        />
                      </div>
                    ),
                    onCancel() {
                      reason.current = '';
                    },
                    onOk() {
                      if (shipmentInfo.shippingStatus === 8) {
                        handleUnholdShipment();
                      } else {
                        handleHoldShipment();
                      }
                    },
                  });
                }}
                loading={loadingHold}
                disabled={isCancelledOrAbandoned}
              >
                {shipmentInfo.shippingStatus === 8
                  ? loadingHold
                    ? 'Unhoding'
                    : 'Unhold Shipment'
                  : loadingHold
                    ? 'Hoding'
                    : 'Hold Shipment'}
              </WarningButton>
            </Row>
          }
        </TabPane>
      </Tabs>
      <CancelShipmentDialog
        shipmentInfo={shipmentInfo}
        visible={cancelDialogVisible}
        onClose={() => setCancelDialogVisible(false)}
        refreshShipment={() => {
          document.body.style.overflowY = 'unset';
          onRefresh();
          onHide();
        }}
      />
    </Modal>
  );
};
