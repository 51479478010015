import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Space,
  Typography,
  Form,
  Tabs,
  Card,
  Input,
  Table,
  message,
  Col,
} from 'antd';
import { leaveEditForm } from 'utils';
import { FormLabel } from 'components/common';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';

const { Text } = Typography;
type Props = {
  onHide: () => void;
  onSubmit: (
    items: { [key: string]: number },
    data: any[],
    str: string,
    tab: string
  ) => void;
  defaultData: string[];
  defaultStr: string;
  skuList: {sku: string, upc: string}[];
  defaultTab: string;
};

// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onSubmit, defaultData, skuList, defaultStr, defaultTab } =
    props;
  const [selectedTab, setSelectTab] = useState<string>(defaultTab);
  const [data, setData] = useState<any[]>(defaultData);
  const [data2, setData2] = useState<any[]>([]);
  const [str, setStr] = useState(defaultStr);
  const [lastScan, setLastScan] = useState<{
    sku: string;
    time: string;
  }>();
  const [form] = Form.useForm();

  const checkStr = useCallback((str: string) => {
    const temp = str.split('\n');
    const tempArr: any[] = [];
    let arr: string[] = [];
    temp.map((i) => {
      if (i) {
        if (arr.indexOf(i) === -1) {
          arr.push(i);
          tempArr.push({
            sku: i,
            qty: 1,
          });
        } else {
          tempArr[arr.indexOf(i)] = {
            sku: i,
            qty: tempArr[arr.indexOf(i)].qty + 1,
          };
        }
        return true;
      } else {
        return true;
      }
    });
    setData2(tempArr);
    return tempArr;
  }, []);

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    let items: { [key: string]: number } = {};
    if (selectedTab === 'single') {
      data.map((i) => {
        items[i.sku] = i.qty;
        return true;
      });
    } else {
      const t = checkStr(str);
      t.map((i) => {
        const index = skuList.findIndex(j => j.sku === i.sku || j.upc === i.sku)
        if(index > -1){
          items[skuList[index].sku] = items[skuList[index].sku] ? items[skuList[index].sku] + i.qty : i.qty;
        }
        return true;
      });
    }
    onSubmit(items, data, str, selectedTab);
  }, [onSubmit, data, str, selectedTab, skuList, checkStr]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  useEffect(() => {
    if (defaultStr) {
      checkStr(defaultStr);
    }
  }, [defaultStr, checkStr]);

  const onKeyDown = (evt: any) => {
    if ([9, 13].indexOf(evt.keyCode) > -1) {
      form.setFieldsValue({
        scanValue: undefined,
      });
      evt.preventDefault();
      if (evt.target.value) {
        const idx = skuList.findIndex(i => i.sku === evt.target.value || i.upc === evt.target.value)
        if (idx > -1) {
          const index = data.findIndex((i) => i.sku === skuList[idx].sku);
          if (index > -1) {
            const temp = [...data];
            temp[index] = {
              sku: skuList[idx].sku,
              upc: skuList[idx].upc,
              qty: temp[index].qty + 1,
            };
            setData([...temp]);
          } else {
            setData([
              {
                sku: skuList[idx].sku,
                upc: skuList[idx].upc,
                qty: 1,
              },
              ...data,
            ]);
          }
          setLastScan({
            sku: evt.target.value,
            time: moment().format('hh:mm:ssA'),
          });
        } else {
          message.error(`[${evt.target.value}] not found`);
        }
      }
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      render: (text) =>
        skuList.findIndex( i => i.sku === text) > -1 ? (
          <Text>{text}</Text>
        ) : (
          <Text style={{color: '#ff4d4f'}} delete>{text}</Text>
        ),
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      key: 'upc',
      render: (text, record) =>
      skuList.findIndex( i => i.sku === record.sku) > -1 ? (
        <Text>{text}</Text>
        ) : (
          <Text style={{color: '#ff4d4f'}} delete>{text}</Text>
        ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record) =>
      skuList.findIndex( i => i.sku === record.sku) > -1 ? (
        <Text>{text}</Text>
      ) : (
        <Text style={{color: '#ff4d4f'}} delete>{text}</Text>
      ),
    },
  ];

  const columns2: ColumnsType<any> = [
    {
      title: 'SKU / UPC',
      dataIndex: 'sku',
      key: 'sku',
      render: (text) =>
        skuList.findIndex( i => i.sku === text || i.upc === text) > -1 ? (
          <Text>{text}</Text>
        ) : (
          <Text style={{color: '#ff4d4f'}} delete>{text}</Text>
        ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      key: 'qty',
      render: (text, record) =>
      skuList.findIndex( i => i.sku === record.sku || i.upc === record.sku) > -1 ? (
        <Text>{text}</Text>
      ) : (
        <Text style={{color: '#ff4d4f'}} delete>{text}</Text>
      ),
    },
  ];



  return (
    <Modal
      title="Scan to Recieve"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              icon={<CheckOutlined />}
            >
              Continue
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Form form={form}>
        <Tabs activeKey={selectedTab} onChange={setSelectTab}>
          <Tabs.TabPane tab="Single Scan" key="single">
            <Card
              title={
                <Row justify="space-between">
                  <Space>
                    <FormLabel>Scan SKU / UPC</FormLabel>
                    <Form.Item noStyle name="scanValue">
                      <Input
                        allowClear
                        style={{ width: 200 }}
                        autoFocus
                        onKeyDown={onKeyDown}
                      />
                    </Form.Item>
                  </Space>
                  {lastScan && (
                    <Row>
                      <Space>
                        <Typography.Text
                          style={{ fontSize: 12 }}
                          type="secondary"
                        >{`Last scan: ${lastScan.sku}`}</Typography.Text>
                        <Typography.Text
                          style={{ fontSize: 12 }}
                          type="secondary"
                        >{`Scanned time: ${lastScan.time}`}</Typography.Text>
                      </Space>
                    </Row>
                  )}
                </Row>
              }
            >
              <Table
                rowKey="sku"
                scroll={{ y: 240 }}
                dataSource={data}
                columns={columns}
                pagination={false}
              />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Paste Multiple" key="multiple">
            <Row>
              <Col span={12}>
              <Space direction="vertical">
                  <Input.TextArea
                    style={{ width: 350 }}
                    rows={16}
                    value={str}
                    onChange={(e) => setStr(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      checkStr(str);
                    }}
                  >
                    Check
                  </Button>
                </Space>
              </Col>
              <Col span={12}>
              <Table
                rowKey="sku"
                scroll={{ y: 322 }}
                dataSource={data2}
                columns={columns2}
                pagination={false}
              />
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
