import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import Content from 'components/Sales/Smart/Pick/SKU/PickList';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartSKUPickListScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.skuPickList" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Pick By SKU(Pick List)(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <Content />
    </ContentLayout>
  );
}
