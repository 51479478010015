import React, { useCallback, useState, useEffect } from 'react';
import { Tabs, Spin } from 'antd';
import { TabsBox } from './style';
// import { fetchInventory } from 'services/product';
import { LoadingOutlined } from '@ant-design/icons';
import { inventoryCheckout2 } from 'services/inventory';
import {
  message,
  HeaderTitle,
  GreyCardWrapper,
  SetDefaultWarehouseDialog,
} from 'components/common';
import RemoveItemsForm from './RemoveItemsForm';
import LocationItems from './LocationItems';
import HistoryItems from './HistoryItems';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getWarehouseCodeFromCache, getWarehouseIdFromCache, getProfileSettingValue } from 'utils';
// import SelectLotUomDialog from '../Add/SelectLotUom'


const { TabPane } = Tabs;

export default function SearchList() {
  const [warehouseNum] = React.useState(
    Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  );
  const [warehouseId] = React.useState(
    getWarehouseIdFromCache(
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    )
  );
  const [inventory, setInventory] = useState<any[]>([]);
  const [history, setHistory] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductRow>();
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [activeKey, setActiveKey] = useState<string>('locations');
  // const [fieldName, setFieldName] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [uomEnable, setUomEnable] = useState(false);
  const [lotEnable, setLotEnable] = useState(false);
  // const [selectLotUomDialogVisible, setSelectLotUomDialogVisible] = useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  /**
   * handle product select change event
   */
  const handleSelectProduct = useCallback(
    (fieldName: string, product?: ProductRow) => {
      // setFieldName(fieldName);
      setProduct(product);
    },
    []
  );

    /**
   * handle loation select change event
   */
    const handleSelectLocation = useCallback(
      (newLocation: WarehouseLocationRow) => {
        setLocation(newLocation);
      },
      []
    );

  const handleAddItems = useCallback(
    async (
      product: ProductRow,
      location: WarehouseLocationRow,
      transactionReason: TransactionReasonRow,
      quantity: number,
      cb: () => void
    ) => {
      if (warehouseNum) {
        setLoading(true);
        //remove items
        try {
          await inventoryCheckout2(
            warehouseId,
            product,
            location,
            transactionReason,
            quantity,
          );
          setLoading(false);
          history.push([product, location, quantity, new Date()]);
          setHistory(JSON.parse(JSON.stringify(history)));
          setActiveKey('history');
          message.success({
            content: 'Checked Out Successfully!',
          });
          setInventory((prev: any[]) => {
            const a = prev[0];
            a.quantity = Number(a.quantity) - Number(quantity);
            return [a];
          });
          cb();
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    },
    [warehouseNum, warehouseId, history]
  );

  // const getInventory = useCallback(async()=>{
  //   let filter = `sku eq '${product?.sku}' and locationCode eq '${location?.code}'`;
  //   if(lotUomInfo&&lotUomInfo.lotNumber) filter =`${filter} and lotNumber eq '${lotUomInfo.lotNumber}'`;
  //   if(lotUomInfo&&lotUomInfo.uom) filter =`${filter} and uom eq '${lotUomInfo.uom}'`
  //   fetchInventory(
  //     { id: warehouseNum, warehouseId: warehouseId },
  //     { $filter: filter }
  //   ).then((entity) => {
  //     if (entity.data.length > 0) {
  //       setInventory(entity.data);
  //     } else {
  //       setInventory([
  //         {
  //           locationCode: location?.code,
  //           locationNum: location?.id,
  //           quantity: 0,
  //           sku: product?.sku,
  //           warehouseNum: location?.warehouseId,
  //         },
  //       ]);
  //     }
  //     setLoading(false);
  //   });
  // },[product, location, lotUomInfo, warehouseId, warehouseNum])

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  // useEffect(()=>{
  //   if(product && product.sku){
  //     setSelectLotUomDialogVisible(true)
  //   }
  // },[product])

  /* eslint-disable */
  // useEffect(() => {
  //   if (warehouseNum) {
  //     if(!uomEnable&&!lotEnable){
  //       if (product && location) {
  //         getInventory()
  //       }
  //     } else {
  //       if(product && location && lotUomInfo){
  //         getInventory()
  //       }
  //     }

  //   }
  // }, [warehouseNum, product, location, ]);
  /* eslint-enable */

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Inventory',
          `Check Out  (Warehouse :  ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      />
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <GreyCardWrapper
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <RemoveItemsForm
            // lotUomInfo={lotUomInfo}
            onSelectLocation={handleSelectLocation}
            onSelectProduct={handleSelectProduct}
            onAddsItem={handleAddItems}
            onSetInventory={(inv) => setInventory(inv)}
            warehouseNum={warehouseNum}
            warehouseId={warehouseId}
            uomEnable={uomEnable}
            lotEnable={lotEnable}
          />
        </GreyCardWrapper>

        {(inventory || product) && (
          <GreyCardWrapper style={{ marginTop: 20 }}>
            <TabsBox>
              <Tabs
                type="card"
                activeKey={activeKey}
                onChange={(activeKey: string) => {
                  setActiveKey(activeKey);
                }}
              >
                <TabPane tab="Locations" key="locations">
                  <LocationItems
                    items={inventory}
                    product={product}
                    lotEnable={lotEnable}
                    uomEnable={uomEnable}
                    lotUomInfo={location}
                    // totalQuantity={inventory ? inventory.totalQuantity : 0}
                  />
                </TabPane>
                <TabPane tab="Check Out Detail" key="history">
                  <HistoryItems items={history} lotEnable={lotEnable} uomEnable={uomEnable} />
                </TabPane>
              </Tabs>
            </TabsBox>
          </GreyCardWrapper>
        )}
      </Spin>
      {/* {selectLotUomDialogVisible && product &&
        <SelectLotUomDialog
          uomEnable={uomEnable}
          lotEnable={lotEnable}
          onHide={()=>{
            setSelectLotUomDialogVisible(false)
          }}
          onOk={(data)=>{
            setLotUomInfo(data)
            setSelectLotUomDialogVisible(false)
          }}
          product={product}
        />
      } */}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
}
