import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import Add from 'components/Inventory/Add';
// eslint-disable-next-line
export default function InventoryAdd(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <Add />
      </SiteContent>
    </ContentLayout>
  );
}
