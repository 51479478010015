import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space, Typography } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { fetchPoList } from 'services/purchaseOrder';
import { RootState } from 'reducers';
import { useIsMountedRef } from 'hooks';
import {
  DataGridPlus,
  ColumnDataType,
  HeaderTitle,
  CopyComponent,
  CopySpan,
  TablesFilter,
} from 'components/common';
import Summary from './Summary';
import ConditionForm from './ConditionForm';
import { convertTimeByUtc, getWarehouseCodeFromCache } from 'utils';
import { DEFAULT_DATE_TIME_FORMAT, LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { poStatusMap, poControlStatus } from 'constants/enums'
import moment from 'moment';
import ScanPoReceive from '../ScanPoReceive'
import { isTestEnv } from 'utils'
import DetailDialog from  './PoDetailDialog'

const { Text } = Typography;
export default function PoList() {
  const defaultWarehouseCode = getWarehouseCodeFromCache(
    Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
  );
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  // const [field, setField] = useState('poNumber');
  // const [key, setKey] = useState('');
  const [poListEntity, setPoListEntity] = useState<PoListEntity>();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [status, setStatus] = useState(-1);
  const skipRef = useRef<number>(0);
  const limitRef = useRef<number>(10);
  const sortString = useRef<string>('fulfillmentPoNum desc');
  // const poStatuses = useSelector(
  //   (state: RootState) => state.admin.poStatuses,
  //   shallowEqual
  // );
  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const [tempParams, setTempParams] = useState<any>();
  const [crossWarehouseReceive, setCrossWarehouseReceive] =
    useState<string>('0');
  const [current, setCurrent] = useState<any>();
  const [receiveVisible, setReceiveVisible] = useState(false)
  const [detailVisible, setDetailVisible] = useState(false)

  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'CrossWarehouseReceive'
      );
      if (temp.length > 0) {
        setCrossWarehouseReceive(temp[0].settingValue);
      }
    }
  }, [userProfileSettings]);

  const history = useHistory();
  /**
   * handle search event
   */
  const handleSearch = useCallback(
    async (
      newField: string,
      newKey: string,
      warehouse: any,
      poStatus: Array<number>,
      waitClose: boolean
    ) => {
      // setField(newField);
      // setKey(newKey);
      setLoading(true);
      try {
        const newPoListEntity = await fetchPoList({
          warehouseCode: warehouse,
          filterFieldName: newField,
          filterKey: newKey,
          // poStatusNum: -1,
          // poStatus: poStatus > -1 ? poStatus : undefined,
          poStatus: poStatus.join(','),
          waitClose,
          sortBy: sortString.current,
        });
        if (isMountedRef.current) {
          setPoListEntity(newPoListEntity);
          setTempParams({
            warehouseCode: warehouse,
            filterFieldName: newField,
            filterKey: newKey,
            poStatus: poStatus.join(','),
            waitClose,
          });
          skipRef.current = 0;
          limitRef.current = 10;
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [isMountedRef]
  );

  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      //api
      if (!tempParams) return;
      setLoading(true);

      const newPoListEntity = await fetchPoList({
        ...tempParams,
        poStatusNum: -1,
        sortBy: sortString.current,
        skip,
        limit,
      });
      setPoListEntity(newPoListEntity);
      setLoading(false);
    },
    [setPoListEntity, setLoading, tempParams]
  );
  const getColumns = useCallback(() => {
    return [
      {
        header: 'Action',
        showColumnMenuTool: false,
        groupBy: false,
        name: 'action',
        defaultFlex: 0,
        defaultLocked: 'end',
        minWidth: isTestEnv() ? 250 : 120,
        maxWidth: isTestEnv() ? 250 : 120,
        sortable: false,
        render: (value: any) => {
          const { data } = value;
          return (
            <Space>
            <Button
              disabled={
                (crossWarehouseReceive === '0' &&
                  data.warehouseCode !== defaultWarehouseCode) || data.controlStatus === 2
              }
              onClick={() => {
                history.push(`/po/receive?PoNumber=${data.poNumber}`);
              }}
            >
              Receive
            </Button>
            {isTestEnv() ? <Button
              disabled={
                (crossWarehouseReceive === '0' &&
                  data.warehouseCode !== defaultWarehouseCode) || data.controlStatus === 2
              }
              onClick={() => {
                setCurrent(data)
                setReceiveVisible(true)
              }}
            >
              Receive Plus
            </Button>: undefined}
            </Space>

          );
        },
      },
      {
        header: 'PO#',
        name: 'poNumber',
        userSelect: true,
        defaultFlex: 0,
        render: (value: any) => {
          const { data } = value;

          return (
            <CopyComponent value={data.poNumber}>
              <Text
                ellipsis={{ tooltip: data.locationCode }}
                style={{
                  cursor: 'pointer',
                  color: '#1976d2',
                  width: `calc(100% - 14px)`,
                }}
                onClick={() => {
                  // history.push(`/po/detail?poNumber=${data.poNumber}`);
                  setCurrent(data)
                  setDetailVisible(true)
                }}
              >
                {data['poNumber']}
              </Text>
            </CopyComponent>
          );
        },
      },
      {
        header: 'Container #',
        name: 'container',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Supplier Name',
        name: 'vendorName',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Supplier #',
        name: 'vendorCode',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'PO Status',
        name: 'poStatusNum',
        userSelect: true,
        defaultFlex: 0,
        renderEnum: poStatusMap,
        render: (value: any) => {
          const { data } = value;
          return (
            <CopySpan inGrid value={poStatusMap[data.poStatusNum] || ''} />
          );
        },
      },
      {
        header: 'Control Status',
        name: 'controlStatus',
        userSelect: true,
        defaultFlex: 0,
        renderEnum: poControlStatus,
        render: (value: any) => {
          const { data } = value;
          return (
            <CopySpan inGrid value={poControlStatus[`${data.controlStatus}`] || ''} />
          );
        },
      },
      {
        header: 'Received Quantity',
        name: 'receivedQuantity',
        userSelect: true,
        defaultFlex: 0,
        render: (value: any) => {
          const { data } = value;
          const { receivedQuantity, quantity } = data;
          return (
            <CopySpan inGrid value={`${receivedQuantity} of ${quantity}`} />
          );
        },
      },
      {
        header: 'Closing',
        name: 'closingQty',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Waiting for Closing',
        name: 'waitCloseQty',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Created Date',
        name: 'createDate',
        userSelect: true,
        defaultFlex: 0,
        dataType: ColumnDataType.DATE,
        format: DEFAULT_DATE_TIME_FORMAT,
        render: (row: any) => {
          const { data } = row;
          if (data.createDate instanceof Date)
            return convertTimeByUtc(moment(data.createDate).toString(), '', '', DEFAULT_DATE_TIME_FORMAT);
        }
      },
      {
        header: 'Order Date',
        name: 'poDate',
        userSelect: true,
        defaultFlex: 0,
        dataType: ColumnDataType.DATE,
        format: DEFAULT_DATE_TIME_FORMAT,
        render: (row: any) => {
          const { data } = row;
          if (data.poDate instanceof Date)
            return convertTimeByUtc(moment(data.poDate).toString(), '', '', DEFAULT_DATE_TIME_FORMAT);
        }
      },
      {
        header: 'Requested Skip Date',
        name: 'requestShipDate',
        userSelect: true,
        defaultFlex: 0,
        dataType: ColumnDataType.DATE,
        format: DEFAULT_DATE_TIME_FORMAT,
        render: (row: any) => {
          const { data } = row;
          if (data.requestShipDate instanceof Date)
            return convertTimeByUtc(moment(data.requestShipDate).toString(), '', '', DEFAULT_DATE_TIME_FORMAT);
        }
      },
      {
        header: 'Arrival Due Date',
        name: 'arrvalDueDate',
        userSelect: true,
        defaultFlex: 0,
        dataType: ColumnDataType.DATE,
        format: DEFAULT_DATE_TIME_FORMAT,
        render: (row: any) => {
          const { data } = row;
          if (data.arrvalDueDate instanceof Date)
            return convertTimeByUtc(moment(data.arrvalDueDate).toString(), '', '', DEFAULT_DATE_TIME_FORMAT);
        }
      },
      {
        header: 'Cancel Date',
        name: 'cancelAfterDate',
        userSelect: true,
        defaultFlex: 0,
        dataType: ColumnDataType.DATE,
        format: DEFAULT_DATE_TIME_FORMAT,
        render: (row: any) => {
          const { data } = row;
          if (data.cancelAfterDate instanceof Date)
            return convertTimeByUtc(moment(data.cancelAfterDate).toString(), '', '', DEFAULT_DATE_TIME_FORMAT);
        }
      },
      {
        header: 'Received Batch',
        name: 'batchNumber',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'PO Creator',
        name: 'createBy',
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Doc ID',
        name: 'poUuid',
        userSelect: true,
        defaultFlex: 1,
      },
    ];
  }, [crossWarehouseReceive, defaultWarehouseCode, history]);

  return (
    <>
      <HeaderTitle breadcrumb={['PO', 'PO List']} />
      <ConditionForm onSearch={handleSearch} />
      {poListEntity && (
        <Summary
          poCount={poListEntity.count}
          skuCount={poListEntity.skuCount}
          totalOrdered={poListEntity.totalOrdered}
          totalReceived={poListEntity.totalReceived}
        />
      )}
      {poListEntity && (
        <div style={{ marginTop: 10 }}>
          <TablesFilter
            columns={getColumns()}
            dataSource={poListEntity.data}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            name="poList"
            showScrollButton
            autoWith={false}
            style={{ minHeight: 300 }}
            skip={skipRef.current}
            limit={limitRef.current}
            // idProperty="poNum"
            columns={getColumns()}
            count={poListEntity.count}
            dataSource={filteredData}
            onChange={(limit, skip) => {
              limitRef.current = limit;
              skipRef.current = skip;
              handleDataGridChange(limit, skip)
            }}
            onSortInfoChange={async (v: any) => {
              if (v) {
                if (v.dir === 1) {
                  sortString.current = `${v.id} asc`;
                  handleDataGridChange(limitRef.current, skipRef.current);
                }
                if (v.dir === -1) {
                  sortString.current = `${v.id} desc`;
                  handleDataGridChange(limitRef.current, skipRef.current);
                }
              } else {
                sortString.current = 'fulfillmentPoNum desc';
                handleDataGridChange(limitRef.current, skipRef.current);
              }
            }}
            pagination
            loading={loading}
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </div>
      )}
      {
        receiveVisible && current && <ScanPoReceive
          visible
          poInfo={current}
          onClose={()=> setReceiveVisible(false)}
          warehouseId={current.warehouseId}
        />
      }
      {
        detailVisible &&
        <DetailDialog 
          poInfo={current}
          visible
          onClose={()=>{
            setDetailVisible(false)
          }}
        />
      }
    </>
  );
}
