import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ZoneLocation from 'components/Admin/ZoneManagement/ZoneLocation';
// eslint-disable-next-line
export default function ZoneLocationScreeen() {
  return (
    <ContentLayout>
      <SiteContent>
        <ZoneLocation />
      </SiteContent>
    </ContentLayout>
  );
}
