import React, { useCallback, useState } from 'react';
import ScanToReceiveDialog from './ScanToReceiveDialog';
import ReceiveSummaryDialog from './ScanResultDialog';
type Props = {
  targetStrs: {sku:string, upc: string}[];
  onHide: () => void;
  onSuccess: (
    data: { sku: string; quantity: number; product: ProductRow }[]
  ) => void;
};
// eslint-disable-next-line
export default (props: Props) => {
  const [step, setStep] = useState<number>(1);
  const { onHide, onSuccess } = props;
  const [items, setItems] = useState<{ [key: string]: number }>();
  const [defaultData, setDefaultData] = useState<any[]>([]);
  const [defaultStr, setDefaultStr] = useState<string>('');
  const [defaultTab, setDefaultTab] = useState('single');
  /**
   * handle scan to move submit event
   */
  const handleScanToReceiveSubmit = useCallback(
    (
      items: { [key: string]: number },
      data: any[],
      str: string,
      tab: string
    ) => {
      setItems(items);
      setDefaultData(data);
      setDefaultStr(str);
      setStep(2);
      setDefaultTab(tab);
    },
    []
  );
  if (step === 1) {
    return (
      <ScanToReceiveDialog
        defaultTab={defaultTab}
        defaultData={defaultData}
        defaultStr={defaultStr}
        skuList={props.targetStrs}
        onSubmit={handleScanToReceiveSubmit}
        onHide={onHide}
      />
    );
  } else if (step === 2 && items) {
    return (
      <ReceiveSummaryDialog
        items={items}
        onHide={(data)=>data ? onSuccess(data) : onHide()}
        reback={() => {
          setItems(undefined);
          setStep(1);
        }}
      />
    );
  }
  return null;
};
