import React, { useState } from 'react';
import { Row, Spin, Modal, Button, Space } from 'antd';
import { message, DataGridPlus } from 'components/common';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { batchArchive } from 'services/product';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  rows: ProductRow[];
}

const columns = [
  {
    name: 'sku',
    header: 'SKU',
    minWidth: 220,
    userSelect: true,
    sortable: true,
    defaultFlex: 1,
  },
  {
    name: 'productTitle',
    header: 'Product Name',
    sortable: true,
    userSelect: true,
    defaultFlex: 1,
    minWidth: 300,
  },
  {
    name: 'upc',
    header: 'UPC',
    userSelect: true,
    sortable: true,
    defaultFlex: 1,
    minWidth: 200,
  },
];

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, rows } = props;
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    Modal.confirm({
      title: 'Are you sure?',
      okText: 'Yes',
      cancelText: 'No',
      content: `The ${rows.length} product(s) will be set to archived status`,
      onOk: async () => {
        try {
          setSubmitLoading(true);
          const res = await batchArchive(rows.map((i) => i.id));
          setSubmitLoading(false);
          if (res) {
            message.success({
              content: 'Archived successfully',
            });
            onRefresh();
            onHide();
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      },
    });
  };

  //   const handleCancel = () => {
  //     const cancel = form.isFieldsTouched();
  //     if (!cancel) {
  //       onHide();
  //     } else {
  //       Modal.confirm({
  //         title: 'Leave / Refresh Page?',
  //         okText: 'Leave',
  //         cancelText: 'Cancel',
  //         content: `Changes you made may not be saved.`,
  //         onOk() {
  //           onHide();
  //         },
  //       });
  //     }
  //   };

  let customProps = {} as any;
  //   const layout = {
  //     labelCol: { span: 12 },
  //     wrapperCol: { span: 10 },
  //   };
  return (
    <Modal
      title="Archive"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="archive_modal_confirm_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              icon={<CheckOutlined />}
            >
              {submitLoading ? 'Confirming' : 'Confirm'}
            </Button>
            <Button id="archive_modal_cancel_button" onClick={onHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <DataGridPlus
          idProperty="sku"
          columns={columns}
          dataSource={rows}
          pagination="local"
          /* eslint-disable react/jsx-props-no-spreading */
          {...({ customProps } as any)}
        />
      </Spin>
    </Modal>
  );
};
