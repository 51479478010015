import React, { useEffect, useCallback, useState } from 'react';
import { Col, Input, Row, Space, Table, Select, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION } from 'constants/config';
import {
  BlockButtonLeftSpace,
  CardWrapper,
  GreyCardWrapper,
  InfoButton,
  FormLabel,
  Loading,
  ScreenMask,
  message,
  HeaderTitle,
  CopySpan,
  DeleteButton,
} from 'components/common';
// import WarehouseSelectBox from '../Warehouse/WarehouseSelectBox';
import {
  deleteWarehouseUserAuthEntity,
  fetchAllUserAuthList,
} from 'services/admin';
import { getAllWarehouse } from 'services/warehouse';
import { sortByString } from 'utils';
import AssignModal from './assignModal';

const AssignFormFieldSpace = styled(Space)`
  width: 100%;

  &.email-field .ant-space-item:nth-child(2) {
    width: calc(100% - 62px);
  }

  &.warehouse-field .ant-space-item:nth-child(2) {
    width: calc(100% - 98px);
  }
`;

/**
 * The type defintion for the row of the authentication table.
 */
type UserAuthRow = {
  rowNum: number;
  warehouseNum: number;
  userEmail: string;
  warehouseCode: string;
  warehouseName: string;
  warehouseId: string;
};
// eslint-disable-next-line
export default () => {
  const [lockScreen, setLockScreen] = React.useState(false);
  const [sourceWarehouseList, setSourceWarehouseList] = React.useState<any[]>(
    []
  );
  const [filterEmail, setFilterEmail] = useState<string>();
  const [filterWarehouse, setFilterWarehouse] = useState<number>(0);
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [sourceList, setSourceList] = useState<UserAuthRow[]>([]);
  const [filterList, setFilterList] = useState<UserAuthRow[]>([]);

  const authColumns = [
    {
      key: 1,
      title: 'Warehouse',
      dataIndex: 'warehouseCode',
      sorter: (a: any, b: any) => sortByString(a, b, 'warehoueCode'),
    },
    {
      key: 3,
      title: 'Email',
      dataIndex: 'userEmail',
      sorter: (a: any, b: any) => sortByString(a, b, 'userEmail'),
      render: (_: number, record: UserAuthRow) => {
        return <CopySpan value={record.userEmail} />;
      },
    },
    {
      key: 4,
      title: 'Action',
      dataIndex: 'action',
      render: (_: number, record: UserAuthRow) => {
        return (
          <Space>
            <DeleteButton
              confirmContent={
                <>
                  <p>{`warehouse: ${record.warehouseCode}`}</p>
                  <p>{`Email: ${record.userEmail}`}</p>
                </>
              }
              confirmTitle="Are you sure you want to delete the row?"
              onClick={() => {
                handleDelUserAuth(record.warehouseId, record.rowNum);
              }}
            />
          </Space>
        );
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getWarehouses = useCallback(async () => {
    try {
      const res = await getAllWarehouse();
      setSourceWarehouseList(
        res.sort((a: any, b: any) => sortByString(a, b, 'warehouseCode'))
      );
    } catch (error) {}
  }, []);

  const handleDelUserAuth = async (warehouseId: string, userId: number) => {
    setLockScreen(true);
    try {
      await deleteWarehouseUserAuthEntity(warehouseId || '', userId);
      setLockScreen(false);
      setSourceList(sourceList.filter((e) => e.rowNum !== userId));
      message.success({
        content: 'Deleted successfully',
        duration: USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION,
      });
    } catch (e) {
      setLockScreen(false);
    }
  };

  const getSourceData = useCallback(async () => {
    try {
      setLockScreen(true);
      const res = await fetchAllUserAuthList();
      setLockScreen(false);
      setSourceList(
        res.sort((a: any, b: any) => sortByString(a, b, 'warehouseCode'))
      );
    } catch (error) {
      setLockScreen(false);
    }
  }, []);

  useEffect(() => {
    getWarehouses();
  }, [getWarehouses]);
  useEffect(() => {
    getSourceData();
  }, [getSourceData]);

  useEffect(() => {
    if (!filterEmail && !filterWarehouse) {
      setFilterList([...sourceList]);
    } else {
      let temp = [...sourceList];
      if (filterWarehouse) {
        temp = temp.filter((i) => i.warehouseNum === filterWarehouse);
      }
      if (filterEmail) {
        temp = temp.filter((i) => i.userEmail.indexOf(filterEmail) === 0);
      }
      setFilterList(temp);
    }
  }, [sourceList, filterEmail, filterWarehouse]);

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Warehouse Assignments']} />
      <GreyCardWrapper>
        <CardWrapper>
          <Row justify="space-between">
            <Col span={20}>
              <Row>
                <Col span={9}>
                  <AssignFormFieldSpace className="warehouse-field">
                    <FormLabel>Warehouse</FormLabel>
                    <Select
                      value={filterWarehouse}
                      style={{ width: '100%' }}
                      onChange={setFilterWarehouse}
                    >
                      <Select.Option key={0} value={0}>
                        All Warehosue
                      </Select.Option>
                      {sourceWarehouseList.map((i) => (
                        <Select.Option
                          key={i.warehouseNum}
                          value={i.warehouseNum}
                        >
                          {i.warehouseCode}
                        </Select.Option>
                      ))}
                    </Select>
                  </AssignFormFieldSpace>
                </Col>
                <Col span={9}>
                  <AssignFormFieldSpace className="email-field">
                    <FormLabel>Email</FormLabel>
                    <Input
                      onChange={(e) => setFilterEmail(e.target.value)}
                      allowClear
                    />
                  </AssignFormFieldSpace>
                </Col>
              </Row>
            </Col>
            <Col>
              <BlockButtonLeftSpace>
                <InfoButton
                  type="primary"
                  block
                  onClick={() => setAssignModalVisible(true)}
                >
                  <PlusOutlined />
                  Assign to User
                </InfoButton>
              </BlockButtonLeftSpace>
            </Col>
          </Row>
        </CardWrapper>
      </GreyCardWrapper>
      <Card style={{ marginTop: 10 }}>
        <Row>
          <Table
            rowKey="rowNum"
            style={{ height: '100%', width: '100%' }}
            columns={authColumns}
            dataSource={filterList}
          />
        </Row>
      </Card>
      {assignModalVisible && (
        <AssignModal
          visible
          onHide={() => setAssignModalVisible(false)}
          onRefresh={() => {
            setAssignModalVisible(false);
            getSourceData();
          }}
          warehouseList={sourceWarehouseList}
        />
      )}

      {lockScreen ? (
        <ScreenMask>
          <Loading size={48} />
        </ScreenMask>
      ) : (
        ''
      )}
    </>
  );
};
