import { warehouseList } from 'actions/adminActions';
import { fetchWarehouseListPlus } from 'services/warehouse';
import store from 'store';

const cacheWarehouseList = async () => {
  const data = await fetchWarehouseListPlus();

  store.dispatch(warehouseList(data));

  return data;
};

export {
  cacheWarehouseList,
};
