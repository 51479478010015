import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Spin,
  Button,
  DatePicker,
  Card,
  Row,
  Space,
  Typography,
  Radio,
  message,
  Checkbox,
} from 'antd';
import { FormLabel } from 'components/common';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import { fetchLOTSettings } from 'services/lotSetting';
import { createLotNumber } from 'services/lot'

interface CreateProps extends ModalProps {
  onClose: Function;
  onSave: Function;
  rows: any[];
  noUniqueControl?: boolean;
  timeZone: string;
  generate: boolean;
  lotNumberGenerationType?: number;
}

export default function Dialog(props: CreateProps) {
  const { rows, timeZone, onSave } = props;
  const [generate, setGenerate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noUniqueControl, setNoUniqueControl] = useState(false);
  const [lotNumberGenerationType, setLotNumberGenerationType] = useState(0);
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [unique, setUnique] = useState<0 | 1>(0);
  const [form] = Form.useForm();

  const getLOTSettings = useCallback(async () => {
    try {
      const res = await fetchLOTSettings();
      if (res.isSuccess) {
        setLotNumberGenerationType(res.data.generation);
        setNoUniqueControl(!!res.data.unique);
        if(res.data.generation === 1){
          setGenerate(true)
        }
      }
    } catch (error) { }
  }, []);

  useEffect(()=>{
    getLOTSettings()
  },[getLOTSettings])

  const closeDetailDialog = () => {
    props.onClose();
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const handleSave = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    console.log(params);
    try {
      setLoading(true);
      //todo generate request
      const res = await createLotNumber({
        TimeZone: timeZone,
        Generate: generate,
        Unique: !!unique,
        Items: rows.map((i) => {
          return {
            SKU: i.sku,
            PolineNum: i.poLineNum,
            LotNumber: params.LotNumber,
            FirstInDate: params.FirstInDate,
            ExpirationDate: params.ExpirationDate,
            ManufactionDate: params.ManufactionDate,
            SupplierLot: params.SupplierLot,
            Note: params.Note,
          };
        }),
      });
      setLoading(false);
      if (res.isSuccess) {
        message.success({ content: 'Save successfully' });
        const t: { [key: string]: any } = {};
        res.data.map((i: any, index: number) => {
          t[i.poLineNum || rows[index].poLineNum] = i.lotNumber;
          return true;
        });
        onSave(t);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={[
          <Space>
            <Button onClick={() => closeDetailDialog()}>Cancel</Button>
            <Button type="primary" onClick={()=>handleSave()}>
              {loading ? 'Saving' : 'Save'}
            </Button>
          </Space>,
        ]}
        fullscreen={true}
        maskClosable={false}
        onClose={closeDetailDialog}
        onFullscreen={onFullscreen}
        title={generate ? 'Generate LOT#' : 'Manually Create New LOT#'}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <Card style={{ height: '100%' }}>
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          >
            <Form
              {...layout}
              form={form}
              style={{ marginTop: 20 }}
              onFinish={handleSave}
            >
              <Row justify="center" style={{ marginBottom: 10 }}>
                {rows.length > 1 && !noUniqueControl && (
                  <Typography.Paragraph>
                    All lines will have the same LOT#.
                  </Typography.Paragraph>
                )}
                {rows.length > 1 && noUniqueControl && (
                  <Radio.Group
                    value={unique}
                    onChange={(e) => setUnique(e.target.value)}
                  >
                    <Radio value={1}>Each line has a unique LOT#</Radio>
                    <Radio value={0}>All Line have the same LOT#</Radio>
                  </Radio.Group>
                )}
              </Row>

              <Form.Item label={<FormLabel>LOT#</FormLabel>}>
                <Space>
                  {!generate && (
                    <Form.Item
                      noStyle
                      name="LotNumber"
                      initialValue={rows.length === 1 ? rows[0].lotNumber : undefined}
                      rules={[
                        { required: true, message: 'Please input LOT#!' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {lotNumberGenerationType !== 2 && (
                    <Checkbox
                      checked={generate}
                      onChange={(e) => {
                        setGenerate(e.target.checked);
                        form.setFieldsValue({ LotNumber: undefined });
                      }}
                    >
                      Automatically generate
                    </Checkbox>
                  )}
                </Space>
              </Form.Item>

              <Form.Item
                name="FirstInDate"
                label={<FormLabel>First In Date</FormLabel>}
                initialValue={moment()}
                rules={[
                  { required: true, message: 'Please input First In Date!' },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="ExpirationDate"
                label={<FormLabel>Expiration Date</FormLabel>}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="ManufactionDate"
                label={<FormLabel>Manufacuring Date</FormLabel>}
                extra="You can set the default manufacturing date to the current date upon receipt and override it to the date of manufacture at the supplier, if applicable."
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="SupplierLot"
                label={<FormLabel>Supplier LOT#</FormLabel>}
              >
                <Input />
              </Form.Item>

              <Form.Item name="Note" label={<FormLabel>Note</FormLabel>}>
                <Input.TextArea rows={2} />
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </ModalDialog>
    </>
  );
}
