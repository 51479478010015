import React, { useState } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select } from 'antd';
// import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import { CloseOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { SelectWarehouse } from 'components/common';
import { CopyChannelServiceToChannelAccountService } from 'services/storeSetup';
import { getShipAccountByWarehouse } from 'services/ship';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  store?: StoreChannelInfo;
  shipList: any[];
  carriers: any[];
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const {
    visible,
    onHide,
    onRefresh,
    store,
    carriers,
    // shipList,
  } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [shipAccountList, setShipAccountList] = useState<ShipAccountRow[]>([]);
  const [carrierList, setCarrierList] = useState<any[]>([]);
  //   const [selectedChannel, setSelectedChannel] = useState<ShipAccountRow>();
  const [form] = Form.useForm();

  const fetchShipAccountByWarehouse = async (id: number) => {
    try {
      setLoading(true);
      const res = await getShipAccountByWarehouse(id);
      setLoading(false);
      if (res.isSuccess) {
        setShipAccountList(res.data);
        setCarrierList([
          ...new Set(res.data.map((item: ShipAccountRow) => item.carrierNum)),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields || !store) return;
    try {
      setSubmitLoading(true);
      const res = await CopyChannelServiceToChannelAccountService(
        store.channelAccountNum,
        params.shipFrom.warehouseNum,
        params.shippingAccount
      );
      setSubmitLoading(false);
      if (res.isSuccess) {
        onRefresh();
        onHide();
      }
    } catch (error) {
      // message.error({ content: error });
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  /* eslint-disable */
  //   useEffect(() => {

  //   }, []);
  /* eslint-enable */
  return (
    <Modal
      title={`Copy Channel Service for ${store?.channelAccountName}`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              icon={loading ? <LoadingOutlined /> : <SaveOutlined />}
            >
              {submitLoading
                ? 'Coping Channel Service'
                : 'Copy Channel Service'}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="shipFrom"
            //   initialValue={service ? { id: service.warehouseNum } : undefined}
            label={<FormLabel>Warehouse </FormLabel>}
            rules={[{ required: true, message: 'please select warehouse' }]}
          >
            <SelectWarehouse
              width={322}
              onChange={(warehouse) => {
                form.setFieldsValue({
                  shippingAccount: undefined,
                });
                // setSelectedChannel(undefined);
                fetchShipAccountByWarehouse(warehouse.id);
              }}
              placeholder="please select warehouse"
            />
          </Form.Item>

          <Form.Item
            name="shippingAccount"
            // initialValue={channelShip ? channelShip.shipAccountNum : undefined}
            label={<FormLabel>Shipping Account</FormLabel>}
            rules={[
              { required: true, message: 'please select a shipping account' },
            ]}
          >
            <Select disabled={!form.getFieldValue('shipFrom')}>
              {carrierList.map((item) => (
                <Select.OptGroup
                  label={carriers.filter((i) => i.carrierNum === item)[0].name}
                  key={item}
                >
                  {shipAccountList
                    .filter((i) => i.carrierNum === item)
                    .map((child) => (
                      <Select.Option
                        key={child.shipAccountNum}
                        value={child.shipAccountNum}
                      >
                        {child.name}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
