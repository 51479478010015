import React, { useCallback } from 'react';
import { Modal, Typography } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { playAudio } from 'utils';
import { message } from 'components/common';
import {
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartBlock,
  SmartButton,
} from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
type SKUFinishDialogProps = {
  order: SmartPickOrder;
  product: SmartPickOrderItem;
  onSubmit: (nextProduct: SmartPickOrderItem) => void;
  onSkipOrder: () => void;
  uomEnable: boolean;
};
export default function SKUFinishDialog(
  props: SKUFinishDialogProps
): JSX.Element {
  const { order, product, onSubmit, onSkipOrder, uomEnable } = props;
  const { t } = useTranslation();
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      //Check whether the sku is in the order range
      const { items } = order;
      const orderSKU = items.filter(
        (item) => `${item.sku}${uomEnable ? '_' : ''}${uomEnable? item.uom : ''}` === nextValue || `${item.upc}${uomEnable ? '_' : ''}${uomEnable? item.uom : ''}` === nextValue );
      // Cannot find sku in this order
      if (orderSKU.length < 1) {
        playAudio('error');
        message.warning(`'${nextValue}' invalid!`);
        return;
      }
      const tempOrderSKU = orderSKU.filter((i) => i.pickQty > i.pickedQty)
      if(tempOrderSKU.length > 0){
        onSubmit(tempOrderSKU[0]);
      } else {
        playAudio('error');
        message.warning(
          `'${nextValue}' has done,Please scan another SKU / UPC!`
        );
        return;
      }
    },
    [onSubmit, order, uomEnable]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    const { sku } = product;
    return (
      <SmartRow justify="center">
        <LikeOutlined />
        <div style={{ marginLeft: 5 }}>{`${sku} was done!`}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>
        <Text style={{ fontSize: 16 }}>
          {t("smart.pick.scanSkuUpcContinue")}
        </Text>
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }}>
        <SmartScanner onChangeValue={handleScannerChange} />
      </SmartRow>
      {/* <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            handleScannerChange('');
          }}
        >
          Next SKU / UPC
        </SmartButton>
      </SmartRow> */}
      <div style={{ marginTop: 10 }}>
        <SmartBackButton>
          <SmartBlock>
            <SmartButton onClick={() => onSkipOrder()}>
              {t("smart.pick.skipOrder")}
            </SmartButton>
          </SmartBlock>
        </SmartBackButton>
      </div>
    </Modal>
  );
}
