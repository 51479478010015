import React, { useState } from 'react';
import { Row, Modal, Button, Space, Checkbox, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { exportChannelAccount } from 'services/files';
import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';

interface EditDetailProps {
  visible: boolean;
  channelAccounts: {
    channelAccountNum: number;
    channelAccountName: string;
  }[];
  onHide: () => void;
  onRefresh: () => void;
}
const CheckboxGroup = Checkbox.Group;
const plainOptions = [
  'StoreSettings',
  'PackingSlip',
  'FromReturnAddress',
  'ShippingAccount',
  'ShippingServiceMapping',
  'OtherSetting',
  'InsertItem',
];
const options = [
  {
    label: 'Store Settings',
    value: 'StoreSettings',
  },
  {
    label: 'Packing Slip',
    value: 'PackingSlip',
  },
  {
    label: 'From Return Address',
    value: 'FromReturnAddress',
  },
  {
    label: 'Shipping Account',
    value: 'ShippingAccount',
  },
  {
    label: 'Shipping Service Mapping',
    value: 'ShippingServiceMapping',
  },
  {
    label: 'Other Setting',
    value: 'OtherSetting',
  },
  {
    label: 'Insert Item',
    value: 'InsertItem',
  },
];
const temp = {
  ChannelAccount: {
    channelName: 'Name',
    channelAccountName: 'Channel',
    category: 'Channel Type',
    updateDate: 'Last Modified',
    status: 'Status',
  },
  StoreSettings: {
    channelName: 'Channel',
    centralChannelAccountNum: 'CCA#',
    channelAccountNum: 'Store #',
    channelAccountName: 'Store Name',
    status: 'Status',
    companyName: 'Company Name',
    phone: 'Phone',
    email: 'Email',
    isHandleLabelSeparately: 'Shipping label is handled separately',
  },
  PackingSlip: {
    salesDivision: 'Sales Division',
    packingSlipTemplateName: 'Packing Slip Template',
    logo: 'Logo',
    isPrintPackingSlip: 'Print Packing Slip',
  },
  FromReturnAddress: {
    warehouseCode: 'Warehouse',
    returnAddressName: 'Return Address',
    fromAddressName: 'Ship From',
  },
  ShippingAccount: {
    warehouseCode: 'Warehouse',
    shipAccountName: 'Ship Account',
    isDefault: 'IsDefault',
  },
  ShippingServiceMapping: {
    warehouseCode: 'Warehouse',
    name: 'Name',
    shipAccountName: 'Ship Account',
    serviceName: 'Service',
    package: 'Package',
    saturdayDelivery: 'Saturday Delivery',
    billType: 'Bill Type',
  },
  OtherSetting: {
    isAllowOrderImport: 'Allow Manual Order Import',
    importFileFormat: 'Order import format',
    isHandleLabelSeparately: 'Allow user handle shipping label separately',
    onlyMappedServiceAndPackage:
      'Only allow mapped shipping service and package type',
    notUseProductSignature: 'Not Use Product Signature Option',
    allowSplitOrder: 'Allow Split Order',
    isRequireInsertItem: 'Require Insert Item(s)',
  },
  InsertItem: {
    warehouseCode: 'Warehouse',
    insertItemName: 'Insert Item Name',
    insertItemPictureLink: 'Picture',
    insertItemNote: 'Note',
    isRemindUser: 'Need Remind User',
  },
};

const CreateChannelAccountDialog = (props: EditDetailProps) => {
  const { visible, onHide, onRefresh, channelAccounts } = props;
  const [saveLoading, setSaveLoading] = useState(false);
  const [checkedList, setCheckedList] = React.useState<any[]>([]);
  const [indeterminate, setIndeterminate] = React.useState<boolean>(false);
  const [checkAll, setCheckAll] = React.useState<boolean>(false);

  const history = useHistory();

  const onChange = (list: any) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleSave = async () => {
    let SelectField: { [key: string]: any } = { ...temp };
    console.log(SelectField['ChannelAccount']);
    plainOptions.map((item) => {
      if (!checkedList.includes(item)) {
        SelectField[`${item}`] = undefined;
      }
      return true;
    });
    const params = {
      Operation: 6,
      FilterCondition: {
        channelAccount: channelAccounts,
      },
      SelectField,
      TabNames: checkedList,
    };
    try {
      setSaveLoading(true);
      const res = await exportChannelAccount(params);
      setSaveLoading(false);
      if (res) {
        Modal.confirm({
          title: 'job has been generated',
          okText: 'Close',
          cancelText: 'Check now',
          content:
            'job in progress ,you can check the job status in the export menu on the left side later.',
          onOk() { },
          onCancel() {
            history.push('/export');
          },
        });
      }
      onRefresh();
      onHide();
    } catch (error) {
      setSaveLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal
      title={`Select export tabs`}
      centered
      visible={visible}
      maskClosable={false}
      width={700}
      destroyOnClose={true}
      onCancel={() => onHide()}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSave}
              loading={saveLoading}
              icon={<CloudDownloadOutlined />}
            >
              Export
            </Button>
            <Button onClick={() => onHide()} icon={<CloseOutlined />}>Close</Button>
          </Space>
        </Row>
      }
    >
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Check all
      </Checkbox>
      <Divider />
      <CheckboxGroup
        style={{ lineHeight: 2 }}
        options={options}
        value={checkedList}
        onChange={onChange}
      />
    </Modal>
  );
};

export default CreateChannelAccountDialog;
