import React, { useState, useEffect, useCallback } from 'react';
import { Row, Modal, Button, Space, Select, Radio } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { getPickList } from 'services/printPickList';
import { convertTimeByUtc } from 'utils';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onSave: (value?: pickListRow) => void;
  priorityPicklistNum?: number;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { t: trans } = useTranslation();
  const { visible, onHide, onSave } = props;
  const [pickList, setPickList] = useState<any[]>([]);
  const [selectedPick, setSelectedPick] = useState<any>();
  //const [value, setValue] = useState<number>(0);
  const [value, setValue] = useState<number>(props.priorityPicklistNum ? 2 : 1);

  const getPicks = useCallback(async () => {
    try {
      const res = await getPickList(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      );
      if (res.isSuccess) {
        setPickList(res.data);
      }

      if (props.priorityPicklistNum) {
        const founds = res.data.filter((i: StringKAnyVPair) => i.pickListNum === props.priorityPicklistNum);

        setSelectedPick(founds[0]);
      }
    } catch (err) { }
  }, [props]);

  /* eslint-disable */
  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      getPicks();
    }
  }, [getPicks]);
  /* eslint-enable */
  return (
    <Modal
      title={trans('scanToShip.priorityPickList.title')}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={() => onHide()}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={value === 2 && !selectedPick}
              onClick={() => {
                if (value === 2 && selectedPick) {
                  onSave(selectedPick);
                } else {
                  onSave();
                }
              }}
              icon={<SaveOutlined />}
            >
              {trans('common.save')}
            </Button>
            <Button onClick={() => onHide()} icon={<CloseOutlined />}>{trans('common.cancel')}</Button>
          </Space>
        </Row>
      }
    >
      <Radio.Group onChange={(e) => setValue(e.target.value)} defaultValue={value}>
        <Space direction="vertical">
          <Radio value={1}>{trans('scanToShip.priorityPickList.noPriority')}</Radio>
          <Radio value={2}>{trans('scanToShip.priorityPickList.setThis')}</Radio>
        </Space>
      </Radio.Group>

      {value === 2 && (
        <Select
          defaultValue={props.priorityPicklistNum}
          style={{ width: 400, marginTop: 8 }}
          placeholder={trans('scanToShip.priorityPickList.pickListPlaceholder')}
          dropdownMatchSelectWidth={false}
          onChange={(value) => {
            setSelectedPick(pickList.filter((i) => i.pickListNum === value)[0]);
          }}
        >
          {Array.isArray(pickList)
            ? pickList
              .filter((i: any) => i.pickListNum !== 0)
              .map((item, index) => {
                return (
                  <Select.Option key={index} value={item.pickListNum}>
                    {`#${item.pickListNum} by ${item.createBy
                      } on ${convertTimeByUtc(item.createDate)}`}
                  </Select.Option>
                );
              })
            : ''}
        </Select>
      )}
      {selectedPick && value === 2 && (
        <Space direction="vertical" style={{ padding: 8 }}>
          <Space>
            <span>{trans('scanToShip.priorityPickList.label.totalOrders')}: {selectedPick.ordersCount}</span>
            <span>{trans('scanToShip.priorityPickList.label.totalProducts')}: {selectedPick.skusCount}</span>
            <span>{trans('scanToShip.priorityPickList.label.printedOrders')}: {selectedPick.printedCount}</span>
            <span>{trans('scanToShip.priorityPickList.label.shippedOrders')}: {selectedPick.shippedCount}</span>
          </Space>
          <Space>
            <span>
            {trans('scanToShip.priorityPickList.label.partiallyShippedOrders')}: {selectedPick.partialShippedCount}
            </span>
            <span>{trans('scanToShip.priorityPickList.label.pendingOrders')}: {selectedPick.pendingCount}</span>
            <span>{trans('scanToShip.priorityPickList.label.canceledOrders')}: {selectedPick.cancelCount}</span>
          </Space>
        </Space>
      )}
    </Modal>
  );
};
