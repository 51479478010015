import store from '.';

export const selectAuthentiction = () => {
  const { regular } = store.getState();

  return {
    authed: regular.authed,
    userIsExpired: regular.userIsExpired,
    userIsValid: regular.userIsValid,
  };
}
