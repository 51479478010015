/**
 * printer service requests are here.
 */
import { digitBridgeApi, digitBridgePrinterApi } from './http';

/**
 * fetchPrinterList
 */
export const fetchPrinterList = async (): Promise<any> => {
  return await digitBridgeApi.post('/api/Printers');
};
/**
 * fetchPrintTemplates
 */
interface PrintTemplateParams {
  TemplateType: number;
  ChannalNum?: number;
  ChannalAccountNum?: number;
  MasterAccountNum: number;
  ProfileNum: number;
}
export const fetchPrintTemplates = async (
  params: PrintTemplateParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/PrintTemplates', json);
};

interface PrintTaskParams {
  SKU: string;
  WarehouseCode: string;
  LocationCode: string;
  PONumber: string;
  User: string;
  Quantity: number;
  PrinterNum: number;
  PrinterAccountNum: number;
  ReportTemplateNum: number;
  MasterAccountNum: number;
  ProfileNum: number;
}
export const printTask = async (params: PrintTaskParams): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post('/api/PrintTemplates', json);
};

/**
 * print label pdf preiew
 */
interface PrintPreviewParams {
  Data: PrintData[];
  User: string;
  Qty: number;
  TemplateType: number;
  PrinterNum: number;
  PrinterAccountNum: number;
  PrintTemplateNum: number;
  MasterAccountNum: number;
  ProfileNum: number;
}

export const PrintLabelExport = async (
  params: PrintPreviewParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/PrintLabelExport', json, {
    responseType: 'blob',
  });
};

/**
 * print label pdf preview
 * @param params
 * @returns
 */
export const PrintLabelPreview = async (
  params: PrintPreviewParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/PrintLabelPreview', json);
};


export const postPrintLabel = async (
  params: PrintPreviewParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/PrintLabel', json);
};

type PostPrintReceivesParams = {
  SKU: string,
  WarehouseCode: string,
  LocationCode: string,
  PONumber: string,
  User: string,
  Quantity: number,
  PrinterNum: number,
  PrinterAccountNum: number,
  PrintTemplateNum: number,
  MasterAccountNum: number,
  ProfileNum: number,
  TemplateType: number,
  Qty: number,
}

export const postPrintReceives = async (
  params: PostPrintReceivesParams
): Promise<any> => {
  const json = JSON.stringify(params);
  return await digitBridgePrinterApi.post('/api/PrintReceives', json);
};