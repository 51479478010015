import React from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import Receive from '../ReturnReceive/index';
import { Row } from 'antd';

interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  onShowDetail: Function;
  onShowComplete: Function;
}

const ProductDetailDialog = (props: Props) => {

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
      </Row>
    );
  };


  return (
    <>
      <ModalDialog
        centered
        fullscreen={true}
        title="Receive"
        maskClosable={false}
        closeButton={true}
        closable={false}
        visible={true}
        bodyHeight={630}
        onClose={closeProductDetailDialog}
        destroyOnClose={true}
        getContainer={false}
        footer={<Footer />}
      >
        <Receive isModal modalReturn={props.modalReturn} showComplete={(data:any) =>props.onShowComplete(data)} showDetail={(data:any) =>props.onShowDetail(data)} />
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
