import { SaveOutlined } from "@ant-design/icons";
import { Card, Form, Input, InputNumber, Spin, Switch, Typography, message } from "antd";
import { Button, CardWrapper, FormLabel, GreyCardWrapper, HeaderTitle } from "components/common";
import React, { useEffect, useState } from "react";
import { fetchPrepackSettings, updatePrepackSettings, updatePrepackSettingsParams } from "services/prepack";

const PrepackSetting = () => {
  const [spinning, setSpinning] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(true);
  const [enableCSN, setEnableCSN] = useState<boolean>(false);
  const [templateParams, setTemplateParams] = useState<any>();
  const [csnFormat, setCsnFormat] = useState<any>({ csnTotalLength: 0, csnNumber: 0, csnPrefix: '', csnSuffix: '' });
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const getPrepackSetting = async () => {
    setSpinning(true);
    setSaveLoading(true);
    try {
      const { data, isSuccess, message: msg } = await fetchPrepackSettings();
      if (isSuccess) {
        const template = {
          ...data,
          enableCSN: data.enableCSN === 1 ? true : false,
          packCSNRequired: data.packCSNRequired === 1 ? true : false
        }
        form.setFieldsValue(template);
        setCsnFormat(template);
        setTemplateParams(template);
        setEnableCSN(data.enableCSN === 1 ? true : false);
      } else {
        message.error(msg);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
      setSaveLoading(false);
    }
  }

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      let queryParams = {};
      if (enableCSN) {
        queryParams = {
          ...templateParams,
          ...params,
          enableCSN: params.enableCSN ? 1 : 0,
          packCSNRequired: params.packCSNRequired ? 1 : 0
        }
      } else {
        queryParams = {
          ...templateParams,
          packOverPercentage: params.packOverPercentage,
          unpackOverPercentage: params.unpackOverPercentage,
          enableCSN: params.enableCSN ? 1 : 0,
          packCSNRequired: templateParams.packCSNRequired ? 1 : 0
        }
      }
      const { isSuccess, message: msg } = await updatePrepackSettings(queryParams as updatePrepackSettingsParams);
      if (isSuccess) {
        message.success('Update prepack setting successfully!');
        setTimeout(() => {
          getPrepackSetting();
        }, 1000);
      } else {
        message.error(msg);
      }
    } catch (error) {

    } finally {
      setSaveLoading(false);
    }
  }

  const handleChangeCsnFormat = (key: string, value: any) => {
    const obj = { ...csnFormat };
    if (key === 'csnTotalLength') {
      obj.csnPrefix = '';
      obj.csnSuffix = '';
      obj.csnNumber = '';
      form.setFieldsValue({
        csnPrefix: '',
        csnSuffix: '',
        csnNumber: '',
      })
    }
    obj[key] = value;
    setCsnFormat(obj);
  }

  useEffect(() => {
    getPrepackSetting();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Prepack']}>
        <Button
          type="primary"
          loading={saveLoading}
          onClick={() => handleSave()}
          icon={<SaveOutlined />}
        >
          Save Settings
        </Button>
      </HeaderTitle>
      <GreyCardWrapper>
        <CardWrapper>
          <Spin spinning={spinning} >
            <Form form={form} {...layout}>
              <Form.Item name="packOverPercentage" label={<FormLabel>Over Pack Percentage</FormLabel>}>
                <InputNumber
                  step={0.01}
                  min={0}
                  max={100}
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item name="unpackOverPercentage" label={<FormLabel>Over Unpack Percentage</FormLabel>}>
                <InputNumber
                  step={0.01}
                  min={0}
                  max={100}
                  addonAfter="%"
                />
              </Form.Item>
              <Form.Item name="enableCSN" valuePropName="checked" label={<FormLabel>Enable Case Serial Number(CS#)</FormLabel>}>
                <Switch
                  checked={enableCSN}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={e => setEnableCSN(e)}
                />
              </Form.Item>
              {enableCSN && (
                <Card >
                  <Typography.Title level={5}>Case Serial Number Format</Typography.Title>
                  <Form.Item name="csnTotalLength" label={<FormLabel>Total Length</FormLabel>}>
                    <InputNumber
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnTotalLength', e)}
                    />
                  </Form.Item>
                  <Form.Item name="csnPrefix" label={<FormLabel>Prefix</FormLabel>}>
                    <Input
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnPrefix', e.target.value)}
                      maxLength={csnFormat.csnTotalLength - `${csnFormat.csnNumber}`.length - csnFormat.csnSuffix.length}
                    />
                  </Form.Item>
                  <Form.Item name="csnNumber" label={<FormLabel>Starting Number</FormLabel>}>
                    <InputNumber
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnNumber', e)}
                      maxLength={csnFormat.csnTotalLength - csnFormat.csnPrefix.length - csnFormat.csnSuffix.length}
                    />
                  </Form.Item>
                  <Form.Item name="csnSuffix" label={<FormLabel>Suffix</FormLabel>}>
                    <Input
                      style={{ width: 250 }}
                      onChange={e => handleChangeCsnFormat('csnSuffix', e.target.value)}
                      maxLength={csnFormat.csnTotalLength - `${csnFormat.csnNumber}`.length - csnFormat.csnPrefix.length}
                    />
                  </Form.Item>
                </Card>
              )}
              {enableCSN && (
                <Card style={{ marginTop: 8 }} >
                  <Typography.Title level={5}>Case Serial Number Enforcement</Typography.Title>
                  <Form.Item valuePropName="checked" name="packCSNRequired" label={<FormLabel>All Pre-Pack Must have CS#</FormLabel>}>
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                </Card>
              )}
            </Form>
          </Spin>
        </CardWrapper>
      </GreyCardWrapper>
    </>
  )
}

export default PrepackSetting;