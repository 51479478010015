import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  EditOutlined,
  ClearOutlined,
  LeftOutlined,
  StopOutlined,
  RightOutlined,
  CloseOutlined,
  SaveOutlined,
  DiffOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  InputInteger,
  message,
  Locker,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  Space,
  Tooltip,
  Button,
  Input,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Tabs,
  Typography,
  Spin,
  DatePicker,
} from 'antd';
import debounce from 'lodash.debounce';
import {
  getWarehouseIdFromCache,
  convertTimeByUtc,
  getWarehouseCodeFromCache,
} from 'utils';
import EditQtyDialog from './EditDialog';
import { playAudio } from 'utils';
import {
  COMMON_PADDING_SPACE,
  LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import theme from 'assets/styles/theme';
import {
  fetchPoList,
  fetchPoLineList,
  // getPoNumberByKeyword,
  receive,
} from 'services/purchaseOrder';
import SearchSelect from 'components/Inventory/MoveMerge/SearchSelect';
import moment from 'moment';
import { poStatusMap } from 'constants/enums';

type TempLineType = {
  fulfillmentPoLineNum: number;
  sku: string;
  upc: string;
  productTitle: string;
  receivingLocationNum: number;
  receivingLocationCode: string;
  receiveQty: number;
};

interface Props extends ModalProps {
  onClose: Function;
  poInfo: any;
  warehouseId: string;
}

const ProductDetailDialog = (props: Props) => {
  const { poInfo } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  // const [loading, setLoading] = useState(false);
  // const [querying, setQuerying] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  const [qtyLocker, setQtyLocker] = useState(true);
  const [locationLocker, setLocationLocker] = useState(false);
  const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  // const [currentProduct, setCurrentProduct] = useState<any>();
  const [filterValue, setFilterValue] = useState<string>();
  const [tempLines, setTempLines] = useState<TempLineType[]>([]); //
  const [tempFilterLines, setTempFilterLines] = useState<TempLineType[]>([]);
  const [historyLines, setHistoryLines] = useState<any[]>([]);
  const [filterHistoryLines, setFilterHistoryLines] = useState<any[]>([]);
  const [lines, setLines] = useState<any>([]);
  const [filterlines, setFilterlines] = useState<any>([]);
  const [initing, setIniting] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showHistory, setShowHistory] = useState(false);
  const [info, setInfo] = useState<any>();
  const [currentLocationCode, setCurrentLocationCode] = useState<string>();
  const [receiptDate, setReceiptDate] = useState<moment.Moment>(moment());
  const poNumberSearchRef = useRef('');

  const [form] = Form.useForm();

  const closeProductDetailDialog = () => {
    if (tempLines.length > 0) {
      Modal.confirm({
        title: 'Do you want to leave this page？',
        okText: 'No',
        cancelText: 'Yes',
        cancelButtonProps: { className: 'hover-danger-button' },
        content: `Changes you made may not be saved.`,
        onCancel() {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const clearForm = useCallback(() => {
    form.setFieldsValue({ sku: undefined });
    // setCurrentProduct(undefined);
    if (!qtyLocker) {
      form.setFieldsValue({ qty: 1 });
    }
    if (!locationLocker) {
      form.setFieldsValue({ location: undefined });
      setCurrentLocationCode('');
    }
  }, [form, qtyLocker, locationLocker]);

  const delReceivingLine = useCallback(
    (l: any) => {
      const index = tempLines.findIndex(
        (i: any) =>
          i.fulfillmentPoLineNum === l.fulfillmentPoLineNum &&
          i.receiveLocationNum === l.receiveLocationNum
      );
      if (index > -1) {
        const idx = lines.findIndex(
          (i: any) => i.fulfillmentPoLineNum === l.fulfillmentPoLineNum
        );
        const tLine = [...lines];
        tLine[index].receivingQty =
          tLine[idx].receivingQty - tempLines[index].receiveQty;
        const temp = [...tempLines];
        temp.splice(index, 1);
        setTempLines([...temp]);
        setLines([...tLine]);
        setDeleteDialogVisible(false);
        message.success({ content: 'Deleted successfully' });
      }
    },
    [tempLines, lines]
  );

  const editReceivingLine = useCallback(
    (l: any) => {
      const index = tempLines.findIndex(
        (i: any) =>
          i.fulfillmentPoLineNum === l.fulfillmentPoLineNum &&
          i.receiveLocationNum === l.receiveLocationNum
      );
      if (index > -1) {
        const idx = lines.findIndex(
          (i: any) => i.fulfillmentPoLineNum === l.fulfillmentPoLineNum
        );
        const tLine = [...lines];
        tLine[index].receivingQty =
          tLine[idx].receivingQty - tempLines[index].receiveQty + l.receiveQty;
        const temp = [...tempLines];
        temp[index] = { ...l };
        // temp.splice(index, 1)
        setTempLines([...temp]);
        setLines([...tLine]);
        setEditQtyDialogVisible(false);
        message.success({ content: 'Edited successfully' });
      }
    },
    [tempLines, lines]
  );

  const getTempLineColumns = useCallback(
    () => [
      { name: 'id', type: 'string', header: 'Id', defaultVisible: false },
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'upc',
        header: 'UPC',
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'receivingLocationCode',
        header: 'Location',
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'receiveQty',
        header: 'Receive Qty',
        minWidth: 130,
        maxWidth: 130,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
        type: 'number',
      },
      {
        header: 'Action',
        name: 'action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        sortable: false,
        maxWidth: 100,
        minWidth: 100,
        defaultFlex: 1,
        render: (value: any) => {
          const { data, rowIndex } = value;
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setEditQtyDialogVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <Button
                      id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
                      type="text"
                      // disabled={data.receiveQty !== 0}
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setCurrent(data);
                        setDeleteDialogVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                id={`catalog_grid_action_row_${rowIndex}_dropdown`}
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const getHistoryLineColumns = useCallback(
    () => [
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'locationCode',
        header: 'Location',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'receiveQty',
        header: 'Qty',
        minWidth: 130,
        maxWidth: 130,
        showColumnMenuTool: false,
        sortable: true,
        userSelect: true,
        type: 'number',
        defaultFlex: 1,
      },
      {
        name: 'time',
        header: 'Time',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
    ],
    []
  );

  const getPoInfoByPoNumber = useCallback(async (value: string) => {
    try {
      setQueryLoading(true);
      const polist = await fetchPoList({
        filterFieldName: 'poNumber',
        filterKey: value || '',
        poStatusNum: -1,
        sortBy: 'fulfillmentPoNum desc',
        skip: 0,
        limit: 10,
      });
      setQueryLoading(false);
      if (polist.data.length > 0) {
        setInfo(polist.data[0]);
      } else {
        message.warning({ content: 'no PO matched' });
      }
    } catch (error) {
      setQueryLoading(false);
    }
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setSaveLoading(true);
      //todo api
      const res = await receive(poInfo.fulfillmentPoId, {
        WarehouseNum: Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
        Items: tempLines.map((i) => {
          return {
            SKU: i.sku,
            FulfillmentPoLineNum: i.fulfillmentPoLineNum,
            ReceiveQty: i.receiveQty,
            LocationNum: i.receivingLocationNum,
            ReceiptDate: moment(receiptDate).format('YYYY-MM-DD'),
          };
        }),
      });
      setSaveLoading(false);
      if (res) {
        // message.success({ content: 'receive successful' });
        Modal.confirm({
          title: 'Received Successfully!',
          icon: <CheckCircleOutlined />,
          content: '',
          okText: 'Next Po',
          okType: 'default',
          cancelText: 'Close',
          autoFocusButton: 'cancel',
          onOk() {
            setInfo(undefined);
            setHistoryLines([]);
            setLines([]);
            setTempLines([]);
          },
          onCancel() {
            props.onClose();
          },
        });

        // todo refresh
      }
    } catch (error) {
      setSaveLoading(false);
    }
  }, [tempLines, props, receiptDate, poInfo.fulfillmentPoId]);

  const queryProduct = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    const res = lines.filter(
      (i: any) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
    );
    if (res && res.length > 0) {
      // setCurrentProduct(res[0]);
      const index = lines.findIndex((item: any) => item.sku === res[0].sku);
      if (index > -1) {
        setLines((prev: any) => {
          const temp = prev;
          temp.unshift(temp.splice(index, 1)[0]);
          return [...temp];
        });
      }
    } else {
      message.info({ content: `[${params.sku}] not found` });
      form.setFieldsValue({ sku: undefined });
    }
  }, [form, lines]);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    console.log(params);
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }

    setShowAll(false);

    const filterList = lines.filter(
      (i: any) =>
        i.sku.trim() === params.sku.trim() ||
        (i.upc || '').trim() === params.sku.trim()
    );
    if (filterList.length > 0) {
      // fulfillmentPoLineNum
      let totalQty = 0;
      filterList.map((i: any) => {
        totalQty = totalQty + i.quantity - i.humanReceiveQty - i.receivingQty;
        return true;
      });
      if (totalQty < Number(params.qty)) {
        playAudio('error');
        return message.info({ content: 'Cannot exceed total quatity' });
      }

      let remainingQty = Number(params.qty);
      filterList.map((i: any) => {
        if (remainingQty > 0) {
          const qty = i.quantity - i.humanReceiveQty - i.receivingQty;
          if (qty > 0) {
            const index = lines.findIndex(
              (k: any) => i.fulfillmentPoLineNum === k.fulfillmentPoLineNum
            );
            //need line
            const receivingIndex = tempLines.findIndex(
              (item: any) =>
                item.fulfillmentPoLineNum === i.fulfillmentPoLineNum &&
                item.receivingLocationNum === params.location
            );
            if (receivingIndex > -1) {
              setTempLines((prev) => {
                const temp = [...prev];
                temp[receivingIndex] = {
                  ...temp[receivingIndex],
                  receiveQty:
                    temp[receivingIndex].receiveQty +
                    (qty > remainingQty ? remainingQty : qty),
                };
                temp.unshift(temp.splice(receivingIndex, 1)[0]);
                return [...temp];
              });
            } else {
              setTempLines((prev: any) => {
                return [
                  {
                    fulfillmentPoLineNum: lines[index].fulfillmentPoLineNum,
                    sku: lines[index].sku,
                    upc: lines[index].upc,
                    receivingLocationNum: params.location,
                    receivingLocationCode: currentLocationCode,
                    receiveQty: qty > remainingQty ? remainingQty : qty,
                  },
                  ...prev,
                ];
              });
            }
            setLines((prev: any) => {
              const temp = prev;
              temp[index].receivingQty =
                temp[index].receivingQty +
                (qty > remainingQty ? remainingQty : qty);
              temp.unshift(temp.splice(index, 1)[0]);
              return [...temp];
            });
            setHistoryLines((prev) => {
              return [
                {
                  sku: params.sku,
                  locationCode: currentLocationCode,
                  receiveQty: params.qty,
                  time: moment().format('hh:mm:ssA'),
                },
                ...prev,
              ];
            });
            if (!showHistory) {
              setShowHistory(true);
            }
            remainingQty = qty > remainingQty ? 0 : remainingQty - qty;
          }
        }
        return true;
      });
      playAudio('success');
      clearForm();
    } else {
      playAudio('error');
      message.info({ content: `[${params.sku}] not found` });
      form.setFieldsValue({ sku: undefined });
      // setCurrentProduct(undefined);
    }
  }, [form, lines, tempLines, currentLocationCode, showHistory, clearForm]);

  const getPoLines = useCallback(async (id: string) => {
    try {
      const lines = await fetchPoLineList(id);
      setLines(
        lines.map((item) => {
          return {
            ...item,
            receivingQty: 0,
            receivingLocationCode: undefined,
            receivingLocationNum: undefined,
          };
        })
      );
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (poInfo && !initing) {
      setInfo(poInfo);
      //todo fetch history
      setIniting(true);
    }
  }, [poInfo, initing]);

  useEffect(() => {
    if (info) {
      getPoLines(info.fulfillmentPoId);
    }
  }, [info, getPoLines]);

  useEffect(() => {
    if (filterValue) {
      const temp = lines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      const tempTemp = tempLines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      const tempHistory = historyLines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      setFilterlines([...temp]);
      setTempFilterLines([...tempTemp]);
      setFilterHistoryLines([...tempHistory]);
    } else {
      setFilterlines([...lines]);
      setTempFilterLines([...tempLines]);
      setFilterHistoryLines([...historyLines]);
    }
  }, [filterValue, lines, historyLines, tempLines]);

  // useEffect(() => {
  //   if (info) fetchHistory();
  // }, [info, fetchHistory]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={[
          <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
          Cancel
        </Button>,
        <Button
          type="primary"
          disabled={tempLines.length === 0}
          loading={saveLoading}
          onClick={handleSave}
          icon={<SaveOutlined />}
        >
          Save
        </Button>
        ]}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`PO Receive (Warehouse: ${getWarehouseCodeFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )})`}
        visible={props.visible}
        width={detailDialogWidth()}
        // titleRightCrumb={
        //   <Space>
        //     <Button onClick={closeProductDetailDialog} icon={<CloseOutlined />}>
        //       Cancel
        //     </Button>
        //     <Button
        //       type="primary"
        //       disabled={tempLines.length === 0}
        //       loading={saveLoading}
        //       onClick={handleSave}
        //       icon={<SaveOutlined />}
        //     >
        //       Save
        //     </Button>
        //   </Space>
        // }
      >
        <div style={{ height: '100%', width: '100%' }}>
          {info && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // backgroundColor: '#fff',
                backgroundColor: theme['@default-backgroud-color'],
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 450,
                  minHeight: 580,
                  height: '100%',
                  // borderRightWidth: 1,
                  borderRadius: `${theme['@border-radius-base']}`,
                  // borderRightColor: ' rgba(0, 0, 0, 0.15)',
                  // borderRightStyle: 'solid',
                  marginRight: COMMON_PADDING_SPACE,
                }}
              >
                <Card size="small" bordered={false}>
                  <Spin
                    spinning={info.receivedQuantity >= info.quantity}
                    indicator={<StopOutlined style={{ fontSize: 20 }} />}
                    tip="All items have been received."
                  >
                    <Tabs
                      onChange={(key) => {
                        if (key === 'item-1') {
                          setFilterValue('');
                        }
                      }}
                      destroyInactiveTabPane={true}
                    >
                      <Tabs.TabPane
                        tab="Add"
                        key="item-1"
                        style={{ overflowX: 'hidden' }}
                      >
                        <Form form={form}>
                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>SKU / UPC</FormLabel>
                              </div>
                            }
                            rules={[{ required: true }]}
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="sku"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input SKU / UPC!',
                                    },
                                  ]}
                                >
                                  <Input
                                    tabIndex={0}
                                    onPressEnter={() => {
                                      if (qtyLocker && locationLocker) {
                                        addSubmit();
                                      } else {
                                        queryProduct();
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 9) {
                                        if (qtyLocker && locationLocker) {
                                          addSubmit();
                                        } else {
                                          queryProduct();
                                        }
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4}></Col>
                            </Row>
                          </Form.Item>

                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>Quantity</FormLabel>
                              </div>
                            }
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="qty"
                                  initialValue={1}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input Quantity!',
                                    },
                                  ]}
                                >
                                  <InputInteger
                                    isTabwork={!qtyLocker}
                                    width={186}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Locker
                                  size={16}
                                  value={qtyLocker}
                                  onChange={(value: boolean) => {
                                    setQtyLocker(value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>

                          <Form.Item
                            label={
                              <div style={{ width: 110, textAlign: 'left' }}>
                                <FormLabel require>Location</FormLabel>
                              </div>
                            }
                          >
                            <Row gutter={8} align="middle">
                              <Col span={20}>
                                <Form.Item
                                  noStyle
                                  name="location"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Select Location!',
                                    },
                                  ]}
                                >
                                  <SearchSelect
                                    warehouseId={getWarehouseIdFromCache(
                                      Number(
                                        localStorage.getItem(
                                          LS_DEFAULT_WAREHOUSE_KEY
                                        )
                                      )
                                    )}
                                    onChange={(value, row) => {
                                      console.log(value);
                                      console.log(row);
                                      setCurrentLocationCode(
                                        row[0].locationCode
                                      );
                                    }}
                                    locationType={0}
                                    showPick={false}
                                    form={form}
                                    title="location"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Locker
                                  size={16}
                                  value={locationLocker}
                                  onChange={(value: boolean) => {
                                    setLocationLocker(value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>

                          <Row
                            justify="center"
                            style={{ marginTop: 20, marginBottom: 20 }}
                          >
                            <Space>
                              <Button
                                type="primary"
                                onClick={addSubmit}
                                icon={<SaveOutlined />}
                              >
                                Submit
                              </Button>
                              <Button onClick={clearForm}>
                                <ClearOutlined />
                                Clear
                              </Button>
                            </Space>
                          </Row>
                        </Form>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Adjustment" key="item-2">
                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel>SKU / UPC</FormLabel>
                            </div>
                          }
                        >
                          <Input
                            allowClear
                            onChange={(e) => {
                              setShowAll(false);
                              debounceFn(e.target.value);
                              // setFilterValue(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Tabs.TabPane>
                    </Tabs>
                  </Spin>
                  <Divider orientation="left">PO Info</Divider>
                  <Row>
                    <Form.Item label={<FormLabel>PO Number</FormLabel>}>
                      <CopySpan value={info?.poNumber || ''} />
                    </Form.Item>

                    <Form.Item label={<FormLabel>Vendor Code</FormLabel>}>
                      <CopySpan value={info?.vendorCode || ''} />
                    </Form.Item>

                    <Form.Item label={<FormLabel>Status</FormLabel>}>
                      <CopySpan value={poStatusMap[info?.poStatusNum]} />
                    </Form.Item>
                    {/* </Col> */}
                    {/* <Col span={12}> */}
                    <Form.Item label={<FormLabel>PO Date</FormLabel>}>
                      <CopySpan
                        value={convertTimeByUtc(
                          info.poDate,
                          '',
                          '',
                          'MM/DD/YYYY'
                        )}
                      />
                    </Form.Item>
                    {/* </Col> */}
                  </Row>
                  <Divider />
                  <Row justify="center" style={{ marginTop: 16 }}>
                    <Button
                      onClick={() => {
                        if (tempLines.length > 0) {
                          Modal.confirm({
                            title: 'Do you want to leave this page？',
                            okText: 'No',
                            cancelText: 'Yes',
                            cancelButtonProps: {
                              className: 'hover-danger-button',
                            },
                            content: `Changes you made may not be saved.`,
                            onCancel() {
                              setInfo(undefined);
                              setHistoryLines([]);
                              setLines([]);
                              setTempLines([]);
                            },
                          });
                        } else {
                          setInfo(undefined);
                          setHistoryLines([]);
                          setLines([]);
                          setTempLines([]);
                        }
                      }}
                      icon={<DiffOutlined />}
                    >
                      Receive Other PO
                    </Button>
                  </Row>
                </Card>
              </div>
              {!showAll && (
                <div
                  style={{
                    height: '100%',
                    minHeight: 580,
                    flexGrow: 1,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    background: '#fff',
                    borderRadius: theme['@border-radius-base'],
                    marginRight: COMMON_PADDING_SPACE,
                  }}
                >
                  <div
                    style={{
                      height: showHistory ? '50%' : '100%',
                      width: '100%',
                      paddingBottom: 8,
                    }}
                  >
                    <Row
                      align="middle"
                      justify="space-between"
                      style={{ paddingTop: 8 }}
                    >
                      <Typography.Text strong>Receving Lines</Typography.Text>
                      <Space>
                        <FormLabel>Receipt Date</FormLabel>
                        <DatePicker
                          allowClear={false}
                          style={{ marginLeft: 10 }}
                          defaultValue={moment()}
                          format="MM/DD/YYYY"
                          onChange={(date) => setReceiptDate(date || moment())}
                        />
                      </Space>
                    </Row>
                    <DataGridPlus
                      id="id"
                      boxStyle={{ height: 'calc(100% - 40px)' }}
                      columns={getTempLineColumns()}
                      dataSource={tempFilterLines.map((i: any) => {
                        return {
                          id: `${i.sku}---${i.receivingLocationNum}`,
                          ...i,
                        };
                      })}
                      autoWith={false}
                      autoHeight={false}
                      style={{
                        height: detailDialogIsFullscreen
                          ? showHistory
                            ? 'auto'
                            : '100%'
                          : showHistory
                          ? 240
                          : 522,
                      }}
                      selected={
                        tempLines.length > 0 ? { [tempLines[0].sku]: true } : {}
                      }
                      pagination={false}
                      {...({} as any)}
                    />
                  </div>
                  {showHistory && (
                    <div
                      style={{
                        height: '50%',
                        width: '100%',
                        paddingBottom: 10,
                      }}
                    >
                      <Row align="middle">
                        <Typography.Text strong>
                          Receive History
                        </Typography.Text>
                      </Row>
                      <DataGridPlus
                        id="id"
                        boxStyle={{ height: 'calc(100% - 40px)' }}
                        columns={getHistoryLineColumns()}
                        dataSource={filterHistoryLines}
                        autoWith={false}
                        autoHeight={false}
                        style={{
                          height: detailDialogIsFullscreen ? 'auto' : 240,
                        }}
                        // selected={lines.length > 0 ? { [lines[0].sku]: true } : {}}
                        pagination={false}
                        {...({} as any)}
                      />
                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  width: showAll ? 'calc(100% - 454px)' : 300,
                  height: '100%',
                  minHeight: 580,
                  backgroundColor: '#fff',
                  borderRadius: theme['@border-radius-base'],
                  // borderLeftWidth: 1,
                  // borderLeftColor: ' rgba(0, 0, 0, 0.15)',
                  // borderLeftStyle: 'solid',
                }}
              >
                <Tooltip placement="right" title={showAll ? 'Close' : 'Open'}>
                  <Button
                    icon={showAll ? <RightOutlined /> : <LeftOutlined />}
                    style={{
                      width: 14,
                      height: 200,
                      alignSelf: 'center',
                    }}
                    onClick={() => setShowAll((prev) => !prev)}
                  />
                </Tooltip>

                <Card
                  title="PO Lines"
                  bordered={false}
                  style={{ width: 'calc(100% - 14px)' }}
                  bodyStyle={{
                    height: 'calc(100% - 70px)',
                    minHeight: 520,
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {filterlines.map((i: any) => (
                    <Card
                      key={i.sku}
                      size="small"
                      type="inner"
                      headStyle={
                        i.returnQty === i.receiveQty + i.receivingQty
                          ? { backgroundColor: theme['@finished-color'] }
                          : {}
                      }
                      title={
                        <Row>
                          <Col span={24}>
                            <CopySpan inGrid={!showAll} value={i.sku || ''} />
                          </Col>
                        </Row>
                        // )
                      }
                      style={{ width: '100%', marginBottom: 16 }}
                    >
                      {showAll ? (
                        <Row gutter={4}>
                          <Col span={8}>
                            <Row>
                              <span>UPC:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan value={i.upc || ''} />
                              </span>
                            </Row>
                          </Col>
                          {/* <Col span={8}>
                            <Row>
                              <span>Title:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan inGrid value={i.title || ''} />
                              </span>
                            </Row>
                          </Col> */}
                          <Col span={8}>
                            <span>{`Quantity: ${i.quantity || 0}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Received Quantity: ${
                              i.humanReceiveQty || 0
                            }`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Receiving Qty: ${i.receivingQty}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Public Notes: ${
                              i.linePublicNote || ''
                            }`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Private Note: ${
                              i.linePrivateNote || ''
                            }`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Waiting for Closing: ${
                              i.waitCloseQty || 0
                            }`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Closing: ${i.closingQty || 0}`}</span>
                          </Col>
                          <Col span={8}>
                            <span>{`Enter Date: ${convertTimeByUtc(
                              i.enterDate,
                              '',
                              '',
                              'MM/DD/YYYY'
                            )}`}</span>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col span={24}>
                            <Row>
                              <span>UPC:&nbsp;</span>
                              <span style={{ width: 'calc(100% - 44px)' }}>
                                <CopySpan value={i.upc || ''} />
                              </span>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <span>{`Quantity: ${i.quantity}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Received Qty: ${i.humanReceiveQty}`}</span>
                          </Col>
                          <Col span={24}>
                            <span>{`Receiving Qty: ${i.receivingQty}`}</span>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  ))}
                </Card>
              </div>
            </div>
          )}
          {!info && (
            <Card
              style={{
                height: '100%',
              }}
              bodyStyle={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Form
                style={{ maxWidth: 1200, width: '80%' }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
              >
                <Row justify="center">
                  <Typography.Title level={2}>PO Receive</Typography.Title>
                </Row>
                <Form.Item label={<FormLabel>po Number</FormLabel>}>
                  <Input.Search
                    id="form_search_value_input_returnNo"
                    allowClear
                    loading={queryLoading}
                    placeholder="Please input search value"
                    onKeyDown={(e) =>
                      e.keyCode === 9 &&
                      getPoInfoByPoNumber(poNumberSearchRef.current)
                    }
                    onChange={(e) =>
                      (poNumberSearchRef.current = e.target.value)
                    }
                    onSearch={(value) => {
                      getPoInfoByPoNumber(value.trim());
                    }}
                  />
                </Form.Item>
              </Form>
            </Card>
          )}
        </div>
        {current && deleteDialogVisible && (
          <Modal
            title="Are you sure delete this Row?"
            visible
            onOk={() => {
              delReceivingLine(current);
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`SKU: ${current.sku}`}</p>`
          </Modal>
        )}
        {current && editQtyDialogVisible && (
          <EditQtyDialog
            visible
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
            onSave={(data: any) => {
              editReceivingLine(data);
            }}
            data={current}
            lines={lines}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
