import React, { useCallback, useEffect, useMemo } from 'react';
import { Select, Spin } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

const { Option } = Select;
type SelectWarehouseProps = {
  showAll?: boolean;
  placeholder?: string;
  width?: number | string;
  style?: React.CSSProperties;
  bordered?: boolean;
  value?: any;
  disabled?: boolean;
  onlyNormal?: boolean;
  onChange?: (warehouse: WarehouseRow) => void;
  id?: string;
  withoutDefaultWarehouse?: boolean;
  size?: 'small' | 'middle' | 'large' | undefined;
  defaultWarehouseNum?: number
};
/**
 * Returns SelectWarehouse Components
 * @param {SelectWarehouseProps} props
 * @returns {JSX.Element}
 */
const SelectWarehouse = (props: SelectWarehouseProps) => {
  const { showAll = false, disabled = false, onlyNormal = true, id, onChange, placeholder, value, width, bordered, withoutDefaultWarehouse = false, defaultWarehouseNum } = props;
  let { style = {} } = props;
  const warehouses = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const data: Array<WarehouseRow> = useMemo(() => {
    return onlyNormal
      ? withoutDefaultWarehouse ? [...warehouses.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5)).filter((i:WarehouseRow) => i.id !== Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))] : [...warehouses.filter((w: WarehouseRow) => (w.warehouseType === 1 || w.warehouseType === 5))]
      : withoutDefaultWarehouse ? [...warehouses.filter((i:WarehouseRow) => i.id !== Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))] : [...warehouses];
  }, [warehouses, onlyNormal, withoutDefaultWarehouse]);

  if (showAll) {
    let existed = false;
    if (data.length > 0) {
      const [first] = data;
      if (first.code === 'All') {
        existed = true;
      }
    }
    if (!existed) {
      data.unshift({ id: 0, code: 'All' } as any);
    }
  }

  /**
   * handle change event
   */
  const handleChange = useCallback((id: number) => {
    if (typeof onChange === 'function') {
      const warehouse = data.find((item: any) => item.id === id);
      if (warehouse !== undefined) {
        onChange(warehouse);
      }
    }
  }, [onChange, data]);

  // set default warehouse
  useEffect(() => {
    let temp;
    if (typeof onChange !== 'function') return;
    if (!value && !showAll && data.length > 0 && !withoutDefaultWarehouse) {
      const id = localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY);
      if (id) {
        temp = data.find(item => item.id === Number(id));
      }
    }
    if (showAll) {
      temp = data.find(item => item.id === 0);
    }
    if (value) {
      temp = data.find(item => item.id === value.id);
    }
    if(defaultWarehouseNum){
      temp = data.find(item => item.id === defaultWarehouseNum);
    }
    if (temp) {
      onChange(temp);
    } else {
      onChange(data[0]);
    }
    // eslint-disable-next-line
  }, [])

  if (data.length === 0) {
    return <Spin />;
  }

  //Compatible placeholder  scene
  let extendProps: StringKAnyVPair = placeholder ? { placeholder } : { defaultValue: data[0].id };

  if (typeof bordered !== 'undefined') {
    if (!bordered) {
      extendProps = {
        ...extendProps,
        bordered: false,
      };
    }
  }

  //style
  style = {
    ...style,
    width: width || '100%',
  };

  return (
    <Select
      id={id}
      showSearch
      size={props.size || 'middle'}
      value={defaultWarehouseNum? defaultWarehouseNum : value ? value.id :undefined}
      /* eslint-disable react/jsx-props-no-spreading */
      {...extendProps}
      disabled={disabled}
      style={style}
      optionFilterProp="children"
      filterOption={(key, option) => {
        if (option) {
          const { value: currentValue } = option;
          const warehouse = data.find((item) => {
            if (item.id === currentValue) {
              return item;
            }
            return undefined;
          });
          if (warehouse) {
            return warehouse.code.toLowerCase().indexOf(key.toLowerCase()) >= 0;
          }
        }
        return false;
      }}
      onChange={handleChange}
    >
      {data
        .map((warehouse: WarehouseRow) => {
          return (
            <Option key={warehouse.id} value={warehouse.id}>
              {warehouse.code}
            </Option>
          );
        })}
    </Select>
  );
};
SelectWarehouse.defaultProps = {
  showAll: false,
  placeholder: '',
  width: undefined,
  style: undefined,
  bordered: undefined,
  value: undefined,
  onChange: undefined,
  disabled: false,
};
export default SelectWarehouse;
