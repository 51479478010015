import React from "react";

const Icon = ({ width = 18, height = 18, stroke = "white", fill = "none", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...other}
    >
      <path
        d="M10.9525 18.9418L2.41431 18.9418V10.537L8.81792 4L15.2215 10.2257C15.2215 10.368 15.2215 10.78 15.2215 11.4916"
        stroke={stroke}
        strokeLinejoin="round"
        fill="none" />
      <path
        d="M16.4067 18.2969V11.8745C16.4067 11.5198 16.1168 11.2322 15.7593 11.2322H10.5801C10.2225 11.2322 9.93268 11.5198 9.93268 11.8745V18.2969C9.93268 18.6516 10.2225 18.9392 10.5801 18.9392H11.2275M16.4067 18.2969C16.4067 18.6516 16.1168 18.9392 15.7593 18.9392H13.8171M16.4067 18.2969L16.4067 13.159C16.4067 12.8043 16.6965 12.5167 17.0541 12.5167H18.7281C18.8998 12.5167 19.0645 12.5844 19.1859 12.7048L21.3963 14.8976C21.5177 15.018 21.5859 15.1814 21.5859 15.3517V18.2969C21.5859 18.6516 21.296 18.9392 20.9385 18.9392H20.2911M16.4067 18.2969C16.4067 18.6516 16.6965 18.9392 17.0541 18.9392H17.7015M11.2275 18.9392C11.2275 19.6486 11.8072 20.2236 12.5223 20.2236C13.2374 20.2236 13.8171 19.6486 13.8171 18.9392M11.2275 18.9392C11.2275 18.2298 11.8072 17.6547 12.5223 17.6547C13.2374 17.6547 13.8171 18.2298 13.8171 18.9392M17.7015 18.9392C17.7015 19.6486 18.2812 20.2236 18.9963 20.2236C19.7114 20.2236 20.2911 19.6486 20.2911 18.9392M17.7015 18.9392C17.7015 18.2298 18.2812 17.6547 18.9963 17.6547C19.7114 17.6547 20.2911 18.2298 20.2911 18.9392"
        stroke={stroke}
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7938 16.3079C7.64829 16.3079 6.71967 15.3808 6.71967 14.2371C6.71967 13.0935 7.64829 12.1663 8.7938 12.1663C9.54183 12.1663 9.88083 12.5137 10.2457 13.1065L10.0435 15.8887C9.69597 16.1513 9.26313 16.3073 8.7938 16.3079ZM7.75789 14.2223C7.76601 13.6567 8.22725 13.2023 8.7938 13.2017C9.36656 13.2017 9.83087 13.6653 9.83087 14.2371V14.2837C9.80538 14.8488 9.33039 15.2888 8.76409 15.2721C8.19778 15.2553 7.74977 14.7879 7.75789 14.2223Z"
        fill={stroke}
        stroke="none"
      />
      <path
        d="M9.73961 19.4141H7.85215C7.60849 19.4141 7.39768 19.2448 7.34554 19.0072L7.13449 18.0318C6.85296 17.9087 6.586 17.7547 6.33854 17.5726L5.386 17.8755C5.15369 17.9494 4.90116 17.8518 4.77931 17.641L3.8335 16.0097C3.71299 15.7988 3.75451 15.5327 3.93358 15.3683L4.67249 14.6953C4.63889 14.3904 4.63889 14.0828 4.67249 13.7779L3.93358 13.1065C3.75425 12.942 3.71271 12.6756 3.8335 12.4645L4.77724 10.8323C4.89909 10.6214 5.15162 10.5238 5.38392 10.5977L6.33647 10.9006C6.46303 10.807 6.59477 10.7205 6.73108 10.6417C6.86192 10.5681 6.99658 10.5014 7.13449 10.4419L7.34606 9.46761C7.39795 9.22997 7.60853 9.06044 7.85215 9.06018H9.73961C9.98322 9.06044 10.1938 9.22997 10.2457 9.46761L10.4593 10.4424C10.6049 10.5064 10.7467 10.5786 10.884 10.6588C11.0121 10.7328 11.1358 10.8138 11.2548 10.9016L12.0327 11.5415H11.3536H10.4593C10.2731 11.4047 10.2546 11.4839 10.0435 11.3898L9.55968 11.1781L9.32271 10.0956H8.27008L8.03208 11.1786L7.54932 11.3898C7.44655 11.4349 7.34632 11.4855 7.24909 11.5415C7.14628 11.6002 7.04691 11.6646 6.95145 11.7346L6.52574 12.0452L5.46793 11.7087L4.94058 12.6219L5.76142 13.3679L5.70334 13.8923C5.67783 14.1215 5.67783 14.3528 5.70334 14.5819L5.76142 15.1058L4.94058 15.8529L5.46689 16.763L6.5247 16.4265L6.9499 16.7371C7.04575 16.8076 7.14565 16.8724 7.24909 16.9312C7.34642 16.9875 7.44663 17.0386 7.54932 17.0845L8.03311 17.2962L8.27008 18.3787H9.32271H9.95977C9.90767 18.6161 9.98307 19.4139 9.73961 19.4141Z"
        fill={stroke}
        stroke="none"
      />
    </svg>
  );
};
export default React.memo(Icon);
