import React, { useCallback, useEffect, useState } from 'react';
import { Row, Checkbox, Button, Space, Modal } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { patchWarehouseLocationZone } from 'services/warehouse';
import { GreyCardWrapper, message, ClearButton } from 'components/common';
import TableTransfer from './TableTransfer';

type TextProps = {
  value: string;
};
const Text = (props: TextProps): JSX.Element => {
  const { value } = props;
  return <span style={{ paddingLeft: 8 }}>{value}</span>;
};
type TitleProps = {
  children: React.ReactNode;
};
const Title = (props: TitleProps): JSX.Element => {
  const { children } = props;
  return <div style={{ fontWeight: 600 }}>{children}</div>;
};
const delta = (
  a1: Array<string>,
  a2: Array<string>
): [Array<string>, Array<string>] => {
  const deletes: Array<string> = [];
  a1.forEach((item) => {
    if (!a2.includes(item)) {
      deletes.push(item);
    }
  });
  const adds: Array<string> = [];
  a2.forEach((item) => {
    if (!a1.includes(item)) {
      adds.push(item);
    }
  });
  return [adds, deletes];
};
type RelationsProps = {
  warehouseNum: number;
  warehouseId: string;
  zone: ZoneRow;
  loading: boolean;
  locations: Array<LocationZoneRow>;
  zoneKeyValue: { [key: string]: string };
  onReload: () => Promise<void>;
};
export default function Relations(props: RelationsProps): JSX.Element {
  const { locations, zone, loading, warehouseId, onReload, zoneKeyValue } =
    props;
  const [targetKeys, setTargetKeys] = useState<Array<string>>([]);
  const [fetching, setFetching] = useState(false);
  const [reassign, setReassign] = useState<boolean>(false);
  const [deleteRows, setDeleteRows] = useState<Array<string>>([]);
  const [addRows, setAddRows] = useState<Array<string>>([]);
  const leftDataSource = locations.filter((item) => {
    if (item.parentNum !== null) {
      return false;
    }

    if (reassign) {
      return item.locationCode !== 'General' ? true : false;
    } else {
      if (
        (item.zoneNum === zone.id ||
          item.zoneNum === null ||
          item.zoneNum === 0 ||
          item.zoneNum === 1) &&
        item.locationCode !== 'General'
      ) {
        return true;
      }
      return false;
    }
  });
  const rightSnapshot = locations
    .filter((row) => {
      if (row.zoneNum === zone.id) {
        return true;
      }
      return false;
    })
    .map((row) => {
      return `${row.locationId}`;
    });
  const rightTableColumns = [
    {
      dataIndex: 'locationCode',
      title: 'Location Code',
      render: (value: string) => {
        return <Text value={value} />;
      },
    },
    {
      dataIndex: 'zoneName',
      title: 'Zone Name',
      render: () => {
        return <Text value={zone.name} />;
      },
    },
  ];
  const leftTableColumns = [
    {
      dataIndex: 'locationCode',
      title: 'Location Code',
      render: (value: string) => {
        return <Text value={value} />;
      },
    },
    {
      dataIndex: 'zoneName',
      title: 'Zone Name',
      render: (value: string, row: LocationZoneRow) => {
        if (row.zoneNum === zone.id) {
          return <Text value="" />;
        }
        return <Text value={zoneKeyValue[row.zoneNum] || ''} />;
      },
    },
  ];
  const onChange = useCallback(
    async (nextTargetKeys: string[]) => {
      setTargetKeys(nextTargetKeys);
      const [adds, deletes] = delta(rightSnapshot, nextTargetKeys);
      setDeleteRows(deletes);
      setAddRows(adds);
    },
    [rightSnapshot]
  );
  const initTargetKeys = useCallback(() => {
    const nextTargetKeys = locations
      .filter((row) => {
        if (row.zoneNum === zone.id) {
          return true;
        }
        return false;
      })
      .map((row) => {
        return `${row.locationId}`;
      });
    setTargetKeys(nextTargetKeys);
  }, [locations, zone]);
  /**
   * Reset
   */
  const handleReset = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure to reset location?',
      okText: 'Reset',
      cancelText: 'Cancel',
      content: 'Changes you made may not be saved.',
      onOk: () => {
        initTargetKeys();
      },
    });
  }, [initTargetKeys]);
  /**
   * handle save
   */
  const handleSave = useCallback(async () => {
    const promiseChain: Array<Promise<any>> = [];
    deleteRows.forEach((locationId) => {
      promiseChain.push(
        patchWarehouseLocationZone(warehouseId, locationId, null)
      );
    });
    addRows.forEach((locationId) => {
      promiseChain.push(
        patchWarehouseLocationZone(warehouseId, locationId, zone.id)
      );
    });
    setFetching(true);
    await Promise.all(promiseChain);
    setFetching(false);
    message.success({ content: 'Saved Zone Locations Successfully!' });
    setAddRows([]);
    setDeleteRows([]);
    onReload();
  }, [deleteRows, addRows, zone, onReload, warehouseId]);

  useEffect(() => {
    initTargetKeys();
  }, [initTargetKeys]);

  return (
    <>
      <GreyCardWrapper style={{ marginTop: 10 }}>
        <Row
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            checked={reassign}
            onChange={() => {
              setReassign(!reassign);
            }}
          >
            Allow to reassign location
          </Checkbox>
          <Row>
            <Space>
              <ClearButton
                disabled={deleteRows.length + addRows.length === 0}
                text="Reset"
                onClick={handleReset}
              />
              <Button
                type="primary"
                disabled={deleteRows.length + addRows.length === 0}
                onClick={handleSave}
              >
                <SaveOutlined />
                <span>Save</span>
              </Button>
            </Space>
          </Row>
        </Row>
      </GreyCardWrapper>
      <GreyCardWrapper style={{ marginTop: 10 }}>
        <TableTransfer
          loading={loading || fetching}
          dataSource={leftDataSource}
          titles={[
            <Title>Avaliable locations</Title>,
            <Title>Assigned locations</Title>,
          ]}
          targetKeys={targetKeys}
          operations={[
            `Assign to zone '${zone.name}'`,
            `Remove from zone '${zone.name}'`,
          ]}
          showSearch
          onChange={onChange}
          locale={{ searchPlaceholder: 'Search Location Code' }}
          listStyle={() => ({} as any)}
          filterOption={(code: string, item: LocationZoneRow) => {
            return (
              item.locationCode
                .toLocaleLowerCase()
                .indexOf(code.toLocaleLowerCase()) !== -1
            );
          }}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          rowKey={(record) => `${record.locationId}`}
        />
      </GreyCardWrapper>
    </>
  );
}
