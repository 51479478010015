import styled from 'styled-components';

export const CarriersItem = styled.div`
    padding: 6;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
`;

export const SelectBox = styled.div`
    width: 900px;
    height: 200px;
    display: flex;
    flex-direction:row;
`;

export const SelectBoxLeft = styled.div`
    width: 200px;                  
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content:flex-start;
`;
export const Box = styled.div`
    flex-grow:1;
    height: 200px;
`;
export const SelectBoxRight = styled.div`
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content:flex-start;
`;

export const TwoCol = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
`
export const ItemCol = styled.div`
    width: 50%;
`