import React from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Image,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons';
import { /*Trans,*/ useTranslation } from 'react-i18next';

import { printMultiPackageLabel } from 'actions/printActions';
import { setPrintMultiPackageResult } from 'actions/salesAction';
import fallbackImage from 'assets/images/logo192.png';
import {
  Loading,
  ScreenMask,
  message,
} from 'components/common';
import DataGrid from 'components/common/DataGrid0';
import { ButtonIcon, Label } from 'components/common/styles';
import {
  LOADING_ICON_SIZE1,
  PRINT_MESSAGE_TYPE_ERROR,
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_ITEM_NOT_VERIFY_STATE,
  SHIPMENT_ITEM_VERIFY_STATE,
  SHIPMENT_SHIPPING_STATE,
} from 'constants/config';
import { RootState } from 'reducers';
import { getWeightString } from 'utils';
import {
  fetchShipmentItemsById,
} from 'services/sales';
import { userProfiles } from 'utils/auth';

import { ShipmentItemDict, ShipmentUnit } from '../';
import { ShipmentTabs } from '../styles';
import { PrintStateWrapper, ShipMultiPackageWrapper } from './styles';

type Props = {
  deviceSetting?: Function;
  onClose: Function;
  openAlertDialog?: Function;
  orderShipmentList: ShipmentUnit[];
  resetOrderShipment?: Function;
  visible: boolean;
};

const ShipMultiPackages = (props: Props) => {
  const { /*useCallback,*/ useMemo, useRef, useState } = React;
  const dispatch: Dispatch<any> = useDispatch();
  const i18n = useTranslation();
  // eslint-disable-next-line
  const [current, setCurrent] = useState<any>();
  //const [currentPageWidth, setCurrentPageWidth] = useState(0);
  const [inited, setInited] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  // eslint-disable-next-line
  const [orderQtyColumnWidth, setOrderQtyColumnWidth] = useState(140);
  const [orderShipmentList, setOrderShipmentList] = useState<ShipmentUnit[]>(
    []
  );
  //const [packageHeight, setPackageHeight] = useState<number>();
  //const [packageLength, setPackageLength] = useState<number>();
  //const [packageName, setPackageName] = useState('');
  //const [packageNum, setPackageNum] = useState(-1);
  //const [packageType, setPackageType] = useState('');
  //const [packageWidth, setPackageWidth] = useState<number>();
  const [printMsgs, setPrintMsgs] = useState<StringKAnyVPair[]>([]);
  const [printState, setPrintState] = useState(0);
  const [printStateVisible, setPrintStateVisible] = useState(false);
  const [refShipment, setRefShipment] = useState<StringKAnyVPair>({});
  const [selectedShipmentNum, setSelectedShipmentNum] = useState(0);
  const [selectedShipmentList, setSelectedShipmentList] = useState<number[]>([]);
  //const [shipAccountNum, setShipAccountNum] = useState(0);
  //const [shipCarrier, setShipCarrier] = useState('');
  //const [shipCarrierNum, setShipCarrierNum] = useState(0);
  //const [shipService, setShipService] = useState('');
  //const [shipWeight, setShipWeight] = useState<number>();
  const [shipmentItemDict, setShipmentItemDict] = useState<ShipmentItemDict>(
    {}
  );
  // eslint-disable-next-line
  const [verifyRowHeight, setVerifyRowHeight] = useState(80);

  const selectedShipment = useMemo(() => {
    const list = orderShipmentList.filter(
      (e) => selectedShipmentNum > 0 && e.shipmentNum === selectedShipmentNum
    );

    return list.length === 1 ? list[0] : null;
  }, [orderShipmentList, selectedShipmentNum]);

  const selectedShipmentTitle = useMemo(() => {
    //const len = orderShipmentList.length;
    let ret = '';

    for (let i = 0; i < orderShipmentList.length; i++) {
      if (orderShipmentList[i].shipmentNum === selectedShipmentNum) {
        //ret = `${orderShipmentList[i].shipmentTitle} of ${len}`;
        ret = `${orderShipmentList[i].shipmentTitle}`;
        break;
      }
    }

    return ret;
  }, [orderShipmentList, selectedShipmentNum]);

  const tabShipmentList = useMemo(() => {
    return orderShipmentList.map(e => e);
    /*const list = orderShipmentList.filter((e) => {
      return !(
        e.shipmentNum !== selectedShipmentNum &&
        e.shippingStatus === SHIPMENT_SHIPPING_STATE
      );
    });

    if (list.length > 1 && list[0].shippingStatus === SHIPMENT_SHIPPING_STATE) {
      if (list[0].shipmentNum !== selectedShipmentNum) {
        list.splice(0, 1);
      }
    }

    return list;*/
  }, [orderShipmentList, /*selectedShipmentNum*/]);

  const printLabelResponse: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printLabelResponse,
    shallowEqual
  );

  const printMultiPackageResult: StringKAnyVPair = useSelector(
    (state: RootState) => state.sales.printMultiPackageResult,
    shallowEqual
  );

  const shipPrinter: Printer = useSelector(
    (state: RootState) => state.sales.shipmentPrinter,
    shallowEqual
  );

  const wrapperRef = useRef<any>(null);

  const columns = [
    /*{
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      resizable: false,
      textAlign: 'center',
      render() {
        return <Checkbox />;
      },
      renderHeader() {
        return <Checkbox />;
      },
    },*/
    {
      cellProps: {
        className: 'InovuaReactDataGrid__thumbnail-cell',
        style: { borderRight: 'none' },
      },
      headerProps: { className: 'thumbnail-col-header' },
      maxWidth: orderQtyColumnWidth,
      minWidth: orderQtyColumnWidth,
      sortable: false,
      resizable: false,
      render: (value: any) => {
        const { data } = value;

        return (
          <Row align="middle" justify="center">
            <Image
              fallback={fallbackImage}
              width={64}
              height={64}
              preview={false}
              src={data.thumbnail || 'error'}
            />
          </Row>
        );
      },
    },
    {
      cellProps: { className: 'verify-item-info-cell' },
      headerProps: { className: 'verify-item-info-col-header' },
      resizable: false,
      sortable: false,
      userSelect: true,
      render: (value: any) => {
        const { data } = value;

        return (
          <Row align="middle" justify="start">
            <Col>
              <Row>
                <Label className="label-bold">{data.title}</Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">SKU: </span>
                  <span className="label-grey">{data.sku}</span>
                </Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">UPC: </span>
                  <span className="label-grey">{data.upc}</span>
                </Label>
              </Row>
              {data.bundleType === 1 && <Row>
                <Label>
                  <span className="label-grey">Component of <Typography.Link onClick={()=>{
                    setCurrent({
                      productId: data.bundleProductId,
                      sku: data.bundleSKU,
                      upc: data.bundleUPC,
                      productTitle: data.bundleTitle,
                    })
                    //setBundleModalVisible(true);
                  }}>{data.bundleSKU}</Typography.Link><span>{`(${data.bundleQty}x${data.orderQty / data.bundleQty})`}</span></span>
                </Label>
              </Row>}
            </Col>
          </Row>
        );
      },
      renderHeader() {
        return selectedShipmentTitle;
      },
    },
    {
      header: i18n.t('common.qty'),
      cellProps: { style: { fontSize: 46 } },
      maxWidth: orderQtyColumnWidth,
      minWidth: orderQtyColumnWidth,
      name: 'shippingQty',
      headerEllipsis: false,
      headerProps: { className: 'scanned-col-header' },
      resizable: false,
      sortable: false,
      textAlign: 'center',
      type: 'number',
    },
  ];

  const closePrintStateBoard = () => {
    setPrintStateVisible(false);
  };

  // eslint-disable-next-line
  /*const fillFormValueByShipment = (shipment: StringKAnyVPair) => {
    const {
      height,
      length,
      packageCode,
      packageName,
      shipCarrierNum,
      shipPackageNum,
      shippingAccountNum,
      shippingCarrier,
      shippingServiceCode,
      width,
    } = shipment;

    if (packageCode) setPackageType(packageCode);
    if (packageName) setPackageName(packageName);
    if (shipCarrierNum) setShipCarrierNum(shipCarrierNum);
    if (typeof shipPackageNum === 'number') setPackageNum(shipPackageNum);
    if (shippingAccountNum) setShipAccountNum(shippingAccountNum);
    if (shippingCarrier) setShipCarrier(shippingCarrier);
    if (shippingServiceCode) setShipService(shippingServiceCode);

    setPackageHeight(height || undefined);
    setPackageLength(length || undefined);
    setPackageWidth(width || undefined);
    setShipWeight(
      selectedShipment ? selectedShipment.weight || undefined : undefined
    );
    setServiceCode(
      selectedShipment
        ? selectedShipment.shippingServiceCode || undefined
        : undefined
    );
    //setPackageUpdateTag(uuid());
    //setServiceUpdateTag(uuid());
  };*/

  const getDimension = () => {
    const { length, height, width } = selectedShipment || {};

    if (length === null || height === null || width === null) {
      return ' ';
    }

    return `${length}Lx${width}Wx${height}H`;
  };

  const getLastPrintMsg = () => {
    const len = printMsgs.length;
    let ret = '';

    if (len > 0) {
      const p = printMsgs[len - 1];

      if (p) {
        const sl = orderShipmentList.filter(e => e.shipmentNum === p.id);

        if (sl.length > 0) {
          ret = sl[0].shipmentTitle || '';
        }
      }

      ret = `"${ret}" has been printed`;
    }

    return ret;
  };

  const getOrderShipmentIcon = (shipment: OrderShipment) => {
    let ret = 'bi-printer';

    if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
      ret = 'bi-truck';
    } else if (shipment.shippingStatus === SHIPMENT_CANCELLED_STATE) {
      ret = 'bi-folder-x';
    } else {
      if (shipment.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE) {
        ret = 'bi-upc-scan';
      }
    }

    return ret;
  };

  // eslint-disable-next-line
  const getShipmentItemsById = async (id: number) => {
    setLockScreen(true);

    try {
      const list = await fetchShipmentItemsById(id, true);
      const items = list.map((e) => ({
        ...e,
        scanError: false,
        scanMessage: '',
      }));

      if (
        //searchId[0] !== '_' &&
        orderShipmentList.length === 1 &&
        items.length === 1 &&
        items[0].shippingQty === 1
      ) {
        const isVerified =
          orderShipmentList[0].verifyStatus === SHIPMENT_ITEM_VERIFY_STATE;

        items[0].verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
        items[0].verifyQty = items[0].shippingQty;

        if (!isVerified) {
          orderShipmentList[0].verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
          setOrderShipmentList([...orderShipmentList]);
          setSelectedShipmentNum(0);
          setTimeout(() => {
            setSelectedShipmentNum(orderShipmentList[0].shipmentNum);
            //setTimeout(handleMarkShipmentVerified, 0);
          }, 0);
        }
      } /*else if (searchId[0] !== '_') {
        if (searchCodeCount === 0) {
          setSearchCodeCount(searchCodeCount + 1);
          items.forEach((e) => {
            if (e.verifyStatus !== SHIPMENT_ITEM_VERIFY_STATE) {
              if (e.sku === searchId || e.upc === searchId) {
                e.verifyQty = 1;

                if (e.verifyQty === e.shippingQty) {
                  e.verifyStatus = SHIPMENT_ITEM_VERIFY_STATE;
                }
              }
            }
          });
        }
      }*/

      // adjust the line data
      items.forEach(e => {
        if (e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE) {
          e.verifyQty = e.shippingQty;
        }
      });

      setShipmentItemDict({
        ...shipmentItemDict,
        [id]: [...items],
      });
      //playBell('success');
      //tryFocusItemVerify();
    } catch (e) {
      message.error(`Fetch shipment items: ${e}`);
    } finally {
      setLockScreen(false);
    }
  };

  const getVerifiedShipments = (sl: ShipmentUnit[]) => {
    return sl.filter(e => e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE);
  };

  const handleChangeShipmentTab = (key: string | number) => {
    const num = typeof key === 'number' ? key : parseInt(key);

    setSelectedShipmentNum(num);
    //setVerifyCode('');
  };

  const isPrintOver = () => {
    //return printMsgs.length > 0 && orderShipmentList.length === printMsgs.length;
    return printMsgs.length > 0 && selectedShipmentList.length === printMsgs.length;
  };

  const isPrintSuccessful = () => {
    //if (printMsgs.length > 0 && orderShipmentList.length === printMsgs.length) {
    if (isPrintOver()) {
      return printMsgs.every(e => e.result !== PRINT_MESSAGE_TYPE_ERROR);
    }

    return false;
  };

  const onSelectedShipmentChange = (
    evt: any,
    e: ShipmentUnit,
  ) => {
    /*if (!shipmentItemDict[e.shipmentNum]) {
      return;
    }*/
    const chk = evt.target.checked;
    const idx = selectedShipmentList.indexOf(e.shipmentNum);

    //console.log('-->', chk, e);
    if (chk) {
      if (idx < 0) {
        selectedShipmentList.push(e.shipmentNum);
      }
    } else {
      if (idx > -1) {
        selectedShipmentList.splice(idx, 1);
      }
    }

    setSelectedShipmentList([...selectedShipmentList]);
  };

  const printPackages = () => {
    let labelPrinter = shipPrinter;
    //console.log('sf', shipFrom, shipPrinter, insuredValue);
    if (
      shipPrinter.printerNum === undefined ||
      shipPrinter.printerNum <= 0
    ) {
      if (typeof props.deviceSetting === 'function') {
        props.deviceSetting();
      }

      return;
    }

    //const shipServiceNum = selectedShipment ? selectedShipment.shipServiceNum : -1;
    //const warehouseNum = selectedShipment ? selectedShipment.warehouseNum : -1;
    const {
      billToType,
      billThirdPartyAccount,
      //billThirdPartyCountryNum,
      billThirdPartyPostalCode,
      cN22 : cn22,
      //dutyBillToType,
      //dutyPayorAccount,
      //dutyPayorCountryNum,
      insuredCurrency,
      shipCarrierNum,
      shipServiceNum = -1,
      shippingAccountNum,
      saturdayDelivery,
      warehouseNum = -1,
    } = (selectedShipment || {} as StringKAnyVPair);

    // if shipServiceNum is less than 0, break down
    // if warehouseNum is less than 0, break down

    const profile = userProfiles.profile;
    const pd = {
      shippingAccountNum: shippingAccountNum,
      carrierNum: shipCarrierNum,
      shipServiceNum,
      printerAccountNum: labelPrinter.printerAccountNum,
      printerName: labelPrinter.printerName,
      printerNum: labelPrinter.printerNum,
      warehouseNum,
      billToType,
      billThirdPartyAccount,
      //billThirdPartyCountryNum,
      billThirdPartyPostalCode,
      //dutyBillToType,
      //dutyPayorAccount,
      //dutyPayorCountryNum,
      insuredCurrency,
      masterAccountNum: profile.masterAccountNum,
      profileNum: profile.profileNum,
      //confirmation,
      cn22: cn22,
      saturdayDelivery,
      shipments: orderShipmentList
        .filter(e => selectedShipmentList.indexOf(e.shipmentNum) > -1)
        .map(e => ({
          shipmentNum: e.shipmentNum,
          shipPackageNum: e.shipPackageNum,
          length: e.length || 0,
          width: e.width || 0,
          height: e.height || 0,
          weight: e.weight,
          insuredValue: e.insuredValue,
        })),
    };

    console.log('pd', pd, selectedShipment);
    setPrintMsgs([]);
    setLockScreen(true);
    dispatch(printMultiPackageLabel(pd));
  };

  const renderPrintResult = () => {
    const sd: any = {};

    orderShipmentList.forEach(e => sd[e.shipmentNum] = e);

    return printMsgs.map(p => {
      const s = sd[p.id];
      const pr = !(s.result === PRINT_MESSAGE_TYPE_ERROR);

      return s ? (
        <div className="print-result-info" key={p.id}>
          <Row className="print-result-summary" justify="space-between">
            <span>{s.shipmentTitle}</span>
            <span className={pr ? 'print-suc' : 'print-fail'}>{pr ? 'Success' : 'Failure'}</span>
          </Row>
          {!pr && <Alert message={p.message} type="error" />}
        </div>
      ) : null;
    });
  };

  const setModalBodyStyle = () => {
    const wDom = wrapperRef.current;

    if (wDom) {
      wDom.parentNode.style.padding = '0';
    }
  };

  const shipmentTableRowStyle = (param: any) => {
    const { data } = param;

    if (data && typeof data === 'object') {
      if (
        data.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE ||
        data.shippingQty === data.verifyQty
      ) {
        return {
          backgroundColor: 'rgba(206, 239, 218, .3)',
        };
      } else {
        if (data.scanError) {
          return {
            backgroundColor: 'rgba(249, 152, 152, .5)',
          };
        }
      }
    }
  };

  // eslint-disable-next-line
  const tryHandlePrintEnd = () => {
    if (isPrintSuccessful()) {
      setTimeout(() => {
        setPrintStateVisible(false);

        Modal.info({
          title: 'Printing completed',
          content: <span style={{fontSize: 16}}>All packages have been printed successfully!</span>,
          onOk: () => {
            onClose();
          },
        });
      }, 1000);
    }
  };

  React.useEffect(() => {
    if (!inited) {
      const sl = getVerifiedShipments(props.orderShipmentList);

      setModalBodyStyle();
      setOrderShipmentList(sl);
      setSelectedShipmentList(sl.map(e => e.shipmentNum));
      setInited(true);

      if (sl.length > 0) {
        const rs = sl.filter(e => e.shipServiceNum > 0);
        //fillFormValueByShipment(sl[0]);
        setSelectedShipmentNum(sl[0].shipmentNum);
        //console.log('s->', props.orderShipmentList);
        if (rs.length > 0) {
          setRefShipment(rs[0]);
        }
      }
    }

    if (selectedShipmentNum > 0 && !shipmentItemDict[selectedShipmentNum]) {
      setShipmentItemDict({
        ...shipmentItemDict,
        [selectedShipmentNum]: [],
      });

      if (selectedShipment) {
        setTimeout(() => {
          getShipmentItemsById(selectedShipmentNum);
        }, 0);
      }
    }

    if (printMultiPackageResult) {
      if (Object.keys(printMultiPackageResult).length > 0) {
        console.log('mpr', printMultiPackageResult);
        setLockScreen(false);

        if (printMultiPackageResult.code === 200) {
          setPrintState(1);
          setPrintStateVisible(true);
        } else {
          if (props.openAlertDialog) {
            const msg = printMultiPackageResult.message || 'Printing packages failed';

            props.openAlertDialog({
              title: 'Print Error',
              content: msg,
            });
          }
        }

        dispatch(setPrintMultiPackageResult({}));
      }

      if (printLabelResponse) {
        if (printLabelResponse.id) {
          if (selectedShipmentList.indexOf(printLabelResponse.id) > -1) {
            console.log('prr', printLabelResponse);
            if (lockScreen) {
              setLockScreen(false);
              setPrintStateVisible(true);
            }

            printMsgs.push({...printLabelResponse});
            setPrintState(2);
            setPrintMsgs([...printMsgs]);
            tryHandlePrintEnd();
          }
        }
      }
    }
  }, [
    dispatch,
    //fillFormValueByShipment,
    getShipmentItemsById,
    inited,
    lockScreen,
    printLabelResponse,
    printMsgs,
    printMultiPackageResult,
    props,
    selectedShipment,
    selectedShipmentList,
    selectedShipmentNum,
    shipmentItemDict,
    tryHandlePrintEnd,
  ]);

  const onClose = () => {
    if (printState > 0) {
      if (props.resetOrderShipment) props.resetOrderShipment();
    }

    props.onClose();
  };

  return (<>
    <Modal
      centered
      footer={
        //<Button onClick={onClose}>Cancel</Button>
        null
      }
      maskClosable={false}
      onCancel={onClose}
      title={'Ship Multiple Packages'}
      visible={props.visible}
      width={'auto'}
    >
      <ShipMultiPackageWrapper ref={wrapperRef}>
        <div className="smp-content-box">
          <Row style={{height: '100%'}}>
            <div className="shipment-ctn">
              <DataGrid
                key={verifyRowHeight}
                columns={columns}
                dataSource={shipmentItemDict[selectedShipmentNum] || []}
                rowHeight={verifyRowHeight}
                rowKey="uuid"
                rowStyle={shipmentTableRowStyle}
                pagination={false}
                showHoverRows={false}
                //style={{ height: verifyGridHeight }}
                style={{ height: 'calc(100% - 52px)' }}
              />
              <div>
                <ShipmentTabs
                    activeKey={`${selectedShipmentNum}`}
                    moreIcon={
                      <>
                        {i18n.t('common.more')}
                        <EllipsisOutlined />
                      </>
                    }
                    onChange={handleChangeShipmentTab}
                    tabPosition="bottom"
                    type="card"
                  >
                    {tabShipmentList.map((e, i) => (
                      <ShipmentTabs.TabPane
                        tab={
                          <>
                            {selectedShipmentNum === e.shipmentNum ? (
                              <div className="ant-popover-arrow">
                                <span className="ant-popover-arrow-content" />
                              </div>
                            ) : (
                              ''
                            )}
                            <div
                              className="tab-item"
                              data-sm-verified={
                                e.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
                                  ? true
                                  : false
                              }
                              data-sm-shipped={
                                e.shippingStatus === SHIPMENT_SHIPPING_STATE
                                  ? true
                                  : false
                              }
                            >
                              <Checkbox
                                className="smp-tab-checkbox"
                                checked={selectedShipmentList.indexOf(e.shipmentNum) > -1}
                                onChange={evt => onSelectedShipmentChange(evt, e)}
                              />
                              <i className={getOrderShipmentIcon(e)} />
                              {e.shipmentTitle}
                            </div>
                          </>
                        }
                        key={e.shipmentNum}
                      />
                    ))}
                  </ShipmentTabs>
              </div>
            </div>
            <div className="label-ctn">
              <div className="shipment-prop-item">
                <span className="prop-item-label">Service</span>
                {refShipment.shippingService || ''}
              </div>
              <div className="shipment-prop-item">
                <span className="prop-item-label">Package</span>
                {refShipment.packageName || ''}
              </div>
              <div className="shipment-prop-item">
                <span className="prop-item-label">Size</span>
                {getDimension()}
              </div>
              <div className="shipment-prop-item">
                <span className="prop-item-label">Weight</span>
                {getWeightString(selectedShipment ? selectedShipment.weight : 0)}
              </div>
            </div>
            <div className={`label-ctn ${printState > 0 ? '' : 'print-idle'}`}>
              {renderPrintResult()}
              {isPrintOver() && !isPrintSuccessful() && (
                <div className="print-result-info">
                  <Alert className="print-result-alert" message="The printing task was failed" type="error" />
                </div>
              )}
            </div>
          </Row>
        </div>
        <Row
          align="middle"
          className="smp-footer"
          justify="space-between"
        >
          <div>
          </div>
          <Space>
            <Button onClick={onClose} size="large">
              <CloseOutlined />
              Close
            </Button>
            <Button
              disabled={printState === 1 || (printState === 2 && (!isPrintOver() || isPrintSuccessful())) || selectedShipmentList.length < 2}
              onClick={printPackages}
              size="large"
              type="primary"
            >
              <ButtonIcon className="bi-printer" />
              Print Labels({selectedShipmentList.length})
            </Button>
          </Space>
        </Row>
      </ShipMultiPackageWrapper>
    </Modal>
    {printStateVisible && (<Modal
      centered
      footer={null}
      maskClosable={false}
      onCancel={closePrintStateBoard}
      title={null}
      visible={printStateVisible}
      width={'auto'}
    >
      <PrintStateWrapper>
        <Row align="middle" justify="center">
        {printState === 1 && (
          <span>Printing, please wait a moment</span>
        )}
        {printState === 2 && (
          <span>{getLastPrintMsg()}</span>
        )}
        </Row>
      </PrintStateWrapper>
    </Modal>)}
    {lockScreen && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default ShipMultiPackages;
