/**
 * Zone-related service requests are here.
 */
import dayjs from 'dayjs';
import { validateHttpStatus } from 'utils';
import { digitBridgeApi } from './http';
/**
 * Fetch zone list
 * @param {string} warehouseId
 * @returns {Array<ZoneRow>}
 */
export const fetchZones = async (
  warehouseId: string
): Promise<Array<ZoneRow>> => {
  const zones = (await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/zones`
  )) as any;
  const result = zones.map((item: any) => {
    return {
      id: item.zoneNum,
      name: item.zoneName,
      description: item.zoneDesc,
      creator: item.createBy,
      locationCount: item.locationCount,
      createdAt: dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
      updater: item.updateBy,
      updatedAt: dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
    };
  });
  return result.reverse();
};
/**
 * Fetch zone
 * @param {string} warehouseId
 * @param {number} zoneId
 * @returns {ZoneRow}
 */
export const fetchZone = async (
  warehouseId: string,
  zoneId: number
): Promise<ZoneRow> => {
  const zone = (await digitBridgeApi.get(
    `/api/warehouses/${warehouseId}/zones/${zoneId}`,
    {
      validateStatus: (status) => validateHttpStatus([404], status),
    }
  )) as any;
  return {
    id: zone.zoneNum,
    name: zone.zoneName,
    description: zone.zoneDesc,
    creator: zone.createBy,
    locationCount: zone.locationCount,
    createdAt: dayjs(zone.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
    updater: zone.updateBy,
    updatedAt: dayjs(zone.updateDte, 'YYYY-MM-DDTHH:mm:00z').toDate(),
  };
};
/**
 * Fetch zone
 * @param {number} warehouseId
 * @param {number} zoneId
 * @returns {void}
 */
export const deleteZone = async (
  warehouseId: string,
  zoneId: number
): Promise<void> => {
  await digitBridgeApi.delete(
    `/api/warehouses/${warehouseId}/zones/${zoneId}`
  );
};
/**
 * Post zone
 * @param {string} warehouseId
 * @param {string} name
 * @param {string} description
 * @returns {boolean}
 */
export const postZone = async (
  warehouseId: string,
  name: string,
  description: string
): Promise<boolean> => {
  const json = JSON.stringify({ ZoneName: name, ZoneDesc: description });
  const result = (await digitBridgeApi.post(
    `/api/warehouses/${warehouseId}/zones`,
    json
  )) as any;
  if (result.error) {
    return false;
  }
  return true;
};
/**
 * Patch zone
 * @param {string} warehouseId
 * @param {string} name
 * @param {string} description
 * @returns {boolean}
 */
export const patchZone = async (
  warehouseId: string,
  zoneId: number,
  query: StringKVPair
): Promise<boolean> => {
  await digitBridgeApi.patch(
    `/api/warehouses/${warehouseId}/zones/${zoneId}`,
    JSON.stringify(query)
  );
  return true;
};
