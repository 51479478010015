import * as actionTypes from './actionTypes';

export const createWarehouse = (operation: boolean) => ({
  type: actionTypes.CREATE_WAREHOUSE,
  value: operation,
});

export const editWarehouseAddress = (
  operation: boolean,
  warehouse: WarehouseRow | null
) => ({
  type: actionTypes.EDIT_WAREHOUSE_ADDRESS,
  warehouse,
});

export const editWarehouseLocation = (
  operation: boolean,
  warehouse: WarehouseRow | null
) => ({
  type: actionTypes.EDIT_WAREHOUSE_LOCATION,
  warehouse,
});

// export const countryList = (countrys: Array<string>) => ({
//   type: actionTypes.COUNTRY_LIST,
//   countrys,
// });
export const warehouseList = (warehouses: Array<WarehouseRow>) => ({
  type: actionTypes.WAREHOUSE_LIST,
  warehouses,
});
export const tagList = (tags: Array<TagRow>) => ({
  type: actionTypes.TAG_LIST,
  tags,
});
export const userProfileSettingList = (userProfileSettings: Array<UserProfileSettingRow>) => ({
  type: actionTypes.USER_PROFILE_SETTINGS,
  userProfileSettings,
});
export const transactionReasonList = (
  transactionReasons: Array<TransactionReasonRow>
) => ({
  type: actionTypes.TRANSACTION_REASON_LIST,
  transactionReasons,
});
export const transactionTypeList = (
  transactionTypes: Array<TransactionTypeRow>
) => ({
  type: actionTypes.TRANSACTION_TYPE_LIST,
  transactionTypes,
});
export const poStatusList = (statuses: Array<PoStatusRow>) => ({
  type: actionTypes.POSTATUS_LIST,
  poStatuses: statuses,
});
export const sendPrint = (payload: any) => ({
  type: 'SEND_PRINT',
  payload,
});
export const specialWarehouseInfo = (
  defaultSpecialWarehouseInfo: {
    warehouseNum: number,
    specialWarehouseInfo: SpecialWarehouseInfo[],
  }
) => ({
  type: actionTypes.DEFAULT_SPECIAL_WAREHOSUE_INFO,
  defaultSpecialWarehouseInfo
})
export const userPermissionsList = (userPermissions: Array<UserPermissionRow>) => ({
  type: actionTypes.USE_PERMISSIONS,
  userPermissions,
});

export const setNewWindowAdminState = (adminState: AdminState) => ({
  type: actionTypes.SET_ADMIN_STATE,
  adminState,
});

export const setDatabaseNum = (databaseNum: string) => ({
  type: actionTypes.SET_DATABASENUM,
  databaseNum
})
