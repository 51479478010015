import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ReturnStock from 'components/Return/ReturnStock';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        {/*<h1>Warehouse</h1>*/}
        <ReturnStock />
      </SiteContent>
    </ContentLayout>
  );
};
