import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import SmallPackages from 'components/Sales/SmallPackagesToContainer';

function Products() {
  return (
    <ContentLayout>
      <SiteContent>
        <SmallPackages />
      </SiteContent>
    </ContentLayout>
  );
}

export default Products;
