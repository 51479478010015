import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Dropdown, Menu, Space } from 'antd';
import { setShipmentItem } from 'actions/salesAction';
import { Loading, ScreenMask, message } from 'components/common';
import {
  LOADING_ICON_SIZE1,
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_ITEM_NOT_VERIFY_STATE,
} from 'constants/config';
import { fetchOrderShipment, fetchShipmentItemsById } from 'services/sales';
import { fetchShipmentDetail } from 'services/shipment';
import ShipmentWrapper from './ShipmentWrapper';
import { ShipmentWrapperContainer } from './style';
import { ShipmentUnit } from '..';
import { DashOutlined } from '@ant-design/icons';
import CreateGroupTicketsDialog from '../OrderDetailDialog/CreateGroupTicketsDialog';

type Props = {
  orderId: string;
  selectedShipmentId?: String;
  setFocusedShipment?: Function;
  setModified: Function;
  style?: StringKAnyVPair;
  warehouseNum: number;
  showDetailButton?: boolean;
  all?: boolean;
  refresh?: boolean;
  order?: Order;
};

export type ShipmentListItem = {
  editItemCount: number;
  editShipmentCount: number;
  isExpanded: boolean;
  isFocused: boolean;
  isItemLoaded: boolean;
} & ShipmentUnit;

const ManageShipment = (props: Props) => {
  const { useMemo, useState } = React;
  const dispatch: Dispatch<any> = useDispatch();
  //const [expandAll, setExpandAll] = useState(false);
  const [inited, setInited] = useState(false);
  const [lockScreen, setLockScreen] = useState(false);
  const [shipmentList, setShipmentList] = useState<ShipmentListItem[]>([]);
  const [createGroupTicketsDialogVisible, setCreateGroupTicketsDialogVisible] = useState(false);
  const expandAll = useMemo(() => {
    return shipmentList.every((e) => e.isExpanded);
  }, [shipmentList]);
  const splitLineStyle = { border: 'none', borderBottom: '1px solid #D9D9D9' };

  const focusShipment = (shipment: ShipmentListItem, list = shipmentList) => {
    list.forEach((e) => {
      e.isFocused = e.shipmentNum === shipment.shipmentNum;

      if (e.isFocused) {
        if (typeof props.setFocusedShipment === 'function') {
          props.setFocusedShipment(e);
        }
      }
    });

    setShipmentList([...list]);
  };

  const getShipmentInList = (shipmentNum: number) => {
    const find = shipmentList.filter((e) => e.shipmentNum === shipmentNum);

    return find[0];
  };

  const loadShipment = async (shipmentNum: number) => {
    setLockScreen(true);

    const item = await fetchShipmentDetail(shipmentNum);
    let list = [...shipmentList];
    let shipment: any = null;

    setLockScreen(false);

    if (item) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].shipmentNum === shipmentNum) {
          list[i] = { ...list[i], ...item };
          shipment = list[i];
          break;
        }
      }

      if (!shipment) {
        shipment = {
          ...item,
          editItemCount: 0,
          editShipmentCount: 0,
          isExpanded: false,
          isFocused: false,
          isItemLoaded: false,
          shipmentTitle: '',
        };
        list.forEach((e, i) => (e.editShipmentCount = e.editShipmentCount + 1));
        list = [...list, shipment];
        list.forEach(
          (e, i) => (e.shipmentTitle = `Shipment ${i + 1} of ${list.length}`)
        );
      }

      setShipmentList(list);
    }

    return [shipment, list];
  };

  const loadShipmentItems = async (shipment: ShipmentListItem) => {
    const { shipmentNum } = shipment;

    try {
      const list = await fetchShipmentItemsById(shipmentNum, true);

      if (Array.isArray(list) && list.length > 0) {
        dispatch(setShipmentItem({
          items: list.map(e => ({
            ...e,
            scanError: false,
            scanMessage: '',
            selectedQty: 0,
            verifyStatus: shipment.shippingStatus === SHIPMENT_CANCELLED_STATE ? SHIPMENT_ITEM_NOT_VERIFY_STATE : e.verifyStatus,
          })),
          shipmentNum,
        }));
        //console.log('items -->', list);
      }
    } catch (e) {
      message.error(`Fetch shipment[${shipmentNum}] items: ${e}`);
    }
  };

  // eslint-disable-next-line
  const loadShipmentList = async () => {
    const { error } = message;

    setLockScreen(true);

    try {
      const selId = props.selectedShipmentId;
      const { message, shipments } = await fetchOrderShipment(
        props.warehouseNum,
        `_${props.orderId}`,
        undefined,
        props.all ? true : false,
      );
      const shipmentList = shipments.map(
        (e, i) =>
        ({
          ...e,
          editItemCount: 0,
          editShipmentCount: 0,
          isExpanded: selId && selId === e.shipmentId ? true : false,
          isFocused: false,
          isItemLoaded: false,
          shipmentTitle: `Shipment ${i + 1} of ${shipments.length}`,
        } as ShipmentListItem)
      );

      setLockScreen(false);
      setShipmentList(shipmentList);
      //console.log('sl', shipmentList);

      if (shipmentList.length === 0 && message) error(message);
    } catch (e) {
      setLockScreen(false);
      message.error(`Fetch shipment list error: ${e}`);
    }
  };

  const refreshShipment = (shipment: ShipmentListItem, list = shipmentList) => {
    //console.log('refresh', shipment);
    for (let i = 0; i < list.length; i++) {
      if (list[i].shipmentNum === shipment.shipmentNum) {
        list[i] = shipment;
        break;
      }
    }

    setShipmentList([...list]);

    return [...list];
  };

  const removeShipment = (shipment: ShipmentListItem, list = shipmentList) => {
    //console.log('remove', shipment);
    for (let i = 0; i < list.length; i++) {
      if (list[i].shipmentNum === shipment.shipmentNum) {
        list.splice(i, 1);
        break;
      }
    }

    list.forEach(
      (e, i) => (e.shipmentTitle = `Shipment ${i + 1} of ${list.length}`)
    );
    setShipmentList([...list]);

    return list;
  };

  const toggleAllShipments = async () => {
    const list = shipmentList;
    const isExpanded = !!expandAll;
    let i = 0;

    if (!isExpanded) setLockScreen(true);

    while (i < list.length) {
      if (list[i].isExpanded === expandAll) {
        list[i].isExpanded = !expandAll;

        if (!expandAll && !list[i].isItemLoaded) {
          await loadShipmentItems(list[i]);
          list[i].isItemLoaded = true;
          /*if (!list[i].isFocused) {
            list[i].editItemCount = list[i].editItemCount + 1;
          }*/
        }
        //break;
      }

      i++;
    }

    setShipmentList([...list]);

    if (!isExpanded) setLockScreen(false);
  };

  useEffect(() => {
    if (props.refresh) {
      loadShipmentList();
    }
  }, [props.refresh, loadShipmentList])

  React.useEffect(() => {
    if (!inited) {
      loadShipmentList();
      setInited(true);
    }
  }, [inited, loadShipmentList]);

  return (
    <>
      <Space>
        <Button id="toggle_all_shipments" onClick={toggleAllShipments} type="primary">
          {expandAll ? 'Collapse All' : 'Expand All'}
        </Button>
        {
          props.order && props.order.shipmentCount >= -1 && (
            <Dropdown overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => setCreateGroupTicketsDialogVisible(true)}>
                  Create Group Packing Slip
                </Menu.Item>
              </Menu>
            }>
              <Button icon={<DashOutlined />} />
            </Dropdown>
          )
        }
      </Space>
      <hr style={splitLineStyle} />
      <ShipmentWrapperContainer style={props.style || {}}>
        {shipmentList.map((e) => (
          <ShipmentWrapper
            focusShipment={focusShipment}
            getShipmentInList={getShipmentInList}
            key={e.shipmentNum}
            loadShipment={loadShipment}
            refreshShipment={refreshShipment}
            removeShipment={removeShipment}
            shipment={e}
            shipmentList={shipmentList}
            warehouseNum={props.warehouseNum}
            showDetailButton={props.showDetailButton}
          />
        ))}
      </ShipmentWrapperContainer>
      {lockScreen && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
      {createGroupTicketsDialogVisible && props.order && (
        <CreateGroupTicketsDialog
          onHide={() => setCreateGroupTicketsDialogVisible(false)}
          visible={true}
          onRefresh={() => { }}
          orderInfo={props.order}
        />
      )}
    </>
  );
};

export default ManageShipment;
