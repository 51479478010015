import { Form, Row } from 'antd';
import styled from 'styled-components';
import theme from 'assets/styles/theme';

export const PackSlipCell = styled(Row)`
  & .pdf-not-printed-icon {
    color: #D9D9D9;
  }

  & .pdf-printed-icon {
    color: ${theme['@primary-color']};
    cursor: pointer;
  }

  & .stress-text {
    color: ${theme['@danger-color']};
  }
`;

export const WrapFormItem = styled(Form.Item)`
  & .ant-form-item-label {
    display: block;
    overflow: visible;
    white-space: normal;
    height: 45px;
    text-align: right;
  }
`;