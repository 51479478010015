import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { SmartButton, SmartRow, SmartScanner } from '../../SmartComponent';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';


type DialogProps = {
  locationCode: string;
  onSubmit: (value: boolean) => void;
};
export default function OrderFinishDialog(props: DialogProps): JSX.Element {
  const { locationCode, onSubmit } = props;
  const { t } = useTranslation()
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (value?: string) => {
      if (value === locationCode) {
        onSubmit(true);
      }
    },
    [onSubmit, locationCode]
  );
  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <Text style={{ marginLeft: 5 }}>
          <span role="img" aria-label="donut" style={{ fontSize: 24 }}>
            &#128561;
          </span>
          {/* <Text>{`Do you want to reset this location [ ${locationCode} ] to Zero?`}</Text> */}
          <Text>{t("smart.audit.locationCount.resetMessage", {value: locationCode} )} </Text>

        </Text>
      </SmartRow>
    );
  };
  const copywrite = t("smart.audit.locationCount.copywrite");
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow>{copywrite}</SmartRow>
      <SmartRow style={{ marginTop: 20 }}>
        <SmartScanner onChangeValue={(value) => handleScannerChange(value)} />
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <SmartButton
          style={{ width: 200 }}
          onClick={() => {
            onSubmit(false);
          }}
        >
          {t("common.cancel")}
        </SmartButton>
      </SmartRow>
    </Modal>
  );
}
