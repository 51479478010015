import React, { useEffect, useState } from 'react';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Modal,
  Form,
  Select,
  Button,
  Row,
  Col,
  Space,
  Card,
  Checkbox,
  Collapse,
  Tag,
  Typography,
  Image,
  Table,
  Spin,
} from 'antd';
import { fetchPrinterList } from 'services/print';
import { FormLabel, message } from 'components/common';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { fetchOrderShipment } from 'services/sales';
import {
  SHIPMENT_CANCELLED_STATE,
  SHIPMENT_ITEM_NOT_VERIFY_STATE,
  SHIPMENT_SHIPPING_STATE /* shipped status */,
} from 'constants/config';
import { convertTimeByUtc, nowTime, formatPrinterToTree } from 'utils';
import { Label } from 'components/common/styles';
import fallbackImage from 'assets/images/logo192.png';
import { exportGroupSlip, previewGroupSlip } from 'services/storeSetup';
import { HTTP_STATUS_OK } from 'constants/config';
import { userProfiles } from 'utils/auth';
import { useDispatch } from 'react-redux';
import { printGroupPackingSlip } from 'actions/printActions';
import {
  shipmentStatusKeyValue as statusKeyValue,
} from 'constants/enums'

const { Panel } = Collapse;

interface CreateGroupTicketsProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  orderInfo: Order;
}

type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};

// eslint-disable-next-line
export default (props: CreateGroupTicketsProps) => {
  const { visible, onHide, orderInfo } = props;
  const [loading, setLoading] = useState(false);
  const [refreshPrinter, setRefeshPrinter] = React.useState(true);
  // const [refreshTemplate, setRefeshTemplate] = React.useState(true);
  const [printerList, setPrinterList] = React.useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = React.useState<OptionsTree[]>();
  // const [printTemplateList, setPrintTemplateList] = React.useState<
  //   PrintTemplate[]
  // >([]);
  const [shipmentList, setShipmentList] = useState<OrderShipment[]>([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);

  const ref = React.useRef<any>(null);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const columns = [
    {
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      title: '',
      render(url: string) {
        return (
          <Row align="middle" justify="center">
            <Image
              fallback={fallbackImage}
              width={44}
              height={44}
              preview={false}
              src={url || 'error'}
            />
          </Row>
        );
      },
    },
    {
      dataIndex: 'sku',
      key: 'title',
      title: '',
      render(sku: string, data: StringKAnyVPair) {
        return (
          <Row align="middle" justify="start">
            <Col>
              <Row>
                <Label className="label-bold">{data.title}</Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">SKU: </span>
                  <span className="label-grey">{data.sku}</span>
                </Label>
              </Row>
              <Row>
                <Label>
                  <span className="label-bold">UPC: </span>
                  <span className="label-grey">{data.upc}</span>
                </Label>
              </Row>
            </Col>
          </Row>
        );
      },
    },
    {
      dataIndex: 'itemTitle',
      key: 'skuTitle',
      title: 'Title',
      width: '30%',
    },
    {
      align: 'center' as 'center',
      dataIndex: 'shippingQty',
      key: 'shippingQty',
      title: 'Remaining Qty',
      render(_: any, data: StringKAnyVPair) {
        return {
          children: data.shippingQty,
          props: {
            style: { fontSize: 22 },
          },
        };
      },
    },
  ];

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list.map(i=> Number(i)));
    setIndeterminate(!!list.length && list.length < shipmentList.length);
    setCheckAll(list.length === shipmentList.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(
      e.target.checked
        ? shipmentList
            .filter((j) => j.shippingStatus !== 16 && j.shippingStatus !== 32)
            .map((i) => i.shipmentNum)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  const getPrinterList = React.useCallback(async () => {
    try {
      const res = await fetchPrinterList();
      setRefeshPrinter(false);
      if (res.isSuccess) {

        setPrinterList(res.data);
        setPrinterTree(formatPrinterToTree(res.data));
      } else {
        message.error({
          content: res.message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
      console.log(err);
    }
  }, []);

  const exportPDF = async () => {
    // const { errorFields } = await form.validateFields();
    // if (errorFields) return;
    if (checkedList.length === 0) return;
    setExportLoading(true);
    try {
      const res = await exportGroupSlip({
        fulfillmentOrderNum: orderInfo.fulfillmentOrderNum,
        shipmentNums: checkedList.toString(),
        Time: convertTimeByUtc(nowTime()),
      });
      const content = res;
      const blob = new Blob([content], { type: 'application/pdf' });
      const fileName = `Group_packing_Slip_${orderInfo.fulfillmentOrderNum}.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('err = ', error);
    }
    setExportLoading(false);
  };

  const preview = async () => {
    if (checkedList.length === 0) return;
    setPreviewLoading(true);
    try {
      const previewRes = await previewGroupSlip({
        fulfillmentOrderNum: orderInfo.fulfillmentOrderNum,
        shipmentNums: checkedList.toString(),
        Time: convertTimeByUtc(nowTime()),
      });

      if (
        typeof previewRes === 'object' &&
        typeof previewRes.code === 'number' &&
        previewRes.code !== HTTP_STATUS_OK
      ) {
        if (previewRes.message) {
          message.info(previewRes.message);
        }
      } else {
        const w = window.open('about:blank');
        w?.document.write(previewRes);
      }
    } catch (err) {
      console.log(err);
    }
    setPreviewLoading(false);
  };

  const print = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    if (!formValues.PrinterNum) {
      message.warning({ content: 'Please select a printer' });
      return;
    }

    setPrintLoading(true);
    try {
      const profile = userProfiles.profile;
      const selectedPrinter = printerList.filter(
        (item) => item.printerNum === formValues.PrinterNum
      )[0];
      const params = {
        fulfillmentOrderNum: orderInfo.fulfillmentOrderNum,
        shipmentNums: checkedList,
        Time: convertTimeByUtc(nowTime()),
        PrinterAccountNum: selectedPrinter.printerAccountNum,
        PrinterNum: selectedPrinter.printerNum,
        ProfileNum: profile.profileNum,
        User: profile.email,
        MasterAccountNum: profile.masterAccountNum,
        // Time: moment().format('YYYY-MM-DD hh:mm:ss')
      };
      dispatch(printGroupPackingSlip(params));
    } catch (err) {
      console.log(err);
    }
    setPrintLoading(false);
  };

  const isShipmentEditable = (shipment: OrderShipment) => {
    return (
      shipment.shippingStatus !== SHIPMENT_SHIPPING_STATE &&
      shipment.shippingStatus !== 32
    );
  };

  const getOrderShipmentStatusLabel = (shipment: OrderShipment) => {
    let ret = 'Shipped';

    if (isShipmentEditable(shipment)) {
      ret =
        shipment.shippingStatus === SHIPMENT_CANCELLED_STATE
          ? 'Cancelled'
          : statusKeyValue[shipment.shippingStatus];
    }

    if (shipment.shippingStatus === 32) {
      ret = 'Abandoned';
    }

    return ret;
  };

  const getOrderShipmentIcon = (shipment: OrderShipment) => {
    let ret = 'bi-printer';

    if (shipment.shippingStatus === SHIPMENT_SHIPPING_STATE) {
      ret = 'bi-truck';
    } else if (shipment.shippingStatus === SHIPMENT_CANCELLED_STATE) {
      ret = 'bi-folder-x';
    } else {
      if (shipment.verifyStatus === SHIPMENT_ITEM_NOT_VERIFY_STATE) {
        ret = 'bi-upc-scan';
      }
    }

    return ret;
  };

  const getShipmentTitleTooltip = (shipment: OrderShipment) => {
    const { shippingCarrier, shippingService, trackingNumber, actualShipDate } =
      shipment;
    const tips = [
      shippingCarrier ? `Carrier: ${shippingCarrier}` : null,
      shippingService ? `Service: ${shippingService}` : null,
      trackingNumber ? `Tracking Number: ${trackingNumber}` : null,
      actualShipDate
        ? `Ship Date: ${convertTimeByUtc(shipment.actualShipDate)}`
        : null,
    ];

    return tips.filter((e) => e).join(', ');
  };

  const loadShipmentList = async () => {
    setLoading(true);
    try {
      const { shipments } = await fetchOrderShipment(
        orderInfo.warehouseNum,
        `__${orderInfo.fulfillmentOrderId}_`,
        undefined,
        true
      );

      setLoading(false);
      setShipmentList(shipments);
    } catch (e) {
      setLoading(false);
      message.error(`Fetch shipment list error: ${e}`);
    }
  };

  /* eslint-disable */
  useEffect(() => {
    loadShipmentList();
  }, []);

  useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }

    if (props.visible) {
      document.body.style.overflowY = 'hidden';
    }
  }, [getPrinterList, props, refreshPrinter]);

  // useEffect(() => {
  //   if (refreshTemplate) {
  //     getPrinterTemplateList();
  //     setRefeshTemplate(false);
  //   }
  // }, [getPrinterTemplateList, refreshTemplate]);

  /* eslint-enable */

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title="Create Group Packing Slip"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      bodyStyle={{ height: 450, overflowY: 'auto' }}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="center">
          <Space>
          {false &&<Button
              loading={previewLoading}
              disabled={checkedList.length === 0}
              onClick={() => preview()}
            >
              Preview
            </Button>}
            <Button
              loading={printLoading}
              disabled={checkedList.length === 0}
              onClick={() => print()}
            >
              Print
            </Button>
            <Button
              loading={exportLoading}
              disabled={checkedList.length === 0}
              onClick={() => exportPDF()}
            >
              Export to PDF
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form form={form} hideRequiredMark {...layout}>

          <Form.Item label={<FormLabel>Select Printer</FormLabel>}>
            <Form.Item
              name="PrinterNum"
              rules={[
                {
                  required: true,
                  message: 'Please select a printer',
                },
              ]}
              colon={false}
              label={
                <Button
                  id="refresh_printer_button"
                  icon={
                    <ReloadOutlined
                      spin={refreshPrinter}
                      style={{ fontSize: 12, color: 'green' }}
                    />
                  }
                  onClick={() => setRefeshPrinter(true)}
                />
              }
            >
              <Select
                id="printer_select"
                ref={ref}
                placeholder="Select a printer"
                optionLabelProp="label"
                style={{width: 350}}
              >
                {printerTree?.map((item) => {
                  return (
                    <Select.OptGroup
                      key={item.label}
                      label={item.label}
                    >
                      {item.options.map((citem: any) => {
                          return (
                            <Select.Option
                              key={citem.value}
                              value={citem.value}
                              label={`${item.label} - ${citem.label}`}
                            >
                              {citem.label}
                            </Select.Option>
                          );
                        })
                        }
                    </Select.OptGroup>
                  );
                })}
              </Select>
            </Form.Item>
          </Form.Item>

          <Card
            size="small"
            title={
              <Checkbox
                indeterminate={indeterminate}
                disabled={shipmentList.filter(i=> i.shippingStatus !== 16 && i.shippingStatus !== 32).length ===0 }
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
            }
            bodyStyle={{ height: 260, overflowY: 'auto' }}
          >
            <Checkbox.Group onChange={onChange} value={checkedList}>
              {shipmentList.map((item, index) => (
                <Row key={item.shipmentNum} justify="start" style={{ marginBottom: 4 }}>
                  <Checkbox
                    disabled={
                      item.shippingStatus === 16 || item.shippingStatus === 32
                    }
                    style={{ marginTop: 15 }}
                    key={item.shipmentNum}
                    value={item.shipmentNum}
                  />
                  <Collapse style={{ width: 800, marginLeft: 8 }}>
                    <Panel
                      header={
                        <Space className="wrapper-space" style={{width: '100%', overflowX: 'auto'}}>
                          <Space size="small">
                            <i className={getOrderShipmentIcon(item)} />
                            <Typography.Text style={{ whiteSpace: 'nowrap'}}>{`Shipment ${index + 1} of ${shipmentList.length}`}</Typography.Text>
                          </Space>
                          <Tag color="#2db7f5">
                            {getOrderShipmentStatusLabel(item)}
                          </Tag>
                          <Typography.Text
                            ellipsis={{
                              tooltip: getShipmentTitleTooltip(item),
                            }}
                            // style={{ width: getShipmentTitleWidth(shipment) }}
                          >
                            {item.actualShipDate && (
                              <Tag color="purple">
                                Ship Date:{' '}
                                {convertTimeByUtc(item.actualShipDate)}
                              </Tag>
                            )}
                            {item.shippingCarrier && (
                              <Tag color="cyan">
                                Carrier: {item.shippingCarrier}
                              </Tag>
                            )}
                            {item.shippingService && (
                              <Tag color="geekblue">
                                Service: {item.shippingService}
                              </Tag>
                            )}
                            {item.trackingNumber && (
                              <Tag color="volcano">
                                Tracking Number: {item.trackingNumber}
                              </Tag>
                            )}
                          </Typography.Text>
                        </Space>
                      }
                      key="1"
                    >
                      <Table
                        columns={columns}
                        dataSource={item.items}
                        rowKey={(row) => row.shipmentLineNum}
                        pagination={false}
                      />
                    </Panel>
                  </Collapse>
                </Row>
              ))}
            </Checkbox.Group>
          </Card>
        </Form>
      </Spin>
    </Modal>
  );
};
