/**
 * channel account address service requests are here.
 */
import { digitBridgeApi } from './http';


export const getChannelAccountAddress = async (id: number): Promise<any> => {
  const params = {
    channelAccountNum: id
  }
  return await digitBridgeApi.get('/api/GetChannelAccountAddressList', { params });
}

export const addChannelAccountAddress = async (params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/AddChannelAccountAddress', json);
}

export const deleteChannelAccountAddress = async (id: number): Promise<any> => {
  return await digitBridgeApi.delete(`/api/channelAccountAddress/${id}`);
}


export const editChannelAccountAddress = async (params: any): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/UpdateChannelAccountAddress', json);
}
