import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import AuditBatch from 'components/Inventory/AuditBatch';

const BrandManage = () => {
  return (
    <ContentLayout>
      <SiteContent>
        <AuditBatch />
      </SiteContent>
    </ContentLayout>
  );
};

export default BrandManage;
