import React, { useEffect, useRef, useState } from "react";
import { Input, InputProps } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import debounce from 'lodash.debounce';

export type FilteredLabelItem = {
  label: string;
  value: any;
}

type DescriptionsFilterProps = {
  dataSource: any;
  // setFilteredData: (filteredData: any[]) => void;
  setFilteredLabels: (filteredLabels: FilteredLabelItem[]) => void;
  labels: FilteredLabelItem[];
  inputProps?: InputProps;
}
const DescriptionsFilter = (props: DescriptionsFilterProps) => {
  const { dataSource, setFilteredLabels, labels = [], inputProps } = props;
  const [filterValue, setFilterValue] = useState<string>();
  const ref = useRef<any>(null);

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  useEffect(() => {
    if (filterValue) {
      const temp: FilteredLabelItem[] = [];
      labels.forEach(label => {
        if ((label.label.toString() || '').toLowerCase().indexOf((filterValue || '').trim().toLowerCase()) > -1) temp.push(label);
      })
      setFilteredLabels([...temp]);
    } else {
      setFilteredLabels([...labels]);
    }
    // eslint-disable-next-line
  }, [filterValue]);

  useEffect(() => {
    ref.current.setState({ value: '' });
    setFilteredLabels(labels);
    // eslint-disable-next-line
  }, [dataSource])

  return (
    <Input
      ref={ref}
      onChange={e => debounceFn(e.target.value)}
      {...inputProps}
      prefix={<FilterOutlined />}
      style={{ width: 150 }}
      placeholder="Filter"
      allowClear
    />
  )
}

export default DescriptionsFilter;