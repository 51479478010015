import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Space,
  Typography,
  Form,
  Input,
  Table,
} from 'antd';
import { leaveEditForm } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

const { Text } = Typography;
type DataItem = {
  id: string;
  sku: string;
  title?: string;
  receivingQty: number;
  receivingLocationCode: string;
  receivingLocationNum: number;
  validsku: boolean;
  validLocation: boolean;
  validQty: boolean;
};
type Props = {
  onHide: () => void;
  onSubmit: (items: DataItem[], str: string) => void;
  defaultStr: string;
  lines: any[];
  locations: any[];
};

// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, onSubmit, lines, defaultStr, locations } = props;
  const [data, setData] = useState<DataItem[]>([]);
  const [str, setStr] = useState(defaultStr);
  const [form] = Form.useForm();

  const checkStr = useCallback(
    (str: string) => {
      const temp = str.split('\n');
      const tempArr: DataItem[] = [];
      temp.map((i, index) => {
        const [str1, str2, str3] = i.split('\t');
        if (str1 && str2 && str3) {
          const skuArr = lines.filter((k) => k.sku === str1 || k.upc === str1);
          const locationArr = locations.filter((j) => j.locationCode === str3);
          const isInvalidNumber =
            Number(str2) > 0 && Number.isInteger(Number(str2));
          tempArr.push({
            id: `row${index}`,
            sku: skuArr.length > 0 ? skuArr[0].sku : str1,
            title: skuArr.length > 0 ? skuArr[0].title : '',
            receivingQty: isInvalidNumber ? Number(str2) : NaN,
            receivingLocationCode: str3,
            receivingLocationNum:
              locationArr.length > 0 ? locationArr[0].locationNum : 0,
            validsku: skuArr.length > 0,
            validLocation: locationArr.length > 0,
            validQty: isInvalidNumber,
          });
        }
        return true;
      });
      setData(tempArr);
    },
    [lines, locations]
  );

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    const validData: DataItem[] = [];
    data
      .filter((i) => i.validsku && i.validLocation && i.validQty)
      .map((k) => {
        const id = `${k.sku}_${k.receivingLocationCode}`;
        const index = validData.findIndex((l) => l.id === id);
        if (index > -1) {
          validData[index] = {
            ...validData[index],
            receivingQty: validData[index].receivingQty + k.receivingQty,
          };
        } else {
          validData.push({
            ...k,
            id,
          });
        }
        return true;
      });
    onSubmit(validData, str);
  }, [onSubmit, data, str]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  useEffect(() => {
    if (defaultStr) {
      checkStr(defaultStr);
    }
  }, [defaultStr, checkStr]);

  const columns: ColumnsType<any> = [
    {
      title: 'SKU / UPC',
      dataIndex: 'sku',
      key: 'sku',
      render: (text, record) =>
        record.validsku ? (
          <Text>{text}</Text>
        ) : (
          <Text style={{ color: '#ff4d4f' }} delete>
            {text}
          </Text>
        ),
    },
    {
      title: 'Receiving Qty',
      dataIndex: 'receivingQty',
      key: 'qty',
      render: (text, record) =>
        record.validQty ? (
          <Text>{text}</Text>
        ) : (
          <Text style={{ color: '#ff4d4f' }} delete>
            {text}
          </Text>
        ),
    },
    {
      title: 'Receipt Location',
      dataIndex: 'receivingLocationCode',
      key: 'loacation',
      render: (text, record) =>
        record.validLocation ? (
          <Text>{text}</Text>
        ) : (
          <Text style={{ color: '#ff4d4f' }} delete>
            {text}
          </Text>
        ),
    },
  ];

  return (
    <Modal
      title="Copy From Excel"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={data.length === 0}
              onClick={handleSubmit}
              icon={<CheckOutlined />}
            >
              Continue
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Row style={{ marginBottom: 8 }}>
        <Space align="center">
          <Input.TextArea
            style={{ width: 550 }}
            placeholder="Please paste the excel cells content"
            rows={16}
            value={str}
            onChange={(e) => setStr(e.target.value)}
          />
          <Button
            type="primary"
            disabled={str.length === 0}
            onClick={() => {
              checkStr(str);
            }}
          >
            Process
          </Button>
        </Space>
      </Row>
      <Table
        rowKey="id"
        scroll={{ y: 322 }}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </Modal>
  );
};
