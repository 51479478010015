import React, { useState } from 'react';
import { Row, Modal, Button, Space, Image, Col, Tooltip } from 'antd';
import upsPic from 'assets/images/ups.png';
import fedExPic from 'assets/images/fedEx.png';
import uspsPic from 'assets/images/usps.png';
import purolatorPic from 'assets/images/logotrans.gif';
import otherShipping from 'assets/images/otherShipping.png';
import dhlPic from 'assets/images/dhl.png';
import { CloseOutlined } from '@ant-design/icons';
import { ButtonIcon } from 'components/common/styles';

interface CreateLotProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: (selected: number) => void;
  isShow: boolean;
}

const SelectCarrierDialog = (props: CreateLotProps) => {
  const { onHide, onRefresh, visible, isShow } = props;
  const [selected, setSelected] = useState(1);

  return (
    <Modal
      title="Select a carrier"
      centered
      visible={visible}
      maskClosable={false}
      width={900}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="select_carrier_button"
              key="submit"
              type="primary"
              onClick={() => onRefresh(selected)}
            >
              <ButtonIcon className="bi-hand-index-thumb" />
              Select
            </Button>
            <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Row>
        <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="FedEx">
            <Image
              id="fedex_img"
              preview={false}
              src={fedExPic}
              alt="FedEx"
              placeholder="FedEx"
              onClick={() => setSelected(1)}
              width={100}
              style={selected === 1 ? { border: '1px solid #006dff' } : {}}
            />
          </Tooltip>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="UPS">
            <Image
              id="ups_img"
              preview={false}
              src={upsPic}
              placeholder="UPS"
              onClick={() => setSelected(2)}
              width={100}
              alt="UPS"
              style={selected === 2 ? { border: '1px solid #006dff' } : {}}
            />
          </Tooltip>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="USPS">
            <Image
              id="usps_img"
              preview={false}
              width={100}
              src={uspsPic}
              placeholder="USPS"
              alt="USPS"
              onClick={() => setSelected(3)}
              style={selected === 3 ? { border: '1px solid #006dff' } : {}}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="Purolator">
            <div style={selected === 4 ? { border: '1px solid #006dff' } : {}}>
              <Image
                id="purolator_img"
                preview={false}
                placeholder="Purolator"
                width={100}
                src={purolatorPic}
                alt="Purolator"
                onClick={() => setSelected(4)}
              />
            </div>
          </Tooltip>
        </Col>
        {isShow && <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="DHL">
          <div style={selected === 5 ? { border: '1px solid #006dff' } : {}}>
            <Image
              id="dhl_img"
              preview={false}
              width={100}
              src={dhlPic}
              placeholder="DHL"
              alt="DHL"
              onClick={() => setSelected(5)}
            />
            </div>
          </Tooltip>
        </Col>}
        <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip placement="rightTop" title="Other">
            <div
              style={
                selected === -1
                  ? {
                      border: '1px solid #006dff',
                      width: 100,
                      textAlign: 'center',
                    }
                  : { width: 100, textAlign: 'center' }
              }
            >
              <Image
                id="other_img"
                preview={false}
                src={otherShipping}
                alt="Other"
                onClick={() => setSelected(-1)}
                style={{ height: 50 }}
              />
            </div>
          </Tooltip>
        </Col>
      </Row>
    </Modal>
  );
};

export default SelectCarrierDialog;
