import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { DataGridPlus, ColumnDataType, ButtonIcon } from 'components/common';
import { DATE_TIME_NO_SECOND_FORMAT } from 'constants/config';
import ModalDialog from '../../../components/common/ModalDialog';
import RomoteDetail from './RemoteDetailDialog';
import { formatAddress, checkSqlmintime } from 'utils';
import moment from 'moment'

const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

type Props = {
  data: any[];
  warehouseId: string;
  warehouseCode: string;
  onHide: () => void;
  onRefresh: () => void;
  visible: boolean;
  onReceive: Function;
  onRestock: Function;
  onComplete: Function;
  onReceivePlus: Function;
  changeCurrent: Function;
  onShippingLabel: Function;
  // onExpressReceive: Function;
  onExpressReceiveWithNoReturnQty: Function;
  // onRefreshRma: Function;
  // onDeleteRma: Function;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { data, onRefresh, warehouseId, warehouseCode, onReceive, onRestock, onComplete, onReceivePlus, changeCurrent, onShippingLabel, onExpressReceiveWithNoReturnQty } = props;
  const [current, setCurrent] = useState<any>();
  const [detailVisible, setDetailVisible] = useState(false);
  const [isCreate, setIsCreate] = useState(false)


  const closeProductDetailDialog = () => {
    props.onHide();
  };


  const columns = [
    {
      header: 'Ship to Name',
      name: 'shipToName',
      userSelect: true,
      sortable: true,
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data } = value;
      //   return data.shipToName || data.endBuyerName;
      // },
    },
    {
      header: 'Ship to Address',
      name: 'shipToAddressLine1',
      userSelect: true,
      sortable: true,
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return formatAddress('row', '', '', data.shipToAddressLine1, data.shipToAddressLine2, data.shipToAddressLine3, data.shipToCity, data.shipToState, data.shipToPostalCode, data.shipToCountry)
      },
    },
    {
      header: 'RMA#',
      name: 'returnNo',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'SO #',
      name: 'salesOrderNumber',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Channel Order ID',
      name: 'channelOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Channel Account Name',
      name: 'channelAccountName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Invoice',
      name: 'invoiceNo',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
    },
    {
      header: 'Invoice Total Qty',
      name: '',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        let temp = 0;
        (data.items || []).map((i: any) => {
          temp = temp + i.shipQty;
          return true;
        });
        return temp
      },
    },
    {
      header: 'Invoice Date',
      name: 'invoiceDate',
      resizable: true,
      draggable: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Order Qty',
      name: 'orderQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Claim Qty',
      name: 'returnQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Receive Qty',
      name: 'receiveQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Receive Date',
      name: 'receiveDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Receive By',
      name: 'receiveBy',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Status',
      name: 'status',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return statusEnum[`${data.status}`];
      },
    },
    {
      header: 'Enter Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // dataType: ColumnDataType.DATE,
      // format: DATE_TIME_NO_SECOND_FORMAT,
      render: (value: any) => {
        const { data } = value;
        return data.returnNo ? moment(data.createDate).format(DATE_TIME_NO_SECOND_FORMAT) : ''
      },
    },
    {
      header: 'Return Date',
      name: 'transDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      // dataType: ColumnDataType.DATE,
      // format: DATE_TIME_NO_SECOND_FORMAT,
      render: (value: any) => {
        const { data } = value;
        return data.transDate ? checkSqlmintime(data.transDate) ?  '' : moment(data.transDate).format(DATE_TIME_NO_SECOND_FORMAT) : ''
      },
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      minWidth: 270,
      maxWidth: 270,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return (
          <Space>
            {/* <Button
              id="grid_remote_detail_button"
              onClick={() => {
                setCurrent(data);
                changeCurrent(data);
                setDetailVisible(true);
              }}
              type="primary"
              icon={<ButtonIcon className="bi bi-file-earmark-text" />}
            >
              Detail
            </Button> */}
            <Button
              id="grid_remote_download_button"
              disabled={!data.returnNo}
              onClick={() => {
                setCurrent(data);
                changeCurrent(data);
                setIsCreate(false)
                setDetailVisible(true);
              }}
              type="primary"
              icon={<ButtonIcon className="bi bi-file-earmark-text" />}
            >
              Download
            </Button>
            <Button
              id="grid_remote_create_new_button"
              // disabled={data.returnNo}
              onClick={() => {
                setCurrent(data);
                changeCurrent(data);
                setIsCreate(true)
                setDetailVisible(true);
              }}
              type="primary"
              icon={<ButtonIcon className="bi bi-file-earmark-text" />}
            >
              Create New
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        // onFullscreen={onFullscreen}
        title="Search ERP"
        visible={props.visible}
      // width={detailDialogWidth()}
      >
        <DataGridPlus
          columns={columns}
          dataSource={data}
          idProperty="sku"
          pagination="local"

          {...({} as any)}
          checkboxOnlyRowSelect={true}
        />
        {detailVisible && current && (
          <RomoteDetail
            warehouseCode={warehouseCode}
            warehouseId={warehouseId}
            dialogData={current}
            isCreate={isCreate}
            onHide={() => setDetailVisible(false)}
            onRefresh={() => {
              setDetailVisible(false);
              onRefresh();
            }}
            onReceive={(data: any) => {
              setDetailVisible(false)
              onReceive(data);
            }}
            onRestock={(data: any) => {
              setDetailVisible(false);
              onRestock(data);
            }}
            onComplete={(data: any) => {
              setDetailVisible(false);
              onComplete(data);
            }}
            onReceivePlus={(data: any) => {
              setDetailVisible(false);
              onReceivePlus(data);
            }}
            onShippingLabel={(data: any) => {
              // setDetailVisible(false)
              onShippingLabel(data);
            }}
            // onExpressReceive={(data: any)=>{
            //   setDetailVisible(false);
            //   onExpressReceive(data)
            // }}
            onExpressReceiveWithNoReturnQty={(data: any)=>{
              setDetailVisible(false);
              onExpressReceiveWithNoReturnQty(data)
            }}
            // onDeleteRma={(data: any) => {
            //   onDeleteRma(data)
            // }}
            // onRefreshRma={(data: any) => {
            //   onRefreshRma(data)
            // }}    
          />
        )}
      </ModalDialog>
    </>
  );
};
