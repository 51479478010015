import React, { useEffect, useRef, useState } from 'react';
import { fetchZones } from 'services/zone';
import { MultipleSelect } from 'components/common';

type MultipleSelectZonesProps = {
  width: number;
  warehouse: WarehouseRow | undefined;
  onChange?: (data: Array<ZoneRow> | undefined) => void;
};
export default function MultipleSelectZones(
  props: MultipleSelectZonesProps
): JSX.Element {
  const { warehouse, width, onChange } = props;
  const [zones, setZones] = useState<Array<ZoneRow>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const warehouseIdRef = useRef(null);
  useEffect(() => {
    const fetch = async () => {
      if (warehouse && warehouseIdRef.current !== warehouse.id) {
        setLoading(true);
        warehouseIdRef.current = warehouse.id as any;
        const newZones = await fetchZones(warehouse.warehouseId);
        setZones(newZones);
        setLoading(false);
      }
    };
    fetch();
  }, [warehouse, onChange]);
  return (
    <MultipleSelect
      loading={loading}
      width={width}
      // value={value || zones}
      labelField="name"
      valueField="id"
      data={zones}
      onChange={(data) => {
        if (data.length === zones.length) {
          if (typeof onChange === 'function') {
            onChange(undefined);
          }
        } else {
          if (typeof onChange === 'function') {
            onChange(data);
          }
        }
      }}
    />
  );
}
MultipleSelectZones.defaultProps = {
  onChange: undefined,
};
