import { isBooleanAction } from 'utils';
import * as actionTypes from 'actions/actionTypes';
import { LS_DEFAULT_WAREHOUSE_KEY, LS_DEFAULT_WAREHOUSE_CODE, LS_DEFAULT_WAREHOUSE_WAREHOUSEID } from 'constants/config';

const initialState: AdminState = {
  editingWarehouse: null,
  isCreatingWarehouse: false,
  isDetailWarehouseAddress: false,
  isEditingWarehouseLocation: false,
  // countrys: [],
  warehouses: [],
  transactionReasons: [],
  transactionTypes: [],
  poStatuses: [],
  tags: [],
  userProfileSettings: null,
  defaultSpecialWarehouseInfo: {
    warehouseNum: undefined,
    specialWarehouseInfo: [],
  },
  userPermissions: null,
  databaseNum: ''
};

const isEditWarehouseAction = (action: any): action is EditWarehouseAction => {
  let ret = false;

  if (action && 'object' === typeof action) {
    ret =
      'string' === typeof action.type &&
      (isWarehouseRow(action.warehouse) || action.warehouse === null);
  }

  return ret;
};

// const isCountryListAction = (action: any): action is CountryListAction => {
//   let ret = false;
//   if (action && 'object' === typeof action) {
//     ret = 'string' === typeof action.type && isCountrysRow(action.countrys);
//   }

//   return ret;
// };

const isWarehouseListAction = (action: any): action is WarehouseListAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isWarehousesRow(action.warehouses);
  }

  return ret;
};
const isPoStatusListAction = (action: any): action is PoStatusListAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isPoStatusesRow(action.poStatuses);
  }

  return ret;
};
const isTransactionReasonListAction = (
  action: any
): action is TransactionReasonListAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret =
      'string' === typeof action.type &&
      isTransactionReasonRow(action.transactionReasons);
  }

  return ret;
};
const isTransactionTypeListAction = (
  action: any
): action is TransactionTypeListAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret =
      'string' === typeof action.type &&
      isTransactionTypeRow(action.transactionTypes);
  }

  return ret;
};
const isTagListAction = (action: any): action is TagListAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isTagRow(action.tags);
  }

  return ret;
};

const isUserProfileSettingsAction = (action: any): action is UserProfileSettingsAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isProfileSettingRow(action.userProfileSettings);
  }

  return ret;
};

const isUserPermissionsAction = (action: any): action is UserPermissionsAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isPermissionRow(action.userPermissions);
  }

  return ret;
};

const isSetAdminStateAction = (action: any): action is SetAdminStateAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type && isPermissionRow(action.adminState);
  }

  return ret;
};

const isDefaultSpecialWarehouseInfoAction = (action: any): action is DefaultSpecialWarehouseInfoAction => {
  let ret = false;
  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type;
  }

  return ret;
};
/**
 * This function may be usable in the future.
const isGeneralEditAction = (action: any): action is GeneralEditAction => {
  let ret = false;

  if (action && 'object' === typeof action) {
    ret = 'string' === typeof action.type
      && 'boolean' === typeof action.edit
      && 'number' === typeof action.value;
  }

  return ret;
};*/

const isWarehouseRow = (row: any): row is WarehouseRow => {
  let ret = false;
  if (row && 'object' === typeof row) {
    ret =
      'number' === typeof row.id &&
      'string' === typeof row.code &&
      'string' === typeof row.name &&
      'boolean' === typeof row.isExternal
    // ('number' === typeof row.totalQuantity || row.totalQuantity === null) &&
    // 'boolean' === typeof row.isEnabled;
  }
  return ret;
};

// const isCountrysRow = (row: any): row is Array<string> => {
//   let ret = false;

//   if (row && 'object' === typeof row) {
//     ret = true;
//   }

//   return ret;
// };
const isWarehousesRow = (row: any): row is Array<WarehouseRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};
const isPoStatusesRow = (row: any): row is Array<PoStatusRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};
const isTransactionReasonRow = (
  row: any
): row is Array<TransactionReasonRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};
const isTransactionTypeRow = (row: any): row is Array<TransactionTypeRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};
const isTagRow = (row: any): row is Array<TagRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};

const isProfileSettingRow = (row: any): row is Array<UserProfileSettingRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};

const isPermissionRow = (row: any): row is Array<UserPermissionRow> => {
  let ret = false;

  if (row && 'object' === typeof row) {
    ret = true;
  }

  return ret;
};


/**
 * The administration reducer. Handling state for:
 * Warehouse,
 */
const reducer = (
  state: AdminState = initialState,
  action: any
): AdminState => {
  switch (action.type) {
    case actionTypes.CREATE_WAREHOUSE:
      if (isBooleanAction(action)) {
        return {
          ...state,
          isCreatingWarehouse: action.value,
        };
      }
      break;

    case actionTypes.EDIT_WAREHOUSE_ADDRESS:
      if (isEditWarehouseAction(action)) {
        return {
          ...state,
          editingWarehouse: action.warehouse,
          isDetailWarehouseAddress: !!action.warehouse,
        };
      }
      break;

    case actionTypes.EDIT_WAREHOUSE_LOCATION:
      if (isEditWarehouseAction(action)) {
        return {
          ...state,
          editingWarehouse: action.warehouse,
          isEditingWarehouseLocation: !!action.warehouse,
        };
      }
      break;
    // case actionTypes.COUNTRY_LIST:
    //   if (isCountryListAction(action)) {
    //     return {
    //       ...state,
    //       countrys: action.countrys,
    //     };
    //   }
    //   break;
    case actionTypes.WAREHOUSE_LIST:
      if (isWarehouseListAction(action)) {
        // match default warehouse. if no matched. clean default warehouse
        if (action.warehouses.filter(item => item.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))).length === 0) {
          localStorage.removeItem(LS_DEFAULT_WAREHOUSE_CODE);
          localStorage.removeItem(LS_DEFAULT_WAREHOUSE_KEY);
          localStorage.removeItem(LS_DEFAULT_WAREHOUSE_WAREHOUSEID);
        }
        return {
          ...state,
          warehouses: action.warehouses,
        };
      }
      break;
    case actionTypes.TRANSACTION_REASON_LIST:
      if (isTransactionReasonListAction(action)) {
        return {
          ...state,
          transactionReasons: action.transactionReasons,
        };
      }
      break;
    case actionTypes.TRANSACTION_TYPE_LIST:
      if (isTransactionTypeListAction(action)) {
        return {
          ...state,
          transactionTypes: action.transactionTypes,
        };
      }
      break;
    case actionTypes.POSTATUS_LIST:
      if (isPoStatusListAction(action)) {
        return {
          ...state,
          poStatuses: action.poStatuses,
        };
      }
      break;
    case actionTypes.TAG_LIST:
      if (isTagListAction(action)) {
        return {
          ...state,
          tags: action.tags,
        };
      }
      break;
    case actionTypes.USER_PROFILE_SETTINGS:
      if (isUserProfileSettingsAction(action)) {
        return {
          ...state,
          userProfileSettings: action.userProfileSettings,
        }
      }
      break;
    case actionTypes.DEFAULT_SPECIAL_WAREHOSUE_INFO:
      if (isDefaultSpecialWarehouseInfoAction(action)) {
        return {
          ...state,
          defaultSpecialWarehouseInfo: action.defaultSpecialWarehouseInfo,
        }
      }
      break;
    case actionTypes.USE_PERMISSIONS:
      if (isUserPermissionsAction(action)) {
        return {
          ...state,
          userPermissions: action.userPermissions,
        }
      }
      break;
    case actionTypes.SET_ADMIN_STATE:
      if (isSetAdminStateAction(action)) {
        return { ...action.adminState }
      }
      break;
    case actionTypes.SET_DATABASENUM:
      return {
        ...state,
        databaseNum: action.databaseNum,
      }
    default:
      break;
  }

  return state;
};

export default reducer;
