import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Modal, Row, Button, Space, Form, Select, message } from 'antd';
import { CloseOutlined, FileSearchOutlined, PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { HTTP_STATUS_OK } from 'constants/config';
import { userProfiles } from 'utils/auth';
import { fetchPrintTemplates, fetchPrinterList } from 'services/print';
import { exportBulkReturnDetailPDF, previewBulkReturnDetail } from 'services/printReturnListDetail';

import { FormLabel } from 'components/common';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { printBulkReturnDetail } from 'actions/printActions';
import { formatPrinterToTree } from 'utils';

const FormItemRow = styled.div`
  display: flex;
  flexDirection: row;
  alignItems: center
`;

type Props = {
  onHide: () => void;
  detailData?: any;
};
type OptionsItem = {
  label: string;
  value: string | number;
};
type OptionsTree = {
  label: string;
  online: boolean;
  options: OptionsItem[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, detailData } = props;
  const [exportLoading, setExportLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  // template part
  const [refreshTemplate, setRefeshTemplate] = useState(true);
  const [printTemplateList, setPrintTemplateList] = useState<PrintTemplate[]>([]);
  // printer part
  const [refreshPrinter, setRefeshPrinter] = useState(true);
  const [printerList, setPrinterList] = useState<Printer[]>([]);
  const [printerTree, setPrinterTree] = useState<OptionsTree[]>();


  const [form] = Form.useForm();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();

  const getPrinterList = useCallback(async () => {
    try {
      const { isSuccess, data = [] } = await fetchPrinterList();
      setRefeshPrinter(false);
      if (isSuccess) {
        setPrinterList(data);
        setPrinterTree(formatPrinterToTree(data));
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshPrinter(false);
    }
  }, []);

  const getPrinterTemplateList = useCallback(async () => {
    try {
      const profile = userProfiles.profile;
      const { isSuccess, data = [], message } = await fetchPrintTemplates({
        TemplateType: 11,
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      });
      setRefeshTemplate(false);
      if (isSuccess) {
        setPrintTemplateList(data || []);
        if (data.length > 0) {
          form.setFieldsValue({ PrintTemplateNum: data[0].printTemplateNum })
        }
      } else {
        message.error({
          content: message,
        });
      }
    } catch (err) {
      setRefeshTemplate(false);
    }
  }, [form]);

  useEffect(() => {
    if (refreshPrinter) {
      getPrinterList();
    }
  }, [getPrinterList, refreshPrinter]);

  useEffect(() => {
    if (refreshTemplate) {
      getPrinterTemplateList();
      setRefeshTemplate(false);
    }
  }, [getPrinterTemplateList, refreshTemplate]);


  // Export Return:  GET api/RMA/Export?FulfillmentReturnNum=10071&PrintTemplateNum=9000
  const exportPDF = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    const { fulfillmentBulkReturnNum, fulfillmentBulkReturnId = '' } = detailData;
    const { PrintTemplateNum } = formValues;
    setExportLoading(true);
    try {
      const res = await exportBulkReturnDetailPDF({
        FulfillmentBulkReturnNum: fulfillmentBulkReturnNum,
        PrintTemplateNum,
      })
      const content = res;
      const blob = new Blob([content], { type: 'application/pdf' });
      const fileName = `BulkReturn_${fulfillmentBulkReturnId}.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('err = ', error);
    }
    setExportLoading(false);
  }

  /**
   * Preview  Return:  POST api/RMA/Preview   ,
      Body: 
        {
            "FulfillmentReturnNum": 10071,
            "PrintTemplateNum":9000
        }
   */
  const preview = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    const { fulfillmentBulkReturnNum } = detailData;
    const { PrintTemplateNum } = formValues;
    setPreviewLoading(true);
    try {
      const previewRes = await previewBulkReturnDetail({
        FulfillmentBulkReturnNum: fulfillmentBulkReturnNum,
        PrintTemplateNum
      })

      if (
        typeof previewRes === 'object' &&
        typeof previewRes.code === 'number' &&
        previewRes.code !== HTTP_STATUS_OK
      ) {
        if (previewRes.message) {
          message.info(previewRes.message);
        }
      } else {
        const w = window.open('about:blank');
        w?.document.write(previewRes);
      }

    } catch (err) {
      console.log(err);
    }
    setPreviewLoading(false);
  }

  const print = async () => {
    const { errorFields } = await form.validateFields();
    if (errorFields) return;
    const formValues = await form.getFieldsValue();
    if (!formValues.PrinterNum) {
      message.warning({ content: 'Please select a printer' });
      return;
    }

    const { fulfillmentBulkReturnNum } = detailData;
    setPrintLoading(true);
    try {
      const profile = userProfiles.profile;
      const selectedPrinter = printerList.filter(
        (item) => item.printerNum === formValues.PrinterNum
      )[0];
      const params = {
        ...formValues,
        FulfillmentBulkReturnNum: fulfillmentBulkReturnNum,
        PrinterAccountNum: selectedPrinter.printerAccountNum,
        ProfileNum: profile.profileNum,
        User: profile.email,
        MasterAccountNum: profile.masterAccountNum,
        Time: moment().format('YYYY-MM-DD hh:mm:ss')
      }
      dispatch(printBulkReturnDetail(params))
    } catch (err) {
      console.log(err);
    }
    setPrintLoading(false);
  }

  return (
    <Modal
      title="Print"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={onHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button id="close_button" onClick={onHide} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
        </Row>
      }
    >
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
        <Form.Item
          label={
            <FormItemRow>
              <FormLabel>Select A Template</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{ fontSize: 12, color: 'green' }}
                  />
                }
                onClick={() => setRefeshTemplate(true)}
              />
            </FormItemRow>
          }
          name="PrintTemplateNum"
          rules={[
            {
              required: true,
              message: 'Please select a template',
            },
          ]}
        >
          <Select
            placeholder="Select a print template"
            style={{ width: 300 }}
          >
            {printTemplateList.map((item, index) => (
              <Select.Option key={index} value={item.printTemplateNum}>
                {item.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <FormItemRow>
              <FormLabel>Printer</FormLabel>
              <Button
                style={{ marginLeft: 12 }}
                icon={
                  <ReloadOutlined
                    spin={false}
                    style={{
                      fontSize: 12,
                      color: 'green',
                    }}
                  />
                }
                onClick={() => setRefeshPrinter(true)}
              />
            </FormItemRow>
          }
          name="PrinterNum"
        >
          <Select
            ref={ref}
            placeholder="Select a printer"
            style={{ width: 300 }}
            optionLabelProp="label"
          >
            {printerTree?.map((item) => {
              return (
                <Select.OptGroup
                  key={item.label}
                  label={item.label}
                >
                  {item.options.map((citem: any) => {
                      return (
                        <Select.Option
                          key={citem.value}
                          value={citem.value}
                          label={`${item.label} - ${citem.label}`}
                        >
                          {citem.label}
                        </Select.Option>
                      );
                    })
                    }
                </Select.OptGroup>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label=" "
        >
          <FormItemRow>
          {false &&<Button
              style={{ marginRight: 12 }}
              onClick={() => preview()}
              loading={previewLoading}
            >
              <FileSearchOutlined />
              Preview
            </Button>}
            <Button
              type="primary"
              style={{ marginRight: 12 }}
              loading={printLoading}
              onClick={() => print()}
            >
              <PrinterOutlined />
              Print
            </Button>
            <Button
              loading={exportLoading}
              onClick={() => exportPDF()}
            >
              Export to PDF
            </Button>
          </FormItemRow>
        </Form.Item>
      </Form>
    </Modal>
  );
};
