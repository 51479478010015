import React, { useState, useCallback } from 'react';
import { Row, Typography, Button, Col, Alert, Spin, Modal } from 'antd';
// import { Link } from 'react-router-dom';
import { fetchProducts } from 'services/product';
import SelectCodeDialog from './SelectCodeDialog';
// import Modal from '../../Modal';
import FormItem from './FormItem';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

type SelectProductProps = {
  onHide: () => void;
  onChange: (fieldName: string, product: ProductRow) => void;
};
/**
 * Return SelectProduct Dialog
 * @param {SelectProductProps} props
 * @returns {JSX.Element}
 */
export default function SelectProductDialog(
  props: SelectProductProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectCodeDialgVisible, setSelectCodeDialogVisible] =
    useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>('');
  // const [tableColumnTitle, setTableColumnTitle] = useState<string>('');
  const [products, setProducts] = useState<Array<ProductRow>>([]);
  const { onHide, onChange } = props;
  /**
   * handle search
   */
  const handleSearch = useCallback(async (name: string, key: string) => {
    // setTableColumnTitle(title);
    setFieldName(name);
    setLoading(true);
    const query = {
      $filter: `${name} cn '${key}'`,
    };
    const { products: data } = await fetchProducts(query);
    setProducts(data);
    if (data.length > 0) {
      setSelectCodeDialogVisible(true);
    }
    setLoading(false);
  }, []);
  /**
   * handle select change event
   */
  const handleChange = useCallback(
    (product: ProductRow) => {
      //close select code dialog
      setSelectCodeDialogVisible(false);
      onChange(fieldName, product);
    },
    [onChange, fieldName]
  );
  /**
   * Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        {/* <Link to="/catalog/view">
          <Button>Catalog</Button>
        </Link> */}
        <Button onClick={onHide} icon={<CloseOutlined />}>Cancel</Button>
      </Row>
    );
  };
  return (
    <>
      {selectCodeDialgVisible && (
        <SelectCodeDialog
          // title={tableColumnTitle}
          field={fieldName}
          products={products}
          onSelect={handleChange}
          onHide={() => {
            setSelectCodeDialogVisible(false);
          }}
        />
      )}
      <Modal
        // title="Search By(SKU/Title/PN)"
        title="Search By(SKU/Title)"
        centered
        visible
        width={600}
        destroyOnClose
        keyboard={false}
        onCancel={onHide}
        footer={<Footer />}
      >
        <Spin spinning={loading}>
          {fieldName.length > 0 && products.length === 0 && !loading && (
            <Row>
              <Col span={24}>
                <Alert
                  message="Failed to find items"
                  description="No matches found"
                  type="error"
                />
              </Col>
            </Row>
          )}
          <Row
            style={{
              marginTop: 10,
              marginBottom: 20,
            }}
          >
            <Text>
              Enter at least three letters to search by partial SKU or Title
            </Text>
          </Row>
          <FormItem
            label="SKU contains"
            onSearch={(value: string) => {
              handleSearch('sku', value);
            }}
          />
          <FormItem
            label="Title"
            onSearch={(value: string) => {
              handleSearch('productTitle', value);
            }}
          />
          {/* <FormItem
            label="Part Number"
            onSearch={(value: string) => {
              handleSearch('Part Number', 'partNumber', 'partNumber', value);
            }}
          /> */}
        </Spin>
      </Modal>
    </>
  );
}
