import * as actionTypes from './actionTypes';

export const loadPrinters = () => ({
  type: actionTypes.LOAD_PRINTER_LIST,
});

export const loadScales = () => ({
  type: actionTypes.LOAD_SCALE_LIST,
});

export const needSetDevice = (needed) => ({
  type: actionTypes.NEED_SET_DEVICE,
  needed,
});

export const setIsLoadingPrinterList = (isLoading) => ({
  type: actionTypes.SET_ISLOADING_PRINTER_LIST,
  isLoading,
});

export const setIsLoadingScaleList = (isLoading) => ({
  type: actionTypes.SET_ISLOADING_SCALE_LIST,
  isLoading,
});

export const setPackingPrinter = (printer) => ({
  type: actionTypes.SET_PACKING_PRINTER,
  printer,
});

export const setPackingPrinterById = (printerId) => ({
  type: actionTypes.SET_PACKING_PRINTER_BY_ID,
  printerId,
});

export const setPackingPrinter2 = (printer) => ({
  type: actionTypes.SET_PACKING_PRINTER2,
  printer,
});

export const setPackingPrinter2ById = (printerId) => ({
  type: actionTypes.SET_PACKING_PRINTER2_BY_ID,
  printerId,
});

export const setPrintCartonResult = (result) => ({
  type: actionTypes.SET_PRINT_CARTON_RESULT,
  result,
});

export const setPrintDocumentResult = (result) => ({
  type: actionTypes.SET_PRINT_DOCUMENT_RESULT,
  result,
});

export const setPrintLabelEnd = (message) => ({
  type: actionTypes.SET_PRINT_LABEL_END,
  message,
});

export const setPrintLabelMessage = (message) => ({
  type: actionTypes.SET_PRINT_LABEL_MSG,
  message,
});

export const setPrintLabelResponse = (response) => ({
  type: actionTypes.SET_PRINT_LABEL_RESPONSE,
  response,
});

export const setPrintLabelResult = (result) => ({
  type: actionTypes.SET_PRINT_LABEL_RESULT,
  result,
});

export const setPrintMultiPackageResult = (result) => ({
  type: actionTypes.SET_PRINT_MULTI_PKG_RESULT,
  result,
});

export const setPrintSlipResult = (result) => ({
  type: actionTypes.SET_PRINT_SLIP_RESULT,
  result,
});

export const setPrinterList = (printers) => ({
  type: actionTypes.SET_PRINTER_LIST,
  printers,
});

export const setPrinterListLoaded = (loaded) => ({
  type: actionTypes.SET_PRINTER_LIST_LOADED,
  loaded,
});

export const setPrintingHang = (hang) => ({
  type: actionTypes.SET_SALES_PRINTING_HANG,
  hang,
});

export const setScale = (scale) => ({
  type: actionTypes.SET_SALES_SCALE,
  scale,
});

export const setScaleById = (scaleId) => ({
  type: actionTypes.SET_SALES_SCALE_BY_ID,
  scaleId,
});

export const setScaleList = (scales) => ({
  type: actionTypes.SET_SCALE_LIST,
  scales,
});

export const setScaleListLoaded = (loaded) => ({
  type: actionTypes.SET_SCALE_LIST_LOADED,
  loaded,
});

export const setScaleWeight = (weight) => ({
  type: actionTypes.SET_SALES_SCALE_WEIGHT,
  weight,
});

export const setShipmentItem = (item) => ({
  type: actionTypes.SET_SHIPMENT_ITEM,
  item,
});

export const setShipmentPrinter = (printer) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER,
  printer,
});

export const setShipmentPrinter4x8 = (printer) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER4x8,
  printer,
});

export const setShipmentPrinterCoton = (printer) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER_COTON,
  printer,
});

export const setShipmentPrinterById = (printerId) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER_BY_ID,
  printerId,
});

export const setShipmentPrinter4x8ById = (printerId) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER4x8_BY_ID,
  printerId,
});

export const setShipmentPrinterCotonById = (printerId) => ({
  type: actionTypes.SET_SHIPMENT_PRINTER_COTON_BY_ID,
  printerId,
});

export const setTestPrintMessage = (message) => ({
  type: actionTypes.SET_TEST_PRINT_MSG,
  message,
});

export const setPrintPickListStatus = (bitInt) => ({
  type: actionTypes.SET_PRINT_PICK_LIST_STATUS,
  bitInt,
})

export const setPrintGroupPackingSlipStatus = (bitInt) => ({
  type: actionTypes.SET_PRINT_GROUP_PACKING_SLIP_STATUS,
  bitInt,
})

