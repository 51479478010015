/**
 *PurchaseOrder related service requests are here.
 */
import dayjs from 'dayjs';
import { digitBridgeApi } from './http';
/**
 * Fetch po numbers
 */
export const fetchPoNumbers = async (
  key?: string
): Promise<Array<PoNumberRow>> => {
  const params = { keyword: key };
  const list = (await digitBridgeApi.get('/api/purchaseOrders/numbers/list', {
    params,
  })) as any;
  return list.map((item: any) => {
    return {
      ...item,
      id: item.fulfillmentPoNum,
      name: item.poNumber,
    };
  });
};
/**
 * Fetch Po.
 * @param {string} poNum -- po num
 * @returns {Promise<PoRow|null>}
 */
export const fetchPo = async (poNum: string): Promise<PoRow | null> => {
  const { data } = await fetchPoList({
    filterFieldName: 'poNumber', filterKey: poNum, poStatusNum: -1, sortBy: 'fulfillmentPoNum desc', waitClose: false,
  });
  if (data.length === 1) {
    return data[0];
  }
  if (data.length > 1) {
    const idx = (data || []).findIndex(po => po.poNumber === poNum);
    if (idx > -1) return data[idx];
  }
  return null;
};
/**
 * Fetch  Po list.
 * @param {string} filterFieldName -- filter field name
 * @param {string} filterKey -- filter key
 * @param {number} poStatusNum -- poStatusNum,if poStatusNum=-1,no poStatus filtering
 * @param {number} skip --  top row number of the page
 * @param {number} limit -- page size
 * @returns {Promise<PoListEntity>}
 */
type FetchfetchPoListParams = {
  warehouseCode?: string,
  filterFieldName: string,
  filterKey: string,
  poStatus?: string,
  poStatusNum?: number,
  waitClose?: boolean,
  sortBy: string,
  skip?: number,
  limit?: number,
}
export const fetchPoList = async (tparams: FetchfetchPoListParams): Promise<PoListEntity> => {
  const params = {
    $skip: tparams.skip || 0,
    $top: tparams.limit || 10,
    $sortBy: tparams.sortBy,
    $count: true,
    $calculateTotal: true,
    warehouseCode: tparams.warehouseCode,
    poNumber: tparams.filterFieldName === 'poNumber' && tparams.filterKey ? tparams.filterKey : undefined,
    SKU: tparams.filterFieldName === 'SKU' && tparams.filterKey ? tparams.filterKey : undefined,
    SKUStartsWith: tparams.filterFieldName === 'SKUStartsWith' && tparams.filterKey ? tparams.filterKey : undefined,
    title: tparams.filterFieldName === 'title' && tparams.filterKey ? tparams.filterKey : undefined,
    MPN: tparams.filterFieldName === 'mpn' && tparams.filterKey ? tparams.filterKey : undefined,
    vendorCode: tparams.filterFieldName === 'VendorCode' && tparams.filterKey ? tparams.filterKey : undefined,
    container: tparams.filterFieldName === 'container' && tparams.filterKey ? tparams.filterKey : undefined,
    waitClose: tparams.waitClose,
    poStatus: tparams.poStatus
  };
  // let $filter = tparams.warehouseCode ? `WarehouseCode eq ${tparams.warehouseCode}` : '';
  // //support po number filter
  // if (tparams.filterFieldName.length > 0 && tparams.filterKey.length > 2) {
  //   if ($filter.length > 0) {
  //     $filter += ' and ';
  //   }
  //   if (tparams.isEq) {
  //     $filter += `${tparams.filterFieldName} eq '${tparams.filterKey}'`;
  //   } else {
  //     $filter += `${tparams.filterFieldName} cn '${tparams.filterKey}'`;
  //   }
  // }
  // if (tparams.poStatusNum > -1) {
  //   if ($filter.length > 0) {
  //     $filter += ' and ';
  //   }
  //   $filter += `poStatusNum eq ${tparams.poStatusNum}`;
  // }
  // if ($filter.length > 0) {
  //   params = {
  //     ...params,
  //     $filter,
  //   };
  // }
  const result = (await digitBridgeApi.get('/api/purchaseOrders', {
    params,
  })) as any;
  return {
    count: result.count,
    skuCount: result.skuCount,
    totalOrdered: result.totalOrdered,
    totalReceived: result.totalReceived,
    data: result.data.map((item: any) => {
      return {
        ...item,
        poNum: item.fulfillmentPoNum,
        poId: item.fulfillmentPoId,
        arrvalDueDate: item.arrivalDueDate ? dayjs(
          item.arrivalDueDate,
          'YYYY-MM-DDTHH:mm:00z'
        ).toDate() : '',
        cancelAfterDate: item.cancelAfterDate ? dayjs(
          item.cancelAfterDate,
          'YYYY-MM-DDTHH:mm:00z'
        ).toDate() : '',
        centralDatabaseNum: item.centralDatabaseNum,
        centralPoNum: item.centralPoNum,
        controlStatus: item.controlStatus,
        createBy: item.createBy,
        createDate: item.createDate ? dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate() : '',
        poDate: item.poDate ? dayjs(item.poDate, 'YYYY-MM-DDTHH:mm:00z').toDate() : '',
        poNumber: item.poNumber,
        privateNote: item.privateNote,
        profileNum: item.profileNum,
        publicNote: item.publicNote,
        requestShipDate: item.requestShipDate ? dayjs(
          item.requestShipDate,
          'YYYY-MM-DDTHH:mm:00z'
        ).toDate() : '',
        terms: item.terms,
        updateBy: item.updateBy,
        updateDate: item.updateDate
          ? dayjs(item.updateDate, 'YYYY-MM-DDTHH:mm:00z').toDate()
          : '',
        vendorName: item.vendorName,
        warehouseCode: item.warehouseCode,
        receivedQuantity: item.receivedQuantity,
        totalPOAmount: item.totalPOAmount,
        poStatusNum: item.poStatusNum,
        quantity: item.quantity,
      };
    }),
  };
};
/**
 * Fetch  Po Line list.
 * @param {number} poNum -- po number
 * @returns {Promise<Array<PoLineRow>>}
 */
export const fetchPoLineList = async (
  poId: string,
  warehouseCode?: string
): Promise<Array<PoLineRow>> => {
  const query = {
    thumbnailSize: 50,
    warehouseCode
  };
  const list = (await digitBridgeApi.get(
    //`/api/purchaseOrders/${dbCode()}-${poNum}/lines`,
    `/api/purchaseOrders/${poId}/lines`,
    { params: query }
  )) as any;
  return list.map((item: any) => {
    return {
      ...item,
      poLineNum: item.fulfillmentPoLineNum,
      poNum: item.fulfillmentPoNum,
      poId,
      sku: item.sku,
      title: item.title,
      price: item.poPrice,
      cost: item.poPrice * item.poQty,
      quantity: item.poQty,
      qtyForOther: item.qtyForOther,
      requestShipDate: dayjs(
        item.lineRequestShipDate,
        'YYYY-MM-DDTHH:mm:00z'
      ).toDate(),
      arrivalDueDate: dayjs(
        item.lineArrivalDueDate,
        'YYYY-MM-DDTHH:mm:00z'
      ).toDate(),
      publicNote: item.linePublicNote,
      privateNote: item.linePrivateNote,
      originalLineId: item.originalLineId,
      humanReceiveQty: item.humanReceiveQty,
      humanAdjustQty: item.humanAdjustQty,
      enterDate: dayjs(item.enterDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
      profileNum: item.profileNum,
      createBy: item.createBy,
      createDate: dayjs(item.createDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
      updateBy: item.updateBy,
      updateDate: dayjs(item.updateDate, 'YYYY-MM-DDTHH:mm:00z').toDate(),
      thumbnailUrl: item.thumbnailUrl,
      waitCloseQty: item.waitCloseQty,
      warehouseCode: item.warehouseCode,
      closingQty: item.closingQty,
    };
  });
};
/**
 * Patch  Po.
 * @param {number} poNum -- po number
 * @returns {Promise<Array<PoLineRow>>}
 */
export const patchPo = async (
  poId: string,
  publicNote: string,
  privateNote: string
): Promise<boolean> => {
  const json = JSON.stringify({
    PublicNote: publicNote,
    PrivateNote: privateNote,
  });
  //await digitBridgeApi.patch(`/api/purchaseOrders/${dbCode()}-${poNum}`, json);
  await digitBridgeApi.patch(`/api/purchaseOrders/${poId}`, json);
  return true;
};

/**
 * create Po
 * @param {string} PoNumber --po number
 * @param {string} VendorName --Vendor Name
 */
export const createPo = async (
  PoNumber: string,
  VendorName: string
): Promise<boolean> => {
  const json = JSON.stringify({
    PoNumber,
    VendorName,
  });
  return await digitBridgeApi.post('/api/purchaseOrders', json);
};

/**
 * receive po
 */
type ReceiveParams = {
  // FulfillmentPoLineNum?: number;
  WarehouseNum: number;
  Items: {
    SKU: string;
    FulfillmentPoLineNum?: number;
    ReceiveQty?: number;
    LocationNum?: number;
    ReceiptDate: any;
    LotNumber?: string;
    Uom?: string;
    UomRate?: number;
  }[];
};
export const receive = async (
  PoId: string,
  params: ReceiveParams
): Promise<boolean> => {
  const json = JSON.stringify(params);
  return await digitBridgeApi.post(
    //`/api/purchaseOrders/${dbCode()}-${PoId}/receive`,
    `/api/purchaseOrders/${PoId}/receive`,
    json
  );
};

/**
 * create po line
 *
 */
export const createPoLine = async (
  PoId: string,
  params: any
): Promise<any> => {
  const json = JSON.stringify({
    SKU: params.SKU,
    Title: params.Title,
    PoQty: params.PoQty
  });
  return await digitBridgeApi.post(
    //`/api/purchaseOrders/${dbCode()}-${PoId}/lines`,
    `/api/purchaseOrders/${PoId}/lines`,
    json
  );
};

/**
 *  get PONumber By poNumber or sku
 */
export const getPoNumberByKeyword = async (keyword: string): Promise<any> => {
  const params = {
    keyword,
  };
  return await digitBridgeApi.get('/api/purchaseOrders/numbers/list', { params });
};

/**
 * po receive list
 */
type GetPoReceiveListParams = {
  vendorCode?: string,
  poNumber?: string,
  sku?: string,
  startDate?: string,
  endDate?: string,
  startEnterDate?: string,
  endEnterDate?: string,
  createDate?: string,
  warehouseType: number,
}
export const getPoReceiveList = async (warehouseId: string, params: GetPoReceiveListParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/poReceive/${warehouseId}`, { params });
};

type PoReceiveCloseParams = {
  PoReceiveNums: number[],
  VendorCode: string,
  BatchNumber: string
}
export const poReceiveClose = async (warehouseId: string, params: PoReceiveCloseParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post(`/api/poReceive/${warehouseId}/close`, json);
};

export const getBatchNumber = async (batchCode: string, localDate: string): Promise<any> => {
  return await digitBridgeApi.get('/api/poReceive/batchNumber', { params: { batchCode, localDate } });
};

export const delPo = async (poReceiveId: string): Promise<any> => {
  return await digitBridgeApi.delete(`/api/poReceive/${poReceiveId}`);
};

export const EditPoRecieveQty = async (poReceiveId: string, receiveQty: number, locationCode: string, receiveDate: string,): Promise<any> => {
  const json = JSON.stringify({ ReceiveQty: receiveQty, LocationCode: locationCode, ReceiveDate: receiveDate })
  return await digitBridgeApi.patch(`/api/poReceive/${poReceiveId}`, json);
};

type PoReceiveBatchHistoryParams = {
  $skip: number;
  $top: number;
  $sortBy: string;
  vendorCode?: string;
  poNumber?: string;
  sku?: string;
  startDate?: string;
  endDate?: string;
  batchNumber?: string;
}
export const getPoReceiveBatchHistory = async (warehuseId: string, params: PoReceiveBatchHistoryParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/poReceive/${warehuseId}/history`, { params });
};

export const getPoReceiveBatchHistorySync = async (warehuseId: string, params: PoReceiveBatchHistoryParams): Promise<any> => {
  return await digitBridgeApi.get(`/api/poReceive/${warehuseId}/sync`, { params });
};

export const delPurchaseOrder = async (PoId: string): Promise<any> => {
  return await digitBridgeApi.delete(`/api/purchaseOrders/${PoId}`);
};

export const abandonPurchaseOrder = async (PoId: string): Promise<any> => {
  return await digitBridgeApi.post(`/api/purchaseOrders/${PoId}/abandon`);
};

// export const updateControlStatus = async (PoId: string, status: number): Promise<any> => {
//   return await digitBridgeApi.post(`/api/purchaseOrders/${PoId}/updateControlStatus`, `${status}`);
// };

export const poControlStatusClose = async (poId: string) => {
  return await digitBridgeApi.post(`/api/purchaseOrders/${poId}/close`);
}

export const poControlStatusOpen = async (poId: string) => {
  return await digitBridgeApi.post(`/api/purchaseOrders/${poId}/open`);
}