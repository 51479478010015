import React, { useState, useEffect } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { message, FormLabel, InputInteger } from 'components/common';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
// import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import {
  addProductFlatRateOption,
  updateProductFlatRateOption,
} from 'services/product';
import {
  fetchCarriers,
  fetchPackageAggregateListByCarrier,
} from 'services/shipment';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  product: ProductRow;
  flatRate?: any;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, product, flatRate } = props;
  //   const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [carriers, setCarriers] = useState<CarrierRow[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<number | undefined>();
  const [loading1, setLoaing1] = useState(false);
  const [showPackage, setShowPackage] = useState(
    flatRate ? flatRate.qualified : 1
  );
  const [defaultPackageList, setDefaultPackageList] = useState<
    ShipmentPackageRow[]
  >([]);

  const [form] = Form.useForm();

  const getCarriers = async () => {
    try {
      const res = await fetchCarriers();
      setCarriers(res);
      if (flatRate) {
        setSelectedCarrier(flatRate.carrierNum);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPackage = async (warehouseId: number) => {
    try {
      setLoaing1(true);
      const res = await fetchPackageAggregateListByCarrier(warehouseId);
      setLoaing1(false);
      if (res) {
        setDefaultPackageList(res.carrierPackages ? res.carrierPackages.filter((i:any)=> i.flatRate) : []);
        // setUserPackageList(res.customPackages ? res.customPackages : []);
      }
    } catch (error) {
      setLoaing1(false);
    }
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    console.log(params);
    const postParams = {
      ProductFlatRateOptionNum: flatRate
        ? flatRate.productFlatRateOptionNum
        : 0,
      FulfillmentProductNum: product.id,
      CarrierNum: params.CarrierNum,
      Qualified: params.Qualified,
      CarrierPackageNum: params.CarrierPackageNum,
      PackageMaxQty: params.PackageMaxQty,
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!flatRate) {
        res = await addProductFlatRateOption(postParams);
      } else {
        res = await updateProductFlatRateOption(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: flatRate ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    getCarriers();
    getAllPackage(0);
  }, []);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${flatRate ? 'Edit' : 'Add'} Flat Rate for ${product.sku}`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="flat_modal_edit_or_save_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {flatRate ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${flatRate ? 'Saving' : 'Adding'}`
                : `${flatRate ? 'Save' : 'Add'}`}
            </Button>
            <Button
              id="edit_add_flat_modal_cancl_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading1}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="CarrierNum"
            initialValue={flatRate?.carrierNum}
            label={<FormLabel>Carrier</FormLabel>}
            rules={[{ required: true, message: 'Please select a carrier!' }]}
          >
            <Select
              id="carrier_select"
              onChange={(i) => setSelectedCarrier(Number(i))}
            >
              {carriers
                .filter((i) => i.carrierNum !== 0)
                .map((item) => (
                  <Select.Option key={item.carrierNum} value={item.carrierNum}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="Qualified"
            initialValue={flatRate?.qualified}
            label={<FormLabel>Is Qualified</FormLabel>}
            rules={[{ required: true, message: 'Please select a qualified!' }]}
          >
            <Select
              id="qualified_select"
              onChange={(i) => setShowPackage(Number(i))}
            >
              <Select.Option value={1}>Yes</Select.Option>
              <Select.Option value={0}>No</Select.Option>
            </Select>
          </Form.Item>

          {showPackage === 1 && (
            <Form.Item
              name="CarrierPackageNum"
              initialValue={flatRate?.carrierPackageNum}
              label={<FormLabel>Package</FormLabel>}
              rules={[{ required: true, message: 'Please select a package!' }]}
            >
              <Select id="carrierPackageNum_select">
                {defaultPackageList.map((item) =>
                  selectedCarrier === item.carrierNum ? (
                    <Select.Option
                      value={item.carrierPackageNum}
                      key={item.carrierPackageNum}
                    >
                      {item.name}
                    </Select.Option>
                  ) : null
                )}
              </Select>
            </Form.Item>
          )}

          {showPackage === 1 && (
            <Form.Item
              name="PackageMaxQty"
              initialValue={flatRate?.packageMaxQty}
              label={<FormLabel>Max Qty</FormLabel>}
              rules={[{ required: true, message: 'Please input Max Qty!' }]}
            >
              <InputInteger id="PackageMaxQty_input" />
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
