import React, { useCallback, useState } from 'react';
import { Form, Button, Row, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { SelectProduct } from 'components/common';
// import { SelectLocationPlus } from 'components/common';
import { SelectTransactionReason } from 'components/common';
import AddItemsFormItem from './RemoveItemsFormItem';
import { InputInteger } from 'components/common';
import { Locker } from 'components/common';
import { ClearOutlined } from '@ant-design/icons';
import SelectLocationPlus from './searchLocation';
interface AddItemFormProps {
  onAddsItem: (
    product: ProductRow,
    location: WarehouseLocationRow,
    reason: TransactionReasonRow,
    quantity: number,
    cb: () => void
  ) => void;
  warehouseNum: number;
  warehouseId: string;
  onSelectLocation: (location: WarehouseLocationRow) => void;
  // onSelectWarehouse: (warehouse: WarehouseRow) => void;
  onSetInventory: (inv: any[]) => void;
  onSelectProduct: (fieldName: string, product?: ProductRow) => void;
  uomEnable: boolean;
  lotEnable: boolean;
}
// eslint-disable-next-line
export default (props: AddItemFormProps) => {
  const [product, setProduct] = useState<ProductRow>();
  // const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>();
  const [transactionReason, setTransactionReason] =
    useState<TransactionReasonRow>();
  const [quantity, setQuantity] = useState<string>('');
  // const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [itemLocked, setItemLocked] = useState<boolean>(false);
  const [locationLocked, setLocationLocked] = useState<boolean>(false);
  const [reasonLocked, setReasonLocked] = useState<boolean>(false);
  const [quantityLocked, setQuantityLocked] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    warehouseNum,
    warehouseId,
    onSelectProduct,
    onSelectLocation,
    onAddsItem,
    onSetInventory,
    uomEnable,
    lotEnable
  } = props;

  /**
   * handle transaction reason select change event
   */
  const handleTransactionReasonChange = useCallback(
    (transactionReason: TransactionReasonRow) => {
      setTransactionReason(transactionReason);
    },
    []
  );
  /**
   * handle select product
   */
  const handleSelectProductChange = useCallback(
    (fieldName: string, product: ProductRow) => {
      setProduct(product);
      if (product.id) {
        onSelectProduct(fieldName, product);
      }
      form.setFieldsValue({
        identifier: product.sku,
      });
    },
    [form, onSelectProduct]
  );
  /**
   * handle select location
   */
  const handleLocationChange = useCallback(
    (row: any) => {
      onSetInventory([row]);
      setLocation(row);
      if (row.locationCode) {
        onSelectLocation(row);
      }
      // form.setFieldsValue({
      //   location: location.code,
      // });
    },
    [onSelectLocation, onSetInventory]
  );

  const reSet = () => {
    if (!itemLocked) {
      form.setFieldsValue({ identifier: '' });
      setProduct(undefined);
      // onSelectProduct('', undefined);
    }
    if (!locationLocked) {
      form.setFieldsValue({ location: '' });
      setLocation(undefined);
      // onSetInventory([]);
    }
    if (!reasonLocked) {
      form.setFieldsValue({ reason: '' });
      setTransactionReason(undefined);
    }
    if (!quantityLocked) {
      form.setFieldsValue({ quantity: '' });
      setQuantity('');
    }
  };
  /**
   * handle add item
   */
  const handleAddItemSubmit = useCallback(async () => {
    let values: StringKAnyVPair = {};
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }
    if (!location) {
      return;
    }
    if (location && product && transactionReason) {
      // setLoading(true);
      // let skuProduct = (await fullProduct(product)) as ProductRow;
      // //judge product'id is empty
      // if (!skuProduct) {
      //   setLoading(false);
      //   return;
      // }
      // onSelectProduct('sku', skuProduct);

      onAddsItem(
        product,
        location,
        transactionReason,
        values['quantity'],
        () => {
          // form.resetFields();
          if (!itemLocked) {
            form.setFieldsValue({ identifier: '' });
            setProduct(undefined);
            // onSelectProduct('', undefined);
          }
          if (!locationLocked) {
            form.setFieldsValue({ location: '' });
            setLocation(undefined);
            // onSetInventory([]);
          }
          if (!reasonLocked) {
            form.setFieldsValue({ reason: '' });
            setTransactionReason(undefined);
          }
          if (!quantityLocked) {
            form.setFieldsValue({ quantity: '' });
            setQuantity('');
          }
          // setLoading(false);
        }
      );
    }
  }, [
    location,
    product,
    form,
    transactionReason,
    onAddsItem,
    itemLocked,
    quantityLocked,
    reasonLocked,
    locationLocked,
  ]);
  return (
    <>
      <Form form={form} style={{ marginTop: 20, marginBottom: 20 }}>
        <AddItemsFormItem
          name="identifier"
          label="SKU / UPC"
          message="SKU / UPC is required!"
        >
          <SelectProduct
            width={265}
            placeholder="SKU / UPC"
            onChange={handleSelectProductChange}
            noControl
            onBlurAble
          />
          <Locker
            size={16}
            value={itemLocked}
            onChange={(value: boolean) => {
              setItemLocked(value);
            }}
          />
        </AddItemsFormItem>
        <AddItemsFormItem
          name="location"
          label="Location"
          message="Location is required!"
        >
          <SelectLocationPlus
            width={265}
            warehouseNum={warehouseNum}
            warehouseId={warehouseId}
            sku={product ? product.sku : ''}
            placeholder="Location"
            onChange={handleLocationChange}
            lotEnable={lotEnable}
            uomEnable={uomEnable}
          />
          <Locker
            size={16}
            value={locationLocked}
            onChange={(value: boolean) => {
              setLocationLocked(value);
            }}
          />
        </AddItemsFormItem>
        <AddItemsFormItem
          name="reason"
          label="Reason"
          message="Reason is required!"
        >
          <SelectTransactionReason
            transactionType={7}
            width={265}
            onChange={handleTransactionReasonChange}
          />
          <Locker
            size={16}
            value={reasonLocked}
            onChange={(value: boolean) => {
              setReasonLocked(value);
            }}
          />
        </AddItemsFormItem>
        {location && <Row justify='center' style={{marginBottom: 8}}>
          <Space size="large">
            {lotEnable&&<Text><Text strong>LOT#: </Text><Text>{location?.lotNumber}</Text></Text>}
            {uomEnable&&<Text><Text strong>UOM: </Text><Text>{location?.uom}</Text></Text>}
            {uomEnable&&<Text><Text strong>Rate: </Text><Text>{location?.uomRate}</Text></Text>}
          </Space>
        </Row>}
        <AddItemsFormItem
          name="quantity"
          label="Quantity"
          message="Quantity is required!"
        >
          <InputInteger
            value={quantity}
            width={120}
            onChange={(value: string) => {
              setQuantity(value);
            }}
          />
          <Space>
          <Locker
            size={16}
            value={quantityLocked}
            onChange={(value: boolean) => {
              setQuantityLocked(value);
            }}
          />
              {uomEnable && <Text>{`Base Qty: ${(location?.uomRate || 1) * Number(quantity || 1)}`}</Text>}
          </Space>
        </AddItemsFormItem>
        <Row justify="center" style={{ marginTop: 10 }}>
          <Space>
            <Button
              type="primary"
              disabled={!product || !location || !transactionReason}
              onClick={handleAddItemSubmit}
            >
              Checkout
            </Button>
            <Button
              onClick={() => {
                reSet();
              }}
            >
              <ClearOutlined />
              Clear
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
};
