import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, TablesFilter } from 'components/common';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
  onHide: () => void;
  onOk: (selectedPo: PoRow) => void;
  poList: PoRow[];
};
// eslint-disable-next-line
export default (props: Props) => {
  const { onHide, poList, onOk } = props;
  const [filteredData, setFilteredData] = useState<any[]>([]);
  // const [selected, setSelected] = useState<PoRow>();
  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const columns = [
    {
      header: 'PO#',
      name: 'poNumber',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'PO Warehouse',
      name: 'warehouseCode',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Container #',
      name: 'container',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Supplier',
      name: 'vendorName',
      userSelect: true,
      defaultFlex: 1,
    },
  ];
  return (
    <Modal
      title="Purchase Order Receiving"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button onClick={handleHide} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <TablesFilter
        columns={columns}
        dataSource={poList}
        setFilteredData={setFilteredData}
      />
      <DataGridPlus
        columns={columns}
        onRowClick={(row) => {
          onOk(row.data);
        }}
        dataSource={filteredData}
        idProperty="poNumber"
        pagination="local"
        {...({} as any)}
      />
    </Modal>
  );
};
