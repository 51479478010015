/**
 * Smart related service requests are here.
 */
import { validateHttpStatus } from 'utils';
import { digitBridgeApi } from './http';

export const scanSkuReturn = async (warehouseId: string, keyword: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/inventories/${keyword}/locations`, { validateStatus: (status) => validateHttpStatus([404], status) })
}

// export const queryLocationByProductIdReturn = async (warehouseId: number, productId: number,): Promise<any> => {
//   return await digitBridgeApi.get(`/api/warehouses/${warehouseId}/inventories/${productId}/locations`)
// }

export const postProductToLocationReturn = async (warehouseId: string, productId: number, locationCode: string): Promise<any> => {

  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/returnToLocation/${locationCode}/products/${productId}`, null, { validateStatus: (status) => validateHttpStatus([404, 409, 400], status) })
}

export const postProductRuturnInspectionToLocation = async (warehouseId: string, productId: number, locationCode: string): Promise<any> => {

  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/inspectionToLocation/${locationCode}/products/${productId}`, null, { validateStatus: (status) => validateHttpStatus([404, 409, 400], status) })
}

export const postProductToLocationReceving = async (warehouseId: string, productId: number, locationCode: string): Promise<any> => {

  return await digitBridgeApi.post(`/api/warehouses/${warehouseId}/receiveToLocation/${locationCode}/products/${productId}`, null, { validateStatus: (status) => validateHttpStatus([404, 409, 400], status) })
}



export const fetchPickingByOrder = async (fulfillmentOrderId: string): Promise<any> => {
  const params = { fulfillmentOrderId }
  return await digitBridgeApi.get('/api/GetPickingByOrder', { params })
}


export const fetchPickingBySKU = async (sku: string): Promise<any> => {
  const params = { sku }
  return await digitBridgeApi.get('/api/GetPickingItemBySKU', { params })
}

type pickingByOrderParams = {
  FulfillmentOrderNum: number,
  LocationCode: string,
  FulfillmentOrderLineNum: number,
  Title: string,
  SkuOrUpc: string,
  PickQty: number,
  PickedQty: number
}
export const pickingByOrder = async (params: pickingByOrderParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/PickingByOrder', json)
}

type pickingByOrderParams2 = {
  FulfillmentOrderNum: number,
  LocationCode: string,
  FulfillmentOrderLineNum: number,
  Title: string,
  SkuOrUpc: string,
  PickQty: number,
  PickedQty: number,
  FulfillmentProductNum: number,
  LotNumber:string,
  UOM: string,
  UomRate: number,
}

export const pickingByOrder2 = async (params: pickingByOrderParams2[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/PickingByOrder2', json)
}

type pickingItemBySKUParams = {
  PickListNum: number,
  LocationCode: string,
  SKU: string,
  PickedQty: number,
}
export const pickingItemBySKU = async (params: pickingItemBySKUParams): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/PickingItemBySKU', json)
}

type pickingItemBySKUParams2 = {
  PickListNum: number,
  LocationCode: string,
  SKU: string,
  PickedQty: number,
  LotNumber: string,
  UOM: string,
  UomRate: number,
}
export const pickingItemBySKU2 = async (params: pickingItemBySKUParams2[]): Promise<any> => {
  const json = JSON.stringify(params)
  return await digitBridgeApi.post('/api/PickingItemBySKU2', json)
}

export const fetchPickingStatus = async (pickListNum: string, sku: string, channelOrderId: string): Promise<any> => {
  return await digitBridgeApi.get(`/api/picking/${pickListNum}`, { params: { sku, channelOrderId } })
}