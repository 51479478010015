import React, { useCallback, useState } from 'react';
import { Form, Row, Button, Select, Input } from 'antd';
import dayjs from 'dayjs';
import { useResponsive } from 'hooks';
import {
  SelectWarehouse,
  FormLabel,
  ExportCSV,
  ClearButton,
  message,
} from 'components/common';
import { fetchCycleCounts } from 'services/warehouse';
import { postCountNote } from 'services/audits';
import MultipleSelectZones from './MultipleSelectZones';
import LocationType from './Filter/LocationType';
import LocationOrContainer from './Filter/LocationOrContainer';
import LastCountDate from './Filter/LastCountDate';
import Product from './Filter/Product';
import MakeCountedDialog from './MakeCountedDialog';
import { PushpinOutlined, SearchOutlined } from '@ant-design/icons';

const { Option } = Select;
type ConditonsFormProps = {
  selectedRows: Array<CycleCountRow>;
  columns: Array<StringKAnyVPair>;
  onChange: (data: Array<CycleCountRow>) => void;
};
export default function ConditonsForm(props: ConditonsFormProps): JSX.Element {
  const { isMobile } = useResponsive();
  const [sort, setSort] = useState<string>('lastCountDate desc');
  const [warehouse, setWarehouse] = useState<WarehouseRow>();
  const [data, setData] = useState<Array<CycleCountRow>>([]);
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMarkSubmitting, setIsMarkSubmitting] = useState(false);
  const { onChange, selectedRows, columns } = props;
  const [makeCountedDialogVisible, setMakeCountedDialogVisible] =
    useState(false);
  const sortOptions = [
    {
      id: 'lastCountDate asc',
      label: 'Last Count Date Asc',
    },
    {
      id: 'lastCountDate desc',
      label: 'Last Count Date Desc',
    },
    {
      id: 'locationCode asc',
      label: 'Location Code Asc',
    },
    {
      id: 'locationCode desc',
      label: 'Location Code Desc',
    },
  ];
  /**
   * handle search event
   */
  const handleSearch = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const conditions = {} as any;

      conditions['zones'] = form.getFieldValue('zones') || 'all';
      conditions['locationType'] = form.getFieldValue('locationType') || 'all';
      conditions['locationOrContainer'] =
        form.getFieldValue('locationOrContainer') || 'all';
      conditions['lastCountDate'] =
        form.getFieldValue('lastCountDate') || 'all';
      conditions['product'] = form.getFieldValue('product') || 'all';
      conditions['warehouse'] = warehouse?.warehouseId;
      conditions['locationCode'] = form.getFieldValue('locationCode');

      const newData = await fetchCycleCounts(conditions, sort);

      onChange(newData);
      setData(newData);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  }, [form, onChange, sort, warehouse]);

  /**
   * handle markCounted event
   */
  const handleSubmit = useCallback(
    async (date: Date, time: Date, notes: string) => {
      setIsMarkSubmitting(true);
      const dateStr = `${dayjs(date).format('YYYY/MM/DD')} ${dayjs(time).format(
        'HH:mm:00'
      )}`;
      const newDate = dayjs(dateStr, 'YYYY/MM/DD HH:mm:ss').toDate();
      const promiseChain = [];
      for (let i = 0; i < selectedRows.length; i++) {
        const item = selectedRows[i];
        promiseChain.push(
          postCountNote(item.warehouseId, item.locationNum, newDate, notes)
        );
      }
      await Promise.all(promiseChain);
      message.success('Mark counted successfully!');
      setIsMarkSubmitting(false);
      setMakeCountedDialogVisible(false);
      //reload
      handleSearch();
    },
    [selectedRows, handleSearch]
  );
  return (
    <>
      {makeCountedDialogVisible && (
        <MakeCountedDialog
          onSubmit={handleSubmit}
          onClose={() => {
            setMakeCountedDialogVisible(false);
          }}
        />
      )}
      <Form form={form} layout="vertical">
        <Row>
          <Form.Item
            name="warehouse"
            style={{ marginLeft: 10 }}
            label={<FormLabel>Warehouse</FormLabel>}
          >
            <SelectWarehouse
              width={200}
              onChange={(newWarehouse) => {
                console.log('cycle cpunting', newWarehouse);
                setWarehouse(newWarehouse);
                if (form.getFieldValue('zones')) {
                  form.setFieldsValue({ zones: undefined });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="locationCode"
            style={{ marginLeft: 10 }}
            label={<FormLabel>Location Keyword</FormLabel>}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="zones"
            style={{ marginLeft: 10 }}
            label={<FormLabel>Zones</FormLabel>}
          >
            <MultipleSelectZones width={200} warehouse={warehouse} />
          </Form.Item>
          <Form.Item
            style={{ marginLeft: 10 }}
            name="locationType"
            label={<FormLabel>Location Type</FormLabel>}
          >
            <LocationType />
          </Form.Item>
          <Form.Item
            style={{ marginLeft: 10 }}
            label={<FormLabel>Location Or Container</FormLabel>}
            name="locationOrContainer"
          >
            <LocationOrContainer />
          </Form.Item>
          <Form.Item
            style={{ marginLeft: 10 }}
            label={<FormLabel>Last Count Date</FormLabel>}
            name="lastCountDate"
          >
            <LastCountDate />
          </Form.Item>
          <Form.Item
            style={{ marginLeft: 10 }}
            label={<FormLabel>Product</FormLabel>}
            name="product"
          >
            <Product />
          </Form.Item>
        </Row>
        <Row
          justify={isMobile ? 'start' : 'space-between'}
          style={{ marginTop: 10, marginBottom: 20 }}
        >
          {!isMobile && <Row />}
          <Row>
            <Row align="middle">
              <div style={{ marginTop: 10 }}>
                <FormLabel>Sort</FormLabel>
              </div>
              <Select
                style={{ marginLeft: 5, marginTop: 10 }}
                value={sort}
                onChange={(value) => {
                  setSort(value);
                }}
              >
                {sortOptions.map((option: any) => {
                  return (
                    <Option key={option.id} value={option.id}>
                      {option.label}
                    </Option>
                  );
                })}
              </Select>
            </Row>
            <Button
              style={{ marginLeft: 10, marginTop: 10 }}
              type="primary"
              onClick={handleSearch}
              loading={isSubmitting}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
            <ClearButton
              style={{ marginLeft: 10, marginTop: 10 }}
              form={form}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              loading={isMarkSubmitting}
              style={{ marginLeft: 10, marginTop: 10 }}
              disabled={selectedRows.length === 0}
              onClick={() => {
                setMakeCountedDialogVisible(true);
              }}
            >
              <PushpinOutlined />
              <span>Mark Counted</span>
            </Button>
            <ExportCSV
              style={{ marginLeft: 10, marginTop: 10 }}
              title="Export Counting Session"
              scope="Counting Session"
              columns={columns}
              data={data}
            />
          </Row>
        </Row>
      </Form>
    </>
  );
}
