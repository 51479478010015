import React from 'react';
import { Button, FormInstance } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

type ClearButtonProps = {
  onClick?: () => void;
  form?: FormInstance;
  style?: React.CSSProperties;
  text?: string;
  disabled?: boolean;
  id?: string;
};
export default function ClearButton(props: ClearButtonProps): JSX.Element {
  const { onClick, form, style, text, disabled = false, id } = props;
  return (
    <Button
      id={id}
      disabled={disabled}
      style={style}
      onClick={() => {
        if (typeof onClick === 'undefined') {
          if (form) {
            form.resetFields();
          }
        } else {
          onClick();
        }
      }}
    >
      <ClearOutlined />
      {text || 'Clear'}
    </Button>
  );
}
ClearButton.defaultProps = {
  onClick: undefined,
  form: undefined,
  style: undefined,
  disabled: false,
  text: 'Clear',
};
