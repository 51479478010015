import React, { useCallback, useEffect, useState } from 'react';
import { Space, Card, Button, Modal, Input, Dropdown, Menu, Checkbox } from 'antd';
import { CaretDownOutlined, DeleteOutlined, ExportOutlined, PlusOutlined, PullRequestOutlined, SearchOutlined, SettingFilled } from '@ant-design/icons';
import {
  deleteChannelAccount,
  fetchStoreList,
  syncChannelAccount,
} from 'services/storeSetup';
import {
  message as showMessage,
  ButtonIcon,
  HeaderTitle,
  DataGridPlus,
  message,
  CopySpan,
} from 'components/common';
import dayjs from 'dayjs';
import EditDialog from './EditDetail';
import CreateDialog from './CreateChannelAccountDialog';
import ExportChannelAccount from './SelectExportTabs';
import { getPermissionValue, onSelectionChange } from 'utils';
import { PERMISSION_LEVEL } from 'constants/config';

type ColumnRenderType = {
  data: StoreChannelRow;
};

// eslint-disable-next-line
export default () => {
  const [storeList, setStoreList] = useState<StoreChannelRow[]>([]);
  const [filteredList, setFilteredList] = useState<StoreChannelRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [current, setCurrent] = useState<StoreChannelRow>();
  const [editVisible, setEditVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [showSelectTabVisible, setShowSelectTabVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [selected, setSelected] = useState<any>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [managePermission, setManagePermission] = useState<number>(PERMISSION_LEVEL['NONE']);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 700);
  const [hideInactive, setHideInactive] = useState(false);
  const [hideDisable, setHideDisable] = useState(false);
  const [allTempList, setAllTempList] = useState<any[]>([])
  const getBrandList = async () => {
    setLoading(true);
    try {
      const res = await fetchStoreList();
      setShowGrid(true);
      setLoading(false);
      if (res.isSuccess) {
        setStoreList(res.data);
        setFilteredList(res.data);
      } else {
        showMessage.error({ content: res.message });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleCopy = async () => {
    try {
      setLoading1(true);
      const res = await syncChannelAccount();
      setLoading1(false);
      if (res.isSuccess) {
        message.success({ content: 'Pull Channel Account Successfully' });
        getBrandList();
      }
    } catch (error) {
      setLoading1(false);
    }
  };

  const handleDelete = (data: StoreChannelRow) => {
    Modal.confirm({
      title: 'Are you sure you want to delete the Channel Account?',
      content: `Name: ${data.channelAccountName}`,
      okText: 'Yes',
      okType: 'default',
      okButtonProps: { className: 'hover-danger-button' },
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        dltChannelAccount(data);
      },
      onCancel() { },
    });

    const dltChannelAccount = async (data: StoreChannelRow) => {
      try {
        const res = await deleteChannelAccount(data.channelAccountNum);
        if (res.isSuccess) {
          message.success({ content: 'Delete Channel Account Successfully' })
          setEditVisible(false);
          getBrandList();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // const switchStatus = async (data: StoreChannelRow) => {
  //   try {
  //     const status = data.status === 1 ? 0 : 1;
  //     const res = await updateChannelAccountStatus(
  //       data.channelAccountNum,
  //       status
  //     );
  //     if (res.isSuccess) {
  //       showMessage.success({ content: 'Switch status successfully' });
  //       getBrandList();
  //     } else {
  //       showMessage.error({ content: res.message });
  //     }
  //   } catch (error) {}
  // };

  const checkManageProductPermission = async () => {
    const permission = await getPermissionValue(40016);
    setManagePermission(permission);
    getBrandList();
  }

  /* eslint-disable */
  useEffect(() => {
    checkManageProductPermission();
  }, []);

  useEffect(() => {
    const upperValue = debouncedSearchTerm.toUpperCase();
    let temp = [...storeList];
    if (hideInactive) {
      temp = storeList.filter((item) => item.status === 1);
    }
    if (hideDisable) {
      temp = temp.filter((item) => item.enableSync === 1);
    }
    setFilteredList(
      temp.filter(
        (item) =>
          item.channelAccountName.toUpperCase().includes(upperValue) ||
          item.channelName.toUpperCase().includes(upperValue)
      )
    );
  }, [debouncedSearchTerm, storeList, hideDisable, hideInactive]);

  const columns = useCallback(() => {
    return [
      {
        name: 'channelAccountNum',
        header: 'ID',
        defaultVisible: false,
      },
      {
        name: 'channelAccountName',
        header: 'Name',
        userSelect: true,
        defaultFlex: 1,
        sortable: true,
      },
      {
        name: 'channelName',
        header: 'Channel',
        userSelect: true,
        defaultFlex: 1,
        sortable: true,
      },
      {
        name: 'category',
        header: 'Channel Type',
        userSelect: true,
        sortable: false,
        // textAlign: 'center',
        // maxWidth: 100,
      },
      {
        name: 'updateDate',
        header: 'Last Modified',
        userSelect: true,
        maxWidth: 150,
        sortable: false,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <CopySpan
              inGrid
              value={
                data.updateDate ? dayjs(data.updateDate).format('MM/DD/YYYY') : ''
              }
            />
          );
        },
      },
      {
        name: 'status',
        header: 'Status',
        userSelect: true,
        sortable: false,
        // textAlign: 'center',
        maxWidth: 100,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <CopySpan inGrid value={data.status === 1 ? 'Active' : 'Inactive'} />
          );
        },
      },
      {
        name: 'enableSync',
        header: 'Enable Sync',
        userSelect: true,
        sortable: false,
        // textAlign: 'center',
        maxWidth: 120,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <CopySpan
              inGrid
              value={data.enableSync === 1 ? 'Enable' : 'Disable'}
            />
          );
        },
      },
      {
        name: 'action',
        header: 'Action',
        showColumnMenuTool: false,
        defaultLocked: 'end',
        groupBy: false,
        sortable: false,
        textAlign: 'center',
        maxWidth: 150,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            // <Space>
            //   <HoverBgButton
            //     hovertype="info"
            //     onClick={() => {
            //       setEditVisible(true);
            //       setCurrent(data);
            //     }}
            //   >
            //     <ButtonIcon className="bi bi-file-earmark-text" />
            //     Detail
            //   </HoverBgButton>
            //   {/* <Button
            //     type="default"
            //     onClick={() => {
            //       Modal.confirm({
            //         title: 'Are you sure you want to switch the status ?',
            //         okText: 'Yes',
            //         content: `${data.channelName} : ${
            //           data.status === 1 ? 'INACTIVE' : 'ACTIVE'
            //         }`,
            //         onOk() {
            //           switchStatus(data);
            //         },
            //       });
            //     }}
            //   >
            //     Switch Status
            //     <ButtonIcon className="bi bi-toggles" />
            //   </Button> */}
            // </Space>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Button
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<ButtonIcon className="bi bi-file-earmark-text" />}
                      onClick={() => {
                        setEditVisible(true);
                        setCurrent(data);
                      }}
                    >
                      Detail
                    </Button>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Button
                      type="text"
                      style={{ width: '100%', textAlign: 'left' }}
                      className="hover-danger-button-2"
                      disabled={managePermission < PERMISSION_LEVEL['EDIT']}
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        handleDelete(data);
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                icon={<SettingFilled />}
              >
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          );
        },
      },
    ];
    // eslint-disable-next-line
  }, [managePermission])

  /* eslint-enable */
  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Channel Account']}>
        {/* <Button
          type="primary"
          onClick={() => {
            setCurrent(undefined);
            setCreateVisible(true);
          }}
        >
          Add ChannelAccount
          <PlusOutlined />
        </Button> */}
        <Button
          loading={loading1}
          onClick={() => {
            Modal.confirm({
              title: 'Are you sure want to pull channel account?',
              okText: 'Yes',
              cancelText: 'No',
              content: '',
              onOk() {
                handleCopy();
              },
            });
          }}
          style={{ marginBottom: 10 }}
        >
          <PullRequestOutlined />
          Pull Channel Account
        </Button>
      </HeaderTitle>
      <Card
        title={`Channel Account (${filteredList.length} Channel Account Connected)`}
        extra={
          <Space>
            <Button
              onClick={() => {
                setCurrent(undefined);
                setCreateVisible(true);
              }}
            >
              <PlusOutlined /> Add Channel Account
            </Button>
            <Button
              disabled={selectedRow.length === 0}
              onClick={() => setShowSelectTabVisible(true)}
              icon={<ExportOutlined />}
            >
              Export channelAccount settings
            </Button>
          </Space>
        }
      >
        <Space>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ width: 500 }}
          />
          <Checkbox onChange={(e) => setHideInactive(e.target.checked)}>
            Hide Inactive
          </Checkbox>
          <Checkbox onChange={(e) => setHideDisable(e.target.checked)}>
            Hide Disable
          </Checkbox>
        </Space>
        {showGrid && (
          <DataGridPlus
            style={{ minHeight: 500 }}
            idProperty="channelAccountNum"
            columns={columns()}
            dataSource={filteredList}
            loading={loading}
            pagination="local"
            checkboxColumn
            checkboxOnlyRowSelect={true}
            selected={selected}
            onSortInfoChange={() => {
              setSelected({});
              setSelectedRow([]);
            }}
            onChange={() => {
              setSelected({});
              setSelectedRow([]);
            }}
            onSelectionChange={(props) => onSelectionChange(props, allTempList, setSelected, setSelectedRow, 'channelAccountNum', true, setAllTempList)}
            // onSelectionChange={(props) => {
            //   const { selected, data, unselected } = props;
            //   setSelected(selected);
            //   if (selected === true && !unselected) {
            //     setSelectedRow(data as any);
            //   } else {
            //     if (!unselected) {
            //       setSelectedRow(Object.values(selected as any));
            //     } else {
            //       setSelectedRow((prev) => {
            //         return prev.filter(
            //           (item) =>
            //             item.channelAccountNum !==
            //             (data as any).channelAccountNum
            //         );
            //       });
            //     }
            //   }
            // }}
            {...({} as any)}
          />
        )}
      </Card>
      {editVisible && (
        <EditDialog
          visible
          onHide={() => {
            setEditVisible(false);
            getBrandList();
          }}
          onRefresh={() => {
            // setEditVisible(false);
            // getBrandList();
          }}
          managePermission={managePermission}
          onDelete={() => {
            current && handleDelete(current);
          }}
          storeInfo={current}
        />
      )}
      {createVisible && (
        <CreateDialog
          visible
          onHide={() => {
            setCreateVisible(false);
          }}
          onRefresh={() => {
            getBrandList();
          }}
          checkDetail={(current: any) => {
            setCurrent(current);
            setCreateVisible(false);
            setEditVisible(true);
          }}
        />
      )}
      {showSelectTabVisible && (
        <ExportChannelAccount
          visible
          onHide={() => {
            setShowSelectTabVisible(false);
          }}
          onRefresh={() => {
            setSelected({});
            setShowSelectTabVisible(false);
          }}
          channelAccounts={selectedRow.map((item) => {
            return {
              channelAccountNum: item.channelAccountNum,
              channelAccountName: item.channelAccountName,
            };
          })}
        />
      )}
    </>
  );
};


// T is a generic type for value parameter, our case this will be string
function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}