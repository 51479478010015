import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import {
  DATAGRID_CELL_ICON_WIDTH1,
  DATE_TIME_NO_SECOND_FORMAT,
  LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  SpaceCell,
  CopyComponent,
  CopyTableButton
} from '../../../components/common';
import {
  Card,
  Col,
  Row,
  Space,
  Button,
  Modal,
  Tooltip,
  message,
  Input,
  Descriptions,
  Typography
} from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  CheckOutlined,
  CopyOutlined,
  PrinterOutlined,
  FilterOutlined,
  EditOutlined
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import { deleteBulkReturn, fetchBulkReturnLines } from 'services/return';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';
import PrintModal from './PrintModal';
import { queryProductByKeyword } from 'services/product';
import { convertTimeByUtc } from 'utils';
import debounce from 'lodash.debounce';
import CompleteDialog from './CompleteDialog';
import RestockDialog from './RestockDialog';
import ReceiveDialog from './ReceiveDialog';
import styled from 'styled-components';
import { RootState } from 'reducers';
import { shallowEqual, useSelector } from 'react-redux';
import EditClaimQtyDialog from './EditClaimTotalQty';

const MiniButton = styled.div`
  display: inline-block;
  background:#1976D2;
  color: white;
  border-radius: 4px;
  padding: 0px 4px;
  font-size: 1em;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
`

interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  receiveDisabled: boolean;
  specialInfo: any;
  defaultLocationCode: string;
  defaultWarehouseType: number;
}

const statusEnum: { [key: string]: string } = {
  '0': 'Draft',
  '1': 'Proccessing',
  '2': 'Complete',
};

const syncStatusEnum: { [key: string]: string } = {
  '0': 'Pending ',
  '1': 'Success',
  '2': 'Failed',
  '3': 'Proccessing',
};

const ReturnDetail = (props: Props) => {
  const { receiveDisabled, specialInfo, defaultLocationCode, defaultWarehouseType } = props;
  const userPermissions = useSelector(
    (state: RootState) => state.admin.userPermissions,
    shallowEqual
  );
  const hasPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40022 && i.permissionLevel >= 2) : []
  const [modalReturn, setModalReturn] = useState<any>(props.modalReturn)
  const [loading, setLoading] = useState(false);
  const [lineFilterIpt, setLineFilterIpt] = useState<string>('');
  const [lines, setLines] = useState<any[]>([]);
  const [filterLines, setFilterLines] = useState<any[]>([]);
  const [printModalVisible, setPrintModalVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterList, setFilterList] = useState<any[]>([]);

  const [receiveDialogVisible, setReceiveDialogVisible] = useState(false); //receive
  const [restockDialogVisible, setRestockDialogVisible] = useState(false); //restock
  const [completeDialogVisible, setCompleteDialogVisible] = useState(false); //complete
  const [editQtyDialogVisible2, setEditQtyDialogVisible2] = useState(false);


  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const lineFilterDebounce = debounce((val: string) => {
    setLineFilterIpt(val);
  }, 300);

  const handlePrint = () => {
    setPrintModalVisible(true);
  };

  const getBulkReturnLines = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchBulkReturnLines(
        modalReturn.fulfillmentBulkReturnNum
      );
      setLoading(false);
      if (res.isSuccess && res.data.length > 0) {
        setLines(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [modalReturn]);

  /**
 * handel delete Customer Return（Bulk Return）
 */
  const delBulkRturn = async (data: any) => {
    try {
      const res = await deleteBulkReturn(data.fulfillmentBulkReturnNum);
      if (res.isSuccess) {
        message.success({ content: 'Deleted successfully' });
      }
    } catch (error) { }
  }

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 180,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;

        return (
          <SpaceCell
            id={`catalog_grid_product_sku_row_${rowIndex}`}
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(`"${data.sku}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickSkuCell(data)}
            text={data.sku}
            textIsButton
          />
        );
      },
    },
    {
      name: 'upc',
      header: 'UPC',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 180,
    },
    {
      name: 'receiveQty',
      header: 'Receive Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'inspectionQty',
      header: 'Inspection Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'stockQty',
      header: 'Resaleable Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'nonStockQty',
      header: 'Damage Qty',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'receiveWarehouseCode',
      header: 'Receive W#',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      defaultVisible: false
    },
    {
      name: 'stockWarehouseCode',
      header: 'Resaleable W#',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      defaultVisible: false
    },
    {
      name: 'nonStockWarehouseCode',
      header: 'Damage W#',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
      defaultVisible: false
    },
  ];

  const onClickSkuCell = async (data: any) => {
    const { sku = '' } = data;
    try {
      const res = await queryProductByKeyword(sku);
      if (res.length > 0) {
        setCurrent(res[0]);
        setDetailVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modalReturn.fulfillmentBulkReturnNum) {
      getBulkReturnLines();
    }
  }, [modalReturn, getBulkReturnLines]);

  const list = useMemo(() => {
    return [
      {
        label: 'WMS Return ID',
        render: <CopySpan value={modalReturn.fulfillmentBulkReturnId} />
      },
      {
        label: 'Store(Channel)',
        render: <CopySpan value={modalReturn.channelAccountName} />
      },
      {
        label: 'Reference No',
        render: <CopySpan value={modalReturn.referenceNo} />
      },
      {
        label: 'Warehouse',
        render: <CopySpan value={modalReturn.warehouseCode} />
      },
      {
        label: 'Status',
        render: <CopySpan value={statusEnum[`${modalReturn.transStatus}`]} />
      },
      {
        label: 'Sync Status',
        // render: <CopySpan value={syncStatusEnum[`${modalReturn.syncStatus}`]} />
        render:    (                  
        <Typography.Text>
        <CopyComponent
          value={
            modalReturn && modalReturn.syncStatus
              ? syncStatusEnum[`${modalReturn.syncStatus}`]
              : ''
          }
        >
          <Typography.Text>
            {syncStatusEnum[`${modalReturn?.syncStatus}`] || ''}
            {modalReturn?.syncNote && (
              <Tooltip
                placement="top"
                title={modalReturn?.syncNote || ''}
              >
                <MiniButton
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    Modal.error({
                      title: syncStatusEnum[`${modalReturn.syncStatus}`],
                      content: (
                        <Typography.Text copyable>
                          {modalReturn?.syncNote}
                        </Typography.Text>
                      ),
                    });
                  }}
                >
                  View
                </MiniButton>
              </Tooltip>
            )}
          </Typography.Text>
        </CopyComponent>
      </Typography.Text>
        )
      },
      {
        label: 'Customer#',
        render: <CopySpan value={modalReturn.customerCode} />
      },
      {
        label: 'Customer Name',
        render: <CopySpan value={modalReturn.customerName} />
      },
      // {
      //   label: 'Channel',
      //   render: <CopySpan value={modalReturn.channelAccountName} />
      // },
      {
        label: 'Return Date',
        render: <CopySpan value={convertTimeByUtc(modalReturn.returnDate, '', '', DATE_TIME_NO_SECOND_FORMAT)} />
      },
      {
        label: 'Create Date',
        render: <CopySpan value={convertTimeByUtc(modalReturn.createDate, '', '', DATE_TIME_NO_SECOND_FORMAT)} />
      },
      {
        label: 'Create By',
        render: <CopySpan value={modalReturn.createBy} />
      },
      {
        label: 'Receive Qty',
        render: <CopySpan value={modalReturn.receiveQty} />
      },
      {
        label: 'Resaleable Qty',
        render: <CopySpan value={modalReturn.stockQty} />
      },
      {
        label: 'Damage Qty',
        render: <CopySpan value={modalReturn.nonStockQty} />
      },
      {
        label: 'Inspection Qty',
        render: <CopySpan value={modalReturn.inspectionQty} />
      },
      {
        label: 'Claimed total Qty',
        render: <span>
          <CopySpan value={modalReturn.claimTotalQty} />
          {hasPermissions.length > 0 && <Button size='small' type="link" onClick={()=> setEditQtyDialogVisible2(true)} icon={<EditOutlined />}/>}
          </span>
      },
      {
        label: 'Note',
        render: <CopySpan value={modalReturn.notes} />
      },
      {
        label: 'Control No',
        render: <CopySpan value={modalReturn.controlNo} />
      },
      {
        label: 'Box ID',
        render: <CopySpan value={modalReturn.boxID} />
      }
    ]
  }, [modalReturn, hasPermissions.length])

  useEffect(() => {
    if (filterValue) {
      const temp = list.filter(
        (i: any) =>
          i.label.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
      );
      setFilterList([...temp]);
    } else {
      setFilterList([...list]);
    }
  }, [filterValue, list]);

  useEffect(() => {
    if (lineFilterIpt) {
      const temp = lines.filter((line: any) => {
        const filterVal = lineFilterIpt.trim().toLowerCase();
        const sku = (line.sku || '').toLowerCase();
        const upc = (line.upc || '').toLowerCase();
        return sku.indexOf(filterVal) > -1 || upc.indexOf(filterVal) > -1;
      })
      setFilterLines([...temp]);
    } else {
      setFilterLines([...lines]);
    }
  }, [lineFilterIpt, lines]);

  return (
    <>
      <Card size="small">
        <Row>
          <Col span={5}>
            <Input
              prefix={<FilterOutlined />}
              size="small"
              placeholder="Filter"
              allowClear
              onChange={(e) => {
                debounceFn(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Descriptions
          size="small"
          column={3}
          labelStyle={{ width: 130, textAlign: 'right', display: 'inline-block' }}
          style={{ margin: '8px 0' }}
        >
          {
            filterList.map((item: any, index) => {
              const { label, render } = item;
              return (
                <Descriptions.Item key={index} label={<FormLabel noColon>{label}</FormLabel>} >
                  {render}
                </Descriptions.Item>
              )
            })
          }
        </Descriptions>
        <Row justify="center" style={{position: 'relative'}}>
          <Space>
            <Button
              id="bulk_return_detail_receive"
              disabled={
                receiveDisabled ||
                modalReturn.warehouseNum !==
                Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) ||
                modalReturn.transStatus === 2
              }
              onClick={() => {
                setReceiveDialogVisible(true)
              }}
            >
              <DiffOutlined />
              Receive
            </Button>
            <Button
              id="bulk_return_detail_restock"
              disabled={
                receiveDisabled ||
                modalReturn.warehouseNum !==
                Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) ||
                modalReturn.transStatus === 2 ||
                !(lines.length > 0)
              }
              onClick={() => {
                setRestockDialogVisible(true);
              }}
            >
              <DiffOutlined />
              Restock
            </Button>
            <Button
              id="bulk_return_detail_comlete"
              disabled={
                receiveDisabled ||
                modalReturn.warehouseNum !==
                Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) ||
                modalReturn.returnQty > modalReturn.stockQty + modalReturn.nonStockQty + modalReturn.inspectionQty || //FE-2792
                modalReturn.transStatus === 2 ||
                !(lines.length > 0)
              }
              onClick={() => {
                setCompleteDialogVisible(true)
              }}
            >
              <CheckOutlined />
              Complete
            </Button>
            <Button
              id="bulk_return_detail_delete"
              disabled={modalReturn.transStatus === 2}
              className={'hover-danger-button'}
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure delete this Customer Return?',
                  icon: <ExclamationCircleOutlined />,
                  content: `Reference No:  ${modalReturn.referenceNo}`,
                  okText: 'Yes',
                  okType: 'default',
                  okButtonProps: { className: 'hover-danger-button' },
                  cancelText: 'No',
                  autoFocusButton: 'cancel',
                  onOk() {
                    delBulkRturn(modalReturn);
                  },
                  onCancel() { },
                });
              }}
            >
              <DeleteOutlined />
              Delete
            </Button>
          </Space>
          <Button
            icon={<PrinterOutlined />}
            style={{
              position: 'absolute',
              top: 0,
              right: 20
            }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Row>
      </Card>

      <Card size="small" style={{ marginTop: 16 }}>
        <Row gutter={16}>
          <Col span={5}>
            <Input
              prefix={<FilterOutlined />}
              size="small"
              placeholder="Filter"
              allowClear
              onChange={(e) => {
                lineFilterDebounce(e.target.value);
              }}
            />
          </Col>
          <Col>
            <CopyTableButton
              columns={columns}
              data={lines}
              buttonProps={{
                size:'small'
              }}
            />
          </Col>
        </Row>
        <DataGridPlus
          loading={loading}
          columns={columns}
          dataSource={filterLines}
          idProperty="sku"
          pagination="local"
          {...({} as any)}
          checkboxOnlyRowSelect={true}
        />
      </Card>
      {printModalVisible && (
        <PrintModal
          detailData={modalReturn}
          onHide={() => setPrintModalVisible(false)}
        />
      )}
      {current && detailVisible && (
        <ProductDetail
          visible={detailVisible}
          onHide={() => setDetailVisible(false)}
          productId={current.productId}
        />
      )}
      {completeDialogVisible && modalReturn && (
        <CompleteDialog
          visible
          onClose={(value: boolean) => {
            setCompleteDialogVisible(false);
          }}
          modalReturn={modalReturn}
        />
      )}
      {restockDialogVisible && modalReturn && (
        <RestockDialog
          visible
          onClose={(key: string) => {
            setRestockDialogVisible(false);
            //todo refresh
            if (key === 'complete') {
              setCompleteDialogVisible(true);
            }
          }}
          modalReturn={modalReturn}
          specialInfo={specialInfo}
          defaultLocationCode={defaultLocationCode}
        />
      )}
      {receiveDialogVisible && modalReturn && (
        <ReceiveDialog
          visible
          onClose={() => {
            setReceiveDialogVisible(false);
          }}
          modalReturn={modalReturn}
          specialInfo={specialInfo}
          defaultWarehouseType={defaultWarehouseType}
          defaultLocationCode={defaultLocationCode}
        />
      )}
      {editQtyDialogVisible2 && (
        <EditClaimQtyDialog
          visible
          onHide={() => setEditQtyDialogVisible2(false)}
          onSave={(value: number) => {
            setModalReturn((prev:any)=>{
              return {
                ...prev,
                claimTotalQty: value
              }
            })
            setEditQtyDialogVisible2(false);
          }}
          data={modalReturn}
        />
      )}
    </>
  )
};

export default ReturnDetail;
