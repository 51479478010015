import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Table, Button, Space, Row } from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { message, SetDefaultWarehouseDialog } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
  SmartButton,
} from '../../SmartComponent';
import { queryLocationsByKeyword } from 'services/warehouse';
import { fetchWarehouseProductList } from 'services/inventory';
import FinishDialog from './Finish';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache } from 'utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const columns = [
  {
    title: 'SKU',
    dataIndex: 'sku',
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
    // align: 'right',
  },
];

export default function ReturnToLocation(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [fetching, setFetching] = useState(false);
  // const [locations, setLocations] = useState<WarehouseLocationRow[]>([]);
  const [finishDialogVisible, setFinishDialogVisible] = useState(false);
  const [step, setStep] = useState<string>('sourceLocation');
  const [sLocation, setSLocation] = useState<WarehouseLocationRow>();
  const [tLocation, setTLocation] = useState<WarehouseLocationRow>();
  const [product, setProduct] = useState<any>();
  const [moveHistory, setMoveHistory] = useState<
    { sku: string; qty: number }[]
  >([]);
  const [warehouseNum, setWarehouseNum] = useState<number>(-1);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseNum(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)));
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const queryLocation = useCallback(
    async (value: string, step: string, callback: (value: any) => void) => {
      try {
        setFetching(true);
        const res = await queryLocationsByKeyword(warehouseId, value, true);
        setFetching(false);

        if (!res.error) {
          callback(res);
          if (step.length > 0) {
            setStep(step);
          }
        }
      } catch (error) {
        setFetching(false);
      }
    },
    [warehouseId]
  );

  const fetchProductBySKU = useCallback(
    async (value: string) => {
      try {
        const query = {
          $skip: 0,
          $top: 1000,
          // $filter: `locationNum eq ${sLocation?.locationNum}`,
          uniqueCode: value,
        };
        const res = await fetchWarehouseProductList(warehouseId, query);
        if (res) {
          const temp = res.data.filter(
            (i: any) => i.locationNum === sLocation?.locationNum
          );
          if (temp.length > 0) {
            console.log(temp[0]);
            setProduct(temp[0]);
            setFinishDialogVisible(true);
          } else {
            message.error('This item was not found in location');
          }
        }
      } catch (error) { }
    },
    [warehouseId, sLocation]
  );

  const getTotalQty = useCallback(() => {
    let totalQty = 0;
    moveHistory.map((i) => (totalQty += i.qty));
    return totalQty;
  }, [moveHistory]);

  // const getLocations = useCallback(async () => {
  //   try {
  //     setFetching(true);
  //     const res = await fetchWarehouseLocationList(warehouseId, true);
  //     setFetching(false);
  //     setLocations(res);
  //   } catch (error) {
  //     setFetching(false);
  //   }
  // }, [warehouseId]);

  useEffect(() => {
    if (step === 'sourceLocation' || step === 'targetLocation') {
      setMoveHistory([]);
      setProduct(undefined);
    }
  }, [step]);

  /* eslint-disable */
  // useEffect(() => {
  //   if (warehouseId && locations.length === 0) {
  //     getLocations();
  //   }
  // }, [warehouseId]);
  /* eslint-enable */

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'sourceLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanSourceLocationtoStart")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                queryLocation(value, 'targetLocation', setSLocation);
              }}
            />
          </SmartSpace>
          <Row style={{ marginBottom: 10 }}>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('Pick', 'targetLocation', setSLocation);
                }}
              >
                Use Pick
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  queryLocation('General', 'targetLocation', setSLocation);
                }}
              >
                Use General
              </Button>
            </Space>
          </Row>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}
      {step === 'targetLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow>
            <SmartFormLabel>{t("smart.movemerge.from")}</SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {sLocation?.locationCode}
            </Text>
          </SmartRow>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanDestinationLocation")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                if (value === sLocation?.locationCode) {
                  return message.error('Cannot use the same location');
                } else {
                  queryLocation(value, 'scanItem', setTLocation);
                }
              }}
            />
            <Row style={{ marginTop: 10 }}>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    queryLocation('Pick', 'scanItem', setTLocation);
                  }}
                >
                  Use Pick
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    queryLocation('General', 'scanItem', setTLocation);
                  }}
                >
                  Use General
                </Button>
              </Space>
            </Row>
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              setStep('sourceLocation');
            }}
          />
        </>
      )}
      {step === 'scanItem' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace>
            <SmartRow>
              <SmartFormLabel>
                {t("smart.movemerge.from")}
              </SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {sLocation?.locationCode}
              </Text>
            </SmartRow>
            <SmartRow>
              <SmartFormLabel>
                {t("smart.movemerge.to")}
              </SmartFormLabel>
              <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
                {tLocation?.locationCode}
              </Text>
            </SmartRow>
          </SmartSpace>
          <SmartSpace>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t("smart.movemerge.scanItem")}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => {
                fetchProductBySKU(value);
              }}
            />
          </SmartSpace>
          <SmartRow>
            <SmartFormLabel>
              {t("smart.movemerge.totalMovedQty")}
            </SmartFormLabel>
            <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
              {getTotalQty()}
            </Text>
          </SmartRow>
          <Table
            size="small"
            columns={columns}
            dataSource={moveHistory}
            bordered
            pagination={false}
          />

          <SmartBackButton>
            <SmartButton
              type="primary"
              onClick={() => {
                setStep('sourceLocation');
              }}
            >
              {t("smart.movemerge.startFromNewSourceLocation")}
            </SmartButton>
            <SmartButton
              type="primary"
              onClick={() => {
                setStep('targetLocation');
              }}
            >
              {t("smart.movemerge.startFromNewDestination")}
            </SmartButton>
          </SmartBackButton>
        </>
      )}
      {finishDialogVisible && tLocation && sLocation && (
        <FinishDialog
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          tLocation={tLocation}
          sLocation={sLocation}
          product={product}
          onCloseModal={() => setFinishDialogVisible(false)}
          onSubmit={(value) => {
            const tempIndex = moveHistory.findIndex(
              (i) => i.sku === product.sku
            );
            if (tempIndex > -1) {
              const qty = moveHistory[tempIndex].qty + value;
              const temp = [...moveHistory];
              temp.splice(tempIndex, 1);
              setMoveHistory([{ sku: product.sku, qty: qty }, ...temp]);
            } else {
              setMoveHistory((prev) => [
                { sku: product.sku, qty: value },
                ...prev,
              ]);
            }
            setProduct(undefined);
            setFinishDialogVisible(false);
          }}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
