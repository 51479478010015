import React, { useState } from 'react';
import { Typography, Row, Col, Image } from 'antd';
import { getErrorImage } from 'utils';
import { DataGridPlus, TablesFilter } from 'components/common';
const { Text, Title } = Typography;
interface LocationsProps {
  items: Array<ProductRow>;
  product?: ProductRow;
  lotEnable: boolean;
  uomEnable: boolean;
  lotUomInfo: any;
}
// eslint-disable-next-line
export default (props: LocationsProps) => {
  const { items, product, lotEnable, lotUomInfo,uomEnable } = props;
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const columns = [
    {
      name: 'locationCode',
      header: 'Location',
      userSelect: true,
      defaultFlex: 1,
      resizable: false,
    },
    {
      name: 'quantity',
      header: 'Quantity',
      userSelect: true,
      defaultFlex: 1,
      resizable: false,
      // textAlign: 'center',
    },
  ];
  return (
    <>
      <Row>
        <Col span={12}>
          <Row>
            <Title level={2}>Current Inventory</Title>
          </Row>
          <Row>
            <Text strong>SKU: </Text>
            <Text style={{ marginLeft: 4 }}>{product ? product.sku : ''}</Text>
          </Row>
          <Row>
            <Text strong>Product Name: </Text>
            <Text style={{ marginLeft: 4 }}>
              {product ? product.productTitle : ''}
            </Text>
          </Row>
          <Row>
            <Text strong>UPC: </Text>
            <Text style={{ marginLeft: 4 }}>{product ? product.upc : ''}</Text>
          </Row>
          {lotEnable && <Row>
            <Text strong>LOT#: </Text>
            <Text style={{ marginLeft: 4 }}>{lotUomInfo ? lotUomInfo.lotNumber : ''}</Text>
          </Row>}
          {uomEnable && <Row>
            <Text strong>UOM: </Text>
            <Text style={{ marginLeft: 4 }}>{lotUomInfo ? lotUomInfo.uom : ''}</Text>
          </Row>}
          {uomEnable && <Row>
            <Text strong>Rate: </Text>
            <Text style={{ marginLeft: 4 }}>{lotUomInfo ? lotUomInfo.uomRate : ''}</Text>
          </Row>}
          {/* <Row>
            <Text strong>Part Number: </Text>
            <Text style={{ marginLeft: 4 }}>
              {product ? product.partNumber : ''}
            </Text>
          </Row> */}
        </Col>
        <Col span={12}>
          <Image
            src={
              product && product.thumbnailUrl ? product.thumbnailUrl : 'error'
            }
            width={50}
            height={50}
            preview={false}
            fallback={getErrorImage()}
          />
        </Col>
      </Row>
      <TablesFilter
        dataSource={items}
        columns={columns}
        setFilteredData={setFilteredData}
      />
      <DataGridPlus
        idProperty="id"
        dataSource={filteredData}
        columns={columns}
        pagination="local"
        /* eslint-disable react/jsx-props-no-spreading */
        {...({} as any)}
      />
    </>
  );
};
