import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import PoList from 'components/Po/PoList';
// eslint-disable-next-line
export default function PoListScreen(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <PoList />
      </SiteContent>
    </ContentLayout>
  );
}
