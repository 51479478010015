import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import History from 'components/Reports/ReprintHistory';

const ReprintHistory = () => {
  return (
    <ContentLayout>
      <SiteContent>
        <History />
      </SiteContent>
    </ContentLayout>
  );
};

export default ReprintHistory;
