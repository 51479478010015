import React from 'react';
import { isEqualObject } from 'crud-object-diff';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Row, Space } from 'antd';
import NumberFormat from 'react-number-format';
import { CellNumberInput } from 'components/common';
import { SCAN_TO_SHIP_OZ_PRECISION, SIGNALR_CONNECTION_CONNECTED } from 'constants/config';
import { RootState } from 'reducers';
import { getWeightLBOZ } from 'utils';
import { joinGroup, leaveGroup } from 'actions/signalrAction';
import { setScale, setScaleList } from 'actions/salesAction';
import {
  LARGE_WIDTH_LIMIT,
  ShipmentScaleWeightBoard,
  ShipmentWeightInputBoard,
} from './styles';

type WeightBoardProps = {
  lbInputId?: string;
  // eslint-disable-next-line
  onChange?: Function;
  ozInputId?: string;
  // eslint-disable-next-line
  shipmentNum?: number;
  weight?: number;
};

const WeightBoard = (props: WeightBoardProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { useState } = React;
  const { shipmentNum = 0 } = props;
  const [currentPageWidth, setCurrentPageWidth] = useState(0);
  const [currentPropsWeight, setCurrentPropsWeight] = useState<number>();
  const [currentScale, setCurrentScale] = useState<Scale>();
  const [currentShipmentNum, setCurrentShipmentNum] = useState(0);
  const [currentUseScale, setCurrentUseScale] = useState(false);
  const [currentWeightValue, setCurrentWeightValue] = useState(0);
  const [inputLBValue, setInputLBValue] = useState<number>();
  const [inputOZValue, setInputOZValue] = useState<number>();
  const [numberInputHeight, setNumberInputHeight] = useState(60);
  const shell = React.useRef<any>(null);
  const pageWidth: number = useSelector(
    (state: RootState) => state.regular.pageWidth,
    shallowEqual
  );
  const scaleLB: number = useSelector(
    (state: RootState) => state.sales.scaleLB,
    shallowEqual
  );
  const scaleList: Scale[] = useSelector(
    (state: RootState) => state.sales.scaleList,
    shallowEqual
  );
  const scaleOZ: number = useSelector(
    (state: RootState) => state.sales.scaleOZ
  );
  const selectedScale: Scale = useSelector(
    (state: RootState) => state.sales.scale as Scale,
    shallowEqual
  );
  const signalConnection: number = useSelector(
    (state: RootState) => state.regular.signalConnection,
    shallowEqual
  );
  const signalConnectionGroup: StringKAnyVPair = useSelector(
    (state: RootState) => state.regular.signalConnectionGroup,
    shallowEqual
  );

  // eslint-disable-next-line
  const updateScaleListByScale = (scale: Scale) => {
    scaleList.forEach((e, i) => {
      if (e.scaleNum === scale.scaleNum) {
        scaleList[i] = scale;
      }
    });
    dispatch(setScaleList([...scaleList]));
  };

  const currentScaleIsOnline: boolean = React.useMemo(() => {
    let ret = false;

    if (currentScale && currentScale.scaleNum > 0) {
      /*ret = ('number' === typeof scaleLB && scaleLB >= 0)
        && ('number' === typeof scaleOZ && scaleOZ >= 0);*/
      ret = 'number' === typeof scaleLB && 'number' === typeof scaleOZ;

      if (ret && !currentScale.online) {
        currentScale.online = true;
        dispatch(setScale({ ...currentScale }));
        updateScaleListByScale(currentScale);
      }
    }

    return ret;
  }, [currentScale, dispatch, scaleLB, scaleOZ, updateScaleListByScale]);
  const signalIsReady: boolean = React.useMemo(() => {
    return signalConnection === SIGNALR_CONNECTION_CONNECTED;
  }, [signalConnection]);
  const useScale: boolean = React.useMemo(() => {
    return !!(selectedScale && selectedScale.scaleNum > 0);
  }, [selectedScale]);
  const weightValue = React.useMemo(() => {
    let [lb = 0, oz = 0] = [inputLBValue, inputOZValue];

    if (useScale) {
      lb = scaleLB || 0;
      oz = scaleOZ || 0;
    }

    return lb + oz * 0.0625;
  }, [inputLBValue, inputOZValue, scaleLB, scaleOZ, useScale]);

  const adjustLayout = () => {
    if (window.innerWidth > LARGE_WIDTH_LIMIT) {
      setNumberInputHeight(90);
    } else {
      setNumberInputHeight(60);
    }
  };

  // eslint-disable-next-line
  const connectScale = (group: string) => {
    console.log('grp', group);
    dispatch(joinGroup(group));
    //dispatch(joinGroup(SIGNALR_GROUP_SCALE));
  };

  // eslint-disable-next-line
  const disconnectScale = (group: string) => {
    dispatch(leaveGroup(group));
    //dispatch(leaveGroup(SIGNALR_GROUP_SCALE));
  };

  const getScaleGroup = (scale: Scale) => {
    return `S-${scale.masterAccountNum}-${scale.scaleNum}`;
  };

  /**
   * Callback when inputed LB Change.
   */
  const onInputLBChange = (value: any) => {
    setInputLBValue(value as number);
  };

  /**
   * Callback when inputed OZ Change.
   */
  const onInputOZChange = (value: any) => {
    setInputOZValue(value as number);
  };

  React.useEffect(() => {
    if (signalIsReady) {
      if (selectedScale.scaleNum > 0) {
        let scaleChanged = false;

        if (currentScale) {
          if (!isEqualObject(currentScale, selectedScale)) {
            if (currentScale.scaleNum > 0) {
              disconnectScale(getScaleGroup(currentScale));
            }

            scaleChanged = true;
          }
        } else {
          scaleChanged = true;
        }

        if (
          !scaleChanged &&
          !signalConnectionGroup[getScaleGroup(selectedScale)]
        ) {
          scaleChanged = true;
        }

        if (scaleChanged) {
          //console.log('scale changed', selectedScale, getScaleGroup(selectedScale));
          setCurrentScale(selectedScale);
          setTimeout(() => {
            connectScale(getScaleGroup(selectedScale));
          }, 0);
        }
        //console.log('-->', scaleLB, scaleOZ);
      } else {
        if (currentScale) {
          if (currentScale.scaleNum > 0) {
            disconnectScale(getScaleGroup(currentScale));
          }
        }

        setCurrentScale(selectedScale);
      }
    }

    if (currentPageWidth !== pageWidth) {
      adjustLayout();
      setCurrentPageWidth(pageWidth);
    }

    if (currentUseScale !== useScale) {
      setCurrentUseScale(useScale);

      if (!useScale) {
        setInputLBValue(0);
        setInputOZValue(0);
      }
    }

    if (weightValue >= 0 && currentWeightValue !== weightValue) {
      setCurrentWeightValue(weightValue);

      if (typeof props.onChange === 'function') {
        props.onChange(weightValue);
      }
    }

    if (currentShipmentNum !== shipmentNum) {
      setCurrentShipmentNum(shipmentNum);
      setInputLBValue(undefined);
      setInputOZValue(undefined);

      if (typeof props.weight === 'number') {
        const [lb, oz] = getWeightLBOZ(props.weight);

        setInputLBValue(lb);
        setInputOZValue(oz);
      }
    }

    if (props.weight !== currentPropsWeight) {
      setCurrentPropsWeight(props.weight);
      setInputLBValue(undefined);
      setInputOZValue(undefined);

      if (typeof props.weight === 'number') {
        const [lb, oz] = getWeightLBOZ(props.weight);

        setInputLBValue(lb);
        setInputOZValue(oz);
      }
    }

    return () => {
      setTimeout(() => {
        // eslint-disable-next-line
        if (!shell?.current) {
          // eslint-disable-next-line
          //console.log(shell?.current, '<<<<<<<<<<<<<');
          if (currentScale && currentScale.scaleNum > 0) {
            disconnectScale(getScaleGroup(currentScale));
          }
        }
      }, 0);
    };
  }, [
    currentPageWidth,
    currentPropsWeight,
    currentScale,
    currentShipmentNum,
    currentUseScale,
    currentWeightValue,
    connectScale,
    disconnectScale,
    pageWidth,
    props,
    selectedScale,
    shipmentNum,
    signalConnectionGroup,
    signalIsReady,
    useScale,
    weightValue,
  ]);

  return (
    <Row align="middle" ref={shell}>
      {useScale ? (
        <ShipmentScaleWeightBoard justify="center">
          {currentScale ? (
            currentScaleIsOnline ? (
              <div className="lcd-area">
                {scaleLB}
                <span className="weight-unit">LB</span>
                <NumberFormat
                  allowLeadingZeros
                  decimalScale={1}
                  displayType="text"
                  fixedDecimalScale
                  value={scaleOZ}
                />
                <span className="weight-unit end-unit">OZ</span>
              </div>
            ) : (
              <div className="scale-error-msg">Scale is Offline</div>
            )
          ) : selectedScale.scaleNum > 0 ? (
            <div className="text-area">Connecting Scale</div>
          ) : (
            <div className="text-area">Please Select a Scale</div>
          )}
        </ShipmentScaleWeightBoard>
      ) : (
        <ShipmentWeightInputBoard justify="center">
          <Space>
            <CellNumberInput
              align="center"
              fontSize={48}
              height={numberInputHeight}
              id={props.lbInputId}
              min={0}
              onChange={onInputLBChange}
              paddingleft={4}
              precision={0}
              value={inputLBValue}
            />
            <span className="weight-unit">(LB)</span>
            <CellNumberInput
              align="center"
              fontSize={48}
              height={numberInputHeight}
              id={props.ozInputId}
              min={0}
              onChange={onInputOZChange}
              paddingleft={4}
              precision={SCAN_TO_SHIP_OZ_PRECISION}
              value={inputOZValue}
            />
            <span className="weight-unit">(OZ)</span>
          </Space>
        </ShipmentWeightInputBoard>
      )}
    </Row>
  );
};

export default WeightBoard;
