import React, { useState, useCallback } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import message from './message';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import theme from 'assets/styles/theme';

type Props = {
  value: string;
  children: React.ReactNode;
};

export default function CopyComponent(props: Props) {
  const { value } = props;
  const [isHover, setIsHover] = useState(false);

  const mouseenter = useCallback((e: any) => {
    e.stopPropagation();
    setIsHover(true);
  }, []);

  const mouseleave = (e: any) => {
    e.stopPropagation();
    setIsHover(false);
  };

  return (
    <Row
      align="middle"
      justify="space-between"
      onMouseEnter={mouseenter}
      onMouseLeave={mouseleave}
    >
      {props.children}
      <CopyToClipboard
        text={value}
        options={{
          format:'text/plain'
        }}
        onCopy={() => message.info({ content: 'Copied' })}
      >
        <Tooltip placement="top" title="Copy">
          <CopyOutlined
            style={{
              visibility: isHover && value ? 'visible' : 'hidden',
              color: theme['@info-color'],
            }}
          />
        </Tooltip>
      </CopyToClipboard>
    </Row>
  );
}
