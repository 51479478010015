import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { HeaderTitle, message } from 'components/common';
import { deteleTestData } from 'services/admin';

// eslint-disable-next-line
export default () => {
  const [loading, setloading] = useState(false);

  const deleteData = async () => {
    try {
      setloading(true);
      await deteleTestData();
      setloading(false);
      //   if (res.isSuccess) {
      message.success({ content: 'Deleted Successfully' });
      //   }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Administration', 'Cypress Data Clear']} />
      <div
        style={{
          width: '100%',
          height: 400,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          danger
          type="primary"
          loading={loading}
          onClick={() => {
            Modal.confirm({
              title: `Are you sure you want to clean cypress data?`,
              okText: 'Yes',
              cancelText: 'No',
              content: '',
              onCancel() {},
              onOk() {
                deleteData();
              },
            });
          }}
        >
          Cypress Data Clear
        </Button>
      </div>
    </>
  );
};
