import React, { useCallback } from 'react';
import { Select } from 'antd';
import SelectPlus from './Select';

// export const PackageTypes = [
//   { label: 'Bag', value: '2' },
//   { label: 'Bubble Mailer', value: '3' },
//   { label: 'Box', value: '4' },
// ];
// description: "Original Product Package"
// name: "Original Product Package"
// value: 1

type SelectPackageTypeProps = {
  value?: string;
  disabled?: boolean;
  edited?: boolean;
  outline?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  id?: string;
  packageTypes: any[];
};
export default function SelectPackageType(
  props: SelectPackageTypeProps
): JSX.Element {
  const { onChange, value, disabled, style, edited, outline, id, packageTypes } = props;
  const handleChange = useCallback(
    (newValue: string) => {
      if ('function' === typeof onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  return (
    <SelectPlus
      id={id}
      edited={edited}
      outline={outline}
      style={style}
      value={value}
      onChange={handleChange}
      disabled={disabled || false}
      dataSource={packageTypes}
    >
      {packageTypes.map((option) => {
        return (
          <Select.Option key={`${option.value}`} value={option.value}>
            {option.name}
          </Select.Option>
        );
      })}
    </SelectPlus>
  );
}
SelectPackageType.defaultProps = {
  value: undefined,
  disabled: undefined,
  edited: undefined,
  outline: undefined,
  onChange: undefined,
  style: undefined,
};
