import React, { useCallback, useState } from 'react';
import ScanToMoveDialog from './ScanToMoveDialog';
import MoveSummaryDialog from './MoveSummaryDialog';

type Props = {
  warehouseNum: number;
  warehouseId: string;
  locationNum: number;
  onHide: () => void;
  onSuccess: () => void;
};
export default function ScaToMoveDialog(props: Props): JSX.Element {
  const [step, setStep] = useState<number>(1);
  const { warehouseId, warehouseNum, locationNum, onHide, onSuccess } = props;
  const [items, setItems] = useState<{ [key: string]: number }>();
  /**
   * handle scan to move submit event
   */
  const handleScanToMoveSubmit = useCallback(
    (items: { [key: string]: number }) => {
      setItems(items);
      setStep(2);
    },
    []
  );
  if (step === 1) {
    return (
      <ScanToMoveDialog onSubmit={handleScanToMoveSubmit} onHide={onHide} />
    );
  } else if (step === 2 && items) {
    return (
      <MoveSummaryDialog
        warehouseNum={warehouseNum}
        warehouseId={warehouseId}
        locationNum={locationNum}
        items={items}
        onHide={onSuccess}
      />
    );
  }
  return <></>;
}
