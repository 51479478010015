import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../common/ModalDialog';
import {
  DeleteOutlined,
  SettingFilled,
  CaretDownOutlined,
  EditOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  InputInteger,
  message,
  Locker,
} from '../../common';
import {
  Card,
  Form,
  Row,
  Col,
  Select,
  // Space,
  Button,
  Input,
  Spin,
  Divider,
  Dropdown,
  Menu,
  Space,
  Modal,
  Tabs,
} from 'antd';
import {
  fetchBulkReturnLines,
  saveBulkReturnLine,
  deleteBulkReturnLine,
} from 'services/return';
import EditQtyDialog from './EditStockQtyDialog';
import EditClaimQtyDialog from './EditClaimTotalQty';
import debounce from 'lodash.debounce';
import { convertTimeByUtc } from 'utils';
import { RootState } from 'reducers';
import { shallowEqual, useSelector } from 'react-redux';
/**
 * For the product, it includes these attributes:
 * [ ProductId, ProductTitle, SKU, styleCode, ]
 */
interface Props extends ModalProps {
  onClose: Function;
  modalReturn: any;
  specialInfo: any;
  //   defaultWarehouseType: number;
  defaultLocationCode: string;
}

const warehouseTypeKeyValue: StringKVPair = {
  '2': 'Resalable',
  '3': 'Damage',
  '4': 'Inspection',
};

const ProductDetailDialog = (props: Props) => {
  const {
    specialInfo,
    // defaultWarehouseType,
    defaultLocationCode,
  } = props;
  const userPermissions = useSelector(
    (state: RootState) => state.admin.userPermissions,
    shallowEqual
  );
  const hasPermissions = userPermissions ? userPermissions.filter(i=>i.permissionNum === 40022 && i.permissionLevel >= 2) : []
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const [modalReturn, setModalReturn] = useState<any>(props.modalReturn)
  const [loading, setLoading] = useState(false);
  const [querying, setQuerying] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [lines, setLines] = useState<any[]>([]);
  const refreshBtnRef = React.useRef<any>(null);
  const [selected, setSelected] = useState<number>(2);
  const [qtyLocker, setQtyLocker] = useState(true);
  const [warehouseLocker, setWarehouseLocker] = useState(false);
  const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [editQtyDialogVisible2, setEditQtyDialogVisible2] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [currentProduct, setCurrentProduct] = useState<any>();
  const [filterLines, setFilterlines] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const InputRef = React.useRef<any>();

  const [form] = Form.useForm();

  const closeProductDetailDialog = () => {
    props.onClose();
  };

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getBulkReturnLines = useCallback(async () => {
    try {
      setLoading(true);
      const res = await fetchBulkReturnLines(
        modalReturn.fulfillmentBulkReturnNum
      );
      setLoading(false);
      if (res.isSuccess) {
        setLines(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [modalReturn]);

  // "ReceiveWarehouseNum": 0,
  // "ReceiveWarehouseCode": "string",
  // "StockWarehouseNum": 0,
  // "StockWarehouseCode": "string",
  // "NonStockWarehouseNum": 0,
  // "NonStockWarehouseCode": "string"
  // "ReceiveQty": 0,
  // "StockQty": 0,
  // "NonStockQty": 0,

  const columns = [
    { name: 'id', type: 'string', header: 'Id', defaultVisible: false },
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 150,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'upc',
      header: 'UPC',
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'receiveQty',
      header: 'Receive',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'inspectionQty',
      header: 'Inspection',
      minWidth: 70,
      showColumnMenuTool: false,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'stockQty',
      header: 'Resaleable',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      name: 'nonStockQty',
      header: 'Damage',
      minWidth: 70,
      showColumnMenuTool: false,
      userSelect: true,
      defaultFlex: 1,
      type: 'number',
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="0">
                  <Button
                    id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setEditQtyDialogVisible(true);
                    }}
                  >
                    Edit
                  </Button>
                </Menu.Item>
                <Menu.Item key="1">
                  <Button
                    id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
                    type="text"
                    className="hover-danger-button-2"
                    // disabled={data.receiveQty !== 0}
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure delete this Row?',
                        icon: <ExclamationCircleOutlined />,
                        content: `SKU : ${data.sku}`,
                        okText: 'Yes',
                        okType: 'default',
                        okButtonProps: { className: 'hover-danger-button' },
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onOk() {
                          deleteLines(data);
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              id={`catalog_grid_action_row_${rowIndex}_dropdown`}
              icon={<SettingFilled />}
            >
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const clearForm = useCallback(() => {
    form.setFieldsValue({ sku: undefined });
    setCurrentProduct(undefined);
    if (!qtyLocker) {
      form.setFieldsValue({ qty: 1 });
    }
    if (!warehouseLocker) {
      form.setFieldsValue({ warehouseType: '2' });
    }
  }, [form, qtyLocker, warehouseLocker]);

  const checkLines = useCallback(
    (data: any[]) => {
      if (data.length === 0) return;
      let showComplate = true;
      data.map((i) => {
        if (i.receiveQty !== i.nonStockQty + i.stockQty) {
          showComplate = false;
        }
        return true;
      });
      if (showComplate) {
        Modal.confirm({
          title: 'All SKUs are restocked.',
          okText: 'Complete Now',
          cancelText: 'Complete Later',
          content: 'you can now complete.',
          onOk() {
            props.onClose('complete');
          },
          onCancel() {},
        });
      }
    },
    [props]
  );

  const saveLine = useCallback(
    async (line: any) => {
      try {
        setSaveLoading(true);
        const res = await saveBulkReturnLine(
          modalReturn.fulfillmentBulkReturnNum,
          {
            ...line,
            inspectionQty: line.receiveQty - line.stockQty - line.nonStockQty,
          }
        );
        setSaveLoading(false);
        if (res.isSuccess) {
          setLines(res.data);
          clearForm();
          message.success({ content: `Update [${line.sku}] successfully` });
          InputRef?.current?.focus();
          checkLines(res.data);
        }
      } catch (error) {
        setSaveLoading(false);
      }
    },
    [modalReturn, clearForm, checkLines, InputRef]
  );

  const deleteLines = useCallback(
    async (line) => {
      try {
        const res = await deleteBulkReturnLine(
          modalReturn.fulfillmentBulkReturnNum,
          line.fulfillmentBulkReturnLineNum
        );
        if (res.isSuccess) {
          message.info({ content: 'Deleted successfully' });
          setLines(res.data);
        }
      } catch (error) {}
    },
    [modalReturn]
  );

  const queryProduct = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    try {
      const res = lines.filter(
        (i) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
      );
      if (res && res.length > 0) {
        setCurrentProduct(res[0]);
        const index = lines.findIndex((item) => item.sku === res[0].sku);
        if (index > -1) {
          setLines((prev) => {
            const temp = prev;
            temp.unshift(temp.splice(index, 1)[0]);
            return [...temp];
          });
        }
      } else {
        message.info({ content: `[${params.sku}] not receive to inspection` });
        form.setFieldsValue({ sku: undefined });
      }
    } catch (error) {
      setQuerying(false);
    }
  }, [form, lines]);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    if (!params.sku.trim()) {
      return message.warning({ content: 'invaild SKU/UPC' });
    }
    try {
      let res: any;
      if (currentProduct) {
        res = [currentProduct];
      } else {
        res = lines.filter(
          (i) => i.sku === params.sku.trim() || i.upc === params.sku.trim()
        );
      }
      if (res && res.length > 0) {
        // todo add to lines
        const index = lines.findIndex((item) => item.sku === res[0].sku);

        //check qty
        if (
          lines[index].stockQty +
            lines[index].nonStockQty +
            Number(params.qty) >
          lines[index].receiveQty
        ) {
          setLines((prev) => {
            const temp = prev;
            temp.unshift(temp.splice(index, 1)[0]);
            return [...temp];
          });
          return message.error({
            content: `Failed to add item, exceeding the quantity of receive`,
          });
        }
        const temp2Loc = specialInfo[2]
          ? (specialInfo[2].locations || []).find(
              (i: any) => i.locationCode === defaultLocationCode
            )
          : undefined;
        const temp3Loc = specialInfo[3]
          ? (specialInfo[3].locations || []).find(
              (i: any) => i.locationCode === defaultLocationCode
            )
          : undefined;

        switch (params.warehouseType) {
          case '2':
            saveLine({
              ...lines[index],
              receiveQty: lines[index].receiveQty,
              stockQty: lines[index].stockQty + Number(params.qty),
              stockWarehouseCode: specialInfo[2].warehouseCode,
              stockWarehouseNum: specialInfo[2].warehouseNum,
              stockLocationCode: temp2Loc?.locationCode,
              stockLocationNum: temp2Loc?.locationNum,
            });
            break;
          case '3':
            saveLine({
              ...lines[index],
              receiveQty: lines[index].receiveQty,
              nonStockQty: lines[index].nonStockQty + Number(params.qty),
              nonStockWarehouseCode: specialInfo[3]
                ? specialInfo[3].warehouseCode
                : '',
              nonStockWarehouseNum: specialInfo[3]
                ? specialInfo[3].warehouseNum
                : 0,
              nonStockLocationCode: temp3Loc?.locationCode || '',
              nonStockLocationNum: temp3Loc?.locationNum || 0,
            });
            break;
          default:
            break;
        }
      } else {
        message.info({ content: `[${params.sku}] not receive to inspection` });
        form.setFieldsValue({ sku: undefined });
        setCurrentProduct(undefined);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [form, defaultLocationCode, lines, specialInfo, currentProduct, saveLine]);

  useEffect(() => {
    if (modalReturn.fulfillmentBulkReturnNum) {
      getBulkReturnLines();
    }
  }, [modalReturn, getBulkReturnLines]);

  useEffect(() => {
    if (filterValue) {
      const temp = lines.filter(
        (i: any) =>
          (i.sku || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0 ||
          (i.upc || '')
            .toLowerCase()
            .indexOf((filterValue || '').trim().toLowerCase()) === 0
      );
      setFilterlines([...temp]);
      // setFilterlines([...lines]);
    } else {
      setFilterlines([...lines]);
    }
  }, [filterValue, lines]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={null}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title="Restock Customer Return (Bulk Return)"
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <Spin
          style={{ height: '100%', width: '100%' }}
          spinning={loading || saveLoading}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              backgroundColor: '#fff',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: 450,
                minHeight: 518,
                borderRightWidth: 1,
                borderRightColor: ' rgba(0, 0, 0, 0.15)',
                borderRightStyle: 'solid',
              }}
            >
              <Card size="small" bordered={false}>
                <Tabs
                  onChange={(key) => {
                    if (key === 'item-1') {
                      setFilterValue('');
                    }
                  }}
                  destroyInactiveTabPane={true}
                >
                  <Tabs.TabPane tab="Add" key="item-1">
                    <Form form={form}>
                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>SKU / UPC</FormLabel>
                          </div>
                        }
                        rules={[{ required: true }]}
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="sku"
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input SKU / UPC!',
                                },
                              ]}
                            >
                              <Input
                                ref={InputRef}
                                tabIndex={0}
                                onPressEnter={() => {
                                  if (qtyLocker && warehouseLocker) {
                                    addSubmit();
                                  } else {
                                    queryProduct();
                                  }
                                }}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 9) {
                                    if (qtyLocker && warehouseLocker) {
                                      addSubmit();
                                    } else {
                                      queryProduct();
                                    }
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={4}></Col>
                        </Row>
                      </Form.Item>

                      {currentProduct && (
                        <Form.Item
                          label={
                            <div
                              style={{
                                width: 110,
                                textAlign: 'left',
                                paddingLeft: 7,
                              }}
                            >
                              <FormLabel>Product Name</FormLabel>
                            </div>
                          }
                        >
                          <CopySpan
                            needToolTip
                            value={currentProduct.productTitle}
                          />
                        </Form.Item>
                      )}

                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>Quantity</FormLabel>
                          </div>
                        }
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="qty"
                              initialValue={1}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input Quantity!',
                                },
                              ]}
                            >
                              <InputInteger
                                isTabwork={!qtyLocker}
                                width={186}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={qtyLocker}
                              onChange={(value: boolean) => {
                                setQtyLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>

                      <Form.Item
                        label={
                          <div style={{ width: 110, textAlign: 'left' }}>
                            <FormLabel require>Marked as</FormLabel>
                          </div>
                        }
                        extra={`Put it into Warehouse: ${specialInfo[selected].warehouseCode}`}
                      >
                        <Row gutter={8} align="middle">
                          <Col span={20}>
                            <Form.Item
                              noStyle
                              name="warehouseType"
                              initialValue={`${2}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select marked as!',
                                },
                              ]}
                            >
                              <Select
                                tabIndex={warehouseLocker ? -1 : 0}
                                onChange={(value: number) => {
                                  setSelected(value);
                                }}
                              >
                                {Object.keys(specialInfo)
                                  .filter((i) => i !== '4')
                                  .map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {warehouseTypeKeyValue[item]}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Locker
                              size={16}
                              value={warehouseLocker}
                              onChange={(value: boolean) => {
                                setWarehouseLocker(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Row
                        justify="center"
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <Space>
                          <Button
                            type="primary"
                            disabled={querying}
                            onClick={addSubmit}
                            icon={<FormOutlined />}
                          >
                            Submit
                          </Button>
                          <Button onClick={clearForm}>
                            <ClearOutlined />
                            Clear
                          </Button>
                        </Space>
                      </Row>
                    </Form>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Manual Type" key="item-2">
                    <Form.Item
                      label={
                        <div style={{ width: 110, textAlign: 'left' }}>
                          <FormLabel>SKU / UPC</FormLabel>
                        </div>
                      }
                    >
                      <Input
                        allowClear
                        onChange={(e) => {
                          debounceFn(e.target.value);
                          // setFilterValue(e.target.value);
                        }}
                      />
                    </Form.Item>
                  </Tabs.TabPane>
                </Tabs>

                <Divider />
                <Row>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>WMS Return#</FormLabel>}>
                      <CopySpan
                        value={modalReturn.fulfillmentBulkReturnNum || ''}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Store(Channel)</FormLabel>}>
                      <CopySpan value={modalReturn.channelAccountName || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Reference No</FormLabel>}>
                      <CopySpan value={modalReturn.referenceNo || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormLabel>Warehouse</FormLabel>}>
                      <CopySpan value={modalReturn.warehouseCode || ''} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Return Date</FormLabel>}>
                      <CopySpan
                        value={convertTimeByUtc(modalReturn.returnDate, '', '', 'MM/DD/YYYY')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Claimed total Qty</FormLabel>}>
                      <span>
                        <CopySpan value={`${modalReturn.claimTotalQty}`} />
                        {hasPermissions.length > 0 && <Button type="link" onClick={()=> setEditQtyDialogVisible2(true)} icon={<EditOutlined />}/>}
                      </span>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={<FormLabel>Note</FormLabel>}>
                      <CopySpan value={modalReturn.notes || ''} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </div>
            <div
              style={{
                height: '100%',
                flexGrow: 1,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 8,
              }}
            >
              <DataGridPlus
                id="id"
                columns={columns}
                dataSource={filterLines.map((i: any) => {
                  return {
                    id: i.sku,
                    ...i,
                  };
                })}
                autoWith={false}
                autoHeight={false}
                selected={
                  filterLines.length > 0 ? { [filterLines[0].sku]: true } : {}
                }
                pagination="local"
                {...({} as any)}
              />
            </div>
          </div>
        </Spin>
        {editQtyDialogVisible && (
          <EditQtyDialog
            visible
            onHide={() => setEditQtyDialogVisible(false)}
            onSave={(data: any) => {
              setLines(data);
              setEditQtyDialogVisible(false);
            }}
            data={current}
          />
        )}
        {editQtyDialogVisible2 && (
          <EditClaimQtyDialog
            visible
            onHide={() => setEditQtyDialogVisible2(false)}
            onSave={(value: number) => {
              setModalReturn((prev:any)=>{
                return {
                  ...prev,
                  claimTotalQty: value
                }
              })
              setEditQtyDialogVisible2(false);
            }}
            data={modalReturn}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default ProductDetailDialog;
