import React from 'react';
import { Popover } from 'antd';
import {
  QuestionCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';
import { DEFAULT_ICON_MARGIN } from 'constants/config';

/**
 * The type definition for the component properties.
 *
 * @param {number} helpId
 * @param {number} marginLeft
 * @param {number} marginRight
 * @param {string} placement - the placement of the popover
 */
type Props = {
  helpId: number;
  marginLeft?: number;
  marginRight?: number;
  placement?:
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom';
};

const HelpMessage = (props: Props) => {
  const { helpId } = props;
  const [isLoadingMessage, setIsLoadingMessage] = React.useState(true);
  const [helpMessage, setHelpMessage] = React.useState('');
  const iconStyle = {
    marginLeft:
      'number' === typeof props.marginLeft
        ? props.marginLeft
        : DEFAULT_ICON_MARGIN,
    marginRight:
      'number' === typeof props.marginRight
        ? props.marginRight
        : DEFAULT_ICON_MARGIN,
  };

  const placement = props.placement || 'top';

  const fetchHelpMessage = () => {
    // This is a simulation code
    setTimeout(() => {
      console.log('help message from id', helpId);
      setHelpMessage('');
      setIsLoadingMessage(false);
    }, 1500);
  };

  const showHelpMessage = () => {
    if (isLoadingMessage) {
      fetchHelpMessage();
    }
  };

  const content = (
    <div style={{ maxWidth: 200 }}>
      {isLoadingMessage ? (
        <Loading3QuartersOutlined style={{ fontSize: 22 }} spin />
      ) : (
        helpMessage
      )}
    </div>
  );

  React.useEffect(() => { });

  return (
    <>
      {
        helpMessage ? (
          <Popover
            content={content}
            onVisibleChange={showHelpMessage}
            placement={placement}
          >
            <QuestionCircleOutlined style={iconStyle} />
          </Popover>
        ) : null
      }
    </>
  );
};

export default HelpMessage;
