import React, { useState } from 'react';
import { Input, Row, Select } from 'antd';

type ProductProps = {
  value?: StringKAnyVPair;
  onChange?: (value: StringKAnyVPair) => void;
};
export default function Product(props: ProductProps): JSX.Element {
  const { onChange, value: oriValue } = props;
  const [type, setType] = useState('cn');
  return (
    <Row>
      <Select
        value={oriValue ? oriValue.type : 'cn'}
        style={{ width: 180 }}
        onChange={(newType) => {
          setType(newType);
          if (typeof onChange === 'function') {
            onChange({ type: newType, value: oriValue ? oriValue.value : '' });
          }
        }}
      >
        <Select.Option value="cn">Contains</Select.Option>
        <Select.Option value="notcn">Does Not Contain</Select.Option>
      </Select>
      <Input
        value={oriValue ? oriValue.value : ''}
        placeholder="SKU,Part Number"
        style={{ width: 150 }}
        onChange={(e) => {
          const { value: nextValue } = e.target;
          if (typeof onChange === 'function') {
            onChange({ type, value: nextValue });
          }
        }}
      />
    </Row>
  );
}
Product.defaultProps = {
  value: undefined,
  onChange: undefined,
};
