import React, { useState, useCallback } from 'react';
import ScanOrder from './ScanOrder';
import ScanLocation from './ScanLocation';
import ScanQty from './ScanQty';
import ScanSKU from './ScanSKU';
import { SetDefaultWarehouseDialog } from 'components/common';
import { fetchPickingByOrder } from 'services/smart';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getWarehouseIdFromCache, getWarehouseCodeFromCache, getProfileSettingValue } from 'utils';

export default function PickList(): JSX.Element {
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const [locations, setLocations] = useState<any[]>([]);
  const [order, setOrder] = useState<SmartPickOrder>();
  const [scanedOrderStr, setScanedOrderStr] = useState('');
  const [location, setLocation] = useState<WarehouseLocationRow>();
  const [product, setProduct] = useState<SmartPickOrderItem>();
  const [pickedQty, setPickedQty] = useState(0);
  const [step, setStep] = useState<
    'scanOrder' | 'scanSKU' | 'scanLocation' | 'scanQty'
  >('scanOrder');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [uomEnable, setUomEnable] = useState(true);
  const [lotEnable, setLotEnable] = useState(true);
  const [lotNumberList, setLotNumberList] = useState<string[]>([])
  const [defaultLotNumber, setDefaultLotNumber] = useState<string>()

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);
  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  const getSettings = useCallback(async()=>{
    const res1 = await getProfileSettingValue('EnableLOT', '0')
    const res2 = await getProfileSettingValue('EnableUOM','0')
      setLotEnable(res1 === '1')
      setUomEnable(res2 === '1')
  },[])

  /**
   * handle SKU Finish
   */
  const handleSKUFinish = useCallback(
    async (scannedQty: number) => {
      if (order && product) {
        const { items } = order;
        order.items = items.map((item) => {
          if (
            // item.sku === product.sku ||
            // (item.upc === product.upc && product.upc
            item.fulfillmentOrderLineNum === product?.fulfillmentOrderLineNum
          ) {
            item.pickedQty = scannedQty;
          }
          return {
            ...item,
          };
        });
        setOrder({
          ...order,
        });
      }
    },
    [order, product]
  );

  const refreshOrder = async (
    nextStep: string,
    product?: SmartPickOrderItem,
    orderStr?: string
  ) => {
    try {
      const res = await fetchPickingByOrder(orderStr || scanedOrderStr);
      if (res.isSuccess) {
        setOrder(res.data);
        if (nextStep === 'scanLocation') {
          const temp = res.data.items.filter(
            (item: SmartPickOrderItem) =>
              item.fulfillmentOrderLineNum === product?.fulfillmentOrderLineNum
          );
          if (temp.length > 0) {
            setProduct(temp[0]);
            setPickedQty(temp[0].pickedQty);
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const fetchLocations = async () => {
    try {
      const res = await fetchWarehouseLocationList(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        ),
        false
      );
      setLocations(res);
    } catch (error) {
      console.log(error);
    }
  };

  const initLotNumbers = useCallback(()=>{
    if(product  && location){
      const temp = product.location.filter(i => i.uom === product.uom && i.locationCode === location.locationCode && i.lotNumber).map(k=>k.lotNumber)
      setLotNumberList(temp)
      setStep('scanQty');
    }
  },[product, location])

  React.useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      fetchLocations();
    }
  }, []);

  React.useEffect(() => {
    getSettings()
  },[getSettings])

  React.useEffect(() => {
    initLotNumbers()
  },[location, initLotNumbers])

  React.useEffect(()=>{
    if(step === 'scanLocation') {
      setLocation(undefined)
      setDefaultLotNumber(undefined)
    }
  },[step])

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanOrder' && (
        <ScanOrder
          onSubmit={(nextOrder: SmartPickOrder, str: string) => {
            setOrder(nextOrder);
            setScanedOrderStr(str);
            setStep('scanSKU');
          }}
        />
      )}
      {step === 'scanSKU' && order && (
        <ScanSKU
          uomEnable={uomEnable}
          order={order}
          onBack={() => {
            setStep('scanOrder');
          }}
          onSubmit={(nextProduct) => {
            setLocation(undefined)
            setProduct(nextProduct);
            setPickedQty(nextProduct.pickedQty);
            setStep('scanLocation');
          }}
        />
      )}
      {step === 'scanLocation' && product && order && (
        <ScanLocation
          lotEnable={lotEnable}
          uomEnable={uomEnable}
          order={order}
          locations={locations}
          warehouseId={getWarehouseIdFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}
          product={product}
          onSubmit={(nextLocation, record) => {
            if(lotEnable && record){
              setDefaultLotNumber(record.lotNumber)
            }
            setLocation(nextLocation);
          }}
          onBack={() => {
            setStep('scanSKU');
          }}
        />
      )}
      {step === 'scanQty' && order && location && product && (
        <ScanQty
          lotEnable={lotEnable}
          uomEnable={uomEnable}
          order={order}
          pickedQty={pickedQty}
          lotNumbers={lotNumberList}
          lotControl={product.lotControl}
          defaultLotNumber={defaultLotNumber}
          onChangeSKU={(nextProduct) => {
            refreshOrder('scanLocation', nextProduct);
            setStep('scanLocation');
          }}
          onChangeOrder={async (nextOrder) => {
            if (nextOrder) {
              // setOrder(nextOrder);
              const result = await refreshOrder(
                'scanSKU',
                undefined,
                nextOrder
              );
              if (result) {
                setScanedOrderStr(nextOrder);
                setStep('scanSKU');
              } else {
                setStep('scanOrder');
              }
            } else {
              setStep('scanOrder');
            }
          }}
          location={location}
          product={product}
          onBack={(nextStep) => {
            if (nextStep === 'scanSKU' || nextStep === 'scanLocation') {
              setDefaultLotNumber(undefined)
              setLocation(undefined)
              refreshOrder(nextStep, product);
            }
            setStep(nextStep);
          }}
          onFinish={handleSKUFinish}
        />
      )}
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
