import { Card, Space, Row, Typography } from 'antd';
import React from 'react';
import { FormLabel } from 'components/common';

const { Text } = Typography;
type Props = {
  poCount: number;
  skuCount: number;
  totalOrdered: number;
  totalReceived: number;
};
export default function Summary(props: Props) {
  const { poCount, skuCount, totalOrdered, totalReceived } = props;
  return (
    <>
      <Card style={{ marginTop: 30 }}>
        <Row justify="center">
          <Space size={60}>
            <Row>
              <FormLabel>Total Ordered</FormLabel>
              <Text>{totalOrdered}</Text>
            </Row>
            <Row>
              <FormLabel>Total Received</FormLabel>
              <Text>{totalReceived}</Text>
            </Row>

            <Row>
              <FormLabel>Purchase Orders</FormLabel>
              <Text>{poCount}</Text>
            </Row>
            <Row>
              <FormLabel>SKU Count</FormLabel>
              <Text>{skuCount}</Text>
            </Row>
          </Space>
        </Row>
      </Card>
    </>
  );
}
