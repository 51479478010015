import React from 'react';
import { Link } from 'react-router-dom';
import { SmartBlock, SmartSpace, SmartButton } from '../SmartComponent';
import { useTranslation } from 'react-i18next';

export default function Pick(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      <SmartBlock>
        <div>
          {t("smart.pick.bySku")}
        </div>
        <SmartSpace>
          <Link to="/smart/sku/pick/list">
            <SmartButton>
              {t("smart.pick.byPickListSku")}
            </SmartButton>
          </Link>
          <SmartButton isComingSoon>
            {t("smart.pick.bySkuLocation")}
          </SmartButton>
          <SmartButton isComingSoon>
            {t("smart.pick.bySkuCode")}
          </SmartButton>
        </SmartSpace>
      </SmartBlock>
      <SmartBlock>
        <div>
          {t("smart.pick.byOrder")}
        </div>
        <SmartSpace>
          <Link to="/smart/order/pick/list">
            <SmartButton>
              {t("smart.pick.byPickListOrder")}
            </SmartButton>
          </Link>
          <SmartButton isComingSoon>
            {t("smart.pick.paperless")}
          </SmartButton>
        </SmartSpace>
      </SmartBlock>
    </div>
  );
}
