import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import CheckItem from 'components/Sales/Smart/Audit/CheckItem';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartMerge(): JSX.Element {
  return (
    <ContentLayout titleKey="titles.checkItem" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))} >
     {/* <ContentLayout title={`View Item Location(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}> */}
      <CheckItem />
    </ContentLayout>
  );
}
