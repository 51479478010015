import { Col, Collapse, Row, Space, Tabs } from 'antd';
import styled from 'styled-components';
import theme from 'assets/styles/theme';
import { LS_THEME_CODE, PAGE_MODE_NORMAL } from 'constants/config';

const LABEL_SCALE_BOARD_HEIGHT1 = 86;

export const LARGE_WIDTH_LIMIT = 1690;
export const MIDDLE_WIDTH_LIMIT = 1460;

const selTheme = localStorage.getItem(LS_THEME_CODE) || '';

export const AlertContainer = styled(Col)`
  & .alert-icon {
    background-color: #FFF;
    border-radius: 100px;
    color: ${theme['@danger-color']};
    font-size: 200px;
    margin: 10px 0;
  }

  & .message-box,
    .title-box {
    padding: 5px 0 0;
    word-break: break-word;
  }

  & .button-box {
    padding: 16px 0;
  }

  & .icon-box {
    background-color: #FF5964;
    border-top-left-radius: ${selTheme === 'new' ? '10px' : theme['@border-radius-base']};
    border-top-right-radius: ${selTheme === 'new' ? '10px' : theme['@border-radius-base']};
  }

  & .message-box {
    color: #555;
    font-size: 20px;
    padding: 0 16px;
    margin-top: 5px;
  }

  & .title-box {
    font-size: 30px;
    margin-top: 10px;
  }
`;

export const BottomButtonRow = styled(Row)`
  background-color: #ececec;
  padding: 6px 0;

  &.verify-btn-row {
    background-color: #dadada;
    padding: 6px 8px;
  }

  & .dropdown-right-col {
    padding-right: 6px;
    text-align: right;
  }

  & .mark-verify-btn {
    width: 300px;
  }

  & .total-shipment-label {
    font-size: 22px;
  }

  & .total-shipment-qty {
    font-size: 26px;
  }

  @media screen and (min-width: ${LARGE_WIDTH_LIMIT}px) {
    & .ant-btn {
      font-size: 20px;
      height: 50px;
    }
  }
`;

export const DeviceSettingAlertRow = styled(Row)`
  & .msg-box-no-printer {
    width: calc(100% - 60px);
  }

  & .refresh-btn {
    font-size: 26px;
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const DeviceSettingScaleRow = styled(Row)`
  & .ant-typography {
    text-align: right;
    width: 160px;
  }

  & .ant-row {
    width: calc(100% - 170px);
  }
`;

export const DeviceSettingPrinterRow = styled(Row)`
  & .ant-typography {
    text-align: right;
    width: 160px;
  }

  & .ant-alert {
    margin-top: 8px;
    width: 100%;
  }

  & .ant-row {
    width: calc(100% - 260px);
  }
`;

export const DeviceSettingPackingPrinterRow = styled(Row)``;

export const DeviceSettingTabs = styled(Tabs)`
  & .ant-tabs-nav {
    margin-bottom: 0;
  }

  & .ant-tabs-tabpane {
    background-color: #FDFDFD;
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
  }
`;

export const DialogShipmentTitle = styled.span`
  color: ${theme['@primary-color']};
`;

export const HeaderGroup = styled(Space)`
  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    font-size: 18px;
  }
`;

export const LabelFormDropdownItem = styled.span`
  font-size: 20px;

  &:hover {
    color: ${theme['@primary-color']};
  }
`;

export const LabelQueueWrapper = styled(Col)`
  background-color: #555;
  border: 1px solid #D9D9D9;
  border-radius: ${theme['@border-radius-base']};
  color: #FFF;
  padding: 8px 1px 1px 1px;
  width: 490px;

  & .history-list {
    max-height: 500px;
    overflow-y: auto;
  }

  & .label-order,
  & .label-shipment {
    font-size: 16px;
  }

  & .label-shipment {
    color: #DDD;
  }

  & .print-item-odd,
  & .print-item-even {
    border: 1px solid #555;
    border-radius: ${theme['@border-radius-base']};
    cursor: pointer;
    margin: 1px 0;
    padding: 6px;
  }

  & .print-item-odd:hover,
  & .print-item-even:hover {
    border-color: #BBB;
  }

  & .print-item-even {
    background-color: #777;
  }

  & .print-item-odd {
    background-color: #666;
  }

  & .print-item-selected {
    border-color: #DDD;
    border-radius: ${theme['@border-radius-base']};
  }

  & .title-bar {
    font-size: 20px;
    padding: 4px 0 8px 8px;
  }
`;

export const LotSelectorDialogBody = styled.div`
  width: 700px;

  & .item-total-counter {
    background-color: #DCDCDC;
    font-size: 40px;
    height: 76px;
    width: 76px;
  }

  & .lot-selector-item-cell {
    font-size: 26px;
    padding: 0 !important;
  }

  & .lot-selector-item-cell .ant-input-number-input {
    font-size: 28px;
  }

  & .no-lot-warning-text {
    font-size: 22px;
  }

  & .title {
    font-size: 16px;
    font-weight: 550;
  }

  & .title:after {
    content: ': ';
  }
`;

type MainContainerProps = {
  pagemode?: number;
};

export const MainContainer = styled(Col) <MainContainerProps>`
  min-height: calc(
    100vh - ${(props) => (props.pagemode === PAGE_MODE_NORMAL ? 75 : 20)}px
  );
  min-width: 1000px;

  & button:focus {
    box-shadow: 1px 1px 1px 1px rgb(25 118 210 / 80%);
    /*font-weight: 500;*/
  }

  & .ant-input-clear-icon {
    font-size: 18px;
  }

  & .header-warehouse {
    font-size: 18px;
    font-weight: 600;
  }

  & .signal-link-light {
    font-size: 20px;
    margin-right: 6px;
  }

  & .skip-pack-slip-tag {
    margin-right: 0;
  }

  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    font-size: 20px;
  }
`;

export const MaskMessageBox = styled(Col)`
  background-color: #FFF;
  border-radius: ${theme['@border-radius-base']};

  & .button-box,
  .message-box {
    padding: 8px;
  }

  & .message-box {
    font-size: 40px;
  }
`;

export const MiniButton = styled.div`
  display: inline-block;
  background:#1976D2;
  color: white;
  border-radius: 4px;
  padding: 0px 4px;
  font-size: 1em;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export const OrderDetailTabs = styled(Tabs)`
  & .ant-tabs-tab {
    background-color: #ECECEC !important;
  }

  & .ant-tabs-tab-active {
    background-color: transparent !important;
  }

  & .order-detail {
    overflow-y: auto;
  }
`;

type PrintLabelFormContainerProps = {
  pageMode?: number;
};

export const PrintLabelFormContainer = styled.div<PrintLabelFormContainerProps>`
  height: calc(
    100vh - ${(props) => (props.pageMode === PAGE_MODE_NORMAL ? 300 : 244)}px
  );
  min-height: 200px;
  overflow-y: auto;

  & .confirmation-container .ant-select-clear {
    font-size: 18px;
    margin-top: -9px;
  }

  @media screen and (min-width: ${MIDDLE_WIDTH_LIMIT}px) {
    height: calc(
      100vh - ${(props) => (props.pageMode === PAGE_MODE_NORMAL ? 294 : 244)}px
    );
  }

  @media screen and (min-width: ${LARGE_WIDTH_LIMIT}px) {
    font-size: 22px;
    height: calc(
      100vh - ${(props) => (props.pageMode === PAGE_MODE_NORMAL ? 320 : 265)}px
    );
  }
`;

export const PrintLabelTitlebar = styled(Row)`
  background-color: #ececec;
  height: 46px;
  padding: 2px 10px 2px 10px;

  & .label-bold {
    color: #555;
    font-size: 1.2em;
  }
`;

export const PrintOptionContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;

  & .ant-checkbox-wrapper {
    margin-left: 16px;
  }
`;

export const PrintOptionPanel = styled(Collapse.Panel)`
  & .ant-collapse-arrow {
    margin-top: 46%;
  }

  & .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }

  & .collapse-bar {
    display: inline-block;
    width: calc(100% - 24px);
  }

  & fieldset {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    padding: 10px 0;
  }

  & legend {
    color: #666;
    margin-left: 12px;
    padding: 0 8px;
    width: unset;
  }
`;

export const RightLabelFormContainer = styled(Col)`
  & .ant-select-selection-item {
    font-size: 16px;
  }

  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    & .ant-select-selection-item {
      font-size: 22px;
    }
  }
`;

export const SizeInputRow = styled(Row)`
  & .ant-input-number {
    height: 40px;
  }

  & input.ant-input-number-input,
  & .ant-input-number-input-wrap {
    height: 100%;
  }

  & input.ant-input-number-input {
    font-size: 24px;
  }

  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    & .ant-input-number {
      height: 50px;
    }

    & input.ant-input-number-input {
      font-size: 30px;
    }
  }
`;

export const ShipmentCellHeader = styled(Row)`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;

  & .anticon-delete {
    color: ${theme['@danger-color']};
    cursor: pointer;
  }
`;

export const ShipmentConfirmDialogBody = styled.div`
  background-color: #FFF;
  border-radius: ${theme['@border-radius-base']};
  font-size: 20px;
    
  & > div {
    border-bottom: 1px solid #D9D9D9;
    margin: 1px;
    padding: 6px;
  }
    
  & .ant-card-body {
    padding: 6px 10px;
  }

  & .ant-input {
    margin: 8px 0;
  }

  & .bold-word {
    font-weight: 600;
  }
    
  & .no-bottom-border {
    border-bottom: none;
  }

  & .stress-msg {
    color: ${theme['@danger-color']};
    font-weight: 500;
  }
`;

export const ShipmentConfirmDialogFooter = styled(Row)`
  margin-top: 32px;
`;

export const ShipmentScaleWeightBoard = styled(Row)`
  background-color: #000;
  color: #fff;
  height: ${LABEL_SCALE_BOARD_HEIGHT1}px;
  width: 100%;

  & .lcd-area,
  & .scale-error-msg,
  & .text-area {
    color: #adb9ed;
    font-size: 60px;
    line-height: ${LABEL_SCALE_BOARD_HEIGHT1}px;
    user-select: none;
  }

  & .scale-error-msg {
    color: ${theme['@danger-color']};
    font-size: 50px;
  }

  & .text-area {
    font-size: 30px;
  }

  & .weight-unit {
    color: #b8b8b8;
    font-family: Orbitron, sans-serif;
    font-size: 20px;
    letter-spacing: 1px;
    margin: 0 4px;
  }

  & .end-unit {
    margin-right: 0;
  }

  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    height: 136px;

    & .lcd-area,
    & .scale-error-msg,
    & .text-area {
      font-size: 100px;
      line-height: 136px;
    }

    & .scale-error-msg {
      font-size: 80px;
    }

    & .text-area {
      font-size: 50px;
    }

    & .weight-unit {
      font-size: 30px;
    }
  }
`;

export const ShipmentTabs = styled(Tabs)`
  & .ant-popover-arrow {
    left: 50%;
    background-color: ${theme['@primary-color']};
    transform: translateX(-50%) rotate(45deg);
    top: -5px;
    /* border-top-color: #fff; */
    border-right-color: transparent;
    border-bottom-color: transparent;
    /* border-left-color: #fff; */
    box-shadow: -2px -2px 5px #0000000f;
  }

  & .ant-popover-arrow-content {
    background-color: ${theme['@primary-color']};
  }

  &.ant-tabs > .ant-tabs-nav {
    margin-bottom: 6px;
    margin-top: 0;
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    background-color: #55acee;
    border-bottom-right-radius: ${theme['@border-radius-base']};
    color: #fff;
    padding: 0 10px;
    width: 70px;
  }

  &.ant-tabs-card {
    height: 56px;
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    background-color: #ececec;
    font-size: 14px;
    line-height: 38px;
    padding: 0;
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: #fff;
    /*border-color: #1976D2;*/
    /*border-top-color: #FFF;*/
    color: #fff;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 6px;
  }

  & .ant-tabs-tab .tab-item {
    border-bottom-left-radius: ${theme['@border-radius-base']};
    border-bottom-right-radius: ${theme['@border-radius-base']};
    padding: 2px 8px;
  }

  & .ant-tabs-tab .tab-item > i {
    margin-right: 4px;
  }

  & .ant-tabs-tab-active .tab-item {
    border: 2px solid ${theme['@primary-color']};
    border-left-width: 1px;
    border-right-width: 1px;
    border-top: none;
    padding-bottom: 1px;
    padding-top: 3px;
  }

  & div[data-sm-verified='false'] i {
    color: ${theme['@danger-color']};
  }

  & div[data-sm-verified='true'] i {
    color: ${theme['@info-color']};
  }

  & div[data-sm-shipped='true'] i {
    color: #ee9b00;
  }

  & .ant-tabs-tab-active div[data-sm-verified='false'] {
    /*background-color: #FF5E78;*/
  }

  & .ant-tabs-tab-active div[data-sm-verified='true'] {
    /*background-color: #3FB077;*/
  }

  @media (width > ${LARGE_WIDTH_LIMIT}px) {
    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
      font-size: 16px;
      width: 82px;
    }

    &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      font-size: 20px;
    }
  }
`;

export const ShipmentWeightInputBoard = styled(Row)`
  background-color: #d9d9d9;
  height: ${LABEL_SCALE_BOARD_HEIGHT1}px;
  width: 100%;

  & .ant-input-number {
    width: 144px;
  }

  & .weight-unit {
    color: #555;
    font-size: 20px;
  }

  @media screen and  (min-width: ${LARGE_WIDTH_LIMIT}px) {
    height: 136px;

    & .ant-input-number {
      width: 170px;
    }

    & .weight-unit {
      font-size: 30px;
    }
  }
`;

export const ShoppingAroundContainer = styled(Col)`
  background-color: #FFF;
  border-radius: ${theme['@border-radius-base']};
  font-size: 16px;

  & hr {
    border: none;
    border-bottom: 1px solid #B9B9B9;
    margin: 8px 0;
  }

  & .carrier-field-label {
    width: 45%;
  }

  & .carrier-field-selector {
    width: 35%;
  }

  & .carrier-field-selector .ant-select {
    width: calc(100% - 8px) !important;
  }

  & .carrier-field-weight {
    width: 50%;
  }

  & .carrier-field-weight-wrapper {
    width: 20%;
  }

  & .error-msg {
    color: ${theme['@danger-color']};
  }

  & .label-field {
    margin-left: 6px;
  }

  & .shipping-addr-row {
    font-size: 16px;
  }

  & .shopping-around-result-label {
    font-size: 16px;
    font-weight: 500;
  }

  & .shopping-around-result-row:hover {
    background-color: #ECECEC;
  }
`;

export const UnitLabel = styled.span`
  padding-left: 4px;
  padding-right: 4px;
`;

export const VerifyContainer = styled(Row)`
  & .scanned-col {
    font-size: 46px;
    text-align: center;
  }

  & .label-container {
    position: relative;
  }

  & .label-container[data-type='slip'] {
    width: 100%;
  }

  & .label-container-mask {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    width: 100%;
    height: 100%;
  }

  & .label-form-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: ${theme['@border-radius-base']};
    min-width: 360px;
    width: 100%;
  }

  & .line-lot-row {
    height: 40px;
    padding-left: 58px;
  }

  & .line-lot-row:nth-child(even) {
    background-color: #DEDEDE;
  }

  & .line-lot-row:nth-child(odd) {
    background-color: #EDEDED;
  }

  & .lot-number-cell {
    display: inline-block;
    text-align: left;
    white-space: break-spaces;
    word-break: break-all;
  }

  & .scanned-col input {
    font-size: 36px;
  }

  & .scanned-col-header .InovuaReactDataGrid__column-header__content {
    font-size: 18px;
    padding: 0;
  }

  & .shipment-item-remaining-board {
    font-size: 1.3em;
    margin-right: 6px;
  }

  & .shipment-item-remaining-board .label-bold {
    font-size: 20px;
    margin-right: 10px;
  }

  & .shipment-item-remaining-number {
    font-size: 28px;
    margin-right: 4px;
  }

  & .slip-bottom-btn-row {
    background-color: #ECECEC;
    overflow-x: hidden;
    padding: 6px 8px;
  }

  & .slip-carrier-row {
    background-color: #D8E2DC;
    border-top: 1px solid #D9D9D9;
  }

  & .slip-carrier-row .carrier-field {
    font-size: 18px;
    height: 32px;
    padding-left: 10px;
  }

  & .slip-carrier-row .carrier-info-wrapper {
    width: calc(100% - 100px);
  }

  & .slip-carrier-row .carrier-logo-wrapper {
    background-color: #ECECEC;
    width: 100px;
    height: 64px;
  }

  & .slip-container {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    font-size: 18px;
  }

  & .slip-field {
    padding: 6px;
  }

  & .slip-label {
    color: #666;
  }

  & .slip-border-left {
    border-left: 1px solid #d9d9d9;
  }

  & .slip-border-right {
    border-right: 1px solid #d9d9d9;
  }

  & .slip-shipment-header {
    font-size: 20px;
    font-weight: 500;
  }

  & .slip-shipment-header > div {
    padding: 8px;
  }

  & .thumbnail-col-header {
    border-right: none;
  }

  & .verified-cell {
    width: 100%;
    height: 100%;
    background-color: #9cc5a1;
    font-size: 50px;
  }

  & .verify-info-space .ant-space-item {
    line-height: 1;
  }

  & .verify-bottom-bar {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: ${theme['@border-radius-base']};
    border-bottom-right-radius: ${theme['@border-radius-base']};
  }

  & .verify-item-info-cell {
    border-left: none;
    font-size: 16px;
    padding: 0 !important;
    /*padding-left: 10px !important;*/
  }

  & .verify-item-info-cell .InovuaReactDataGrid__cell__content {
    height: 100%;
  }

  & .verify-item-info-cell .InovuaReactDataGrid__cell__content > .ant-row {
    height: 100%;
  }

  & .verify-item-info-col-header {
    border-left: none;
  }

  & .verify-msg-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: top 800ms;
  }

  & .verify-msg-bar .ant-row {
    width: 100%;
    height: calc(100% - 2px);
  }

  & .verify-msg-bar .ant-alert {
    width: 100%;
    font-size: 1.4em;
    cursor: pointer;
  }

  & .verify-msg-bar .close-icon {
    font-size: 22px;
  }

  & .verify-msg-bar .left-icon {
    margin-right: 12px;
  }

  & .verify-msg-bar .error-icon {
    color: ${theme['@danger-color']};
  }

  & .verify-msg-bar .success-icon {
    color: ${theme['@success-color']};
  }

  @media screen and (min-width: ${LARGE_WIDTH_LIMIT}px) {
    & .scanned-col input {
      font-size: 44px;
    }

    & .scanned-col-header .InovuaReactDataGrid__column-header__content {
      font-size: 20px;
    }

    & .shipment-item-remaining-board .label-bold {
      font-size: 30px;
    }

    & .shipment-item-remaining-number {
      font-size: 36px;
    }

    & .slip-bottom-btn-row .ant-btn {
      height: 50px;
    }

    & .verify-item-info-cell {
      font-size: 18px;
    }

    & .verify-msg-bar .ant-alert {
      font-size: 1em;
    }
  }
`;

export const deviceSettingDialogBodyStyle = {
  backgroundColor: '#ECECEC',
  fontSize: 16,
};


