import * as actionTypes from './actionTypes';

export const joinGroup = (group: string) => ({
  type: actionTypes.SIGNALR_JOIN_GROUP,
  group,
});


export const leaveGroup = (group: string) => ({
  type: actionTypes.SIGNALR_LEAVE_GROUP,
  group,
});
