import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import EmployeeShipment from 'components/Reports/EmployeeShipment';
// eslint-disable-next-line
export default function EmployeeShipmentScreen(): JSX.Element {
  return (
    <ContentLayout>
      <SiteContent>
        <EmployeeShipment />
      </SiteContent>
    </ContentLayout>
  );
}
