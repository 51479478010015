import React from 'react';
import { Row } from 'antd';
import { PrintedLabel, ShipmentUnit } from '.';
import { LabelQueueWrapper } from './styles';

type Props = {
  hide: Function;
  onMouseEnter: Function;
  onSelect: Function;
  queue: PrintedLabel[];
  shipment?: ShipmentUnit;
};

const LabelQueue = (props: Props) => {
  const handleClickHistoryItem = (item: PrintedLabel) => {
    props.hide();
    props.onSelect(item);
  };

  const historyItemClassName = (item: PrintedLabel, seq: number) => {
    const list = [seq % 2 ? 'print-item-odd' : 'print-item-even'];

    if (props.shipment) {
      if (item.shipmentNum === props.shipment.shipmentNum) {
        list.push('print-item-selected');
      }
    }

    return list.join(' ');
  };

  const printAffix = (ele: PrintedLabel) => {
    return ele.isOnly ? '' : ` (${ele.shipmentTitle})`;
  };

  return (
    <LabelQueueWrapper
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.hide()}
    >
      <div className="title-bar">Print History</div>
      <div className="history-list">
        {props.queue.map((e, i) => (
          <React.Fragment key={e.uuid}>
            <Row
              align="middle"
              className={historyItemClassName(e, i)}
              justify="space-between"
              onClick={() => handleClickHistoryItem(e)}
            >
              <span className="label-order">{e.orderId}</span>
              <span className="label-shipment">{printAffix(e)}</span>
              <span>{e.printDate}</span>
            </Row>
            {/*<Row>
            <Space>
              <Button type="text">
                Label
              </Button>
              <Button type="text">
                Packing Slip
              </Button>
              <Button type="text">
                Pick List
              </Button>
            </Space>
          </Row>*/}
          </React.Fragment>
))}
      </div>
    </LabelQueueWrapper>
  );
};

export default LabelQueue;
