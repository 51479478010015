import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Input,
  Form,
  Select,
  Button,
  Row,
  Card,
  Space,
  Modal,
} from 'antd';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { SetDefaultWarehouseDialog, message } from 'components/common';
import {
  SmartSpace,
  SmartFormLabel,
  GreenColor,
  SmartRow,
  SmartScanner,
  SmartBackButton,
  SmartSpin,
} from '../../SmartComponent';
import { resetInventory2 } from 'services/inventory';
import { queryLocationsByKeyword } from 'services/warehouse';
import {
  getWarehouseIdFromCache,
  getWarehouseCodeFromCache,
  getProfileSettingValue,
} from 'utils';
import { userProfiles } from 'utils/auth';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useTranslation } from 'react-i18next';
import AddItem from './AddItem';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import EditModal from './EditItem'

const { Text } = Typography;

type Item = {
  id: string;
  sku: string;
  upc?: string;
  lotNumber?: string;
  uom: string;
  rate: number;
  qty: number;
  uomList?: any[],
  lotNumberList?: any[],
}

export default function ResetInventory(): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const transactionReasons = useSelector(
    (state: RootState) => state.admin.transactionReasons,
    shallowEqual
  );
  const profile = userProfiles.profile;
  const [fetching, setFetching] = useState(false);
  const [step, setStep] = useState<'scanLocation' | 'scanItems' | 'addItem'>(
    'scanLocation'
  );
  const [items, setItems] = useState<Item[]>([]);
  const [notes, setNotes] = useState<string>(
    `Set location by ${profile.email}`
  );
  const [reason, setReason] = useState<string>('CycleCount');
  const [locationCode, setLocationCode] = useState<string>('');
  const [locationNum, setLocationNum] = useState<number>(-1);
  const [current, setCurrent] = useState<Item>();
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [totalInfo, setTotalInfo] = useState({
    skuCount: 0,
    totalQty: 0,
    totalBaseQty: 0,
  })
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [uomEnable, setUomEnable] = useState(true);
  const [lotEnable, setLotEnable] = useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openSetDefaultWarehouseDialog({ closable: false });
      }
    }
  }, [whs]);

  useEffect(() => {
    if (localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) {
      setWarehouseId(
        getWarehouseIdFromCache(
          Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
        )
      );
    }
  }, []);

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  const reFreshTotalInfo = useCallback(()=>{
    const skuArr:string[] = [];
    let totalQty = 0;
    let totalBaseQty = 0;
    items.map(i=> {
      skuArr.push(i.sku)
      totalQty = totalQty + i.qty;
      totalBaseQty = totalBaseQty + i.qty * i.rate
      return true
    })
    setTotalInfo({
      skuCount: [...new Set(skuArr)].length,
      totalQty,
      totalBaseQty,
    })

  },[items])

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  useEffect(()=>{
    reFreshTotalInfo()
  },[items, reFreshTotalInfo])

  const resetQty = async () => {
    try {
      setFetching(true);
      const itemParams = items.map((item) => {
        return {
          SKU: item.sku,
          Quantity: item.qty,
          LotNumber: item.lotNumber,
          Uom: item.uom,
          UomRate: item.rate
        };
      });
      const res = await resetInventory2(warehouseId, locationNum, {
        LocationNotes: notes,
        Items: itemParams,
        Reason: reason,
      });
      if(res){
        message.success('Confirm count successfully')
        setCurrent(undefined)
        setItems([])
        Modal.info({
          title: t('smart.audit.locationCount.confirmCount'),
          content: (
            <>
              <Row style={{marginBottom: 10}}>
                <Text>
                {t('smart.audit.locationCount.countWasConfirmed')}
                </Text>
              </Row>
              <Space size="small" direction="vertical">
                <span>{`${t('common.location')}: ${locationCode}`}</span>
                <span>{`${t('common.skuCount')}: ${totalInfo.skuCount}`}</span>
                <span>{`${t('common.totalQty')}: ${totalInfo.totalQty}`}</span>
                <span>{`${t('common.totalBaseQty')}: ${totalInfo.totalBaseQty}`}</span>
              </Space>
              <Row style={{marginTop: 10}}>
                <Text>
                {t('smart.audit.locationCount.nextContinue')}
                </Text>
              </Row>
              <Row>
              <SmartScanner
                style={{ marginTop: 10 }}
                onChangeValue={(value) => checkLocationCode(value)}
              />
              </Row>
            </>
            
          ),
          okText: t('common.done'),
          onOk() {
            setStep('scanLocation')
            setTotalInfo({
              skuCount: 0,
              totalQty: 0,
              totalBaseQty: 0,
            })
          },
        });
      }
      setFetching(false);
      if (res) {
      }
    } catch (error) {
      setFetching(false);
    }
  };

  const checkLocationCode = async (code: string) => {
    try {
      const res = await queryLocationsByKeyword(warehouseId, code, false);
      if (res) {
        setLocationCode(res.locationCode);
        setLocationNum(res.locationNum);
        setStep('scanItems');
        setTotalInfo({
          skuCount: 0,
          totalQty: 0,
          totalBaseQty: 0,
        })
        Modal.destroyAll()
      }
    } catch (error) {}
  };

  /* eslint-disable */
  useEffect(() => {
    if (step === 'scanLocation') {
      setNotes(`Set location by ${profile.email}`);
      setLocationNum(-1);
      setLocationCode('');
    }
  }, [step]);

  /* eslint-enable */

  return (
    <div style={{ marginTop: 20 }}>
      {step === 'scanLocation' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartSpace style={{ marginBottom: 10 }}>
            <SmartFormLabel
              style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
            >
              {t('smart.audit.locationCount.scanLocation')}
            </SmartFormLabel>
            <SmartScanner
              style={{ marginTop: 10 }}
              onChangeValue={(value) => checkLocationCode(value)}
            />
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              history.goBack();
            }}
          />
        </>
      )}

      {step === 'scanItems' && (
        <>
          <SmartSpin loading={fetching} />
          <SmartRow
            justify="space-between"
            style={{ marginTop: 10, marginBottom: 10, paddingLeft: 4, paddingRight: 4 }}
          >
            <Text style={{ fontSize: 16 }}>
              {t('smart.audit.locationCount.location')} :{' '}
              <Text>{locationCode}</Text>
            </Text>
            <Button
              onClick={() => {
                setCurrent(undefined);
                setStep('addItem');
              }}
              icon={<PlusOutlined />}
            >
              {t('smart.audit.locationCount.countItem')}
            </Button>
          </SmartRow>

          {items.length > 0 && (
            <Space>
              <span>{`${t('common.sku')}: ${totalInfo.skuCount}`}</span>
              <span>{`${t('common.qty')}: ${totalInfo.totalQty}`}</span>
              <span>{`${t('common.totalBaseQty')}: ${totalInfo.totalBaseQty}`}</span>
            </Space>
          )}

          {items.length > 0 && (
            <Card bodyStyle={{ padding: 4 }}>
              {items.map((item) => (
                <Card type="inner" style={{marginBottom: 4}} key={item.id}  bodyStyle={{ padding: 4 }}>
                  <Row justify="end">
                    <Button size="small" onClick={() => {
                      setCurrent(item)
                      setEditModalVisible(true)
                    }} icon={<EditFilled />}></Button>
                  </Row>
                  <Space direction="vertical">
                    <Typography.Text>{`${t('common.sku')}: ${item.sku}`}</Typography.Text>
                    <Typography.Text>{`${t('common.upc')}: ${item.upc}`}</Typography.Text>
                    {lotEnable && <Typography.Text>{`${t('common.lotNumber')}: ${item.lotNumber}`}</Typography.Text>}
                    {uomEnable && <Space>
                      <Typography.Text>{`${t('common.uom')}: ${item.uom}`}</Typography.Text>
                      <Typography.Text>{`${t('common.rate')}: ${item.rate}`}</Typography.Text>
                    </Space>}
                    <Space>
                      <Typography.Text>{`${t('common.qty')}: ${item.qty}`}</Typography.Text>
                      {uomEnable && <Typography.Text>{`${t('common.baseQty')}: ${item.qty * item.rate}`}</Typography.Text>}
                    </Space>
                  </Space>
                </Card>
              ))}
            </Card>
          )}

          <Form.Item
            label={
              <Text strong style={{ fontSize: 14 }}>
                {t('smart.audit.locationCount.notes')} :
              </Text>
            }
          >
            <Input.TextArea defaultValue={notes} rows={3} />
          </Form.Item>

          <Form.Item
            label={
              <Text strong style={{ fontSize: 14 }}>
                {t('smart.audit.locationCount.reason')} :
              </Text>
            }
          >
            <Select
              defaultValue={reason}
              onChange={(value: string) => setReason(value)}
            >
              {transactionReasons
                .filter((i) => i.type === 4)
                .map((item) => (
                  <Select.Option key={item.id} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>{' '}
          </Form.Item>

          <Row justify="start">
            <Button type="primary" disabled={items.length === 0} onClick={()=>{
              Modal.confirm({
                title: t('smart.audit.locationCount.confirmCount'),
                content: (
                  <>
                    <Row style={{marginBottom: 16}}>
                      <Text>
                        {t('smart.audit.locationCount.confirmCountMessage')}
                      </Text>
                    </Row>
                    <Space size="small" direction="vertical">
                      <span>{`${t('common.location')}: ${locationCode}`}</span>
                      <span>{`${t('common.skuCount')}: ${totalInfo.skuCount}`}</span>
                      <span>{`${t('common.totalQty')}: ${totalInfo.totalQty}`}</span>
                      <span>{`${t('common.totalBaseQty')}: ${totalInfo.totalBaseQty}`}</span>
                    </Space>
                  </>
                  
                ),
                okText: t('common.yes'),
                cancelText: t('common.no'),
                onOk() {
                  resetQty()
                },
                onCancel() {
                  return;
                },
              });
            }}>{t('smart.audit.locationCount.confirmCount')}</Button>
          </Row>

          <SmartBackButton
            onClick={() => {
              setStep('scanLocation');
            }}
          />
        </>
      )}

      {step === 'addItem' && (
        <>
          <AddItem
            locationCode={locationCode}
            lotEnable={lotEnable}
            uomEnable={uomEnable}
            onClose={() => {}}
            onSubmit={(list) => {
              setItems([...list]);
              setStep('scanItems');
            }}
          />
        </>
      )}

      {editModalVisible&& current&&
        <EditModal
          visible
          onHide={()=> setEditModalVisible(false)}
          onUpdate={(value)=>{
            const temp = items.filter(i=> i.id !== value.id)
            setItems([value,...temp])
            setEditModalVisible(false)
          }}
          onDelete={(id)=>{
            if(id){
              const temp = items.filter(i=> i.id !== id)
              setItems([...temp])
            }
            setEditModalVisible(false)
          }}
          info={current}
          lotEnable={lotEnable}        
          uomEnable={uomEnable}
        />
      }

      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </div>
  );
}
