import React, { useState, useEffect } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Select } from 'antd';
import { message, FormLabel } from 'components/common';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import {
  addProductCarrierSignature,
  updateProductCarrierSignature,
  getCarrierSignatures,
} from 'services/product';
import { fetchCarriers } from 'services/shipment';

interface AddProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  product: ProductRow;
  signatureOption?: any;
}

// eslint-disable-next-line
export default (props: AddProps) => {
  const { visible, onHide, onRefresh, product, signatureOption } = props;
  //   const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [carriers, setCarriers] = useState<CarrierRow[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<number | undefined>();
  const [options, setOptions] = useState<any[]>([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [form] = Form.useForm();

  const getCarriers = async () => {
    try {
      setLoading1(true);
      const res = await fetchCarriers();
      setLoading1(false);
      setCarriers(res);
      if (signatureOption) {
        setSelectedCarrier(signatureOption.carrierNum);
      }
    } catch (error) {
      setLoading1(false);
      console.log(error);
    }
  };

  const getSignatureOptions = async () => {
    try {
      setLoading2(true);
      const res = await getCarrierSignatures();
      setLoading2(false);
      if (res.isSuccess) {
        setOptions(res.data);
      }
    } catch (error) {
      setLoading2(false);
    }
  };

  const handleSubmit = async () => {
    const params = await form.validateFields();
    console.log(params);
    const postParams = {
      ProductCarrierSignatureNum: signatureOption
        ? signatureOption.productCarrierSignatureNum
        : 0,
      FulfillmentProductNum: product.id,
      CarrierSignatureNum: params.CarrierSignatureNum,
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!signatureOption) {
        res = await addProductCarrierSignature(postParams);
      } else {
        res = await updateProductCarrierSignature(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: signatureOption
            ? 'Saved successfully'
            : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    getSignatureOptions();
    getCarriers();
  }, []);
  /* eslint-enable */
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${signatureOption ? 'Edit' : 'Add'} Signature Option for ${product.sku
        }`}
      centered
      visible={visible}
      maskClosable={false}
      width={600}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="add_signature_modal_save_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              {signatureOption ? <SaveOutlined /> : <PlusOutlined />}
              {submitLoading
                ? `${signatureOption ? 'Saving' : 'Adding'}`
                : `${signatureOption ? 'Save' : 'Add'}`}
            </Button>
            <Button
              id="add_signature_modal_cancel_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading1 || loading2}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="CarrierNum"
            initialValue={signatureOption?.carrierNum}
            label={<FormLabel>Carrier</FormLabel>}
            rules={[{ required: true, message: 'Please select a carrier!' }]}
          >
            <Select
              id="carrier_select"
              disabled={signatureOption}
              onChange={(i) => {
                setSelectedCarrier(Number(i));
                form.setFieldsValue({ CarrierSignatureNum: undefined });
              }}
            >
              {carriers
                .filter((i) => i.carrierNum !== 0)
                .map((item) => (
                  <Select.Option key={item.carrierNum} value={item.carrierNum}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="CarrierSignatureNum"
            initialValue={signatureOption?.carrierSignatureNum}
            label={<FormLabel>Signature Option</FormLabel>}
            rules={[
              { required: true, message: 'Please select a signature option!' },
            ]}
          >
            <Select id="carrier_signature_selct" disabled={!selectedCarrier}>
              {options
                .filter((i) => i.carrierNum === selectedCarrier)
                .map((item) => (
                  <Select.Option
                    key={item.carrierSignatureNum}
                    value={item.carrierSignatureNum}
                  >
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
