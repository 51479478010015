import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  HeaderTitle,
  ColumnDataType,
  DataGridPlus,
  GreyCardWrapper,
  ClearButton,
  FormLabel,
  ExportCSV,
  SelectTransactionReason,
  SelectTransactionType,
  CopySpan,
  TablesFilter,
} from 'components/common';
import { RootState } from 'reducers';
import {
  Form,
  Row,
  Col,
  Button,
  Space,
  Typography,
  Select,
  Radio,
  DatePicker,
  Modal,
  Input,
  AutoComplete,
} from 'antd';
// import SearchSelect from 'components/Inventory/MoveMerge/SearchSelect';
import { exportBusinessFile } from 'services/files';
import { fetchInventoryLogs } from 'services/inventoryLog';
import { convertToUtcTime, trimValues } from 'utils';
// import SelectSerial from './SelectSerial';
// import SelectLocation from './SelectLocation';
// import SelectPo from './SelectPo';
// import SelectLot from './SelectLot';
// import MultipleSelectUser from './MultipleSelectUser';
// import MultipleSelectWarehouse from './MultipleSelectWarehouse';
import Tag from './Tag';
// import SelectProduct from './SelectProduct';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { SearchOutlined } from '@ant-design/icons';
import { userProfiles } from 'utils/auth';
// import DatePicker from './DatePicker';

const { Text } = Typography;

type ColumnRenderType = {
  data: any;
};

const formartTransactionTypes = (types: any[]) => {
  let temp: { [key: string]: any } = {};
  types.map((i: any) => {
    temp[`${i.id}`] = i.name;
    return true;
  });
  return temp;
};

export default function TransactionHistory() {
  const [logs, setLogs] = useState<Array<InventoryLogRow>>([]);
  const transactionTypes = useSelector(
    (state: RootState) => state.admin.transactionTypes,
    shallowEqual
  );
  const transactionTypeEnums = formartTransactionTypes(transactionTypes);
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSKU = searchParams.get('sku');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Array<ProductRow>>(
    defaultSKU ? [{ id: -1, productId: '', sku: defaultSKU }] : []
  );
  const [totalCount, setTotalCount] = useState(0);
  // const [serials, setSerials] = useState<Array<SerialNumberRow>>([]);
  const [lots, setLots] = useState<Array<LotNumberRow>>([]);
  // const [pos, setPos] = useState<Array<PoNumberRow>>([]);
  // const [locations, setLocations] = useState<WarehouseLocationRow[]>([]);
  const [locationList, setLocationList] = useState<WarehouseLocationRow[]>([]);
  const [options, setOptions] = useState<{ lable: string; value: string }[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useState<Array<WarehouseRow>>([]);
  const limitRef = React.useRef<number>(10);
  const skipRef = React.useRef<number>(0);
  const gridSort = React.useRef('TransactionDateTime');
  // const [userCheckAll, setUserCheckAll] = useState<boolean>(true);
  //users default undefined
  // const [users, setUsers] = useState<Array<WarehouseUserEmailRow>>();
  const [transactionType, setTransactionType] = useState<TransactionTypeRow>();
  const [transactionReason, setTransactionReason] =
    useState<TransactionReasonRow>();
  const [start, setStart] = useState<any>(
    moment().subtract(7, 'days').startOf('day')
  );
  const [end, setEnd] = useState<any>(moment().add(1, 'days').endOf('day'));
  const [form] = Form.useForm();
  const [searchFrom, setSearchFrom] = useState<number>(1);
  const [skuMode, setSkuMode] = useState<string>('SKU');
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [otherKeywordType, setOtherKeywordType] =
    useState<string>('divisionCode');
  const warehouses = useSelector((state: RootState) => {
    return state.admin.warehouses;
  }, shallowEqual);

  const enableLOT = userProfiles.getProfileSettingValue('EnableLOT') === '1';
  const enableUOM = userProfiles.getProfileSettingValue("EnableUOM") === '1';

  const history = useHistory();

  /**
   * handle product change event
   */
  // const handleProductChange = useCallback(
  //   (product: ProductRow) => {
  //     const item = products.find((currentItem: ProductRow) => {
  //       if (currentItem.id === product.id) {
  //         return currentItem;
  //       }
  //       return null;
  //     });
  //     if (!item) {
  //       products.push(product);
  //       setProducts(JSON.parse(JSON.stringify(products)));
  //     }
  //     //clear sku
  //     form.setFieldsValue({ sku: null });
  //   },
  //   [products, form]
  // );

  const fetchLocationList = async (warehouse: WarehouseRow) => {
    const conver2DataObject = (row: any) => {
      return {
        id: row.locationNum,
        code: row.locationCode,
        parentCode: row.parentCode,
        parent: row.parentNum,
        creator: row.createBy,
        reserved: row.reserved,
        backstock: row.backstock,
        warehouseId: row.warehouseId,
        ...row,
      };
    };
    fetchWarehouseLocationList(warehouse.warehouseId, false).then((list) => {
      setLocationList(
        list.map((item: any) => {
          return conver2DataObject(item);
        })
      );
      setOptions(
        list.map((i) => {
          return { lable: i.locationNum, value: i.locationCode };
        })
      );
    });
  };
  /**
   * handle user change event
   */
  // const handleUserChange = useCallback(
  //   (checked: boolean, newUsers: Array<WarehouseUserEmailRow>) => {
  //     setUserCheckAll(checked);
  //     setUsers(newUsers);
  //     //set form value
  //     form.setFieldsValue({ user: newUsers });
  //   },
  //   [form]
  // );

  /**
   * download logfile from api
   */
  const downloadFile = useCallback(
    async (SelectField) => {
      try {
        const query = {} as any;

        if (start) {
          //query['startTime'] = dayjs(start).format('YYYY-MM-DD HH:mm:ss');
          query['startTime'] = convertToUtcTime(start.format());
        }

        if (end) {
          //query['endTime'] = dayjs(end).format('YYYY-MM-DD HH:mm:ss');
          query['endTime'] = convertToUtcTime(end.format());
        }
        // if (products.length > 0) {
        //   query['sku'] = products.map((item) => {
        //     return item.sku;
        //   });
        // }
        const sku = form.getFieldValue('sku');
        if (sku) {
          skuMode === 'SKU' ? query['sku'] = [sku] : query['skuStartsWith'] = sku;
        }
        if (selectedRows && selectedRows.length > 0) {
          query['warehouseCode'] =
            selectedRows.length > 0 ? selectedRows[0].code : null;
          // selectedRows.map((item) => {
          //   return item.code;
          // });
        }
        const locationCode = form.getFieldValue('location');
        if (locationCode) {
          query['LocationCode'] = [locationCode];
        }
        // if (locations.length > 0) {
        //   query['locationCode'] =
        //     locations.length > 0 ? locations[0].locationCode : null;
        // }
        if (transactionType && transactionType.id > -1) {
          query['transactionType'] = transactionType.id;
        }
        if (transactionReason && transactionReason.id > -1) {
          query['transactionReasonName'] = transactionReason.name;
        }
        const users = form.getFieldValue('user');
        if (users && users.length > 0) {
          query['user'] = users.split(',');
        }
        query['dataSource'] = searchFrom;
        const otherKeyword = form.getFieldValue('otherKeyword');
        if (otherKeyword) {
          query[otherKeywordType] = otherKeyword;
        }
        const job = await exportBusinessFile(2, {
          FilterCondition: query,
          SelectField,
          OrderByField: gridSort.current,
        });
        if (job.jobguid && job.systemfilename) {
          Modal.confirm({
            title: 'job has been generated',
            okText: 'Close',
            cancelText: 'Check now',
            content:
              'job in progress ,you can check the job status in the export menu on the left side later.',
            onOk() { },
            onCancel() {
              history.push('/export');
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      start,
      end,
      // products,
      searchFrom,
      // serials,
      // lots,
      selectedRows,
      transactionType,
      transactionReason,
      // users,
      history,
      form,
      otherKeywordType,
      skuMode
    ]
  );
  /**
   * handle start change event
   */
  const handleStartChange = useCallback((data: any, dataString: string) => {
    setStart(data);
  }, []);
  /**
   * handle end change event
   */
  const handleEndChange = useCallback((data: any, dataString: string) => {
    setEnd(data);
  }, []);
  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
    setStart(moment().subtract(7, 'days').startOf('day'));
    setEnd(moment().add(1, 'days').endOf('day'));
    setProducts([]);
    // setSerials([]);
    setLots([]);
    setTransactionType(undefined);
    setTransactionReason(undefined);
    setSelectedRows([]);
    // setPos([]);
    // setUserCheckAll(false);
    // setUsers([]);
  }, [form]);
  /**
   * handle generate report
   */
  const handleGenerateReport = useCallback(
    async (defaultSKU?: string) => {
      form.setFieldsValue({ ...trimValues(form.getFieldsValue()) });
      setLoading(true);
      const query = {
        // StartTime: start ? dayjs(start).format('YYYY-MM-DDTHH:mm:00z') : null,
        // endTime: end ? dayjs(end).format('YYYY-MM-DDTHH:mm:00z') : null,
      } as any;
      if (start) {
        query['startTime'] = convertToUtcTime(start.format());
      }
      if (end) {
        //query['endTime'] = dayjs(end).format('YYYY-MM-DD HH:mm:ss');
        query['endTime'] = convertToUtcTime(end.format());
      }
      // if (products.length > 0) {
      //   query['SKU'] = products.map((item) => {
      //     return item.sku;
      //   });
      // }
      const sku = form.getFieldValue('sku');
      if (sku) {
        // query['SKU'] = [sku];
        skuMode === 'SKU' ? query['SKU'] = [sku] : query['SKUStartsWith'] = sku;
      }
      if (selectedRows && selectedRows.length > 0) {
        query['WarehouseCode'] = selectedRows.map((item) => {
          return item.code;
        });
      }
      const locationCode = form.getFieldValue('location');
      if (locationCode) {
        query['LocationCode'] = [locationCode];
      }
      // if (locations.length > 0) {
      //   query['LocationCode'] = locations.map((item) => {
      //     return item.locationCode;
      //   });
      // }
      if (transactionType && transactionType.id > -1) {
        query['TransactionType'] = transactionType.id;
      }
      if (transactionReason && transactionReason.id > -1) {
        query['TransactionReasonName'] = transactionReason.name;
      }
      const users = form.getFieldValue('user');
      if (users && users.length > 0) {
        query['User'] = users.split(',');
      }
      const otherKeyword = form.getFieldValue('otherKeyword');
      try {
        const { data, count } = await fetchInventoryLogs(
          query,
          skipRef.current,
          limitRef.current,
          gridSort.current,
          searchFrom,
          otherKeyword,
          otherKeywordType
        );
        // const newLogs = logs.sort((a: any, b: any) =>
        //   sortByDate(a, b, 'transactionDateTime')
        // );
        setLogs(data);
        setTotalCount(count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [
      form,
      start,
      end,
      // products,
      // serials,
      // lots,
      searchFrom,
      selectedRows,
      // locations,
      transactionType,
      transactionReason,
      // users,
      // pos,
      otherKeywordType,
      skuMode
    ]
  );
  const warehouseCodes: Array<string> = [];
  if (selectedRows) {
    selectedRows.forEach((item) => {
      warehouseCodes.push(item.code);
    });
  }
  // const userCodes: Array<string> = [];
  // if (users) {
  //   users.forEach((item) => {
  //     userCodes.push(item.userEmail);
  //   });
  // }

  const onSortChange = async (info: any) => {
    const sort = ['TransactionDateTime', ''];

    if (info) {
      let { dir, id } = info;

      sort[0] = id === 'title' ? 'TransactionDateTime' : id;
      sort[1] = dir > 0 ? 'asc' : 'desc';
      //console.log('-=>', id, dir);
    }

    const clause = sort.filter((e) => e[1]).join(' ');

    //setGridSort(clause);
    gridSort.current = clause;
    handleGenerateReport();
  };

  /* eslint-disable */
  useEffect(() => {
    if (defaultSKU) {
      handleGenerateReport(defaultSKU);
    }
  }, []);
  /* eslint-enable */

  const getColumns = useCallback(() => {
    const cls = [
      {
        header: 'Local Time',
        name: 'transactionDateTime',
        dataType: ColumnDataType.DATE,
        defaultFlex: 0,
        userSelect: true,
        minWidth: 200,
        // sort: () => 1,
      },
      {
        header: 'User',
        name: 'user',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
      },
      {
        header: 'Type',
        name: 'transactionType',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          const temp = transactionTypes.filter(
            (i) => i.id === data.transactionType
          );
          return (
            <CopySpan inGrid value={temp.length > 0 ? temp[0].name : ''} />
          );
        },
      },
      // {
      //   header: 'Item Scanned',
      //   name: 'scannedCode',
      //   sortable: false,
      //   defaultFlex: 0,
      // },
      {
        header: 'SKU',
        name: 'sku',
        minWidth: 220,
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
      },
      {
        header: 'UPC',
        name: 'upc',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
      },
      {
        header: 'LOT#',
        name: 'lotNumber',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
        lotColumn: true,
      },
      {
        header: 'UOM',
        name: 'uom',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
        uomColumn: true,
      },
      // {
      //   header: 'Serial Number',
      //   name: 'serialNumber',
      //   sortable: false,
      //   defaultFlex: 0,
      //   userSelect: true,
      // },
      {
        header: 'Product Name',
        name: 'title',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
      },
      {
        header: 'Rate',
        name: 'uomRate',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
        uomColumn: true,
      },
      {
        header: 'Base Qty',
        name: 'uomBaseQty',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
        uomColumn: true,
      },
      {
        header: 'Reason',
        name: 'transactionReasonName',
        sortable: false,
        defaultFlex: 0,
      },
      {
        header: 'Warehouse',
        name: 'warehouseCode',
        userSelect: true,
        sortable: false,
        defaultFlex: 0,
      },
      {
        header: 'Location',
        name: 'locationCode',
        sortable: false,
        userSelect: true,
        defaultFlex: 0,
      },
      {
        header: 'Relational ID',
        name: 'fulfillmentSaleId',
        sortable: false,
        userSelect: true,
        defaultFlex: 0,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <CopySpan inGrid value={data.transactionReasonName === 'Ship' || data.transactionReasonName === 'Void'  ? `ShipmentID: ${data.fulfillmentSaleId}` : data.fulfillmentSaleId} />
          );
        },
      },
      {
        header: 'Prior Quantity',
        name: 'beforeQuantity',
        sortable: false,
        userSelect: true,
        type: 'number',
        defaultFlex: 0,
      },
      {
        header: 'Transaction Quantity',
        name: 'transactionQuantity',
        sortable: false,
        userSelect: true,
        type: 'number',
        defaultFlex: 0,
      },
      {
        header: 'After Quantity',
        name: 'afterQuantity',
        sortable: false,
        userSelect: true,
        type: 'number',
        defaultFlex: 0,
      },
      {
        header: 'Enter Date',
        name: 'enterDate',
        dataType: ColumnDataType.DATE,
        defaultFlex: 0,
        userSelect: true,
        sortable: false,
        minWidth: 200,
      },
      {
        header: 'Log ID',
        name: 'logId',
        sortable: false,
        defaultFlex: 0,
        userSelect: true,
      },
    ];
    let temp = [...cls]
    temp = enableLOT ? [...temp] : temp.filter(i=> !i.lotColumn)
    temp = enableUOM ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  }, [transactionTypes, enableLOT, enableUOM]);
  return (
    <>
      <HeaderTitle breadcrumb={['Report', 'Transaction Log']} />
      <GreyCardWrapper>
        <Form form={form} layout="vertical">
          <Row>
            <Col span={6}>
              <Form.Item label={<FormLabel>Start</FormLabel>}>
                <DatePicker
                  allowClear={false}
                  showTime
                  format="MM/DD/YYYY hh:mm:ssA"
                  onChange={handleStartChange}
                  value={start}
                  style={{ width: '90%' }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label={<FormLabel>End</FormLabel>}>
                <DatePicker
                  allowClear={false}
                  showTime
                  format="MM/DD/YYYY hh:mm:ssA"
                  onChange={handleEndChange}
                  value={end}
                  style={{ width: '90%' }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<FormLabel>Warehouse</FormLabel>}
                name="warehouses"
              >
                {/* <MultipleSelectWarehouse
                onChange={(whs) => setSelectedRows(whs)}
              /> */}
                <Select
                  style={{ width: '90%' }}
                  allowClear
                  showSearch
                  optionFilterProp="label"
                  onChange={(value) => {
                    const temp = warehouses.filter(
                      (item) => item.code === value
                    );
                    form.setFieldsValue({ location: undefined });
                    if (temp.length > 0) {
                      fetchLocationList(temp[0]);
                    }
                    setSelectedRows(temp);
                  }}
                >
                  {warehouses.map((item) => (
                    <Select.Option
                      key={item.warehouseId}
                      value={item.code}
                      label={item.code}
                    >
                      {item.code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<FormLabel>Location / Container</FormLabel>}
                name="location"
              >
                {/* <SearchSelect
                  warehouseId={
                    selectedRows.length > 0
                      ? selectedRows[0].warehouseId
                      : undefined
                  }
                  locationType={0}
                  showPick={true}
                  style={{ width: '90%' }}
                  onChange={(value, row) => {
                    setLocations(row);
                  }}
                /> */}
                {/* <Select
                  style={{ width: '90%' }}
                  allowClear
                  disabled={selectedRows.length !== 1}
                  onChange={(value) =>
                    setLocations(
                      locationList.filter((i) => i.locationCode === value)
                    )
                  }
                >
                  {locationList.map((item) => (
                    <Select.Option
                      key={item.id}
                      value={item.locationCode || ''}
                    >
                      {item.locationCode}
                    </Select.Option>
                  ))}
                </Select> */}
                <AutoComplete
                  id="locations_auto_input"
                  style={{ width: '90%' }}
                  disabled={selectedRows.length !== 1}
                  options={options}
                  // style={{ width: 200 }}
                  // onSelect={onSelect}
                  onSearch={(value) =>
                    value
                      ? setOptions(
                        locationList
                          .map((i) => {
                            return {
                              lable: `${i.locationNum}`,
                              value: i.code,
                            };
                          })
                          .filter(
                            (j) =>
                              j.value
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                          )
                      )
                      : setOptions(
                        locationList.map((i) => {
                          return { lable: `${i.locationNum}`, value: i.code };
                        })
                      )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* 
          <Col span={5}>
            <Form.Item
              label={<FormLabel>Serial Number</FormLabel>}
              name="serial"
            >
              <SelectSerial
                placeholder={`${serials.length} serial number(s) selected`}
                onChange={handleSerialChange}
              />
            </Form.Item>
          </Col> */}
            {/* 
          <Col span={5}>
            <Form.Item label={<FormLabel>Lot Number</FormLabel>} name="lot">
              <SelectLot
                placeholder={`${lots.length} lots(s) selected`}
                onChange={handleLotChange}
              />
            </Form.Item>
          </Col> */}

            <Col span={6}>
              <Form.Item
                label={
                  <Select
                    bordered={false}
                    size="small"
                    style={{
                      minWidth: 150,
                      fontSize: 12,
                    }}
                    value={skuMode}
                    options={[
                      { value: 'SKU', label: <FormLabel>SKU/UPC equals</FormLabel> },
                      { value: 'SKUStartsWith', label: <FormLabel>SKU starts with</FormLabel> }
                    ]}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onChange={e => setSkuMode(e)}
                  >
                  </Select>
                }
                labelCol={{
                  style: { display: 'flex', flexDirection: 'row' }
                }}
                name="sku"
              >
                {/* <SelectProduct
                  placeholder={`${products.length} product(s) selected`}
                  onChange={handleProductChange}
                /> */}
                <Input type="text" allowClear style={{ maxWidth: '90%' }} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<FormLabel>Transaction Type</FormLabel>}
                name="transactionType"
              >
                <SelectTransactionType
                  onChange={(type) => {
                    setTransactionType(type);
                    form.setFieldsValue({
                      transactionType: type ? type.id : undefined,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={<FormLabel>Transaction Reason</FormLabel>}
                name="transactionReason"
              >
                <SelectTransactionReason
                  width="90%"
                  showAll
                  transactionType={transactionType ? transactionType.id : -1}
                  onChange={(reason) => {
                    setTransactionReason(reason);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="user" label={<FormLabel>User</FormLabel>}>
                {/* <MultipleSelectUser
                  disabled={selectedRows.length !== 1}
                  warehouse={
                    selectedRows.length === 1 ? selectedRows[0] : undefined
                  }
                  onChange={handleUserChange}
                /> */}
                <Input.TextArea style={{ maxWidth: '90%' }} autoSize />
              </Form.Item>
            </Col>
          </Row>
          {products.length > 0 && (
            <Row>
              <Text strong>Product selected</Text>
            </Row>
          )}
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Space wrap>
              <Tag
                title="Product SKU(s)"
                data={products}
                labelField="sku"
                valueField="id"
                onRemove={(newProducts: any) => {
                  setProducts(newProducts);
                }}
              />
              <Tag
                title="Lot Number(s)"
                data={lots}
                labelField="lotNumber"
                valueField="id"
                onRemove={(newLots: any) => {
                  setLots(newLots);
                }}
              />
            </Space>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name="other">
                <div style={{ display: 'flex', maxWidth: '90%' }}>
                  <Select
                    style={{
                      padding: 0,
                      width: '40%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    size="small"
                    bordered={false}
                    dropdownMatchSelectWidth={false}
                    value={otherKeywordType}
                    onChange={(value) => {
                      setOtherKeywordType(value);
                    }}
                  >
                    <Select.Option value="divisionCode">
                      <FormLabel>Division Code</FormLabel>
                    </Select.Option>
                    <Select.Option value="departmentCode">
                      <FormLabel>Department Code</FormLabel>
                    </Select.Option>
                    <Select.Option value="groupCode">
                      <FormLabel>Group Code</FormLabel>
                    </Select.Option>
                    <Select.Option value="subGroupCode">
                      <FormLabel>Subgroup Code</FormLabel>
                    </Select.Option>
                  </Select>
                  <Form.Item name="otherKeyword" style={{ width: '60%' }}>
                    <Input type="text" allowClear />
                  </Form.Item>
                </div>
              </Form.Item>
            </Col>
            <Col span={18}>
              <Space>
                <Col>
                  <Form.Item>
                    <FormLabel>Search From </FormLabel>
                    <Radio.Group
                      defaultValue={1}
                      onChange={(e) => setSearchFrom(e.target.value)}
                      style={{ marginLeft: 10 }}
                    >
                      <Radio value={1}>Current</Radio>
                      <Radio value={2}>History</Radio>
                      <Radio value={3}>Current and History</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Space>
                    <Button
                      type="primary"
                      loading={loading}
                      onClick={() => {
                        skipRef.current = 0;
                        handleGenerateReport();
                      }}
                    >
                      <SearchOutlined />
                      Search
                    </Button>
                    <ExportCSV
                      title="Export Transaction Log Report"
                      scope="Transaction Log Report"
                      columns={getColumns()}
                      data={logs}
                      formatColumns={{ transactionType: transactionTypeEnums }}
                      downloadFromApi={(value) => downloadFile(value)}
                    />
                    <ClearButton onClick={handleReset} />
                  </Space>
                </Col>
              </Space>
            </Col>
          </Row>
        </Form>
      </GreyCardWrapper>
      <GreyCardWrapper style={{ marginTop: 20 }}>
        <TablesFilter
          columns={getColumns()}
          dataSource={logs}
          setFilteredData={setFilteredData}
        />
        {transactionTypes.length > 0 && (
          <DataGridPlus
            name="transactionHistory"
            autoWith={false}
            showScrollButton
            idProperty="logId"
            skip={skipRef.current}
            pageSizes={[10, 50, 200, 500, 1000]}
            onSortInfoChange={onSortChange}
            onChange={(limit, skip) => {
              limitRef.current = limit;
              skipRef.current = skip;
              handleGenerateReport();
            }}
            count={totalCount}
            columns={getColumns()}
            loading={loading}
            dataSource={filteredData}
            pagination
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        )}
      </GreyCardWrapper>
    </>
  );
}
