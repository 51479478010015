import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Input,
  Row,
  Spin,
  Button,
  Modal,
  Space,
  Select,
  Col,
  Card,
} from 'antd';
import styled from 'styled-components';
import { message, FormLabel } from 'components/common';
import {
  CloseOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  updateOrdreraddress,
  // updateOrdrercarrier,
  editOrderMapservice,
} from 'services/orders';
import { getCountrys, getStates } from 'services/address';
// import { fetchCarriers, getCarrierServices } from 'services/shipment';
import { getShipAccountListWithShoppingAround } from 'services/storeSetup';
import { fetchServiceListByShipAccount } from 'services/ship';
import { formatAddress, sortByString } from 'utils';

const CardPlus = styled(Card)`
  & .ant-card-body {
    padding: 5px;
  }
`;

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  orderInfo: Order;
  orderId: string;
  editType: 'address' | 'carrier';
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, orderInfo, editType, orderId } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [stateList, setStateList] = useState<StateRow[]>([]);
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<
    CountryRow | undefined
  >();
  const [selectedState, setSelectedState] = useState<StateRow | undefined>();
  const [isOther, setIsOther] = useState<boolean>(false);
  // const [carriers, setCarriers] = useState<CarrierRow[]>([]);
  const [services, setServices] = useState<any[]>([]);
  const [shipAccountList, setShipAccountList] = useState<any[]>([]);
  const [carrierList, setCarrierList] = useState<any[]>([]);
  const [selectedShipAccount, setSelectedShipAccount] = useState<any>();

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;

    const tempService = params.MappedShippingService
      ? services.filter(
          (item) => item.carrierServiceNum === params.MappedShippingService
        )[0]
      : {};

    const postParams =
      editType === 'address'
        ? {
            ShipToName: params.ShipToName,
            ShipToFirstName: params.ShipToFirstName,
            ShipToLastName: params.ShipToLastName,
            ShipToCompany: params.ShipToCompany,
            ShipToCompanyJobTitle: params.ShipToCompanyJobTitle,
            ShipToAttention: params.ShipToAttention,
            ShipToDaytimePhone: params.ShipToDaytimePhone,
            ShipToNightPhone: orderInfo?.shipToNightPhone,
            ShipToAddressLine1: params.ShipToAddressLine1,
            ShipToAddressLine2: params.ShipToAddressLine2,
            ShipToAddressLine3: params.ShipToAddressLine3,
            ShipToCity: params.ShipToCity,
            ShipToState: isOther ? params.InputShipToState : selectedState? selectedState.code : orderInfo.shipToState,
            ShipToStateFullName: isOther ? params.InputShipToState: selectedState ? selectedState.name : orderInfo.shipToStateFullName,
            ShipToPostalCode: params.ShipToPostalCode,
            ShipToPostalCodeExt: params.ShipToPostalCodeExt,
            //   ShipToCounty: orderInfo.shipToCounty,
            ShipToCountry: selectedCountry
              ? selectedCountry.iso2
              : orderInfo.shipToCountry,
            ShipToEmail: params.ShipToEmail,
          }
        : {
            ShipAccountNum: selectedShipAccount.shipAccountNum,
            ShipCarrierNum: selectedShipAccount.carrierNum,
            ShipCarrier: selectedShipAccount.carrierName,
            ShipServiceNum: tempService.carrierServiceNum,
            ShipServiceCode: tempService.carrierServiceCode,
            ShipServiceName: tempService.carrierServiceName,
          };

    try {
      setSubmitLoading(true);
      let res;
      if (editType === 'address') {
        res = await updateOrdreraddress(orderId, postParams);
      } else {
        // res = await updateOrdrercarrier(
        //   orderInfo.fulfillmentOrderId,
        //   postParams
        // );
        res = await editOrderMapservice(
          orderInfo.fulfillmentOrderNum,
          postParams
        );
      }
      setSubmitLoading(false);
      if (res) {
        message.success({
          content: 'Updated successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const fetchStateList = useCallback(
    async (id: number, first?: boolean) => {
      //   if (!selectedCountry) return;
      try {
        setLoading(true);
        const res = await getStates(id);
        setLoading(false);
        if (res.isSuccess) {
          setStateList(
            res.data.sort((a: any, b: any) => sortByString(a, b, 'code'))
          );
          if (first) {
            const temp = res.data.filter(
              (i: StateRow) => i.code === orderInfo.shipToState
            );
            if (temp.length > 0) {
              setSelectedState(temp[0]);
            } 
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [orderInfo]
  );

  const fetchCountryList = async (first?: boolean) => {
    try {
      setLoading(true);
      const res = await getCountrys();
      setLoading(false);
      if (res.isSuccess) {
        setCountryList(res.data);
        if (first) {
          const temp = res.data.filter(
            (i: CountryRow) => i.iso2 === orderInfo.shipToCountry
          );
          if (temp.length > 0) {
            setSelectedCountry(temp[0]);
            fetchStateList(temp[0].countryNum, true);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };

  // const getCarriers = async (first: boolean) => {
  //   try {
  //     const res = await fetchCarriers();
  //     setCarriers(res);
  //     if (first) {
  //       if (orderInfo.mappedShippingCarrier) {
  //         const temp = res.filter(
  //           (i) => i.name === orderInfo.mappedShippingCarrier
  //         );
  //         if (temp.length > 0) {
  //           getServices(temp[0].carrierNum);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getServices = async (carrierNum: number) => {
  //   try {
  //     const res = await getCarrierServices(carrierNum);
  //     if (res.isSuccess) {
  //       setServices(res.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getShipAccountServices = async (shipAccountNum: number) => {
    try {
      const res = await fetchServiceListByShipAccount(shipAccountNum);
      if (res.isSuccess) {
        setServices(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getShipAccounts = async () => {
    try {
      const res = await getShipAccountListWithShoppingAround(
        orderInfo.channelAccountNum,
        orderInfo.warehouseNum
      );
      if (res.isSuccess) {
        const carrierList = Array.from(
          new Set(res.data.map((i: any) => i.carrierName))
        );
        setShipAccountList(res.data);
        setCarrierList(carrierList);
      }
    } catch (error) {}
  };

  /* eslint-disable */
  // useEffect(() => {
  //   fetchCountryList(true);
  //   // fetchStateList(10000);
  // }, []);
  useEffect(() => {
    if (editType === 'address') {
      fetchCountryList(true);
    } else {
      // getCarriers(true);
      getShipAccounts();
      // if (orderInfo.mappedShippingCarrier) {
      //   getServices(orderInfo.mappedShippingCarrier);
      // }
    }
  }, [editType]);
  /* eslint-enable */

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={
        editType === 'address'
          ? 'Edit Shipping Address'
          : 'Edit Shipping Carrier & Service'
      }
      centered
      visible={visible}
      maskClosable={false}
      width={720}
      bodyStyle={
        editType === 'address' ? { height: 450, overflowY: 'auto' } : {}
      }
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_address"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
            >
              <SaveOutlined />
              {submitLoading ? 'Saving' : 'Save'}
            </Button>
            <Button
              id="cancel_edit_address"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          {editType === 'address' && (
            <>
              <Form.Item
                name="ShipToCountry"
                initialValue={orderInfo.shipToCountry}
                label={<FormLabel>Country</FormLabel>}
                rules={[{ required: true, message: 'please select Country' }]}
              >
                <Select
                  id="country_selector"
                  onChange={(value) => {
                    form.setFieldsValue({
                      ShipToState: undefined,
                    });
                    setSelectedState(undefined);
                    const temp = countryList.filter((i) => i.iso2 === value)[0];
                    setSelectedCountry(temp);
                    fetchStateList(temp.countryNum);
                  }}
                >
                  {countryList.map((item) => (
                    <Select.Option key={item.countryNum} value={item.iso2}>
                      {`${item.name}(${item.iso2})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="ShipToName"
                initialValue={orderInfo.shipToName}
                label={<FormLabel>Name</FormLabel>}
                rules={[{ required: true, message: 'please input Name' }]}
              >
                <Input id="name_input" maxLength={34} />
              </Form.Item>
              <Form.Item
                name="ShipToFirstName"
                initialValue={orderInfo.shipToFirstName}
                label={<FormLabel>First Name</FormLabel>}
                // rules={[{ required: true, message: 'please input First Name' }]}
              >
                <Input id="firstname_input" />
              </Form.Item>
              <Form.Item
                name="ShipToLastName"
                initialValue={orderInfo.shipToLastName}
                label={<FormLabel>Last Name</FormLabel>}
                // rules={[{ required: true, message: 'please input Last Name' }]}
              >
                <Input id="lastname_input" />
              </Form.Item>

              <Form.Item
                name="ShipToCompany"
                initialValue={orderInfo.shipToCompany}
                label={<FormLabel>Company</FormLabel>}
              >
                <Input id="company_input" />
              </Form.Item>

              <Form.Item
                name="ShipToCompanyJobTitle"
                initialValue={orderInfo.shipToCompany}
                label={<FormLabel>Company Job Title</FormLabel>}
              >
                <Input id="company_job_title_input" />
              </Form.Item>

              <Form.Item
                name="ShipToAddressLine1"
                initialValue={orderInfo.shipToAddressLine1}
                label={<FormLabel>Address Line 1</FormLabel>}
                rules={[{ required: true, message: 'please input Address 1' }]}
              >
                <Input id="address_line1_input" />
              </Form.Item>
              <Form.Item
                name="ShipToAddressLine2"
                initialValue={orderInfo.shipToAddressLine2}
                label={<FormLabel>Address Line 2</FormLabel>}
              >
                <Input id="address_line2_input" />
              </Form.Item>
              <Form.Item
                name="ShipToAddressLine3"
                initialValue={orderInfo.shipToAddressLine3}
                label={<FormLabel>Address Line 3</FormLabel>}
              >
                <Input id="address_line3_input" />
              </Form.Item>

              <Form.Item
                name="ShipToCity"
                initialValue={orderInfo.shipToCity}
                label={<FormLabel>City</FormLabel>}
                rules={[{ required: true, message: 'please input city' }]}
              >
                <Input id="city_input" />
              </Form.Item>

                <Form.Item
                  name="ShipToState"
                  initialValue={orderInfo.shipToState}
                  label={<FormLabel>State</FormLabel>}
                  rules={[{ required: true, message: 'please select state' }]}
                >
                  <Select
                    id="state_selector"
                    showSearch
                    filterOption={(input, option) => {
                      if (option) {
                        const { children } = option;

                        if (typeof children === 'string') {
                          return (
                            (children as string)
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        }
                      }

                      return false;
                    }}
                    onChange={(value) => {
                      if(value !== '-1') {
                        setSelectedState(
                          stateList.filter((i) => i.code === value)[0]
                        );
                        setIsOther(false)
                      } else {
                        setSelectedState(undefined)
                        setIsOther(true)
                      }
                      
                    }}
                  >
                    {stateList.map((item) => (
                      <Select.Option key={item.stateNum} value={item.code}>
                        {`${item.name}(${item.code})`}
                      </Select.Option>
                    ))}
                    <Select.Option key="otherAddress" value="-1">Other</Select.Option>
                  </Select>
                </Form.Item>
              
                {isOther && <Form.Item
                  name="InputShipToState"
                  label={<FormLabel noColon>{' '}</FormLabel>}
                  rules={[
                    { required: true, message: 'please input state' },
                    {
                      len:
                        selectedCountry?.iso2 === 'US' ||
                        selectedCountry?.iso2 === 'CA'
                          ? 2
                          : undefined,
                      message: 'Please input the 2 letters abbreviation of the state you want to fill in.',
                    },
                  ]}
                >
                  <Input  placeholder='please input state'/>
                </Form.Item>
              }

              <Form.Item
                name="ShipToPostalCode"
                initialValue={orderInfo.shipToPostalCode}
                label={<FormLabel>Zip Code</FormLabel>}
                rules={[{ required: true, message: 'please input Zip Code' }]}
              >
                <Input id="zipCode_input" />
              </Form.Item>

              <Form.Item
                name="ShipToPostalCodeExt"
                initialValue={orderInfo.shipToPostalCodeExt}
                label={<FormLabel>Zip Code Ext</FormLabel>}
              >
                <Input id="zipCodeExt_input" />
              </Form.Item>

              <Form.Item
                name="ShipToDaytimePhone"
                initialValue={orderInfo.shipToDaytimePhone}
                label={<FormLabel>Phone #</FormLabel>}
              >
                <Input id="phone_input" />
              </Form.Item>
              {/* <Form.Item
                name="ShipToNightPhone"
                initialValue={orderInfo.shipToNightPhone}
                label={<FormLabel> Phone #</FormLabel>}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="ShipToEmail"
                initialValue={orderInfo.shipToEmail}
                label={<FormLabel>Email</FormLabel>}
              >
                <Input id="email_input" />
              </Form.Item>
              <Form.Item
                name="ShipToAttention"
                initialValue={orderInfo.shipToAttention}
                label={<FormLabel>Attention</FormLabel>}
              >
                <Input id="attention_input" />
              </Form.Item>
            </>
          )}
          {editType === 'carrier' && (
            <>
              {/* <Form.Item
                name="MappedShippingCarrier"
                // initialValue={orderInfo.mappedShippingCarrier}
                label={<FormLabel>Carrier</FormLabel>}
                rules={[{ required: true, message: 'please select carrier' }]}
              >
                <Select
                  onChange={(value) => {
                    setSelectedCarrier(Number(value));
                    const temp = carriers.filter(
                      (i) => i.carrierNum === Number(value)
                    )[0];
                    form.setFieldsValue({ MappedShippingService: undefined });
                    if (temp.carrierNum === 0) {
                      getServices(temp.carrierNum);
                    }
                  }}
                >
                  {carriers.map((item) => (
                    <Select.Option
                      key={item.carrierNum}
                      value={item.carrierNum}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}

              <Form.Item
                name="ShippingAccount"
                label={<FormLabel>Shipping Account</FormLabel>}
                rules={[
                  {
                    required: true,
                    message: 'please select shipping account',
                  },
                ]}
              >
                <Select
                  id="shipping_account_selector"
                  onChange={(value) => {
                    form.setFieldsValue({ MappedShippingService: undefined });
                    getShipAccountServices(Number(value));
                    setSelectedShipAccount(
                      shipAccountList.filter(
                        (i) => i.shipAccountNum === value
                      )[0]
                    );
                  }}
                >
                  {/* {shipAccountList.map((item) => (
                    <Select.Option
                      key={item.shipAccountNum}
                      value={item.shipAccountNum}
                    >
                      {item.shipAccountName}
                    </Select.Option>
                  ))} */}
                  {carrierList.map((item) => (
                    <Select.OptGroup label={item} key={item}>
                      {shipAccountList
                        .filter((i) => i.carrierName === item)
                        .map((child) => (
                          <Select.Option
                            key={child.shipAccountNum}
                            value={child.shipAccountNum}
                          >
                            {child.shipAccountName}
                          </Select.Option>
                        ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="carrier" label={<FormLabel>Carrier</FormLabel>}>
                {selectedShipAccount?.carrierName}
              </Form.Item>

              <Form.Item
                name="MappedShippingService"
                // initialValue={orderInfo.mappedShippingService}
                label={<FormLabel>Service</FormLabel>}
                rules={[{ required: true, message: 'please select service' }]}
              >
                <Select id="service_selector">
                  {services
                    // .filter((i) => i.carrierNum === selectedCarrier)
                    .map((item) => (
                      <Select.Option
                        key={item.carrierServiceNum}
                        value={item.carrierServiceNum}
                      >
                        {item.carrierServiceName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="requestShippingCarrier"
                label={<FormLabel>Request Shipping Carrier</FormLabel>}
              >
                {orderInfo?.requestShippingCarrier}
              </Form.Item>
              <Form.Item
                name="requestShippingService"
                label={<FormLabel>Request Shipping Service</FormLabel>}
              >
                {orderInfo?.requestShippingService}
              </Form.Item>
              <Form.Item
                name="Buyer/Recipient Address"
                label={<FormLabel>Shipping Address</FormLabel>}
              >
                <CardPlus>
                  <Col>
                    <Row>
                      {formatAddress(
                        'col',
                        orderInfo?.shipToName,
                        '',
                        orderInfo?.shipToAddressLine1,
                        `${
                          orderInfo?.shipToAddressLine2
                            ? orderInfo?.shipToAddressLine2
                            : ''
                        }`,
                        `${
                          orderInfo?.shipToAddressLine3
                            ? orderInfo?.shipToAddressLine3
                            : ''
                        }`,
                        orderInfo?.shipToCity,
                        orderInfo?.shipToState,
                        `${orderInfo?.shipToPostalCode}${
                          orderInfo?.shipToPostalCodeExt
                            ? `-${orderInfo?.shipToPostalCodeExt}`
                            : ''
                        }`,
                        orderInfo?.shipToCountry
                      )}
                    </Row>
                    <Row>
                      <span>{orderInfo?.shipToDaytimePhone}</span>
                    </Row>
                  </Col>
                  {/* {order && (
                    <ShipmentAddress
                      shipment={shipment}
                      onChangeShipment={onChangeShipment}
                    />
                  )} */}
                </CardPlus>
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};
