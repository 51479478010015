import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { ButtonIcon } from 'components/common';
import { playBell } from 'utils';
import {
  SmartRow,
  SmartSpace,
  SmartScanner,
  SmartBackButton,
  SmartLabel,
  SmartFormLabel,
  SmartText,
} from '../../SmartComponent';
import { scanSkuReturn } from 'services/smart';
import { Spin, Space } from 'antd';
import { useTranslation } from 'react-i18next';

type FailDialogProps = {
  warehouseNum: number;
  warehouseId: string;
  vWarehouseId: string;
  vWarehouseCode: string;
  product: any;
  location: string;
  onSubmit: (product: any) => void;
  onBack: () => void;
  showLocations: boolean;
};
export default function FailDialog(props: FailDialogProps): JSX.Element {
  const { onSubmit, product, onBack, location, warehouseId, showLocations, vWarehouseId, vWarehouseCode } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        setLoading(true);
        try {
          const res1 = await scanSkuReturn(vWarehouseId, nextValue)
            if (res1.isSuccess) {
              if(res1.data.locations.length === 0){
                setLoading(false);
                setErrorMessage(`cannot not find item [ ${nextValue} ] in [ ${vWarehouseCode} ]`);
              } else {
                const res = await scanSkuReturn(warehouseId, nextValue);
                setLoading(false);
                if (res.isSuccess) {
                  playBell('success');
                  onSubmit(res.data);
                } else {
                  if (res.code === 404) {
                    setErrorMessage(`Item "${nextValue}" is not valid!`);
                  }
                }
              }
            } else {
              setLoading(false);
              if (res1.code === 404) {
                setErrorMessage(`Item "${nextValue}" is not valid!`);
              }
            }
        } catch (error) {
          setLoading(false);
        }
      }
    },
    [onSubmit, warehouseId, vWarehouseCode, vWarehouseId]
  );

  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <Spin spinning={loading} style={{ width: '100%' }}>
          {errorMessage ? (
            <Space>
              <ButtonIcon
                className="bi-emoji-frown"
                style={{ width: 20, height: 20, color: 'red', fontSize: 28 }}
              />
              <div style={{ marginLeft: 5, color: 'red', fontSize: 16 }}>
                {errorMessage}
              </div>
            </Space>
          ) : (
            <Space>
              <ButtonIcon
                className="bi-emoji-smile"
                style={{ width: 20, height: 20, color: 'green', fontSize: 28 }}
              />
              <div
                style={{ marginLeft: 5, color: 'green', fontSize: 16 }}
              >{`Item "${product.sku}" was put into location “${location}” Successfully`}</div>
            </Space>
          )}

          <SmartSpace>
            <div
              style={{
                // color: 'blue',
                fontWeight: 600,
                fontSize: 16,
                marginTop: 5,
              }}
            >
              {t("smart.virtualWarehouseToLocation.receivingToLocation.scanNextItem")}
            </div>
            <SmartScanner
              id="scanItem2"
              style={{ marginTop: 10 }}
              isAutoFocus={1}
              onChangeValue={handleScannerChange}
            />
            {/* {errorMessage && (
              <div style={{ color: 'red', fontSize: 16, marginTop: 5 }}>
                {errorMessage}
              </div>
            )} */}
          </SmartSpace>
          <SmartRow style={{ marginTop: 10 }}>
            <SmartLabel>
              <SmartFormLabel>{t("common.sku")}</SmartFormLabel>
            </SmartLabel>
            <SmartText>{product ? product.sku : ''}</SmartText>
          </SmartRow>
          <SmartRow style={{ marginTop: 10 }}>
            <SmartLabel>
              <SmartFormLabel>{t("common.upc")}</SmartFormLabel>
            </SmartLabel>
            <SmartText>{product ? product.upc : ''}</SmartText>
          </SmartRow>
          <SmartRow style={{ marginTop: 10, marginBottom: showLocations ? 0 : 10}}>
            <SmartLabel>
              <SmartFormLabel>{t("common.title")}</SmartFormLabel>
            </SmartLabel>
            <SmartText>{product ? product.productTitle : ''}</SmartText>
          </SmartRow>
          {showLocations && <SmartRow style={{ marginTop: 10, marginBottom: 10 }}>
            <SmartLabel>
              <SmartFormLabel>{t("common.location")}</SmartFormLabel>
            </SmartLabel>
            <SmartText>{product.locations.join(', ')}</SmartText>
          </SmartRow>}
          <SmartBackButton
            onClick={() => {
              onBack();
            }}
          />
        </Spin>
      </SmartRow>
    );
  };
  return (
    <Modal
      visible
      width={300}
      title={<Title />}
      footer={null}
      closable={false}
    />
  );
}
