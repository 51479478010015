import React, { useCallback } from 'react';
import { Button, Row, Col, Table, Input, Form, Space, Select } from 'antd';
import { HeaderTitle } from 'components/common';
import { FormLabel } from 'components/common';
import { PrintLabel as PrintLabelDialog } from 'components/common';
import { GreyCardWrapper } from 'components/common';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { BoolCell, message } from 'components/common';

// eslint-disable-next-line
export default () => {
  const [warehouseNum, setWarehouseNum] = React.useState(-1);
  const [warehouseId, setWarehouseId] = React.useState('');
  const [data, setData] = React.useState<WarehouseLocationRow[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [printLabelVisible, setPrintLabelVisible] = React.useState(false);
  const [labelData, setLabelData] = React.useState<PrintLabelTypes[]>([]);
  // const [pageSize, setPageSize] = React.useState<any>(20)

  const [form] = Form.useForm();

  const onSelectWarehouse = (id: number, opt: any) => {
    try {
      // const json = JSON.parse(opt);
      setWarehouseId(opt.warehouseId);
    } catch (error) {
      console.log(error);
    }
    setWarehouseNum(id);
  };

  // const fetchLocationChildren = useCallback(async () => {
  //   if (locationId < 0) {
  //     message.error({ content: 'Location is required' });
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     setSelectedRowKeys([]);
  //     setData([]);
  //     const data = await fetchWarehouseLocationChildren(
  //       warehouseId,
  //       locationId
  //     );
  //     setLoading(false);
  //     setData([
  //       selectedLocation,
  //       ...data.map((item: any) => {
  //         return { id: item.locationNum, code: item.locationCode };
  //       }),
  //     ]);
  //   } catch (err) {}
  // }, [warehouseId, locationId, selectedLocation]);

  const fetchLocation = useCallback(async () => {
    const keyword = form.getFieldValue('keyWord');
    const sortBy = form.getFieldValue('sortBy');
    if (!warehouseId) {
      return message.error('warehouse info no found');
    }
    setSelectedRowKeys([]);
    setData([]);
    setLoading(true);
    try {
      const res = await fetchWarehouseLocationList(
        warehouseId,
        false,
        keyword,
        undefined,
        sortBy
      );
      setLoading(false);
      setData(
        res.map((item) => {
          return { ...item, code: item.locationCode };
        })
      );
    } catch (error) {
      setLoading(false);
    }
  }, [warehouseId, form]);

  // useEffect(() => {
  //   if (locationId > 0) {
  //     fetchLocationChildren();
  //   }
  // }, [locationId, fetchLocationChildren]);

  const columns = [
    {
      title: 'Location',
      dataIndex: 'code',
      align: 'center' as 'center',
    },
    {
      title: 'Is Container',
      dataIndex: 'code',
      width: 200,
      align: 'center' as 'center',
      render: (_: number, record: WarehouseLocationRow) => (
        <BoolCell value={record.parentCode ? true : false} />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'print',
      key: 'print',
      align: 'center' as 'center',
      width: 100,
      render: (_: number, record: WarehouseLocationRow) => (
        <Button
          onClick={() => {
            setPrintLabelVisible(true);
            setLabelData([
              {
                code: record.code + '',
                title: '',
                quantity: 1,
              },
            ]);
          }}
        >
          <PrinterOutlined />
          Print
        </Button>
      ),
    },
  ];

  return (
    <>
      <HeaderTitle breadcrumb={['Print', 'Print Location Labels']} />
      <GreyCardWrapper>
        <Form form={form}>
          <Row
            align="middle"
            justify="center"
            gutter={[24, 24]}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Col span={4}>
              <FormLabel>Warehouse</FormLabel>
              <WarehouseSelectBox onChange={onSelectWarehouse} />
            </Col>
            <Col span={4}>
              <FormLabel>Location</FormLabel>
              <Form.Item name="keyWord" noStyle>
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <FormLabel>Sort By</FormLabel>
              <Form.Item name="sortBy" noStyle>
                <Select style={{ width: '100%' }}>
                  <Select.Option value="LocationCode ASC">
                    Location Name Acending
                  </Select.Option>
                  <Select.Option value="LocationCode DESC">
                    Location Name descending
                  </Select.Option>
                  <Select.Option value="CreateDate ASC">
                    Enter Date Acending
                  </Select.Option>
                  <Select.Option value="CreateDate DESC">
                    Enter Date descending
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Space style={{ marginTop: 20 }}>
                <Button
                  disabled={warehouseNum < 0}
                  loading={loading}
                  onClick={() => fetchLocation()}
                >
                  <SearchOutlined />
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>

        <Row
          align="middle"
          justify="center"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          <Button
            type="primary"
            icon={<PrinterOutlined />}
            disabled={selectedRowKeys.length === 0}
            style={{ width: 360 }}
            onClick={() => {
              setPrintLabelVisible(true);
              setLabelData(
                selectedRowKeys.map((item) => {
                  return {
                    code: item + '',
                    title: '',
                    quantity: 1,
                  };
                })
              );
            }}
          >
            Print Selected Locations
          </Button>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Table
            style={{ width: '50%' }}
            loading={loading}
            columns={columns}
            rowKey={(item) => item.code}
            dataSource={data}
            bordered
            size="small"
            // onChange={(pagination)=>{
            //   // console.log(pagination)
            //   setPageSize(pagination.pageSize)
            // }}
            pagination={{
              defaultPageSize: 20,
              defaultCurrent: 1,
              total: data.length,
            }}
            
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) =>
                setSelectedRowKeys(selectedRowKeys),
            }}
          />
        </Row>
        {printLabelVisible && (
          <PrintLabelDialog
            visible={true}
            onHide={() => setPrintLabelVisible(false)}
            printType="location"
            labelData={labelData}
          />
        )}
      </GreyCardWrapper>
    </>
  );
};
