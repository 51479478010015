import { Table, Row } from 'antd';
import React, { useMemo } from 'react';
import { LikeOutlined } from '@ant-design/icons';
import './SmartTable.css';

type SmartTableProps = {
  dataSource: Array<SmartPickOrderItem>;
  showUom: boolean;
  showLot: boolean;
};
export default function SmartTable(props: SmartTableProps): JSX.Element {
  const { dataSource, showUom, showLot} = props;
  // const [data, setData] = useState<SmartPickOrderItem[]>([]);
  const columns = useMemo(() => {
    const cls = [
      {
        title: 'SKU',
        dataIndex: 'sku',
        align: 'center' as 'center',
      },
      {
        title: 'UPC',
        dataIndex: 'upc',
        align: 'center' as 'center',
      },
      {
        title: 'UOM',
        dataIndex: 'uom',
        align: 'center' as 'center',
      },
      {
        title: 'LOT#',
        dataIndex: 'lotNumber',
        align: 'center' as 'center',
      },
      {
        title: 'Qty',
        dataIndex: 'pickQty',
        align: 'center' as 'center',
      },
      {
        title: 'Scanned Qty',
        dataIndex: 'pickedQty',
        align: 'center' as 'center',
        render: (value: number, row: SmartPickOrderItem) => {
          const finished = row.pickQty === row.pickedQty;
          return (
            <Row justify="center" align="middle">
              <Row>{value}</Row>
              {finished && <LikeOutlined style={{ marginLeft: 5 }} />}
            </Row>
          );
        },
      },
    ];
    let temp = [...cls]
    temp = showLot ? temp : temp.filter(i=> i.dataIndex !== 'lotNumber')
    temp = showUom ? temp : temp.filter(i => i.dataIndex !== 'uom')
    return temp;
  }, [showUom, showLot]);
  // useEffect(() => {
  //   setData([...dataSource]);
  // }, [dataSource]);
  return (
    <Table
      bordered
      columns={columns}
      dataSource={dataSource}
      rowKey="sku"
      pagination={false}
      rowClassName={(row: SmartPickOrderItem) => {
        return row.pickQty > 0 && row.pickedQty < row.pickQty
          ? 'no-scanned'
          : '';
      }}
    />
  );
}
