import React from 'react';
import ContentLayout from 'components/ContentLayout';
import DeleteData from 'components/CleanTestData';

function CleanData() {
  return (
    <ContentLayout>
      <DeleteData />
    </ContentLayout>
  );
}

export default CleanData;
