import React, { useCallback, useEffect } from 'react';
import { Select, SelectProps } from 'antd';
import Input from './Input';

interface SelectPlusProps extends SelectProps<any> {
  edited?: boolean;
  outline?: boolean;
  dataSource: Array<StringKAnyVPair>;
}
/**
 * Extend the Select component
 * Feature:Support readonly status
 * @param {SelectPlusProps} props
 * @returns {JSX.Element}
 */
export default function SelectPlus(props: SelectPlusProps): JSX.Element {
  const { edited, outline, dataSource, value, onChange, ...other } = props;
  const { children } = props;
  const handleChange = useCallback(
    (value: string, option: any) => {
      if (typeof onChange === 'function') {
        onChange(value, option);
      }
    },
    [onChange]
  );
  useEffect(() => {
    if (!value) {
      //default option is first option
      if (dataSource.length > 0) {
        const [first] = dataSource;
        handleChange(first['value'], first);
      }
    }
  }, [value, dataSource, handleChange]);
  if (edited) {
    return (
      <Select
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
        value={value}
        onChange={handleChange}
      >
        {children}
      </Select>
    );
  } else {
    const { style, value } = props;
    const option = dataSource.find((item) => {
      if (item.value === value) {
        return item;
      }
      return undefined;
    });
    return (
      <Input
        style={style}
        edited={false}
        outline={outline}
        value={option ? option.name : ''}
      />
    );
  }
}
SelectPlus.defaultProps = {
  edited: undefined,
  outline: undefined,
};
