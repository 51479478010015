import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Input, List, Row, Typography } from 'antd';
import { playAudio } from 'utils';
import { DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Text } = Typography;
type ScanningGunInputProps = {
  rows?: number;
  value?: string;
  autoCount?: boolean;
  onChange?: (value: string) => void;
  defaultValue?: string;
  loading?: boolean;
  placeholder?: string;
  targetStrs?: string[];
  tempStr?: string;
};
export default function ScanningGunInput(
  props: ScanningGunInputProps
): JSX.Element {
  const {
    onChange,
    rows,
    value,
    autoCount = true,
    defaultValue,
    loading,
    targetStrs,
    tempStr,
  } = props;
  const [end, setEnd] = useState(false);
  const textInput = useRef<any>(null);
  const [valueStatus, setValueStatus] = useState<any>({});

  const getStrNums = (data: string) => {
    const arr = data.split('\n');
    var map: any = {};
    for (let i = 0; i < arr.length; i++) {
      const key = arr[i];
      if (!key) continue;
      if (map[key]) {
        map[key] += 1;
      } else {
        map[key] = 1;
      }
    }
    return map;
  };

  const removeStr = (str: string) => {
    let r = textInput.current.resizableTextArea.props?.value || '';
    r = r.substr(r.length -1 ,1) === '\n' ? r : `${r}\n`;
    const newR = r.replaceAll(`${str}\n`, '');
    setValueStatus(getStrNums(newR || ''));
    if (typeof onChange === 'function') {
      onChange(newR);
    }
  };

  useEffect(() => {
    if (tempStr) {
      setValueStatus(getStrNums(tempStr));
    }
  }, [tempStr]);

  useEffect(() => {
    if (defaultValue && textInput.current && onChange) {
      textInput.current.focus();
      setValueStatus(getStrNums(defaultValue || ''));
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);
  return (
    <Row>
      <Col span={autoCount ? 12 : 24}>
        <TextArea
          id="scanning_gun_input"
          placeholder={props.placeholder}
          value={defaultValue ? defaultValue : value}
          rows={rows || 5}
          disabled={loading}
          ref={textInput}
          style={{ height: 'auto' }}
          onKeyDown={(e) => {
            const { key } = e;
            if (key === 'Tab') {
              //convert Tab to Enter
              if (!end) {
                const input = textInput.current as any;
                const { innerHTML } = input.resizableTextArea.textArea;
                const newValue = `${innerHTML}\n`.replace('\n\n', '\n');
                setValueStatus(getStrNums(newValue));
                if (typeof onChange === 'function') {
                  onChange(newValue);
                }
                playAudio('success');
                e.preventDefault();
              } else {
                console.log('koko');
              }
            }
          }}
          onChange={(e) => {
            //support auto bottom
            const { value: newValue } = e.target;
            if (typeof onChange === 'function') {
              onChange(newValue);
            }
            e.target.scrollTop = e.target.scrollHeight;
            //judge input end
            if (
              newValue.length >= 2 &&
              newValue.substring(newValue.length - 2, newValue.length) ===
                '\n\n'
            ) {
              setEnd(true);
            }
          }}
        />
      </Col>
      {autoCount && <Col span={1} />}
      <Col span={11}>
        <List size="small" style={{ maxHeight: 230, overflowY: 'auto' }}>
          {Object.keys(valueStatus).map((i: string) => (
            <List.Item
              key={i}
              actions={[
                <Text>{valueStatus[i]}</Text>,
                <Button
                  onClick={() => removeStr(i)}
                  size="small"
                  icon={<DeleteOutlined />}
                ></Button>,
              ]}
            >
              {targetStrs?.indexOf(i) === -1 ? (
                <Text
                  style={{ width: 180 }}
                  ellipsis={{ tooltip: i }}
                  type="warning"
                  delete
                >
                  {i}
                </Text>
              ) : (
                <Text
                  style={{ width: 180 }}
                  ellipsis={{ tooltip: i }}
                  type="success"
                >
                  {i}
                </Text>
              )}
            </List.Item>
          ))}
        </List>
      </Col>
    </Row>
  );
}
/**
 * parse result
 * @param {string} value
 * @return {Array<string>}
 */
export function parseScanningGunResult(value: string): Array<string> {
  const items = value.split('\n').filter((item: string) => {
    if (item.length > 0) {
      return true;
    }
    return false;
  });
  return items;
}
ScanningGunInput.defaultProps = {
  rows: undefined,
  value: undefined,
  autoCount: undefined,
  onChange: undefined,
  loading: false,
  defaultValue: undefined,
  placeholder: undefined,
  targetStrs: undefined,
};
