import React, { useCallback, useState } from 'react';
import { Modal, Row, Button, Space } from 'antd';
import { DataGridPlus, message } from 'components/common';
// import { receive } from 'services/purchaseOrder';
import { moveReturn } from 'services/return';
import { CloseOutlined, DiffOutlined } from '@ant-design/icons';
// import moment from 'moment';

type Props = {
  onHide: () => void;
  lines: any[];
  returnNum: number;
  warehouse: WarehouseRow;
  damageWarehouseNum: number;
  returnWarehouseNum: number;
  onRefresh: (newData:any) => void;
  dateString: string;
};
// eslint-disable-next-line
export default (props: Props) => {
  const {
    onHide,
    lines,
    onRefresh,
    returnNum,
    warehouse,
    damageWarehouseNum,
    returnWarehouseNum,
  } = props;
  const [loading, setLoding] = useState(false);

  /**
   * handle hide event
   */
  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleReceive = useCallback(async () => {
    setLoding(true);
    const Items = lines.map((item) => {
      return {
        FulfillmentReturnLineNum: item.fulfillmentReturnLineNum,
        ReturnLocationNum: item.stockingLocationNum,
        DamageLocationNum: item.damageingLocationNum,
        ReturnQty: item.stockingQty || 0,
        DamageQty: item.damageingQty || 0,
      };
    });
    try {
      const res = await moveReturn(warehouse.warehouseId, returnNum, {
        ReturnWarehouseNum: returnWarehouseNum,
        DamageWarehouseNum: damageWarehouseNum,
        Items,
      });
      setLoding(false);
      if (res) {
        message.success({ content: 'stock successful' });
        onRefresh(res);
      } else {
        message.error({ content: 'stock failed' });
      }
    } catch (error: any) {
      // message.error({ content: error.response.data.error });
      setLoding(false);
    }
  }, [
    returnNum,
    lines,
    onRefresh,
    warehouse,
    damageWarehouseNum,
    returnWarehouseNum,
  ]);

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    // {
    //   name: 'picture',
    //   defaultFlex: 1,
    //   resizable: false,
    //   sortable: false,
    //   maxWidth: 100,
    //   minWidth: 100,
    //   textAlign: 'center',
    //   textVerticalAlign: 'middle',
    //   renderHeader: () => 'Picture',
    //   render: (value: ColumnRenderType) => {
    //     const { data } = value;

    //     return (
    //       <Image
    //         width={32}
    //         height={32}
    //         preview={false}
    //         src={data.thumbnailUrl || 'error'}
    //         fallback={fallbackImage}
    //         onClick={() => {
    //           console.log('click picture', data);
    //         }}
    //         style={{ cursor: 'pointer' }}
    //       />
    //     );
    //   },
    // },
    {
      name: 'stockingQty',
      header: 'Stock Qty',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },

    {
      name: 'stockingLocationCode',
      header: 'Stock Location',
      showColumnMenuTool: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'damageingQty',
      header: 'Damage Qty',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },

    {
      name: 'damageingLocationCode',
      header: 'Damage Location',
      userSelect: true,
      showColumnMenuTool: true,
      defaultFlex: 1,
    },
  ];

  return (
    <Modal
      title="Restock"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleHide}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="receive_button"
              type="primary"
              disabled={lines.length === 0}
              loading={loading}
              onClick={() => handleReceive()}
              icon={<DiffOutlined />}
            >
              {loading ? 'Restocking' : 'Restock'}
            </Button>
            <Button id="cancel_button" onClick={handleHide} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <DataGridPlus
        columns={columns}
        dataSource={lines}
        idProperty="fulfillmentTransferLineNum"
        {...({} as any)}
      />
    </Modal>
  );
};
