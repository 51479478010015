import React, { useEffect, useRef } from 'react';
import { InputNumber, InputNumberProps, Input } from 'antd';

interface InputNumberPlusProps extends InputNumberProps {
  edited?: boolean;
  outline?: boolean;
}
/**
 * Extend the InputNumber component
 * Feature:Support readonly status
 */
export default function InputNumberPlus(
  props: InputNumberPlusProps
): JSX.Element {
  const { edited, autoFocus, outline, ...other } = props;
  const input = useRef(null);
  useEffect(() => {
    if (edited && autoFocus) {
      const textarea = input.current as any;
      textarea.focus();
    }
  }, [edited, autoFocus]);
  if (edited) {
    return (
      <InputNumber
        ref={input}
        /* eslint-disable react/jsx-props-no-spreading */
        {...other}
      />
    );
  } else {
    return (
      <Input
        /* eslint-disable react/jsx-props-no-spreading */
        {...(other as any)}
        readOnly
        className={
          outline ? 'form-input-readonly-outline' : 'form-input-readonly'
        }
      />
    );
  }
}
InputNumberPlus.defaultProps = {
  edited: undefined,
  outline: undefined,
};
