import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Button,
  Select,
  Row,
  Space,
  Card,
  Typography,
  InputNumber,
} from 'antd';
import {
  SmartScanner,
  SmartSpace,
  SmartFormLabel,
  GreenColor,
} from '../../SmartComponent';
import Text from 'antd/lib/typography/Text';
// import { useTranslation } from 'react-i18next';
import {
  queryProductByKeyword,
  getProductLotList,
  getProductUomList,
} from 'services/product';
import { EditFilled } from '@ant-design/icons';
import { FormLabel } from 'components/common';
import { UnlockFilled, LockFilled } from '@ant-design/icons';
import EditModal from './EditItem'
import { useTranslation } from 'react-i18next';

type AddItemProps = {
  locationCode: string;
  lotEnable: boolean;
  uomEnable: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
};
type Info = {
  productId: string;
  sku: string;
  upc: string;
  uomList: {
    productUomNum: number;
    name: string;
    uom: string;
    rate: number;
  }[];
  lotNumberList: any[];
};

type Item = {
  id: string;
  sku: string;
  upc?: string;
  lotNumber?: string;
  uom: string;
  rate: number;
  qty: number;
  uomList?: any[],
  lotNumberList?: any[],
}

export default function AddItem(props: AddItemProps): JSX.Element {
  const { onSubmit, lotEnable, uomEnable } = props;
  const { t } = useTranslation();
  const [itemInfo, setItemInfo] = useState<Info>();
  const [itemList, setItemList] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalInfo, setTotalInfo] = useState({
    skuCount: 0,
    totalQty: 0,
    totalBaseQty: 0,
  });
  const [qtyLock, setQtyLock] = useState(false);
  const [uomLock, setUomLock] = useState(false);
  const [lotNumberLock, setLotNumberLock] = useState(false);
  const [selectedUom, setSelectedUom] = useState<any>();
  const [qty, setQty] = useState<number>(1);
  const [current, setCurrent] = useState<Item>()
  const [editModalVisible, setEditModalVisible] = useState(false);
  // const { t } = useTranslation()
  const [form] = Form.useForm();

  const resetForm = useCallback(() => {
    if (!qtyLock) {
      setQty(1);
    }
    // setUomLock(false);
    // setLotNumberLock(false);
    if(!lotNumberLock) {
      form.setFieldsValue({
        lotNumber: undefined,
      });
    }
    if(!uomLock) {
      form.setFieldsValue({
        uom: undefined,
      });
      setSelectedUom(undefined);
    }
  }, [form, qtyLock, lotNumberLock, uomLock]);

  const onAddItem = useCallback(() => {
    const formValue = form.getFieldsValue();
    const temp = [...itemList];
    const id = `${itemInfo?.sku}-${formValue?.lotNumber || ''}-${
      formValue?.uom || ''
    }`;
    const index = itemList.findIndex((i) => i.id === id);
    if (index === -1) {
      temp.push({
        id,
        sku: itemInfo?.sku || '',
        upc: itemInfo?.upc,
        lotNumber: formValue.lotNumber,
        uom: formValue.uom,
        rate: selectedUom?.uomRate || 1,
        qty: qty,
        uomList: itemInfo?.uomList,
        lotNumberList: itemInfo?.lotNumberList,
      });
    } else {
      temp[index] = {
        ...temp[index],
        qty: temp[index].qty + qty,
      };
    }
    setItemList([...temp]);
    // setQty(1)
    resetForm();
  }, [form, itemInfo, itemList, selectedUom, qty, resetForm]);

  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(async (value?: string) => {
    if (!value) return;
    if (value === itemInfo?.sku || value === itemInfo?.upc) {
      if (lotNumberLock && uomLock && qtyLock) {
        onAddItem();
      } else {
        setQty((prev) => prev + 1);
      }
    } else {
      resetForm();
      try {
        setLoading(true);
        const res = await queryProductByKeyword(value);
        setLoading(false);
        if (res && res.length === 1) {
          const res2 = await getProductLotList(res[0].productId);
          const res3 = await getProductUomList(res[0].sku);
          setItemInfo({
            ...res[0],
            lotNumberList: res2.isSuccess ? res2.data : [],
            uomList: res3.isSuccess ? res3.data : [],
          });
          if (res2.isSuccess && res2.data.length === 1) {
            form.setFieldsValue({
              lotNumber: res2.data[0].lotNumber,
            });
          }
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [itemInfo, lotNumberLock, uomLock, qtyLock, form, onAddItem, resetForm]);

  const reFreshTotalInfo = useCallback(()=>{
    const skuArr:string[] = [];
    let totalQty = 0;
    let totalBaseQty = 0;
    itemList.map(i=> {
      skuArr.push(i.sku || '')
      totalQty = totalQty + i.qty;
      totalBaseQty = totalBaseQty + i.qty * i.rate
      return true
    })
    setTotalInfo({
      skuCount: [...new Set(skuArr)].length,
      totalQty,
      totalBaseQty,
    })

  },[itemList])

  useEffect(()=>{
    reFreshTotalInfo()
  },[itemList, reFreshTotalInfo])

  return (
    <>
      <SmartSpace style={{ marginBottom: 10 }}>
        <SmartFormLabel
          style={{ color: GreenColor, fontWeight: 600, fontSize: 18 }}
        >
          {t('common.item')}
        </SmartFormLabel>
        <SmartScanner
          style={{ marginTop: 10 }}
          disabled={loading}
          onChangeValue={handleScannerChange}
        />
      </SmartSpace>
      {itemInfo && (
        <Form form={form}>
          <Row justify="start" style={{ marginBottom: 8 }}>
            <FormLabel>{t('common.sku')}</FormLabel>
            <Typography.Text>{itemInfo.sku}</Typography.Text>
          </Row>
          {lotEnable && (
            <Row justify="start" style={{ marginBottom: 8 }}>
              <FormLabel>{t('common.lotNumber')}</FormLabel>
              <Space>
                <Form.Item noStyle name="lotNumber">
                  <Select style={{ width: 200, marginLeft: 4 }}>
                    {itemInfo.lotNumberList.map((i) => (
                      <Select.Option key={i.productLotNum} value={i.lotNumber}>
                        {i.lotNumber}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {lotNumberLock ? (
                  <LockFilled
                    onClick={() => setLotNumberLock((prev) => !prev)}
                    style={{ color: 'red', fontSize: 24 }}
                  />
                ) : (
                  <UnlockFilled
                    onClick={() => setLotNumberLock((prev) => !prev)}
                    style={{ fontSize: 24 }}
                  />
                )}
              </Space>
            </Row>
          )}
          {uomEnable && (
            <Row justify="start" style={{ marginBottom: 8 }}>
              <FormLabel>{t('common.uom')}</FormLabel>
              <Space>
                <Form.Item noStyle name="uom">
                  <Select
                    style={{ width: 100, marginLeft: 4 }}
                    onChange={(value) => {
                      if (value) {
                        const temp = itemInfo.uomList.filter(
                          (i) => i.uom === value
                        );
                        setSelectedUom(temp[0]);
                      } else {
                        setSelectedUom(undefined);
                      }
                    }}
                  >
                    {itemInfo.uomList.map((i) => (
                      <Select.Option key={i.productUomNum} value={i.uom}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {uomLock ? (
                  <LockFilled
                    onClick={() => setUomLock((prev) => !prev)}
                    style={{ color: 'red', fontSize: 24 }}
                  />
                ) : (
                  <UnlockFilled
                    onClick={() => setUomLock((prev) => !prev)}
                    style={{ fontSize: 24 }}
                  />
                )}
                <Text>
                  <FormLabel>{t('common.rate')}</FormLabel>
                  {selectedUom?.uomRate}
                </Text>
              </Space>
            </Row>
          )}

          <Row justify="start" style={{ marginBottom: 8 }}>
            <FormLabel>{t('common.qty')}</FormLabel>
            <Space>
              <Form.Item noStyle>
                <InputNumber
                  min={1}
                  value={qty}
                  style={{ width: 100, marginLeft: 4 }}
                  onChange={(e) => setQty(e)}
                />
              </Form.Item>
              {qtyLock ? (
                <LockFilled
                  onClick={() => setQtyLock((prev) => !prev)}
                  style={{ color: 'red', fontSize: 24 }}
                />
              ) : (
                <UnlockFilled
                  onClick={() => setQtyLock((prev) => !prev)}
                  style={{ fontSize: 24 }}
                />
              )}
              {selectedUom && uomEnable && (
                <Text>
                  <FormLabel>{t('common.baseQty')}</FormLabel>
                  {qty * selectedUom.uomRate}
                </Text>
              )}
            </Space>
          </Row>

          <Row justify="space-around">
            <Button type="primary" onClick={() => onAddItem()}>
              {t('common.submit')}
            </Button>
            <Button type="primary" onClick={() => onSubmit(itemList)}>
              {t('common.done')}
            </Button>
          </Row>
          {itemList.length > 0 && (
            <Space>
              <span>{`SKU: ${totalInfo.skuCount}`}</span>
              <span>{`Qty: ${totalInfo.totalQty}`}</span>
              <span>{`Total Base Qty: ${totalInfo.totalBaseQty}`}</span>
            </Space>
          )}

          {itemList.length > 0 && (
            <Card bodyStyle={{ padding: 4 }}>
              {itemList.map((item) => (
                <Card type="inner" style={{marginBottom: 4}} key={item.id} bodyStyle={{ padding: 4 }}>
                  <Row justify="end">
                    <Button size="small" onClick={() => {
                      setCurrent(item)
                      setEditModalVisible(true)
                    }} icon={<EditFilled />}></Button>
                  </Row>
                  <Space direction="vertical">
                    <Typography.Text>{`${t('common.sku')}: ${item.sku}`}</Typography.Text>
                    <Typography.Text>{`${t('common.upc')}: ${item.upc}`}</Typography.Text>
                    {lotEnable && <Typography.Text>{`${t('common.lotNumber')}: ${item.lotNumber || ''}`}</Typography.Text>}
                    {uomEnable && <Space>
                      <Typography.Text>{`${t('common.uom')}: ${item.uom || ''}`}</Typography.Text>
                      <Typography.Text>{`${t('common.rate')}: ${item.rate || ''}`}</Typography.Text>
                    </Space>}
                    <Space>
                      <Typography.Text>{`${t('common.qty')}: ${item.qty}`}</Typography.Text>
                      {uomEnable && <Typography.Text>{`${t('common.baseQty')}: ${
                        item.rate * item.qty
                      }`}</Typography.Text>}
                    </Space>
                  </Space>
                </Card>
              ))}
            </Card>
          )}
        </Form>
      )}
      {editModalVisible&& current&&
        <EditModal
          visible
          onHide={()=> setEditModalVisible(false)}
          onUpdate={(value)=>{
            const temp = itemList.filter(i=> i.id !== value.id)
            setItemList([value,...temp])
            setEditModalVisible(false)
          }}
          onDelete={(id)=>{
            if(id){
              const temp = itemList.filter(i=> i.id !== id)
              setItemList([...temp])
            }
            setEditModalVisible(false)
          }}
          info={current}
          lotEnable={lotEnable}        
          uomEnable={uomEnable}
        />
      }
    </>
  );
}
