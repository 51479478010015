import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import CheckProgress from 'components/Sales/Smart/Prepack/CheckPackProgress';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartUnpackItem(): JSX.Element {
  return (
    <ContentLayout 
        titleKey="titles.checkPackProgress" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
     >
      <CheckProgress />
    </ContentLayout>
  );
}
