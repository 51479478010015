import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import ReceivingToLocation from 'components/Sales/Smart/VirtualWarehouseToLocation/ReceivingToLocation';
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function SmartReceivingToLocationScreen(): JSX.Element {
  return (
    <ContentLayout 
    titleKey="titles.receivingToLocation" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
    // title={`Receiving to Location(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <ReceivingToLocation />
    </ContentLayout>
  );
}
