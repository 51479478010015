import React from 'react';
import { /*useTranslation,*/ Trans } from 'react-i18next';
import { /*useDispatch,*/ shallowEqual, useSelector } from 'react-redux';
import {
  //Button,
  Col,
  Row,
  Select,
} from 'antd';
import styled from 'styled-components';
import { Loading } from 'components/common';
import { LOADING_ICON_SIZE1 } from 'constants/config';
import { RootState } from 'reducers';
import { fetchSiteConfig } from  'utils';
import { userProfiles } from 'utils/auth';

type Props = {
  onChange?: Function;
  profiles: StringKAnyVPair[];
};

const WelcomeWrapper = styled.div`
  width: 100%;
  background-color: #f5f6fa;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & .logo-img {
    width: 185px;
  }
`;

const WelcomeText = styled.p`
  font-family: Lato;
  font-size: 28px;
  font-weight: normal;
  color: #2d3f5d;
`;

const ProfileSelector = (props: Props) => {
  /*const locale: string =*/ useSelector(
    (state: RootState) => state.regular.locale,
    shallowEqual
  );

  const siteCfg = fetchSiteConfig();

  const onSelectProfile = (value: any) => {
    if (typeof props.onChange === 'function') {
      props.onChange(value);
      userProfiles.saveCurrentProfileToBrowser();
    }
  };

  const profileOptions = (list: StringKAnyVPair[]) => {
    return list.map((e, i) => (<Select.Option key={i} value={i}>
        {e.displayName}
      </Select.Option>
    ));
  };

  return (
    <>
      {props.profiles.length > 0 ?
        <>
          <Col span={12}>
            <WelcomeWrapper>
              <img style={{ marginBottom: '36px' }} className="logo-img" alt="Company Logo" src={siteCfg.logo185} />
              {/*<WelcomeText><Trans i18nKey="common.msg.welcome" /></WelcomeText>*/}
              <WelcomeText>Welcome {siteCfg.name ? `to ${siteCfg.name}` : ''}</WelcomeText>
            </WelcomeWrapper>
          </Col>
          <Col span={12}>
            <Row justify="center">
              <div>
                <span style={{fontSize: 20, fontWeight: 550}}><Trans i18nKey="common.msg.select.profile" /></span>
                <br />
                <Select onChange={onSelectProfile} size="large" style={{width: 400, marginTop: 6}}>
                  {profileOptions(props.profiles)}
                </Select>
              </div>
            </Row>
          </Col>
        </>
        :
        <Loading size={LOADING_ICON_SIZE1} />
      }
      {/*process.env.NODE_ENV === 'development' && <Col>
        <span>Temporarily used for testing</span>
        <br />
        <Button
          type="link"
          onClick={() => window.open('https://wmsapp.digitbridge.com/', '_blank')}
        >
          Open WMS by JS
        </Button>
        <br />
        <Button
          type="link"
          href="https://wmsapp.digitbridge.com/"
          target="_blank"
        >
          Open WMS by link
        </Button>
      </Col>*/}
    </>
  );
};

export default ProfileSelector;
