/**
 * shopping around service requests are here.
 */
import { digitBridgeApi } from './http';


export const getShippriority = async (warehouseNum: number): Promise<any> => {
    return await digitBridgeApi.get(`/api/shippriority/${warehouseNum}`)
}

type ShippriorityParams = {
    referenceType: number,
    referenceNum: number,
    group: number,
    seq: number

}
export const postShippriority = async (warehouseNum: number, params: ShippriorityParams[]): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post(`/api/shippriority/${warehouseNum}`, json)
}