import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import Dashboard from 'components/Dashboard';

function Products() {
  return (
    <ContentLayout>
      <SiteContent>
        <Dashboard />
      </SiteContent>
    </ContentLayout>
  );
}

export default Products;
