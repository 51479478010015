import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Row, Button } from 'antd';
import { DataGridPlus, ColumnDataType, TablesFilter } from 'components/common';
// import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
//import dayjs from 'dayjs';

const { Text } = Typography;
interface HistoryProps {
  items: Array<any>;
  uomEnable: boolean;
  lotEnable: boolean;
}
// eslint-disable-next-line
export default (props: HistoryProps) => {
  const { items, uomEnable, lotEnable } = props;
  const [dataSource, setDataSource] = useState<Array<StringKAnyVPair>>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  useEffect(() => {
    const data = items.map((item, index) => {
      const [product, location, quantity, date] = item;
      return {
        id: index,
        sku: product.sku,
        title: product.productTitle,
        quantity,
        location: location.locationCode,
        date,
        uom: location?.uom,
        uomRate: location?.uomRate,
        lotNumber: location?.lotNumber,
        baseQty: quantity * (location?.uomRate || 1)
      };
    });
    setDataSource(data);
  }, [items]);

  const columns = useMemo(()=>{
    const cls =  [
      {
        header: 'SKU',
        name: 'sku',
        userSelect: true,
        minWidth: 220,
        defaultFlex: 1,
      },
      {
        header: 'Product Name',
        name: 'title',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'LOT#',
        name: 'lotNumber',
        userSelect: true,
        defaultFlex: 1,
        lotColumn: true,
      },
      {
        header: 'UOM',
        name: 'uom',
        userSelect: true,
        defaultFlex: 1,
        uomColumn:true
      },
      {
        header: 'Rate',
        name: 'uomRate',
        userSelect: true,
        defaultFlex: 1,
        uomColumn:true
      },
      {
        header: 'Base Qty',
        name: 'baseQty',
        userSelect: true,
        defaultFlex: 1,
        uomColumn:true
      },
      {
        header: 'Quantity',
        name: 'quantity',
        userSelect: true,
        type: 'number',
        defaultFlex: 1,
        sort: (a: number, b: number) => a - b,
      },
      {
        header: 'Location',
        name: 'location',
        userSelect: true,
        defaultFlex: 1,
      },
      {
        header: 'Date',
        name: 'date',
        userSelect: true,
        defaultFlex: 1,
        dataType: ColumnDataType.DATE,
      },
    ];
    let temp = [...cls]
    temp = lotEnable ? [...temp] : temp.filter(i=> !i.lotColumn)
    temp = uomEnable ? [...temp] : temp.filter(i => !i.uomColumn)
    return temp;
  },[lotEnable, uomEnable])

  return (
    <>
      <Row>
        <Text>
          All history will be cleared if you reload or navigate away from remove
        </Text>
      </Row>
      <TablesFilter
        dataSource={dataSource}
        columns={columns}
        setFilteredData={setFilteredData}
      />
      <DataGridPlus
        idProperty="id"
        dataSource={filteredData}
        columns={columns}
        pagination="local"
        {...({} as any)}
      />
      <Row style={{ marginTop: 10 }} justify="space-between">
        <Link to="/reports/transaction-log">
          <Button type="primary">Go to Full User History</Button>
        </Link>
        <Link to="/inventory/checkin">
          <Button type="primary">Check In Inventory</Button>
        </Link>
      </Row>
    </>
  );
};
