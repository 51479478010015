import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { ButtonIcon } from 'components/common';
import { playBell } from 'utils';
import {
  SmartRow,
  SmartSpace,
  SmartScanner,
  SmartBackButton,
} from '../../SmartComponent';
import { scanSkuReturn } from 'services/smart';
import { Spin, Space } from 'antd';
import { useTranslation } from 'react-i18next';

type FailDialogProps = {
  warehouseId: string;
  warehouseNum: number;
  product: any;
  onSubmit: (product: any) => void;
  onBack: () => void;
};
export default function FailDialog(props: FailDialogProps): JSX.Element {
  const { onSubmit, warehouseId, product, onBack } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    `Cannot find return information of scanned code “${product.sku}”`
  );
  /**
   * handle scan changed event
   */
  const handleScannerChange = useCallback(
    async (nextValue: string) => {
      if (nextValue.length > 0) {
        setLoading(true);
        try {
          const res = await scanSkuReturn(warehouseId, nextValue);
          setLoading(false);
          if (res.isSuccess) {
            playBell('success');
            onSubmit(res.data);
          } else {
            // playBell('error');
            if (res.code === 404) {
              setErrorMessage(`Item "${nextValue}" is not valid!`);
            }
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    },
    [onSubmit, warehouseId]
  );

  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <Spin spinning={loading} style={{ width: '100%' }}>
          <Space>
            <ButtonIcon
              className="bi-emoji-frown"
              style={{ width: 20, height: 20, color: 'red', fontSize: 28 }}
            />
            <div style={{ marginLeft: 5, color: 'red', fontSize: 16 }}>
              {errorMessage}
            </div>
          </Space>

          <SmartSpace>
            <div
              style={{
                // color: 'blue',
                fontWeight: 600,
                fontSize: 16,
                marginTop: 5,
              }}
            >
              {t("smart.virtualWarehouseToLocation.inspectionToLocation.scanOtherItem")}
            </div>
            <SmartScanner
              style={{ marginTop: 10 }}
              id="scanItem3"
              isAutoFocus={1}
              onChangeValue={handleScannerChange}
            />
            {/* {errorMessage && (
              <div style={{ color: 'red', fontSize: 16, marginTop: 5 }}>
                {errorMessage}
              </div>
            )} */}
          </SmartSpace>
          <SmartBackButton
            onClick={() => {
              onBack();
            }}
          />
        </Spin>
      </SmartRow>
    );
  };
  return (
    <Modal
      visible
      width={300}
      title={<Title />}
      footer={null}
      closable={false}
    />
  );
}
