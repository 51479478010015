import React, { useEffect, useCallback, useState } from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import ModalDialog from '../../../components/common/ModalDialog';
import {
  // DeleteOutlined,
  // SettingFilled,
  // CaretDownOutlined,
  EditOutlined,
  ClearOutlined,
  // StopOutlined,
  SaveOutlined,
  // UpOutlined,
  // DownOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  CopySpan,
  DataGridPlus,
  FormLabel,
  message,
  Locker,
  TablesFilter,
  // ColumnDataType,
} from '../../../components/common';
import {
  Card,
  Form,
  Row,
  Col,
  // Space,
  // Tooltip,
  Button,
  Input,
  // Divider,
  // Dropdown,
  // Menu,
  Space,
  // Modal,
  Typography,
  // Switch,
  InputNumber,
  // Collapse,
  Empty,
  Select,
  Modal,
} from 'antd';
// import debounce from 'lodash.debounce';
// import EditQtyDialog from './EditDialog';
// import { playAudio } from 'utils';
import {
  COMMON_PADDING_SPACE,
  // LS_DEFAULT_WAREHOUSE_KEY,
} from 'constants/config';
import theme from 'assets/styles/theme';
// import styled from 'styled-components';
import {
  getPackLines,
  // fetchPrepackSettings,
  confirmPack,
  getPackByNum,
} from 'services/prepack';
// import SearchSelect from './SelectLocation';
import EditQtyDialog from './EditLineDialog';
import { GridIconBox } from 'components/common/styles';
// const { Panel } = Collapse;

// const TCollapse = styled(Collapse)`
//   &.ant-collapse-icon-position-right
//     > .ant-collapse-item
//     > .ant-collapse-header {
//     position: relative;
//     padding: 0px 16px;
//     padding-right: 40px;
//   }
// `;

interface Props extends ModalProps {
  onClose: Function;
  pack: Pack;
}

type LastSubmit = {
  childSku?: string;
  qty?: number;
  packedQty?: number;
};

const PageDialog = (props: Props) => {
  const { pack } = props;
  const [info, setInfo] = useState<Pack>();
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] =
    useState(false);
  const refreshBtnRef = React.useRef<any>(null);
  // const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [processLines, setProcessLines] = useState<PackLineItem[]>([]); //
  const [processFilterLines, setProcessFilterLines] = useState<PackLineItem[]>(
    []
  );
  const [lines, setLines] = useState<PackLine[]>([]);
  const [filterlines, setFilterlines] = useState<PackLine[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [qtyLocker, setQtyLocker] = useState(false);
  // const [skuLocker, setSkuLocker] = useState(false);
  // const [submitLoading, setSubmitLoading] = useState(false);
  const [editQtyDialogVisible, setEditQtyDialogVisible] = useState(false);
  const [lineLoading, setLineLoading] = useState(false);
  const [selectedLine, setSelectedLine] = useState<PackLine>();
  const [selectedItems, setSelectedItems] = useState<PackLineItem[]>([]);
  const [lastSubmit, setLastSubmit] = useState<LastSubmit>();
  // const [overQty, setOverQty] = useState(false);
  // const [enableCSN, setEnableCSN] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [confirmAble, setConfirmAble] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (pack) {
      setInfo(pack);
    }
  }, [pack]);

  const closeProductDetailDialog = useCallback(() => {
    if(processing){
      Modal.confirm({
        title: 'Do you want to leave the page?',
        icon: <ExclamationCircleFilled />,
        content: 'Submit operations that have not been confirmed will be lost.',
        okText: 'Leave',
        cancelText: 'Cancel',
        onOk() {
          props.onClose();
        },
        onCancel() {},
      });
    }else {
      props.onClose();
    }
  },[processing, props]);

  // const debounceFn = debounce((val: string) => {
  //   setFilterValue(val);
  // }, 300);

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
        ? window.innerWidth * 0.8
        : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const clearForm = useCallback(() => {
    // form.setFieldsValue({ sku: undefined });
    // setSelectedLine(undefined);
    setSelectedItems([]);
    form.setFieldsValue({
      childSKU: undefined,
    });
    if (!qtyLocker) {
      form.setFieldsValue({ packQty: 1 });
    }
    // if (!skuLocker) {
    //   form.setFieldsValue({
    //     fulfillmentPackLineNum: undefined,
    //     caseSerialNumber: undefined,
    //     prepackSKU: undefined
    //   });
    // }
  }, [form, qtyLocker]);

  // const fetchProcessLines = useCallback(async (fulfillmentPackNum: number) => {
  //   try {
  //     setProcessLoading(true);
  //     const res = await getPackProcessLines(fulfillmentPackNum);
  //     setProcessLoading(false);
  //     if (res) {
  //       setProcessLines(res);
  //     }
  //   } catch (error) {
  //     setProcessLoading(false);
  //   }
  // }, []);

  const fetchLines = useCallback(async (id: number, prepackFulfillmentProductNum?: number) => {
    try {
      setLineLoading(true);
      const res = await getPackLines(id);
      setLineLoading(false);
      if (res) {
        setLines(res);
        setFilterlines(res);
        if(prepackFulfillmentProductNum){
          const temp = res.filter(
            (i: any) =>
              i.prepackFulfillmentProductNum === prepackFulfillmentProductNum
          );
          if (temp.length > 0) {
            setSelectedLine(temp[0]);
          }
        }
      }
    } catch (error) {
      setLineLoading(false);
    }
  }, []);

  const refreshInfo = useCallback(
    async (fulfillmentPackNum: number, prepackFulfillmentProductNum: number) => {
      try {
        const res = await getPackByNum(fulfillmentPackNum);
        if (res) {
          setInfo(res);
        }
        fetchLines(fulfillmentPackNum, prepackFulfillmentProductNum);
      } catch (error) {}
    },
    [fetchLines]
  );

  const getTempLineColumns = useCallback(
    () => [
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 150,
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'componentQuantity',
        header: 'Comp Qty',
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'packingQty',
        header: 'Qty',
        showColumnMenuTool: false,
        userSelect: true,
        defaultFlex: 1,
        render: (value: any) => {
          const { data } = value;
          return (
            <>
              {data.packingQty}
              <GridIconBox>
                <EditOutlined
                  onClick={() => {
                    setCurrent(data);
                    setEditQtyDialogVisible(true);
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      // {
      //   header: 'Action',
      //   name: 'action',
      //   showColumnMenuTool: false,
      //   defaultLocked: 'end',
      //   sortable: false,
      //   maxWidth: 90,
      //   minWidth: 90,
      //   defaultFlex: 1,
      //   render: (value: any) => {
      //     const { data, rowIndex } = value;
      //     return (
      //       <Dropdown
      //         overlay={
      //           <Menu>
      //             <Menu.Item key="0">
      //               <Button
      //                 id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
      //                 type="text"
      //                 style={{ width: '100%', textAlign: 'left' }}
      //                 icon={<EditOutlined />}
      //                 onClick={() => {
      //                   setCurrent(data);
      //                   setEditQtyDialogVisible(true);
      //                 }}
      //               >
      //                 Edit
      //               </Button>
      //             </Menu.Item>
      //             <Menu.Item key="1">
      //               <Button
      //                 id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
      //                 type="text"
      //                 disabled={data.status}
      //                 style={{ width: '100%', textAlign: 'left' }}
      //                 className="hover-danger-button-2"
      //                 icon={<DeleteOutlined />}
      //                 onClick={() => {
      //                   setCurrent(data);
      //                   setDeleteDialogVisible(true);
      //                 }}
      //               >
      //                 Reset
      //               </Button>
      //             </Menu.Item>
      //           </Menu>
      //         }
      //         trigger={['click']}
      //       >
      //         <Button
      //           id={`catalog_grid_action_row_${rowIndex}_dropdown`}
      //           icon={<SettingFilled />}
      //         >
      //           <CaretDownOutlined />
      //         </Button>
      //       </Dropdown>
      //     );
      //   },
      // },
    ],
    []
  );

  // const getProcessLineColumns = useCallback((showCSNumber: boolean) => {
  //   const temp = [
  //     {
  //       name: 'fulfillmentPackProcessNum',
  //       type: 'string',
  //       header: 'Id',
  //       defaultVisible: false,
  //     },
  //     {
  //       name: 'caseSerialNumber',
  //       header: 'CS#',
  //       minWidth: 150,
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //     {
  //       name: 'packQty',
  //       header: 'Qty',
  //       minWidth: 150,
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //     {
  //       dataType: ColumnDataType.DATE,
  //       name: 'createDate',
  //       header: 'Create Date',
  //       userSelect: true,
  //       minWidth: 110,
  //       defaultFlex: 1,
  //     },
  //     {
  //       name: 'createBy',
  //       header: 'Create By',
  //       showColumnMenuTool: false,
  //       userSelect: true,
  //       defaultFlex: 1,
  //     },
  //     {
  //       header: 'Action',
  //       name: 'action',
  //       showColumnMenuTool: false,
  //       defaultLocked: 'end',
  //       sortable: false,
  //       maxWidth: 100,
  //       minWidth: 100,
  //       defaultFlex: 1,
  //       render: (value: any) => {
  //         const { data, rowIndex } = value;
  //         return (
  //           <Dropdown
  //             overlay={
  //               <Menu>
  //                 <Menu.Item key="0">
  //                   <Button
  //                     id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_details`}
  //                     type="text"
  //                     style={{ width: '100%', textAlign: 'left' }}
  //                     icon={<EditOutlined />}
  //                     onClick={() => {
  //                       setCurrent(data);
  //                       setEditQtyDialogVisible(true);
  //                     }}
  //                   >
  //                     Edit
  //                   </Button>
  //                 </Menu.Item>
  //                 <Menu.Item key="1">
  //                   <Button
  //                     id={`bulk_return_receive_grid_action_row_${rowIndex}_dropdown_delete`}
  //                     type="text"
  //                     // disabled={data.receiveQty !== 0}
  //                     style={{ width: '100%', textAlign: 'left' }}
  //                     className="hover-danger-button-2"
  //                     icon={<DeleteOutlined />}
  //                     onClick={() => {
  //                       setCurrent(data);
  //                       setDeleteDialogVisible(true);
  //                     }}
  //                   >
  //                     Delete
  //                   </Button>
  //                 </Menu.Item>
  //               </Menu>
  //             }
  //             trigger={['click']}
  //           >
  //             <Button
  //               id={`catalog_grid_action_row_${rowIndex}_dropdown`}
  //               icon={<SettingFilled />}
  //             >
  //               <CaretDownOutlined />
  //             </Button>
  //           </Dropdown>
  //         );
  //       },
  //     },
  //   ];
  //   return showCSNumber
  //     ? temp
  //     : temp.filter((i) => i.name !== 'caseSerialNumber');
  // }, []);

  const addSubmit = useCallback(async () => {
    const params = await form.validateFields();
    if (!info) return message.warning('no find pack');
    if (params.errorFields) return;
    if (params.requestNumber !== info.packCode) {
      return message.warning(`no match ${info.packCode}`);
    }
    let tempSelected: any;
    if (selectedItems.length === 0) {
      const tempItems = processLines.filter(
        (i) =>
          i.sku === params.childSKU.trim() || i.upc === params.childSKU.trim()
      );
      // console.log('tempItem', tempItems);
      if (tempItems.length > 0) {
        const validItems = tempItems.filter(
          (i) => i.componentQuantity - i.packingQty > 0
        );
        if (validItems.length === 0) {
          // return message.warning(`${params.childSKU} is enough`);
          return Modal.warning({title: `${params.childSKU} is enough`})
        } else {
          setSelectedItems(validItems);
          tempSelected = validItems;
        }
      } else {
        // return message.warning(`${params.childSKU} not found`);
        // return Modal.warning({title:`${params.childSKU} not found`})
        return Modal.warning({
          icon:undefined,
          content:(
          <Space  direction="vertical" align="center" style={{width: '100%'}}>
            <Typography.Text>SKU</Typography.Text>
            <Typography.Text>{`[ ${params.childSKU} ]`}</Typography.Text>
            <Typography.Text>was not found</Typography.Text>
          </Space>
        )})
      }
    } else {
      tempSelected = selectedItems;
    }
    // console.log(tempSelected)
    if (tempSelected.length === 0) {
      // return message.warning(`${params.childSKU} not found`);
      // return Modal.warning({title:`${params.childSKU} not found`})
      return Modal.warning({
        icon:undefined,
        content:(
        <Space direction="vertical" align="center" style={{width: '100%'}}>
          <Typography.Text>SKU</Typography.Text>
          <Typography.Text>{`[ ${params.childSKU} ]`}</Typography.Text>
          <Typography.Text>was not found</Typography.Text>
      </Space>
    )})
    }

    let totalRequireQty = 0;
    tempSelected.map((i: PackLineItem) => {
      totalRequireQty = totalRequireQty + i.componentQuantity - i.packingQty;
      return true;
    });

    if (totalRequireQty < params.packQty) {
      // return message.warning('quantity exceeded');
      return Modal.warning({title:'quantity exceeded'})
    }

    const arr: PackLineItem[] = [];
    let usedQty = 0;
    tempSelected.map((i: PackLineItem) => {
      if (usedQty < params.packQty) {
        if (
          params.packQty - usedQty - (i.componentQuantity - i.packingQty) >=
          0
        ) {
          arr.push({
            ...i,
            packingQty: i.componentQuantity,
          });
          usedQty = usedQty + (i.componentQuantity - i.packingQty);
        } else {
          arr.push({
            ...i,
            packingQty: i.packingQty + (params.packQty - usedQty),
          });
          usedQty = params.packQty;
        }
      } else {
        arr.push({ ...i });
      }
      return true;
    });

    const temp = processLines.filter(
      (i) =>
        i.sku !== tempSelected[0].sku ||
        (i.sku === tempSelected[0].sku && i.componentQuantity === i.packingQty)
    );
    setProcessLines([...arr, ...temp]);
    message.success('Submit successfully');
    clearForm();
    setLastSubmit((prev: any) => {
      return {
        ...prev,
        childSku: params.childSKU.trim(),
        qty: params.packQty,
      };
    });
  }, [form, info, clearForm, selectedItems, processLines]);

  // const getPrepackSetting = useCallback(async () => {
  //   try {
  //     const res = await fetchPrepackSettings();
  //     if (res.isSuccess) {
  //       if (
  //         res.data.unpackOverPercentage &&
  //         res.data.unpackOverPercentage > 0
  //       ) {
  //         setOverQty(true);
  //       }
  //       if (res.data.enableCSN && res.data.enableCSN === 1) {
  //         setEnableCSN(true);
  //       }
  //     }
  //   } catch (error) {}
  // }, []);

  const checkChildSku = useCallback(async () => {
    const params = await form.getFieldsValue();
    if (!params.childSKU || !params.childSKU.trim()) {
      return message.warning({ content: 'invaild Child SKU' });
    }
    if (!selectedLine) {
      return;
    }
    const tempItem = processLines.filter(
      (i) =>
        i.sku === params.childSKU.trim() || i.upc === params.childSKU.trim()
    );
    if (tempItem.length === 0) {
      message.info(`invaild Child SKU [ ${params.childSKU} ]`);
      form.setFieldsValue({ childSKU: undefined });
    } else {
      const validItems = tempItem.filter(
        (i) => i.componentQuantity - i.packingQty > 0
      );
      if (validItems.length === 0) {
        message.info(`[ ${params.childSKU} ] is enough`);
        form.setFieldsValue({ childSKU: undefined });
      } else {
        setSelectedItems(validItems);
      }
      // const temp = processLines.filter(
      //   (i) =>
      //     i.fulfillmentPackLineNum === tempItem[0].fulfillmentPackLineNum &&
      //     i.sku === params.childSKU
      // );
      // if (temp.length > 0) {
      //   setLastSubmit((prev) => {
      //     return {
      //       ...prev,
      //       packedQty: temp[0].packQty,
      //     };
      //   });
      // }
    }
  }, [selectedLine, processLines, form]);

  const handleConfirm = useCallback(async () => {
    if (!info || !selectedLine) return;
    try {
      setConfirming(true);
      const res = await confirmPack(
        info.fulfillmentPackNum,
        selectedLine.fulfillmentPackLineNum
      );
      if (res) {
        message.success('Confirm successfully');
        refreshInfo(info.fulfillmentPackNum, selectedLine.prepackFulfillmentProductNum);
        setProcessLines(
          selectedLine.packLineItems.map((i) => {
            return { ...i, packingQty: 0 };
          })
        );
      }
      setConfirming(false);
    } catch (error) {
      setConfirming(false);
    }
  }, [info, refreshInfo, selectedLine]);

  const rowStyle = (rowData: any) => {
    const { data } = rowData;
    return {
      backgroundColor:
        data.packingQty === data.componentQuantity
          ? theme['@success-color']
          : '#fff',
    };
  };

  const checkConfirmAble = useCallback(() => {
    let enable = true;
    let inProcessing = false;
    if (processLines.length === 0) {
      enable = false;
    } else {
      processLines.map((i) => {
        if (i.packingQty !== i.componentQuantity) {
          enable = false;
        }
        if(i.packingQty > 0){
          inProcessing = true
        }
        return true;
      });
    }
    setProcessing(inProcessing)
    setConfirmAble(enable);
    if(enable){
      Modal.confirm({
        title: 'Do you want to confirm?',
        icon: <ExclamationCircleFilled />,
        content: 'All child-skus have been submitted. You can now perform the confirm operation.',
        okText: 'Confirm',
        cancelText: 'Later',
        onOk() {
          handleConfirm()
        },
        onCancel() {},
      });
    }
  }, [processLines, handleConfirm]);

  useEffect(() => {
    if (info) {
      // getPrepackSetting();
      fetchLines(info.fulfillmentPackNum);
    }
  }, [info, fetchLines]);

  useEffect(() => {
    checkConfirmAble();
  }, [processLines, checkConfirmAble]);

  useEffect(()=>{
    setProcessFilterLines(processLines)
  },[processLines])


  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton={true}
        footer={[
          <Button
            type="primary"
            disabled={!confirmAble}
            loading={confirming}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        ]}
        fullscreen={true}
        maskClosable={false}
        onClose={closeProductDetailDialog}
        onFullscreen={onFullscreen}
        title={`Pack Repack (Process): ${info?.packCode}`}
        visible={props.visible}
        width={detailDialogWidth()}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {info && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // backgroundColor: '#fff',
                backgroundColor: theme['@default-backgroud-color'],
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: 450,
                  minHeight: 580,
                  height: '100%',
                  // borderRightWidth: 1,
                  borderRadius: `${theme['@border-radius-base']}`,
                  // borderRightColor: ' rgba(0, 0, 0, 0.15)',
                  // borderRightStyle: 'solid',
                  marginRight: COMMON_PADDING_SPACE,
                }}
              >
                <Card size="small" bordered={false} style={{ width: 450 }}>
                  <Form form={form}>
                    <Form.Item
                      label={
                        <div style={{ width: 120, textAlign: 'left' }}>
                          <FormLabel require>Confirm Request#</FormLabel>
                        </div>
                      }
                      rules={[{ required: true }]}
                    >
                      <Row gutter={8} align="middle">
                        <Col span={20}>
                          <Form.Item
                            noStyle
                            name="requestNumber"
                            rules={[
                              {
                                required: true,
                                message: 'Please input Request#!',
                              },
                            ]}
                          >
                            <Input
                              tabIndex={0}
                              onPressEnter={() => {}}
                              onKeyDown={(e) => {}}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4}></Col>
                      </Row>
                    </Form.Item>

                    <Card style={{ marginTop: 10 }}>
                      <FormLabel require>Current Pre-Pack SKU or CS#</FormLabel>
                      <Row
                        gutter={8}
                        align="middle"
                        style={{ marginBottom: 10 }}
                      >
                        <Col span={20}>
                          <Form.Item
                            noStyle
                            name="fulfillmentPackLineNum"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Pre-Pack SKU or CS#!',
                              },
                            ]}
                          >
                            <Select
                              style={{ width: '100%' }}
                              onChange={(value) => {
                                const temp = lines.filter(
                                  (i: any) =>
                                    i.prepackFulfillmentProductNum === value
                                );
                                if (temp.length > 0) {
                                  setSelectedLine(temp[0]);
                                  setProcessLines(
                                    temp[0].packLineItems.map((i) => {
                                      return { ...i, packingQty: 0 };
                                    })
                                  );
                                  form.setFieldsValue({
                                    // caseSerialNumber:
                                    //   temp[0].caseSerialNumber,
                                    prepackSKU: temp[0].prepackSKU,
                                  });
                                }
                              }}
                            >
                              {lines.map((i) => (
                                <Select.Option
                                  key={i.prepackFulfillmentProductNum}
                                  value={i.prepackFulfillmentProductNum}
                                >{`${i.prepackSKU}`}</Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          {/* <Locker
                              size={16}
                              value={skuLocker}
                              onChange={(value: boolean) => {
                                setSkuLocker(value);
                              }}
                            /> */}
                        </Col>
                      </Row>

                      <Form.Item noStyle name="caseSerialNumber">
                        <Input type="hidden" />
                      </Form.Item>

                      <Form.Item noStyle name="prepackSKU">
                        <Input type="hidden" />
                      </Form.Item>

                      {selectedLine && (
                        <Row style={{ marginTop: 10, marginBottom: 10 }}>
                          <Space size="large">
                            <Space>
                              <FormLabel>Requested</FormLabel>
                              <Typography.Text>
                                {selectedLine?.requestQty}
                              </Typography.Text>
                            </Space>
                            <Space>
                              <FormLabel>Packed</FormLabel>
                              <Typography.Text>
                                {selectedLine?.packQty}
                              </Typography.Text>
                            </Space>
                          </Space>
                        </Row>
                      )}

                      <Card bodyStyle={{ paddingLeft: 8, paddingRight: 0 }}>
                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel require>Child SKU#</FormLabel>
                            </div>
                          }
                          rules={[{ required: true }]}
                        >
                          <Row gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                noStyle
                                name="childSKU"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Child SKU!',
                                  },
                                ]}
                              >
                                <Input
                                  disabled={!selectedLine}
                                  tabIndex={0}
                                  onPressEnter={() => {
                                    if (qtyLocker && selectedLine) {
                                      addSubmit();
                                    } else {
                                      checkChildSku();
                                    }
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 9) {
                                      if (qtyLocker && selectedLine) {
                                        addSubmit();
                                      } else {
                                        checkChildSku();
                                      }
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={4}></Col>
                          </Row>
                        </Form.Item>

                        <Form.Item
                          label={
                            <div style={{ width: 110, textAlign: 'left' }}>
                              <FormLabel require>Qty</FormLabel>
                            </div>
                          }
                        >
                          <Row gutter={8} align="middle">
                            <Col span={20}>
                              <Form.Item
                                noStyle
                                name="packQty"
                                initialValue={1}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input Qty!',
                                  },
                                ]}
                              >
                                <InputNumber min={0} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Locker
                                size={16}
                                value={qtyLocker}
                                onChange={(value: boolean) => {
                                  setQtyLocker(value);
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Item>

                        <Row
                          justify="center"
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <Space>
                            <Button
                              type="primary"
                              onClick={addSubmit}
                              icon={<SaveOutlined />}
                            >
                              Submit
                            </Button>
                            <Button onClick={clearForm}>
                              <ClearOutlined />
                              Clear
                            </Button>
                          </Space>
                        </Row>
                        <Row justify="center">
                          <Space>
                            <FormLabel>Last Child SKU</FormLabel>
                            <Typography.Text>
                              {lastSubmit?.childSku || ''}
                            </Typography.Text>
                          </Space>
                        </Row>
                        <Row justify="center">
                          <Space>
                            <FormLabel>Last Child Qty</FormLabel>
                            <Typography.Text>
                              {lastSubmit?.qty || ''}
                            </Typography.Text>
                          </Space>
                        </Row>
                        {/* <Row justify="center">
                            <Space>
                              <FormLabel>Current Child Packed</FormLabel>
                              <Typography.Text>
                                {selectedItem?.packQty}
                              </Typography.Text>
                            </Space>
                          </Row> */}
                      </Card>
                    </Card>
                  </Form>
                </Card>
              </div>
              {!showAll && (
                <div
                  style={{
                    height: '100%',
                    minHeight: 580,
                    flexGrow: 1,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingBottom: 8,
                    background: '#fff',
                    borderRadius: theme['@border-radius-base'],
                    marginRight: COMMON_PADDING_SPACE,
                  }}
                >
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      paddingBottom: 8,
                    }}
                  >
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ paddingTop: 8 }}
                    >
                      <TablesFilter
                        dataSource={processLines}
                        setFilteredData={setProcessFilterLines}
                        columns={[{ name: 'sku' }]}
                      />
                      <Typography.Text strong>Current Process</Typography.Text>
                      <Typography.Text> </Typography.Text>
                    </Row>
                    {/* <Row style={{marginTop: 8}}>
                      <Space size="large">
                        <FormLabel>Prepack SKU</FormLabel>
                        <Text>{selectedLine?.prepackSKU}</Text>
                      </Space>
                      <Space>
                        <FormLabel>C#</FormLabel>
                        <Text>{selectedLine?.caseSerialNumber}</Text>
                      </Space>
                    </Row> */}
                    {selectedLine && <Row style={{marginTop: 4}}><Typography.Text strong>Pre-Pack SKU:</Typography.Text><Typography.Text>{selectedLine?.prepackSKU}</Typography.Text></Row>}
                    <DataGridPlus
                      id="fulfillmentPackProcessNum"
                      boxStyle={{ height: 'calc(100% - 40px)' }}
                      columns={getTempLineColumns()}
                      rowStyle={rowStyle}
                      dataSource={processFilterLines}
                      autoWith={false}
                      autoHeight={false}
                      style={{
                        height: detailDialogIsFullscreen ? '100%' : 522,
                      }}
                      pagination={false}
                      {...({} as any)}
                    />
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                  width: showAll ? 'calc(100% - 454px)' : 300,
                  height: '100%',
                  minHeight: 580,
                  backgroundColor: '#fff',
                  borderRadius: theme['@border-radius-base'],
                  // borderLeftWidth: 1,
                  // borderLeftColor: ' rgba(0, 0, 0, 0.15)',
                  // borderLeftStyle: 'solid',
                }}
              >
                <Card
                  loading={lineLoading}
                  title={
                    <Row
                      justify="space-between"
                      style={{ paddingLeft: showAll ? 4 : 0 }}
                    >
                      {showAll && (
                        <TablesFilter
                          dataSource={lines}
                          setFilteredData={setFilterlines}
                          columns={[{ name: 'prepackSKU' }]}
                        />
                      )}
                      {!showAll && (
                        <Button
                          type="link"
                          onClick={() => setShowAll(true)}
                        >{`< Expand`}</Button>
                      )}
                      <Typography.Text>All</Typography.Text>
                      {!showAll && <Typography.Text> </Typography.Text>}
                      {showAll && (
                        <Button
                          type="link"
                          onClick={() => setShowAll(false)}
                        >{`callapse >`}</Button>
                      )}
                    </Row>
                  }
                  bordered={false}
                  headStyle={{ padding: 0 }}
                  style={{ width: 'calc(100% - 14px)' }}
                  bodyStyle={{
                    height: 'calc(100% - 70px)',
                    minHeight: 520,
                    width: '100%',
                    overflowY: 'auto',
                  }}
                >
                  {filterlines.map((item) => (
                    <Card
                      style={{ marginBottom: 8 }}
                      extra={
                        showAll ? (
                          <Space>
                            <Space>
                              <FormLabel>Requested</FormLabel>
                              <Typography.Text>
                                {item.requestQty}
                              </Typography.Text>
                            </Space>
                            <Space>
                              <FormLabel>Packed</FormLabel>
                              <Typography.Text>{item.packQty}</Typography.Text>
                            </Space>
                          </Space>
                        ) : undefined
                      }
                      key={item.prepackFulfillmentProductNum}
                      size="small"
                      headStyle={
                        item.packQty > item.inventoryPackQty
                          ? { backgroundColor: theme['@finished-color'] }
                          : {}
                      }
                      title={
                        <Row>
                          <Col span={24}>
                            <CopySpan
                              inGrid={!showAll}
                              value={item.prepackSKU || ''}
                            />
                          </Col>
                        </Row>
                      }
                    >
                      {item.packLineItems.map((i) => (
                        <Card
                          key={i.fulfillmentPackLineNum}
                          size="small"
                          type="inner"
                          headStyle={
                            i.componentQuantity &&
                            Math.floor(i.packQty / i.componentQuantity) >
                              item.packQty &&
                            item.packQty <= item.inventoryPackQty
                              ? { backgroundColor: theme['@finished-color'] }
                              : {}
                          }
                          title={
                            <Row>
                              <Col span={24}>
                                <CopySpan
                                  inGrid={!showAll}
                                  value={i.sku || ''}
                                />
                              </Col>
                            </Row>
                          }
                          style={{ width: '100%', marginBottom: 16 }}
                        >
                          {showAll ? (
                            // <TCollapse
                            //   ghost
                            //   expandIconPosition="right"
                            //   expandIcon={({ isActive }) => {
                            //     return isActive ? (
                            //       <Typography.Link>
                            //         <UpOutlined style={{ marginRight: 2 }} />
                            //         Hide Detail
                            //       </Typography.Link>
                            //     ) : (
                            //       <Typography.Link>
                            //         <DownOutlined style={{ marginRight: 2 }} />
                            //         Show Detail
                            //       </Typography.Link>
                            //     );
                            //   }}
                            // >
                            //   <Panel
                            //     header={
                            //       <Row style={{ width: '80%' }}>
                            //         <Col span={8}>
                            //           <FormLabel>UPC</FormLabel>
                            //           {i.upc}
                            //         </Col>
                            //         <Col span={8}>
                            //           <FormLabel>Title</FormLabel>
                            //           {i.productTitle}
                            //         </Col>
                            //         <Col span={8}>
                            //           <FormLabel>Requested</FormLabel>
                            //           {`${i.requestQty} (${i.componentQuantity} * ${item.requestQty})`}
                            //         </Col>
                            //         <Col span={8}>
                            //           <FormLabel>Picked</FormLabel>
                            //           {i.pickedQty}
                            //         </Col>
                            //         <Col span={8}>
                            //           <FormLabel>Packed</FormLabel>
                            //           {i.packQty}
                            //         </Col>
                            //       </Row>
                            //     }
                            //     key="1"
                            //   >
                            //     <DataGridPlus
                            //       name="lineGrid"
                            //       style={{ minHeight: 200 }}
                            //       idProperty="code"
                            //       dataSource={[]}
                            //       columns={getProcessLineColumns(enableCSN)}
                            //       /* eslint-disable react/jsx-props-no-spreading */
                            //       {...({} as any)}
                            //     />
                            //   </Panel>
                            // </TCollapse>
                            <Row style={{ width: '100%' }}>
                              <Col span={8}>
                                <FormLabel>UPC</FormLabel>
                                {i.upc}
                              </Col>
                              <Col span={8}>
                                <FormLabel>Product Name</FormLabel>
                                {i.productTitle}
                              </Col>
                              <Col span={8}>
                                <FormLabel>Requested</FormLabel>
                                {`${i.requestQty} (${i.componentQuantity} * ${item.requestQty})`}
                              </Col>
                              <Col span={8}>
                                <FormLabel>Picked</FormLabel>
                                {i.pickedQty}
                              </Col>
                              <Col span={8}>
                                <FormLabel>Packed</FormLabel>
                                {`${i.componentQuantity * item.packQty} (${i.componentQuantity} * ${item.packQty})`}
                              </Col>
                            </Row>
                          ) : (
                            <Row>
                              <Col span={24}>
                                <span>{`UPC: ${i.upc}`}</span>
                              </Col>
                              <Col span={24}>
                                <span>{`Requested: ${i.requestQty} (${i.componentQuantity} * ${item.requestQty})`}</span>
                              </Col>
                              <Col span={24}>
                                <span>{`Picked: ${i.pickedQty}`}</span>
                              </Col>
                              <Col span={24}>
                                <span>{`Packed: ${i.componentQuantity * item.packQty} (${i.componentQuantity} * ${item.packQty})`}</span>
                              </Col>
                            </Row>
                          )}
                        </Card>
                      ))}
                    </Card>
                  ))}
                  {filterlines.length === 0 && <Empty />}
                </Card>
              </div>
            </div>
          )}
        </div>
        {/* {current && deleteDialogVisible && (
          <Modal
            title="Are you sure reset this Row?"
            visible
            onOk={() => {
              setDeleteDialogVisible(false);
              const temp = processLines.filter(
                (i) =>
                  i.fulfillmentPackLineNum !== current.fulfillmentPackLineNum
              );
              setProcessLines([{ ...current, packingQty: 0 }, ...temp]);
            }}
            onCancel={() => setDeleteDialogVisible(false)}
            okText="Yes"
            cancelText="No"
          >
            <p>{`Pre-Pack SKU: ${current.prepackSKU}`}</p>
            <p>{`SKU: ${current.sku}`}</p>
          </Modal>
        )} */}
        {editQtyDialogVisible && current && (
          <EditQtyDialog
            data={current}
            visible
            packCode={info?.packCode || ''}
            onSave={(data) => {
              // console.log(data)
              setEditQtyDialogVisible(false);
              const temp = processLines.filter(
                (i) => i.fulfillmentPackLineNum !== data.fulfillmentPackLineNum
              );
              setProcessLines([data, ...temp]);
            }}
            onHide={() => {
              setEditQtyDialogVisible(false);
            }}
          />
        )}
      </ModalDialog>
    </>
  );
};

export default PageDialog;
