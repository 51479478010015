/**
 * packs service requests are here.
 */
import { digitBridgeApi, getDataListInResponse } from './http';

interface ShipmentContainerLines {
  ShipmentNum: number,
  TrackingNumber: string
}

interface FetchPacksParams {
  $sortBy: string;
  $top: number;
  $skip: string;
}

interface AddPacksParams {
  CarrierNum: number;
  CarrierServiceNum: number;
  BOL: string;
  // Length: number;
  // Width: number;
  // Height: number;
  // Weight: number;
  DimensionUnit: number;
  WeightUnit: number;
  ShipperAddressNum: number;
  ConsigneeAddressNum: number;
  FedExTime: string;
  ContainerId: string;
  Fee: number;
  WarehouseNum: number;
  ShipmentContainerLines: ShipmentContainerLines[];
}

interface EditPacksParams {
  BOL: string;
  Length?: number;
  Width?: number;
  Height?: number;
  Weight?: number;
  DimensionUnit?: number;
  WeightUnit?: number;
  ShipperAddressNum?: number;
  ConsigneeAddressNum?: number;
  FedExTime: string;
  Fee: number;
  ShipmentContainerLines: ShipmentContainerLines[];
  WarehouseNum: number;
  ContainerId: string;
  TrackingNumber?: string;
  Barcode?: string;
  FlatRate?: boolean;
  SecondaryTrackingNumber?: string;
  ShipPackageNum?: number;
  PackageName?: string;
  PackageCode?:string;
}

export const fetchShippingAccount = async (warehouseNum: number, carrierNum: number): Promise<any> => {
  return digitBridgeApi.get(`/api/Warehouse/${warehouseNum}/${carrierNum}/shipaccount`);
};

export const fetchShipmentContainers = async (params: FetchPacksParams): Promise<any> => {
  return digitBridgeApi.get('/api/shipment/containers', { params });
};

export const AddShipmentContainers = async (params: AddPacksParams): Promise<any> => {
  const json = JSON.stringify(params);
  return digitBridgeApi.post('/api/shipment/containers', json);
};

export const AddShipmentContainersLine = async (shipmentContainerId: string, params: ShipmentContainerLines): Promise<any> => {
  const json = JSON.stringify(params);
  return digitBridgeApi.post(`/api/shipment/containers/${shipmentContainerId}/lines`, json);
};

export const delShipmentContainersLine = async (shipmentContainerId: string, shipmentContainerLineNum: number): Promise<any> => {
  return digitBridgeApi.delete(`/api/shipment/containers/${shipmentContainerId}/lines/${{ shipmentContainerLineNum }}`);
};

export const editShipmentContainers = async (shipmentContainerId: string, params: EditPacksParams): Promise<any> => {
  const json = JSON.stringify(params);
  return digitBridgeApi.put(`/api/shipment/containers/${shipmentContainerId}`, json);
};

export const fetchWarehouseServiceList = async (
  warehouseNum: number,
): Promise<any> => {
  const res = await digitBridgeApi.get(`/api/warehouse/${warehouseNum}/ShipServices`);
  return getDataListInResponse(res);
};

export const fetchWarehousePackageList = async (
  warehouseNum: number,
  carrierNum: number,
): Promise<any> => {
  const { data } = await digitBridgeApi.get(`api/warehouse/${warehouseNum}/${carrierNum}/Packages`);
  if (data) {
    return data;
  }
  return null;
};

export const fetchHeaderByCarrier = async (carrierNum: number): Promise<any> => {
  return await digitBridgeApi.get('api/shipment/containers/getHeaderByCarrier', { params: { carrierNum } });
};

export const uploadShipmentContainer = async (shipmentContainerNum: number): Promise<any> => {
  return await digitBridgeApi.post(`api/shipment/containers/${shipmentContainerNum}/upload`);
};

export const deleteShipmentContainer = async (shipmentContainerId: string): Promise<any> => {
  return await digitBridgeApi.delete(`api/shipment/containers/${shipmentContainerId}`);
};

export const voidShippingLable = async (shipmentContainerNum: number): Promise<any> => {
  return await digitBridgeApi.post(`api/shipment/containers/${shipmentContainerNum}/void`);
};

export const resetShippingLable = async (shipmentContainerNum: number): Promise<any> => {
  return await digitBridgeApi.post(`api/shipment/containers/${shipmentContainerNum}/reset`);
};

export const getShipmentContainerById = async (shipmentContainerId: string): Promise<any> => {
  return await digitBridgeApi.get(`api/shipment/containers/${shipmentContainerId}`);
};

export const checkTrackingNumber = async (trackingNumber: string, warehouseNum: number, shippingCarrier: number): Promise<any> => {
  return await digitBridgeApi.get('api/shipments/getByTrackingNumber', { params: { trackingNumber, warehouseNum, shippingCarrier } });
};