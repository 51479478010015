// const colors = {
//   blue: '#1976d2',
//   danger: '#C82333',
//   green: '#02a800',
//   info: '#138496',
//   warning: '#faad14',
//   warningBg: '#FFFBE6',
//   white: '#fff',
//   red: '#bc0000',
//   secondary: '#5A6268',
//   black: '#000',
//   grayBase: '#d8dde6',
//   finished: '#f6ffed',
//   background: '#f0f2f5',
// };

const colors = {
  blue: '#335FE2',
  danger: '#C82333',
  green: '#02a800',
  info: '#138496',
  warning: '#faad14',
  warningBg: '#FFFBE6',
  white: '#fff',
  red: '#bc0000',
  secondary: '#5A6268',
  black: '#000',
  grayBase: '#f5f5f5',
  finished: '#f6ffed',
  background: '#F5F9FF',
  darkBgLight: '#41474e',
};

module.exports = {
  '@primary-color': colors.blue,
  '@info-color': colors.info,
  '@secondary-color': colors.secondary,
  '@success-color': colors.green,
  '@warning-color': colors.warning,
  '@warning-bg-color': colors.warningBg,
  '@processing-color': colors.blue,
  '@error-color': colors.red,
  '@highlight-color': colors.red,
  '@normal-color': colors.grayBase,
  '@white': colors.white, // do not modify it, maybe it is used by others
  '@black': colors.black, // do not modify it, maybe it is used by others
  '@danger-color': colors.danger,
  '@border-radius-base': '10px',
  '@section-margin': 10,
  '@section-margin1': 8,
  '@section-margin2': 12,
  '@finished-color': colors.finished,
  '@default-backgroud-color': colors.background,
  '@default-input-box-shadow': '0px 0px 2.5px 0.5px #335FE2',
  '@dark-bg-light': colors.darkBgLight,
  '@default-tertiary': '#104067',
  '@default-background-hover': '#D0DCFF',
  '@default-background-active': '#e2edff',
  '@default-background-active-2': '#ddeaff',
  '@dark-bg-lighter': '#515961',
};
