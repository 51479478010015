import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  Button,
  Row,
  Form,
  Col,
  Space,
  DatePicker,
  Input,
  Select,
  Menu,
  Dropdown,
  Tooltip,
  // Radio,
  Modal,
  Checkbox,
  //   Typography,
} from 'antd';
import {
  ExclamationCircleOutlined,
  DiffOutlined,
  CheckOutlined,
  SettingFilled,
  CaretDownOutlined,
  FileExcelOutlined,
  SearchOutlined,
  CopyOutlined,
  FilePdfOutlined,
  EditOutlined,
  // ReloadOutlined,
} from '@ant-design/icons';
import copy from 'copy-text-to-clipboard';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import {
  ClearButton,
  ColumnDataType,
  DataGridPlus,
  FormLabel,
  HeaderTitle,
  SelectWarehouse,
  GreyCardWrapper,
  CopySpan,
  // SpaceCell,
  message,
  SpaceCell,
  TablesFilter,
} from 'components/common';
import {
  DATAGRID_CELL_ICON_WIDTH1,
  DATE_TIME_NO_SECOND_FORMAT,
} from 'constants/config';
import { trimValues, sortByString, convertToUtcTime, getWarehouseIdFromCache } from 'utils';
import { fetchStoreList } from 'services/storeSetup';
import {
  fetchReturns,
  fetchRemoteReturns,
  printReturnShippingLabel,
  // delReturn,
  // refeshReturn,
  abandonReturn,
} from 'services/return';
import CompleteDetailModal from './CompleteModal';
// import DetailModal from './DetailDialog';
import DetailModal from './RemoteDetailDialog';
import RemoteDialog from './RemoteSearchDialog';
import ReceiveModal from './ReceiveModal';
import RestockModal from './RestockModal';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import DeviceSettingDialog from '../../Sales/PrintGeneratedPicklists/DeviceSettingDialog';
import { RootState } from 'reducers';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import ReceiveDialog from '../Receive';
import { getWarehouseCodeFromCache } from 'utils';
import { getSpecialLocations } from 'services/warehouse';
import { specialWarehouseInfo } from 'actions/adminActions';
import { Dispatch } from 'redux';
import theme from 'assets/styles/theme';
import ExportCSV from './ExportCSV'

import { PackSlipCell } from 'components/Sales/ShipmentList/styles';
import { userProfiles } from 'utils/auth';
import ExportLabelPDF from './LablePdf';
import { ClassicCard } from 'components/common/styles';
// import ExpressReceiveDialog from './ExpressReceive'
import ExpressReceiveDialog2 from './ExpressReceiveWithNoReturnQty'
import {
  shipmentStatusKeyValue as statusKeyValue
} from 'constants/enums'

const { RangePicker } = DatePicker;
// const { Link, Text } = Typography;
const statusEnum: { [key: string]: string } = {
  '0': 'Pending',
  '1': 'Processing',
  '2': 'Completed',
  '8': 'Abandoned'
};

type SpecialInfoItem = {
  databaseNum: number;
  locations: any[];
  warehouseCode: string;
  warehouseId: string;
  warehouseNum: number;
  warehouseType: number;
};

type SpecialInfoType = {
  2?: SpecialInfoItem;
  3?: SpecialInfoItem;
  4?: SpecialInfoItem;
};

export default function LocationReport(): JSX.Element {
  const defaultWarehouseType = 4;
  const defaultLocationCode = 'General';
  const [data, setData] = useState<any[]>();
  const whs = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const cacheInfo = useSelector(
    (state: RootState) => state.admin.defaultSpecialWarehouseInfo,
    shallowEqual
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [initing, setIniting] = useState(true);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseRow | undefined
  >();
  const [total, setTotal] = useState<number>(12);
  const [loading, setLoading] = useState(false);
  const [tempParams, setTempParams] = useState<any>();
  const [keyWordType, setKeyWordType] = useState<any>('channelOrderId');
  const limitRef = useRef(10);
  const skipRef = useRef(0);
  const sortString = React.useRef<string>('FulfillmentReturnNum desc');
  const [current, setCurrent] = React.useState<any>();
  const [closeDetailDialogVisible, setCloseDetailDialogVisible] =
    React.useState(false);
  const [detailDialogVisible, setDetailDialogVisible] = React.useState(false);
  const [stores, setStores] = useState<Array<ChannelAccountRow>>([]);
  const [remoteDialogVisible, setRemoteDialogVisible] = useState(false);
  const [remoteLoading, setRemoteLoading] = useState(false);
  const [remoteData, setRemoteData] = useState<any[]>([]);
  const [currentWarehouseId, setCurrentWarehouseId] = useState<string>('');
  const [currentWarehouseCode, setCurrentWarehouseCode] = useState<string>('');
  const [receiveModalVisible, setReceiveModalVisible] = useState(false);
  const [receiveModalVisible1, setReceiveModalVisible1] = useState(false);
  // const [expressReceiveModalVisible, setExpressReceiveModalVisible] = useState(false);
  const [expressReceiveModalVisible2, setExpressReceiveModalVisible2] = useState(false);
  const [restockModalVisible, setRestockModalVisible] = useState(false);
  const [deviceDialogClosable, setDeviceDialogClosable] = React.useState(true);
  const [deviceSettingVisible, setDeviceSettingVisible] = React.useState(false);
  // const [hasStatus, setHasStatus] = useState(false);
  // const [hasReceiveBy, setHasReceiveBy] = useState(false);
  // const [hasReceiveDate, setHasReceiveDate] = useState(false);
  // const [hadRemmoveDate, setHadRemoveDate] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchType, setSearchType] = useState<any[]>([1, 2]); // rma = 1  erp =2
  const [exportLablePdfVisible, setExportLablePdfVisible] = useState(false);
  const [shippedDateDisabled, setShippedDateDisabled] = useState(false);
  const [receveDateDisabled, setReceiveDateDisabled] = useState(false);
  const [returnDateDisabled, setReturnDateDisabled] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [oper, setOper] = useState<number>(0);
  const [specialInfo, setSpecialInfo] = useState<any>({
    2: undefined,
    3: undefined,
    4: undefined,
  });
  const [receiveDisabled, setReceiveDisabled] = useState(false);

  const [form] = Form.useForm();


  const getAllSpecialInfo = useCallback(async () => {
    setIniting(true);
    if (
      cacheInfo &&
      cacheInfo.warehouseNum &&
      cacheInfo.warehouseNum === selectedWarehouse?.id
    ) {
      if (cacheInfo.specialWarehouseInfo) {
        let temp: SpecialInfoType = {
          2: undefined,
          3: undefined,
          4: undefined,
        };
        cacheInfo.specialWarehouseInfo.map((i: any) => {
          if (i.warehouseType === 2) {
            temp[2] = i;
          }
          if (i.warehouseType === 3) {
            temp[3] = i;
          }
          if (i.warehouseType === 4) {
            temp[4] = i;
          }
          return true;
        });
        if (!temp[2] || !temp[3] || !temp[4]) {
          setReceiveDisabled(true);
          message.info({
            content:
              'Warehouse is not found, please check whether you have permission or whether the warehouse exists',
          });
        }
        setSpecialInfo(temp);
        setIniting(false);
      }
    } else {
      try {
        const res = await getSpecialLocations(
          selectedWarehouse?.warehouseId || '',
          0
        );
        if (res) {
          dispatch(
            specialWarehouseInfo({
              warehouseNum: selectedWarehouse?.id || 0,
              specialWarehouseInfo: res,
            })
          );
        }
      } catch (error) { }
    }
  }, [selectedWarehouse, cacheInfo, dispatch]);

  /**
 * handle remote search
 */
  const handleRemoteSearch = useCallback(async () => {
    const params = trimValues(await form.validateFields());
    const queryparams = {
      channelOrderId:
        keyWordType === 'channelOrderId'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,
      SalesorderNumber:
        keyWordType === 'SalesorderNumber'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,
      secondaryChannelOrderId:
        keyWordType === 'secondaryChannelOrderId'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,
      sellerOrderId:
        keyWordType === 'sellerOrderId'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,
      centralOrderNum:
        keyWordType === 'centralOrderNum'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,
      returnNo:
        keyWordType === 'RMA#'
          ? params.keyWord
            ? params.keyWord
            : undefined
          : undefined,

      channelAccountNum: params.channelAccountNum,
      invoice: params.invoice,
      customerName: params.customerName,
      customerAddress: params.customerAddress,
      customerZipCode: params.customerZipCode,
      trackingNumber: params.trackingNumber,
      referenceNo: params.referenceNo,
      sku: params.sku,
      shipDate: params.createDate
        ? `${moment(params.createDate[0]).format('YYYY-MM-DD HH:mm')}@${moment(
          params.createDate[1]
        ).format('YYYY-MM-DD HH:mm')}`
        : undefined,
      returnDate: params.returnDate
        ? `${moment(params.returnDate[0]).format('YYYY-MM-DD HH:mm')}@${moment(
          params.returnDate[1]
        ).format('YYYY-MM-DD HH:mm')}`
        : undefined,
      oper,
    };
    try {
      setRemoteLoading(true);
      const res = await fetchRemoteReturns('1-0', queryparams);
      setRemoteLoading(false);
      if (res) {
        if (searchType.length === 2 && res.data.length === 0) {
          message.info('No matching results found')
        } else {
          setCurrentWarehouseId(params.warehouseNum.warehouseId);
          setCurrentWarehouseCode(params.warehouseNum.warehouseCode);
          setRemoteData(res.data);
          setRemoteDialogVisible(true);
        }
      }
    } catch (error) {
      setRemoteLoading(false);
    }
  }, [form, keyWordType, searchType, oper]);


  /**
 * handle search event
 */
  const handleSearch = useCallback(
    async (limit?: number, skip?: number, sortString?: string) => {
      // setSearchType(1);
      const params = trimValues(await form.validateFields());
      const queryparams = {
        channelOrderId:
          keyWordType === 'channelOrderId'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        SalesorderNumber:
          keyWordType === 'SalesorderNumber'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        secondaryChannelOrderId:
          keyWordType === 'secondaryChannelOrderId'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        sellerOrderId:
          keyWordType === 'sellerOrderId'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        centralOrderNum:
          keyWordType === 'centralOrderNum'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        returnNo:
          keyWordType === 'RMA#'
            ? params.keyWord
              ? params.keyWord
              : undefined
            : undefined,
        channelAccountNum: params.channelAccountNum,
        invoice: params.invoice,
        customerName: params.customerName,
        customerAddress: params.customerAddress,
        customerZipCode: params.customerZipCode,
        trackingNumber: params.trackingNumber,
        sku: params.sku,
        shipDate: params.createDate
          ? `${convertToUtcTime(params.createDate[0].format('YYYY-MM-DD HH:mm'))}@${convertToUtcTime(params.createDate[1].format('YYYY-MM-DD HH:mm'))}`
          : undefined,
        status: params.status,
        receiveBy: params.receiveBy,
        referenceNo: params.referenceNo,
        receiveDate: params.receiveDate
          ? `${convertToUtcTime(params.receiveDate[0].format('YYYY-MM-DD HH:mm'))}@${convertToUtcTime(params.receiveDate[1].format('YYYY-MM-DD HH:mm'))}`
          : undefined,
        returnDate: params.returnDate
          ? `${convertToUtcTime(params.returnDate[0].format('YYYY-MM-DD HH:mm'))}@${convertToUtcTime(params.returnDate[1].format('YYYY-MM-DD HH:mm'))}`
          : undefined,
        oper,
      };
      try {
        setLoading(true);
        setSearchLoading(true);
        const res = await fetchReturns(params.warehouseNum.warehouseId, {
          ...queryparams,
          $top: limit ? limit : limitRef.current,
          $skip: skip ? skip : 0,
          $sortBy: sortString || 'FulfillmentReturnNum desc',
        });
        setLoading(false);
        setSearchLoading(false);
        if (res) {
          setData(res.data.map((i: any) => { return { ...i, warehouseId: params.warehouseNum.warehouseId } }));
          setTotal(res.count);
          skipRef.current = skip || 0;
          limitRef.current = limit || limitRef.current;
          setCurrentWarehouseId(params.warehouseNum.warehouseId);
          setCurrentWarehouseCode(params.warehouseNum.warehouseCode);
          // setTempParams({
          //   warehouseId: params.warehouseNum.warehouseId,
          //   channelOrderId:
          //     keyWordType === 'channelOrderId' ? params.keyWord : undefined,
          //   secondaryChannelOrderId:
          //     keyWordType === 'secondaryChannelOrderId'
          //       ? params.keyWord
          //       : undefined,
          //   sellerOrderId:
          //     keyWordType === 'sellerOrderId' ? params.keyWord : undefined,
          //   centralOrderNum:
          //     keyWordType === 'centralOrderNum' ? params.keyWord : undefined,
          //   returnNo: keyWordType === 'RMA#' ? params.keyWord : undefined,

          //   channelAccountNum: params.channelAccountNum,
          //   invoice: params.invoice,
          //   customerName: params.customerName,
          //   customerAddress: params.customerAddress,
          //   customerZipCode: params.customerZipCode,
          //   trackingNumber: params.trackingNumber,
          //   sku: params.sku,
          //   referenceNo: params.referenceNo,
          //   createDate: params.createDate
          //     ? `${moment(params.createDate[0]).format('YYYY-MM-DD')}@${moment(
          //       params.createDate[1]
          //     ).format('YYYY-MM-DD')}`
          //     : undefined,
          // });
          setTempParams({
            ...queryparams,
            warehouseId: params.warehouseNum.warehouseId,
          });
          if (res.count === 0 && searchType.indexOf(2) > -1) {
            handleRemoteSearch()
          }
        }
      } catch (error) {
        //setLoading(false);
        setSearchLoading(false);
        setLoading(false);
      }
    },
    [form, keyWordType, handleRemoteSearch, searchType, oper]
  );

  // const deleteReturn = useCallback(async (data: any, closeModal?: boolean) => {
  //   try {
  //     const res = await delReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
  //     if (res.isSuccess) {
  //       message.success('deleted successfully')
  //       if (closeModal) {
  //         setDetailDialogVisible(false);
  //         setRemoteDialogVisible(false)
  //       }
  //       handleSearch()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [handleSearch])

  const handleAbandonReturn = useCallback(async (data: any) => {
    try {
      const res = await abandonReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
      if (res.isSuccess) {
        message.success('Now you can edit this RMA in ERP');
        handleSearch()
      }
    } catch (error) {
      console.log(error)
    }
  }, [handleSearch])

  // const refreshRMAReturn = useCallback(async (data: any) => {
  //   try {
  //     const res = await refeshReturn(getWarehouseIdFromCache(data.warehouseNum), data.fulfillmentReturnNum)
  //     if (res.isSuccess) {
  //       message.success('refresh successfully')
  //       handleSearch()
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }, [handleSearch])


  const columns = [
    {
      header: 'Custom Name',
      name: 'shipToName',
      userSelect: true,
      sortable: true,
      resizable: true,
      draggable: true,
      defaultFlex: 1,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={data.shipToName || data.endBuyerName} />;
      },
    },
    {
      header: 'RMA#',
      name: 'returnNo',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      minWidth: 160,
      render: (row: any) => {
        const { data } = row;
        return (
          <SpaceCell
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.returnNo);
                    message.info(
                      `"${data.returnNo}" has been copied to clipboard`
                    );
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => {
              setCurrent(data);
              setDetailDialogVisible(true);
            }}
            text={data.returnNo}
            textIsButton
          />
        );
      },
    },
    {
      header: 'SO #',
      name: 'salesOrderNumber',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      minWidth: 160,
      defaultFlex: 1,
    },
    {
      header: 'Channel Order ID',
      name: 'channelOrderId',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Invoice #',
      name: 'invoiceNo',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Channel',
      name: 'channelName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Store Name',
      name: 'channelAccountName',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Return Date',
      name: 'transDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Order Qty',
      name: 'orderQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Claim Qty',
      name: 'returnQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Receive Qty',
      name: 'receiveQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Receive Date',
      name: 'receiveDate',
      resizable: true,
      draggable: true,
      userSelect: true,
      sortable: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Receive By',
      name: 'receiveBy',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Inspection Qty',
      name: 'inspectionQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Resaleable Qty',
      name: 'stockQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Damage Qty',
      name: 'nonStockQty',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Status',
      name: 'status',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      renderEnum: statusEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={statusEnum[`${data.status}`]} />;
      },
    },
    {
      header: 'Action',
      name: 'action',
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Button
                    id="grid_detail_button"
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<FileExcelOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setDetailDialogVisible(true);
                    }}
                  >
                    Detail
                  </Button>
                </Menu.Item>
                <Menu.Item key="2">
                  <Button
                    id="grid_receive_button"
                    disabled={
                      data.status === 2 ||
                      data.status === 8 ||
                      data.warehouseNum !==
                      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                    }
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DiffOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setReceiveModalVisible(true);
                    }}
                  >
                    Receive
                  </Button>
                </Menu.Item>
                {/* <Menu.Item key="3">
                  <Button
                    id="grid_restock_button"
                    disabled={
                      data.status === 2 ||
                      data.warehouseNum !==
                      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                    }
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DiffOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setRestockModalVisible(true);
                    }}
                  >
                    Restock
                  </Button>
                </Menu.Item> */}
                <Menu.Item key="4">
                  <Button
                    id="grid_complete_button"
                    disabled={data.status === 2 || data.status === 8}
                    onClick={() => {
                      setCurrent(data);
                      setCloseDetailDialogVisible(true);
                    }}
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<CheckOutlined />}
                  >
                    Complete
                  </Button>
                </Menu.Item>


                {/* {data.status !== 2 && <Menu.Item key="7">
                  <Button
                    id="grid_complete_button"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want to refresh this return?',
                        icon: <ExclamationCircleOutlined />,
                        content: `RMA# : ${data.returnNo}`,
                        okText: 'Yes',
                        okType: 'default',
                        // okButtonProps: { className: 'hover-danger-button' },
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onCancel() { },
                        onOk() {
                          refreshRMAReturn(data);
                        },
                      });
                    }}
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<ReloadOutlined />}
                  >
                    Refresh
                  </Button>
                </Menu.Item>} */}

                {/* {data.status === 0 && (
                  <Menu.Item key="6">
                    <Button
                      id="grid_delete_button"
                      type="text"
                      className="hover-danger-button-2"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to delete this return?',
                          icon: <ExclamationCircleOutlined />,
                          content: `RMA# : ${data.returnNo}`,
                          okText: 'Yes',
                          okType: 'default',
                          okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onCancel() { },
                          onOk() {
                            deleteReturn(data);
                          },
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Menu.Item>
                )} */}

                {data.status === 0 && (
                  <Menu.Item key="6">
                    <Button
                      id="grid_edit_button"
                      type="text"
                      className="hover-danger-button-2"
                      style={{ width: '100%', textAlign: 'left' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure you want to Edit this return?',
                          icon: <ExclamationCircleOutlined />,
                          content: `RMA# : ${data.returnNo}`,
                          okText: 'Yes',
                          okType: 'default',
                          okButtonProps: { className: 'hover-danger-button' },
                          cancelText: 'No',
                          autoFocusButton: 'cancel',
                          onCancel() { },
                          onOk() {
                            handleAbandonReturn(data);
                          },
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </Menu.Item>
                )}

                <Menu.Item key="5">
                  <Button
                    id="grid_receive_button"
                    disabled={
                      data.status === 2 ||
                      data.status === 8 ||
                      data.warehouseNum !==
                      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
                    }
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DiffOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setReceiveModalVisible1(true);
                    }}
                  >
                    Receive Plus
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button
              id={`catalog_grid_action_row_${rowIndex}_dropdown`}
              icon={<SettingFilled />}
            >
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      header: 'Enter Date',
      name: 'createDate',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DATE_TIME_NO_SECOND_FORMAT,
    },
    {
      header: 'Shipping Label PDF',
      name: 'shippingStatus',
      resizable: true,
      draggable: true,
      sortable: true,
      textAlign: 'center',
      maxWidth: 200,
      minWidth: 200,
      render(row: any) {
        const {
          row: { data },
        } = { row };
        return (
          <Row>
            <Col offset={5} span={14}>
              <Tooltip placement="top" title="Generate Shipping Label PDF">
                <PackSlipCell align="middle" justify="space-between">
                  <span>
                    {data.shippingStatus === 0 ? 'Not Printed' : 'Printed'}
                  </span>
                  <FilePdfOutlined
                    className="pdf-printed-icon"
                    style={{ cursor: 'pointer', color: '#1976d2' }}
                    onClick={() => {
                      if (data.shippingStatus === 0) {
                        setCurrent(data);
                        setExportLablePdfVisible(data);
                      } else {
                        handleExportShippingLabel(data);
                      }
                    }}
                  />
                </PackSlipCell>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      header: 'Reference #',
      name: 'referenceNo',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Return Tracking #',
      name: 'returnTrackingNumber',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
      // render: (value: any) => {
      //   const { data } = value;
      //   return <CopySpan inGrid value={data.returnTrackingNumber || data.trackingNumber} />;
      // },
    },
    {
      header: 'Tracking #',
      name: 'trackingNumber',
      resizable: true,
      draggable: true,
      sortable: true,
      userSelect: true,
      defaultFlex: 1,
    },
  ];

  const handleExportShippingLabel = async (data: any) => {
    if (data.shippingStatus !== 1) {
      message.error({
        content: `shipment status(${statusKeyValue[data.shippingStatus]
          }) cann't be export.`,
      });
      return;
    }
    setLoading(true);
    try {
      const profile = userProfiles.profile;
      const params = {
        FulfillmentReturnNum: data.fulfillmentReturnNum,
        ShippingAccountNum: data.shipAccountNum,
        // Carrier: '',
        CarrierNum: data.carrierNum,
        ShipServiceNum: data.carrierServiceNum,
        ShipPackageNum: data.shipPackageNum,
        Weight: data.weight,
        Length: data.length,
        Width: data.width,
        Height: data.height,
        Confirmation: null,
        // InsuredValue: null,
        PrinterAccountNum: 0,
        // PrinterNum: 0,
        PrinterName: 'Printer',
        // BillToType: '',
        // BillThirdPartyAccount: '',
        // BillThirdPartyCountry: '',
        // BillThirdPartyPostalCode: '',
        MasterAccountNum: profile.masterAccountNum,
        ProfileNum: profile.profileNum,
      };
      const res = await printReturnShippingLabel(params);
      const content = res;
      const blob = new Blob([content], { type: 'application/pdf' });
      const fileName = `RMA-${data.returnNo}.pdf`;
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      }
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  const openDeviceSettingDialog = (config: StringKAnyVPair) => {
    setDeviceDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDeviceSettingVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openDeviceSettingDialog({ closable: false });
    }
  }, []);

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      }
    }
  }, [whs]);

  /**
   * handle reset event
   */
  const handleReset = useCallback(() => {
    form.resetFields();
    setSearchType([1, 2]);
  }, [form]);

  /**
   * handle datagrid change event
   */
  const handleDataGridChange = useCallback(
    async (limit: number, skip: number, sort: string) => {
      if (!tempParams) return;
      const queryparams = {
        $top: limit ? limit : 10,
        $skip: skip ? skip : 0,
        $sortBy: sortString || 'FulfillmentReturnNum desc',
        ...tempParams,
      };
      try {
        setLoading(true);
        const res = await fetchReturns(tempParams.warehouseId, queryparams);
        setLoading(false);
        if (res) {
          setData(res.data.map((i: any) => { return { ...i, warehouseId: tempParams.warehouseId } }));
          setTotal(res.count);
          skipRef.current = skip || 0;
          limitRef.current = limit || 10;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [tempParams]
  );

  const uniqueParamsChanged = useCallback(async () => {
    const { invoice, keyWord } = await form.getFieldsValue();
    if ((invoice || '').trim() || (keyWord || '').trim()) {
      form.setFieldsValue({ createDate: undefined, returnDate: undefined, receiveDate: undefined });
      setShippedDateDisabled(true);
      setReturnDateDisabled(true);
      setReceiveDateDisabled(true);
    } else {
      setShippedDateDisabled(false);
      setReturnDateDisabled(false);
      setReceiveDateDisabled(false);
      form.setFieldsValue({
        createDate: [
          moment().add(-90, 'day').startOf('day'),
          moment().add(-3, 'day').endOf('day'),
        ],
        returnDate: [
          moment().add(-7, 'day').startOf('day'),
          moment().endOf('day'),
        ]
      });
    }
  }, [form]);

  const changedtoErp = useCallback(() => {
    form.setFieldsValue({
      returnDate: undefined,
    });
    uniqueParamsChanged();
  }, [form, uniqueParamsChanged]);

  useEffect(() => {
    if (searchType.indexOf(2) === 0 && searchType.length === 1) {
      changedtoErp();
    }
  }, [searchType, changedtoErp]);

  /* eslint-disable */
  useEffect(() => {
    const fetch = async () => {
      const res = await fetchStoreList();
      if (res.isSuccess) {
        setStores(
          res.data
            .filter((i: any) => i.hideFromSelection === 0)
            .sort((a: any, b: any) => sortByString(a, b, 'channelAccountName'))
        );
      }
    };
    fetch();
  }, []);
  /* eslint-enable */

  React.useEffect(() => {
    if (whs) {
      const exist = whs.filter(
        (e) =>
          e.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)) &&
          (e.warehouseType === 1 || e.warehouseType === 5)
      );
      if (exist.length === 0) {
        openDeviceSettingDialog({ closable: false });
      } else {
        setSelectedWarehouse(exist[0]);
      }
    }
  }, [whs]);

  useEffect(() => {
    if (
      selectedWarehouse &&
      selectedWarehouse.id ===
      Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    ) {
      // getSpecialWarehouse();
      getAllSpecialInfo();
    }
  }, [selectedWarehouse, getAllSpecialInfo]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Return',
          `RMA List (Warehouse : ${getWarehouseCodeFromCache(
            Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
          )}）`,
        ]}
      />
      <GreyCardWrapper
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form
          form={form}
          {...formItemLayout}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <ClassicCard>
            <Row>
              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 9 },
                    style: { display: 'flex', flexDirection: 'row', justifyContent: 'end' }
                  }}
                  label={
                    <>
                      <Select
                        onChange={(value) => {
                          setKeyWordType(value);
                          form.setFieldsValue({
                            keyWord: ''
                          });
                        }}
                        id="form_select_label_keyword"
                        style={{ padding: 0, fontWeight: 900 }}
                        size="small"
                        bordered={false}
                        dropdownMatchSelectWidth={false}
                        value={keyWordType}
                        defaultValue="channelOrderId"
                      >
                        <Select.Option value="channelOrderId">
                          Channel Order ID
                        </Select.Option>
                        <Select.Option value="SalesorderNumber">
                          SO #
                        </Select.Option>
                        <Select.Option value="secondaryChannelOrderId">
                          2nd Chennel Order ID
                        </Select.Option>
                        <Select.Option value="sellerOrderId">
                          Seller Order ID
                        </Select.Option>
                        <Select.Option value="centralOrderNum">
                          Central Order ID
                        </Select.Option>
                        <Select.Option value="RMA#">RMA#</Select.Option>
                      </Select>
                    </>
                  }
                >
                  <Form.Item noStyle name="keyWord">
                    <Input
                      addonBefore={
                        <Select
                          value={oper}
                          onChange={(value) => setOper(value)}
                          dropdownMatchSelectWidth={100}
                        //  disabled={keyWordType === 'centralOrderNum' || keyWordType === 'fulfillmentOrderId'}
                        >
                          <Select.Option value={0}>equals</Select.Option>
                          <Select.Option value={1}>starts with</Select.Option>
                          {/* <Select.Option value={2}>contains</Select.Option> */}
                        </Select>
                      }
                      id="form_keyword_input"
                      type="text"
                      allowClear
                      onChange={() => {
                        uniqueParamsChanged();
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  id="form_warehouse_select"
                  name="warehouseNum"
                  label={<FormLabel>Warehouse</FormLabel>}
                >
                  <SelectWarehouse onlyNormal disabled={searchType.indexOf(2) === 0 && searchType.length === 1} />
                </Form.Item>
              </Col>

              <Col lg={8} md={12} sm={24}>
                <Form.Item
                  label={<FormLabel>Store Name</FormLabel>}
                  name="channelAccountNum"
                >
                  <Select
                    id="form_store_name_select"
                    showSearch
                    allowClear
                    placeholder="Please select"
                    style={{ width: '100%' }}
                    optionFilterProp="label"
                  >
                    {stores.filter(i => i.hideFromSelection === 0).map((item) => (
                      <Select.Option
                        key={item.channelAccountNum}
                        value={item.channelAccountNum}
                        label={item.channelAccountName}
                      >
                        {item.channelAccountName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="invoice"
                  label={<FormLabel>Invoice</FormLabel>}
                >
                  <Input
                    id="form_invoice_input"
                    allowClear
                    onChange={() => {
                      uniqueParamsChanged();
                    }}
                  />
                </Form.Item>
              </Col>

              {/* <Col lg={8} sm={24} md={12}>
                <Form.Item name="TrackingNumber" label={<FormLabel>TrackingNumbers</FormLabel>}>
                  <Input />
                </Form.Item>
              </Col> */}

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="createDate"
                  initialValue={[
                    // moment().add(-90, 'day').startOf('day'),
                    // moment().add(-3, 'day').endOf('day'),\
                    moment().add(-180, 'day').startOf('day'),
                    moment().add(-3, 'day').endOf('day'),
                  ]}
                  label={<FormLabel>Shipped Date</FormLabel>}
                >
                  <RangePicker
                    id="form_crete_date_range_picker"
                    style={{ width: '100%' }}
                    disabled={shippedDateDisabled}
                    allowClear={searchType.includes(2) ? false : true}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>

              {/* <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="EnterDate"
                  label={<FormLabel>Enter Date</FormLabel>}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col> */}

              <Col lg={8} sm={24} md={12}>
                <Form.Item name="sku" label={<FormLabel>SKU / UPC</FormLabel>}>
                  <Input id="form_sku_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="customerName"
                  label={<FormLabel>Ship Name</FormLabel>}
                >
                  <Input id="form_custom_name_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="customerAddress"
                  label={<FormLabel>Address</FormLabel>}
                >
                  <Input id="form_customer_address_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="customerZipCode"
                  label={<FormLabel>Zip</FormLabel>}
                >
                  <Input id="form_custom_zip_input" />
                </Form.Item>
              </Col>

              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="trackingNumber"
                  label={<FormLabel>Return Tracking #</FormLabel>}
                >
                  <Input id="form_tracking_number_input" />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="status"
                  initialValue={undefined}
                  label={<FormLabel>Status</FormLabel>}
                >
                  <Select
                    dropdownMatchSelectWidth={false}
                    allowClear
                    disabled={searchType.indexOf(2) === 0 && searchType.length === 1}
                  // onChange={(value) =>
                  //   setHasStatus(value || value === 0 ? true : false)
                  // }
                  >
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>Processing</Select.Option>
                    <Select.Option value={2}>Completed</Select.Option>
                    <Select.Option value={8}>Abandoned</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="receiveDate"
                  label={<FormLabel>Receive Date</FormLabel>}
                >
                  <RangePicker
                    id="form_receiveDate_picker"
                    disabled={receveDateDisabled || (searchType.indexOf(2) === 0 && searchType.length === 1)}
                    // onChange={(value) =>
                    //   setHasReceiveDate(value ? true : false)
                    // }
                    style={{ width: '100%' }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="receiveBy"
                  label={<FormLabel>Receive By</FormLabel>}
                >
                  <Input
                    id="form_receiveBy_input"
                    disabled={searchType.indexOf(2) === 0 && searchType.length === 1}
                  // onChange={(e) => setHasReceiveBy(!!e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="referenceNo"
                  label={<FormLabel>Reference #</FormLabel>}
                >
                  <Input id="form_referenceNo_input" />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="returnDate"
                  initialValue={[
                    // moment().add(-7, 'day').startOf('day'),
                    // moment().endOf('day'),
                    moment().add(-30, 'day').startOf('day'),
                    moment().endOf('day'),
                  ]}
                  label={<FormLabel>Return Date</FormLabel>}
                >
                  <RangePicker
                    id="form_returnDate_picker"
                    disabled={returnDateDisabled}
                    // onChange={(value) =>
                    //   setHasReceiveDate(value ? true : false)
                    // }
                    style={{ width: '100%' }}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment('00:00:00', 'hh:mm:ss'),
                        moment('23:59:59', 'hh:mm:ss'),
                      ],
                    }}
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                    format="MM/DD/YYYY hh:mm:ssA"
                  />
                </Form.Item>
              </Col>

              {/* <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="TransferStatus"
                  label={<FormLabel>Status</FormLabel>}
                >
                  <Select dropdownMatchSelectWidth={false} allowClear>
                    <Select.Option value={0}>Pending</Select.Option>
                    <Select.Option value={1}>End</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Row justify="center" align="middle" wrap={false}>
              <Space>
                {/* <Radio.Group
                  onChange={(e) => setSearchType(e.target.value)}
                  value={searchType}
                >
                  <Radio value={1}>Search RMA</Radio>
                  <Radio value={2}>Search ERP</Radio>
                </Radio.Group> */}
                <Checkbox.Group value={searchType} onChange={(value) => setSearchType(value)}>
                  <Row wrap={false}>
                    <Checkbox disabled={searchType.indexOf(1) === 0 && searchType.length === 1} value={1}>Search RMA</Checkbox>
                    <Checkbox disabled={searchType.indexOf(2) === 0 && searchType.length === 1} value={2}>Search ERP</Checkbox>
                  </Row>
                </Checkbox.Group>
                <Button
                  id="form_search_button"
                  type="primary"
                  onClick={() => {
                    searchType.indexOf(1) > -1 ? handleSearch() : handleRemoteSearch();
                  }}
                  loading={searchLoading || remoteLoading}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
                {/* <Button
                  id="from_remote_search_button"
                  disabled={hasReceiveBy || hasReceiveDate || hasStatus}
                  loading={remoteLoading}
                  type="primary"
                  onClick={handleRemoteSearch}
                  icon={<SearchOutlined />}
                >
                  Search ERP
                </Button> */}
                <ExportCSV
                  id="form_export_button"
                  data={data || []}
                  columns={columns.filter((item) => item.header !== 'Action')}
                  scope="OrderReturn"
                  formatColumns={{ status: statusEnum }}
                />
                <ClearButton id="form_clean_button" onClick={handleReset} />
              </Space>
            </Row>
          </ClassicCard>
        </Form>
      </GreyCardWrapper>

      {!initing && data && (
        <GreyCardWrapper
          style={{
            marginTop: 20,
          }}
        >
          <TablesFilter
            dataSource={data}
            columns={columns}
            setFilteredData={setFilteredData}
          />
          <DataGridPlus
            skip={skipRef.current}
            limit={limitRef.current}
            name="OrderReturnReport"
            autoWith={false}
            showScrollButton
            count={total}
            onSortInfoChange={(v: any, d: any) => {
              if (v) {
                if (v.dir === 1) {
                  sortString.current = `${v.id} asc`;
                  handleDataGridChange(
                    limitRef.current,
                    skipRef.current,
                    sortString.current
                  );
                }
                if (v.dir === -1) {
                  sortString.current = `${v.id} desc`;
                  handleDataGridChange(
                    limitRef.current,
                    skipRef.current,
                    sortString.current
                  );
                }
              } else {
                sortString.current = 'FulfillmentReturnNum desc';
                handleDataGridChange(
                  limitRef.current,
                  skipRef.current,
                  sortString.current
                );
              }
            }}
            dataSource={filteredData}
            onChange={(limit, skip) => {
              limitRef.current = limit;
              skipRef.current = skip;
              handleDataGridChange(limit, skip, sortString.current);
            }}
            pageSizes={[10, 50, 200, 500, 1000]}
            loading={loading}
            columns={columns}
            idProperty="fulfillmentReturnNum"
            pagination="remote"
            /* eslint-disable react/jsx-props-no-spreading */
            {...({} as any)}
          />
        </GreyCardWrapper>
      )}
      {detailDialogVisible && current && (
        <DetailModal
          dialogData={current}
          isCreate={false}
          warehouseId={currentWarehouseId}
          warehouseCode={currentWarehouseCode}
          onHide={() => setDetailDialogVisible(false)}
          onRefresh={() => handleSearch()}
          onShippingLabel={(data: any) => {
            if (data?.shippingStatus === 0) {
              setCurrent(data);
              setExportLablePdfVisible(data);
            } else {
              handleExportShippingLabel(data);
            }
          }}
          noDownLoad={true}
          onReceive={(data: any) => {
            if (data) setCurrent(data);
            setDetailDialogVisible(false);
            setReceiveModalVisible(true);
          }}
          onRestock={(data: any) => {
            if (data) setCurrent(data);
            setDetailDialogVisible(false);
            setRestockModalVisible(true);
          }}
          onComplete={(data: any) => {
            if (data) setCurrent(data);
            setDetailDialogVisible(false);
            setCloseDetailDialogVisible(true);
          }}
          onReceivePlus={(data: any) => {
            if (data) setCurrent(data);
            setDetailDialogVisible(false);
            setReceiveModalVisible1(true);
          }}
        // onExpressReceive={(data: any)=>{
        //   setDetailDialogVisible(false);
        //   setExpressReceiveModalVisible(true);
        // }}
        />
      )}
      {remoteDialogVisible && (
        <RemoteDialog
          warehouseId={currentWarehouseId}
          warehouseCode={currentWarehouseCode}
          data={remoteData}
          visible
          changeCurrent={(erpCurrent: any) => setCurrent(erpCurrent)}
          onHide={() => setRemoteDialogVisible(false)}
          onRefresh={() => {
            setRemoteDialogVisible(false);
            handleSearch();
          }}
          onReceive={(data: any) => {
            setCurrent(data)
            setRemoteDialogVisible(false);
            setReceiveModalVisible(true);
          }}
          onRestock={(data: any) => {
            setCurrent(data)
            setRemoteDialogVisible(false);
            setRestockModalVisible(true);
          }}
          onComplete={(data: any) => {
            setCurrent(data)
            setCloseDetailDialogVisible(true);
          }}
          onReceivePlus={(data: any) => {
            setCurrent(data)
            setReceiveModalVisible1(true);
          }}
          onShippingLabel={(data: any) => {
            if (data?.shippingStatus === 0) {
              setCurrent(data);
              setExportLablePdfVisible(data);
            } else {
              handleExportShippingLabel(data);
            }
          }}
          // onExpressReceive={(data: any) => {
          //   setCurrent(data)
          //   setExpressReceiveModalVisible(true)
          // }}
          onExpressReceiveWithNoReturnQty={(data: any) => {
            setCurrent(data)
            setExpressReceiveModalVisible2(true)
          }}
        />
      )}
      {closeDetailDialogVisible && current && (
        <CompleteDetailModal
          current={current}
          onHide={() => setCloseDetailDialogVisible(false)}
          onRefresh={() => {
            setCloseDetailDialogVisible(false);
            handleSearch();
          }}
        />
      )}
      {receiveModalVisible1 && current && (
        <ReceiveDialog
          visible
          receiveDisabled={receiveDisabled}
          onClose={() => {
            setReceiveModalVisible1(false);
            setRemoteDialogVisible(false);
            handleDataGridChange(
              limitRef.current,
              skipRef.current,
              sortString.current
            );
          }}
          modalReturn={current}
          specialInfo={specialInfo}
          defaultWarehouseType={defaultWarehouseType}
          defaultLocationCode={defaultLocationCode}
          warehouseId={selectedWarehouse?.warehouseId || ''}
        />
      )}
      {receiveModalVisible && current && (
        <ReceiveModal
          visible
          onClose={() => {
            setReceiveModalVisible(false);
            setRemoteDialogVisible(false);
            handleDataGridChange(
              limitRef.current,
              skipRef.current,
              sortString.current
            );
          }}
          onShowDetail={(newCurrent: any) => {
            setReceiveModalVisible(false);
            setRemoteDialogVisible(false);
            if (newCurrent) {
              setCurrent({ ...newCurrent })
            }
            setDetailDialogVisible(true);
          }}
          onShowComplete={(newCurrent: any) => {
            setReceiveModalVisible(false);
            setRemoteDialogVisible(false);
            if (newCurrent) {
              setCurrent({ ...newCurrent })
            }
            setCloseDetailDialogVisible(true);
          }}
          modalReturn={current}
        />
      )}
      {restockModalVisible && current && (
        <RestockModal
          visible
          onClose={(key: string, newData: any) => {
            setRestockModalVisible(false);
            handleDataGridChange(
              limitRef.current,
              skipRef.current,
              sortString.current
            );
            if (key) {
              setCurrent({ ...newData });
              if (key === 'details') {
                setDetailDialogVisible(true);
              }
              if (key === 'receive') {
                setReceiveModalVisible1(true);
              }
              if (key === 'complete') {
                setCloseDetailDialogVisible(true);
              }
            }
          }}
          modalReturn={current}
        />
      )}
      {
        <DeviceSettingDialog
          closable={deviceDialogClosable}
          close={() => {
            setDeviceSettingVisible(false);
          }}
          visible={deviceSettingVisible}
        />
      }
      {exportLablePdfVisible && (
        <ExportLabelPDF
          visible
          onClose={() => {
            setExportLablePdfVisible(false);
            // setRemoteDialogVisible(false);
            // handleDataGridChange(limitRef.current, skipRef.current, '');
          }}
          // onHide={()=>setExportLablePdfVisible(false)}
          onRefresh={() => { }}
          shipment={current}
          markVerified={true}
        />
      )}
      {/* {expressReceiveModalVisible && current &&
        <ExpressReceiveDialog
          visible
          onClose={() => {
            setExpressReceiveModalVisible(false);
            setRemoteDialogVisible(false);
          }}
          onShowDetail={(newCurrent: any) => {
            setExpressReceiveModalVisible(false);
            setRemoteDialogVisible(false);
            if (newCurrent) {
              setCurrent({ ...newCurrent })
            }
            setDetailDialogVisible(true);
          }}
          modalReturn={current}
          specialInfo={specialInfo}
          defaultWarehouseType={defaultWarehouseType}
          defaultLocationCode={defaultLocationCode}
          warehouseCode={selectedWarehouse?.warehouseCode || ''}
          warehouseId={selectedWarehouse?.warehouseId || ''}
        />
      } */}
      {expressReceiveModalVisible2 && current &&
        <ExpressReceiveDialog2
          visible
          onClose={() => {
            setExpressReceiveModalVisible2(false);
            setRemoteDialogVisible(false);
          }}
          onShowDetail={(newCurrent: any) => {
            setExpressReceiveModalVisible2(false);
            setRemoteDialogVisible(false);
            if (newCurrent) {
              setCurrent({ ...newCurrent })
            }
            setDetailDialogVisible(true);
          }}
          modalReturn={current}
          specialInfo={specialInfo}
          defaultWarehouseType={defaultWarehouseType}
          defaultLocationCode={defaultLocationCode}
          warehouseCode={selectedWarehouse?.warehouseCode || ''}
          warehouseId={selectedWarehouse?.warehouseId || ''}
        />
      }
    </>
  );
}
