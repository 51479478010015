import React, { ReactNode } from 'react';
import { Form, Row } from 'antd';
import { FormLabel } from 'components/common';

type FormItemProps = {
  name: string;
  label: string;
  children: ReactNode;
  message?: string;
};
export default function AddItemsFormItem(props: FormItemProps) {
  const { name, label, children, message } = props;
  const rules = [{ required: message ? true : false, message }];
  let middleNode = children;
  let lastNode = null;
  if (Array.isArray(children)) {
    [middleNode, lastNode] = children;
  }
  return (
    <Row align="middle" justify="center" style={{ width: 492 }}>
      <div
        style={{
          width: 110,
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: 20,
        }}
      >
        <FormLabel>{label}</FormLabel>
      </div>
      <Form.Item name={name} rules={rules}>
        {middleNode}
      </Form.Item>
      <div style={{ marginLeft: 10 }}>{lastNode}</div>
    </Row>
  );
}
AddItemsFormItem.defaultProps = {
  message: undefined,
};
