import styled from 'styled-components';
import theme from 'assets/styles/theme';

export const DateInputSwitcher = styled.i`
  cursor: pointer;
`;

export const PickOptionWrapper = styled.div`
  border: 1px solid #dcdcdc;
  padding: 6px;
  height: 130px;
  border-radius: ${theme['@border-radius-base']};

  & .ant-col {
    height: 100%;
  }

  & .check-all {
    background-color: #ececec;
    text-align: center;
    width: 100%;
  }
`;

export const SectionHeader = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #dcdcdc;
`;
