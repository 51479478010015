import React, { useCallback, useEffect, useState } from 'react';
import { Row, Modal, Button, Space, Form, Input, Spin } from 'antd';
import { message } from 'components/common';
import { fetchProduct, patchProduct } from 'services/product';
import { leaveEditForm } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

type EditProductDetailsProps = {
  visible: boolean;
  onHide: () => void;
  onSuccess?: (id: number, product: StringKVPair) => void;
  product: { id: number; productId: string };
};
export default function EditProductTitleDialog(props: EditProductDetailsProps) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState<ProductRow | null>();
  const { visible, onHide, onSuccess } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    const fetch = async () => {
      if (props.product && props.visible) {
        console.log(props.product);
        setLoading(true);
        const newProduct = await fetchProduct(props.product.productId);
        setProduct(newProduct);
        if (newProduct) {
          form.setFieldsValue({
            ProductTitle: newProduct.productTitle,
            ShortDescription: newProduct.shortDescription,
            Notes: newProduct.notes,
          });
        }
        setLoading(false);
      }
    };
    fetch();
  }, [props, form]);
  const onSubmit = useCallback(async () => {
    if (product) {
      const values = await form.validateFields();
      if (values.errorFields) return;
      setLoading(true);
      try {
        const res = await patchProduct(props.product.productId, values);
        setLoading(false);
        if (!res.error) {
          message.success({ content: 'Edit product title success!' });
          if (onSuccess && 'function' === typeof onSuccess) {
            onSuccess(product.id, {
              title: values.ProductTitle,
            });
          }
          onHide();
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [product, props, onHide, onSuccess, form]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    if (product) {
      const cancel = await leaveEditForm(
        {
          ProductTitle: product.productTitle,
          ShortDescription: product.shortDescription,
          Notes: product.notes,
        },
        form
      );
      if (cancel) {
        onHide();
      }
    } else {
      onHide();
    }
  }, [onHide, product, form]);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  /**
   * Modal Footer
   * @returns {JSX.Element}
   */
  const Footer = (): JSX.Element => {
    return (
      <Row justify="end">
        <Space>
          <Button id="ok_button" key="submit" type="primary" onClick={onSubmit} icon={<CheckOutlined />}>
            Ok
          </Button>
          <Button onClick={handleCancel} id="cancel_button" icon={<CloseOutlined />}>Cancel</Button>
        </Space>
      </Row>
    );
  };
  return (
    <Modal
      title="Edit Product Title"
      centered
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={handleCancel}
      footer={<Footer />}
    >
      <Spin spinning={loading}>
        <Form
          /* eslint-disable react/jsx-props-no-spreading */
          {...layout}
          form={form}
        >
          <Form.Item
            name="ProductTitle"
            label="Title"
            rules={[{ required: true }]}
          >
            <Input.TextArea id="ProductTitle" rows={3} />
          </Form.Item>
          <Form.Item name="ShortDescription" label="Short Description">
            <Input.TextArea id="ShortDescription" rows={3} />
          </Form.Item>
          <Form.Item name="Notes" label="Long Description">
            <Input.TextArea id="Notes" rows={3} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
EditProductTitleDialog.defaultProps = {
  product: undefined,
  onSuccess: undefined,
};
