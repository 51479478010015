/**
 *InventoryLog related service requests are here.
 */
import { digitBridgeApi } from './http';

/**
 * fetch inventory logs
 * @param {{[key:string]:any}} query
 */
export const fetchInventoryLogs = async (
    query: { [key: string]: any; },
    $skip: number,
    $top: number,
    $sortBy: string, dataSource: number,
    otherKeyword: string = '',
    otherKeywordType: string
  ): Promise<any> => {
  let params = {
    queryInventoryLog: query,
    dataSource,
    $skip,
    $top,
    $sortBy,
    $count: true
  };
  if(otherKeyword) {
    //@ts-ignore
    params[otherKeywordType] = otherKeyword;
  }
  return await digitBridgeApi.get('/api/inventoryLog', {
    params,
  });
};
