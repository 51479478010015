import React, { useState, useCallback, useEffect } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import message from './message';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { convertTimeByUtc } from 'utils';

type Props = {
  data: any[];
  columns: any[];
  formatColumns?: any;
  buttonProps?: ButtonProps;
  title?: React.ReactNode;
};

export default function CopySpan(props: Props) {
  const { data, columns, formatColumns, title, buttonProps } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>('');

  const initValue = useCallback(() => {
    setLoading(true);
    try {
      const selectedColumns: Array<string> = [];
      columns.forEach((item) => {
        selectedColumns.push(item.dataIndex || item.name);
      });
      const headers = [] as any;
      const lines = [] as any;
      selectedColumns.forEach((dataIndex: string) => {
        const column = columns.find((item) => {
          if (item.name === dataIndex || item.dataIndex === dataIndex) {
            return item;
          }
          return null;
        });
        if (column) {
          headers.push(column.header || column.title);
        }
      });
      lines.push(headers.join('\t'));
      data.forEach((row) => {
        const items = [] as any;
        selectedColumns.forEach((dataIndex: string) => {
          let value: string;
          value =
            formatColumns && formatColumns[dataIndex]
              ? formatColumns[dataIndex][`${row[dataIndex]}`]
              : row[dataIndex];
          if (isNaN(Number(value)) && moment.utc(value).isValid()) {
            value = convertTimeByUtc(value);
          }
          if (typeof value === 'string') {
            if (value.includes(',')) {
              if (value.includes('"')) {
                value = value.replace(/"/g, '""');
              }
              value = `"${value}"`;
            }
          } else {
            value = value || value === 0 ? `"${value}"` : '';
          }
          items.push(value);
        });
        lines.push(items.join('\t'));
      });
      setValue(lines.join('\n'));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [columns, data, formatColumns]);

  useEffect(() => {
    initValue();
  }, [initValue]);

  return (
    <CopyToClipboard
      text={value}
      options={{
        format: 'text/plain',
      }}
      onCopy={() => message.info({ content: 'Copied' })}
    >
      <Button type="text" {...buttonProps} icon={<CopyOutlined  style={{color: '#69b1ff'}}/>} loading={loading}>
        {title ? title : 'Copy'}
      </Button>
    </CopyToClipboard>
  );
}
