import React from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, Col, InputNumber, Modal, Row, Space } from 'antd';
import { isEqualObject } from 'crud-object-diff';
import { Label } from 'components/common/styles';
// import { TransactionType } from 'constants/config';
import {
  HelpMessage,
  message,
  FormLabel,
  DataGridPlus,
  ScreenMask,
  Loading,
} from 'components/common';
import { REGULAR_SECTION_ROW_VERTICAL_SPACING } from 'constants/config';
import { deselectAuditProductRows } from 'actions/inventoryActions';
// import { updateProductQuantity } from 'services/product';
import { confirmLeave } from 'utils/uiHelper';
import WarehouseSelectBox from '../../Warehouse/WarehouseSelectBox';
import WarehouseLocationSearchBox from '../../Warehouse/WarehouseLocationSearchBox';
import { inventoryBatchMove } from 'services/inventory';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

type ColumnRenderType = {
  cellProps?: any;
  data: InventoryProductRow;
};

type QuantityDictType = {
  [key: string]: {
    qty: number;
    move: number;
  };
};

type Props = {
  warehouseId: string;
  inventoryList: InventoryProductRow[];
  location: StringKAnyVPair;
  onHide: () => void;
  onSuccess?: Function;
  visible: boolean;
};

// eslint-disable-next-line
export default (props: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { inventoryList, location, onHide, onSuccess, visible, warehouseId } =
    props;
  const tempValue = React.useRef<number>(0);
  const [locationId, setLocationId] = React.useState(-1);
  const [rawState, setRawState] = React.useState<StringKAnyVPair>({});
  const [inited, setInited] = React.useState(false);
  const [lockScreen, setLockScreen] = React.useState(false);
  const [warehouseNum, setWarehouseNum] = React.useState(-1);
  //const [warehouseId, setWarehouseId] = React.useState('');
  const [quantityDict, setQuantityDict] = React.useState<QuantityDictType>({});
  const spacerStyle = { height: REGULAR_SECTION_ROW_VERTICAL_SPACING };
  const labelBoxStyle = { paddingRight: 5 };
  const locationCode = React.useMemo(() => {
    let ret = '';

    if (location.code && Array.isArray(location.code)) {
      ret = location.code.join(', ');
    } else if ('string' === typeof location.code) {
      ret = location.code;
    }

    return ret;
  }, [location]);
  const totalQuantity = React.useMemo(() => {
    let total = 0;

    for (let k in quantityDict) {
      total += quantityDict[k].move;
    }

    return total;
  }, [quantityDict]);

  const clearState = () => {
    setInited(false);
    setQuantityDict({});
    setLocationId(-1);
    setWarehouseNum(-1);
    //setWarehouseId('');
    //setTotalQuantity(0);
  };

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      headerAlign: 'center',
      defaultFlex: 1,
      userSelect: true,
      needToolTip: true,
    },
    {
      name: 'partNumber',
      header: 'Part Number',
      defaultFlex: 1,
      userSelect: true,
    },
    /*{
      name: 'lotNumber',
      header: 'Lot Number',
      defaultFlex: 1,
      userSelect: true,
    },*/
    {
      name: 'title',
      header: 'Product Name',
      headerAlign: 'center',
      defaultFlex: 1,
      userSelect: true,
      needToolTip: true,
    },
    {
      name: 'quantity',
      header: 'Quantity',
      defaultFlex: 1,
      headerAlign: 'center' as 'center',
      render: (value: ColumnRenderType) => {
        const { data } = value;

        return quantityDict[data.uuid]?.qty || 0;
      },
    },
    {
      name: '',
      header: '',
      defaultFlex: 1,
      maxWidth: 100,
      minWidth: 100,
      resizable: false,
      sortable: false,
      textAlign: 'center' as 'center',
      renderHeader: () => totalQuantity,
      render: (value: ColumnRenderType) => {
        const { data } = value;
        return (
          <InputNumber
            key={`${data.id}-${data.locationId}-${data.productNum}`}
            size="large"
            // min={0}
            // max={data.quantity}
            defaultValue={quantityDict[data.uuid]?.move}
            style={{ width: '100%', height: '100%' }}
            onChange={(value) => (tempValue.current = value)}
            onBlur={() => onChangeMoveNumber(data, tempValue.current)}
          />
        );
      },
    },
  ];

  const handleCancel = async () => {
    const formValue = {
      warehouseNum,
      locationId,
      move: quantityDict,
    };

    if (isEqualObject(formValue, rawState)) {
      onHide();
    } else {
      confirmLeave(onHide);
    }
  };

  const handleSubmit = async () => {
    // let update: StringKAnyVPair[] = [];

    if (warehouseNum < 0) {
      message.error('Please select a warehouse');
      return;
    }

    if (locationId < 0) {
      message.error('Please select a location');
      return;
    }

    // inventoryList.forEach((e: InventoryProductRow) => {
    //   if (quantityDict[e.uuid]) {
    //     const move = quantityDict[e.uuid].move;

    //     if (move > 0) {
    //       update.push({
    //         FulfillmentProductNum: e.productNum,
    //         WarehouseNum: e.warehouseId,
    //         LocationNum: e.locationId,
    //         Quantity: move,
    //         SKU: e.sku,
    //         TransactionType: TransactionType.Remove,
    //       });
    //       update.push({
    //         FulfillmentProductNum: e.productNum,
    //         WarehouseNum: warehouseNum,
    //         LocationNum: locationId,
    //         Quantity: move,
    //         SKU: e.sku,
    //         TransactionType: TransactionType.Add,
    //       });
    //     }
    //   }
    // });
    //console.log('up', update);
    const items = inventoryList.map((item) => {
      return {
        FulfillmentProductNum: item.productNum,
        Quantity: quantityDict[item.uuid].move,
      };
    });
    setLockScreen(true);
    try {
      // const res = await updateProductQuantity(update);
      const res = await inventoryBatchMove(warehouseId, location.id, {
        LocationNum: locationId,
        MoveItem: items,
      });
      let closeLater = false;

      if (res) {
        const deselect = () => {
          dispatch(
            deselectAuditProductRows(
              true,
              inventoryList.map((e) => e.id)
            )
          );
          setTimeout(() => {
            dispatch(deselectAuditProductRows(false));
          }, 100);
        };

        if (onSuccess && 'function' === typeof onSuccess) {
          closeLater = true;
          //onSuccess();
          setTimeout(() => {
            setLockScreen(false);
            message.success('Move inventories successfully');
            onSuccess();
            deselect();
            onHide();
          }, 2000); // use timeout to avoid React max deep execution warning
        }

        if (!closeLater) {
          message.success('Move inventories successfully');
          setLockScreen(false);
          onHide();
        }
      } else {
        message.error('Move inventories failed');
      }
    } catch (e) {
      setLockScreen(false);
      message.error(`Move inventories error: ${e}`);
    }
  };

  /**
   * The callback when the move number was changed.
   */
  const onChangeMoveNumber = (row: InventoryProductRow, value: number) => {
    const { quantity, uuid } = row;
    let dict = {
      ...quantityDict,
      [uuid]: {
        move: value && value > 0 ? (value < quantity ? value : quantity) : 0,
        qty: quantity,
      },
    };

    setQuantityDict(dict);
  };

  const onDialogClose = () => {
    clearState();
  };

  const onSelectLocation = (id: number) => {
    setLocationId(id);
  };

  const onSelectWarehouse = (id: number, opt: StringKAnyVPair) => {
    //console.log('warehouse id', id, location, opt);
    /*try {
      const json = JSON.parse(opt);
      setWarehouseId(json.warehouseId);
    } catch (error) {
      console.log(error);
    }*/
    setWarehouseNum(id);
  };

  React.useEffect(() => {
    if (visible && !inited) {
      let dict: QuantityDictType = {};

      inventoryList.forEach((e) => {
        dict[e.uuid] = {
          qty: e.quantity,
          move: e.quantity,
        };
      });
      setRawState({
        warehouseId: location.warehouseId,
        locationId,
        move: JSON.parse(JSON.stringify(dict)),
      });
      setQuantityDict(dict);
      setInited(true);
    }
  }, [inited, inventoryList, location, locationId, visible]);

  return (
    <Modal
      title="Move items to new Location"
      centered
      destroyOnClose={true}
      getContainer={false}
      onCancel={handleCancel}
      maskClosable={false}
      afterClose={onDialogClose}
      visible={visible}
      width={800}
      footer={
        <Row justify="space-between">
          <Space>
            <HelpMessage placement="top" helpId={4} marginLeft={5} />
          </Space>
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={locationId <= 0 || totalQuantity === 0}
              onClick={handleSubmit}
              icon={<SaveOutlined />}
            >
              Move
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Row>
        <Label>
          Select location to which you want to move item with quantity{' '}
          <span className="label-bold">'{totalQuantity}'</span>.
        </Label>
      </Row>
      <div style={spacerStyle}></div>
      <Row>
        <Label>
          <span className="label-bold">Current Location: </span>
          <span className="label-grey">{locationCode}</span>
        </Label>
      </Row>
      <div style={spacerStyle}></div>
      <Row align="middle">
        <Col span={4} offset={4}>
          <Row justify="end" style={labelBoxStyle}>
            <FormLabel>Warehouse</FormLabel>
          </Row>
        </Col>
        <Col span={12}>
          <WarehouseSelectBox
            disabled={true}
            onChange={onSelectWarehouse}
            value={location.warehouseId}
          />
        </Col>
      </Row>
      <div style={spacerStyle}></div>
      <Row align="middle">
        <Col span={4} offset={4}>
          <Row justify="end" style={labelBoxStyle}>
            <FormLabel>New Location</FormLabel>
          </Row>
        </Col>
        <Col span={12}>
          <WarehouseLocationSearchBox
            showPick
            warehouseNum={warehouseNum}
            excludedLocationList={
              Array.isArray(location.id) ? location.id : [location.id]
            }
            onChange={onSelectLocation}
          />
        </Col>
      </Row>
      <div style={spacerStyle}></div>
      <DataGridPlus
        columns={columns}
        dataSource={inventoryList}
        idProperty="uuid"
        pagination={false}
        showColumnMenuTool={false}
        {...({} as any)}
      />
      {lockScreen ? (
        <ScreenMask>
          <Loading size={48} />
        </ScreenMask>
      ) : (
        ''
      )}
    </Modal>
  );
};
