import React from 'react';
import ContentLayout from 'components/ContentLayout/SmartLayout';
import ReturnRtoDamage from 'components/Sales/Smart/MoveMerge/MoveWarehouse/ReturnRtoDamage'
import { getWarehouseCodeFromCache } from 'utils';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';

export default function Page(): JSX.Element {
  return (
    <ContentLayout
    titleKey="titles.returnRtoDamage" value={getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))}
 
    // title={`From Return-Resalable to Damage(W#: ${getWarehouseCodeFromCache(Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)))})`}
    >
      <ReturnRtoDamage />
    </ContentLayout>
  );
}

