/**
 * shopping around service requests are here.
 */
import { digitBridgeApi } from './http';


export const getShoppingAroundList = async (params: { warehouseNum: number, shoppingAroundCarrierServiceNum?: number }): Promise<any> => {
    return await digitBridgeApi.get('/api/GetWarehouseShoppingAroundList', {
        params,
    });
};

export const getAllShoppingAround = async (pageIndex: number, pageSize: number, warehouseNum?: number): Promise<any> => {
    const params = {
        pageIndex,
        pageSize,
        warehouseNum,
    }
    return await digitBridgeApi.get('/api/GetShoppingAroundList', {
        params,
    });
};


type WarehouseShoppingAroundParams = {
    WarehouseShoppingAroundNum: number,
    WarehouseNum: number,
    ShoppingAroundCarrierServiceNum: number,
    CarrierNum: number,
    CarrierServiceNum: number,
}
export const addWarehouseShoppingAround = async (params: WarehouseShoppingAroundParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/AddWarehouseShoppingAround', json)
}

export const copyShoppingAround = async (warehouseNum: number): Promise<any> => {
    const params = {
        warehouseNum
    }
    return await digitBridgeApi.get('/api/CopyToWarehouseShoppingAround', { params })
}

export const delWarehouseShoppingAround = async (id: number): Promise<any> => {
    return await digitBridgeApi.delete(`/api/warehouseShoppingArounds/${id}`)
}

export const setWarehouseShoppingAround = async (params: WarehouseShoppingAroundParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/SetWarehouseShoppingArounds', json)
}

export const updateWarehouseShoppingAround = async (params: WarehouseShoppingAroundParams): Promise<any> => {
    const json = JSON.stringify(params)
    return await digitBridgeApi.post('/api/UpdateWarehouseShoppingAround', json)
}