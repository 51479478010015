import React, { useCallback, useState, useRef } from 'react';
import { Row, Card, Form, Col, DatePicker, Space, Button, Input } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  ColumnDataType,
  DataGridPlus,
  FormLabel,
  HeaderTitle,
  SelectWarehouse,
  SetDefaultWarehouseDialog,
  TablesFilter,
} from 'components/common';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { queryReprintHistory } from 'services/reports';
import { convertToUtcTime, getWarehouseCodeFromCache } from 'utils';

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState<PoReceiveHistory[]>([]);
  const [count, setCount] = useState<number>(0);
  const [dialogClosable, setDialogClosable] = React.useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] =
    React.useState(false);
  const [tempParams, setTempParams] = useState<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const limitRef = useRef(10);
  const skipRef = useRef(0);

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  const [form] = Form.useForm();

  const columns = [
    {
      dataType: ColumnDataType.DATE,
      name: 'requestTime',
      header: 'Local Time',
      // headerAlign: 'center' as 'center',
      defaultFlex: 1,
    },
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'shipmentId',
      header: 'Shipment ID',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'trackingNumber',
      header: 'Tracking #',
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'printer',
      header: 'Printer',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'printerNum',
      header: 'Printer Num',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'source',
      header: 'Source',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'sourceNum',
      header: 'Source Num',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'updateBy',
      header: 'User',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      name: 'shippingLabelPrintLogNum',
      header: 'Log ID',
      userSelect: true,
      defaultFlex: 1,
    },
  ];

  const handleSearch = useCallback(async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    const { startDate, endDate, sku, shipmentId, warehouse } = await form.getFieldsValue();
    const params = {
      pageIndex: 1,
      pageSize: limitRef.current,
    };
    const bodyParams = {
      sku,
      shipmentId,
      start: convertToUtcTime(startDate.format()),
      end: convertToUtcTime(endDate.format()),
      warehouseNum: warehouse.id
    }
    const json = JSON.stringify(bodyParams);
    try {
      setLoading(true);
      const res = await queryReprintHistory(
        // Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
        warehouse.id,
        params,
        json
      );
      setLoading(false);
      if (res.isSuccess) {
        setHistoryList(res.data.data);
        setCount(res.data.count);
        setTempParams(bodyParams);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [skipRef, limitRef, form]);

  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      const params = {
        pageIndex: skip / limit + 1,
        pageSize: limit,
      };
      try {
        setLoading(true);
        const res = await queryReprintHistory(
          // Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY)),
          tempParams.warehouseNum,
          params,
          tempParams
        );
        setLoading(false);
        if (res.isSuccess) {
          setHistoryList(res.data.data);
          setCount(res.data.count);
          limitRef.current = limit;
          skipRef.current = skip;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [skipRef, limitRef, tempParams]
  );

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'Report',
          'Reprint History',
        ]}
      />
      <Row justify="center">
        <Card style={{ width: '100%', minWidth: 400 }}>
          <Form form={form}>
            <Row gutter={16}>
              <Col span={8}>
                <FormLabel>Warehouse</FormLabel>
                <Form.Item name="warehouse">
                  <SelectWarehouse />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>SKU</FormLabel>
                <Form.Item name="sku">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Shipment ID</FormLabel>
                <Form.Item name="shipmentId">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>Start Date</FormLabel>
                <Form.Item
                  name="startDate"
                  initialValue={moment().subtract(1, 'days').startOf('day')}
                >
                  <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <FormLabel>End Date</FormLabel>
                <Form.Item
                  name="endDate"
                  initialValue={moment().add(1, 'days').endOf('day')}
                >
                  <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="center">
            <Space>
              <Button type="primary" onClick={() => handleSearch()}>
                <SearchOutlined />
                Search
              </Button>
              <Button onClick={() => form.resetFields()}>
                <ClearOutlined />
                Reset
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
      <Card style={{ marginTop: 20 }}>
        <TablesFilter
          columns={columns}
          dataSource={historyList}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="sku"
          showScrollButton
          loading={loading}
          columns={columns}
          pageSizes={[10, 20, 50, 100]}
          dataSource={filteredData}
          pagination
          style={{ height: 500 }}
          count={count}
          onChange={(limit, skip) => handleDataGridChange(limit, skip)}
          {...({} as any)}
        />
      </Card>
      {
        <SetDefaultWarehouseDialog
          closable={dialogClosable}
          close={() => {
            setDefaultWarehouseVisible(false);
          }}
          visible={defaultWarehouseVisible}
        />
      }
    </>
  );
};
