import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Tooltip } from 'antd';
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import { RootState } from 'reducers';
import { GridIconBox } from 'components/common/styles';
import { CopySpan, DataGrid, message, SpaceCell } from 'components/common';
import EditProductQuantityDialog from './EditProductQuantityDialog';
import ProductDetailsDialog from '../ProductDetail/ProductDetailsDialog';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import { DATAGRID_CACHE_AUDIT_TABLE_COLS, DATAGRID_CELL_ICON_WIDTH1 } from 'constants/config';
import { getProfileSettingValue } from 'utils';

/**
 * Type definition for the properties of the component.
 *
 * @param {?boolean} isLoading - whether is loading the product list
 * @param {InventoryProductRow[]} productList
 * @param {Function} - onEditMoveQuantity
 * @param {Function} - onSelectInventoryIdList
 */
type Props = {
  isLoading?: boolean;
  count: number;
  productList: InventoryProductRow[];
  filteredData: InventoryProductRow[];
  warehouseId: string;
  locationId: string;
  onEditMoveQuantity?: (id: number, quantity: number) => void;
  onSelectInventoryIdList?: Function;
  quantityList: {
    [key: number]: number;
  };
  onPageChange: (limit: number, skip: number, sort?: string) => void;
  onSuccess: (edit?: boolean) => void;
  selectedRowIds: number[];
  setSelectedRowIds: Function;
  setColumns: Function;
};

type InventoryState = {
  deselectAuditProductRowIds: Array<number>;
  isEditingAuditProductRow: boolean;
};

type ColumnRenderType = {
  cellProps?: any;
  data: InventoryProductRow;
};
// eslint-disable-next-line
export default (props: Props) => {
  const { useCallback, useMemo, useState } = React;
  const {
    isLoading,
    productList,
    filteredData,
    onEditMoveQuantity,
    onSelectInventoryIdList,
    count,
    onPageChange,
    //quantityList,
    onSuccess,
    warehouseId,
    locationId,
    selectedRowIds,
    setSelectedRowIds,
    setColumns
  } = props;
  const [
    editProductQuantityDialogVisible,
    setEditProductQuantityDialogVisible,
  ] = useState(false);
  //const [gridRef, setGridRef] = useState<any>(null);
  const [inited, setInited] = useState(false);
  const [isPaging, setIsPaging] = useState(false);
  const [productDetailsDialogVisible, setProductDetailsDialogVisible] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<InventoryProductRow>(
    {} as InventoryProductRow
  );
  const gridSort = React.useRef('ProductTitle');
  const paramLimit = React.useRef(10);
  const paramSkip = React.useRef(0);
  const isLoadingProductList = isLoading ? isLoading : false;
  const deselectProductRowIds = useSelector(
    (state: RootState) =>
      (state.inventory as InventoryState).deselectAuditProductRowIds,
    shallowEqual
  );
  const isEditingProductRow = useSelector(
    (state: RootState) =>
      (state.inventory as InventoryState).isEditingAuditProductRow,
    shallowEqual
  );
  const [lotEnable, setLotEnable] = useState(true);
  const [uomEnable, setUomEnable] = useState(true);

  const selectedAllRow = useMemo(() => {
    return (
      selectedRowIds.length > 0 && selectedRowIds.length === productList.length
    );
  }, [selectedRowIds, productList]);

  const selectedSomeRow = useMemo(() => {
    return (
      selectedRowIds.length > 0 && selectedRowIds.length < productList.length
    );
  }, [selectedRowIds, productList]);

  const getSetting = useCallback(async()=>{
    const res1 = await getProfileSettingValue('EnableLOT', '0')
    const res2 = await getProfileSettingValue('EnableUOM','0')
      setLotEnable(res1 === '1')
      setUomEnable(res2 === '1')
  },[])

  React.useEffect(()=>{
    getSetting()
  },[getSetting])

  /**
   * Callback for edting value.
   * Currently it is only used for set the moving quantity.
   */
  const onEditValueChange = (edit: any) => {
    const { data, value /*columnIndex, rowIndex*/ } = edit;
    let real = value;

    if (real > data.quantity) {
      real = data.quantity;
      message.warning(
        `Your input value can not be bigger then ${data.quantity}`
      );
    } else if (real < 0) {
      real = 0;
      message.warning(`Your input value can not be less then 0`);
    }
    //console.log('edit value', value, real, edit);
    //gridRef?.current.startEdit({ columnId: edit.columnId, rowIndex: edit.rowIndex, value: real });
    onChangeMoveNumber(data, real);
  };

  /**
   * The callback when the move number was changed.
   */
  const onChangeMoveNumber = (row: InventoryProductRow, value: number) => {
    let list = [...selectedRowIds];

    if ('function' === typeof onEditMoveQuantity) {
      onEditMoveQuantity(row.id, value);
    }

    //console.log('row', row, value);
    if (value > 0) {
      if (selectedRowIds.indexOf(row.id) === -1) {
        list = [...list, row.id];
        setSelectedRowIds(list);
      }
    } else {
      let index = list.indexOf(row.id);

      if (index > -1) {
        list.splice(index, 1);
        setSelectedRowIds([...list]);
      }
    }

    if ('function' === typeof onSelectInventoryIdList) {
      onSelectInventoryIdList(list);
    }
  };

  /**
   * The callback when checked the select all row checkbox.
   */
  const onCheckAllRow = useCallback(
    (checked: boolean) => {
      let list: Array<any> = [];

      if (checked) {
        list = productList.map((e) => e.id);
      }

      setSelectedRowIds(list);
      console.log('check all', list);

      if ('function' === typeof onSelectInventoryIdList) {
        onSelectInventoryIdList(list);
      }
    },
    // eslint-disable-next-line
    [onSelectInventoryIdList, productList]
  );

  /**
   * The callback when select the row checkbox.
   */
  const onCheckRow = (id: number, checked: boolean) => {
    let index = -1;
    let list = [...selectedRowIds];

    for (let i = 0; i < selectedRowIds.length; i++) {
      if (id === selectedRowIds[i]) {
        index = i;
        break;
      }
    }
    console.log('list', selectedRowIds, id);

    if (index > -1) {
      if (!checked) {
        list.splice(index, 1);
      }
    } else {
      if (checked) {
        list.push(id);
      }
    }

    setSelectedRowIds(list);

    if ('function' === typeof onSelectInventoryIdList) {
      //console.log('ppp list', list);
      onSelectInventoryIdList(list);
    }
  };

  const onGridPageChange = React.useCallback(
    async (limit: number, skip: number) => {
      setIsPaging(true);
      paramLimit.current = limit;
      paramSkip.current = skip;
      await onPageChange(limit, skip, gridSort.current);
      setIsPaging(false);
    },
    [gridSort, onPageChange]
  );

  const onSortChange = async (info: any) => {
    const sort = ['ProductTitle', ''];

    if (info) {
      let { dir, id } = info;

      sort[0] = id === 'title' ? 'ProductTitle' : id;
      sort[1] = dir > 0 ? 'asc' : 'desc';
      //console.log('-=>', id, dir);
    }

    const clause = sort.filter((e) => e[1]).join(' ');

    //setGridSort(clause);
    gridSort.current = clause;
    setIsPaging(true);
    await onPageChange(paramLimit.current, paramSkip.current, clause);
    setIsPaging(false);
  };

  const openEditProductQuantityDialog = () => {
    setEditProductQuantityDialogVisible(true);
  };

  const openProductDetailsDialog = () => {
    setProductDetailsDialogVisible(true);
  };

  const getAuditColumns = ()=>{
    const columns =  [
      {
        name: 'checkbox',
        header: 'checkbox',
        showColumnMenuTool: false,
        groupBy: false,
        maxWidth: 40,
        minWidth: 40,
        defaultFlex: 1,
        resizable: false,
        sortable: false,
        textAlign: 'center' as 'center',
        render: (value: ColumnRenderType) => {
          const { data } = value;
          //console.log('row id', selectedRowIds, data);
          return (
            <Checkbox
              id={`${data.sku}_checkbox`}
              checked={selectedRowIds.indexOf(data.id) > -1}
              onChange={(evt) => {
                console.log('p -=+ >', productList, props);
                onCheckRow(data.id, evt.target.checked);
              }}
            />
          );
        },
        renderHeader() {
          return (
            <Checkbox
              id="check_all_checkbox"
              checked={selectedAllRow}
              indeterminate={selectedSomeRow}
              onChange={(evt) => onCheckAllRow(evt.target.checked)}
            />
          );
        },
      },
      {
        name: 'sku',
        header: 'SKU',
        defaultFlex: 1,
        minWidth: 220,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <SpaceCell
              icon={
                <Tooltip
                  placement="top"
                  title="Copy"
                  trigger={['hover', 'click']}
                >
                  <CopyOutlined
                    onClick={() => {
                      copy(data.sku);
                      message.info(`"${data.sku}" has been copied to clipboard`);
                    }}
                    style={{ color: theme['@info-color'] }}
                  />
                </Tooltip>
              }
              iconWidth={DATAGRID_CELL_ICON_WIDTH1}
              onTextClick={() => {
                setSelectedProduct(data);
                openProductDetailsDialog();
              }}
              text={data.sku}
              textIsButton
            />
          );
        },
      },
      {
        name: 'upc',
        header: 'UPC',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.upc} />;
        },
      },
      // {
      //   name: 'lotNumber',
      //   header: 'Lot Number',
      //   defaultFlex: 1,
      //   userSelect: true,
      // },
      {
        name: 'title',
        header: 'Product Name',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.title} />;
        },
      },
      {
        name: 'partNumber',
        header: 'Part Number',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.partNumber} />;
        },
      },
      {
        name: 'lotNumber',
        header: 'LOT#',
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.lotNumber || ''} />;
        },
      },
      {
        name: 'uom',
        header: 'UOM',
        isUom: true,
        defaultFlex: 1,
        userSelect: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return <CopySpan inGrid value={data.uom || ''} />;
        },
      },
      {
        name: 'uomRate',
        header: 'Rate',
        minWidth: 150,
        sortable: false,
        defaultFlex: 1,
        isUom: true,
      },
      {
        name: 'quantity',
        header: 'Quantity',
        defaultFlex: 1,
        // maxWidth: 110,
        minWidth: 110,
        resizable: false,
        type: 'number',
        headerAlign: 'center' as 'center',
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <>
              {data.quantity}
              <GridIconBox>
                <EditOutlined
                  onClick={() => {
                    setSelectedProduct(data);
                    openEditProductQuantityDialog();
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      {
        name: 'uomBaseQty',
        header: 'Base Qty',
        minWidth: 150,
        sortable: false,
        defaultFlex: 1,
        isUom: true,
      },
      /*{
        name: '',
        header: '',
        defaultFlex: 1,
        maxWidth: 150,
        minWidth: 150,
        resizable: false,
        sortable: false,
        textAlign: 'center' as 'center',
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <InputNumber
              key={`${data.id}-${data.locationId}-${data.productNum}`}
              size="large"
              min={0}
              max={data.quantity}
              defaultValue={quantityList[data.id]}
              style={{ width: '100%', height: '100%' }}
              onChange={(value) => onChangeMoveNumber(data, value)}
            />
          );
        },
      },*/
    ];
    let temp = [...columns]
    temp = lotEnable ? temp : temp.filter(i=> i.name !== 'lotNumber')
    temp = uomEnable ? temp : temp.filter(i => !i.isUom)
    return temp
  }

  React.useEffect(() => {
    if (!inited) {
      setColumns(getAuditColumns());
      if (isLoading) {
        onCheckAllRow(false);
      }

      setInited(true);
    }

    if (isEditingProductRow) {
      //console.log('desel', deselectProductRowIds);
      if (deselectProductRowIds.length > 0) {
        let list = [...selectedRowIds];

        for (let i = list.length - 1; i >= 0; i--) {
          if (deselectProductRowIds.indexOf(list[i]) > -1) {
            list.splice(i, 1);
          }
        }

        if (list.length < selectedRowIds.length) {
          setSelectedRowIds(list);

          if ('function' === typeof onSelectInventoryIdList) {
            onSelectInventoryIdList(list);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    deselectProductRowIds,
    inited,
    isEditingProductRow,
    isLoading,
    onCheckAllRow,
    onSelectInventoryIdList,
    selectedRowIds,
  ]);

  return (
    <>
      {/* <Table
        bordered
        columns={productColumns}
        loading={isLoadingProductList}
        showSorterTooltip={false}
        rowKey={(record: InventoryProductRow) => record.id}
        dataSource={productList}
        style={{ overflow: 'auto', width: '100%' }}
      /> */}
      <DataGrid
        cacheKey={DATAGRID_CACHE_AUDIT_TABLE_COLS}
        columns={getAuditColumns()}
        count={count}
        dataSource={filteredData}
        idProperty="id"
        loading={isLoadingProductList || isPaging}
        onEditValueChange={onEditValueChange}
        onSortInfoChange={onSortChange}
        // onReady={setGridRef}
        // showColumnMenuTool={false}
        onChange={onGridPageChange}
        pagination="remote"
        showColumnMenuTool
        style={{ height: 'calc(100vh - 406px)' }}
        {...({} as any)}
      />
      {editProductQuantityDialogVisible && (
        <EditProductQuantityDialog
          product={selectedProduct}
          warehouseId={warehouseId}
          locationId={locationId}
          onSuccess={() => {
            onSuccess(true);
          }}
          onHide={() => setEditProductQuantityDialogVisible(false)}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
        />
      )}
      {productDetailsDialogVisible && selectedProduct && (
        <ProductDetailsDialog
          visible
          productId={selectedProduct.productId}
          onHide={() => setProductDetailsDialogVisible(false)}
        />
      )}
    </>
  );
};
