import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Switch, Typography, message, Space } from "antd";
import { FormLabel, ModalDialog } from "components/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { fetchProductLOTSettings, updateProductLOTSettings } from "services/lotSetting";
import { patchProduct } from "services/product";
import styled from "styled-components";

const FormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    height: 0;
  }
`

const LOTRadio = styled(Radio)`
  margin-bottom: 10px;
`

type LOTSettingDialogProps = {
  product: ProductRow;
  onClose: () => void;
  onRefresh: () => void;
  visible: boolean;
}

const LOTSettingDialog = (props: LOTSettingDialogProps) => {
  const { onClose, onRefresh, visible, product } = props;
  const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState<boolean>(false);
  const refreshBtnRef = useRef<any>(null);
  const [spinning, setSpinning] = useState<boolean>(true);
  const [saveLoading, setSaveLoading] = useState<boolean>(true);
  const [tempParams, setTemParams] = useState<any>();
  const [uniqueFields, setUniqueFields] = useState<any>();
  // const [useRule, setUseRule] = useState<number>(0);
  // const [ruleValue, setRuleValue] = useState<number>(0);
  // const [controlTotalLength, setControlTotalLength] = useState<boolean>(true);
  // const [prefix, setPrefix] = useState<string>('');
  // const [prefixSeparator, setPrefixSeparator] = useState<string>('');
  // const [dateFormat, setDateFormat] = useState<string>('');
  // const [timeFormat, setTimeFormat] = useState<string>('');
  // const [suffixSeparator, setSuffixSeparator] = useState<string>('');
  // const [suffix, setSuffix] = useState<string>('');
  // const [appendTime, setAppendTime] = useState<boolean>(false);

  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const detailDialogWidth = useCallback(
    (isFullscreen = detailDialogIsFullscreen) => {
      return isFullscreen
        ? window.innerWidth - 4
        : window.innerWidth > 1280
          ? window.innerWidth * 0.8
          : 1200;
    },
    [detailDialogIsFullscreen]
  );

  const getDialogDom = () => {
    const refreshBtn = refreshBtnRef?.current;

    if (refreshBtn) {
      return refreshBtn.parentNode.parentNode.parentNode.parentNode.parentNode
        .parentNode.parentNode;
    }

    return null;
  };

  const onFullscreen = (isFullscreen: boolean) => {
    const dialog = getDialogDom();

    setDetailDialogIsFullscreen(isFullscreen);

    if (dialog) {
      const width = detailDialogWidth(isFullscreen);

      dialog.style.width = `${width}px`;
    }
  };

  const getProductLOTSettings = async () => {
    if (!product.fulfillmentProductNum) return;
    setSpinning(true);
    setSaveLoading(true);
    try {
      const data = await fetchProductLOTSettings(product.fulfillmentProductNum);
      setTemParams(data);
      form.setFieldsValue({
        ...data,
        useRule: product.useRule,
        lotControl: product.lotControl
      });
      setUniqueFields({
        lotControl: product.lotControl,
        useRule: product.useRule,
        ruleValue: data.rule,
        prefix: data.prefix,
        prefixSeparator: data.prefixSeparator,
        dateFormat: data.dateFormat,
        timeFormat: data.timeFormat,
        suffixSeparator: data.suffixSeparator,
        suffix: data.suffix,
        appendTime: data.appendTime === 1 ? true : false,
        controlTotalLength: data.totalLength === 0 ? false : true
      })
      // setUseRule(product.useRule);
      // setRuleValue(data.rule);
      // setPrefix(data.prefix);
      // setPrefixSeparator(data.prefixSeparator);
      // setDateFormat(data.dateFormat);
      // setTimeFormat(data.timeFormat);
      // setSuffixSeparator(data.suffixSeparator);
      // setSuffix(data.suffix);
      // setAppendTime(data.appendTime === 1 ? true : false);
      // setControlTotalLength(data.totalLength === 0 ? false : true);
    } catch (error) {
      console.log(error);
    } finally {
      setSpinning(false);
      setSaveLoading(false);
    }
  }

  const handleSave = async () => {
    setSaveLoading(true);
    if (!product.fulfillmentProductNum) return;
    try {
      const params = await form.validateFields();
      if (params.errorFields) return;
      delete params.controlTotalLength;
      delete params.useRule;
      delete params.lotControl;
      const queryParams = {
        ...tempParams,
        ...params,
        appendTime: params.appendTime ? 1 : 0,
        totalLength: uniqueFields.controlTotalLength ? params.totalLength : 0,
      }
      const res1 = await updateProductLOTSettings(product.fulfillmentProductNum, queryParams);
      const res2 = await patchProduct(product.productId, {
        useRule: uniqueFields.useRule,
        lotControl: uniqueFields.lotControl
      });
      if (res1 && res2) {
        message.success('Process Susccessfully!');
        // getProductLOTSettings();
        onRefresh();
        onClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSaveLoading(false);
    }
  }

  useEffect(() => {
    getProductLOTSettings();
    // eslint-disable-next-line
  }, [])

  return (
    <ModalDialog
      centered
      className="fullscreen-modal"
      closable={false}
      closeButton={true}
      // footer={null}
      fullscreen={true}
      maskClosable={false}
      onClose={onClose}
      onFullscreen={onFullscreen}
      title={`LOT Settings - ${product.sku}`}
      visible={visible}
      width={detailDialogWidth()}
      // titleRightCrumb={<Button
      //   type="primary"
      //   loading={saveLoading}
      //   onClick={() => handleSave()}
      //   icon={<SaveOutlined />}
      // >
      //   Process
      // </Button>}
      footer={
        <Row justify="end">
          <Space>
            <Button
              type="primary"
              loading={saveLoading}
              onClick={() => handleSave()}
              icon={<SaveOutlined />}
            >
              Save
            </Button>
            <Button onClick={onClose} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      {uniqueFields && (
        <Card loading={spinning} style={{ height: '100%', overflowY:'auto' }}>
          <Form form={form} {...layout}>
            <Form.Item label={<FormLabel>SKU</FormLabel>}>{product.sku}</Form.Item>
            <Form.Item label={<FormLabel>Product Name</FormLabel>}>{product.productTitle}</Form.Item>

            <FormItem name="lotControl" label={<FormLabel>LOT Control</FormLabel>}>
              <Radio.Group value={uniqueFields.lotControl} onChange={e => setUniqueFields({ ...uniqueFields, lotControl: e.target.value })}>
                <LOTRadio value={1}>Full LOT Control</LOTRadio>
                <LOTRadio value={0}>No LOT Control</LOTRadio>
              </Radio.Group>
            </FormItem>

            <FormItem name="useRule" label={<FormLabel>LOT Number Rule</FormLabel>}>
              <Radio.Group value={uniqueFields.useRule} onChange={e => setUniqueFields({ ...uniqueFields, useRule: e.target.value })}>
                <LOTRadio value={0}>Use Default Rule</LOTRadio>
                <LOTRadio value={1}>Use SKU Rule</LOTRadio>
              </Radio.Group>
            </FormItem>

            {uniqueFields.useRule === 1 && (<>
              <FormItem name="rule" label={<FormLabel noColon> </FormLabel>}>
                <Radio.Group value={uniqueFields.ruleValue} onChange={e => setUniqueFields({ ...uniqueFields, ruleValue: e.target.value })}>
                  <LOTRadio value={0}>By Sequence</LOTRadio>
                  <LOTRadio value={1}>By Date</LOTRadio>
                </Radio.Group>
              </FormItem>
              {uniqueFields.ruleValue === 0 && (
                <Card>
                  <Form.Item
                    name="prefix"
                    label={<FormLabel>Prefix</FormLabel>}
                    rules={[{ required: true, message: 'please input Prefix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.prefix}
                      onChange={e => setUniqueFields({ ...uniqueFields, prefix: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item name="startNumber" label={<FormLabel>Start Number</FormLabel>}>
                    <Input style={{ width: 250 }} />
                  </Form.Item>
                  <Form.Item
                    name="suffix"
                    label={<FormLabel>Suffix</FormLabel>}
                    rules={[{ required: true, message: 'please input Suffix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.suffix}
                      onChange={e => setUniqueFields({ ...uniqueFields, suffix: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item name="controlTotalLength" label={<FormLabel>Control Total Length</FormLabel>}>
                    <Switch
                      checked={uniqueFields.controlTotalLength}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={e => setUniqueFields({ ...uniqueFields, controlTotalLength: e })}
                    />
                  </Form.Item>
                  {uniqueFields.controlTotalLength && (
                    <>
                      <Form.Item
                        name="totalLength"
                        label={<FormLabel>Total Length</FormLabel>}
                        rules={[{ required: uniqueFields.controlTotalLength, message: 'please input Total Length' }]}
                      >
                        <InputNumber
                          style={{ width: 250 }}
                          step={1}
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item name="paddingZero" label={<FormLabel>Zero Padding</FormLabel>}>
                        <Radio.Group>
                          <Radio value={0}>Between Prefix and Date</Radio>
                          <Radio value={1}>Between date and Suffix</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}
                </Card>
              )}
              {uniqueFields.ruleValue === 1 && (
                <Card>
                  <Row wrap={false}>
                    <Col span={6}>
                      <div style={{ textAlign: 'right', marginBottom: 10, marginRight: 10 }}><FormLabel>Format</FormLabel></div>
                    </Col>
                    <Col span={14}>
                      <Typography.Title level={5}>
                        {`${uniqueFields.prefix}${uniqueFields.prefixSeparator}${uniqueFields.dateFormat}${uniqueFields.appendTime ? uniqueFields.timeFormat : ''}${uniqueFields.suffixSeparator}${uniqueFields.suffix}`}
                      </Typography.Title>
                    </Col>
                  </Row>
                  <Form.Item
                    name="prefix"
                    label={<FormLabel>Prefix</FormLabel>}
                    rules={[{ required: true, message: 'please input Prefix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.prefix}
                      onChange={e => setUniqueFields({ ...uniqueFields, prefix: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="prefixSeparator"
                    label={<FormLabel>Prefix Separator</FormLabel>}
                    rules={[{ required: true, message: 'please input Prefix Separator' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.prefixSeparator}
                      onChange={e => setUniqueFields({ ...uniqueFields, prefixSeparator: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="dateFormat"
                    label={<FormLabel>Date Format</FormLabel>}
                    rules={[{ required: true, message: 'please select Date Format' }]}
                  >
                    <Radio.Group
                      value={uniqueFields.dateFormat}
                      onChange={e => setUniqueFields({ ...uniqueFields, dateFormat: e.target.value })}
                    >
                      <Radio value="YYYYMMDD">YYYYMMDD</Radio>
                      <Radio value="YYMMDD">YYMMDD</Radio>
                      <Radio value="YYDDD">YYDDD</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name="appendTime" label={<FormLabel noColon> </FormLabel>} valuePropName="checked">
                    <Checkbox
                      checked={uniqueFields.appendTime}
                      onChange={e => setUniqueFields({ ...uniqueFields, appendTime: e.target.checked })}
                    >
                      Append Time
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="timeFormat"
                    label={<FormLabel noColon> </FormLabel>}
                    rules={[{ required: uniqueFields.appendTime, message: 'please select Time Format' }]}
                  >
                    <Radio.Group
                      value={uniqueFields.timeFormat}
                      onChange={e => setUniqueFields({ ...uniqueFields, timeFormat: e.target.value })}
                    >
                      <Radio value="hhmm">hhmm</Radio>
                      <Radio value="hhmmss">hhmmss</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="suffixSeparator"
                    label={<FormLabel>Suffix Separator</FormLabel>}
                    rules={[{ required: true, message: 'please input Suffix Separator' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.suffixSeparator}
                      onChange={e => setUniqueFields({ ...uniqueFields, suffixSeparator: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="suffix"
                    label={<FormLabel>Suffix</FormLabel>}
                    rules={[{ required: true, message: 'please input Suffix' }]}
                  >
                    <Input
                      style={{ width: 250 }}
                      value={uniqueFields.suffix}
                      onChange={e => setUniqueFields({ ...uniqueFields, suffix: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item name="controlTotalLength" label={<FormLabel>Control Total Length</FormLabel>}>
                    <Switch
                      checked={uniqueFields.controlTotalLength}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={e => setUniqueFields({ ...uniqueFields, controlTotalLength: e })}
                    />
                  </Form.Item>
                  {uniqueFields.controlTotalLength && (
                    <>
                      <Form.Item
                        name="totalLength"
                        label={<FormLabel>Total Length</FormLabel>}
                        rules={[{ required: uniqueFields.controlTotalLength, message: 'please input Total Length' }]}
                      >
                        <InputNumber
                          style={{ width: 250 }}
                          step={1}
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item name="paddingZero" label={<FormLabel>Zero Padding</FormLabel>}>
                        <Radio.Group>
                          <Radio value={0}>Between Prefix and Date</Radio>
                          <Radio value={1}>Between date and Suffix</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}
                </Card>
              )}
            </>)}
          </Form>
        </Card>
      )}
    </ModalDialog>
  )
}

export default LOTSettingDialog;