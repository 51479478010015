import React, { useState, useCallback, useEffect } from 'react';
import { Row, Input } from 'antd';
import SelectLocationDialog from './SelectLocationDialog';
// import { fetchInventory } from 'services/product';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { message } from 'components/common';

const { Search } = Input;

type SearchLocationProps = {
  warehouseNum: number;
  warehouseId: string;
  placeholder?: string;
  value?: string;
  width?: number;
  sku?: string;
  onChange: (location?: WarehouseLocationRow) => void;
};
/**
 * Return SelectLocation Component
 * @param {SelectLocationPlusProps} props
 * @returns {JSX.Element}
 */
export default function SelectLocationPlus(
  props: SearchLocationProps
): JSX.Element {
  const {
    warehouseNum,
    onChange,
    value: oriValue,
    width,
    sku,
    warehouseId,
  } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  // const [location, setLocation] = useState<WarehouseLocationRow>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState<boolean>(false);
  useEffect(() => {
    if (typeof oriValue === 'string') {
      setValue(oriValue || '');
    }
  }, [oriValue]);

  const { placeholder = 'location' } = props;
  /**
   * handle press Enter
   */
  const handlePressEnter = useCallback(async () => {
    if (!value.trim()) return;
    try {
      setLoading(true);
      const res = await fetchWarehouseLocationList(warehouseId, false, value);

      if (res.length > 0) {
        // const filter = `sku eq '${sku}' and locationCode eq '${value}'`;
        // const res1 = await fetchInventory(
        //   { id: warehouseId },
        //   { $filter: filter }
        // );
        onChange(res[0]);
        // setLocation(res[0]);
        setValue(res[0].code);
        // message.success({ content: 'Location Code matched' });
      } else {
        message.warning({ content: 'Location Code not matched' });
        onChange(undefined);
        // setLocation(undefined);
        setValue('');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [value, onChange, warehouseId]);

  const style: StringKAnyVPair = { width: width || 200 };
  return (
    <Row align="middle">
      <Search
        style={style}
        disabled={!sku}
        placeholder={placeholder || 'Location'}
        loading={loading}
        value={value}
        defaultValue={value}
        onChange={(e) => {
          const { value: currentValue } = e.target;
          setValue(currentValue);
        }}
        onBlur={() => {
          handlePressEnter();
        }}
        onPressEnter={() => handlePressEnter()}
        onKeyDown={(e) => e.keyCode === 9 && handlePressEnter()}
        onSearch={() => setSelectLocationDialogVisible(true)}
      />
      {selectLocationDialogVisible && (
        <SelectLocationDialog
          visible={true}
          sku={sku}
          warehouseId={warehouseId}
          warehouseNum={warehouseNum}
          onSelect={(location: WarehouseLocationRow, inv: any[]) => {
            setValue(location.code);
            onChange(location);
            setSelectLocationDialogVisible(false);
          }}
          onHide={() => {
            setSelectLocationDialogVisible(false);
          }}
        />
      )}
    </Row>
  );
}
SelectLocationPlus.defaultProps = {
  placeholder: undefined,
  value: undefined,
  width: undefined,
  showSearchButton: undefined,
  onChange: undefined,
};
