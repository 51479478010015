import React from 'react';
import { Modal, Typography, Button } from 'antd';
import { ButtonIcon } from 'components/common';
import { SmartRow } from '../../../SmartComponent';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type OrderFinishDialogProps = {
  sLocation: string;
  tLocation: string;
  sku: string;
  upc: string;
  qty: number;
  onSubmit: (value: string) => void;
};
export default function OrderFinishDialog(
  props: OrderFinishDialogProps
): JSX.Element {
  const { sLocation, tLocation, sku, upc, qty, onSubmit } = props;
  const { t } = useTranslation()

  /**
   * return modal title
   * @returns {JSX.Element}
   */
  const Title = (): JSX.Element => {
    return (
      <SmartRow justify="center">
        <ButtonIcon className="bi-emoji-smile" />
        <div style={{ marginLeft: 5 }}>{'Move Done!'}</div>
      </SmartRow>
    );
  };
  return (
    <Modal visible width={300} title={<Title />} footer={null} closable={false}>
      <SmartRow justify="space-between">
        <Text>
          {t("smart.movemerge.from")}:
          <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
            {sLocation}
          </Text>
        </Text>
      </SmartRow>
      <SmartRow>
        <Text>
          {t("smart.movemerge.to")}:
          <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
            {tLocation}
          </Text>
        </Text>
      </SmartRow>
      <SmartRow justify="space-between">
        <Text>
          {t("common.sku")}:
          <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
            {sku}
          </Text>
        </Text>
      </SmartRow>
      <SmartRow>
        <Text>
          {t("common.upc")}:
          <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
            {upc}
          </Text>
        </Text>
      </SmartRow>
      <SmartRow>
        <Text>
          {t("common.qty")}:
          <Text strong style={{ marginLeft: 4, fontSize: 18 }}>
            {qty}
          </Text>
        </Text>
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <Button
          type="default"
          style={{ width: 250 }}
          onClick={() => {
            onSubmit('home');
          }}
        >
          {t("smart.movemerge.backHome")}
        </Button>
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <Button
          type="primary"
          style={{ width: 250 }}
          onClick={() => {
            onSubmit('sourceLocation');
          }}
        >
          {t("smart.movemerge.startFormNewSourceLocation")}
        </Button>
      </SmartRow>
      <SmartRow style={{ marginTop: 20 }} justify="center">
        <Button
          type="primary"
          style={{ width: 250 }}
          onClick={() => {
            onSubmit('item');
          }}
        >
          {t("smart.movemerge.startFromNewItem")}
        </Button>
      </SmartRow>
    </Modal>
  );
}
